<screen-detection (resized)="isDesktop = $event.isDesktop"></screen-detection>

<div class="main__wrapper main__wrapper--mb-lg">

  <ng-template #contentDialog>
    <div  class="flex justify-end margin-bottom--xs flex-row">
      <button class="bunk-overlay__close-btn" (click)="dref.close()"><mat-icon svgIcon="icon-close"></mat-icon></button>
    </div>

    <mat-dialog-content>

      <h2 class="margin-bottom--sm">Sign up as a tenant and {{(viewingRequest ? 'book a viewing' : 'rent property')}}</h2>

      <small class="flex flex-row items-center margin-bottom--md">Already have an account ? <button class="btn__pseudo-link" (click)="logIn()">Sign in</button></small>

      <form [formGroup]="signupformGroup" >
        <form-create-account formControlName="signup" roleInput="tenant" [showLabel]="false" direction="column"
                             (submitForm)="createAccountAndMakeOffer(signupformGroup.value, property.landlord_uid)"
                             [showSpinner]="showPopUpSpinner$.value" title="Create account & continue">
        </form-create-account>

      </form>


    </mat-dialog-content>

  </ng-template>

  <button *ngIf="!isDesktop" class="btn__crumb-back truncate" (click)="goBackToSearch()">Search</button>
  <nav *ngIf="isDesktop" class="bread-crumbs margin-bottom--sm">
    <ol>
      <li (click)="goBackToSearch()" class="bread-crumb-prior">Search</li>
      <li *ngIf="titleToDisplay()">{{ titleToDisplay() }}</li>
    </ol>
  </nav>

  <div class="main__content-row">

    <div class="main__content-body main__content-body--aside">
      <div class="card__base">
        <property-details [property]="property">
          <div bookingSection>
            <ng-container *ngTemplateOutlet="hmoOffer"></ng-container>
          </div>
        </property-details>
      </div>
    </div>


    <aside class="main__content-aside main__content-aside--right"
           *ngIf="!display.isDeleted && display.isListed && isDesktop">
      <ng-container *ngTemplateOutlet="BookingTemplate"></ng-container>
    </aside>

    <ng-template #BookingTemplate>


      <section class="card__base" *ngIf="isDesktop">
        <h3 class="text--lg" *ngIf="!this.showSuccess">
          <mat-icon class="cursor-pointer" (click)="showSignUpForm = false" svgIcon="chevron-left"
                    *ngIf="showSignUpForm"></mat-icon>
          Book a viewing
        </h3>
        <ng-container *ngIf="!property.is_not_taking_viewings; else noMoreViewings">
          <ng-container *ngTemplateOutlet="bookViewing"></ng-container>
        </ng-container>

        <ng-template #noMoreViewings >
          <div class="block-info">
            Viewings for this property are now fully booked. Please return to the search page to see all available properties.

            <button class="btn__generic margin-top--sm" (click)="goBackToSearch()"> Search</button>
          </div>

        </ng-template>
      </section>

      <section class="margin-top--sm" [ngClass]="{'card__base ': isDesktop}" *ngIf="isDesktop || !isDesktop && display.isHMO">
        <h3 class="text--lg margin-bottom-sm">{{TRANSLATIONS.rent_property}}</h3>
        <span >Fancy this as your new home? Let the {{landlord}} know and apply now.</span>
          <div *ngIf="!display.isHMO && property.is_under_offer" class="block-info margin-top--sm">
            Please be aware of possible delayed responses as this property is 'Under Offer'.
          </div>
          <div *ngIf="display.isHMO && display.totalRoomsUnderOffer >= 1" class="block-info margin-top--sm">
            Please be aware of possible delayed responses on {{client_data.unit_name}}s that are 'Under Offer'.
          </div>

          <div *ngIf="display.isHMO && display.listingType === 'block'" class="block-chip margin-top--sm">
            <mat-icon svgIcon="house-icon"></mat-icon>

            <span>
              <ng-container  *ngIf="display.totalRoomsAvailable < display.totalRooms; else allAvailable">
              Only <strong>{{display.totalRoomsAvailable}}</strong> of <strong>{{display.totalRooms}}</strong> Available
              </ng-container>
              <ng-template #allAvailable><strong>{{display.totalRooms}}</strong> Available</ng-template>
            </span>
        </div>

  <ng-container *ngTemplateOutlet="hmoOffer"></ng-container>
        <div class="margin-top" *ngIf="isDesktop && !display.isHMO || isDesktop && display.isHMO && display.listingType === 'block' ">
          <ng-container *ngTemplateOutlet="makeOfferButtons"></ng-container>
        </div>

      </section>

      <section class="margin-top--sm" [ngClass]="{'card__base ': isDesktop}"
      *ngIf="(managerData$ | async) as managerData">
<span class="property-manager hide-sm hide-md">
 <small *ngIf="managerData.companyName" class="arrange">Managed by <strong>{{managerData.companyName}}</strong></small>
 <div>
 <img *ngIf="managerData.bannerAsideLg" (click)="viewAllPropsByManager()" class="property-manager__logo"
      src="{{managerData.bannerAsideLg}}"
      alt="{{ managerData.companyName ? managerData.companyName : 'managerData data'}}">

</div>
<ng-container *ngIf="!managerData.isTeamData">
 <div class="property-manager__partner-bar">
   <img src="/assets/logos/partner-logo.svg" class="property-manager__logo-partner" #tooltip="matTooltip"
        matTooltip="A partner is a property manager whose properties have been fully verified and meet our quality standards"
        matTooltipPosition="above"
        aria-tooltip="A partner is a property manager whose properties have been fully verified and meet our quality standards">
   <button (click)="tooltip.show()" class="btn__pseudo-link">More info</button>
 </div>



 <button (click)="viewAllPropsByManager()"
         class="btn__seethrough btn--round btn--sm margin-top--md property-manager__view-all-btn">View all
   properties</button>
  </ng-container>
  <div class="arrange">
 <div *ngIf="managerData.contactNumber" class="margin-top--md"><span class="arrange__head">Contact </span><a
   href="{{'tel:' + managerData.contactNumber}}">{{managerData.contactNumber}}</a></div>
   <div *ngIf="managerData.contactEmail" class="margin-top--md"><span class="arrange__head">Email</span> <a
     href="mailto: {{managerData.contactEmail}}">{{managerData.contactEmail}}</a></div>
    <ng-container *ngIf="managerData.timings">
     <div class="margin-top--md"><span class="arrange__head">Hours</span>
       <div class="arrange__item">
      <div *ngFor="let timing of managerData.timings">
        <span class="arrange__timings">{{timing.day | titlecase}} </span>
        <span *ngIf="timing.opens">{{timing.opens}} - {{timing.closes}}</span>
        <span *ngIf="!timing.opens && !timing.closes"> CLOSED </span>
      </div>
       </div>
      </div>
    </ng-container>
  </div>

</span>
</section>
    </ng-template>

  </div>
</div>


<ng-template #hmoOffer>

  <ul *ngIf="display.isHMO && display.listingType !== 'block'" class="hmo__room-list">
    <ng-container *ngFor="let bedroom of display.bedrooms">

      <li *ngIf="bedroom.is_listed">
        <div class="rc jc_sb">
          <div class="room-image" [ngClass]="{'image__placeholder image__placeholder--preview': !bedroom.room_thumbnail_url
          || bedroom.room_thumbnail_url && bedroom.room_thumbnail_url.image_url == null}">
            <img *ngIf="bedroom.room_thumbnail_url && bedroom.room_thumbnail_url.image_url !== null "
                 src="{{bedroom.room_thumbnail_url?.image_small_url}}" alt="Bedroom Image">
          </div>
          <span class="cc">
            <p class="price text--primary-hc text--md" *ngIf="bedroom.listed_rent_pcm">

              {{client_data.currency_symbol + getRent(bedroom)}}<small>
              {{ rentFrequency(bedroom.rent_interval_count)}}</small></p>

            <p *ngIf="bedroom.listed_deposit_amount || bedroom.deposit_amount" class="mb_0">
              {{client_data.currency_symbol + (bedroom.listed_deposit_amount || bedroom.deposit_amount)}}<small> Deposit</small> </p>
            <span *ngIf="bedroom.date_available"> <small>Available from</small> {{bedroom.date_available.toDate() | date: 'd/M/yy'}}</span>
            <p><em *ngIf="bedroom.is_under_offer">Under offer</em></p>
          </span>
        </div>
        <span>
          <strong *ngIf="bedroom.room_name">{{ bedroom.room_name }}</strong>
          <p *ngIf="bedroom.bed_type"><small>{{ bedroom.bed_type | titlecase }} bed</small></p>
        </span>
        <ng-container *ngIf="!_auth.authenticated">
          <bunk-dialog [component]="contentDialog" (dialogRef)="dref = $event" restrict="true">
            <button *ngIf="property.is_hmo" class="btn__cta margin-top--md w_100"
                    (click)="handleAnonymousOffer(bedroom.bedroom_id)">Apply now</button>
          </bunk-dialog>
        </ng-container>

        <button *ngIf="_auth.authenticated" class="btn__cta margin-top--md w_100 "
                (click)="handleTenantOffer(property.landlord_uid, bedroom.bedroom_id)">Apply now</button>
      </li>

    </ng-container>
  </ul>
</ng-template>



<div class="sticky-footer-mobile" *ngIf="!isDesktop">
  <div class="sticky-footer-button" [ngClass]="{'sticky-footer-button__hmo': display.isHMO  && display.listingType !== 'block'}" >

    <button class="btn__generic btn-lg" type="button" (click)="bookAViewing()">Book a viewing</button>

  </div>
  <div class="sticky-footer-button" *ngIf="!display.isHMO || display.listingType === 'block'">

    <ng-container *ngTemplateOutlet="makeOfferButtons"></ng-container>

  </div>
</div>


<ng-template #bookViewing>

  <form [formGroup]="formGroup">

    <book-viewing-form *ngIf="!showSignUpForm && !showSuccess" formControlName="book_viewing" [showLabel]="false">
    </book-viewing-form>

    <ng-container *ngIf="(requiredFields$ | async)">
      <ng-container *ngIf="book_viewing.valid">

        <div class="flex flex-column" *ngIf="_auth.authenticated && !showSignUpForm && !showSuccess">
          <strong *ngIf="!hasRequiredFields$.value">The {{landlord}} will receive:</strong>
          <span *ngIf="!hasRequiredFields$.value">Name, phone number and email address</span>
        </div>

        <div class="block-info" *ngIf="hasRequiredFields$.value">This {{landlord}} has requested a few extra details when
          booking a viewing.</div>

        <ng-container *ngIf="!_auth.authenticated && !showSuccess ">
          <div [hidden]="!showSignUpForm || showSuccess">
        <span class="margin-bottom">
          Booking on <strong>{{getMomentDate(book_viewing.value.viewing_date)}} </strong>at <strong>
            {{book_viewing.value.viewing_time | date:'shortTime'}}. </strong>
          <a href="#" (click)="showSignUpForm = false">Change</a>
        </span>
            <hr>

            <form-create-account
              formControlName="user_account" roleInput="tenant" [showLabel]="false" direction="column"
              (submitForm)="requestViewing()" [showSpinner]="showSpinner$.value" title="Request viewing">
            </form-create-account>

          </div>
        </ng-container>

        <div class="card__list-item-cta margin-top" [hidden]="showSuccess">

          <button (click)="requestViewing()" class="btn__generic" type="submit" *ngIf="(_auth.authenticated && !hasRequiredFields$.value); else next">
            <processing-spinner *ngIf="showSpinner$.value" color="#FFFFFF"></processing-spinner>
            <span
              *ngIf="!showSpinner$.value">Request viewing</span>
          </button>

          <ng-template #next>

            <bunk-dialog *ngIf="!_auth.authenticated && hasRequiredFields$.value" [component]="contentDialog" (dialogRef)="dref = $event" restrict="true">

              <button class="btn__generic" type="button" (click)="nextStep()">
                <processing-spinner *ngIf="showSpinner$.value" color="#FFFFFF"></processing-spinner>
                <span
                  *ngIf="!showSpinner$.value">Next</span>
              </button>
            </bunk-dialog>

            <ng-container *ngIf="(!_auth.authenticated && !hasRequiredFields$.value && !showSignUpForm ) || ( _auth.authenticated && hasRequiredFields$.value)">
              <button class="btn__generic" type="button" (click)="nextStep()">
                <processing-spinner *ngIf="showSpinner$.value" color="#FFFFFF"></processing-spinner>
                <span
                  *ngIf="!showSpinner$.value">Next</span>
              </button>
            </ng-container>

          </ng-template>


        </div>


      </ng-container>
    </ng-container>

    <div *ngIf="signingSpinner" class="flex justify-center">
      <processing-spinner colour="#FFFFFF" size="40px"></processing-spinner>
    </div>


  </form>
  <booking-success *ngIf="showSuccess" [property]="property" aside="true"></booking-success>

</ng-template>

<ng-template #makeOfferButtons>

  <button *ngIf="_auth.authenticated || display.listingType === 'block'" class="btn__cta btn-lg w_100" type="button"
          (click)="handleTenantOffer(property.landlord_uid)">Apply now</button>

  <ng-container *ngIf="!_auth.authenticated && display.listingType !== 'block'">
    <bunk-dialog [component]="contentDialog" (dialogRef)="dref = $event" restrict="true">
      <button class="btn__cta btn-lg w_100" type="button"
              (click)="handleAnonymousOffer()">Apply now</button>
    </bunk-dialog>
  </ng-container>

</ng-template>

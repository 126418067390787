import { AfterContentChecked, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { of } from 'rxjs/internal/observable/of';
import { AuthService } from '@app/core/services';
import { Admin } from '@env/routing';
import { environment } from '@env/environment';
import { TenancyModel } from '@app/_models/tenancy.model';
import { TenancyService, UserService } from '@app/services';
import { CompanyName } from '@app/shared/shared.constants';
import { EUserRole } from '@rentbunk/bunk-models';

interface INavSidebar {
  svg: string;
  label: string;
  routerLink: string;
  id?: string;
  counterValue$?: Observable<number>;
  bracketsValue$?: Observable<number>;
}

export type NavSidebarItems = INavSidebar[];

@Component({
  selector: 'nav-sidebar',
  templateUrl: './nav-sidebar.component.html',
  styleUrls: ['./nav-sidebar.component.scss']
})
export class NavSidebarComponent implements OnInit, AfterContentChecked {
  public adminRoutes$: Observable<any>;
  @ViewChild('sidenav', { static: true }) sidenav: MatSidenav;
  @Output() toggleSidebar: EventEmitter<Object>;

  @Input('navItems') set navItems(arr: NavSidebarItems) {
    this._navItems = arr;
    this.navbarListHovers = new Array(this._navItems.length);
    this.navbarListHovers.fill(false);
  }
  get navItems(): NavSidebarItems | null {
    return this._navItems;
  }
  get hasBackHome(): boolean {
    return CompanyName.isNrla && this._user.userDb.role !== EUserRole.tenant;
  }

  private _navItems: NavSidebarItems;
  public isDesktop: boolean;
  public navbarListHovers: boolean[];
  public client_data = environment.client_data;
  public activeTenancies$: Observable<TenancyModel[]>;
  public selectedTenanancy:Array<TenancyModel>;
  public isLoading = true;

  constructor(
    private cdref: ChangeDetectorRef,
    private _auth: AuthService,
    private _tenancy: TenancyService,
    private _user: UserService,
  ) {}

  ngAfterContentChecked() {
    // deal with responsive layout changes and `ExpressionChangedAfterItHasBeenCheckedError`
    this.cdref.detectChanges();
  }

  ngOnInit(): void {
    this.navbarListHovers = new Array(this.navItems.length);
    this.navbarListHovers.fill(false);
    this.adminRoutes$ = this._auth.getCurrentUserCustomClaims().pipe(
      tap((isAdmin: any) => {
        if (isAdmin) {
          const adminRoutes = this.createAdminRoutes()
          this.navbarListHovers = new Array(this.navItems.length + adminRoutes.length);
          this.navbarListHovers.fill(false);
          return this.navItems = [...this.navItems, ...this.createAdminRoutes()];
        }
        return of([]);
      }),
    );

    this.activeTenancies$ = this._tenancy.getTenanciesByTenantId().pipe(
      map((tenancies: Array<TenancyModel>) => {
        return tenancies.map((tenancy) => this._tenancy.getSelectTenancyLabels(tenancy) );
      }),tap( (selectedTenanancy :Array<TenancyModel>)=>{
        this.selectedTenanancy = selectedTenanancy
       this.isLoading =false;
      })
    );

    }

  createAdminRoutes() {
    return [
      {
        svg: 'bunk-icon-admin',
        label: 'Admin',
        routerLink: `${Admin.base}/${Admin.general.base}`,
        id: 'button_admin-properties'
      },
    ];
  }

  handleOpenMenu() {
    this.sidenav.open();
  }

  handleCloseMenu() {
    this.sidenav.close();
  }

  generateUTM() {
    const url = `https://rentbunk.com/?utm_source${this.client_data.company_name}&utm_medium=referral&utm_campaign=whitelabel%20product&utm_content=powered%20by%20bunk`;
    window.open(url, '_blank');
  }

  showDashboard() {
    const isCampusKey = environment.firebaseConfig.projectId === 'client-campus-key';
    const isTenant = this._user.userDb.role === 'tenant';
    const hasActiveTenancy = this.selectedTenanancy && this.selectedTenanancy.length > 1;
    const hideSideNav = isCampusKey && isTenant && !!hasActiveTenancy && this._user.userDb.has_tenant_living_dashboard;
    this.isLoading = false;
    const isDashboardView = !isTenant || this._user.userDb.has_tenant_living_dashboard || (isTenant && !this._user.userDb.has_tenant_living_dashboard);
    return !hideSideNav && !this.isLoading && isDashboardView;
  }

  goNrlaHome(): void {
    window.open('https://www.nrla.org.uk/account', '_self');
  }
}

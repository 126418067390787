import {Injectable, EventEmitter} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TenantVerifyEventEmiterService {
  public gotoStep = new EventEmitter();
  public gotoPrevStep = new EventEmitter();
  public gotoNextStep = new EventEmitter();
  public stepCompleted = new EventEmitter();
  public stepUpdated = new EventEmitter();

  navigateStep(direction: string): void {
    this.gotoStep.emit(direction);
  }

  prevStep() {
    this.gotoPrevStep.emit();
  }

  nextStep() {
    this.gotoNextStep.emit();
  }

  stepComplete() {
    this.stepCompleted.emit();
  }

  stepUpdate() {
    this.stepUpdated.emit();
  }
}

import { Component, Input, OnInit } from '@angular/core';
import { Tenant, Landlord, Profile } from '@env/routing';
import { UserService } from '@app/services';
import { AuthService } from '@app/core/services';
import { finalize, flatMap } from 'rxjs/operators';
import { BunkUploaderModel } from '@app/shared/_components';

@Component({
  selector: 'account-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  public routes = { Tenant, Landlord, Profile };
  @Input() user;
  @Input() formGroup;
  showSpinner = false;
  edit = false;
  filePath = `user/${this._auth.currentUserId}/profile`;

  constructor(
    public _user: UserService,
    private _auth: AuthService
  ) { }

  editItem() {
    this.edit = !this.edit;
  }

  saveBio() {
    this._user.updateUserBio(this.formGroup.get('bio').value)
      .subscribe(
        success => console.log('Success upload bio'),
        error => console.log(error)
      );
  }

  onUpStart = () => {
    this.showSpinner = true;
  };

  onUploadedFile = (task: BunkUploaderModel) => {
    this._user.updateProfileImage(task.image.image_original_url).pipe(
      finalize(() => this.showSpinner = false)
    ).subscribe();
  };

  ngOnInit() { }
}

import { Injectable } from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import { environment } from '@env/environment';


@Injectable({
  providedIn: 'root',
})
export class MaintenanceGuard implements CanActivate {
  constructor(private router: Router,
  ){}

  canActivate() {
    if (environment.firebaseConfig.projectId === "client-living-way") {
      this.router.navigate(['/']);
      return false;
    } else {
      return true;
    }
  }

}

import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { combineLatest, Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})

export class TenancyPackageService {
  public tenancyPackageCollection: AngularFirestoreCollection<any>;
  constructor(
    public afAuth: AngularFireAuth,
    private readonly afs: AngularFirestore
  ) {
    this.tenancyPackageCollection = afs.collection<any>('tenancy_packages');
  }

  getDocTenancyPackageById = (id: string): AngularFirestoreDocument<any> => this.tenancyPackageCollection.doc(id);

  getDocTenancyPackageByIds = (ids: string[]): AngularFirestoreDocument<any>[] => ids.map(id => this.getDocTenancyPackageById(id));

  getTenancyPackagesByIds = (ids: string[]): Observable<any[] | undefined> => combineLatest(this.getDocTenancyPackageByIds(ids).map(ref => ref.valueChanges()));


  getPackageById(package_id: string) {
    return this.tenancyPackageCollection.doc(package_id);
  }
}

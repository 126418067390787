/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./bunk-overlay.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i3 from "@angular/material/icon";
import * as i4 from "../../../../../node_modules/@angular/material/progress-spinner/typings/index.ngfactory";
import * as i5 from "@angular/material/progress-spinner";
import * as i6 from "@angular/cdk/platform";
import * as i7 from "@angular/common";
import * as i8 from "@angular/platform-browser/animations";
import * as i9 from "./file-preview-overlay.component";
import * as i10 from "./file-preview-overlay-ref";
import * as i11 from "./file-preview-overlay.tokens";
var styles_FilePreviewOverlayComponent = [i0.styles];
var RenderType_FilePreviewOverlayComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FilePreviewOverlayComponent, data: { "animation": [{ type: 7, name: "fade", definitions: [{ type: 0, name: "fadeOut", styles: { type: 6, styles: { opacity: 0 }, offset: null }, options: undefined }, { type: 0, name: "fadeIn", styles: { type: 6, styles: { opacity: 1 }, offset: null }, options: undefined }, { type: 1, expr: "* => fadeIn", animation: { type: 4, styles: null, timings: "400ms cubic-bezier(0.25, 0.8, 0.25, 1)" }, options: null }], options: {} }, { type: 7, name: "slideContent", definitions: [{ type: 0, name: "void", styles: { type: 6, styles: { transform: "translate3d(0, 25%, 0)", opacity: 0 }, offset: null }, options: undefined }, { type: 0, name: "enter", styles: { type: 6, styles: { transform: "none", opacity: 1 }, offset: null }, options: undefined }, { type: 0, name: "leave", styles: { type: 6, styles: { transform: "translate3d(0, 25%, 0)", opacity: 0 }, offset: null }, options: undefined }, { type: 1, expr: "* => *", animation: { type: 4, styles: null, timings: "400ms cubic-bezier(0.25, 0.8, 0.25, 1)" }, options: null }], options: {} }] } });
export { RenderType_FilePreviewOverlayComponent as RenderType_FilePreviewOverlayComponent };
function View_FilePreviewOverlayComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "overlay__image-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "h2", [["class", "truncate text--md"]], null, null, null, null, null)), (_l()(), i1.ɵted(2, null, ["", ""])), (_l()(), i1.ɵeld(3, 0, null, null, 3, "button", [["class", "bunk-overlay__close-btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["close"])), (_l()(), i1.ɵeld(5, 0, null, null, 1, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"], ["svgIcon", "icon-close"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i2.View_MatIcon_0, i2.RenderType_MatIcon)), i1.ɵdid(6, 9158656, null, 0, i3.MatIcon, [i1.ElementRef, i3.MatIconRegistry, [8, null], [2, i3.MAT_ICON_LOCATION], [2, i1.ErrorHandler]], { svgIcon: [0, "svgIcon"] }, null)], function (_ck, _v) { var currVal_3 = "icon-close"; _ck(_v, 6, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.image.name; _ck(_v, 2, 0, currVal_0); var currVal_1 = i1.ɵnov(_v, 6).inline; var currVal_2 = (((i1.ɵnov(_v, 6).color !== "primary") && (i1.ɵnov(_v, 6).color !== "accent")) && (i1.ɵnov(_v, 6).color !== "warn")); _ck(_v, 5, 0, currVal_1, currVal_2); }); }
function View_FilePreviewOverlayComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "spinner-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "mat-spinner", [["class", "mat-spinner mat-progress-spinner"], ["mode", "indeterminate"], ["role", "progressbar"]], [[2, "_mat-animation-noopable", null], [4, "width", "px"], [4, "height", "px"]], null, null, i4.View_MatSpinner_0, i4.RenderType_MatSpinner)), i1.ɵdid(2, 114688, null, 0, i5.MatSpinner, [i1.ElementRef, i6.Platform, [2, i7.DOCUMENT], [2, i8.ANIMATION_MODULE_TYPE], i5.MAT_PROGRESS_SPINNER_DEFAULT_OPTIONS], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2)._noopAnimations; var currVal_1 = i1.ɵnov(_v, 2).diameter; var currVal_2 = i1.ɵnov(_v, 2).diameter; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }); }
function View_FilePreviewOverlayComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "img", [["class", "overlay-content__image"]], [[24, "@fade", 0], [8, "src", 4]], [[null, "load"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("load" === en)) {
        var pd_0 = (_co.onLoad($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.loading ? "fadeOut" : "fadeIn"); var currVal_1 = _co.image.url; _ck(_v, 0, 0, currVal_0, currVal_1); }); }
export function View_FilePreviewOverlayComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, "div", [["class", "overlay-content"]], [[24, "@slideContent", 0]], [[null, "@slideContent.start"], [null, "@slideContent.done"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("@slideContent.start" === en)) {
        var pd_0 = (_co.onAnimationStart($event) !== false);
        ad = (pd_0 && ad);
    } if (("@slideContent.done" === en)) {
        var pd_1 = (_co.onAnimationDone($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FilePreviewOverlayComponent_1)), i1.ɵdid(2, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FilePreviewOverlayComponent_2)), i1.ɵdid(4, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FilePreviewOverlayComponent_3)), i1.ɵdid(6, 16384, null, 0, i7.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.image.name; _ck(_v, 2, 0, currVal_1); var currVal_2 = _co.loading; _ck(_v, 4, 0, currVal_2); var currVal_3 = _co.image; _ck(_v, 6, 0, currVal_3); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.animationState; _ck(_v, 0, 0, currVal_0); }); }
export function View_FilePreviewOverlayComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "file-preview-overlay", [], null, [["document", "keydown"]], function (_v, en, $event) { var ad = true; if (("document:keydown" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).handleKeydown($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_FilePreviewOverlayComponent_0, RenderType_FilePreviewOverlayComponent)), i1.ɵdid(1, 49152, null, 0, i9.FilePreviewOverlayComponent, [i10.FilePreviewOverlayRef, i11.FILE_PREVIEW_IMAGE], null, null)], null, null); }
var FilePreviewOverlayComponentNgFactory = i1.ɵccf("file-preview-overlay", i9.FilePreviewOverlayComponent, View_FilePreviewOverlayComponent_Host_0, {}, {}, []);
export { FilePreviewOverlayComponentNgFactory as FilePreviewOverlayComponentNgFactory };

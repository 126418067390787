import { EventEmitter, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { RENT_STATUS } from '@app/core-landlord/core-landlord.constants';
import { FormBuilder, Validators } from '@angular/forms';
import * as moment from 'moment';
import { PaymentsService } from '@app/services/payments.service';
import { finalize, flatMap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { PAYMENT_PAID_ON_TYPES, PAYMENT_STATUS } from '@app/shared/shared.constants';
import { environment } from '@env/environment';
import { TABLE_ITEMS_PP } from '@app/_constants/forms.constants';
import { ExportToCsv } from 'export-to-csv';
import { UserService } from '@app/services';
import { MY_FORMATS } from '@app/shared/_services/dates.service';
const ɵ0 = MY_FORMATS;
export class BunkPaymentTableComponent {
    constructor(_formBuilder, _payment, _user, _toastr) {
        this._formBuilder = _formBuilder;
        this._payment = _payment;
        this._user = _user;
        this._toastr = _toastr;
        this.isAdmin = false;
        this.selectedId = new EventEmitter();
        this.pagination = new EventEmitter();
        this.pageSize = TABLE_ITEMS_PP;
        this.displayedAdminColumns = ['month', 'user_reference', 'mark_paid', 'more_options'];
        this.RENT_STATUS = RENT_STATUS;
        this.showSpinner = false;
        this.downloadData = [];
        this.deletePaymentsSpinner = false;
        this.paymentPaidOnTypes = PAYMENT_PAID_ON_TYPES;
        this.paymentStatuses = PAYMENT_STATUS;
        this.disabledButtons = [];
        this.showKey = false;
        this.environment = environment;
        this.client_data = environment.client_data;
        this.recallAlgolia = new EventEmitter(null);
        this.adminFormGroup = this.initAdminForm();
        this.partially_paid_input.setValidators(this.partiallyPaidValidator());
        this.deletePaymentGroup = this._formBuilder.group({ is_deleting_all: false });
    }
    get data() {
        return this.dataSource;
    }
    set data(tableData) {
        this.dataSource = new MatTableDataSource(tableData);
        // this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        // moment dates cant be sorted by mat-table: this is their recommendation to convert it to timestamp to be sorted (still displays as moment date tho :D)
        this.dataSource.sortingDataAccessor = (item, property) => {
            switch (property) {
                case 'momentDueDate': return new Date(item.momentDueDate);
                case 'timestampPaid': return item.timestampPaid.toDate();
                default: return item[property];
            }
        };
    }
    get itemsPerPage() {
        return this.hitsPerPage;
    }
    set itemsPerPage(event) {
        this.hitsPerPage = event;
    }
    get pageNumber() {
        return this.page;
    }
    set pageNumber(event) {
        this.page = event;
    }
    get is_deleting_all() {
        return this.deletePaymentGroup.get('is_deleting_all');
    }
    get due_date() {
        return this.adminFormGroup.get('due_date');
    }
    get amount() {
        return this.adminFormGroup.get('amount');
    }
    get payment_paid_on() {
        return this.adminFormGroup.get('payment_paid_on');
    }
    get payment_paid_string() {
        return this.adminFormGroup.get('payment_paid_string');
    }
    get payment_status() {
        return this.adminFormGroup.get('payment_status');
    }
    get payment_status_string() {
        return this.payment_status.value.replace(/_/g, ' ');
    }
    get is_being_held() {
        return this.adminFormGroup.get('is_being_held');
    }
    get notes() {
        return this.adminFormGroup.get('notes');
    }
    get partially_paid_input() {
        return this.adminFormGroup.get('partially_paid_amount');
    }
    get payment_amount() {
        if (this.payment_status.value === 'partially_paid') {
            return this.partially_paid_input.value || 0;
        }
        return this.selected_payment_amount;
    }
    partiallyPaidValidator() {
        return (control) => {
            if (this.payment_status.value !== 'partially_paid') {
                return null;
            }
            const value = control.value;
            if (value === null || value === undefined) {
                return { missing_value: true };
            }
            if (value <= 0) {
                return { not_positive: true };
            }
            if (value >= this.selected_payment_amount) {
                return { more_than_payment: true };
            }
            return null;
        };
    }
    get partially_paid_error_message() {
        const { errors } = this.partially_paid_input;
        if (errors.missing_value) {
            return 'Please enter the amount that has been paid';
        }
        else if (errors.not_positive) {
            return 'Amount paid must be positive';
        }
        else if (errors.more_than_payment) {
            return 'Partially paid paymemts must be less than the total payment';
        }
        return '';
    }
    updateValue(element) {
        this.selected_payment_amount = element.amount;
        this.setFormGroup();
    }
    emitId(id) {
        this.selectedId.emit(id);
    }
    markAsPaid(payment_id) {
        if (this.payment_status.value === 'paid') {
            this.payment_paid_on.setValidators([Validators.required]);
            this.payment_paid_on.updateValueAndValidity();
        }
        if (this.adminFormGroup.valid) {
            this.disabledButtons.push(payment_id);
            const index = this.disabledButtons.indexOf(payment_id);
            const date = this.payment_paid_on.value ? this.payment_paid_on.value.toDate() : null;
            this.dref.close();
            this._payment.updatePaymentStatus(payment_id, date, this.payment_status.value, this.notes.value, this.partially_paid_input.value).subscribe(() => {
                this.payment_paid_on.setValidators(null);
                this.payment_paid_on.updateValueAndValidity();
                this._toastr.success(`Payment update successful`);
                this.setFormGroup();
                // if (this.paymentStatus.value !== 'paid') { this.disabledButtons.splice(index, 1); }
            }, () => {
                this.disabledButtons.splice(index, 1);
                this.payment_paid_on.setValidators(null);
                this.payment_paid_on.updateValueAndValidity();
                this.setFormGroup();
                this._toastr.error(`Payment update failed`);
            });
        }
    }
    setFormGroup() {
        this.adminFormGroup.reset();
        this.payment_status.setValue('paid');
        this.payment_status.updateValueAndValidity();
    }
    setDatePaidOnType(type) {
        this.payment_paid_string.setValue(type);
        if (type !== 'other') {
            const isToday = (type === 'today');
            this.setDatePaid(isToday);
        }
    }
    setDatePaid(isToday) {
        (isToday)
            ? this.payment_paid_on.setValue(moment())
            : this.payment_paid_on.setValue(moment().subtract(1, 'days'));
    }
    initAdminForm() {
        return this._formBuilder.group({
            amount: null,
            due_date: null,
            payment_paid_on: null,
            payment_paid_string: '',
            payment_status: 'paid',
            partially_paid_amount: 0,
            notes: '',
            is_being_held: false
        });
    }
    setFormValues(due_date, amount) {
        this.due_date.setValue(moment(due_date));
        this.amount.setValue(amount);
    }
    updatePaymentDetails(payment_id) {
        this.dref.close();
        this._payment.adminUpdatePayment(payment_id, moment(this.due_date.value), parseFloat(this.amount.value), this.notes.value, this.is_being_held.value).subscribe(() => {
            this.payment_paid_on.setValidators(null);
            this.payment_paid_on.updateValueAndValidity();
            this._toastr.success(`Payment update successful`);
            this.setFormGroup();
            // if (this.paymentStatus.value !== 'paid') { this.disabledButtons.splice(index, 1); }
        }, () => {
            this._toastr.error(`Payment update failed`);
        });
    }
    ngOnInit() {
        this.displayAllColumns = (this.isAdmin || this._user.userDb.is_paid_off_platform)
            ? [...this.displayedColumns, ...this.displayedAdminColumns]
            : this.displayedColumns;
    }
    updatePage(event) {
        this.pagination.emit({ hitsPerPage: event.pageSize, page: event.pageIndex });
    }
    openFirestoreDoc(type, id) {
        const url = `${environment.firebaseConfig.firestoreUrl}${type}~2F${id}`;
        window.open(url, '_blank');
    }
    getAllData() {
        this.showSpinner = true;
        this.downloadData = [...this.dataSource.data, ...this.downloadData];
        if (this.downloadData.length < this.totalHits) {
            this.pagination.emit({ hitsPerPage: this.hitsPerPage, page: this.page + 1 });
            return setTimeout(() => {
                return this.getAllData();
            }, 500);
        }
        const csvExporter = new ExportToCsv({
            filename: `${this._user.userDb.profile_data.full_name} Payments`,
            fieldSeparator: ',',
            quoteStrings: '"',
            decimalSeparator: '.',
            showLabels: true,
            useTextFile: false,
            useBom: true,
            useKeysAsHeaders: true,
        });
        csvExporter.generateCsv(this.generateCSVData(this.downloadData));
        this.showSpinner = false;
        this.downloadData = [];
        this.pagination.emit({ hitsPerPage: this.hitsPerPage, page: 0 });
        this.dref.close();
    }
    generateCSVData(data) {
        return data.map((hit) => {
            return {
                ['Due Date']: hit.momentDueDate,
                Address: hit.propertyAddress,
                ['Tenant name']: (hit.tenantFullName) ? hit.tenantFullName : hit.tenantName,
                Type: hit.paymentType,
                Amount: hit.amount,
                ['Date paid by tenant']: hit.timestampPaid || '',
                Status: hit.status.viewValue,
                ['Amount outstanding']: hit.amountOutstanding || '',
                ['Payment Id']: hit.id
            };
        });
    }
    openXero(payment) {
        if (payment.team_id) {
            this._user.getTeamById(payment.team_id).valueChanges().pipe(flatMap((team) => {
                console.log('the team', team);
                if (payment.custom_fields.xero_invoice_id) {
                    const url = `https://go.xero.com/organisationlogin/default.aspx?shortcode=!${team.xero_short_code}&redirecturl=/AccountsReceivable/View.aspx?InvoiceID=${payment.custom_fields.xero_invoice_id}`;
                    return window.open(url, '_blank');
                }
            })).subscribe();
        }
    }
    deletePayments(payment) {
        console.log(payment, 'hello payment');
        const item = {
            id: payment.objectID,
            is_deleting_all: this.is_deleting_all.value,
            payment_type: payment.paymentType,
            sender_uid: payment.uid,
            tenancy_id: payment.tenancyId ? payment.tenancyId : null
        };
        this.deletePaymentsSpinner = true;
        console.log(item, 'item to send to CF');
        this._payment.adminDeletePayment(item).pipe(finalize(() => {
            this.deletePaymentsSpinner = false;
        })).subscribe((res) => {
            this.recallAlgolia.emit({ removedId: res.ids });
            this.is_deleting_all.setValidators(null);
            this.is_deleting_all.updateValueAndValidity();
            this._toastr.success(`Payment deleted successful`);
            this.dref.close();
        }, () => {
            this.deletePaymentsSpinner = false;
            this._toastr.error(`Payment deletion failed`);
        });
    }
}
export { ɵ0 };

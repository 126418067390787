import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UtilsModule } from '@app/core/utils/utils.module';

import { BunkFormFieldComponent } from './form-field-wrapper.component';

@NgModule({
  imports: [
    CommonModule,
    UtilsModule
  ],
  exports: [BunkFormFieldComponent],
  declarations: [BunkFormFieldComponent],
  providers: [],
})
export class BunkFormFieldModule { }

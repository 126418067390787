import { Injectable, Injector, ComponentRef, TemplateRef } from '@angular/core';
import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal';

import { TemplatePreviewOverlayComponent } from './template-preview-overlay.component';
import { FilePreviewOverlayComponent } from './file-preview-overlay.component';
import { PdfPreviewOverlayComponent } from './pdf-preview-overlay.component';

import { FilePreviewOverlayRef } from './file-preview-overlay-ref';
import { FILE_PREVIEW_IMAGE, FILE_PREVIEW_TEMPLATE, PDF_PREVIEW_DOC } from './file-preview-overlay.tokens';

export interface Image {
  name: string;
  url: string;
}
export interface Pdf {
  name: string;
  url: string;
}

interface FilePreviewDialogConfig {
  panelClass?: string;
  hasBackdrop?: boolean;
  backdropClass?: string;
  image?: Image;
  template?: TemplateRef<any>;
  pdf?: Pdf;
}

const DEFAULT_CONFIG: FilePreviewDialogConfig = {
  hasBackdrop: true,
  backdropClass: 'dark-backdrop',
  panelClass: 'tm-file-preview-dialog-panel',
  image: null
};

@Injectable()
export class FilePreviewOverlayService {

  constructor(
    private injector: Injector,
    private overlay: Overlay) { }

  open(config: FilePreviewDialogConfig = {}) {
    // Override default configuration
    const dialogConfig = { ...DEFAULT_CONFIG, ...config };

    // Returns an OverlayRef which is a PortalHost
    const overlayRef = this.createOverlay(dialogConfig);

    // Instantiate remote control
    const dialogRef = new FilePreviewOverlayRef(overlayRef);

    const overlayComponent = this.attachDialogContainer(overlayRef, dialogConfig, dialogRef);

    dialogRef.componentInstance = overlayComponent;
    overlayRef.backdropClick().subscribe(_ => dialogRef.close());

    return dialogRef;
  }

  private createOverlay(config: FilePreviewDialogConfig) {
    const overlayConfig = this.getOverlayConfig(config);
    return this.overlay.create(overlayConfig);
  }

  private attachDialogContainer(overlayRef: OverlayRef, config: FilePreviewDialogConfig, dialogRef: FilePreviewOverlayRef) {
    const injector = this.createInjector(config, dialogRef);

    if (config.template) {
      const containerPortal = new ComponentPortal(TemplatePreviewOverlayComponent, null, injector);
      const containerRef: ComponentRef<TemplatePreviewOverlayComponent> = overlayRef.attach(containerPortal);
      return containerRef.instance;
    } else if (config.image) {
      const containerPortal = new ComponentPortal(FilePreviewOverlayComponent, null, injector);
      const containerRef: ComponentRef<FilePreviewOverlayComponent> = overlayRef.attach(containerPortal);
      return containerRef.instance;
    } else {
      const containerPortal = new ComponentPortal(PdfPreviewOverlayComponent, null, injector);
      const containerRef: ComponentRef<PdfPreviewOverlayComponent> = overlayRef.attach(containerPortal);
      return containerRef.instance;
    }
  }

  private createInjector(config: FilePreviewDialogConfig, dialogRef: FilePreviewOverlayRef): PortalInjector {
    const injectionTokens = new WeakMap();

    injectionTokens.set(FilePreviewOverlayRef, dialogRef);
    injectionTokens.set(FILE_PREVIEW_IMAGE, config.image);
    injectionTokens.set(FILE_PREVIEW_TEMPLATE, config.template);
    injectionTokens.set(PDF_PREVIEW_DOC, config.pdf);

    return new PortalInjector(this.injector, injectionTokens);
  }

  private getOverlayConfig(config: FilePreviewDialogConfig): OverlayConfig {
    const positionStrategy = this.overlay.position()
      .global()
      .centerHorizontally()
      .centerVertically();

    const overlayConfig = new OverlayConfig({
      hasBackdrop: config.hasBackdrop,
      backdropClass: config.backdropClass,
      panelClass: config.panelClass,
      scrollStrategy: this.overlay.scrollStrategies.block(),
      positionStrategy
    });

    return overlayConfig;
  }
}

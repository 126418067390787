import { Component, OnDestroy, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { BunkPassportService } from '@app/services';
import { BunkPassportModel } from '@app/_models/bunk_passport.model';


import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { MY_FORMATS } from '@app/shared/_services/dates.service';

import { Tenant, Profile } from '@env/routing';
import { ActivatedRoute, Router } from '@angular/router';

import { tap, takeUntil, flatMap, map, catchError } from 'rxjs/operators';
import { Observable, Subject, of } from 'rxjs';
import { TenantVerifyEventEmiterService } from '../profile-tenant.service';
import { TenantAccountStepComponent } from './tenant-account-step.component';
import { AuthService } from '@app/core/services';


@Component({
  selector: 'app-tenant-account-verify-search-preferences',
  template: `
  <ng-container *ngIf="(passport$ | async )"></ng-container>
      <div>
        <h2 class="text--lg">Search details</h2>
        <p>Please fill in some information about yourself and search preferences.</p>
      </div>

      <form class="cc" [formGroup]="formGroup" (ngSubmit)="nextStep(formGroup.value)">

        <form-search-details formControlName="search_details"></form-search-details>

        <div class="margin-bottom--xl">
          <button *ngIf="showPrev" (click)="goBack()" data-test="backButton" class="btn__seethrough btn__form-prev">Back</button>
          <button class="btn__generic btn__form-submit btn__form-submit--align-right" type="submit">
            <!--[disabled]="formGroup.invalid || submittingData">-->
            <span *ngIf="!submittingData && !submittingDataSuccess">Save<span *ngIf="!standAlone"> and continue</span></span>
            <processing-spinner *ngIf="submittingData || submittingDataSuccess" color="#FFFFFF" [complete]="submittingDataSuccess"></processing-spinner>
          </button>
        </div>

      </form>
  `,
  styleUrls: ['./tenant-account-step.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
  ],
})
export class TenantAccountVerifySearchPreferencesComponent extends TenantAccountStepComponent implements OnInit, OnDestroy {
  @Input() showPrev: boolean;
  @Input() standAlone = false;
  public formGroup: FormGroup;
  public queryParams: string;
  public routes = { Tenant };
  public passport$: Observable<BunkPassportModel>;
  public formControls;
  public reference$: Observable<any>;
  public references$: Observable<any>; 
  public referenceId$: Observable<any>; 

  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    _verifyEvent: TenantVerifyEventEmiterService,
    private _formBuilder: FormBuilder,
    private _router: Router,
    private _route: ActivatedRoute,
    private _passport: BunkPassportService,
    public _auth: AuthService
  ) {
    super(_verifyEvent);
    this.formGroup = this.initFormGroup();
    this.queryParams = _route.snapshot.queryParams.tenancy_offer_id;
    
    this.passport$ = this._passport.getUsersBunkPassportById().valueChanges()
      .pipe(
        tap((passport: BunkPassportModel) => {
          if (passport.search_preferences) {
            this.preloadFormGroup(passport.search_preferences);
          }

        }),
      );

      this.references$ = this._passport.getBunkReferencesById('university').valueChanges();
      this.reference$ = this.references$
      .pipe(
        flatMap((references: any) => {
          if (references.length > 0) {          
            return this._passport.getBunkReferenceById(references[0].bunk_passport_reference_id).valueChanges().pipe(
                tap((reference: any) => {
                  if (reference.bunk_passport_reference_id) {
                    this.preloadUniFormGroup(reference.reference_data);
                    this._router.navigate( ['.'], { queryParams: { uniReferenceId: reference.bunk_passport_reference_id}, relativeTo: this._route, replaceUrl: true } );
                  }
                })
              );
          } else {
            return of([]);
          }
        }
        )
      );


  }

  initFormGroup(): FormGroup {
    return this._formBuilder.group({
      search_details: [null],
    });
  }

  nextStep(form: any) {

    this.submittingData = true;
    this.formGroup.disable();  
    this._passport.updateUserReferenceData(form)
    .pipe(
      takeUntil(this.destroy$),
      catchError(err => of([]))
    )
    .subscribe(
      res => {
        this.stepComplete();
      },
      err => {
        console.log('HTTP Error', err);
        this.stepError();
      },
      () => {
        this.formGroup.enable();
        console.log('HTTP request completed.');
      }
    );
  }

  autocompleteUniversity(event: any) {
    this.formGroup.patchValue({ university_name: event.name });
  }

  preloadFormGroup(form: any): void {
    const item = {
      is_student: form.is_student,
      searching_with: form.searching_with,
      number_children: form.number_children ? form.number_children : null,
      has_pets: form.has_pets,
      is_smoker: form.is_smoker,
      is_renting: form.is_renting,
      currently_renting_from: form.currently_renting_from ? form.currently_renting_from : null,
      expected_move_in_date: form.expected_move_in_date ? form.expected_move_in_date.toDate() : null
    };
    this.formGroup.get('search_details').patchValue(item);
  }

  preloadUniFormGroup(value: any): void {
    const item = {
      university_name: value.university_name,
      course_end_year: value.course_end_year,
      course_name: value.course_name,
    };
    this.formGroup.patchValue(item);
  }

  goBack() {
    this._verifyEvent.navigateStep('prev');
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  ngOnInit() {

  }
}

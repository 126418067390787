import { Component, OnInit, forwardRef, Optional, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR, NG_VALIDATORS, Validator, FormGroupDirective, FormGroup, FormControl, Validators } from '@angular/forms';
import { AbstractValueAccessor } from '@app/shared/_components/searches/instant-search/instant-search.utils';
import { FormsService } from '@app/services/form.service';
import { RELATIONSHIP_TYPES, FORM_GUARANTOR_DETAILS, FORM_GUARANTOR_DETAILS_CAMPUS_KEY } from './application-forms.constants';
import { COUNTRY_IS03166 } from '@app/_constants/countries.constants';
import { TRANSLATIONS } from '@app/_constants/translations.constants';
import { environment } from '@env/environment';

@Component({
  selector: 'form-guarantor-details',
  template: `
  <form [formGroup]="guarantorForm" autocomplete="none">

    <bunk-form-field *ngIf="getFormControl('has_uk_guarantor') && showUKGuarantor">
    <label bunk-label>Do you have a UK guarantor?</label>
    <div bunk-input>
      <mat-radio-group formControlName="has_uk_guarantor" class="flex flex-row margin-bottom" (change)="setGuarantorValidators(getFormControl('has_uk_guarantor').value)">
        <mat-radio-button [value]="true" class="bunk-outlined-radio mr_5" [ngClass]="{'bunk-outlined-radio--selected': getFormControl('has_uk_guarantor').value === true}" >Yes</mat-radio-button>
        <mat-radio-button [value]="false" class="bunk-outlined-radio" [ngClass]="{'bunk-outlined-radio--selected': getFormControl('has_uk_guarantor').value === false}">No</mat-radio-button>
      </mat-radio-group>
      <mat-error class="bunk-mat-error-font-match"*ngIf="getFormControl('has_uk_guarantor').getError('required') && submitAttempted">Please select</mat-error>
    </div>
    </bunk-form-field>


      <div class="block-info block-info--advice" *ngIf="getFormControl('has_uk_guarantor') && getFormControl('has_uk_guarantor').value === false">
        <h3 class="overline">Note</h3>
        <p>Without a UK guarantor, the {{landlord}} will typically ask you to pay all the rent upfront.</p>
      </div>

       <ng-container *ngIf="getFormControl('has_uk_guarantor') && getFormControl('has_uk_guarantor').value || !showUKGuarantor">

      <bunk-form-field *ngIf="getFormControl('referee_name')">
        <label bunk-label>What is your guarantors name?</label>
        <mat-form-field bunk-input appearance="outline">
          <input matInput formControlName="referee_name" type="text" placeholder=" E.g Georgina Ryder">
          <mat-error>Please enter your guarantor's name</mat-error>
        </mat-form-field>
      </bunk-form-field>

      <bunk-form-field *ngIf="getFormControl('referee_email')">
         <label bunk-label> What is your guarantors email?</label>
         <mat-form-field bunk-input appearance="outline">
           <input matInput formControlName="referee_email" type="text" placeholder="E.g georgina19@gmail.com">
           <mat-error>Please enter your guarantors email</mat-error>
         </mat-form-field>
       </bunk-form-field>

      <bunk-form-field *ngIf="getFormControl('referee_phone')">
        <label bunk-label >What is your guarantors number?</label>
        <div bunk-input>
        <form-input-phone
          bunk-input
          formControlName="referee_phone"
          showLabel="true"
          [submitAttempted]="submitAttempted"
        ></form-input-phone>
        </div>
      </bunk-form-field>

      <bunk-form-field *ngIf="getFormControl('relationship')">
        <label bunk-label >What is their relationship to you?</label>
        <mat-form-field bunk-input appearance="outline">
          <mat-select formControlName="relationship" placeholder="Select">
            <mat-option *ngFor="let type of relationshipTypes" [value]="type.value">
              {{type.viewValue}}
            </mat-option>
          </mat-select>
          <mat-error>Please enter the relationship to you</mat-error>
        </mat-form-field>
      </bunk-form-field>

         <bunk-form-field *ngIf="getFormControl('referee_dob')">
           <label bunk-label>What is your guarantors of birth?</label>
           <div bunk-input>
             <date-selector
               yearStart="1901"
               formControlName="referee_dob"
               [submitAttempted]="submitAttempted">
             </date-selector>
           </div>
         </bunk-form-field>

      <bunk-form-field *ngIf="getFormControl('referee_country_of_residence')">
        <label bunk-label >What is their country of residence?</label>
        <mat-form-field bunk-input appearance="outline">
          <mat-select formControlName="referee_country_of_residence" placeholder="Select">
            <mat-option *ngFor="let country of countryOfResidence" [value]="country.value">
              {{country.country}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </bunk-form-field>

      <bunk-form-field *ngIf="getFormControl('has_declared_uk_homeowner')">
      <label bunk-label>Are they a UK homeowner?</label>
      <div bunk-input>
        <mat-radio-group formControlName="has_declared_uk_homeowner" class="flex flex-row margin-bottom">
          <mat-radio-button [value]="true" class="bunk-outlined-radio mr_5" [ngClass]="{'bunk-outlined-radio--selected': getFormControl('has_declared_uk_homeowner').value === true}" >Yes</mat-radio-button>
          <mat-radio-button [value]="false" class="bunk-outlined-radio" [ngClass]="{'bunk-outlined-radio--selected': getFormControl('has_declared_uk_homeowner').value === false}">No</mat-radio-button>
        </mat-radio-group>
        <mat-error class="bunk-mat-error-font-match"*ngIf="getFormControl('has_declared_uk_homeowner').getError('required') && submitAttempted">Please select</mat-error>

      </div>
      </bunk-form-field>
      </ng-container>
  </form>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormGuarantorDetailsComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => FormGuarantorDetailsComponent),
      multi: true
    }
  ]
})
export class FormGuarantorDetailsComponent extends AbstractValueAccessor implements OnInit, Validator {
  @Input() requiredFields = FORM_GUARANTOR_DETAILS;
  @Input() showUKGuarantor = true;
  guarantorForm: FormGroup;
  public relationshipTypes = RELATIONSHIP_TYPES;
  public countryOfResidence = COUNTRY_IS03166;
  public landlord = TRANSLATIONS.landlord;

  public isCampusKey = environment.client_data.company_name === 'CampusKey'
  private _submitAttempted = false;

  @Input('submitAttempted') set submitAttempted(boo: boolean) {
    this._submitAttempted = boo;
    if (boo) {
      this.dirtyFormGroup();
    }
  }

  dirtyFormGroup() {
    if (this.guarantorForm ) {
    const controls = this.guarantorForm.controls;
    for (const control in controls) {
      if (controls.hasOwnProperty(control)) {
        this.guarantorForm.controls[control].markAsTouched();
      }
    }
  }
  }

  get submitAttempted(): boolean {
    return this._submitAttempted;
  }
  constructor(
    @Optional() public _reactiveForm: FormGroupDirective,
    private _forms: FormsService
  ) {
    super();

  }

  getFormControl(name: string): FormControl {
    return this.guarantorForm.get(name) as FormControl;
  }

  ngOnInit(): void {
    if (this.isCampusKey) { 
      this.requiredFields = FORM_GUARANTOR_DETAILS_CAMPUS_KEY;
    }
    this.guarantorForm = this._forms.toFormGroup(this.requiredFields);
    this.guarantorForm.addControl('bunk_passport_reference_id', new FormControl(''))
    this._reactiveForm.ngSubmit.subscribe((data: Event) => {
      this.submitAttempted = true;
      const controls = this.guarantorForm.controls;
      for (const control in controls) {
        if (controls.hasOwnProperty(control)) {
          this.guarantorForm.controls[control].markAsTouched();
        }
      }
    });
   }


  public onTouched: () => void = () => { };

  writeValue(val: any): void {
    super.writeValue(val);
    if (val) {
      this.guarantorForm.patchValue(val, { emitEvent: false });
      if (val.has_uk_guarantor !== null) {
        this.setGuarantorValidators(val.has_uk_guarantor);
      }
    }
    if(this.isCampusKey){
      !this.guarantorForm.get('referee_country_of_residence').value && this.guarantorForm.get('referee_country_of_residence').setValue('ZA');
    }

  }

  setGuarantorValidators(selectValue: any) {
    if (selectValue === false) {
      for (const field of this.requiredFields ) {
        if ( field !== 'has_uk_guarantor') {
          this.guarantorForm.removeControl(field);
          this.guarantorForm.updateValueAndValidity();
        }
      }
    } else {
      for (const field of this.requiredFields ) {
        this.guarantorForm.addControl(field, new FormControl(null, Validators.required));
        this.guarantorForm.get(field).updateValueAndValidity();

      }

    }

  }

  registerOnChange(fn: any): void {
    this.guarantorForm.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.guarantorForm.disable() : this.guarantorForm.enable();
  }

  validate() {
    return this.guarantorForm.valid ? null : { invalidForm: { valid: false, message: 'FormGuarantorDetailsComponent > formGroup fields are invalid' } };
  }

}

import {Directive, HostListener, Input, Output, EventEmitter} from '@angular/core';


@Directive({
  selector: '[copyToClipboard]'
})
export class CopyToClipboardDirective  {
  @Input() text_copy: string;
  @Output() isCopied: EventEmitter<string> = new EventEmitter<string>();


  @HostListener('click', ['$event.target'])
  onClick() {
  this.copyToClipboard();
  }

  constructor() {
  }

  copyToClipboard() {

    // We need to create a dummy textarea with the text to be copied in the DOM
    const textArea = document.createElement("textarea");

    // Hide the textarea from actually showing
    textArea.style.position = 'fixed';
    textArea.style.top = '-999px';
    textArea.style.left = '-999px';
    textArea.style.width = '2em';
    textArea.style.height = '2em';
    textArea.style.padding = '0';
    textArea.style.border = 'none';
    textArea.style.outline = 'none';
    textArea.style.boxShadow = 'none';
    textArea.style.background = 'transparent';

    // Set the texarea's content to our value defined in our [text-copy] attribute
    textArea.value = this.text_copy;
    document.body.appendChild(textArea);

    // This will select the textarea
    textArea.select();

    document.execCommand('copy');
    this.isCopied.emit(this.text_copy);

    document.body.removeChild(textArea);
  }

}


    <form [formGroup]="searchForm" autocomplete="none">
      <bunk-form-field *ngIf="getFormControl('is_student')">
        <label bunk-label>Are you a student?</label>
        <div bunk-input>
          <mat-radio-group formControlName="is_student" class="flex flex-row margin-bottom" (change)="isUniValidation(getFormControl('is_student').value)">
            <mat-radio-button [value]="true" class="bunk-outlined-radio mr_5" [ngClass]="{'bunk-outlined-radio--selected': getFormControl('is_student').value === true}">Yes</mat-radio-button>
            <mat-radio-button [value]="false" class="bunk-outlined-radio" [ngClass]="{'bunk-outlined-radio--selected': getFormControl('is_student').value === false}">No</mat-radio-button>
          </mat-radio-group>
          <mat-error class="bunk-mat-error-font-match" *ngIf="getFormControl('is_student').getError('required') && submitAttempted">Please select</mat-error>
        </div>
      </bunk-form-field>


      <bunk-form-field *ngIf="getFormControl('searching_with')">
        <label bunk-label>Who are you searching with?</label>
        <mat-form-field bunk-input appearance="outline">
          <mat-select formControlName="searching_with" placeholder="Select">
            <mat-option *ngFor="let search of searching" [value]="search.value">
              {{search.text}}
            </mat-option>
          </mat-select>
          <mat-error>Please select who you are searching with</mat-error>
        </mat-form-field>
      </bunk-form-field>
      <bunk-form-field *ngIf="getFormControl('searching_with') && getFormControl('searching_with').value === 'family' && getFormControl('number_children')">
        <label bunk-label>Do you have children, if so how many?</label>
        <mat-form-field bunk-input appearance="outline">
          <input matInput formControlName="number_children" type="number" min="0" placeholder="E.g 2">
        </mat-form-field>
      </bunk-form-field>

      <bunk-form-field *ngIf="getFormControl('has_pets')">
        <label bunk-label>Do you have any pets?</label>
        <div bunk-input>
          <mat-radio-group formControlName="has_pets" class="flex flex-row margin-bottom">
            <mat-radio-button [value]="true" class="bunk-outlined-radio mr_5" [ngClass]="{'bunk-outlined-radio--selected': getFormControl('has_pets').value === true}">Yes</mat-radio-button>
            <mat-radio-button [value]="false" class="bunk-outlined-radio" [ngClass]="{'bunk-outlined-radio--selected': getFormControl('has_pets').value === false}">No</mat-radio-button>
          </mat-radio-group>
          <mat-error class="bunk-mat-error-font-match" *ngIf="getFormControl('has_pets').getError('required') && submitAttempted">Please select</mat-error>
        </div>
      </bunk-form-field>
      <bunk-form-field *ngIf="getFormControl('is_smoker')">
        <label bunk-label>Are you a smoker?</label>
    <div bunk-input>
      <mat-radio-group formControlName="is_smoker" class="flex flex-row margin-bottom">
        <mat-radio-button [value]="true" class="bunk-outlined-radio mr_5" [ngClass]="{'bunk-outlined-radio--selected': getFormControl('is_smoker').value === true}" >Yes</mat-radio-button>
        <mat-radio-button [value]="false" class="bunk-outlined-radio" [ngClass]="{'bunk-outlined-radio--selected': getFormControl('is_smoker').value === false}">No</mat-radio-button>
      </mat-radio-group>
      <mat-error class="bunk-mat-error-font-match"*ngIf="getFormControl('is_smoker').getError('required') && submitAttempted">Please select</mat-error>
    </div>
    </bunk-form-field>
    <bunk-form-field *ngIf="getFormControl('expected_move_in_date')">
    <label bunk-label>When are you looking to move into a property?</label>
    <mat-form-field bunk-input appearance="outline">
        <input matInput
              formControlName="expected_move_in_date"
              [matDatepicker]="moveInDate"
              (click)="moveInDate.open()"
              readonly
              placeholder="Choose a date"
              [min]="minDate"
        >
        <mat-datepicker-toggle matSuffix [for]="moveInDate"></mat-datepicker-toggle>
        <mat-datepicker #moveInDate></mat-datepicker>
        <mat-error>Please enter a move in date</mat-error>
    </mat-form-field>
    </bunk-form-field>
    <bunk-form-field *ngIf="getFormControl('is_renting')">
    <label bunk-label>Are you currently renting?</label>
    <div bunk-input>
      <mat-radio-group formControlName="is_renting" class="flex flex-row margin-bottom"  (change)="isRentingValidation(getFormControl('is_renting').value)">
        <mat-radio-button [value]="true" class="bunk-outlined-radio mr_5" [ngClass]="{'bunk-outlined-radio--selected': getFormControl('is_renting').value === true}" >Yes</mat-radio-button>
        <mat-radio-button [value]="false" class="bunk-outlined-radio" [ngClass]="{'bunk-outlined-radio--selected': getFormControl('is_renting').value === false}">No</mat-radio-button>
      </mat-radio-group>
      <mat-error class="bunk-mat-error-font-match"*ngIf="getFormControl('is_renting').getError('required') && submitAttempted">Please select</mat-error>
    </div>
    </bunk-form-field>

    <bunk-form-field *ngIf="getFormControl('currently_renting_from') && searchForm.get('is_renting').value === true">
    <label bunk-label> Who are you currently renting with?</label>
    <mat-form-field bunk-input appearance="outline">
          <mat-select formControlName="currently_renting_from" placeholder="Select">
            <mat-option *ngFor="let current of renting" [value]="current.value">
              {{current.text}}
            </mat-option>
          </mat-select>
    </mat-form-field>
  </bunk-form-field>



  <bunk-form-field *ngIf="getFormControl('institution_location')">
        <label bunk-label>Which tertiary institution will you be studying at? </label>
          <mat-form-field bunk-input appearance="outline">
            <input formControlName="institution_location" matInput type="text" name="institution_location" placeholder="E.g University of Cape Town">
            <mat-error>Please enter the name of your institution</mat-error>
          </mat-form-field>
  </bunk-form-field>

  <bunk-form-field *ngIf="getFormControl('study_year')">
    <label bunk-label >What study year are you next year?</label>
    <mat-form-field bunk-input appearance="outline">
      <mat-select formControlName="study_year" placeholder="Select">
        <mat-option *ngFor="let year of study_years" [value]="year.value">
          {{year.viewValue}}
        </mat-option>
      </mat-select>
    <mat-error>Please select the year</mat-error>
    </mat-form-field>
  </bunk-form-field>

  <bunk-form-field *ngIf="getFormControl('is_student_at_uni')">
    <label bunk-label >Are you a current {{client_data.company_name}} student?</label>
    <div bunk-input>
      <mat-radio-group formControlName="is_student_at_uni" class="flex flex-row margin-bottom">
        <mat-radio-button [value]="true" class="bunk-outlined-radio mr_5" [ngClass]="{'bunk-outlined-radio--selected': getFormControl('is_student_at_uni').value === true}" >Yes</mat-radio-button>
        <mat-radio-button [value]="false" class="bunk-outlined-radio" [ngClass]="{'bunk-outlined-radio--selected': getFormControl('is_student_at_uni').value === false}">No</mat-radio-button>
      </mat-radio-group>
      <mat-error class="bunk-mat-error-font-match"*ngIf="getFormControl('is_student_at_uni').getError('required') && submitAttempted">Please select</mat-error>
    </div>
    </bunk-form-field>

    <bunk-form-field *ngIf="getFormControl('years_at_uni') && searchForm.get('is_student_at_uni').value === true">
    <label bunk-label > How long have you been living with us?</label>
    <mat-form-field bunk-input appearance="outline">
      <mat-select formControlName="years_at_uni" placeholder="Select">
        <mat-option *ngFor="let year of years_at_campus" [value]="year.value">
          {{year.viewValue}}
        </mat-option>
      </mat-select>
    <mat-error>Please select the years</mat-error>
    </mat-form-field>
  </bunk-form-field>



    <bunk-form-field *ngIf="getFormControl('has_friends_at_uni')">
    <label bunk-label >Will any of your friends be living at {{client_data.company_name}}?</label>
    <div bunk-input>
      <mat-radio-group formControlName="has_friends_at_uni" class="flex flex-row margin-bottom">
        <mat-radio-button [value]="true" class="bunk-outlined-radio mr_5" [ngClass]="{'bunk-outlined-radio--selected': getFormControl('has_friends_at_uni').value === true}" >Yes</mat-radio-button>
        <mat-radio-button [value]="false" class="bunk-outlined-radio" [ngClass]="{'bunk-outlined-radio--selected': getFormControl('has_friends_at_uni').value === false}">No</mat-radio-button>
      </mat-radio-group>
      <mat-error class="bunk-mat-error-font-match"*ngIf="getFormControl('has_friends_at_uni').getError('required') && submitAttempted">Please select</mat-error>
    </div>
    </bunk-form-field>


  </form>
  
import {Component, Input, forwardRef, OnInit, Optional, Output, EventEmitter} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NG_VALIDATORS, AbstractControl, FormBuilder, FormGroup, Validators, FormGroupDirective, Validator } from '@angular/forms';
import { map } from 'rxjs/operators';

export abstract class AbstractValueAccessor implements ControlValueAccessor {
  _value: any = '';
  get value(): Date { return this._value; }

  set value(v: Date) {
    if (v !== this._value) {
      this._value = v;
      this.onChange(v);
      this.onTouched();
    }
  }

  writeValue(value: any) {
    this._value = value;
    this.onChange(value);
  }

  onChange = (val: any) => {};
  onTouched = () => {};

  registerOnChange(fn: (_: any) => void): void { this.onChange = fn; }
  registerOnTouched(fn: () => void): void { this.onTouched = fn; }
}


@Component({
  selector: 'time-picker',
  template: `
    <label *ngIf="inputLabel" for="hours">{{inputLabel}}</label>
    <form [formGroup]="formGroup">

      <mat-form-field appearance="outline" class="input__hours">
        <mat-label>Hour (24)</mat-label>
        <mat-select formControlName="hours" name="hours" (selectionChange)="emitTime()">
          <mat-option *ngFor="let hour of hoursArr" [value]="hour">
            {{ hour }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="submitAttempted && formGroup.get('hours').errors?.required">Select an hour</mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline" class="input__mins">
      <mat-label>Minutes</mat-label>
        <mat-select formControlName="mins" (selectionChange)="emitTime()">
          <mat-option *ngFor="let min of [0,5,10,15,20,25,30,35,40,45,50,55]" [value]="min">
            {{ min.toString().padStart(2,'0') }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="submitAttempted && formGroup.get('mins').errors?.required">Select minutes</mat-error>
      </mat-form-field>
    </form>
  `,
  styleUrls: ['./time-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TimePickerComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => TimePickerComponent),
      multi: true
    }
  ]
})
export class TimePickerComponent extends AbstractValueAccessor implements OnInit, Validator {
  @Output() valueChange: EventEmitter<any> = new EventEmitter<any>();
  @Input() inputLabel: string; // @optional: so that there can be a label above the whole(all 3 inputs) if you need one
  @Input() minHours = 0;
  @Input() maxHours = 24;
  @Input('submitAttempted') set submitAttempted(boo: boolean) {
    this._submitAttempted = boo;
    if (boo) {
      this.dirtyFormGroup();
    }
  }
  get submitAttempted(): boolean {
    return this._submitAttempted;
  }
  private _submitAttempted = false;
  private pickerTime: Date = new Date();
  public formGroup: FormGroup;
  public hoursArr: number[];

  constructor(
    @Optional() private reactiveForm: FormGroupDirective,
    private _formBuilder: FormBuilder,
  ) {
    super();
  }

  ngOnInit() {
    this.formGroup = this.initFormGroup();
    this.hoursArr = this.getArrayOfNumbers(this.minHours, this.maxHours);
    // Force validate the form when parent form submits, loop all controls
    this.reactiveForm.ngSubmit.subscribe((data: Event) => {
      this.submitAttempted = true;
      if (this.reactiveForm.submitted) {
        this.dirtyFormGroup();
      }
    });
  }

  dirtyFormGroup() {
    const controls = this.formGroup.controls;
    for (const control in controls) {
      if (controls.hasOwnProperty(control)) {
        console.log(this.formGroup.controls[control].errors)
        this.formGroup.controls[control].markAsTouched();
      }
    }
  }

  initFormGroup(): FormGroup {
    const seFormGroup = this._formBuilder.group({
      hours: [null, [Validators.required]],
      mins: [null, [Validators.required]]
    });
    return new FormGroup(seFormGroup.controls);
  }

  public onTouched: () => void = () => { };

  writeValue(val: any): void {
    super.writeValue(val);
    if (val) {
      const valueToDate = new Date(val);
      const hours = valueToDate.getHours();
      const mins = valueToDate.getMinutes();
      this.formGroup.patchValue({ hours, mins }, { emitEvent: false });
    }
  }
  registerOnChange(fn: any): void {
    this.formGroup.valueChanges.pipe(
      map((form: any) => {
        this.pickerTime.setHours(form.hours);
        this.pickerTime.setMinutes(form.mins);
        this.pickerTime.setSeconds(0);
        return this.pickerTime;
      })
    ).subscribe(fn);
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  getArrayOfNumbers = (min: number, max: number): Array<number> => Array.apply(null, {length: (max + 1) - min}).map((_, idx) => idx + min);

  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.formGroup.disable() : this.formGroup.enable();
  }

  validate(control: AbstractControl) {
    return this.formGroup.valid ? null : { invalidForm: { valid: false, message: 'TimePickerComponent > formGroup fields are invalid' } };
  }

  emitTime() {
    const {hours, mins} = this.formGroup.getRawValue();
    if (hours && mins) {
      this.valueChange.emit(true);
    }
  }
}

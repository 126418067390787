
    <tenant-main-template *ngIf="(references$ | async) as references">

    <screen-detection (resized)="isDesktop = $event.isDesktop;"></screen-detection>
        <div class="tenant-account__background">
          <div [ngClass]="{'main__wrapper': isDesktop}">
            <h1 *ngIf="isDesktop" class="p-all_5"> Profile</h1>
            <header class="bunk-pp__header bunk-pp__header--verified" [ngClass]="{'bunk-pp__header--rounded': isDesktop}">
              <h1 *ngIf="!isDesktop" class="p-all_9 mb_0">Profile</h1>
            </header>
          </div>
        </div>

        <div class="tenant-account__background verify-full__passport">
          <div [ngClass]="{'main__wrapper': isDesktop}">
            <div class="users__info users__info--verified">
              <div class="profile-verified">

                <div class="margin-bottom margin-top"><span class="bunk-pp__logo-left">RENTAL</span><span class="bunk-pp__logo-right">PASSPORT</span></div>

                <div class="profile-info">

                <profile-avatar size="lg" justified="center" [profileImgUrl]="_user.userDb?.profile_data.profile_image_url" [profileName]="_user.userDb?.profile_data.full_name" class="profile__image"></profile-avatar>

                  <div class="profile-text" *ngIf="(passport$ | async )as passport">
                    <h2 class="text--lg">{{passport.personal_details.first_name + ' ' + passport.personal_details.last_name }}</h2>
                    <small class="text--subtle">Member since {{passport.date_created.toDate()| date: 'MMMM y'}}</small>
                  </div>

                </div>

              </div>
            </div>
          </div>

          <div class="main__wrapper" *ngIf="(passport$ | async )as passport">

            <div class="profile-details">

              <div class="main__grid">

                <div class="card__list-item card__list-item--bp main__grid-col main__grid-col--half sg__grid-col cc ">
                  <bunk-overlay [template]="info" (overlayRef)="overProfile = $event">
                    <h3 class="card-title">Personal details
                      <button class="bunk-overlay__close-btn">
                        <mat-icon class="edit" svgIcon="edit-pencil"></mat-icon>
                      </button>
                    </h3>
                    <ng-template #info>
                      <div class="bunk-overlay__external-content bunk-overlay__external-content--scroll">
                        <div class="main__wrapper main__wrapper--narrow ">
                          <div class="bunk-overlay__close-bar">
                            <button class="bunk-overlay__close-btn" (click)="overProfile.close()">
                              <mat-icon svgIcon="icon-close"></mat-icon>
                            </button>
                          </div>

                          <tenant-account-verify-info [standAlone]="true"></tenant-account-verify-info>
                        </div>
                      </div>
                    </ng-template>
                  </bunk-overlay>

                  <span>{{passport.personal_details.first_name}}
                    {{passport.personal_details.last_name}}</span>
                  <span *ngIf="passport.address_details">{{passport.personal_details.address_details.first_line_address}}
                    {{passport.personal_details.address_details.post_code}}</span>
                  <span *ngIf="passport.personal_details.phone"><span>+ {{passport.personal_details.country_code}} </span>{{passport.personal_details.phone}}</span>
                  <span *ngIf="passport.personal_details.dob">{{passport.personal_details.dob.toDate() | date: 'd MMM, y'}}</span>
                </div>

                <div class="card__list-item card__list-item--bp main__grid-col main__grid-col--half sg__grid-col cc">

                  <bunk-overlay [template]="details" (overlayRef)="overProfile = $event">
                    <h3 class="card-title">Search preferences
                      <button class="bunk-overlay__close-btn">
                        <mat-icon class="edit" svgIcon="edit-pencil"></mat-icon>
                      </button>
                    </h3>
                    <ng-template #details>
                      <div class="bunk-overlay__external-content bunk-overlay__external-content--scroll">
                        <div class="main__wrapper main__wrapper--narrow ">
                          <div class="bunk-overlay__close-bar">
                            <button class="bunk-overlay__close-btn" (click)="overProfile.close()">
                              <mat-icon svgIcon="icon-close"></mat-icon>
                            </button>
                          </div>

                          <app-tenant-account-verify-search-preferences [standAlone]="true"></app-tenant-account-verify-search-preferences>
                        </div>
                      </div>
                    </ng-template>
                  </bunk-overlay>

                  <ng-container *ngIf="passport.search_preferences">
                    <span *ngIf="passport.search_preferences.is_student !== null">{{(passport.search_preferences.is_student ? 'Student': 'Professional')}}</span>

                    <span *ngIf="passport.search_preferences.searching_with">{{passport.search_preferences.searching_with}}</span>

                    <span *ngIf="passport.search_preferences.number_children">{{passport.search_preferences.number_children}}
                      Children</span>
                    <span *ngIf="passport.search_preferences.is_smoker !== null">{{(passport.search_preferences.is_smoker ? 'Smoker' : 'Non Smoker')}}</span>
                    <span *ngIf="passport.search_preferences.has_pets !== null">{{(passport.search_preferences.has_pets ? 'Pets' : 'No Pets')}}</span>
                  </ng-container>

                </div>
              </div>


          <ng-container *ngIf="references.guarantors && references.guarantors.length; else noGuarantor">
          <ng-container *ngFor="let guarantor of references.guarantors" >
            <ng-container *ngTemplateOutlet="referenceTemplate; context: {reference: guarantor}"></ng-container>
          </ng-container>
          </ng-container>

          <ng-template #noGuarantor>
            <ng-container *ngTemplateOutlet="noReferenceTemplate; context: {reference: 'Guarantor'}"></ng-container>
          </ng-template>

          <ng-container *ngIf="references.employers && references.employers.length; else noEmployers">
          <ng-container *ngFor="let employer of references.employers" >
            <ng-container *ngTemplateOutlet="referenceTemplate; context: {reference: employer}"></ng-container>
          </ng-container>
          </ng-container>

          <ng-template #noEmployers>
            <ng-container *ngTemplateOutlet="noReferenceTemplate; context: {reference: 'Employer'}"></ng-container>
          </ng-template>

          <ng-container *ngIf="references.landlords && references.landlords.length; else noLandlord">
          <ng-container *ngFor="let landlord of references.landlords" >
            <ng-container *ngTemplateOutlet="referenceTemplate; context: {reference: landlord}"></ng-container>
          </ng-container>
          </ng-container>

          <ng-template #noLandlord>
            <ng-container *ngTemplateOutlet="noReferenceTemplate; context: {reference: 'Previous tenancy'}"></ng-container>
          </ng-template>

          <ng-container *ngIf="references.unis && references.unis.length; else noUnis">
          <ng-container *ngFor="let uni of references.unis" >
            <ng-container *ngTemplateOutlet="referenceTemplate; context: {reference: uni}"></ng-container>
          </ng-container>
          </ng-container>

          <ng-template #noUnis>
            <ng-container *ngTemplateOutlet="noReferenceTemplate; context: {reference: 'University'}"></ng-container>
          </ng-template>





          <ng-template #noReferenceTemplate let-reference="reference">
            <div class="card__list-item card__list-item--bp cc">
              <bunk-overlay [template]="noRef" (overlayRef)="overProfile = $event">
                <h3 class="card-title">{{reference}}
                  <button class="bunk-overlay__close-btn">
                    <mat-icon class="edit" svgIcon="edit-pencil"></mat-icon>
                  </button>
                </h3>
                <ng-template #noRef>
                  <div class="bunk-overlay__external-content bunk-overlay__external-content--scroll">
                    <div class="main__wrapper main__wrapper--narrow ">
                      <div class="bunk-overlay__close-bar">
                        <button class="bunk-overlay__close-btn" (click)="overProfile.close()">
                          <mat-icon svgIcon="icon-close"></mat-icon>
                        </button>
                      </div>

                    <app-tenant-account-verify-guarantor-details *ngIf="reference === 'Guarantor'" [standAlone]="true"></app-tenant-account-verify-guarantor-details>

                    <tenant-account-verify-previous-tenancy *ngIf="reference === 'Previous tenancy'" [standAlone]="true" ></tenant-account-verify-previous-tenancy>

                    <app-tenant-account-verify-employment *ngIf="reference === 'Employer'" [standAlone]="true"></app-tenant-account-verify-employment>

                    <tenant-account-university-details *ngIf="reference === 'University'" [standAlone]="true" ></tenant-account-university-details>
                  </div>
                  </div>
                </ng-template>
              </bunk-overlay>
              <i>Incomplete - you can add this information by click the edit icon.</i>

            </div>
          </ng-template>




            </div>
          </div>

        </div>
      </tenant-main-template>


      <ng-template #referenceTemplate let-reference="reference">
          <div class="card__list-item card__list-item--bp cc">
            <bunk-overlay [template]="referenceRef" (overlayRef)="overProfile = $event">

              <div class="card-title"><h3>{{reference.title}}</h3>
                  <small *ngIf="reference.status  === 'pending_signature'" class="reference-chip reference-chip--pending">pending</small>
                  <small *ngIf="reference.status  === 'complete'" class="reference-chip reference-chip--complete">verified</small>
                  <small *ngIf="reference.status  === 'declined'" class="reference-chip reference-chip--declined">declined</small>

                <button class="bunk-overlay__close-btn" *ngIf="reference.status  !== 'complete'">
                  <mat-icon class="edit" svgIcon="edit-pencil"></mat-icon>
                </button>
              </div>

              <ng-template #referenceRef>
                <div class="bunk-overlay__external-content bunk-overlay__external-content--scroll">
                  <div class="main__wrapper main__wrapper--narrow ">
                    <div class="bunk-overlay__close-bar">
                      <button class="bunk-overlay__close-btn" (click)="overProfile.close()">
                        <mat-icon svgIcon="icon-close"></mat-icon>
                      </button>
                    </div>


                    <app-tenant-account-verify-guarantor-details *ngIf="reference.type === 'guarantor'" [standAlone]="true" [reference]="reference.patchValue"></app-tenant-account-verify-guarantor-details>

                    <tenant-account-verify-previous-tenancy *ngIf="reference.type === 'previous_tenancy'" [standAlone]="true" [reference]="reference.patchValue"></tenant-account-verify-previous-tenancy>

                    <app-tenant-account-verify-employment *ngIf="reference.type === 'employer'" [standAlone]="true" [reference]="reference.patchValue"></app-tenant-account-verify-employment>

                    <tenant-account-university-details *ngIf="reference.type === 'university'" [standAlone]="true" [reference]="reference.patchValue"></tenant-account-university-details>

                  </div>
                </div>
              </ng-template>
            </bunk-overlay>

            <ng-container *ngIf="reference.type === 'guarantor'">
              <span *ngIf="reference.name">{{reference.name}}</span>
              <span *ngIf="reference.address">{{reference.address}}</span>
              <span *ngIf="reference.email">{{reference.email}}</span>
              <span *ngIf="reference.phone">{{reference.phone}}</span>
              <span *ngIf="reference.relationship">{{reference.relationship}}</span>
            </ng-container>

            <ng-container *ngIf="reference.type === 'previous_tenancy'">
              <span *ngIf="reference.address">{{reference.address}}</span>
              <span *ngIf="reference.name">{{reference.name}}</span>
              <span *ngIf="reference.rent">{{reference.rent}}</span>
              <span *ngIf="reference.tenancyLength">{{reference.tenancyLength}}</span>
              <span *ngIf="reference.endDate">{{reference.endDate}}</span>
            </ng-container>

            <ng-container *ngIf="reference.type === 'employer'">

              <ng-container *ngIf="reference.employmentStatus === 'Unemployed'; else employed">
                <span>{{reference.employmentStatus}}</span>
              </ng-container>

              <ng-template #employed>
                <span *ngIf="reference.jobTitle" [ngClass]="{'margin-top--md' : reference.status !== 'incomplete'}">{{reference.jobTitle}}</span>
                <span *ngIf="reference.companyName">{{reference.companyName}}</span>
                <span *ngIf="reference.salary">{{reference.salary}}</span>
                <span *ngIf="reference.startDate">{{reference.employmentStatus}} since
                  {{reference.startDate}}</span>
                <span *ngIf="reference.employerName">Referee name: {{reference.employerName}}</span>

              </ng-template>
            </ng-container>


            <ng-container *ngIf="reference.type === 'university'">

              <span *ngIf="reference.courseName">{{reference.courseName}}</span>
              <span *ngIf="reference.uniName">{{reference.uniName}}</span>
              <span *ngIf="reference.endYear">Course end: {{reference.endYear}}</span>
            </ng-container>

          </div>
      </ng-template>
  
import { map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { Component, Input, OnInit } from '@angular/core';
import { PropertyService } from '@app/services';

@Component({
  selector: 'property-address',
  template: `
         <div class="property__row" *ngIf="(address$ | async) as address;">
            <span class="property__title" >
              {{address}}
            </span>
        </div>
  `,
  styleUrls: ['./property-address.component.scss']
})
export class PropertyAddressComponent implements OnInit {
  @Input() application: any;
  address$: Observable<any>
  constructor(public _property: PropertyService,) {
  }

  ngOnInit() {
    this.address$ = this.application ? this.getPropertyToDisplay(this.application) : of('')

  }
  getPropertyToDisplay(tenancy_offer) {
    return this._property.getPropertyById(tenancy_offer.property_id? tenancy_offer.property_id : tenancy_offer.parent_property_id).get().pipe(
      map((ref: any) => ref.data()),
      map((data: any) => this._property.createPropertyAddress(data, tenancy_offer.bedroom_id)))
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {SortableListComponent}from './sortable-list.component'
import { SortablejsModule } from 'ngx-sortablejs';
@NgModule({
  imports: [
    CommonModule,
    SortablejsModule,
  ],
  declarations: [SortableListComponent],
  exports:[SortableListComponent]
})
export class SortableListModule { }

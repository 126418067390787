import { OnInit } from '@angular/core';
import { AbstractValueAccessor } from '@app/shared/_forms/abstract-value-accessor/abstract-value-accessor';
import { Validator, FormGroup, FormGroupDirective, FormBuilder, AbstractControl, Validators } from '@angular/forms';
import { UK_DATE_FORMAT } from '@app/_constants/date.constants';
import { TRANSLATIONS } from '@app/_constants/translations.constants';
const ɵ0 = UK_DATE_FORMAT;
export class BookViewingSharedFormComponent extends AbstractValueAccessor {
    constructor(_reactiveForm, _formBuilder) {
        super();
        this._reactiveForm = _reactiveForm;
        this._formBuilder = _formBuilder;
        this.showLabel = true;
        this.showDescription = true;
        this.submitAttempted = false;
        this.minDate = new Date();
        this.landlord = TRANSLATIONS.landlord;
        this.onTouched = () => { };
    }
    ngOnInit() {
        this.formGroup = this.initFormGroup();
        // Force validate the form when parent form submits, loop all controls
        this._reactiveForm.ngSubmit.subscribe((data) => {
            this.submitAttempted = true;
            const controls = this.formGroup.controls;
            for (const control in controls) {
                if (controls.hasOwnProperty(control)) {
                    this.formGroup.controls[control].markAsTouched();
                }
            }
        });
    }
    initFormGroup() {
        const formGroup = this._formBuilder.group({
            viewing_date: [null, Validators.required],
            viewing_time: [null, Validators.required],
            viewing_request_message: '',
        });
        return new FormGroup(formGroup.controls);
    }
    writeValue(val) {
        super.writeValue(val);
        if (val) {
            this.formGroup.patchValue(val, { emitEvent: false });
        }
    }
    registerOnChange(fn) {
        this.formGroup.valueChanges.subscribe(fn);
    }
    registerOnTouched(fn) {
        this.onTouched = fn;
    }
    setDisabledState(isDisabled) {
        isDisabled ? this.formGroup.disable() : this.formGroup.enable();
    }
    validate(control) {
        return this.formGroup.valid ? null : { invalidForm: { valid: false, message: 'BookViewingFormComponent > formGroup fields are invalid' } };
    }
}
export { ɵ0 };

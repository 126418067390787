/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./instant-algolia-search.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../_directives/click-outside.directive";
import * as i4 from "../../../../../../node_modules/@angular/material/checkbox/typings/index.ngfactory";
import * as i5 from "@angular/forms";
import * as i6 from "@angular/material/checkbox";
import * as i7 from "@angular/cdk/a11y";
import * as i8 from "@angular/platform-browser/animations";
import * as i9 from "../../cards/profile-cards/profile-avatar.component.ngfactory";
import * as i10 from "../../cards/profile-cards/profile-avatar.component";
import * as i11 from "../../../_pipes/truncate.pipe";
import * as i12 from "./instant-algolia-search.component";
import * as i13 from "../../../../core/services/auth.service";
var styles_InstantAlgoliaSearchComponent = [i0.styles];
var RenderType_InstantAlgoliaSearchComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_InstantAlgoliaSearchComponent, data: {} });
export { RenderType_InstantAlgoliaSearchComponent as RenderType_InstantAlgoliaSearchComponent };
function View_InstantAlgoliaSearchComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "i-search__input-icon-close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clearSearch() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["alt", "Close list"], ["src", "/assets/svg/baseline-close-24px.svg"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.hideResults = true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, null); }
function View_InstantAlgoliaSearchComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_InstantAlgoliaSearchComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_InstantAlgoliaSearchComponent_6)), i1.ɵdid(2, 540672, null, 0, i2.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutletContext: [0, "ngTemplateOutletContext"], ngTemplateOutlet: [1, "ngTemplateOutlet"] }, null), i1.ɵpod(3, { hit: 0 }), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = _ck(_v, 3, 0, _v.parent.context.$implicit); var currVal_1 = i1.ɵnov(_v.parent.parent.parent.parent, 7); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_InstantAlgoliaSearchComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_InstantAlgoliaSearchComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_InstantAlgoliaSearchComponent_8)), i1.ɵdid(2, 540672, null, 0, i2.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutletContext: [0, "ngTemplateOutletContext"], ngTemplateOutlet: [1, "ngTemplateOutlet"] }, null), i1.ɵpod(3, { hit: 0 }), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = _ck(_v, 3, 0, _v.parent.context.$implicit); var currVal_1 = i1.ɵnov(_v.parent.parent.parent.parent, 8); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_InstantAlgoliaSearchComponent_10(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_InstantAlgoliaSearchComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_InstantAlgoliaSearchComponent_10)), i1.ɵdid(2, 540672, null, 0, i2.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutletContext: [0, "ngTemplateOutletContext"], ngTemplateOutlet: [1, "ngTemplateOutlet"] }, null), i1.ɵpod(3, { hit: 0 }), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = _ck(_v, 3, 0, _v.parent.context.$implicit); var currVal_1 = i1.ɵnov(_v.parent.parent.parent.parent, 6); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_InstantAlgoliaSearchComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 6, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InstantAlgoliaSearchComponent_5)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InstantAlgoliaSearchComponent_7)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InstantAlgoliaSearchComponent_9)), i1.ɵdid(6, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = ((_co.indexName === "USERS-full_name") && (_v.context.$implicit.role === "tenant")); _ck(_v, 2, 0, currVal_0); var currVal_1 = (_co.indexName === "universities"); _ck(_v, 4, 0, currVal_1); var currVal_2 = (_co.indexName === "properties"); _ck(_v, 6, 0, currVal_2); }, null); }
function View_InstantAlgoliaSearchComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, [[2, 0], ["searchHits", 1]], null, 3, "div", [["class", "i-search__hits-container"]], null, [[null, "clickOutside"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("clickOutside" === en)) {
        var pd_0 = (_co.clickHideSearchResults($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(1, 212992, null, 0, i3.ClickOutsideDirective, [i1.ElementRef], null, { clickOutside: "clickOutside" }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InstantAlgoliaSearchComponent_4)), i1.ɵdid(3, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); var currVal_0 = _co.resultsList; _ck(_v, 3, 0, currVal_0); }, null); }
function View_InstantAlgoliaSearchComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "i-search"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["alt", "Search..."], ["class", "i-search__input-icon"], ["src", "/assets/svg/baseline-search-24px.svg"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InstantAlgoliaSearchComponent_2)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(4, 0, [[1, 0], ["searchInput", 1]], null, 3, "input", [["autocomplete", "off"], ["class", "i-search__input"]], [[8, "placeholder", 0], [8, "disabled", 0]], [[null, "input"], [null, "click"], [null, "keydown.esc"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (_co.setSearchQuery($event) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.setSearchQuery($event) !== false);
        ad = (pd_1 && ad);
    } if (("keydown.esc" === en)) {
        var pd_2 = (_co.hideSearchResults() !== false);
        ad = (pd_2 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(6, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(7, { "i-search__input--active": 0 }), (_l()(), i1.ɵeld(8, 0, null, null, 0, "small", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InstantAlgoliaSearchComponent_3)), i1.ɵdid(10, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (i1.ɵnov(_v, 4).value.length > 1); _ck(_v, 3, 0, currVal_0); var currVal_3 = "i-search__input"; var currVal_4 = _ck(_v, 7, 0, _co.showHits); _ck(_v, 6, 0, currVal_3, currVal_4); var currVal_5 = _co.showHits; _ck(_v, 10, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = i1.ɵinlineInterpolate(1, "", (!_co.disabled ? _co.message : _co.disabledMessage), ""); var currVal_2 = _co.disabled; _ck(_v, 4, 0, currVal_1, currVal_2); }); }
function View_InstantAlgoliaSearchComponent_13(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""])), i1.ɵpad(2, 2), i1.ɵppd(3, 2)], null, function (_ck, _v) { var currVal_0 = i1.ɵunv(_v, 1, 0, _ck(_v, 3, 0, i1.ɵnov(_v.parent.parent.parent, 0), _v.parent.parent.context.hit.address.second_line_address, _ck(_v, 2, 0, 25, "..."))); _ck(_v, 1, 0, currVal_0); }); }
function View_InstantAlgoliaSearchComponent_14(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "mat-checkbox", [["class", "prompt mat-checkbox"]], [[8, "id", 0], [1, "tabindex", 0], [2, "mat-checkbox-indeterminate", null], [2, "mat-checkbox-checked", null], [2, "mat-checkbox-disabled", null], [2, "mat-checkbox-label-before", null], [2, "_mat-animation-noopable", null]], null, null, i4.View_MatCheckbox_0, i4.RenderType_MatCheckbox)), i1.ɵprd(5120, null, i5.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i6.MatCheckbox]), i1.ɵdid(2, 8568832, [[3, 4], ["checkbox", 4]], 0, i6.MatCheckbox, [i1.ElementRef, i1.ChangeDetectorRef, i7.FocusMonitor, i1.NgZone, [8, null], [2, i6.MAT_CHECKBOX_CLICK_ACTION], [2, i8.ANIMATION_MODULE_TYPE]], { id: [0, "id"] }, null)], function (_ck, _v) { var currVal_7 = _v.parent.parent.context.hit.property_id; _ck(_v, 2, 0, currVal_7); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).id; var currVal_1 = null; var currVal_2 = i1.ɵnov(_v, 2).indeterminate; var currVal_3 = i1.ɵnov(_v, 2).checked; var currVal_4 = i1.ɵnov(_v, 2).disabled; var currVal_5 = (i1.ɵnov(_v, 2).labelPosition == "before"); var currVal_6 = (i1.ɵnov(_v, 2)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
function View_InstantAlgoliaSearchComponent_12(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "label", [["type", "label"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.selectAlgoliaProperty(_v.parent.context.hit.property_id) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "div", [["aria-role", "button"], ["class", "i-search__hit-row"], ["tabindex", "0"]], [[1, "aria-label", 0]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 3, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", "", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InstantAlgoliaSearchComponent_13)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InstantAlgoliaSearchComponent_14)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_3 = _v.parent.context.hit.address.second_line_address; _ck(_v, 5, 0, currVal_3); var currVal_4 = _co.multiselect; _ck(_v, 7, 0, currVal_4); }, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "Add ", _v.parent.context.hit.name, ""); _ck(_v, 1, 0, currVal_0); var currVal_1 = _v.parent.context.hit.address.first_line_address; var currVal_2 = (_v.parent.context.hit.address.second_line_address ? "," : ""); _ck(_v, 3, 0, currVal_1, currVal_2); }); }
function View_InstantAlgoliaSearchComponent_11(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_InstantAlgoliaSearchComponent_12)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.storedHits.includes(_v.context.hit.property_id); _ck(_v, 1, 0, currVal_0); }, null); }
function View_InstantAlgoliaSearchComponent_15(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["aria-role", "button"], ["class", "i-search__hit-row"], ["tabindex", "0"]], [[1, "aria-label", 0]], [[null, "click"], [null, "keydown.space"], [null, "keydown.enter"], [null, "keydown.esc"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.getSelectedId(_v.context.hit) !== false);
        ad = (pd_0 && ad);
    } if (("keydown.space" === en)) {
        var pd_1 = (_co.getSelectedId(_v.context.hit) !== false);
        ad = (pd_1 && ad);
    } if (("keydown.enter" === en)) {
        var pd_2 = (_co.getSelectedId(_v.context.hit) !== false);
        ad = (pd_2 && ad);
    } if (("keydown.esc" === en)) {
        var pd_3 = (_co.hideSearchResults() !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "profile-avatar", [["size", "sm"]], null, null, null, i9.View_ProfileAvatarComponent_0, i9.RenderType_ProfileAvatarComponent)), i1.ɵdid(2, 114688, null, 0, i10.ProfileAvatarComponent, [], { size: [0, "size"], profileName: [1, "profileName"], profileImgUrl: [2, "profileImgUrl"] }, null), (_l()(), i1.ɵeld(3, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(4, null, ["", ""]))], function (_ck, _v) { var currVal_1 = "sm"; var currVal_2 = _v.context.hit.profile_data.full_name; var currVal_3 = _v.context.hit.profile_data.profile_image_url; _ck(_v, 2, 0, currVal_1, currVal_2, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "Add ", _v.context.hit.profile_data.full_name, ""); _ck(_v, 0, 0, currVal_0); var currVal_4 = _v.context.hit.profile_data.full_name; _ck(_v, 4, 0, currVal_4); }); }
function View_InstantAlgoliaSearchComponent_16(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["aria-role", "button"], ["class", "i-search__hit-row"], ["tabindex", "0"]], [[1, "aria-label", 0]], [[null, "click"], [null, "keydown.space"], [null, "keydown.enter"], [null, "keydown.esc"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.value = _v.context.hit) !== false);
        ad = (pd_0 && ad);
    } if (("keydown.space" === en)) {
        var pd_1 = ((_co.value = _v.context.hit) !== false);
        ad = (pd_1 && ad);
    } if (("keydown.enter" === en)) {
        var pd_2 = ((_co.value = _v.context.hit) !== false);
        ad = (pd_2 && ad);
    } if (("keydown.esc" === en)) {
        var pd_3 = (_co.hideSearchResults() !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["class", "i-search__hit-image"], ["src", "/assets/img/icons/bunk-default-avatar.svg"]], [[8, "alt", 0]], null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "p", [["class", "truncate"]], null, null, null, null, null)), (_l()(), i1.ɵted(3, null, ["", ""])), (_l()(), i1.ɵeld(4, 0, null, null, 1, "small", [["class", "prompt"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Add"]))], null, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "Add ", _v.context.hit.name, ""); _ck(_v, 0, 0, currVal_0); var currVal_1 = _v.context.hit.name; _ck(_v, 1, 0, currVal_1); var currVal_2 = _v.context.hit.name; _ck(_v, 3, 0, currVal_2); }); }
export function View_InstantAlgoliaSearchComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i11.TruncatePipe, []), i1.ɵqud(671088640, 1, { searchInput: 0 }), i1.ɵqud(671088640, 2, { searchHits: 0 }), i1.ɵqud(671088640, 3, { myCheckboxes: 1 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InstantAlgoliaSearchComponent_1)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, [["algoliaProperties", 2]], null, 0, null, View_InstantAlgoliaSearchComponent_11)), (_l()(), i1.ɵand(0, [["algoliaUsersHit", 2]], null, 0, null, View_InstantAlgoliaSearchComponent_15)), (_l()(), i1.ɵand(0, [["algoliaUniversities", 2]], null, 0, null, View_InstantAlgoliaSearchComponent_16))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.resultsList; _ck(_v, 5, 0, currVal_0); }, null); }
export function View_InstantAlgoliaSearchComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "instant-algolia-search", [], null, null, null, View_InstantAlgoliaSearchComponent_0, RenderType_InstantAlgoliaSearchComponent)), i1.ɵdid(1, 114688, null, 0, i12.InstantAlgoliaSearchComponent, [i1.ChangeDetectorRef, i13.AuthService], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var InstantAlgoliaSearchComponentNgFactory = i1.ɵccf("instant-algolia-search", i12.InstantAlgoliaSearchComponent, View_InstantAlgoliaSearchComponent_Host_0, { env: "env", indexName: "indexName", disabled: "disabled", disabledMessage: "disabledMessage", message: "message", ignoreList: "ignoreList", hideResults: "hideResults", showImmediately: "showImmediately", multiselect: "multiselect", storedIds: "storedIds", availableHits: "availableHits" }, { selectedId: "selectedId", valueChange: "valueChange", storedIdsChange: "storedIdsChange" }, []);
export { InstantAlgoliaSearchComponentNgFactory as InstantAlgoliaSearchComponentNgFactory };

import { Component, OnInit, ViewChild } from '@angular/core';
import { MaintenanceService, PropertyService, UserService } from '@app/services';
import { AuthService } from '@app/core/services';
import { Observable } from 'rxjs/Observable';
import { AngularFirestore } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';
import { MatPaginator, MatSort } from '@angular/material';
import { Landlord } from '@env/routing';
import { Router } from '@angular/router';

@Component({
  selector: 'bunk-maintenance-table',
  template: `

    <ng-container *ngIf="(maintenances$ | async) as maintenances; else loadSkeleton">
      <screen-detection (resized)="isDesktop = $event.isDesktop"></screen-detection>

      <div class="widget__card table-overflow">
        <table class="widget__summary-table">
              <tr>
                <th>
                  <span class="text--md">Maintenance</span>
                </th>
                <th>
                  <span>Property</span>
                </th>
                <th>
                  <span>Priority</span>
                </th>
                <th >

                  <div class=" flex flex-row justify-between">
                  <span>Next step</span>
                  <span (click)="goToMaintenanceSummary()" class="btn__hyperlink btn__hyperlink--blue">View all</span>
                  </div>
                </th>

              </tr>

              <tr class="cursor-pointer"
                  *ngFor="let maintenance of maintenances | paginate: { itemsPerPage: pagination.items, currentPage: pagination.index + 1 }; trackBy: trackByFn"
                  bunk-maintenance-row [maintenanceData]="maintenance"
                  (click)="goToMaintenance(maintenance.request_id)"
              ></tr>

            </table>
    </div>

      <mat-paginator [length]="maintenances.length"
                     [pageSize]="pagination.items"
                     (page)="updatePage($event)"
      ></mat-paginator>

    </ng-container>

    <ng-template #loadSkeleton>
        <skeleton-loading></skeleton-loading>
    </ng-template>

  `,
  styleUrls: ['./bunk-maintenance-table.component.scss']
})

export class BunkMaintenanceTableComponent implements OnInit {
  public maintenances$: Observable<any>;
  public notes$: Observable<any>;
  isDesktop;

  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginate: MatPaginator;
  public pagination = { items: 5, index: 0 };

  constructor(private _user: UserService,
              private _auth: AuthService,
              private afs: AngularFirestore,
              private _maintenance: MaintenanceService,
              private _property: PropertyService,
              private _router: Router) {}

  ngOnInit() {

    this.maintenances$ = this._maintenance.getAllMaintenanceByLandlordUid().pipe(
      map((maintenances: any) => {
        console.log(maintenances, 'maintenances');
        const notCompleted = maintenances.filter((main: any) => main.status !== 'completed');
        notCompleted.map((maintenance: any) => {
           return {
             ...maintenance,
             property_address: maintenance.property_id && maintenance.bedroom_id
               ? this._property.getPropertyAddressWithId(maintenance.property_id, maintenance.bedroom_id)
               : maintenance.property_id ? this._property.getPropertyAddressWithId(maintenance.property_id) : null
           };
         });
        return maintenances;
      })
    );
  }

  updatePage(event: any) {
    this.pagination = { items: event.pageSize, index: event.pageIndex };
  }


  trackByFn(index, item) {
    return index; // or item.id
  }

  goToMaintenance(maintenance_id: string) {
    return this._router.navigate(['/', Landlord.base, Landlord.manage.base, Landlord.manage.maintenance, maintenance_id]);
  }

  goToMaintenanceSummary() {
    return this._router.navigate(['/', Landlord.base, Landlord.manage.base, Landlord.manage.maintenance]);

  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { PropertyGeneralModel, PropertyModel } from '@app/core-landlord/property/_models/property.model';

import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { FirebaseApp } from '@angular/fire';
import { firestore } from 'firebase/app';
import { UserService } from '@app/services/user.service';
import { AuthService } from '@app/core/services/auth.service';

import { environment } from '@env/environment';

import { flatMap, map, mergeMap, switchMap } from 'rxjs/operators';
import { combineLatest, from as fromPromise, from, Observable, of, throwError } from 'rxjs';
import { PROPERTY_DOCS } from '@app/_constants/property_docs.constants';
import { ISimplePropertyModel } from '../../../../bunk-models/src';

const LIST_PROPERTY_STEPS = ['address', 'rooms', 'listing_info', 'photos', 'credit_info', 'checkout'];

export class IPropertyAddress {
  constructor(
    public first_line_address: string,
    public second_line_address: string,
    public third_line_address: string,
    public city: string,
    public post_code: string,
    public outward_postcode: string,
    public county: string,
    public country: string,
    public lat: any,
    public lng: any,
    public thoroughfare: string,
    public dependant_locality: string,
    public country_iso?: string) {
  }
}

export class IPropertyOwnerAddress {
  constructor(
    public first_line_address: string,
    public second_line_address: string,
    public post_code: string,
    public county: string
  ) {
  }
}

@Injectable()
export class PropertyService {
  public propertiesCollection: AngularFirestoreCollection<any>;
  public propertyEditsCollection: AngularFirestoreCollection<any>;
  public propertyOwnersCollection: AngularFirestoreCollection<any>;
  public propertyPortfolioCollection: AngularFirestoreCollection<any>;
  public propertyMetadataCollection: AngularFirestoreCollection<any>;
  public propertyListingsCollection: AngularFirestoreCollection<any>;

  constructor(
    private readonly afs: AngularFirestore,
    private _auth: AuthService,
    private fb: FirebaseApp,
    private _http: HttpClient,
    private _user: UserService
  ) {
    this.propertiesCollection = afs.collection<any>('properties');
    this.propertyEditsCollection = afs.collection<any>('property_edits');
    this.propertyOwnersCollection = afs.collection<any>('property_owners');
    this.propertyPortfolioCollection = afs.collection<any>('property_portfolios');
    this.propertyMetadataCollection = afs.collection<any>('property_metadata');
    this.propertyListingsCollection = afs.collection<any>('property_listings');
  }

  private headers = {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*'
  };

  getCollection(is_editing: boolean) {
    return (is_editing) ? this.propertyEditsCollection : this.propertiesCollection;
  }
  // Init Property, write property on DB and reference it to the user
  initCreationProperty(hmo: boolean): Observable<{ property_id: string }> {
    const propertyId = this.afs.createId();
    const date = new Date();
    const item: PropertyGeneralModel = {
      property_id: propertyId,                     // Persist a document id
      property_title: '',
      landlord_uid: this._auth.currentUserId,
      date_modified: date,
      date_created: date,
      date_listed: null,
      is_listed: false,                    // property listed (true: property public, false: property private)
      has_completed_information: false,
      has_current_tenancy: false,
      is_marketing_listing: false,
      pending_approval: false,
      is_hmo: hmo,
      listed_as_hmo: null,
      is_deleted: false,
      team_id: null,
      manager_uid: (this._user.userDb && this._user.userDb.property_manager_data) ? this._user.userDb.property_manager_data.company_uid : null
    };

    return fromPromise(this.propertiesCollection.doc(propertyId).set(item))
      .pipe(
        flatMap(() => this.createReferencePropertyToUser(propertyId)),
        map(() => ({ property_id: propertyId }))
      );
  }

  initCreateSimpleProperty(formData: any):Observable<string | void> {

    const propertyId = this.afs.createId();
    const date = new Date();
    const item: ISimplePropertyModel = {
      address: formData.address,
      property_id: propertyId,                     // Persist a document id
      property_title: '',
      landlord_uid: this._auth.currentUserId,
      date_modified: date,
      date_created: date,
      date_listed: null,
      is_listed: false,                    // property listed (true: property public, false: property private)
      has_current_tenancy: false,
      is_marketing_listing: false,
      has_completed_information: false,
      pending_approval: false,
      // property_documents: PROPERTY_DOCS,
      media_ids: [],
      is_hmo: formData.is_hmo,
      listed_as_hmo: formData.listed_as_hmo,
      is_deleted: false,
      property_ref: formData.property_ref,
      team_id: formData.team_id || null,
      manager_uid: (this._user.userDb && this._user.userDb.property_manager_data) ? this._user.userDb.property_manager_data.company_uid : null
    };

    return fromPromise(this.propertiesCollection.doc(propertyId).set(item))
      .pipe(
        flatMap(() => this.createReferencePropertyToUser(propertyId)),
        map(() => {
          if (formData.is_hmo) {
            for (let index = 0; index < formData.number_bedrooms; index++) {
              this.initSimplePropertyBedroomNew(propertyId, index);
            }
          }
       return  propertyId;
      })

      );

  }

  createPropertyRef(address: IPropertyAddress) {
    return `${address.first_line_address}${address.second_line_address}`.toUpperCase().replace(/[^a-zA-Z0-9]/g, '').substring(0, 14);
  }


  deletePropertyBedroomCollection(property_id: string) {
    const qs = this.propertiesCollection.doc(property_id).collection('bedrooms').ref.get();
    const batch = this.afs.firestore.batch();
    return qs.then(doc => {
      doc.docs.forEach((res: any) => batch.delete(res.ref));
      return batch.commit();
    });
  }

  updateProperty(property_id: string, item: any, is_editing?: boolean) {
    return fromPromise(this.getCollection(is_editing).doc(property_id).set(item, { merge: true }));
  }

  updatePropertyDoc(property_id: string, bedroom_id: string, item: any, is_editing?: boolean) {
    const batch = this.afs.firestore.batch();

    if (bedroom_id) {
      const bedroomDocs = item.bedrooms[bedroom_id].property_documents;
      const mediIds = item.bedrooms[bedroom_id].media_ids;
      console.log(item.bedrooms[bedroom_id].property_documents, 'item.bedrooms[bedroom_id].property_documents', item);
      const bedroomRef = this.getCollection(is_editing).doc(property_id).collection('bedrooms').doc(bedroom_id).ref;
      const bedroomItem = {}
      if (bedroomDocs) {
        bedroomItem['property_documents'] = bedroomDocs;
      }
      if (mediIds) {
        bedroomItem['media_ids'] = mediIds;
      }
      batch.set(bedroomRef, bedroomItem, { merge: true });
    }

    const propertyRef = this.getCollection(is_editing).doc(property_id).ref;
    batch.set(propertyRef, item, { merge: true });

    return fromPromise(batch.commit());
  }

  updatePropertyOwners(property_id: string, property_owner_id: string) {
    return fromPromise(this.propertiesCollection.doc(property_id).set({ property_owner_id }, { merge: true }));
  }

  initPropertyOwners(property_id: string, value: any) {
    const property_owner_id = this.afs.createId();
    const propertyRef = this.propertiesCollection.doc(property_id).ref;
    const ownerRef = this.propertyOwnersCollection.doc(property_owner_id).ref;
    const batch = this.afs.firestore.batch();

    const ownerItem = {
      property_owner_id,
      team_id: (this._user.userHasTeam) ? this._user.userReadOnlyDb.team_ids[0] : null,
      landlord_uid: this._auth.currentUserId,
      ...value
    };
    const propertyItem = {
      property_owner_id
    };

    batch.set(propertyRef, propertyItem, { merge: true });
    batch.set(ownerRef, ownerItem, { merge: true });

    return fromPromise(batch.commit());
  }

  managePropertyOwners(property_id: string, saved_owner_id: string | undefined, value: any) {
    const batch = this.afs.firestore.batch();
    const property_owner_id = saved_owner_id ? saved_owner_id : this.afs.createId();

    const propertyRef = this.propertiesCollection.doc(property_id).ref;
    const ownerRef = this.propertyOwnersCollection.doc(property_owner_id).ref;
    const userDocRef = this.afs.collection('users').doc(this._auth.currentUserId).ref;

    const propertyItem = {
      property_owner_id
    };

    // Update or Create a new property owner if one hasn't been selected or doesnt exist
    const ownerItem = {
      landlord_uid: this._auth.currentUserId,
      property_owner_id,
      date_created: new Date(),
      ...value
    };
    batch.set(ownerRef, ownerItem, { merge: true });

    if (!saved_owner_id) {
      // Save a reference to the new owner on the user (update array of saved owners)
      const userPropertyOwnerIds = {
        date_modified: new Date(),
        property_owner_ids: firestore.FieldValue.arrayUnion(property_owner_id)
      };
      batch.update(userDocRef, userPropertyOwnerIds);
    }

    // Finally update the property to acknowledge the owner
    batch.set(propertyRef, propertyItem, { merge: true });

    return fromPromise(batch.commit()).pipe(map(() => property_owner_id));
  }

  deletePropertyBedroomById(property_id: string, bedroom_id: string) {
    return fromPromise(this.propertiesCollection.doc(property_id).collection('bedrooms').doc(bedroom_id).delete());
  }

  deleteBedroomCollections(property_id: string, bedroom_id: string, is_editing?: boolean) {
    return fromPromise(this.getCollection(is_editing).doc(property_id).collection('bedrooms').doc(bedroom_id).delete())
      .pipe(
        flatMap(() => this.deleteBedroomFromProperty(property_id, bedroom_id, is_editing))
      );
  }

  deleteBedroomFromProperty(property_id: string, bedroom_id: string, is_editing: boolean) {
    return fromPromise(this.getCollection(is_editing).doc(property_id).update({
      [`bedrooms.${bedroom_id}`]: firestore.FieldValue.delete()
    }));
  }

  deleteBedroomsFromPropertyForWholeProperty(property_id: string, bedroom_id: string, is_editing: boolean) {
    return fromPromise(this.getCollection(is_editing).doc(property_id).update({
      bedroom_id: firestore.FieldValue.delete()
    })).pipe(
      flatMap(() => this.deleteBedroomCollections(property_id, bedroom_id, is_editing))
    );
  }

  initPropertyBedroom(property_id: string, bedroom_id: string, has_tenants: boolean) {
    const item = {
      bedroom_id: bedroom_id,
      bedroom_listed: false,
      mid_tenants: [
        // {
        //   ...(has_tenants ? {first_name: null, email: null} : {}),
        //   rent_amount: null,
        //   currency: 'GBP',
        //   bed_type: null,
        //   has_ensuite: false
        // }
      ]
    };
    return fromPromise(this.propertiesCollection.doc(property_id).collection('bedrooms').doc(bedroom_id).set(item));
  }

  initPropertyBedroomNew(property_id: string, has_tenants: boolean, index: number, is_editing?: boolean): Observable<{ bedroom_id: string }> {
    const bedroom_id = this.afs.createId();

    const propertyRef = this.getCollection(is_editing).doc(property_id).ref;
    const bedroomRef = this.getCollection(is_editing).doc(property_id).collection('bedrooms').doc(bedroom_id).ref;

    const batch = this.afs.firestore.batch();

    const propertyItem = {
      bedrooms: {
        [bedroom_id]: {
          is_mid_tenants: has_tenants,
          is_listed: false,
          index: index,
          property_documents: PROPERTY_DOCS,
          media_ids: []
        }
      }
    };

    const bedroomItem = {
      property_id,
      bedroom_id: bedroom_id,
      is_mid_tenants: has_tenants,
      index: index,
      property_documents: PROPERTY_DOCS,
      media_ids: [],
      temp_tenancy_data: {}
    };

    batch.set(propertyRef, propertyItem, { merge: true });
    batch.set(bedroomRef, bedroomItem, { merge: true });

    return fromPromise(batch.commit()).pipe(
      map(() => ({ bedroom_id: bedroom_id }))
    );
  }

  initSimplePropertyBedroomNew(propertyId: string, index: number) {
    const bedroom_id = this.afs.createId();

    const propertyRef = this.propertiesCollection.doc(propertyId).ref;
    const bedroomRef = this.propertiesCollection.doc(propertyId).collection('bedrooms').doc(bedroom_id).ref;
    const batch = this.afs.firestore.batch();


    const propertyItem = {
      bedrooms: {
        [bedroom_id]: {
          bedroom_id: bedroom_id,
          room_name: `${environment.client_data.unit_name} ${index + 1}`,
          index: index,
          is_listed: false,
          is_mid_tenants: false,
          property_documents: PROPERTY_DOCS,
          media_ids: []
        }
      }
    };

    const bedroomItem = {
      property_id: propertyId,
      bedroom_id: bedroom_id,
      room_name: `Room ${index + 1}`,
      is_listed: false,
      is_mid_tenants: false,
      index: index,
      property_documents: PROPERTY_DOCS,
      media_ids: [],
      temp_tenancy_data: {}
    };

    batch.set(propertyRef, propertyItem, { merge: true });
    batch.set(bedroomRef, bedroomItem, { merge: true });

    return fromPromise(batch.commit()).pipe(
      map(() => ({ bedroom_id: bedroom_id }))
    );
  }

  updateBedroomCollectionNoTenants(property_id: string, bedroom_id: string, form: any, is_editing: boolean, has_edited: boolean) {
    const propertyRef = this.getCollection(is_editing).doc(property_id).ref;
    const bedroomRef = this.getCollection(is_editing).doc(property_id).collection('bedrooms').doc(bedroom_id).ref;
    const batch = this.afs.firestore.batch();

    const propertyItem = {
      bedrooms: {
        [bedroom_id]: {
          bedroom_id,
          ...form
        }
      }
    };
    const bedroomItem = {
      property_id,
      bedroom_id,
      ...form
    };

    batch.set(propertyRef, propertyItem, { merge: true });
    batch.set(bedroomRef, bedroomItem, { merge: true });

    return fromPromise(batch.commit());
  }

  updateBedroomMidTenants(property_id: string, bedroom_id: string, form: any, tenant_data: any, is_editing: boolean, has_edited: boolean) {
    console.log('updateBedroomMidTenants: ', tenant_data);
    const propertyRef = this.getCollection(is_editing).doc(property_id).ref;
    const bedroomRef = this.getCollection(is_editing).doc(property_id).collection('bedrooms').doc(bedroom_id).ref;

    const batch = this.afs.firestore.batch();

    const propertyItem = {
      bedrooms: {
        [bedroom_id]: {
          apartment_type: form.apartment_type,
          bedroom_id: bedroom_id,
          room_name: form.room_name,
          bed_type: form.bed_type,
          has_ensuite: form.has_ensuite,
          rent_qtr: form.rent_qtr,
          listed_rent_pcm: form.listed_rent_pcm,
          deposit_amount: form.deposit_amount,
          rent_interval_count: form.rent_interval_count,
          does_accept_couples: form.does_accept_couples,
          room_thumbnail_url: form.room_thumbnail_url,
          is_editing: (is_editing && has_edited),
          property_documents: form.property_documents,
          media_ids: form.media_ids || []
        }
      }
    };

    const bedroomItem = {
      bedroom_id,
      property_id,
      apartment_type: form.apartment_type,
      room_name: form.room_name,
      rent_interval_count: form.rent_interval_count,
      listed_rent_pcm: form.listed_rent_pcm,
      rent_qtr: form.rent_qtr,
      room_thumbnail_url: form.room_thumbnail_url,
      property_documents: form.property_documents,
      media_ids: form.media_ids || [],
      temp_tenancy_data: {
        rent_start_on: form.rent_start_on,
        rent_due_day: form.rent_due_day,
        rent_interval: 'month',
        no_end_date: form.no_end_date,
        rent_interval_count: form.rent_interval_count,
        ...tenant_data,
        tenants: tenant_data.tenants.map(tenant => {
          return {
            ...tenant,
            currency: 'gbp'
          };
        }),
        tenancy_end_date: tenant_data.no_end_date ? false : tenant_data.tenancy_end_date,
        agreement_url: tenant_data.agreement_url
      }
    };

    batch.set(propertyRef, propertyItem, { merge: true });
    batch.set(bedroomRef, bedroomItem, { merge: true });

    return fromPromise(batch.commit());

  }


  updateBedroom(property_id: string, bedroom_id: string, item: any) {
    return fromPromise(this.propertiesCollection.doc(property_id).collection('bedrooms').doc(bedroom_id).update(item));
  }

  getPropertyBedrooms(property_id: string): AngularFirestoreCollection<any> {
    const propertyRef = this.propertiesCollection.doc(property_id);
    return propertyRef.collection('bedrooms', ref =>
      ref.where('bedroom_id', '>', '')
    );
  }

  deletePropertyBedroom(property_id: string, bedroom_id: string) {
    this.afs.doc(`properties/${property_id}/bedrooms/${bedroom_id}`).delete();
  }

  publishProperty(property_id: string) {
    const item = {
      is_listed: true,
      date_listed: new Date()
    };
    return this.propertiesCollection.doc(property_id).update(item)
      .then(() => console.log('Property published!'));
  }

  unpublishProperty(property_id: string) {
    const item = {
      is_listed: false,
      date_listed: null
    };
    return this.propertiesCollection.doc(property_id).update(item)
      .then(() => console.log('Property unpublished!'));
  }

  generateFeatureTags(object) {
    const tags = [];
    Object.keys(object)
      .forEach((value) => {
        if (tags.length < 7) {
          if (object[value]) {
            value = value
              .replace(/_/g, ' ')
              .replace(/(?: |\b)(\w)/g, (key) => key.toUpperCase())
              .replace(/ /g, '');
            const tag = '#' + value;
            tags.push(tag);
          }
        }
      });
    return tags;
  }

  /* Validate if the form is ready to be published - Check if value passed match the firsts keys of each step */
  isFormCompleted = (property: PropertyModel): boolean => {
    const validForm = ['street_number', 'description', 'number_bedrooms', 'documents', 'available_from', 'self_managed_plan'];
    const keys = Object.keys(property);
    const x = validForm.filter(keyValid => keys.indexOf(keyValid) === -1);
    return x.length === 0;
  };

  getPropertyById(id): AngularFirestoreDocument<any> {
    return this.propertiesCollection.doc(id);
  }
  getPortfolioById(id): AngularFirestoreDocument<any> {
    return this.propertyPortfolioCollection.doc(id);
  }

  getPropertyEditById(id): AngularFirestoreDocument<any> {
    return this.propertyEditsCollection.doc(id);
  }

  getBedroomById(bedroom_id: string, property_id: string, is_editing?: boolean): AngularFirestoreDocument<any> {
    console.log(is_editing);
    return this.getCollection(is_editing).doc(property_id).collection('bedrooms').doc(bedroom_id);
  }

  getAllBedrooms(property_id: string, is_editing?: boolean) {
    return this.getCollection(is_editing).doc(property_id).collection('bedrooms', (ref) => ref.orderBy('index'));
  }


  getPropertyOwnerById(id: string): AngularFirestoreDocument<any> {
    return this.propertyOwnersCollection.doc(id);
  }

  getAllPropertiesFromLiked() {
    const likedProperties = this._user.userDb.tenant_data.liked_property_ids;
    const properties = [];
    if (likedProperties) {
      Object.keys(likedProperties).forEach((key, index) => {
        const property = this.getPropertyById(key).valueChanges();
        properties.push(property);
      });
      return of(properties);
    }
  }

  getTotalUserLikedProperties(): Observable<number> {
    const likedProperties = this._user.userDb && this._user.userDb.tenant_data.liked_property_ids;
    const totalLiked = likedProperties ? Object.keys(likedProperties).length : 0;
    return of(totalLiked);
  }


  getAllUserProperties(): Observable<any> {
    const teamQuery = this._user.userReadOnlyDb.team_ids
      ? this.afs.collection('properties', ref => ref
        .where('is_deleted', '==', false)
        .where('team_id', 'in', this._user.userReadOnlyDb.team_ids)).valueChanges()
      : of([]);

    const landlordQuery = this.afs.collection('properties', ref => ref
      .where('is_deleted', '==', false)
      .where('landlord_uid', '==', this._auth.currentUserId)).valueChanges();

    return this._auth.getCurrentUserCustomClaims().pipe(
      switchMap((res: boolean) => {
        return (res && !this._auth.currentUser.displayName)
          ? this.afs.collection('properties', ref => ref.where('is_deleted', '==', false)).valueChanges()
          : this._auth.combineQueries(teamQuery, landlordQuery);
      })
    );
  }

  getListedProperties(): Observable<any> {
    const teamQuery = this._user.userReadOnlyDb.team_ids
      ? this.afs.collection('properties', ref => ref
        .where('has_completed_information', '==', true)
        .where('team_id', 'in', this._user.userReadOnlyDb.team_ids)).valueChanges()
      : of([]);

    const landlordQuery = this.afs.collection('properties', ref => ref
      .where('has_completed_information', '==', true)
      .where('landlord_uid', '==', this._auth.currentUserId)).valueChanges();

    return this._auth.getCurrentUserCustomClaims().pipe(
      switchMap((res: boolean) => {
        return (res && !this._auth.currentUser.displayName)
          ? this.afs.collection('properties', ref => ref.where('has_completed_information', '==', true)).valueChanges()
          : this._auth.combineQueries(teamQuery, landlordQuery);
      })
    );
  }

  deleteProperty(property_id: string) {
    return fromPromise(this.propertiesCollection.doc(property_id).set({ is_deleted: true }, { merge: true }));
  }

  getListedHMOProperties(): Observable<any> {
    const teamQuery = this._user.userReadOnlyDb.team_ids
      ? this.afs.collection('properties', ref => ref
        .where('is_hmo', '==', true)
        .where('team_id', 'in', this._user.userReadOnlyDb.team_ids)).valueChanges()
      : of([]);
    const landlordQuery = this.afs.collection('properties', ref => ref
      .where('is_hmo', '==', true)
      .where('landlord_uid', '==', this._auth.currentUserId)).valueChanges();

    return this._auth.getCurrentUserCustomClaims().pipe(
      switchMap((res: boolean) => {
        return (res && !this._auth.currentUser.displayName)
          ? this.afs.collection('properties', ref => ref.where('is_hmo', '==', true)).valueChanges()
          : this._auth.combineQueries(teamQuery, landlordQuery);
      })
    );
  }

  //
  getUnfinishedProperties(): AngularFirestoreCollection<any> {
    return this.afs.collection('properties', ref =>
      ref.where('landlord_uid', '==', this._auth.currentUserId)
        .where('has_completed_information', '==', false).where('is_deleted', '==', false)
    );
  }

  getLandlordActiveProperties(): AngularFirestoreCollection<any> {
    return this.afs.collection('properties', ref =>
      ref.where('landlord_uid', '==', this._auth.currentUserId)
        .where('has_completed_information', '==', true)
        .where('has_current_tenancy', '==', true)
    );
  }

  getLandlordPropertyPortfolios(): Observable<any> {
    const teamQuery = this._user.userReadOnlyDb.team_ids
      ? this.afs.collection('property_portfolios', ref => ref
        .where('is_deleted', '==', false)
        .where('team_id', 'in', this._user.userReadOnlyDb.team_ids)).valueChanges()
      : of([]);
    const landlordQuery = this.afs.collection('property_portfolios', ref => ref
      .where('is_deleted', '==', false)
      .where('landlord_uid', '==', this._auth.currentUserId)).valueChanges();

    return this._auth.combineQueries(teamQuery, landlordQuery);
  }

  // Reference property to user, generate field properties_id: []
  createReferencePropertyToUser(property_id: string) {
    const userDocRef = this.afs.collection('users').doc(this._auth.currentUserId).ref;
    return this.fb.firestore().runTransaction(transaction => {
      return transaction.get(userDocRef)
        .then(user => {
          const userData = user.data();
          const newIDs: { properties_id: Array<string> } = (userData.properties_id)
            ? { properties_id: [...userData.properties_id, property_id] }
            : { properties_id: [property_id] };
          transaction.set(userDocRef, newIDs, { merge: true });
        });
    });
  }

  updateListAPropertyStepArray(property_id: string, step: number, is_editing: boolean): Observable<any> {
    // get either property or property_edits ref
    const propertyRef = (is_editing) ? this.propertyEditsCollection.doc(property_id).ref : this.propertiesCollection.doc(property_id).ref;
    return fromPromise(this.fb.firestore().runTransaction(transaction => {
      return transaction.get(propertyRef)
        .then((propertyDoc: any) => {

          const property = propertyDoc.data();
          const date = new Date();
          // set current step and boolean for next step
          let current_step = null;
          let has_next_step = false;

          // function to create the object that needs to be added to the array base on whether its updating, creating current or creating next step
          const getItem = (next_step?: boolean) => {
            return {
              step: (next_step) ? step + 1 : step,
              step_name: (next_step) ? LIST_PROPERTY_STEPS[step] : LIST_PROPERTY_STEPS[step - 1],
              date_created: (!next_step && current_step) ? current_step.date_created : date,
              date_modified: (next_step) ? null : date,
              date_completed: (next_step) ? null : (current_step && current_step.date_completed) ? current_step.date_completed : date
            };
          };

          // check if this is the first item in the array
          if (property.step_completion) {
            property.step_completion.map((iStep: any, index: number) => {
              // Return current step if already exists (So it can be updated)
              if (iStep.step === step) { current_step = { ...iStep, index }; }
              // Check is the next step has already been created
              if (iStep.step === step + 1) { has_next_step = true; }
            });
          }

          if (current_step) {
            // If the current step is being updated (not created) remove the old version from firestore as you can not update an object within an array
            transaction.update(propertyRef, { step_completion: firestore.FieldValue.arrayRemove(property.step_completion[current_step.index]) });
          }

          // If the next step hasnt been created and its not the last step, create the next step && update the current step
          // If its the last step, or the next step has been created, just update current step
          const array = (!has_next_step && step < 6) ? firestore.FieldValue.arrayUnion(getItem(), getItem(true)) : firestore.FieldValue.arrayUnion(getItem());

          // run transaction update on all array items that need updating
          transaction.update(propertyRef, { date_modified: new Date(), step_completion: array });

        });
    }));
  }

  deletePropertyById(property_id: string): Observable<any> {
    return fromPromise(this.propertiesCollection.doc(property_id).delete());
  }

  deletePropertyOwnerById(property_owner_id: string, property_id: string): Observable<any> {
    return fromPromise(this.propertyOwnersCollection.doc(property_owner_id).delete())
      .pipe(
        flatMap(() => this.deletePropertyOwnerIdInProperty(property_id))
      );

  }

  deletePropertyOwnerIdInProperty(property_id: string): Observable<any> {
    return fromPromise(this.propertiesCollection.doc(property_id).update({
      property_listed_as: 'individual',
      property_owner_id: firestore.FieldValue.delete()
    }));
  }

  // updatePropertyPendingApprovalBoolean(id) {
  //   const item = {
  //     pending_approval: true
  //   };
  //   return fromPromise(this.propertiesCollection.doc(id).set(item, {merge: true}))
  // }

  updatePendingApprovalStatus(property, bedrooms?) {

    const body = {
      property_id: property.property_id,
      total_rent_pcm: property.household_rent_pcm,
      first_line_address: property.address.first_line_address,
      second_line_address: property.address.second_line_address,
      post_code: property.address.post_code,
      third_line_address: property.third_line_address,
      landlord_name: this._user.userDb.profile_data.full_name,
      is_mid_tenants: property.is_mid_tenants,
      is_hmo: property.is_hmo,
      bedrooms_data: (bedrooms) ? bedrooms : '',
      number_bedrooms: property.number_bedrooms,
      project: environment.firebaseConfig.projectId
    };
    return this._http.post(environment.firebaseConfig.apiUrl + '/landlord-listProperty', body, { headers: this.headers, observe: 'response' });
  }

  getAdminPropertyData() {
    return this._http.get(environment.firebaseConfig.apiUrl + '/adminFirestore-createListedPropertyData');
  }

  // property list new updating
  updatePropertyListAddressForm(value: any, property_id: string, is_editing: boolean) {
    const address = value.address;
    const item: any = {
      is_mid_tenants: false,
      property_ref: address.property_ref,
      _geoloc: {
        lat: address.lat,
        lng: address.lng
      },
      address: {
        first_line_address: address.first_line_address,
        second_line_address: address.second_line_address,
        city: address.city,
        post_code: address.post_code,
        county: address.county,
        country: address.country,
        lat: address.lat,
        lng: address.lng,
        thoroughfare: address.thoroughfare,
        dependant_locality: address.dependant_locality,
        listed_as_hmo: address.listed_as_hmo,
      }
    };
    if (value.team_id) {
      item.team_id = value.team_id.team_id;
    }
    return fromPromise(this.getCollection(is_editing).doc(property_id).set(item, { merge: true }));
  }

  updatePropertyCompleteInformation(property_id: string, is_bunk_registering_deposit: boolean, pricing_plan: string) {
    const item = {
      has_completed_information: true,
      is_bunk_registering_deposit,
      pricing_plan
    };
    return fromPromise(this.propertiesCollection.doc(property_id).set(item, { merge: true }));
  }

  updateBedroomMidTenantsInfo(property_id: string, bedroom_id: string, form: any, is_editing?: boolean) {
    const propertyRef = this.getCollection(is_editing).doc(property_id).ref;

    const bedroomRef = this.getCollection(is_editing).doc(property_id).collection('bedrooms').doc(bedroom_id).ref;

    const batch = this.afs.firestore.batch();

    const propertyItem = {
      rent_qtr: form.rent_qtr,
      rent_interval_count: form.rent_interval_count,
      household_rent_pcm: form.household_rent_pcm,
      deposit_amount: form.deposit_amount,
    };

    const bedroomItem = {
      bedroom_id,
      temp_tenancy_data: {
        ...form.temp_tenancy_data,
        rent_interval_count: form.rent_interval_count,
        rent_start_on: form.rent_start_on,
        rent_due_day: form.rent_due_day,
        tenancy_start_date: form.tenancy_start_date,
        tenancy_end_date: form.temp_tenancy_data.no_end_date ? false : form.tenancy_end_date,
        is_mid_tenants: form.is_mid_tenants,
        deposit_amount: form.deposit_amount,
        tenants: form.temp_tenancy_data.tenants.map(tenant => {
          return {
            ...tenant,
            currency: 'gbp'
          };
        })
      },
    };

    batch.set(propertyRef, propertyItem, { merge: true });
    batch.set(bedroomRef, bedroomItem, { merge: true });

    return fromPromise(batch.commit());
  }


  initRentDetailsBedroom(property_id: string, item: any, is_editing: boolean): Observable<{ bedroom_id: string }> {
    const bedroom_id = this.afs.createId();
    const propertyRef = this.getCollection(is_editing).doc(property_id).ref;
    const bedroomRef = this.getCollection(is_editing).doc(property_id).collection('bedrooms').doc(bedroom_id).ref;
    const batch = this.afs.firestore.batch();

    const propertyItem = {
      bedroom_id: bedroom_id,
      is_mid_tenants: item.is_mid_tenants
    };

    const bedroomItem = {
      bedroom_id: bedroom_id
    };

    batch.update(propertyRef, propertyItem);
    batch.set(bedroomRef, bedroomItem, { merge: true });

    return fromPromise(batch.commit()).pipe(
      map(() => ({ bedroom_id: bedroom_id }))
    );
  }

  removeAlgoliaPropertyData(property_id: string) {
    return this._http.post(environment.firebaseConfig.apiUrl + '/algolia-removePropertyData', { property_id: property_id });
  }

  landlordUnlistProperty(property_id: string, form: any) {
    return this._http.post(environment.firebaseConfig.apiUrl + '/landlord-unlistProperty',
      {
        property_id,
        bedroom_ids: form.bedroom_ids,
        cancellation_type: form.cancellation_type,
        reason: form.reason
      });
  }

  initEditProperty(id: string) {
    return this._http.post(environment.firebaseConfig.apiUrl + '/landlord-landlordEditProperty', { property_id: id });
  }

  landlordPublishPropertyEdit(id: string) {
    return this._http.post(environment.firebaseConfig.apiUrl + '/landlord-publishChangesToProperty', { property_id: id });
  }

  hasFormUpdated(oldForm: any, newForm: any) {
    return (JSON.stringify(oldForm) !== JSON.stringify(newForm));
  }

  updatePropertyEditStatus(step: string, property_edit_id: string) {
    const item = {
      steps: {
        [step]: true
      }
    };
    return fromPromise(this.propertyEditsCollection.doc(property_edit_id).set(item, { merge: true }));
  }

  cancelPropertyEdits(property_id: string) {
    return fromPromise(this.propertiesCollection.doc(property_id).set({ is_editing: false }, { merge: true }));
  }

  createPropertyPortfolio(form: any, id = this.afs.createId()) {
    const item = {
      ...form,
      portfolio_id: id,
      landlord_uid: this._auth.currentUserId,
      is_deleted: false,
      date_created: new Date(),
      date_modified: new Date(),
      team_id: this._user.userReadOnlyDb.team_ids[0]
    };
    return fromPromise(this.propertyPortfolioCollection.doc(id).set(item, { merge: true }));
  }

  updatePropertyPortfolio(form: any) {
    return fromPromise(this.propertyPortfolioCollection.doc(form.portfolio_id).set({ ...form }, { merge: true }));
  }

  createUUID() {
    return this.afs.createId();
  }

  createPropertyMetaData(property_id: string, is_hmo: boolean) {
    const id = this.createUUID();
    const date = new Date();
    return fromPromise(this.propertyMetadataCollection.doc(id).set({
      property_metadata_id: id,
      type: 'listing_view',
      date_created: date,
      date_modified: date,
      property_id,
      is_hmo,
      uid: (this._auth.currentUserId) ? this._auth.currentUserId : 'guest'
    }, { merge: true }));
  }

  deletePropertyDocument(property_id: string, bedroom_id: string, object: any, is_editing: boolean) {
    // if (bedroom_id) {
    //   console.log('hello bedroom id');
    //
    // }


    return fromPromise(this.getCollection(is_editing).doc(property_id).update({
      property_documents: firestore.FieldValue.arrayRemove(object)
    }));

    // Wasnt working for bedroom id

    // return bedroom_id ?  fromPromise(this.getCollection(is_editing).doc(property_id).update({
    //   bedrooms : {
    //     [bedroom_id] : {
    //       property_documents: firestore.FieldValue.arrayRemove(object)
    //     }
    //   }
    // })) : fromPromise(this.getCollection(is_editing).doc(property_id).update({
    //   property_documents: firestore.FieldValue.arrayRemove(object)
    // }));

  }

  createPropertyAddress(property: any, bedroom_id: any = null) {
    const address = `${property.address.first_line_address} ${property.address.second_line_address} ${property.address.city}, ${property.address.post_code}`;
    return (bedroom_id && property.bedrooms[bedroom_id])
      ? `${property.bedrooms[bedroom_id].room_name}, ${address}`
      : address;
  }

  getPropertyAddressWithId(property_id: string, bedroom_id: any = null) {

    const property$ = this.getPropertyById(property_id).valueChanges();

    const prop_address = this.getPropertyById(property_id).valueChanges().pipe(
      map((property: any) => {
        return {
          property_address: this.createPropertyAddress(property, bedroom_id)
        };
      })
    );

    return prop_address;
  }

  updateListed(bedroomIds: string, property: any, isListed: boolean) {
    const batch = this.afs.firestore.batch();
    const propertyRef = this.getCollection(property.is_editing).doc(property.property_edit_id).ref;

    if (property.is_hmo && !bedroomIds.length) {
      batch.update(propertyRef, { is_listed: isListed });
    }

    if (property.is_hmo && bedroomIds.length) {
      for (const bedroomId of bedroomIds) {
        console.log(bedroomId, 'each bedroom id');
        const propertyItem = {
          bedrooms: {
            [bedroomId]: {
              is_listed: true,
            }
          },
          is_listed: true
        };
        console.log(propertyItem, 'pro item');
        batch.set(propertyRef, propertyItem, { merge: true });
      }
    } else {
      batch.update(propertyRef, { is_listed: isListed });
    }
    return fromPromise(batch.commit());
  }


  inviteMidTenants(tenancy_id: string) {
    return this._http.post(environment.firebaseConfig.apiUrl + '/activeTenancies-inviteMidTenants', { tenancy_id });
  }

  updateBedrooms(property_id: string, bedroom_id: string, item: any, is_editing?: boolean) {
    const propertyRef = this.getCollection(is_editing).doc(property_id).ref;
    const bedroomRef = this.getCollection(is_editing).doc(property_id).collection('bedrooms').doc(bedroom_id).ref;
    const batch = this.afs.firestore.batch();


    batch.set(propertyRef, item, { merge: true });
    batch.set(bedroomRef, item, { merge: true });

    return fromPromise(batch.commit());
  }

  getAllPropertyOwners(): Observable<any> {
    const teamQuery = this._user.userReadOnlyDb.team_ids
      ? this.afs.collection('property_owners', ref => ref
        .where('team_id', 'in', this._user.userReadOnlyDb.team_ids)).valueChanges()
      : of([]);

    const landlordQuery = this.afs.collection('property_owners', ref => ref
      .where('landlord_uid', '==', this._auth.currentUserId)).valueChanges();

    return this._auth.getCurrentUserCustomClaims().pipe(
      switchMap((res: boolean) => {
        return (res && !this._auth.currentUser.displayName)
          ? this.afs.collection('property_owners').valueChanges()
          : this._auth.combineQueries(teamQuery, landlordQuery);
      })
    );
  }
  getAllPropertiesByParentPropertyId(propertyId) {
    console.log(propertyId, 'property')
    return this.afs.collection<any>('properties', ref =>
      ref.where('parent_property_id', '==', propertyId)
        .where('is_deleted', '==', false),
    ).valueChanges();
  }


  updateIsUnderOfferWhileSwappingRooms(selectedPropertyId, currentPropertyId) {
    return from(this.propertiesCollection.doc(currentPropertyId).set({ is_under_offer: false }, { merge: true })).pipe(flatMap(() =>
      this.propertiesCollection.doc(selectedPropertyId).set({ is_under_offer: true }, { merge: true })
    ))
  }

  getAllCampusPropertyData() {
    return this.afs.collection<any>('properties', ref =>
      ref.orderBy('campus')).valueChanges();
  }

  addPropertyListing(property_id: string, products: any[]) {
    const property_listing_id = this.createUUID();
    const date = new Date();
    const body = {
      date_created: date,
      date_modified: date,
      property_listing_id,
      property_id,
      products,
      team_id: (this._user.userHasTeam) ? this._user.userReadOnlyDb.team_ids[0] : null,
      landlord_uid: this._auth.currentUserId,
      status_history: [
        {
          status: 'unlisted',
          date,
        },
      ],
    }
    return from(this.propertyListingsCollection.doc(property_listing_id).set(body)).pipe(
      map(() => property_listing_id),
    );
  }

  getPropertyListing(property_listing_id: string) {
    return this.propertyListingsCollection.doc(property_listing_id).get().pipe(map((data) => data.data()));
  }

  addSessionIdToPropertyListing(property_listing_id: string, sessionId: string) {
    return this.propertyListingsCollection.doc(property_listing_id).get().pipe(
      mergeMap((propertyListing) => {
        if (!propertyListing.exists) {
          return throwError(`Property or Property listing doesn't exist`);
        }
        const propertyListingRef = propertyListing.ref;
        const batch = this.afs.firestore.batch();
        const date = new Date();
        batch.set(propertyListingRef, {
          date_modified: date,
          sessionId,
        }, { merge: true });

        return fromPromise(batch.commit());
      })
    )
  }

  paidPropertyListing(property_id: string, property_listing_id: string): Observable<any> {
    const body = {
      property_id,
      property_listing_id,
    }
    return this._http.post(environment.firebaseConfig.apiUrl + '/landlord-listProperty', body, { headers: this.headers, observe: 'response' });
  }
}


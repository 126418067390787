import { Component, Input, OnInit } from '@angular/core';
import {UserService, NotesService, PropertyService} from '@app/services';
import { Observable} from 'rxjs';

import * as moment from 'moment';
import {map} from 'rxjs/operators';
import {of} from 'rxjs/internal/observable/of';
import {MathService} from '@app/services/math.service';

const PRIORITY_STATS = {
  medium: 'Medium',
  'non-urgent': 'Non-Urgent',
  low: 'Low',
  high: 'High'
};

@Component({
  selector: '[bunk-maintenance-row]',
  template: `
    <td>
      <div class="flex flex-column">
      <span class="title">{{maintenance.title}}</span>
      <small>Reported {{maintenance.created_at}}</small>
      </div>
    </td>

    <td *ngIf="(property$ | async) as property"  >
      <span class="text--sm address">{{property.property_address}}</span>
    </td>

    <td>
      <div  class="flex flex-column">
      <span *ngIf="maintenance.priority; else noItem"
            class="text--xs">
        <span class="key__status"
        [ngClass]="{'key__status--success' : maintenance.priority === 'Low',
                    'key__status--warning' : maintenance.priority === 'Medium',
                    'key__status--danger' : maintenance.priority === 'High'
        }"></span>
        {{maintenance.priority}}
      </span>
      </div>
      <div class="flex flex-row items-center">
      <small class="mr_3" [ngClass]="{'overdue': maintenance.overdue}">{{maintenance.days_different}} Day{{(maintenance.days_different > 1 ? 's' : '')}}</small>
        <span *ngIf="maintenance.overdue" class="icon__warning icon__warning--xs"
              #tooltip="matTooltip"
              matTooltip="Fix Period Exceeded"
              matTooltipPosition="below"
              matTooltipHideDelay="1000"
        ></span>

      </div>


    </td>
    <ng-container *ngIf="(notes$ | async) as note">
      <td>
        <div class="flex flex-row justify-between items-center" *ngIf="note.length; else noItem">

          <div class="flex flex-column">
            <span>{{note.title}}</span>
            <small>Updated {{note.updated}}</small>
          </div>

          <div>
            <mat-icon svgIcon="notes" class="note-icon"></mat-icon>
            <span>{{note.length}}</span>
          </div>

        </div>
    </td>

    <td class="flex items-center justify-end">

    </td>

    </ng-container>

    <ng-template #noItem>
      <small>-</small>
    </ng-template>

        `,
  styleUrls: ['bunk-maintenance-table.component.scss']
})

export class BunkMaintenanceRowComponent implements OnInit {
  @Input() maintenance: any;
  public notes$: Observable<any>;
  public isDesktop: boolean;
  public combined$: Observable<any>;
  public property$: Observable<any>;
  public today = new Date();



  @Input()
  get maintenanceData(): any {
    return this.maintenance;
  }
  set maintenanceData(event: any) {
    if (event) {
      this.maintenance = event;
      this.setData();
    }
  }


  constructor(private _user: UserService,
              private _notes: NotesService,
              private _property: PropertyService,
              private _math: MathService,
  ) {}

  ngOnInit(): void {
  }

  setData() {
    const difference = moment(this.today).diff(moment(this.maintenance.created_at.toDate()), 'days');

    this.maintenance = {
      ...this.maintenance,
      created_at: this.maintenance.created_at ? this.formatDate(this.maintenance.created_at) : null,
      priority: this.maintenance.priority ? PRIORITY_STATS[this.maintenance.priority] : null,
      days_different: difference,
      overdue: this.maintenance.priority ? this.getOverdueMaintenance(difference) : null,

    };

    this.notes$ = this._notes.getMaintenanceNotes(this.maintenance.property_id, this.maintenance.request_id).pipe(
      map((notes: any) => {
       if (notes.length )  {
         const sortedNotes = this._math.sortArrayDescending(notes, 'date_modified')
         const firstNote = sortedNotes[0];
         return {
           updated: firstNote.date_modified ? this.formatDate(firstNote.date_modified) : this.formatDate(firstNote.date_created),
           title: firstNote.title,
           description: firstNote.description,
           length: notes.length
         };

       } else {
         return of([]);
       }

      })
    );

    this.property$ = this._property.getPropertyById(this.maintenance.property_id).valueChanges().pipe(
      map((property: any) => {
        return {
          property_address: this._property.createPropertyAddress(property)
        };
      })
    );
  }

  formatDate(date: any) {
    return moment(date.toDate()).format('DD MMM YYYY');
  }

  getOverdueMaintenance(difference: number ) {
    console.log(this.maintenance.priority)
    switch (true) {
      case (this.maintenance.priority === 'non-urgent' || this.maintenance.priority === 'low') && difference > 30:
        return true;
      case this.maintenance.priority === 'medium' && difference > 7:
        return true;
      case this.maintenance.priority === 'high' && difference > 2:
        return true;
      default:
        return false;
    }

  }



}

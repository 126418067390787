import { Component, Input, forwardRef, Output, EventEmitter, OnDestroy, OnInit, Optional } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NG_VALIDATORS, AbstractControl, FormBuilder, FormGroup, Validators, FormGroupDirective } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as moment from 'moment';

const MONTHS = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

@Component({
  selector: 'date-selector',
  template: `
    <label *ngIf="inputLabel">{{inputLabel}}</label>
    <form [formGroup]="formGroup">
      <mat-form-field appearance="outline" class="input__date">
        <mat-label>Day</mat-label>
        <input matInput formControlName="day"
               type="number" name="day"
               min="1"
               max="31"
               step="1"
               (change)="onInputChange()"
               (focusout)="updateErrors('day')">
      </mat-form-field>
      <mat-form-field appearance="outline" class="input__month">
        <mat-label>Month</mat-label>
        <mat-select formControlName="month"
                    (selectionChange)="onInputChange()"
                    (focusout)="updateErrors('month')">
          <mat-option *ngFor="let month of getArrayOfNumbers(12)" [value]="month">
            {{ monthsArr[month] }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline" class="input__year">
        <mat-label>Year</mat-label>
        <input matInput formControlName="year"
               type="number" name="year"
               [min]="yearStart"
               [max]="yearEnd"
               step="1"
               (change)="onInputChange()"
               (focusout)="updateErrors('year')">
      </mat-form-field>
      <small *ngIf="errorMessage"><mat-error>{{errorMessage}}</mat-error></small>
      <!-- <span *ngIf="errorMessage" class="mat-error error">{{errorMessage}}</span> -->
    </form>
  `,
  styleUrls: ['./date-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateSelectorComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => DateSelectorComponent),
      multi: true
    }
  ]
})
export class DateSelectorComponent implements OnInit, OnDestroy, ControlValueAccessor {
  @Output() isChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input() inputLabel: string; // @optional: so that there can be a label above the whole(all 3 inputs) if you need one
  @Input() yearStart = 1970;   // @optional: validate year start
  @Input() yearEnd = 2100;     // @optional: validate year end

  /** @deprecated */
  @Input() set parentFormInvalid(val: any) {
    this._parentFormInvalid = val;
    this.markAsTouched();
  }
  get parentFormInvalid(): any {
    return this._parentFormInvalid;
  }
  private _submitAttempted = false;

  @Input('submitAttempted') set submitAttempted(boo: boolean) {
    this._submitAttempted = boo;
    if (boo) {
      this.dirtyFormGroup();
    }
  }
  get submitAttempted(): boolean {
    return this._submitAttempted;
  }


  /** @deprecated */
  @Input() parentForm: FormGroup;

  private _parentFormInvalid: boolean; /** @deprecated */
  private valueChange: Date;
  private destroy$: Subject<boolean> = new Subject<boolean>();
  public formGroup: FormGroup;
  public errorMessage: string;
  public monthsArr: Array<string> = MONTHS;

  get value(): Date {
    return this.valueChange;
  }

  set value(val: Date) {
    this.valueChange = val;
    this._onChange(val);
    this._onTouched();
  }

  constructor(
    @Optional() private reactiveForm: FormGroupDirective,
    private _formBuilder: FormBuilder
  ) {
    if (this.parentForm) {
      console.warn(`DateSelectorComponent 'parentForm' input is deprecated`);
    }
    if (this.parentFormInvalid) {
      console.warn(`DateSelectorComponent 'parentFormInvalid' input is deprecated`);
    }
  }

  dirtyFormGroup() {
    if (this.formGroup) {
      const controls = this.formGroup.controls;
      for (const control in controls) {
        if (controls.hasOwnProperty(control)) {
          this.formGroup.controls[control].markAsTouched();
        }
      }
    }
  }

  ngOnInit() {
    this.formGroup = this.initFormGroup();
    this.reactiveForm.ngSubmit.subscribe((data: Event) => {
      this.markAsTouched();
    });

    this.formGroup.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(val => this.update(val));
  }

  public update(val: any) {
    let isValid = false;
    if (this.formGroup.valid && this.formGroup.enabled) {
      isValid = moment(`${val.month + 1}/${val.day}/${val.year}`, 'M/DD/YYYY').isValid();
      if (isValid) {
        this.value = new Date(val.year, val.month, val.day);
        this.errorMessage = undefined;
      } else {
        this.value = undefined;
        this.errorMessage = `That date in time does not exist, please select a valid date`;
      }
    } else if (this.errorMessage) {
      this.errorMessage = this.getErrorMessage();
    } else if (this.value) {
      this.errorMessage = undefined;
      this.value = undefined;
    }
    return isValid;
  }

  markAsTouched() {
    if (!this.formGroup) {
      return;
    }

    if (!this.formGroup.get('day').value) {
      this.formGroup.get('day').markAsTouched();
    }
    if (!this.formGroup.get('month').value) {
      this.formGroup.get('month').markAsTouched();
    }
    if (!this.formGroup.get('year').value) {
      this.formGroup.get('year').markAsTouched();
    }
    if (this.formGroup.invalid) {
      this.errorMessage = this.getErrorMessage();
    }
    this.formGroup.updateValueAndValidity();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  onInputChange = () => {
    this.isChanged.emit(false);
  };


  initFormGroup(): FormGroup {
    return this._formBuilder.group({
      'day': [null, [Validators.required, Validators.min(1), Validators.max(31)]],
      'month': [null, [Validators.required]],
      'year': [null, [Validators.required, Validators.min(this.yearStart), Validators.max(this.yearEnd)]]
    });
  }

  getErrorMessage(): string {
    const errorsArr = [];
    if (this.formGroup.get('day').invalid) {
      errorsArr.push('day');
    }
    if (this.formGroup.get('month').invalid) {
      errorsArr.push('month');
    }
    if (this.formGroup.get('year').invalid) {
      errorsArr.push('year');
    }
    return `Please enter a valid ${errorsArr.join(', ')}`;
  }

  getArrayOfNumbers = (N: number): Array<number> => Array.from(new Array(N), (val, index) => index);

  updateErrors(inputName: string): void {
    if (this.formGroup.get(inputName).errors) {
      this.errorMessage = this.getErrorMessage();
    }
  }

  // ** https://angular.io/api/forms/ControlValueAccessor **

  writeValue(value) {
    if (value instanceof Date) {
      this.value = value;
      this.formGroup.patchValue({
        day: value.getDate(),
        month: value.getMonth(),
        year: value.getFullYear()
      });
    }
  }

  validate(control: AbstractControl) {
    return this.formGroup.valid ? null : { invalidForm: { valid: false, message: 'DateSelectorComponent > formGroup fields are invalid' } };
  }

  registerOnChange(fn) { this._onChange = fn; }

  registerOnTouched(fn) { this._onTouched = fn; }

  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.formGroup.disable({emitEvent: false}) : this.formGroup.enable({emitEvent: false});
  }

  private _onChange = (_: any) => { };
  private _onTouched: any = () => { };
}

import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { Profile } from '@env/routing';
import { ProfilePictureGuard } from '@app/login/guards/profile-picture.guard';
import { verificationSteps, VeriStepRoutes  } from './profile-tenant.constants';
import { TenantAccountVerifyFullComponent } from './bunk-passport-verify/bunk-passport-verify-full.component';
import { TenantAccountProfileComponent } from './bunk-passport-profile/tenant-account-profile.component';
import { IsTenantVerifiedBunkPassportGuard, IsTenantUnVerifiedBunkPassportGuard } from './guards/profile-tenant.guard';

const ProfileTenantRoutes: VeriStepRoutes = [
  {
    path: `${Profile.tenant.bunk_passport.base}`,
    component: TenantAccountProfileComponent,
    canActivate: [ProfilePictureGuard]
  },
  {
    path: `${Profile.tenant.bunk_passport.verify}`,
    component: TenantAccountVerifyFullComponent,
    // canActivate: [IsTenantUnVerifiedBunkPassportGuard],
    children: verificationSteps
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(ProfileTenantRoutes)
  ],
  exports: [
    RouterModule
  ],
  providers: [
    IsTenantVerifiedBunkPassportGuard,
    IsTenantUnVerifiedBunkPassportGuard
  ]
})
export class ProfileTenantRoutingModule {
}

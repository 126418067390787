import { Component, OnInit, Input } from '@angular/core';
import { PropertyModel } from '@app/core-landlord/property/_models/property.model';
import { TitleCasePipe } from '@angular/common';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { UserService, StorageLocalService } from '@app/services';
import { Router } from '@angular/router';
import { Shared } from '@env/routing';
import * as _ from 'lodash';
import {environment} from '@env/environment';
import { TRANSLATIONS } from '@app/_constants/translations.constants';

@Component({
  selector: 'property-small-card',
  templateUrl: './property-small-card.component.html',
  styleUrls: ['./property-small-card.component.scss']
})
export class PropertySmallCardComponent implements OnInit {
  @Input() property: any;
  @Input() bedroomId?: string;
  @Input() imageSize: 'xs'|'sm' | 'md' | 'lg' = 'md';
  @Input() isViewing ? = false;
  public landlordPublic$: Observable<any>;
  public display: any = {};
  public client_data = environment.client_data;
  public rent_label_abbreviation = TRANSLATIONS.rent_label_abbreviation;
  routes = { Shared };
  isDesktop: boolean;
  viewing;
  constructor(
    public _titlecasePipe: TitleCasePipe,
    public _user: UserService,
    private _router: Router,
    protected _localStorage: StorageLocalService,
  ) {}

  ngOnInit(): void {
    const localStore = this._localStorage.getStore('BOOK_VIEWING');
    localStore ? this.viewing = {
      date: new Date(localStore.viewing_date),
      time: new Date(localStore.viewing_time)
    } : null;
    const housePhoto = this.property.property_photos.length ? this.property.property_photos[0].image_large_url : null;

    const city = this._titlecasePipe.transform(this.property.address.city);
    const bedroomInfo = this.property.bedrooms[this.bedroomId];

    const bedroomPhoto = this.bedroomId && bedroomInfo.room_thumbnail_url &&
    bedroomInfo.room_thumbnail_url.image_url !== null ?
    bedroomInfo.room_thumbnail_url.image_large_url : housePhoto;

    const roomMinPcm =  this.property.is_hmo && this.property.landlord_uid === 'R4EGdbwhhlWwF6KyKwmTBTxRXey1' ? (this.property.hmo_min_pcm / 4 ) : this.property.hmo_min_pcm;
    const roomMaxPcm =  this.property.is_hmo && this.property.landlord_uid === 'R4EGdbwhhlWwF6KyKwmTBTxRXey1' ? (this.property.hmo_max_pcm / 4) : this.property.hmo_max_pcm;


    this.display = {
      isHMO: this.property.is_hmo,
      photo:  this.property.is_hmo ? bedroomPhoto : housePhoto,
      firstLineAddress: this.property.address.first_line_address,
      roomName: this.property.is_hmo && this.bedroomId ? this.property.bedrooms[this.bedroomId].room_name : null,


      rent: this.getRentAmount(),

      rentInterval: !this.property.is_hmo ? this.property.rent_interval_count : this.property.is_hmo && this.bedroomId ? this.property.bedrooms[this.bedroomId].rent_interval_count : null,
      rentQtr :  !this.property.is_hmo && this.property.rent_qtr ? this.property.rent_qtr : null,
      roomMin: this.property.is_hmo && this.property.hmo_min_pcm ? parseInt(roomMinPcm, 10).toLocaleString() : undefined,
      roomMax: this.property.is_hmo && this.property.hmo_max_pcm ? parseInt(roomMaxPcm, 10).toLocaleString() : undefined,


      city: city + ', ' + this.property.address.post_code.split(' ')[0],
      landlordUid: this.property.landlord_uid,
      propertyType: this.property.property_type,
      date: this.viewing ? this.viewing.date : null,
      time: this.viewing ? this.viewing.time : null
    };


    this.landlordPublic$ = this._user.getUserById(this.property.landlord_uid).valueChanges().pipe(
      map((landlordPublic: any) => {
        return {
          firstName: landlordPublic.profile_data.first_name,
          uid: landlordPublic.uid,
        };
      })
      );

   }
getRentAmount() {
    switch (true) {
      case !this.property.is_hmo && this.property.rent_interval_count === 1:
        return this.property.household_rent_pcm.toFixed(2);
      case !this.property.is_hmo && this.property.rent_interval_count === 3:
        return this.property.rent_qtr;
      case !this.property.is_hmo && this.property.rent_interval_count === 0:
      return this.property.rent_weekly;
      case this.property.is_hmo && this.bedroomId && this.property.bedrooms[this.bedroomId].rent_interval_count === 3 :
        return this.property.bedrooms[this.bedroomId].rent_qtr;
      case this.property.is_hmo && this.bedroomId && this.property.bedrooms[this.bedroomId].rent_interval_count === 1 :
        return this.property.bedrooms[this.bedroomId].listed_rent_pcm;
      case this.property.is_hmo && this.bedroomId && this.property.bedrooms[this.bedroomId].rent_interval_count === 0 :
        return this.property.bedrooms[this.bedroomId].rent_weekly;
    }


  this.property.is_hmo && this.bedroomId ? this.property.bedrooms[this.bedroomId].listed_rent_pcm
    : this.property.is_hmo && !this.bedroomId ? null : this.property.household_rent_pcm.toFixed(2);
}
  gotoProperty() {
    this._router.navigate(['/' + this.routes.Shared.search, this.property.property_id] , { queryParamsHandling: 'merge' });
  }

  rentFrequency(intervalCount) {
    if (intervalCount === 1) {
      return this.rent_label_abbreviation;
    } else if ( intervalCount === 0) {
      return 'per week';
    } else if ( intervalCount === 3) {
      return 'per quarter'
    }
  }

}

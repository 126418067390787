import { NgModule } from '@angular/core';

import { FormCreateExpensesComponent } from './form-create-expenses.component';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule, MatSelectModule, MatInputModule, MatDatepickerModule } from '@angular/material';
import { InstantNewAlgoliaSearchModule } from '../../_components/searches/instant-new-algolia-search/instant-new-algolia-search.module';
import { FormGetTenancyDetailsModule } from '../form-get-tenancy-details';
import { ProcessingSpinnerModule } from '../../_components';
import { BunkFormFieldModule } from '../form-field-wrapper';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatInputModule,
    MatDatepickerModule,
    FormGetTenancyDetailsModule,
    InstantNewAlgoliaSearchModule,
    ProcessingSpinnerModule,
    BunkFormFieldModule
  ],
  exports: [FormCreateExpensesComponent],
  declarations: [FormCreateExpensesComponent],
  providers: [],
})
export class FormCreateExpensesModule { }

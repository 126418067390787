import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import {environment} from '@env/environment';


import 'hammerjs';

if (environment.production) {
  // window.console.log = function() {};
  // enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(console.log);


import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import {ScrollingModule} from '@angular/cdk/scrolling';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';

import { NavTopComponent } from './topbar';
import { NavSidebarComponent } from './sidebar';
import { CounterComponent } from './counter';
import { NotebarComponent } from './notebar';
import { CircularProgressComponent } from './circular-progress/circular-progress.component';
import { StepperComponent, MobileStepperComponent, DesktopStepperComponent } from './stepper';
import { TruncatePipe } from './pipes/truncate.pipe';

import { UtilsModule } from '@app/core/utils/utils.module';
import {MaterialModule, SharedModule, UserModule} from '@app/shared';
import { NotificationsComponent } from './notifications';
// import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import {ScrollableDirective} from '@app/shared/_directives';
// import { ScrollingModule } from '@angular/cdk/scrolling';

@NgModule({
  declarations: [
    NavTopComponent,
    NavSidebarComponent,
    StepperComponent,
    MobileStepperComponent,
    DesktopStepperComponent,
    CircularProgressComponent,
    CounterComponent,
    TruncatePipe,
    NotebarComponent,
    NotificationsComponent,
    // CdkVirtualScrollViewport
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatIconModule,
    MatSidenavModule,
    MatButtonModule,
    MatMenuModule,
    MatListModule,
    UtilsModule,
    UserModule,
    SharedModule,
    MaterialModule,
    ScrollingModule
  ],
  exports: [
    NavTopComponent,
    NavSidebarComponent,
    StepperComponent,
    MobileStepperComponent,
    DesktopStepperComponent,
    CircularProgressComponent,
    CounterComponent,
    NotebarComponent,
    NotificationsComponent
  ],
  providers: []
})
export class NavigationModule {
  constructor(
    protected _iconRegistry: MatIconRegistry,
    protected _sanitizer: DomSanitizer
  ) {
    _iconRegistry.addSvgIcon(
      'bunk-icon-burger',
      _sanitizer.bypassSecurityTrustResourceUrl('assets/svg/navigation/bunk-icon-burger.svg')
    );
    _iconRegistry.addSvgIcon(
      'icon-close',
      _sanitizer.bypassSecurityTrustResourceUrl('assets/svg/icon-close.svg')
    );
  }
}

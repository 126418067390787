import { Component, HostListener, OnInit, Input, SimpleChanges, OnChanges } from '@angular/core';
import { BUNK_BANK_DETAILS, BUNK_INTERNATIONAL_BANK_DETAILS } from '@app/core-tenant/property/property.constants';
import { TenancyOfferService, TenancyService, UserService } from '@app/services';
import { Observable } from 'rxjs/Observable';
import { PaymentsService } from '@app/services/payments.service';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { catchError, finalize, flatMap, map, switchMap, tap } from 'rxjs/operators';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '@app/core/services';
import { of } from 'rxjs/internal/observable/of';
import { paymentStatus } from '@app/_constants/status.contstants';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '@env/environment';
import { TRANSLATIONS } from '@app/_constants/translations.constants';

@Component({
  selector: 'manual-payments',
  templateUrl: './manual-payments.component.html',
  styleUrls: ['./manual-payments.component.scss']
})

export class ManualPaymentsComponent implements OnInit, OnChanges {
  @Input() reviewingPayment = false;
  @Input() set tenancyId(id: string) {
    this.tenancyId$.next(id);
  }

  public tenancy$: Observable<any>;
  public payoutAccount$: Observable<any>;
  public duePayments$: Observable<any>;
  public overduePayments$: Observable<any>;
  public scheduledPayments$: Observable<any>;
  public partiallyPaid$: Observable<any>;
  public partiallyReceived$: Observable<any>;
  public tenancyAgreement$: Observable<any>;

  public combinedPayments$: Observable<any>;
  public dialogRef;
  public BUNK_INTERNATIONAL_BANK_DETAILS = BUNK_INTERNATIONAL_BANK_DETAILS;
  public isInternational = false;
  public copiedText = '';
  public showBreakdown = false;

  public desktop;
  public isDesktop;

  public showSpinner = false;
  public submittingDataSuccess = false;
  public showRentAmount = false;
  public paymentStatuses = paymentStatus;
  public environment = environment;
  public tenancyId$: BehaviorSubject<string> = new BehaviorSubject('');
  public client_data = environment.client_data;
  public landlord = TRANSLATIONS.landlord;

  @HostListener('document:keydown.escape', ['$event']) private handleKeydown(event: KeyboardEvent) {
    this.closeModal();
  }

  constructor(private _user: UserService,
    private _payment: PaymentsService,
    public _auth: AuthService,
    private _tenancy: TenancyService,
    private _toastr: ToastrService,
    private _router: Router,
    private _tenancyOffer: TenancyOfferService,
    private _activatedRoute: ActivatedRoute) {

  }

  getBankDetails(payout_account: any) {
    const payoutAccount = (payout_account && payout_account.is_read_allowed) ? [
      {
        DETAIL: payout_account.account_holder_name,
        TITLE: 'Account name'
      },
      {
        DETAIL: payout_account.account_number,
        TITLE: 'Account number'
      },
      {
        DETAIL: payout_account.sort_code,
        TITLE: 'Sort code'
      },
    ] : null;

    if (payout_account && payout_account.is_international) {
      payoutAccount.push(
        {
          DETAIL: payout_account.bank_address,
          TITLE: 'Bank Address'
        },
        {
          DETAIL: payout_account.bic,
          TITLE: 'SwiftBic Bank identifier code (BIC)'
        },
        {
          DETAIL: payout_account.iban,
          TITLE: 'International Bank Account Number (IBAN)'
        },
      );
    }

    return payoutAccount ? payoutAccount : !payoutAccount && environment.is_bunk_environment ? BUNK_BANK_DETAILS : null
  }

  getUserReferenceCode(tenants: any) {
    const tenant = tenants.find((ten: any) => ten.tenant_uid === this._auth.currentUserId);
    return (tenant.user_reference_code) ? tenant.user_reference_code : null;
  }

  getDepositPayment(combinedPayments: any) {
    return combinedPayments.find((payments: any) => payments.payment_type === 'deposit');
  }

  removeDepositPayments(combinedPayments: any) {
    return combinedPayments.filter((payments: any) => payments.payment_type === 'rent');
  }

  getPaymentIds(payments: any) {
    return payments.map((payment: any) => payment.payment_id);
  }

  getTotalAmount(currentPayments: any) {
    const amount = ((currentPayments && currentPayments.length)
      ? currentPayments.map((payment: any) => payment.amount_outstanding ? payment.amount_outstanding : payment.amount).reduce((prev: any, curr: any) => prev + curr, 0) : 0);
    return amount.toFixed(2).toLocaleString();
  }
  createPaymentObjects(payments: any) {
    return payments.map((payment: any) => {
      if (payment) {
        return this.getPaymentObject(payment);
      }
      return;
    });
  }

  getPaymentObject(payment: any) {
    return {
      amount: payment.amount_outstanding && payment.amount_outstanding !== 0 ? payment.amount_outstanding.toFixed(2).toLocaleString() : payment.amount.toFixed(2).toLocaleString(),
      amountOutstanding: payment.amount_oustanding ? payment.amount_oustanding : null,
      dueDate: moment(payment.due_date.toDate()).format('DD MMM YYYY'),
      status: paymentStatus[payment.status],
      paymentType: payment.payment_type,
      payment_id: payment.payment_id
    };
  }

  checkIsSameDay(dueDate: any) {
    return moment(dueDate.toDate()).diff(moment().set({
      hours: 0,
      minutes: 0,
      seconds: 0,
      milliseconds: 0
    }), 'day') === 0 ? 'today' : moment(dueDate.toDate()).format('Do MMMM');
  }

  getScheduledPayments(payments: any) {
    const _date = moment(payments[0].due_date.toDate());
    return payments.filter((payment: any) => _date.diff(payment.due_date.toDate(), 'days') === 0);
  }

  getNextPaymentDate(combinedDueOverDue: any, upcomingPayments: any, currentPayments: any, dueDay: any) {
    return (combinedDueOverDue.length && upcomingPayments)
      ? moment(upcomingPayments[0].due_date.toDate())
      : (currentPayments.length) ? this.calculateNextPaymentDate(currentPayments, dueDay)
        : null;
  }

  calculateNextPaymentDate(payments: any, dueDay: number) {
    const sortedPayments = this.sortPayments(payments);
    const lastPayment = sortedPayments[sortedPayments.length - 1];
    const date = new Date(`${lastPayment.month + 1}-${dueDay}-${lastPayment.year}`);
    return moment(date).add(1, 'months');
  }

  getIsTenancyEndDate(tenancyEndDate: any, nextPaymentDue: any) {
    return (nextPaymentDue) ? moment(nextPaymentDue).isSameOrAfter(tenancyEndDate.toDate()) : false;
  }

  sortPayments(payments: any) {
    return payments.sort((a, b) => a.year - b.year || a.month - b.month);
  }

  closeModal() {
    this.dialogRef.close();
    this.isInternational = false;
    this.copiedText = '';
  }

  addQueryParams() {
    this._router.navigate(['./'], { queryParams: { tenant_payment: true }, relativeTo: this._activatedRoute });
  }

  ngOnInit() {
    this.tenancy$ = this.tenancyId$.pipe(
      switchMap((id) => {
        return this._tenancy.getUserTenancyById(id).valueChanges()
      }), tap(tenancy => {
        this.tenancyAgreement$ = this._tenancyOffer.getTenancyAgreementById(tenancy.tenancy_agreement_id).valueChanges();
      })
    )
    this.updatePayment();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.tenancyId) {
      this.updatePayment();

    }
  }
  updatePayment() {

    this.payoutAccount$ = this.tenancy$.pipe(
      flatMap((tenancy: any) => this._payment.getPayoutAccountById(tenancy.payout_account_id)),
      tap((payout: any) => console.log({ payout })),
      catchError(err => of({ err }))
    );
    this.duePayments$ = this._payment.getPaymentsByStatus('due');
    this.overduePayments$ = this._payment.getPaymentsByStatus('overdue');
    this.scheduledPayments$ = this._payment.getPaymentsByStatus('scheduled');
    this.partiallyPaid$ = this._payment.getPaymentsByStatus('partially_paid');
    this.partiallyReceived$ = this._payment.getPaymentsByStatus('partially_received');

    this.combinedPayments$ = combineLatest([this.duePayments$, this.overduePayments$, this.scheduledPayments$, this.partiallyPaid$, this.partiallyReceived$, this.tenancy$, this.payoutAccount$]).pipe(
      map(([duePayments, overduePayments, scheduledPayments, partiallyPaid, partiallyReceived, tenancy, payoutAccount]) => {
        if (!duePayments.length && !overduePayments.length && !scheduledPayments.length) {
          return {
            noPayments: true,
            bank_details: this.getBankDetails(payoutAccount),
            userReferenceCode: this.getUserReferenceCode(tenancy.tenants),
          }

        }
        let combinedDueOverDue = [...overduePayments, ...duePayments, ...partiallyPaid, ...partiallyReceived];
        const depositPay = this.getDepositPayment(combinedDueOverDue);
        combinedDueOverDue = (depositPay && combinedDueOverDue.length > 1) ? this.removeDepositPayments(combinedDueOverDue) : combinedDueOverDue;

        const upcomingPayments = (scheduledPayments.length) ? this.getScheduledPayments(scheduledPayments) : null;
        const currentPay = (combinedDueOverDue.length) ? combinedDueOverDue : upcomingPayments;
        const dueDate = (combinedDueOverDue.length) ? 'today' : this.checkIsSameDay(currentPay[0].due_date);
        const nextPaymentDueDate = this.getNextPaymentDate(combinedDueOverDue, upcomingPayments, currentPay, tenancy.rent_due_day);
        const totalAmount = this.getTotalAmount(currentPay);
        const tenancyEnd = (tenancy.rolling_end_date) ? tenancy.rolling_end_date : tenancy.tenancy_end;
        const isEndOfTenancy = (tenancyEnd && !tenancy.is_rolling)
          ? this.getIsTenancyEndDate(tenancyEnd, nextPaymentDueDate)
          : false;

        const depositPayment = depositPay ? this.getPaymentObject(depositPay) : null;
        const hasDepositAndRent = (depositPay && this.removeDepositPayments(combinedDueOverDue).length >= 1);
        const nextPaymentDue = (nextPaymentDueDate && !isEndOfTenancy) ? nextPaymentDueDate.format('Do MMMM YYYY') : (isEndOfTenancy) ? 'End of tenancy' : null;
        const paymentIds = (hasDepositAndRent) ? this.getPaymentIds([...currentPay, depositPay]) : this.getPaymentIds(currentPay);
        const userReferenceCode = this.getUserReferenceCode(tenancy.tenants);
        const currentPayments = this.createPaymentObjects(currentPay);
        const bank_details = this.getBankDetails(payoutAccount);

        return {
          noPayments: false,
          userReferenceCode,
          dueDate,
          depositPayment,
          hasDepositAndRent,
          nextPaymentDue,
          currentPayments,
          paymentIds,
          totalAmount,
          payoutAccount,
          bank_details
        };
      })
    );
  }
  isUrbanCreation() {
    const urbanCreationId = 'R4EGdbwhhlWwF6KyKwmTBTxRXey1'
    return this._auth.currentUserId === urbanCreationId;
  }
}
import {Component, OnDestroy, OnInit, Renderer2} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { IntercomService, SeoService } from '@app/services';
import { PWAService } from '@app/services/pwa.service';
import { UserService, PropertyService } from '@app/services';
import { AuthService } from '@app/core/services';
import {Observable, of, Subject} from 'rxjs';
import {map, tap} from 'rxjs/operators';
import { NavSidebarItems } from '@app/core/navigation/sidebar';
import { NotificationService } from '@app/chat/services/notification.service';
import {
  Tenant,
  Landlord,
  Shared,
  Profile,
  Notices,
  Account,
  Dashboards,
  Tasks,
  Tenancies,
  Calendar,
  Viewings,
  Documents,
  Payments,
  BankFeed
} from '@env/routing';
import { IUser, IUserGuestModel } from '@app/core/models';
import { AngularFireRemoteConfig } from '@angular/fire/remote-config';
import initHelpHero from 'helphero';
import {environment} from '@env/environment';
import {AngularFireAuth} from '@angular/fire/auth';
import {CookieService} from 'ngx-cookie-service';
import { CompanyName } from './shared/shared.constants';



@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {
  private destroy$: Subject<boolean> = new Subject<boolean>();
  public user$: Observable<any>;
  public userReadOnly$: Observable<any>;
  public chatCount$: Observable<number>;
  public isbunkPassportVersion2$: Observable<boolean>;
  public savePropertiesCount$: Observable<number>;
  public sidebarList$: Observable<NavSidebarItems>;
  public notebarNotificationOpen = false;
  public verificationDialogOpen = false;
  public isDesktop: boolean;
  public navbarList: { id: string, text: string, url: any[] | string, isHidden$?: Observable<boolean> }[];
  public searchSidebarList: NavSidebarItems;
  public livingSidebarList: NavSidebarItems;
  public routes = { Shared, Landlord, Profile, Documents };
  public environment = environment;
  nrla = ['client-nrla', 'client-nrla-staging'];

  // NRLA-50: hide static buttons in NRLA-client and NRLA-staging
  get hideStaticButtons(): boolean {
    return CompanyName.isNrla ? true : false;
  }

  constructor(
    private _router: Router,
    private _intercom: IntercomService,
    private _pwa: PWAService,
    private _user: UserService,
    private _notification: NotificationService,
    private _properties: PropertyService,
    protected _auth: AuthService,
    public readonly remoteConfig: AngularFireRemoteConfig,
    private _seo: SeoService,
    public afAuth: AngularFireAuth,
    public _cookies: CookieService,
    private renderer: Renderer2
  ) {

    // if (environment.firebaseConfig.projectId === 'client-nrla' && !this._cookies.check('BUNKSession')) {
    //   from(this.afAuth.auth.signOut()).pipe(
    //     takeUntil(this.destroy$)
    //   ).subscribe(
    //     () => this._router.navigate(['/'])
    //   );
    // }
    // Seo service to add meta data to routes
    // this._seo.updateSeo();

    // Init intercom (hidden by default)
    this._intercom.initIntercom();

    // On route change update Intercom.hide_default_launcher property depending on url and mobile device
    this._router.events.subscribe(events => {
      if (events instanceof NavigationEnd) {
        const exludeIntercomList = [
          'bunk-passport',
          'inbox',
          'rent-property',
          'tenant/manage/viewings',
          'viewings',
          'tenant/make-an-offer'
        ];
        const hide_default_launcher = exludeIntercomList.some((slug: string) => events.url.includes(slug));
        this._intercom.updateIntercom({ hide_default_launcher });
      }
    });
    // console.log('%c Welcome to BUNK App - %c We are currently looking for awesome talents. Get in touch with us at %cinfo@rentbunk.com',
    //   'font-weight:600; color: #26ADE6;',
    //   'font-weight:600; color: #333;',
    //   'font-weight:400; color: #333; text-decoration: underline;'
    // );
    this._pwa.checkForUpdates();


    /* this.change$ = this.remoteConfig.parameters.pipe(
      filterFresh(172_800_000),
      first(),
      mapToObject({
        // These are iOS specific (and what were set in firebase console settings)...
        maximumShowBunkPassport: 999,
        minimumShowBunkPassport: 0.88,
        minimumVersionSupported: 0.89
      })
    ); */
    // this.change$.subscribe(d => console.log(d));
    // this.remoteConfig.activate();
    // this.remoteConfig.fetchAndActivate();

    /* Zen Desk Chat for NRLA and landlord logged in users */
    this.showZenDeskChat() && this.loadZenDeskJsScript(this.renderer, 'https://static.zdassets.com/ekr/snippet.js?key=eb77cc70-8b41-4f8d-a234-12ce28ad22bb', 'ze-snippet');
  }

  ngOnInit(): void {
    this.userReadOnly$ = this._user.userReadOnly$;
    this.user$ = this._user.user$.pipe(
      tap((user: IUser | IUserGuestModel) => {
        console.log({ InAPPCOM: user });

        if (user) {
          this.chatCount$ = this._notification.getUnreadMessageCount();

          if (environment.client_data.company_name === 'NRLA') {
            this.getHelpHero();
          }

          switch (user.role) {
            case 'tenant':
              this.sidebarList$ = of(this.createSidebarTenant(user.has_tenant_living_dashboard));
              break;

            case 'landlord':
              this.sidebarList$ = of(this.createSidebarLandlord());
              break;
          }
        } else {
          this.sidebarList$ = of([]);
        }
      }),
      map((user: IUser | IUserGuestModel) => {
        return {
          ...user,
          isLandlord: user && user.role === 'landlord',
          isTenant: user && user.role === 'tenant',
          isGuest: user && user.role === 'guest',
          isGuestLandlord: user && user.role === 'guest_landlord'
        };
      }),
    );

    this.isbunkPassportVersion2$ = this._user.bunkPassport$.pipe(
      map((passport: any) => (passport && passport.version === 2))
    );

  }

  createSidebarLandlord(): any {
    this.navbarList = [
      ...!this.nrla.includes(environment.firebaseConfig.projectId) ? [{
        text: 'Profile',
        url: [`/${Profile.landlord.base}/${Profile.landlord.bunk_passport.base}`],
        id: 'button_nav-profile'
      }] : [],
      ...!this.nrla.includes(environment.firebaseConfig.projectId) ? [{
        text: 'Billing',
        url: [`/${Landlord.base}/${Landlord.account.base}/${Landlord.account.billing}`],
        id: 'button_nav-billing'
      }] : [],
      {
        text: 'Feedback',
        url: [`/${Landlord.base}/${Landlord.account.base}/${Landlord.account.feedback}`],
        id: 'button_nav-feedback'
      },
      {
        text: 'Settings',
        url: `/${Account.base}/${Account.settings.base}`,
        id: 'button_nav-settings'
      },
    ];

    return [
      ...!this._user.userDb.has_limited_access || !this._user.userDb.has_limited_access.dashboard ? [{
        svg: 'bunk-icon-home',
        label: 'Home',
        routerLink: `/${Dashboards.base}`,
        id: 'button_nav-home'
      }] : [],
      ...!this._user.userDb.has_limited_access || !this._user.userDb.has_limited_access.properties ? [{
        svg: 'bunk-icon-properties',
        label: 'Properties',
        routerLink: `/${Landlord.base}/${Landlord.properties.base}/${Landlord.properties.summary}`,
        id: 'button_nav-properties'
      }] : [],
      ...!this._user.userDb.has_limited_access || !this._user.userDb.has_limited_access.viewings ? [{
        svg: 'bunk-icon-viewings',
        label: 'Viewings',
        routerLink: `/${Viewings.base}/${Viewings.manage.overview}`,
        id: 'button_nav-viewings'
      }] : [],
      ...!this._user.userDb.has_limited_access || !this._user.userDb.has_limited_access.applications ? [{
        svg: 'bunk-icon-application',
        label: 'Applications',
        routerLink: `/${Landlord.base}/${Landlord.manage.base}/${Landlord.manage.applications.base}`,
        id: 'button_nav-applications'
      }] : [],
      {
        svg: 'bunk-icon-tenancies',
        label: 'Tenancies',
        routerLink: `${Documents.base}`,
        id: 'button_nav-agreements'
      },
      {
        svg: 'bunk-icon-tasks',
        label: 'Tasks',
        routerLink: `/${Tasks.base}`,
        id: 'button_nav-tasks'
      },
      ...!this._user.userDb.has_limited_access || !this._user.userDb.has_limited_access.payments ? [{
        svg: 'bunk-icon-payment',
        label: 'Payments',
        routerLink: `/${Payments.base}`,
        id: 'button_nav-payments'
      }] : [],
      // ...!this._user.userDb.has_limited_access || !this._user.userDb.has_limited_access.payments || !CompanyName.isNrla  ? [{
      //   svg: 'bunk-icon-payment',
      //   label: 'Payments [new]',
      //   routerLink: `/${Payments.base}-new`,
      //   id: 'button_nav-payments'
      // }] : [],
      ...!this._user.userDb.has_limited_access || !this._user.userDb.has_limited_access.maintenance ? [{
        svg: 'bunk-icon-maintenance',
        label: 'Maintenance',
        routerLink: `/${Landlord.base}/${Landlord.manage.base}/${Landlord.manage.maintenance}`,
        id: 'button_nav-maintenance'
      }] : [],
      ...!this._user.userDb.has_limited_access || !this._user.userDb.has_limited_access.notices ? [{
        svg: 'bunk-icon-notices',
        label: 'Announcements',
        routerLink: `/${Notices.base}/${Notices.summary}`,
        id: 'button_nav-notices'
      }] : [],
      {
        svg: 'bunk-icon-chat',
        label: 'Chats',
        routerLink: `/${Shared.chat.inbox}`,
        counterValue: (this._user.userDb.notification_counters) ? this._user.userDb.notification_counters.chat : 0,
        id: 'button_nav-chats'
      },
       {
        svg: 'bunk-icon-calendar',
        label: 'Calendar',
        routerLink: `/${Calendar.base}`,
        id: 'button_nav-calendar'
      },
      {
        svg: 'bunk-icon-tenants',
        label: 'Tenants',
        routerLink: `/${Profile.landlord.base}/${Tenancies.base}/${Tenancies.tenants.base}`,
        id: 'button_nav-tenants'
      },
      ...this._user.userDb.moneyhub_user_id || (this._user.userDb.has_limited_access && this._user.userDb.has_limited_access.bank_feed === false) ? [{
        svg: 'bunk-icon-bank-feed',
        label: 'Bank Feed',
        routerLink: `/${BankFeed.base}`,
        id: 'button_nav-bank-feed'
      }] : [],
    ];
  }

  createSidebarTenant(isLivingDashboard: boolean): any[] {
    this.savePropertiesCount$ = this._properties.getTotalUserLikedProperties();

    const listItemDash = {
      svg: 'bunk-icon-home',
      label: 'Dashboard',
      routerLink: `/${Tenant.base}/${Tenant.manage.base}/${Tenant.manage.my_property.base}/${Tenant.manage.my_property.dashboard}`,
      id: 'button_nav-home'
    };

    const listItemChat = {
      svg: 'bunk-icon-chat',
      label: 'Chats',
      routerLink: `/${Shared.chat.inbox}`,
      counterValue: (this._user.userDb.notification_counters) ? this._user.userDb.notification_counters.chat : 0,
      id: 'button_nav-chats'
    };

    this.navbarList = [
      {
        text: 'Profile',
        url: [`/${Profile.tenant.base}/${Profile.tenant.bunk_passport.base}`],
        id: 'button_nav-profile'
      },
      {
        text: 'Billing',
        url: [`/${Tenant.base}/${Tenant.account.base}/${Tenant.account.billing}`],
        isHidden$: this.isbunkPassportVersion2$,
        id: 'button_nav-billing'
      },
      {
        text: 'Feedback',
        url: [`/${Tenant.base}/${Tenant.account.base}/${Tenant.account.feedback}`],
        id: 'button_nav-feedback'
      },
      {
        text: 'Settings',
        url: `/${Account.base}/${Account.settings.base}`,
        id: 'button_nav-settings'
      },
    ];

    this.searchSidebarList = [
      {
        svg: 'bunk-icon-search',
        label: 'Search',
        routerLink: `/${Tenant.rent_property}`,
        id: 'button_nav-search'
      },
      {
        svg: 'bunk-icon-saved',
        label: 'Saved',
        routerLink: `/${Tenant.base}/${Tenant.manage.base}/${Tenant.manage.liked}`,
        bracketsValue$: this.savePropertiesCount$,
        id: 'button_nav-saved'
      },
      {
        svg: 'bunk-icon-viewings',
        label: 'Viewings',
        routerLink: `/${Tenant.base}/${Tenant.manage.base}/${Tenant.viewing.base}`,
        id: 'button_nav-viewings'
      },
      {
        svg: 'bunk-icon-application',
        label: 'Applications',
        routerLink: `/${Tenant.base}/${Tenant.manage.base}/${Tenant.my_offers}`,
        id: 'button_nav-applications'
      },

    ];

    this.livingSidebarList = environment.firebaseConfig.projectId === 'client-living-way' ? [] :[{
      svg: 'bunk-icon-maintenance',
      label: 'Maintenance',
      routerLink: `/${Tenant.base}/${Tenant.manage.base}/${Tenant.manage.my_property.base}/${Tenant.manage.my_property.maintenance}`,
      id: 'button_nav-maintenance'
    }];

    this.livingSidebarList = [
      ...this.livingSidebarList,
      {
        svg: 'bunk-icon-notices',
        label: 'Notices',
        routerLink: `/${Notices.base}/${Notices.summary}`,
        id: 'button_nav-notices'
      },
      // {
      //   svg: 'document-file',
      //   label: 'Documents',
      //   routerLink: `/${Tenant.base}/${Tenant.manage.base}/${Tenant.manage.my_property.base}/${Tenant.manage.my_property.documents}`,
      //   id: 'button_nav-documents'
      // }
    ];

    return isLivingDashboard ?
      [listItemDash, ...this.livingSidebarList, listItemChat]
      : [...this.searchSidebarList, listItemChat];
  }

  getHelpHero() {
    console.log('HELLO IN HELP HERO');
    const hlp = initHelpHero('D2bxDI30N4');
    if (this._user.userDb) {
      console.log('HELLLOOO ', this._user.userDb.uid);
      console.log(hlp, 'the hlp');
      if (hlp) {
        // hlp.anonymous();
        const user = this._user.userDb;
        const profile_data = user.profile_data ? user.profile_data : 'no profile data';

        hlp.identify(user.uid, {
          role: user.role,
          full_name: profile_data.full_name,
          email: profile_data.email,
          phone: profile_data.country_code &&  profile_data.phone ? profile_data.country_code + profile_data.phone : 'no number',
          account_created: user.account_created
        });
      }

    }
  }
  showAddProperty(isDesktop:boolean, isLandlord:boolean ){
    return isDesktop && isLandlord && !CompanyName.isNrlaProd;
  }
  loadZenDeskJsScript(renderer: Renderer2, src: string, id: string) {
    const script = renderer.createElement('script');
    script.src = src;
    script.id = id;
    renderer.appendChild(window.document.head, script);
  }
  showZenDeskChat(): boolean {
    let loggedInLandlordUserLoggedIn;
    this._user.user$.subscribe(user => {
      loggedInLandlordUserLoggedIn = user && user.role === 'landlord';
    });
    return CompanyName.isNrla && loggedInLandlordUserLoggedIn;
  }
  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}

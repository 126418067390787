import {Component, ContentChild, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {map, mergeMap, scan, tap, throttleTime} from 'rxjs/operators';
import {CdkVirtualScrollViewport} from '@angular/cdk/scrolling';
import {Subject} from 'rxjs';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {Observable} from 'rxjs/internal/Observable';
import {AuthService} from '@app/core/services';
import {AngularFirestore} from '@angular/fire/firestore';

@Component({
  selector: 'infinite-scroll',
  template: `
    <ng-container *ngIf="data$ | async as dataSets">
      <ng-container *ngIf="dataSets && dataSets.length > 0; else noData">
        <cdk-virtual-scroll-viewport itemSize="100" (scrolledIndexChange)="nextBatch($event, (dataSets[dataSets.length - 1])[query.order.key])">
            <ng-container *cdkVirtualFor="let data of dataSets; trackBy: trackByIdx">
            <ng-container *ngTemplateOutlet="templateRef; context: { $implicit: data }"></ng-container>
<!--              THIS BELOW IS FOR END OF SCROLL NOTIFICATIONS ETC-->
              <ng-content></ng-content>
            </ng-container>
          <ng-container *ngIf="loading">
            <div class="flex justify-center margin-top--sm margin-bottom--sm">
              <processing-spinner color="#FFFFFF" [size]="'52px'"></processing-spinner>
            </div>
          </ng-container>
        </cdk-virtual-scroll-viewport>
      </ng-container>
    </ng-container>

    <ng-template #noData>
      <div class="flex justify-center margin-top--sm margin-bottom--sm">
        <p class="text--subtle">You have no notifications.</p>
      </div>
    </ng-template>
  `,
  styleUrls: ['./infinte-scroll.component.scss']
})

export class InfinteScrollComponent implements OnInit {
  @ContentChild(TemplateRef, {static: false}) templateRef;
  @ViewChild(CdkVirtualScrollViewport, {static: false}) viewport: CdkVirtualScrollViewport;
  @Output() emitTheEnd: EventEmitter<any> = new EventEmitter<any>();
  @Input() batch = 8;
  @Input()
  get getScrollQuery(): string[] {
    return this.query;
  }

  set getScrollQuery(data) {
    this.query = data;
    this.initQuery();
  }
  public query;

  protected destroy$: Subject<boolean> = new Subject<boolean>();
  public offset = new BehaviorSubject(null);
  public data$: Observable<any>;

  loading = false;
  theEnd = false;
  constructor(private _auth: AuthService,
              private _afs: AngularFirestore) {
  }

  ngOnInit() {
  }

  initQuery = () => {
    const batchMap = this.offset.pipe(
      throttleTime(500),
      mergeMap((n: any) => this.getBatch(n)),
      scan((acc, batch) => {
        this.loading = false;
        return  (this.offset.value !== 'cleared') ? { ...acc, ...batch } : {};
      }, {})
    );

    this.data$ = batchMap.pipe(map(v => {
      return this.sortArrayDescending(Object.values(v), this.query.order.key);
    }));
  }

  getBatch(offset: string) {
    return this.getFirestoreQuery(offset)
      .snapshotChanges()
      .pipe(
        tap((arr: any) => ((arr.length && arr.length >= this.batch) ? null : this.theEnd = true)),
        map((arr: any) => {
          if (this.theEnd) {
            this.emitTheEnd.emit(true);
          }
          return arr.reduce((acc, cur) => {
            const id = cur.payload.doc.id;
            const data = cur.payload.doc.data();
            return { ...acc, [id]: data };
          }, {});
        })
      );
  }

  nextBatch(e, offset) {
    this.loading = true;
    if (this.theEnd) {
      this.emitTheEnd.emit(true);
      this.loading = false;
      return;
    }

    const end = this.viewport.getRenderedRange().end;
    const total = this.viewport.getDataLength();
    if (end === total) {
      this.offset.next(offset);
    }
  }

  trackByIdx(i) {
    return i;
  }

  getFirestoreQuery = (offset: any) => {
    console.log(this.query);
    return this._afs
      .collection(this.query.collection, ref => {
        let data: any = ref;
        for (const {key, condition, value} of this.query.clauses) {
          data = data.where(key, condition, value);
        }
        return data
          .orderBy(this.query.order.key, this.query.order.condition)
          .limit(this.batch)
          .startAfter((offset) ? offset : '');
      });
  }

  sortArrayDescending = (array: any, item: any) => (array && array.length > 0) ? array.sort((a: any, b: any) =>  b[item].toDate() - a[item].toDate()) : [];
}

<form [formGroup]="formGroup" autocomplete="none" >
  <bunk-form-field [direction]="direction" class="bunk-form-field--sm-margin" [showLabel]="showLabel"
                   [hidden]="roleInput || uniqueCode">
    <label bunk-label for="signup-role">Please select account type</label>
    <mat-form-field bunk-input appearance="outline">
      <mat-select formControlName="role" placeholder="Account type" (selectionChange)="setRole(this.formGroup.get('role').value)">
        <mat-option value="tenant" >I am a Tenant</mat-option>
        <mat-option value="landlord">I am a Landlord</mat-option>
      </mat-select>
    </mat-form-field>
  </bunk-form-field>
  <ng-container  *ngIf="role.value === 'landlord'">
    <bunk-form-field [direction]="direction" class="bunk-form-field--sm-margin" [showLabel]="showLabel">
      <label bunk-label >How many properties do you have?</label>
      <mat-form-field bunk-input appearance="outline">
        <input id="signup-portfolio" matInput type="number" [min]="0" formControlName="number_of_properties_declared" placeholder="How many properties do you have?"
               (blur)="checkSmallTimeLL(this.formGroup.get('number_of_properties_declared').value)">
        <mat-error >Please enter the amount of properties you have</mat-error>
      </mat-form-field>
    </bunk-form-field>

    <ng-container *ngIf="client_data.company_name !== 'NRLA'">
    <div class="block-info block-info--advice" *ngIf="smallTimeLL$.value === 'yes' || smallTimeLL$.value === 'continue' ">
      <h3>Note</h3>
      <span>
        Premium support is only available for subscriptions with 10 properties or more. You can still have email support and access our award-winning software to manage your properties.
        If you are comfortable navigating the platform without full support please continue and we look forward to working with you.
      </span>
    </div>

    <button (click)="continueAsSmallTimeLL()" *ngIf="smallTimeLL$.value === 'yes'" class="btn__generic" type="button">Continue</button>
    </ng-container>
  </ng-container>


  <div [hidden]="role.value === 'landlord' && smallTimeLL$.value === 'yes'">
    <bunk-form-field [direction]="direction" class="bunk-form-field--sm-margin" [showLabel]="showLabel">
      <label bunk-label for="name">What is your name?</label>
      <form-name-row
        bunk-input
        id="name"
        formControlName="name"
        showLabel="true"
        [submitAttempted]="submitAttempted"
      ></form-name-row>
    </bunk-form-field>
    <bunk-form-field [direction]="direction" class="bunk-form-field--sm-margin" [showLabel]="showLabel">
      <label bunk-label for="signup-email">What is your email address?</label>
      <mat-form-field bunk-input appearance="outline">
        <input id="signup-email" matInput type="email" formControlName="email" placeholder="Email" (blur)="checkUserEmail()">
        <mat-error *ngIf="submitAttempted && formGroup.get('email').errors?.required && !formGroup.get('email').errors?.emailExists && !formGroup.get('email').errors.email">Please enter your email</mat-error>
        <mat-error *ngIf="submitAttempted && formGroup.get('email').errors?.email || formGroup.get('email').errors?.email">Please enter a valid email</mat-error>
        <mat-error *ngIf="submitAttempted && formGroup.get('email').errors?.emailExists || formGroup.get('email').errors?.emailExists ">This email already exists</mat-error>
      </mat-form-field>
    </bunk-form-field>
    <bunk-form-field [direction]="direction" class="bunk-form-field--sm-margin" [showLabel]="showLabel">
      <label bunk-label for="signup-email">Please confirm your email address</label>
      <mat-form-field bunk-input appearance="outline">
        <input id="signup-email-confirmation" (blur)="trim('confirm_email')" matInput type="Email" formControlName="confirm_email" placeholder="Confirm email">
        <mat-error>Your emails do not match</mat-error>
      </mat-form-field>
    </bunk-form-field>
    <bunk-form-field [direction]="direction" class="bunk-form-field--sm-margin" [showLabel]="showLabel">
      <label bunk-label for="signup-phone">What is your phone number?</label>
      <form-input-phone
        bunk-input
        id="signup_phone_number_with_area"
        formControlName="signup_phone_number_with_area"
        showLabel="true"
        [submitAttempted]="submitAttempted"
      ></form-input-phone>
    </bunk-form-field>
    <ng-container *ngIf="role.value === 'landlord'">
      <bunk-form-field [direction]="direction" class="bunk-form-field--sm-margin" [showLabel]="showLabel">
        <label bunk-label >How did you hear about us?</label>
        <mat-form-field bunk-input appearance="outline">
          <mat-select formControlName="referral_source" placeholder="How did you hear about us?">
            <mat-option *ngFor="let source of referralSources$ | async" [value]="source.value">
              {{source.view_value}}
            </mat-option>
          </mat-select>
          <mat-error>Please let us know how you heard about us.</mat-error>
        </mat-form-field>
      </bunk-form-field>
    </ng-container>
    <bunk-form-field [direction]="direction" class="bunk-form-field--sm-margin" [showLabel]="showLabel" *ngIf="showPassword">
      <label bunk-label for="signup-phone">Choose a password</label>
      <small bunk-label-stack class="flex">
        <ng-container *ngTemplateOutlet="password"></ng-container>
      </small>
      <div bunk-input class="flex flex-column">
        <mat-form-field appearance="outline">
          <mat-label *ngIf="!showLabel">Password</mat-label>
          <input matInput type="password" formControlName="password" (blur)="trim('password')" id="signup-phone" placeholder="Create password">
          <mat-error *ngIf="submitAttempted && !formGroup.get('password').valid">Please enter a valid password</mat-error>
        </mat-form-field>

      </div>
    </bunk-form-field>

    <p class="margin-top--lg">By creating an account with us you agree to our <a class="btn__hyperlink btn__hyperlink--grey" href="{{client_data.terms_url}}" target="_blank" class="btn__hyperlink">Terms and Conditions</a>. You also agree to our <a href="{{client_data.privacy_policy_url}}" target="_blank" class="btn__hyperlink">Privacy Policy</a>.</p>

    <ng-content></ng-content>

    <button
      (click)="emitForm(this.formGroup.valid)"
    class="btn__generic margin-bottom--lg margin-top--sm btn__form-submit"
    type="submit"> <span *ngIf="!loading" >{{title}}</span>
    <processing-spinner *ngIf="loading" color="#FFFFFF"></processing-spinner>
    </button>


  </div>
  <ng-template #password>
    <ul class="ul-reset password-strength" *ngIf="passwordStrength$ | async as passwordStrength">
      <li>At least 8 characters <span class="status__tick" *ngIf="passwordStrength.hasLength"></span></li>
      <li>Includes a uppercase <span class="status__tick" *ngIf="passwordStrength.hasUppercase"></span></li>
      <li>Includes a lowercase letter <span class="status__tick" *ngIf="passwordStrength.hasLowercase"></span></li>
      <li>Includes a number <span class="status__tick" *ngIf="passwordStrength.hasNumber"></span></li>
    </ul>
  </ng-template>
</form>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormGetPropertyDetailsComponent } from './form-get-property-details.component';
import { ReactiveFormsModule } from '@angular/forms';
import { BunkFormFieldModule } from '../form-field-wrapper';
import { InstantNewAlgoliaSearchModule, BunkOverlayModule, BunkDialogModule } from '../../_components';
import { MatChipsModule, MatFormFieldModule, MatSelectModule, MatIconModule } from '@angular/material';
import { CreateSimplePropertyModule } from '../properties';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    BunkFormFieldModule,
    InstantNewAlgoliaSearchModule,
    MatChipsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatIconModule,
    CreateSimplePropertyModule,
    BunkDialogModule,
    BunkOverlayModule
  ],
  exports: [FormGetPropertyDetailsComponent],
  declarations: [FormGetPropertyDetailsComponent]
})
export class FormGetPropertyDetailsModule { }

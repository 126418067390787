export const Home = '/';

// ##########

export const Auth = {
  signin: 'signin',
  signup: 'signup',
  activate: 'activate',
  resetPassword: 'reset-password'
};

// ##########

export const StyleGuide = {
  base: 'style-guide'
};

// ##########

export const Guarantors = {
  base: 'guarantors'
};

// ##########


export const Profile = {
  base: 'profile',
  tenant: {
    base: 'tenant',
    bunk_passport : {
      base: 'bunk-passport',
      verify: 'verify',
      profile: 'profile',
      intro: 'intro',
      info: 'info',
      search_details: 'search-details',
      employment_details: 'employment-details',
      previous_tenancy: 'previous-tenancy',
      guarantor_details : 'guarantor-details',
      identity: 'identity',
      guarantor: 'guarantor',
      payment: 'payment',
      credit_info: 'credit-info',
      completed: 'completed'
    }

  },
  landlord: {
    base: 'landlord',
    bunk_passport: {
      base: 'bunk-passport',
      intro: 'intro',
      info: 'info',
      identity: 'identity',
      payment: 'payment',
      complete: 'complete',
    }
  }
};

// ##########

export const Pages = {
  blog: 'blog',
  community: 'community',
  team: 'team',
  tenants: 'tenants',
  landlords: 'landlords',
  terms: 'terms-of-use',
  privacy: 'privacy-policy',
};


// ##########

export const Shared = {
  chat: {
    inbox: 'inbox',
    message: 'message'
  },
  search: 'rent-property',
  ie_user: 'ie-user',
  bedroom: 'bedroom',
  no_user: 'no-user'
};

// ##########

export const Landlord = {
  base: 'landlord',
  edit: 'edit',
  list_property_new: 'list-property-new',
  list_property: 'list-property',
  property_type: 'property-list-type',

  properties: {
    base: 'properties',
    summary: 'summary',
    overview: 'overview',
    detail: 'detail',
  },
  tenancies: {
    base: 'tenancies',
    tenants: 'tenants'
  },
  notices: {
    base: 'notices',
    overview: 'overview',
    new: 'new'
  },
  property_list_form: {
    base: 'property-list-new',
    preview: 'preview',
    edit: 'edit',
    address: 'address',
    rooms: {
      base: 'rooms',
      router: 'room',
      tenants_selection: 'add',
      listing: 'listing',
      rent_details: 'rent-details',
    },
    info: 'info',
    pictures: 'pictures',
    confirm: 'confirm',
    checkout: 'checkout',
  },
  manage: {
    base: 'manage',
    applications: {
      base: 'applications',
      offers: {
        base: 'offers',
        manage: {
          base: 'manage'
        }
      },
      references: {
        base: 'references'
      },
      agreement: {
        base: 'agreement',
        select: 'select',
        property: 'property',
        terms: 'terms',
        additional: 'additional'
      },
      summary: 'summary'
    },
    viewings: {
      base: 'viewings',
      overview: 'overview',
      reschedule: 'reschedule'
    },
    properties: {
      base: 'properties',
      overview: 'overview',
    },
    calendar: 'calendar',
    dashboard: 'dashboard',
    // properties: 'properties',
    tenancy: 'tenancy',
    overview: 'overview',
    hmo_overview: 'hmo-overview',
    rent_payments: 'rent-payments',
    tenancy_request: 'tenancy-request',
    manage_tenancy: 'tenancy',
    active_tenancy: 'active-tenancies',
    property_viewing: 'property-viewings',
    maintenance: 'maintenance',
    rent_payments_due: 'due-payments',
    rent_payments_paid: 'paid-payments',
    edit: 'edit'
  },
  account: {
    base: 'account',
    // bunkPassport: 'tenant-account-bunk-passport',
    billing: 'billing',
    // documents: 'documents',
    settings: 'settings',
    feedback: 'feedback',
    invite: 'invite'
  },
};

export const BankFeed = {
  base: 'bank-feed',
  transactions: {
    base: 'transactions'
  }
};

export const Admin = {
  base: 'admin',
  properties: {
    base: 'properties'
  },
  transactions: {
    base: 'transactions'
  },
  payout: {
    base: 'payouts',
    details: 'details'
  },
  general: {
    base: 'general'
  }
};

export const Tenancies = {
  base: 'tenancies',
  tenants: {
    base: 'tenants'
  }
};
export const Tasks = {
  base: 'tasks',
}
export const Calendar = {
  base: 'calendar',
  team_view: 'team-view',
  overview: 'overview'
}
// ##########

export const References = {
  base: 'references',
  verification:'verification',
  summary:'summary'
}

export const Tenant = {
  base: 'tenant',
  edit: 'edit',
  rent_property: 'rent-property',
  manage: {
    base: 'manage',
    liked: 'liked-properties',
    request: 'request',
    my_property: {
      base: 'my-property',
      dashboard: 'dashboard',
      rent_bills: 'rent-bills',
      documents: 'documents',
      maintenance: 'maintenance',
      requests: 'maintenance-requests',
      rent_request: 'rent-request'
    }
  },
  account: {
    base: 'account',
    // bunkPassport: 'tenant-account-bunk-passport',
    billing: 'billing',
    documents: 'documents',
    settings: 'settings',
    feedback: 'feedback',
    verification: {
      base: 'tenant-account-bunk-passport',
      start: 'profile',
      intro: 'intro',
      info: 'info',
      search_details: 'search-details',
      employment_details: 'employment-details',
      guarantor_details : 'guarantor-details',
      identity: 'identity',
      payment: 'payment',
      credit_info: 'credit-info',
      completed: 'completed',
    },
  },
  make_an_offer: {
    base: 'make-an-offer',
    rent: 'rent',
    add_house_mates: 'add-house-mates',
    deposit_type: 'deposit-type',
    move_in: 'move-in',
    summary: 'summary',
    cancel: 'cancel',
    references: {
      base: 'references',
      overview: 'overview',
      landlord: 'landlord',
      employer: 'employer',
      university: 'university',
      guarantors: 'guarantors'
    },
    agreement: {
      base: 'agreement',
      overview: 'overview',
      view: 'view'
    },
    securing_payments: {
      base: 'securing-payments',
      split_payments: 'split-payments'
    }
  },
  viewing: {
    base: 'viewings',
    overview: 'overview',
    reschedule: 'reschedule'
  },
  my_offers: 'my-offers',
  book_viewing: 'book-viewing'
};

// ##########

export const Viewings = {
  base: 'viewings',
  manage: {
    overview: 'overview',
    book: 'book'
  }

};

// ##########

export const Notices = {
    base: 'notices',
    summary: 'summary',
    create: 'create'
};

export const Documents = {
  base: 'tenancies',
  new_tenancy: 'new-tenancy',
  tenancy_agreement: {
    base: 'tenancy-agreement',
    summary: 'summary',
    select: 'select',
    property: 'property',
    terms: 'terms',
    sign: 'sign',
    rent_schedule: 'rent-schedule',
  }
};


// ##########

export const Account = {
  base: 'account',
  settings: {
    base: 'settings',
    notifications: 'notifications'
  },
  feedback: 'feedback',
};

// ##########

export const Dashboards = {
  base: 'dashboard',
  manage: {
    stats: 'stats',
    property_manager: 'manage',
    landlord: 'landlord'
  }
};

// ##########
// ##########

export const Applications = {
  base: 'applications',
  overview: 'overview'
};

// ##########


export const Payments = {
  base: 'payments'
}

import { Injectable } from '@angular/core';
import { AuthService } from '@app/core/services/auth.service';
import { ChatMessageModel } from '@app/chat/models/group.model';
import { UserService } from '@app/services/user.service';
import { switchMap, first, map, skipWhile, timeout } from 'rxjs/operators';
import { firestore } from 'firebase/app';
import { from as fromPromise, Observable, BehaviorSubject, zip, race } from 'rxjs';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { FirebaseApp } from '@angular/fire';
import { of, combineLatest } from 'rxjs';
import { HelpersService } from '@app/shared/_services/helpers.service';
import * as _ from 'lodash';

@Injectable()
export class GroupService {
  public groupsCollection: AngularFirestoreCollection<any>;
  limit = 10;
  index$ = new BehaviorSubject(null);
  queryObservable;

  constructor(
    private readonly afs: AngularFirestore,
    private fb: FirebaseApp,
    private _auth: AuthService,
    private _user: UserService,
    private _helpers: HelpersService
  ) {
    this.groupsCollection = this.afs.collection<any>('groups');
  }

  getGroupById(id: string) {
    return this.groupsCollection.doc(id);
  }

  /*
    Get sets of groups with FireStore 'in' (max 10) - better performance
  */
  private getGroupsByIn(idArr: string[]) {
    return this.afs.collection('groups', ref => ref.where('group_id', 'in', idArr));
  }

  /*
    Creates chunk arrays for batch fetching from Firestore collection with where(_ in _))
    NOTE: where(_ 'in' _) will emit in batches!
    So we need to wait until the final emmission length equals the original idArr length:
   */
  getGroupsFromIds(idArr: string[]): Observable<any> {
    const chunksArr = _.chunk(idArr, 10);
    console.log({chunksArr});
    const arrs: any[] = chunksArr.map((chunk: any) => this.getGroupsByIn(chunk).valueChanges().pipe(
      skipWhile(chunkValueArr => chunkValueArr.length < chunk.length)
    ));

    const zippedArrs = zip(...arrs);
    // In the unlikely case of the chat group existing in Algolia and not Firestore groups collection,
    // Lets fallback after 2 seconds to the available results (from the last emmission)
    // The fallback results WILL NOT match the original idArr.length
    return race(zippedArrs, zippedArrs.pipe(timeout(2000))).pipe(
      first(),
      map((d: any) => d.flat()),
      skipWhile((finalArr: any[]) => finalArr.length < idArr.length)
    );
  }

  getAllGroupsOfCurrentUser() {
    return this._auth.getCurrentUserCustomClaims().pipe(
      switchMap((res: boolean) => {
        return (res && !this._auth.currentUser.displayName)
          ? this.afs.collection('groups', ref => ref.where(`group_id`, '>', '')).valueChanges()
          : this.afs.collection('groups', ref => ref.where(`members_uid.${this._auth.currentUserId}`, '==', 'ACCEPTED')).valueChanges();
      })
    );
  }

  getAllPendingGroupsOfCurrentUser() {
    return this.afs.collection('groups', ref => ref.where(`members_uid.${this._auth.currentUserId}`, '==', 'PENDING'));
  }

  // Returns an observable of all the groups containing both the authenticated user and a second user (with id = user_id)
  getAllGroupsOfCurrentAndOtherUser(user_id: string, property_id: string): Observable<any> {
   return this.afs.collection('groups', ref => ref
        .where(`member_uids.${user_id}`, '==', true)
        .where(`member_uids.${this._auth.currentUserId}`, '==', true)
        .where(`property_id`, '==', property_id)).valueChanges();

  }

  initChatGroup(uid: string, property?: any, tenancy_id?: string): Observable<any> {
    const id = this.afs.createId();
    const date = new Date();
    const members = [this._auth.currentUserId, uid].map((userId: string) => {
      return {
        uid: userId,
        date_added: date,
        date_modified: date,
        is_notified: false
      };
    });
    return fromPromise(this.groupsCollection.doc(id).set({
      group_id: id,
      property_id: property.property_id,
      creator_uid: this._auth.currentUserId,
      tenancy_id: tenancy_id || null, // @todo: need to remove null condition
      member_uids: { [this._auth.currentUserId]: true, [uid]: true },
      last_read_index: { [this._auth.currentUserId]: null, [uid]: null },
      members,
      group_name: property.address.thoroughfare,
      date_created: date,
      date_modified: date,
      date_deleted: null,
      is_deleted: false,
      group_icon_url: (property.property_photos && property.property_photos[0] && property.property_photos[0].image_small_url) ? property.property_photos[0].image_small_url : '',   // @todo group_icon_url
      is_renting: false,    // @ todo is_renting
      team_id: (property.team_id) ? property.team_id : null,
    }).then(() => id));
  }

  updateLastReadIndex(group): Observable<any> {
    const index = group.last_message.index;
      const date = new Date();
      console.log('HEREEEEE');
      return fromPromise(this.groupsCollection.doc(group.group_id).set({
        date_modified: date,
        last_read_index: {
          [this._auth.currentUserId]: index
        }
      }, { merge: true }));
  }

  getCurrentMemberAndGroupMembers(members: any) {
    return {
      current_user: members.find((user: any) => user.uid === this._auth.currentUserId),
      other_users: members.filter((user: any) => user.uid !== this._auth.currentUserId)
    };
  }



  updateLastMessage(group_id: string, message_body: string, message_time: firestore.FieldValue, message_type: string): Observable<any> {
    const docRef = this.afs.collection<any>('groups').doc(group_id).ref;
    return Observable.create(observer => {
      this.fb.firestore().runTransaction(transaction => {
        return transaction.get(docRef).then(doc => {
          if (!doc.exists) {
            observer.error('Document does not exist!');
          }
          const date = new Date();
          const updatedIndex = (doc.data().last_message) ? doc.data().last_message.index + 1 : 0;
          console.log({updatedIndex});
          transaction.set(docRef, {
            date_modified: date,
            last_message: {
              index: updatedIndex,
              message_body,
              sender_name: this._user.userDb.profile_data.full_name,
              first_name: this._user.userDb.profile_data.first_name,
              message_time,
              message_type,
              sender_uid: this._auth.currentUserId
            },
            last_read_index: {
              [this._auth.currentUserId]: updatedIndex
            }
          }, {merge: true});

          observer.next(updatedIndex);
          observer.complete();
        });
      });

    });

  }


  getLatestMessagesOfCurrentGroup(group_id: string) {
    return this.groupsCollection.doc(group_id).collection('messages', ref => {
      return ref.orderBy('index', 'desc');
    });
  }

  submitMessage(group_id: string, message: ChatMessageModel): Observable<any> {
    const item: ChatMessageModel = {
      sender_last_name: this._user.userDb.profile_data.last_name,
      sender_first_name: this._user.userDb.profile_data.first_name,
      sender_name: `${this._user.userDb.profile_data.first_name} ${this._user.userDb.profile_data.last_name}`,
      sender_uid: this._user.userDb.uid,
      sender_image_url: (this._user.userDb.profile_data.profile_image_url) ? this._user.userDb.profile_data.profile_image_url : '/assets/img/icons/user.png',
      message_time: message.message_time,
      message_body: message.message_body,
      index: message.index,
      is_image: message.is_image,
      image_url: message.image_url,
      message_type: message.message_type
    };
    return fromPromise(this.groupsCollection.doc(group_id).collection('messages').add(item));
  }


  searchUsersByName(name: string): AngularFirestoreCollection<any> {
    return this.afs.collection('users_public', ref =>
      ref.where('profile_data.first_name', '==', name)
    );
  }

  searchUsersByEmail(email: string): AngularFirestoreCollection<any> {
    return this.afs.collection('users_public', ref =>
      ref.where('profile_data.email', '==', email)
    );
  }

  getUsersFromGroup(group) {
    const ids = [];

    Object.keys(group.members_uid).forEach((key, index) => {
      ids.push(key);
    });

    const docs = [];
    ids.map(id => {
      docs.push(this._user.getUserById(id).valueChanges());
    });

    return of(docs);
  }
}

import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { combineLatest, from as fromPromise, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as _ from 'lodash';
import { AuthService } from '@app/core/services';

@Injectable({
  providedIn: 'root'
})
export class NotesService {
  public notesCollection: AngularFirestoreCollection<any>;
  constructor(
    public afAuth: AngularFireAuth,
    private readonly afs: AngularFirestore,
    private _auth: AuthService
  ) {
    this.notesCollection = afs.collection<any>('notes');
  }

  getPropertyViewingNotes(property_id:string, viewing_id:string): Observable<any> {
    return this.afs.collection('notes', ref =>
      ref.where('viewing_id', '==', viewing_id)
        .where('property_id', '==', property_id)
        .where('type', '==', 'viewing')
    ).valueChanges() as Observable<unknown[]>;
  }
  getMaintenanceNotes(property_id:string, maintenance_id:string): Observable<any> {
    return this.afs.collection('notes', ref =>
      ref.where('maintenance_id', '==', maintenance_id)
        .where('property_id', '==', property_id)
        .where('type', '==', 'maintenance')
    ).valueChanges() as Observable<unknown[]>;
  }

  getPropertyNotes(property_id:string): Observable<any> {
    return this.afs.collection('notes', ref =>
      ref.where('property_id', '==', property_id)
        .where('type', '==', 'property')
    ).valueChanges() as Observable<unknown[]>;
  }

  getPropertyNotesWithTenancy(property_id:string, tenancy_id:string): Observable<any> {
    return this.afs.collection('notes', ref =>
      ref.where('property_id', '==', property_id)
        .where('tenancy_id', '==', tenancy_id)
        .where('type', '==', 'tenancy')
    ).valueChanges() as Observable<unknown[]>;
  }

  getRoomNotes(property_id:string, bedroom_id:string): Observable<any> {
    return this.afs.collection('notes', ref =>
      ref.where('property_id', '==', property_id)
        .where('bedroom_id', '==', bedroom_id)
        .where('type', '==', 'bedroom')
    ).valueChanges() as Observable<unknown[]>;
  }

  getRoomNotesWithTenancy(property_id:string, bedroom_id:string, tenancy_id:string): Observable<any> {
    return this.afs.collection('notes', ref =>
      ref.where('property_id', '==', property_id)
        .where('bedroom_id', '==', bedroom_id)
        .where('tenancy_id', '==', tenancy_id)
        .where('type', '==', 'tenancy')
    ).valueChanges() as Observable<unknown[]>;
  }

  getTaskNotes(note_ids:Array<string>): Observable<any> {
    let note_id_array = this.splitArray(note_ids,10);
    let queries = note_id_array.map(id_array=>{
      return this.afs.collection('notes', ref => ref
      .where('note_id', 'in', id_array)
      .where(`is_deleted`, '==', false)).valueChanges()
    })
    return this._auth.combineMultipleQueries(queries);
  }

  saveNote(note:any) {
    const temp_id = this.afs.createId();
    const date = new Date();
    return fromPromise(this.afs.collection('notes').doc(temp_id).set({ ...note, note_id: temp_id, date_created: date, date_modified: date, date_deleted: null, is_deleted: false }));
  }
  generateId(){
    return this.afs.createId();
  }
  saveNotesWithCustomId(note:any, id:string) {
    const date = new Date();
    return fromPromise(this.afs.collection('notes').doc(id).set({ ...note, date_created: date, date_modified: date, date_deleted: null, is_deleted: false }));
  }

  updateNote(note:any) {
    const { title, description, note_id, tenancy_id, type, media_ids, note_type } = note;
    const possibleUpdates = { title, description ,media_ids, type, note_type, tenancy_id, date_modified: new Date(), has_edited: true };
    const updationData = {}
    Object.keys(possibleUpdates).forEach(key => possibleUpdates[key] ? updationData[key] = possibleUpdates[key] : '')
    return fromPromise(this.afs.collection('notes').doc(note_id).set(updationData, { merge: true }));
  }

  deleteNote(note_id: string) {
    return fromPromise(this.afs.collection('notes').doc(note_id).set({ date_deleted: new Date(), is_deleted: true }, { merge: true }));
  }



  splitArray(array: Array<any>, limit:number) {
    const result = []
    const givenArray = array.slice(0); // make a copy without reference
    while (givenArray.length > limit) {
      result.push(givenArray.splice(0, limit))
    }
    if (givenArray.length) {
      result.push(givenArray)
    }
    return result;
  }
}

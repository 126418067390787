/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./notifications.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../pipes/truncate.pipe";
import * as i4 from "../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i5 from "@angular/material/icon";
import * as i6 from "@angular/material/menu";
import * as i7 from "@angular/cdk/overlay";
import * as i8 from "@angular/cdk/bidi";
import * as i9 from "@angular/cdk/a11y";
import * as i10 from "../../../../../node_modules/@angular/material/menu/typings/index.ngfactory";
import * as i11 from "../../../shared/_components/infinite-scroll/infinite-scroll.component.ngfactory";
import * as i12 from "../../../shared/_components/infinite-scroll/infinite-scroll.component";
import * as i13 from "../../services/auth.service";
import * as i14 from "@angular/fire/firestore";
import * as i15 from "./notifications.component";
import * as i16 from "../../../services/notifications.service";
import * as i17 from "@angular/router";
import * as i18 from "../../../services/user.service";
var styles_NotificationsComponent = [i0.styles];
var RenderType_NotificationsComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NotificationsComponent, data: {} });
export { RenderType_NotificationsComponent as RenderType_NotificationsComponent };
function View_NotificationsComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "li", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.updateNotificationToRead(_v.context.$implicit.notification_id, _v.context.$implicit.category, _v.context.$implicit.reference_id) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { ngClass: [0, "ngClass"] }, null), i1.ɵpod(3, { "unread": 0 }), (_l()(), i1.ɵeld(4, 0, null, null, 2, "p", [["class", "text--md mb_0"]], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, ["", ""])), i1.ɵppd(6, 1), (_l()(), i1.ɵeld(7, 0, null, null, 3, "p", [["class", "text--subtle text--sm mb_0"]], null, null, null, null, null)), (_l()(), i1.ɵted(8, null, ["", ""])), i1.ɵpad(9, 2), i1.ɵppd(10, 2), (_l()(), i1.ɵeld(11, 0, null, null, 1, "small", [["class", "text--subtle"]], null, null, null, null, null)), (_l()(), i1.ɵted(12, null, ["", ""]))], function (_ck, _v) { var currVal_0 = _ck(_v, 3, 0, !_v.context.$implicit.notification_is_viewed); _ck(_v, 2, 0, currVal_0); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = i1.ɵunv(_v, 5, 0, _ck(_v, 6, 0, i1.ɵnov(_v.parent, 0), _v.context.$implicit.title)); _ck(_v, 5, 0, currVal_1); var currVal_2 = i1.ɵunv(_v, 8, 0, _ck(_v, 10, 0, i1.ɵnov(_v.parent, 1), _v.context.$implicit.body, _ck(_v, 9, 0, 80, "..."))); _ck(_v, 8, 0, currVal_2); var currVal_3 = _co.getMomentTime(_v.context.$implicit); _ck(_v, 12, 0, currVal_3); }); }
function View_NotificationsComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "flex justify-center margin-top--sm margin-bottom--sm"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "p", [["class", "text--subtle"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["You've reached the end of your notifications."]))], null, null); }
export function View_NotificationsComponent_0(_l) { return i1.ɵvid(0, [i1.ɵpid(0, i2.TitleCasePipe, []), i1.ɵpid(0, i3.TruncatePipe, []), i1.ɵqud(671088640, 1, { viewport: 0 }), (_l()(), i1.ɵeld(3, 0, null, null, 22, "div", [["class", "flex justify-end"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 21, "div", [["class", "notifications-nav_header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 1, "div", [["class", "title_3"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Notifications"])), (_l()(), i1.ɵeld(7, 16777216, null, null, 3, "mat-icon", [["aria-haspopup", "true"], ["aria-hidden", "false"], ["aria-label", "Example home icon"], ["class", "cursor-pointer mat-icon notranslate mat-menu-trigger"], ["role", "img"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null], [1, "aria-expanded", 0]], [[null, "mousedown"], [null, "keydown"], [null, "click"]], function (_v, en, $event) { var ad = true; if (("mousedown" === en)) {
        var pd_0 = (i1.ɵnov(_v, 9)._handleMousedown($event) !== false);
        ad = (pd_0 && ad);
    } if (("keydown" === en)) {
        var pd_1 = (i1.ɵnov(_v, 9)._handleKeydown($event) !== false);
        ad = (pd_1 && ad);
    } if (("click" === en)) {
        var pd_2 = (i1.ɵnov(_v, 9)._handleClick($event) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i4.View_MatIcon_0, i4.RenderType_MatIcon)), i1.ɵdid(8, 9158656, null, 0, i5.MatIcon, [i1.ElementRef, i5.MatIconRegistry, [8, "false"], [2, i5.MAT_ICON_LOCATION], [2, i1.ErrorHandler]], null, null), i1.ɵdid(9, 1196032, null, 0, i6.MatMenuTrigger, [i7.Overlay, i1.ElementRef, i1.ViewContainerRef, i6.MAT_MENU_SCROLL_STRATEGY, [2, i6.MatMenu], [8, null], [2, i8.Directionality], i9.FocusMonitor], { menu: [0, "menu"] }, null), (_l()(), i1.ɵted(-1, 0, ["more_vert"])), (_l()(), i1.ɵeld(11, 0, null, null, 14, "mat-menu", [], null, null, null, i10.View__MatMenu_0, i10.RenderType__MatMenu)), i1.ɵdid(12, 1294336, [["list", 4]], 3, i6._MatMenu, [i1.ElementRef, i1.NgZone, i6.MAT_MENU_DEFAULT_OPTIONS], null, null), i1.ɵqud(603979776, 2, { _allItems: 1 }), i1.ɵqud(603979776, 3, { items: 1 }), i1.ɵqud(603979776, 4, { lazyContent: 0 }), i1.ɵprd(2048, null, i6.MatMenu, null, [i6._MatMenu]), i1.ɵprd(2048, null, i6.MAT_MENU_PANEL, null, [i6.MatMenu]), (_l()(), i1.ɵeld(18, 0, null, 0, 3, "button", [["class", "btn btn--sm menu__item mat-menu-item"], ["mat-menu-item", ""]], [[1, "role", 0], [2, "mat-menu-item-highlighted", null], [2, "mat-menu-item-submenu-trigger", null], [1, "tabindex", 0], [1, "aria-disabled", 0], [1, "disabled", 0]], [[null, "click"], [null, "mouseenter"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 19)._checkDisabled($event) !== false);
        ad = (pd_0 && ad);
    } if (("mouseenter" === en)) {
        var pd_1 = (i1.ɵnov(_v, 19)._handleMouseEnter() !== false);
        ad = (pd_1 && ad);
    } if (("click" === en)) {
        var pd_2 = (_co.clearNotifications() !== false);
        ad = (pd_2 && ad);
    } return ad; }, i10.View_MatMenuItem_0, i10.RenderType_MatMenuItem)), i1.ɵdid(19, 180224, [[2, 4], [3, 4]], 0, i6.MatMenuItem, [i1.ElementRef, i2.DOCUMENT, i9.FocusMonitor, [2, i6.MAT_MENU_PANEL]], null, null), (_l()(), i1.ɵeld(20, 0, null, 0, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Mark all as read"])), (_l()(), i1.ɵeld(22, 0, null, 0, 3, "button", [["class", "btn btn--sm menu__item mat-menu-item"], ["mat-menu-item", ""]], [[1, "role", 0], [2, "mat-menu-item-highlighted", null], [2, "mat-menu-item-submenu-trigger", null], [1, "tabindex", 0], [1, "aria-disabled", 0], [1, "disabled", 0]], [[null, "click"], [null, "mouseenter"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 23)._checkDisabled($event) !== false);
        ad = (pd_0 && ad);
    } if (("mouseenter" === en)) {
        var pd_1 = (i1.ɵnov(_v, 23)._handleMouseEnter() !== false);
        ad = (pd_1 && ad);
    } if (("click" === en)) {
        var pd_2 = (_co.clearNotifications(true) !== false);
        ad = (pd_2 && ad);
    } return ad; }, i10.View_MatMenuItem_0, i10.RenderType_MatMenuItem)), i1.ɵdid(23, 180224, [[2, 4], [3, 4]], 0, i6.MatMenuItem, [i1.ElementRef, i2.DOCUMENT, i9.FocusMonitor, [2, i6.MAT_MENU_PANEL]], null, null), (_l()(), i1.ɵeld(24, 0, null, 0, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Clear all notifications"])), (_l()(), i1.ɵeld(26, 0, null, null, 5, "infinite-scroll", [], null, [[null, "emitTheEnd"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("emitTheEnd" === en)) {
        var pd_0 = (_co.hasEnded($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i11.View_InfinteScrollComponent_0, i11.RenderType_InfinteScrollComponent)), i1.ɵdid(27, 114688, null, 1, i12.InfinteScrollComponent, [i13.AuthService, i14.AngularFirestore], { batch: [0, "batch"], getScrollQuery: [1, "getScrollQuery"] }, { emitTheEnd: "emitTheEnd" }), i1.ɵqud(603979776, 5, { templateRef: 0 }), (_l()(), i1.ɵand(0, [[5, 2]], 0, 0, null, View_NotificationsComponent_1)), (_l()(), i1.ɵand(16777216, [[5, 2]], 0, 1, null, View_NotificationsComponent_2)), i1.ɵdid(31, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 8, 0); var currVal_3 = i1.ɵnov(_v, 12); _ck(_v, 9, 0, currVal_3); _ck(_v, 12, 0); var currVal_16 = 8; var currVal_17 = _co.queryData; _ck(_v, 27, 0, currVal_16, currVal_17); var currVal_18 = _co.theEnd; _ck(_v, 31, 0, currVal_18); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 8).inline; var currVal_1 = (((i1.ɵnov(_v, 8).color !== "primary") && (i1.ɵnov(_v, 8).color !== "accent")) && (i1.ɵnov(_v, 8).color !== "warn")); var currVal_2 = (i1.ɵnov(_v, 9).menuOpen || null); _ck(_v, 7, 0, currVal_0, currVal_1, currVal_2); var currVal_4 = i1.ɵnov(_v, 19).role; var currVal_5 = i1.ɵnov(_v, 19)._highlighted; var currVal_6 = i1.ɵnov(_v, 19)._triggersSubmenu; var currVal_7 = i1.ɵnov(_v, 19)._getTabIndex(); var currVal_8 = i1.ɵnov(_v, 19).disabled.toString(); var currVal_9 = (i1.ɵnov(_v, 19).disabled || null); _ck(_v, 18, 0, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9); var currVal_10 = i1.ɵnov(_v, 23).role; var currVal_11 = i1.ɵnov(_v, 23)._highlighted; var currVal_12 = i1.ɵnov(_v, 23)._triggersSubmenu; var currVal_13 = i1.ɵnov(_v, 23)._getTabIndex(); var currVal_14 = i1.ɵnov(_v, 23).disabled.toString(); var currVal_15 = (i1.ɵnov(_v, 23).disabled || null); _ck(_v, 22, 0, currVal_10, currVal_11, currVal_12, currVal_13, currVal_14, currVal_15); }); }
export function View_NotificationsComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "notifications", [], null, null, null, View_NotificationsComponent_0, RenderType_NotificationsComponent)), i1.ɵdid(1, 180224, null, 0, i15.NotificationsComponent, [i16.NotificationsService, i17.Router, i18.UserService, i14.AngularFirestore, i13.AuthService], null, null)], null, null); }
var NotificationsComponentNgFactory = i1.ɵccf("notifications", i15.NotificationsComponent, View_NotificationsComponent_Host_0, {}, { closeNotifications: "closeNotifications", openVerificationDialog: "openVerificationDialog" }, []);
export { NotificationsComponentNgFactory as NotificationsComponentNgFactory };

import {Injectable} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {UserService} from '@app/services/user.service';
import {Observable, Subject, of, combineLatest} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {AuthService} from '@app/core/services';
import {TenancyService} from '@app/services/tenancy.service';
import {fromPromise} from 'rxjs/internal-compatibility';
import { environment } from '@env/environment';
import { HttpClient } from '@angular/common/http';
import {Landlord, Payments} from '@env/routing';
import {Router} from '@angular/router';
import * as moment from 'moment';

@Injectable()
export class PaymentsService {
  constructor(private afs: AngularFirestore,
              private _user: UserService,
              private _auth: AuthService,
              private _tenancy: TenancyService,
              private _router: Router,
              private _http: HttpClient) {

  }

  get poId(): string {
    return this._user.userDb.payments_data.payments_overview_id;
  }

  get hasUsedIncentive(): boolean {
    return this._user.userDb.payments_data.incentive_used;
  }

  routeToPayments(params: any = null) {
    console.log({params});
      return this._router.navigate(['/' + Payments.base], {queryParams: params});
  }

  markPaymentsAsNotifyPaid(payment_ids: any) {
    return this._http.post(environment.firebaseConfig.apiUrl + '/payments-markPaymentsAsNotifyPaid', {payment_ids});
  }

  updatePaymentStatus(payment_id: string, date_paid: any, status: string, notes: string, partially_paid_amount?: number) {
    console.log(status, 'CF');
    return this._http.post(environment.firebaseConfig.apiUrl + '/payments-updatePaymentStatus', {payment_id, date_paid, status, notes, partially_paid_amount});
  }

  adminUpdatePayment(payment_id: string, due_date: any, amount: number, notes: any, is_being_held: boolean = false) {
    console.log({is_being_held});
    return this._http.post(environment.firebaseConfig.apiUrl + '/payments-adminUpdatePayment', {payment_id, due_date, notes, amount, is_being_held});
  }
  userCreatePayment(data: any) {
    return this._http.post(environment.firebaseConfig.apiUrl + '/payments-userCreatePayment', {...data, due_date: moment(data.due_date), timestamp_paid: (data.timestamp_paid) ? moment(data.timestamp_paid) : null});
  }

  userCreateMultiplePayment(property_id: string, bedroom_id: string, payment_type: string, dueMonth: Date) {
  const unit_id = bedroom_id || property_id ;
  const unit_query = bedroom_id ? "bedroom_id" : "property_id"
    return this.afs.collection('payments',(ref) =>
       ref
          .where(unit_query, '==',unit_id)
          .where('payment_type', '==', payment_type)
          .where(`receiver_uid`, '==', this._auth.currentUserId)
          .where(`month`, '==', dueMonth)
       )  ;
  }

  getAllPaymentsByLandlordId(): Observable<any> {
    const start = new Date('1970-01-01');
    //
    // const teamQuery = this._user.userReadOnlyDb.team_ids
    //   ? this.afs.collection('payments', ref => ref
    //     .where('team_id', 'in', this._user.userReadOnlyDb.team_ids)
    //     .orderBy('due_date', 'desc')
    //   ).valueChanges()
    //   : of([]);
    //
    // const landlordQuery = this.afs.collection('payments', ref => ref
    //   .where('receiver_uid', '==', this._auth.currentUserId).orderBy('due_date', 'desc')
    // ).valueChanges();
    //
    //
    // return this._auth.getCurrentUserCustomClaims().pipe(
    //   switchMap((res: boolean) => {
    //     return (res && !this._auth.currentUser.displayName)
    //       ? this.afs.collection('payments', ref => ref.where('due_date', '>', start)
    //         .orderBy('due_date', 'desc')).valueChanges()
    //       : this._auth.combineQueries(teamQuery, landlordQuery);
    //   })
    // );

    return this._auth.getCurrentUserCustomClaims().pipe(
      switchMap((res: boolean) => {
        return (res && !this._auth.currentUser.displayName)
          ? this.afs.collection('payments', ref => ref.where('due_date', '>', start).orderBy('due_date', 'desc')).valueChanges()
          : this.afs.collection('payments', (ref) => ref.where(`receiver_uid`, '==', this._auth.currentUserId).orderBy('due_date', 'desc')).valueChanges();
      })
    );

  }

  addPayoutBank(payout_bank_details: any) {
    return this._http.post(environment.firebaseConfig.apiUrl + '/payments-addPayoutBank', {payout_bank_details, uid: this._auth.currentUserId});
  }

  getPayoutAccountById(payout_account_id) {
    console.log(payout_account_id, 'in service');
    return this.afs.collection('payout_accounts').doc(payout_account_id).valueChanges();
  }



  getPaymentsByTenancyIdAndUserId(tenancyId, userId) {
    return this.afs.collection('payments', (ref) =>
      ref
        .where('sender_uid', '==', userId)
        .where('tenancy_id', '==', tenancyId)
        .where('payment_type', '==', 'rent')
        .orderBy('year', 'asc')
        .orderBy('due_date', 'asc')
        .orderBy('month', 'asc')
    );
  }

  getPaymentsByTenancyId(tenancyId) {
    return this.afs.collection('payments', (ref) =>
      ref
        .where('tenancy_id', '==', tenancyId)
        .where('payment_type', '==', 'rent')
        .orderBy('year', 'asc')
        .orderBy('due_date', 'asc')
        .orderBy('month', 'asc')
    );
  }
  getPaymentsByReferenceCode(code: string) {
    return this.afs.collection('payments', (ref) =>
      ref
        .where('user_reference_code', '==', code)
        .where('status', 'in', ['partially_paid', 'partially_received', 'due', 'overdue', 'scheduled'])
        .orderBy('year', 'asc')
        .orderBy('due_date', 'asc')
        .orderBy('month', 'asc')
    );
  }

  listCustomerSubscriptions(): Observable<any> {
    return this._user.user$
      .pipe(
        map(user => user.payments_data.payments_overview_id),
        switchMap(poId => {
          if (poId) {
            return this.afs
              .collection('payments_overview')
              .doc(poId)
              .collection('subscriptions', ref => ref.orderBy('status', 'asc'))
              .valueChanges();
          } else {
            return of([]);
          }
        })
      );
  }

  getCurrentUserPaymentsOverviewDoc() {
    const poId = this._user.userDb.payments_data.payments_overview_id;
    return this.afs
      .collection('payments_overview')
      .doc(poId)
      .valueChanges();
  }

  getUserDepositPayments() {
    return this.afs.collection('payments', ref =>
      ref
        .where('sender_uid', '==', this._auth.currentUserId)
        .where('payment_type', '==', 'deposit')
        .where('tenancy_id', '==', this._tenancy.getIdCurrentTenancy())
    ).valueChanges()
      .pipe(
        map((payments: any) => {
          return payments.filter(payment => payment.status === 'due' || payment.status === 'notified_paid');
        })
      );
  }

  getPaymentsByStatus(status: string, isDeleted:boolean = false): Observable<any> {

    return this.afs.collection('payments', ref =>
      ref
        .where('sender_uid', '==', this._auth.currentUserId)
        .where('is_deleted', '==', isDeleted)
        .where('status', '==', status)
        .where('tenancy_id', '==', this._tenancy.getIdCurrentTenancy())
        .orderBy('due_date', 'asc')).valueChanges();
  }

  getUserDuePayments(tenancyId, userId): Observable<any> {
    const paymentsRef = this.afs.collection('payments').ref;

    // query all due payments
    const dueRef = paymentsRef
      .where('sender_uid', '==', userId)
      .where('tenancy_id', '==', tenancyId)
      .where('status', '==', 'due')
      .where('payment_type', '==', 'rent')
      .orderBy('due_date', 'desc')
      .limit(36);

    // query all overdue payments
    const overdueRef = paymentsRef
      .where('sender_uid', '==', userId)
      .where('tenancy_id', '==', tenancyId)
      .where('status', '==', 'overdue')
      .where('payment_type', '==', 'rent')
      .orderBy('due_date', 'desc')
      .limit(36);

    const scheduledRef = paymentsRef
      .where('sender_uid', '==', userId)
      .where('tenancy_id', '==', tenancyId)
      .where('status', '==', 'scheduled')
      .where('payment_type', '==', 'rent')
      .orderBy('due_date', 'desc')
      .limit(36);

    const notifiedPaidRef = paymentsRef
      .where('sender_uid', '==', userId)
      .where('tenancy_id', '==', tenancyId)
      .where('status', '==', 'notified_paid')
      .where('payment_type', '==', 'rent')
      .orderBy('due_date', 'desc')
      .limit(36);




    const due$ = new Subject(); // initiate empty due$ observable
    const overdue$ = new Subject(); // initiate empty overdue$ observable
    const scheduled$ = new Subject(); // initiate empty scheduled$ observable
    const notifiedPaid$ = new Subject(); // initiate empty scheduled$ observable


    // map docs data and send it to observables
    dueRef.onSnapshot((snapshot) => {
      const data = snapshot.docs.map((d) => d.data());
      due$.next(data);
    });

    overdueRef.onSnapshot((snapshot) => {
      const data = snapshot.docs.map((d) => d.data());
      overdue$.next(data);
    });

    scheduledRef.onSnapshot((snapshot) => {
      const data = snapshot.docs.map((d) => d.data());
      scheduled$.next(data);
    });

    notifiedPaidRef.onSnapshot((snapshot) => {
      const data = snapshot.docs.map((d) => d.data());
      notifiedPaid$.next(data);
    });


    // combine due$, overdue$ and scheduled$ into a single stream
    return combineLatest([due$, overdue$, scheduled$, notifiedPaid$])
      .pipe(
        switchMap(
          (payments: any) => {
            const [due, overdue, scheduled, notifiedPaid] = payments;
            const combined = [...overdue, ...due, ...scheduled, ...notifiedPaid];
            const sorted = this.sortPaymentsByDate(combined, 'asc');
            return of(sorted);
          })
      );
  }

  getUserPricing(): Observable<any> {
    const pricingColRef = this.afs.collection('pricing');
    return pricingColRef.doc(this._user.userDb.payments_data.pricing_tier).valueChanges();
  }

  getAvailableCoupons() {
    return this.afs.collection('coupons', ref =>
      ref.where('type', '==', 'landlord_percent_off'));
  }

  getUserCoupons(): any {
    let couponIds = [];
    if (this._user.userDb.payments_data.coupons) {
      couponIds = this._user.userDb.payments_data.coupons.map(item => item.coupon_id);
    }
    const array = [];
    for (const id of couponIds) {
      const user$: Observable<any> = this._user.user$
        .pipe(map(data => data.payments_data.coupons.filter(item => item.coupon_id === id)));
      const coupon$: Observable<any> = this.afs.collection('coupons').doc(id).valueChanges();
      const combined: Observable<any[]> = combineLatest(user$, coupon$)
        .pipe(
          map(([obs1, obs2]: any) => [...obs1, obs2]), // Flatten array emitted by observable 1 into an object. E.G. [[{}], {}] ===> [{}, {}]
          map(([obs1, obs2]) => [Object.assign(obs1, obs2)]), // Merge two objects into a single one with all the properties from the two. E.G. [{}, {}] ===> [{}]
          map(([obs]) => obs) // Take out object from array. E.G. [{}] ===> {}
        );
      array.push(combined);
    }
    return combineLatest(array);
  }

  sortPaymentsByDate(payments: any[], order: 'asc' | 'desc') {
    const multiplier = order === 'asc' ? 1 : -1;
    payments.sort((a: any, b: any) => {
      if (a.year > b.year) {
        return multiplier; // (1 * multiplier)
      }
      if (a.year < b.year) {
        return -1 * multiplier;
      }
      if (a.year === b.year) {
        if (a.month > b.month) {
          return multiplier;
        } else {
          return -1 * multiplier;
        }
      }
    });
    return payments;
  }

  getPricingDocument(pricing_plan: string): Observable<any> {
    const pricingCollectionRef = this.afs.collection('pricing');
    return pricingCollectionRef.doc(pricing_plan).valueChanges();
  }

  getAllDuePayments(userId: string, tenancyId: string, dueStatuses: any) {
    return this.afs.collection('payments', ref =>
      ref
        .where('sender_uid', '==', userId)
        .where('tenancy_id', '==', tenancyId)
        .where('status', 'in', dueStatuses)
        .where('payment_type', '==', 'rent')
        .orderBy('due_date', 'desc')).valueChanges();

  }

  getPaymentById(id: string ) {
    return this.afs.collection('payments').doc(id);
  }

  adminDeletePayment(item: any) {
    console.log(item, 'ITEM  in CF');
    return this._http.post(environment.firebaseConfig.apiUrl + '/payments-adminDeletePayment', {...item});

  }

  getAllPaymentsByTenancyOfferId(tenancy_offer_id: string) {
    if(this._user.userDb.role === 'tenant'){
      return this.afs.collection('payments', (ref) =>
      ref.where('sender_uid', '==', this._auth.currentUserId)
     .where('tenancy_offer_id', '==', tenancy_offer_id)
    .where('is_deleted', '==', false)
      );
    }
    else if(this._user.userDb.role === 'landlord'){
      return this.afs.collection('payments', (ref) =>
      ref.where('tenancy_offer_id', '==', tenancy_offer_id)
    .where('is_deleted', '==', false)
   );
    }

  }


  getAllUserPayouts(): Observable<any> {
    const teamQuery = this._user.userReadOnlyDb.team_ids
      ? this.afs.collection('payout_accounts', ref => ref
        .where('team_id', 'in', this._user.userReadOnlyDb.team_ids)).valueChanges()
      : of([]);

    const landlordQuery = this.afs.collection('payout_accounts', ref => ref
      .where('landlord_uid', '==', this._auth.currentUserId)).valueChanges();

    return this._auth.getCurrentUserCustomClaims().pipe(
      switchMap((res: boolean) => {
        return (res && !this._auth.currentUser.displayName)
          ? this.afs.collection('payout_accounts', ref => ref).valueChanges()
          : this._auth.combineQueries(teamQuery, landlordQuery);
      })
    );
  }

  getPayoutById(id: string ) {
    return this.afs.collection('payout_account').doc(id);
  }
}

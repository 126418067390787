import { Component } from '@angular/core';
import { TRANSLATIONS } from '@app/_constants/translations.constants';
import {environment} from '@env/environment';

@Component({
  selector: 'sample-tenancy-agreement',
  template: `
    <section class="doc">
      <div class="doc__header">
        <h1>{{client_data.company_name}} • Tenancy Agreement</h1>

        <div class="wp-block-image">
          <figure class="aligncenter is-resized"><img
              src="https://rentbunk.com/wp-content/uploads/2019/12/BunkForSignatureNavy-1-728x641.png" alt=""
              class="wp-image-1725" width="182" height="160"
              srcset="https://rentbunk.com/wp-content/uploads/2019/12/BunkForSignatureNavy-1-728x641.png 728w, https://rentbunk.com/wp-content/uploads/2019/12/BunkForSignatureNavy-1-227x200.png 227w, https://rentbunk.com/wp-content/uploads/2019/12/BunkForSignatureNavy-1-614x540.png 614w, https://rentbunk.com/wp-content/uploads/2019/12/BunkForSignatureNavy-1-768x676.png 768w, https://rentbunk.com/wp-content/uploads/2019/12/BunkForSignatureNavy-1.png 876w"
              sizes="(max-width: 182px) 100vw, 182px">
            <figcaption><br></figcaption>
          </figure>
        </div>
        <h2>Assured Shorthold Tenancy Agreement</h2>
      </div>

      <div class="container-s">
        <div class="fcol">
          <p>Within the meaning of the Housing Act 1988, as amended by the Housing Act 1996. </p>



          <p>This Agreement contains the terms of the Tenancy. It sets out the promises made between you (the
            “Tenant”) and us (the “{{landlord | titlecase}}”). These promises will be legally binding once this Agreement has been
            both signed and dated and the initial funds outlined in this Agreement have been paid. You should read
            it carefully to ensure it contains everything you want and nothing that you are not prepared to agree
            to. </p>



          <p><strong>You understand that we will be entitled to recover possession when the Tenancy ends.</strong></p>



          <p>The Inventory and Schedule of Condition should be checked carefully and agreed with either us or the
            property manager – if applicable. Please note that if the Inventory and Schedule of Condition are not
            agreed or challenged by you in writing within seven days of the start of the Tenancy, or receiving the
            Inventory and Schedule of Condition (whichever is later), then we will take it that you have accepted
            the Inventory and Schedule of Condition as fair and accurate and that we may rely on them at the end of
            the Tenancy as being correct and accurate. If you do not understand this Agreement, or anything in it,
            it is strongly suggested that you ask for an explanation before signing it. You might consider
            consulting a solicitor, Citizen’s Advice Bureau or Housing Advice Centre.</p>



          <h4><strong>This Agreement is between us, the {{landlord}}:</strong></h4>



          <p class="has-text-color has-very-dark-gray-color"><em>Example {{landlord}}</em></p>



          <h4><strong>And you, the Tenant(s) (individually and together):</strong></h4>



          <p class="has-text-color has-very-dark-gray-color"><em>Example Tenant</em></p>



          <h4><strong>And is made in relation to the Property at: </strong></h4>



          <p class="has-text-color has-very-dark-gray-color"><em>Example Address</em></p>



          <h4>Our contact details:</h4>



          <p>In accordance with Sections 47 and 48 of the Landlord and Tenant Act 1987 the Landlords’s name and address
            in England and Wales at which notices (including Notices of Proceedings) may be served on the Landlord
            by you are:</p>



          <p class="has-text-color has-very-dark-gray-color"><em>Example {{landlord | titlecase}}</em></p>



          <p class="has-text-color has-very-dark-gray-color"><em>Example {{landlord | titlecase}} Address</em></p>



          <h4><strong>Number of Permitted Occupiers</strong></h4>



          <p>The maximum number of people permitted to occupy the Property within the Terms of the Agreement is:
            <em>Example Maximum Occupiers</em></p>



          <h4><strong>Term</strong></h4>



          <p>A fixed term of example tenancy length commencing on and including <em>Example Tenancy Start Date
            </em>and ending on <em>Example Tenancy End Date</em>. </p>



          <h4><strong>Rent</strong></h4>



          <p>You agree to pay the total Rent of {{client_data.currency_symbol}}<em>Example Rent Amount</em> per calendar month in​ advance in the
            following instalments. The first payment of {{client_data.currency_symbol}}<em>Example Rent Amount</em> ​​is to be paid on or before 1
            January 2021. Subsequent payments of {{client_data.currency_symbol}}<em>Example Rent Amount</em> ​​are to be paid by the 1st of each​
            month​.</p>



          <h4><strong>Accepted Payment Method</strong></h4>



          <p>Payments to be made in advance, through the {{client_data.company_name}} platform. In the event that the {{landlord}} cancels their
            account with {{client_data.company_name}} then payments are to be made via standing order or direct bank transfer to the
            {{landlord | titlecase}}’s bank account.</p>



          <h4><strong>Deposit</strong></h4>



          <p>The Tenant must also pay a deposit of {{client_data.currency_symbol}}<em>Example Deposit Amount</em> to the {{landlord | titlecase}} to be held as a
            security for any breach of any of the Tenant’s obligations in this agreement. The {{landlord | titlecase}} will protect
            this money with a government approved tenancy deposit protection scheme (TDSL) within 30 days of
            receipt. </p>



          <h4><strong>Utilities and other relevant suppliers</strong></h4>



          <p><strong>You and we agree: </strong></p>



          <p><strong>Water Charges: </strong><em>The {{landlord | titlecase}}/Tenant</em> <em>(example)</em> is responsible for
            paying.</p>



          <p><strong>Council Tax (or similar charge which replaces it):</strong> <em>The {{landlord | titlecase}}/Tenant</em>
            <em>(example)</em><strong> </strong>is responsible for paying.</p>



          <p><strong>Gas: </strong><em>The {{landlord | titlecase}}/Tenant</em> <em>(example)</em> is responsible for paying.</p>



          <p><strong>Electricity:</strong> <em>The {{landlord | titlecase}}/Tenant</em> <em>(example)</em> is responsible for paying.
          </p>



          <p><strong>Television License: </strong><em>The {{landlord | titlecase}}/Tenant</em> <em>(example)</em><strong> </strong>is
            responsible for paying.</p>



          <p><strong>Wifi/Internet:</strong> <em>The {{landlord | titlecase}}/Tenant</em> <em>(example)</em> is responsible for
            paying.</p>



          <h4><strong>The Property is let together with the use of the:</strong></h4>



          <p>Contents as specified in the Inventory and Schedule of Condition.</p>



          <p><em>Example Garden Access.</em></p>



          <p><em>Example Car Parking Access</em></p>



          <h2>Special terms</h2>



          <p><em>Example of Special Term – “The Tenants will not be allowed access to the garage”.</em></p>



          <h2>Definitions</h2>



          <p>“<strong>Agent</strong>” means the company or person who has been engaged by us to manage the Property on
            our behalf, or anyone who subsequently takes over the rights and obligations of our Agent. </p>



          <p>“<strong>Contents</strong>” means anything provided by us as stated in the Inventory including but not
            limited to white goods, furniture, cutlery, utensils, implements, tools, equipment or the Fixtures and
            Fittings. </p>



          <p>“<strong>Emergency</strong>” means where there is a risk to life or damage to the fabric of the Property
            or the Contents. </p>



          <p>“<strong>Fixtures and Fittings</strong>” includes references to any fixtures, fittings, furnishings,
            effects, floor, ceiling or wall coverings. </p>



          <p>“<strong>Gas Safety Certificate</strong>” means a gas safety record within the meaning of the Gas Safety
            (Installation and Use) Regulations 1998 (or similar regulations which amend it). “GDPR” means the EU
            General Data Protection Regulation 206/6769. </p>



          <p>“<strong>Head Lease</strong>” sets out the promises we have made to our superior {{landlord}}, if the
            Property is leasehold. You will also be bound by these promises if you have prior knowledge of them. The
            superior {{landlord}} is the person who owns the interest in the Property giving them the right to
            possession of the Property at the end of our lease. </p>



          <p>“<strong>Inventory</strong>” is the document drawn up by us, our Agent, or an inventory clerk, which will
            be given to you on or shortly after the commencement of the Tenancy. It describes the Contents of the
            Property as provided by us. It may include a Schedule of Condition, written report, photos or videos to
            record the Contents and condition of the Property or Contents. It may include meter readings. </p>



          <p>“<strong>Joint and Severally Liable</strong>” means where there are two or more Tenants, you will each be
            responsible for complying with the obligations in this Agreement both individually and together. We may
            seek to enforce these obligations or claim damages against any one or more of you. For example, if three
            Tenants are named on this Agreement and one Tenant does not pay their proportion of the Rent, we can
            recover the amount owed from any one of you or any group. If this Agreement has become periodic and one
            Tenant gives notice to leave, the notice will end the Tenancy for all of you. </p>



          <p>“<strong>{{landlord | titlecase}}</strong>” includes anyone entitled to possession of the Property under this Agreement.
          </p>



          <p>“<strong>Policy</strong>” means any insurance policy held by us for the Property or Contents. </p>



          <p>“<strong>Property</strong>” includes any part or parts of the building boundaries, fences, garden and
            outbuildings belonging to us unless they have been specifically excluded from this Agreement. Where the
            Property is part of a larger building, Property includes the common access ways and shared facilities.
          </p>



          <p>“<strong>Rental Period</strong>” means the time between Rent due dates. For example if the Rent is paid
            weekly and due on a Wednesday, the Rental Period will be from Wednesday to Tuesday. If the Rent is paid
            monthly and due on the 10th of each month, the Rental Period will be from 10th to the 9th of the
            following month. </p>



          <p>“<strong>Schedule of Condition</strong>” is a summary of the condition of the Property or Contents and
            usually includes a description of any faults, damage or missing items. </p>



          <p>“<strong>Tenancy</strong>” means the time between the commencement and the termination of this Agreement
            including any extensions that may have been granted to you by us. </p>



          <p>“<strong>Us</strong>” “<strong>our</strong>” “<strong>we</strong>” means the {{landlord | titlecase}}.</p>



          <p>“<strong>Utilities and other relevant suppliers</strong>” includes but is not limited to; water charges,
            Council Tax (or similar charge which replaces it), gas, electricity, television licence, broadband,
            cable television and satellite television. </p>



          <p>“<strong>Working Day</strong>” does not include Saturdays, Sundays and Bank Holidays. </p>



          <p>“<strong>You</strong>” “<strong>your</strong>” means the Tenant. References to the singular include the
            plural and references to the plural include the singular.</p>



          <h2>Terms and Conditions</h2>



          <p>In accordance with Ground 2 in Schedule 2 of the Housing Act 1988 we hereby give notice that the Property
            is subject to a mortgage which was granted before the beginning of the Tenancy. We let the Property
            together with the Contents to you for the Tenancy on the letting terms set out in this Agreement as
            supplemented by any special letting terms.</p>



          <h4>1.0 Tenant’s Obligations </h4>



          <h5><strong>You hereby agree with us as follows: </strong></h5>



          <p>1.1 Any of your obligations in this Agreement to do or not to do anything also means that you can not
            allow your household or any visitor to do or not to do the same thing. </p>



          <p>1.2 Where there is more than one Tenant you will all be Joint and Severally Liable for the obligations
            contained within this Agreement. In the event of non-payment of Rent and or other breaches of this
            Agreement any individual Tenant or group of Tenants may be held liable. This means that legal action may
            be brought against any one or any group of Tenants. If this Agreement has become periodic notice to
            leave by any individual Tenant will also end the Tenancy for all Tenants. </p>



          <h5><strong>Rent and Charges </strong></h5>



          <p>1.3 To pay the Rent to us at the times and in the manner specified in this Agreement whether or not it
            has been formally demanded. </p>



          <p>1.4 Any payment for less than the Rental Period is to be apportioned on a daily basis and will include
            the last day of the Tenancy. </p>



          <p>1.5 To pay the charges for Council Tax (or similar charge which replaces it) and Utilities and other
            relevant suppliers that you are responsible for as specified in this Agreement. </p>



          <p>1.6 To pay all reasonable costs and expenses incurred by us: </p>



          <p>1.6.1 in the recovery from you of any Rent and any other money which is in arrears; </p>



          <p>1.6.2 in the enforcement of any of the provisions of this Agreement; </p>



          <p>1.6.3 in the service of any notice relating to the breach by you of any of your obligations under this
            Agreement whether or not the same shall result in court proceedings; 1.6.4 the cost of any bank or other
            charges if any cheque written by you is dishonoured or if any standing order or any other payment method
            is withdrawn by your bankers; </p>



          <p>1.6.5 the cost of repairing, decorating or cleaning the Property or the Contents so they are to the same
            standard as at the commencement of the Tenancy (fair wear and tear excepted); and 1.6.6 any other monies
            owed by you to us. 1.7 You must not exercise any right or claim to withhold Rent in respect of legal or
            equitable set-off.</p>



          <h5><strong>Use of the Property</strong></h5>



          <p>1.8 Occupy the Property as your only or principal home and behave in a tenant-like manner.</p>



          <h5><strong>Leaving the Property Empty </strong></h5>



          <p>1.9 Take reasonable care of the Property and common parts (if any).</p>



          <p>1.10 Not assign, take a lodger, sublet or part with or give up to another person possession of the
            Property or any part of it without our written permission (which will not be unreasonably withheld).</p>



          <p>1.11 Not carry on in the Property any trade, profession, business or receive paying guests or register
            any business at theProperty or use the Property for any purpose other than your private residence
            without our written permission (whichwill not be unreasonably withheld).</p>



          <p>1.12 Not use the Parking (if Parking is specified in this Agreement) for any purpose other than for the
            storage of a privatemotor car or motor bike without our written permission (which will not be
            unreasonably withheld).</p>



          <p>1.13 Not exhibit any poster or notice board or notice so as to be visible from the exterior of the
            Property without ourwritten permission (which will not be unreasonably withheld).</p>



          <p>1.14 You, those living with you, and your visitors must not harass or act in an antisocial manner to, or
            pursue a course of antisocial conduct against any person in the neighbourhood. Such people include
            residents, visitors, us, our Agents and contractors. “<strong>Antisocial</strong>” means behaving in a
            way which causes or likely to cause alarm, distress, nuisance or annoyance to any person or causing
            damage to anyone’s property; or which amount to harassment of any person. Harassment of a person
            includes causing the person alarm or distress. Antisocial behaviour includes speech. In particular, you,
            those living with you, and your visitors must not:</p>



          <p>1.14.1 make excessive noise. This includes, but is not limited to, the use of televisions, CD players,
            digital mediaplayers, radios and musical instruments, DIY and power tools;</p>



          <p>1.14.2 fail to control pets properly or allow them to foul or cause damage to other people’s property;
          </p>



          <p>1.14.3 allow visitors to the Property to be noisy or disruptive;</p>



          <p>1.14.4 use the Property or allow it to be used, for illegal or immoral purposes;</p>



          <p>1.14.5 vandalise or damage the Property or any part of the common parts (if any) or neighbourhood;</p>



          <p>1.14.6 leave rubbish and recycling either in unauthorised places or at inappropriate times;</p>



          <p>1.14.7 allow any other person (including children) to cause nuisance or annoyance to other people by
            failing to exercise reasonable control over them and take steps to prevent this;</p>



          <p>1.14.8 harass, threaten or assault any other tenant, member of his/her household, visitors, neighbours,
            our family members or our employees or our Agent, or any other person or persons in the Property, or
            neighbourhood, for whatever reason. This includes behaviour due to that person’s race colour or ethnic
            origin, nationality, gender, sexuality, disability, age, religion or other belief, or other status;</p>



          <p>1.14.9 use or carry offensive weapons;</p>



          <p>1.14.10 use, sell, cultivate or supply unlawful drugs or sell alcohol; and</p>



          <p>1.14.11 store or bring onto the Property any type of firearm or firearm ammunition including any replica
            or decommissioned firearms.The particular prohibitions on behaviour listed above do not in any way
            restrict your general responsibilities.</p>



          <p>1.15 Not change the supplier of the Utilities and other relevant suppliers or install or cause or
            authorise installations relating to the supply of water, electricity, gas or other services to the
            Property without our written permission (which will not be unreasonably withheld).</p>



          <p>1.16 Not bring into the Property any furniture or electrical equipment or other items which might be a
            hazard or cause damage or injury to the Property or to other occupants in the Property.</p>



          <p>1.17 Not introduce into the Property any dangerous or flammable goods, materials, or substances, apart
            from those required for general household use. Not to store any heating fuel, paraffin or bottled gas or
            other gaseous fuel without our written permission (which will not be unreasonably withheld).</p>



          <p>1.18 Not smoke or to permit a visitor to smoke tobacco or any other substance in the Property without our
            written permission (which will not be unreasonably withheld). For the avoidance of doubt nicotine
            staining is not considered to be fair wear and tear.</p>



          <p>1.19 Not keep any pets or animals, reptiles, insects, rodents or birds at the Property without our
            written permission (which will not be unreasonably withheld). For the avoidance of doubt, this clause
            does not apply in connection with registered guide and assistance dogs. Any pet (where permitted) will
            be kept under supervision and control to ensure that it does not cause deterioration in the Property,
            deterioration in the condition of common areas or nuisance either to neighbours or persons in the
            locality of the Property. If you fail to exert reasonable supervision and control, we shall be entitled
            to withdraw our consent and require immediate removal of the pet. You will be liable for reasonable
            costs and expenses incurred by us in replacing and or reinstating the Property and its Contents owing to
            any damage or soiling to theProperty and Contents caused by the pet including but not limited to
            de-infestation where required.</p>



          <p>1.20 Take all reasonable steps not to block or cause a blockage to the drains and pipes, gutters and
            channels in or about the Property.</p>



          <p>1.21 Take all reasonable precautions to prevent condensation and or mould growth by keeping the Property
            adequately ventilated and heated.</p>



          <p>1.22 Take all reasonable precautions to prevent damage occurring to any pipes or other installations in
            the Property that may be caused by frost provided the pipes and other installations were adequately
            insulated at the start of the Tenancy.</p>



          <p>1.23 Not put any damaging oil, grease or other harmful or corrosive substance into the washing or
            sanitary appliances or drains.</p>



          <p>1.24 To arrange suitable contents insurance which you require for your own belongings. We will have no
            liability to insure any items belonging to you.</p>



          <p>1.25 Not park in any space not designated to you.</p>



          <p>1.26 Not interfere with the smoke detectors, carbon monoxide detectors, heat detectors or the fire alarm
            system.</p>



          <p>1.27 In the case of a flatted property, or any other property having common parts, you agree, in
            conjunction with the other proprietors/occupiers, to sweep and clean the common stairway and to
            co-operate with the other proprietors/occupiers in keeping the garden, back green or other communal
            areas clean and tidy.</p>



          <p>1.28 Not obstruct the fire escape or common parts (if any). Any obstructions may be removed by us or our
            Agent.</p>



          <p>1.29 Not allow children to play in the fire escapes or common parts (if any).</p>



          <p>1.30 Replace or repair or pay our reasonable costs of repairing or replacing or reinstating the Property
            or its Contents which are destroyed, damaged, soiled, removed, or lost during the Tenancy (fair wear and
            tear excepted).</p>



          <p>1.31 Advise us by giving reasonable written notice if you intend to be absent from the Property for more
            than 14 days and to provide the actual dates that the Property will be unoccupied. </p>



          <p>1.32 Ensure at all times when the Property is unoccupied that all external doors/windows are properly
            locked or are otherwise properly secured and take appropriate action with regard to supplies of water,
            gas and electricity to prevent flooding, frost or fire. </p>



          <p>1.33 Flush through any water systems following any period where the Property is left unoccupied by
            running all taps and showers to remove any stagnant water.</p>



          <h5>Condition of the Property </h5>



          <p>1.34 Keep the interior of the Property including the Fixtures and Fittings and the Contents in the same
            condition, cleanliness, repair and decoration as at the commencement of the Tenancy (fair wear and tear
            excepted) and to carry out those jobs that you would reasonably be expected to carry out including but
            not limited to the cleaning of any sanitary appliances, shower wastes and windows as often as necessary.
          </p>



          <p>1.35 Not remove any of the Contents from the Property without our written permission (which will not be
            unreasonably withheld). </p>



          <p>1.36 Not make any alteration or addition to the Property or the electric, gas or plumbing system or
            decorate or change the style or colour of the decoration whether it be internal or external, or to erect
            and or install any aerial, satellite dish or cable television without our written permission (which will
            not be unreasonably withheld). Any request for adaptations, auxiliary aids or services in terms of the
            Equality Act 2010 must be made in writing to us. Consent to such alterations requested under this
            legislation will not be unreasonably withheld. </p>



          <p>1.37 Not damage the Property or the electric, gas, or plumbing system. </p>



          <p>1.38 Regularly test any smoke, carbon monoxide or other alarms at the Property and to replace batteries
            where necessary and to report any fault to us immediately. </p>



          <p>1.39 Notify us as soon as reasonably possible having regard to the urgency of the matter of any defect in
            the Property which comes to your attention. </p>



          <p>1.40 Replace any light bulbs, fluorescent tubes, or batteries, promptly and when necessary. </p>



          <p>1.41 Keep the exterior free from rubbish and recycling and place all rubbish and recycling receptacles in
            the allocated space for collection on the day for collection. Rubbish and recycling receptacles should
            be returned to their normal storage places as soon as possible after rubbish and recycling has been
            collected. </p>



          <p>1.42 Take proper care of the shared facilities (if any) and clean as appropriate after use. </p>



          <p>1.43 Keep the gardens, driveways, pathways, lawns, hedges, flower beds, shrubs, rockeries and ponds (if
            any) in good and safe condition and as neat, tidy free from rubbish and properly tended as they were at
            the start of the Tenancy subject to seasonal requirements. Not to alter the general layout of the garden
            or cut down, lop, remove or otherwise damage any trees, shrubs or plants (with the exception of normal
            pruning). To cut the grass as necessary and properly tend the lawns and any borders in order to keep the
            same in a neat and tidy condition, subject to seasonal conditions.</p>



          <h5>Letters and Notices </h5>



          <p>1.44 Forward any notice, order, proposal or legal proceedings affecting the Property or its boundaries to
            us promptly upon receipt of any notice, order, proposal or legal proceedings. </p>



          <p>1.45 Forward all correspondence addressed to the {{landlord | titlecase}} at the Property to us within a reasonable time.
          </p>



          <h5>Access for Repairs, Inspections and Valuations </h5>



          <p>1.46 Permit us (and our Agent or our contractors) reasonable access to the Property for any authorised
            purpose where you have been given 24 hours’ notice, or access is required urgently for the purpose of
            carrying out work on the Property or inspecting the Property in order to determine what work we are
            entitled or have an obligation to carry out. The following are authorised purposes: carrying out any
            work on the Property which we have an entitlement or obligation to carry out; inspecting the Property
            (i) in order to determine what work type to carry out (ii) in pursuance of any entitlement or obligation
            which we have to carry out; valuing the let Property (or any part of it); and viewing the Property with
            prospective occupiers or purchasers during the last 2 months of the Tenancy. Reference to us having an
            entitlement or obligation to do something are to us having an entitlement or obligation to do something
            by virtue of an enactment or the terms of any agreement between us and you. </p>



          <p>1.47 Allow reasonable use of the facilities within the Property in connection with anything done or to be
            done under Clause 1.46 of this Agreement. 1.48 Permit us and our Agent immediate access to the Property
            in the event of an Emergency, including but not limited to an imminent risk to your health and safety or
            members of your household or other persons in the vicinity.</p>



          <h5>Notice to Repair </h5>



          <p>1.49 If we give you written notice to remedy a defect for which you are responsible you agree to carry
            out the repair within one month of the date of the given notice.</p>



          <h5>Key and Alarm Codes </h5>



          <p>1.50 Permit us and our Agent to hold a set of keys to the Property for the purpose of entering the
            Property in an Emergency. </p>



          <p>1.51 Not change the alarms codes, door locks and or have any duplicate keys cut without our written
            permission (which will not be unreasonably withheld). Should you lose your keys you will be liable to
            meet our reasonable costs for replacement and or having new locks fitted and new keys cut. </p>



          <h5>At the End of the Tenancy </h5>



          <p>1.52 At the termination of the Tenancy you agree to: </p>



          <p>1.52.1 give up the Property with vacant possession; </p>



          <p>1.52.2 give up the Property and the Contents in the same state of cleanliness, condition and decoration
            as it was at the commencement of the Tenancy (fair wear and tear excepted) and pay for the
            reinstatement, repair or replacement of the Property and Contents damaged, soiled, stained, marked or
            lost during the Tenancy which were your responsibility in this Agreement; </p>



          <p>1.52.3 remove all rubbish and recycling from the Property and properly dispose of it in receptacles
            outside the Property provided by the local council for the purpose of waste collection, or arrange with
            the local council for a special collection to uplift excessive or heavy rubbish for disposal; </p>



          <p>1.52.4 allow us or our Agent to enter the Property with a surveyor for the purposes of carrying out an
            inspection; </p>



          <p>1.52.5 leave the Contents in the respective positions that they occupied at the commencement of the
            Tenancy; </p>



          <p>1.52.6 return all sets of keys to us and pay reasonable costs of having new locks fitted and new keys cut
            in the event that not all keys are returned to us; </p>



          <p>1.52.7 remove all personal belongings including food stuff; and </p>



          <p>1.52.8 provide us or our Agent with a forwarding address at the end of the Tenancy for ease of
            administration and communication between both parties including the ease of return of the Deposit. </p>



          <p>1.53 Any goods or personal effects belonging to you or members of your household which have been left at
            the Property after the expiry or termination of the Tenancy shall be deemed to have been abandoned and
            will be removed or stored by us. We may dispose of such goods or personal effects as we think
            appropriate and you will be liable for the reasonable costs of removal, storage and disposal provided we
            or our Agent have given written notice to you, or where you cannot be found after reasonable steps have
            been taken to trace you, and at least 14 days have passed (except perishable or hazardous items which we
            may dispose of immediately). We may deduct the reasonable cost of removal, storage and disposal from
            your Deposit. </p>



          <p>1.54 Allow us to erect a ‘to let’ or ‘for sale’ sign at the Property during the last two months of the
            Tenancy</p>



          <h5>Occupier’s Liability </h5>



          <p>1.55 You are responsible for verifying the suitability of the Property for you and members of your
            household including but not limited to any gardens, fences, ponds or outbuildings, especially in
            relation to the safety of pets and young children.</p>



          <p>1.56 You are responsible (under the Occupier’s Liability Act 1984) for the safety of all guests and other
            visitors who attend the Property and protect them (especially children) from any hazards at the
            Property, for example but not limited to ponds, swimming pools, fences and electric gates. </p>



          <h2>2.0 {{landlord | titlecase}}’s Obligations </h2>



          <h5>We hereby agree with you as follows: </h5>



          <p>2.1 Allow you to quietly possess and enjoy the Property during the Tenancy without interruption from us
            (not withstanding Clause 1.46, 1.47 and 1.48 of this Agreement). </p>



          <p>2.2 Pay all assessments and outgoings in respect of the Property which are our responsibility. </p>



          <p>2.3 Ensure that any gas supply and appliances supplied by us comply with the Gas Safety (Installation and
            Use) Regulations 1998 (as amended). </p>



          <p>2.4 Ensure that any furniture and equipment supplied by us comply with the Furniture and Furnishings
            (Fire) (Safety) Regulations 1988 (as amended). </p>



          <p>2.5 Keep in repair the structure and exterior of the dwelling (including drains, gutters and external
            pipes) and keep in repair and proper working order the installations for the supply of any water,
            electricity, gas, sanitation and for space heating and water heating in accordance with Section 11 of
            the {{landlord | titlecase}} and Tenant Act 1985 (as amended). </p>



          <p>2.6 Keep in repair all mechanical and electrical appliances which form part of the Contents (unless
            specifically excluded), unless the fault and or failure is due to your act or failure to act. </p>



          <p>2.7 Pay the charges for Utilities and other relevant suppliers that we are responsible for as specified
            in this Agreement and or ground rent, if applicable. </p>



          <p>2.8 Arrange for payment of premiums for any insurance of the Property and Contents belonging to us, such
            as those items included in the Inventory. We have no liability to insure any items belonging to you.</p>



          <p>2.9 Arrange for the Property and Contents (but not your possessions) to be insured and use all reasonable
            effort to arrange for any damage caused by an insured risk to be remedied as soon as is reasonably
            practicable, and to refund to you any Rent paid for any period in which the Property is uninhabitable or
            inaccessible as a result of such damage, unless the insurers refuse to pay out the Policy monies because
            of anything you have done or failed to do in breach of this Agreement. </p>



          <h2>3.0 Rent Increases </h2>



          <p>3.1 We shall have the option on the anniversary of this agreement to increase the Rent by a reasonable
            percentage provided we have given you at least one month’s notice of our intention to exercise this
            option.</p>



          <h2>4.0 Interest on Rent Arrears and Other Monies </h2>



          <p>4.1 You agree to pay interest at the rate of 3% above the Bank of England base rate upon any Rent or
            other monies due under this Agreement which is more than 14 days in arrears in respect of the date from
            when it became due to the date of payment. </p>



          <h2>5.0 Termination </h2>



          <p>5.1 If we allow you to remain in the Property after the fixed term has expired then the Tenancy will
            continue as a contractual periodic tenancy in accordance with the Housing Act 1988 (as amended). To end
            the periodic tenancy you shall give us at least one months’ notice in writing. The notice must expire on
            the last day of a Rental Period. </p>



          <p>5.2 We have the right to recover possession of the Property by lawful means if: </p>



          <p>5.2.1 the fixed term has come to an end; </p>



          <p>5.2.2 we have given you at least two months’ notice of our intention to recover possession of the
            Property; and </p>



          <p>5.2.3 at least six months have passed since the commencement of the Term of the original agreement. </p>



          <p>5.3 We reserve the right to re-enter the Property (subject always to any statutory restrictions on our
            power to do so) and immediately thereon the tenancy shall terminate without prejudice to our other
            rights and remedies if: </p>



          <p>5.3.1 the Rent is unpaid 14 days after becoming payable whether it has been formally demanded or not;
          </p>



          <p>5.3.2 you have breached this Agreement; </p>



          <p>5.3.3 you become bankrupt; </p>



          <p>5.3.4 an Interim Receiver of the Property is appointed;</p>



          <p>5.3.5 you (without making prior arrangements in writing with us) leave the Property vacant or unoccupied
            for more than 28 days; or </p>



          <p>5.3.6 any of the Grounds 2, 8, 10-15 and 17 set out in Schedule 2 of the Housing Act 1988 apply. This
            clause does not affect your rights under the Protection from Eviction Act 1977. </p>



          <p>5.4 We reserve the right to re-enter the Property by lawful means if you do not have the Right to Rent in
            the United Kingdom as determined by Section 22 of the Immigration Act 2014.</p>



          <h2>6.0 Effect of Termination </h2>



          <p>6.1 Termination of this Agreement ends the Tenancy but does not release you from any outstanding
            obligations. </p>



          <p>6.2 At the end of the Tenancy you shall return the Property together with the Contents to us in the
            condition required by this Agreement.</p>



          <h2>7.0 Inventory </h2>



          <p>7.1 Upon taking the Tenancy you will be invited to attend a check-in process where you will be given
            access to the Property and an opportunity to take meter readings. You will be provided with the
            Inventory and Schedule of Condition relating to the Property on or shortly after the commencement of the
            Tenancy. The Inventory is an agreed record of the Contents and condition of the Property and Contents at
            commencement of the Tenancy and shall be used to assess any loss, damage or dilapidation as recorded at
            the end of the Tenancy. </p>



          <p>7.2 You have a period of 7 days from the start of the Tenancy, or receipt of the Inventory and Schedule
            of Condition (whichever is later), to ensure that the Inventory and Schedule of Condition is correct and
            to tell us or our Agent of any discrepancies in writing, after which the Inventory and Schedule of
            Condition will be amended as appropriate. If you take no action and after the 7 day period has expired,
            you shall be deemed to be fully satisfied with the terms. </p>



          <p>7.3 At the end of the Tenancy you will be invited to attend a check-out process which will be arranged by
            us. The check-out process will comprise a full inspection of the Property and its Contents and an
            opportunity to take meter readings. Any items missing, damaged or otherwise in a different state to
            their condition at the check-in will be recorded. You are strongly encouraged to be present at this
            process as best practice. This will give you the opportunity to dispute or explain any deficiencies or
            defects discovered at the check-out or to take any immediate remedial action by negotiation with us or
            our Agent.</p>



          <h2>8.0 Deposit </h2>



          <p>8.1 You must pay a Deposit of {{client_data.currency_symbol}}<em>Eample Deposit Amount</em> to us to be protected as security towards
            the discharge or part discharge of any liability referred to in Clause 8.4 of this Agreement and subject
            to this on trust for you absolutely. The Deposit will be held in a government approved tenancy deposit
            scheme.</p>



          <p>8.2 If the Deposit is held in a custodial-based government approved tenancy deposit scheme, any interest
            on the Deposit will be paid to you subject to the terms and conditions of the scheme. If the Deposit is
            held by us or our Agent you will not receive interest on the Deposit. </p>



          <p>8.3 The Deposit shall be returned to you (less any deductions properly made) within 10 working days of
            the end of the Tenancy upon vacant possession of the Property and return of the keys if you have kept to
            all the obligations within this Agreement. </p>



          <p>8.4 Monies shall be deducted from the Deposit in respect of all reasonable costs and expenses incurred by
            us in respect of: </p>



          <p>8.4.1 the recovery from you of any Rent or any other money which is in arrears;</p>



          <p>8.4.2 the enforcement of any of the provisions of this Agreement; </p>



          <p>8.4.3 compensation in respect of your use and occupation in the event that you fail to vacate the
            Property on the due date; </p>



          <p>8.4.4 the service of any notice relating to the breach by you of any of your obligations under this
            Agreement whether or not the same shall result in court proceedings; </p>



          <p>8.4.5 the cost of any bank or other charges incurred by us if any cheque written by you is dishonoured or
            if any standing order payment is withdrawn by your bankers; </p>



          <p>8.4.6 the cost of repairing, decorating or cleaning the Property or the Contents so they are to the same
            standard as at the commencement of the Tenancy (fair wear and tear excepted); </p>



          <p>8.4.7 the cost of replacing any items listed in the Inventory which are missing from the Property at the
            end of the Tenancy; </p>



          <p>8.4.8 the cost of removal, storage and disposal by us of any goods or personal effects belonging to you
            or members of your household which have been left at the Property after the expiry or termination of the
            Tenancy in accordance with Clause 1.53 of this Agreement; </p>



          <p>8.4.9 any other monies owed by you to us; and8.4.10 our reasonable costs in reinstating the Property
            owing to your breach of any conditions of this Agreement. </p>



          <p>8.5 If the Deposit shall be insufficient you shall pay us such additional sums as shall be required to
            cover all costs, charges and expenses properly due.</p>



          <h2>9.0 Notices</h2>



          <p>9.1 We agree that any notices given under or in relation to this Agreement which are to be given in
            writing may be served on the {{landlord | titlecase}} either by being left at the address in Clause 9.2 of this
            Agreement or by being sent to that address by first class post. Notices left at the address are deemed
            received the next working day. Notices sent by first class post are deemed received two working days
            after posting. </p>



          <p>9.2 The {{landlord | titlecase}}’s address for service is:</p>



          <p><em>{{landlord | titlecase}} Example </em></p>



          <p><em>{{landlord | titlecase}} Address Example</em></p>



          <p>9.3 You agree that any notices given under or in relation to this Agreement which are to be given in
            writing may be served on you either by being left at the Property or by being sent to the Property by
            first class post. Notices left at the Property are deemed received the next working day. Notices sent by
            first class post are deemed received two working days after posting.</p>



          <p>9.4 You agree that the service of notices, Rent Increase Notices, the How to Rent Guide, Gas Safety
            Certificate, Energy Performance Certificate and Prescribed Information in relation to the government
            approved tenancy deposit scheme may be served on you by email. Your email address for these purposes is:
          </p>



          <p><em>Tenant Example Email</em></p>



          <p>Notices sent by email are deemed to be served the next working day after being sent.</p>



          <h2>10.0 Consents </h2>



          <p>10.1 We confirm that all necessary consents have been obtained to enable us to enter into this Agreement
            (whether from superior {{landlord}}, lenders, mortgagees, insurers, or others).</p>


          <h2>11.0 Data Protection </h2>

          <p>11.1 We require to process and retain certain personal information that you have provided to us. From
            time to time we may pass any or all of that personal information on to third parties who may carry out
            specific work on our behalf for processing. Full details of the personal information we hold, why we
            hold that information, how long it is held for and with whom that information is shared are set out in
            the Fair Processing Notice provided to you. </p>



          <p>11.2 In the event that we require your consent to process and retain any of your personal information we
            shall seek your written permission to do so separately. </p>



          <p>11.3 In terms of the EU General Data Protection Regulation 2016/6769 (hereinafter “GDPR”) you are
            entitled to request and inspect personal information of yours that we hold. Should you wish to inspect
            any of your personal information that we hold, you have the right to request sight of this data,
            provided it is done in writing and detail the specific information that you are seeking. We will provide
            you with a copy of any personal information held (which constitutes “Personal Data” in terms of GDPR)
            within one month of receipt of your written request.</p>

          <h3>Signed as an Agreement dated: </h3>

          <p><em>Example Agreement Date</em></p>

          <h3>Between us, the {{landlord | titlecase}}: </h3>

          <p><em>Example {{landlord | titlecase}} Signature</em></p>

          <h3>And you, the Tenant(s): </h3>
          <p><em>Example Tenant Signature</em></p>

          <p>This document is prepared in good faith by {{client_data.company_legal_name}}. No responsibility whatsoever is accepted by Bunk
            or the officers of said organisation for the accuracy or the legal effect of the document(s) nor shall
            {{client_data.company_name}} or their officers be held responsible for the consequences of its use by a member of {{client_data.company_name}} or by the
            general public.</p>
          <p></p>
        </div>
      </div>
    </section>`,
  styles: [`
    :host {
      height: 100%;
    }
    .doc {
      background-color: #FFFFFF;
      height: 100%;
      overflow: scroll;
    }
    .doc__header {
      max-width: 1079px;
      margin: 0 auto;
      text-align: center;
    }
    .fcol {
      padding: 0 32px;
    }
    .container-s {
      max-width: 1079px;
      margin: 0 auto;
    }
    .wp-block-image {
      max-width: 100%;
      margin-bottom: 1em;
      margin-left: 0;
      margin-right: 0;
      margin-top: 20px;
    }
    .wp-block-image .aligncenter {
      margin-left: auto;
      margin-right: auto;
    }
    p {
      max-width: calc(100% - 32px);
      margin-left: auto;
      margin-right: auto;
    }
  `]
})
export class SampleTenancyAgreementComponent {
  public client_data = environment.client_data;
  public landlord = TRANSLATIONS.landlord;
}

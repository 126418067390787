import { Component, Input, OnInit } from '@angular/core';
import { TenancyModel } from '@app/_models/tenancy.model';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';

const ALPHABET = 'abcdefghijklmnopqrstuvwxyz';

@Component({
  selector: 'profile-avatar',
  template: `
    <div *ngIf="profileName" role="button" class="profiles__card-avatar" [ngClass]="setConditionalClasses()">
      <ng-container *ngIf="profileImg && !hideOnError; else showInitials">
        <img [src]="profileImg" (error)="handleError($event.type)" class="profiles__card-avatar-image" alt="{{profileName}}">
      </ng-container>
      <ng-template #showInitials>
        <span>{{ initials }}</span>
      </ng-template>
    </div>
  `,
  styleUrls: ['./profile-avatar.component.scss']
})
export class ProfileAvatarComponent implements OnInit {
  @Input() size: 'xs'|'sm' | 'md' | 'lg' = 'md';
  @Input() justified: 'left' | 'right' | 'center' = 'left';
  @Input() profileName = '';
  @Input() showManager = true;
  public hideOnError = false;
  public isDefaultImage: boolean;
  public initials: string;
  public randomColourModifier: string;
  private modifiers = [
    '--primary',
    '--secondary',
    '--accent'
  ];
  public profileImg;

  @Input() get profileImgUrl() {
    return this.profileImg;
  }
  set profileImgUrl(event: any) {
    this.profileImg = event;
    this.setDefaultImage();
  }

  ngOnInit(): void {
    this.setDefaultImage();
  }

  setDefaultImage() {
    this.isDefaultImage = this.profileImg === 'assets/img/icons/bunk-default-avatar.svg' || !this.profileImg;
    if (this.isDefaultImage) {
      this.handleError('error');
    } else {
      this.hideOnError = false;
    }
  }

  handleError = (type: string) => {
    const chunkLength = Math.ceil(ALPHABET.length / this.modifiers.length);
    const matches = this.profileName.match(/\b(\w)/g); // Get all first letters of every name/word
    const modIndex = this.chunk(ALPHABET, chunkLength).findIndex((chunk: string) => chunk.includes(matches[0].toLowerCase())); // split the alphabet into as many parts as there are colour modifiers
    this.randomColourModifier = this.modifiers[modIndex];
    this.hideOnError = (type === 'error');
    this.initials = matches.length <= 2 ? matches.join('') : [matches[0], matches[matches.length - 1]].join(''); // only take 2 characters
  };

  setConditionalClasses(): object {
    return {
      ['profiles__card-avatar--justified-' + this.justified]: true,
      'profiles__card-initials' : this.hideOnError,
      ['profiles__card-initials--' + this.size] : this.size,
      ['profiles__card-initials' + this.randomColourModifier] : this.hideOnError,
      ['profiles__card-avatar--' + this.size]: true,
      ['profiles__card-initials--clickable']: this.showManager,
    };
  }

  chunk(str: string, size: number) {
    return str.match(new RegExp('.{1,' + size + '}', 'g'));
  }
}

import { Injectable } from '@angular/core';
import { from as fromPromise } from 'rxjs';
import { AuthService } from '@app/core/services/auth.service';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';


@Injectable()
export class NotificationsService {
  public notificationsCollection: AngularFirestoreCollection<any>;
  constructor(
    private afs: AngularFirestore,
    private _auth: AuthService
  ) {
    this.notificationsCollection = afs.collection<any>('notifications');
  }

  getAllUserNotifications(): AngularFirestoreCollection<any> {
    return this.afs.collection('notifications', ref =>
      ref.where('uid', '==', this._auth.currentUserId)
        .where('is_deleted', '==', false)
        .orderBy('notification_sent_time', 'desc')
    );
  }

  updateNotificationToRead(notification_id: string) {
    const item = {
      notification_is_viewed: true
    };
    return fromPromise(this.updateNotificationDoc(notification_id, item));
  }

  clearAllNotifications(clear_all: boolean, notifications: any) {
    const batchArray: any = [];
    // add batch to first instance of array
    batchArray.push(this.afs.firestore.batch());
    let arrayIndex = 0;
    let operationCounter = 0;

    notifications.forEach((notification: any) => {

      const notifRef = this.notificationsCollection.doc(notification.notification_id).ref;
      batchArray[arrayIndex].set(notifRef, { ...clear_all && {is_deleted: true}, notification_is_viewed: true }, { merge: true });
      operationCounter++;

      // if we hit 500 docs, create new index in array and add batch
      if (operationCounter === 499) {
        batchArray.push(this.afs.firestore.batch());
        operationCounter = 0;
        arrayIndex++;

      }
    });

    // return each index of the array and batch commit
    return batchArray.map((batch: any) => fromPromise(batch.commit()));
  }

  private updateNotificationDoc(id: string, item) {
    return this.notificationsCollection.doc(id).set(item, { merge: true })
      .catch((err: any) => console.log(err));
  }
}


    <label *ngIf="inputLabel">{{inputLabel}}</label>
    <form [formGroup]="formGroup">
      <mat-form-field appearance="outline" class="input__date">
        <mat-label>Day</mat-label>
        <input matInput formControlName="day"
               type="number" name="day"
               min="1"
               max="31"
               step="1"
               (change)="onInputChange()"
               (focusout)="updateErrors('day')">
      </mat-form-field>
      <mat-form-field appearance="outline" class="input__month">
        <mat-label>Month</mat-label>
        <mat-select formControlName="month"
                    (selectionChange)="onInputChange()"
                    (focusout)="updateErrors('month')">
          <mat-option *ngFor="let month of getArrayOfNumbers(12)" [value]="month">
            {{ monthsArr[month] }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="outline" class="input__year">
        <mat-label>Year</mat-label>
        <input matInput formControlName="year"
               type="number" name="year"
               [min]="yearStart"
               [max]="yearEnd"
               step="1"
               (change)="onInputChange()"
               (focusout)="updateErrors('year')">
      </mat-form-field>
      <small *ngIf="errorMessage"><mat-error>{{errorMessage}}</mat-error></small>
      <!-- <span *ngIf="errorMessage" class="mat-error error">{{errorMessage}}</span> -->
    </form>
  
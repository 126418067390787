import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { UserSignupModel, IUserGuestModel } from '@app/core/models';
import { CredentialsModel } from '@app/login/models/credentials.model';
import { environment } from '@env/environment';
import { DeviceDetectorService } from 'ngx-device-detector';
import * as firebase from 'firebase/app';
import { Observable, from, of } from 'rxjs';
import { flatMap, map, tap, switchMap, mergeMap, catchError, delay } from 'rxjs/operators';
import { combineLatest } from 'rxjs/internal/observable/combineLatest';
import * as _ from 'lodash';
import {CookieService} from 'ngx-cookie-service';
import * as ip from 'public-ip';
import {CompanyName} from '@app/shared/shared.constants';

@Injectable()
export class AuthService {
  public usersCollection: AngularFirestoreCollection<any>;
  public usersAnonymousCollection: AngularFirestoreCollection<any>;
  public usersPublicCollection: AngularFirestoreCollection<any>;
  public usersPermissionedCollection: AngularFirestoreCollection<any>;
  public referralSourcesCollection: AngularFirestoreCollection<any>;
  public metaCollection: AngularFirestoreCollection<any>;
  public authState = null;

  public countryCodes = [93, 358, 355, 213, 1 + ' ' + 684, 376, 244, 1 + ' ' + 264, 672,
    1268, 54, 374, 297, 61, 43, 994, 1242, 973, 880, 1246, 375, 32, 501, 229, 1441, 975,
    591, 387, 267, 55, 246, 673, 359, 226, 257, 855, 237, 238, 345, 236, 235, 56, 86,
    57, 269, 242, 243, 682, 506, 225, 385, 53, 357, 420, 45, 253, 1767, 1849, 593, 20, 503,
    240, 291, 372, 251, 500, 298, 679, 33, 594, 689, 241, 220, 995, 49, 233, 350, 30,
    299, 1473, 590, 1671, 502, 224, 245, 595, 509, 379, 504, 852, 36, 354, 91, 62, 98,
    964, 353, 972, 39, 1876, 81, 962, 7 + ' ' + 7, 254, 686, 850, 82, 965, 996, 856, 371,
    961, 266, 231, 218, 423, 370, 352, 853, 389, 261, 265, 60, 960, 223, 356, 692, 596, 222,
    230, 262, 52, 691, 373, 377, 976, 382, 1664, 212, 258, 85, 264, 674, 977, 31, 599, 687,
    64, 505, 227, 234, 683, 1 + ' ' + 670, 968, 92, 680, 970, 507, 675, 51, 63, 872,
    48, 351, 1 + ' ' + 939, 974, 40, 7, 250, 290, 1 + ' ' + 869, 1758, 508, 1784, 685, 378, 239,
    966, 221, 381, 248, 232, 65, 421, 386, 677, 252, 27, 34, 94, 249, 597, 47, 268,
    46, 41, 963, 886, 992, 255, 66, 670, 228, 690, 676, 1868, 216, 90, 993, 1649, 688, 256,
    380, 971, 44, 1, 598, 998, 678, 58, 84, 1284, 1340, 681, 967, 260, 263];

  constructor(
    public afAuth: AngularFireAuth,
    private readonly afs: AngularFirestore,
    private deviceService: DeviceDetectorService,
    private _http: HttpClient,
    private _cookies: CookieService
  ) {
    this.usersCollection = afs.collection<any>('users');
    this.usersAnonymousCollection = afs.collection<any>('users_anonymous');
    this.usersPublicCollection = afs.collection<any>('users_public');
    this.usersPermissionedCollection = afs.collection<any>(
      'users_permissioned'
    );

    this.referralSourcesCollection = afs.collection<any>('referral_sources');

    this.metaCollection = afs.collection<any>('metadata');

    this.afAuth.authState.subscribe((auth: any) => {
      this.authState = auth;
    });
  }

  // Returns true if user is logged in
  get authenticated(): boolean {
    return this.authState !== null;
  }

  // Returns current user
  get currentUser(): any {
    return this.authenticated ? this.afAuth.auth.currentUser : null;
  }

  // Returns current user UID
  get currentUserId(): string {
    return (this.authenticated && (this.currentUser && !this.currentUser.displayName)) ? this.currentUser.uid : (this.authenticated && (this.currentUser && this.currentUser.displayName)) ? this.currentUser.displayName : null;
  }

  // Anonymous User
  get currentUserAnonymous(): boolean {
    return this.authenticated ? this.authState.isAnonymous : false;
  }

  // Returns current user display name or Guest
  get currentUserDisplayName(): string {
    if (!this.authState) {
      return 'Guest';
    } else if (this.currentUserAnonymous) {
      return 'Anonymous';
    } else {
      return this.authState['displayName'] || 'User without a Name';
    }
  }

  // Get id token from firebase and convert it to observable - used by the interceptor
  getCurrentUserIdToken() {
    return from(this.afAuth.auth.currentUser.getIdToken());
  }

  getCurrentUserCustomClaims(): Observable<boolean> {
    return from(this.afAuth.auth.currentUser.getIdTokenResult(true)).pipe(
      map((res: any) => res.claims.admin)
    );
  }

  updateUserAuth(uid?: string): Observable<any> {
    return this._http.post(`${environment.firebaseConfig.apiUrl}/auth-updateUserAuth`, { uid: (uid) ? uid : null });
  }

  updateMoneyhubConnection(): Observable<any> {
    return this._http.post(`${environment.firebaseConfig.apiUrl}/moneyhub-reAuthMoneyhubUser`, {uid: this.currentUserId});
  }

  testLogin(): Observable<any> {
    return this._http.post(`${environment.firebaseConfig.apiUrl}/auth-generateLoginAuthToken?key=c15dbc47e06463d54284bdbd88b3d3171128aa13`, {
      uid: 'TCmr40RprwFrdT62mj1c',
      first_name: 'JAMES',
      last_name: 'NEWWW USER',
      email: 'james+nrla7@gobunk.co.uk',
      phone_number: '+447983347876'
    });
  }
  // endUserSession(): Observable<any> {
  //   return this._http.post(`${environment.firebaseConfig.apiUrl}/auth-endUserSession?key=c15dbc47e06463d54284bdbd88b3d3171128aa13`, {
  //     uid: 'TCmr40RprwFrdT62mj1c',
  //   }).pipe(
  //     tap((res: any) => console.log({res})),
  //     tap(() => {
  //       if (this._cookies.check('BUNKSession')) {
  //         this._cookies.delete('BUNKSession', '/');
  //       }
  //       if (this._cookies.check('NRLASession')) {
  //         this._cookies.delete('NRLASession', '/');
  //       }
  //       if (this._cookies.check('NRLASessionStaging')) {
  //         this._cookies.delete('NRLASessionStaging', '/');
  //       }
  //     })
  //   );
  // }

  getReferralSources(): AngularFirestoreDocument<any> {
     return this.referralSourcesCollection.doc('referral_sources');
  }

  createUserDocuments(uid: any, user: UserSignupModel): Observable<any> {
    return this._http.post(`${environment.firebaseConfig.apiUrl}/auth-initUser`, {
      uid: uid,
      first_name: user.first_name,
      last_name: user.last_name,
      email: user.email,
      role: user.role,
      country_code: user.country_code,
      phone_number: user.phone_number,
      has_accepted_privacy_policy: user.has_accepted_privacy_policy,
      has_accepted_terms: user.has_accepted_terms,
      terms_version: user.terms_version,
      privacy_policy_version: user.privacy_policy_version,
      number_of_properties_declared: user.number_of_properties_declared,
      referral_source: user.referral_source,
      is_student: user.is_student ? user.is_student : false
    });
  }

  createGuestUserDocument(user: IUserGuestModel): Observable<any> {
    return this._http.post(`${environment.firebaseConfig.apiUrl}/auth-initGuestUser`, user);
  }

  checkUserEmail(email: string) {
    return from(this.afAuth.auth.fetchSignInMethodsForEmail(email));
  }

  updateUserPassword(currentPassword: string, newPassword: string): Observable<any> {
    return this.reAuthenticate(currentPassword)
      .pipe(
        flatMap(() => this.currentUser.updatePassword(newPassword))
      );
  }

  reAuthenticate = (currentPassword: string): Observable<any> => {
    const cred = firebase.auth.EmailAuthProvider.credential(this.currentUser.email, currentPassword);
    return from(this.currentUser.reauthenticateWithCredential(cred));
  };


  signInWithEmailAndPassword(user: CredentialsModel): Observable<any> {
    return from(this.afAuth.auth.signInWithEmailAndPassword(user.email, user.password))
      .pipe(
        flatMap(() => this.createMetaForLogin(this.deviceService.getDeviceInfo(), 'login'))
      );
  }
  signInWithCustomToken(token: string): Observable<any> {
    return from(this.afAuth.auth.signInWithCustomToken(token))
      .pipe(
        flatMap(() => this.createMetaForLogin(this.deviceService.getDeviceInfo(), 'login'))
      );
  }

  /*
    Sign in to Firebase anonymous auth and create a unique users_anonymous document
  */
  signInAnonymously(formGroup: any): Observable<IUserGuestModel> {
    return from(this.afAuth.auth.signInAnonymously()).pipe(
      catchError((error) => of({ error })),
      tap(() => this.createMetaForLogin(this.deviceService.getDeviceInfo(), 'login-anonymous')),
      flatMap((userAnonymous: any) => this.createGuestUserDocument({
        uid: userAnonymous.user.uid,
        ...formGroup
      })
    ));
  }

  createUserWithEmailAndPassword(user: any) {
    return from(firebase.auth().createUserWithEmailAndPassword(user.email, user.password));
  }

  resetPassword(email: string) {
    return from(this.afAuth.auth.sendPasswordResetEmail(email));
    // return this._http.post(`${environment.firebaseConfig.apiUrl}/auth-resetPassword`, {email});
  }

  validateResetPasswordToken(token: string) {
    return this._http.post(`${environment.firebaseConfig.apiUrl}/auth-validateResetPasswordToken`, {token});
  }

  updatePassword(password: string, token: string) {
    return this._http.post(`${environment.firebaseConfig.apiUrl}/auth-updatePassword`, {password, token});
  }

  logoutAndRemoveCookies(ip_address: string, uid: string): Observable<Object> {
    return this._http.post(`${environment.firebaseConfig.apiUrl}/auth-logoutAndRemoveCookies`, {ip_address, uid});
  }

  logout() {
    const uid = this.currentUserId;
    return from(this.afAuth.auth.signOut()).pipe(
      switchMap(() => ip.v4()),
      flatMap((ipAddress: any) => (CompanyName.isNrla) ?  this.logoutAndRemoveCookies(ipAddress, uid) : of({})),
      tap(async () => {
        console.log({BUNKSESSION: this._cookies.check('BUNKSession')});
        if (this._cookies.check('BUNKSession')) {
          await this._cookies.delete('BUNKSession', '/', '.nrla.org.uk', true, 'Lax');
          console.log({GET: this._cookies.get('BUNKSession')});
        }
        if (this._cookies.check('BUNKSessionStaging')) {
          this._cookies.delete('BUNKSessionStaging', '/', '.nrla.org.uk', true, 'Lax');
        }
        if (this._cookies.check('NRLASession')) {
          console.log('DELETING NRLA!')
          this._cookies.delete('NRLASession', '/', '.nrla.org.uk', true, 'Lax');
        }
        if (this._cookies.check('NRLASessionStaging')) {
          this._cookies.delete('NRLASessionStaging', '/', '.nrla.org.uk', true, 'Lax');
        }
      })
    );
  }

  createMetaForLogin(user_agent: any, meta_data_type) {
    const meta_id = this.afs.createId();
    const screen_height = window.screen.height + 'px';
    const screen_width = window.screen.width + 'px';
    const item = {
      meta_id,
      user_agent,
      latest_login: new Date(),
      ip: '',
      uid: this.afAuth.auth.currentUser.uid,
      screen_height,
      screen_width,
      meta_data_type
    };
    return from(this.metaCollection.doc(meta_id).set(item));
  }

  createAlgoliaSecureSearchKey(search_key: string, index: string): Observable<Object> {
    return this._http.post(`${environment.firebaseConfig.apiUrl}/algolia-createAlgoliaSecureSearchKey`, {search_key, index});
  }

  activateUserAccount(body: any): Observable<Object> {
    return this._http.post(`${environment.firebaseConfig.apiUrl}/auth-activateGuestUserAccount`, body);
  }

  getLoginCookies(): Observable<Object> {
    // const httpOptions = {
    //   headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    //   withCredentials: true //this is required so that Angular returns the Cookies received from the server. The server sends cookies in Set-Cookie header. Without this, Angular will ignore the Set-Cookie header
    // };
    return this._http.get(`${environment.firebaseConfig.apiUrl}/auth-getCookies`, {
      withCredentials: true,

    });
  }

  combineQueries(teamQuery: any, landlordQuery: any) {
    return combineLatest([teamQuery, landlordQuery]).pipe(
      map(([query1, query2]) => {
        const combined = _.concat(query1, query2);
        return _.uniqWith(combined, _.isEqual);
      })
    );
  }
  combineMultipleQueries(queries: any) {
    return combineLatest([...queries]).pipe(
      map((res: any) => {
        const combined = [...res.flat()];
        return _.uniqWith(combined, _.isEqual);
      })
    );
  }

  adminLoggedInAsUser() {
    return this.currentUserDisplayName !== 'User without a Name';
  }

  getHeyHubAuthId(){
    return this._http.post(environment.firebaseConfig.apiUrl + '/auth-getHeyHubUser', { uid: this.currentUserId });
  }
}

import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { PropertyService, TenancyOfferService, UserService } from '@app/services';
import { ActivatedRoute, Router } from '@angular/router';
import { flatMap, map } from 'rxjs/operators';
import { AuthService } from '@app/core/services';
import { Tenant } from '@env/routing';
import { Location } from '@angular/common';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { ToastrService } from 'ngx-toastr';
import { environment } from '@env/environment';
import { TRANSLATIONS } from '@app/_constants/translations.constants';
import { EPropertyType } from '@rentbunk/bunk-models';
import { PropertyType } from '@app/_constants/property_docs.constants';

enum ApartmentTypes {
  '1_bed' = '1 bed',
  '2_bed' = '2 bed',
  duplex = 'Duplex',
  studio = 'Studio',
  twodio = 'Twodio'
}

@Component({
  selector: 'property-preview-bedroom',
  template: `


    <screen-detection (resized)="isDesktop = $event.isDesktop"></screen-detection>

    <div class="main__wrapper" *ngIf="{property: property$ | async, bedrooms: bedrooms$ |async, landlordPublic: landlordPublic$ | async} as values">
      <button class="btn__crumb-back truncate" (click)="goBackToSearch()">Back</button>

      <section class="card__base">
        <h1 class="margin-bottom">Application</h1>

        <div *ngIf="values.property" class="margin-bottom--lg" [ngClass]="{
          'hide-sm': true, 'hide-md': true}">
          <property-small-card [property]="values.property" imageSize="lg" ></property-small-card>
        </div>


        <ng-container *ngIf="values.landlordPublic && values.landlordPublic.manager">
          <property-owner-card [landlordId]="values.property.landlord_uid" [showLogo]="false" [showManager]="true" [property]="values.property"></property-owner-card>
        </ng-container>

        <hr>

        <div *ngIf="values.property">
          <span class="text--md margin-bottom">Please choose from the below</span>
        </div>

        <div class="justify-center">
          <div class="bedroom-container">

            <ng-container *ngFor="let bedroom of values.bedrooms">

              <div class="bedroom__card" (click)="selectBedroom(bedroom)" [ngClass]="{'bedroom__card--active': bedroom.bedroomIds.includes(selectedBedroomId) }">

                <div class="bedroom__card--img">
                  <img src="{{bedroom.image}}/">
                </div>

                <div class="bedroom__card--info">
                  <div class="flex flex-column">
                    <span *ngIf="bedroom.allUnderOffer" class="block-chip block-chip--sm">All under offer</span>

                    <span
                      class="bedroom__card--subtle">{{bedroom.available}} {{!bedroom.allUnderOffer ? 'available' : PropertyType[values.property.property_type]}} {{bedroom.length > 1 ? 's' : '' }}</span>
                    <span class="bedroom__card--name" *ngIf="bedroom.apartmentType">{{bedroom.apartmentType}} {{PropertyType[values.property.property_type]}}</span>

                    <span class="bedroom__card--name" *ngIf="values.property.property_title && !bedroom.apartmentType">{{values.property.property_title}}</span>

                    <span class="bedroom__card--rent" *ngIf="bedroom.minMax && bedroom.apartmentType">{{bedroom.minMax}}{{bedroom.frequency}}</span>
                    <span class="bedroom__card--rent" *ngIf="!bedroom.apartmentType">{{client_data.currency_symbol + bedroom.amount}}{{bedroom.frequency}}</span>
                  </div>

                  <div class="bedroom__card--icon-container">
                    <span class="bedroom__card--icon mr_5" *ngIf="bedroom.allDoubleBeds"> <mat-icon svgIcon="bed-icon" class="mr_2"></mat-icon> <span>Double</span></span>
                    <!--                <span class="bedroom__card&#45;&#45;icon"  *ngIf="!bedroom.apartmentType"> <mat-icon svgIcon="lock-icon" ></mat-icon><span>£{{bedroom.deposit}}</span></span>-->
                  </div>

                </div>

              </div>

            </ng-container>
          </div>
        </div>

        <form [formGroup]="formGroup" class="margin-top--lg" *ngIf="bedroomGroup$.getValue().length >= 1 && showSelection$.getValue()">

          <span class="text--md">Select {{EPropertyType[values.property.property_type]}}</span>
          <mat-form-field appearance="outline" class="w_100">
            <mat-select [formControlName]="'bedroom_id'" placeholder="Please select the apartment you would like">
              <ng-container *ngFor="let bedroom of bedroomGroup$.getValue()">
                <mat-option *ngIf="!bedroom.is_mid_tenants" [value]="bedroom.bedroom_id" (onSelectionChange)="selectBedroomId(bedroom.bedroom_id)">
                  <strong *ngIf="bedroom.is_under_offer" class="under-offer">Under Offer</strong>
                  {{bedroom.room_name}}
                  - {{client_data.currency_symbol}} {{(bedroom.rent_weekly ? bedroom.rent_weekly : bedroom.listed_rent_pcm)}} {{(bedroom.rent_weekly ? 'weekly' : rent_label_abbreviation)}}
                </mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>
        </form>

        <div *ngIf="values.property?.tenancy_package_ids && values.property?.tenancy_package_ids.length" class="margin-top">
          <h3>Select tenancy type</h3>
          <span class="text--md margin-bottom">Why not pay a little extra and get some additional luxury</span>
          <tenancy-package [tenancyPackageIds]="values.property?.tenancy_package_ids" (selectedPackage)="updateTenancyPackaged($event)"></tenancy-package>

        </div>


        <div class="flex justify-between margin-top--lg">

          <button class="btn__seethrough" type="button" (click)="goBackToSearch()">Back</button>

          <button *ngIf="_auth.authenticated " class="btn__cta btn-lg" type="button" (click)="handleTenantOffer(values.property, selectedBedroomId)">Apply now</button>

                 <offer-sign-up-component *ngIf="!_auth.authenticated" [bedroomId]="selectedBedroomId"  [property]="values.property" [tenancyPackageId]="this.formGroup.get('tenancy_package_id').value"></offer-sign-up-component>
        </div>

      </section>

    </div>


  `,
  styleUrls: ['property-preview-bedroom.component.scss']

})

export class PropertyPreviewBedroomComponent implements OnInit {
  public propertyId: string;
  public bedrooms$: Observable<any>;
  public property$: Observable<any>;
  public landlordPublic$: Observable<any>;
  public selectedBedroomId: string;
  public PropertyType = PropertyType;
  public formGroup: FormGroup;
  public bedroomGroup$: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  public showSelection$: BehaviorSubject<any> = new BehaviorSubject<any>(false);
  public isDesktop;
  public client_data = environment.client_data;
  public rent_label_abbreviation = TRANSLATIONS.rent_label_abbreviation;


  dref;
  elemAtTop;

  constructor(private _property: PropertyService,
              private _route: ActivatedRoute,
              private _user: UserService,
              public _auth: AuthService,
              public _tenancyOffer: TenancyOfferService,
              public _router: Router,
              public _activedRoute: ActivatedRoute,
              public _locaction: Location,
              private _formBuilder: FormBuilder,
              public _toastr: ToastrService
              ) {}

  initFormBuilder(): any {
    return this._formBuilder.group({
      bedroom_id: [null],
      tenancy_package_id: [null]
    });
  }

  get tenancy_package_id(): FormControl {
    return this.formGroup.get('tenancy_package_id') as FormControl;
  }



  ngOnInit() {

    this.formGroup = this.initFormBuilder();
    this.propertyId = this._route.snapshot.paramMap.get('id');
    this.property$ = this._property.getPropertyById(this.propertyId).valueChanges().pipe(
      map((prop: any) => {
        return {
          ...prop,
          hmoBedrooms: prop.is_hmo ?  Object.values(prop.bedrooms) : null,
        };
      })
    );

    this.selectedBedroomId = this._activedRoute.snapshot.queryParamMap && this._activedRoute.snapshot.queryParamMap.get('bedroom_id')
      ? this._activedRoute.snapshot.queryParamMap.get('bedroom_id') : null;

    this.landlordPublic$ = this.property$.pipe(
      flatMap(property =>
      property.team_id ? this._user.getTeamPublicById(property.team_id).pipe(
          map((teamPublic:any) => {
            return{
              property,
              teamPublic
            };
          }),
          map((team:any) => {
            const {teamPublic, property} = team;
            let manager = null;
            if (teamPublic && teamPublic.team_data) {
              const image_small_url = teamPublic.team_data.brand_assets && team.team_data.brand_assets.banner_aside && team.team_data.brand_assets.banner_aside.image_small_url;
              const image_large_url = teamPublic.team_data.brand_assets && team.team_data.brand_assets.banner_aside && team.team_data.brand_assets.banner_aside.image_large_url;
              manager = {
                companyName: teamPublic.team_data.company_name,
                companyNameShort: teamPublic.team_data.company_name_short,
                contactNumber: teamPublic.team_data.contact_number,
                contactEmail: teamPublic.team_data.contact_email,
                bannerAsideSm: image_small_url,
                bannerAsideLg: image_large_url,
                profileImgUrl: teamPublic.team_data.company_logo
              };
            }
            return {
              firstName: manager && manager.companyName ? manager.companyName : null,
              companyName: manager && manager.companyName ? manager.companyName : null,
              profileImgUrl: manager && manager.profileImgUrl ? manager.profileImgUrl : null,
              uid: property.property && property.property.landlord_uid ? property.property.landlord_uid : null,
              property,
              manager
            };
          }) )
        :
        this._user.getUserById(property.landlord_uid).valueChanges().pipe(
        map(landlordPublic => {
          return {
            landlordPublic,
            property
          };
        }),
      map(landlordProps => {
        const {landlordPublic, property} = landlordProps;
        let manager = null;
        if (landlordProps.landlordPublic.property_manager_data) {
          const { image_small_url , image_large_url } = landlordPublic.property_manager_data.brand_assets.banner_aside;
          manager = {
            companyName: landlordPublic.property_manager_data.company_name,
            companyNameShort: landlordPublic.property_manager_data.company_name_short,
            contactNumber: landlordPublic.property_manager_data.contact_number,
            bio: landlordPublic.property_manager_data.bio,
            bannerAsideSm: image_small_url,
            bannerAsideLg: image_large_url
          };
        }
        return {
          firstName: landlordPublic.profile_data.first_name,
          profileImgUrl: landlordPublic.profile_data.profile_image_url,
          uid: landlordPublic.uid,
          property,
          manager
        };
      })
    )));



    this.bedrooms$ = this.property$.pipe(
      map((property: any) => {
        const bedrooms: any = Object.values(property.bedrooms).filter((bedroom: any) => bedroom.is_listed && !bedroom.is_mid_tenants);


        if ( bedrooms.every(val => val.apartment_type ) ) {
          this.showSelection$.next(true);

          const allBedrooms = this.groupBy(bedrooms, 'apartment_type').map((group: any) => {
            return this.formatBedroom(property, group, this.rent_label_abbreviation);
          });

          return this.orderBy([...allBedrooms]);


        } else {
          this.showSelection$.next(false);
          const pcm = bedrooms.filter((bedroom: any) => !bedroom.rent_qtr);
          const rentQtr = bedrooms.filter( (bedroom: any) => bedroom.rent_qtr);

          const bedroomsPcm = this.groupBy(pcm, 'listed_rent_pcm').map((group: any) => {
            return this.formatBedroom(property, group, this.rent_label_abbreviation);
          });
          const bedroomsQtr = this.groupBy(rentQtr, 'rent_qtr').map((group: any) => {
            return this.formatBedroom(property, group, 'per qtr');
          });

          return this.orderBy([...bedroomsPcm, ...bedroomsQtr]);

        }
      })
    );

  }

  selectBedroom(bedroom: any) {
    this.bedroomGroup$.next(bedroom.data);
    const filteredBedrooms = bedroom.data.filter((data: any) => !data.is_under_offer);
    if (!filteredBedrooms || !filteredBedrooms.length) {
      const {bedroom_id} = bedroom.data[Math.floor(Math.random() * bedroom.data.length)];
      this.selectedBedroomId = bedroom_id;
    } else {
      const {bedroom_id} = filteredBedrooms[Math.floor(Math.random() * filteredBedrooms.length)];
      this.selectedBedroomId = bedroom_id;
    }
  }
  orderBy (array: any) {
    return array.sort((a: any, b: any) => (a.amount > b.amount) ? 1 : ((b.amount > a.amount) ? -1 : 0));
  }

  groupBy(arr, prop) {
    const bedroom = new Map(Array.from(arr, obj => [obj[prop], []]));
    arr.forEach(obj => bedroom.get(obj[prop]).push(obj));
    return Array.from(bedroom.values());

  }

  groupByTwoFields(array, f) {
    const groups = {};
    array.forEach(function (o) {
      const group = JSON.stringify(f(o));
      groups[group] = groups[group] || [];
      groups[group].push(o);
    });
    return Object.keys(groups).map(function (group) {
      return groups[group];
    })
  }

  handleTenantOffer(property: any, bedroomId: string): any {
  if (!bedroomId) {
    return this._toastr.error(`Please select a ${EPropertyType[property.property_type]}`);
  }

  if (property.tenancy_package_ids &&  property.tenancy_package_ids.length && !this.tenancy_package_id.value ) {
    return this._toastr.error('Please select a tenancy');
  }
  let alreadyOfferId = '';

   return this._tenancyOffer.getTenancyOfferByLeadTenantUid(property.property_id, bedroomId).pipe(
      flatMap((offer: any[]) => {
          if (offer.length >= 1) {
            alreadyOfferId = offer[0].tenancy_offer_id;
           return this._tenancyOffer.updateOffer({bedroom_id: bedroomId, tenancy_package_id: this.tenancy_package_id.value }, offer[0].tenancy_offer_id);
          } else {
            return this._tenancyOffer.createTenancyOffer(property, property.landlord_uid, bedroomId, property.team_id, this.tenancy_package_id.value);
          }
        }
      )
    ).subscribe((offerId: string) => {
      // We only reroute with a tenancy_offer_id
      if (offerId) {
        this._router.navigate(['/' + Tenant.base, Tenant.make_an_offer.base, offerId, Tenant.make_an_offer.rent]);
      }
      if (alreadyOfferId) {
        this._router.navigate(['/' + Tenant.base, Tenant.make_an_offer.base, alreadyOfferId, Tenant.make_an_offer.rent]);
      }
    });

  }

  goBackToSearch() {
    this._locaction.back();
  }

  selectBedroomId(bedroomId: string) {
    this.selectedBedroomId = bedroomId;
  }

  formatBedroom(property: any, bedroom: any, frequency: string) {
    const photo = bedroom.find(value => value.room_thumbnail_url.image_small_url);
    const priceAsc = bedroom.sort((a: any, b: any) => a.listed_rent_pcm - b.listed_rent_pcm);

    return  {
      bedroomIds: bedroom.map(bed => bed.bedroom_id),
      allUnderOffer: bedroom.every(val => val.is_under_offer === true),
      amount: bedroom[0].rent_weekly ? bedroom[0].rent_weekly : frequency === 'per qtr' ? parseFloat(bedroom[0].rent_qtr) :  parseFloat(bedroom[0].listed_rent_pcm),
      deposit: parseFloat(bedroom[0].deposit_amount),
      available: bedroom.length,
      frequency: bedroom[0].rent_weekly ? 'weekly' : frequency,
      image: photo ? photo.room_thumbnail_url.image_small_url : property.property_photos[0].image_small_url,
      allDoubleBeds: bedroom.every(val => val.bed_type === 'Double'),
      allEnsuite: bedroom.every(val => val.has_ensuite === true),
      apartmentType: bedroom[0].apartment_type ? ApartmentTypes[bedroom[0].apartment_type] : null,
      minMax: priceAsc[0].listed_rent_pcm === priceAsc[bedroom.length - 1].listed_rent_pcm ? `${this.client_data.currency_symbol + ' ' + priceAsc[0].listed_rent_pcm}` :  ` ${this.client_data.currency_symbol + ' ' + priceAsc[0].listed_rent_pcm} - ${priceAsc[bedroom.length - 1].listed_rent_pcm}` ,
      data: bedroom
    };
  }

  updateTenancyPackaged(e: any) {
    this.formGroup.get('tenancy_package_id').patchValue(e);
  }

}

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./profile-avatar.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./profile-avatar.component";
var styles_ProfileAvatarComponent = [i0.styles];
var RenderType_ProfileAvatarComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ProfileAvatarComponent, data: {} });
export { RenderType_ProfileAvatarComponent as RenderType_ProfileAvatarComponent };
function View_ProfileAvatarComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["class", "profiles__card-avatar-image"]], [[8, "src", 4], [8, "alt", 0]], [[null, "error"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("error" === en)) {
        var pd_0 = (_co.handleError($event.type) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.profileImg; var currVal_1 = i1.ɵinlineInterpolate(1, "", _co.profileName, ""); _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_ProfileAvatarComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.initials; _ck(_v, 1, 0, currVal_0); }); }
function View_ProfileAvatarComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "div", [["class", "profiles__card-avatar"], ["role", "button"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProfileAvatarComponent_2)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["showInitials", 2]], null, 0, null, View_ProfileAvatarComponent_3))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "profiles__card-avatar"; var currVal_1 = _co.setConditionalClasses(); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = (_co.profileImg && !_co.hideOnError); var currVal_3 = i1.ɵnov(_v, 5); _ck(_v, 4, 0, currVal_2, currVal_3); }, null); }
export function View_ProfileAvatarComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_ProfileAvatarComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.profileName; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_ProfileAvatarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "profile-avatar", [], null, null, null, View_ProfileAvatarComponent_0, RenderType_ProfileAvatarComponent)), i1.ɵdid(1, 114688, null, 0, i3.ProfileAvatarComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ProfileAvatarComponentNgFactory = i1.ɵccf("profile-avatar", i3.ProfileAvatarComponent, View_ProfileAvatarComponent_Host_0, { size: "size", justified: "justified", profileName: "profileName", showManager: "showManager", profileImgUrl: "profileImgUrl" }, {}, []);
export { ProfileAvatarComponentNgFactory as ProfileAvatarComponentNgFactory };

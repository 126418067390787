import { Component, EventEmitter, OnInit, Output, Input } from '@angular/core';
import { environment } from '@env/environment';
import * as IdealPostcodes from 'ideal-postcodes-core';
import { FormGroup, FormBuilder, FormControl, ValidatorFn, ValidationErrors, AbstractControl } from '@angular/forms';


function requiredIf(required: boolean ): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    // const value = control.value;
    if (required) {
      return {
        requiredIf: { condition: required }
      };
    }
    return null;
  };
}


@Component({
  selector: 'ideal-postcodes-autocomplete',
  template: `
    <form [formGroup]="formGroup" class="ideal-postcodes__form">
      <mat-form-field appearance="{{appearanceType}}">
        <mat-label *ngIf="labelText">{{labelText}}</mat-label>
        <input matInput
          #addressText
          name="address"
          type="text"
          placeholder="Type postcode here e.g. BS16 1QY"
          [attr.aria-label]="labelText"
          formControlName="address"
          [matAutocomplete]="auto"
          [attr.disabled]="disabled"
          (keyup)="searchAddress(addressText.value)"
          data-test="selectAddressInput">

        <mat-error *ngIf="showError" class="margin-bottom--sm">Please enter the street address</mat-error>

        <mat-autocomplete #auto="matAutocomplete">
          <mat-option *ngFor="let option of options"
            [value]="option.suggestion"
            (onSelectionChange)="chooseAddress(option.udprn)"
            data-test="addressSearchResult">
            {{option.suggestion}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>

    </form>
  `,
  styleUrls: ['./ideal-postcodes-autocomplete.component.scss']
})
export class IdealPostcodesAutocompleteComponent implements OnInit {
  @Input() appearanceType = 'legacy';
  @Input() labelText: string;
  @Input() disabled = false;
  @Input() address;
  @Input() set showError(val: any) {
    this._showError = val;
    this.setError(val);
  }
  get showError(): any {
    return this._showError;
  }
  @Output() selectedAddress = new EventEmitter();

  private client;
  private _showError: boolean;
  public formGroup: FormGroup;
  public options = [];

  constructor(
    private _formBuilder: FormBuilder
  ) {
    this.client = new IdealPostcodes.Client({ api_key: environment.idealPostCodes.apiKey });
  }

  setError(show: boolean) {
    if (!this.formGroup) {
      return;
    }
    const address = this.formGroup.get('address') as FormControl;
    if (show) {
      address.markAsTouched();
      address.setValidators([requiredIf(this.showError)]);
    } else {
      address.markAsPristine();
      address.setValidators(null);
    }
    address.updateValueAndValidity();
  }

  ngOnInit() {
    const addressStr = this.address ? ([this.address.first_line_address, this.address.second_line_address, this.address.third_line_address, this.address.city, this.address.post_code].filter( Boolean )).join(', ') : null;
    this.formGroup = this._formBuilder.group({
      address: [addressStr , [requiredIf(this.showError)]]
    });
    this.setError(this.showError);
  }

  searchAddress(address: string) {
    this.client.registerAutocompleteCallback((error, response) => {
      if (error) {
        console.log(error);
        return;
      }
      this.options = response.hits;
    });

    this.client.autocompleteAddress({
      query: address
    });
  }

  chooseAddress(udprn: number) {
    // console.log('Deep look address:', udprn);
    this.client.lookupUdprn({ id: udprn },
      (error, address) => {
        if (error) {
          console.log(error);
          return;
        }
        this.selectedAddress.emit(address);
      });
  }

}

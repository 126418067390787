<div class="flex justify-end margin-bottom--sm">
  <div class="flex items-center">
    <button
      (mouseover)="showKey = true"
      (mouseout)="showKey = false"
      class="btn__text-generic mr_8"
      id="payments-status-key"
    >
      <span>Status Key</span>
      <span class="icon__info icon__info--sm"></span>
    </button>
  </div>
  <div class="key__card" *ngIf="showKey">
    <span class="key__card--title">Status key</span>
    <hr />
    <ul>
      <li>
        <span><span class="key__status"></span>Scheduled</span>
        <small>Set to arrive on certain date</small>
      </li>
      <li>
        <span><span class="key__status key__status--warning"></span>Due</span>
        <small>Payment is expected</small>
      </li>
      <li>
        <span
          ><span class="key__status key__status--danger"></span>Overdue</span
        >
        <small>Payment is late</small>
      </li>
      <ng-container *ngIf="environment.is_bunk_environment">
        <li>
          <span><span class="key__status"></span>Received</span>
          <small>Payment received by Bunk</small>
        </li>
        <li>
          <span
            ><span class="key__status key__status--warning"></span>Partially
            received</span
          >
          <small>Part payment received by Bunk</small>
        </li>
        <li>
          <span
            ><span class="key__status key__status--warning"></span>Partially
            paid</span
          >
          <small>Part payment paid</small>
        </li>
      </ng-container>
      <li>
        <span><span class="key__status key__status--success"></span>Paid </span>
        <small>Payment paid in full</small>
      </li>
      <li>
        <span><span class="key__status"></span>Register Now</span>
        <small>Deposit needs registering</small>
      </li>
      <li>
        <span
          ><span class="key__status key__status--success"></span
          >Registered </span
        ><small>Deposit registered</small>
      </li>
    </ul>
  </div>
  <bunk-dialog
    [component]="contentDialog"
    (dialogRef)="dref = $event"
    restrict="true"
  >
    <button class="btn__seethrough btn__download">Export as CSV</button>
  </bunk-dialog>
  <ng-template #contentDialog>
    <h2>Please confirm export</h2>
    <!--        <div class="block-info">-->
    <p class="margin-top--md margin-bottom--lg">
      You are about to download {{ totalHits }} items. If you would like to
      refine your selected content and download less, please press cancel and
      filter the table to your preference or press <b>Export As CSV</b> to start
      the export.
    </p>

    <!--        </div>-->
    <div class="flex justify-center" *ngIf="showSpinner">
      <span class="exporting text--md"
        >Exporting<span>.</span><span>.</span><span>.</span></span
      >
    </div>
    <div class="flex justify-center margin-bottom--sm" *ngIf="showSpinner">
      <small class="text--subtle"
        >Please do not click away or refresh the page whilst this is in
        progress.</small
      >
    </div>
    <nav class="action-bar">
      <button
        [disabled]="showSpinner"
        type="button"
        class="btn__subdued btn__subdued"
        (click)="dref.close()"
      >
        Cancel
      </button>
      <button
        [disabled]="showSpinner"
        type="button"
        class="btn__cta"
        (click)="getAllData()"
      >
        <span *ngIf="!showSpinner">Export as CSV</span>
        <processing-spinner
          *ngIf="showSpinner"
          color="#FFFFFF"
          size="20px"
        ></processing-spinner>
      </button>
    </nav>
  </ng-template>
</div>

<div class="table-overflow">
  <table mat-table [dataSource]="dataSource" multiTemplateDataRows>
    <ng-container *ngFor="let item of columnsToDisplay">
      <ng-container [matColumnDef]="item.value">
        <th mat-header-cell *matHeaderCellDef>
          <span class="flex flex-row">{{ item.title }} </span>
        </th>

        <td mat-cell *matCellDef="let element">
          <span
            *ngIf="
              item.title !== 'Status' &&
              item.title !== 'Property' &&
              item.title !== 'Tenant'
            "
          >
            <span
              *ngIf="
                element[item.value] === element.amount ||
                (element[item.value] === element.amountOutstanding &&
                  element.amountOutstanding !== null)
              "
              >{{ client_data.currency_symbol }}</span
            >
            {{ element[item.value] }}
          </span>

          <span
            class="card__status-banner"
            *ngIf="item.title === 'Status'"
            [ngClass]="{
              'card__status-banner--warning':
                element.status.value === RENT_STATUS.DUE ||
                element.status.value === RENT_STATUS.PARTIALLY_PAID ||
                element.status.value === RENT_STATUS.PARTIALLY_RECEIVED,
              'card__status-banner--danger':
                element.status.value === RENT_STATUS.OVERDUE,
              'card__status-banner--success':
                element.status.value === RENT_STATUS.PAID
            }"
            >{{ element.status.viewValue }}</span
          >

          <div
            *ngIf="item.title === 'Property'"
            class="table-link truncate"
            title="{{ element[item.value] }}"
            (click)="emitId(element[item.additional])"
          >
            {{ element[item.value] }}
          </div>
          <span *ngIf="item.title === 'Tenant'" class="table-link">
            <bunk-overlay
              [template]="overlay"
              (overlayRef)="overRef = $event"
              *ngIf="element[item.value]"
            >
              {{ element[item.value] }}
            </bunk-overlay>
          </span>
          <ng-template #overlay>
            <div
              class="bunk-overlay__external-content bunk-overlay__external-content--scrollable"
            >
              <section
                class="main__wrapper main__wrapper--narrow main__wrapper--mb-lg"
              >
                <profile-card-overlay
                  [uid]="element.uid"
                  (closeOverlay)="overRef.close()"
                ></profile-card-overlay>
              </section>
            </div>
          </ng-template>

          <ng-container [matColumnDef]="'timestampPaid'">
            <th mat-header-cell *matHeaderCellDef>Date Paid</th>
            <td mat-cell *matCellDef="let element">
              <span>{{ element.timestampPaid }}</span>
            </td>
          </ng-container>
        </td>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="isAdmin || _user.userDb.is_paid_off_platform">
      <ng-container [matColumnDef]="'month'">
        <th mat-header-cell *matHeaderCellDef>Month</th>
        <td mat-cell *matCellDef="let element">
          <span>{{ element.month }}</span>
        </td>
      </ng-container>

      <ng-container [matColumnDef]="'user_reference'">
        <th mat-header-cell *matHeaderCellDef>Reference Code</th>
        <td mat-cell *matCellDef="let element">
          <span>{{ element.tenantCode }}</span>
        </td>
      </ng-container>

      <ng-container [matColumnDef]="'mark_paid'">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <bunk-dialog [component]="markPaid" (dialogRef)="dref = $event">
            <button
              class="btn__generic btn--xs"
              [disabled]="disabledButtons.includes(element.id)"
              (click)="this.updateValue(element)"
            >
              Update status
            </button>
          </bunk-dialog>

          <ng-template #markPaid>
            <mat-dialog-content>
              <h3 class="text--md">Update payment status</h3>
              <form [formGroup]="adminFormGroup">
                <mat-form-field appearance="outline">
                  <mat-label>Select status: </mat-label>
                  <mat-select [formControlName]="'payment_status'">
                    <mat-option
                      *ngFor="let status of paymentStatuses"
                      [value]="status.value"
                    >
                      {{ status.viewValue }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <ng-container *ngIf="payment_status.value === 'partially_paid'">
                  <mat-form-field appearance="outline">
                    <mat-label>Amount paid: </mat-label>
                    <div matPrefix>{{ client_data.currency_symbol }}</div>
                    <input
                      matInput
                      type="number"
                      min = '0'
                      [formControlName]="'partially_paid_amount'"
                    />
                  </mat-form-field>
                  <mat-error *ngIf="partially_paid_input.invalid && partially_paid_input.touched"
                    >{{partially_paid_error_message}}</mat-error
                  >
                </ng-container>

                <small class="margin-top--sm text--subtle"
                  >Confirm payment is {{ payment_status_string }} for
                  <strong>{{ element.tenantName }}</strong> at
                  <strong>{{ element.propertyAddress }}</strong> with the amount
                  of
                  <strong>{{
                    client_data.currency_symbol + payment_amount
                  }}</strong
                  >.</small
                >

                <ng-container *ngIf="payment_status.value === 'paid'">
                  <ng-container *ngIf="element.payout_account">
                    <h3 class="text--md margin-top--sm">Payout details</h3>
                    <p class="margin-top--sm text--subtle">
                      <strong>Payee:</strong>
                      {{ element.payout_account.account_holder_name }}
                    </p>
                    <p class="margin-top--sm text--subtle">
                      <strong>Account number last 4 digits:</strong>
                      {{ element.payout_account.account_number_last4 }}
                    </p>
                  </ng-container>
                  <h3 class="text--md margin-top--md">
                    When was the payment made?
                  </h3>

                  <div
                    class="filter__type-container filter__type-container--content cursor-pointer"
                    *ngFor="let type of paymentPaidOnTypes"
                    (click)="setDatePaidOnType(type.value)"
                  >
                    <p>{{ type.viewValue }}</p>
                    <span
                      class="selected"
                      *ngIf="payment_paid_string.value === type.value"
                    ></span>
                  </div>
                  <mat-error *ngIf="payment_paid_on.invalid"
                    >Please select when the payment was made</mat-error
                  >

                  <mat-form-field
                    appearance="outline"
                    class="margin-top--md"
                    *ngIf="payment_paid_string.value === 'other'"
                  >
                    <mat-label>Set payment date</mat-label>
                    <input
                      [formControlName]="'payment_paid_on'"
                      matInput
                      (click)="paymentPaidDate.open()"
                      [matDatepicker]="paymentPaidDate"
                    />
                    <mat-datepicker-toggle
                      matSuffix
                      [for]="paymentPaidDate"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #paymentPaidDate></mat-datepicker>
                    <mat-error>This field is required</mat-error>
                  </mat-form-field>
                </ng-container>

                <mat-form-field
                  appearance="outline"
                  class="w_100"
                  *ngIf="isAdmin"
                >
                  <mat-label>Notes</mat-label>
                  <textarea
                    [formControlName]="'notes'"
                    row="3"
                    col="80"
                    maxlength="5000"
                    matInput
                    placeholder="Add a description"
                    [value]="element.notes"
                  ></textarea>
                  <mat-error>Description required</mat-error>
                </mat-form-field>
              </form>
              <div class="flex flex-column items-center margin-top--md">
                <button class="btn__cta" (click)="markAsPaid(element.id)">
                  Mark as {{ payment_status_string }}
                </button>
              </div>
            </mat-dialog-content>
          </ng-template>
        </td>
      </ng-container>
      <ng-container [matColumnDef]="'more_options'">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <mat-icon
            [matMenuTriggerFor]="list"
            aria-hidden="false"
            aria-label="Example home icon"
            class="cursor-pointer"
            >more_vert</mat-icon
          >
          <mat-menu #list="matMenu">
            <bunk-dialog
              [component]="editPayments"
              (dialogRef)="dref = $event"
              (click)="is_being_held.setValue(element.is_being_held)"
            >
              <button
                class="btn btn--sm menu__item"
                mat-menu-item
                (click)="setFormValues(element.dueDate, element.amount)"
              >
                <i class="material-icons btn-icons">edit</i>
                <span>Edit Payment</span>
              </button>
            </bunk-dialog>

            <button
              class="btn btn--sm menu__item"
              *ngIf="
                element.custom_fields && element.custom_fields.xero_invoice_id
              "
              mat-menu-item
              (click)="openXero(element)"
            >
              <i class="material-icons btn-icons">open_in_new</i>
              Xero Invoice
            </button>

            <bunk-dialog
              [component]="deletePayment"
              (dialogRef)="dref = $event"
              (click)="is_being_held.setValue(element.is_being_held)"
              *ngIf="
                ['due', 'scheduled', 'overdue'].includes(element.status.value)
              "
            >
              <button class="btn btn--sm menu__item" mat-menu-item>
                <i class="material-icons btn-icons">delete</i>
                <span>Delete Payment</span>
              </button>
            </bunk-dialog>

            <ng-container *ngIf="isAdmin">
              <button
                class="btn btn--sm menu__item"
                mat-menu-item
                (click)="openFirestoreDoc('tenancies', element.tenancyId)"
              >
                <i class="material-icons btn-icons">open_in_new</i>
                View tenancy Doc
              </button>
              <button
                class="btn btn--sm menu__item"
                mat-menu-item
                (click)="openFirestoreDoc('payments', element.id)"
              >
                <i class="material-icons btn-icons">open_in_new</i>
                View payment Doc
              </button>
            </ng-container>
          </mat-menu>
          <ng-template #deletePayment>
            <h3 class="text--md">Delete Payment?</h3>
            <div class="delete-container">
              <span
                ><strong>Amount:</strong>
                {{ client_data.currency_symbol + element.amount }}</span
              >
              <span><strong>Type:</strong> {{ element.paymentType }}</span>
              <span><strong>Due:</strong> {{ element.momentDueDate }}</span>
              <span *ngIf="element.tenantName"
                ><strong>Name:</strong> {{ element.tenantFullName }}</span
              >
              <span>
                <strong>Address:</strong> {{ element.propertyAddress }}</span
              >
            </div>

            <div *ngIf="element.paymentType !== 'expense'">
              <form [formGroup]="deletePaymentGroup">
                <bunk-form-field [direction]="'column'">
                  <label bunk-label class="flex flex-column">
                    <span class="margin-bottom--sm">
                      Would you like to delete all
                      <strong>{{ element.paymentType }}</strong> payments for
                      this tenant?</span
                    >
                    <div class="block-info block-info--warning">
                      <small
                        >By deleting all
                        <strong>{{ element.paymentType }}</strong> payments for
                        this tenant, you will be deleting all due, scheduled and
                        overdue payments</small
                      >
                    </div>
                  </label>

                  <div bunk-input>
                    <mat-radio-group
                      [formControlName]="'is_deleting_all'"
                      class="flex flex-row"
                    >
                      <mat-radio-button
                        class="bunk-outlined-radio mr_5"
                        [value]="true"
                        [ngClass]="{
                          'bunk-outlined-radio--selected':
                            is_deleting_all.value === true
                        }"
                        >Yes, delete all</mat-radio-button
                      >
                      <mat-radio-button
                        class="bunk-outlined-radio"
                        [value]="false"
                        [ngClass]="{
                          'bunk-outlined-radio--selected':
                            is_deleting_all.value === false
                        }"
                        >No, just this one</mat-radio-button
                      >
                    </mat-radio-group>
                  </div>
                </bunk-form-field>
              </form>
            </div>

            <div class="flex flex-row margin-top--md justify-between">
              <button
                class="btn__seethrough"
                type="button"
                (click)="dref.close()"
              >
                Close
              </button>
              <button class="btn__warning" (click)="deletePayments(element)">
                <processing-spinner
                  *ngIf="deletePaymentsSpinner"
                  color="#FFFFFF"
                  size="20px"
                ></processing-spinner>
                <span>{{
                  is_deleting_all.value
                    ? 'Yes, delete all'
                    : 'Delete one payment'
                }}</span>
              </button>
            </div>
          </ng-template>
          <ng-template #editPayments>
            <h3 class="text--md">Update Payment</h3>
            <p class="margin-top--md">
              You are editing the payment for
              <strong>{{ element.tenantFullName }}</strong> at
              <strong>{{ element.propertyAddress }}</strong
              >.
            </p>
            <ng-container *ngIf="element.status.value === RENT_STATUS.PAID">
              <p class="margin-top--md"><strong>Date Paid</strong></p>
              <p class="margin-top--md">{{ element.timestampPaid }}</p>
            </ng-container>
            <form
              [formGroup]="adminFormGroup"
              class="flex flex-column margin-top--md"
            >
              <mat-form-field appearance="outline">
                <mat-label>Rent Amount</mat-label>
                <mat-error>You need a valid rent amount</mat-error>
                <input matInput [formControlName]="'amount'" />
              </mat-form-field>
              <mat-form-field appearance="outline">
                <mat-label>Due Date:</mat-label>
                <input
                  [formControlName]="'due_date'"
                  matInput
                  (click)="dueDate.open()"
                  [matDatepicker]="dueDate"
                />
                <mat-datepicker-toggle
                  matSuffix
                  [for]="dueDate"
                ></mat-datepicker-toggle>
                <mat-datepicker #dueDate></mat-datepicker>
                <mat-error>This field is required</mat-error>
              </mat-form-field>

              <mat-form-field
                appearance="outline"
                class="w_100"
                *ngIf="isAdmin"
              >
                <mat-label>Notes</mat-label>
                <textarea
                  [formControlName]="'notes'"
                  row="3"
                  col="80"
                  maxlength="5000"
                  matInput
                  placeholder="Add a description"
                  [value]="element.notes"
                ></textarea>
                <mat-error>Description required</mat-error>
              </mat-form-field>
              <mat-form-field appearance="outline" *ngIf="isAdmin">
                <mat-label
                  >Is this payment being held (and not being paid out)?
                </mat-label>
                <mat-select [formControlName]="'is_being_held'">
                  <mat-option [value]="true"> Yes </mat-option>
                  <mat-option [value]="false"> No </mat-option>
                </mat-select>
              </mat-form-field>
            </form>
            <div class="flex flex-column items-center margin-top--md">
              <button
                class="btn__cta"
                (click)="updatePaymentDetails(element.id)"
              >
                Confirm Changes
              </button>
            </div>
          </ng-template>
        </td>
      </ng-container>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayAllColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayAllColumns"></tr>
  </table>
</div>
<mat-paginator
  [pageSizeOptions]="pageSize"
  [pageIndex]="page"
  [length]="totalHits"
  (page)="updatePage($event)"
></mat-paginator>


    <ng-container>
      <div>
        <h2 class="text--lg">Payment methods</h2>
        <p data-test="landlordVerificationSubtitle" *ngIf="!_user.user.payments_data.cards || !_user.user.payments_data.cards[0]">This information is required to setup your account.</p>
      </div>
      <div class="card-details margin-bottom--lg">
        <manage-cards [showCardsForm]="false" ></manage-cards>
        <cards-form (setupComplete)="addCard($event)" [disabled]="disableCardsForm" [clear]="clearCardsForm"></cards-form>
      </div>
      <div class="margin-bottom--xl">
        <button *ngIf="showPrev" (click)="goBack()" data-test="backButton" class="btn__seethrough btn__form-prev">Back</button>

        <button
          *ngIf="cardSubmitted && stepCompleted"
          class="btn__generic btn__form-submit btn__form-submit--align-right margin-top"
          [disabled]="!cardSubmitted && (_user.user.payments_data.cards && !_user.user.payments_data.cards[0])"
          (click)="nextStep()">
          <span>Continue</span>
        </button>

      </div>
    </ng-container>
  
import { Component, Input } from '@angular/core';

@Component({
    selector: 'counter',
    template: `<div *ngIf="value" class="counter" [ngClass]="{ 'counter--wide': wide }">{{ value < 10 ? value : '9+' }}</div>`,
    styleUrls: ['./counter.component.scss']
})
export class CounterComponent {
    @Input() value: string;
    @Input() wide: boolean;
}

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./bunk-overlay.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../loading-spinner/loading-spinner.component.ngfactory";
import * as i3 from "../loading-spinner/loading-spinner.component";
import * as i4 from "@angular/common";
import * as i5 from "../../../../../node_modules/@angular/material/icon/typings/index.ngfactory";
import * as i6 from "@angular/material/icon";
import * as i7 from "./pdf-preview-overlay.component";
import * as i8 from "./file-preview-overlay-ref";
import * as i9 from "@angular/platform-browser";
import * as i10 from "./file-preview-overlay.tokens";
var styles_PdfPreviewOverlayComponent = [i0.styles];
var RenderType_PdfPreviewOverlayComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PdfPreviewOverlayComponent, data: { "animation": [{ type: 7, name: "fade", definitions: [{ type: 0, name: "fadeOut", styles: { type: 6, styles: { opacity: 0 }, offset: null }, options: undefined }, { type: 0, name: "fadeIn", styles: { type: 6, styles: { opacity: 1 }, offset: null }, options: undefined }, { type: 1, expr: "* => fadeIn", animation: { type: 4, styles: null, timings: "400ms cubic-bezier(0.25, 0.8, 0.25, 1)" }, options: null }], options: {} }, { type: 7, name: "slideContent", definitions: [{ type: 0, name: "void", styles: { type: 6, styles: { transform: "translate3d(0, 25%, 0)", opacity: 0 }, offset: null }, options: undefined }, { type: 0, name: "enter", styles: { type: 6, styles: { transform: "none", opacity: 1 }, offset: null }, options: undefined }, { type: 0, name: "leave", styles: { type: 6, styles: { transform: "translate3d(0, 25%, 0)", opacity: 0 }, offset: null }, options: undefined }, { type: 1, expr: "* => *", animation: { type: 4, styles: null, timings: "400ms cubic-bezier(0.25, 0.8, 0.25, 1)" }, options: null }], options: {} }] } });
export { RenderType_PdfPreviewOverlayComponent as RenderType_PdfPreviewOverlayComponent };
function View_PdfPreviewOverlayComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "h2", [["class", "truncate text--md"]], null, null, null, null, null)), (_l()(), i1.ɵted(1, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.pdf.name; _ck(_v, 1, 0, currVal_0); }); }
function View_PdfPreviewOverlayComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "spinner-wrapper"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "loading-spinner", [], null, null, null, i2.View_LoadingSpinnerComponent_0, i2.RenderType_LoadingSpinnerComponent)), i1.ɵdid(2, 114688, null, 0, i3.LoadingSpinnerComponent, [], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
export function View_PdfPreviewOverlayComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { pdfFrameCont: 0 }), (_l()(), i1.ɵeld(1, 0, null, null, 13, "div", [["class", "overlay-content"]], [[24, "@slideContent", 0]], [[null, "@slideContent.start"], [null, "@slideContent.done"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("@slideContent.start" === en)) {
        var pd_0 = (_co.onAnimationStart($event) !== false);
        ad = (pd_0 && ad);
    } if (("@slideContent.done" === en)) {
        var pd_1 = (_co.onAnimationDone($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 12, "div", [["class", "bunk-overlay__external-content"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 6, "div", [["class", "bunk-overlay__header bunk-overlay__header--white"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PdfPreviewOverlayComponent_1)), i1.ɵdid(5, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 3, "button", [["class", "bunk-overlay__close-btn"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.close() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["close"])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "mat-icon", [["class", "mat-icon notranslate"], ["role", "img"], ["svgIcon", "icon-close"]], [[2, "mat-icon-inline", null], [2, "mat-icon-no-color", null]], null, null, i5.View_MatIcon_0, i5.RenderType_MatIcon)), i1.ɵdid(9, 9158656, null, 0, i6.MatIcon, [i1.ElementRef, i6.MatIconRegistry, [8, null], [2, i6.MAT_ICON_LOCATION], [2, i1.ErrorHandler]], { svgIcon: [0, "svgIcon"] }, null), (_l()(), i1.ɵeld(10, 0, null, null, 4, "div", [["class", "bunk-overlay__content bunk-overlay__content--scroll-y"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_PdfPreviewOverlayComponent_2)), i1.ɵdid(12, 16384, null, 0, i4.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(13, 0, [[1, 0], ["pdfFrame", 1]], null, 1, "iframe", [], [[24, "@fade", 0], [8, "src", 5]], null, null, null, null)), i1.ɵpid(131072, i4.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.pdf.name; _ck(_v, 5, 0, currVal_1); var currVal_4 = "icon-close"; _ck(_v, 9, 0, currVal_4); var currVal_5 = _co.loading; _ck(_v, 12, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.animationState; _ck(_v, 1, 0, currVal_0); var currVal_2 = i1.ɵnov(_v, 9).inline; var currVal_3 = (((i1.ɵnov(_v, 9).color !== "primary") && (i1.ɵnov(_v, 9).color !== "accent")) && (i1.ɵnov(_v, 9).color !== "warn")); _ck(_v, 8, 0, currVal_2, currVal_3); var currVal_6 = (_co.loading ? "fadeOut" : "fadeIn"); var currVal_7 = i1.ɵunv(_v, 13, 1, i1.ɵnov(_v, 14).transform(_co.src)); _ck(_v, 13, 0, currVal_6, currVal_7); }); }
export function View_PdfPreviewOverlayComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "pdf-preview-overlay", [], null, [["document", "keydown"]], function (_v, en, $event) { var ad = true; if (("document:keydown" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).handleKeydown($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_PdfPreviewOverlayComponent_0, RenderType_PdfPreviewOverlayComponent)), i1.ɵdid(1, 4440064, null, 0, i7.PdfPreviewOverlayComponent, [i8.FilePreviewOverlayRef, i9.DomSanitizer, i10.PDF_PREVIEW_DOC], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PdfPreviewOverlayComponentNgFactory = i1.ɵccf("pdf-preview-overlay", i7.PdfPreviewOverlayComponent, View_PdfPreviewOverlayComponent_Host_0, {}, {}, []);
export { PdfPreviewOverlayComponentNgFactory as PdfPreviewOverlayComponentNgFactory };

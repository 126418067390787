<mat-sidenav-container class="nav-topbar__bg" (backdropClick)="closeNotificationsSideBar()">
  <mat-sidenav
    class="notifications"
    [fixedInViewport]="true"
    [opened]="notebarNotificationOpen"
    mode="over"
    position={{position}}
    (keydown.escape)="closeNotificationsSideBar()"
    disableClose>
<!--    <div class="notifications-nav_header" *ngIf="position === 'end'">-->
<!--      <div class="title_3">Notifications</div>-->
<!--      <mat-icon svgIcon="icon-close" class="cursor-pointer" (click)="closeNotificationsSideBar()"></mat-icon>-->
<!--    </div>-->
    <mat-nav-list class="notification__side">
      <ng-content></ng-content>
    </mat-nav-list>
  </mat-sidenav>
</mat-sidenav-container>



      <div class="flex justify-end">
        <div class="notifications-nav_header">
          <div class="title_3">Notifications</div>
          <mat-icon [matMenuTriggerFor]="list" aria-hidden="false" aria-label="Example home icon" class="cursor-pointer">more_vert</mat-icon>
          <mat-menu #list="matMenu">
            <button class="btn btn--sm menu__item" mat-menu-item (click)="clearNotifications()">
              <span>Mark all as read</span>
            </button>
            <button class="btn btn--sm menu__item" mat-menu-item (click)="clearNotifications(true)">
              <span>Clear all notifications</span>
            </button>
          </mat-menu>
        </div>

      </div>
      <infinite-scroll [batch]='8' [getScrollQuery]="this.queryData" (emitTheEnd)="hasEnded($event)">
        <ng-template let-data>
          <li [ngClass]="{'unread': !data.notification_is_viewed}" (click)="updateNotificationToRead(data.notification_id, data.category, data.reference_id)">
            <p class="text--md mb_0">{{data.title | titlecase}}</p>
            <p class="text--subtle text--sm mb_0">{{data.body | truncate:[80, '...']}}</p>
            <small class="text--subtle">{{getMomentTime(data)}}</small>
          </li>
        </ng-template>
        <ng-container *ngIf="theEnd">
          <div class="flex justify-center margin-top--sm margin-bottom--sm">
            <p class="text--subtle">You've reached the end of your notifications.</p>
          </div>
        </ng-container>
      </infinite-scroll>
  
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {FormAssignRoomComponent} from './form-assign-room.component'
import { MaterialModule } from '@app/shared/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule.withConfig({ warnOnNgModelWithFormControl: 'always' }),

  ],
  exports:[FormAssignRoomComponent],
  declarations: [FormAssignRoomComponent],
})
export class FormAssignRoomModule { }

export const MIN_TENANT_AGE = 16;

export const TITLES: object[] = [
  { viewValue: 'Mr', value: 'mr' },
  { viewValue: 'Mrs', value: 'mrs' },
  { viewValue: 'Miss', value: 'miss' },
  { viewValue: 'Ms', value: 'ms' },
  { viewValue: 'Dr', value: 'dr' },
  { viewValue: 'Mstr', value: 'mstr' },
  { viewValue: 'Mx', value: 'mx'}
];

export const COUNTRY_CODES: (string | number)[] = [
  93, 358, 355, 213, 1 + ' ' + 684, 376, 244, 1 + ' ' + 264, 672,
  1268, 54, 374, 297, 61, 43, 994, 1242, 973, 880, 1246, 375, 32, 501, 229, 1441, 975,
  591, 387, 267, 55, 246, 673, 359, 226, 257, 855, 237, 238, 345, 236, 235, 56, 86,
  57, 269, 242, 243, 682, 506, 225, 385, 53, 357, 420, 45, 253, 1767, 1849, 593, 20, 503,
  240, 291, 372, 251, 500, 298, 679, 33, 594, 689, 241, 220, 995, 49, 233, 350, 30,
  299, 1473, 590, 1671, 502, 224, 245, 595, 509, 379, 504, 852, 36, 354, 91, 62, 98,
  964, 353, 972, 39, 1876, 81, 962, 7 + ' ' + 7, 254, 686, 850, 82, 965, 996, 856, 371,
  961, 266, 231, 218, 423, 370, 352, 853, 389, 261, 265, 60, 960, 223, 356, 692, 596, 222,
  230, 262, 52, 691, 373, 377, 976, 382, 1664, 212, 258, 85, 264, 674, 977, 31, 599, 687,
  64, 505, 227, 234, 683, 1 + ' ' + 670, 968, 92, 680, 970, 507, 675, 51, 63, 872,
  48, 351, 1 + ' ' + 939, 974, 40, 7, 250, 290, 1 + ' ' + 869, 1758, 508, 1784, 685, 378, 239,
  966, 221, 381, 248, 232, 65, 421, 386, 677, 252, 27, 34, 94, 249, 597, 47, 268,
  46, 41, 963, 886, 992, 255, 66, 670, 228, 690, 676, 1868, 216, 90, 993, 1649, 688, 256,
  380, 971, 44, 1, 598, 998, 678, 58, 84, 1284, 1340, 681, 967, 260, 263
];

export const GENDER_VALUES: object[] = [
  { value: 'male', viewValue: 'Male'},
  { value: 'female', viewValue: 'Female'},
  { value: 'other', viewValue: 'Other' }
];

export const EMPLOYMENT_TYPES: object[] = [
  { value: 'full_time', text: 'Full-time' },
  { value: 'part_time', text: 'Part-time' },
  { value: 'self_employed', text: 'Self-employed' },
  { value: 'unemployed', text: 'Unemployed' },
];

export const TABLE_ITEMS_PP: number[] = [10, 25, 100];

export const RENT_RESPONSIBILITY = [
  {
    viewValue: 'Guarantor',
    value: 'guarantor'
  },
  {
    viewValue: 'Tenant',
    value: 'tenant'
  },
  {
    viewValue: 'Paid Yearly in Advance',
    value: 'paid_yearly_in_advance'
  }
];

export const PAYMENT_PREFERENCE = [
  {
    viewValue: 'Yearly',
    value: 'yearly'
  },
  {
    viewValue: 'Every 6 months (UK Guarantor Required)',
    value: 'six_months'
  },
  {
    viewValue: 'Quarterly (UK Guarantor Required)',
    value: 'quarterly'
  }
];

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./bunk-uploader.component";
import * as i2 from "../../../services/storage.service";
import * as i3 from "../../../services/media.service";
var styles_BunkUploaderComponent = [];
var RenderType_BunkUploaderComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_BunkUploaderComponent, data: {} });
export { RenderType_BunkUploaderComponent as RenderType_BunkUploaderComponent };
export function View_BunkUploaderComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(671088640, 1, { fileInputRef: 0 }), (_l()(), i0.ɵeld(1, 0, [[1, 0], ["inputRef", 1]], null, 0, "input", [["type", "file"]], [[8, "multiple", 0], [8, "accept", 0]], [[null, "change"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("change" === en)) {
        var pd_0 = (_co.uploadFiles($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.multiple; var currVal_1 = _co.accept; _ck(_v, 1, 0, currVal_0, currVal_1); }); }
export function View_BunkUploaderComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "bunk-uploader", [], null, null, null, View_BunkUploaderComponent_0, RenderType_BunkUploaderComponent)), i0.ɵdid(1, 49152, null, 0, i1.BunkUploaderComponent, [i2.StorageService, i3.MediaService], null, null)], null, null); }
var BunkUploaderComponentNgFactory = i0.ɵccf("bunk-uploader", i1.BunkUploaderComponent, View_BunkUploaderComponent_Host_0, { filePath: "filePath", multiple: "multiple", accept: "accept", mediaData: "mediaData" }, { uploadStart: "uploadStart", uploadChange: "uploadChange" }, []);
export { BunkUploaderComponentNgFactory as BunkUploaderComponentNgFactory };

import { AngularFirestore } from '@angular/fire/firestore';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/fire/firestore";
export class TypesService {
    constructor(afs) {
        this.afs = afs;
    }
    getTypes(type) {
        return this.afs.collection('types', (ref) => ref.where('type', '==', type)
            .where('is_default', '==', true)).valueChanges().pipe(map(res => { console.log('iam from expense service::', res); return res[0]; }), map((res) => res.types));
    }
    getViewValuePairs(types) {
        return of(types).pipe(map((types) => Object.keys(types).map(key => ({ value: key, viewValue: types[key] }))));
    }
}
TypesService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TypesService_Factory() { return new TypesService(i0.ɵɵinject(i1.AngularFirestore)); }, token: TypesService, providedIn: "root" });

import { Component, Input, OnInit } from '@angular/core';
import { TitleCasePipe } from '@angular/common';
import { Shared } from '@env/routing';
import { environment } from '@env/environment';
import { Observable } from 'rxjs/Observable';
import { PropertyService } from '@app/services';
import { of } from 'rxjs';
import { PropertyType } from '@app/_constants/property_docs.constants';


@Component({
  selector: 'property-card-mobile',
  templateUrl: './property-card-mobile.component.html',
  styleUrls: ['./property-card-mobile.component.scss']
})
export class PropertyCardMobileComponent implements OnInit {

  @Input() property: any;
  @Input() bedroomId: any;
  @Input() elemAtTop: boolean;
  routes = { Shared };
  overRef: any;
  display: any = {};
  public bedroom$: Observable<any>;
  public client_data = environment.client_data;

  constructor(
    public titlecasePipe: TitleCasePipe,
    public _property: PropertyService,
  ) {

  }

  ngOnInit() {
    this.bedroom$ = this.bedroomId ? this._property.getBedroomById(this.bedroomId, this.property.property_id).valueChanges() : of({});

    const thoroughfare = this.property.address.thoroughfare ? this.property.address.thoroughfare + (this.property.address.post_code ? ', ' + this.property.address.post_code.split(' ')[0] : '') : '';
    const housePhoto = this.property.property_photos.length ? this.property.property_photos[0].image_small_url : undefined;
    const bedroomPhoto = this.bedroomId && this.property.bedrooms[this.bedroomId].room_thumbnail_url && this.property.bedrooms[this.bedroomId].room_thumbnail_url.image_url !== null ?
      this.property.bedrooms[this.bedroomId].room_thumbnail_url.image_small_url : housePhoto;

    this.display = {
      photo: this.bedroomId ? bedroomPhoto : housePhoto,
      propertyType: this.property.number_bedrooms ?
        this.property.number_bedrooms + ' ' + this.client_data.unit_name + ' ' + PropertyType[this.property.property_type].toLowerCase()
        : PropertyType[this.property.property_type],
      thoroughfare,
    };
  }

}

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./tenant-account-step.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../shared/_components/payment-methods/cards/cards.component.ngfactory";
import * as i4 from "../../shared/_components/payment-methods/cards/cards.component";
import * as i5 from "../../services/stripe.service";
import * as i6 from "../../services/user.service";
import * as i7 from "ngx-toastr";
import * as i8 from "../../shared/_forms/payment-forms/cards-form.component.ngfactory";
import * as i9 from "../../shared/_forms/payment-forms/cards-form.component";
import * as i10 from "@angular/forms";
import * as i11 from "./tenant-account-verify-payment.component";
import * as i12 from "../profile-tenant.service";
import * as i13 from "../../services/user-verification.service";
import * as i14 from "../../services/bunk-passport.service";
import * as i15 from "../../core/services/auth.service";
import * as i16 from "@angular/router";
var styles_TenantAccountVerifyPaymentComponent = [i0.styles];
var RenderType_TenantAccountVerifyPaymentComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TenantAccountVerifyPaymentComponent, data: {} });
export { RenderType_TenantAccountVerifyPaymentComponent as RenderType_TenantAccountVerifyPaymentComponent };
function View_TenantAccountVerifyPaymentComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "p", [["data-test", "landlordVerificationSubtitle"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["This information is required to setup your account."]))], null, null); }
function View_TenantAccountVerifyPaymentComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "btn__seethrough btn__form-prev"], ["data-test", "backButton"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.goBack() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["Back"]))], null, null); }
function View_TenantAccountVerifyPaymentComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "button", [["class", "btn__generic btn__form-submit btn__form-submit--align-right margin-top"]], [[8, "disabled", 0]], [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.nextStep() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Continue"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = (!_co.cardSubmitted && (_co._user.user.payments_data.cards && !_co._user.user.payments_data.cards[0])); _ck(_v, 0, 0, currVal_0); }); }
export function View_TenantAccountVerifyPaymentComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 15, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 4, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "h2", [["class", "text--lg"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Payment methods"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TenantAccountVerifyPaymentComponent_1)), i1.ɵdid(5, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(6, 0, null, null, 4, "div", [["class", "card-details margin-bottom--lg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "manage-cards", [], null, null, null, i3.View_CardsComponent_0, i3.RenderType_CardsComponent)), i1.ɵdid(8, 245760, null, 0, i4.CardsComponent, [i5.StripeService, i6.UserService, i7.ToastrService], { showCardsForm: [0, "showCardsForm"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 1, "cards-form", [], null, [[null, "setupComplete"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("setupComplete" === en)) {
        var pd_0 = (_co.addCard($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i8.View_CardsFormComponent_0, i8.RenderType_CardsFormComponent)), i1.ɵdid(10, 4440064, null, 0, i9.CardsFormComponent, [i1.ChangeDetectorRef, i6.UserService, i7.ToastrService, i5.StripeService, i10.FormBuilder], { disabled: [0, "disabled"], clear: [1, "clear"] }, { setupComplete: "setupComplete" }), (_l()(), i1.ɵeld(11, 0, null, null, 4, "div", [["class", "margin-bottom--xl"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TenantAccountVerifyPaymentComponent_2)), i1.ɵdid(13, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TenantAccountVerifyPaymentComponent_3)), i1.ɵdid(15, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (!_co._user.user.payments_data.cards || !_co._user.user.payments_data.cards[0]); _ck(_v, 5, 0, currVal_0); var currVal_1 = false; _ck(_v, 8, 0, currVal_1); var currVal_2 = _co.disableCardsForm; var currVal_3 = _co.clearCardsForm; _ck(_v, 10, 0, currVal_2, currVal_3); var currVal_4 = _co.showPrev; _ck(_v, 13, 0, currVal_4); var currVal_5 = (_co.cardSubmitted && _co.stepCompleted); _ck(_v, 15, 0, currVal_5); }, null); }
export function View_TenantAccountVerifyPaymentComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "tenant-account-verify-payment", [], null, null, null, View_TenantAccountVerifyPaymentComponent_0, RenderType_TenantAccountVerifyPaymentComponent)), i1.ɵdid(1, 180224, null, 0, i11.TenantAccountVerifyPaymentComponent, [i12.TenantVerifyEventEmiterService, i13.UserVerificationService, i5.StripeService, i6.UserService, i14.BunkPassportService, i15.AuthService, i16.ActivatedRoute], null, null)], null, null); }
var TenantAccountVerifyPaymentComponentNgFactory = i1.ɵccf("tenant-account-verify-payment", i11.TenantAccountVerifyPaymentComponent, View_TenantAccountVerifyPaymentComponent_Host_0, { showPrev: "showPrev" }, {}, []);
export { TenantAccountVerifyPaymentComponentNgFactory as TenantAccountVerifyPaymentComponentNgFactory };

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import {AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument} from '@angular/fire/firestore';
import { environment } from '@env/environment';
import {from as fromPromise} from 'rxjs';
import {Observable} from 'rxjs/Observable';

@Injectable({
  providedIn: 'root'
})
export class AdminService {
  public transactionCollection: AngularFirestoreCollection<any>;
  public balanceCollection: AngularFirestoreCollection<any>;
  public referenceOutlawsCollection: AngularFirestoreCollection<any>;


  constructor(
    private readonly afs: AngularFirestore,
    private _http: HttpClient,
  ) {
    this.transactionCollection = afs.collection<any>('transactions');
    this.balanceCollection = afs.collection<any>('account_balances');
    this.referenceOutlawsCollection = afs.collection<any>('reference_outlaws');
  }

  createDemoUser(form: any) {
    return this._http.post(environment.firebaseConfig.apiUrl + '/adminFirestore-adminCreateDemoUser', {form});
  }

  continueCreateDemoUser(team_id: string, uid: string) {
    return this._http.post(environment.firebaseConfig.apiUrl + '/adminFirestore-adminContinueCreateDemoUser', {team_id, uid});
  }

  removeNewDemoUser(uid: string) {
    console.log({uid})
    return this._http.post(environment.firebaseConfig.apiUrl + '/adminFirestore-adminRemoveNewDemoUser', {uid});
  }

  runBalancingFormulas() {
    return this._http.post(environment.firebaseConfig.apiUrl + '/moneyhub-runTransactionBalancingFormulas', {});
  }

  updateTransaction(item: any, id: string): Observable<any> {
    return fromPromise(this.transactionCollection.doc(id).update(item));
  }

  getAccountBalanceDocument(): AngularFirestoreDocument<any> {
    return this.balanceCollection.doc('client_account');
  }

  createReferenceOutlaw(refs: any): Observable<any>  {
    console.log(refs, 'hiya refs');
    const reference_outlaws_id = this.afs.createId();
    const date = new Date();
    const item = {
      reference_outlaws_id,
      reference_outlaw: refs.reference_outlaw.substring(0 ,  refs.reference_outlaw.lastIndexOf(' ')),
      user_reference_code: refs.user_reference_code.toUpperCase(),
      date_modified: date,
      date_created: date,
    };
    console.log(item, 'item');
    return fromPromise(this.referenceOutlawsCollection.doc(reference_outlaws_id).set(item));
  }

  matchTenancyToTransaction(transaction_id, tenancy_id) {
    console.log(transaction_id, tenancy_id);
    return this._http.post(environment.firebaseConfig.apiUrl + '/moneyhub-mapPaymentsByTenancy', {transaction_id, tenancy_id});

  }

  createUser(form: any) {
    return this._http.post(environment.firebaseConfig.apiUrl + '/adminFirestore-adminCreateUser', {form});
  }

  mapTransactionById(transaction_id) {
    const params = new HttpParams().set('id', transaction_id);
    return this._http.post(environment.firebaseConfig.apiUrl + '/transactions-httpMapTransaction', {}, { params });
  }


}

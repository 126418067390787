import { HostListener, EventEmitter } from '@angular/core';
import { AnimationEvent } from '@angular/animations';
import { FilePreviewOverlayRef } from './file-preview-overlay-ref';

const ESCAPE = 27;

export class PreviewOverlayComponent {
  public loading = true;
  animationState: 'void' | 'enter' | 'leave' = 'enter';
  animationStateChanged = new EventEmitter<AnimationEvent>();
  

  @HostListener('document:keydown', ['$event']) private handleKeydown(event: KeyboardEvent) {
    if (event.keyCode === ESCAPE) {
      this.close();
    }
  }

  constructor(
    public dialogRef: FilePreviewOverlayRef) {}

  onAnimationStart(event: AnimationEvent) {
    this.animationStateChanged.emit(event);
  }

  onAnimationDone(event: AnimationEvent) {
    this.animationStateChanged.emit(event);
  }

  startExitAnimation() {
    this.animationState = 'leave';
  }

  close() {
    this.dialogRef.close();
  }

  onLoad(event: Event) {
    this.loading = false;
  }
}

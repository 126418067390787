import { Component, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@app/core/services';
import { StorageLocalService, UserService } from '@app/services';
import { environment } from '@env/environment';
import { Auth, Shared, Tenant } from '@env/routing';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { finalize, first, flatMap, map } from 'rxjs/operators';


@Component({
  selector: 'app-signin',
  templateUrl: './activate-account.component.html',
  styleUrls: [`./signin.component.scss`]
})
export class ActivateAccountComponent implements OnDestroy {
  public passwordStrength$: Observable<any>;
  public isDesktop: boolean
  public routes = { Auth, Shared };
  public formGroup: FormGroup;
  public isPasswordForgotten = false;
  public showSpinner = false;
  public emailParam;
  private destroy$: Subject<boolean> = new Subject<boolean>();
  public client_data = environment.client_data;
  showError = false;
  hideForm = false;
  passwordReset = false;
  get email(): FormGroup {
    return this.formGroup.get('email') as FormGroup;
  }

  get password(): FormGroup {
    return this.formGroup.get('password') as FormGroup;
  }

  constructor(
    public _auth: AuthService,
    private _user: UserService,
    private _formBuilder: FormBuilder,
    private _router: Router,
    private _toastr: ToastrService,
    private route: ActivatedRoute,
    protected _localStorage: StorageLocalService,
  ) {
    this.formGroup = this.initFormGroup();
    if (!this._localStorage.getStore('account-activation') || !this._localStorage.getStore('account-activation').email) {
      this.emailParam = this.route.snapshot ? this.route.snapshot.queryParamMap.get('email') : null;
      if (this.emailParam) {
        this._localStorage.saveStore('account-activation', { email: this.emailParam });

        this.email.setValue(this._localStorage.getStore('account-activation') ? this._localStorage.getStore('account-activation').email : null);
        this.email.updateValueAndValidity();
        if (this.email.value) {
          this._router.navigate([], { queryParams: { email: null } });
        } else {
          this.email.setValue(this.emailParam);
        }
      }
    } else {
      this.email.setValue(this._localStorage.getStore('account-activation').email);
    }

    this.passwordStrength$ = this.formGroup.get('password').valueChanges.pipe(
      map((val: string) => {
        return {
          hasLowercase: /[a-z]/.test(val),
          hasUppercase: /[A-Z]/.test(val),
          hasNumber: /\d/.test(val),
          hasLength: val.length >= environment.minimumPasswordLength
        };
      })
    );
  }

  initFormGroup(): FormGroup {
    return this._formBuilder.group({
      email: ['', Validators.required],
      password: ['', [Validators.required, Validators.pattern('^(?:(?:(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]))|(?:(?=.*[a-z])(?=.*[A-Z])(?=.*[*.!@$%^&(){}[]:;<>,.?/~_+-=|\]))|(?:(?=.*[0-9])(?=.*[A-Z])(?=.*[*.!@$%^&(){}[]:;<>,.?/~_+-=|\]))|(?:(?=.*[0-9])(?=.*[a-z])(?=.*[*.!@$%^&(){}[]:;<>,.?/~_+-=|\]))).{8,32}$')]],
    });
  }

  activateAccount() {
    if (this.formGroup.valid) {
      this.showError = false;
      this.formGroup.disable();
      this.showSpinner = true;
      return this._auth.activateUserAccount(this.formGroup.getRawValue()).pipe(
        flatMap(() => this.emailLogin()),
      ).subscribe(
        () => {
          this.showSpinner = false;
          this._localStorage.clearStore();
          this._router.navigate(['/' + Tenant.base, Tenant.manage.base]);
        },
        (err: any) => {
          this.formGroup.enable();
          this.showSpinner = false;
          if (err.code === 'auth/wrong-password' || err.code === 'auth/invalid-email' || err.code === 'auth/user-not-found') {
            this.showError = true;
          }
        }
      );
    }
  }

  trim(controlName) {
    this.formGroup.get(controlName).patchValue(this.formGroup.get(controlName).value.trim())
  }

  emailLogin() {
    return this._auth.signInWithEmailAndPassword({ email: this.email.value, password: this.password.value })
      .pipe(
        finalize(() => this.showSpinner = false),
        flatMap(() =>
          this._user.user$
        ),
        first());
  }


  toggleIsPasswordForgotten(): void {
    this.isPasswordForgotten = !this.isPasswordForgotten;
  }


  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }



}

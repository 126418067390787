/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./ie-users.component";
var styles_IeUsersComponent = [];
var RenderType_IeUsersComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_IeUsersComponent, data: {} });
export { RenderType_IeUsersComponent as RenderType_IeUsersComponent };
export function View_IeUsersComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 7, "div", [["class", "main__wrapper"]], null, null, null, null, null)), (_l()(), i0.ɵeld(1, 0, null, null, 6, "div", [["class", "empty__state"]], null, null, null, null, null)), (_l()(), i0.ɵeld(2, 0, null, null, 1, "h1", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["The Internet Explorer site is coming soon... "])), (_l()(), i0.ɵeld(4, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, ["In the meantime please upgrade to a modern browser."])), (_l()(), i0.ɵeld(6, 0, null, null, 1, "a", [["class", "btn__seethrough"], ["href", "https://bestvpn.org/outdatedbrowser/en"], ["target", "_blank"]], null, null, null, null, null)), (_l()(), i0.ɵted(-1, null, [" Download another browser"]))], null, null); }
export function View_IeUsersComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "ie-users", [], null, null, null, View_IeUsersComponent_0, RenderType_IeUsersComponent)), i0.ɵdid(1, 49152, null, 0, i1.IeUsersComponent, [], null, null)], null, null); }
var IeUsersComponentNgFactory = i0.ɵccf("ie-users", i1.IeUsersComponent, View_IeUsersComponent_Host_0, {}, {}, []);
export { IeUsersComponentNgFactory as IeUsersComponentNgFactory };

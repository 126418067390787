import { Component, Input, OnDestroy, ViewChild, HostListener } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NotesService, TypesService, UserService, StorageService } from '@app/services';
import { Observable, of, zip, Subject, combineLatest, BehaviorSubject } from 'rxjs';
import { last, map, mergeMap, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { PropertyModel } from '@app/core-landlord/property/_models/property.model';
import { NotesModel } from './notes.model';
import { ActivatedRoute } from '@angular/router';
import {AuthService} from '@app/core/services';
import { BunkUploaderModel } from '..';
import { MediaService } from '@app/services/media.service';
import {environment} from '@env/environment.ts';


@Component({
  selector: 'notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss']
})
export class NotesComponent implements OnDestroy {
  isDesktop: boolean;
  notes$: Observable<any> = of([]);
  public notesFilter$: BehaviorSubject<any> = new BehaviorSubject<any>('');
  notesRaw$: Observable<any> = of([]);
  noteFormGroup: FormGroup;
  notesLength: any = 0;
  dref: any;
  userNote;
  propertyId: any;
  viewingId: any;
  bedroomId: any;
  setTenancyCalled: boolean;
  users: object = {};
  private destroy$: Subject<boolean> = new Subject<boolean>();
  scrollContainerHeight = '45';
  noteHeight = '100';
  windowWidth;
  editNoteMode = false;
  client_data = environment.client_data;
  attachTo = [
    {viewValue: 'Tenancy', value: 'tenancy'},
    {viewValue: `${this.client_data.unit_name}`, value: 'bedroom'},
];
noteTypeObject = {};
  attachType = {
    'tenancy': 'Tenancy',
    'bedroom': 'Bedroom',
    'property': 'Property',
    'viewing': 'Viewing',
    'Apartment': 'Bedroom'
  };
  public documentFile: any;
  public processingFiles = false;
  public totalFiles;
  public errorsMessage = undefined;
  public errorsCount = 0;
  public fileLoadCounter = 0;
  noteTypes$: Observable<any> = of([]);
  // refers cdk virtual scroll
  @ViewChild('scrollViewport', { static: false })
  private cdkVirtualScrollViewport;
  @Input() headerTitle = 'Notes';
  @Input() maintenanceId: any;
  public tenancy: any;

  public viewing: any;

  public property: PropertyModel;

  @Input()
  get tenancyData(): any {
    return this.tenancy;
  }
  set tenancyData(event: any) {
    if (event) {
      this.tenancy = event;
    }
    this.setTenancyCalled = true;
    if (this.property)
      this.getNotes();
  }

  @Input()
  get viewingData(): any {
    return this.viewing;
  }
  set viewingData(event: any) {
    if (event) {
      this.viewing = event;
    }
  }

  @Input()
  get propertyData(): PropertyModel {
    return this.property;
  }
  set propertyData(event: PropertyModel) {
    if (event) {
      this.property = event;
    }
    if (!(this.property.is_hmo && this.bedroomId)) {
      this.attachTo[1].value = 'property';
    }
    if (this.setTenancyCalled || this.viewingId || this.maintenanceId)
      this.getNotes();
  }
  // adjust height of cdk virtual scroll on window resize
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.windowWidth = event.target.innerWidth;
    this.setScrollHeight(this.notesLength);
  }
  constructor(private _formBuilder: FormBuilder,
              private _auth: AuthService,
              private _notesService: NotesService,
              private _route: ActivatedRoute,
              private _user: UserService,
              private _storage: StorageService,
              private _media: MediaService,
              private _types: TypesService,

  ) {
    this.windowWidth = window.innerWidth;
    this.propertyId = this._route.snapshot && this._route.snapshot.params && this._route.snapshot.params['propertyId'];
    this.bedroomId = this._route.snapshot && this._route.snapshot.params && this._route.snapshot.params['bedroomId'];
    this.viewingId = this._route.snapshot && this._route.snapshot.params && this._route.snapshot.params['viewingId'];
    this.noteTypes$ = this._types.getTypes('notes').pipe(
      switchMap(res=> {
        this.noteTypeObject = res;
        return this._types.getViewValuePairs(res);
      })
    )
  }

  getNotes() {
    this.setScrollHeight(0);
    const notesObservable: Observable<any>[] = new Array();
    if (this.bedroomId) {
      notesObservable.push(this._notesService.getRoomNotes(this.propertyId, this.bedroomId));
      if (this.tenancy) {
        notesObservable.push(this._notesService.getRoomNotesWithTenancy(this.propertyId, this.bedroomId, this.tenancy));
      }
    } else if (this.viewingId) {
      notesObservable.push(this._notesService.getPropertyViewingNotes(this.property.property_id, this.viewingId));
    } else if (this.maintenanceId) {
      notesObservable.push(this._notesService.getMaintenanceNotes(this.property.property_id, this.maintenanceId));
    } else {
      notesObservable.push(this._notesService.getPropertyNotes(this.propertyId));
      if (this.tenancy) {
        notesObservable.push(this._notesService.getPropertyNotesWithTenancy(this.propertyId, this.tenancy));
      }
    }
    this.notesRaw$ = combineLatest(notesObservable).pipe(
      takeUntil(this.destroy$),
      map((res: any) => {
        return res.flat();
      }),
      map(notes => notes.map(note => {
          if (this.users[note.creator_uid]) {
            return of({ ...note, creator_full_name: this.users[note.creator_uid] });
          } else {
            return this._user.getUserById(note.creator_uid).valueChanges().pipe(
              map(user => ({ ...note, creator_full_name: user.profile_data.full_name }))
            );
          }
        }
      )),
      switchMap((notes: any) => zip(...notes)),
      map(notes=>{
        return notes.map((note:any)=>{
          return note.media_ids && note.media_ids.length ? this._media.getMediaFromIds(note.media_ids).pipe(map(media=>({...note,media})))
          : of(note)
         })
      }),
      switchMap((notes: any) => zip(...notes)),
    );

    this.notes$ = combineLatest([this.notesRaw$,this.notesFilter$]).pipe(
      map(([notes,filter])=>{
        return notes.filter(note=>{
          const FilteredTitlePresent = note.title && note.title.toLowerCase().includes(filter.toLowerCase());
          const FilteredDescriptionPresent = note.description && note.description.toLowerCase().includes(filter.toLowerCase());
          const FilteredCreatorPresent = note.creator_full_name && note.creator_full_name.toLowerCase().includes(filter.toLowerCase());
          const FilteredTypePresent = note.note_type && note.note_type.toLowerCase().includes(filter.toLowerCase());
         return FilteredTitlePresent || FilteredCreatorPresent || FilteredTypePresent || FilteredDescriptionPresent;
        })
      }),
      map((notes: any) => {
        return notes.sort((a, b) => ((a.date_modified && b.date_modified) ? (b.date_modified.toDate() - a.date_modified.toDate()) :
          (a.date_modified ? (b.date_created.toDate() - a.date_modified.toDate()) :
            (b.date_modified ? (b.date_modified.toDate() - a.date_created.toDate()) :
              b.date_created.toDate() - a.date_created.toDate()))));
      }),
      tap(notes=>{
        this.notesLength = notes.length;
        this.setScrollHeight(this.notesLength)
      })
    );
  }


  get filePath(){
    return 'notes/'+this.note_id.value;
  }

  get title(): FormControl {
    return this.noteFormGroup.get('title') as FormControl;
  }

  get description(): FormControl {
    return this.noteFormGroup.get('description') as FormControl;
  }
  get note_id():FormControl{
    return this.noteFormGroup.get('note_id') as FormControl;
  }
  get media_ids(): FormArray {
    return this.noteFormGroup.get('media_ids') as FormArray;
  }
  openDialog(edit) {
    this.editNoteMode = edit;
    this.noteFormGroup = this.initNoteForm();
  }

  saveNote(note: NotesModel) {
    if (this.noteFormGroup.invalid) {
      return;
    }
    const media_ids = this.media_ids.value.map(media => media.media_id);
    note = {
      ...note, media_ids, creator_uid: this._auth.currentUserId,
      property_id: this.property.property_id || null, team_id: this.property.team_id || null
    };

    if (this.viewingId) {
      note.viewing_id = this.viewingId;
    }
    if (note['type'] == 'tenancy') {
      note.tenancy_id = this.tenancy;
    }
    if (this.bedroomId) {
      note.bedroom_id = this.bedroomId;
    }
    if (this.maintenanceId) {
      note.maintenance_id = this.maintenanceId;
    }

    const saveNote = this._notesService.saveNotesWithCustomId(note, note.note_id).subscribe();
    saveNote.unsubscribe();
    this.dref.close();
  }


  updateNote(note: NotesModel) {
    if (this.noteFormGroup.invalid) {
      return;
    }
    if (this.bedroomId || (this.tenancy && !this.property.is_hmo)) {
      note.tenancy_id = note['type'] === 'tenancy' ? this.tenancy : null;
    }
    const media_ids = this.media_ids.value.map(media => media.media_id);
    const updateNote = this._notesService.updateNote({...note, media_ids}).subscribe();
    updateNote.unsubscribe();
    this.dref.close();
  }

  editNote(note: NotesModel) {
    this.noteFormGroup.patchValue({
      title: note.title ? note.title : null,
      description: note.description,
      note_id: note.note_id,
      type: note.type,
      media_ids: [],
      note_type: note.note_type ? note.note_type : null
    });
    if (note.media_ids && note.media_ids.length) {
      this._media.getMediaFromIds(note.media_ids).pipe(take(1)).subscribe(mediaArray => {
        mediaArray.forEach(media => {
          this.media_ids.push(this._formBuilder.group({...media, existingMedia: true}));
        });
      }, err => {console.log('error', err); });
    }

  }
  initNoteForm() {
    const type = this.viewingId ? 'viewing' : (this.bedroomId ? 'bedroom' : (this.propertyId ? 'property' : 'maintenance'));
    return this._formBuilder.group({
      title: [null],
      description: null,
      note_id: this._notesService.generateId(),
      type: type,
      media_ids: this._formBuilder.array([]),
      note_type: [null],
    });
  }

  limitText(description, type= 'desc') {
    switch (type) {
      case 'desc':    if (description && description.length > 135) {
                          return description.replace(/<br\s*[\/]?>/gi, '').slice(0, 135) + '...';
                      } else {
                          return description;
                      }
      case 'title':   const trimLength = this.windowWidth >= 426 ? 27 : 35;
                      if (description && description.length > trimLength) {
                          return description.replace(/<br\s*[\/]?>/gi, '').slice(0, trimLength) + '...';
                      } else {
                          return description;
                      }
      default: return description;
    }

  }

  viewThisNote(note) {
    this.userNote = note;
  }
  setScrollHeight(length) {
    if (length > 0) {
      if (this.windowWidth >= 426) {
        // not small device
        this.noteHeight = '140';
        if (length < 4) {
          this.scrollContainerHeight = `${(length * 140)}`;
        } else {
          this.scrollContainerHeight = '560';
        }
      } else {
        // small device
        this.noteHeight = '220';
        if (length < 2) {
          this.scrollContainerHeight = `${(length * 220)}`;
        } else {
          this.scrollContainerHeight = '440';
        }
      }
    } else {
      this.scrollContainerHeight = '45';
    }
    setTimeout(() => {
      // Makes CdkVirtualScrollViewport to refresh its internal size values after
      // changing the container height. This should be delayed with a "setTimeout"
      // because we want it to be executed after the container has effectively
      // changed its height.

      this.cdkVirtualScrollViewport && this.cdkVirtualScrollViewport.checkViewportSize();
    }, 300);
  }

  applyFilter(keyword:string){
    this.notesFilter$.next(keyword)
  }


  onUploadedFile = (task: BunkUploaderModel) => {
    this.fileLoadCounter++;

    if (task.file && task.image) {
      this.media_ids.push(this._formBuilder.group({
        name: task.file.name,
        image_large_url: task.image.image_large_url,
        image_small_url: task.image.image_small_url,
        image_original_url: task.image.image_original_url,
        image_full_path: task.image.image_full_path,
        image_url: task.image.image_large_url,
        media_id: task.media_id
      }));
    } else {
      this.errorsCount++;
      this.errorsMessage = `There was a problem uploading ${this.errorsCount} file${this.errorsCount > 1 ? 's' : ''}.`;
    }

    if (this.fileLoadCounter >= this.totalFiles) {
      this.processingFiles = false;
      this.fileLoadCounter = 0;
    }
  };

  remove(image_urls: any, index: number) {

    for (const url of image_urls) {
      if (url) {
        this._storage.deleteFileFromStorageByURL(url);
      }
    }
    const deletingMedia = this.media_ids.at(index).value;
    this._media.removeMediaById(deletingMedia.media_id);
    this.media_ids.removeAt(index); // to remove from media_ids from group (to remove from ui)
    this.media_ids.updateValueAndValidity();
    if (deletingMedia.existingMedia) { // if removed media is already attached to note (if its id is stored in media_ids)
      const media_ids = this.media_ids.value.filter(media => media.existingMedia).map(media => media.media_id); // get media_id of medias which are already attached to this note
      this._notesService.updateNote({media_ids, note_id: this.note_id.value}); // updates media_ids after removing the deleted one
    }
  }

  onUpStart(task: BunkUploaderModel) {
    this.processingFiles = true;
    this.totalFiles = task.uploadsTotal;
    this.errorsMessage = undefined;
    this.errorsCount = 0;
  }

  openDocument(url: string) {
    window.open(url, '_blank');
  }

  isCertificatePDF = (url: string): boolean => {
    return (url.toString().toLowerCase().indexOf('.pdf') > 0);
  };
  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}

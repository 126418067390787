import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { DropdownMenuComponent } from './dropdown-menu.component';

@NgModule({
  imports: [
    CommonModule,
    MatIconModule,
    MatMenuModule
  ],
  exports: [DropdownMenuComponent],
  declarations: [DropdownMenuComponent]
})
export class DropdownMenuModule { }

import {Injectable} from '@angular/core';
import {PhoneNumberModel} from '@app/login/models/phone-number.model';
import {environment} from '@env/environment';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class PhoneVerificationService {

  constructor(
    private _http: HttpClient
  ) {}

  /*
 * Request Phone verification (Twilio)
 */
  requestPhoneVerification(phone: number, country_code: number) {
    // If country is not present set default UK country_code
    return this._http.post(environment.firebaseConfig.apiUrl + '/verification-send', {
      via: 'sms',
      phone_number: phone,
      country_code: country_code
    });
  }

  /*
   * Verify Phone (Twilio)
   */

  verifyPhone(phone: { verification_code: string, phone_number: string, country_code: number }) {
    return this._http.post(environment.firebaseConfig.apiUrl + '/verification-check', {
      verification_code: phone['verification_code'],
      phone_number: phone['phone_number'],
      country_code: phone['country_code']
    });
  }

}

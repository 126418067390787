import {ActivatedRoute, ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import { Injectable } from '@angular/core';
import {  References } from '@env/routing';
import { tap, map} from 'rxjs/operators';
import {TenancyOfferService, UserService} from '@app/services';


@Injectable()
export class VouchSelectionGuard implements CanActivate {

  constructor(private router: Router,
              private _tenancyOffer: TenancyOfferService,
              public _activatedRoute: ActivatedRoute,) {}

  canActivate(route: ActivatedRouteSnapshot,
              state: RouterStateSnapshot) {
                return  this._tenancyOffer.getTenancyOfferById(route.paramMap.get('offerId')).valueChanges().pipe(
                    map((offer: any) => {
                      if (offer) {
                        if(offer.references && offer.references.members &&  offer.references.members.some(member=> member.reference_id)){
                          this.router.navigate([state.url,References.summary])
                          return false;
                        }
                        return true;

                      }
                      return false
                    })
                  )

  }
}
@Injectable()
export class VouchReferenceGuard implements CanActivate {

  constructor(private router: Router,
              private _tenancyOffer: TenancyOfferService,
              public _activatedRoute: ActivatedRoute,) {}

  canActivate(route: ActivatedRouteSnapshot,
              state: RouterStateSnapshot) {
                const base = state.url.split('/').splice(1,2)
                return  this._tenancyOffer.getTenancyOfferById(route.parent.paramMap.get('offerId')).valueChanges().pipe(
                    map((offer: any) => {
                      if (offer) {
                        if(offer.references && offer.references.members &&  offer.references.members.some(member=> member.reference_id)){
                          this.router.navigate([...base,References.summary])
                          return false;
                        }
                        if(!(offer.references && offer.references.members) || !(offer && offer.references && offer.references.members && offer.references.members.some(member=> member.is_being_referenced))){
                          this.router.navigate(base)
                          return false;
                        }
                        return true;


                      }
                      return false
                    })
                  )

  }
}
@Injectable()
export class VouchViewGuard implements CanActivate {

  constructor(private router: Router,
              private _tenancyOffer: TenancyOfferService,
              public _activatedRoute: ActivatedRoute,) {}

  canActivate(route: ActivatedRouteSnapshot,
              state: RouterStateSnapshot) {
                const base = state.url.split('/').splice(1,2)
                return  this._tenancyOffer.getTenancyOfferById(route.parent.paramMap.get('offerId')).valueChanges().pipe(
                    map((offer: any) => {
                      if (offer) {
                        if (!(offer.references && offer.references.members )) {
                          this.router.navigate([...base, References.verification])
                          return false;
                        }
                        if (!(offer.references && offer.references.members) || !(offer && offer.references && offer.references.members)){
                          this.router.navigate(base)
                          return false;
                        }
                        return true;

                      }
                      return false
                    })
                  )

  }
}

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Shared } from '@env/routing';
import { TitleCasePipe } from '@angular/common';
import { AuthService } from '@app/core/services';
import { UserService } from '@app/services';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { finalize, map } from 'rxjs/operators';
import * as moment from 'moment';
import { CLIENTS } from '@app/_constants/client.constants';
import { environment } from '@env/environment';
import { Observable, of } from 'rxjs';
import { TenancyPackageService } from '@app/services/tenancy-package.service';
import { TRANSLATIONS } from '@app/_constants/translations.constants';
import { PropertyType } from '@app/_constants/property_docs.constants';

@Component({
  selector: 'property-card',
  templateUrl: './property-card.component.html',
  styleUrls: ['./property-card.component.scss']
})
export class PropertyCardComponent implements OnInit {
  @Output() unLike: EventEmitter<any> = new EventEmitter<any>();
  @Input() property: any;
  @Input() bedroomId: any;
  @Input() static = false;
  @Input() showLiked = true;
  routes = { Shared };
  displayObject: any = {};
  public display$: Observable<any>;

  // features:any =[]
  client_name: any;
  public client_data = environment.client_data;
  public rent_label_abbreviation = TRANSLATIONS.rent_label_abbreviation;

  constructor(
    public _titlecasePipe: TitleCasePipe,
    public _auth: AuthService,
    public _user: UserService,
    public _router: Router,
    private _toastr: ToastrService,
    private _tenancyPackage: TenancyPackageService
  ) { }

  ngOnInit() {

    const city = this._titlecasePipe.transform(this.property.address.city);
    const totalRoomsAvailable = this.property.is_hmo ? this.property.number_units_listed : 0;
    const totalRoomsUnderOffer = this.property.is_hmo ? this.property.number_units_under_offer : 0;

    const housePhoto = this.property.property_photo ? this.property.property_photo : this.property.property_photos && this.property.property_photos[0] ? this.property.property_photos[0].image_large_url : null;
    const bedroomPhoto = this.bedroomId && this.property.bedrooms[this.bedroomId].room_thumbnail_url &&
      this.property.bedrooms[this.bedroomId].room_thumbnail_url.image_url !== null ?
      this.property.bedrooms[this.bedroomId].room_thumbnail_url.image_large_url : housePhoto;
    const dateAvailable = !this.property.is_hmo ? this.formatDateAvailable(this.property.date_available) : undefined;

    const roomMinPcm =  this.property.is_hmo && this.property.landlord_uid === 'R4EGdbwhhlWwF6KyKwmTBTxRXey1' ? (this.property.hmo_min_pcm / 4 ): this.property.hmo_min_pcm;
    const roomMaxPcm =  this.property.is_hmo && this.property.landlord_uid === 'R4EGdbwhhlWwF6KyKwmTBTxRXey1' ? (this.property.hmo_max_pcm / 4) : this.property.hmo_max_pcm;

    this.displayObject = {
      photo: this.property.is_hmo && this.bedroomId ? bedroomPhoto : housePhoto,
      rentPCM: this.property.household_rent_pcm !== undefined ? parseInt(this.property.household_rent_pcm, 10).toLocaleString() : undefined,
      rentInterval: !this.property.is_hmo ? this.property.rent_interval_count : null,
      rentQtr :  !this.property.is_hmo && this.property.rent_qtr ? this.property.rent_qtr : null,
      rentWeekly :  !this.property.is_hmo && this.property.rent_weekly ? this.property.rent_weekly : null,
      // roomRentPCM: this.property.is_hmo && !this.property.household_rent_pcm ?
      //   parseInt(bedsArray.sort((a, b) => a.listed_rent_pcm - b.listed_rent_pcm)[0].listed_rent_pcm, 10).toLocaleString() : undefined,
      roomMin: this.property.is_hmo && this.property.hmo_min_pcm ? parseInt(roomMinPcm, 10).toLocaleString() : 0,
      roomMax: this.property.is_hmo && this.property.hmo_max_pcm ? parseInt(roomMaxPcm, 10).toLocaleString() : undefined,
      isHMO: this.property.is_hmo,
      isMarketingListing: this.property.is_marketing_listing,
      totalBedrooms: this.property.number_bedrooms,
      totalBathrooms: this.property.number_bathrooms,
      totalRoomsAvailable,
      totalRoomsUnderOffer,
      underOffer: this.property.is_under_offer || false,
      isDeleted: this.property.is_deleted,
      isListed: this.property.is_listed,
      justPropertyType: this.property.property_type,
      propertyType: this.property.number_bedrooms ?
        this.property.number_bedrooms + ' bedroom ' + PropertyType[this.property.property_type].toLowerCase()
        : PropertyType[this.property.property_type],
      isHouseShare: this.property.number_bedrooms ?
        this.property.number_bedrooms + ((this.property.number_bedrooms > 1) ? ' bedrooms ' : ' bedroom ') + ' in house share' : 'house share',
      address: this.property.address.thoroughfare ?
        (this.property.manager_uid === 'rengen' || this.property.manager_uid === 'urban_creation' || this._auth.authenticated && this._user.userDb.role === 'landlord')
          ? `${this.property.address.first_line_address}, ${this.property.address.thoroughfare}, ${city}, ${this.property.address.post_code.split(' ')[0]}`
          : `${this.property.address.thoroughfare}, ${city}, ${this.property.address.post_code.split(' ')[0]}`
        : `${city}, ${this.property.address.post_code.split(' ')[0]}`,
      dateAvailable: dateAvailable,
      listingTitle: this.property.property_title && this.property.property_title.length ? this.property.property_title : null,
      // bedroomPhoto : this.property.is_hmo && bedroomPhoto ? bedroomPhoto : undefined
      // features,
    };
   this.display$ = this.property.tenancy_package_ids && this.property.tenancy_package_ids.length === 1 ? this._tenancyPackage.getPackageById(this.property.tenancy_package_ids[0]).valueChanges().pipe(
     map( (data:any) =>
       ({...this.displayObject,features:data.features})
     )
    ) : of(this.displayObject)
  }

  likeProperty(property_id: string): void {
    if (this._auth.authenticated) {
      this.updateUserLikedProperties(property_id)
    }
  }

  updateUserLikedProperties(property_id: string): void {
    if (!this._user.userDb.tenant_data.liked_property_ids ||
      !(this._user.userDb.tenant_data.liked_property_ids.hasOwnProperty(property_id))) {
      this.displayObject.favourite = true;
      this._user.updateUserLikedProperties(property_id)
        .subscribe(
          () => this._toastr.success('Property added to your favourites!'),
          error => this._toastr.error('An error as occurred'));
    } else {
      this.displayObject.favourite = false;
      this._user.removeUserLikedProperty(property_id).pipe(
        finalize(() => this.unLike.emit())
      )
        .subscribe(
          () => this._toastr.success('Property deleted from your favourites'),
          error => this._toastr.error('An error as occurred'));
    }
  }

  gotoProperty() {
    if (!this.static) {
      return this.property.redirect_url  ? window.open(this.property.redirect_url, '_blank')
        : this._router.navigate(['/' + this.routes.Shared.search, this.property.property_id]);
    }
  }

  formatDateAvailable(value: any) {
    if (value && value._seconds) {
      const timeStamp = (value._seconds * 1000);
      const date = new Date(timeStamp);
      return date.toLocaleDateString();
    } else {
      return this.getMomentDate(value);
    }
  }

  getMomentDate(date: any) {
    return moment(date.toDate()).format('DD/MM/YYYY');
  }

  get redirect_project_id() {
    this.client_name = CLIENTS[this.property.redirect_project_id];
    return this.property.redirect_project_id || false;
  }

}

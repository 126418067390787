<div class="flex justify-between items-center relative">
  <h3 *ngIf="verificationType === 'identity'">Identity verification</h3>
  <h3 *ngIf="verificationType === 'ownership'">Proof of ownership</h3>
  <span class="text--xxxl close-btn absolute" (click)="dialogRef.close()">+</span>
</div>
<div class="p-3 flex flex-column verification-info">
  <strong>Required documents to upload</strong>
  <div *ngIf="verificationType === 'identity'" class="flex flex-column">
    <span>Passport <strong>AND</strong> proof of address (Utility bill, bank statement, mortgage statement, council tax)</span>
    <strong>OR</strong>
    <span>Photocard driving license</span>
  </div>
  <span *ngIf="verificationType === 'ownership'"><strong>ONE</strong> of the following: HM land registry title, title deed, mortgage agreement, certificate of insurance or signed solicitor's letter.</span>
  <div class="flex justify-between items-center mt-4">
    <span>We support: PDF, doc, docx, JPG, JPEG & PNG files - Max {{documentMaxSizeMb}}MB</span>
    <span class="flex items-center text-blue">
          Help
          <mat-icon class="ml-1">help_outline</mat-icon>
        </span>
  </div>
</div>
<form [formGroup]="formGroup">
  <div *ngIf="verificationType === 'identity'">
    <mat-radio-group formControlName="identity_verification_type">
      <div class="flex justify-between mt-4 mb-2">
        <mat-radio-button class="flex-basis-half" value="passport_and_address">Passport and Proof of address
        </mat-radio-button>
        <mat-radio-button class="flex-basis-half" value="driving_license">Driving license</mat-radio-button>
      </div>
      <div *ngIf="identity_verification_type.value === 'passport_and_address'" class="flex justify-between">
        <document-upload-box
          customClasses="passport-document"
          verificationDocumentType="passport"
          verificationTypeTitle="Passport"
          svgIcon="bunk-icon-tenancies"
          [propertyId]="propertyId"
          class="w-50 mr-2"
          (documentFileChanged)="onDocumentFileChange($event)"
          [documentMaxSizeMb]="documentMaxSizeMb"
          [uploadedFileOriginalUrl]="passportUploadedFileOriginalUrl$ | async"
          [uploadedFileName]="passportUploadedFileName"
        ></document-upload-box>
        <document-upload-box
          customClasses="address-document"
          verificationDocumentType="address"
          verificationTypeTitle="Proof of address"
          svgIcon="bunk-icon-application"
          [propertyId]="propertyId"
          [documentTypesList]="addressDocumentTypesList"
          class="w-50 ml-2"
          (documentFileChanged)="onDocumentFileChange($event)"
          (documentTypeChanged)="onDocumentTypeChange($event)"
          [documentMaxSizeMb]="documentMaxSizeMb"
          [uploadedDocumentType]="addressDocumentType"
          [uploadedFileOriginalUrl]="addressUploadedFileOriginalUrl$ | async"
          [uploadedFileName]="addressUploadedFileName"
        ></document-upload-box>
      </div>
      <div *ngIf="identity_verification_type.value === 'driving_license'">
        <document-upload-box
          customClasses="driving-license"
          verificationDocumentType="driving-license"
          verificationTypeTitle="Driving license"
          svgIcon="bunk-icon-tenancies"
          [propertyId]="propertyId"
          (documentFileChanged)="onDocumentFileChange($event)"
          [documentMaxSizeMb]="documentMaxSizeMb"
          [uploadedFileOriginalUrl]="drivingLicenseUploadedFileOriginalUrl$ | async"
          [uploadedFileName]="drivingLicenseUploadedFileName"
        ></document-upload-box>
      </div>
    </mat-radio-group>
  </div>
  <div *ngIf="verificationType === 'ownership'">
    <document-upload-box
      customClasses="ownership-document"
      verificationDocumentType="ownership"
      verificationTypeTitle="Proof of ownership"
      svgIcon="bunk-icon-application"
      [propertyId]="propertyId"
      [documentTypesList]="ownershipDocumentTypesList"
      (documentFileChanged)="onDocumentFileChange($event)"
      (documentTypeChanged)="onDocumentTypeChange($event)"
      [documentMaxSizeMb]="documentMaxSizeMb"
      [uploadedDocumentType]="ownershipDocumentType"
      [uploadedFileOriginalUrl]="ownershipUploadedFileUrl$ | async"
      [uploadedFileName]="ownershipUploadedFileName"
    ></document-upload-box>
  </div>
</form>
<div class="flex justify-center mt-5">
  <button class="btn__seethrough btn__seethrough--primary border-0" type="button" (click)="dialogRef.close()">Cancel
  </button>
  <button class="as_fs text_light" [ngClass]="formGroup.valid ? 'btn__generic': 'btn__seethrough'"
          (click)="submit(formGroup)" type="button">Attach documents
  </button>
</div>

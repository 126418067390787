import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FeedbackService } from '@app/services';
import { finalize, delay } from 'rxjs/operators';

@Component({
  selector: 'form-feedback',
  template: `<form [formGroup]="feedbackFormGroup" class="main__wrapper main__wrapper--narrow flex flex-column items-center">
      
      <h1 class="text--lg margin-top--sm margin-bottom--sm">{{(submittingDataSuccess ? 'Thank you for your feedback!' : 'Send us your feedback!')}}</h1>

      <div class="card__base card__base--sm flex flex-column align-center">
        <div class="block-info block-info--success" *ngIf="submittingDataSuccess || showSpinner">
          <span class="text--sm">Your feedback has been submitted! We appreciate you taking the time to let us know.</span>
        </div>

        <span class="feedback__title">How would you rate us?</span>

        <mat-radio-group class="flex justify-center margin-bottom--md" formControlName="rating">
          <label class="m-all_5">
            <mat-radio-button class="radio__rating" [value]="1"></mat-radio-button>
            <img class="rating__emoji" src="/assets/svg/sentiment-angry.svg">
          </label>
          <label class="m-all_5">
            <mat-radio-button class="radio__rating" [value]="2"></mat-radio-button>
            <img class="rating__emoji" src="/assets/svg/sentiment-sad.svg">
          </label>
          <label class="m-all_5">
            <mat-radio-button class="radio__rating" [value]="3"></mat-radio-button>
            <img class="rating__emoji" src="/assets/svg/sentiment-ok.svg">
          </label>
          <label class="m-all_5">
            <mat-radio-button class="radio__rating" [value]="4"></mat-radio-button>
            <img class="rating__emoji" src="/assets/svg/sentiment-happy.svg">
          </label>
          <label class="m-all_5">
            <mat-radio-button class="radio__rating" [value]="5"></mat-radio-button>
            <img class="rating__emoji" src="/assets/svg/sentiment-ecstatic.svg">
          </label>
        </mat-radio-group>

      <span class="feedback__title">Do you have a suggestion or have you found something not working?</span>
      <mat-form-field appearance="outline" class="w_100">
      <mat-label>Tell us about your experience</mat-label>
        <textarea formControlName="description" matInput row="5" col="60"
          class="descriptionArea textarea" maxlength="2000" required></textarea>
          <mat-error>Please add your experience</mat-error>
      </mat-form-field>


    <div class="flex flex-column margin-top--sm">
      <span class="feedback__title">What type of feedback is this?</span>
        <mat-radio-group [formControlName]="'feedback_reason'" class="radio__wrapper" >
                    <mat-radio-button value="bug">Bug</mat-radio-button>
                    <mat-radio-button value="suggestions">Suggestions</mat-radio-button>
                    <mat-radio-button value="other">Other</mat-radio-button>
        </mat-radio-group>
    </div>

      <div class="flex justify-end w_100 margin-top--sm">
        <button type="button"
                    class="btn__cta btn__cta--lowercase" (click)="publishFeedback()">
                    <span *ngIf="!showSpinner && !submittingDataSuccess || submittingDataSuccess ">Send Feedback</span>
                    <processing-spinner *ngIf="showSpinner" color="#FFFFFF"></processing-spinner>
        </button>  
      </div>   

      </div>
    </form>`,
  styleUrls: ['./form-tenant-feedback.scss']
})
export class FormFeedbackComponent {
  public feedbackFormGroup: FormGroup;
  public showSpinner = false;
  public submittingDataSuccess = false;

  constructor(private _formBuilder: FormBuilder,
              private _feedback: FeedbackService) { 
              this.feedbackFormGroup = this.initFormGroup();
              }

  initFormGroup() {
    return this._formBuilder.group({
      rating: null,
      feedback_reason: '',
      description: ['', Validators.required]
    });

  }

  publishFeedback() {
    if (this.feedbackFormGroup.valid) {
    this.submittingDataSuccess = false;
    this.showSpinner = true;
    this._feedback.createFeedbackDocument(this.feedbackFormGroup.getRawValue()).pipe(
      delay(200),
       finalize(() => {
          this.showSpinner = false; 
          this.submittingDataSuccess = true;
          this.feedbackFormGroup.reset();
      })
    ).subscribe(() => {
      console.log('feedback sent!');
    });
  }
  }

}

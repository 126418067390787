import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { FirebaseApp } from '@angular/fire';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument, DocumentReference } from '@angular/fire/firestore';
import { AuthService } from '@app/core/services';

import { environment } from '@env/environment';
import {
  ReferencesEmployerFormModel,
  ReferencesGuarantorsFormModel,
  ReferencesLandlordFormModel,
  ReferencesUniversityFormModel
} from '@app/_models/references.model';

import { combineLatest, from, Observable, from as fromPromise } from 'rxjs';
import { flatMap } from 'rxjs/operators';
import { REFERENCE_STATUS } from '@app/core-tenant/property/tenant-property-references/references.constants';
import { BunkPassportService } from './bunk-passport.service';

@Injectable()
export class ReferencesService {
  public tenancyOfferCollection: AngularFirestoreCollection<any>;
  public referencesCollection: AngularFirestoreCollection<any>;

  constructor(private _afs: AngularFirestore,
    private _auth: AuthService,
    private _http: HttpClient,
    private _fb: FirebaseApp,
    private _passport: BunkPassportService) {
    this.tenancyOfferCollection = _afs.collection<any>('tenancy_offers');
    this.referencesCollection = _afs.collection<any>('references');
  }

  getDocReferenceById = (id: string): AngularFirestoreDocument<any> => this.referencesCollection.doc(id);

  getDocReferencesByIds = (ids: string[]): AngularFirestoreDocument<any>[] => ids.map(id => this.getDocReferenceById(id));

  getReferenceById = (id: string): Observable<any | undefined> => this.getDocReferenceById(id).valueChanges();

  getReferencesByIds = (ids: string[]): Observable<any[] | undefined> => combineLatest(this.getDocReferencesByIds(ids).map(ref => ref.valueChanges()));

  getReferencesById(referenceType: string): AngularFirestoreCollection<any> {
    return this._afs.collection('references', ref =>
      ref.where('uid', '==', this._auth.currentUserId)
        .where('type', '==', referenceType)
        .orderBy('date_modified', 'desc')
    );
  }
  getReferencesByTenantUid(uid:string): AngularFirestoreCollection<any>{
    return this._afs.collection('references', ref =>
    ref.where('uid', '==', uid)
      .where('status', '==', 'complete')
      .where('is_deleted', '==' , false)
  );
  }


  createReference(tenancyOfferId: string,
    type: 'universities',
    form: ReferencesUniversityFormModel,
    status: REFERENCE_STATUS.COMPLETE) {
    const referenceId = this._afs.createId();

    const referencesDocRef = this.referencesCollection.doc(referenceId).ref;
    const tenancyOfferDocRef = this.tenancyOfferCollection.doc(tenancyOfferId).ref;

    return this.createTenancyOfferReference(tenancyOfferDocRef, referencesDocRef, tenancyOfferId, referenceId, form, type, status);
  }

  createReferenceAndSendDocumentForSigning(property_id: string, tenancyOfferId: string,
    type: 'guarantors' | 'employers' | 'previous_tenancies',
    form: ReferencesGuarantorsFormModel | ReferencesEmployerFormModel | ReferencesLandlordFormModel | ReferencesUniversityFormModel) {
    const id = this._afs.createId();

    const referencesDocRef = this.referencesCollection.doc(id).ref;
    const tenancyOfferDocRef = this.tenancyOfferCollection.doc(tenancyOfferId).ref;

    return this.createTenancyOfferReference(tenancyOfferDocRef, referencesDocRef, tenancyOfferId, id, form, type, REFERENCE_STATUS.PENDING_SIGNATURE)
      .pipe(flatMap(() => this.sendDocumentForSigning(id, 'send_new', property_id)));
  }

  landlordAcceptReferences(id) {
    const body = {
      tenancy_offer_id: id,
    };
    return this._http.post(environment.firebaseConfig.apiUrl + '/tenancyOffer-landlordAcceptReferences', body);
  }

  updateReference(referenceId: string,
    tenancyOfferId: string,
    form: ReferencesUniversityFormModel) {
    const referencesDocRef = this.referencesCollection.doc(referenceId).ref;
    const tenancyOfferDocRef = this.tenancyOfferCollection.doc(tenancyOfferId).ref;

    const batch = this._afs.firestore.batch();

    const refItem = {
      date_modified: new Date(),
      has_been_resent: true,
      date_resent: new Date(),
      reference_data: {
        ...form
      }
    };

    const tenancyItem = {
      date_modified: new Date(),
      references: {
        date_modified: new Date()
      }
    };

    batch.set(referencesDocRef, refItem, { merge: true });
    batch.set(tenancyOfferDocRef, tenancyItem, { merge: true });

    return from(batch.commit());
  }



  updateReferenceAndReSendDocumentForSigning(property_id: string,
    referenceId: string,
    tenancyOfferId: string,
    form: ReferencesGuarantorsFormModel | ReferencesEmployerFormModel | ReferencesLandlordFormModel,
    action: 'resend' | 'update_signer') {
    const referencesDocRef = this.referencesCollection.doc(referenceId).ref;
    const tenancyOfferDocRef = this.tenancyOfferCollection.doc(tenancyOfferId).ref;

    const batch = this._afs.firestore.batch();

    const refItem = {
      date_modified: new Date(),
      has_been_resent: true,
      date_resent: new Date(),
      reference_data: {
        ...form
      }
    };

    const tenancyItem = {
      date_modified: new Date(),
      references: {
        date_modified: new Date()
      }
    };

    batch.set(referencesDocRef, refItem, { merge: true });
    batch.set(tenancyOfferDocRef, tenancyItem, { merge: true });

    return from(batch.commit())
      .pipe(
        flatMap(() => this.sendDocumentForSigning(referenceId, action, property_id))
      );
  }

  /*
   * sendDocumentForSigning
   * https://rentbunk.atlassian.net/wiki/spaces/BW/pages/111280129/Bunk-tenancy-offer-cloud-functions
   */
  sendDocumentForSigning(id: string, action: 'send_new' | 'update_signer' | 'resend', property_id: string, ) {
    const body = { property_id: property_id, reference_id: id, action: action };
    return this._http.post(environment.firebaseConfig.apiUrl + '/esignatures-sendDocumentForSigning', body);
  }

  private createTenancyOfferReference(tenancyOfferDocRef: DocumentReference, referencesDocRef: DocumentReference,
    tenancyOfferId: string,
    referenceId: string,
    form: ReferencesGuarantorsFormModel | ReferencesEmployerFormModel | ReferencesLandlordFormModel | ReferencesUniversityFormModel,
    type: 'guarantors' | 'employers' | 'previous_tenancies' | 'universities',
    statusReference: REFERENCE_STATUS.PENDING_SIGNATURE | REFERENCE_STATUS.COMPLETE) {
    const singularizeType = (pluralType: 'guarantors' | 'employers' | 'previous_tenancies' | 'universities') => ({
      'guarantors': 'guarantor',
      'employers': 'employer',
      'previous_tenancies': 'previous_tenancy',
      'universities': 'university'
    })[type];

    const createOrUpdateMemberReference = (offerData: any) => {
      return (offerData.references.members) ?
        // if member exist
        (offerData.references.members[this._auth.currentUserId]) ?
          // if reference of user exist
          (offerData.references.members[this._auth.currentUserId][type])
            // push to existing ref
            ? { [type]: [...offerData.references.members[this._auth.currentUserId][type], referenceId] }
            // else create new ref
            : { [type]: [referenceId] } : { [type]: [referenceId] } : { [type]: [referenceId] };
    };

    return from(this._fb.firestore().runTransaction(transaction => {
      return transaction.get(tenancyOfferDocRef)
        .then((offer: any) => {
          const tenancyOffer = offer.data();
          const newRef = createOrUpdateMemberReference(tenancyOffer);

          const tenancyOfferItem = {
            date_modified: new Date(),
            references: {
              date_modified: new Date(),
              status: 'reference_started',
              members: {
                [this._auth.currentUserId]: {
                  ...newRef
                }
              }
            }
          };

          const referenceItem = {
            reference_id: referenceId,
            date_created: new Date(),
            date_modified: new Date(),
            date_sent: new Date(),
            status: statusReference,
            type: singularizeType(type),
            uid: this._auth.currentUserId,
            reference_data: {
              ...form
            },
            tenancy_offers: {
              [tenancyOfferId]: null
            }

          };

          transaction.set(referencesDocRef, referenceItem, { merge: true });
          transaction.set(tenancyOfferDocRef, tenancyOfferItem, { merge: true });
        });
    }));
  }

  requestTenantGuarantor(uid,offerId){
    const body = {
      uid,
      tenancy_offer_id: offerId,
    };
    return this._http.post(environment.firebaseConfig.apiUrl + '/references-requestTenantGuarantor', body);
  }

}







import { Injectable } from '@angular/core';
import { AuthService } from '@app/core/services/auth.service';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { fromPromise } from 'rxjs/internal-compatibility';


@Injectable()
export class InvoiceService {
  constructor(
    private afs: AngularFirestore,
    private _auth: AuthService
  ) {
  }
  // this._auth.currentUserId

  getInvoiceForTenant(offerId:string){
  return this.afs.collection('invoices', ref =>
  ref.where('sender_uid', '==', this._auth.currentUserId)
    .where('tenancy_offer_id', '==', offerId)
    .where('is_deleted', '==', false)
).valueChanges();
}
}

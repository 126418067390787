import { Router } from '@angular/router';
import { environment } from '@env/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
export class MaintenanceGuard {
    constructor(router) {
        this.router = router;
    }
    canActivate() {
        if (environment.firebaseConfig.projectId === "client-living-way") {
            this.router.navigate(['/']);
            return false;
        }
        else {
            return true;
        }
    }
}
MaintenanceGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MaintenanceGuard_Factory() { return new MaintenanceGuard(i0.ɵɵinject(i1.Router)); }, token: MaintenanceGuard, providedIn: "root" });

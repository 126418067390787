

    <form [formGroup]="formGroup" class="bunk-input flex justify-content-between">
      <mat-form-field bunk-input appearance="outline" class="bunk-input__first-name">
        <input id="signup-first-name" matInput type="text" formControlName="first_name" placeholder="First Name">
        <mat-error *ngIf="submitAttempted && formGroup.get('first_name').errors?.required">Please enter your first name</mat-error>
      </mat-form-field>

      <mat-form-field bunk-input appearance="outline" class="bunk-input__last-name">
        <input id="signup-last-name" matInput type="text" formControlName="last_name" placeholder="Last Name">
        <mat-error *ngIf="submitAttempted && formGroup.get('last_name').errors?.required">Please enter your last name</mat-error>
      </mat-form-field>

    </form>
  

    <label *ngIf="inputLabel" for="hours">{{inputLabel}}</label>
    <form [formGroup]="formGroup">

      <mat-form-field appearance="outline" class="input__hours">
        <mat-label>Hour (24)</mat-label>
        <mat-select formControlName="hours" name="hours" (selectionChange)="emitTime()">
          <mat-option *ngFor="let hour of hoursArr" [value]="hour">
            {{ hour }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="submitAttempted && formGroup.get('hours').errors?.required">Select an hour</mat-error>
      </mat-form-field>

      <mat-form-field appearance="outline" class="input__mins">
      <mat-label>Minutes</mat-label>
        <mat-select formControlName="mins" (selectionChange)="emitTime()">
          <mat-option *ngFor="let min of [0,5,10,15,20,25,30,35,40,45,50,55]" [value]="min">
            {{ min.toString().padStart(2,'0') }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="submitAttempted && formGroup.get('mins').errors?.required">Select minutes</mat-error>
      </mat-form-field>
    </form>
  
import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';

import { Tenant, Profile } from '@env/routing';
import { BunkPassportService, UserService } from '@app/services';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@app/core/services';
import { takeUntil, map, catchError, finalize } from 'rxjs/operators';
import { Subject, of  } from 'rxjs';

import { TenantVerifyEventEmiterService } from '../profile-tenant.service';
import { TenantAccountStepComponent } from './tenant-account-step.component';
import { TRANSLATIONS } from '@app/_constants/translations.constants';




@Component({
  selector: 'app-tenant-account-verify-guarantor-details',
  template: `

    <div>
      <h2 class="text--lg">Guarantor details</h2>
      <p>You will only need a guarantor if you earn less than 2.5 times the annual rental value or if the {{landlord}} requests one at the references stage.</p>
    </div>

    <form class="cc" [formGroup]="formGroup" (ngSubmit)="formGroup.valid && nextStep(formGroup.value)">

    <form-guarantor-details formControlName="guarantor_details"></form-guarantor-details>

      <div class="margin-bottom--xl">
        <button *ngIf="showPrev" (click)="goBack()" data-test="backButton" class="btn__seethrough btn__form-prev">Back</button>
        <button class="btn__generic btn__form-submit btn__form-submit--align-right" type="submit">
          <!--[disabled]="formGroup.invalid || submittingData">-->
          <span *ngIf="!submittingData && !submittingDataSuccess">Save<span *ngIf="!standAlone"> and continue</span></span>
          <processing-spinner *ngIf="submittingData || submittingDataSuccess" color="#FFFFFF" [complete]="submittingDataSuccess"></processing-spinner>
        </button>
      </div>

    </form>

  `,
  styleUrls: ['./tenant-account-step.component.scss'],
})
export class TenantAccountVerifyGuarantorDetailsComponent extends TenantAccountStepComponent implements OnInit, OnDestroy {
  @Input() standAlone = false;
  @Input() reference?: any;
  public formGroup: FormGroup;
  public queryParams: string;
  public routes = {Tenant};
  public landlord = TRANSLATIONS.landlord;

  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    _verifyEvent: TenantVerifyEventEmiterService,
    private _formBuilder: FormBuilder,
    public _passport: BunkPassportService,
    public _user: UserService
  ) {
    super(_verifyEvent);
    this.formGroup = this.initFormGroup();
   }


  initFormGroup(): FormGroup {
    return this._formBuilder.group({
      guarantor_details: [null]
    });
  }

  nextStep(form: any) {
    const reference_id = this.reference ? this.reference.bunk_passport_reference_id : null;

    const finalForm = {
      guarantor_details: {
        ...form.guarantor_details,
        referee_email: form.guarantor_details.referee_email.trim(),
        referee_phone: form.guarantor_details.referee_phone ? form.guarantor_details.referee_phone.phone_number : null,
        referee_country_code: form.guarantor_details.referee_phone ? form.guarantor_details.referee_phone.area_code : null,
        bunk_passport_reference_id: reference_id,
      }
    };

    this.submittingData = true;
    this.formGroup.disable();
    this._passport.updateUserReferenceData(finalForm)
    .pipe(
      takeUntil(this.destroy$),
      catchError(err => of([]))
    )
    .subscribe(
      res => {
        this.stepComplete();
      },
      err => {
        console.log('HTTP Error', err);
        this.stepError();
      },
      () => {
        this.formGroup.enable();
        console.log('HTTP request completed.');
      }
    );
  }

  preloadFormGroup(form: any): void {
    const item = {
        has_uk_guarantor : form.has_uk_guarantor ? form.has_uk_guarantor : null,
        referee_name: form.reference_data.referee_name,
        referee_email: form.reference_data.referee_email ? form.reference_data.referee_email : null,
        referee_phone: {
          area_code: form.reference_data.referee_country_code,
          phone_number: form.reference_data.referee_phone,
        },
        referee_country_code: form.reference_data.referee_country_code ? form.reference_data.referee_country_code : null,
        relationship: form.reference_data.relationship ? form.reference_data.relationship : null,
        has_declared_uk_homeowner: form.reference_data.has_declared_uk_homeowner ? form.reference_data.has_declared_uk_homeowner : false,
        referee_country_of_residence: form.reference_data.referee_country_of_residence ? form.reference_data.referee_country_of_residence : null,
    };
    this.formGroup.get('guarantor_details').patchValue(item);
  }

  goBack() {
    this._verifyEvent.navigateStep('prev');
  }

  ngOnInit() {
    console.log(this.reference, 'ref');
     if (this.reference) {
      this.preloadFormGroup(this.reference);
    }
  }


  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}

import {Injectable, Injector} from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';

import {AuthService} from '@app/core/services';
import {Observable} from 'rxjs';
import {switchMap} from 'rxjs/operators';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  private auth;

  constructor(private inj: Injector) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (request.url.indexOf('oauthCallback') > -1) {
      return next.handle(request);
    }

    this.auth = this.inj.get(AuthService);  // Manually inject the authService

    if (this.auth.currentUser) {
      return this.auth.getCurrentUserIdToken().pipe(
        switchMap(token => {
          request = request.clone({
            setHeaders: {
              Authorization: `Bearer ${token}`
            }
          });
          return next.handle(request);
        })
      );
    } else {
        return next.handle(request);
    }




  }


}

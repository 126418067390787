import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'search-input',
  templateUrl: './search-input.component.html',
  styleUrls: ['./search-input.component.scss']
})
export class SearchInputComponent implements OnInit {
  @Output() filterString: EventEmitter<string> = new EventEmitter<string>();
  @Input() placeHolderName = '';
  constructor() { }

  ngOnInit() {
  }
  applyFilter(searchKey:string){
    this.filterString.emit(searchKey)
  }

}

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./tenant-main-template.component";
var styles_TenantMainTemplateComponent = [];
var RenderType_TenantMainTemplateComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_TenantMainTemplateComponent, data: {} });
export { RenderType_TenantMainTemplateComponent as RenderType_TenantMainTemplateComponent };
export function View_TenantMainTemplateComponent_0(_l) { return i0.ɵvid(0, [i0.ɵncd(null, 0)], null, null); }
export function View_TenantMainTemplateComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "tenant-main-template", [], null, null, null, View_TenantMainTemplateComponent_0, RenderType_TenantMainTemplateComponent)), i0.ɵdid(1, 49152, null, 0, i1.TenantMainTemplateComponent, [], null, null)], null, null); }
var TenantMainTemplateComponentNgFactory = i0.ɵccf("tenant-main-template", i1.TenantMainTemplateComponent, View_TenantMainTemplateComponent_Host_0, {}, {}, ["*"]);
export { TenantMainTemplateComponentNgFactory as TenantMainTemplateComponentNgFactory };

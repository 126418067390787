import { Input } from '@angular/core';

interface TenancyMemberProfileCardModel {
  fullName: string;
  profileImgUrl?: string;
  uid: string;
  role: string;
  countryCode?: number;
  phone?: string;
  email?: string;
  isVerified?: boolean;
  property?: any;
  additionalInfo?: string;
}

export class ProfileCardComponent {
  @Input() members;
  @Input() property: any;
  @Input() showMessage;
  @Input() seethrough = false;
  @Input() activityColumn = false;
  @Input() userIsLandlord = false;
}

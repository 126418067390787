
  <form [formGroup]="prevTenancyForm" autocomplete="none">
  <bunk-form-field *ngIf="getFormControl('has_previous_tenancy')" [hidden]="!showHasPreviousTenancy">
    <label bunk-label>Do you have any previous rental history?</label>
    <div bunk-input>
      <mat-radio-group formControlName="has_previous_tenancy" class="flex flex-row margin-bottom" (change)="setValidators(getFormControl('has_previous_tenancy').value)">
        <mat-radio-button  [value]="true" class="bunk-outlined-radio mr_5" [ngClass]="{'bunk-outlined-radio--selected': getFormControl('has_previous_tenancy').value === true}"  >Yes</mat-radio-button>
        <mat-radio-button  [value]="false" class="bunk-outlined-radio" [ngClass]="{'bunk-outlined-radio--selected': getFormControl('has_previous_tenancy').value === false}" >No</mat-radio-button>
      </mat-radio-group>
      <mat-error class="bunk-mat-error-font-match"*ngIf="getFormControl('has_previous_tenancy').getError('required') && submitAttempted">Please select</mat-error>

    </div>
    </bunk-form-field>

    <div *ngIf="getFormControl('has_previous_tenancy').value || !showHasPreviousTenancy" >

      <bunk-form-field *ngIf="getFormControl('previous_address')">
      <label bunk-label>Please enter the postcode</label>
      <div bunk-input>
        <ideal-postcodes-autocomplete (selectedAddress)="setPreviousAddress($event)"
          appearanceType="outline">
        </ideal-postcodes-autocomplete>
      </div>
      </bunk-form-field>

      <bunk-form-field *ngIf="getFormControl('previous_address')">
        <label bunk-label>What was your previous address?</label>
        <mat-form-field bunk-input appearance="outline">
          <input formControlName="previous_address" matInput type="text" name="name" >
          <mat-error>Please enter your previous address </mat-error>
        </mat-form-field>
      </bunk-form-field>


      <bunk-form-field *ngIf="getFormControl('referee_name')">
        <label bunk-label>What was your{{landlord}}s name?</label>
        <mat-form-field bunk-input appearance="outline">
          <input formControlName="referee_name" matInput type="text" name="name" placeholder="E.g Jenny Jones">
          <mat-error>Please enter the {{landlord | titlecase}}s name</mat-error>
        </mat-form-field>
      </bunk-form-field>


      <bunk-form-field *ngIf="getFormControl('referee_email')">
        <label bunk-label>What was your {{landlord}}s email?</label>
        <mat-form-field bunk-input appearance="outline">
          <input formControlName="referee_email" matInput type="email" name="name" placeholder="jenny.jones@gmail.com" >
          <mat-error>Please enter the {{landlord | titlecase}}s email</mat-error>
      </mat-form-field>
      </bunk-form-field>


    <bunk-form-field *ngIf="getFormControl('referee_phone')">
      <label bunk-label >What is your {{landlord}}s number?</label>
      <div bunk-input>
      <form-input-phone
        bunk-input
        formControlName="referee_phone"
        showLabel="true"
        [submitAttempted]="submitAttempted"
      ></form-input-phone>
      </div>
    </bunk-form-field>


    <bunk-form-field *ngIf="getFormControl('end_of_tenancy_date')">
      <label bunk-label >When is your tenancy end date?</label>
      <mat-form-field bunk-input appearance="outline">
      <input matInput
            formControlName="end_of_tenancy_date"
            [matDatepicker]="endDate"
            (click)="endDate.open()"
            readonly
            placeholder="Choose a date"
      >
      <mat-datepicker-toggle matSuffix [for]="endDate"></mat-datepicker-toggle>
      <mat-datepicker #endDate></mat-datepicker>
      <mat-error>Please enter your tenancy end date</mat-error>
    </mat-form-field>
    </bunk-form-field>

    <bunk-form-field *ngIf="getFormControl('tenancy_duration_months')">
      <label bunk-label>What was the length of your tenancy?</label>
      <mat-form-field bunk-input appearance="outline">
        <mat-select formControlName="tenancy_duration_months" placeholder="Select">
          <mat-option *ngFor="let type of tenancyDurationMonthsList"
          [value]="type.value">
            {{type.viewValue}}
          </mat-option>
        </mat-select>
        <mat-error>Please enter the length of tenancy</mat-error>
      </mat-form-field>
    </bunk-form-field>


    <bunk-form-field *ngIf="getFormControl('monthly_rent_paid')" >
      <label bunk-label>How much rent did you pay?</label>
      <mat-form-field  bunk-input appearance="outline">
        <input formControlName="monthly_rent_paid" matInput type="number" min="0"
        placeholder="E.g 700">
        <span matPrefix [ngClass]="{'disabled-label' : prevTenancyForm.disabled}">{{client_data.currency_symbol}}&nbsp;</span>
        <mat-error>Please enter the amount</mat-error>
      </mat-form-field>
    </bunk-form-field>
      </div>
  </form>

  
import { Component, ViewChild, OnDestroy, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserVerifyCardModel } from '@app/_models/user-verification.models';
import { StripeService } from '@app/services/stripe.service';
import { UserService, UserVerificationService, BunkPassportService } from '@app/services';
import { Tenant, Profile } from '@env/routing';
import { takeUntil, catchError, tap } from 'rxjs/operators';
import { TenantVerifyEventEmiterService } from '../profile-tenant.service';
import { Subject, of } from 'rxjs';
import { TenantAccountStepComponent } from './tenant-account-step.component';
import { AuthService } from '@app/core/services';

@Component({
  selector: 'tenant-account-verify-payment',
  template: `
    <ng-container>
      <div>
        <h2 class="text--lg">Payment methods</h2>
        <p data-test="landlordVerificationSubtitle" *ngIf="!_user.user.payments_data.cards || !_user.user.payments_data.cards[0]">This information is required to setup your account.</p>
      </div>
      <div class="card-details margin-bottom--lg">
        <manage-cards [showCardsForm]="false" ></manage-cards>
        <cards-form (setupComplete)="addCard($event)" [disabled]="disableCardsForm" [clear]="clearCardsForm"></cards-form>
      </div>
      <div class="margin-bottom--xl">
        <button *ngIf="showPrev" (click)="goBack()" data-test="backButton" class="btn__seethrough btn__form-prev">Back</button>

        <button
          *ngIf="cardSubmitted && stepCompleted"
          class="btn__generic btn__form-submit btn__form-submit--align-right margin-top"
          [disabled]="!cardSubmitted && (_user.user.payments_data.cards && !_user.user.payments_data.cards[0])"
          (click)="nextStep()">
          <span>Continue</span>
        </button>

      </div>
    </ng-container>
  `,
  styleUrls: ['./tenant-account-step.component.scss']
})
export class TenantAccountVerifyPaymentComponent extends TenantAccountStepComponent implements OnDestroy {
  private token: any;
  private destroy$: Subject<boolean> = new Subject<boolean>();
  public routes = { Tenant };
  public cardSubmitted: boolean;
  public stepCompleted: boolean;
  public userVerifyCardInfo: UserVerifyCardModel;
  public clearCardsForm: Subject<boolean> = new Subject();
  public disableCardsForm: Subject<boolean> = new Subject();

  constructor(
    _verifyEvent: TenantVerifyEventEmiterService,
    private _tenant: UserVerificationService,
    private _pmt: StripeService,
    public _user: UserService,
    public _passport: BunkPassportService,
    public _auth: AuthService,
    public _route: ActivatedRoute
  ) {
    super(_verifyEvent);
    this.userVerifyCardInfo = this._tenant.initVerifyUserCardInfo();
    this.cardSubmitted = (this._user.user.payments_data.cards && this._user.user.payments_data.cards[0]);

    this._user.getUserReadOnlyById(this._auth.currentUserId).valueChanges().pipe(
      takeUntil(this.destroy$),
      tap((userRE: any) => {
        this.stepCompleted = userRE.verification_steps.find((step: any) => step.step === this._route.snapshot.routeConfig['id']);
      })
    ).subscribe();
  }

  nextStep() {
    if (this.stepCompleted) {
      this.stepComplete(0);
    } else {
      this.stepError();
    }
  }

  updateBunkPassport = () => this._passport.updateBunkPassport(Profile.tenant.bunk_passport.payment)
    .pipe(
      catchError(err => of([]))
    )
    .subscribe(
      res => {
        this.stepCompleted = true;
      },
      err => {
        console.log('HTTP Error', err);
        this.stepError();
      },
      () => {
        console.log('HTTP request completed.');
      }
    );

  goBack() {
    this._verifyEvent.navigateStep('prev');
  }

  addCard(token) {
    // console.log(token);
    this.token = token;
    if (this.token) {
      this._pmt.createStripeCustomer(this.token.payment_method).subscribe(
        res => {
          console.log(res);
          this.cardSubmitted = true;
          this.clearCardsForm.next(true);
          this.disableCardsForm.next(false);
          this.updateBunkPassport();
        },
        err => {
          console.log('Error', err);
          this.clearCardsForm.next(false);
          this.disableCardsForm.next(false);
        });
    } 
  }

  ngOnDestroy() {
    console.log('DESTROY:  tenant-account-verify-payment.component');
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}

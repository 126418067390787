import { Inject, Injectable } from '@angular/core';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
@Injectable()
export class StorageLocalService {

  constructor(@Inject(LOCAL_STORAGE) private storage: StorageService) {}

  public saveStore(STORAGE_KEY: string, data: any) {
    const store = this.storage.get(STORAGE_KEY);
    this.storage.set(STORAGE_KEY, { ...store, ...data});
  }

  public clearStore() {
    this.storage.clear();
  }

  public getStore(STORAGE_KEY: string) {
    return this.storage.get(STORAGE_KEY);
  }
}

import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { Location } from '@angular/common';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
import * as i2 from "@angular/common";
export class EnvironmentRouteGuard {
    constructor(router, _location) {
        this.router = router;
        this._location = _location;
    }
    canActivate() {
        if (environment.client_data && environment.client_data.route_guards && environment.client_data.route_guards.some(route => this._location.path().includes(route))) {
            this.router.navigate(['/']);
            return false;
        }
        else {
            return true;
        }
    }
}
EnvironmentRouteGuard.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function EnvironmentRouteGuard_Factory() { return new EnvironmentRouteGuard(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i2.Location)); }, token: EnvironmentRouteGuard, providedIn: "root" });

import * as tslib_1 from "tslib";
import { AfterViewInit, ElementRef, ChangeDetectorRef, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { NgForm, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { environment } from '@env/environment';
import { StripeService, UserService } from '@app/services';
import { ToastrService } from 'ngx-toastr';
import { switchMap, takeUntil } from 'rxjs/operators';
import { from, Subject } from 'rxjs';
export class CardsFormComponent {
    constructor(cd, _user, _toastr, _stripe, _formBuilder) {
        this.cd = cd;
        this._user = _user;
        this._toastr = _toastr;
        this._stripe = _stripe;
        this._formBuilder = _formBuilder;
        this.setupComplete = new EventEmitter();
        this.goBackVerification = new EventEmitter();
        this.isVerification = false;
        this.destroy$ = new Subject();
        this.stripe = Stripe(environment.stripeConfig.publicKey);
        this.elements = this.stripe.elements({ fonts: [{ cssSrc: 'https://fonts.googleapis.com/css?family=Open+Sans&display=optional' }] });
        this.processingIntent = false;
        this.intentProcessed = false;
        this.awaitingCompletion = false;
        this.cardComplete = false;
        this.disableForm = false;
    }
    ngOnInit() {
        this.updateCardForm = this._formBuilder.group({
            cardHolderName: [undefined, Validators.required]
        });
        if (this.clear) {
            this.clear.subscribe(clear => {
                if (clear) {
                    this.card.clear();
                    this.updateCardForm.get('cardHolderName').markAsPristine();
                    this.updateCardForm.get('cardHolderName').markAsUntouched();
                    this.updateCardForm.enable();
                    this.processingIntent = false;
                    this.intentProcessed = false;
                    this.card.update({ disabled: false });
                    this.awaitingCompletion = false;
                }
                console.log('clear the card form, reset', clear);
            });
        }
        if (this.disabled) {
            this.disabled.subscribe(disabled => {
                this.card.update({ disabled });
                this.disableForm = disabled;
                (disabled) ? this.updateCardForm.disable() : this.updateCardForm.enable();
            });
        }
    }
    ngAfterViewInit() {
        const style = {
            base: {
                fontFamily: '-apple-system, Arial, sans-serif',
                fontSmoothing: 'antialiased',
                fontSize: '16px'
            },
            invalid: {
                color: '#E81120',
                iconColor: '#E81120'
            }
        };
        this.card = this.elements.create('card', {
            style,
            iconStyle: 'solid'
        });
        this.card.mount(this.cardInfo.nativeElement);
        this.card.addEventListener('change', (e) => {
            console.log(e);
            this.cardComplete = e.complete;
            if (e.error) {
                this.cardError = e.error.message;
            }
            else {
                this.cardError = null;
            }
        });
    }
    onSubmit(form) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            console.log('onSubmit');
            if (!this.cardComplete) {
                if (!this.cardError) {
                    this.cardError = 'Please enter your card details';
                }
                return;
            }
            const cardHolderName = this.updateCardForm.get('cardHolderName').value;
            if (!cardHolderName.length) {
                return;
            }
            this.card.update({ disabled: true });
            this.processingIntent = true;
            this.updateCardForm.disable();
            const userAddressData = this._user.userDb.verification_data.address_details;
            const data = {
                billing_details: {
                    address: {
                        city: userAddressData.city,
                        country: 'GB',
                        line1: userAddressData.first_line_address,
                        line2: userAddressData.second_line_address,
                        postal_code: userAddressData.post_code
                    },
                    name: cardHolderName
                },
            };
            return this._stripe.createSetupIntent()
                .pipe(takeUntil(this.destroy$), switchMap((intent) => {
                console.log(intent);
                return from(this.stripe.handleCardSetup(intent.client_secret, this.card, {
                    payment_method_data: data
                }));
            }))
                .subscribe((item) => {
                console.log(item);
                if (item.error) {
                    this.processingIntent = false;
                    this.updateCardForm.enable();
                    this._toastr.error(item.error.message);
                    this.card.update({ disabled: false });
                    this.awaitingCompletion = false;
                }
                else {
                    this._toastr.success('Card successfully added');
                    this.processingIntent = false;
                    this.intentProcessed = true;
                    this.awaitingCompletion = this.clear ? true : false;
                    this.setupComplete.emit(item.setupIntent);
                }
            });
        });
    }
    goBack() {
        this.goBackVerification.emit(true);
    }
    /* getError(code: string): string {
      let message: string;
      switch (code) {
        case 'parameter_invalid_empty':
          message = this.cardHolderName.value ? 'Please ';
          break;
      
        default:
          message = 'An error has occured - please contact Bunk';
          break;
      }
      return message;
    } */
    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
}

import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {FormControl} from '@angular/forms';
import {map, startWith} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';
import {Observable, combineLatest, BehaviorSubject} from 'rxjs';
import {tap} from 'rxjs/internal/operators/tap';
import {of} from 'rxjs/internal/observable/of';
import {isArray} from 'rxjs/internal-compatibility';
import { environment } from '@env/environment';
import {AuthService} from '@app/core/services';
import { CompanyName } from '@app/shared/shared.constants';
import { EEnvironmentFlags } from '@rentbunk/bunk-models';

@Component({
  selector: 'shared-filters',
  template: `
    <ng-container *ngIf="router$ | async"></ng-container>
    <ng-container *ngIf="searchStr$ | async"></ng-container>
    <ng-container *ngIf="statusFilter$ | async"></ng-container>
    <ng-container *ngIf="portfolioFilter$ | async"></ng-container>
    <ng-container *ngIf="combined$ | async"></ng-container>


    <!-- for buttons -->
    <div class="flex flex-row justify-between margin-bottom shared-filter__header">
      <h2>{{title}}</h2>
       <ng-content select="[actionButton]"></ng-content>
    </div>

    <nav class="secondary__nav" *ngIf="showStatusNav">
      <span *ngFor="let item of statusFilterValues" class="secondary__nav-title"  [ngClass]="{'active': item.viewValue && statusViewValue && statusViewValue === item.viewValue }"
            (click)="displayFilterChoices(statusFilterValues, statusFilter, 'status', item.value)">{{item.viewValue}}</span>
    </nav>

    <div class="filter__container">
      <div class=filter__container--inner>
        <div class="search-filters " (clickOutside)="$event.value && clickedOutside($event)" [listen]="showFilters">

          <div class="search-bar--payments" *ngIf="showSearch">
            <instant-search-input
              class="search-bar__keyword"
              [formControl]="searchStr"
              [indexName]="indexName"
              placeholder="Search">
            </instant-search-input>
          </div>

          <div class="search-bar--payments"  *ngIf="instantSearchFilerValues">
          <instant-algolia-search
            class="search-bar__keyword"
            [indexName]="'properties'"
            [message]="'Filter by property'"
            [hideResults]="false"
            [storedIds]="storedIds"
            (storedIdsChange)="onStoredIdsChange($event)"
            [showImmediately]="true"
            [availableHits]="instantSearchFilerValues">
          </instant-algolia-search>
          </div>

          <button *ngIf="statusFilterValues" class=" margin-top--xs search-filters__btn-control btn__seethrough btn--round btn--sm mr_5"
                  (click)="displayFilterChoices(statusFilterValues, statusFilter, 'status')">
            <span>{{statusViewValue}}</span>
            <mat-icon svgIcon="icon-expand-more"></mat-icon>
          </button>

          <button *ngIf="typeFilterValues" class=" margin-top--xs search-filters__btn-control btn__seethrough btn--round btn--sm mr_5"
                  (click)="displayFilterChoices(typeFilterValues, typeFilter, 'type')">
            <span>{{typeViewValue}}</span>
            <mat-icon svgIcon="icon-expand-more"></mat-icon>
          </button>

          <button *ngIf="sortFilterValues" class="margin-top--xs search-filters__btn-control btn__seethrough btn--round btn--sm mr_5"
                  (click)="displayFilterChoices(sortFilterValues, sortFilter, 'sort')">
            <span>{{sortViewValue}}</span>
            <mat-icon svgIcon="icon-expand-more"></mat-icon>
          </button>

          <button *ngIf="portfolioFilterValues && portfolioFilterValues.length" class=" margin-top--xs search-filters__btn-control btn__seethrough btn--round btn--sm mr_5"
                  (click)="displayFilterChoices(portfolioFilterValues, portfolioFilter, 'portfolio')">
            <span>{{portfolioViewValue}}</span>
            <mat-icon svgIcon="icon-expand-more"></mat-icon>
          </button>


          <button *ngIf="assigneeFilterValues && assigneeFilterValues.length" class=" margin-top--xs search-filters__btn-control btn__seethrough btn--round btn--sm mr_5"
                  (click)="displayFilterChoices(assigneeFilterValues, assigneeFilter, 'assignee')">
            <span>{{assigneeViewValue}}</span>
            <mat-icon svgIcon="icon-expand-more"></mat-icon>
          </button>

          <!--           Add any unique filtering items -->

             <ng-content></ng-content>

          <div class="search-filters__controls-menu" *ngIf="showFilters">
<!--            <span class="btn__pseudo-link btn__pseudo-link&#45;&#45;text-secondary justify-end">Reset</span>-->
            <ng-container *ngTemplateOutlet="displayChoices"></ng-container>
            <button class="btn__info" (click)="showFilters = false">Done</button>
          </div>
        </div>

      </div>
    </div>


    <ng-template #displayChoices>
      <div class="filter__type-container filter__type-container--content cursor-pointer" (click)="updateFormValue(filter.value)" *ngFor="let filter of filterChoices">
        <p> {{filter.viewValue}}</p>
        <span class="selected" *ngIf="selectedFormControl.value === filter.value"></span>
      </div>
    </ng-template>
  `,
  styleUrls: ['./shared-filters.component.scss']
})

export class SharedFiltersComponent implements OnInit {
  public router$: Observable<any>;
  public searchStr$: Observable<any>;
  public statusFilter$: Observable<any>;
  public typeFilter$: Observable<any>;
  public portfolioFilter$: Observable<any>;
  public sortFilter$: Observable<any>;
  public assigneeFilter$: Observable<any>;
  public instantSearchFilter$: Observable<any>;

  public combined$: Observable<any>;
  public pagination$: BehaviorSubject<any> = new BehaviorSubject<any>({page: null, hits: null});
  public showChoices = false;
  public showFilters = false;
  public filterChoices;
  public selectedFormControl: FormControl = new FormControl(null);
  public searchStr: FormControl;
  public portfolioFilter: FormControl;
  public statusFilter: FormControl;
  public typeFilter: FormControl;
  public sortFilter: FormControl;
  public assigneeFilter: FormControl;
  public instantSearchFilter: FormControl;

  public statusViewValue = '';
  public portfolioViewValue = '';
  public typeViewValue = '';
  public sortViewValue = '';
  public filterName = '';
  public assigneeViewValue = '';
  public instantSearchViewValue = '';
  public isCampusKey = environment.firebaseConfig.projectId === 'client-campus-key';
  private hideAllPortfolios = environment.client_data.flags.includes(EEnvironmentFlags.HIDE_ALL_PORTFOLIOS_FILTER);

  // instant search
  public storedIds: string[] = [];
  public availableProperties: string[] = [];
  private state = new BehaviorSubject([]);
  private state$ = this.state.asObservable();
  public environment = environment;

  @Input() title;
  @Input() indexName;
  @Input() statusFilterValues;
  @Input() typeFilterValues;
  @Input() portfolioFilterValues;
  @Input() sortFilterValues;
  @Input() assigneeFilterValues;
  @Input() instantSearchFilerValues;
  @Input() showStatusNav = false;
  @Input() showSearch = true;
  @Input()
  get getPaginationParams(): any {
    return this.pagination$.value;
  }

  set getPaginationParams(pagination: any) {
    this.pagination$.next({page: pagination.page, hits: pagination.hits});
  }
  @Output() filterEmitter: EventEmitter<any> = new EventEmitter<any>();

  constructor(private _route: ActivatedRoute,
              private _router: Router,
              private _auth: AuthService) {
  }

  ngOnInit() {
    this.router$ = this._route.queryParamMap.pipe(
      tap((event: any) => {
        return this.initFilterObservables();
      })
    );

    this.initFilterObservables();
  }

  getStartUpPortfolio() {
    const queryParam = this._route.snapshot.queryParamMap.get('portfolio');
    if (queryParam) {
      if (this.hideAllPortfolios && queryParam === 'all') {
        return this.portfolioFilterValues && this.portfolioFilterValues.length ? this.portfolioFilterValues[0].value : '';
      }
      return queryParam;
    }
    return 'all';
  }

  initFilterObservables() {
    const startUpStatusFilter = (this._route.snapshot.queryParamMap.get('filter')) ? this._route.snapshot.queryParamMap.get('filter')
      : this.statusFilterValues && this.statusFilterValues[0] ? this.statusFilterValues[0].value : 'all';
    const startUpTypeFilter = (this._route.snapshot.queryParamMap.get('type')) ? this._route.snapshot.queryParamMap.get('type') : 'all';
    const startUpSearchString = (this._route.snapshot.queryParamMap.get('search')) ? this._route.snapshot.queryParamMap.get('search') : '';
    const startUpPortfolioString = this.getStartUpPortfolio();
    console.log(startUpPortfolioString);
    const startUpAssigneeString = (this._route.snapshot.queryParamMap.get('assignee')) ? this._route.snapshot.queryParamMap.get('assignee') : 'all';
    const startUpInstantSearch = (this._route.snapshot.queryParamMap.get('instantSearch')) ? this._route.snapshot.queryParamMap.get('instantSearch') : 'all';

    const startUpSortFilter = (this._route.snapshot.queryParamMap.get('sort')) ? this._route.snapshot.queryParamMap.get('sort')
      : this.sortFilterValues && this.sortFilterValues[0] ? this.sortFilterValues[0].value : 'date';
    this.sortFilter = new FormControl(startUpSortFilter);
    this.statusFilter = new FormControl(startUpStatusFilter);
    this.typeFilter = new FormControl(startUpTypeFilter);
    this.searchStr = new FormControl(startUpSearchString);
    this.portfolioFilter = new FormControl(startUpPortfolioString);
    this.assigneeFilter = new FormControl(startUpAssigneeString);
    this.instantSearchFilter = new FormControl(startUpInstantSearch);



    this.sortFilter$ = this.sortFilter.valueChanges.pipe(startWith(startUpSortFilter),
    map((value: any) => {
      this.sortViewValue = (this.sortFilterValues) ? this.getViewValue(this.sortFilterValues, value) : null;
      return (this.sortFilterValues) ? value : null;
    }));

    this.searchStr$ = this.searchStr.valueChanges.pipe(startWith(startUpSearchString),
      map((value: any) => (this.showSearch) ? value : null));

    this.statusFilter$ = this.statusFilter.valueChanges.pipe(startWith(startUpStatusFilter),
      map((value: any) => {
        this.statusViewValue = (this.statusFilterValues) ? this.getViewValue(this.statusFilterValues, value) : null;
        return (this.statusFilterValues) ? value : null;
      }));

    this.typeFilter$ = this.typeFilter.valueChanges.pipe(startWith(startUpTypeFilter),
      map((value: any) => {
        this.typeViewValue = (this.typeFilterValues) ? this.getViewValue(this.typeFilterValues, value) : null;
        return (this.typeFilterValues) ? value : null;
      }));

    this.portfolioFilter$ = this.getPortfolioFilter(startUpPortfolioString);
    this.assigneeFilter$ = this.getAssigneeFilter(startUpAssigneeString);
    this.instantSearchFilter$ = this.getInstantFilter(startUpInstantSearch);

    this.combined$ = combineLatest([this.searchStr$, this.statusFilter$, this.typeFilter$, this.sortFilter$, this.portfolioFilter$, this.assigneeFilter$, this.instantSearchFilter$, this.pagination$.asObservable()]).pipe(
      tap(([search, filter, type, sort, portfolio, assignee, instantSearch, pagination]) => this._router.navigate(['.'], {
        queryParams: {filter, type, portfolio, assignee,  sort, search, instantSearch, hits: pagination.hits, page: pagination.page},
        relativeTo: this._route,
        queryParamsHandling: 'merge',
      })),
      map(([search, filter, type, sort, portfolio, assignee, instantSearch]) => {
        const portfolioFilter = (portfolio && portfolio !== 'all') ? {portfolio_id: this.portfolioFilterValues.find((value: any) => value.value === portfolio).value, property_ids: this.portfolioFilterValues.find((value: any) => value.value === portfolio).additional} : 'all';
        console.log(portfolioFilter, 'in shared');
        const assigneeFilter = (assignee && assignee !== 'all') ? this.assigneeFilterValues.find((value: any) => value.value === assignee) : 'all';
        this.filterEmitter.emit({search, filter, type, sort, instantSearch, portfolio: portfolioFilter, assignee: assigneeFilter});
      }));
  }

  getPortfolioFilter(startUpPortfolioString: string) {
    return !(this.portfolioFilterValues && this.portfolioFilterValues.length > 0) ? of(null) : this.portfolioFilter.valueChanges.pipe(startWith(startUpPortfolioString),
      map((value: any) => {
        console.log(value, 'set the value');
        if (CompanyName.isNrla && this._auth.currentUserId === 'W2s0Azx4aXeNDmkBuWSy9HEyQZx1' ) {
          value = 'mLu9b7KnL2CTbdd1qSTt';
        }
        this.portfolioViewValue = this.getViewValue(this.portfolioFilterValues, value);
        return value;
      }));
  }

  getAssigneeFilter(startUpAssigneeString: string) {
    return (this.assigneeFilterValues && this.assigneeFilterValues.length > 0)
      ? this.assigneeFilter.valueChanges.pipe(startWith(startUpAssigneeString),
        map((value: any) => {
          this.assigneeViewValue = this.getViewValue(this.assigneeFilterValues, value);
          return value;
        }))
      : of(null);
  }

  getInstantFilter(startUpInstantFilter: string) {

    let updatedState;
    if (startUpInstantFilter && isArray(startUpInstantFilter)) {
      updatedState = [...startUpInstantFilter];
      this.storedIds = startUpInstantFilter.filter((v) => v !== 'all');
    } else if (startUpInstantFilter) {
      updatedState = [startUpInstantFilter];
      this.storedIds = startUpInstantFilter === 'all' ? [] : [startUpInstantFilter];
    } else {
      updatedState = ['all'];
      this.storedIds = [];
    }
    this.state.next(updatedState);
    return this.state.asObservable();

  }


  displayFilterChoices(types: any, formControl: FormControl, name: string, statusValue?: string) {
    this.showChoices =  true;
    this.showFilters = true;
    this.filterChoices = types;
    this.selectedFormControl = formControl;
    this.filterName = name;

    if (statusValue) {
      this.updateFormValue(statusValue)
    }
  }

  clickedOutside(event) {
    if (!event.target.className.includes('mat-calendar') && !event.target.className.includes('btn__pseudo-link')) {
      this.showFilters = false;
    }
  }

  updateFormValue(value: string) {
    switch (this.filterName) {
      case 'status':
        this.statusFilter.setValue(value);
        this.statusFilter.updateValueAndValidity();
        break;
      case 'portfolio':
        this.portfolioFilter.setValue(value);
        this.portfolioFilter.updateValueAndValidity();
        break;
      case 'type':
        this.typeFilter.setValue(value);
        this.typeFilter.updateValueAndValidity();
        break;
      case 'sort':
        this.sortFilter.setValue(value);
        this.sortFilter.updateValueAndValidity();
        break;
      case 'assignee':
        this.assigneeFilter.setValue(value);
        this.assigneeFilter.setValue(value);
        this.assigneeFilter.updateValueAndValidity();
        break;
      case 'instantSearch':
        this.instantSearchFilter.setValue(value);
        this.instantSearchFilter.updateValueAndValidity();
        break;
    }
    this.selectedFormControl.setValue(value);
  }

  getViewValue(filterValues: any, value: any) {
    const arrayValue = filterValues.find((type: any) => type.value === value);
    if (!arrayValue) {
      return '';
    }
    return arrayValue.viewValue;
  }

  onStoredIdsChange(value: string[]) {
    this.storedIds = value;
    const updatedState = (value.length <= 0) ? ['all'] : value;
    this.state.next(updatedState);
  }


}

import { Component, OnDestroy, Input } from '@angular/core';
import { TenantVerifyEventEmiterService } from '../profile-tenant.service';
import { UserService } from '@app/services';
import { Subject, Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {environment} from '@env/environment';

@Component({
  selector: 'tenant-bunk-passport-overview',
  template: `
    <screen-detection (resized)="isDesktop = $event.isDesktop"></screen-detection>
    <ng-container *ngIf="userReadOnly$ | async as userReadOnly">
      <div class="request-viewings request-viewings--header margin-bottom--lg">
        <div class="request-viewings__body">
          <p><strong>Rental Passport</strong> is your free rental identity.</p>
          <p>We use this information to carry out referencing checks and help the landlord understand a little more about yourself.</p>
          <p>Your data is completely private and secure. {{client_data.company_name}} will never share your data without your permission.</p>

          <p class="request-viewings__cta-cell"><button class="btn__cta btn--lg btn--round margin-top--md" (click)="next()">Complete my Passport</button></p>
        </div>

        <div class="request-viewings__passport-holder">
          <div class="request-viewings__passport">
            <span class="bunk-pp__logo-left">RENTAL</span><span class="bunk-pp__logo-right">PASSPORT</span>

            <img
              class="bunk-pp__avatar"
              alt="My profile picture"
              [src]="(_user.userDb?.profile_data.profile_image_url ? _user.userDb?.profile_data.profile_image_url : '/assets/img/passport/passport-avatar.png')">

            <ul class="bunk-pp__tiers hide-sm hide-md">
              <li class="bunk-pp__tier-level">
                <span class="status__tick bunk-pp__tier-level-icon"></span><strong>Message landlords</strong>
              </li>
              <li class="bunk-pp__tier-level" [ngClass]="{'bunk-pp__tier-level--inactive': userReadOnly.verification_tier < 2}">
                <span *ngIf="userReadOnly.verification_tier >= 2" class="status__tick bunk-pp__tier-level-icon"></span>
                <mat-icon *ngIf="userReadOnly.verification_tier < 2" svgIcon="icon-lock" class="bunk-pp__tier-level-icon"></mat-icon>
                <strong>Request viewings</strong>
              </li>
              <li class="bunk-pp__tier-level" [ngClass]="{'bunk-pp__tier-level--inactive': userReadOnly.verification_tier < 3}">
                <span *ngIf="userReadOnly.verification_tier >= 3" class="status__tick bunk-pp__tier-level-icon"></span>
                <mat-icon *ngIf="userReadOnly.verification_tier < 3" svgIcon="icon-lock" class="bunk-pp__tier-level-icon"></mat-icon>
                <strong>Make offers</strong>
              </li>
            </ul>
          </div>
        </div>
      </div>

    </ng-container>`,
  styleUrls: ['./tenant-account-step.component.scss']
})
export class TenantBunkPassportOverViewComponent implements OnDestroy {
  @Input() standAlone = false;
  private destroy$: Subject<boolean> = new Subject<boolean>();
  public userReadOnly$: Observable<any>;
  isDesktop;
  public client_data = environment.client_data;
  constructor(
    public _verifyEvent: TenantVerifyEventEmiterService,
    public _user: UserService,
  ) {
    this._user.user$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(user => {
      if (user) {
        this.userReadOnly$ = this._user.getUserReadOnlyById(user.uid).valueChanges();
      }
    });
  }
  next() {
    if (this.standAlone) {
      this._verifyEvent.nextStep();
    } else {
      this._verifyEvent.stepComplete();
    }
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}

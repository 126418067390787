/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./infinte-scroll.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../processing-spinner/processing-spinner.component.ngfactory";
import * as i4 from "../processing-spinner/processing-spinner.component";
import * as i5 from "../../../../../node_modules/@angular/cdk/scrolling/typings/index.ngfactory";
import * as i6 from "@angular/cdk/scrolling";
import * as i7 from "@angular/cdk/bidi";
import * as i8 from "./infinite-scroll.component";
import * as i9 from "../../../core/services/auth.service";
import * as i10 from "@angular/fire/firestore";
var styles_InfinteScrollComponent = [i0.styles];
var RenderType_InfinteScrollComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_InfinteScrollComponent, data: {} });
export { RenderType_InfinteScrollComponent as RenderType_InfinteScrollComponent };
function View_InfinteScrollComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_InfinteScrollComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_InfinteScrollComponent_4)), i1.ɵdid(2, 540672, null, 0, i2.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutletContext: [0, "ngTemplateOutletContext"], ngTemplateOutlet: [1, "ngTemplateOutlet"] }, null), i1.ɵpod(3, { $implicit: 0 }), i1.ɵncd(null, 0), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _ck(_v, 3, 0, _v.context.$implicit); var currVal_1 = _co.templateRef; _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_InfinteScrollComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 2, "div", [["class", "flex justify-center margin-top--sm margin-bottom--sm"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "processing-spinner", [["color", "#FFFFFF"]], null, null, null, i3.View_ProcessingSpinnerComponent_0, i3.RenderType_ProcessingSpinnerComponent)), i1.ɵdid(3, 114688, null, 0, i4.ProcessingSpinnerComponent, [], { color: [0, "color"], size: [1, "size"] }, null)], function (_ck, _v) { var currVal_0 = "#FFFFFF"; var currVal_1 = "52px"; _ck(_v, 3, 0, currVal_0, currVal_1); }, null); }
function View_InfinteScrollComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, null, null, null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 8, "cdk-virtual-scroll-viewport", [["class", "cdk-virtual-scroll-viewport"], ["itemSize", "100"]], [[2, "cdk-virtual-scroll-orientation-horizontal", null], [2, "cdk-virtual-scroll-orientation-vertical", null]], [[null, "scrolledIndexChange"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("scrolledIndexChange" === en)) {
        var pd_0 = (_co.nextBatch($event, _v.parent.context.ngIf[(_v.parent.context.ngIf.length - 1)][_co.query.order.key]) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i5.View_CdkVirtualScrollViewport_0, i5.RenderType_CdkVirtualScrollViewport)), i1.ɵprd(6144, null, i6.CdkScrollable, null, [i6.CdkVirtualScrollViewport]), i1.ɵdid(3, 540672, null, 0, i6.CdkFixedSizeVirtualScroll, [], { itemSize: [0, "itemSize"] }, null), i1.ɵprd(1024, null, i6.VIRTUAL_SCROLL_STRATEGY, i6._fixedSizeVirtualScrollStrategyFactory, [i6.CdkFixedSizeVirtualScroll]), i1.ɵdid(5, 245760, [[1, 4]], 0, i6.CdkVirtualScrollViewport, [i1.ElementRef, i1.ChangeDetectorRef, i1.NgZone, [2, i6.VIRTUAL_SCROLL_STRATEGY], [2, i7.Directionality], i6.ScrollDispatcher], null, { scrolledIndexChange: "scrolledIndexChange" }), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_InfinteScrollComponent_3)), i1.ɵdid(7, 409600, null, 0, i6.CdkVirtualForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers, [1, i6.CdkVirtualScrollViewport], i1.NgZone], { cdkVirtualForOf: [0, "cdkVirtualForOf"], cdkVirtualForTrackBy: [1, "cdkVirtualForTrackBy"] }, null), (_l()(), i1.ɵand(16777216, null, 0, 1, null, View_InfinteScrollComponent_5)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_2 = "100"; _ck(_v, 3, 0, currVal_2); _ck(_v, 5, 0); var currVal_3 = _v.parent.context.ngIf; var currVal_4 = _co.trackByIdx; _ck(_v, 7, 0, currVal_3, currVal_4); var currVal_5 = _co.loading; _ck(_v, 9, 0, currVal_5); }, function (_ck, _v) { var currVal_0 = (i1.ɵnov(_v, 5).orientation === "horizontal"); var currVal_1 = (i1.ɵnov(_v, 5).orientation !== "horizontal"); _ck(_v, 1, 0, currVal_0, currVal_1); }); }
function View_InfinteScrollComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InfinteScrollComponent_2)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = (_v.context.ngIf && (_v.context.ngIf.length > 0)); var currVal_1 = i1.ɵnov(_v.parent, 4); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_InfinteScrollComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "flex justify-center margin-top--sm margin-bottom--sm"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 1, "p", [["class", "text--subtle"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["You have no notifications."]))], null, null); }
export function View_InfinteScrollComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { viewport: 0 }), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_InfinteScrollComponent_1)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef]), (_l()(), i1.ɵand(0, [["noData", 2]], null, 0, null, View_InfinteScrollComponent_6))], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 2, 0, i1.ɵnov(_v, 3).transform(_co.data$)); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_InfinteScrollComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "infinite-scroll", [], null, null, null, View_InfinteScrollComponent_0, RenderType_InfinteScrollComponent)), i1.ɵdid(1, 114688, null, 1, i8.InfinteScrollComponent, [i9.AuthService, i10.AngularFirestore], null, null), i1.ɵqud(603979776, 1, { templateRef: 0 })], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var InfinteScrollComponentNgFactory = i1.ɵccf("infinite-scroll", i8.InfinteScrollComponent, View_InfinteScrollComponent_Host_0, { batch: "batch", getScrollQuery: "getScrollQuery" }, { emitTheEnd: "emitTheEnd" }, ["*"]);
export { InfinteScrollComponentNgFactory as InfinteScrollComponentNgFactory };

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {SendMessageDirective} from './send-message.directive'

@NgModule({
  imports: [
    CommonModule,
  ],
  exports:[ SendMessageDirective ],
  declarations: [SendMessageDirective]
})
export class SendMessageModule { }

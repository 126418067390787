/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./bunk-passport-verify-tiers.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "ng-dynamic-component";
import * as i4 from "./bunk-passport-verify-tiers.component";
import * as i5 from "../profile-tenant.service";
import * as i6 from "@angular/router";
import * as i7 from "../../services/user.service";
import * as i8 from "../../core/services/auth.service";
var styles_TenantAccountVerifyTiersComponent = [i0.styles];
var RenderType_TenantAccountVerifyTiersComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TenantAccountVerifyTiersComponent, data: {} });
export { RenderType_TenantAccountVerifyTiersComponent as RenderType_TenantAccountVerifyTiersComponent };
function View_TenantAccountVerifyTiersComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_TenantAccountVerifyTiersComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 5, "section", [["class", "main__wrapper main__wrapper--narrow main__wrapper--mb-lg"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 4, null, View_TenantAccountVerifyTiersComponent_3)), i1.ɵdid(2, 671744, null, 0, i2.NgComponentOutlet, [i1.ViewContainerRef], { ngComponentOutlet: [0, "ngComponentOutlet"] }, null), i1.ɵprd(131584, null, i3.ɵb, i3.ɵb, [i1.KeyValueDiffers, i1.ComponentFactoryResolver]), i1.ɵdid(4, 16384, null, 0, i3.ɵc, [i2.NgComponentOutlet], null, null), i1.ɵdid(5, 802816, null, 0, i3.DynamicDirective, [i1.Injector, i3.ɵb, i3.ɵa, [2, i3.ɵc]], { ngComponentOutletNdcDynamicInputs: [0, "ngComponentOutletNdcDynamicInputs"], ngComponentOutletNdcDynamicOutputs: [1, "ngComponentOutletNdcDynamicOutputs"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.stepComponent; _ck(_v, 2, 0, currVal_0); var currVal_1 = _co.inputs; var currVal_2 = _co.outputs; _ck(_v, 5, 0, currVal_1, currVal_2); }, null); }
function View_TenantAccountVerifyTiersComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "span", [["class", "status__step status__step--verify"], ["role", "button"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(3, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(4, { "status__step--verify-tier-pending": 0, "status__step--verify-complete": 1 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = "status__step status__step--verify"; var currVal_1 = _ck(_v, 4, 0, (_co.currentStepNumber === _v.context.index), (_v.context.$implicit.status === "approved")); _ck(_v, 3, 0, currVal_0, currVal_1); }, null); }
function View_TenantAccountVerifyTiersComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "footer", [["class", "verify-tiers__footer"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "ul", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 4, "li", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 3, "span", [["class", "status__step status__step--verify-tier-pending"], ["role", "button"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(5, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(6, { "status__step--verify-complete": 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TenantAccountVerifyTiersComponent_5)), i1.ɵdid(8, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = "status__step status__step--verify-tier-pending"; var currVal_1 = _ck(_v, 6, 0, (_co.currentStepNumber !== (0 - 1))); _ck(_v, 5, 0, currVal_0, currVal_1); var currVal_2 = _v.parent.context.ngIf; _ck(_v, 8, 0, currVal_2); }, null); }
function View_TenantAccountVerifyTiersComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TenantAccountVerifyTiersComponent_2)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TenantAccountVerifyTiersComponent_4)), i1.ɵdid(4, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.stepComponent; _ck(_v, 2, 0, currVal_0); var currVal_1 = ((_v.context.ngIf.length > 1) && !_co.requestViewings); _ck(_v, 4, 0, currVal_1); }, null); }
export function View_TenantAccountVerifyTiersComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 2, null, View_TenantAccountVerifyTiersComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵpid(131072, i2.AsyncPipe, [i1.ChangeDetectorRef])], function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵunv(_v, 1, 0, i1.ɵnov(_v, 2).transform(_co.userSteps$)); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_TenantAccountVerifyTiersComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "app-tenant-verify-tier", [], null, null, null, View_TenantAccountVerifyTiersComponent_0, RenderType_TenantAccountVerifyTiersComponent)), i1.ɵdid(1, 180224, null, 0, i4.TenantAccountVerifyTiersComponent, [i5.TenantVerifyEventEmiterService, i6.Router, i6.ActivatedRoute, i7.UserService, i8.AuthService], null, null)], null, null); }
var TenantAccountVerifyTiersComponentNgFactory = i1.ɵccf("app-tenant-verify-tier", i4.TenantAccountVerifyTiersComponent, View_TenantAccountVerifyTiersComponent_Host_0, { tier: "tier", propertyThoroughfare: "propertyThoroughfare", overlay: "overlay" }, { callback: "callback", currentPageEvent: "currentPageEvent" }, []);
export { TenantAccountVerifyTiersComponentNgFactory as TenantAccountVerifyTiersComponentNgFactory };

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./instant-new-algolia-search.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../cards/profile-cards/profile-avatar.component.ngfactory";
import * as i4 from "../../cards/profile-cards/profile-avatar.component";
import * as i5 from "../../../../../../node_modules/@angular/material/checkbox/typings/index.ngfactory";
import * as i6 from "@angular/forms";
import * as i7 from "@angular/material/checkbox";
import * as i8 from "@angular/cdk/a11y";
import * as i9 from "@angular/platform-browser/animations";
import * as i10 from "./instant-new-algolia-search.component";
var styles_InstantNewAlgoliaSearchComponent = [i0.styles];
var RenderType_InstantNewAlgoliaSearchComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_InstantNewAlgoliaSearchComponent, data: {} });
export { RenderType_InstantNewAlgoliaSearchComponent as RenderType_InstantNewAlgoliaSearchComponent };
function View_InstantNewAlgoliaSearchComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "button", [["class", "i-search__input-icon-close"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.clearSearch() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["alt", "Close list"], ["src", "/assets/svg/baseline-close-24px.svg"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.hideResults = true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, null); }
function View_InstantNewAlgoliaSearchComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_InstantNewAlgoliaSearchComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_InstantNewAlgoliaSearchComponent_5)), i1.ɵdid(2, 540672, null, 0, i2.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutletContext: [0, "ngTemplateOutletContext"], ngTemplateOutlet: [1, "ngTemplateOutlet"] }, null), i1.ɵpod(3, { hit: 0 }), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = _ck(_v, 3, 0, _v.context.$implicit); var currVal_1 = i1.ɵnov(_v.parent.parent.parent, 4); _ck(_v, 2, 0, currVal_0, currVal_1); }, null); }
function View_InstantNewAlgoliaSearchComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, [[2, 0], ["searchHits", 1]], null, 2, "div", [["class", "i-search__hits-container"]], null, [[null, "clickOutside"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("clickOutside" === en)) {
        var pd_0 = (_co.clickHideSearchResults($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InstantNewAlgoliaSearchComponent_4)), i1.ɵdid(2, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.resultsList; _ck(_v, 2, 0, currVal_0); }, null); }
function View_InstantNewAlgoliaSearchComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 9, "div", [["class", "i-search"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "img", [["alt", "Search..."], ["class", "i-search__input-icon"], ["src", "/assets/svg/baseline-search-24px.svg"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InstantNewAlgoliaSearchComponent_2)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(4, 0, [[1, 0], ["searchInput", 1]], null, 3, "input", [["autocomplete", "off"], ["class", "i-search__input"]], [[8, "placeholder", 0], [8, "disabled", 0]], [[null, "input"], [null, "click"], [null, "keydown.esc"], [null, "keydown.backspace"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("input" === en)) {
        var pd_0 = (_co.setSearchQuery($event) !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.setSearchQuery($event) !== false);
        ad = (pd_1 && ad);
    } if (("keydown.esc" === en)) {
        var pd_2 = (_co.hideSearchResults() !== false);
        ad = (pd_2 && ad);
    } if (("keydown.backspace" === en)) {
        var pd_3 = (_co.clearSearch(false) !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(6, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(7, { "i-search__input--active": 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InstantNewAlgoliaSearchComponent_3)), i1.ɵdid(9, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (i1.ɵnov(_v, 4).value.length > 1); _ck(_v, 3, 0, currVal_0); var currVal_3 = "i-search__input"; var currVal_4 = _ck(_v, 7, 0, _co.showHits); _ck(_v, 6, 0, currVal_3, currVal_4); var currVal_5 = _co.showHits; _ck(_v, 9, 0, currVal_5); }, function (_ck, _v) { var _co = _v.component; var currVal_1 = i1.ɵinlineInterpolate(1, "", (!_co.disabled ? _co.message : _co.disabledMessage), ""); var currVal_2 = _co.disabled; _ck(_v, 4, 0, currVal_1, currVal_2); }); }
function View_InstantNewAlgoliaSearchComponent_8(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "profile-avatar", [["size", "sm"]], null, null, null, i3.View_ProfileAvatarComponent_0, i3.RenderType_ProfileAvatarComponent)), i1.ɵdid(1, 114688, null, 0, i4.ProfileAvatarComponent, [], { size: [0, "size"], profileName: [1, "profileName"], profileImgUrl: [2, "profileImgUrl"] }, null)], function (_ck, _v) { var currVal_0 = "sm"; var currVal_1 = _v.parent.parent.context.hit.profile_data.full_name; var currVal_2 = _v.parent.parent.context.hit.profile_data.profile_image_url; _ck(_v, 1, 0, currVal_0, currVal_1, currVal_2); }, null); }
function View_InstantNewAlgoliaSearchComponent_9(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "mat-checkbox", [["class", "prompt mat-checkbox"]], [[8, "id", 0], [1, "tabindex", 0], [2, "mat-checkbox-indeterminate", null], [2, "mat-checkbox-checked", null], [2, "mat-checkbox-disabled", null], [2, "mat-checkbox-label-before", null], [2, "_mat-animation-noopable", null]], null, null, i5.View_MatCheckbox_0, i5.RenderType_MatCheckbox)), i1.ɵprd(5120, null, i6.NG_VALUE_ACCESSOR, function (p0_0) { return [p0_0]; }, [i7.MatCheckbox]), i1.ɵdid(2, 8568832, [["checkbox", 4]], 0, i7.MatCheckbox, [i1.ElementRef, i1.ChangeDetectorRef, i8.FocusMonitor, i1.NgZone, [8, null], [2, i7.MAT_CHECKBOX_CLICK_ACTION], [2, i9.ANIMATION_MODULE_TYPE]], { id: [0, "id"], checked: [1, "checked"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_7 = _co.getValuesFromObject(_v.parent.parent.context.hit, _co.fieldsToReturn)[0]; var currVal_8 = _co.isChecked(_co.getValuesFromObject(_v.parent.parent.context.hit, _co.fieldsToReturn)[0]); _ck(_v, 2, 0, currVal_7, currVal_8); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 2).id; var currVal_1 = null; var currVal_2 = i1.ɵnov(_v, 2).indeterminate; var currVal_3 = i1.ɵnov(_v, 2).checked; var currVal_4 = i1.ɵnov(_v, 2).disabled; var currVal_5 = (i1.ɵnov(_v, 2).labelPosition == "before"); var currVal_6 = (i1.ɵnov(_v, 2)._animationMode === "NoopAnimations"); _ck(_v, 0, 0, currVal_0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6); }); }
function View_InstantNewAlgoliaSearchComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 7, "label", [["type", "label"]], null, [[null, "click"], [null, "keydown.space"], [null, "keydown.enter"], [null, "keydown.esc"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.setValueChanges(_v.parent.context.hit, _v.context.ngIf) !== false);
        ad = (pd_0 && ad);
    } if (("keydown.space" === en)) {
        var pd_1 = (_co.setValueChanges(_v.parent.context.hit, _v.context.ngIf) !== false);
        ad = (pd_1 && ad);
    } if (("keydown.enter" === en)) {
        var pd_2 = (_co.setValueChanges(_v.parent.context.hit, _v.context.ngIf) !== false);
        ad = (pd_2 && ad);
    } if (("keydown.esc" === en)) {
        var pd_3 = (_co.hideSearchResults() !== false);
        ad = (pd_3 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 6, "ul", [["aria-role", "button"], ["class", "i-search__hit-row"], ["tabindex", "0"]], [[1, "aria-label", 0]], null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InstantNewAlgoliaSearchComponent_8)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(4, 0, null, null, 1, "p", [], null, null, null, null, null)), (_l()(), i1.ɵted(5, null, [" ", " "])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InstantNewAlgoliaSearchComponent_9)), i1.ɵdid(7, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.showAvatar; _ck(_v, 3, 0, currVal_1); var currVal_3 = _co.multiselect; _ck(_v, 7, 0, currVal_3); }, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "Add ", _v.context.ngIf, ""); _ck(_v, 1, 0, currVal_0); var currVal_2 = _v.context.ngIf; _ck(_v, 5, 0, currVal_2); }); }
function View_InstantNewAlgoliaSearchComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_InstantNewAlgoliaSearchComponent_7)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.getValueFromObject(_v.context.hit, _co.fieldsToDisplay); _ck(_v, 1, 0, currVal_0); }, null); }
export function View_InstantNewAlgoliaSearchComponent_0(_l) { return i1.ɵvid(0, [i1.ɵqud(671088640, 1, { searchInput: 0 }), i1.ɵqud(671088640, 2, { searchHits: 0 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_InstantNewAlgoliaSearchComponent_1)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(0, [["algoliaList", 2]], null, 0, null, View_InstantNewAlgoliaSearchComponent_6))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.resultsList; _ck(_v, 3, 0, currVal_0); }, null); }
export function View_InstantNewAlgoliaSearchComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "instant-new-algolia-search", [], null, null, null, View_InstantNewAlgoliaSearchComponent_0, RenderType_InstantNewAlgoliaSearchComponent)), i1.ɵdid(1, 114688, null, 0, i10.InstantNewAlgoliaSearchComponent, [i1.ChangeDetectorRef], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var InstantNewAlgoliaSearchComponentNgFactory = i1.ɵccf("instant-new-algolia-search", i10.InstantNewAlgoliaSearchComponent, View_InstantNewAlgoliaSearchComponent_Host_0, { env: "env", indexName: "indexName", disabled: "disabled", disabledMessage: "disabledMessage", message: "message", ignoreList: "ignoreList", hideResults: "hideResults", showImmediately: "showImmediately", multiselect: "multiselect", showAvatar: "showAvatar", fieldsToDisplay: "fieldsToDisplay", fieldsToReturn: "fieldsToReturn", storedIds: "storedIds", availableHits: "availableHits" }, { valueChange: "valueChange", storedIdsChange: "storedIdsChange" }, []);
export { InstantNewAlgoliaSearchComponentNgFactory as InstantNewAlgoliaSearchComponentNgFactory };

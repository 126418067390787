import {Directive, ElementRef, HostListener, Input, OnDestroy, Output, EventEmitter} from '@angular/core';
import {switchMap, take, takeUntil} from 'rxjs/operators';
import {of, Subject} from 'rxjs';
import {GroupService} from '@app/services/group.service';
import {Router} from '@angular/router';

@Directive({
  selector: '[appContactButton]'
})
export class ContactButtonDirective implements OnDestroy {
  private onDestroy: Subject<null> = new Subject();

  @Input() property: any;
  @Input() addresseeId: string;


  @HostListener('click', ['$event.target'])
  onClick() {
    this.contact(this.addresseeId, this.property);
  }

  constructor(el: ElementRef,
              private _group: GroupService,
              private router: Router) {
  }

  contact(userId: string, property: any) {
    console.log({property: this.property});
    this._group.getAllGroupsOfCurrentAndOtherUser(userId, property.property_id )
      .pipe(
        take(1),
        switchMap((array: any[]) => {
          if (array.length > 0) {
            this.router.navigate(['/inbox', array[0].group_id]);
            return of(null);
          } else {
            return this._group.initChatGroup(userId, property);
          }
        }),
      )
      .subscribe(result => {
        if (result === null) {
          return;
        }
        this.router.navigate(['/inbox', result]);
      });
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

}

import {Component, Input} from '@angular/core';
import {MaintenanceService} from '@app/services';
import {FormArray, FormBuilder, FormGroup} from '@angular/forms';
import {StorageService} from '@app/services';
import {AuthService} from '@app/core/services';

import {last, mergeMap, tap} from 'rxjs/operators';

@Component({
  selector: 'form-tenant-maintenance',
  template: `
    <form [formGroup]="formGroup">
      <ng-content></ng-content>
      <div class="">
        <div class=" rc dialog-viewer_base jc_c ai_c">
          <div class="cc input__container">
            <h1 class="title_1">Title</h1>
            <div class="rc">
              <mat-form-field class="form_input w_100">
                <textarea matInput formControlName="title" class="textarea_sml"></textarea>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="rc dialog-viewer_base jc_c ai_c">
          <div class="cc input__container">
            <h1 class="title_1">Description</h1>
            <mat-form-field class="form_input">
              <textarea matInput formControlName="description" maxlength="600" class="textarea"></textarea>
            </mat-form-field>
            <div class="textarea__counter">{{formGroup.get('description').value.length}}/600</div>
          </div>
        </div>
        <div class="rc dialog-viewer_base jc_c ai_c">
          <div class="cc input__container image_scroll">
            <h1 class="title_1">Upload Photos</h1>
            <div class="uploaded-images__container rc">
              <div class="uploaded-image__container" *ngFor="let picture of property_photos.value">
                <img *ngIf="picture" [src]="picture" class="uploaded-image shadow_base">
              </div>
              <div class="uploaded-image__container">
                <label for="property-pictures-upload" class="pictures-upload_label shadow_base">
                  <input type="file" id="property-pictures-upload"
                         (change)="uploadPropertyPictures($event)">+
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>`,
  styleUrls: ['./form-tenant-maintenance-request.component.scss']
})
export class FormTenantMaintenanceRequestComponent {
  @Input() formGroup: FormGroup;

  constructor(private _maintenance: MaintenanceService,
              private _storage: StorageService,
              private _auth: AuthService,
              private _formBuilder: FormBuilder) {
  }

  get property_photos(): FormArray {
    return this.formGroup.get('maintenance_images_url') as FormArray;
  }

  uploadPropertyPictures(file) {
    this._storage.uploadFile(file, `tenancies/maintenance/${this._auth.currentUserId}`)
      .pipe(
        last(),
        mergeMap(() => this._storage.downloadURL),
        tap(url => this.property_photos.push(this._formBuilder.control(url)))
      )
      .subscribe(
        () => console.log('Image uploaded'),
        error => console.log('An error has occurred: ', error)
      );
  }


}

import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { UserService } from '@app/services';
import { map } from 'rxjs/operators';
import {AuthService} from '@app/core/services';

@Injectable()
export class userReadOnlyGuard implements CanActivate {
  constructor(private router: Router,
              private _user: UserService,
              ) {
  }

  canActivate(route: ActivatedRouteSnapshot,
              state: RouterStateSnapshot) {
    return this._user.userReadOnly$
      .pipe(
        map(user => {
          if (user) {
            console.log('hasUsersReadOnly');
            return true;
          }
          return false;
        })
      );
  }
}

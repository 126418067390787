import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { PropertyService, TenancyOfferService } from '@app/services';
import {  Router } from '@angular/router';
import { Documents, Landlord, References } from '@env/routing';
import { MathService } from '@app/services/math.service';

@Component({
  selector: 'applications-summary',
  templateUrl: './applications-summary.component.html',
  styleUrls: ['./applications-summary.component.scss']
})
export class ApplicationsSummaryComponent implements OnInit {
  tenancy_offers$: Observable<any>;
  offerTypes: any = {
    'application': { name: 'Offer', color: 'danger' },
    'references': { name: 'Referencing', color: 'warning' },
    'securing_payment': { name: 'Move-in', color: 'primary' },
    'tenancy_agreement': { name: 'Contract', color: 'success' }
  }

  constructor(
    private _tenancyOffers: TenancyOfferService,
    public _property: PropertyService,
    private _router: Router,
    private _math: MathService
  ) {
    this.tenancy_offers$ = this._tenancyOffers.getTenancyOffers()
      .pipe(
        map((offers: any) => this._math.sortArrayDescending(offers, 'date_modified')),
        map((offers: any) => {
          const offerStatus = ['incomplete', 'cancelled_by_tenant', 'rejected_by_landlord'];
          const activeOffers = offers.filter((tenancies: any) => !offerStatus.includes(tenancies.application.status) );
         return  activeOffers.slice(0, 20);
        }),
        map((tenancy_offers: any) => {
          console.log(tenancy_offers, 'hello active offers');
          return tenancy_offers.map(tenancy_offer => {
            return {
              ...tenancy_offer,
              step: this.getStep(tenancy_offer.phase, tenancy_offer.status)
            };
          });
        }),

      );

  }

  selectProperty(propId: number, bedroomId: string, offerId: string, step: number, offer: any): void {

    switch (step) {
      case 1:
        if (bedroomId) {
          this._router.navigate([ Landlord.base, Landlord.manage.base, Landlord.manage.applications.base, propId, bedroomId, Landlord.manage.applications.offers.base]);
        } else {
          this._router.navigate([Landlord.base, Landlord.manage.base, Landlord.manage.applications.base, propId, Landlord.manage.applications.offers.base, offerId]);
        }
        break;

      case 2:
        offer.references.type && offer.references.type === 'vouch' ? this._router.navigate([References.base, offerId]) : this._router.navigate(['/' + Landlord.base, Landlord.manage.base, Landlord.manage.applications.base, propId, Landlord.manage.applications.references.base, offerId]);
        break;
      case 3:

        if (offer.agreement.status !== 'incomplete') {
          this._router.navigate(['/' + Documents.base, Documents.tenancy_agreement.base, Documents.tenancy_agreement.sign, offer.agreement.tenancy_agreement_id]);
        } else {
          this._router.navigate(['/' + Documents.base, Documents.new_tenancy, offer.agreement.tenancy_agreement_id]);
        }
        break;
      case 4:
        this._router.navigate(['/' + Documents.base, Documents.tenancy_agreement.base, Documents.tenancy_agreement.sign, offer.agreement.tenancy_agreement_id]);
        break;
      default:
        break;
    }
  }


  getStep(phase: string, status?: string): any {
    let step: number;

    if (status === 'cancelled_by_tenant' || status === 'rejected_by_landlord') {
      return 0
    }
    switch (phase) {
      case 'application':
        step = 1;
        break;
      case 'references':
        step = 2;
        break;

      case 'tenancy_agreement':
        step = 3;
        break;

      case 'securing_payment':
        step = 4;
        break;

      case 'payment_complete':
        step = 5;
        break;

      default:
        step = 0;
        break;
    }
    return step;
  }
  ngOnInit() {

  }

}

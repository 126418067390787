import { EventEmitter, OnInit } from '@angular/core';
import { PropertyService, TenancyOfferService, TenancyService, UserService } from '@app/services';
import { FormBuilder, Validators } from '@angular/forms';
import { PAYMENT_STATUS } from '@app/shared/shared.constants';
import { finalize, map, switchMap, tap } from 'rxjs/operators';
import { MY_FORMATS } from '@app/shared/_services/dates.service';
import { PaymentsService } from '@app/services/payments.service';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
import { ExpensesService } from '@app/services/expenses.service';
import { of, zip } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { EEnvironmentFlags } from '@rentbunk/bunk-models';
import { environment } from '@env/environment';
const ɵ0 = MY_FORMATS;
export class FormCreatePaymentsComponent {
    constructor(_property, _payments, _tenancy, _expenses, _toastr, _formBuilder, _route, _tenancyOffer, _user) {
        this._property = _property;
        this._payments = _payments;
        this._tenancy = _tenancy;
        this._expenses = _expenses;
        this._toastr = _toastr;
        this._formBuilder = _formBuilder;
        this._route = _route;
        this._tenancyOffer = _tenancyOffer;
        this._user = _user;
        this.paymentTypes = [];
        this.isRentSchedule = false;
        this.rentScheduleStartDate = undefined;
        this.submitAttempted = false;
        this.tenants = [];
        this.paymentStatuses = PAYMENT_STATUS;
        this.hideSearch = false;
        this.paidStatuses = ['paid', 'partially_paid', 'received', 'partially_received'];
        this.resetForm = false;
        this.submittingData = false;
        this.submittingDataSuccess = false;
        this.client_data = environment.client_data;
        this.closeDialog = new EventEmitter();
        this.payments = new EventEmitter();
        this.tenancy_agreement_id = null;
        this.noDueDateLimits = environment.client_data.flags.includes(EEnvironmentFlags.PAYMENT_SCHEDULE_DUE_DATE_NO_LIMITS);
        this.formGroup = this.initFormGroup();
        this.property_details.valueChanges.subscribe((property) => {
            this.checkOverlappingCarParking();
        });
    }
    get status() {
        return this.formGroup.get('status');
    }
    get amount() {
        return this.formGroup.get('amount');
    }
    get timestamp_paid() {
        return this.formGroup.get('timestamp_paid');
    }
    get due_date() {
        return this.formGroup.get('due_date');
    }
    get payment_frequency() {
        return this.formGroup.get('payment_frequency');
    }
    get number_of_payments() {
        return this.formGroup.get('number_of_payments');
    }
    get scheduleTenants() {
        return this.formGroup.get('scheduleTenants');
    }
    get property_details() {
        return this.formGroup.get('property_details');
    }
    ngOnInit() {
        this.tenancy_agreement_id = this._route.snapshot.paramMap.get('agreementId');
        this.tenancyAgreement$ = this.tenancy_agreement_id ? this._tenancyOffer.getTenancyAgreementById(this.tenancy_agreement_id).valueChanges() : of();
        this.tenants$ = this.tenancyAgreement$.pipe(map((agreement) => {
            return agreement.tenant_uids && agreement.tenant_uids.length ? agreement.tenant_uids.map((tenant) => this._user.getUserById(tenant).valueChanges()) : of([]);
        }), switchMap((users) => (users && users.length) ? zip(...users) : of([])), tap(tenants => {
            tenants && (tenants.length === 1) ? this.formGroup.get('scheduleTenants').setValue([tenants[0].uid]) : null;
        }));
        if (this.isRentSchedule) {
            this.setScheduleTenantValidation();
            this.removePropertyValidation();
            this.removeStatusValidation();
        }
    }
    setTimestampPaidValidation(status) {
        if (this.paidStatuses.includes(status.value)) {
            this.timestamp_paid.setValidators([Validators.required]);
        }
        else {
            this.timestamp_paid.setValidators(null);
        }
        this.timestamp_paid.updateValueAndValidity();
    }
    setScheduleTenantValidation() {
        if (this.isRentSchedule && this.scheduleTenants.value === null) {
            this.scheduleTenants.setValidators([Validators.required]);
        }
        else {
            this.scheduleTenants.setValidators(null);
        }
        this.scheduleTenants.updateValueAndValidity();
    }
    setLastPaymentValidation(frequency) {
        console.log({ frequency });
        if (frequency.value !== 'one_time') {
            this.number_of_payments.setValidators([Validators.required]);
        }
        else {
            this.number_of_payments.setValidators(null);
        }
        this.number_of_payments.updateValueAndValidity();
    }
    removePropertyValidation() {
        this.property_details.setValidators(null);
        this.property_details.updateValueAndValidity();
    }
    removeStatusValidation() {
        this.status.setValidators(null);
        this.status.updateValueAndValidity();
    }
    initFormGroup() {
        return this._formBuilder.group({
            amount: [null, Validators.required],
            due_date: [null, Validators.required],
            status: [null, Validators.required],
            payment_type: [null, Validators.required],
            property_details: [null],
            timestamp_paid: null,
            payment_frequency: ['', Validators.required],
            number_of_payments: null,
            scheduleTenants: null
        });
    }
    generateSchedule() {
        // const startDate = this.due_date.value;
        if (this.amount.value && this.number_of_payments.value && this.due_date.value && this.payment_frequency.value) {
            const lastPayment = (this.payment_frequency.value === 'monthly')
                ? moment(this.due_date.value).add((this.number_of_payments.value - 1), 'months')
                : moment(this.due_date.value).add(((this.number_of_payments.value - 1) * 3), 'months');
            console.log({ lastPayment });
            this.schedule = `Payments of ${this.client_data.currency_symbol + this.amount.value} will be created ${this.payment_frequency.value}.
    The first payment will be taken on ${moment(this.due_date.value).format('Do MMM YYYY')}
    and the last payment will be taken on ${lastPayment.format('Do MMM YYYY')}.`;
        }
        this.checkOverlappingCarParking();
    }
    checkOverlappingCarParking() {
        if (this.formGroup.controls.payment_type.value === 'car_parking') {
            const PropertyDetails = this.property_details.value && this.property_details.value.property_details;
            if (PropertyDetails && (PropertyDetails.bedroom_id || PropertyDetails.property_id) && this.due_date.value) {
                const property_id = PropertyDetails.property_id;
                const bedroom_id = PropertyDetails.bedroom_id;
                const payment_type = this.formGroup.controls.payment_type.value;
                const dueMonth = this.due_date.value.month();
                this.payments$ = this._payments.userCreateMultiplePayment(property_id, bedroom_id, payment_type, dueMonth).valueChanges();
            }
        }
    }
    submitForm() {
        this.submitAttempted = true;
        if (this.formGroup.valid) {
            this.submittingData = true;
            if (this.isRentSchedule) {
                const scheduleTenants = this.formGroup.get('scheduleTenants').value;
                if (scheduleTenants && scheduleTenants.length) {
                    let paymentData = [];
                    const date = moment(this.due_date.value).toDate();
                    let payments = [];
                    if (this.payment_frequency.value === 'one_time') {
                        payments.push({
                            amount: this.formGroup.get('amount').value,
                            due_date: date,
                            month: (+moment(date).format('MM')) - 1,
                            type: this.formGroup.get('payment_type').value,
                            year: +moment(date).format('YYYY')
                        });
                    }
                    else if (this.payment_frequency.value === 'monthly' || this.payment_frequency.value === 'quarterly') {
                        for (let i = 0; i < this.number_of_payments.value; i++) {
                            let scheduledDate;
                            if (this.payment_frequency.value === 'monthly') {
                                scheduledDate = moment(this.due_date.value).add((i), 'months');
                            }
                            else {
                                let j = i;
                                scheduledDate = moment(this.due_date.value).add((j * 3), 'months');
                            }
                            payments.push({
                                amount: this.amount.value,
                                is_additional: true,
                                due_date: scheduledDate.toDate(),
                                month: (+moment(scheduledDate).format('MM')) - 1,
                                type: this.formGroup.get('payment_type').value,
                                year: +moment(scheduledDate).format('YYYY')
                            });
                        }
                    }
                    scheduleTenants.map((data) => {
                        paymentData.push({
                            uid: data,
                            payments
                        });
                    });
                    this.payments.emit(paymentData);
                    this.formGroup.reset();
                    this.formGroup.updateValueAndValidity();
                    this.resetForm = true;
                    this.closeDialog.emit(true);
                    this.submittingDataSuccess = true;
                }
            }
            else {
                const property_details = this.formGroup.get('property_details').value;
                const item = Object.assign({}, this.formGroup.getRawValue(), { property_details: {
                        // being passed in from another component
                        property_id: property_details.property_details.property_id,
                        bedroom_id: property_details.property_details.bedroom_id,
                        tenancy_id: property_details.tenancy_id,
                        sender_uid: property_details.sender_uid,
                        user_reference_code: property_details.user_reference_code
                    } });
                return this._payments.userCreatePayment(item).pipe(finalize(() => this.submittingData = false)).subscribe(() => {
                    this._toastr.success(`Payment creation initialised`);
                    this.formGroup.reset();
                    this.formGroup.updateValueAndValidity();
                    this.resetForm = true;
                    this.closeDialog.emit(true);
                    this.submittingDataSuccess = true;
                }, (err) => {
                    console.log({ err });
                    if (err.status === 403) {
                        this._toastr.error(err.error.message);
                    }
                    else {
                        this._toastr.error(`Payment creation failed`);
                    }
                });
            }
        }
        else {
            this._toastr.error('Please fill in all required fields');
        }
    }
    get datePickerMin() {
        return this.noDueDateLimits ? null : this.rentScheduleStartDate;
    }
}
export { ɵ0 };

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { IProperties, PropertyModel } from '@app/core-landlord/property/_models/property.model';
import * as _ from 'lodash';
export interface IRooms {
  name: string;
  assigned_property_id: string;
  reserved: boolean
  tenants?: string;
}
export interface IDisplayList {
  blocks: string[];
  floors: string[];
  rooms: IRooms[];
}


@Component({
  selector: 'form-assign-room',
  templateUrl: './form-assign-room.component.html',
  styleUrls: ['./form-assign-room.component.scss']
})
export class FormAssignRoomComponent implements OnInit {
  @Input() offerYear: number;
  @Input() get properties(){
    return this._properties
  };
  set properties(data:IProperties[]){
    this._properties = data;
    this.properties.map((property: IProperties) => {
      if (property.block) { this.display.blocks = _.union([property.block], this.display.blocks).sort(); }
      if (property.floor) { this.display.floors = _.union([property.floor], this.display.floors).sort(); }
      const propertyCustomFieldTenants = property.custom_fields && property.custom_fields.tenants;
      const propertyTenants = propertyCustomFieldTenants || null;
      const reservedYears: number[] = property.custom_fields ? property.custom_fields.reserved_years || [] : [];
      const isReserved = (property.is_under_offer || property.has_current_tenancy) && reservedYears.includes(this.offerYear);
      const roomsPropertyList = {
        name: property.room,
        reserved: isReserved,
        assigned_property_id: property.property_id,
        tenants: propertyTenants
      }
      this.display.rooms = (_.union([roomsPropertyList], this.display.rooms))
        .filter(element => !!element.name)
        .sort(this.objectSort);
    });

  }
  @Input() assignedProperty: PropertyModel;
  @Output() roomData = new EventEmitter();
  public assigned = null;
  public display: IDisplayList = {
    blocks: null,
    floors: null,
    rooms: null,
  };
  _properties:IProperties[];
  public formGroup: FormGroup;
  constructor(
    private _formBuilder: FormBuilder,
  ) {
    this.formGroup = this.initFormGroup();
  }
  initFormGroup() {
    return this._formBuilder.group({
      block: '',
      room: '',
      floor: '',
    });
  }

  ngOnInit() {
  }

  updateSelectFormControlRoom(roomId: string) {
    this.roomData.emit(roomId);
    const selectedRoom = this.properties.find((property: IProperties) => property.property_id === roomId);
    this.formGroup.get('floor').setValue(selectedRoom.floor);
    this.formGroup.get('block').setValue(selectedRoom.block);
    this.setRoomsList();
  }

  updateSelectFormControlFloor(floorId: string) {
    const selectedFloor = this.properties.find((property: IProperties) => property.floor === floorId);
    this.formGroup.get('block').setValue(selectedFloor.block);
    this.setRoomsList();
  }

  setRoomsList() {
    if (this.formGroup.get('block').value) {
      const currentBlock = this.formGroup.get('block').value;
      this.display.rooms = this.properties.filter((property: IProperties) => property.floor === this.formGroup.get('floor').value && property.block === currentBlock).map(p => this.mapRoom(p, this.offerYear)).sort(this.objectSort);
    } else {
      this.display.rooms = this.properties.filter((property: IProperties) => property.floor === this.formGroup.get('floor').value).map(p => this.mapRoom(p, this.offerYear)).sort(this.objectSort);
    }
  }

  updateSelectFormControlBlock(blockId: string) {
    const selectedBlockProperties = this.properties.filter((property: IProperties) => property.block === blockId)
    this.display.rooms = selectedBlockProperties.map(p => this.mapRoom(p, this.offerYear)).sort(this.objectSort);
    this.display.floors = selectedBlockProperties.map(selectedRoom => selectedRoom.floor).sort();
  }

  objectSort(a: { name: string }, b: { name: string }) {
    return a.name.localeCompare(b.name);
  }

  mapRoom(selectedRoom: IProperties, offerYear: number) {
    const reservedYears: number[] = selectedRoom.custom_fields ? selectedRoom.custom_fields.reserved_years || [] : [];
    const isReserved = (selectedRoom.is_under_offer || selectedRoom.has_current_tenancy) && reservedYears.includes(offerYear);
    const customFieldsTenants = selectedRoom.custom_fields && selectedRoom.custom_fields.tenants;
    return ({
      name: selectedRoom.room, reserved: isReserved, assigned_property_id: selectedRoom.property_id,
      tenants: customFieldsTenants ? customFieldsTenants : null
    })
  }

}

import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { StepperModel } from './stepper.model';

@Component({
    selector: 'stepper-new',
    template: `
    <nav>
    <ol class="cd-multi-steps text-bottom count" [ngClass]="{'cursor' : canEdit}">
      <li *ngFor="let step of stepper; let first = first"
      [ngClass]="{'visited': step.index <= currentIndex || first }"
      [routerLink]="canEdit ? step.routerLink: null" [queryParams]="step.queryParams ? step.queryParams : ''">
      <a [ngClass]="{'visited': step.index <= currentIndex || first }" >{{step.label}}</a>
      </li>
    </ol>
    </nav>`,
    styleUrls: ['./stepper-new.component.scss']
})

export class StepperNewComponent implements OnInit, OnDestroy {
  @Input() stepper?: StepperModel[];
  @Input() canEdit?: boolean;
  public currentIndex: number;
  public disabled = false;

  // helpers
  private destroy$: Subject<boolean> = new Subject<boolean>();


  constructor(private router: Router, private route: ActivatedRoute ) {}

  update(url: any ) {
    url = this.router.url;
    for (const step of this.stepper) {
      if (url.includes(step.routerName)) {
        this.currentIndex = step.index;
      } else if (url.includes('checkout') || url.includes('preview')) {
        this.currentIndex = 4;
      }
    }
  }

  ngOnInit() {
    this.router.events
    .pipe(takeUntil(this.destroy$))
    .subscribe((val: NavigationEnd) => this.update(val.url));
    this.update(this.router.url);
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}

import { checkout_guard, confirm_guard } from './constants';

import { EEnvironmentFlags } from '@rentbunk/bunk-models';
export const environment = {
  production: true,
  is_bunk_environment: false,
  url: 'https://client-campus-key.firebaseapp.com',
  version: require('../../package.json').version,
  firebaseConfig: {
    apiUrl: 'https://europe-west2-client-campus-key.cloudfunctions.net',
    databaseURL: 'https://client-campus-key.firebaseio.com',
    apiKey: 'AIzaSyAY-AdMLc8B_UfEBCC3trkWPK5ret3KZ6M',
    authDomain: 'client-campus-key.firebaseapp.com',
    projectId: 'client-campus-key',
    storageBucket: 'client-campus-key.appspot.com',
    messagingSenderId: '506278727965',
    firestoreUrl: 'https://console.firebase.google.com/u/0/project/client-campus-key/firestore/data/~2F'
  },
  gmapsConfig: {
    apiUrl: 'https://maps.googleapis.com/maps/api',
    apiKey: 'AIzaSyDztM9368f63oKY6sJupTznSE-lzWIB6fA'
  },
  stripeConfig: {
    publicKey: 'pk_test_AloMc0VKYl79u7S03ms70Pil'
  },
  algolia: {
    env: 'client-campus-key',
    appId: '0NGCVTIQSM',
    apiKey: 'f9ece56187569c40e8179ab6a698d0e5'
  },
  facebook: {
    app_id: 760538417439960
  },
  idealPostCodes: {
    apiKey: 'ak_jjtq0cokIipQCFaqS5PCoE9kv56qU'
  },
  hey_hub: {
    url: 'https://heyhub.com/',
    api_key: 'd0e14128fb5a5e54cb57012786486f',
    auth_key: 'ce6fddae2ab0d4c2c62909bfa71477a514cfaabd0b07106f64b6186b00f8'
  },
  client_data: {
    date_created: '25/10/2021',
    date_modified: '25/10/2021',
    client_id: '',
    company_legal_name: 'CampusKey',
    company_name: 'CampusKey',
    currency_symbol: 'R',
    currency: 'zar',
    listing_code: '4', // @TODO GEE - I've already updated this
    privacy_policy_url: 'https://www.campuskey.co.za/privacy-policy',
    terms_url: 'https://www.campuskey.co.za/',
    term_version: 1,
    privacy_policy_version: 1,
    support_number: '+27 86 178 83368',
    contractor_phone_number:'',
    twilio_number: '+441617111222',
    support_email: 'info+campuskey@gobunk.co.uk',
    contact_url: 'https://www.campuskey.co.za/contact',
    demo_url: '',
    email_footer: 'This message was produced and distributed by CampusKey.',
    signup_text: 'YOU\'RE JUST A FEW CLICKS AWAY FROM FINDING YOUR PEOPLE.',
    website_url: 'https://www.campuskey.co.za/',
    business_hours: '9am - 6pm',
    app_url: '',
    pre_verified: true,
    has_payments_direct: true,
    unit_name: 'Room',
    time_zone:'Africa/Johannesburg',
    translation: {
      rent_label_abbreviation: 'pm',
      offer_message_label: 'Do you have any preferences or additional requests?',
      offer_message_placeholder: 'Add any preferences or requests',
      tenancy_agreement: 'Service And Residents Agreement',
      application_overview_labels : {
        pending: 'Residents'
        },
        country_code : +27,
      rent_amount : 'Service Fee Amount',
      move_in_date: 'Check-in date',
      holding_deposit: 'Deposit',
      service_fee: 'Admin fee',
      rent_property: "Apply Now",
      letting_information: "Service Information",
      amenities: 'Inclusive Services',
      tenancy_period: 'Period of your stay',
      landlord: 'team'
    },
    logos: {
      logo_white_out_transparent: '',
      logo_full_colour: '',
      logo_full_colour_transparent: 'https://firebasestorage.googleapis.com/v0/b/client-campus-key.appspot.com/o/branding%2Femail%20logo%402x.png?alt=media&token=b44c2497-89fa-4de0-809d-022698d3036b',
      logo_black_white: '',
    },
    colours: {
      primary_colour: '#F7931D',
      // secondary_colour: '00CF6B',
      // accent_colour: '#FE5196',
    },
    route_guards: [checkout_guard, confirm_guard]
  },
  expireDay: 5,
  minimumPasswordLength: 8,
  flags: [
    EEnvironmentFlags.HIDE_ALL_PORTFOLIOS_FILTER,
    EEnvironmentFlags.PAYMENT_SCHEDULE_DUE_DATE_NO_LIMITS
  ]
};

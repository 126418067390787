/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "../skeleton-loading.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./filter-skeleton.component";
var styles_FilterSkeletonComponent = [i0.styles];
var RenderType_FilterSkeletonComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_FilterSkeletonComponent, data: {} });
export { RenderType_FilterSkeletonComponent as RenderType_FilterSkeletonComponent };
function View_FilterSkeletonComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, "div", [["class", "search-bar--payments loading-animation"]], null, null, null, null, null))], null, null); }
export function View_FilterSkeletonComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "filter__container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 9, "div", [["class", "filter__container--inner"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 8, "div", [["class", "search-filters "]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 2, "button", [["class", " margin-top--xs search-filters__btn-control btn__seethrough btn--round btn--sm mr_5 loading-animation"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "span", [["class", "button_text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["All properties >"])), (_l()(), i1.ɵeld(6, 0, null, null, 2, "button", [["class", " margin-top--xs search-filters__btn-control btn__seethrough btn--round btn--sm mr_5 loading-animation"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "span", [["class", "button_text"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["All properties >"])), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_FilterSkeletonComponent_1)), i1.ɵdid(10, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.showSearch; _ck(_v, 10, 0, currVal_0); }, null); }
export function View_FilterSkeletonComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "filter-skeleton", [], null, null, null, View_FilterSkeletonComponent_0, RenderType_FilterSkeletonComponent)), i1.ɵdid(1, 114688, null, 0, i3.FilterSkeletonComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var FilterSkeletonComponentNgFactory = i1.ɵccf("filter-skeleton", i3.FilterSkeletonComponent, View_FilterSkeletonComponent_Host_0, { showSearch: "showSearch" }, {}, []);
export { FilterSkeletonComponentNgFactory as FilterSkeletonComponentNgFactory };

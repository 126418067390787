export  const addPropertySteps = {
  initial: {
    title: 'Add a property',
    options: [
      {
        id: 'advertise',
        title: 'Advertise',
        description: 'You want to publish your property on portals like Rightmove',
      },
      {
        id: 'create',
        title: 'Create',
        description: 'You want to create a property without advertising it'
      }
    ],
    nextStep: {
      advertise: 'advertise',
      create: 'create',
    }
  },
  advertise: {
    title: 'Advertise property',
    options: [
      {
        id: 'advertise-existing',
        title: 'Advertise Existing Property',
        description: '',
      },
      {
        id: 'create-new',
        title: 'Create New Property',
        description: ''
      }
    ],
    nextStep: {
      'create-new': 'create_to_advertise'
    }
  },
  create_to_advertise: {
    title: 'Create property to advertise',
    options: [
      {
        id: 'whole',
        title: 'Whole Property',
        description: 'If you want to add a whole house and one tenancy agreement',
      },
      {
        id: 'hmo',
        title: 'By Room',
        description: 'If you are adding a HMO with separate tenancy agreements'
      }
    ],
    nextStep: {}
  },
  create: {
    title: 'Create a property',
    options: [
      {
        id: 'whole',
        title: 'Whole Property',
        description: 'If you want to add a whole house and one tenancy agreement',
      },
      {
        id: 'hmo',
        title: 'By Room',
        description: 'If you are adding a HMO with separate tenancy agreements'
      }
    ],
    nextStep: {}
  }
}

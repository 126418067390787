import {Component, OnInit} from '@angular/core';
import {BehaviorSubject, combineLatest, from, Observable} from 'rxjs';
import {FormControl} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {UserService} from '@app/services';
import {AuthService} from '@app/core/services';
import {finalize, flatMap, map, switchMap, take, tap} from 'rxjs/operators';
import {environment} from '@env/environment';
import algoliasearch from 'algoliasearch/lite';
import * as moment from 'moment';
import {TitleCasePipe} from '@angular/common';
import {AdminService} from '@app/services/admin.service';
import {MathService} from '@app/services/math.service';
import {ToastrService} from 'ngx-toastr';
import {AlgoliaService} from '@app/services/algolia.service';
import {of} from 'rxjs/internal/observable/of';
import {IUser, IUserReadOnly} from '@app/core/models';

const COLUMNS_TO_DISPLAY = [
  {
    title: 'Date Paid',
    value: 'timestamp_paid'
  },
  {
    title: 'In / Out',
    value: 'transaction_direction',
  },
  {
    title: 'Amount',
    value: 'transaction_amount'
  },
  {
    title: 'Unassigned',
    value: 'transaction_amount_unassigned_to_payment'
  },
  {
    title: 'Short Ref',
    value: 'short_description'
  },
  {
    title: 'Long Ref',
    value: 'long_description'
  },
  {
    title: 'Payout Ref',
    value: 'user_reference_code'
  },
  {
    title: 'Payout Amount',
    value: 'total_to_payout'
  },
  {
    title: 'Account',
    value: 'payout_accounts'
  },
  {
    title: 'Source',
    value: 'source'
  },
  {
    title: ' ',
    value: 'transaction_id'
  },
];

const TYPE_FILTER = [
  {
    viewValue: 'All directions',
    value: 'all'
  },
  {
    viewValue: 'Inward transactions',
    value: 'in'
  },
  {
    viewValue: 'Outward transactions',
    value: 'out'
  }
];

const STATUS_FILTER = [
  {
    viewValue: 'All transactions',
    value: 'all'
  },
  {
    viewValue: 'Client transaction',
    value: 'client_transaction'
  },
  {
    viewValue: 'Forfeited holding fees',
    value: 'forfeited_holding_fees'
  },
  {
    viewValue: 'Expenses',
    value: 'expenses'
  },
  {
    viewValue: 'Non client transaction',
    value: 'non_client_transaction'
  },
  {
    viewValue: 'Off platform',
    value: 'off_platform'
  },
  {
    viewValue: 'Refund transaction',
    value: 'refund_transaction'
  },
  {
    viewValue: 'Partially associated payments',
    value: 'partial_association'
  },
  {
    viewValue: 'No associated payments',
    value: 'has_payment_association'
  },
];

export const source = {
  client_transaction : 'Client transaction',
  non_client_transaction : 'Non client transaction',
  forfeited_holding_fees : 'Forfeited holding fees',
  off_platform: 'Off platform',
  refund_transaction: 'Refund Transaction',
  expenses: 'Expenses'
};

@Component({
  selector: 'transactions-component',
  template: `


            <shared-filters
              [title]="'Transactions'"
              [typeFilterValues]="typeFilterValues"
              [statusFilterValues]="statusFilterValues"
              (filterEmitter)="updateBehaviourSubjects($event)"
            >

              <button *ngIf="(isAdmin$ | async)" (click)="showFilters = true" class="margin-top--xs search-filters__btn-control btn__seethrough btn--round btn--sm mr_5">
                <span>{{OpenBankingFilterTitle}}</span>
                <mat-icon svgIcon="icon-expand-more"></mat-icon>
              </button>
              <ng-container *ngIf="moneyHubUserFilter$ | async as moneyhubUsers">
              <div class=" search-filters__controls-menu" *ngIf="showFilters">
                  <div class="filter__type-container filter__type-container--content cursor-pointer" *ngFor="let moneyhubUser of moneyhubUsers" (click)="updateOpenBankingFilter(moneyhubUser)">
                  <p>{{moneyhubUser.viewValue}}</p>
                  <span class="selected" *ngIf="selectedMoneyHubUser.value === moneyhubUser.value"></span>
                </div>
                <button class="btn__info" (click)="showFilters = false">Done</button>
              </div>
            </ng-container>

            </shared-filters>
            <ng-container  *ngIf="{hits: combined$ | async, balance: balance$ | async} as data; else loadSkeleton" >
              <ng-container *ngIf="data.hits as hits">
                <ng-container *ngIf="data.balance as balance">
            <div class="margin-top--lg">
              <div class="margin-bottom--lg flex flex-column" *ngIf="isBunk && balance.balance">
                <span><strong>Client Account Balance:</strong> £{{balance.balance}}</span>
                <span> <strong>Balance Last updated: </strong> {{balance.date_modified}}</span>
                <div class="margin-top--sm ">
                  <button class="btn__seethrough btn--sm" (click)="updateFormulaBalancing()">
                    <span *ngIf="!showSpinner">Update balance</span>
                    <processing-spinner *ngIf="showSpinner" color="#FFFFFF" [size]="'24px'"></processing-spinner></button>
                </div>
              </div>

              <transactions-table
                [data]="hits.combined"
                (pagination)="updatePagination($event)"
                [displayedColumns]="displayColumns"
                [columnsToDisplay]="columnsToDisplay"
                [itemsPerPage]="pagination.value.hitsPerPage"
                [totalHits]="hits.totalHits"
                [pageNumber]="pagination.value.page"
              ></transactions-table>
            </div>

            <ng-content></ng-content>
          </ng-container>
        </ng-container>

    </ng-container>



    <ng-template #loadSkeleton>
      <div class="hide-sm">
        <skeleton-loading></skeleton-loading>
      </div>
    </ng-template>


`,
  styleUrls: ['./transactions.component.scss']
})

export class TransactionsComponent implements OnInit {
  public algoliaIndex;
  public combined$: Observable<any>;
  public user$: Observable<any>;
  public team$: Observable<any>;
  public isAdmin$: Observable<any>;
  public balance$: Observable<any>;
  public pagination: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public filters$: BehaviorSubject<any> = new BehaviorSubject<any>({status: 'all', search: '', type: 'all'});
  public indexName;
  public displayColumns: string[] = ['timestamp_paid', 'transaction_direction', 'transaction_amount', 'transaction_amount_unassigned_to_payment', 'short_description', 'long_description', 'user_reference_code', 'total_to_payout', 'payout_accounts', 'source', 'transaction_id'];
  public columnsToDisplay = COLUMNS_TO_DISPLAY;
  showFilters = false;
  public typeFilterValues = TYPE_FILTER;
  public statusFilterValues = STATUS_FILTER;
  public showSpinner = false;
  public isBunk = environment.is_bunk_environment;
  moneyHubUserFilter$ = of(null)
  selectedMoneyHubUser = new FormControl(null);
  selectedMoneyHubUser$ = new BehaviorSubject(null);
  currentFilters = null;
  OpenBankingFilterTitle = 'Open banking filters'
  constructor(
    private _route: ActivatedRoute,
    private _user: UserService,
    private _router: Router,
    private _auth: AuthService,
    private _titlecasePipe: TitleCasePipe,
    private _admin: AdminService,
    private _math: MathService,
    private _toastr: ToastrService,
    private _algolia: AlgoliaService,
  ) {
  }

  ngOnInit() {
    this.pagination.next({page: 0, hitsPerPage: 10});
    this.initTableData();
    this._route.queryParamMap.pipe(take(1)).subscribe(params => {
      const openBank = params.get('openBank');
      if (openBank) {
        this.selectedMoneyHubUser.patchValue(openBank);
        this.selectedMoneyHubUser$.next(openBank)
      }
    })
    this.moneyHubUserFilter$ = this._user.getAllMoneyHubUsers().pipe(map((musers: IUser[]) => {
      return musers.map((muser: IUser) => {
        const viewValue = muser.profile_data ? muser.profile_data.full_name : muser.moneyhub_user_id;
        return { value: muser.moneyhub_user_id, viewValue: viewValue }
      })
    }), switchMap((musers: {value:string,viewValue:string}[]) => {
      return this.team$.pipe(map(team => {
        const team_moneyhub_user_id = (team && team.moneyhub_user_id) ? team.moneyhub_user_id : '';
        return team_moneyhub_user_id ? [{ value: team_moneyhub_user_id, viewValue: 'Team' }, ...musers] : musers;
      }))
    }), tap((musers: {value:string,viewValue:string}[]) => {
      if (musers.length && !this.selectedMoneyHubUser.value) {
        this.selectedMoneyHubUser.patchValue(musers[0].value);
        this.selectedMoneyHubUser$.next(musers[0].value);
      }
    }))
  }

  initTableData() {
    const { appId, apiKey, env } = environment.algolia;
    const searchClient = algoliasearch(appId, apiKey);
    this.user$ = this._user.getUserReadOnlyById(this._auth.currentUserId).valueChanges()
    this.indexName = `${env}-transactions`;
    this.algoliaIndex = searchClient.initIndex(this.indexName);
    this.balance$ = this._admin.getAccountBalanceDocument().valueChanges().pipe(
      map((balance: any) => {
        return  (balance) ? {
          balance: this._math.roundToTwoDP(balance.balance + balance.balance_difference).toLocaleString(),
          date_modified: moment(balance.date_modified.toDate()).format('LLL')
        } : {};
      }),
      tap((res: any) => console.log({res}))
    );
      this.team$ = this.user$.pipe(
        flatMap((user: any) => (user.team_ids && user.team_ids.length) ? this._user.getTeamById(user.team_ids[0]).valueChanges() : of({}))
      );
      this.isAdmin$ = this._auth.getCurrentUserCustomClaims();
    this.combined$ = combineLatest([this.filters$.asObservable(), this.pagination.asObservable(), this.isAdmin$, this.team$, this.user$, this.selectedMoneyHubUser$]).pipe(
      flatMap(([filters, pagination, isAdmin, team, user, selectedOpenBanking]: any) => {
        return this.getAlgoliaData(filters, pagination, isAdmin, team, user, selectedOpenBanking);
        // return {isAdmin}
      })
      );
  }

  updatePagination(event: any) {
    this.pagination.next(event);
  }

  updateBehaviourSubjects(event: any) {
    this.currentFilters = event;
    return this.filters$.next(event);
  }



  getAlgoliaData(filter: any, pagination: any, isAdmin: boolean, team: any, user: IUserReadOnly, selectedOpenBanking:string): Observable<any> {
    const moneyhub_user_id = (team && team.moneyhub_user_id) ? team.moneyhub_user_id : user.moneyhub_user_id;

    let filters = `transactions`;
    if (selectedOpenBanking) {
      const landlordFilter = ` AND moneyhub_user_id:${selectedOpenBanking}`;
      filters += landlordFilter;
    }
    if (filter.type !== 'all') {
      filters += ` AND transaction_direction:${filter.type}`;
    }

    if (filter.filter !== 'all') {
      if(filter.filter === 'partial_association'){
        filters += ` AND has_payment_association:true AND transaction_amount_unassigned_to_payment > 0 AND source:client_transaction`;
      }
      else if(filter.filter === 'has_payment_association'){
        filters += ` AND has_payment_association:false AND source:client_transaction`;
      }
      else{
        filters += ` AND source:${filter.filter}`;
      }
    }

    return this._algolia.getFilteredAlgoliaData(this.algoliaIndex, filters, filter.search, pagination).pipe(
      map((res: any) => (res === 'No index') ? {combined: []} : {
        ...res,
        combined: this.createDataStructure(res.combined),
      }));
  }

  createDataStructure(data: any) {
    return data.map((tran: any) => {
      return {
        ...tran,
        transaction_direction: this._titlecasePipe.transform(tran.transaction_direction),
        has_paid_out: tran.has_paid_out,
        timestamp_paid: tran.timestamp_paid ? moment(tran.timestamp_paid).format('DD MMM YY HH:mm a') : null,
        payout_account_length: tran.payout_accounts ? tran.payout_accounts.length : null,
        payout_accounts: tran.payout_accounts && tran.payout_accounts.length ? tran.payout_accounts.map((account: any) => this.formatPayout(account)) : null,
        transaction_amount_unassigned_to_payment: tran.transaction_amount_unassigned_to_payment ? tran.transaction_amount_unassigned_to_payment : null,
        payout_is_less: tran.total_to_payout < tran.transaction_amount,
        status: tran.status ?  this._titlecasePipe.transform(tran.status) : null,
        long_description:  tran.long_description ?  tran.long_description.toUpperCase() : null,
        short_description:   tran.long_description ? tran.short_description.toUpperCase() : null,
        show_account: false,
        source: tran.source ? source[tran.source] : null,
        amount_to_pay: tran.amount_to_pay ? tran.amount_to_pay.toLocaleString() : null,
        transaction_amount:  tran.transaction_amount ? tran.transaction_amount.toLocaleString() : null
      };

    });
  }

  formatPayout(payout: any) {
    const sortCode = payout.sort_code ? payout.sort_code.match(/.{1,2}/g) : null;
    return {
      ...payout,
      sort_code: (sortCode) ? sortCode.join('-') : null
    };
  }

  updateFormulaBalancing() {
    this.showSpinner = true;
    this._admin.runBalancingFormulas().pipe(
      finalize(() => this.showSpinner = false)
    ).subscribe(() => this._toastr.success('Balance Updated.'),
      () => this._toastr.error('Failed to update balance'));
  }

  updateOpenBankingFilter(user: { value: string, viewValue: string }) {
    const event = { ...this.currentFilters, openBank: user.value }
    this.selectedMoneyHubUser.patchValue(user.value);
    this.selectedMoneyHubUser$.next(user.value);
    this.OpenBankingFilterTitle = user.viewValue;
    this._router.navigate(['.'], {
      queryParams: event,
      relativeTo: this._route,
      queryParamsHandling: 'merge',
    })

  }

}

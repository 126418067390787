import {Component, Input, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {UserService} from '@app/services';

@Component({
  selector: 'tenancy-members-card',
  template: `<div class="user_info" *ngIf="user$ | async as user">
      <div class="rc">
        <div class="user_image_container">
          <img  class="user_image profile__background" [src]="user?.profile_data.profile_image_url" alt="">
        </div>
        <div class="name">
          {{user?.profile_data.full_name}}
        </div>
      </div>
  </div>`,
  styleUrls: ['./tenancy-members-card.component.scss']
})
export class TenancyMembersCardComponent implements OnInit {
  @Input() userId: string;
  public user$: Observable<any>;
  constructor(private _user: UserService) {
  }

  ngOnInit() {
    if (this.userId) {
      this.user$ = this._user.getUserById(this.userId).valueChanges();
    }
  }

}

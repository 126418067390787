<form [formGroup]="formGroup" class="bunk-input">
  <mat-form-field appearance="outline" class="bunk-input__area-code">
    <mat-label *ngIf="showLabel">Area</mat-label>
    <mat-select formControlName="area_code">
      <mat-option *ngFor="let code of countryCodes" [value]="code">+{{code}}</mat-option>
    </mat-select>
  </mat-form-field>
  
  <mat-form-field appearance="outline" class="bunk-input__phone">
    <mat-label *ngIf="showLabel">{{ labelValue }}</mat-label>
    <input matInput formControlName="phone_number" type="tel">
    <mat-error>Please enter a phone number</mat-error>
  </mat-form-field>
</form>
import { Injectable } from '@angular/core';
import { AuthService } from '@app/core/services/auth.service';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { FirebaseApp } from '@angular/fire';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';

import { from as fromPromise, Observable, from, combineLatest } from 'rxjs';
import { BunkPassportModel, BunkPassportModelPermissioned } from '@app/_models/bunk_passport.model';
import { UserService } from './user.service';
import { flatMap } from 'rxjs/operators';
import { BunkPassportReferences } from '@app/_models/bunk_passport.model';


@Injectable({
  providedIn: 'root'
})
export class BunkPassportService {

  // Bunk Passport Collections
  public bunkPassportCollection: AngularFirestoreCollection<BunkPassportModel>;
  public bunkPassportPermissionedCollection: AngularFirestoreCollection<BunkPassportModelPermissioned>;
  public bunkPassportReferenceCollection: AngularFirestoreCollection;

  public tenancyOfferCollection: AngularFirestoreCollection<any>;
  public bunkPassportLaunchDate = new Date(2019, 10, 13);

  constructor(
    private readonly afs: AngularFirestore,
    private _auth: AuthService,
    private _http: HttpClient,
    private _user: UserService,
    private _fb: FirebaseApp,

  ) {
    // Declare Bunk Passport Collections
    this.bunkPassportCollection = afs.collection<any>('bunk_passports');
    this.bunkPassportPermissionedCollection = afs.collection<any>('bunk_passports_permissioned');
    this.bunkPassportReferenceCollection = afs.collection<any>('bunk_passports_references');
    this.tenancyOfferCollection = afs.collection<any>('tenancy_offers');

  }

  getDocReferenceById = (id: string): AngularFirestoreDocument<any> => this.bunkPassportReferenceCollection.doc(id);

  getDocReferencesByIds = (ids: string[]): AngularFirestoreDocument<any>[] => ids.map(id => this.getDocReferenceById(id));

  getReferencesByIds = (ids: string[]): Observable<any[] | undefined> => combineLatest(this.getDocReferencesByIds(ids).map(ref => ref.valueChanges()));

  getBunkPassportIdentityStep(user: any) {
    if (user.verification_steps) {
      for (const step of user.verification_steps) {
        if (step.step === 6) {
          return step;
        }
      }
    }

  }

  getBunkReferencesById(referenceType: string): AngularFirestoreCollection<any> {
    return this.afs.collection('bunk_passports_references', ref =>
      ref.where('uid', '==', this._auth.currentUserId)
        .where('type', '==', referenceType)
        .orderBy('date_modified', 'desc')
    );
  }
  getBunkReferencesByUidAndType(uid: string, referenceType: string) {
    return this.afs.collection('bunk_passports_references', ref =>
      ref.where('uid', '==', uid)
        .where('type', '==', referenceType)
        .orderBy('date_modified', 'desc')
    ).valueChanges() as Observable<BunkPassportReferences[]>;
  }

  getBunkReferenceById(id: string) {
    return this.bunkPassportReferenceCollection.doc(id);
  }

  getUsersBunkPassportById(): AngularFirestoreDocument<any> {
    return this.bunkPassportCollection.doc(this._auth.currentUserId);
  }

  getTenantsBunkPassport(id: string) {
    return this.bunkPassportPermissionedCollection.doc(id);
  }

  private updatePassportPermission(passport_private: BunkPassportModel, passport_permissioned: BunkPassportModelPermissioned, merge: boolean = true) {
    const id = this._auth.currentUserId;

    const passportPrivateRef = this.bunkPassportCollection.doc(id).ref;
    const passportPermissionedRef = this.bunkPassportPermissionedCollection.doc(id).ref;

    const batch = this.afs.firestore.batch();

    (merge) ? batch.set(passportPrivateRef, passport_private, { merge: true }) : batch.update(passportPrivateRef, passport_private);
    (merge) ? batch.set(passportPermissionedRef, passport_permissioned, { merge: true }) : batch.update(passportPermissionedRef, passport_permissioned);

    return fromPromise(batch.commit());
  }

  updateProfileImage(profile_image_url: string): Observable<void> {
    const item: any = {
      date_modified: new Date(),
      personal_details: {
        profile_image_url: profile_image_url
      }
    };
    return this.updatePassportPermission(item, item).pipe(
      flatMap(() => this._user.updateProfileImage(profile_image_url))
    );
  }

  // cloud function
  updateBunkPassport(step: string, form?: any, id?: any) {
    return this._http.post(environment.firebaseConfig.apiUrl + '/bunkpassport-updateBunkPassport', { form, step: step, reference_id: id });
  }



  createBunkPassportReferenceUni(tenancyOfferId: string, form: any, id?: string) {
    const referenceId = (id) ? id : this.afs.createId();
    const referencesDocRef = this.bunkPassportReferenceCollection.doc(referenceId).ref;
    const tenancyOfferDocRef = this.tenancyOfferCollection.doc(tenancyOfferId).ref;

    return (id) ?
      this.updateIncompleteUniRef(referenceId, tenancyOfferId, form)
      : this.createTenancyOfferBunkReference(tenancyOfferDocRef, referencesDocRef, tenancyOfferId, referenceId, form, 'universities', 'complete');

  }

  createReferenceAndSendDocumentForSigning(id:string, property_id: string, tenancyOfferId: string,
    type: 'guarantors' | 'employers' | 'previous_tenancies',
    form: any) {
    const referencesDocRef = this.bunkPassportReferenceCollection.doc(id).ref;
    const tenancyOfferDocRef = this.tenancyOfferCollection.doc(tenancyOfferId).ref;

    return this.createTenancyOfferBunkReference(tenancyOfferDocRef, referencesDocRef, tenancyOfferId, id, form, type, 'pending_signature')
      .pipe(flatMap(() => this.sendDocumentForSigning(id, 'send_new', property_id, tenancyOfferId)));
  }


    /*
   * sendDocumentForSigning
   * https://rentbunk.atlassian.net/wiki/spaces/BW/pages/111280129/Bunk-tenancy-offer-cloud-functions
   */
  sendDocumentForSigning(id: string, action: 'send_new' | 'update_signer' | 'resend', property_id: string, tenancy_offer_id: string) {
    const body = { property_id: property_id, bunk_passport_reference_id: id, action: action, tenancy_offer_id };
    return this._http.post(environment.firebaseConfig.apiUrl + '/esignatures-sendDocumentForSigning', body);
  }



  private createTenancyOfferBunkReference(tenancyOfferDocRef: any, referencesDocRef: any,
    tenancyOfferId: string,
    referenceId: string,
    form: any,
    type: 'guarantors' | 'employers' | 'previous_tenancies' | 'universities',
    statusReference: any) {
    const singularizeType = (pluralType: 'guarantors' | 'employers' | 'previous_tenancies' | 'universities') => ({
      'guarantors': 'guarantor',
      'employers': 'employer',
      'previous_tenancies': 'previous_tenancy',
      'universities': 'university'
    })[type];

    const refType = (type === 'employers') ? 'employment_references' : (type === 'guarantors')
      ? 'guarantor_references' : (type === 'previous_tenancies') ? 'previous_tenancy_references' : 'university_references';

    const bunkPassportRef = this.bunkPassportCollection.doc(this._auth.currentUserId).ref;
    const bunkPassportPermRef = this.bunkPassportPermissionedCollection.doc(this._auth.currentUserId).ref;
    const createOrUpdateReference = (passport: any) => {
      return (passport[refType]) ?
        { [refType]: [...passport[refType], referenceId] }
        : { [refType]: [referenceId] };
    };


    const createOrUpdateMemberReference = (offerData: any, type: string, referenceId: string) => {
      return (offerData.references.members) ?
        // if member exist
        (offerData.references.members[this._auth.currentUserId]) ?
          // if reference of user exist
          (offerData.references.members[this._auth.currentUserId][type])
            // push to existing ref
            ? { [type]: [...offerData.references.members[this._auth.currentUserId][type], referenceId] }
            // else create new ref
            : { [type]: [referenceId] } : { [type]: [referenceId] } : { [type]: [referenceId] };
    };

    // adding the bunk_passport_reference_id to the bunk_passport doc & the offer if not already there
    return from(this._fb.firestore().runTransaction(transaction => {
      return transaction.get(tenancyOfferDocRef)
        .then((offer: any) => {
          return transaction.get(bunkPassportRef).then((user: any) => {
            const tenancyOffer = offer.data();
            const bunkPassport = user.data();

            const newRef = createOrUpdateMemberReference(tenancyOffer, type, referenceId);
            const passportRef = createOrUpdateReference(bunkPassport);

            const tenancyOfferItem = {
              date_modified: new Date(),
              references: {
                date_modified: new Date(),
                status: 'reference_started',
                members: {
                  [this._auth.currentUserId]: {
                    ...newRef
                  }
                }
              }
            };

            const referenceItem = {
              bunk_passport_reference_id: referenceId,
              date_created: new Date(),
              date_modified: new Date(),
              date_sent: new Date(),
              status: statusReference,
              type: singularizeType(type),
              has_been_resent: false,
              uid: this._auth.currentUserId,
              version: 1,
              reference_data: {
                ...form
              },
              tenancy_offers: {
                [tenancyOfferId]: null
              }
            };

            const passportItem = {
              date_modified: new Date(),
              ...passportRef
            };


            transaction.set(bunkPassportRef, passportItem, { merge: true });
            transaction.set(bunkPassportPermRef, passportItem, { merge: true });
            transaction.set(referencesDocRef, referenceItem, { merge: true });
            transaction.set(tenancyOfferDocRef, tenancyOfferItem, { merge: true });
          });

        });
    }));
  }


  updateReferenceAndReSendDocumentForSigning(property_id: string,
    referenceId: string,
    tenancyOfferId: string,
    form: any,
    action: any,
    type: 'guarantors' | 'employers' | 'previous_tenancies' | 'universities',
    has_been_resent?: boolean) {

      console.log('updating');

    return this.updateReference(referenceId, tenancyOfferId, form, has_been_resent, status)
      .pipe(
        flatMap(() => this.sendDocumentForSigning(referenceId, action, property_id, tenancyOfferId))
      );
  }

  updateRefStatus(referenceId: string, status: string) {
    return fromPromise(this.bunkPassportReferenceCollection.doc(referenceId).update({status: status}));
  }


  private updateReference(referenceId: string,
    tenancyOfferId: string,
    form: any,
    has_been_resent: boolean,
    status: string
  ) {
    const referencesDocRef = this.bunkPassportReferenceCollection.doc(referenceId).ref;
    const tenancyOfferDocRef = this.tenancyOfferCollection.doc(tenancyOfferId).ref;

    const batch = this.afs.firestore.batch();

    const refItem = {
      date_modified: new Date(),
      date_resent: new Date(),
      has_been_resent,
      status: 'pending_signature',
      reference_data: form.reference_data ? { ...form.reference_data } : { ...form },
      tenancy_offers: {
        [tenancyOfferId]: null
      }
    };

    const tenancyItem = {
      date_modified: new Date(),
      references: {
        date_modified: new Date(),
      }
    };

    batch.set(referencesDocRef, refItem, { merge: true });
    batch.set(tenancyOfferDocRef, tenancyItem, { merge: true });

    return fromPromise(batch.commit());


  }


  private updateIncompleteUniRef(referenceId: string,
    tenancyOfferId: string,
    form: any,
  ) {
    const referencesDocRef = this.bunkPassportReferenceCollection.doc(referenceId).ref;
    const tenancyOfferDocRef = this.tenancyOfferCollection.doc(tenancyOfferId).ref;

    const batch = this.afs.firestore.batch();

    const refItem = {
      date_modified: new Date(),
      date_resent: new Date(),
      status: 'complete',
      reference_data: form.reference_data ? { ...form.reference_data } : { ...form },
      tenancy_offers: {
        [tenancyOfferId]: null
      }
    };

    const tenancyItem = {
      date_modified: new Date(),
      references: {
        date_modified: new Date(),
      }
    };

    batch.set(referencesDocRef, refItem, { merge: true });
    batch.set(tenancyOfferDocRef, tenancyItem, { merge: true });

    return fromPromise(batch.commit());
  }

  getGuarantorsReferenceData(bunk_passport_guarantor_id: string): Observable<any> {
    const body = { bunk_passport_guarantor_id };
    return this._http.post(environment.firebaseConfig.apiUrl + '/guarantor-createGuarantorObject', body);
  }

  getGuarantorSteps(bunk_passport_guarantor_id: string): Observable<any> {
    return this._http.post(environment.firebaseConfig.apiUrl + '/guarantor-getGuarantorSteps', { bunk_passport_guarantor_id });
  }

  declineGuarantorsReferencing(bunk_passport_guarantor_id: string): Observable<any> {
    const body = { bunk_passport_guarantor_id };
    return this._http.post(environment.firebaseConfig.apiUrl + '/guarantor-declineGuarantorsReferencing', body);
  }

  guarantorDetailsSubmitForm(bunk_passport_guarantor_id: string, form: object, indices: number[] = []): Observable<any> {
    const body = {
      bunk_passport_guarantor_id,
      indices,
      form
    };
    return this._http.post(environment.firebaseConfig.apiUrl + '/guarantor-guarantorSubmitForm', body);
  }

  guarantorCreateDocument(bunk_passport_guarantor_id: string): Observable<any> {
    return this._http.post(environment.firebaseConfig.apiUrl + '/guarantor-createEsignaturesDocument', {bunk_passport_guarantor_id});
  }

  checkHasGuarantorSign(bunk_passport_guarantor_id: string): Observable<any> {
    return this._http.post(environment.firebaseConfig.apiUrl + '/guarantor-checkHasGuarantorSign', {bunk_passport_guarantor_id});
  }

  verifyGuarantorIdentity(bunk_passport_guarantor_id: string, document_image: object, verification_data: object, document_id: string): Observable<any> {
    const body = {
      bunk_passport_guarantor_id,
      document_image,
      verification_data,
      document_id
    };
    return this._http.post(environment.firebaseConfig.apiUrl + '/guarantor-verifyGuarantorIdentity', body);
  }

  checkGuarantorContegoStatus(bunk_passport_guarantor_id: string, retry_count: any): Observable<any> {
    const body = {
      bunk_passport_guarantor_id,
      retry_count
    };
    return this._http.post(environment.firebaseConfig.apiUrl + '/guarantor-checkGuarantorContegoStatus', body);
  }

  /*
   *  get identity document
  */
  getTenantIdentityUrl(uid: string) {
    return this._http.post(environment.firebaseConfig.apiUrl + '/bunkpassport-getUserPassport', {uid});
  }

  getGuarantorEsignatureDocument(reference_id: string,type:string) {
    const refId = type && type === 'references' ? {reference_id} : {bunk_passport_reference_id:reference_id}
    return this._http.post(environment.firebaseConfig.apiUrl + '/bunkpassport-getEsignaturesSignedDocument', refId);
  }


  // New referencing requirements CFs
  updateUserReferenceData(form: any, type?: string) {
    console.log(form, 'FORM BEFORE SENDING');
    return this._http.post(environment.firebaseConfig.apiUrl + '/application-updateUserReferenceData', {...form, type});
  }

  retrieveReferenceRequirements(property_id: string, type: string) {
    return this._http.post(environment.firebaseConfig.apiUrl + '/application-retrieveReferenceRequirements', { property_id, type });
  }

  formatAddress(address: any) {
    return  {
      first_line_address: address.first_line_address,
      second_line_address: address.second_line_address,
      third_line_address: address.third_line_address,
      city: address.city,
      post_code: address.post_code,
      county: address.county,
      country: address.country,
      country_iso: address.country_iso
    };
  }

  formatReferences(reference: any){
    return {
      ... reference,
      referee_phone: reference.referee_phone ? reference.referee_phone.phone_number : null,
      referee_country_code: reference.referee_phone ? reference.referee_phone.area_code : null
    };
  }


  deleteReference(bunk_passport_reference_id: string) {
    return this._http.post(environment.firebaseConfig.apiUrl + '/references-deleteReference', {bunk_passport_reference_id});
  }
}

export const paymentStatus = {
  paid: 'Paid',
  notified_paid: 'Declared paid',
  due: 'Due',
  overdue: 'Overdue',
  scheduled: 'Scheduled',
  waived: 'Waived',
  partially_paid : 'Partially paid',
  received: 'Received',
  partially_received: 'Partially received',
  register_now: 'Register now',
  register: 'Register'
};

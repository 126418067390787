import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  ChangeDetectorRef
} from '@angular/core';
import { environment } from '@env/environment';
import algoliasearch from 'algoliasearch/lite';
import instantsearch from 'instantsearch.js';
import { connectHits } from 'instantsearch.js/cjs/connectors';
import * as _ from 'lodash';
import * as moment from 'moment';
@Component({
  selector: 'instant-new-algolia-search',
  templateUrl: './instant-new-algolia-search.component.html',
  styleUrls: ['./instant-new-algolia-search.component.scss']
})
export class InstantNewAlgoliaSearchComponent implements OnInit {
  @ViewChild('searchInput', { static: false }) searchInput: ElementRef;
  @ViewChild('searchHits', { static: false }) searchHits: ElementRef;


  @Output() valueChange: EventEmitter<any> = new EventEmitter<any>();
  @Input() env: 'prod' | 'test';  // force environment prefix for algolia
  @Input() indexName: string;     // algolia table name
  @Input() disabled = false;
  @Input() disabledMessage = '';
  @Input() message = 'Type and search...';
  /* ignoreList
  **do not display any item from the ignore list prop**
  inputs possible
  - you can input an array of strings, which will be assumed as uid
  - you can input an array of objects, in format, [{path:'address.city', value:'some_city'}]
  */
  @Input() ignoreList: string[] = [];
  @Input() hideResults = true;
  @Input() showImmediately = false;
  @Input() multiselect = true;
  /*
  showAvatar
  input : set it to true if its users list only (will display profile image)
  */
  @Input() showAvatar = false;
    /*
fieldsToDisplay

input 1: Array of objects (with options), each object has key named path, which is path to the field which needs to be displayed in list
!example 1: [{path: 'address.fullname'},{path: 'others.number.id', prefix: '$'}]
Output 1: if object is {address:{fullname:'helo'},others:{number:{id:1}}}, displays "helo, $1" in the list.
*Options for object: [path:STRING, prefix:STRING, postfix:STRING, formatTime:BOOLEAN]

input 2: Array of strings, each string is path to the field which needs to be displayed in list
!example 2: ['address.fullname', 'others.number.id']
Output 2: if object is {address:{fullname:'helo'},others:{number:{id:1}}}, displays "helo, 1" in the list.
? combintion of string and object is possible.
  */
  @Input() fieldsToDisplay = ['address.first_line_address', 'address.second_line_address'];
/*
fieldsToReturn
input: Array of strings, each string is path to the field which needs to be returned once user click the item
example#1: ['others.user.id', 'address.fullname']
?important- first element of array should be an id (We are assuming it in the component)
Output#1: if object is {address:{fullname:'helo'},others:{user:{id:1}}}, returns [{id:1, fullname:'helo'}].

example#2: ['others.user.id']
?important- here, we returns array of strings (ids)
Output#2: if object is {address:{fullname:'helo'},others:{number:{id:1}}}, returns [1].

*/
  @Input() fieldsToReturn = ['property_id'];
  public showHits = false;
  public search: any;
  public resultsList: any[] = [];
  private instantsearchWidgetHelper: any;
  public searchError: boolean;
  public totalHits: number;

  storedIdsValue: string[];
  storedHits: string[];
  storedObjects: object[] = [];
  @Output() storedIdsChange: EventEmitter<string[]> = new EventEmitter<string[]>();

  @Input()
  get storedIds(): string[] {
    return this.storedIdsValue || [];
  }
  set storedIds(ids: string[]) {
    this.storedIdsValue = ids;
    this.storedIdsChange.emit(ids);
  }
  @Input()
  get availableHits(): string[] {
    return this.storedHits;
  }

  set availableHits(ids: string[]) {
    this.storedHits = ids;
  }



  set value(value: any) {
    this.valueChange.emit(value);
    this.searchInput.nativeElement.focus();
  }


  constructor(private ref: ChangeDetectorRef, ) { }

  ngOnInit() {
    const { apiKey, appId, env } = environment.algolia;
    const indexName = (this.indexName === 'properties' || this.indexName === 'transactions' || this.indexName === 'payments') ? `${env}-${this.indexName}` : `${env}_${this.indexName}`;

    this.search = instantsearch({
      indexName,
      searchClient: algoliasearch(appId, apiKey),
      searchFunction: (helper: any) => {
        // skip search on page load....
        // https://www.algolia.com/doc/api-reference/widgets/instantsearch/js/#widget-param-searchfunction
        if (!this.instantsearchWidgetHelper) {
          this.instantsearchWidgetHelper = helper;
        } else {
          helper.search();
        }
      }
    });

    this.search.on('error', () => {
      this.totalHits = 0;
      this.searchError = true;
    });

    // 1. Create a render function
    const renderHits = (opts: any, isFirstRender: boolean) => {
      this.instantsearchWidgetHelper = opts.instantSearchInstance.helper;
      // if the results are identical to the last do nothing, otherwise return the results...
      if (!_.isEqual(this.resultsList, opts.hits)) {
        this.totalHits = opts.results.nbHits; // keep a reference of the total hits (for pagination) that are not filtered by the page factor
        this.resultsList = this.checkForDupes(opts.results.hits);
      } else if (!opts.hits.length) {
        this.totalHits = 0;
      }
      this.searchError = false;
    };
    // 2. Create the custom widget
    const customHits = connectHits(
      renderHits
    );

    // 3. Instantiate
    this.search.addWidgets([
      customHits()
    ]);

    this.search.start();

  }


  isChecked(checkBoxId) {
    if (this.fieldsToReturn.length === 1) {
     return this.storedIds.some(id => id === checkBoxId);
    }
    if (this.fieldsToReturn.length > 1) {
     return this.storedObjects.some(obj => {
      const idKey = Object.keys(obj)[0]; // always first element will be ID
      return obj[idKey] === checkBoxId;
    });
    }
  }

  setSearchQuery(e: any) {
    const searchStr = e.currentTarget.value;

    this.showHits = this.showImmediately || searchStr.length > 1;
    this.instantsearchWidgetHelper.setQuery(searchStr).search();

  }

  clearSearch(clearAll = true): void {
 /*
if its multiSelect  => only when you click 'x' it will clear the input field
                    => when you click backspace, it removes one character
if its singleSelect => on clicking 'x', it clear input and clear the selected value
                    => on pressing backspace,
                          --> if no item selected, it will remove a character
                          --> if item is selected, it will clear the input
 */
    if (!this.multiselect) {
      if (this.storedIds.length || this.storedObjects.length || clearAll) {
        this.searchInput.nativeElement.value = '';
        this.storedIds = [];
        this.storedObjects = [];
        this.value = [];
        this.hideResults && this.hideSearchResults();
        return;
      }
      return;
    }
    if (clearAll) {
      this.searchInput.nativeElement.value = '';
      this.hideResults && this.hideSearchResults();
      return;
    }

  }

  hideSearchResults(): void {
    this.showHits = false;
    this.searchInput.nativeElement.blur();
  }

  clickHideSearchResults(e: any) {
    if (e.target !== this.searchInput.nativeElement && (this.hideResults || e.value)) {
      this.hideSearchResults();
    }
  }

  // @todo: works just for uid field at the moment check for dupes, do not display any item from the ignore list prop
  // checkForDupes = (hitsArr): any[] => hitsArr.filter(hit => !this.ignoreList.find(ignoreItem => ignoreItem === hit['uid']));
// new function
  /* ignoreList
  **do not display any item from the ignore list prop**
  inputs possible
  - you can input an array of strings, which will be assumed as uid
  - you can input an array of objects, in format, [{path:'address.city', value:'some_city'}]
  */

  checkForDupes(hitsArr) {
 return hitsArr.filter(hit => !this.ignoreList.some((ignoreItem: any) => {
    let hitValueToIgnore;
    let valueInIgnoreList;
    if (typeof ignoreItem === 'object') {
    hitValueToIgnore = this.getValuesFromObject(hit, [ignoreItem.path])[0];
    valueInIgnoreList = ignoreItem.value;
    }
    if (typeof ignoreItem === 'string') {
    hitValueToIgnore = hit['uid'];
    valueInIgnoreList = ignoreItem;
    }
    if (hitValueToIgnore && valueInIgnoreList) {
    return valueInIgnoreList === hitValueToIgnore;
    }
    return true;
    }));
}
  setValueChanges(hit: object, value: string) {
    const data = this.getValuesFromObject(hit, this.fieldsToReturn);
    const object = {};
    if(!this.multiselect){
      this.hideSearchResults()
    }
    // if we have multiple fields to return on click,
    // makes an object out of this array, key==last field in each element of FieldsToReturn
    if (this.fieldsToReturn.length > 1) {
      this.fieldsToReturn.forEach((field, index) => {
        const key = field.split('.').pop();
        object[key] =  data[index];
      });
      if (!_.isEmpty(object)) {
        this.storedObjects = this.updateObjectArray(object);
        this.value = this.storedObjects;
        this.searchInput.nativeElement.value = this.multiselect ? '' : value;
      }
      return;
    }
    // if we have only a single field to return (it will be id)
    // data will be [id] => data[0] == id
    this.searchInput.nativeElement.value = this.multiselect ? '' : value;
    this.storedIds = this.updateIdArray(data[0]);
    this.value = this.storedIds;
  }

  updateObjectArray(obj: object): any {
    if (!this.multiselect) {
      return [obj];
    }
   const objectExist = this.storedObjects.some(out => _.isEqual(out, obj));
   return objectExist ? this.storedObjects.filter(out => !_.isEqual(out, obj)) : [...this.storedObjects, obj];
  }

  updateIdArray(id: string) {
    if (!this.multiselect) {
      return [id];
    }
    return (this.storedIds.includes(id)) ? this.storedIds.filter(e => e !== id) : [...this.storedIds, id];
  }

  /*
getValueFromObject(object, arrayOfFields)
input: Object of data, array containing fields to return
Output: String having all the data available in object (of fields listed in array), separated by comma.
  */
  getValueFromObject(obj, keysArray) {
    if (this.indexName === 'USERS-full_name' && obj.role !== 'tenant') {
     return;
    }
    if (this.indexName === 'properties' && !this.storedHits.includes(obj.property_id)) {
      return;
    }
   return this.getValuesFromObject(obj, keysArray).join(', ');
  }
  /*
getValuesFromObject(object, arrayOfMetadata)
input: Object of data, array containing metadata object fields to return
Output: Array having all the data available in object (of meta fields listed in array).
  */
  getValuesFromObject(obj, metaDataArray: Array<any>) {
    const valuesArray = [];
    metaDataArray.forEach(metaData => {
      const metaDataIsObject = typeof metaData === 'object';
      const splitted = metaDataIsObject  ? metaData['path'].split('.') : metaData.split('.');
      let value = '';
      splitted.forEach((key, index, array) => {
          value = !value ? (!_.isUndefined(obj[key]) ? obj[key] : '') : (!_.isUndefined(value[key]) ? value[key] : value);
          const isLastElement = index === (array.length - 1);
        if (value !== '' && !_.isNil(value) && isLastElement) { // on last loop, index === arry.length-1 becomes true
          value =  metaDataIsObject ? this.alterValue(value, metaData) : _.isBoolean(value) ? value : value.toString();
        }
        });

        if (!_.isNil(value)) {
          const valueIsObject = _.isObject(value)
          const valueIsNotObjectAndValid = !valueIsObject && value !== '';
          const ValueIsObjectAndValid = valueIsObject && !_.isEmpty(value)
          const valueIsValid = valueIsNotObjectAndValid || ValueIsObjectAndValid;
          if(valueIsValid){
            valuesArray.push(value);
          }
        }
      });
    return valuesArray;
  }

  alterValue(value, object) {
    if (object.prefix) {
      value = object.prefix.toString() + value;
    }

    if (object.postfix) {
      value = value + object.prefix.toString();
    }

    if (object.formatTime) {
      value = moment(value).format('DD MMM YYYY');
    }
    return value;
  }


}

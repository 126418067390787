import { Injectable } from '@angular/core';
import algoliasearch from 'algoliasearch';
import { map, catchError } from 'rxjs/operators';
import { from, Observable } from 'rxjs';
import { isArray } from 'rxjs/internal-compatibility';
import * as _ from 'lodash'

/** @todo: bunk-models */
import { IBaseRequest, IBaseResponse, IBaseFilter, BasePagination, BaseRequest } from '@rentbunk/bunk-models';

import { environment } from '@env/environment';
const BASE_ALGOLIA_INDEX = environment.algolia.env;

type SearchClient = any;
type SearchIndex = any;
type SearchOptions = any;
type SearchResponse = any;

@Injectable()
export class AlgoliaAdapter {

  private searchClient: SearchClient;

  constructor(
  ) {
    this.init();
  }

  init() {
    console.log('Algolia Adapter v.3 init');
    const { appId, apiKey } = environment.algolia;
    this.searchClient = algoliasearch(appId, apiKey);
  }

  getByObjectId<T>(indexName: string, objectId: string): Observable<T> {
    const index: string = this.getAlgoliaIndexName(indexName);
    const algoliaIndex: SearchIndex = this.searchClient.initIndex(index);   
    return algoliaIndex.getObject(objectId)
  }

  /** @todo: us 'settings' instead of 'isAll' */
  getFilteredAlgoliaData<T>(indexName: string, request: IBaseRequest<IBaseFilter>, isAll?: boolean): Observable<IBaseResponse<T>> {
    const index: string = this.getAlgoliaIndexName(indexName, request.order);
    const algoliaIndex: SearchIndex = this.searchClient.initIndex(index);   

    const query = request.query || '';
    /** @todo: Add new model for algolia pagination? need to exclude totalHits */
    const searchOptions: SearchOptions = {
      query,
      page: request.pagination.page,
      hitsPerPage: request.pagination.hitsPerPage,
      filters: this.convertFilterToString(request.filter)
      /** @TODO add ability to use facets, numericFilters etc */
    };

    /** isAll removes pagination limits and makes it possible to load all data at once */
    /** Method not allowed with this API key */
    // if (isAll) {
    //   algoliaIndex.setSettings({
    //     paginationLimitedTo: searchOptions.hitsPerPage
    //   });
    // }

    console.log('Algolia Adapter searchOptions = ', searchOptions);

    return from(algoliaIndex.search(searchOptions)).pipe(
      map((res: SearchResponse) => ({
        items: res.hits,
        pagination: new BasePagination({
          page: res.page,
          hitsPerPage: res.hitsPerPage,
          totalHits: res.nbHits
        })
      })),
      /** @TODO error catching */
    )
  }

  private convertFilterToString(filter: IBaseFilter): string {
    if (!filter) {
      return '';
    }
    return Object.keys(filter)
      .filter((key: string) => !!filter[key])
      .map((key: string) => {
        let value = filter[key];

        if (_.isArray(value)) {
          return value.map(x => `${key}:${x}`).join(' OR ')
        }
        
        if (_.isObject(value)) {
          return `${key}:${value.from} TO ${value.to}`;
        }

        return `${key}:${value}`;
      }).join(' AND ');
  }

  private getAlgoliaIndexName(indexName: string, order?: string): string {
    const index = `${BASE_ALGOLIA_INDEX}-${indexName}`;
    return order ? `${index}-${order}` : index;
  }
}

import { TenantVerifyEventEmiterService } from '../profile-tenant.service';
import { Input } from '@angular/core';

export class TenantAccountStepComponent {
  @Input() showPrev = false;
  public submittingData = false;
  public submittingDataSuccess = false;

  constructor(
    public _verifyEvent: TenantVerifyEventEmiterService,
  ) { }

  public stepComplete(delay: number = 1200): void {
    setTimeout(() => this._verifyEvent.stepComplete(), delay);
    this.submittingDataSuccess = true;
    this.submittingData = false;
  }

  public stepError() {
    this.submittingDataSuccess = false;
    this.submittingData = false;
  }
}

import { OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject, Observable, of } from 'rxjs';
import { UserService } from '@app/services';
import { TenantVerifyEventEmiterService } from '../profile-tenant.service';
import { VeriStepRoute, VeriStepRoutes, verificationSteps } from '../profile-tenant.constants';
import { flatMap, takeUntil, map, tap } from 'rxjs/operators';
import { AuthService } from '@app/core/services';

export class TenantAccountVerifyControllerComponent implements OnDestroy {
  protected destroy$: Subject<boolean> = new Subject<boolean>();
  protected currentStepNumber: number;
  protected userReadOnlySub: any;
  protected steps: VeriStepRoutes;
  protected userTier: number;
  protected tierBreakPoints: number[];
  public userSteps$: Observable<any>;
  public navigateStep$: Observable<any>;
  public combined$: Observable<any>;
  public userReadOnly$: Observable<any>;
  public userIsStudent: boolean;
  
  constructor(
    public _eventEmiter: TenantVerifyEventEmiterService,
    public _router: Router,
    public _route: ActivatedRoute,
    public _user: UserService,
    public _auth: AuthService
  ) {
    this.currentStepNumber = 0;
    this.steps = verificationSteps;

    // @TODO rename to userSteps?
    this.userSteps$ = this._user.user$.pipe(
      tap((user: any) => this.userIsStudent = user ? user.is_student : this.userIsStudent),
      flatMap((user: any) => user ? this._user.getUserReadOnlyById(user.uid).valueChanges() : of(null)),
      map((user_re: any) => user_re ? this.updateUserReadOnlySteps(user_re) : of([]))
    );

    this.userReadOnly$ = this._user.getUserReadOnlyById(this._auth.currentUserId).valueChanges();
    
    // UI Event handlers
    this._eventEmiter.stepCompleted.pipe(
      map(e => {
        const nextStep = this.getNextStep();
        this.gotoStep(nextStep);
        return nextStep;
      }),
      takeUntil(this.destroy$)
    ).subscribe();

    this._eventEmiter.gotoStep.pipe(
      // startWith(undefined),
      tap((navigateStr: string) => {
        const next = this.getNextFromNavigation(navigateStr);
        this.gotoStep(next);
      }),
      takeUntil(this.destroy$)
    ).subscribe();
  }

  get filteredSteps(): VeriStepRoutes {
    // apply student conditions to array and breakpoints...
    this.tierBreakPoints = [3, this.userIsStudent ? 8 : 7]; // side-effect
    return this.userIsStudent ? [ ...verificationSteps ] : [ ...verificationSteps ].filter((step: VeriStepRoute) => !step.studentsOnly);
  }

  protected getNextStep(): VeriStepRoute {
    const breakPoint: number = this.tierBreakPoints[this.userTier - 1];
    // const prevIncompleteStep: VeriStepRoute = this.steps.slice(0, this.currentStepNumber).find((step: VeriStepRoute) => !step.complete);
    const nextIncompleteStep: VeriStepRoute = this.filteredSteps.slice(this.currentStepNumber + 1, breakPoint).find((step: VeriStepRoute) => !step.status);
    return nextIncompleteStep || this.filteredSteps.slice(0, this.currentStepNumber).find((step: VeriStepRoute) => !step.status);
  }

  protected getNextFromNavigation(navigateStep: string): VeriStepRoute  {
    let nextStep = this.steps.find((step: VeriStepRoute) => this.steps[this.currentStepNumber].path === step.path);
    if (navigateStep === 'next') {
      nextStep = this.steps.slice(this.currentStepNumber + 1).find((step: VeriStepRoute) => !step.status);
    } else if (navigateStep === 'prev') {
      nextStep = this.steps.slice(0, this.currentStepNumber).reverse().find((step: VeriStepRoute) => !step.status);
    }
    return nextStep;
  }

  protected getVerificationSteps(user_re_steps: VeriStepRoutes) {
    const steps = this.steps.map((step: VeriStepRoute) => {
      step.hidden = !this.userIsStudent && step.studentsOnly;
      return step;
    });
    // Use the master array of steps to build the expected verification flow
    user_re_steps.forEach((re_item: any) => {
      steps[re_item.step - 1].status = re_item.status !== '' ? re_item.status : 'unverified';
    });
    return steps;
  }

  /* 
    Listens to the 'user_read_only' stream for all verification step updates and apply the update to our steps array
    Directly update each local step's status
  */
  protected updateUserReadOnlySteps(user: any): VeriStepRoutes {
    this.userTier = user.verification_tier;
    return this.getVerificationSteps(user.verification_steps);
  }

  /* 
    Will be overridded by the sub class - the last function call for any navigation
  */
  protected gotoStep(targetStep: VeriStepRoute): void {
    if (!targetStep) {
      return;
    }
    this.currentStepNumber = this.filteredSteps.findIndex((step: VeriStepRoute) => targetStep.path === step.path);
  }

  protected getStep(id: number): VeriStepRoute {
    return this.steps.find((step: VeriStepRoute) => this.steps[id].path === step.path);
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}

import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { OnlyLoggedinNotAuthenticatedRouteGuard } from '@app/core/guards/only-loggedin-users.guard';
import { SigninComponent } from '@app/login/signin/signin.component';
import { SignupComponent } from '@app/login/signup/signup.component';
import { PropertiesSearchComponent } from '@app/properties/properties-search';
import { BedroomPageComponent, PropertyPageComponent, PropertyPreviewBedroomComponent } from '@app/properties/property-page';

import {
  OnlyLoggedinUsersGuard, IsLandLordGuard, IsRoleDefinedGuard, IsTenantGuard, DevelopmentGuard, InternetExplorerGuard, userReadOnlyGuard, HasFullAccess
} from '@app/core/guards';


import {
  Auth,
  Landlord,
  Shared,
  Tenant,
  StyleGuide,
  Profile,
  Guarantors,
  Viewings,
  Admin,
  Notices,
  Documents,
  Account,
  Dashboards,
  Tasks,
  Tenancies,
  Calendar,
  Applications,
  Payments,
  References,
  BankFeed
} from '@env/routing';

import { IeUsersComponent } from '@app/core/ie-users';
import { NoUserDocComponent } from '@app/core/no-user-doc';
import { NoUserDocsGuard } from '@app/core/guards/no-user-docs.guard';
import {ApplicationsModule} from '@app/applications/applications.module';
import {ActivateAccountComponent} from '@app/login/signin/activate-account.component';
import {ResetPasswordComponent} from '@app/login/reset-password/reset-password.component';

export const appRoutes: Routes = [
  // @TODO: only if guest user or determine role and redirect to dashboard..
  {
    path: '',
    redirectTo: Auth.signin,
    pathMatch: 'full'
  },

  {
    path: Auth.signin, component: SigninComponent, canActivate: [OnlyLoggedinNotAuthenticatedRouteGuard, InternetExplorerGuard]
  },
  {
    path: Auth.signup, component: SignupComponent, canActivate: [OnlyLoggedinNotAuthenticatedRouteGuard, InternetExplorerGuard]
  },
  {
    path: Auth.activate, component: ActivateAccountComponent, canActivate: [OnlyLoggedinNotAuthenticatedRouteGuard, InternetExplorerGuard]
  },
  {
    path: Auth.resetPassword, component: ResetPasswordComponent, canActivate: [OnlyLoggedinNotAuthenticatedRouteGuard, InternetExplorerGuard]
  },

  {
    path: Shared.ie_user,
    component: IeUsersComponent
  },
  {
    path: Shared.no_user,
    component: NoUserDocComponent
  },

  {
    path: Shared.search,
    canActivate: [InternetExplorerGuard],
    component: PropertiesSearchComponent,
  },

  {
    path: `${Shared.search}/:id`,
    canActivate: [InternetExplorerGuard],
    component: PropertyPageComponent,
  },
  {
    path: `${Shared.search}/:id/${Shared.bedroom}`,
    canActivate: [InternetExplorerGuard],
    component: BedroomPageComponent,
  },

  {
    path: Shared.chat.inbox,
    canActivate: [OnlyLoggedinUsersGuard],
    loadChildren: () => import('./chat/chat.module').then(m => m.ChatModule)
  },

  {
    path: StyleGuide.base,
    canActivate: [DevelopmentGuard, InternetExplorerGuard],
    loadChildren: () => import('./style-guide/style-guide.module').then(m => m.StyleGuideModule)
  },

  {
    path: Guarantors.base,
    loadChildren: () => import('./guarantors/guarantors.module').then(m => m.GuarantorsModule)
  },

  {
    path: `${Profile.landlord.base}/${Profile.landlord.bunk_passport.base}`,
    canActivate: [OnlyLoggedinUsersGuard, IsRoleDefinedGuard, IsLandLordGuard, InternetExplorerGuard],
    loadChildren: () => import('./profile-landlord/profile-landlord.module').then(m => m.ProfileLandlordModule)
  },

  {
    path: Landlord.base,
    canActivate: [OnlyLoggedinUsersGuard, IsRoleDefinedGuard, IsLandLordGuard, InternetExplorerGuard, userReadOnlyGuard, NoUserDocsGuard],
    loadChildren: () => import('./core-landlord/core-landlord.module').then(m => m.CoreLandlordModule)
  },

  {
    path: Tenant.base,
    canActivate: [OnlyLoggedinUsersGuard, IsRoleDefinedGuard, IsTenantGuard, InternetExplorerGuard, NoUserDocsGuard],
    loadChildren: () => import('./core-tenant/core-tenant.module').then(m => m.CoreTenantModule)
  },

  {
    path: Profile.tenant.base,
    canActivate: [OnlyLoggedinUsersGuard, IsRoleDefinedGuard, IsTenantGuard, InternetExplorerGuard],
    loadChildren: () => import('./profile-tenant/profile-tenant.module').then(m => m.ProfileTenantModule)
  },
  {
    path: Admin.base,
    canActivate: [OnlyLoggedinUsersGuard, IsRoleDefinedGuard, InternetExplorerGuard],
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule)
  },
  {
    path: BankFeed.base,
    canActivate: [OnlyLoggedinUsersGuard, IsRoleDefinedGuard, InternetExplorerGuard],
    loadChildren: () => import('./bank-feed/transactions.module').then(m => m.TransactionsModule)
  },
  {
    path: Documents.base,
    canActivate: [OnlyLoggedinUsersGuard, IsRoleDefinedGuard, InternetExplorerGuard, userReadOnlyGuard],
    loadChildren: () => import('./documents/documents.module').then(m => m.DocumentsModule)
  },
  {
    path: Notices.base,
    canActivate: [OnlyLoggedinUsersGuard, IsRoleDefinedGuard, InternetExplorerGuard, HasFullAccess],
    data: { route: 'notices' },
    loadChildren: () => import('./notices/notices.module').then(m => m.NoticesModule)
  },
  {
    path: Account.base,
    canActivate: [OnlyLoggedinUsersGuard, IsRoleDefinedGuard, InternetExplorerGuard],
    loadChildren: () => import('./account/account.module').then(m => m.AccountModule)
  },
  {
    path: Dashboards.base,
    canActivate: [OnlyLoggedinUsersGuard, IsRoleDefinedGuard, InternetExplorerGuard, HasFullAccess],
    data: {route: 'dashboard'} ,
    loadChildren: () => import('./dashboards/dashboards.module').then(m => m.DashboardsModule)
  },
  {
    path: Tasks.base,
    canActivate: [OnlyLoggedinUsersGuard, InternetExplorerGuard, userReadOnlyGuard],
    loadChildren: () => import('./tasks/tasks.module').then(m => m.TasksModule)
  },
  {
    path: References.base,
    canActivate: [OnlyLoggedinUsersGuard, InternetExplorerGuard, userReadOnlyGuard],
    loadChildren: () => import('./referencing/referencing.module').then(m => m.ReferencingModule)
  },
  {
    path: Viewings.base,
    canActivate: [InternetExplorerGuard, HasFullAccess],
    data: { route: 'viewings' },
    loadChildren: () => import('./viewings/viewings.module').then(m => m.ViewingsModule)
  },
  {
    path: Calendar.base,
    canActivate: [OnlyLoggedinUsersGuard, InternetExplorerGuard, userReadOnlyGuard],
    loadChildren: () => import('./calendar/calendar.module').then(m => m.CalendarModule)
  },
  {
    path: Payments.base,
    canActivate: [OnlyLoggedinUsersGuard, InternetExplorerGuard, userReadOnlyGuard],
    loadChildren: () => import('./payments/payments.module').then(m => m.PaymentsModule)
  },
  {
    path: Payments.base + '-new',
    canActivate: [OnlyLoggedinUsersGuard, InternetExplorerGuard, userReadOnlyGuard],
    loadChildren: () => import('./payments-new/payments-new.module').then(m => m.PaymentsNewModule)
  },
  {
    path: Applications.base,
    canActivate: [OnlyLoggedinUsersGuard, InternetExplorerGuard, userReadOnlyGuard],
    loadChildren: () => import('./applications/applications.module').then(m => m.ApplicationsModule)
  },
  {
    path:  `${Profile.landlord.base}/${Tenancies.base}/${Tenancies.tenants.base}`,
    canActivate: [OnlyLoggedinUsersGuard, IsRoleDefinedGuard, IsLandLordGuard, InternetExplorerGuard, userReadOnlyGuard],
    loadChildren: () => import('./core-landlord/core-landlord.module').then(m => m.CoreLandlordModule)
  },
  { path: '**', redirectTo: '/', pathMatch: 'full' },

];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {
      useHash: false,
      preloadingStrategy: PreloadAllModules,
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
      scrollOffset: [0, 80], // 52: The height of the global navbar. Manually adding offset even though zero as could be some issue with some browsers - cannot rely on 'scrollPositionRestoration: top'
      // enableTracing: true
    }),
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }

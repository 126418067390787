import {Component, OnDestroy, OnInit} from '@angular/core';
import {Observable} from 'rxjs/Observable';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Subject} from 'rxjs';
import {environment} from '@env/environment';
import {ActivatedRoute, Router} from '@angular/router';
import {finalize, map} from 'rxjs/operators';
import {AuthService} from '@app/core/services';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-reset-password',
  templateUrl: `./reset-password.component.html`,
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnDestroy, OnInit {
  public isDesktop: boolean;
  public formGroup: FormGroup;
  public showSpinner = false;
  private destroy$: Subject<boolean> = new Subject<boolean>();
  public client_data = environment.client_data;
  public logo = this.client_data.company_name === 'CampusKey' ? '/assets/logos/white-logo.svg' : '/assets/logos/main-logo.svg';
  public showLabel = false;
  public passwordStrength$: Observable<any>;
  public submitAttempted = false;
  public token: string;
  public isValidToken = false;

  get password(): FormGroup {
    return this.formGroup.get('password') as FormGroup;
  }

  constructor(
    public _auth: AuthService,
    private _formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private _toastr: ToastrService,
    private _router: Router
  ) {
    this.formGroup = this.initFormGroup();
    this.route.queryParams.subscribe(params => {
      console.log(params.token);
      if (params.token) {
        this._auth.validateResetPasswordToken(params.token).subscribe(
          () => {
            this.token = params.token;
            this.isValidToken = true;
          },
          () => {
            this.token = '';
            this.isValidToken = false;
          });
      }
    });
  }

  ngOnInit() {
    this.passwordStrength$ = this.formGroup.get('password').valueChanges.pipe(
      map((val: string) => {
        return {
          hasLowercase: /[a-z]/.test(val),
          hasUppercase: /[A-Z]/.test(val),
          hasNumber: /\d/.test(val),
          hasLength: val.length >= environment.minimumPasswordLength
        };
      })
    );
  }

  initFormGroup(): FormGroup {
    return this._formBuilder.group({
      password: ['', [Validators.required, Validators.pattern('^(?:(?:(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]))|(?:(?=.*[a-z])(?=.*[A-Z])(?=.*[*.!@$%^&(){}[]:;<>,.?/~_+-=|\]))|(?:(?=.*[0-9])(?=.*[A-Z])(?=.*[*.!@$%^&(){}[]:;<>,.?/~_+-=|\]))|(?:(?=.*[0-9])(?=.*[a-z])(?=.*[*.!@$%^&(){}[]:;<>,.?/~_+-=|\]))).{8,32}$')]],
    });
  }

  trim(controlName) {
    this.formGroup.get(controlName).patchValue(this.formGroup.get(controlName).value.trim());
  }

  updatePassword() {
    this.submitAttempted = true;
    this.showSpinner = true;
    this.formGroup.disable();
    this._auth.updatePassword(this.password.value.trim(), this.token)
      .pipe(
        finalize(() => {
          this.submitAttempted = false;
          this.formGroup.enable();
        })
      ).subscribe(
      (success: any) => {
        this._toastr.success(success.message);
        setTimeout(() => {
          return this._router.navigate(['/']);
        }, 1000);
      },
      (err: any) => {
        this._toastr.error(err.error.message);
      }
    );
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}

import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';

export interface IMenuOption {
  icon?: string;
  title: string;
  action: string;
}

@Component({
  selector: 'app-dropdown-menu',
  templateUrl: './dropdown-menu.component.html',
  styleUrls: ['./dropdown-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DropdownMenuComponent implements OnInit {

  @Input() options: IMenuOption[];
  @Output() action = new EventEmitter<string>();

  constructor() { }

  ngOnInit() {
  }

  public onAction(action: string): void {
    this.action.emit(action);
  }

}

import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { TooltipPosition } from '@angular/material';

@Component({
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TooltipComponent {
  @Input() message: string;
  @Input() ariaLabel: string;
  @Input() position: TooltipPosition = 'below';
  @Input() disabled: boolean;
  @Input() hideDelay: number = 0;
  @Input() showDelay: number = 0;
  @Input() tooltipClass: any;

  constructor() { }
}

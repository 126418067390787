import { Component, Input, OnInit } from '@angular/core';
import {MaintenanceService} from '@app/services/maintenance.service';
import {Observable} from 'rxjs';
import {PropertyService} from '@app/services/property.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { flatMap, map, startWith, tap } from 'rxjs/operators';
import { combineLatest, BehaviorSubject, of } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '@app/services';
import { isArray } from 'rxjs/internal-compatibility';
import { AuthService } from '@app/core/services';


@Component({
  selector: 'maintenance-overview',
  template: `
    <section class="main__wrapper">
        <ng-container *ngIf="{portfolios: propertyPortfolios$ | async, results: filteredResults$ | async } as data; else loading">
          <ng-container *ngIf="data.portfolios as portfolios; else loading">
            <ng-container *ngIf="data.results as results; else loading">
              <div class="filter-bar" [ngClass]="{'filter-bar--portfolios': portfolios.length}">

                <div class="mr_5" >
                  <instant-algolia-search
                    [indexName]="'properties'"
                    [message]="'Filter by property'"
                    [hideResults]="false"
                    [storedIds]="storedIds"
                    (storedIdsChange)="onStoredIdsChange($event)"
                    [showImmediately]="true"
                    [availableHits]="availableProperties">
                  </instant-algolia-search>
                </div>

                <mat-form-field appearance="outline">
                  <mat-label>Filter by: </mat-label>
                  <mat-select [formControl]="statusFilter">
                    <mat-option *ngFor="let type of statusFilterTypes" [value]="type.value">
                      {{type.viewValue}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <mat-form-field appearance="outline">
                  <mat-label>Order by: </mat-label>
                  <mat-select [formControl]="orderFilter">
                    <mat-option *ngFor="let type of orderFilterTypes" [value]="type.value">
                      {{type.viewValue}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                <ng-container *ngIf="_user.userDb.role === 'landlord'">
                  <mat-form-field appearance="outline" *ngIf="portfolios.length">
                    <mat-label>Portfolios: </mat-label>
                    <mat-select [formControl]="portfolioFilter">
                      <mat-option [value]="'all'" (click)="latestPortfolioId.next('all')">All</mat-option>
                      <mat-option *ngFor="let portfolio of portfolios"
                                  [value]="(portfolio.portfolio_id)"
                                  (click)="latestPortfolioId.next(portfolio.portfolio_id)"
                      >
                        {{portfolio?.portfolio_name}}
                      </mat-option>
                    </mat-select>
                    <mat-error>Please select a Portfolio</mat-error>
                  </mat-form-field>
                </ng-container>
                <div class="hide-sm hide-md">
                  <button
                    (click)="resetFilters()"
                    class="btn__subdued btn--round btn--sm margin-top--md">Reset</button>
                </div>
              </div>

              <ul>
                <ng-container *ngFor="let request of results">
                  <maintenance-card [request]="request"></maintenance-card>
                </ng-container>
              </ul>
            </ng-container>
            </ng-container>
          </ng-container>

      <ng-template #loading>
        <div class="margin-top--lg hide-sm">
          <card-skeleton [is_small_card]="true"></card-skeleton>
        </div>
      </ng-template>
    </section>

  `,
  styleUrls: ['./maintenance-overview.component.scss']
})
export class MaintenanceOverviewComponent implements OnInit {
  @Input() maintenance$: Observable<any>;
  public filteredResults$: Observable<any>;
  public statusFilter: FormControl;
  public statusFilterTypes: object[];
  public filterStatus$: Observable<string>;
  public orderFilter: FormControl;
  public orderFilterTypes: object[];
  public filterOrder$: Observable<string>;
  public filterQuery: string;
  public orderQuery: string;

  public propertyQuery: any; // properties ids from query params


  public portfolio$: Observable<any>;
  public propertyPortfolios$: Observable<any>;
  private latestPortfolioId: BehaviorSubject<string> = new BehaviorSubject('all');
  public portfolioFilter$ = this.latestPortfolioId.asObservable();
  public portfolioFilter: FormControl;
  public portfolioQuery: string;

  public storedIds: string[] = [];
  public availableProperties: string[] = [];
  public searchProperties$: Observable<any>;
  private state = new BehaviorSubject([]);
  private state$ = this.state.asObservable();


  constructor(private _maintenance: MaintenanceService,
              private _property: PropertyService,
              private _user: UserService,
              private _route: ActivatedRoute,
              private _router: Router,
              private _auth: AuthService) {

  }

  ngOnInit() {

    this.filterQuery = this._route.snapshot ? this._route.snapshot.queryParamMap.get('filter') : null;
    this.orderQuery = this._route.snapshot ? this._route.snapshot.queryParamMap.get('order') : null;

    this.portfolioQuery = this._route.snapshot.queryParamMap.get('portfolio');

    this.portfolioQuery ? this.latestPortfolioId.next(this.portfolioQuery) : this.latestPortfolioId.next('all');
    this.portfolioFilter = new FormControl(this.latestPortfolioId.getValue());
    this.propertyQuery = this._route.snapshot ? this._route.snapshot.queryParams.id : null;

    this.portfolio$ = this.portfolioFilter$.pipe(
      flatMap((id: string) => (id !== 'all') ? this._property.getPortfolioById(id).valueChanges() : of(''))
    );

    this.propertyPortfolios$ = this._property.getLandlordPropertyPortfolios();

    const startUpFilter = this.filterQuery ? this.filterQuery : 'all';
    const startUpOrder = this.orderQuery ? this.orderQuery : 'descending';

    this.statusFilter = new FormControl(startUpFilter);
    this.filterStatus$ = this.statusFilter.valueChanges.pipe(startWith(startUpFilter));

    this.orderFilter = new FormControl(startUpOrder);
    this.filterOrder$ = this.orderFilter.valueChanges.pipe(startWith(startUpOrder));

    let updatedState;
    if (this.propertyQuery && isArray(this.propertyQuery)) {
      updatedState = [...this.propertyQuery];
      this.storedIds = this.propertyQuery.filter((v) => v !== 'all');
    } else if (this.propertyQuery) {
      updatedState = [this.propertyQuery];
      this.storedIds = this.propertyQuery === 'all' ? [] : [this.propertyQuery];
    } else {
      updatedState = ['all'];
      this.storedIds = [];
    }
    this.state.next(updatedState);

    this.statusFilterTypes = [
      {
        viewValue: 'All Requests',
        value: 'all'
      },
      {
        viewValue: 'Pending Requests',
        value: 'pending'
      },
      {
        viewValue: 'In Progress',
        value: 'in_progress'
      },
      {
        viewValue: 'Completed',
        value: 'completed'
      },
    ];

    this.orderFilterTypes = [
      {
        viewValue: 'Latest',
        value: 'descending'
      },
      {
        viewValue: 'Oldest',
        value: 'ascending'
      }
    ];

    this.filteredResults$ = combineLatest([this.maintenance$, this.filterStatus$, this.filterOrder$, this.portfolioFilter$, this.portfolio$, this.state$]).pipe(
      tap(([maintenance, filterStatusString, filterOrderString, portfolioFilter, portfolio, propertyId]) => {
        (this._user.userDb.role === 'landlord')
        ? this._router.navigate( ['.'], { queryParams: { filter: filterStatusString, order: filterOrderString, id: propertyId, portfolio: portfolioFilter }, relativeTo: this._route, replaceUrl: true } )
        : this._router.navigate( ['.'], { queryParams: { filter: filterStatusString, order: filterOrderString }, relativeTo: this._route, replaceUrl: true } );

        const propertyIds = maintenance.map((main: any) => main.property_id);
        this.availableProperties = propertyIds.filter((item, index) => propertyIds.indexOf(item) === index).sort();

      }),
      map(([maintenance, filterStatusString, filterOrderString, portfolioFilter, portfolio, propertyId]) => {
        let filtered = maintenance;

        const propertyIds = maintenance.map((main: any) => main.property_id);
        this.availableProperties = propertyIds.filter((item, index) => propertyIds.indexOf(item) === index).sort();

        if (!propertyId.includes('all')) {
          const mainArray = propertyId.map((id: string) => maintenance.filter(main => main.property_id === id));
          filtered = [].concat(...mainArray);
        }

        if (filterStatusString !== 'all') {
          filtered = maintenance.filter(request => request.status === filterStatusString);
        }

        if (portfolioFilter !== 'all') {
          filtered = filtered.filter(main => (portfolio && portfolio.property_ids.includes(main.property_id) ? main : null));
        }


        switch (filterOrderString) {
          case 'ascending':
            filtered = filtered.sort((a, b) => a.created_at.toDate() - b.created_at.toDate() );
            break;
          case 'descending':
            filtered = filtered.sort((a, b) => b.created_at.toDate()  - a.created_at.toDate() );
            break;
          default:
            break;
        }
        return filtered;
      })
    );
  }

  resetFilters() {
    this.statusFilter.setValue('all');
    this.orderFilter.setValue('descending');
    this.portfolioFilter.setValue('all');
    this.latestPortfolioId.next('all');
    this.storedIds = [];
    this.state.next(['all']);
  }

  onStoredIdsChange(value: string[]) {
    this.storedIds = value;
    const updatedState = (value.length <= 0) ? ['all'] : value;
    this.state.next(updatedState);
  }

}

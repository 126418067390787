


    <screen-detection (resized)="isDesktop = $event.isDesktop"></screen-detection>

    <div class="main__wrapper" *ngIf="{property: property$ | async, bedrooms: bedrooms$ |async, landlordPublic: landlordPublic$ | async} as values">
      <button class="btn__crumb-back truncate" (click)="goBackToSearch()">Back</button>

      <section class="card__base">
        <h1 class="margin-bottom">Application</h1>

        <div *ngIf="values.property" class="margin-bottom--lg" [ngClass]="{
          'hide-sm': true, 'hide-md': true}">
          <property-small-card [property]="values.property" imageSize="lg" ></property-small-card>
        </div>


        <ng-container *ngIf="values.landlordPublic && values.landlordPublic.manager">
          <property-owner-card [landlordId]="values.property.landlord_uid" [showLogo]="false" [showManager]="true" [property]="values.property"></property-owner-card>
        </ng-container>

        <hr>

        <div *ngIf="values.property">
          <span class="text--md margin-bottom">Please choose from the below</span>
        </div>

        <div class="justify-center">
          <div class="bedroom-container">

            <ng-container *ngFor="let bedroom of values.bedrooms">

              <div class="bedroom__card" (click)="selectBedroom(bedroom)" [ngClass]="{'bedroom__card--active': bedroom.bedroomIds.includes(selectedBedroomId) }">

                <div class="bedroom__card--img">
                  <img src="{{bedroom.image}}/">
                </div>

                <div class="bedroom__card--info">
                  <div class="flex flex-column">
                    <span *ngIf="bedroom.allUnderOffer" class="block-chip block-chip--sm">All under offer</span>

                    <span
                      class="bedroom__card--subtle">{{bedroom.available}} {{!bedroom.allUnderOffer ? 'available' : PropertyType[values.property.property_type]}} {{bedroom.length > 1 ? 's' : '' }}</span>
                    <span class="bedroom__card--name" *ngIf="bedroom.apartmentType">{{bedroom.apartmentType}} {{PropertyType[values.property.property_type]}}</span>

                    <span class="bedroom__card--name" *ngIf="values.property.property_title && !bedroom.apartmentType">{{values.property.property_title}}</span>

                    <span class="bedroom__card--rent" *ngIf="bedroom.minMax && bedroom.apartmentType">{{bedroom.minMax}}{{bedroom.frequency}}</span>
                    <span class="bedroom__card--rent" *ngIf="!bedroom.apartmentType">{{client_data.currency_symbol + bedroom.amount}}{{bedroom.frequency}}</span>
                  </div>

                  <div class="bedroom__card--icon-container">
                    <span class="bedroom__card--icon mr_5" *ngIf="bedroom.allDoubleBeds"> <mat-icon svgIcon="bed-icon" class="mr_2"></mat-icon> <span>Double</span></span>
                    <!--                <span class="bedroom__card&#45;&#45;icon"  *ngIf="!bedroom.apartmentType"> <mat-icon svgIcon="lock-icon" ></mat-icon><span>£{{bedroom.deposit}}</span></span>-->
                  </div>

                </div>

              </div>

            </ng-container>
          </div>
        </div>

        <form [formGroup]="formGroup" class="margin-top--lg" *ngIf="bedroomGroup$.getValue().length >= 1 && showSelection$.getValue()">

          <span class="text--md">Select {{EPropertyType[values.property.property_type]}}</span>
          <mat-form-field appearance="outline" class="w_100">
            <mat-select [formControlName]="'bedroom_id'" placeholder="Please select the apartment you would like">
              <ng-container *ngFor="let bedroom of bedroomGroup$.getValue()">
                <mat-option *ngIf="!bedroom.is_mid_tenants" [value]="bedroom.bedroom_id" (onSelectionChange)="selectBedroomId(bedroom.bedroom_id)">
                  <strong *ngIf="bedroom.is_under_offer" class="under-offer">Under Offer</strong>
                  {{bedroom.room_name}}
                  - {{client_data.currency_symbol}} {{(bedroom.rent_weekly ? bedroom.rent_weekly : bedroom.listed_rent_pcm)}} {{(bedroom.rent_weekly ? 'weekly' : rent_label_abbreviation)}}
                </mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>
        </form>

        <div *ngIf="values.property?.tenancy_package_ids && values.property?.tenancy_package_ids.length" class="margin-top">
          <h3>Select tenancy type</h3>
          <span class="text--md margin-bottom">Why not pay a little extra and get some additional luxury</span>
          <tenancy-package [tenancyPackageIds]="values.property?.tenancy_package_ids" (selectedPackage)="updateTenancyPackaged($event)"></tenancy-package>

        </div>


        <div class="flex justify-between margin-top--lg">

          <button class="btn__seethrough" type="button" (click)="goBackToSearch()">Back</button>

          <button *ngIf="_auth.authenticated " class="btn__cta btn-lg" type="button" (click)="handleTenantOffer(values.property, selectedBedroomId)">Apply now</button>

                 <offer-sign-up-component *ngIf="!_auth.authenticated" [bedroomId]="selectedBedroomId"  [property]="values.property" [tenancyPackageId]="this.formGroup.get('tenancy_package_id').value"></offer-sign-up-component>
        </div>

      </section>

    </div>


  
import { NgModule, ModuleWithProviders } from '@angular/core';

import { DialogService } from './dialog.service';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material';

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule
  ],
  exports: [],
  declarations: [],
  providers: [],
})
export class DialogModule {
  static forRoot(): ModuleWithProviders {
      return {
          ngModule: DialogModule,
          providers: [
            DialogService
          ]
      };
  }
}

import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { UserService } from '@app/services';
import { Applications } from '@env/routing';
import { map } from 'rxjs/operators';
import {environment} from '@env/environment';

@Injectable()
export class RengenApplicationGuard implements CanActivate {
  constructor(private router: Router,
              private _user: UserService) {
  }



  canActivate(route: ActivatedRouteSnapshot,
              state: RouterStateSnapshot) {
    return this._user.user$
      .pipe(
        map(user => {

          if (!['hWAAcWRpaQYYTt9n9pnG6TT4OTN2', 'INqWol5EZrgfaouGZMuFqyktrpu1'].includes(user.uid) && !['client-campus-key', 'client-tailor-made-management'].includes(environment.firebaseConfig.projectId)) {
            return true;
          } else {
            this.router.navigate(['/' + Applications.base, Applications.overview]);
            return false;
          }
        })
      );
  }
}

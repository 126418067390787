import { environment } from '@env/environment';

export const ADMIN_ALGOLIA_INDICES = [
    {
      name: `${environment.algolia.env}_ADMIN-payments`,
      filter: 'dueDate'
    },
    {
      name: `${environment.algolia.env}_ADMIN-payments-address-desc`,
      filter: 'address_z_a'
    },
    {
      name:  `${environment.algolia.env}_ADMIN-payments-address-asc`,
      filter: 'address_a_z'
    },
    {
      name: `${environment.algolia.env}_ADMIN-payments-amount-desc`,
      filter: 'amount_high_low'
    },
    {
      name: `${environment.algolia.env}_ADMIN-payments-amount-asc`,
      filter: 'amount_low_high'
    },
  ];

export const COLUMNS_TO_DISPLAY = [
    {
      title: 'Due Date',
      value: 'momentDueDate'
    },
    {
      title: 'Property',
      value: 'propertyAddress',
      additional: 'propertyId'
    },
    {
      title: 'Building',
      value: 'building'
    },
    {
      title: 'Campus',
      value: 'campus'
    },
    {
      title: 'Tenant',
      value: 'tenantName'
    },
    {
      title: 'Type',
      value: 'paymentType'
    },
    {
      title: 'Category',
      value: 'category'
    },
    {
      title: 'Amount',
      value: 'amount'
    },
    {
      title: 'Outstanding',
      value: 'amountOutstanding'
    },
    {
      title: 'Date Paid',
      value: 'timestampPaid'
    },
    {
      title: 'Status',
      value: 'status'
    }
  ];
export const SORT_FILTER_TYPES = [
    {
      viewValue: 'Due date (desc)',
      value: 'dueDate'
    },
    {
      viewValue: 'Due date (asc)',
      value: 'asc(algolia_timestamp)'
    },
    {
      viewValue: 'Address: A - Z',
      value: 'address_a_z'
    },
    {
      viewValue: 'Address: Z - A',
      value: 'address_z_a'
    },
    {
      viewValue: 'Amount: High - Low',
      value: 'amount_high_low'
    },
    {
      viewValue: 'Amount: Low - High',
      value: 'amount_low_high'
    },
  ];
export const DATE_FILTER_TYPES = [
    {
      viewValue: 'All months',
      value: 'all'
    },
    {
      viewValue: 'This month',
      value: 'current_month'
    },
    {
      viewValue: 'Last month',
      value: 'previous_month'
    },
    {
      viewValue: 'Next month',
      value: 'next_month'
    },
    {
      viewValue: 'Custom date',
      value: 'custom_date'
    }
  ];
  export const PAYMENT_TYPE_FILTER = [
    {
      viewValue: 'All payment types',
      value: 'all'
    },
    {
      viewValue: 'Rent',
      value: 'rent'
    },
    {
      viewValue: 'Deposit',
      value: 'deposit'
    }
  ];
  export const PAYMENT_STATUS_FILTER = [
    {
      viewValue: 'All Statuses',
      value: 'all'
    },
    {
      viewValue: 'Overdue',
      value: 'overdue'
    },
    {
      viewValue: 'Partially Paid',
      value: 'partially_paid'
    },
    {
      viewValue: 'Scheduled',
      value: 'scheduled'
    },
    {
      viewValue: 'Due',
      value: 'due'
    },
    {
      viewValue: 'Paid',
      value: 'paid'
    },
    {
      viewValue: 'Register Now',
      value: 'register_now'
    },
    {
      viewValue: 'Registered',
      value: 'registered'
    }
  ];

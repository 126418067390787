import {Component, ViewChild, ElementRef, HostListener, Input, Output, EventEmitter, AfterViewInit} from '@angular/core';

// import { debounce } from '@app/shared/_decorators/performance.decorators';

@Component({
  selector: 'sticky-section',
  template: `
    <section #staticContainer [class.sticky-container]="sticky">
      <div #stickyContainer [class.fixed-top]="sticky">
        <ng-content></ng-content>
      </div>
    </section>`,
  styleUrls: ['./sticky-section.component.scss']
})
export class StickySectionComponent implements AfterViewInit {
  @ViewChild('staticContainer', { static: true }) staticContainer: ElementRef;
  @ViewChild('stickyContainer', { static: true }) stickyContainer: ElementRef;

  @Output() shouldStickAtTop = new EventEmitter();
  @Input() offsetY: number;

  sticky: boolean;
  staticHeight: string;
  staticWidth: number;
  elementPosition: any;
  vw: number;

  constructor() {
    this.sticky = false;
  }

  getStaticHeight() {
    return this.stickyContainer.nativeElement.children ? this.outerHeight(this.stickyContainer.nativeElement.children[0]) : this.staticContainer.nativeElement.offsetHeight;
  }

  outerHeight(element: any) {
    const height = element.offsetHeight,
      style = window.getComputedStyle(element);

    return ['top', 'bottom']
      .map(side => parseInt(style[`margin-${side}`], 10))
      .reduce((total, side) => total + side, height);
  }

  ngAfterViewInit() {
    // get the initial height of the element that will become sticky
    this.staticHeight = this.getStaticHeight();
    this.staticWidth = this.stickyContainer.nativeElement.offsetWidth;
    // give the sticky block a fixed height container so there is no stutter when position is changed
    this.staticContainer.nativeElement.style.height = this.staticHeight + 'px';
    // store for resizing
    this.vw = window.innerWidth;
  }

  @HostListener('window:resize', ['$event'])
  handleResize() {
    if (!this.sticky) {
      this.vw = window.innerWidth;
      this.staticWidth = this.stickyContainer.nativeElement.offsetWidth;
      this.staticHeight = this.getStaticHeight();
      this.staticContainer.nativeElement.style.height = this.staticHeight + 'px';
      this.stickyContainer.nativeElement.children[0].style.width = this.staticWidth + 'px';
    }
  }

  @HostListener('window:scroll', ['$event'])
  handleScroll() {
    const windowScroll = window.pageYOffset;
    // get the element position so we know when to switch to sticky
    this.elementPosition = this.staticContainer.nativeElement.offsetTop;

    if (!this.sticky && windowScroll >= (this.elementPosition - this.offsetY)) {
      this.stickyContainer.nativeElement.children[0].classList.add('sticky-section');
      this.staticWidth = this.stickyContainer.nativeElement.offsetWidth;
      this.stickyContainer.nativeElement.children[0].style.width = this.staticWidth + 'px';
      this.stickyContainer.nativeElement.children[0].style.width = '100%';
      // offset the the position of the sticky container inline with the param
      this.stickyContainer.nativeElement.style.top = this.offsetY + 'px';
      this.sticky = true;

    } else if (this.sticky && windowScroll < (this.elementPosition - this.offsetY)) {
      this.stickyContainer.nativeElement.style.top = '0';
      this.stickyContainer.nativeElement.children[0].classList.remove('sticky-section');
      // We'll know if the window has been resized when sticky here...
      if (this.vw !== window.innerWidth) {
        // Delay to allow the DOM to update
        setTimeout(() => {
          this.staticWidth = this.stickyContainer.nativeElement.offsetWidth;
          this.stickyContainer.nativeElement.children[0].style.width = this.staticWidth + 'px';
          this.staticHeight = this.getStaticHeight();
          this.staticContainer.nativeElement.style.height = this.staticHeight + 'px';
        }, 10);
        this.vw = window.innerWidth;
      } else {
        setTimeout(() => {
          this.staticWidth = this.stickyContainer.nativeElement.offsetWidth;
          this.stickyContainer.nativeElement.children[0].style.width = this.staticWidth + 'px';
        }, 10);
      }
      this.sticky = false;
    }
    this.shouldStickAtTop.emit(this.sticky);
  }
}

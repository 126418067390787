import {selector} from 'rxjs-compat/operator/publish';

type SelectType = Array<{ value: number | string, viewValue: string }>;


export const TENANT_FIND_100_200 = 'tenant_find_100_200';
export const SELF_MANAGE_100_200 = 'self_manage_100_200';
export const FULLY_MANAGED_200_6 = 'fully_managed_200_6';

export const RENT_STATUS = {
  PAID: 'paid',
  WAIVED: 'waived',
  DUE: 'due',
  OVERDUE: 'overdue',
  SCHEDULED: 'scheduled',
  NOTIFIED_PAID: 'notified_paid',
  RECEIVED: 'received',
  PARTIALLY_PAID: 'partially_paid',
  PARTIALLY_RECEIVED: 'partially_received'
};

export enum MANAGER_ID {
  RENGEN = 'INqWol5EZrgfaouGZMuFqyktrpu1',
  BUNK_URBAN_CREATION = 'R4EGdbwhhlWwF6KyKwmTBTxRXey1',
  DORIAN_HARDACRE_STIRLING_STUDENT = 'LKkceMunJFYY8yBNoSvCJMrcyZf1',
}

export const PROPERTY_MANAGER_IDS = [MANAGER_ID.RENGEN, MANAGER_ID.DORIAN_HARDACRE_STIRLING_STUDENT, MANAGER_ID.BUNK_URBAN_CREATION];

export const DEPOSIT_NUMBER_WEEKS: SelectType = [
  { value: 5, viewValue: '5 weeks' },
  { value: 4, viewValue: '4 weeks' },
  { value: 3, viewValue: '3 weeks' },
  { value: 2, viewValue: '2 weeks' },
  { value: 1, viewValue: '1 week' },
];

export const TENANCY_MONTHS: SelectType = [
  { value: '6-months', viewValue: '6 Months' },
  { value: '9-months', viewValue: '9 Months' },
  { value: '12-months', viewValue: '12 Months' },
  { value: '18-months', viewValue: '18 Months' },
  { value: '24-months', viewValue: '24 Months +' },
];

export const RENT_FREQUENCY: SelectType = [
  { value: 1, viewValue: 'Monthly' },
  { value: 3, viewValue: 'Quarterly' }
];

export const APARTMENT_TYPE: SelectType = [
  { value: '1_bed', viewValue: '1 Bed' },
  { value: '2_bed', viewValue: '2 Bed' },
  { value: 'duplex', viewValue: 'Duplex' },
  { value: 'studio', viewValue: 'Studio'},
  { value: 'twodio', viewValue: 'Twodio'}
];

export const BANDS: SelectType = [
  { viewValue: 'A', value: 'a' },
  { viewValue: 'B', value: 'b' },
  { viewValue: 'C', value: 'c' },
  { viewValue: 'D', value: 'd' },
  { viewValue: 'E', value: 'e' },
  { viewValue: 'F', value: 'f' },
  { viewValue: 'G', value: 'g' },
  { viewValue: 'H', value: 'h' },
  { viewValue: 'I', value: 'i' },
]

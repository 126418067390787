import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SkeletonLoadingComponent } from './skeleton-loading.component';

@NgModule({
  imports: [
    CommonModule
  ],
  exports: [SkeletonLoadingComponent],
  declarations: [SkeletonLoadingComponent],
  providers: [],
})
export class SkeletonLoadingModule { }

import { Component, Input } from '@angular/core';
import * as PAYMENT_CONSTANTS from '../../payments.constants';
import { TypesService, UserService } from '@app/services';
import { MathService } from '@app/services/math.service';
import { combineLatest, Observable, of } from 'rxjs';
import { catchError, debounceTime, flatMap, map, startWith, switchMap, tap } from 'rxjs/operators';
import * as moment from 'moment';
import { AbstractControl, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { ActivatedRoute, Router } from '@angular/router';
import { RENT_STATUS } from '@app/core-landlord/core-landlord.constants';
import { Landlord } from '@env/routing';
import { AuthService } from '@app/core/services';
import { TitleCasePipe } from '@angular/common';
import { environment } from '@env/environment';
import algoliasearch from 'algoliasearch';
import { from } from 'rxjs/internal/observable/from';
import { CAMPUS_STRUCTURE, CAMPUS_TYPES, CompanyName } from '@app/shared/shared.constants';
import * as _ from 'lodash';

@Component({
  selector: 'payments-overview',
  templateUrl: './payments-overview.component.html',
  styleUrls: ['./payments-overview.component.scss']
})
export class PaymentsOverviewComponent {

  @Input() algolia_filter = '';
   env;
   indexName;
   algoliaIndex;
   searchClientKey$: Observable<any>;
   isAdmin$: Observable<any>;
   combinedPaymentsAndfilters$: Observable<any>;
   dateToFrom: BehaviorSubject<any> = new BehaviorSubject<any>({date_to: null, date_from: null});
   pagination: BehaviorSubject<any> = new BehaviorSubject<any>(null);
   searchStr$: Observable<any>;
   dateFilter$: Observable<any>;
   typeFilter$: Observable<any>;
   sortFilter$: Observable<any>;
   statusFilter$: Observable<string>;
   campusFilter$: Observable<string> = of(null);
   buildingFilter$: Observable<string> = of(null);
   defaultCampusValue:string;
   defaultBuildingValue:string;
   customDateFormGroup: FormGroup;

   dateTitle = PAYMENT_CONSTANTS.DATE_FILTER_TYPES[0].viewValue;
   RENT_STATUS = RENT_STATUS;
   dateFilterType = PAYMENT_CONSTANTS.DATE_FILTER_TYPES;
   paymentFilters: FormGroup;
   paymentsFilterControls: Partial<{
     campus: AbstractControl ,
     building: AbstractControl ,
     date: AbstractControl ,
     sort: AbstractControl ,
     type: AbstractControl,
     search: AbstractControl ,
     status: AbstractControl }>;

    startUpValueMap:{
      date:string,
      type:string,
      sort:string,
      search:string,
      status:string,
      campus?:string,
      building?:string
    } = {
      date: 'all',
      type: PAYMENT_CONSTANTS.PAYMENT_TYPE_FILTER[0].value,
      sort: PAYMENT_CONSTANTS.SORT_FILTER_TYPES[0].value,
      search: '',
      status: PAYMENT_CONSTANTS.PAYMENT_STATUS_FILTER[0].value
    }
   showMore = false;
   expenseTypes$: Observable<any>;
   paymentTypes$: Observable<any>;
   showFilters = false;
   pageNumber = 0;
   conditionalFields = CompanyName.isCampusKey ? ['building', 'campus'] : []
   displayedColumns: string[] = ['momentDueDate', 'propertyAddress',...this.conditionalFields, 'tenantName', 'paymentType', 'category', 'amount', 'amountOutstanding', 'timestampPaid', 'status'];
   columnsToDisplay = PAYMENT_CONSTANTS.COLUMNS_TO_DISPLAY;
   dref;
   allFilterTypes:{
     status: {value:string,viewValue:string}[],
     sort: {value:string,viewValue:string}[],
     type: {value:string,viewValue:string}[],
     campus?: {value:string,viewValue:string}[],
     building?: {value:string,viewValue:string}[],
   } = {
    status: PAYMENT_CONSTANTS.PAYMENT_STATUS_FILTER,
    sort: PAYMENT_CONSTANTS.SORT_FILTER_TYPES,
    type: PAYMENT_CONSTANTS.PAYMENT_TYPE_FILTER
  }

  constructor(
              private _auth: AuthService,
              private _types: TypesService,
              public _user: UserService,
              private _formBuilder: FormBuilder,
              public _titlecasePipe: TitleCasePipe,
              private _router: Router,
              private _route: ActivatedRoute,
              private _math: MathService) {

    const {appId, apiKey} = environment.algolia;
    const startUpFilters:{
      date: string,
      type: string,
      sort: string,
      search: string,
      campus?: string,
      building?: string,
      status: string
    }  = {
      date: null,
      type: null,
      sort: null,
      search: null,
      status: null
    }

    if(CompanyName.isCampusKey){
      this.allFilterTypes.campus = CAMPUS_TYPES.filter((type:{value:string,viewValue:string,teamIds:string[]})=>{
        if(type.value==='all')return true;
        const CampusExistInCurrentTeam = type.teamIds.some(id => this._user.userReadOnly.team_ids.includes(id));
        return CampusExistInCurrentTeam
      });
      this.defaultCampusValue = this.allFilterTypes['campus'][0].value;
      this.setBuildingsFilter();
      this.defaultBuildingValue = this.allFilterTypes['building'][0].value;
      this.startUpValueMap.campus = this.defaultCampusValue;
      this.startUpValueMap.building= this.defaultBuildingValue;
      startUpFilters.campus = null;
      startUpFilters.building = null;
      }
    for (const [key,value] of Object.entries(this.startUpValueMap)) {
      startUpFilters[key] = this._route.snapshot.queryParamMap.get(key) || value;
    }

    this.paymentFilters = this.initFormGroup(startUpFilters);
    this.paymentsFilterControls = this.paymentFilters.controls;

    if(CompanyName.isCampusKey){
      this.campusFilter$ =  this.paymentsFilterControls.campus.valueChanges.pipe(startWith(startUpFilters.campus));
      this.buildingFilter$ = this.paymentsFilterControls.building.valueChanges.pipe(startWith(startUpFilters.building));
    }
    const routeDateRange = {
      date_from: this._route.snapshot.queryParamMap.get('dateFrom'),
      date_to: this._route.snapshot.queryParamMap.get('dateTo')
    };

    this.dateToFrom.next(routeDateRange);

    this.customDateFormGroup = this.initCustomDateForm(routeDateRange);

    this.dateFilter$ = this.paymentsFilterControls.date.valueChanges.pipe(startWith(startUpFilters.date));
    this.typeFilter$ = this.paymentsFilterControls.type.valueChanges.pipe(startWith(startUpFilters.type));
    this.sortFilter$ = this.paymentsFilterControls.sort.valueChanges.pipe(startWith(startUpFilters.sort));
    this.searchStr$ = this.paymentsFilterControls.search.valueChanges.pipe(startWith(startUpFilters.search));
    this.statusFilter$ = this.paymentsFilterControls.status.valueChanges.pipe(startWith(startUpFilters.status));

    this.isAdmin$ = this._auth.getCurrentUserCustomClaims();
    this.pagination.next({page: 0, hitsPerPage: 10});

    this.searchClientKey$ = this._auth.createAlgoliaSecureSearchKey(apiKey, 'payments');

    this.expenseTypes$ = this._types.getTypes('expenses').pipe
    (
      switchMap(res=> this._types.getViewValuePairs(res))
      );

    this.paymentTypes$ = this._types.getTypes('payments').pipe
    (
      switchMap(res=> this._types.getViewValuePairs(res))
     );



    this.combinedPaymentsAndfilters$ = combineLatest([this.searchStr$, this.pagination.asObservable(), this.dateFilter$, this.dateToFrom.asObservable(), this.typeFilter$, this.sortFilter$, this.statusFilter$, this.campusFilter$, this.buildingFilter$, this.isAdmin$, this.searchClientKey$]).pipe(
      debounceTime(200),
      tap(([searchStr, pagination, dateFilter, dateToFrom, type, sort, status, campus, building, isAdmin, searchClientKey]) => {
        const currentParams = {
          type:this._route.snapshot.queryParamMap.get('type'),
          sort:this._route.snapshot.queryParamMap.get('sort'),
          status:this._route.snapshot.queryParamMap.get('status'),
          search:this._route.snapshot.queryParamMap.get('search'),
          date:this._route.snapshot.queryParamMap.get('date'),
          dateFrom:this._route.snapshot.queryParamMap.get('dateFrom'),
          dateTo:this._route.snapshot.queryParamMap.get('dateTo'),
          page:this._route.snapshot.queryParamMap.get('page'),
          hits:this._route.snapshot.queryParamMap.get('hits')
        }
        const updatedParams = {date: dateFilter, dateFrom: dateToFrom.date_from, dateTo: dateToFrom.date_to, type, sort, search: searchStr, status, page:pagination.page, hits:pagination.hitsPerPage }
        if(CompanyName.isCampusKey){
          currentParams['campus'] = this._route.snapshot.queryParamMap.get('campus');
          currentParams['building'] = this._route.snapshot.queryParamMap.get('building');
          updatedParams['campus'] = campus;
          updatedParams['building'] = building;
        }
        if (!_.isEqual(currentParams, updatedParams)) {
          this._router.navigate(['.'], {
            queryParams: { date: dateFilter, dateFrom: dateToFrom.date_from, dateTo: dateToFrom.date_to, type, sort, search: searchStr, status, page: pagination.page, campus, building, hits: pagination.hitsPerPage },
            relativeTo: this._route,
            replaceUrl: true
          });

        }
      }),
      flatMap(([searchStr, pagination, dateFilter, dateToFrom, type, sort, status, campus, building, isAdmin, searchClientKey]: any) => {
        this.indexName = this.getUserAlgoliaIndex(sort);

        const searchClient = algoliasearch(appId, apiKey);
        this.algoliaIndex = searchClient.initIndex(this.indexName.name);
        return this.getAlgoliaData(searchStr, pagination, dateFilter, dateToFrom, type, sort, status, campus, building, isAdmin, this.algoliaIndex);
      }),
      map((res: any) => (res === 'No index') ? {combined: []} : res),
    );
  }

  getUserAlgoliaIndex(sort: string) {
    const USER_ALGOLIA_INDEX = [
      {
        name: `${environment.algolia.env}-payments`,
        filter: 'dueDate'
      },
      {
        name: `${environment.algolia.env}-payments-due_date-asc`,
        filter: 'asc(algolia_timestamp)'
      },
      {
        name: `${environment.algolia.env}-payments-address-desc`,
        filter: 'address_z_a'
      },
      {
        name:  `${environment.algolia.env}-payments-address-asc`,
        filter: 'address_a_z'
      },
      {
        name: `${environment.algolia.env}-payments-amount-desc`,
        filter: 'amount_high_low'
      },
      {
        name: `${environment.algolia.env}-payments-amount-asc`,
        filter: 'amount_low_high'
      },
    ];
    return USER_ALGOLIA_INDEX.find((indexData: any) => indexData.filter === sort);
  }

  get date_from(): FormControl {
    return this.customDateFormGroup.get('date_from') as FormControl;
  }

  get date_to(): FormControl {
    return this.customDateFormGroup.get('date_to') as FormControl;
  }

  initCustomDateForm(routeDateRange: any) {
    const date_from = moment(routeDateRange.date_from, 'DD-MM-YYYY').format('MM/DD/YYYY');
    const date_to = moment(routeDateRange.date_to, 'DD-MM-YYYY').format('MM/DD/YYYY');
    return this._formBuilder.group({
      date_from: [date_from],
      date_to:  [date_to]
    });
  }

  getAlgoliaData(query: string, pagination: any, dateFilter: any, dateToFrom: any, type: any, sort: any, status: string, campus: string, building:string, isAdmin: any, algoliaIndex: any): Observable<any> {

    const hitsPerPage = pagination.hitsPerPage;
    const page = pagination.page === this.pageNumber ? 0 : pagination.page
    this.pageNumber = pagination.page;

    let filters = `payments${this.algolia_filter}`;
    if (type !== 'all') {
      filters += ` AND payment_type:${type}`;
    }
    if (dateFilter !== 'all') {
      filters += this.filterByDate(dateFilter, dateToFrom);
    }
    if(CompanyName.isCampusKey){
      if(campus !== this.defaultCampusValue) {
        filters += ` AND campus:"${campus}"`
      }
      if(building !== this.defaultBuildingValue){
        filters += ` AND building:"${building}"`
      }
    }
    if(status !== 'all'){
      filters += this.filterByStatus(status)
    }
    return from(algoliaIndex.search({
      filters,
      hitsPerPage,
      page,
      ...query && { query }
    })).pipe(
      map((res: any) => {
        const totalHits = res.nbHits;
        this.algoliaIndex.setSettings({
          paginationLimitedTo: totalHits
        });
        this.showMore = (res.nbPages > 1);
        const combined = this.createDataStructure(res.hits);
        this.dateTitle = this.getDateViewValue(dateFilter);
        return {
          combined,
          isAdmin,
          totalHits,
          page,
          hitsPerPage,
        };
      }),
      catchError((res: any) => of('No index')),
    );
  }

  createDataStructure(payments: [any]) {
    return payments.map((payment: any) => {
      const item = {
        tenantImage: payment.tenant_image,
        tenantName: payment.tenant_name,
        tenantFullName: payment.tenant_name_full,
        uid: payment.uid,
        tenantCode: payment.type !== 'expense' ? (payment.tenant_code) ? payment.tenant_code : 'n/a' : payment.expense_reference_code ? payment.expense_reference_code : 'n/a' ,
        propertyAddress: payment.property_address,
        propertyId: payment.property_id,
        isHmo: payment.is_hmo,
        dueDate: payment.due_date,
        momentDueDate: moment(payment.due_date).format('DD MMM YYYY'),
        amount: this._math.roundToTwoDP(payment.amount),
        paymentType: payment.payment_type ? this.getPaymentType(payment.payment_type) : payment.type,
        status: payment.status,
        month: moment(payment.month + 1, 'MM').format('MMM'),
        id: payment.id,
        payout_account: (payment.payout_account) ? payment.payout_account : null,
        timestampPaid: (payment.timestamp_paid) ? moment(payment.timestamp_paid).format('DD MMM  hh:mm a') : null,
        tenancyId: payment.tenancy_id,
        amountOutstanding: payment.amount_outstanding ? payment.amount_outstanding : null,
        notes: (payment.notes) ? payment.notes : '',
        is_being_held: payment.is_being_held,
        transactionIds: payment.transaction_ids ? payment.transaction_ids : null,
        category: payment.category,
        objectID: payment.objectID,
        campus: payment.property_campus || '',
        building: payment.property_building || '',
        custom_fields: payment.custom_fields || null,
        team_id: payment.team_id || null
      };

      return item;
    });
  }

  getPaymentType(payment_type: string) {
    switch (payment_type) {
      case 'holding_deposit':
        return 'holding fee';
      case 'car_parking':
        return 'car parking';
      default:
        return payment_type;
    }
  }

  filterByDate(value: string, dateToFrom: any) {
    switch (value) {
      case 'current_month':
        return ` AND algolia_timestamp:${moment().startOf('month').unix()} TO ${moment().endOf('month').unix()}`;
      case 'next_month':
        return ` AND algolia_timestamp:${moment().add(1, 'months').startOf('month').unix()} TO ${moment().add(1, 'months').endOf('month').unix()}`;
      case 'previous_month':
        return ` AND algolia_timestamp:${moment().subtract(1, 'months').startOf('month').unix()} TO ${moment().subtract(1, 'months').endOf('month').unix()}`;
      case 'custom_date':
        return (dateToFrom.date_from && dateToFrom.date_to)
          ? this.getPaymentsBetweenDates(dateToFrom)
          : ``;
      default:
        return ``;
    }
  }

  filterByStatus(status: string){
    if(status === 'overdue'){
      const currentDate = new Date();
      const formattedDate = Math.floor(currentDate.setDate(currentDate.getDate()) / 1000);
      return ` AND algolia_timestamp < ${formattedDate} AND (status.value:overdue OR status.value:partially_paid)`
    }
    else{
      return ` AND status.value:${status}`
    }
  }

  getPaymentsBetweenDates(dateToFrom: any) {
    const formatDateFrom = moment(dateToFrom.date_from, 'DD-MM-YYYY').format('YYYY-MM-DD');
    const formatDateTo = moment(dateToFrom.date_to, 'DD-MM-YYYY').format('YYYY-MM-DD');
    return ` AND algolia_timestamp:${new Date(formatDateFrom).getTime() / 1000} TO ${new Date(formatDateTo).getTime() / 1000}`;
  }

  dateHasChanged() {
    if (this.date_to.value && this.date_from.value) {
      // if both are set, then trigger the observable update to chnage routing url and filter by these dates
      this.dateToFrom.next(
        {
          date_from: moment(this.date_from.value).format('DD-MM-YYYY'),
          date_to: moment(this.date_to.value).format('DD-MM-YYYY')
        });

      this.dateTitle = `${moment(this.date_from.value).format('DD-MM-YYYY')} - ${ moment(this.date_to.value).format('DD-MM-YYYY')}`;

    }
  }


  getViewValue(dateFilterType: any, value: any) {
    const arrayValue = dateFilterType.find((type: any) => type.value === value);
    return arrayValue.viewValue;
  }

  getDateViewValue(value: any) {
    const nextMonth = new Date(new Date().setMonth(new Date().getMonth() + 1));
    const previousMonth = new Date(new Date().setMonth(new Date().getMonth() - 1));

    switch (value) {
      case 'current_month':
         return moment(new Date()).format('MMMM YYYY');
      case 'next_month':
        return  moment(nextMonth).format('MMMM YYYY');
      case 'previous_month':
        return  moment(previousMonth).format('MMMM YYYY');
      case 'all':
        return PAYMENT_CONSTANTS.DATE_FILTER_TYPES[0].viewValue;
      case 'custom_date':
        return this.date_from.value && this.date_to.value ? `${moment(this.date_from.value).format('DD-MM-YYYY')} - ${ moment(this.date_to.value).format('DD-MM-YYYY')}` : 'Custom date';
    }
  }

  updateFormValue(value: string) {
    this.paymentsFilterControls.date.setValue(value);
    if (value !== 'custom_date') {
      this.customDateFormGroup.reset();
      this.dateToFrom.next({
        date_from: null,
        date_to: null
      });
    }
  }


  goToProperty(event: any) {
    this._router.navigate(['/' + Landlord.base, Landlord.properties.base, Landlord.properties.overview, event]);
  }

  updatePagination(event: any) {
    this.pagination.next(event);
  }

  loadMore() {
    const hits = this.pagination.value.hitsPerPage;
    this.pagination.next({hitsPerPage: hits + 10, page: 0});
  }

  closeDialog(event: any) {
    if (event) {
      this.dref.close();
    }
  }

  updateBehaviourSubjects(event: any) {
    Object.entries(this.paymentsFilterControls).forEach(([key,filterControl])=>{
      if(key==='date') return;
      const newFilterValue = event[key];
      const currentFilterValue = filterControl.value;
      const filterValueChanged = currentFilterValue !== newFilterValue;
      if(filterValueChanged){
        filterControl.patchValue(newFilterValue);
        if(key==='campus'){
          this.setBuildingsFilter(newFilterValue);
          event.building = this.defaultBuildingValue;

        }
      }
    })
    }

    initFormGroup(startUpFilters:
      {
      date: string;
      type: string;
      sort: string;
      search: string;
      status: string;
      campus?: string;
      building?: string;
  }
  ): FormGroup {
      const form = {
        date: [startUpFilters.date],
        sort: [startUpFilters.sort],
        type: [startUpFilters.type],
        search: [startUpFilters.search],
        status: [startUpFilters.status],
      }
      if(startUpFilters.campus){
        form['campus'] = startUpFilters.campus;
      }
      if(startUpFilters.building){
        form['building'] = startUpFilters.building;
      }
      return this._formBuilder.group(form);
    }

    setBuildingsFilter(selectedCampus='all'){
      if(selectedCampus==='all'){
        const buildings = this.allFilterTypes['campus'].map((campus:{value:string,viewValue:string,teamIds:string[]})=>{
          return CAMPUS_STRUCTURE[campus.value]
        });
        this.allFilterTypes.building = _.flatten(buildings)
      }
      else{
        this.paymentsFilterControls.building.setValue(this.defaultBuildingValue);
        this.allFilterTypes['building'] = _.flatten([CAMPUS_STRUCTURE[this.defaultCampusValue],CAMPUS_STRUCTURE[selectedCampus]]);
      }
    }
}

import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'portfolio-skeleton',
  template: `
    <div class="flex flex-row justify-between">
      <div *ngFor="let i of [1,2,3,4]">
        <div class="card-base card-base__portfolio loading-animation--header">
            <div class="margin-top--sm middle loading-animation"></div>
            <div class="margin-top--sm small loading-animation"></div>
        </div>
      </div>
    </div>
  `,
  styleUrls: ['../skeleton-loading.component.scss']
})

export class PortfolioSkeletonComponent implements OnInit {
  constructor() {
  }

  ngOnInit() {
  }
}

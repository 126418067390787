import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ScreenDetectionComponent } from '@app/core/utils/screen-detection/screen-detection.component';

@NgModule({
  declarations: [
    ScreenDetectionComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    ScreenDetectionComponent
  ],
  providers: [],
})
export class UtilsModule { }

import { Directive, HostListener, ElementRef, Input } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';

@Directive({ 
  selector: '[bnkSwitchFormatToLocale]'
})
export class NumberToLocaleStringDirective implements ControlValueAccessor {
  @Input('value') _value;
  private el: HTMLInputElement;

  onChange: any = () => { };
  onTouched: any = () => { };

  constructor(
    private elementRef: ElementRef,
  ) {
    this.el = this.elementRef.nativeElement;
    this.el.type = 'text';
    if (this.el.value) { this.el.value = parseInt(this.el.value).toLocaleString('en-GB'); }
  }

  get value() {
    return this._value;
  }

  set value(val) {
    this._value = val;
    this.onChange(val);
    this.onTouched();
  }

  registerOnChange(fn) {
    this.onChange = fn;
  }

  writeValue(value) {
    if (value) {
      this.value = value;
    }
  }

  registerOnTouched(fn) {
    this.onTouched = fn;
  }

  @HostListener('focus', ['$event.target.value'])
  onFocus(value) {
    // Known issues with FireFox and OnFocus and onBlur events not bubbling properly - so we're putting outside of the loop
    setTimeout(() => {
      this.el.type = 'number';
      if (value) { this.el.value = value.toString().replace(/,/g, ''); }
    });
  }

  @HostListener('blur', ['$event.target.value'])
  onBlur(value) {
    setTimeout(() => {
      this.el.type = 'text';
      if (value) { this.el.value = parseInt(value).toLocaleString('en-GB'); }
    });
  }
}

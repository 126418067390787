import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { combineLatest } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/fire/auth";
import * as i2 from "@angular/fire/firestore";
export class TenancyPackageService {
    constructor(afAuth, afs) {
        this.afAuth = afAuth;
        this.afs = afs;
        this.getDocTenancyPackageById = (id) => this.tenancyPackageCollection.doc(id);
        this.getDocTenancyPackageByIds = (ids) => ids.map(id => this.getDocTenancyPackageById(id));
        this.getTenancyPackagesByIds = (ids) => combineLatest(this.getDocTenancyPackageByIds(ids).map(ref => ref.valueChanges()));
        this.tenancyPackageCollection = afs.collection('tenancy_packages');
    }
    getPackageById(package_id) {
        return this.tenancyPackageCollection.doc(package_id);
    }
}
TenancyPackageService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TenancyPackageService_Factory() { return new TenancyPackageService(i0.ɵɵinject(i1.AngularFireAuth), i0.ɵɵinject(i2.AngularFirestore)); }, token: TenancyPackageService, providedIn: "root" });

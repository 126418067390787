import {
  Directive, HostListener, Input, OnInit, OnDestroy
} from '@angular/core';
import { Observable } from 'rxjs';
import { tap, take } from 'rxjs/operators';
import { StorageService } from '@app/services';

@Directive({
  selector: '[fileDownload]'
})
export class FileDownloadDirective implements OnInit, OnDestroy {

  @Input() path: string
  url$: Observable<string>

  constructor(
    private _storage: StorageService
  ) { }

  ngOnInit() {
    this.url$ = this._storage.getDownloadURLbyPath(this.path)
  }

  ngOnDestroy() {
  }

  @HostListener('click', ['$event']) onClick() {
    this.url$.pipe(
      take(1),
      tap(url => { window.open(url, '_blank') })
    ).subscribe()
  }
}
/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./tenant-account-step.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/router";
import * as i3 from "./tenant-account-request-viewing-summary.component";
var styles_TenantAccountRequestViewingsSummaryComponent = [i0.styles];
var RenderType_TenantAccountRequestViewingsSummaryComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TenantAccountRequestViewingsSummaryComponent, data: {} });
export { RenderType_TenantAccountRequestViewingsSummaryComponent as RenderType_TenantAccountRequestViewingsSummaryComponent };
export function View_TenantAccountRequestViewingsSummaryComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "jc_sb rc w_100"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "button", [["class", "btn__generic btn--round"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 2).onClick() !== false);
        ad = (pd_0 && ad);
    } if (("click" === en)) {
        var pd_1 = (_co.closeOverlay.emit() !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), i1.ɵdid(2, 16384, null, 0, i2.RouterLink, [i2.Router, i2.ActivatedRoute, [8, null], i1.Renderer2, i1.ElementRef], { routerLink: [0, "routerLink"] }, null), i1.ɵpad(3, 2), (_l()(), i1.ɵted(-1, null, ["Search properties"]))], function (_ck, _v) { var currVal_0 = _ck(_v, 3, 0, "/", "rent-property"); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_TenantAccountRequestViewingsSummaryComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "tenant-account-request-viewings-summary", [], null, null, null, View_TenantAccountRequestViewingsSummaryComponent_0, RenderType_TenantAccountRequestViewingsSummaryComponent)), i1.ɵdid(1, 114688, null, 0, i3.TenantAccountRequestViewingsSummaryComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TenantAccountRequestViewingsSummaryComponentNgFactory = i1.ɵccf("tenant-account-request-viewings-summary", i3.TenantAccountRequestViewingsSummaryComponent, View_TenantAccountRequestViewingsSummaryComponent_Host_0, {}, { callback: "callback", closeOverlay: "closeOverlay" }, []);
export { TenantAccountRequestViewingsSummaryComponentNgFactory as TenantAccountRequestViewingsSummaryComponentNgFactory };

import { Component } from '@angular/core';
import { UserService } from '@app/services';
import { environment } from '@env/environment';
import { ProfileCardComponent } from './profile-card.component';

@Component({
  selector: 'profile-card-applicant',
  templateUrl: './profile-card-applicant.component.html',
  styleUrls: ['./profile-card.component.scss']
})
export class ProfileCardApplicantComponent extends ProfileCardComponent {
  public isCampusKeyTenant = environment.firebaseConfig.projectId === 'client-campus-key' && this._user.userDb.role === 'tenant';
  constructor(
    public _user: UserService,
  ) {
    super();
  }
}

import { Component, Input, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';
import { HelpersService } from '@app/shared/_services/helpers.service';
import { UserService } from '@app/services/user.service';
import { DatesService, UK_FORMAT } from '@app/shared/_services/dates.service';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '@app/core/services';
import { BunkPassportService, SeoService, StorageLocalService, TenancyOfferService, ViewingService } from '@app/services';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatDialog } from '@angular/material';
import { Auth, Shared, Tenant, Viewings } from '@env/routing';
import { BehaviorSubject, Observable, of, Subject } from 'rxjs';
import { finalize, flatMap, map, switchMap, takeUntil } from 'rxjs/operators';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { environment } from '@env/environment';
import * as _ from 'lodash';
import { TRANSLATIONS } from '@app/_constants/translations.constants';
import { PropertyType } from '@app/_constants/property_docs.constants';


@Component({
  selector: 'property-preview',
  templateUrl: './property-preview.component.html',
  styleUrls: ['./property-preview.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },
    { provide: MAT_DATE_FORMATS, useValue: UK_FORMAT }
  ]
})
export class PropertyPreviewComponent implements OnInit, OnDestroy {
  // @todo: define model of following variables
  @Input() property: any;

  private destroy$: Subject<boolean> = new Subject<boolean>();
  public hasRequiredFields$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);

  private offerId: string;
  public routes = { Auth, Tenant };
  public propertyManager$: Observable<any>;
  public minDate = new Date();
  // public currentPassportTier;
  bedrooms$: Observable<any>;
  bedrooms: any[];
  formGroup: FormGroup;
  sections: Array<{ name: string, index: number }>;
  date: { year: number, month: number };
  isDesktop;
  userReadOnly$: Observable<any>;
  public requiredFields$: Observable<any>;
  public managerData$: Observable<any>;

  public dref;
  public viewingRequest = false;
  public showSignUpForm = false;
  public showPopUpSpinner$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public showSpinner$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public showSuccess;
  public signupformGroup: FormGroup;
  public signingSpinner = false;
  public client_data = environment.client_data;
  public TRANSLATIONS = TRANSLATIONS;
  public rent_label_abbreviation = TRANSLATIONS.rent_label_abbreviation;
  public landlord = TRANSLATIONS.landlord;


  @ViewChildren('target')
  target: QueryList<any>;
  display: any = {};

  constructor(
    private readonly afs: AngularFirestore,
    private _formBuilder: FormBuilder,
    private _toastr: ToastrService,
    private _tenancyOffer: TenancyOfferService,
    private _viewings: ViewingService,
    private _activatedRoute: ActivatedRoute,
    public _user: UserService,
    public _dates: DatesService,
    public _auth: AuthService,
    public _router: Router,
    public _helpers: HelpersService,
    public dialog: MatDialog,
    private _passport: BunkPassportService,
    protected _localStorage: StorageLocalService,
    private _seo: SeoService
  ) {
    this.signupformGroup = this.initSignupFormGroup();
    this.formGroup = this.initFormGroup();
  }

  get book_viewing(): FormControl {
    return this.formGroup.get('book_viewing') as FormControl;
  }

  ngOnInit(): void {
    const bedsArray = _.values(this.property.bedrooms);
    const totalRoomsUnAvailable = this.property.is_hmo && this.property.bedrooms && bedsArray.filter(b => !b.is_listed).length ? bedsArray.filter(b => !b.is_listed).length : 0;
    const totalRoomsAvailable = this.property.is_hmo && bedsArray.length - totalRoomsUnAvailable ? bedsArray.length - totalRoomsUnAvailable : 0;
    const totalRoomsUnderOffer = this.property.is_hmo && bedsArray.filter(bed => bed.is_listed && bed.is_under_offer).length ? bedsArray.filter(bed => bed.is_listed && bed.is_under_offer).length : 0;

    this.display = {
      propertyType: this.property.number_bedrooms ?
        this.property.number_bedrooms + ' bedroom ' + PropertyType[this.property.property_type].toLowerCase()
        : PropertyType[this.property.property_type],
      city: this.property.address.city,
      thoroughfare: this.property.address.thoroughfare,
      isHMO: this.property.is_hmo,
      bedrooms: Object.values(this.property.bedrooms),
      isDeleted: this.property.is_deleted,
      isListed: this.property.is_listed,
      listingType: this.property.listing_type ? this.property.listing_type : null,
      listingTitle: this.property.property_title && this.property.property_title.length ? this.property.property_title : null,
      totalBedrooms: this.property.number_bedrooms,
      totalRoomsAvailable: totalRoomsAvailable - totalRoomsUnderOffer,
      totalRooms: totalRoomsAvailable

    };

    const metaDescription = `Flats & Houses to Rent in ${this.display.city} - Find properties - the award-winning online letting agent and property management platform`;
    const metaImg = this.property.property_photos && this.property.property_photos[0] && this.property.property_photos[0].image_full_path ? this.property.property_photos[0].image_full_path : 'https://firebasestorage.googleapis.com/v0/b/bunkapptest.appspot.com/o/meta-img%2Flink-sharing-meta-image-joint.jpg?alt=media&token=0d354960-ea59-43af-b80b-d24b898dca3e';
    const metaTitle = this.display.propertyType + ' in ' + this.display.city + ' ';

    // this._seo.updateMeta(metaDescription, PROPERTY_SEARCH_META.KEYWORDS, metaTitle, metaImg);

    this.display.totalRoomsUnderOffer = this.display.isHMO && this.display.bedrooms.filter(bed => bed.is_listed && bed.is_under_offer).length
      ? this.display.bedrooms.filter(bed => bed.is_listed && bed.is_under_offer).length : 0;


    this.managerData$ = this.property && this.property.team_id ? this._user.getTeamPublicById(this.property.team_id).pipe(
      map(data => {
        if (!(data && data.office_hours)) {
          return null;
        }
        return {
          ...data,
          timings: data.office_hours.map(res => {
            return {
              opens: res.opens ? this.formatOfficeTime(res.opens) : null,
              day: res.day.substring(0, 3),
              closes: res.closes ? this.formatOfficeTime(res.closes) : null,
            };
          })
        };
      }),
      map((team: any) => {
        if (team && team.team_data) {
          const image_small_url = team.team_data.brand_assets && team.team_data.brand_assets.banner_aside && team.team_data.brand_assets.banner_aside.image_small_url;
          const image_large_url = team.team_data.brand_assets && team.team_data.brand_assets.banner_aside && team.team_data.brand_assets.banner_aside.image_large_url;
          return {
            companyName: team.team_data.company_name,
            companyNameShort: team.team_data.company_name_short,
            contactNumber: team.team_data.contact_number,
            contactEmail: team.team_data.contact_email,
            bannerAsideSm: image_small_url,
            bannerAsideLg: image_large_url,
            isTeamData: true,
            timings: team.timings ? this.groupOfficeTime(team.timings) : []
          };
        } else {
          return null;
        }
      }))
      : this._user.getUserById(this.property.landlord_uid).valueChanges().pipe(
        map(landlord => {
          if (landlord.property_manager_data) {
            const { image_small_url, image_large_url } = landlord.property_manager_data.brand_assets.banner_aside;
            return {
              companyName: landlord.property_manager_data.company_name,
              companyNameShort: landlord.property_manager_data.company_name_short,
              contactNumber: landlord.property_manager_data.contact_number,
              bannerAsideSm: image_small_url,
              bannerAsideLg: image_large_url,
              isTeamData: false
            };
          } else {
            return null;
          }
        }));

    this._activatedRoute.queryParams.subscribe(params => {
      this.showSuccess = this._activatedRoute.snapshot.queryParamMap.get('success');
    });


    this.requiredFields$ = this._passport.retrieveReferenceRequirements(this.property.property_id, 'viewing').pipe(
      map((fields: any) => {
        (fields.requirement_item) ? this.hasRequiredFields$.next(true) : this.hasRequiredFields$.next(false);
        return fields;
      })
    );
  }

  formatOfficeTime(time, givenFormat = 'HH:mm', convertWithMinute = 'h:mm a', convertWithoutMinute = 'ha') {
    const convertTo = moment(time, givenFormat).minutes() ? convertWithMinute : convertWithoutMinute;
    return moment(time, givenFormat).format(convertTo);
  }

  scrollTo(indexEl) {
    this._helpers.scrollTo(this.target, indexEl);
  }

  likeProperty(id: string) {
    if (this._auth.authenticated) {
      this._user.updateUserLikedProperties(id)
        .subscribe(
          () => this._toastr.success('Property added to your favourites!'),
          error => this._toastr.error('An error as occurred')
        );
    }
  }

  groupOfficeTime(array) {
    // input: array of object having keys: opens closes and day (strings)
    // if consecutive elements have same opens and closes, they are combined
    // output, array after grouping
    const final = [];
    for (let i = 0; i < array.length; i++) {
      let temp = array[i];
      for (let j = i + 1; j < array.length; j++) {
        if (!(array[i].opens === array[j].opens && array[i].closes === array[j].closes)) {
          break;
        }
        i++;
        temp = { ...temp, day: temp.day + ' - ' + array[j].day };

      }
      const dayName = temp.day.split('-');
      temp.day = dayName.length > 2 ? [dayName.shift(), dayName.pop()].join(' - ') : temp.day;
      final.push(temp);
    }
    return final;
  }

  // Authenticated users only...
  handleTenantOffer(landlordUid: string, bedroomId: string = null): void {
    if (this.display.listingType === 'block') {
      this._router.navigate([Shared.bedroom], { relativeTo: this._activatedRoute });
    } else {
      this._tenancyOffer.getTenancyOfferByLeadTenantUid(this.property.property_id, bedroomId).pipe(
        takeUntil(this.destroy$),
        flatMap((offer: any[]) => {
          if (offer.length >= 1) {
            this._router.navigate(['/' + Tenant.base, Tenant.make_an_offer.base, offer[0].tenancy_offer_id, Tenant.make_an_offer.rent]);
            return of(null);
          } else {
            console.log(this.property, 'HEYY');
            return this._tenancyOffer.createTenancyOffer(this.property, landlordUid, bedroomId, this.property.team_id);
          }
        }
        )
      ).subscribe((offerId: string) => {
        // We only reroute with a tenancy_offer_id
        if (offerId) {
          this._router.navigate(['/' + Tenant.base, Tenant.make_an_offer.base, offerId, Tenant.make_an_offer.rent]);
        }
      });
    }
  }

  // Anonymous users...
  handleAnonymousOffer(bedroomId = null): void {
    this.viewingRequest = false;
    // this.showSignUpForm = false;
    (!bedroomId) ?
      this._router.navigate(['.'], { queryParams: { property_id: this.property.property_id }, relativeTo: this._activatedRoute, replaceUrl: true })
      : this._router.navigate(['.'], { queryParams: { property_id: this.property.property_id, bedroom_id: bedroomId }, relativeTo: this._activatedRoute, replaceUrl: true });

  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  goBackToSearch() {
    this._router.navigate(['rent-property']);
  }

  logIn() {
    this.dref.close();
    this._router.navigate(['/signin'], { queryParams: { property_id: this.property.property_id } });
  }

  nextStep() {
    if (this.hasRequiredFields$.value && this.book_viewing.valid) {
      return this.bookAViewing();
    }

    if (this.book_viewing.valid && !this._auth.authenticated && !this.showSignUpForm) {
      return this.showSignUpForm = true;
    }

  }


  requestViewing() {

    if (this.formGroup.valid) {
      const viewing = this.formGroup.get('book_viewing').value;
      const user = this.formGroup.get('user_account').value;
      const viewings_id = this.afs.createId();

      this.showSpinner$.next(true);
      this.formGroup.disable();

      const userDoc = user ? {
        ...user,
        phone_number: user.signup_phone_number_with_area.phone_number,
        country_code: user.signup_phone_number_with_area.area_code,
        first_name: user.name.first_name,
        last_name: user.name.last_name
      } : null;

      this.signingSpinner = true;

      return ((!this._auth.authenticated) ?
        this._auth.createUserWithEmailAndPassword({
          email: userDoc.email,
          password: userDoc.password
        }) : of(null)).pipe(
          flatMap((auth: any) => (auth) ? this._auth.createUserDocuments(auth.user.uid, userDoc) : of(null)),
          switchMap(() => this._auth.authenticated && this._user.userDb
            ? this._viewings.createViewingDocument(this.property.property_id, this.property.landlord_uid, viewing, this.property.team_id, viewings_id) : of([]).pipe(
              finalize(() => {
                this.showSpinner$.next(false);
                this.signingSpinner = false;
                this.showSignUpForm = false;
              }))
          )).subscribe(() => {
            if (!this._user.userDb) {
              return this._router.navigate(['/', Shared.no_user]);
            }
            this.showSpinner$.next(false);
            this.signingSpinner = false;

            this._router.navigate(['.'], { queryParams: { success: viewings_id }, relativeTo: this._activatedRoute, queryParamsHandling: 'merge' });
            this._toastr.success('Viewing successfully requested.');
          },
            // Handle Errors
            err => {
              this.formGroup.enable();
              if (err.error) {
                this._toastr.error(err.error, 'Oops!', {
                  timeOut: 5000
                });
              }
            });
    }

  }

  createAccountAndMakeOffer(form: any, landlordUid: string) {
    const user = form.signup;
    const bedroom_id = this._activatedRoute.snapshot.queryParamMap.get('bedroom_id');

    if (this.signupformGroup.valid) {

      this.showPopUpSpinner$.next(true);
      const userDoc = {
        ...user,
        phone_number: user.signup_phone_number_with_area.phone_number,
        country_code: user.signup_phone_number_with_area.area_code,
        first_name: user.name.first_name,
        last_name: user.name.last_name
      };
      this.signupformGroup.disable();
      console.log(this.property, 'EHY');
      this._auth.createUserWithEmailAndPassword({
        email: userDoc.email,
        password: userDoc.password
      }).pipe(flatMap((auth: any) => this._auth.createUserDocuments(auth.user.uid, userDoc)),
        switchMap(() => (!this.viewingRequest && this._auth.authenticated && this._user.userDb) ? this._tenancyOffer.createTenancyOffer(this.property, landlordUid, bedroom_id, this.property.team_id) : of(null)
        )).subscribe((offerId: string) => {
          if (!this._user.userDb) {
            return this._router.navigate(['/', Shared.no_user]);
          }

          if (this.viewingRequest) {
            this._router.navigate([Viewings.base, Viewings.manage.book], { queryParams: { property_id: this.property.property_id } });
          }

          // if (this.display.listingType === 'block') {
          //   this._router.navigate([Shared.bedroom]);
          // }

          if (offerId) {
            this._router.navigate(['/' + Tenant.base, Tenant.make_an_offer.base, offerId, Tenant.make_an_offer.rent]);
          }

          this.showPopUpSpinner$.next(false);
          this.dref.close();
        },
          err => {
            this.signupformGroup.enable();
            this.showPopUpSpinner$.next(false);
            if (err.error) {
              this._toastr.error(err.error, 'Oops!', {
                timeOut: 5000
              });
            }
          });
    }

  }

  initSignupFormGroup() {
    return this._formBuilder.group({
      signup: [null]
    });
  }

  initFormGroup() {
    return this._formBuilder.group({
      book_viewing: [null],
      user_account: [null]
    });
  }

  getMomentDate(date: any) {
    return moment(date).format('dddd Do MMM YYYY');
  }

  viewAllPropsByManager() {
    this._router.navigate([Tenant.rent_property], { queryParams: { location: 'all', manager: this.property.manager_uid } });
  }



  bookAViewing() {
    this.viewingRequest = true;
    this._localStorage.saveStore('BOOK_VIEWING', this.book_viewing.value);
    if (!this.isDesktop || this.isDesktop && this.hasRequiredFields$.value && this._auth.authenticated) {
      this._router.navigate([Viewings.base, Viewings.manage.book], { queryParams: { property_id: this.property.property_id } });
    }
  }

  titleToDisplay() {
    return this.display.listingTitle || this.display.thoroughfare || this.display.propertyType;
  }
  rentFrequency(intervalCount) {
    if (intervalCount === 1) {
      return this.rent_label_abbreviation;
    } else if (intervalCount === 0) {
      return 'weekly';
    } else {
      return 'quarterly';
    }
  }

  getRent(bedroom) {

    const rentInterval = bedroom.rent_interval_count;
    if (rentInterval === 1) {
      return bedroom.listed_rent_pcm.toLocaleString();
    } else if (rentInterval === 3) {
      return bedroom.rent_qtr;
    } else if (rentInterval === 0) {
      return bedroom.rent_weekly;
    } else {
      return '';
    }
  }

}


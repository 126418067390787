import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClickOutsideModule } from 'ng-click-outside';

import { PopoverDirective } from './popover.directive';
import { PopoverComponent } from './popover.component';



@NgModule({
  declarations: [
    PopoverDirective, 
    PopoverComponent
  ],
  imports: [
    CommonModule,
    ClickOutsideModule
  ],
  exports: [PopoverDirective],
  entryComponents: [PopoverComponent]
})
export class PopoverModule { }

import {NgModule} from '@angular/core';
import {TenantPropertyCardComponent} from '@app/core-tenant/_shared/_components';
import {
  TenantOfferMembersComponent,
  TenantMaintenanceCardComponent,
  ManualPaymentsComponent
} from '@app/core-tenant/_shared/_components';
import {
  TenancyOfferDepositTypeFormComponent,
  TenancyOfferDepositTypeBunkComponent,
  TenancyOfferDepositTypeCashComponent
} from '@app/core-tenant/_shared/_forms';

import {
  TenantMainTemplateComponent
} from '@app/core-tenant/_shared/_layouts/templates';

import {SharedModule} from '@app/shared';
import { NavigationModule } from '@app/core/navigation/navigation.module';
import { UtilsModule } from '@app/core/utils/utils.module';

export const SHARED_TENANT_COMPONENTS = [
  TenantPropertyCardComponent,
  TenantOfferMembersComponent,
  TenantMaintenanceCardComponent,
  TenancyOfferDepositTypeFormComponent,
  TenancyOfferDepositTypeBunkComponent,
  TenancyOfferDepositTypeCashComponent,
  ManualPaymentsComponent
];

export const SHARED_TENANT_LAYOUTS = [
  TenantMainTemplateComponent
];

@NgModule({
  imports: [
    SharedModule,
    NavigationModule,
    UtilsModule
  ],
  declarations: [
    SHARED_TENANT_COMPONENTS,
    SHARED_TENANT_LAYOUTS
  ],
  exports: [
    SHARED_TENANT_COMPONENTS,
    SHARED_TENANT_LAYOUTS
  ],
  providers: []
})
export class SharedTenantModule {
}

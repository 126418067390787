<div class="filter__container">
    <div class=filter__container--inner>
      <div class="search-filters ">
        <button  class=" margin-top--xs search-filters__btn-control btn__seethrough btn--round btn--sm mr_5 loading-animation">
          <span class="button_text">All properties ></span>
        </button>

        <button class=" margin-top--xs search-filters__btn-control btn__seethrough btn--round btn--sm mr_5 loading-animation">
          <span class="button_text">All properties ></span>
        </button>

        <div class="search-bar--payments loading-animation" *ngIf="showSearch">
        </div>
      </div>

    </div>
  </div>
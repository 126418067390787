import {Component, Input, OnInit} from '@angular/core';
import {flatMap, switchMap} from 'rxjs/operators';
import {BehaviorSubject, of} from 'rxjs';
import {Shared, Tenant, Viewings} from '@env/routing';
import {TenancyOfferService, UserService, ViewingService} from '@app/services';
import {ActivatedRoute, Router} from '@angular/router';
import {DatesService} from '@app/shared/_services/dates.service';
import {AuthService} from '@app/core/services';
import {FormBuilder, FormGroup} from '@angular/forms';
import {environment} from '@env/environment';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'offer-sign-up-component',
  template:  `


    <bunk-dialog *ngIf="bedroomId" [component]="contentDialog" (dialogRef)="dref = $event" restrict="true">
      <button class="btn__cta"
              (click)="handleAnonymousOffer()">Apply now</button>
    </bunk-dialog>


    <ng-template #contentDialog>
      <div  class="flex justify-end margin-bottom--xs flex-row">
        <button class="bunk-overlay__close-btn" (click)="dref.close()"><mat-icon svgIcon="icon-close"></mat-icon></button>
      </div>

      <mat-dialog-content>

        <h2 class="margin-bottom--sm">Sign up as a tenant and {{(viewingRequest ? 'book a viewing' : 'rent property')}}</h2>

        <small class="flex flex-row items-center margin-bottom--md">Already have an account ? <button class="btn__pseudo-link" (click)="logIn()">Sign in</button></small>

        <form [formGroup]="signupformGroup" >
          <form-create-account formControlName="signup" roleInput="tenant" [showLabel]="false" direction="column"
                               (submitForm)="createAccountAndMakeOffer(signupformGroup.value)"
                               [showSpinner]="showPopUpSpinner$.value" title="Create account & continue">
          </form-create-account>

        </form>


      </mat-dialog-content>
    </ng-template>


  `
})

export class OfferSignUpComponent implements OnInit {
  @Input() property: any;
  @Input() bedroomId: string;
  @Input() tenancyPackageId = null;


  public dref;
  public viewingRequest = false;
  public showSignUpForm = false;
  public showPopUpSpinner$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public showSpinner$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public showSuccess;
  public signupformGroup: FormGroup;
  public signingSpinner = false;
  public client_data = environment.client_data;

  constructor( private _viewings: ViewingService,
               private _activatedRoute: ActivatedRoute,
               public _user: UserService,
               public _tenancyOffer: TenancyOfferService,
               public _auth: AuthService,
               public _router: Router,
               private _formBuilder: FormBuilder,
               private _toastr: ToastrService
  ) {
  }

  ngOnInit() {
    this.signupformGroup = this.initSignupFormGroup();
    console.log(this.tenancyPackageId, 'THIS PACKAGE ID IN OFFER ');

  }
  initSignupFormGroup() {
    return this._formBuilder.group({
      signup: [null]
    });
  }


  createAccountAndMakeOffer(form: any) {
    const user = form.signup;
    console.log(user, 'user');
    // const bedroom_id = this._activatedRoute.snapshot.queryParamMap.get('bedroom_id');
    console.log(this.signupformGroup.valid);
    if (this.signupformGroup.valid) {

      this.showPopUpSpinner$.next(true);
      const userDoc = {
        ...user,
        phone_number: user.signup_phone_number_with_area.phone_number,
        country_code: user.signup_phone_number_with_area.area_code,
        first_name: user.name.first_name,
        last_name: user.name.last_name
      };
      this.signupformGroup.disable();
      console.log(this.property, 'HELLOO PROPERTY ID');
      this._auth.createUserWithEmailAndPassword({
        email: userDoc.email,
        password: userDoc.password
      }).pipe(flatMap((auth: any) => this._auth.createUserDocuments(auth.user.uid, userDoc)) ,
        switchMap(() =>
          (this.property.listingType === 'block' && this._auth.authenticated && this._user.userDb)
            ? this._tenancyOffer.createTenancyOffer(this.property, this.property.landlord_uid, this.bedroomId, this.property.team_id, this.tenancyPackageId)
            : of(null)
        )).subscribe((offerId: string) => {
          if (!this._user.userDb) {
            return this._router.navigate(['/',  Shared.no_user]);
          }
          if (this.viewingRequest) {
            this._router.navigate([Viewings.base, Viewings.manage.book], { queryParams: { property_id: this.property.property_id} });
          }
          if (offerId) {
            this._router.navigate(['/' + Tenant.base, Tenant.make_an_offer.base, offerId, Tenant.make_an_offer.rent]);
          }
          this.showPopUpSpinner$.next(false);
          this.dref.close();
        },
        err => {
          this.signupformGroup.enable();
          this.showPopUpSpinner$.next(false);
          if (err.error) {
            this._toastr.error(err.error, 'Oops!', {
              timeOut: 5000
            });
          }
        });
    }
  }

  logIn() {
    this.dref.close();
    this._router.navigate(['/signin'], { queryParams: { property_id: this.property.property_id } });
  }

  handleAnonymousOffer(): void {
    if (this.bedroomId) {
      this._router.navigate(['.'], { queryParams: { bedroom_id: this.bedroomId }, relativeTo: this._activatedRoute, replaceUrl: true });
    }
    this.viewingRequest = false;
  }

}


    <ng-container *ngIf="router$ | async"></ng-container>
    <ng-container *ngIf="searchStr$ | async"></ng-container>
    <ng-container *ngIf="statusFilter$ | async"></ng-container>
    <ng-container *ngIf="portfolioFilter$ | async"></ng-container>
    <ng-container *ngIf="combined$ | async"></ng-container>


    <!-- for buttons -->
    <div class="flex flex-row justify-between margin-bottom shared-filter__header">
      <h2>{{title}}</h2>
       <ng-content select="[actionButton]"></ng-content>
    </div>

    <nav class="secondary__nav" *ngIf="showStatusNav">
      <span *ngFor="let item of statusFilterValues" class="secondary__nav-title"  [ngClass]="{'active': item.viewValue && statusViewValue && statusViewValue === item.viewValue }"
            (click)="displayFilterChoices(statusFilterValues, statusFilter, 'status', item.value)">{{item.viewValue}}</span>
    </nav>

    <div class="filter__container">
      <div class=filter__container--inner>
        <div class="search-filters " (clickOutside)="$event.value && clickedOutside($event)" [listen]="showFilters">

          <div class="search-bar--payments" *ngIf="showSearch">
            <instant-search-input
              class="search-bar__keyword"
              [formControl]="searchStr"
              [indexName]="indexName"
              placeholder="Search">
            </instant-search-input>
          </div>

          <div class="search-bar--payments"  *ngIf="instantSearchFilerValues">
          <instant-algolia-search
            class="search-bar__keyword"
            [indexName]="'properties'"
            [message]="'Filter by property'"
            [hideResults]="false"
            [storedIds]="storedIds"
            (storedIdsChange)="onStoredIdsChange($event)"
            [showImmediately]="true"
            [availableHits]="instantSearchFilerValues">
          </instant-algolia-search>
          </div>

          <button *ngIf="statusFilterValues" class=" margin-top--xs search-filters__btn-control btn__seethrough btn--round btn--sm mr_5"
                  (click)="displayFilterChoices(statusFilterValues, statusFilter, 'status')">
            <span>{{statusViewValue}}</span>
            <mat-icon svgIcon="icon-expand-more"></mat-icon>
          </button>

          <button *ngIf="typeFilterValues" class=" margin-top--xs search-filters__btn-control btn__seethrough btn--round btn--sm mr_5"
                  (click)="displayFilterChoices(typeFilterValues, typeFilter, 'type')">
            <span>{{typeViewValue}}</span>
            <mat-icon svgIcon="icon-expand-more"></mat-icon>
          </button>

          <button *ngIf="sortFilterValues" class="margin-top--xs search-filters__btn-control btn__seethrough btn--round btn--sm mr_5"
                  (click)="displayFilterChoices(sortFilterValues, sortFilter, 'sort')">
            <span>{{sortViewValue}}</span>
            <mat-icon svgIcon="icon-expand-more"></mat-icon>
          </button>

          <button *ngIf="portfolioFilterValues && portfolioFilterValues.length" class=" margin-top--xs search-filters__btn-control btn__seethrough btn--round btn--sm mr_5"
                  (click)="displayFilterChoices(portfolioFilterValues, portfolioFilter, 'portfolio')">
            <span>{{portfolioViewValue}}</span>
            <mat-icon svgIcon="icon-expand-more"></mat-icon>
          </button>


          <button *ngIf="assigneeFilterValues && assigneeFilterValues.length" class=" margin-top--xs search-filters__btn-control btn__seethrough btn--round btn--sm mr_5"
                  (click)="displayFilterChoices(assigneeFilterValues, assigneeFilter, 'assignee')">
            <span>{{assigneeViewValue}}</span>
            <mat-icon svgIcon="icon-expand-more"></mat-icon>
          </button>

          <!--           Add any unique filtering items -->

             <ng-content></ng-content>

          <div class="search-filters__controls-menu" *ngIf="showFilters">
<!--            <span class="btn__pseudo-link btn__pseudo-link&#45;&#45;text-secondary justify-end">Reset</span>-->
            <ng-container *ngTemplateOutlet="displayChoices"></ng-container>
            <button class="btn__info" (click)="showFilters = false">Done</button>
          </div>
        </div>

      </div>
    </div>


    <ng-template #displayChoices>
      <div class="filter__type-container filter__type-container--content cursor-pointer" (click)="updateFormValue(filter.value)" *ngFor="let filter of filterChoices">
        <p> {{filter.viewValue}}</p>
        <span class="selected" *ngIf="selectedFormControl.value === filter.value"></span>
      </div>
    </ng-template>
  
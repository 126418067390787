import {Injectable} from '@angular/core';
import {Location} from '@angular/common';
import {Router} from '@angular/router';

import {firestore} from 'firebase/app';
import FieldValue = firestore.FieldValue;

@Injectable()
export class HelpersService {
  styles = [
    {
      'featureType': 'landscape',
      'stylers': [
        {
          'color': '#f1eef0'
        },
        {
          'weight': 1
        }
      ]
    },
    {
      'featureType': 'landscape.natural',
      'elementType': 'geometry',
      'stylers': [
        {
          'color': '#b7e0cf'
        },
        {
          'visibility': 'simplified'
        }
      ]
    },
    {
      'featureType': 'poi',
      'elementType': 'geometry',
      'stylers': [
        {
          'visibility': 'off'
        }
      ]
    },
    {
      'featureType': 'poi',
      'elementType': 'labels',
      'stylers': [
        {
          'visibility': 'off'
        }
      ]
    },
    {
      'featureType': 'poi.park',
      'elementType': 'geometry',
      'stylers': [
        {
          'visibility': 'on'
        }
      ]
    },
    {
      'featureType': 'poi.park',
      'elementType': 'geometry.fill',
      'stylers': [
        {
          'color': '#b7e0cf'
        }
      ]
    },
    {
      'featureType': 'poi.park',
      'elementType': 'labels',
      'stylers': [
        {
          'visibility': 'on'
        }
      ]
    },
    {
      'featureType': 'poi.park',
      'elementType': 'labels.text.fill',
      'stylers': [
        {
          'color': '#81838c'
        }
      ]
    },
    {
      'featureType': 'road',
      'stylers': [
        {
          'color': '#ffffff'
        },
        {
          'weight': 1.5
        }
      ]
    },
    {
      'featureType': 'road',
      'elementType': 'geometry',
      'stylers': [
        {
          'weight': 1.5
        }
      ]
    },
    {
      'featureType': 'road',
      'elementType': 'labels.icon',
      'stylers': [
        {
          'visibility': 'off'
        }
      ]
    },
    {
      'featureType': 'road',
      'elementType': 'labels.text.fill',
      'stylers': [
        {
          'color': '#9598a2'
        }
      ]
    },
    {
      'featureType': 'transit',
      'elementType': 'geometry',
      'stylers': [
        {
          'visibility': 'off'
        }
      ]
    },
    {
      'featureType': 'transit',
      'elementType': 'labels',
      'stylers': [
        {
          'visibility': 'off'
        }
      ]
    },
    {
      'featureType': 'water',
      'stylers': [
        {
          'visibility': 'on'
        }
      ]
    },
    {
      'featureType': 'water',
      'elementType': 'geometry',
      'stylers': [
        {
          'color': '#b0deeb'
        },
        {
          'visibility': 'on'
        }
      ]
    }
  ];

  constructor(private location: Location,
              private router: Router) {

  }

  generateRandomString = (length) => {
    const allowedCharacters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let randomString = '';
    for (let i = 0; i < length; i++) {
      const randomPoz = Math.floor(Math.random() * allowedCharacters.length);
      randomString += allowedCharacters.substring(randomPoz, randomPoz + 1);
    }
    return randomString;
  };

  routeHas(url: string) {
    // console.log(this.router.url.startsWith(url));
    return this.router.url.startsWith(url);
  }

  /* split url to get child route (I.E. /tenant-manage-property/your-properties => your-properties) */
  getChildRouteUrl = (start: number, url: string) => {
    const delimiter = '/',
      tokens = url.split(delimiter).slice(start);
    return tokens.join(delimiter); // current Path
  };

  // Scroll to specific element
  scrollTo(target, indexEl) {
    const el = target.find((item, index, array) => index === indexEl);
    el.nativeElement.scrollIntoView({behavior: 'smooth'});
  }

  serverTimestamp = (): FieldValue => firestore.FieldValue.serverTimestamp();

  // Find out if user is on mobile device
  detectMob(): boolean {
    return !!(navigator.userAgent.match(/Android/i)
      || navigator.userAgent.match(/webOS/i)
      || navigator.userAgent.match(/iPhone/i)
      || navigator.userAgent.match(/iPad/i)
      || navigator.userAgent.match(/iPod/i)
      || navigator.userAgent.match(/BlackBerry/i)
      || navigator.userAgent.match(/Windows Phone/i));
  }

}

import { Injectable } from '@angular/core';
import {
  TenancyOfferModel,
  TenancyOfferReferencesEmploymentModel,
  TenancyOfferReferencesLandlordModel,
} from '@app/_models/tenancy-offer.model';

import {
  AngularFirestore,
  AngularFirestoreCollection,
} from '@angular/fire/firestore';
import { AuthService } from '@app/core/services';
import { from as fromPromise, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { UserService } from '@app/services/user.service';
import { environment } from '@env/environment';
import { firestore } from 'firebase/app';
import { catchError, flatMap, map, switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs/internal/Observable';
import { FirebaseApp } from '@angular/fire';
import * as moment from 'moment';
import { TENANCY_OFFER_SCHEMA } from '@app/core-tenant/property/property.constants';
import { BunkPassportService } from './bunk-passport.service';
import { ReferencesService } from './references.service';
import { CompanyName } from '@shared/shared.constants';
import {
  EApplicationStatus,
  ITenancyOffer,
  EOfferPhase,
} from '@rentbunk/bunk-models';

@Injectable()
export class TenancyOfferService {
  public tenancyOfferCollection: AngularFirestoreCollection<any>;
  public tenancyAgreementCollection: AngularFirestoreCollection<any>;
  public tenancyOfferCancellationReason: AngularFirestoreCollection<any>;
  public tenancyCollection: AngularFirestoreCollection<any>;
  public tenancyAgreementTemplateCollection: AngularFirestoreCollection<any>;
  public propertiesCollection: AngularFirestoreCollection<any>;
  environment = environment;

  constructor(
    private afs: AngularFirestore,
    private _auth: AuthService,
    private _user: UserService,
    private fb: FirebaseApp,
    private _http: HttpClient,
    private _passport: BunkPassportService,
    private _reference: ReferencesService
  ) {
    this.tenancyOfferCollection = afs.collection<any>('tenancy_offers');
    this.tenancyAgreementCollection = afs.collection<any>('tenancy_agreements');
    this.tenancyOfferCancellationReason = afs.collection<any>(
      'tenancy_offer_cancellation_reason'
    );
    this.propertiesCollection = afs.collection<any>('properties');
    this.tenancyAgreementTemplateCollection = afs.collection<any>(
      'tenancy_agreement_templates'
    );
    this.tenancyCollection = afs.collection<any>('tenancies');
  }

  getTenancyOfferById(id: string) {
    return this.tenancyOfferCollection.doc(id);
  }

  getTenancyAgreementById(id: string) {
    return this.tenancyAgreementCollection.doc(id);
  }

  getTenancyAgreementTemplateById() {
    // Doing it this way so all 6 teams in campuskey can view this new template
    const templateId = CompanyName.isCampusKey
      ? 'VPPcjON30tcVWW2CimCbGy8AFWc2'
      : this._auth.currentUserId;
    return this.tenancyAgreementTemplateCollection.doc(templateId);
  }

  getPendingTenancyOffersForPropertyBedroom(
    id: string,
    bedroomId: string = null
  ): Observable<any> {
    const teamQuery = this._user.userReadOnlyDb.team_ids
      ? this.afs
          .collection('tenancy_offers', ref =>
            ref
              .where('team_id', 'in', this._user.userReadOnlyDb.team_ids)
              .where('status', '==', 'incomplete')
              .where(`property_id`, '==', id)
              .where('bedroom_id', '==', bedroomId)
              .where(`application.status`, '==', 'pending_landlord_decision')
          )
          .valueChanges()
      : of([]);

    const landlordQuery = this.afs
      .collection('tenancy_offers', ref =>
        ref
          .where('landlord_uid', '==', this._auth.currentUserId)
          .where('status', '==', 'incomplete')
          .where(`property_id`, '==', id)
          .where('bedroom_id', '==', bedroomId)
          .where(`application.status`, '==', 'pending_landlord_decision')
      )
      .valueChanges();

    return this._auth.getCurrentUserCustomClaims().pipe(
      switchMap((res: boolean) => {
        return res && !this._auth.currentUser.displayName
          ? this.afs
              .collection('tenancy_offers', ref =>
                ref
                  .where('status', '==', 'incomplete')
                  .where(`property_id`, '==', id)
                  .where('bedroom_id', '==', bedroomId)
                  .where(
                    `application.status`,
                    '==',
                    'pending_landlord_decision'
                  )
              )
              .valueChanges()
          : this._auth.combineQueries(teamQuery, landlordQuery);
      })
    );
  }
  getAllPendingTenancyOffersForBedroom(
    id: string,
    bedroomId: string
  ): Observable<any> {
    const teamQuery = this._user.userReadOnlyDb.team_ids
      ? this.afs
          .collection('tenancy_offers', ref =>
            ref
              .where('team_id', 'in', this._user.userReadOnlyDb.team_ids)
              .where('status', '==', 'incomplete')
              .where(`property_id`, '==', id)
              .where('bedroom_id', '==', bedroomId)
              .where('phase', '==', 'application')
          )
          .valueChanges()
      : of([]);

    const landlordQuery = this.afs
      .collection('tenancy_offers', ref =>
        ref
          .where('landlord_uid', '==', this._auth.currentUserId)
          .where('status', '==', 'incomplete')
          .where(`property_id`, '==', id)
          .where('bedroom_id', '==', bedroomId)
          .where('phase', '==', 'application')
      )
      .valueChanges();

    return this._auth.getCurrentUserCustomClaims().pipe(
      switchMap((res: boolean) => {
        return res && !this._auth.currentUser.displayName
          ? this.afs
              .collection('tenancy_offers', ref =>
                ref
                  .where('status', '==', 'incomplete')
                  .where(`property_id`, '==', id)
                  .where('bedroom_id', '==', bedroomId)
                  .where('phase', '==', 'application')
              )
              .valueChanges()
          : this._auth.combineQueries(teamQuery, landlordQuery);
      })
    );
  }

  getAllPendingTenancyOffersForProperty(id: string): Observable<any> {
    const teamQuery = this._user.userReadOnlyDb.team_ids
      ? this.afs
          .collection('tenancy_offers', ref =>
            ref
              .where('team_id', 'in', this._user.userReadOnlyDb.team_ids)
              .where('status', '==', 'incomplete')
              .where(`property_id`, '==', id)
              .where(`phase`, '==', 'application')
          )
          .valueChanges()
      : of([]);

    const landlordQuery = this.afs
      .collection('tenancy_offers', ref =>
        ref
          .where('landlord_uid', '==', this._auth.currentUserId)
          .where('status', '==', 'incomplete')
          .where(`property_id`, '==', id)
          .where(`phase`, '==', 'application')
      )
      .valueChanges();

    return this._auth.getCurrentUserCustomClaims().pipe(
      switchMap((res: boolean) => {
        return res && !this._auth.currentUser.displayName
          ? this.afs
              .collection('tenancy_offers', ref =>
                ref
                  .where('status', '==', 'incomplete')
                  .where(`property_id`, '==', id)
                  .where(`phase`, '==', 'application')
              )
              .valueChanges()
          : this._auth.combineQueries(teamQuery, landlordQuery);
      })
    );
  }

  getTenancyOffersForPropertyByStatus(
    id: string,
    status: string
  ): Observable<any> {
    const teamQuery = this._user.userReadOnlyDb.team_ids
      ? this.afs
          .collection('tenancy_offers', ref =>
            ref
              .where('team_id', 'in', this._user.userReadOnlyDb.team_ids)
              .where('status', '==', 'incomplete')
              .where(`property_id`, '==', id)
              .where(`application.status`, '==', status)
          )
          .valueChanges()
      : of([]);

    const landlordQuery = this.afs
      .collection('tenancy_offers', ref =>
        ref
          .where('landlord_uid', '==', this._auth.currentUserId)
          .where('status', '==', 'incomplete')
          .where(`property_id`, '==', id)
          .where(`application.status`, '==', status)
      )
      .valueChanges();

    return this._auth.getCurrentUserCustomClaims().pipe(
      switchMap((res: boolean) => {
        return res && !this._auth.currentUser.displayName
          ? this.afs
              .collection('tenancy_offers', ref =>
                ref
                  .where('status', '==', 'incomplete')
                  .where(`property_id`, '==', id)
                  .where(`application.status`, '==', status)
              )
              .valueChanges()
          : this._auth.combineQueries(teamQuery, landlordQuery);
      })
    );
  }

  getTenancyOffersForLandlord(): Observable<any> {
    const teamQuery = this._user.userReadOnlyDb.team_ids
      ? this.afs
          .collection('tenancy_offers', ref =>
            ref
              .where('team_id', 'in', this._user.userReadOnlyDb.team_ids)
              // .where('phase', '==', 'application')
              .where(`status`, '==', 'incomplete')
          )
          .valueChanges()
      : of([]);

    const landlordQuery = this.afs
      .collection('tenancy_offers', ref =>
        ref
          .where('landlord_uid', '==', this._auth.currentUserId)
          // .where('phase', '==', 'application')
          .where(`status`, '==', 'incomplete')
      )
      .valueChanges();

    return this._auth.getCurrentUserCustomClaims().pipe(
      switchMap((res: boolean) => {
        return res && !this._auth.currentUser.displayName
          ? this.afs
              .collection('tenancy_offers', ref =>
                ref.where(
                  `application.status`,
                  '==',
                  'pending_landlord_decision'
                )
              )
              .valueChanges()
          : this._auth.combineQueries(teamQuery, landlordQuery);
      })
    );
  }

  getTenancyOffers(): Observable<any> {
    const teamQuery = this._user.userReadOnlyDb.team_ids
      ? this.afs
          .collection(
            'tenancy_offers',
            ref =>
              ref
                .where('team_id', 'in', this._user.userReadOnlyDb.team_ids)
                .where(`status`, '==', 'incomplete')
            // .orderBy('date_modified', 'desc')
          )
          .valueChanges()
      : of([]);

    const landlordQuery = this.afs
      .collection(
        'tenancy_offers',
        ref =>
          ref
            .where('landlord_uid', '==', this._auth.currentUserId)
            .where(`status`, '==', 'incomplete')
        // .orderBy('date_modified', 'desc')
      )
      .valueChanges();

    return this._auth.getCurrentUserCustomClaims().pipe(
      switchMap((res: boolean) => {
        return res && !this._auth.currentUser.displayName
          ? this.afs
              .collection(
                'tenancy_offers',
                ref => ref.where(`status`, '==', 'incomplete')
                // .orderBy('date_modified', 'desc')
              )
              .valueChanges()
          : this._auth.combineQueries(teamQuery, landlordQuery);
      })
    );
  }

  getAcceptedTenancyOffersForProperty(propertyId: string): Observable<any> {
    const teamQuery = this._user.userReadOnlyDb.team_ids
      ? this.afs
          .collection('tenancy_offers', ref =>
            ref
              .where('team_id', 'in', this._user.userReadOnlyDb.team_ids)
              .where(`application.status`, '==', 'accepted')
              .where(`status`, '==', 'incomplete')
              .where('property_id', '==', propertyId)
          )
          .valueChanges()
      : of([]);

    const landlordQuery = this.afs
      .collection('tenancy_offers', ref =>
        ref
          .where('landlord_uid', '==', this._auth.currentUserId)
          .where(`application.status`, '==', 'accepted')
          .where(`status`, '==', 'incomplete')
          .where('property_id', '==', propertyId)
      )
      .valueChanges();

    return this._auth.getCurrentUserCustomClaims().pipe(
      switchMap((res: boolean) => {
        return res && !this._auth.currentUser.displayName
          ? this.afs
              .collection('tenancy_offers', ref =>
                ref
                  .where(`application.status`, '==', 'accepted')
                  .where('status', '==', 'incomplete')
                  .where('property_id', '==', propertyId)
              )
              .valueChanges()
          : this._auth.combineQueries(teamQuery, landlordQuery);
      })
    );
  }

  getAcceptedTenancyOffersForBedroom(bedroomId: string): Observable<any> {
    const teamQuery = this._user.userReadOnlyDb.team_ids
      ? this.afs
          .collection('tenancy_offers', ref =>
            ref
              .where('team_id', 'in', this._user.userReadOnlyDb.team_ids)
              .where(`application.status`, '==', 'accepted')
              .where(`status`, '==', 'incomplete')
              .where('bedroom_id', '==', bedroomId)
          )
          .valueChanges()
      : of([]);

    const landlordQuery = this.afs
      .collection('tenancy_offers', ref =>
        ref
          .where('landlord_uid', '==', this._auth.currentUserId)
          .where(`application.status`, '==', 'accepted')
          .where(`status`, '==', 'incomplete')
          .where('bedroom_id', '==', bedroomId)
      )
      .valueChanges();

    return this._auth.getCurrentUserCustomClaims().pipe(
      switchMap((res: boolean) => {
        return res && !this._auth.currentUser.displayName
          ? this.afs
              .collection('tenancy_offers', ref =>
                ref
                  .where(`application.status`, '==', 'accepted')
                  .where('status', '==', 'incomplete')
                  .where('bedroom_id', '==', bedroomId)
              )
              .valueChanges()
          : this._auth.combineQueries(teamQuery, landlordQuery);
      })
    );
  }

  getOffersForLandlordByStatus(status: string) {
    const teamQuery = this._user.userReadOnlyDb.team_ids
      ? this.afs
          .collection('tenancy_offers', ref =>
            ref
              .where('team_id', 'in', this._user.userReadOnlyDb.team_ids)
              .where('status', '==', status)
              .where('is_deleted', '==', false)
          )
          .valueChanges()
      : of([]);

    const landlordQuery = this.afs
      .collection('tenancy_offers', ref =>
        ref
          .where('landlord_uid', '==', this._auth.currentUserId)
          .where('status', '==', status)
          .where('is_deleted', '==', false)
      )
      .valueChanges();

    return this._auth.getCurrentUserCustomClaims().pipe(
      switchMap((res: boolean) => {
        return res && !this._auth.currentUser.displayName
          ? this.afs
              .collection('tenancy_offers', ref =>
                ref.where(`status`, '==', status)
              )
              .valueChanges()
          : this._auth.combineQueries(teamQuery, landlordQuery);
      })
    );
  }

  getAllTenancyOffersByUserId() {
    return this.afs.collection('tenancy_offers', ref =>
      ref.where(
        `application.members.${this._auth.currentUserId}.status`,
        '>',
        ''
      )
    );
  }

  getTenancyOfferByLeadTenantUid(
    property_id: string,
    bedroomId: string = null
  ) {
    return this.afs
      .collection('tenancy_offers', ref =>
        ref
          .where('lead_applicant_id', '==', this._auth.currentUserId)
          .where('property_id', '==', property_id)
          .where('bedroom_id', '==', bedroomId)
          .where('is_deleted', '==', false)
          .where('status', '==', 'incomplete')
          .orderBy('date_modified', 'desc')
      )
      .valueChanges()
      .pipe(catchError(error => of([])));
  }

  getUsersFromOffer(members) {
    const ids = [];

    Object.keys(members).forEach((key, index) => {
      ids.push(key);
    });

    const docs = [];
    ids.map(id => {
      docs.push(this._user.getUserById(id).valueChanges());
    });

    return of(docs);
  }
  // returns passport, passport reference, vouch reference status if exist
  getPassportsAndReferenceFromOffer(offer) {
    const ids = Object.keys(offer.application.members).map(key => key);
    const docs = ids.map(id => {
      const passportWithReference = this._passport
        .getTenantsBunkPassport(id)
        .valueChanges()
        .pipe(
          switchMap((passport: any) => {
            if (passport.employment_references) {
              return this._passport
                .getReferencesByIds(passport.employment_references)
                .pipe(
                  map(references => {
                    return this.getReferenceObject(references);
                  }),
                  map(reference => {
                    return {
                      ...passport,
                      reference: reference,
                    };
                  }),
                  switchMap((reference: any) => {
                    const member =
                      offer.references &&
                      offer.references.members &&
                      offer.references.members.find(
                        member => member.uid === reference.uid
                      );
                    return member && member.reference_id
                      ? this._reference
                          .getReferenceById(member.reference_id)
                          .pipe(
                            map(vouchreference => {
                              return {
                                ...reference,
                                ...(member &&
                                  member.is_being_referenced && {
                                    is_applicable: member.is_being_referenced,
                                  }),
                                vouch_status: vouchreference.status,
                                vouch_guarantor_completed:
                                  !!vouchreference.guarantor_details,
                                vouch_reference_data:
                                  vouchreference.reference_data,
                              };
                            })
                          )
                      : of({
                          ...reference,
                          ...(member &&
                            member.is_being_referenced && {
                              is_applicable: member.is_being_referenced,
                            }),
                        });
                  })
                );
            }
            return of(passport);
          })
        );
      return passportWithReference;
    });

    return of(docs);
  }

  getReferenceObject(references) {
    if (references.length > 1) {
      let selectedReference;
      references.forEach((reference, index, array) => {
        if (reference.reference_data.employment_status != 'unemployed') {
          selectedReference = reference;
          return;
        }
        // last object in the array -> set selectedReference to first object
        if (index === array.length - 1) {
          selectedReference = reference[0];
        }
      });
      return selectedReference;
    }
    // return first reference
    return references[0];
  }

  getUsersFromAgreement(members) {
    const ids = [];
    console.log(members);

    const tenants = Object.values(members);
    console.log(tenants, 'hello tenants');

    tenants.map((tenant: any) => ids.push(tenant.tenant_uid));

    console.log(ids);

    const docs = [];
    ids.map(id => {
      docs.push(this._user.getUserById(id).valueChanges());
    });

    return of(docs);
  }

  updateUserDepositType(id: string, deposit: string) {
    const item = {
      date_modified: new Date(),
      application: {
        members: {
          [this._auth.currentUserId]: {
            date_modified: new Date(),
            deposit_type: deposit,
          },
        },
        date_modified: new Date(),
      },
    };
    return fromPromise(this.updateTenancyOffer(item, id));
  }

  updateUserMoveInDate(props: object, id: string) {
    const item = {
      application: {
        date_modified: new Date(),
        ...props,
      },
    };
    return fromPromise(
      this.updateTenancyOffer(item, id).then(() =>
        console.log('move-in date modified ', new Date())
      )
    );
  }

  updateRentOfferAmount(form: any, id: string, date_created: Date) {
    console.log(form, 'HELLO FORM');
    const item: any = {
      application: {
        date_modified: new Date(),
        offer_amount: form.offer_amount,
      },
      tenancy_package_id: form.tenancy_package_id
        ? form.tenancy_package_id
        : null,
      application_year: form.application_year || null,
      step_completion: [
        {
          date_created,
          date_modified: new Date(),
          date_completed: null,
          step: 1,
        },
      ],
    };

    if (form.additional_extra_ids) {
      item.additional_extra_ids = form.additional_extra_ids;
    }
    return fromPromise(
      this.updateTenancyOffer(item, id).then(() =>
        console.log('update successful')
      )
    );
  }

  updateTempMembersObject(tenancy_offer: any, members_uids) {
    console.log(tenancy_offer, 'tenancy_offer');
    const item = {
      temp_members: {},
      date_modified: new Date(),
      step_completion: [
        {
          date_created: tenancy_offer.step_completion[0].date_created,
          date_modified: new Date(),
          date_completed: null,
          step: 1,
        },
      ],
    };
    if (members_uids.length) {
      for (const uid of members_uids) {
        item['temp_members'] = {
          ...item.temp_members,
          [uid]: {
            status: 'pending',
            deposit_type: '',
            date_modified: new Date(),
          },
        };
      }
    } else {
      item['temp_members'] = firestore.FieldValue.delete();
    }
    return fromPromise(
      this.updateTenancyOffer(item, tenancy_offer.tenancy_offer_id)
    );
  }

  updateTenancyOfferMembersAndStatus(id: string) {
    const body = {
      tenancy_offer_id: id,
    };
    return this._http.post(
      environment.firebaseConfig.apiUrl +
        '/tenancyOffer-leadApplicantCreateTenancyOffer',
      body
    );
  }

  updateTenancyOfferMemberStatus(id: string, status: string) {
    const body = {
      tenancy_offer_id: id,
      uid: this._auth.currentUserId,
      status: status,
    };
    return this._http.post(
      environment.firebaseConfig.apiUrl +
        '/tenancyOffer-tenantInviteesUpdateTenancyOffer',
      body
    );
  }

  landlordAcceptOffer(
    id: string,
    has_skipped_referencing: boolean,
    assigned_property_id: string
  ) {
    const body = {
      tenancy_offer_id: id,
      sender_uid: this._auth.currentUserId,
      has_skipped_referencing,
      assigned_property_id,
    };
    return this._http.post(
      environment.firebaseConfig.apiUrl +
        '/tenancyOffer-landlordAcceptTenancyOffer',
      body
    );
  }

  tenantAcceptSplitRent(id: string) {
    const body = {
      tenancy_offer_id: id,
      uid: this._auth.currentUserId,
    };
    return this._http.post(
      environment.firebaseConfig.apiUrl + '/tenancyOffer-tenantAcceptSplitRent',
      body
    );
  }

  getSignedTenancyAgreement(tenancy_agreement_id: string) {
    return this._http.post(
      environment.firebaseConfig.apiUrl +
        '/bunkpassport-getEsignaturesSignedDocument',
      { tenancy_id: tenancy_agreement_id }
    );
  }

  landlordRejectOffer(
    tenancy_offer_id: string,
    landlord_rejected_message: string
  ) {
    const body = {
      tenancy_offer_id,
      landlord_rejected_message,
      sender_uid: this._auth.currentUserId,
    };
    return this._http.post(
      environment.firebaseConfig.apiUrl +
        '/tenancyOffer-landlordRejectTenancyOffer',
      body
    );
  }

  landlordHasReadOffer(id: string, isRead: boolean = true) {
    const item = {
      application: {
        is_read: isRead,
      },
    };
    return fromPromise(this.updateTenancyOffer(item, id));
  }

  tenantRentSet(members: any, id: string) {
    console.log(members);
    const item = {
      date_modified: new Date(),
      securing_payment: {
        ...members,
        rent_assigner_uid: this._auth.currentUserId,
      },
    };

    console.log(item);

    return fromPromise(this.updateTenancyOffer(item, id));
  }

  tenantInitSplitPayment(members: any, id: string) {
    const body = {
      members,
      current_user_id: this._auth.currentUserId,
      tenancy_offer_id: id,
    };
    return this._http.post(
      environment.firebaseConfig.apiUrl +
        '/tenancyOffer-tenantInitSplitPayment',
      body
    );
  }

  deleteUserFromTempObject(offer_id: string, uid: string) {
    return fromPromise(
      this.tenancyOfferCollection.doc(offer_id).update({
        [`temp_members.${uid}`]: firestore.FieldValue.delete(),
      })
    );
  }

  createTenancyOfferCancellation(offerId: string, form: any) {
    const item = {
      application: {
        date_cancelled: new Date(),
        members: {
          [this._auth.currentUserId]: {
            cancellation_reason:
              form.text === 'Other' ? form.cancellation_reason : form.text,
          },
        },
      },
    };
    return fromPromise(this.updateTenancyOffer(item, offerId));
  }

  updateReferences(
    refs:
      | TenancyOfferReferencesEmploymentModel
      | TenancyOfferReferencesLandlordModel,
    offerId: string
  ) {
    const item = {
      references: {
        members: {
          [this._auth.currentUserId]: refs,
        },
      },
    };
    return fromPromise(this.updateTenancyOffer(item, offerId));
  }

  /*
    Creates a new tenancy and returns the new 'tenancy_offer_id'
  */
  createTenancyOffer(
    property: any,
    landlord_uid: string,
    bedroom_id: string = null,
    team_id: string = null,
    tenancy_package_id: string = null
  ) {
    const tenancy_offer_id = this.afs.createId();
    const rent_interval_count = bedroom_id
      ? property.bedrooms[bedroom_id].rent_interval_count
      : property.rent_interval_count;

    const package_id =
      environment.firebaseConfig.projectId === 'client-campus-key' &&
      property.tenancy_package_ids &&
      property.tenancy_package_ids.length
        ? property.tenancy_package_ids[0]
        : tenancy_package_id;
    const application = {
      ...(property.service_fee_amount && {
        service_fee_amount: property.service_fee_amount,
      }),
      ...(property.holding_deposit_amount && {
        holding_deposit_amount: property.holding_deposit_amount,
      }),
      members: {
        [this._auth.currentUserId]: {
          deposit_type: '',
        },
      },
      offer_amount: null,
      rent_interval_count,
      status: EApplicationStatus.incomplete,
    };

    const date = new Date();
    const item: ITenancyOffer = {
      tenancy_offer_id,
      bedroom_id,
      phase: EOfferPhase.application,
      status: EApplicationStatus.incomplete,
      landlord_uid,
      lead_applicant_id: this._auth.currentUserId,
      cancellation_reason: '',
      team_id,
      ...(property.is_marketing_listing && {
        parent_property_id: property.property_id,
      }),
      tenancy_package_id: package_id,
      application,
      property_id: property.property_id,
      offer_amount: null,
      is_deleted: false,
      date_created: date,
      date_modified: date,
      deleted_date: null,
      step_completion: [
        {
          date_modified: date,
          date_completed: date,
          step: 1,
        },
      ],
    };

    return fromPromise(
      this.tenancyOfferCollection.doc(tenancy_offer_id).set(item)
    ).pipe(map(() => tenancy_offer_id));
  }

  createAgreement(
    form: any,
    tenancy_offer_id: string,
    tenancy_agreement_id: string
  ) {
    const date = new Date();
    const tenancy_id = this.afs.createId();
    const batch = this.afs.firestore.batch();

    const agreementItem = {
      ...form,
      tenancy_agreement_id,
      tenancy_offer_id,
      date_created: date,
      date_modified: date,
      status: 'pending_signature',
      tenancy_id,
    };

    const tenancyItem = {
      date_created: date,
      date_modified: date,
      status: 'incomplete',
    };

    return fromPromise(
      this.tenancyAgreementCollection
        .doc(tenancy_agreement_id)
        .set(agreementItem)
    ).pipe(
      flatMap(() =>
        tenancy_offer_id ? this.updateStepsArray(tenancy_offer_id) : of({})
      )
    );
  }

  updateAgreementData(
    tenancy_offer_id = null,
    form: any,
    tenancy_agreement_id: string,
    tenancy_id: string
  ) {
    const date = new Date();

    const item = {
      ...form,
      date_modified: date,
      tenancy_id,
    };

    return fromPromise(this.updateAgreement(item, tenancy_agreement_id)).pipe(
      flatMap(() =>
        tenancy_offer_id !== null
          ? this.updateStepsArray(tenancy_offer_id)
          : of({})
      )
    );
  }

  updateTenancyAgreement(tenancy_offer_id: string, form: any) {
    const item = {
      agreement: {
        ...form,
      },
    };
    return fromPromise(this.updateTenancyOffer(item, tenancy_offer_id)).pipe(
      flatMap(() =>
        tenancy_offer_id ? this.updateStepsArray(tenancy_offer_id) : of({})
      )
    );
  }

  updateTenancyOfferApplication(tenancy_offer_id: string, form: any) {
    const item = {
      application: {
        ...form,
      },
    };

    return fromPromise(this.updateTenancyOffer(item, tenancy_offer_id));
  }

  updateOffer(item, tenancy_offer_id) {
    return fromPromise(this.updateTenancyOffer(item, tenancy_offer_id));
  }

  sendTenancyAgreement(tenancy_offer_id: string, form: any) {
    const item = {
      agreement: {
        ...form,
      },
    };
    return fromPromise(this.updateTenancyOffer(item, tenancy_offer_id)).pipe();
  }

  getCurrentUserAgreement(landlord: any): string | null {
    let agreement;
    for (const signer_id of Object.keys(landlord)) {
      agreement = landlord[signer_id];
    }
    return agreement ? agreement : null;
  }

  private updateTenancyOffer(item, id: string) {
    return this.tenancyOfferCollection
      .doc(id)
      .set(item, { merge: true })
      .catch((err: any) => console.log(err));
  }

  private updateAgreement(item, id: string) {
    return this.tenancyAgreementCollection
      .doc(id)
      .set(item, { merge: true })
      .catch((err: any) => console.log(err));
  }

  updateStepsArray(offerId: string) {
    const offerRef = this.tenancyOfferCollection.doc(offerId).ref;
    return fromPromise(
      this.fb.firestore().runTransaction(transaction => {
        return transaction.get(offerRef).then((offerDoc: any) => {
          const offer = offerDoc.data();
          let currentStep: any;
          offer.step_completion.map((iStep: any, index: number) => {
            // Return current step if already exists (So it can be updated)
            if (iStep.step === 3) {
              currentStep = { ...iStep, index };
            }
          });

          if (currentStep) {
            transaction.update(offerRef, {
              step_completion: firestore.FieldValue.arrayRemove(
                offer.step_completion[currentStep.index]
              ),
            });
            console.log(currentStep);
            const item = {
              date_modified: new Date(),
              date_created: currentStep.date_created,
              date_completed: null,
              step: 3,
            };

            transaction.update(offerRef, {
              step_completion: firestore.FieldValue.arrayUnion(item),
            });
          }
        });
      })
    );
  }

  updateReferencesObject(obj, offerId) {
    let data = {
      references: obj,
      date_modified: new Date(),
    };
    this.updateTenancyOffer(data, offerId);
  }
  getAmountOfApplications(
    offers: any
  ): { value: string; viewValue: string; amount: number }[] {
    const allActiveOffers = offers.filter(
      offer => offer.status === 'incomplete'
    );

    const pendingOffers = allActiveOffers.reduce(
      (acc, cur) => (cur.phase === 'application' ? ++acc : acc),
      0
    );
    const acceptedOffers = allActiveOffers.reduce(
      (acc, cur) => (cur.phase === 'references' ? ++acc : acc),
      0
    );
    const referencesCompleteOffers = allActiveOffers.reduce(
      (acc, cur) => (cur.phase === 'tenancy_agreement' ? ++acc : acc),
      0
    );
    const awaitingPaymentOffers = allActiveOffers.reduce(
      (acc, cur) => (cur.phase === 'securing_payment' ? ++acc : acc),
      0
    );
    const completedOffers = offers.reduce(
      (acc, cur) => (cur.status === 'complete' ? ++acc : acc),
      0
    );
    const cancelledOffers = offers.reduce(
      (acc, cur) =>
        cur.status === 'cancelled_by_tenant' ||
        cur.status === 'rejected_by_landlord'
          ? ++acc
          : acc,
      0
    );

    return [
      {
        viewValue: 'All active offers',
        value: 'all',
        amount: allActiveOffers.length,
      },
      {
        viewValue: 'Pending offers',
        value: 'application',
        amount: pendingOffers,
      },
      {
        viewValue: 'Referencing',
        value: 'references',
        amount: acceptedOffers,
      },
      {
        viewValue: 'Agreement',
        value: 'tenancy_agreement',
        amount: referencesCompleteOffers,
      },
      {
        viewValue: 'Awaiting payment',
        value: 'securing_payment',
        amount: awaitingPaymentOffers,
      },
      {
        viewValue: 'Completed',
        value: 'complete',
        amount: completedOffers,
      },
      {
        viewValue: 'Cancelled',
        value: 'cancelled',
        amount: cancelledOffers,
      },
    ];
  }

  getStatusInfo(offer: any) {
    let statusTxt: string;
    let nextStepTxt: string;
    const lastModified = offer.date_modified.toDate();
    const offerId = offer.tenancy_offer_id;
    const phase = offer.phase;

    if (
      offer.status === TENANCY_OFFER_SCHEMA.CANCELLED_BY_TENANT ||
      offer.status === TENANCY_OFFER_SCHEMA.REJECTED_BY_LANDLORD
    ) {
      return {
        statusTxt: 'Cancelled - ' + moment(lastModified).fromNow(),
        nextStepTxt:
          status === 'cancelled_by_tenant'
            ? `This offer was cancelled by the tenant`
            : `This offer was rejected by you`,
      };
    }
    switch (offer.phase) {
      case 'references':
        statusTxt = 'Offer accepted - ' + moment(lastModified).fromNow();
        nextStepTxt = `Review references`;
        break;

      case 'tenancy_agreement':
        statusTxt = 'References complete - ' + moment(lastModified).fromNow();
        nextStepTxt = `Tenancy Agreement`;
        break;

      case 'securing_payment':
        statusTxt = 'Agreement signed - ' + moment(lastModified).fromNow();
        nextStepTxt =
          offer.status && offer.status === 'complete'
            ? 'Offer Complete'
            : `Awaiting payment`;
        break;

      case 'payment_complete':
        statusTxt = 'Payment received ' + moment(lastModified).fromNow();
        nextStepTxt = `View move-in checklist`;
        break;

      default:
        statusTxt = '';
        nextStepTxt = '';
        break;
    }
    return { statusTxt, nextStepTxt, offerId, phase, offer };
  }

  landlordRequestHoldingDeposit(
    tenancy_offer_id: string,
    holding_deposit_reference_code: string
  ) {
    const body = {
      tenancy_offer_id,
      holding_deposit_reference_code,
    };
    return this._http.post(
      environment.firebaseConfig.apiUrl +
        '/tenancyOffer-landlordRequestHoldingDeposit',
      body
    );
  }

  tenantPayHoldingDeposit(tenancy_offer_id: string) {
    return this._http.post(
      environment.firebaseConfig.apiUrl +
        '/tenancyOffer-tenantMarkHoldingDepositNotifiedPaid',
      { tenancy_offer_id }
    );
  }

  changeProperty(id: string, assigned_property_id: string) {
    const item = {
      property_id: assigned_property_id,
      date_modified: new Date(),
    };
    return fromPromise(this.updateTenancyOffer(item, id));
  }
  createTenancyOfferPayments(tenancy_offer_id: string) {
    return this._http.post(
      environment.firebaseConfig.apiUrl +
        '/payments-createTenancyOfferPayments',
      { tenancy_offer_id }
    );
  }
}

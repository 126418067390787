import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import * as moment from 'moment';
import { PropertyModel } from '@app/core-landlord/property/_models/property.model';
import { FormBuilder, Validators, FormGroup, FormControl, FormArray } from '@angular/forms';
import { AuthService } from '@app/core/services';
import { PropertyService, StorageService } from '@app/services';
import { finalize, last, map, mergeMap, switchMap, take, tap } from 'rxjs/operators';
import { DateAdapter, MAT_DATE_LOCALE, MAT_DATE_FORMATS } from '@angular/material';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DatesService, UK_FORMAT } from '@app/shared/_services/dates.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PROPERTY_DOCS } from '@app/_constants/property_docs.constants';
import { of } from 'rxjs';
import { MediaService } from '@app/services/media.service';
import { IMedia } from '@rentbunk/bunk-models';
export const DOCUMENT_TYPES = [
  { text: 'Energy Performance Certificate', value: 'EPC' },
  { text: 'Gas safety', value: 'GAS' },
  { text: 'Fire Safety', value: 'Fire Safety' },
  { text: 'Floorplan', value: 'Floorplan' },
  { text: 'Electric Installation Condition Report', value: 'EICR' },
  { text: 'Inventory', value: 'Inventory' },
  { text: 'Deposit certificate', value: 'Deposit certificate' },
  { text: 'Other', value: 'other' },
];

export const EPC_RATING = ['A', 'B', 'C', 'D', 'E', 'F', 'G'];
@Component({
  selector: 'upload-documents',
  template: `
  <h1 class="margin-bottom">Add a document</h1>
  <form [formGroup]="documentForm" (ngSubmit)="submitDocument(documentForm.value)" >
    <bunk-form-field>
        <label bunk-label for="document-name">What is the document name? </label>
        <mat-form-field bunk-input appearance="outline" class="w_100">
          <mat-select formControlName="document_type" placeholder="Please select">
            <mat-option *ngFor="let type of documentTypes" id="document-name" [value]="type.value">{{type.text}}</mat-option>
          </mat-select>
          <mat-error>Document type required</mat-error>
      </mat-form-field>
    </bunk-form-field>

    <bunk-form-field *ngIf="document_type.value === 'other'">
      <label bunk-label for="other-name">Please enter the document name </label>
      <mat-form-field bunk-input appearance="outline" class="w_100" >
          <input matInput formControlName="other" id="other-name" type="text" placeholder="e.g Insurance certificate">
      </mat-form-field>
    </bunk-form-field>
    <bunk-form-field *ngIf="document_type.value === 'other'">
      <label bunk-label for="other-name">Does the document have an expiry date?</label>
      <div bunk-input appearance="outline" class="w_100" >
        <mat-radio-group  [formControlName]="'has_expiry'" class="mb_5">
              <mat-radio-button [value]="true" class="mr_5">Yes</mat-radio-button>
              <mat-radio-button [value]="false">No</mat-radio-button>

        </mat-radio-group>
      </div>
    </bunk-form-field>
    <bunk-form-field *ngIf="document_type.value !== 'Floorplan'" [hidden]="document_type.value === 'other' && has_expiry.value === false || document_type.value === 'Inventory'">
    <label bunk-label for="document-expiry">When does this document expire?</label>
      <mat-form-field bunk-input appearance="outline">
        <input formControlName="expiry_date" matInput (click)="documentExpiry.open()" placeholder="Expiry"
          [matDatepicker]="documentExpiry" [min]="currentDate">
        <mat-datepicker-toggle matSuffix [for]="documentExpiry"></mat-datepicker-toggle>
        <mat-datepicker #documentExpiry></mat-datepicker>
      </mat-form-field>
    </bunk-form-field>
    <bunk-form-field *ngIf="document_type.value === 'EPC'" blockQuote="true">
      <label bunk-label for="epc-rating">What is the EPC rating?</label>
      <mat-form-field  bunk-input appearance="outline" class="w_100" >
        <mat-select formControlName="epc_rating" id="epc-rating">
          <mat-option *ngFor="let rating of epcRatings" [value]="rating">{{rating}}</mat-option>
        </mat-select>
        <mat-error>EPC rating required</mat-error>
      </mat-form-field>
    </bunk-form-field>

    <bunk-form-field >
      <label bunk-label for="upload-document">Upload your document</label>

      <div bunk-input  class="w_100">
        <input-uploader
          #uploadDocumentInput
          [multiple]="false"
          (handleDocs)="onUploadedFile($event)"
          ></input-uploader>
          <button [hidden]="image_url.value" class="btn__info btn--round" (click)="uploadDocumentInput.click()" type="button">
          <mat-icon>add</mat-icon>
          <span>Add document</span>
          </button>
          <small [hidden]="image_url.value" class="flex margin-top--sm">We support: PDF, DOC, DOCX, JPG, JPEG, JPEG & PNG files - Max 5MB</small>
          <mat-error class="flex bunk-mat-error-font-match" *ngIf="image_url.getError('required')">Document required</mat-error>
          <div class="cc">
            <div class="document-container" *ngIf="image_url.value">
              <div>
                <mat-icon *ngIf="image_url.value && isCertificatePDF(image_url.value)" svgIcon="pdf-icon"></mat-icon>
                <img *ngIf="image_url.value !== null && !isCertificatePDF(image_url.value)" src="{{image_url.value}}" class="doc-img"/>
              </div>
              <span *ngIf="documentFile; else documentName">{{documentFile.name}}</span>
              <ng-template #documentName>{{document_type.value}}</ng-template>
              <div class="flex flex-row">
                <span class="view-circle" role="button" type="button">
                <mat-icon svgIcon="search-icon" class="view-icon" (click)="openDocument(image_url.value)"></mat-icon>
                </span>
                <mat-icon svgIcon="bin-icon" class="doc-icon" (click)="remove(image_url.value)"></mat-icon>
            </div>
            </div>
          </div>
      </div>

    </bunk-form-field>

    <div class="flex justify-end">
      <button type="submit" class="btn__generic">
        <span  *ngIf="!submittingData"> {{(index ?  'Update' : 'Save')}} </span>
        <processing-spinner *ngIf="submittingData" color="#FFFFFF" [complete]="submittingDataSuccess"></processing-spinner>
      </button>
    </div>
  </form>
`,
  styleUrls: ['./form-upload-documents.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },
    { provide: MAT_DATE_FORMATS, useValue: UK_FORMAT }
  ]
})
export class UploadDocumentsForm implements OnInit {
  @Input() get property() {
    return this.propertyData
  }
  propertyData
  set property(data) {
    this.propertyData = data;
    if (!this.bedroomId) {
      this.mediaIds = data.media_ids ? data.media_ids : [];
    }
  }
  mediaIds = [];
  @Input() get bedroomId() {
    return this._bedroomId;
  }
  set bedroomId(id: string) {
    if (id) {
      this._bedroomId = id;
      this.mediaIds = this.property ? this.property.bedrooms[id].media_ids || [] : [];
    }
  }
  _bedroomId: string;
  @Input() index?: number; // Used to edit documents
  @Output() closeOverly?: EventEmitter<boolean> = new EventEmitter<boolean>();

  public isDesktop: boolean;
  public display = {};
  public documentTypes = DOCUMENT_TYPES;
  public epcRatings = EPC_RATING;
  public currentDate = new Date();
  public documentForm: FormGroup;
  public filePath: string;
  public documentFile: any;
  public submittingDataSuccess = false;
  public submittingData = false;
  mediaData;
  constructor(
    private _formBuilder: FormBuilder,
    private _auth: AuthService,
    private _property: PropertyService,
    private _storage: StorageService,
    private _router: Router,
    public _date: DatesService,
    private _activatedRoute: ActivatedRoute, private _media: MediaService) {
    this.documentForm = this.initFormGroup();
  }

  get document_type(): FormControl {
    return this.documentForm.get('document_type') as FormControl;
  }

  get property_documents(): FormArray {
    return this.documentForm.get('property_documents') as FormArray;
  }

  get image_url(): FormControl {
    return this.documentForm.get('image_url') as FormControl;
  }

  get has_expiry(): FormControl {
    return this.documentForm.get('has_expiry') as FormControl;
  }
  get expiry_date(): FormControl {
    return this.documentForm.get('expiry_date') as FormControl;
  }
  get other(): FormControl {
    return this.documentForm.get('other') as FormControl;
  }
  get epc_rating(): FormControl {
    return this.documentForm.get('epc_rating') as FormControl;
  }

  ngOnInit() {
    this.filePath = this.bedroomId
      ? `user/${this._auth.currentUserId}/property/${this.property.property_id}/${this.property.bedroom_id}/certificate` :
      `user/${this._auth.currentUserId}/property/${this.property.property_id}/certificate`;


    if (this.index >= 0) {
      const doc = this.bedroomId ? this.property.bedrooms[this.bedroomId].property_documents[this.index] : this.property.property_documents[this.index];
      this.image_url.patchValue(doc.image_url);

      if (doc.expiry_date) {
        this.expiry_date.patchValue(doc.expiry_date.toDate());
      }
      if (this.index >= 7) {
        this.document_type.patchValue('other');
        this.other.patchValue(doc.name);
        if (doc.expiry_date) {
          this.has_expiry.patchValue(true);
        }
        if (doc.name === 'Inventory') {
          this.document_type.patchValue('Inventory')
        }
      } else {
        this.document_type.patchValue(doc.name);
      }
      if (doc.name === 'EPC') {
        this.epc_rating.patchValue(doc.epc_rating);
      }

      console.log(this.property_documents.value, 'property docs in value');

    }

  }

  getMomentDate(date: any) {
    return moment(date.toDate()).format('DD MMM YYYY');
  }

  checkIfDocExpired(date: any) {
    const expiredDate = this._date.getTimeZone();
    const docDate = date ? date.toDate() : null;
    switch (true) {
      case expiredDate.isAfter(docDate):
        return 'Expired';
      case expiredDate.isBefore(docDate):
        return 'Up-to-date';
      default:
        return 'Incomplete';
    }
  }

  initFormGroup() {
    return this._formBuilder.group({
      document_type: ['', Validators.required],
      other: [''],
      image_url: [null],
      expiry_date: null,
      epc_rating: null,
      has_expiry: null,

      property_documents: this._formBuilder.array([
        this._formBuilder.group({
          name: 'EPC',
          image_url: null,
          expiry_date: null,
          epc_rating: null
        }),
        this._formBuilder.group({
          name: 'GAS',
          image_url: null,
          expiry_date: null
        }),
        this._formBuilder.group({
          name: 'Fire Safety',
          image_url: null,
          expiry_date: null
        }),
        this._formBuilder.group({
          name: 'Floorplan',
          image_url: null
        }),
        this._formBuilder.group({
          name: 'EICR',
          image_url: null,
          expiry_date: null
        }),
        this._formBuilder.group({
          name: 'Inventory',
          image_url: null,
        }),
        this._formBuilder.group({
          name: 'Deposit certificate',
          image_url: null,
        })

      ])
    })
  }

  onUploadedFile = (task: any) => {
    this.documentFile = (task.target as HTMLInputElement).files[0];
    this._storage
      .uploadFile(task, this.filePath)
      .pipe(
        last(),
        tap(data => { this.mediaData = data }),
        mergeMap(() => this._storage.downloadURL),
        tap((url: string) => {
          this.image_url.patchValue(url);
        },
        )
      )
      .subscribe(
        success => console.log('Upload certificate: ', success),
        error => {
          console.log('An error as occurred: ', error)
        }
      );
  };



  submitDocument(value: any) {
    const docs = this.bedroomId ? this.property.bedrooms[this.bedroomId].property_documents : this.property.property_documents ? this.property.property_documents : PROPERTY_DOCS;
    if (value.image_url === null) {
      this.image_url.setErrors({ required: true });
    }
    console.log('DOES INDEX EXIST', this.index);
    if (this.documentForm.valid) {
      this.submittingData = true;
      this.documentForm.get('property_documents').patchValue(docs);

      if (value.document_type === 'other' && !this.index) {
        const otherDoc = {
          name: value.other,
          expiry_date: value.expiry_date ? moment(value.expiry_date).toDate() : null,
          image_url: this.image_url.value
        };

        docs.push(otherDoc);
      } else {
        console.log(this.property_documents.value, 'BEFORE UPDATING ONE');

        const docIndex = this.documentTypes.findIndex(x => x.value === value.document_type);
        docs[docIndex].image_url = this.image_url.value;
        docs[docIndex].name = value.document_type;

        value.expiry_date = value.expiry_date ? moment(value.expiry_date).toDate() : null;
        docIndex !== 3 && docIndex !== 5 && docIndex !== 6 ? docs[docIndex].expiry_date = value.expiry_date : docs[docIndex].expiry_date = null;
        docIndex === 0 ? docs[docIndex].epc_rating = value.epc_rating : null;
        console.log(docs, 'HELLO DOCS');

      }

      if (this.index >= 7) {

        docs[this.index].image_url = this.image_url.value;
        docs[this.index].name = value.other;
        value.expiry_date = value.expiry_date ? moment(value.expiry_date).toDate() : null;
        docs[this.index].expiry_date = value.expiry_date ? docs[this.index].expiry_date = value.expiry_date : null;
      }


      let finalDocs;
      if (this.bedroomId) {
        finalDocs = {
          bedrooms: {
            [this.bedroomId]: {
              property_documents: [
                ...docs
              ]
            }
          }
        };
      } else {
        finalDocs = {
          property_documents: [
            ...docs
          ]
        };
      }

      return this._property.updatePropertyDoc(this.property.property_id, this.bedroomId, finalDocs, this.property.is_editing).pipe(
        switchMap(() => {
          if (this.mediaIds.length) {
            return this._media.getMediaFromIds(this.mediaIds).pipe(map((medias: IMedia[]) => {
              return medias.filter(media => media.type === 'property_documents');
            }))
          }
          return of([])
        }),
        switchMap((medias: IMedia[]) => {
          const viewName = this.document_type.value === 'other' ? this.other.value : this.document_type.value;
          const exisitingDoc = medias.find(media => media.view_name === viewName);
          if (exisitingDoc) {
            this._media.removeMediaById(exisitingDoc.media_id);
            this.mediaIds = this.mediaIds.filter(mediaId => mediaId !== exisitingDoc.media_id);
          }
          const data = this._media.generateMetaData({ ...this.mediaData, url: this.image_url.value }, this.expiry_date.value, viewName, this.property.property_id, this.epc_rating.value);
          return this._media.addMedia(data);

        }),
        switchMap((mediaId: string) => {
          this.mediaIds = [...this.mediaIds, mediaId];
          let finalDocs;
          if (this.bedroomId) {
            finalDocs = {
              date_modified: new Date(),
              bedrooms: {
                [this.bedroomId]: {
                  media_ids: this.mediaIds
                }
              }
            };
          } else {
            finalDocs = {
              date_modified: new Date(),
              media_ids: this.mediaIds
            };
          }
          return this._property.updatePropertyDoc(this.property.property_id, this.bedroomId, finalDocs, this.property.is_editing)
        })
      ).subscribe(() => {
        this.submittingData = false;
        this._router.navigate(['./'], { queryParams: { document_uploaded: true }, relativeTo: this._activatedRoute });
        this.closeOverly.emit();
        this.submittingDataSuccess = true;
        this.documentForm.reset();
      });
    }
  }

  remove(image_url: string) {
    this._storage.deleteFileFromStorageByURL(image_url);
    this.image_url.reset();
  }

  openDocument(url: string) {
    window.open(url, '_blank');
  }

  isCertificatePDF = (url: string): boolean => {
    return (url.toString().toLowerCase().indexOf('.pdf') > 0);
  };

}

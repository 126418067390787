import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PaymentsRoutingModule } from './payments-routing.module';
import { PaymentsOverviewComponent } from './components/payments-overview/payments-overview.component';
import { PaymentsManageComponent } from './payments-manage/payments-manage.component';
import { SharedModule, UserModule } from '@app/shared';
import { NgxPaginationModule } from 'ngx-pagination';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    NgxPaginationModule,
    UserModule,
    PaymentsRoutingModule
  ],
  declarations: [PaymentsOverviewComponent, PaymentsManageComponent],
  exports:[PaymentsOverviewComponent]
})
export class PaymentsModule { }

<div class="i-search" *ngIf="resultsList">
  <img class="i-search__input-icon" src='/assets/svg/baseline-search-24px.svg' alt="Search...">
  <button *ngIf="searchInput.value.length > 1" class="i-search__input-icon-close" (click)="clearSearch()">
    <img src='/assets/svg/baseline-close-24px.svg' alt="Close list" (click)="hideResults = true">
  </button>
  <input #searchInput placeholder="{{ !disabled ? message : disabledMessage }}" autocomplete="off"
    class="i-search__input" [disabled]="disabled" [ngClass]="{ 'i-search__input--active': showHits }"
    (input)="setSearchQuery($event)" (click)="setSearchQuery($event)" (keydown.esc)="hideSearchResults()" (keydown.backspace)="clearSearch(false)" />
  
    <div #searchHits class="i-search__hits-container" *ngIf="showHits" (clickOutside)="clickHideSearchResults($event)">
    <ng-container *ngFor="let hit of resultsList">
        <ng-container *ngTemplateOutlet="algoliaList; context: {hit: hit}"></ng-container>
    </ng-container>
  </div>
</div>

<ng-template #algoliaList let-hit="hit">
  <label type="label" (click)="setValueChanges(hit, value)" *ngIf="getValueFromObject(hit,fieldsToDisplay) as value"
    (keydown.space)="setValueChanges(hit, value)" (keydown.enter)="setValueChanges(hit, value)" (keydown.esc)="hideSearchResults()">
    <ul class="i-search__hit-row" tabindex="0" aria-role="button" attr.aria-label="Add {{value}}">
      <profile-avatar size="sm" *ngIf="showAvatar" [profileImgUrl]="hit.profile_data.profile_image_url"
        [profileName]="hit.profile_data.full_name"></profile-avatar>
      <p>
        {{value}}
      </p>
      <mat-checkbox *ngIf="multiselect" class="prompt" #checkbox [id]="getValuesFromObject(hit,fieldsToReturn)[0]" [checked]="isChecked(getValuesFromObject(hit,fieldsToReturn)[0])">
      </mat-checkbox>

    </ul>
  </label>
</ng-template>
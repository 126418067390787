<screen-detection (resized)="isDesktop = $event.isDesktop"></screen-detection>
<div class="main__wrapper main__wrapper--signin" [hidden]="hideForm">
  <div class="signin__section signin__section--background"
    [ngClass]="{'signin__section--background--cover': client_data.company_name === 'Bunk'}">
    <div class="logo">
      <a href="{{client_data.website_url}}"> <img src="{{logo}}" alt="{{client_data.company_name}}"> </a>
    </div>
  </div>

  <div class="signin__section">

    <div class="signin__signup">
      <small>Don't have a {{client_data.company_name}} account? <a [routerLink]="['/' + routes.Auth.signup]">Sign
          Up</a></small>
    </div>
    <div class="signin__form">

      <span class="signup__back-btn" type="button" *ngIf="isPasswordForgotten" (click)="toggleIsPasswordForgotten()">
        <mat-icon svgIcon="chevron-left"></mat-icon>
      </span>

      <h1 class="margin-bottom">{{(isPasswordForgotten ? 'Forgot password?' : 'Sign in to '+client_data.company_name)}}
      </h1>

      <form [formGroup]="formGroup" *ngIf="!isPasswordForgotten; else passwordForgotten" (ngSubmit)="emailLogin()">
        <div class="block-info block-info--warning" *ngIf="showError || passwordReset">
          <span *ngIf="showError">Incorrect email or password.</span>
          <span *ngIf="passwordReset">Too many unsuccessful login attempts. </span>
          <button *ngIf="passwordReset" class="btn__hyperlink btn__hyperlink--grey" role="button"
            aria-roledescription="Reset password button" (click)="isPasswordForgotten =  true">Click here to reset your
            password</button>
        </div>


        <mat-form-field appearance="outline">
          <mat-label>Email address</mat-label>
          <input id="email" type="text" matInput [formControlName]="'email'">
          <mat-error *ngIf="email.hasError('required')">Email is required</mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Password</mat-label>
          <input id="password" type="password" matInput [formControlName]="'password'">
          <mat-error *ngIf="password.hasError('required')">Password is required</mat-error>
        </mat-form-field>
        <button class="btn__hyperlink btn__hyperlink--grey btn__hyperlink--signin" (click)="isPasswordForgotten = true"
          type="button">Forgot your password?</button>
        <button id="user-signin" class="btn__generic btn--lg margin-top--md signin__button" type="submit"
          [disabled]="showSpinner"> <span *ngIf="!showSpinner; else spinner">Sign In</span></button>
      </form>
    </div>
  </div>
</div>

<ng-template #passwordForgotten>
  <p>Enter the email address used for this account and we will send you a link to reset your password.</p>
  <form [formGroup]="formGroup" (ngSubmit)="resetPassword()">
    <mat-form-field appearance="outline">
      <mat-label>Email address</mat-label>
      <input type="text" matInput [formControlName]="'email'">
      <mat-error>Email is required</mat-error>
    </mat-form-field>
    <button type="submit" class="btn__generic btn--lg margin-top--md signin__button" [disabled]="showSpinner">
      <span *ngIf="!showSpinner; else spinner">Send reset link</span></button>
  </form>
</ng-template>

<ng-template #spinner>
  <processing-spinner color="#FFFFFF"></processing-spinner>
</ng-template>
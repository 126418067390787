import { Router } from '@angular/router';
import { switchMap } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { TasksService } from '@app/services/tasks.service';
import * as moment from 'moment';
import { Observable, of } from 'rxjs';

@Component({
  selector: 'tasks-summary',
  templateUrl: './tasks-summary.component.html',
  styleUrls: ['./tasks-summary.component.scss']
})
export class TasksSummaryComponent implements OnInit {

  public tasks$: Observable<any>;
  public assignee$: Observable<any>;

  constructor(private _task: TasksService, private _router: Router) {

    this.tasks$ = this._task.getTasks(20);

    this.assignee$ = this._task.getTeamMembers().pipe(
      switchMap((data: any) => {
        return of(data.map(item => ({ [item.uid]: { name: item.name, url: item.profile_image_url } }))
          .reduce((a, b) => ({ ...a, ...b })))
      })
    )

  }

  ngOnInit() {
  }
  navigateToTask(id) {
    this._router.navigate(['/tasks'], { queryParams: { viewTask: id } });

  }
  dueDate(due) {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    due.setHours(0, 0, 0, 0);
    const difference = moment(due).diff(today, 'days');

    if (difference === 0) {
      return { value: 'Today', color: 'success' }
    }
    if (difference === 1) {
      return { value: 'Tomorrow', color: 'subtle' }
    }
    if (difference > -7 && difference < 1) {
      return { value: `${Math.abs(difference)} days ago`, color: 'danger' }
    }
    if (difference <= -7) {
      return { value: '+ 1 week ago', color: 'danger' }
    } else {
      return { value: 'date', color: difference > 1 ? 'subtle' : 'danger' }
    }

  }

}

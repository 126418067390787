import {IProperty} from '@rentbunk/bunk-models';
import {CompanyName} from '@shared/shared.constants';

export  interface CountdownData {
  expiredDays: number;
  countdownColor: string;
}

export const showCountdown = (property: IProperty, seconds: number): boolean => {
  return CompanyName.isNrla
    && property.is_listed
    && property.date_listed
    && getCountdownData(seconds).expiredDays <= 30
    && getCountdownData(seconds).expiredDays >= 0;
};
export const getCountdownData = (seconds: number): CountdownData => {
  const currentDateTime = new Date().getTime();
  const expiresDateTime = new Date(seconds * 1000).getTime();
  const expiredDays = 30 - Math.ceil((currentDateTime - expiresDateTime) / (1000 * 3600 * 24));
  const countdownColor = (expiredDays <= 10 && '#ff453a') || (expiredDays > 10 && expiredDays <= 20 && '#ff8f10') || (expiredDays > 20 && expiredDays <= 30 && '#119ae4');
  return {
    expiredDays,
    countdownColor
  };
};

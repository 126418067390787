import {Component, Input} from '@angular/core';
import {MenuPositionX, MenuPositionY} from '@angular/material/typings/menu';

@Component({
  selector: 'bunk-menu',
  template: `
    <button mat-button [matMenuTriggerFor]="menu">
      <ng-content select="[menu-button]"></ng-content>
    </button>
    <mat-menu #menu="matMenu"
              [xPosition]="xPosition"
              [yPosition]="yPosition"
    >
      <ng-content select="[menu-item-top]"></ng-content>
      <button mat-menu-item *ngFor="let item of menuItem" [routerLink]="item.url">{{item.text}}</button>
      <ng-content select="[menu-item-bottom]"></ng-content>
    </mat-menu>
  `,
  styles: [``]
})
export class BunkMenuComponent {
  @Input() menuItem: { text: string, url: any[] | string; }[];
  @Input() xPosition: MenuPositionX;
  @Input() yPosition: MenuPositionY;

  constructor() {
    this.xPosition = 'before';
    this.yPosition = 'below';
  }

}

import { EventEmitter } from '@angular/core';
import * as i0 from "@angular/core";
export class TenantVerifyEventEmiterService {
    constructor() {
        this.gotoStep = new EventEmitter();
        this.gotoPrevStep = new EventEmitter();
        this.gotoNextStep = new EventEmitter();
        this.stepCompleted = new EventEmitter();
        this.stepUpdated = new EventEmitter();
    }
    navigateStep(direction) {
        this.gotoStep.emit(direction);
    }
    prevStep() {
        this.gotoPrevStep.emit();
    }
    nextStep() {
        this.gotoNextStep.emit();
    }
    stepComplete() {
        this.stepCompleted.emit();
    }
    stepUpdate() {
        this.stepUpdated.emit();
    }
}
TenantVerifyEventEmiterService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TenantVerifyEventEmiterService_Factory() { return new TenantVerifyEventEmiterService(); }, token: TenantVerifyEventEmiterService, providedIn: "root" });

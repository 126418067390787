import { Component, Inject } from '@angular/core';
import { FilePreviewOverlayRef } from './file-preview-overlay-ref';
import { PreviewOverlayComponent } from './preview-overlay.component';
import { FILE_PREVIEW_IMAGE } from './file-preview-overlay.tokens';
import { animations } from './bunk-overlay.animations';

@Component({
  selector: 'file-preview-overlay',
  template: `
    <div class="overlay-content" 
      [@slideContent]="animationState"
      (@slideContent.start)="onAnimationStart($event)"
      (@slideContent.done)="onAnimationDone($event)">

      <div *ngIf="image.name" class="overlay__image-header">
        <h2 class="truncate text--md">{{image.name}}</h2>
        <button class="bunk-overlay__close-btn" (click)="close()">close<mat-icon svgIcon="icon-close"></mat-icon></button>
      </div>

      <div class="spinner-wrapper" *ngIf="loading">
        <mat-spinner></mat-spinner>
      </div>

      <img class="overlay-content__image" *ngIf="image" [@fade]="(loading ? 'fadeOut' : 'fadeIn')" (load)="onLoad($event)" [src]="image.url">
      
    </div>
  `,
  styleUrls: ['./bunk-overlay.component.scss'],
  animations: [animations]
})
export class FilePreviewOverlayComponent extends PreviewOverlayComponent {
  constructor(
    public dialogRef: FilePreviewOverlayRef,
    @Inject(FILE_PREVIEW_IMAGE) public image: any) {
      super(dialogRef);
    }
}

import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormControl} from '@angular/forms';
import {BehaviorSubject, Observable, Subject, combineLatest, throwError} from 'rxjs';
import {delay, map, switchMap, take, takeUntil, tap} from 'rxjs/operators';
import {AngularFirestore} from '@angular/fire/firestore';
import {StripeService, UserService} from '@app/services';
import {ToastrService} from 'ngx-toastr';
import {HttpResponse} from '@angular/common/http';
import {PaymentsService} from '@app/services/payments.service';
import {environment} from '@env/environment';

@Component({
  selector: 'coupons-form',
  template: `
    <div class="cc ai_fs flex_auto main_container">
      <h2>Your coupons</h2>
      <div class="text mt_4">In this section you can redeem coupons if you have any.</div>
      <div class="rc ai_c mt_4">
        <mat-form-field (click)="couponCode.enable()">
          <input type="text" matInput [formControl]="couponCode" [name]="couponCode"
                 style="padding-bottom: 2px;" placeholder="Enter code here">
        </mat-form-field>
        <mat-icon *ngIf="couponIsValid" color="accent">check</mat-icon>
        <button (click)="submitCoupon()"
                class="btn__generic ml_4"
                [disabled]="couponCode.disabled"
                *ngIf="!loadingCoupon; else couponIsLoading">Apply
        </button>
        <ng-template #couponIsLoading>
          <loading-spinner class="ml_4"></loading-spinner>
        </ng-template>
      </div>
      <ng-container *ngIf="(queriedCoupons$ | async) as coupons">
        <div *ngIf="couponIsValid" class="mt_4 text_colour_1">
            <span *ngIf="coupons[0].percent_off; else amountOffDiscount">
              Great! That's {{coupons[0].percent_off}}%
              off your first {{coupons[0].duration_in_months}} months of {{client_data.company_name}} membership!
            </span>
          <ng-template #amountOffDiscount>
            Great! That's {{client_data.currency_symbol + coupons[0].amount_off / 100}} off towards your future payments on {{client_data.company_name}}!
          </ng-template>
        </div>
      </ng-container>

      <div *ngIf="couponsSubject$ | async as coupons" class="active-coupons_container w_100">
        <h4 class="mt_4">Active coupons</h4>
        <div *ngFor="let coupon of coupons" class="m-vertical_4 w_100 coupon_container p-all_4">
          <div class="rc jc_sb">
            <div class="b_600 text_colour_1">{{coupon.code}}</div>
            <div *ngIf="coupon.redeemed && coupon.redeemed_at; else couponNotRedeemed"
                 class="text-tiny text_colour_2 text-right">Active since {{coupon.redeemed_at.toDate() | date}}</div>
            <ng-template #couponNotRedeemed>
              <div class="text-tiny text_colour_2 text-right">Not active</div>
            </ng-template>
          </div>

          <div class="mr_8 b_600" *ngIf="coupon.amount_off; else percentOff">
            {{coupon.amount_off / 100 | currency: 'GBP'}} OFF <span
            *ngIf="coupon.target !== 'customer'; else customerCoupon">your {{coupon.target_nickname | titlecase}}
            subscription</span>
          </div>
          <span [ngSwitch]="coupon.duration" class="text">
              <ng-container *ngSwitchCase="'once'">
                Valid for one month
              </ng-container>
              <ng-container *ngSwitchCase="'repeating'">
                Valid for {{coupon.duration_in_months}} months
              </ng-container>
              <ng-container *ngSwitchCase="'forever'">
                Valid for all future payments
              </ng-container>
          </span>
          <ng-template #customerCoupon> any payment made on {{client_data.company_name}}</ng-template>
          <ng-template #percentOff>
            <div class="mr_8 b_600">
              {{coupon.percent_off}}% OFF <span
              *ngIf="coupon.target !== 'customer'; else customerCoupon">your {{coupon.target_nickname | titlecase}}
              subscription</span>
            </div>
          </ng-template>
        </div>
        <div *ngIf="coupons.length <= 0" class="text_colour_2 text mt_4">You don't have any active coupons.</div>
      </div>

    </div>
  `,
  styleUrls: ['./coupons.component.scss']
})
export class CouponsComponent implements OnInit, OnDestroy {

  public couponIsValid: boolean;
  public couponCode = new FormControl('');
  public queriedCoupons$: Observable<any>;
  public loadingCoupon: boolean;
  public couponId: string;
  public couponsSubject$: Subject<any> = new BehaviorSubject<any>([]);
  private destroy$: Subject<boolean> = new Subject<boolean>();
  public client_data = environment.client_data;

  constructor(private readonly _afs: AngularFirestore,
              private _stripe: StripeService,
              private _toastr: ToastrService,
              private _user: UserService,
              private _pmt: PaymentsService) {

    this._user.user$  /// Listen for updates on userDoc!
      .pipe(
        takeUntil(this.destroy$),
        switchMap(() => this._pmt.getUserCoupons()))
      .subscribe(data => {
        this.couponsSubject$.next(data);
      });
  }

  ngOnInit() {
  }

  submitCoupon() {
    this.queriedCoupons$ = this._afs.collection('coupons', ref => ref.where('code', '==', this.couponCode.value).limit(1)).valueChanges();
    this.loadingCoupon = true;
    this.queriedCoupons$
      .pipe(
        take(1),
        switchMap(coupons => {
          if (coupons[0].valid) { this.couponId = coupons[0].id; }
          return this._stripe.retrieveCoupon(this.couponCode.value);
        }),
        switchMap((response: HttpResponse<any>) => {
          this.couponCode.disable();
          if (!response.body.data.valid) {
            return throwError('This coupon is invalid or it\'s already been redeemed');
          } else {
            return this._stripe.redeemCoupon(this.couponCode.value, this.couponId);
          }

        }),
        tap((response: HttpResponse<any>) => {
          if (response.status === 205) {
            this.loadingCoupon = false;
            this._toastr.warning(response.body.message);
          } else {
            this.loadingCoupon = false;
            this.couponIsValid = true;
          }
        }),
        delay(3500),
        tap(() => {
          this.couponIsValid = false;
          this.couponCode.reset();
        }),
        take(1),
      ).subscribe(() => {
      },
      () => {
        this.loadingCoupon = false;
        this.couponIsValid = false;
        this.couponCode.disable();
        this._toastr.error('This coupon is invalid or it\'s already been redeemed').onHidden.subscribe(() => {
          this.couponCode.reset();
          this.couponCode.enable();
        });
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

}

import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {MatDialog, MatDialogConfig, MatDialogRef} from '@angular/material/dialog';
import {ComponentType} from '@angular/cdk/portal';
import {BreakpointObserver, Breakpoints} from '@angular/cdk/layout';
import {Subject, Subscription} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

/*
 * Bunk Dialog
 * https://v6.material.angular.io/components/dialog/overview
 *
     <ng-template #contentDialog>
      <h1>ViewContainerRef</h1>
    </ng-template>
    <bunk-dialog [component]="contentDialog">
      <button class="btn btn_blue">Test me</button>
    </bunk-dialog>
 *
 *   </bunk-dialog>
 */

@Component({
  selector: 'bunk-dialog',
  template: `

    <span (click)="openDialog()">
      <ng-content></ng-content>
    </span>
  `,

})
export class BunkDialogComponent implements OnInit, OnDestroy {
  @Input() config: MatDialogConfig;
  @Input() component: ComponentType<any>;
  @Input() snapFullScreen?: Boolean;

  @Input() restrict?: Boolean;

  @Output() dialogRef: EventEmitter<MatDialogRef<any>> = new EventEmitter<MatDialogRef<any>>();
  @Output() onClosed: EventEmitter<boolean> = new EventEmitter<boolean>();

  public matDialogRef: MatDialogRef<any>;
  public smallDialogSubscription: Subscription;

  private readonly bunkDialogConfig = {
    width: '600px',
  };

  // Setting default maxWidth as cant use 'updateSize' for max & need to override the bunk 80vw
  private readonly bunkDialogConfigFullScreen = {
    maxWidth: '100vw',
    panelClass: 'mat-dialog-no-border-radius'
  };
  // Using for sign up form - full screen on mobile and cannot close by clicking away
  private readonly bunkDialogRestrict = {
    disableClose: true,
    maxWidth: '100vw',
    panelClass: 'mat-dialog-no-border-radius',
  };

  private destroy$: Subject<boolean> = new Subject<boolean>();


  constructor(public dialog: MatDialog, private breakpointObserver: BreakpointObserver) {
    this.dialog.afterAllClosed
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
      this.onClosed.emit(true);
    })
  }

  ngOnInit(): void {
    // Change into switch case??
    (this.snapFullScreen) ? this.config = (!this.config) ?
    this.bunkDialogConfigFullScreen : {...this.bunkDialogConfigFullScreen, ...this.config}
    : this.restrict ? this.config = (!this.config) ? this.bunkDialogRestrict : {...this.bunkDialogRestrict, ...this.config}
    : this.config = (!this.config) ? this.bunkDialogConfig : {...this.bunkDialogConfig, ...this.config} ;

  }

  openDialog() {
    this.dialogRef.emit(this.matDialogRef = this.dialog.open(this.component, this.config));

    // using Angular layout built in break points https://v6.material.angular.io/cdk/layout/overview
    this.smallDialogSubscription = this.breakpointObserver.observe(Breakpoints.XSmall)
    .subscribe(size => {
    (size.matches && this.snapFullScreen || size.matches && this.restrict) ? this.matDialogRef.updateSize('100vw', '100%')
    : this.matDialogRef.updateSize(this.config.width || '600px', 'auto');
    });

    this.matDialogRef.afterClosed().subscribe(res => {
      this.smallDialogSubscription.unsubscribe();
    });

  }

  closeAll = () => this.dialog.closeAll();

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}

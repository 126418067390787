import {Component, EventEmitter, forwardRef, Input, OnInit, Output} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {Observable} from 'rxjs/internal/Observable';
import {UserService} from '@app/services';
import {FormArray, FormBuilder, FormControl, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validator, Validators} from '@angular/forms';
import {AuthService} from '@app/core/services';
import {AbstractValueAccessor} from '@app/shared/_components/searches/instant-search/instant-search.utils';
import {flatMap, map, tap} from 'rxjs/operators';
import {TenancyAgreementService} from '@app/documents/services/tenancy-agreement.service';
import {of} from 'rxjs/internal/observable/of';

@Component({
  selector: 'form-add-tenants',
  templateUrl: './form-add-tenants.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormAddTenantsComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => FormAddTenantsComponent),
      multi: true
    }
  ],
  styleUrls: ['form-add-tenants.component.scss']
})

export class FormAddTenantsComponent extends AbstractValueAccessor implements OnInit, Validator {
  public storedIds$: BehaviorSubject<any> = new BehaviorSubject<any>([]);
  public members$: Observable<any>;
  private _submitAttempted = false;
  private _tenantValidation = false;
  public formGroup: FormGroup;

  @Input('submitAttempted') set submitAttempted(attemped: boolean) {
    this._submitAttempted = attemped;
  }

  @Input('tenantValidation') set tenantValidation(validate: boolean) {
    this._tenantValidation = validate;
    if (validate) {
      this.dirtyFormGroup();
    }
  }

  @Input()tenancyAgreement: any;

  @Output() submitTenantValidation: EventEmitter<boolean> = new EventEmitter<boolean>();

  get submitAttempted(): boolean {
    return this._submitAttempted;
  }

  get tenantValidation(): boolean {
    return this._tenantValidation;
  }


  constructor(
    public _user: UserService,
    public _formBuilder: FormBuilder,
    private _auth: AuthService,
    private _tenancyAgreement: TenancyAgreementService
  ) {
    super();
  }

  get new_tenants(): FormArray {
    return this.formGroup.get('new_tenants') as FormArray;
  }

  ngOnInit() {
    this.formGroup = this.initFormGroup();

    if (this.formGroup.value.tenant_uids) {
      this.storedIds$.next(this.formGroup.value.tenant_uids);
    }



    this.members$ = (this.tenancyAgreement.tenancy_agreement_id) ? this._tenancyAgreement.getTenancyAgreementById(this.tenancyAgreement.tenancy_agreement_id).pipe(
      map((agreement: any) => {
        this.storedIds$.next(agreement.tenant_uids);
        if (agreement.new_tenants) {
          this.setMaunalTenants(agreement.new_tenants.length);
          agreement.new_tenants = agreement.new_tenants.map((tenant) => {
            return {
              ...tenant,
              phone: {area_code: tenant.country_code, phone_number: tenant.phone}
            };
          });

          this.new_tenants.patchValue(agreement.new_tenants);
        }
        return agreement;
      }),
      flatMap(() => this.getMembersObservable())
    ) : of([]);

  }

  initFormGroup(): FormGroup {
    return this._formBuilder.group({
      tenant_uids: [[]],
      new_tenants: this._formBuilder.array([])
    });
  }

  initTenants() {
    return this._formBuilder.group({
      first_name: ['', Validators.required],
      last_name: ['', Validators.required],
      email: ['', Validators.required],
      phone: ['', Validators.required],
      is_guest_user: [null,  Validators.required]
    });
  }

  addTenant() {
        this.submitTenantValidation.emit(false)
    this.new_tenants.push(this.initTenants());
  }

  setUserByEmittedId(id: string) {
    if (!this.storedIds$.value || !this.storedIds$.value.includes(id)) {
      this.storedIds$.next((this.storedIds$.value) ? [...this.storedIds$.value, id] : [id]);
      this.formGroup.get('tenant_uids').patchValue( this.storedIds$.value);
      this.members$ = this.getMembersObservable();
    }
  }

  removePlatformMember(idx, id) {
    this.storedIds$.value.splice(this.storedIds$.value.indexOf(id), 1);
    this.formGroup.get('tenant_uids').patchValue(this.storedIds$.value);
    this.members$ = this.getMembersObservable();
  }

  removeNewMember(idx: number) {
      this.submitTenantValidation.emit(false);
    return this.new_tenants.removeAt(idx);

  }

  getMembersObservable() {
    return this._user.getUsersFromIds(this.storedIds$.value);
  }

  checkUserEmail(idx: any) {
    this.new_tenants.at(idx).get('email').patchValue(this.new_tenants.at(idx).get('email').value.trim());
    const email = this.new_tenants.at(idx).get('email') as FormControl;
    if (email.valid) {
      this._auth.checkUserEmail(email.value.trim()).subscribe((res: any) => {
        if (res.length >= 1) {
          email.setErrors({emailExists: true});
          this.formGroup.updateValueAndValidity()
        }

      });
    }
  }

  setMaunalTenants(value: number) {
    if (value === this.new_tenants.length) {
      return;
    }
    let difference: any;
    if (value > this.new_tenants.length) {
      difference = value - this.new_tenants.length;
      for (let i = 0; i < difference; i++) {
        this.addTenant();
      }
    }
    if (value < this.new_tenants.length) {
      difference = this.new_tenants.length - value;
      for (let i = 0; i < difference; i++) {
        this.removeNewMember(this.new_tenants.length - 1);
      }
    }
  }


  dirtyFormGroup() {
    if (this.formGroup) {
      const controls = this.formGroup.controls;
      this.new_tenants.markAllAsTouched();
      for (const control in controls) {
        if (controls.hasOwnProperty(control)) {
          this.formGroup.controls[control].markAsTouched();
        }
      }
    }
  }

  public onTouched: () => void = () => { };

  writeValue(val: any): void {
    super.writeValue(val);
    if (val) {
      if (val.new_tenants) {
        this.formGroup.patchValue(val, { emitEvent: false });
        this.new_tenants.value.push(val.new_tenants);
      }
    }
  }
  registerOnChange(fn: any): void {
    this.formGroup.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.formGroup.disable() : this.formGroup.enable();
  }

  validate() {
    return this.formGroup.valid ? null : { invalidForm: { valid: false, message: 'FormGetPropertyDetailsComponent > formGroup fields are invalid' } };
  }
}

import {Injectable} from '@angular/core';
import {TenantDataModel} from '@app/_models/user-verification.models';

@Injectable()
export class UserVerificationStepsService {

  private _userData: TenantDataModel = {};

  saveUserData(data: TenantDataModel, stepType) {
    switch (stepType) {
      case 'info':
        return this._userData.info = data.info;
      case 'occupation':
        return this._userData.occupation = data.occupation;
      case 'contact':
        return this._userData.contact = data.contact;
      case 'guarantor':
        return this._userData.guarantor = data.guarantor;
    }
  }
  //

  get userData() {
    return this._userData;
  }

}

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class VouchReferencingService {

  constructor(private _http: HttpClient) {}


createReferenceVouch(data): Observable<any> {
  return this._http.post(`${environment.firebaseConfig.apiUrl}/references-createVouchApplication`, data);
}

skipReferencing(tenancy_offer_id : string){
  return this._http.post(`${environment.firebaseConfig.apiUrl}/references-landlordSkipReferences`, {tenancy_offer_id});
}
}

import {Component, Input} from '@angular/core';
import {FormArray} from '@angular/forms';

@Component({
  selector: 'sortable-list',
  template: `
    <div class="rc view-buttons" *ngIf="listData.value[0]">
      <button class="btn__seethrough" type="button" (click)="showGrid = true">
        <i class="material-icons btn-icons">grid_on</i>
        Grid
      </button>
      <button class="btn__seethrough" type="button" (click)="showGrid = false">
        <i class="material-icons btn-icons">list</i>
        List
      </button>
    </div>
    <!-- Grid view -->
    <div [sortablejs]="listData" *ngIf="showGrid; else showList" class="listing cc">
      <ul *ngFor="let item of listData.value; let i = index">
        <div *ngIf="listData.value">
          <li class="cc grid-item card__base--sortable p_5">
            <img src="{{item?.image_large_url}}" class="w_100" alt="" class="photo"/>
            <div class="rc icons">
              <div class="delete" (click)="remove(i)">
                <img src='/assets/svg/delete.svg' class="w_100" alt="">
              </div>
              <div class="rc">
                <i class="material-icons md-48 hide-lg" (click)="swap(i - 1, i)">keyboard_arrow_up</i>
                <i class="material-icons md-48 hide-lg" (click)="swap(i, i + 1)">keyboard_arrow_down</i>
                <i class="material-icons md-48 hide-md hide-sm " (click)="swap(i - 1, i)">keyboard_arrow_left</i>
                <i class="material-icons md-48 hide-md hide-sm " (click)="swap(i, i + 1)">keyboard_arrow_right</i>
              </div>
            </div>
          </li>
        </div>
      </ul>
    </div>
    <!-- List View -->
    <ng-template #showList>
      <div [sortablejs]="listData">
        <ul *ngFor="let item of listData.value; let i = index">
          <div *ngIf="listData.value">
            <li class="card__base--sortable rc list-view">
              <div class="list-photo-container"><img src="{{item?.image_small_url}}" alt="" class="list-photo"/></div>
              <div class="rc list-icons">
                <div class="arrows">
                  <i class="material-icons md-48" (click)="swap(i - 1, i)">keyboard_arrow_up</i>
                  <i class="material-icons md-48" (click)="swap(i, i + 1)">keyboard_arrow_down</i>
                </div>
                <div class="delete" (click)="remove(i)">
                  <img src='/assets/svg/delete.svg' class="w_100" alt="">
                </div>
              </div>
            </li>
          </div>
        </ul>
      </div>
    </ng-template>
  `,
  styleUrls: ['./sortable-list.component.scss']
})
export class SortableListComponent {
  @Input() listData: FormArray;
  public showGrid = true;

  constructor() { }

  swap(index1: number, index2: number) {
    const extrasFormArray = this.listData as FormArray;
    const extras = [...extrasFormArray.value];
    if (index2 > 0 && index1 < extras.length - 1) {
      [extras[index1], extras[index2]] = [extras[index2], extras[index1]];
      extrasFormArray.setValue(extras);
    }
  }

  remove(id: number) {
    this.listData.removeAt(id);
  }

}

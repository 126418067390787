import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Auth, Dashboards, Landlord, Shared, Tenant } from '@env/routing';
import { AuthService } from '@app/core/services';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { finalize, flatMap, first, delay, takeUntil, map } from 'rxjs/operators';
import { UserService } from '@app/services';
import { environment } from '@env/environment';
import { tap } from 'rxjs/internal/operators/tap';
import { CookieService } from 'ngx-cookie-service';
import { of } from 'rxjs/internal/observable/of';
import { Observable } from 'rxjs/Observable';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: [`./signin.component.scss`]
})
export class SigninComponent implements OnDestroy {
  public getCookies$: Observable<any>;
  public isDesktop: boolean;
  public routes = { Auth, Shared };
  public formGroup: FormGroup;
  public isPasswordForgotten = false;
  public showSpinner = false;
  public queryParam: { property_id?: string };
  private destroy$: Subject<boolean> = new Subject<boolean>();
  public client_data = environment.client_data;
  public logo = this.client_data.company_name === 'CampusKey' ? '/assets/logos/white-logo.svg' : '/assets/logos/main-logo.svg';
  showError = false;
  hideForm = false;
  passwordReset = false;
  get email(): FormGroup {
    return this.formGroup.get('email') as FormGroup;
  }

  get password(): FormGroup {
    return this.formGroup.get('password') as FormGroup;
  }

  constructor(
    public _auth: AuthService,
    private _user: UserService,
    private _formBuilder: FormBuilder,
    private _router: Router,
    private _toastr: ToastrService,
    private route: ActivatedRoute,
    private _cookies: CookieService
  ) {
    this.formGroup = this.initFormGroup();
    this.route.queryParams.subscribe(params => {
      this.queryParam = params;
    });
    if (this._cookies.check('BUNKSession') || this._cookies.check('BUNKSessionStaging')) {
      this.hideForm = true;
      const token = (this._cookies.check('BUNKSession')) ? this._cookies.get('BUNKSession') : this._cookies.get('BUNKSessionStaging');
      this._auth.signInWithCustomToken(token).pipe(
        takeUntil(this.destroy$)
      ).subscribe(
        () => this._router.navigate(['/' + Dashboards.base])
      );
    }
  }

  initFormGroup(): FormGroup {
    return this._formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  emailLogin(): void {
    if (this.formGroup.valid) {
      this.showError = false;
      this.formGroup.disable();
      this.showSpinner = true;
      this._auth.signInWithEmailAndPassword({ email: this.email.value.trim(), password: this.password.value.trim() })
        .pipe(
          finalize(() => this.showSpinner = false),
          flatMap(() =>
            this._user.user$
          ),
          first(),
        )
        .subscribe(
          acc => {
            if (!acc) {
              return this._router.navigate(['/', Shared.no_user]);
            }

            if (this.queryParam.property_id) {
              this._router.navigate(['/', 'rent-property', this.queryParam.property_id]);
            }

            if (acc.role === 'tenant') {
              this._router.navigate(['/' + Tenant.base, Tenant.manage.base]);
            }

            if (acc.role === 'landlord') {
              this._router.navigate(['/' + Dashboards.base]);
            }
          },
          err => {
            this.formGroup.enable();
            if (err.code === 'auth/wrong-password' || err.code === 'auth/invalid-email' || err.code === 'auth/user-not-found') {
              this.showError = true;
            }
            if (err.code === 'auth/too-many-requests') {
              this.passwordReset = true;
            }
          }
        );
    }
  }

  resetPassword(): void {
    this.showSpinner = true;
    this.formGroup.disable();
    this._auth.resetPassword(this.email.value.trim())
      .pipe(
        finalize(() => {
          this.showSpinner = false;
          this.formGroup.enable();
        })
      ).subscribe(
        () => {
          this.toggleIsPasswordForgotten();
          this._toastr.success(`Great! An email with a link to reset your password has been sent to: ${this.email.value}`);
        },
        (err: any) => {
          this._toastr.error(err.error.message);
        }
      );
  }

  toggleIsPasswordForgotten(): void {
    this.isPasswordForgotten = !this.isPasswordForgotten;
  }

  testLogin() {
    return this._auth.testLogin().pipe(
      map((res: any) => (this._cookies.check('BUNKSession')) ? of({}) : this._cookies.set('BUNKSession', res.token, null, '/')),
      takeUntil(this.destroy$),
    ).subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }



}

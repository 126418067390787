<form [formGroup]="teamFormGroup">
  <ng-container *ngIf="teamIds && teamIds.length>1">
    <div *ngIf="teams$ | async as teams">
      <ng-container *ngIf="isTenancy;else nonTenancyBlock">
        <bunk-form-field [showLabel]="true">
          <label bunk-label>Select team</label>
          <div bunk-input>
            <mat-form-field appearance="outline" class="w_100">
              <mat-label>Select team</mat-label>
              <mat-select placeholder="Please select" formControlName="team_id">
                <mat-option *ngFor="let team of teams" [value]="team.team_id">{{team.team_name}}</mat-option>
              </mat-select>
              <mat-error>This field is required</mat-error>
            </mat-form-field>
          </div>
        </bunk-form-field>
      </ng-container>

      <ng-template #nonTenancyBlock>
        <h1 class="text--lg" *ngIf="!isTenancy">Select team to manage this property? </h1>
        <mat-form-field appearance="outline" class="w_100">
          <mat-label>Select team</mat-label>
          <mat-select placeholder="Please select" formControlName="team_id">
            <mat-option *ngFor="let team of teams" [value]="team.team_id">{{team.team_name}}</mat-option>
          </mat-select>
          <mat-error>This field is required</mat-error>
        </mat-form-field>
      </ng-template>

    </div>
  </ng-container>
</form>
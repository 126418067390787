import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'card-skeleton',
  template: `
    <ul>
      <li class="card__list-item loading-animation--header" *ngFor="let i of [0, 1, 2, 3, 4, 5]">
        <div class="flex flex-row">
          <div class="card_image loading-animation"
               [ngClass]="{'card_image--small': is_small_card}"></div>
          <div class="flex flex-column">
            <div class="margin-top--sm title loading-animation"></div>
            <div class="margin-top--lg middle loading-animation" *ngIf="!is_small_card"></div>
            <div class="margin-top--lg small loading-animation" [ngClass]="{'margin-top--xl': is_small_card, 'margin-top--lg': !is_small_card}"></div>
          </div>
          <div class="flex flex-column buttons">
            <div class="margin-top--sm button loading-animation" [ngClass]="{'button--rounded': is_small_card}"
            ></div>
            <div class="margin-top--xs button loading-animation" *ngIf="!is_small_card"
            ></div>
          </div>
        </div>
      </li>
    </ul>
  `,
  styleUrls: ['../skeleton-loading.component.scss']
})

export class CardSkeletonComponent implements OnInit {
  @Input() is_small_card = false;
  constructor() {
  }

  ngOnInit() {
  }
}


    <form [formGroup]="formGroup" class="ideal-postcodes__form">
      <mat-form-field appearance="{{appearanceType}}">
        <mat-label *ngIf="labelText">{{labelText}}</mat-label>
        <input matInput
          #addressText
          name="address"
          type="text"
          placeholder="Type postcode here e.g. BS16 1QY"
          [attr.aria-label]="labelText"
          formControlName="address"
          [matAutocomplete]="auto"
          [attr.disabled]="disabled"
          (keyup)="searchAddress(addressText.value)"
          data-test="selectAddressInput">

        <mat-error *ngIf="showError" class="margin-bottom--sm">Please enter the street address</mat-error>

        <mat-autocomplete #auto="matAutocomplete">
          <mat-option *ngFor="let option of options"
            [value]="option.suggestion"
            (onSelectionChange)="chooseAddress(option.udprn)"
            data-test="addressSearchResult">
            {{option.suggestion}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>

    </form>
  
import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { Shared, Landlord } from '@env/routing';
import { TitleCasePipe } from '@angular/common';
import { AuthService } from '@app/core/services';
import { UserService } from '@app/services';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import * as moment from 'moment';
import { TENANT_FIND_100_200, SELF_MANAGE_100_200 } from '../../../../core-landlord/core-landlord.constants';
import { map, take, tap } from 'rxjs/operators';
import { PaymentsService } from '@app/services/payments.service';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { TenancyModel } from '@app/_models/tenancy.model';
import { PropertyModel } from '@app/core-landlord/property/_models/property.model';
import { TenancyService } from '../../../../services/tenancy.service';
import { environment } from '@env/environment';
import { IMedia } from '@rentbunk/bunk-models';
import { MediaService } from '@app/services/media.service';
import { EPropertyType } from '@rentbunk/bunk-models';


@Component({
  selector: 'bedroom-card-list',
  template: `
  <screen-detection (resized)="isDesktop = $event.isDesktop"></screen-detection>
  <span>
  <div class="prop-card__image-main prop-card__image-main--small" [ngClass]="{'image__placeholder': !display.photo}" (click)="gotoProperty()">
    <img
      *ngIf="display.photo"
      src="{{ display.photo }}"
      alt="{{ display.propertyType }}">
  </div>

  <div class="flex flex-row">
    <span class="prop__card-document-icons" *ngIf="display.hasFloorplan || display.totalPictures >= 1">
      <span *ngIf="display.hasFloorplan"> <mat-icon svgIcon="floorplan-icon" class="prop__card-icon"></mat-icon></span>
      <span class="prop__card-divider" *ngIf="display.hasFloorplan"></span>
      <span *ngIf="display.totalPictures >= 1"><mat-icon svgIcon="camera-icon" class="prop__card-icon"></mat-icon>{{display.totalPictures}}</span>
    </span>
    <button class=" btn__preview" type="button" (click)="gotoProperty()"><mat-icon svgIcon="preview-icon"></mat-icon>Preview</button>
  </div>

  <div class="prop-card__body--list">
    <ng-container *ngIf="_user.userDb.role === 'tenant'">
      <span class="prop-card__status-banner prop-card__status-banner--warning" *ngIf="!display.isHMO && display.underOffer">Under Offer</span>
      <span class=" prop-card__status-banner prop-card__status-banner--danger " *ngIf="!display.isListed">Not Available</span>

      <ng-container *ngIf="display.isHMO && display.isListed">
        <span class="prop-card__status-banner prop-card__status-banner--success" *ngIf="display.totalRoomsAvailable >= 1 && !(display.totalRoomsUnderOffer >= 1 && display.totalRoomsAvailable == 1)">{{ (display.totalRoomsAvailable - display.totalRoomsUnderOffer) > 1 ? (display.totalRoomsAvailable - display.totalRoomsUnderOffer) + ' rooms' : display.totalRoomsAvailable + ' room'}} still available! <br> </span>
        <span class="prop-card__status-banner prop-card__status-banner--success" *ngIf="display.totalRoomsUnderOffer >= 1">{{ display.totalRoomsUnderOffer > 1 ? display.totalRoomsUnderOffer + ' rooms' : display.totalRoomsUnderOffer + ' room'  }} under offer </span>
      </ng-container>
    </ng-container>

    <span class="prop-card__title--list">{{ display.roomName }}</span>
    <p class="prop-card__subtitle">{{display.city}}</p>

    <ng-container *ngIf="pricing$ | async">
      <div class="prop__card-pricing-chip" *ngIf="_user.userDb.role === 'landlord' && display.pricingPlan">
        <div>
          <span>{{display.pricingPlan}}</span>
          <span>
            {{ client_data.currency_symbol + this.subscription$.getValue()}}
            /{{(display.pricingPlan === 'Self manage' ? this.subscriptionInterval$.getValue() : 'annually')}}</span>
        </div>
      </div>
    </ng-container>

    <ul class="prop-card__list">

      <li>{{client_data.unit_name}} <span>{{display.bedType}}</span></li>
      <li>En-suite <span>{{display.ensuite}}</span></li>
      <li *ngIf="!display.tenancy">Rent <span *ngIf="display.rent"> {{client_data.currency_symbol + display.rent}}<small>{{rentFrequency(display.rentInterval)}}</small></span></li>
      <li *ngIf="display.tenancy">Rent <span *ngIf="display.rent || (display.tenancy && display.tenancy.rentAmount) "> {{client_data.currency_symbol + display.tenancy && !display.tenancy.rentAmount ? display.rent : display.tenancy.rentAmount }} <small>{{(display.rentInterval === 1 ? '/monthly' : '/quarterly')}}</small></span></li>


      <li>Deposit <span *ngIf="display.deposit">{{client_data.currency_symbol + display.deposit}}</span></li>
      <li>Type <span>{{display.furnished}}</span></li>

      <ng-container *ngIf="tenancy">
        <li *ngIf="display.tenancy.tenancyAgreement">Agreement <span class="prop__card-underline" (click)="getTenancyAgreementDocument(display.tenancy)">View</span></li>
      </ng-container>

    </ul>
  </div>
  <ng-content></ng-content>
</span>
  `,
  styleUrls: ['./property-card.component.scss']
})
export class BedroomCardListComponent implements OnInit {
  @Input() property: PropertyModel;
  @Input() bedroom: any;
  @Input() static = false;
  @Input() tenancy?: TenancyModel;
  @Output() closeOverlay?: EventEmitter<boolean> = new EventEmitter<boolean>();
  public client_data = environment.client_data;

  @Input()
  get tenancyData(): TenancyModel {
    return this.tenancy;
  }
  set tenancyData(event: TenancyModel) {
    if (event) {
      this.tenancy = event;
      this.setBedroomCard();
    }
  }

  public routes = { Shared, Landlord };
  public display: any = {};
  public showMore = false;

  public subscriptionInterval$: BehaviorSubject<string> = new BehaviorSubject(null);
  public subscription$: BehaviorSubject<string> = new BehaviorSubject(null);
  public pricingPlan$: Observable<any>;
  public pricingInterval$: Observable<any>;
  public isDesktop: boolean;
  public pricing$: Observable<any>;

  constructor(
    public _titlecasePipe: TitleCasePipe,
    public _auth: AuthService,
    public _user: UserService,
    public _router: Router,
    private _pmt: PaymentsService,
    private _tenancy: TenancyService,
    private _media: MediaService

  ) { }

  ngOnInit() {
    if (this._user.userDb.role === 'landlord' && this.property.pricing_plan) {
      this.pricingInterval$ = this._pmt.getCurrentUserPaymentsOverviewDoc();
      this.pricingPlan$ = this._pmt.getPricingDocument(this.property.pricing_plan);

      this.pricing$ = combineLatest([this.pricingInterval$, this.pricingPlan$]).pipe(
        map(([interval, pricingPlan]) => {
          if (interval && interval.subscription_interval && this.property.pricing_plan === SELF_MANAGE_100_200) {
            this.subscriptionInterval$.next(interval.subscription_interval);
            this.getSelfManagedSubscriptions(pricingPlan);
          } else {
            this.property.is_hmo ? this.subscription$.next(pricingPlan.unit_net_price_hmo) : this.subscription$.next(pricingPlan.unit_net_price_whole_property);
          }
          return { interval, pricingPlan };
        })
      );
    }

    this.setBedroomCard();

  }

  setBedroomCard() {

    const bedroomInfo = this.property.bedrooms[this.bedroom.bedroom_id];
    const city = this._titlecasePipe.transform(this.property.address && this.property.address.city ? this.property.address.city : null);
    const housePhoto = this.property.property_photos && this.property.property_photos.length ? this.property.property_photos[0].image_large_url : null;
    const bedroomPhoto = this.bedroom.room_thumbnail_url &&
      this.bedroom.room_thumbnail_url.image_url !== null ?
      this.bedroom.room_thumbnail_url.image_large_url : housePhoto;
    const dateAvailable = bedroomInfo.date_available ? this.formatDateAvailable(bedroomInfo.date_available) : undefined;
    if (!(this.property.media_ids && this.property.media_ids.length)) {
      const propertyDocs = this.property.property_documents;
      const floorPlan = propertyDocs && propertyDocs.length ? propertyDocs[3] : null;
      this.display = {
        roomName: this.bedroom.room_name,
        photo: bedroomPhoto,
        rent: this.getRent(this.bedroom.rent_interval_count),
        rentInterval: this.bedroom.rent_interval_count,
        deposit: bedroomInfo.deposit_amount ? bedroomInfo.deposit_amount : null,
        bedType: bedroomInfo.bed_type ? bedroomInfo.bed_type.charAt(0).toUpperCase() + bedroomInfo.bed_type.substr(1) : null,
        ensuite: this.bedroom.has_ensuite ? 'Yes' : 'No',
        furnished: this.property.default_amenities ? this.getFurnishing(this.property.default_amenities) : undefined,
        underOffer: bedroomInfo.is_under_offer || false,
        isListed: bedroomInfo.is_listed,
        city: city + ', ' + (this.property.address && this.property.address.post_code ? this.property.address.post_code.split(' ')[0] : null),
        dateAvailable: !this.tenancy ? dateAvailable : undefined,
        pricingPlan: this.property.pricing_plan ? this.getPricingPlanName(this.property.pricing_plan) : undefined,
        totalPictures: this.property.property_photos && this.property.property_photos.length ? this.property.property_photos.length : null,
        hasFloorplan: propertyDocs && floorPlan
          && floorPlan.image_url ? true : false,
        propertyType: EPropertyType[this.property.property_type],
        tenancy: this.tenancy ? {
          rentAmount: this._tenancy.getTenancyRentAmount(this.tenancy),
          tenancyAgreement: this.tenancy.agreement_url ? this.tenancy.agreement_url : null,
          tenancyAgreementId: this.tenancy.tenancy_agreement_id
        } : null
      };
    }
    else {
      this._media.getMediaFromIds(this.property.media_ids).pipe(map((medias: IMedia[]) => {
        return medias.filter(media => media.type === 'property_documents');
      }), tap(
        (medias: IMedia[]) => {
          const floorDoc = medias.find(media => media.view_name === 'Floorplan');
          let floorPlan = null;
          if (floorDoc) {
            floorPlan = {
              name: floorDoc.view_name || null,
              expiry_date: floorDoc.date_expires ? new Date(floorDoc.date_expires['seconds'] * 1000) : null,
              ...floorDoc.epc_rating && { epc_rating: floorDoc.epc_rating },
              image_url: floorDoc.url || null
            }
          }
          this.display = {
            roomName: this.bedroom.room_name,
            photo: bedroomPhoto,
            rent: this.getRent(this.bedroom.rent_interval_count),
            rentInterval: this.bedroom.rent_interval_count,
            deposit: bedroomInfo.deposit_amount ? bedroomInfo.deposit_amount : null,
            bedType: bedroomInfo.bed_type ? bedroomInfo.bed_type.charAt(0).toUpperCase() + bedroomInfo.bed_type.substr(1) : null,
            ensuite: this.bedroom.has_ensuite ? 'Yes' : 'No',
            furnished: this.property.default_amenities ? this.getFurnishing(this.property.default_amenities) : undefined,
            underOffer: bedroomInfo.is_under_offer || false,
            isListed: bedroomInfo.is_listed,
            city: city + ', ' + (this.property.address && this.property.address.post_code ? this.property.address.post_code.split(' ')[0] : null),
            dateAvailable: !this.tenancy ? dateAvailable : undefined,
            pricingPlan: this.property.pricing_plan ? this.getPricingPlanName(this.property.pricing_plan) : undefined,
            totalPictures: this.property.property_photos && this.property.property_photos.length ? this.property.property_photos.length : null,
            hasFloorplan: !!floorPlan
              && floorPlan.image_url ? true : false,
            propertyType: EPropertyType[this.property.property_type],
            tenancy: this.tenancy ? {
              rentAmount: this._tenancy.getTenancyRentAmount(this.tenancy),
              tenancyAgreement: this.tenancy.agreement_url ? this.tenancy.agreement_url : null,
              tenancyAgreementId: this.tenancy.tenancy_agreement_id
            } : null
          };
        }
      ), take(1)).subscribe();
    }
  }


  gotoProperty() {
    if (this._user.userDb.role === 'tenant') {
      this.closeOverlay.emit(true);
      this._router.navigate(['/' + this.routes.Shared.search, this.property.property_id]);
    } else {
      this.closeOverlay.emit(true);
      this._router.navigate(['/' + Landlord.base, Landlord.properties.base, Landlord.properties.detail, this.property.property_id]);
    }
  }

  formatDateAvailable(value: any) {
    if (value && value._seconds) {
      const timeStamp = (value._seconds * 1000);
      const date = new Date(timeStamp);
      return date.toLocaleDateString();
    } else {
      return this.getMomentDate(value);
    }
  }

  getMomentDate(date: any) {
    return moment(date.toDate()).format('DD MMM YYYY');
  }

  getPricingPlanName(pricingName: string): string {
    return (pricingName === TENANT_FIND_100_200) ? 'Tenant find' : (pricingName === SELF_MANAGE_100_200)
      ? 'Self manage' : 'Fully managed';
  }

  getSelfManagedSubscriptions(pricing: any) {
    if (this.property.is_hmo) {
      this.subscriptionInterval$.getValue() === 'month' ? this.subscription$.next(pricing.unit_monthly_price_hmo) : this.subscription$.next(pricing.unit_annual_price_hmo);
    } else {
      this.subscriptionInterval$.getValue() === 'month' ? this.subscription$.next(pricing.unit_monthly_price_whole_property) : this.subscription$.next(pricing.unit_annual_price_whole_property);
    }
  }
  openDocument(url: string) {
    window.open(url, '_blank');
  }

  getTenancyAgreementDocument(tenancy: any) {
    if (tenancy.tenancyAgreement.includes('esignatures')) {
      this._tenancy.getTenancyEsignatureDocument(tenancy.tenancyAgreementId)
        .subscribe((res: any) =>
          this.openDocument(res.agreement_url),
          err => {
            console.log('HTTP Error', err);
          });
    } else {
      this.openDocument(tenancy.tenancyAgreement);
    }
  }


  getFurnishing(default_amenities) {
    if (default_amenities.fully_furnished === true) {
      return 'Fully furnished';
    }
    if (default_amenities.partly_furnished === true) {
      return 'Partly furnished';
    }
    if (default_amenities.unfurnished === true) {
      return 'Unfurnished';
    }
  }

  rentFrequency(intervalCount) {
    if (intervalCount === 1) {
      return '/monthly'
    } else if (intervalCount === 0) {
      return '/weekly'
    } else {
      return '/quarterly'
    }
  }

  getRent(intervalCount) {
    if (intervalCount === 1 && this.bedroom.listed_rent_pcm) {
      return this.bedroom.listed_rent_pcm;
    } else if (intervalCount === 3 && this.bedroom.rent_qtr) {
      return this.bedroom.rent_qtr;
    } else if (intervalCount === 0 && this.bedroom.rent_weekly) {
      return this.bedroom.rent_weekly;
    } else {
      return null;
    }
  }


}

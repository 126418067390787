import { Injectable } from '@angular/core';
import {Moment} from 'moment';

@Injectable({
  providedIn: 'root'
})
export class MathService {

  roundToTwoDP (amount: number) {
    return Math.floor((amount + Number.EPSILON) * 100) / 100;
  }

  getDifferenceBetweenTwoDates = (d1: Moment, d2: Moment) => {
    return d1.diff(d2, 'days');
  }
  sortArrayDescending = (array: any, item: any) => array.sort((a: any, b: any) =>  b[(item)] - a[(item)]);
  sortArrayAscending = (array: any, item: any) => (array && array.length > 0) ? array.sort((a: any, b: any) =>  a[item].toDate() - b[item].toDate()) : [];
}



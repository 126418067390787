import { Component, EventEmitter, forwardRef, Input, NgZone, OnDestroy, OnInit, Optional, Output } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, FormGroupDirective, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validator, Validators } from '@angular/forms';
import { AbstractValueAccessor } from '../abstract-value-accessor/abstract-value-accessor';
import { IPropertyAddress, PropertyService, UserService } from '@app/services';
import { TitleCasePipe } from '@angular/common';
import { COUNTRY_IS03166 } from '@app/_constants/countries.constants';
import { environment } from '@env/environment';
import { CustomValidators } from '@app/shared/_validators/custom.validators';
import { CompanyName } from '@app/shared/shared.constants';
import { Subscription } from 'rxjs';
import { EEnvironmentFlags } from '@rentbunk/bunk-models';

@Component({
  selector: 'form-input-address',
  templateUrl: './input-address.component.html',
  styleUrls: ['./input-address.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputAddressComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => InputAddressComponent),
      multi: true
    }
  ]
})
export class InputAddressComponent extends AbstractValueAccessor implements OnInit, Validator, OnDestroy {
  @Input() manualForm = false;
  @Input() showManualFormButton = true;
  @Input() postcodeSearch = true;
  @Input() labelValue = 'Search with postcode';
  @Input() showLabel = false;
  @Input() isHmo: boolean;
  @Input() address = null;

  @Input('submitAttempted') set submitAttempted(boo: boolean) {
    this._submitAttempted = boo;
    if (boo) {
      this.dirtyFormGroup();
    }
  }
  @Output() countryAllowed = new EventEmitter<boolean>();
  get submitAttempted(): boolean {
    return this._submitAttempted;
  }
  readonly allowedCountries = ['United Kingdom', 'England', 'Wales'];
  formGroup: FormGroup;
  countryOfResidence = COUNTRY_IS03166;
  isNonUKClient = false;
  isClientNRLA = CompanyName.isNrla;
  isLandlord = this._user.isLandlord;

  private _submitAttempted = false;
  private sub = new Subscription();

  public isRentSmartRequired = environment.client_data.flags && environment.client_data.flags.includes(EEnvironmentFlags.RENT_SMART_NO_REQUIRED);

  constructor(
    @Optional() private reactiveForm: FormGroupDirective,
    private _formBuilder: FormBuilder,
    private _titleCasePipe: TitleCasePipe,
    private zone: NgZone,
    private _property: PropertyService,
    private _user: UserService,
  ) {
    super();
    this.isNonUKClient = ['client-campus-key'].includes(environment.firebaseConfig.projectId);
  }

  dirtyFormGroup() {
    if (this.formGroup) {
      const controls = this.formGroup.controls;
      for (const control in controls) {
        if (controls.hasOwnProperty(control)) {
          this.formGroup.controls[control].markAsTouched();
        }
      }
    }
  }

  ngOnInit() {
    this.formGroup = this.initFormGroup();
    if (this.isRentSmartRequired) {
      this.sub.add(
        this.formGroup.get('country').valueChanges.subscribe(value => {
          this.updateValidators(value);
        }),
      )
    }
    this.sub.add(
      this.reactiveForm.ngSubmit.subscribe((data: Event) => {
        this.submitAttempted = true;
        if (this.reactiveForm.submitted) {
          this.dirtyFormGroup();
        }
      }),
    )
  }

  ngOnDestroy() {
    this.sub.unsubscribe();
  }

  initFormGroup(): FormGroup {
    const addrFormGroup = this._formBuilder.group({
      first_line_address: [null, Validators.required],
      second_line_address: [null],
      third_line_address: [null],
      city: [null, Validators.required],
      post_code: [null, this.isNonUKClient ? [Validators.required] : [Validators.required, CustomValidators.ValidatePostCodeUK]],
      county: [null, Validators.required],
      country: [null, Validators.required],
      country_iso: [null],
      rent_smart_no: [null],
      lat: [null],
      lng: [null],
      thoroughfare: [null],
      dependant_locality: [null],
      property_ref: [null],
      listed_as_hmo: [this.isHmo !== undefined ? this.isHmo : null, {
        updateOn: 'change',
      }],
    });
    return new FormGroup(addrFormGroup.controls, { updateOn: 'blur' });
  }

  writeValue(val: any): void {
    super.writeValue(val);
    if (val) {
      this.manualForm = true;
      this.address = val;
      this.formGroup.patchValue(val, { emitEvent: false });
      if (this.isRentSmartRequired) {
        this.updateValidators(val.country);
      }
    }
  }

  updateValidators(country: string) {
    if (country === 'Wales') {
      this.formGroup.get('rent_smart_no').setValidators(Validators.required)
    } else {
      this.formGroup.get('rent_smart_no').setValidators(null)
    }
    this.formGroup.get('rent_smart_no').updateValueAndValidity();
  }

  autocompleteAddress(address: any): void {
    const iso = 'GB'; // If us// ing ideal postcode it will always be GB address


    const patch: any = new IPropertyAddress(
      address.line_1 || null,
      address.line_2 || null,
      address.line_3 || null,
      this._titleCasePipe.transform(address.post_town) || null,
      address.postcode || null,
      address.postcode_outward || null,
      address.county || null,
      address.country || null,
      address.lat || address.latitude || null,
      address.lng || address.longitude || null,
      address.thoroughfare || null,
      address.dependant_locality || null,
    );

    const property_ref = this._property.createPropertyRef(patch);
    this.formGroup.get('property_ref').patchValue(property_ref);
    if (!this.isNonUKClient) {
      this.formGroup.patchValue({...patch, country_iso: 'GB'});
    } else {
      this.formGroup.patchValue({...patch, country_iso: address.country_iso});
    }
    if (!this.formGroup.get('county').value) {
      this.formGroup.get('county').setValidators(null);
    } else {
      this.formGroup.get('county').setValidators(Validators.required)
    }
    this.zone.run(() => this.manualForm = true);
    this.formGroup.updateValueAndValidity();

    if (this.allowedCountries.includes(this.formGroup.value.country)) {
      this.countryAllowed.emit(true);
    } else {
      this.countryAllowed.emit(false);
    }
  }

  public onTouched: () => void = () => { };


  registerOnChange(fn: any): void {
    this.formGroup.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.formGroup.disable() : this.formGroup.enable();
  }

  validate(control: AbstractControl) {
    return this.formGroup.valid ? null : { invalidForm: { valid: false, message: 'InputAddressComponent > formGroup fields are invalid' } };
  }
}

<ng-container *ngIf="(landlordPublic$ | async) as landlordPublic">
  <ng-container *ngIf="!isPreview">
    <p class="prop-card__available" *ngIf="display.isHMO && !display.isDeleted && display.isListed && !display.listingTitle && !display.isMarketingListing">
      <span *ngIf="display.totalRoomsAvailable >= 1 && !(display.totalRoomsUnderOffer >= 1 && display.totalRoomsAvailable == 1)">{{ (display.totalRoomsAvailable - display.totalRoomsUnderOffer) > 1 ? (display.totalRoomsAvailable - display.totalRoomsUnderOffer) + ' rooms' : display.totalRoomsAvailable + ' room'}} still available! <br> </span>
      <span *ngIf="display.totalRoomsUnderOffer >= 1">{{ display.totalRoomsUnderOffer > 1 ? display.totalRoomsUnderOffer + ' rooms' : display.totalRoomsUnderOffer + ' room'  }} under offer </span>
    </p>

    <p class="prop-card__available" *ngIf="display.isHMO && !display.isDeleted && display.isListed && display.listingTitle && !display.isMarketingListing">
      <span *ngIf="display.totalRoomsAvailable >= 1 && !(display.totalRoomsUnderOffer >= 1 && display.totalRoomsAvailable == 1)">{{ (display.totalRoomsAvailable - display.totalRoomsUnderOffer) > 1 ? (display.totalRoomsAvailable - display.totalRoomsUnderOffer) + ' ' + display.justPropertyType + 's' : display.totalRoomsAvailable + ' ' + display.justPropertyType}} still available! <br>
        <span *ngIf="display.totalRoomsUnderOffer >= 1">{{ display.totalRoomsUnderOffer > 1 ? display.totalRoomsUnderOffer + ' ' + display.justPropertyType + 's' : display.totalRoomsUnderOffer + ' ' + display.justPropertyType }} under offer </span>
       </span>
    </p>

    <p class="prop-card__available " *ngIf="(display.isDeleted || !display.isListed) && !display.isMarketingListing">Not Available</p>

    <p class="prop-card__available " *ngIf="!display.isHMO && display.isUnderOffer && !display.isMarketingListing">UNDER OFFER</p>
  </ng-container>

  <ng-template #placeholderImage>
    <div class="image__placeholder image__placeholder--preview margin-bottom--md"></div>
  </ng-template>

  <ng-container *ngIf="display.propertyPhotos && display.propertyPhotos.length; else placeholderImage">

    <ng-template #staticImage>
      <picture class="card__hero">
        <source srcset="{{ display.propertyPhotos[0].image_large_url }}"
                media="(max-width: 749px)">
        <source srcset="{{ display.propertyPhotos[0].image_large_url }}"
                media="(min-width: 750px)">
        <img src="{{ display.propertyPhotos[0].image_large_url }}" alt="{{ display.propertyPhotos[0].name ? display.propertyPhotos[0].name : '' }}"/>
      </picture>
    </ng-template>

    <div class="card__carousel" *ngIf="display.propertyPhotos && display.propertyPhotos.length > 1; else staticImage">
      <carousel>
        <div class="card__hero-slide-container" *ngFor="let image of display.propertyPhotos; let i=index">
          <picture class="card__hero-slide" *ngIf="!image.virtualTourUrl">
            <source srcset="{{ image.image_large_url }}"
                    media="(max-width: 749px)">
            <source srcset="{{ image.image_large_url }}"
                    media="(min-width: 750px)">
            <img src="{{image.image_large_url}}" alt="{{image.name ? image.name : ''}}">
          </picture>
          <div *ngIf="image.virtualTourUrl" class="card__hero-video-placeholder">
            <mat-icon svgIcon="play" (click)="openVideoPreview(image.virtualTourUrl)"></mat-icon>
          </div>
        </div>
      </carousel>
    </div>
  </ng-container>

  <div class="header" #target>
    <div class="prop-card__rent">
      <h2 *ngIf="!display.isHMO" class="text--primary-hc">{{client_data.currency_symbol + display.rentPCM}}
        <small>{{rent_label_abbreviation}}</small></h2>
      <div class="cc" *ngIf="display.isHMO">
        <h3 class="text--md text--primary" *ngIf="display.listingTitle; else noListType">{{ display.listingTitle }}</h3>
        <ng-template #noListType>  <h3 class="text--md text--primary"> {{ (property.is_hmo || property.is_house_share) ? display.isHouseShare :  display.propertyType }}</h3></ng-template>

        <p class="mb_0" [ngClass]="{'margin-bottom--lg': display.isHMO}">{{ display.address }}</p>
        <em *ngIf="display.dateListed" class="text--subtle"><small>The property was advertised {{ display.dateListed }}</small></em>
      </div>
    </div>

    <div class="prop-card__amenities">
      <p class="prop-card__amenities_item" *ngIf="display.totalBedrooms >= 1"><img src='/assets/img/icons/bed@3x.png' alt="Bedrooms" class="prop-card__icon"> {{ display.totalBedrooms }}<span class="hide-sm"> {{ display.totalBedrooms > 1 ? 'beds' : 'bed' }}</span></p>
      <p class="prop-card__amenities_item" *ngIf="display.totalBathrooms >= 1"><img src='/assets/img/icons/bath@3x.png' alt="Bathrooms" class="prop-card__icon">{{ display.totalBathrooms }}<span class="hide-sm"> {{ display.totalBathrooms > 1 ? 'baths' : 'bath' }}</span></p>
    </div>

  </div>

  <div class="cc margin-bottom--lg" *ngIf="!display.isHMO">
    <h3 class="text--md text--primary">{{ display.listingTitle ? display.listingTitle : display.propertyType }}</h3>
    <p class="mb_0">{{ display.address }}</p>
    <em *ngIf="display.dateListed" class="text--subtle"><small>The property was advertised {{ display.dateListed }}</small></em>
  </div>

  <ng-container *ngIf="!_auth.authenticated || _auth.authenticated && _user.userDb?.role &&_user.userDb.role === 'tenant'">
      <div class="hide-lg">

        <ng-content select="[bookingSection]"></ng-content>

      </div>
  </ng-container>

  <div class="margin-top--md" *ngIf="landlordPublic.manager">
    <property-owner-card [landlordId]="display.landlordUid" [showLogo]="true" [showManager]="true" [property]="property" (closeOverlay)="closeOverlay.emit($event)"></property-owner-card>
  </div>

  <div class="cc">
      <div class="flex__container">
        <ul >
          <p class="overline margin-top--lg">{{TRANSLATIONS.letting_information}}</p>
          <li>Furnishing:<span *ngFor="let furnish of display.furnishing" >{{ furnish }} </span></li>
          <li *ngIf="!display.isHMO && !display.midTenants">Date available: <span *ngIf="!display.isHMO">{{display.dateAvailable}}</span></li>
          <li *ngIf="!display.isHMO">Deposit:<span *ngIf="!display.isHMO">{{client_data.currency_symbol + display.deposit}}</span></li>
          <li *ngIf="!display.isHMO && !display.midTenants">Minimum duration:<span *ngIf="!display.isHMO">{{minimumDuration}}</span></li>
        </ul>
        <ul *ngIf="checkForBills()">
          <p class="overline margin-top--lg">Bills included</p>
          <ng-container *ngFor="let bill of display.billsIncluded">
            <li *ngIf="property?.bills[bill]">{{bill | titlecase | replaceUnderscore}}: <span>Yes</span></li>
          </ng-container>
        </ul>
      </div>
    </div>


  <div *ngIf="display.amenities && display.amenities.length" class="margin-top--lg">
    <p class="overline">{{TRANSLATIONS.amenities}}</p>
    <mat-chip-list #chipList>
      <mat-chip class="selected" *ngFor="let amenity of display.amenities; let i =index">
        {{ amenity }}
      </mat-chip>
    </mat-chip-list>
  </div>

  <div *ngIf="display.description" class="margin-top--lg">
    <p class="overline">Property description</p>
    <p [innerHTML]="display.description">
     </p>
  </div>



  <ng-container *ngIf="display.tenancyPackageIds && !isCampusKey">
    <p class="overline">Tenancy Packages</p>
    <tenancy-package [tenancyPackageIds]="display.tenancyPackageIds" [listingView]="true"></tenancy-package>
  </ng-container>

  <div *ngIf="display.hasServiceFee || display.hasHoldingAmount" class="margin-top--lg">
    <p class="overline">Fees</p>
    <p>
      <span *ngIf="display.hasServiceFee">
        Upon application, you will need to pay an admin fee of
        {{client_data.currency_symbol}}{{display.serviceFeeAmount ? display.serviceFeeAmount : "" }}
        which is non-refundable.
      </span>
      <span *ngIf="display.hasHoldingAmount">
        If your application to rent the property is successful, you will need to pay a holding
        deposit of {{client_data.currency_symbol}}{{display.holdingDepositAmount ? display.holdingDepositAmount : "" }} which is refundable.
      </span>
      By paying this fee it ensures the property is taken off the market and your application can proceed.</p>
  </div>

  <ng-container *ngIf="landlordPublic.manager; else propertyOwnerCard">
    <div class="cc mt_8 flex_wrap margin-top--lg">
      <property-owner-card [landlordId]="display.landlordUid" [showManager]="true" [bio]="true" [property]="property" (closeOverlay)="closeOverlay.emit($event)"></property-owner-card>
    </div>
  </ng-container>

  <ng-template #propertyOwnerCard>
    <div class="cc mt_8 flex_wrap margin-top--lg">
      <p class="overline">{{landlord | titlecase}}</p>

      <property-owner-card [landlordId]="display.landlordUid" [property]="property" (closeOverlay)="closeOverlay.emit($event)">
        <ng-container *ngIf="!_auth.authenticated">
          <bunk-dialog [component]="contentDialog" (dialogRef)="dref = $event" restrict="true">
            <ng-container *ngIf="isCampusKeyTenant; else isNotCampusKeyTenant">
            <button *ngIf="!property.is_hmo" class="btn__seethrough btn--round btn--sm" appSendMessage [property]="property">Send message</button>
          </ng-container>
          <ng-template #isNotCampusKeyTenant>

            <button *ngIf="!property.is_hmo" class="btn__seethrough btn--round btn--sm" (click)="handleAnonymousOffer()">Send message</button>
          </ng-template>

          </bunk-dialog>



          <ng-template #contentDialog>
            <div class="flex justify-end margin-bottom--xs flex-row">
                <button class="bunk-overlay__close-btn" (click)="dref.close()"><mat-icon svgIcon="icon-close"></mat-icon></button>
            </div>
            <mat-dialog-content>
              <strong class="text--lg">Sign up to contact the {{landlord}}</strong>

              <ng-container *ngIf="!showSignUpForm">
                <p class="margin-top">It won't take long, and you'll be able to speak to the {{landlord}} directly.</p>
                <button class="btn__info margin-top w_100 btn--round btn--lg" (click)="showSignUpForm = true"> Sign up </button>
                <hr>
              </ng-container>

              <div *ngIf="showSignUpForm" class="margin-top--lg">

                <form [formGroup]="formGroup">

                  <form-create-account formControlName="user_account" direction="column"  roleInput="tenant" [showLabel]="false"
                                       (submitForm)="emailSignup()" [showSpinner]="showSpinner$.value"></form-create-account>

                </form>

              </div>

            </mat-dialog-content>
          </ng-template>
        </ng-container>
      </property-owner-card>

    </div>
  </ng-template>

  <div>
    <p class="overline margin-top--md" *ngIf="display.propertyDocuments">Certificates</p>
    <div>
      <div *ngFor="let doc of display.propertyDocuments">
        <ng-container *ngIf="doc.image_url">
          <div class="certificate__item" *ngIf="!isCertificatePDF(doc.image_url)">
            <strong>{{ doc.name }}</strong>
            <bunk-overlay [file]="{ url: doc.image_url, name: doc.name }">
              <button class="btn__seethrough btn--round btn--sm">Open</button>
            </bunk-overlay>
          </div>

          <div class="certificate__item" *ngIf="isCertificatePDF(doc.image_url)">
            <strong>{{ doc.name }}</strong>
            <bunk-overlay [pdf]="{ url: doc.image_url, name: doc.name }">
              <button class="btn__seethrough btn--round btn--sm">Open</button>
            </bunk-overlay>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</ng-container>

import {Component, Input, Output, OnInit, ViewChild, EventEmitter, AfterViewInit} from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { IPropertyAddress } from '@app/services/property.service';
import { GoogleAddressService } from '../../_services/google-address.service'

function requiredIf(required: boolean ): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (required) {
      return {
        requiredIf: { condition: required }
      };
    }
    return null;
  };
}
@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss']
})
export class AddressComponent implements OnInit, AfterViewInit {
  showDetails = false;
  place!: object;
  @ViewChild('addresstext', { static: true }) addresstext: any;
  @Output() selectedAddress = new EventEmitter();
  @Input() appearanceType = 'legacy';
  @Input() set showError(val: any) {
    this._showError = val;
    this.setError(val);
  }
  get showError(): any {
    return this._showError;
  }
  @Input() labelText: string;
  establishmentAddress: Object;
  disabled;

  formattedAddress: string;
  formattedEstablishmentAddress: string;

  addressForm!: FormGroup;
  googleAddress!: IPropertyAddress;
  private _showError: boolean;

  constructor(private googleAddressService: GoogleAddressService,
    private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.addressForm = this.formBuilder.group({
      address: [null, [requiredIf(this.showError)]]
    });
    this.setError(this.showError);
  }

  setError(show: boolean) {
    if (!this.addressForm) {
      return;
    }
    const address = this.addressForm.get('address') as FormControl;
    if (show) {
      address.markAsTouched();
      address.setValidators([requiredIf(this.showError)]);
    } else {
      address.markAsPristine();
      address.setValidators(null);
    }
    address.updateValueAndValidity();
  }

  ngAfterViewInit() {
    this.getPlaceAutocomplete();
  }

  private getPlaceAutocomplete() {
    const autocomplete = new google.maps.places.Autocomplete(this.addresstext.nativeElement);
    google.maps.event.addListener(autocomplete, 'place_changed', () => {
      this.place = autocomplete.getPlace();
      this.formattedAddress = this.googleAddressService.getFormattedAddress(this.place);
      this.patchGoogleAddress();
      this.showDetails = true;
    });
  }

  patchGoogleAddress() {
    const streetNo = this.googleAddressService.getStreetNumber(this.place);
    const street = this.googleAddressService.getStreet(this.place);
    let googleAddress: any = {
      line_1: this.googleAddressService.getStreet(this.place),
      line_2: this.googleAddressService.getSublocalityLevel1(this.place),
      lat: this.googleAddressService.getLat(this.place),
      lng: this.googleAddressService.getLng(this.place),
      postcode: this.googleAddressService.getPostCode(this.place),
      post_town: this.googleAddressService.getLocality(this.place),
      dependant_locality: this.googleAddressService.getState(this.place),
      country: this.googleAddressService.getCountry(this.place),
      third_line_address: '',
      country_iso: this.googleAddressService.getCountryShort(this.place),
      thoroughfare: '',
      county:'',
      outward_postcode:'',
    };
    this.selectedAddress.emit(googleAddress);
  }

}

import { Title, Meta } from '@angular/platform-browser';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter, map, mergeMap } from 'rxjs/operators';
import * as i0 from "@angular/core";
import * as i1 from "@angular/platform-browser";
import * as i2 from "@angular/router";
const STANDARD_SEO = {
    title: 'Online Letting Agents and Property Management Platform ',
    description: 'Empowering property owners & renters to take back control. Award-winning platform. Digital agreements, rent collection & maintenance reporting',
    keywords: 'Online letting agents, property management, maintenance reporting, rent collection',
    image: 'https://firebasestorage.googleapis.com/v0/b/bunkapptest.appspot.com/o/meta-img%2Flink-sharing-meta-image-tenants.jpg?alt=media&token=ef6104c3-bc01-4d86-96f4-ca8db18abac1'
};
export class SeoService {
    constructor(titleService, meta, router, activatedRoute) {
        this.titleService = titleService;
        this.meta = meta;
        this.router = router;
        this.activatedRoute = activatedRoute;
    }
    updateMeta(description, keywords, title, img) {
        this.meta.updateTag({ name: 'description', content: description });
        this.meta.updateTag({ property: 'og:description', content: description });
        this.meta.updateTag({ name: 'keywords', content: keywords });
        this.meta.updateTag({ property: 'og:title', content: title + ' | Bunk' });
        this.meta.updateTag({ property: 'site_name', content: title + ' | Bunk' });
        this.meta.updateTag({ property: 'og:site_name', content: title + ' | Bunk' });
        this.titleService.setTitle(title + ' | Bunk');
        this.meta.updateTag({ property: 'og:image', content: img });
    }
    updateSeo() {
        this.router.events
            .pipe(filter((event) => event instanceof NavigationEnd), map(() => this.activatedRoute), map((route) => {
            while (route.firstChild) {
                route = route.firstChild;
            }
            return route;
        }), filter((route) => route.outlet === 'primary'), mergeMap((route) => route.data)).subscribe((event) => {
            if (event.title) {
                this.updateMeta(event.description, event.keywords, event.title, event.image);
            }
            else {
                this.updateMeta(STANDARD_SEO.description, STANDARD_SEO.keywords, STANDARD_SEO.title, STANDARD_SEO.image);
            }
            this.meta.updateTag({ property: 'og:url', content: this.router.url });
        });
    }
}
SeoService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function SeoService_Factory() { return new SeoService(i0.ɵɵinject(i1.Title), i0.ɵɵinject(i1.Meta), i0.ɵɵinject(i2.Router), i0.ɵɵinject(i2.ActivatedRoute)); }, token: SeoService, providedIn: "root" });

import { map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { Component, Input, OnInit } from '@angular/core';
import { PropertyService, UserService } from '@app/services';

@Component({
  selector: 'summary-info',
  template: `

            <div class="badge badge--primary" *ngIf="viewing && data.type && data.type === 'virtual'">
              Online Viewing
            </div>
            <span class="info__name" *ngIf="viewing && (assignee$ | async) as assignee">
              {{assignee['name']}}
            </span>
            <span class="text--subtle" *ngIf="(address$ | async) as address">
              {{address}}
            </span>

  `,
  styleUrls: ['./summary-info.component.scss']
})
export class SummaryInfoComponent implements OnInit {
  @Input() data;
  @Input() viewing = true;
  property_id;
  assignee$: Observable<any>;
  address$: Observable<any>;
  constructor(private _property: PropertyService, private _user: UserService) { }

  ngOnInit() {
    this.property_id = this.viewing ? this.data.property_id : this.data.properties[0].id;
    this.assignee$ = this.viewing && this.data.assignee_uid ? this._user.getUserById(this.data.assignee_uid).get().pipe(
      map((ref: any) => ref.data()),
      map((doc: any) => ({ name: doc.profile_data.full_name, url: doc.profile_data.profile_image_url })),
    ) : of({ name: this._user.userDb.profile_data.full_name, url: null });
    this.address$ = this._property.getPropertyById(this.property_id).get()
      .pipe(
        map((u: any) => u.data()),
        map((doc: any) => (doc.address.first_line_address + ' ' + doc.address.second_line_address) as string))
  }

}

import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';

@Component({
  selector: 'note-bar',
  templateUrl: './notebar.component.html',
  styleUrls: ['./notebar.component.scss']
})
export class NotebarComponent implements OnInit {
  @Input() position = 'end';
  @Output() notebarNotificationOpenChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  private _notebarNotificationOpenValue: boolean;
  
  constructor() {
  }

  @Input()
  get notebarNotificationOpen() {
    return this._notebarNotificationOpenValue;
  }
  set notebarNotificationOpen(bool: boolean) {
    this._notebarNotificationOpenValue = bool;
    this.notebarNotificationOpenChange.emit(bool);
  }

  ngOnInit(): void { }

  closeNotificationsSideBar = () => this.notebarNotificationOpen = false;
}

<screen-detection (resized)="isDesktop = $event.isDesktop"></screen-detection>

<div class="main__wrapper main__wrapper--signup" *ngIf="(combined$ | async )as combined">

   <div class="signup__left" >
      <div class="logo">

        <a href="{{client_data.website_url}}"><img class="hide-md hide-lg" src="/assets/logos/main-logo.svg" alt="{{client_data.company_name}}" [routerLink]="['/']"></a>
        <a href="{{client_data.website_url}}"><img class="hide-sm" src="/assets/logos/white-logo.svg" alt="{{client_data.company_name}}" [routerLink]=""></a>
      </div>

      <h1 *ngIf="propertyId && tenantName" class="margin-bottom--lg">Welcome to {{client_data.company_name}}, {{tenantName}}! You're just a step away from moving into your new home.</h1>

    <div class="signup-quote margin-bottom--lg" *ngIf="!propertyId && !tenantName">
     <h1 class="hide-sm">
       {{client_data.signup_text}}
     </h1>
    </div>

    <div class="hide-sm margin-bottom--lg" *ngIf="propertyId && tenantName">
      <div class="signup__mid-tenancy-quote">
        <span>
          Renting through {{client_data.company_name}} has been stress free. I can manage my rent payments, chat directly to my {{landlord}} and even report maintenance issues all in the app. Best of all, there are no fees!
        </span>

      </div>
      <img class="signup__image" src="/assets/img/stock-images/women-1.png"/>
    </div>

  </div>

  <div class="signup__right">
      <div class="form-container">
        <div class="flex justify-end margin-bottom hide-sm">
          <small>Already have an account? <a [routerLink]="['/' + routes.Auth.signin]"> Sign In</a></small>
        </div>

          <h2 class="margin-bottom--sm" *ngIf="propertyId && tenantName; else noMid">Join your tenancy</h2>
        <ng-template #noMid>
          <h2 class="margin-bottom--sm" *ngIf="!propertyId && !tenantName">Sign up <span *ngIf="role">as a {{role}}</span></h2>
        </ng-template>
          <ng-container *ngIf="propertyId && tenantName">

          <property-small-card [property]="combined.property" [bedroomId]="bedroomId"></property-small-card>

          <hr class="margin-bottom--md margin-top--md">
        </ng-container>

        <div class="flex justify-start margin-bottom--sm hide-md hide-lg">
          <small>Already have a account? <a [routerLink]="['/' + routes.Auth.signin]"> Sign In</a></small>
        </div>

        <form [formGroup]="formGroup">

          <form-create-account class="margin-bottom--md" formControlName="user_account" direction="column" [showLabel]="false"
                               (submitForm)="emailSignup()" [showSpinner]="showSpinner$.value" roleInput="{{(clientCompanyNames.includes(client_data.company_name) ? 'tenant' : null )}}"></form-create-account>

        </form>


      </div>

    </div>

  </div>



export const environment = {
  production: true,
  is_bunk_environment: false,
  url: 'https://client-tailor-made-management.web.app/',
  version: require('../../package.json').version,
  hey_hub: {
    url: '',
    api_key: '',
    auth_key: ''
  },
  firebaseConfig: {
    apiUrl: 'https://europe-west2-client-tailor-made-management.cloudfunctions.net',
    apiKey: 'AIzaSyDP3kY_sg4_IkILo4IblZWHja7UnodT3G4',
    authDomain: 'client-tailor-made-management.firebaseapp.com',
    projectId: 'client-tailor-made-management',
    storageBucket: 'client-tailor-made-management.appspot.com',
    messagingSenderId: '1053204118579',
    databaseURL: 'https://client-tailor-made-management.firebaseio.com',
    appId: '1:1053204118579:web:3a713489e9c9b9b5a2b746',
    measurementId: 'G-WEKKQVFRN0',
    firestoreUrl: 'https://console.firebase.google.com/u/0/project/client-tailor-made-management/firestore/data/~2F'

  },
  gmapsConfig: {
    apiUrl: 'https://maps.googleapis.com/maps/api',
    apiKey: 'AIzaSyCO-Wisui-vUiKRZ8sYGCoBkoMtzczSuU4'
  },
  stripeConfig: {
    publicKey: 'pk_test_AloMc0VKYl79u7S03ms70Pil'
  },
  algolia: {
    env: 'client-tailor-made-management',
    appId: '0NGCVTIQSM',
    apiKey: 'f9ece56187569c40e8179ab6a698d0e5'
  },
  facebook: {
    app_id: 760538417439960
  },
  idealPostCodes: {
    apiKey: 'ak_jjtq0cokIipQCFaqS5PCoE9kv56qU'
  },
  client_data: {
    date_created: '22/03/2022',
    date_modified: '22/03/2022',
    client_id: '',
    company_legal_name: 'Tailor Made Management Ltd',
    company_name: 'Tailor Made Management',
    privacy_policy_url: 'https://www.tailormade.management/privacy',
    terms_url: 'https://www.tailormade.management/contact-us', // new ts&cs?
    term_version: 1.1,
    privacy_policy_version: 1.1,
    support_number: '020 7266 4424',
    contractor_phone_number: '',
    support_email: 'info@tailormademanagementlimited.co.uk',
    contact_url: 'https://www.tailormade.management/contact-us',
    demo_url: '',
    email_footer: 'This message was produced and distributed by Tailor Made Management Ltd.',
    website_url: 'https://www.tailormade.management/',
    business_hours: '9 - 6 Mon - Fri',
    app_url: '',
    has_signup_img: false,
    signup_text: 'Helping tenants feel at home.',
    unit_name: 'Bedroom',
    time_zone: 'Europe/London',
    currency_symbol: '£',
    currency: 'gbp',
    logos: {
      logo_white_out_transparent: '',
      logo_full_colour: '',
      logo_full_colour_transparent: 'https://firebasestorage.googleapis.com/v0/b/client-tailor-made-management.appspot.com/o/branding%2FEmail%20Logo.png?alt=media&token=8715caa3-a9ce-46ad-b770-c63b00b378ac',
      logo_black_white: '',
    },
    colours: {
      primary_colour: '#0077c1',
      secondary_colour: '#161616',
      accent_colour: '#00bdb6',
    },
    route_guards: ['property-list-new/checkout'],
    flags: [],
  },
  expireDay: 5,
  minimumPasswordLength: 8
};

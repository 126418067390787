/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./sortable-list.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "ngx-sortablejs";
import * as i4 from "./sortable-list.component";
var styles_SortableListComponent = [i0.styles];
var RenderType_SortableListComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SortableListComponent, data: {} });
export { RenderType_SortableListComponent as RenderType_SortableListComponent };
function View_SortableListComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "div", [["class", "rc view-buttons"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 3, "button", [["class", "btn__seethrough"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.showGrid = true) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "i", [["class", "material-icons btn-icons"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["grid_on"])), (_l()(), i1.ɵted(-1, null, [" Grid "])), (_l()(), i1.ɵeld(5, 0, null, null, 3, "button", [["class", "btn__seethrough"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = ((_co.showGrid = false) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "i", [["class", "material-icons btn-icons"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["list"])), (_l()(), i1.ɵted(-1, null, [" List "]))], null, null); }
function View_SortableListComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 13, "li", [["class", "cc grid-item card__base--sortable p_5"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "img", [["alt", ""], ["class", "photo"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 11, "div", [["class", "rc icons"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 1, "div", [["class", "delete"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.remove(_v.parent.context.index) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "img", [["alt", ""], ["class", "w_100"], ["src", "/assets/svg/delete.svg"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 8, "div", [["class", "rc"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 1, "i", [["class", "material-icons md-48 hide-lg"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.swap((_v.parent.context.index - 1), _v.parent.context.index) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["keyboard_arrow_up"])), (_l()(), i1.ɵeld(9, 0, null, null, 1, "i", [["class", "material-icons md-48 hide-lg"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.swap(_v.parent.context.index, (_v.parent.context.index + 1)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["keyboard_arrow_down"])), (_l()(), i1.ɵeld(11, 0, null, null, 1, "i", [["class", "material-icons md-48 hide-md hide-sm "]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.swap((_v.parent.context.index - 1), _v.parent.context.index) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["keyboard_arrow_left"])), (_l()(), i1.ɵeld(13, 0, null, null, 1, "i", [["class", "material-icons md-48 hide-md hide-sm "]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.swap(_v.parent.context.index, (_v.parent.context.index + 1)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["keyboard_arrow_right"]))], null, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "", ((_v.parent.context.$implicit == null) ? null : _v.parent.context.$implicit.image_large_url), ""); _ck(_v, 2, 0, currVal_0); }); }
function View_SortableListComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "ul", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SortableListComponent_4)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.listData.value; _ck(_v, 2, 0, currVal_0); }, null); }
function View_SortableListComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [["class", "listing cc"]], null, null, null, null, null)), i1.ɵdid(1, 737280, null, 0, i3.SortablejsDirective, [[2, i3.ɵa], i3.ɵb, i1.ElementRef, i1.NgZone, i1.Renderer2], { sortablejs: [0, "sortablejs"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SortableListComponent_3)), i1.ɵdid(3, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.listData; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.listData.value; _ck(_v, 3, 0, currVal_1); }, null); }
function View_SortableListComponent_7(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 10, "li", [["class", "card__base--sortable rc list-view"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 1, "div", [["class", "list-photo-container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "img", [["alt", ""], ["class", "list-photo"]], [[8, "src", 4]], null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 7, "div", [["class", "rc list-icons"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 4, "div", [["class", "arrows"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 1, "i", [["class", "material-icons md-48"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.swap((_v.parent.context.index - 1), _v.parent.context.index) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["keyboard_arrow_up"])), (_l()(), i1.ɵeld(8, 0, null, null, 1, "i", [["class", "material-icons md-48"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.swap(_v.parent.context.index, (_v.parent.context.index + 1)) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, ["keyboard_arrow_down"])), (_l()(), i1.ɵeld(10, 0, null, null, 1, "div", [["class", "delete"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.remove(_v.parent.context.index) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 0, "img", [["alt", ""], ["class", "w_100"], ["src", "/assets/svg/delete.svg"]], null, null, null, null, null))], null, function (_ck, _v) { var currVal_0 = i1.ɵinlineInterpolate(1, "", ((_v.parent.context.$implicit == null) ? null : _v.parent.context.$implicit.image_small_url), ""); _ck(_v, 3, 0, currVal_0); }); }
function View_SortableListComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "ul", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SortableListComponent_7)), i1.ɵdid(2, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.listData.value; _ck(_v, 2, 0, currVal_0); }, null); }
function View_SortableListComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "div", [], null, null, null, null, null)), i1.ɵdid(1, 737280, null, 0, i3.SortablejsDirective, [[2, i3.ɵa], i3.ɵb, i1.ElementRef, i1.NgZone, i1.Renderer2], { sortablejs: [0, "sortablejs"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SortableListComponent_6)), i1.ɵdid(3, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.listData; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.listData.value; _ck(_v, 3, 0, currVal_1); }, null); }
export function View_SortableListComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_SortableListComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_SortableListComponent_2)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"], ngIfElse: [1, "ngIfElse"] }, null), (_l()(), i1.ɵand(0, [["showList", 2]], null, 0, null, View_SortableListComponent_5))], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.listData.value[0]; _ck(_v, 1, 0, currVal_0); var currVal_1 = _co.showGrid; var currVal_2 = i1.ɵnov(_v, 4); _ck(_v, 3, 0, currVal_1, currVal_2); }, null); }
export function View_SortableListComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "sortable-list", [], null, null, null, View_SortableListComponent_0, RenderType_SortableListComponent)), i1.ɵdid(1, 49152, null, 0, i4.SortableListComponent, [], null, null)], null, null); }
var SortableListComponentNgFactory = i1.ɵccf("sortable-list", i4.SortableListComponent, View_SortableListComponent_Host_0, { listData: "listData" }, {}, []);
export { SortableListComponentNgFactory as SortableListComponentNgFactory };

export const PROPERTY_DOCS = [
    {
      name: 'EPC',
      image_url: null,
      expiry_date: '',
      epc_rating: null
    },
    {
      name: 'GAS',
      image_url: null,
      expiry_date: null
    },
    {
      name: 'Fire Safety',
      image_url: null,
      expiry_date: null
    },
  {
    name: 'Floorplan',
    image_url: null
  },
  {
    name: 'EICR',
    image_url: null,
    expiry_date: null
  },
  {
    name: 'Inventory',
    image_url: null,
  },
  {
    name: 'Deposit certificate',
    image_url: null,
  }
]

export const PropertyType = {
  'detached': 'detached house',
  'semi-detached': 'semi-detached house',
  'terraced': 'terraced house',
  'flat': 'flat',
  'bungalow': 'bungalow',
  'maisonette': 'maisonette',
  'studio': 'studio',
  'apartment': 'apartment',
  'office': 'office',
  'house-share': 'house-share',
  // missing types in rightmove: bedsit, communal. Defaulting to house-share
};

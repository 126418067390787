import {NoUserDocsGuard} from '@app/core/guards/no-user-docs.guard';

export * from './only-loggedin-users.guard';
export * from '../../login/guards/profile-picture.guard';
export * from './role.guard';
export * from './tenancy-id.guard';
export * from './is-living-phase.guard';
export * from './development.guard';
export * from './internet-explorer.guard';
export * from './user-read-only.guard';
export * from './white-label.guard';
export * from './no-user-docs.guard';
export * from './vouch-reference.guard';
export * from './maintenance.guard';

import { OnlyLoggedinNotAuthenticatedRouteGuard, OnlyLoggedinUsersGuard } from './only-loggedin-users.guard';
import {IsLandLordGuard, IsTenantGuard, IsRoleDefinedGuard, HasFullAccess} from './role.guard';
import { TenancyIdGuard } from '@app/core/guards/tenancy-id.guard';
import { IsLivingPhaseGuard } from '@app/core/guards/is-living-phase.guard';
import { DevelopmentGuard } from '@app/core/guards/development.guard';
import { InternetExplorerGuard } from '@app/core/guards/internet-explorer.guard';
import {userReadOnlyGuard} from '@app/core/guards/user-read-only.guard';
import {EnvironmentRouteGuard} from '@app/core/guards/white-label.guard';
import {RengenApplicationGuard} from '@app/core/guards/rengen-application.guard';

import { VouchSelectionGuard } from './vouch-reference.guard';
import { VouchReferenceGuard } from './vouch-reference.guard';
import { VouchViewGuard } from './vouch-reference.guard';
import {MaintenanceGuard} from '@app/core/guards/maintenance.guard'

export const AUTH_GUARDS = [
    OnlyLoggedinNotAuthenticatedRouteGuard,
    OnlyLoggedinUsersGuard,
    IsLandLordGuard, IsTenantGuard, IsRoleDefinedGuard, TenancyIdGuard,
    IsLivingPhaseGuard,
    DevelopmentGuard,
    InternetExplorerGuard,
    userReadOnlyGuard,
    HasFullAccess,
    EnvironmentRouteGuard,
    NoUserDocsGuard,
    RengenApplicationGuard,
    VouchSelectionGuard,
    VouchReferenceGuard,
    VouchViewGuard,
    MaintenanceGuard
];

import { switchMap } from 'rxjs/operators';
import { Observable, zip, of } from 'rxjs';
import { Component, Input, OnInit } from '@angular/core';
import { TenancyOfferService } from '@app/services';

@Component({
  selector: 'application-members',
  template: `
  <span class="application__profiles">
     <ng-container *ngIf="(members$ | async) as members">
                <ng-container *ngFor="let user of members">
                  <profile-avatar size="sm" [profileImgUrl]="user?.profile_data?.profile_image_url"
                    [profileName]="user?.profile_data?.full_name">
                  </profile-avatar>
                </ng-container>
    </ng-container>
  </span>
  `,
  styleUrls: ['./application-members.component.scss']
})
export class ApplicationMembersComponent implements OnInit {
  @Input() members;
  members$: Observable<any>;
  constructor(private _tenancyOffers: TenancyOfferService) { }

  ngOnInit() {
    this.members$ = this.members ? this.getMembers(this.members) : of([]);
  }
  getMembers(members) {
    return this._tenancyOffers.getUsersFromOffer(members).pipe(
      switchMap((users: any) => zip(...users))

    )
  }
}

import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router} from '@angular/router';
import {isNullOrUndefined} from 'util';

import {UserService} from '@app/services/user.service';
import {map} from 'rxjs/operators';


@Injectable()
export class IsLandLordGuard implements CanActivate {
  constructor(private router: Router,
              private _user: UserService) {
  }

  canActivate(route: ActivatedRouteSnapshot,
              state: RouterStateSnapshot) {
    return this._user.user$
      .pipe(
        map(user => {
          if (user) {
            if (user.role === 'landlord') {
              return true;
            } else {
              this.router.navigate(['/']);
              console.log('IsLandlordGuard says: "Only tenant can view this page bro!"');
              return false;
            }
          }
        })
      );
  }
}


@Injectable()
export class IsTenantGuard implements CanActivate {
  constructor(private router: Router,
              private _user: UserService) {
  }

  canActivate(route: ActivatedRouteSnapshot,
              state: RouterStateSnapshot) {
    return this._user.user$
      .pipe(
        map(user => {
          if (user) {
            if (user.role === 'tenant') {
              return true;
            } else {
              this.router.navigate(['/']);
              console.log('IsTenantGuard says: "Only landlord can view this page bro!"');
              return false;
            }
          }
        })
      );
  }
}

@Injectable()
export class IsRoleDefinedGuard implements CanActivate {
  constructor(private router: Router,
              private _user: UserService) {
  }

  canActivate(route: ActivatedRouteSnapshot,
              state: RouterStateSnapshot) {
    return this._user.user$
      .pipe(
        map(user => {
          if (user) {
            if (user.role === 'landlord') {
              console.log('is landlord');
              return true;
            } else if (user.role === 'tenant') {
              console.log('is tenant');
              return true;
            } else if (isNullOrUndefined(user.role)) {
              this.router.navigate(['/set-role']);
              return false;
            }
          }
        })
      );
  }
}

@Injectable()
export class HasFullAccess implements CanActivate {
  constructor(private router: Router,
              private _user: UserService) {
  }

  canActivate(route: ActivatedRouteSnapshot,
              state: RouterStateSnapshot) {
    const data: string = route.data.route as string;

    return this._user.user$
      .pipe(
        map(user => {
          if (user) {
            if (!user.has_limited_access || !user.has_limited_access[data]) {
              return true;
            } else {
              this.router.navigate(['/']);
              console.log('ACCESS DENIED');
              return false;
            }
          }
          return true;
        })
      );
  }
}


import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CreatePropertyComponent } from './create-simple-property.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule, MatFormFieldModule, MatIconModule, MatInputModule, MatRadioModule, MatSelectModule } from '@angular/material';
import { BunkOverlayModule, BunkUploaderModule, IdealPostcodesAutocompleteModule, ProcessingSpinnerModule, PropertyPicturesUploadModule, SelectTeamModule } from '@app/shared/_components';
import { BunkFormFieldModule } from '../../form-field-wrapper';
@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatRadioModule,
    MatSelectModule,
    IdealPostcodesAutocompleteModule,
    ProcessingSpinnerModule,
    MatCheckboxModule ,
    BunkFormFieldModule,
    PropertyPicturesUploadModule,
    BunkUploaderModule,
    BunkOverlayModule,
    MatIconModule,
    SelectTeamModule
  ],
  exports: [CreatePropertyComponent],
  declarations: [CreatePropertyComponent]
})
export class CreateSimplePropertyModule { }

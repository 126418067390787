<div role="button" *ngIf="guest" class="nav-topbar__logo">
  <img src="/assets/logos/main-logo.svg" alt="{{client_data.company_name}}" [routerLink]="['/']">
</div>

<ng-template #mobileLogo>
  <div role="button" *ngIf="!guest" class="nav-topbar__logo-bg" [routerLink]="['/']"></div>
</ng-template>

<!-- <processing-spinner *ngIf="showSpinner" color="#FFFFFF" size="60px"></processing-spinner> -->
<ng-container *ngIf="isAdmin$ | async; else mobileLogo">
  <div *ngIf="_auth?.currentUser?.displayName" class="nav-topbar__admin">
    <small class="margin-top--xs mr_5 text-em-warning"> Logged in as {{_user.userDb.role}}<em>: {{_user.userDb.profile_data.full_name}}</em></small>
    <button class="btn__warning btn--round btn--sm margin-right" (click)="updateUserAuth()">Log out</button>
  </div>
</ng-container>

<header>
  <span class="nav-topbar__trans-content">
    <ng-content></ng-content>
  </span>

    <div class="nav-topbar__nav" *ngIf="_user.userDb && !guest">
      <a href="https://landlords.zendesk.com/hc/en-gb" target="_blank" *ngIf="isClientNrla && _user.userDb?.role === 'landlord'" class="nav-topbar__btn nav-topbar__btn-note nav-topbar__btn-note--padding">Get support</a>
      <button
        id="button_nav-notifications"
        class="nav-topbar__btn nav-topbar__btn-note"
        (click)="openNotificationsSideBar()" *ngIf="_user.userDb.notification_counters">
        <mat-icon class="nav-topbar__btn-note-icon" *ngIf="_user.userDb.notification_counters && _user.userDb.notification_counters.total === 0" svgIcon="bunk-icon-notifications"></mat-icon>
        <mat-icon class="nav-topbar__btn-note-icon-unread" *ngIf="_user.userDb.notification_counters && _user.userDb.notification_counters.total >= 1" svgIcon="bunk-icon-notifications-new"></mat-icon>
        <counter [value]="_user.userDb.notification_counters.total"></counter>
      </button>

      <button
        id="button_nav-profile"
        class="nav-topbar__btn nav-topbar__btn-user"
        [matMenuTriggerFor]="menu"
        #clickMenuTrigger="matMenuTrigger">

          <profile-avatar size="sm" justified="center" [profileImgUrl]="_user.userDb.profile_data.profile_image_url" [profileName]="_user.userDb.profile_data.full_name"></profile-avatar>
          <span class="nav-topbar__btn-user-name">{{_user.userDb.profile_data.full_name || (_user.userDb.profile_data.first_name + ' ' + 	_user.userDb.profile_data.last_name)}}</span>

        <mat-icon svgIcon="bunk-icon-chevron" class="mat-icon--sm"></mat-icon>
      </button>

      <mat-menu #menu="matMenu" xPosition="before" yPosition="below" class="nav-topbar__menu">
        <ng-container *ngFor="let item of navItems">
          <button mat-menu-item *ngIf="(item.isHidden$ | async) !== true" [routerLink]="item.url" id="item.id">{{item.text}}</button>
        </ng-container>
        <button mat-menu-item (click)="logout()">Logout</button>
      </mat-menu>
    </div>

</header>

import {Component, OnDestroy} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {StripeService} from '@app/services/stripe.service';
import {UserService} from '@app/services/user.service';
import {map, takeUntil} from 'rxjs/operators';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'manage-payouts',
  template: `
  <p class="overline margin-top--sm">Saved accounts</p>
<ng-container *ngIf="_user.userDb.payments_data?.payout_accounts && _user.userDb.payments_data?.payout_accounts.length > 0; else getStripeCards">
  <ng-container *ngFor="let card of _user.userDb.payments_data?.payout_accounts">
    <ng-container *ngTemplateOutlet="cardsTemplate; context : {card : card}"></ng-container>
  </ng-container>

</ng-container>

<ng-template #getStripeCards>
  <ng-container *ngIf="(accounts$ | async) as accounts; else loading">
    <ng-container *ngFor="let card of accounts.data">
      <ng-container *ngTemplateOutlet="cardsTemplate; context : {card : card}"></ng-container>

    </ng-container>

    <div class="error" *ngIf="accountError">{{accountError}}</div>

  </ng-container>
</ng-template>

<ng-template #loading>
  <processing-spinner *ngIf="account_loading || deleting" color="#FFFFFF"></processing-spinner>
</ng-template>



<ng-template #cardsTemplate let-card="card">
  <div class="flex flex-column">
    <div class="flex flex-column w_100">
      <div class="payment__card payment__card--added" *ngIf="deleting === card.id">
        <div class="flex flex-row justify-between border-bottom pb_2">
          <div>
            <small class="mr_3">{{card.bank_name}}</small>
            <small>ending in {{card.last4}}</small>
          </div>
          <small *ngIf="card.default_for_currency"  class="text--primary-hc">Default account</small>
        </div>
        <p class="margin-top--md margin-bottom--md">Removing account</p>
        <processing-spinner color="#FFFFFF"></processing-spinner>
      </div>

      <div class="payment__card payment__card--added" *ngIf="deleting !== card.id">

        <div class="flex flex-row justify-between border-bottom pb_2">
          <div>
            <small class="mr_3">{{card.bank_name}}</small>
            <small>ending in {{card.last4}}</small>
          </div>
          <small *ngIf="card.default_for_currency"  class="text--primary-hc">Default account</small>

        </div>
        <div class="flex flex-row justify-between margin-top--sm">
          <div class="flex flex-column w_75">
            <div>
              <small>Account holder</small>
              <p>{{(card.account_holder_name === 'User without a Name' ? _user.userDb.profile_data.full_name :
                card.account_holder_name )}}</p>
            </div>

          </div>
          <div class="flex flex-column">
            <button [disabled]="deleting" class="btn__warning btn--sm btn--round margin-top--sm" (click)="deleteAccount(card)">Remove
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

</ng-template>

<!--<bank-account-form (emitter)="addAccount($event)" [disabled]="disableCardsForm" [clear]="clearCardsForm">-->
<!--</bank-account-form>-->
  `,
  styleUrls: ['../payment-methods.scss']
})
export class PayoutsComponent implements  OnDestroy {

  public account_loading: boolean;
  public account_message = 'Please wait while we fetch your account(s) data...';
  public accounts$: Observable<any>;
  private destroy$: Subject<boolean> = new Subject<boolean>();
  public accountError: string;
  private landlordAccountId: string;
  public clearCardsForm: Subject<boolean> = new Subject();
  public disableCardsForm: Subject<boolean> = new Subject();
  public deleting: string;

  constructor(private _payment: StripeService,
              public _user: UserService,
              private _toastr: ToastrService) {
    this.account_loading = true;
    this.landlordAccountId = this._user.userDb.payments_data.account_id;

    this.accounts$ = this._payment.getAllExternalAccounts();
    
    this.accounts$
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.account_loading = false;
      });
  }

  deleteAccount(account) {
    if (account.default_for_currency) {
      this._toastr.warning('You are trying to remove your default bank account. Please add a new default bank account before removing the current one.');
    } else {
      this.deleting = account.id;
      this._payment.deleteExternalAccount(account.account, account.id)
        .pipe(
          takeUntil(this.destroy$)
        )
        .subscribe(() => {
          this.deleting = undefined;
          this._toastr.success('Successfully removed');
        }, (error) => {
          this.deleting = undefined;
          this.accountError = error.error.message;
        });
    }
  }

  addAccount = (item) => {
    this._payment.addPayout(this.landlordAccountId, item.token.id, item.default_for_currency)
      .pipe(
        map(val => {
          this.account_loading = true;
        }),
        takeUntil(this.destroy$)
      )
      .subscribe((data) => {
        this.clearCardsForm.next(true);
        this.disableCardsForm.next(false);
        this.account_loading = false;
        this._toastr.success('Account successfully added!');

      }, error => {
        this.account_loading = false;
        this.clearCardsForm.next(false);
        this.disableCardsForm.next(false);
        this.accountError = error.error.message;
      });
  };

  clearErrors() {
    this.accountError = null;
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

}

import { NgModule, isDevMode } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatDialogModule } from '@angular/material/dialog';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { TokenInterceptor } from '@app/core/token.interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { NavigationModule } from '@app/core/navigation/navigation.module';
import { UtilsModule } from '@app/core/utils/utils.module';

import { AUTH_GUARDS } from '@app/core/guards';
import { AngularFireModule } from '@angular/fire';
import { environment } from '@env/environment';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { AngularFireStorageModule } from '@angular/fire/storage';
import { AngularFireRemoteConfigModule, SETTINGS as REMOTE_CONFIG_SETTINGS } from '@angular/fire/remote-config';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { ToastContainerModule, ToastrModule } from 'ngx-toastr';
import { IeUsersComponent } from './ie-users';
import {NoUserDocComponent} from '@app/core/no-user-doc';
import {SharedModule} from '@app/shared';

@NgModule({
  declarations: [
    IeUsersComponent,
    NoUserDocComponent,
  ],
  imports: [
    RouterModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),  // imports firebase/app needed for everything
    AngularFirestoreModule,                                       // imports firebase/firestore, only needed for database features
    AngularFireAuthModule,                                        // imports firebase/auth, only needed for auth features,
    AngularFireStorageModule,
    AngularFireRemoteConfigModule,                                  // imports firebase/storage only needed for storage features
    DeviceDetectorModule.forRoot(),
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: 'toast-bottom-left',
      closeButton: true
    }),
    ToastContainerModule,
    MatInputModule,
    MatIconModule,
    MatDialogModule,
    SharedModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    RouterModule,
    BrowserAnimationsModule,
    HttpClientModule,
    UtilsModule,
    NavigationModule,
    IeUsersComponent
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    {
      provide: REMOTE_CONFIG_SETTINGS,
      useFactory: () => isDevMode ? { fetchTimeoutMillis: 800, minimumFetchIntervalMillis: 10_000 } : {}
    },
    AUTH_GUARDS,
  ]
})
export class CoreModule {
}

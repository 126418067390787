import {Component, EventEmitter, forwardRef, Input, OnInit, Optional, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, FormGroupDirective, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validator, Validators} from '@angular/forms';
import {FormsService} from '@app/services/form.service';
import {AbstractValueAccessor} from '@app/shared/_components/searches/instant-search/instant-search.utils';
import {flatMap, map} from 'rxjs/operators';
import {of} from 'rxjs/internal/observable/of';
import {Observable} from 'rxjs/Observable';
import {BehaviorSubject} from 'rxjs';
import {PropertyService, TenancyService} from '@app/services';
import {environment} from '@env/environment';
import { EEnvironmentFlags } from '@rentbunk/bunk-models';

@Component({
  selector: 'form-get-properties-details',
  templateUrl: './form-get-property-details.component.html',
  styleUrls: ['./form-get-property-details.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormGetPropertyDetailsComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => FormGetPropertyDetailsComponent),
      multi: true
    }
  ]
})

export class FormGetPropertyDetailsComponent extends AbstractValueAccessor implements OnInit, Validator {
  public property$: Observable<any>;
  public properties$: Observable<any>;
  public selectedProperty: Observable<any>;
  public propertyID$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public formGroup: FormGroup;
  overRef;
  dialogRef;
  currentOption = null;


  @Output() tenancyPropertyId: EventEmitter< {property_id: string, bedroom_id: null | string}> = new EventEmitter<{property_id: null, bedroom_id: null}>();

  public client_data = environment.client_data;
  public tenants = [];
  public hideSearch = false;
  @Input() isExpense = false;
  @Input () propertyRequired = true;
  @Input('submitAttempted') set submitAttempted(boo: boolean) {
    this._submitAttempted = boo;
    console.log({boo})
    if (boo) {
      this.dirtyFormGroup();
    }
  }
  get submitAttempted(): boolean {
    return this._submitAttempted;
  }

  set resetForm(boo: boolean) {
    console.log('RESET!!!!:', boo)
    if (boo) {
      this.removeProperty();
    }
  }
  @Input() get resetForm(): boolean {
    return this._resetForm;
  }
  public isDocumentsRequired = environment.client_data.flags && environment.client_data.flags.includes(EEnvironmentFlags.VERIFICATION_DOCUMENTS_REQUIRED);

  private _submitAttempted = false;
  private _resetForm = false;
  constructor(
    @Optional() public _reactiveForm: FormGroupDirective,
    private _forms: FormsService,
    private _property: PropertyService,
    private _tenancy: TenancyService,
    private _formBuilder: FormBuilder
  ) {
    super();
  }


  get property_id(): FormControl {
    return this.formGroup.get('property_id') as FormControl;
  }

  get bedroom_id(): FormControl {
    return this.formGroup.get('bedroom_id') as FormControl;
  }

  ngOnInit() {
    this.properties$ = this._property.getAllUserProperties().pipe(
      map((properties: any) => {
        return properties.map((property: any) => property.property_id);
      })
    );
    this.property$ = this.propertyID$.asObservable().pipe(
      flatMap((property_id: string) => (property_id) ? this._property.getPropertyById(property_id).valueChanges() : of(null)),
      map((property: any) => {
        if (!property) {
          return null;
        }

        if (property.is_hmo && property.bedrooms) {
          // this.bedroom_id.setValidators([Validators.required]);
          // this.bedroom_id.updateValueAndValidity();

              return {
                ...property,
                bedrooms_form:Object.values(property.bedrooms).sort((a:string,b:string) => a["index"] - b["index"]),
              };

        }

        this.tenancyPropertyId.next({property_id: property.property_id, bedroom_id: null});
        console.log(property)
        return {
          ...property
        };
      })
    );
    this.formGroup = this.initFormGroup();

    if (!this.propertyRequired) {
      this.property_id.setValidators(null);
      this.property_id.updateValueAndValidity();
      this.bedroom_id.setValidators(null);
      this.bedroom_id.updateValueAndValidity();
    }

  }

  initFormGroup() {
    return this._formBuilder.group({
      property_id: [null, Validators.required],
      bedroom_id: [null],
    });
  }

  getPropertyId(id: any) {
    if (id && id.length) {
      this.property_id.setValue(Array.isArray(id) ? id[0] : id);
      this.property_id.updateValueAndValidity();
      this.hideSearch = true;
      this.propertyID$.next(Array.isArray(id) ? id[0] : id);
    }
  }

  selectBedroom(event: any) {
    this.tenancyPropertyId.next({property_id: this.propertyID$.value, bedroom_id: event});
  }


  removeProperty() {
    this.propertyID$.next(null);
    this.tenancyPropertyId.next(null);
    this.hideSearch = false;
    this.tenants = [];
    this.propertyID$.next(null);
    this.tenancyPropertyId.next(null);
    this.hideSearch = false;
    this.resetBedroom();
    this.property_id.setValue(null);
    this.property_id.updateValueAndValidity();
    this.tenants = [];
  }

  resetBedroom() {
    this.bedroom_id.setValue(null);
    this.bedroom_id.setValidators(null);
    this.bedroom_id.updateValueAndValidity();
  }

  dirtyFormGroup() {
    if (this.formGroup) {
      const controls = this.formGroup.controls;
      for (const control in controls) {
        if (controls.hasOwnProperty(control)) {
          this.formGroup.controls[control].markAsTouched();
        }
      }
    }
  }

  public onTouched: () => void = () => { };

  writeValue(val: any): void {
    super.writeValue(val);
    if (val) {
      this.formGroup.patchValue(val, { emitEvent: false });
      this.getPropertyId(val.property_id);
    }
  }
  registerOnChange(fn: any): void {
    this.formGroup.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.formGroup.disable() : this.formGroup.enable();
  }

  validate() {
    return this.formGroup.valid ? null : { invalidForm: { valid: false, message: 'FormGetPropertyDetailsComponent > formGroup fields are invalid' } };
  }

  test(e) {
    console.log(e, 'helllooo e');
    this.propertyID$.next(e);
    console.log(this.propertyID$.getValue());
    this.getPropertyId(e);
    this.overRef.close();
  }
  onCancel(){
    this.overRef.close();
  }

  setOption(option: boolean) {
    this.currentOption = option;
  }
}

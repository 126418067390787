import {Component, EventEmitter, OnInit, Output, Input} from '@angular/core';
import {TenancyService} from '@app/services/tenancy.service';
import {ToastrService} from 'ngx-toastr';
import {finalize, flatMap} from 'rxjs/operators';
import { UserService } from '@app/services';
import { Router } from '@angular/router';
import {Tenant} from '@env/routing';
import { IUser } from '@app/core/models';


@Component({
  selector: 'tenant-add-tenancy',
  template: `   
    <div class="cc ai_c w_100 mt_15 mb_15">
      <div class="ai_c cc w_80" *ngIf="!showSuccess">
        <small>Join a tenancy</small>
        <strong class="text--lg text--bold mb_15">Add invite code</strong>
        <mat-form-field class="w_100" appearance="outline">
        <mat-label>Code (max. 20 character)</mat-label>
        <input matInput type="text"
              [(ngModel)]="token" #tenancyCodeText="ngModel">
        </mat-form-field>

        <button (click)="addTenancy()" type="button" [hidden]="loading"
        class="btn__cta btn--round mt_10"
        [disabled]="loading || isCodeValid()">CONFIRM
        </button>

        <div *ngIf="loading">
        <loading-spinner></loading-spinner>
        </div>
      </div>

      <div *ngIf="showSuccess" class="cc ai_c">
        <span class="status__complete status__complete--md"></span>
        <div class="ai_c cc m-vertical_15">
          <h2 class="text--lg">Success!</h2>
          <p class="text--sm text-center">You can now access your tenancy from your dashboard.</p>
          <p class="text--sm text-center">Welcome to Bunk!</p>
        </div>
        <button class="btn__seethrough btn__seethrough--secondary btn--round" type="button"
        (click)="close()">
        Close</button>
    </div>
    </div>

  `,
  styleUrls: ['./tenant-add-tenancy.component.scss']
})
export class TenantAddTenancyComponent implements OnInit {
  public token: string;
  public loading = false;
  public showSuccess = false;
  public user: IUser;
  @Input() dref: any; 

  @Output()
  success = new EventEmitter<false | string>();

  constructor(private _tenancy: TenancyService,
              private _toastr: ToastrService,
              public _user: UserService,
              private _router: Router) {

  this._user.user$.subscribe(user => {
    this.user = user;
  });
  }

  ngOnInit() {
    this.token = '';
  }

  addTenancy() {
    this.loading = true;
    this._tenancy.joinTenancy(this.token, this._user.userDb.profile_data.email)
      .pipe(
        finalize(() => this.loading = false),
      )
      .subscribe(
        success => {
          this.showSuccess = true;
          this.success.next(this.token);
          this._toastr.success(`Yep! You have just joined a tenancy!`);
        },
        error => {
          this.success.next(false);
          this._toastr.error(`Opps theres been an error`);
        }
      );
  }

  isCodeValid() {
    return this.token.length < 10;
  }

  close() {
    this.dref.close();
    this._router.navigate([Tenant.base, Tenant.manage.base, Tenant.manage.my_property.base, Tenant.manage.my_property.dashboard]);

    // window.location.reload();
  }
}

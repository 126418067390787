import { Injectable } from '@angular/core';
import {
    VerifyContactInfoModel,
    VerifyGuarantorInfoModel,
    VerifyUserOccupationModel,
    UserVerifyCardModel,
    VerifyUserInfoModel,
} from '@app/_models/user-verification.models';

import { Observable ,  from as fromPromise } from 'rxjs';
import { UserService } from '@app/services/user.service';
import { AuthService } from '@app/core/services';
import {AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument} from '@angular/fire/firestore';

@Injectable()
export class UserVerificationService {
    private userDoc: AngularFirestoreDocument<any>;
    private userDoc$: Observable<any>;
    public tenantEmployersCollection: AngularFirestoreCollection<any>;
    public tenantGuarantorsCollection: AngularFirestoreCollection<any>;

    constructor(
        private afs: AngularFirestore,
        private _user: UserService,
        private _auth: AuthService
    ) {
        this.tenantEmployersCollection = afs.collection<any>(
            'tenant_employers'
        );
        this.tenantGuarantorsCollection = afs.collection<any>(
            'tenant_guarantors'
        );

        this.userDoc = this.afs
            .collection('users')
            .doc(this._auth.currentUserId);
    }

    getGuarantorDocumentById(id) {
        return this.tenantGuarantorsCollection.doc(id);
    }

    getEmployersDocumentById(id) {
        return this.tenantEmployersCollection.doc(id);
    }

    // batchSetTenantEmployers(data: any) {
    //   let id = this.afs.createId();
    //   const userDocRef = this.afs.collection('users').doc(this._user.userDb.uid).ref;
    //   const tenantEmployerRef = this.tenantEmployersCollection.doc(id).ref;
    //
    //   const userItem = {
    //     verification_data: {
    //       tenant_verification_data: {
    //         employer_ids: id,
    //         is_employer_verified: true,
    //         is_student: false,
    //         university: null,
    //         graduation_date: null,
    //         is_professional: true,
    //         is_employed: data.is_employed,
    //         annual_salary_value: data.salary_amount,
    //         salary_frequency: 'Annually',
    //         profession: data.job_title,
    //         employment_type: data.employment_type,
    //       }
    //     }
    //   };
    //   const employerItem = {
    //     tenant_employer_id: id,
    //     tenant_uid: this._auth.currentUserId,
    //     document_status: '',
    //     company_name: data.company_name,
    //     signed_reference_url: '',
    //     status: 'pending',
    //     employer_name: data.employer_referee_name,
    //     employer_number: data.company_number,
    //     employer_email: data.employer_referee_email,
    //     tenant_employers_address: {
    //       first_line_address: data.registered_office,
    //     }
    //   };
    //
    //   return fromPromise(userDocRef.get() && tenantEmployerRef.get())
    //     .pipe(
    //       map(() => {
    //         const batch = this.afs.firestore.batch();
    //         batch.set(userDocRef, userItem);
    //         batch.set(tenantEmployerRef, employerItem);
    //         return batch.commit();
    //       })
    //     );
    // }

    createTenantEmployersDocument(id, employer) {
        const item = {
            tenant_employer_id: id,
            tenant_uid: this._auth.currentUserId,
            document_status: '',
            company_name: employer.company_name,
            signed_reference_url: '',
            status: 'pending',
            employer_name: employer.employer_referee_name,
            employer_number: employer.company_number,
            employer_email: employer.employer_referee_email,
            tenant_employers_address: {
                first_line_address: employer.registered_office,
            },
        };
        return fromPromise(this.tenantEmployersCollection.doc(id).set(item));
    }

    createTenantGuarantorsDocument(id, guarantor) {
        const item = {
            tenant_guarantor_id: id,
            tenant_uid: this._auth.currentUserId,
            address_data: guarantor.address_data,
            guarantor_type: guarantor.relation,
            guarantor_first_name: guarantor.guarantor_name,
            guarantor_last_name: guarantor.last_name,
            guarantor_email: guarantor.guarantor_email,
            guarantor_phone_number: guarantor.guarantor_phone_number,
        };
        return fromPromise(this.tenantGuarantorsCollection.doc(id).set(item));
    }

    // createReferenceEmployersDocumentToUser(id: string) {
    //   const userDocRef = this.afs.collection('users').doc(this._auth.currentUserId).ref;
    //   return this.fb.firestore().runTransaction(transaction => {
    //     return transaction.get(userDocRef)
    //       .then(user => {
    //         const userData = user.data();
    //         const newIDs: { verification_data: {tenant_verification_data: { Array<string>}} } = (userData.verification_data.tenant_verification_data)
    //           ? {verification_data: {tenant_verification_data: [...userData.verification_data.tenant_verification_data, id]}}
    //           : {verification_data: {tenant_verification_data: [id]}};
    //         transaction.set(userDocRef, newIDs, {merge: true});
    //       });
    //   });
    // }

    populateVerifyUserInfo = (user): VerifyUserInfoModel => ({
        first_name: user.profile_data.first_name,
        last_name: user.profile_data.last_name,
        dob: user.profile_data.dob,
        gender: user.profile_data.gender,
    });

    populateVerifyContact = (user): VerifyContactInfoModel => ({
        email: user.profile_data.email,
        phone_number: user.profile_data.phone_number,
        address_data: {
            first_line_address:
                user.verification_data.address_details.first_line_address,
            second_line_address:
                user.verification_data.address_details.second_line_address,
            third_line_address:
                user.verification_data.address_details.third_line_address,
            city: user.verification_data.address_details.city,
            post_code: user.verification_data.address_details.post_code,
            county: user.verification_data.address_details.county,
            country: user.verification_data.address_details.country,
            lat: user.verification_data.address_details.lat,
            lng: user.verification_data.address_details.lng,
        },
    });

    populateVerifyOccupation = (user): VerifyUserOccupationModel => ({
        graduation_date:
            user.verification_data.tenant_verification_data.graduation_date,
        university_name:
            user.verification_data.tenant_verification_data.university,
        is_student: user.verification_data.tenant_verification_data.is_student,
        salary_amount:
            user.verification_data.tenant_verification_data.salary_value,
        is_employed:
            user.verification_data.tenant_verification_data.is_employed,
        is_professional:
            user.verification_data.tenant_verification_data.is_professional,
        company_name:
            user.verification_data.tenant_verification_data.company_name,
        company_number:
            user.verification_data.tenant_verification_data.company_number,
        job_title: user.verification_data.tenant_verification_data.profession,
        registered_office:
            user.verification_data.tenant_verification_data.registered_office,
        employer_referee_name:
            user.verification_data.tenant_verification_data.employer_name,
        employer_referee_email:
            user.verification_data.tenant_verification_data.employer_email,
        employment_type:
            user.verification_data.tenant_verification_data.employment_type,
    });

    populateVerifyGuarantor = (user): VerifyGuarantorInfoModel => {
        const path = user.verification_data.tenant_verification_data;
        return {
            guarantor_name: path.guarantor_first_name,
            last_name: path.guarantor_last_name,
            relation: path.guarantor_type,
            guarantor_email: path.guarantor_email,
            guarantor_phone_number: path.guarantor_phone_number,
            guarantor_status: path.guarantor_status,
            address_data: {
                first_line_address: path.address_data.first_line_address,
                second_line_address: path.address_data.second_line_address,
                third_line_address: path.address_data.third_line_address,
                city: path.address_data.city,
                post_code: path.address_data.post_code,
                county: path.address_data.county,
                country: path.address_data.country,
                lat: path.address_data.lat,
                lng: path.address_data.lng,
            },
        };
    };

    initVerifyContact = (): VerifyContactInfoModel => ({
        email: '',
        phone_number: null,
        address_data: {
            first_line_address: '',
            second_line_address: '',
            third_line_address: '',
            city: '',
            post_code: '',
            county: '',
            country: '',
            lat: '',
            lng: '',
        },
    });

    initVerifyGuarantor = (): VerifyGuarantorInfoModel => ({
        guarantor_name: '',
        last_name: '',
        relation: '',
        guarantor_email: '',
        guarantor_phone_number: null,
        guarantor_status: false,
        address_data: {
            first_line_address: '',
            second_line_address: '',
            third_line_address: '',
            city: '',
            post_code: '',
            county: '',
            country: '',
            lat: '',
            lng: '',
        },
    });

    initVerifyStudent = (): VerifyUserOccupationModel => ({
        graduation_date: '',
        university_name: '',
        is_student: true,
        salary_amount: null,
        is_employed: null,
        is_professional: false,
        company_name: null,
        company_number: null,
        job_title: null,
        registered_office: null,
        employer_referee_name: null,
        employer_referee_email: null,
        employment_type: null,
    });

    initVerifyProfessional = (): VerifyUserOccupationModel => ({
        salary_amount: null,
        is_employed: null,
        is_professional: true,
        company_name: '',
        company_number: '',
        job_title: '',
        registered_office: '',
        employer_referee_name: '',
        employer_referee_email: '',
        employment_type: 'full-time',
        graduation_date: null,
        university_name: null,
        is_student: false,
    });

    initVerifyUserInfo = (): VerifyUserInfoModel => ({
        first_name: '',
        last_name: '',
        dob: null,
        gender: 'Unspecified',
    });

    initVerifyUserCardInfo = (): UserVerifyCardModel => ({
        card_name: '',
        card_number: '',
        expiry_year: '',
        expiry_month: '',
    });

    pushToDb(item) {
        return this.userDoc.set(item, { merge: true });
    }
}

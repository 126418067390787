import { OnInit } from '@angular/core';
import { FormControl, FormGroup, FormGroupDirective, Validator, Validators } from '@angular/forms';
import { AbstractValueAccessor } from '@app/shared/_components/searches/instant-search/instant-search.utils';
import { FormsService } from '@app/services/form.service';
import { CAMPUS_KEY_YEARS, FORM_SEARCH_DETAILS, FORM_SEARCH_DETAILS_CAMPUS_KEY, RENTING_OPTIONS, SEARCHING_OPTIONS, STUDY_YEAR } from './application-forms.constants';
import { UK_DATE_FORMAT } from '@app/_constants/date.constants';
import { environment } from '@env/environment';
const ɵ0 = UK_DATE_FORMAT;
export class FormSearchDetailsComponent extends AbstractValueAccessor {
    constructor(_reactiveForm, _forms) {
        super();
        this._reactiveForm = _reactiveForm;
        this._forms = _forms;
        this.requiredFields = FORM_SEARCH_DETAILS;
        this.searching = SEARCHING_OPTIONS;
        this.renting = RENTING_OPTIONS;
        this.study_years = STUDY_YEAR;
        this.years_at_campus = CAMPUS_KEY_YEARS;
        this._submitAttempted = false;
        this.minDate = new Date();
        this.client_data = environment.client_data;
        this.isCampusKey = environment.client_data.company_name === 'CampusKey';
        this.onTouched = () => { };
    }
    set submitAttempted(boo) {
        this._submitAttempted = boo;
        if (boo) {
            this.dirtyFormGroup();
        }
    }
    get submitAttempted() {
        return this._submitAttempted;
    }
    dirtyFormGroup() {
        if (this.searchForm) {
            const controls = this.searchForm.controls;
            for (const control in controls) {
                if (controls.hasOwnProperty(control)) {
                    this.searchForm.controls[control].markAsTouched();
                }
            }
        }
    }
    getFormControl(name) {
        return this.searchForm.get(name);
    }
    ngOnInit() {
        if (this.isCampusKey) {
            this.requiredFields = FORM_SEARCH_DETAILS_CAMPUS_KEY;
        }
        this.searchForm = this._forms.toFormGroup(this.requiredFields.search_details);
        this.isUniValidation(this.getFormControl('is_student').value);
        if (this.getFormControl('number_children')) {
            this.getFormControl('number_children').setValidators(null);
            this.getFormControl('number_children').updateValueAndValidity();
        }
        if (this.getFormControl('years_at_uni')) {
            this.getFormControl('years_at_uni').setValidators(null);
            this.getFormControl('years_at_uni').updateValueAndValidity();
        }
        this._reactiveForm.ngSubmit.subscribe((data) => {
            this.submitAttempted = true;
            const controls = this.searchForm.controls;
            for (const control in controls) {
                if (controls.hasOwnProperty(control)) {
                    this.searchForm.controls[control].markAsTouched();
                }
            }
        });
        if (this.getFormControl('is_student_at_uni')) {
            this.getFormControl('is_student_at_uni').valueChanges.subscribe(value => {
                if (value) {
                    this.getFormControl('years_at_uni').setValidators(Validators.required);
                    this.getFormControl('years_at_uni').updateValueAndValidity();
                }
                else {
                    this.getFormControl('years_at_uni').patchValue(null);
                    this.getFormControl('years_at_uni').setValidators(null);
                    this.getFormControl('years_at_uni').updateValueAndValidity();
                }
            });
        }
    }
    isRentingValidation(selectValue) {
        if (selectValue === false && this.getFormControl('currently_renting_from')) {
            this.getFormControl('currently_renting_from').setValidators(null);
            this.getFormControl('currently_renting_from').updateValueAndValidity();
        }
        else {
            if (this.getFormControl('currently_renting_from')) {
                this.getFormControl('currently_renting_from').setValidators([Validators.required]);
                this.getFormControl('currently_renting_from').updateValueAndValidity();
            }
        }
    }
    isUniValidation(selectValue) {
        if (selectValue === false) {
            this.searchForm.removeControl('university_details');
            this.searchForm.updateValueAndValidity();
        }
        else {
            this.searchForm.addControl('university_details', new FormControl(null));
            this.getFormControl('university_details').updateValueAndValidity();
            this.searchForm.updateValueAndValidity();
        }
    }
    writeValue(val) {
        super.writeValue(val);
        if (val) {
            this.searchForm.patchValue(val, { emitEvent: false });
            if (val.is_renting !== null) {
                this.isRentingValidation(val.is_renting);
            }
            if (val.is_student !== null) {
                this.isUniValidation(val.is_student);
            }
        }
    }
    registerOnChange(fn) {
        this.searchForm.valueChanges.subscribe(fn);
    }
    registerOnTouched(fn) {
        this.onTouched = fn;
    }
    setDisabledState(isDisabled) {
        isDisabled ? this.searchForm.disable() : this.searchForm.enable();
    }
    validate() {
        return this.searchForm.valid ? null : { invalidForm: { valid: false, message: 'FormSearchDetailsComponent > formGroup fields are invalid' } };
    }
}
export { ɵ0 };

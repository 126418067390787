import { Injectable } from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import { environment } from '@env/environment';
import {Location} from '@angular/common';


@Injectable({
  providedIn: 'root',
})
export class EnvironmentRouteGuard implements CanActivate {
  constructor(private router: Router,
              private _location: Location
  ){}

  canActivate() {
    if (environment.client_data && environment.client_data.route_guards && environment.client_data.route_guards.some(route => this._location.path().includes(route))) {
      this.router.navigate(['/']);
      return false;
    } else {
      return true;
    }
  }

}

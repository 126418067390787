import { FormGroup, FormControl, Validators } from '@angular/forms';
import * as i0 from "@angular/core";
export class FormsService {
    toFormGroup(fieldArray) {
        const group = {};
        fieldArray.map((field) => {
            group[field] = new FormControl(null, Validators.required);
        });
        return new FormGroup(group);
    }
}
FormsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function FormsService_Factory() { return new FormsService(); }, token: FormsService, providedIn: "root" });



export * from './seo.service';
export * from './feedback.service';
export * from './maintenance.service';
export * from './stripe.service';
export * from './property.service';
export * from './storage.service';
export * from './storage-local.service';
export * from './tenancy.service';
export * from './user.service';
export * from './user-verification.service';
export * from './user-verification-steps.service';
export * from './viewing.service';
export * from './tenancy-offer.service';
export * from './vouch-referencing.service'
export * from './references.service';
export * from './notifications.service';
export * from './group.service';
export * from './bunk-passport.service';
export * from './intercom.service';
export * from './pagination.service';
export * from './notes.service';
export * from './types.service'
export * from './csv.service';
export * from './payments-new.service';
export * from './algolia-adapter';
export * from './dialog';
import {FeedbackService} from './feedback.service';
import {MaintenanceService} from './maintenance.service';
import {InvoiceService} from './invoice.service'
import {StripeService} from './stripe.service';
import {PropertyService} from './property.service';
import {StorageService} from './storage.service';
import {StorageLocalService} from './storage-local.service';
import {TenancyService} from './tenancy.service';
import {UserService} from './user.service';
import {ViewingService} from './viewing.service';
import {SeoService} from './seo.service';
import {TenancyOfferService} from '@app/services/tenancy-offer.service';
import { VouchReferencingService } from './vouch-referencing.service';
import {ReferencesService} from '@app/services/references.service';
import {NotificationsService} from '@app/services/notifications.service';
import {GroupService} from '@app/services/group.service';
import {BunkPassportService} from '@app/services/bunk-passport.service';
import { IntercomService } from '@app/services/intercom.service';
import { AdminService } from './admin.service';
import {PaginationService} from '@app/services/pagination.service';
import {UserVerificationService} from './user-verification.service';
import {UserVerificationStepsService} from './user-verification-steps.service';
import {TenancyPackageService} from '@app/services/tenancy-package.service';
import { StatsService } from './stats.service';
import {PaymentsService} from '@app/services/payments.service';
import { ExpensesService } from '@app/services/expenses.service';
import { CSVService } from './csv.service';
import { PaymentsNewService } from './payments-new.service';
import { AlgoliaAdapter } from './algolia-adapter';

export const APP_SERVICES = [
  FeedbackService,
  MaintenanceService,
  StripeService,
  ExpensesService,
  PaymentsService,
  PropertyService,
  StorageService,
  StorageLocalService,
  TenancyService,
  UserService,
  ViewingService,
  SeoService,
  TenancyOfferService,
  VouchReferencingService,
  ReferencesService,
  NotificationsService,
  GroupService,
  BunkPassportService,
  IntercomService,
  AdminService,
  PaginationService,
  StatsService,
  TenancyPackageService,
  InvoiceService,
  CSVService,
  PaymentsNewService,
  AlgoliaAdapter
];


export const VERIFY_SERVICES = [
  UserVerificationService,
  UserVerificationStepsService
];



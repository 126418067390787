import { Component, HostListener, ViewChild, AfterViewInit } from '@angular/core';
import { UserService, BunkPassportService } from '@app/services';
import { TenantVerifyEventEmiterService } from '../profile-tenant.service';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { TenantAccountVerifyControllerComponent } from './bunk-passport-verify-controller.component';
import { Profile, Tenant } from '@env/routing';
import { VeriStepRoute } from '../profile-tenant.constants';
import { AuthService } from '@app/core/services';
import { FilePreviewOverlayRef } from '@app/shared/_components/file-preview-overlay/file-preview-overlay-ref';
import { tap, takeUntil, catchError } from 'rxjs/operators';
import { Subscription, of } from 'rxjs';
import { TRANSLATIONS } from '@app/_constants/translations.constants';

@Component({
  selector: 'app-tenant-verify-full',
  templateUrl: './bunk-passport-verify-full.component.html',
  styleUrls: ['../profile-tenant.scss']
})
export class TenantAccountVerifyFullComponent extends TenantAccountVerifyControllerComponent implements AfterViewInit {
  @ViewChild('verifyForm', { static: false }) verifyForm;
  protected tenancyOfferId: string;
  public hoverIndex = -1;
  public isDesktop: boolean;
  public menuStep: string;
  public activeVeriStepRoute: VeriStepRoute;
  public isPassportMenuUrl: boolean;
  public showVerfiedOverlay = false;
  public showPassportProgressOverlay = false;
  public verifiedScale = 0;
  public whatPassportOverlay: FilePreviewOverlayRef;
  public completePassportOverlay: FilePreviewOverlayRef;
  private identitySub: Subscription;
  public landlord = TRANSLATIONS.landlord;

  constructor(
    _eventEmiter: TenantVerifyEventEmiterService,
    _router: Router,
    _route: ActivatedRoute,
    _user: UserService,
    _auth: AuthService,
    public _passport: BunkPassportService,
  ) {
    super(_eventEmiter, _router, _route, _user, _auth);
    this.menuStep = `/${Profile.tenant.base}/${Profile.tenant.bunk_passport.verify}`;
    this.tenancyOfferId = this._route.snapshot.queryParams.tenancy_offer_id;
    this._router.events.subscribe((val: NavigationEnd) => this.update());

    // Subscribe to the read only verification status (contego response) and respond as needed.
    // Do not automatically proceed to the next step as the user may be interacting with another step - unless their verification_tier is 3
    this.identitySub = this.userReadOnly$.subscribe(userRE => {
      if (userRE.verification_status === 'approved') {
        if (userRE.verification_tier === 3) {
          this.showVerfiedOverlay = true;
        }

        if (this._passport.getBunkPassportIdentityStep(userRE)) {
          return;
        }
        this._passport.updateBunkPassport(Profile.tenant.bunk_passport.identity).pipe(
          takeUntil(this.destroy$),
          catchError(err => of([]))
        )
        .subscribe(
          res => {},
          err => {
            console.log('HTTP Error', err);
          },
          () => {
            console.log('HTTP request completed.');
          }
        );
        this.identitySub.unsubscribe();
      }
    });

    this._eventEmiter.gotoNextStep.pipe(
      tap(e => {
        this.whatPassportOverlay.close();
      }),
      takeUntil(this.destroy$)
    ).subscribe();
  }

  ngAfterViewInit() {
    const stepRoute: VeriStepRoute = this.update();
    if (this.isDesktop) {
      this.gotoStep(stepRoute ? stepRoute : this.filteredSteps[0]);
    } else {
      const currStepId = this.filteredSteps.findIndex((step: VeriStepRoute) => this._router.url.includes(step.path));
      this.currentStepNumber =  currStepId >= 0 ? currStepId : 0;
    }
  }

  update(): VeriStepRoute {
    const url = this._router.url;
    this.isPassportMenuUrl = url === this.menuStep; // checks if the URl is the parent /tenant/verify
    this.activeVeriStepRoute = this.steps.find(step => url.includes(step.path));
    this.updatePassportCompletion();
    return this.activeVeriStepRoute;
  }

  updatePassportCompletion() {
    this.verifiedScale = Math.ceil((100 / this.filteredSteps.length) * this.filteredSteps.filter(step => step.status === 'approved').length);
  }

  onHover(i: number) {
    this.hoverIndex = i;
  }


  @HostListener('window:resize', ['$event'])
  onResize() {
    this.updatePassportCompletion();
    // Load default first step if resize to large
    setTimeout(() => {
      if (this.isDesktop) {
        this.gotoStep(this.activeVeriStepRoute ? this.activeVeriStepRoute : this.filteredSteps[0]);
      } else {
        this.hoverIndex = -1;
      }
    });
  }

  set desktop(bool: boolean) {
    this.isDesktop = bool;
  }

  goBackMobile() {
    this._router.navigate([Profile.tenant.base, Profile.tenant.bunk_passport.verify]);
  }

  closeAndGotoNextPage() {
    this.completePassportOverlay.close();
    setTimeout(() => this.gotoNextPage()); // allow for overlay to close before Angular's scrollPositionRestoration is activated 
  }

  gotoNextPage() {
    if (this.tenancyOfferId) {
      this._router.navigate([Tenant.base, Tenant.make_an_offer.base, this.tenancyOfferId, Tenant.make_an_offer.summary]);
    } else {
      this._router.navigate([Profile.tenant.base, Profile.tenant.bunk_passport.base]);
    }
  }

  gotoStep(targetStep: VeriStepRoute): void {
    super.gotoStep(targetStep);
    // If ther are no more steps then wrap this up with the verified overlay/prompt
    if (!targetStep) {
      this.showVerfiedOverlay = true;
      return;
    }
    // check for query params...
    if (targetStep && this.tenancyOfferId) {
      this._router.navigate([targetStep.path], { relativeTo: this._route, queryParamsHandling: 'merge' });
    } else {
      this._router.navigate([targetStep.path], { relativeTo: this._route});
    }
  }

  scrollToForm() {
    this.verifyForm.nativeElement.scrollIntoView({ behavior: 'smooth' });
  }
}



      <screen-detection (resized)="isDesktop = $event.isDesktop"></screen-detection>
      <ng-template #labelStackTemplate>
        <ng-content select="[bunk-label-stack]"></ng-content>
      </ng-template>
      <div class="bunk-form-field__wrapper" [ngClass]="{
        'bunk-form-field__wrapper--responsive': direction === 'responsive',
        'block__quote': blockQuote
      }">
        <span class="bunk-form-field__label-stack" *ngIf="showLabel">
          <ng-content select="[bunk-label]"></ng-content>
          <ng-container *ngIf="isDesktop && direction === 'responsive'">
            <ng-container *ngTemplateOutlet="labelStackTemplate" ></ng-container>
          </ng-container>
        </span>

        <ng-content select="[bunk-input]"></ng-content>
        <ng-content select="[input-errors]"></ng-content>
        <ng-container *ngIf="!isDesktop  || direction === 'column'">
          <ng-container *ngTemplateOutlet="labelStackTemplate" ></ng-container>
        </ng-container>
      </div>
    
import { Component, EventEmitter, Input, Output, ViewChild, ElementRef } from '@angular/core';
@Component({
  selector: 'input-uploader',
  template: `
      <input
      #inputRef
      class="displayNone"
      (change)="handleDocs.emit($event)"
      type="file"
      [multiple]="multiple"
      accept=".doc, .docx, .pdf, image/png, image/jpeg, image/jpg">
  `,
})
export class InputUploaderComponent {
  @Input() multiple = true;   // Sets or retrieves the Boolean value indicating whether multiple items can be selected from a list
  @Output() handleDocs = new EventEmitter<any>();
  @ViewChild('inputRef', { static: false }) fileInputRef: ElementRef<HTMLInputElement>;
  click = (): void => this.fileInputRef.nativeElement.click();

}

import { Component, OnInit, forwardRef, Optional, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR, NG_VALIDATORS, FormGroup, Validator, Validators, FormGroupDirective, FormBuilder, AbstractControl } from '@angular/forms';
import { AbstractValueAccessor } from '../abstract-value-accessor/abstract-value-accessor';
import { TRANSLATIONS } from "@app/_constants/translations.constants";
import { AuthService } from "@app/core/services";
import { UserService } from "@app/services";

const COUNTRY_CODES: (string | number)[] = [
  93, 358, 355, 213, 1 + ' ' + 684, 376, 244, 1 + ' ' + 264, 672,
  1268, 54, 374, 297, 61, 43, 994, 1242, 973, 880, 1246, 375, 32, 501, 229, 1441, 975,
  591, 387, 267, 55, 246, 673, 359, 226, 257, 855, 237, 238, 345, 236, 235, 56, 86,
  57, 269, 242, 243, 682, 506, 225, 385, 53, 357, 420, 45, 253, 1767, 1849, 593, 20, 503,
  240, 291, 372, 251, 500, 298, 679, 33, 594, 689, 241, 220, 995, 49, 233, 350, 30,
  299, 1473, 590, 1671, 502, 224, 245, 595, 509, 379, 504, 852, 36, 354, 91, 62, 98,
  964, 353, 972, 39, 1876, 81, 962, 7 + ' ' + 7, 254, 686, 850, 82, 965, 996, 856, 371,
  961, 266, 231, 218, 423, 370, 352, 853, 389, 261, 265, 60, 960, 223, 356, 692, 596, 222,
  230, 262, 52, 691, 373, 377, 976, 382, 1664, 212, 258, 85, 264, 674, 977, 31, 599, 687,
  64, 505, 227, 234, 683, 1 + ' ' + 670, 968, 92, 680, 970, 507, 675, 51, 63, 872,
  48, 351, 1 + ' ' + 939, 974, 40, 7, 250, 290, 1 + ' ' + 869, 1758, 508, 1784, 685, 378, 239,
  966, 221, 381, 248, 232, 65, 421, 386, 677, 252, 27, 34, 94, 249, 597, 47, 268,
  46, 41, 963, 886, 992, 255, 66, 670, 228, 690, 676, 1868, 216, 90, 993, 1649, 688, 256,
  380, 971, 44, 1, 598, 998, 678, 58, 84, 1284, 1340, 681, 967, 260, 263
];

@Component({
  selector: 'form-input-phone',
  templateUrl: './input-phone.component.html',
  styleUrls: ['./input-phone.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputPhoneComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => InputPhoneComponent),
      multi: true
    }
  ]
})
export class InputPhoneComponent extends AbstractValueAccessor implements OnInit, Validator {
  @Input() showLabel = false;
  @Input() labelValue = 'Phone number';
  @Input('submitAttempted') set submitAttempted(boo: boolean) {
    this._submitAttempted = boo;
    if (boo) {
      this.dirtyFormGroup();
    }
  }
  get submitAttempted(): boolean {
    return this._submitAttempted;
  }
  private _submitAttempted = false;
  public formGroup: FormGroup;
  public countryCodes: (string | number)[];

  constructor(
    @Optional() private reactiveForm: FormGroupDirective,
    private _formBuilder: FormBuilder,
    private _auth: AuthService,
    private _user: UserService
  ) {
    super();
    this.countryCodes = COUNTRY_CODES.sort();
  }

  dirtyFormGroup() {
    if (this.formGroup) {
      const controls = this.formGroup.controls;
      for (const control in controls) {
        if (controls.hasOwnProperty(control)) {
          this.formGroup.controls[control].markAsTouched();
        }
      }
    }
  }

  ngOnInit() {
    this.formGroup = this.initFormGroup();
    this.reactiveForm.ngSubmit.subscribe((data: Event) => {
      this.submitAttempted = true;
      if (this.reactiveForm.submitted) {
        this.dirtyFormGroup();
      }
    });
  }

  initFormGroup(): FormGroup {
    const addrFormGroup = this._formBuilder.group({
      area_code: [this._auth.authenticated && this._user.userDb.profile_data.country_code ? this._user.userDb.profile_data.country_code: TRANSLATIONS.country_code, Validators.required],
      phone_number: [null, Validators.required]
    });
    return new FormGroup(addrFormGroup.controls, { updateOn: 'blur' });
  }

  public onTouched: () => void = () => { };

  writeValue(val: any): void {
    super.writeValue(val);
    if (val) {
      this.formGroup.patchValue(val, { emitEvent: false });
    }
  }
  registerOnChange(fn: any): void {
    this.formGroup.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.formGroup.disable() : this.formGroup.enable();
  }

  validate(control: AbstractControl) {
    return this.formGroup.valid ? null : { invalidForm: { valid: false, message: 'InputPhoneComponent > formGroup fields are invalid' } };
  }
}

import { Profile } from '@env/routing';
import { Route } from '@angular/router';
import { TenantAccountVerifyInfoComponent } from './_shared/tenant-account-verify-info.component';
import { TenantAccountVerifySearchPreferencesComponent } from './_shared/tenant-account-verify-search-preferences';
import { TenantAccountVerifyEmploymentComponent } from './_shared/tenant-account-verify-employment.component';
import { TenantAccountVerifyGuarantorDetailsComponent } from './_shared/tenant-account-verify-guarantor-details.component';
import { TenantAccountVerifyIdentityComponent } from './_shared/tenant-account-verify-identity.component';
import { TenantAccountVerifyPaymentComponent } from './_shared/tenant-account-verify-payment.component';
import { TenantAccountCreditInfoComponent } from './_shared/tenant-account-verify-credit-info.component';
import { TenantBunkPassportOverViewComponent } from './_shared/tenant-bunk-passport-overview.component';
import { TenantBunkPassportTierTwoGuard } from './guards/profile-tenant.guard';
import { VerificationStatusString } from '@app/_models/verfify-types.model';
import { TenantAccountVerifyPreviousTenancyComponent } from './_shared/tenant-account-verify-previous-tenancy.component';

export interface VeriStepRoute extends Route {
  id?: number;
  title?: string;
  tier?: number;
  children?: VeriStepRoutes;
  status?: VerificationStatusString;
  studentsOnly?: boolean;
  canActivate?: any;
  hidden?: boolean;
}
export type VeriStepRoutes = VeriStepRoute[];

export const requestIntro: VeriStepRoute = {
  path: Profile.tenant.bunk_passport.intro,
  component: TenantBunkPassportOverViewComponent,
  title: 'Rental passport',
  tier: 2,
};

export const verificationSteps: VeriStepRoutes = [
  {
    path: Profile.tenant.bunk_passport.info,
    component: TenantAccountVerifyInfoComponent,
    title: 'Official details',
    tier: 2,
    id: 1
  },
  {
    path: Profile.tenant.bunk_passport.search_details,
    component: TenantAccountVerifySearchPreferencesComponent,
    title: 'Search details',
    tier: 2,
    id: 2
  },
  {
    path: Profile.tenant.bunk_passport.employment_details,
    component: TenantAccountVerifyEmploymentComponent,
    title: 'Employment details',
    tier: 2,
    id: 3
  },
  {
    path: Profile.tenant.bunk_passport.previous_tenancy,
    component: TenantAccountVerifyPreviousTenancyComponent,
    title: 'Previous landlord details',
    tier: 3,
    canActivate: [TenantBunkPassportTierTwoGuard],
    id: 4
  },
  {
    path: Profile.tenant.bunk_passport.guarantor_details,
    component: TenantAccountVerifyGuarantorDetailsComponent,
    title: 'Guarantor details',
    tier: 3,
    studentsOnly: true,
    canActivate: [TenantBunkPassportTierTwoGuard],
    id: 5
  },
  {
    path: Profile.tenant.bunk_passport.identity,
    component: TenantAccountVerifyIdentityComponent,
    title: 'Identity verification',
    tier: 3,
    canActivate: [TenantBunkPassportTierTwoGuard],
    id: 6
  },
  {
    path: Profile.tenant.bunk_passport.credit_info,
    component: TenantAccountCreditInfoComponent,
    title: 'Credit info',
    tier: 3,
    canActivate: [TenantBunkPassportTierTwoGuard],
    id: 7
  }
];


      <div>
        <h2 class="text--lg">University details</h2>
        <p>If you're currently studying at university please complete the form below</p>

      </div>

      <form [formGroup]="formGroup" (ngSubmit)="nextStep(formGroup.value)">
        <form-university-details formControlName="university_details"></form-university-details>

        <div class="margin-bottom--xl">
          <button class="btn__generic btn__form-submit btn__form-submit--align-right" type="submit">
            <!--[disabled]="formGroup.invalid || submittingData">-->
            <span *ngIf="!submittingData && !submittingDataSuccess">Save<span *ngIf="!standAlone"> and continue</span></span>
            <processing-spinner *ngIf="submittingData || submittingDataSuccess" color="#FFFFFF" [complete]="submittingDataSuccess"></processing-spinner>
          </button>
        </div>
      </form>
  
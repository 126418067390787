import {Injectable} from '@angular/core';
import {MaintenanceModel} from '@app/_models/maintenance.model';
import {Observable, from as fromPromise} from 'rxjs';
import {UserService} from '@app/services/user.service';
import {AuthService} from '@app/core/services/auth.service';
import { switchMap, tap } from 'rxjs/operators';
import {ToastrService} from 'ngx-toastr';
import {AngularFirestore, AngularFirestoreCollection} from '@angular/fire/firestore';
import { from } from 'rxjs/internal/observable/from';
import { isNull } from 'util';
import { TenancyService } from '@app/services/tenancy.service';
import { of } from 'rxjs/internal/observable/of';

@Injectable()
export class MaintenanceService {
  public maintenanceCollection: AngularFirestoreCollection<any>;
  public tenancyCollection: AngularFirestoreCollection<any>;

  constructor(private afs: AngularFirestore,
              private _user: UserService,
              private _auth: AuthService,
              private _tenancy: TenancyService,
              private _toastr: ToastrService) {
    this.maintenanceCollection = afs.collection<any>('maintenance');
    this.tenancyCollection = afs.collection<any>('tenancies');
  }

  createMaintenanceRequest(request_id: string, form: any, tenancy: any) {

    const item: MaintenanceModel = {
      created_by: {
        first_name: this._user.user.profile_data.first_name,
        last_name: this._user.user.profile_data.last_name,
        email: this._user.user.profile_data.email,
        profile_image_url: this._user.user.profile_data.profile_image_url,
        uid: this._user.user.uid,
      },
      property_id: tenancy.property_id,
      request_id: request_id,
      tenancy_id: tenancy.tenancy_id,
      landlord_id: tenancy.landlord_uid,
      team_id: (tenancy.team_id) ? tenancy.team_id : null,
      title: form.title,
      maintenance_location: form.maintenance_location,
      has_given_access_permission: form.has_given_access_permission,
      description: form.description,
      is_complete: false,
      maintenance_images_url: form.maintenance_images_url,
      status: 'pending',
      created_at: new Date(),
      priority: null
    };
    return fromPromise(this.maintenanceCollection.doc(request_id).set(item));
  }

  updateMaintenanceRequest(request_id: string, form: any) {
   return from(this.maintenanceCollection.doc(request_id).set({...form}, {merge: true}));
  }
  updatePriority(request_id: string, priority: string) {
    return from(this.maintenanceCollection.doc(request_id).set({priority}, {merge: true}));
  }
  updateStatusDate(request_id: string, status: string) {
    const date = (status === 'in_progress') ? 'accepted_at' : (status === 'completed') ? 'completed_at' : null;
    const item = {
      status: status,
      is_complete: (status === 'completed')
    };
    if (!isNull(date)) {
      item[date] = new Date();
    }
    return from(this.maintenanceCollection.doc(request_id).set(item, {merge: true}));
  }

  getMaintenanceById(id) {
    return this.maintenanceCollection.doc(id);
  }

  createRefMaintenance(maintenance_id: string, tenancy_id: string): Observable<any> {
    const item = {maintenance_id: {[maintenance_id]: true}};
    return fromPromise(this.tenancyCollection.doc(tenancy_id).set(item, {merge: true}));
  }

  getAllMaintenanceByPropertyId(property_id: string) {
    return this.afs.collection('maintenance', ref =>
      ref.where('property_id', '==', property_id)
    );
  }

  getAllMaintenanceByLandlordUid():  Observable<any> {
    const teamQuery = this._user.userReadOnlyDb.team_ids
      ? this.afs.collection('maintenance', ref => ref
        .where('team_id', 'in', this._user.userReadOnlyDb.team_ids)
        .orderBy('created_at', 'desc')).valueChanges()
      : of([]);
    const landlordQuery = this.afs.collection('maintenance', ref => ref
      .where('landlord_id', '==', this._auth.currentUserId)
      .orderBy('created_at', 'desc')).valueChanges();

    return this._auth.getCurrentUserCustomClaims().pipe(
      switchMap((res: boolean) => {
        return (res && !this._auth.currentUser.displayName)
          ? this.afs.collection('maintenance', ref => ref.where('request_id', '>', '').orderBy('request_id', 'desc').orderBy('created_at', 'desc')).valueChanges()
          : this._auth.combineQueries(teamQuery, landlordQuery);
      })
    );
  }
  getAllMaintenanceByTenantUid():  AngularFirestoreCollection<any> {
    return this.afs.collection('maintenance', ref =>
      ref.where('created_by.uid', '==', this._auth.currentUserId)
        .where('tenancy_id', '==', this._tenancy.getIdCurrentTenancy())
        .orderBy('created_at', 'desc')
    );
  }


  GetAllLandlordMaintenanceByStatus(status: string): Observable<any> {
    const teamQuery = this._user.userReadOnlyDb.team_ids
      ? this.afs.collection('maintenance', ref => ref
        .where('team_id', 'in', this._user.userReadOnlyDb.team_ids)
        .where('status', '==', status)).valueChanges()
      : of([]);

    const landlordQuery = this.afs.collection('maintenance', ref => ref
      .where('landlord_id', '==', this._auth.currentUserId)
      .where('status', '==', status)).valueChanges();

    return this._auth.getCurrentUserCustomClaims().pipe(
      switchMap((res: boolean) => {
        return (res && !this._auth.currentUser.displayName)
          ? this.afs.collection('maintenance', ref => ref.where('status', '==', status)).valueChanges()
          : this._auth.combineQueries(teamQuery, landlordQuery);
      })
    );

  }


  updateIsUrgentStatus(maintenance_id: string, is_urgent: boolean) {
    const item = {
      is_urgent: is_urgent
    };
    return fromPromise(this.maintenanceCollection.doc(maintenance_id).update(item));
  }

  getMaintenanceStatus(role: string, status: string) {
    const maintenanceStatus = {
      pending: role === 'landlord' ? 'New request' : 'Request submitted',
      in_progress: 'In progress',
      completed: 'Completed'
    };

    return maintenanceStatus[status];
  }


}


  <form [formGroup]="guarantorForm" autocomplete="none">

    <bunk-form-field *ngIf="getFormControl('has_uk_guarantor') && showUKGuarantor">
    <label bunk-label>Do you have a UK guarantor?</label>
    <div bunk-input>
      <mat-radio-group formControlName="has_uk_guarantor" class="flex flex-row margin-bottom" (change)="setGuarantorValidators(getFormControl('has_uk_guarantor').value)">
        <mat-radio-button [value]="true" class="bunk-outlined-radio mr_5" [ngClass]="{'bunk-outlined-radio--selected': getFormControl('has_uk_guarantor').value === true}" >Yes</mat-radio-button>
        <mat-radio-button [value]="false" class="bunk-outlined-radio" [ngClass]="{'bunk-outlined-radio--selected': getFormControl('has_uk_guarantor').value === false}">No</mat-radio-button>
      </mat-radio-group>
      <mat-error class="bunk-mat-error-font-match"*ngIf="getFormControl('has_uk_guarantor').getError('required') && submitAttempted">Please select</mat-error>
    </div>
    </bunk-form-field>


      <div class="block-info block-info--advice" *ngIf="getFormControl('has_uk_guarantor') && getFormControl('has_uk_guarantor').value === false">
        <h3 class="overline">Note</h3>
        <p>Without a UK guarantor, the {{landlord}} will typically ask you to pay all the rent upfront.</p>
      </div>

       <ng-container *ngIf="getFormControl('has_uk_guarantor') && getFormControl('has_uk_guarantor').value || !showUKGuarantor">

      <bunk-form-field *ngIf="getFormControl('referee_name')">
        <label bunk-label>What is your guarantors name?</label>
        <mat-form-field bunk-input appearance="outline">
          <input matInput formControlName="referee_name" type="text" placeholder=" E.g Georgina Ryder">
          <mat-error>Please enter your guarantor's name</mat-error>
        </mat-form-field>
      </bunk-form-field>

      <bunk-form-field *ngIf="getFormControl('referee_email')">
         <label bunk-label> What is your guarantors email?</label>
         <mat-form-field bunk-input appearance="outline">
           <input matInput formControlName="referee_email" type="text" placeholder="E.g georgina19@gmail.com">
           <mat-error>Please enter your guarantors email</mat-error>
         </mat-form-field>
       </bunk-form-field>

      <bunk-form-field *ngIf="getFormControl('referee_phone')">
        <label bunk-label >What is your guarantors number?</label>
        <div bunk-input>
        <form-input-phone
          bunk-input
          formControlName="referee_phone"
          showLabel="true"
          [submitAttempted]="submitAttempted"
        ></form-input-phone>
        </div>
      </bunk-form-field>

      <bunk-form-field *ngIf="getFormControl('relationship')">
        <label bunk-label >What is their relationship to you?</label>
        <mat-form-field bunk-input appearance="outline">
          <mat-select formControlName="relationship" placeholder="Select">
            <mat-option *ngFor="let type of relationshipTypes" [value]="type.value">
              {{type.viewValue}}
            </mat-option>
          </mat-select>
          <mat-error>Please enter the relationship to you</mat-error>
        </mat-form-field>
      </bunk-form-field>

         <bunk-form-field *ngIf="getFormControl('referee_dob')">
           <label bunk-label>What is your guarantors of birth?</label>
           <div bunk-input>
             <date-selector
               yearStart="1901"
               formControlName="referee_dob"
               [submitAttempted]="submitAttempted">
             </date-selector>
           </div>
         </bunk-form-field>

      <bunk-form-field *ngIf="getFormControl('referee_country_of_residence')">
        <label bunk-label >What is their country of residence?</label>
        <mat-form-field bunk-input appearance="outline">
          <mat-select formControlName="referee_country_of_residence" placeholder="Select">
            <mat-option *ngFor="let country of countryOfResidence" [value]="country.value">
              {{country.country}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </bunk-form-field>

      <bunk-form-field *ngIf="getFormControl('has_declared_uk_homeowner')">
      <label bunk-label>Are they a UK homeowner?</label>
      <div bunk-input>
        <mat-radio-group formControlName="has_declared_uk_homeowner" class="flex flex-row margin-bottom">
          <mat-radio-button [value]="true" class="bunk-outlined-radio mr_5" [ngClass]="{'bunk-outlined-radio--selected': getFormControl('has_declared_uk_homeowner').value === true}" >Yes</mat-radio-button>
          <mat-radio-button [value]="false" class="bunk-outlined-radio" [ngClass]="{'bunk-outlined-radio--selected': getFormControl('has_declared_uk_homeowner').value === false}">No</mat-radio-button>
        </mat-radio-group>
        <mat-error class="bunk-mat-error-font-match"*ngIf="getFormControl('has_declared_uk_homeowner').getError('required') && submitAttempted">Please select</mat-error>

      </div>
      </bunk-form-field>
      </ng-container>
  </form>
  
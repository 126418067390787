
    <div class="overlay-content"
      [@slideContent]="animationState"
      (@slideContent.start)="onAnimationStart($event)"
      (@slideContent.done)="onAnimationDone($event)">
      <div class="bunk-overlay__external-content">
        <div class="bunk-overlay__header bunk-overlay__header--white">
          <h2 *ngIf="pdf.name" class="truncate text--md">{{pdf.name}}</h2>
          <button class="bunk-overlay__close-btn" (click)="close()">close<mat-icon svgIcon="icon-close"></mat-icon></button>
        </div>
        <div class="bunk-overlay__content bunk-overlay__content--scroll-y">
          <div class="spinner-wrapper" *ngIf="loading">
          <loading-spinner></loading-spinner>
          </div>
          <iframe #pdfFrame [@fade]="(loading ? 'fadeOut' : 'fadeIn')" [src]="src | async"></iframe>
        </div>
      </div>
    </div>
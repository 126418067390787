
    <h3 class="margin-bottom--md">Create Expenses</h3>
    <form [formGroup]="formGroup" autocomplete="none" (ngSubmit)="submitForm()">

    <bunk-form-field [showLabel]="true">
    <label bunk-label>What is the expense type?</label>

    <mat-form-field  bunk-input appearance="outline">
      <mat-select [formControlName]="'expense_type'">
      <mat-option *ngFor="let status of expenseTypes" [value]="status.value">
      {{status.viewValue}}
    </mat-option>
      </mat-select>
      <mat-error>Expense type is required</mat-error>
    </mat-form-field>
  </bunk-form-field>

      <bunk-form-field [showLabel]="true">
        <label bunk-label>What is the status of the expense?</label>
        <mat-form-field  bunk-input appearance="outline">
          <mat-select [formControlName]="'status'">
            <mat-option *ngFor="let status of expenseStatuses" [value]="status.value">
              {{status.viewValue}}
            </mat-option>
          </mat-select>
          <mat-error>Expense status is required</mat-error>
        </mat-form-field>
      </bunk-form-field>

      <bunk-form-field [showLabel]="true" *ngIf="(isAdmin$ | async) || moneyhubUserId">
        <label bunk-label>Select the outward transaction</label>
        <div bunk-input>
            <instant-new-algolia-search
              [indexName]="'transactions'"
              [multiselect]="false"
              [fieldsToReturn] = "['transaction_id','long_description', 'short_description','timestamp_paid', 'transaction_amount']"
              [fieldsToDisplay]="['long_description', {path:'transaction_amount', prefix:'£'}, {path:'timestamp_paid', formatTime:true}]"
              (valueChange)="setTransaction($event)"></instant-new-algolia-search>
        </div>
          <mat-error>Expense transaction is required</mat-error>
      </bunk-form-field>

      <bunk-form-field [showLabel]="true">
      <label bunk-label>What is the expense amount?</label>
      <mat-form-field  bunk-input appearance="outline">
        <input [formControlName]="'amount'" type="number" matInput>
        <mat-error>The expense amount is required</mat-error>
      </mat-form-field>
    </bunk-form-field>

    <bunk-form-field [showLabel]="true">
    <label bunk-label>When was the expense due?</label>
    <mat-form-field  bunk-input appearance="outline">
      <input [formControlName]="'due_date'" matInput
             (click)="datePaid.open()"
             [matDatepicker]="datePaid"
      >
      <mat-datepicker-toggle matSuffix [for]="datePaid"></mat-datepicker-toggle>
      <mat-datepicker #datePaid ></mat-datepicker>
      <mat-error>Expense due date is required</mat-error>
    </mat-form-field>
  </bunk-form-field>

    <form-get-tenancy-details [resetForm]="resetForm" [formControlName]="'property_details'" [submitAttempted]="submitAttempted" [showReference] = "false"></form-get-tenancy-details>
    <ng-container>
        <bunk-form-field [showLabel]="true">
          <label bunk-label>What reference code do you want to use?</label>
          <mat-form-field bunk-input appearance="outline">
            <input type="text" [formControlName]="'expense_reference_code'" matInput>
            <mat-error>Please add a reference code</mat-error>
          </mat-form-field>
        </bunk-form-field>
      </ng-container>
      <div class="margin-top--lg flex justify-end">
        <button  class="btn__generic btn__form-submit btn__form-submit--align-right" type="submit">
          <span *ngIf="!submittingData && !submittingDataSuccess"> Create Expense</span>
          <processing-spinner *ngIf="submittingData || submittingDataSuccess" color="#FFFFFF" [complete]="submittingDataSuccess"></processing-spinner>
        </button>
      </div>
    </form>
  
<ng-container *ngIf="(landlordPublic$ | async) as landlordPublic">
  <ng-container *ngIf="showManager">
    <div *ngIf="showLogo || dashboard" class="landlord-card"
    [ngClass]="{'hide-lg': !dashboard, 'hide-sm': landlordId === 'R4EGdbwhhlWwF6KyKwmTBTxRXey1', 'landlord-card--search': search}">
      <img
        *ngIf="landlordPublic.manager.bannerAsideLg && environment.is_bunk_environment"
        (click)="viewAllPropsByManager()"
        class="landlord-card__manager-logo"
        src="{{landlordPublic.manager.bannerAsideLg}}"
        alt="{{ landlordPublic.manager.companyName ? landlordPublic.manager.companyName : 'Property manager'}}">
    </div>
    <div class="landlord-card">
      <profile-avatar *ngIf="!dashboard" [profileImgUrl]="landlordPublic.profileImgUrl" [profileName]="landlordPublic.isTeamData ? landlordPublic.manager.companyName :landlordPublic.firstName"></profile-avatar>
      <!-- <img *ngIf="!dashboard" [src]="landlordPublic.profileImgUrl" alt="{{landlordPublic.manager.companyName ? landlordPublic.manager.companyName : 'Landlord profile picture'}}" class="landlord-image profile__background profile__background--md"> -->

      <div class="landlord-bio-container">
        <p class="margin-left--sm" [ngStyle]="{'width': landlordPublic.isTeamData ? '100%' : '', 'margin': landlordPublic.isTeamData ? 'auto' : ''}">{{ landlordPublic.isTeamData ? 'Property Managed by' : 'This property is owned by' }} <strong>{{ landlordPublic.isTeamData ? landlordPublic.manager.companyName :landlordPublic.firstName }}</strong></p>
        <p *ngIf="dashboard && landlordPublic.manager.contactNumber" class="hide-sm">Contact: <a href="{{'tel:' + landlordPublic.manager.contactNumber}}">{{landlordPublic.manager.contactNumber}}</a></p>
        <div class="landlord-bio-container__options">
          <ng-container *ngIf="client_data.company_name === 'Bunk'">
          <img
            src="/assets/logos/partner-logo.svg"
            class="landlord-bio-container__options-partner"
            #tooltip="matTooltip"
            matTooltip="A partner is a property manager whose properties have been fully verified and meet our quality standards"
            matTooltipPosition="above"
            aria-tooltip="A partner is a property manager whose properties have been fully verified and meet our quality standards">
          </ng-container>

          <ng-container *ngIf="isCampusKeyTenant; else isNotCampusKey">
            <button *ngIf="_auth.authenticated && property" class="btn__seethrough btn--round btn--sm hide-sm" appSendMessage [property]="property"  (click)="closeOverlay.emit(true);handleAnonymousMessage();">Send message</button>

          </ng-container>
          <ng-template #isNotCampusKey>
            <button *ngIf="_auth.authenticated && property" class="btn__seethrough btn--round btn--sm hide-sm" appContactButton [property]="property" [addresseeId]="landlordPublic.uid" (click)="closeOverlay.emit(true);handleAnonymousMessage();">Send message</button>
          </ng-template>
        </div>
      </div>
    </div>
    <div class="landlord-card hide-md hide-lg">
      <div class="landlord-bio-container">
        <ng-container *ngIf="isCampusKeyTenant; else isNotTenantCampusKey">
        <button *ngIf="_auth.authenticated && property && !landlordPublic.isTeamData" class="btn__seethrough btn--round btn--sm w_100" appSendMessage [property]="property"  (click)="closeOverlay.emit(true);handleAnonymousMessage()">Send message</button>
      </ng-container>
        <ng-template #isNotTenantCampusKey>
        <button *ngIf="_auth.authenticated && property && !landlordPublic.isTeamData" class="btn__seethrough btn--round btn--sm w_100" appContactButton [property]="property" [addresseeId]="landlordPublic.uid" (click)="closeOverlay.emit(true);handleAnonymousMessage()">Send message</button>
      </ng-template>

        <a *ngIf="dashboard" class="btn__seethrough btn--round btn--sm margin-top--sm w_100" href="{{'tel:' + landlordPublic.manager.contactNumber}}">Call {{landlordPublic.manager.companyNameShort}} - {{landlordPublic.manager.contactNumber}}</a>
      </div>
    </div>
    <ng-container *ngIf="!landlordPublic.isTeamData">
    <div class="landlord-card landlord-card--bio" *ngIf="landlordPublic.manager && landlordPublic.manager.bio">
      <p>{{ landlordPublic.manager.bio }}</p>
      <button (click)="viewAllPropsByManager()" class="btn__seethrough btn--round btn--sm margin-top--md">View all properties</button>
      <a class="btn__seethrough btn--round btn--sm margin-top--sm hide-lg" href="{{'tel:' + landlordPublic.manager.contactNumber}}">Call {{landlordPublic.manager.companyNameShort}} - {{landlordPublic.manager.contactNumber}}</a>
    </div>
  </ng-container>
  </ng-container>

  <ng-container *ngIf="!showManager">
    <div class="landlord-card">
      <profile-avatar [profileImgUrl]="landlordPublic.profileImgUrl" [showManager]="showManager" [profileName]="landlordPublic.isTeamData ? landlordPublic.manager.companyName :landlordPublic.firstName"></profile-avatar>
      <span [ngStyle]="{'display': landlordPublic.isTeamData ? 'contents' : ''}">
        <p class="margin-left--sm" [ngStyle]="{'width': landlordPublic.isTeamData ? '100%' : '', 'margin': landlordPublic.isTeamData ? 'auto' : ''}">{{ landlordPublic.isTeamData ? 'Property Mananged by' : 'This property is owned by' }} <strong>{{ landlordPublic.isTeamData ? landlordPublic.manager.companyName :landlordPublic.firstName }}</strong></p>

        <ng-container *ngIf="isCampusKeyTenant; else isNotCampusKeyTenant">
              <button *ngIf="_auth.authenticated && property" class="btn__seethrough btn--round btn--sm" appSendMessage [property]="property" (click)="closeOverlay.emit(true);handleAnonymousMessage()">Send message</button>
        </ng-container>

        <ng-template #isNotCampusKeyTenant>
        <button *ngIf="_auth.authenticated && property" class="btn__seethrough btn--round btn--sm" appContactButton [property]="property" [addresseeId]="landlordPublic.isTeamData ? property.landlord_uid :landlordPublic.uid" (click)="closeOverlay.emit(true);handleAnonymousMessage()">Send message</button>
      </ng-template>
        <ng-content *ngIf="!_auth.authenticated"></ng-content>
      </span>
    </div>
  </ng-container>
</ng-container>

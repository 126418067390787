


    <bunk-dialog *ngIf="bedroomId" [component]="contentDialog" (dialogRef)="dref = $event" restrict="true">
      <button class="btn__cta"
              (click)="handleAnonymousOffer()">Apply now</button>
    </bunk-dialog>


    <ng-template #contentDialog>
      <div  class="flex justify-end margin-bottom--xs flex-row">
        <button class="bunk-overlay__close-btn" (click)="dref.close()"><mat-icon svgIcon="icon-close"></mat-icon></button>
      </div>

      <mat-dialog-content>

        <h2 class="margin-bottom--sm">Sign up as a tenant and {{(viewingRequest ? 'book a viewing' : 'rent property')}}</h2>

        <small class="flex flex-row items-center margin-bottom--md">Already have an account ? <button class="btn__pseudo-link" (click)="logIn()">Sign in</button></small>

        <form [formGroup]="signupformGroup" >
          <form-create-account formControlName="signup" roleInput="tenant" [showLabel]="false" direction="column"
                               (submitForm)="createAccountAndMakeOffer(signupformGroup.value)"
                               [showSpinner]="showPopUpSpinner$.value" title="Create account & continue">
          </form-create-account>

        </form>


      </mat-dialog-content>
    </ng-template>


  
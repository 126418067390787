
    <ng-container *ngIf="(passport$ | async) as passport">
      <div>
        <h2 class="text--lg">Official details</h2>
        <p data-test="landlordVerificationTitle">Please complete all information as it appears <strong>on your passport</strong>. This will enable you to be verified faster</p>
      </div>

      <form [formGroup]="formGroup" (ngSubmit)="nextStep(formGroup.value)">
        <label formGroupName="profile_image_url" #profileUploader>
          <bunk-uploader #profileUploader
                         [filePath]="filePath"
                         (uploadChange)="onUploadedFile($event)"
                         (uploadStart)="onUpStart()"
                         [multiple]="false"
                         hidden>
          </bunk-uploader>
        </label>
        <div class="cc ai_c margin-bottom--md">
          <div class="img_container">
            <ng-container *ngIf="!showSpinner; else spinner">
              <div>

              <profile-avatar size="lg" justified="center" [profileImgUrl]="_user.userDb?.profile_data.profile_image_url" [profileName]="_user.userDb?.profile_data.full_name"></profile-avatar>

                <!-- <img class="profile_image margin-bottom--sm" [src]="_user?.userDb.profile_data.profile_image_url" alt=""> -->
              </div>
              <label for="image" class="text">
                <div *ngIf="_user?.userDb.profile_data.profile_image_url !== null || !_user?.userDb.profile_data.profile_image_url " class="overlay">
                  <img class="delete" src='assets/svg/delete-bin.svg' (click)="remove()">
                </div>
              </label>
            </ng-container>
            <ng-template #spinner>
              <div class="img-background">
                <processing-spinner></processing-spinner>
              </div>
            </ng-template>
          </div>
          <p class="mb_2">Upload a profile image</p>
          <label for="property-pictures-upload" (click)="profileUploader.click()" class="">
            <button class="btn__seethrough btn--round" type="button" [disabled]="submittingData">Choose file</button>
          </label>
        </div>

        <form-info formControlName="info"></form-info>

        <div class="margin-bottom--xl">
          <button *ngIf="showPrev" (click)="goBack()" data-test="backButton" class="btn__seethrough btn__form-prev">Back</button>
          <button class="btn__generic btn__form-submit btn__form-submit--align-right" type="submit">
            <!--[disabled]="formGroup.invalid || submittingData">-->
            <span *ngIf="!submittingData && !submittingDataSuccess">Save<span *ngIf="!standAlone"> and continue</span></span>
            <processing-spinner *ngIf="submittingData || submittingDataSuccess" color="#FFFFFF" [complete]="submittingDataSuccess"></processing-spinner>
          </button>
        </div>
      </form>
    </ng-container>
  
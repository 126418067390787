import * as i0 from "@angular/core";
export class MathService {
    constructor() {
        this.getDifferenceBetweenTwoDates = (d1, d2) => {
            return d1.diff(d2, 'days');
        };
        this.sortArrayDescending = (array, item) => array.sort((a, b) => b[(item)] - a[(item)]);
        this.sortArrayAscending = (array, item) => (array && array.length > 0) ? array.sort((a, b) => a[item].toDate() - b[item].toDate()) : [];
    }
    roundToTwoDP(amount) {
        return Math.floor((amount + Number.EPSILON) * 100) / 100;
    }
}
MathService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MathService_Factory() { return new MathService(); }, token: MathService, providedIn: "root" });

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared';
import { LoginModule } from '@app/login/login.module';

import { PropertyDetailsComponent } from '@app/properties/_shared/property-details';
import { PropertiesSearchComponent } from '@app/properties/properties-search';

import { PropertyPageComponent } from '@app/properties/property-page';
import { PropertyPreviewComponent } from '@app/properties/property-page/property-preview';
import { PropertyPreviewBedroomComponent } from '@app/properties/property-page/property-preview-bedrooms';

import { ProfileTenantModule } from '@app/profile-tenant/profile-tenant.module';
import { SharedTenantModule } from '@app/core-tenant/_shared/shared-tenant.module';
import { UtilsModule } from '@app/core/utils/utils.module';
import { NavigationModule } from '@app/core/navigation/navigation.module';
import { BedroomPageComponent } from '@app/properties/property-page/bedroom-page.component';
import {OfferSignUpComponent, TenancyPackageComponent} from '@app/properties/_shared';
import { AdditionalExtrasComponent } from './_shared/additional-extras/additional-extras.component';

@NgModule({
  declarations: [
    PropertyDetailsComponent,
    PropertiesSearchComponent,
    PropertyPageComponent,
    PropertyPreviewComponent,
    PropertyPreviewBedroomComponent,
    BedroomPageComponent,
    OfferSignUpComponent,
    TenancyPackageComponent,
    AdditionalExtrasComponent
  ],
  imports: [
    CommonModule,
    UtilsModule,
    NavigationModule,
    SharedModule,
    SharedTenantModule,
    LoginModule,
    ProfileTenantModule,
  ],
  exports: [
    PropertyDetailsComponent,
    PropertiesSearchComponent,
    PropertyPageComponent,
    PropertyPreviewComponent,
    PropertyPreviewBedroomComponent,
    BedroomPageComponent,
    OfferSignUpComponent,
    TenancyPackageComponent,
    AdditionalExtrasComponent
  ],
  providers: [],
})
export class PropertiesModule { }

import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import * as i0 from "@angular/core";
import * as i1 from "@angular/common/http";
export class VouchReferencingService {
    constructor(_http) {
        this._http = _http;
    }
    createReferenceVouch(data) {
        return this._http.post(`${environment.firebaseConfig.apiUrl}/references-createVouchApplication`, data);
    }
    skipReferencing(tenancy_offer_id) {
        return this._http.post(`${environment.firebaseConfig.apiUrl}/references-landlordSkipReferences`, { tenancy_offer_id });
    }
}
VouchReferencingService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function VouchReferencingService_Factory() { return new VouchReferencingService(i0.ɵɵinject(i1.HttpClient)); }, token: VouchReferencingService, providedIn: "root" });

import { NgModule, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { OverlayModule } from '@angular/cdk/overlay';

import { CoreModule } from '@app/core/core.module';
import { LoginModule } from '@app/login/login.module';
import { PropertiesModule } from '@app/properties/properties.module';

import { APP_SERVICES } from './services';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { NotificationService } from '@app/chat/services/notification.service';
import { HelpersService } from '@app/shared/_services/helpers.service';
import { CookieService } from 'ngx-cookie-service';
// External library
import { RavenErrorHandler } from '@app/raven-error-handler';
import { environment } from '@env/environment';

import * as Raven from 'raven-js';
import { ServiceWorkerModule } from '@angular/service-worker';
import { PWAService } from '@app/services/pwa.service';
import { AuthService } from '@app/core/services';
import { CalendarModule } from './calendar/calendar.module';
import { PaymentsModule } from './payments/payments.module';
import { AlgoliaService } from './services/algolia.service';

// TODO: remove PopoverModule from the AppModule
// import { PopoverModule } from './payments-new/shared';
import { BunkDialogModule } from './shared/_components';
import { AddPropertyModule } from './shared/_components/add-property';
import { AgmCoreModule } from '@agm/core';

let UTILITIES_PROVIDERS = [];

if (environment.production) {
  Raven.config('https://3acb83436ad640429387acce2cd5e2a2@sentry.io/1280898').install();
  UTILITIES_PROVIDERS = [
    { provide: ErrorHandler, useClass: RavenErrorHandler }
  ];
}

// HOTFIX: https://github.com/Edenbj/bunk-web/pull/694
// @TODO: Remove when File uploader is fixed for safari ngsw
const isSafari = !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/);
// const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

if (isSafari) {
  navigator.serviceWorker.getRegistrations().then(function (registrations) {
    for (const registration of registrations) {
      registration.unregister();
    }
  });
}


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    OverlayModule,
    CoreModule,
    // HOTFIX: https://github.com/Edenbj/bunk-web/pull/694
    // @TODO: Remove when File uploader is fixed for safari ngsw
    ServiceWorkerModule.register('./ngsw-worker.js', { enabled: isSafari ? false : environment.production }),
    AppRoutingModule,
    LoginModule,
    PropertiesModule,
    AgmCoreModule.forRoot({
      apiKey: environment.gmapsConfig.apiKey,
    }),
    // CalendarModule
    PaymentsModule,
    // PopoverModule,
    AddPropertyModule,
    BunkDialogModule

  ],
  providers: [
    APP_SERVICES,
    AuthService,
    NotificationService,
    HelpersService,
    PWAService,
    CookieService,
    AlgoliaService,
    ...UTILITIES_PROVIDERS
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

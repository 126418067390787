
    <form [formGroup]="formGroup">
      <form-get-properties-details [propertyRequired]="propertyRequired" [formControlName]="'property_details'" (tenancyPropertyId)="setIds($event)" [submitAttempted]="submitAttempted"></form-get-properties-details>
    <ng-container *ngIf="tenancies$ | async as tenancies">
      <bunk-form-field [showLabel]="true">
        <label bunk-label>Select a tenancy</label>
        <mat-form-field bunk-input  appearance="outline">
          <mat-select [formControlName]="'tenancy_id'">
            <mat-optgroup *ngFor="let group of tenancies" [label]="group.name"
                          [disabled]="group.disabled">
            <mat-option *ngFor="let tenancy of group.tenancies" [value]="tenancy.value" (click)="selectTenancy(tenancy.tenants)">
              {{tenancy.viewValue}}
            </mat-option>
            </mat-optgroup>
          </mat-select>
        </mat-form-field>
      </bunk-form-field>
    </ng-container>

    <ng-container *ngIf="tenants && tenants.length && !tenantsHidden">
      <bunk-form-field [showLabel]="true">
        <label bunk-label>Which tenant is the payment associated with?</label>
        <mat-form-field bunk-input  appearance="outline">
          <mat-select [formControlName]="'sender_uid'">
            <mat-option *ngFor="let tenant of tenants" [value]="tenant.value" (click)="setUserReferenceCode(tenant.user_reference_code)">
              {{tenant.viewValue}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </bunk-form-field>
    </ng-container>

      <ng-container *ngIf="showReference">
        <bunk-form-field [showLabel]="true">
          <label bunk-label>What reference code do you want to use?</label>
          <mat-form-field bunk-input appearance="outline">
            <input type="text" [formControlName]="'user_reference_code'" matInput>
            <mat-error>Please add a reference code</mat-error>
          </mat-form-field>
        </bunk-form-field>
      </ng-container>
    </form>
  
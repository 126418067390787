import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddPropertyComponent } from './add-property.component';
import { BunkOverlayModule } from '../file-preview-overlay/bunk-overlay.module';
import { ProcessingSpinnerModule } from '../processing-spinner/processing-spinner.module';
import { InstantNewAlgoliaSearchModule } from '../searches/instant-new-algolia-search/instant-new-algolia-search.module';
import { MatFormFieldModule, MatIcon, MatIconModule } from '@angular/material';
import { CreateSimplePropertyModule } from '@app/shared/_forms/properties/create-simple-property/create-simple-property.module';


@NgModule({
  imports: [
    CommonModule,
    BunkOverlayModule,
    ProcessingSpinnerModule,
    InstantNewAlgoliaSearchModule,
    MatIconModule,
    MatFormFieldModule,
    CreateSimplePropertyModule,
  ],
  declarations: [AddPropertyComponent],
  exports:[AddPropertyComponent]
})
export class AddPropertyModule { }

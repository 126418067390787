import {Injectable} from '@angular/core';
import {CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {Profile} from '@env/routing';

import {UserService} from '@app/services';

import {map} from 'rxjs/operators';

@Injectable()
export class IsTenantVerifiedBunkPassportGuard implements CanActivate {
  constructor(private router: Router,
              private _user: UserService) {}

  canActivate(route: ActivatedRouteSnapshot,
              state: RouterStateSnapshot) {

    return this._user.userReadOnly$
      .pipe(
        map(user => {
          if (user) {
            if (user.verification_tier === 3) {
              return true;
            } else {
              this.router.navigate(['./' + Profile.tenant.base, Profile.tenant.bunk_passport.verify]);
              return false;
            }
          }
        })
      );

  }
}

@Injectable()
export class IsTenantUnVerifiedBunkPassportGuard implements CanActivate {
  constructor(
    private router: Router,
    private _user: UserService
  ) {}

  canActivate(route: ActivatedRouteSnapshot,
              state: RouterStateSnapshot) {

    return this._user.userReadOnly$
      .pipe(
        map(user => {
          if (user) {
            if (user.verification_tier < 3) {
              return true;
            } else {
              this.router.navigate(['./' + Profile.tenant.base, Profile.tenant.bunk_passport.base]);
              return false;
            }
          }
        })
      );

  }
}

@Injectable()
export class TenantBunkPassportTierTwoGuard implements CanActivate {
  constructor(private router: Router,
              private _user: UserService) {}

  canActivate(route: ActivatedRouteSnapshot,
              state: RouterStateSnapshot) {

    return this._user.userReadOnly$
      .pipe(
        map(user => {
          if (user) {
            if (user.verification_tier > 1) {
              return true;
            } else {
              this.router.navigate(['./' + Profile.tenant.base, Profile.tenant.bunk_passport.verify]);
              return false;
            }
          }
        })
      );

  }
}

import { Component, OnInit, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';

/**
 * @ngdoc component
 * @name ScreenDetectionComponent
 * @description 
 * Detect desktop screensize based on whether this template utility classes (hide-sm hide-md) are in affect. Allows us to work with CSS breakpoints.
 * @example
 * <screen-detection (resized)="isDesktop = $event.isDesktop"></screen-detection>
*/

@Component({
  selector: 'screen-detection',
  template: `<span class="hide-sm hide-md" #checker (window:resize)="handleResize($event)"></span>`
})
export class ScreenDetectionComponent implements OnInit {
  @Output() resized: EventEmitter<Object>;
  @ViewChild('checker', { static: true }) checker: ElementRef;

  constructor() {
    this.resized = new EventEmitter();
  }

  ngOnInit(): void {
    this.handleResize();
  }

  handleResize(e: any = null): void {
    const styles = getComputedStyle(this.checker.nativeElement);
    this.resized.emit({
      isDesktop: (styles.display !== 'none')
    });
  } 
}

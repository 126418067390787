import { NgModule } from '@angular/core';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule, MatIconRegistry } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatStepperModule } from '@angular/material/stepper';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatMenuModule } from '@angular/material/menu';
import { MatNativeDateModule } from '@angular/material';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatDialogModule } from '@angular/material/dialog';
import { MatRadioModule } from '@angular/material/radio';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatChipsModule } from '@angular/material/chips';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { DomSanitizer } from '@angular/platform-browser';
import { MatSortModule } from '@angular/material/sort';


export const CUSTOM_MATERIAL_MODULE = [
  MatIconModule,
  MatStepperModule,
  MatFormFieldModule,
  MatInputModule,
  MatSelectModule,
  MatCheckboxModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatAutocompleteModule,
  MatDialogModule,
  MatTabsModule,
  MatRadioModule,
  MatToolbarModule,
  MatButtonModule,
  MatSidenavModule,
  MatListModule,
  MatSlideToggleModule,
  MatProgressSpinnerModule,
  MatMenuModule,
  MatExpansionModule,
  MatChipsModule,
  MatPaginatorModule,
  MatTooltipModule,
  MatTableModule,
  MatSortModule,
];

import { REFERENCE_STATUS } from '@app/core-tenant/property/tenant-property-references/references.constants';

@NgModule({
  imports: [
    CUSTOM_MATERIAL_MODULE
  ],
  exports: [
    CUSTOM_MATERIAL_MODULE,
  ]
})
export class MaterialModule {
  constructor(private _iconRegistry: MatIconRegistry,
    private _sanitizer: DomSanitizer) {

    _iconRegistry.addSvgIcon(
      'bunk-icon-saved',
      _sanitizer.bypassSecurityTrustResourceUrl('assets/svg/navigation/bunk-icon-saved.svg')
    );

    _iconRegistry.addSvgIcon(
      'bunk-icon-admin',
      _sanitizer.bypassSecurityTrustResourceUrl('assets/svg/navigation/bunk-icon-admin.svg')
    );

    _iconRegistry.addSvgIcon(
      'bunk-icon-search',
      _sanitizer.bypassSecurityTrustResourceUrl('assets/svg/navigation/bunk-icon-search.svg')
    );

    _iconRegistry.addSvgIcon(
      'bunk-icon-home',
      _sanitizer.bypassSecurityTrustResourceUrl('assets/svg/navigation/bunk-icon-home.svg')
    );

    _iconRegistry.addSvgIcon(
      'bunk-icon-properties',
      _sanitizer.bypassSecurityTrustResourceUrl('assets/svg/navigation/bunk-icon-properties.svg')
    );

    _iconRegistry.addSvgIcon(
      'bunk-icon-application',
      _sanitizer.bypassSecurityTrustResourceUrl('assets/svg/navigation/bunk-icon-application.svg')
    );

    _iconRegistry.addSvgIcon(
      'bunk-icon-viewings',
      _sanitizer.bypassSecurityTrustResourceUrl('assets/svg/navigation/bunk-icon-viewings.svg')
    );

    _iconRegistry.addSvgIcon(
      'bunk-icon-maintenance',
      _sanitizer.bypassSecurityTrustResourceUrl('assets/svg/navigation/bunk-icon-maintenance.svg')
    );

    _iconRegistry.addSvgIcon(
      'bunk-icon-payment',
      _sanitizer.bypassSecurityTrustResourceUrl('assets/svg/navigation/bunk-icon-payment.svg')
    );

    _iconRegistry.addSvgIcon(
      'bunk-icon-chat',
      _sanitizer.bypassSecurityTrustResourceUrl('assets/svg/navigation/bunk-icon-chat.svg')
    );

    _iconRegistry.addSvgIcon(
      'icon-filter',
      _sanitizer.bypassSecurityTrustResourceUrl('assets/svg/icon-filters.svg')
    );

    _iconRegistry.addSvgIcon(
      'icon-expand-less',
      _sanitizer.bypassSecurityTrustResourceUrl('assets/svg/expand-less.svg')
    );

    _iconRegistry.addSvgIcon(
      'icon-expand-more',
      _sanitizer.bypassSecurityTrustResourceUrl('assets/svg/expand-more.svg')
    );

    _iconRegistry.addSvgIcon(
      'icon-add-photo',
      _sanitizer.bypassSecurityTrustResourceUrl('assets/svg/add-photo.svg')
    );

    _iconRegistry.addSvgIcon(
      'icon-search',
      _sanitizer.bypassSecurityTrustResourceUrl('assets/svg/baseline-search-24px.svg')
    );

    _iconRegistry.addSvgIcon(
      'icon-lock',
      _sanitizer.bypassSecurityTrustResourceUrl('assets/svg/baseline-lock.svg')
    );

    _iconRegistry.addSvgIcon(
      'favorite',
      _sanitizer.bypassSecurityTrustResourceUrl('assets/svg/baseline-favorite.svg')
    );

    _iconRegistry.addSvgIcon(
      'favorite_border',
      _sanitizer.bypassSecurityTrustResourceUrl('assets/svg/baseline-favorite-border.svg')
    );

    _iconRegistry.addSvgIcon(
      REFERENCE_STATUS.INCOMPLETE,
      _sanitizer.bypassSecurityTrustResourceUrl('assets/svg/unsent.svg')
    );

    _iconRegistry.addSvgIcon(
      REFERENCE_STATUS.COMPLETE,
      _sanitizer.bypassSecurityTrustResourceUrl('assets/svg/verified-icon.svg')
    );

    _iconRegistry.addSvgIcon(
      REFERENCE_STATUS.PENDING_SIGNATURE,
      _sanitizer.bypassSecurityTrustResourceUrl('assets/svg/pending.svg')
    );
    _iconRegistry.addSvgIcon(
      REFERENCE_STATUS.DECLINED,
      _sanitizer.bypassSecurityTrustResourceUrl('assets/svg/failed-icon.svg')
    );

    _iconRegistry.addSvgIcon(
      'file-icon',
      _sanitizer.bypassSecurityTrustResourceUrl('assets/svg/file-icon.svg')
    );

    _iconRegistry.addSvgIcon(
      'pending',
      _sanitizer.bypassSecurityTrustResourceUrl('assets/svg/pending.svg')
    );

    _iconRegistry.addSvgIcon(
      'icon-close',
      _sanitizer.bypassSecurityTrustResourceUrl('assets/svg/icon-close.svg')
    );

    _iconRegistry.addSvgIcon(
      'nav-icon-applications',
      _sanitizer.bypassSecurityTrustResourceUrl('assets/svg/lateral-navbar-icons/nav-icon-applications.svg')
    );
    _iconRegistry.addSvgIcon(
      'nav-icon-applications-active',
      _sanitizer.bypassSecurityTrustResourceUrl('assets/svg/lateral-navbar-icons/nav-icon-applications-active.svg')
    );

    _iconRegistry.addSvgIcon(
      'nav-icon-arrow-down',
      _sanitizer.bypassSecurityTrustResourceUrl('assets/svg/lateral-navbar-icons/nav-icon-arrow-down.svg')
    );

    _iconRegistry.addSvgIcon(
      'nav-icon-calendar',
      _sanitizer.bypassSecurityTrustResourceUrl('assets/svg/lateral-navbar-icons/nav-icon-calendar.svg')
    );
    _iconRegistry.addSvgIcon(
      'nav-icon-calendar-active',
      _sanitizer.bypassSecurityTrustResourceUrl('assets/svg/lateral-navbar-icons/nav-icon-calendar-active.svg')
    );

    _iconRegistry.addSvgIcon(
      'nav-icon-chat',
      _sanitizer.bypassSecurityTrustResourceUrl('assets/svg/lateral-navbar-icons/nav-icon-chat.svg')
    );
    _iconRegistry.addSvgIcon(
      'nav-icon-chat-active',
      _sanitizer.bypassSecurityTrustResourceUrl('assets/svg/lateral-navbar-icons/nav-icon-chat-active.svg')
    );

    _iconRegistry.addSvgIcon(
      'nav-icon-dash',
      _sanitizer.bypassSecurityTrustResourceUrl('assets/svg/lateral-navbar-icons/nav-icon-dash.svg')
    );
    _iconRegistry.addSvgIcon(
      'nav-icon-dash-active',
      _sanitizer.bypassSecurityTrustResourceUrl('assets/svg/lateral-navbar-icons/nav-icon-dash-active.svg')
    );

    _iconRegistry.addSvgIcon(
      'nav-icon-documents',
      _sanitizer.bypassSecurityTrustResourceUrl('assets/svg/lateral-navbar-icons/nav-icon-documents.svg')
    );
    _iconRegistry.addSvgIcon(
      'nav-icon-documents-active',
      _sanitizer.bypassSecurityTrustResourceUrl('assets/svg/lateral-navbar-icons/nav-icon-documents-active.svg')
    );

    _iconRegistry.addSvgIcon(
      'nav-icon-maintenance',
      _sanitizer.bypassSecurityTrustResourceUrl('assets/svg/lateral-navbar-icons/nav-icon-maintenance.svg')
    );
    _iconRegistry.addSvgIcon(
      'nav-icon-maintenance-active',
      _sanitizer.bypassSecurityTrustResourceUrl('assets/svg/lateral-navbar-icons/nav-icon-maintenance-active.svg')
    );
    _iconRegistry.addSvgIcon(
      'nav-icon-payments',
      _sanitizer.bypassSecurityTrustResourceUrl('assets/svg/lateral-navbar-icons/nav-icon-payments.svg')
    );
    _iconRegistry.addSvgIcon(
      'nav-icon-payments-active',
      _sanitizer.bypassSecurityTrustResourceUrl('assets/svg/lateral-navbar-icons/nav-icon-payments-active.svg')
    );

    _iconRegistry.addSvgIcon(
      'nav-icon-properties',
      _sanitizer.bypassSecurityTrustResourceUrl('assets/svg/lateral-navbar-icons/nav-icon-properties.svg')
    );
    _iconRegistry.addSvgIcon(
      'nav-icon-properties-active',
      _sanitizer.bypassSecurityTrustResourceUrl('assets/svg/lateral-navbar-icons/nav-icon-properties-active.svg')
    );

    _iconRegistry.addSvgIcon(
      'nav-icon-settings',
      _sanitizer.bypassSecurityTrustResourceUrl('assets/svg/lateral-navbar-icons/nav-icon-settings.svg')
    );
    _iconRegistry.addSvgIcon(
      'chevron-left',
      _sanitizer.bypassSecurityTrustResourceUrl('assets/svg/baseline-chevron-left.svg')
    );
    _iconRegistry.addSvgIcon(
      'chevron-right',
      _sanitizer.bypassSecurityTrustResourceUrl('assets/svg/baseline-chevron-right.svg')
    );
    _iconRegistry.addSvgIcon(
      'has-tenants',
      _sanitizer.bypassSecurityTrustResourceUrl('assets/svg/looking-for-tenants.svg')
    );
    _iconRegistry.addSvgIcon(
      'no-tenants',
      _sanitizer.bypassSecurityTrustResourceUrl('asstets/svg/have-tenant.svg')
    );
    _iconRegistry.addSvgIcon(
      'circle-minus-icon',
      _sanitizer.bypassSecurityTrustResourceUrl('assets/svg/minus-icon.svg')
    );
    _iconRegistry.addSvgIcon(
      'circle-add-icon',
      _sanitizer.bypassSecurityTrustResourceUrl('assets/svg/add-icon.svg')
    );
    _iconRegistry.addSvgIcon(
      'pdf-icon',
      _sanitizer.bypassSecurityTrustResourceUrl('assets/svg/pdf-icon.svg')
    );
    _iconRegistry.addSvgIcon(
      'nav-icon-viewings',
      _sanitizer.bypassSecurityTrustResourceUrl('assets/svg/lateral-navbar-icons/nav-icon-viewings.svg')
    );
    _iconRegistry.addSvgIcon(
      'nav-icon-viewings-active',
      _sanitizer.bypassSecurityTrustResourceUrl('assets/svg/lateral-navbar-icons/nav-icon-viewings-active.svg')
    );

    _iconRegistry.addSvgIcon(
      'nav-icon-settings-active',
      _sanitizer.bypassSecurityTrustResourceUrl('assets/svg/lateral-navbar-icons/nav-icon-settings-active.svg')
    );
    _iconRegistry.addSvgIcon(
      'nav-icon-search',
      _sanitizer.bypassSecurityTrustResourceUrl('assets/svg/sidebar-icons/nav-icon-search.svg')
    );
    _iconRegistry.addSvgIcon(
      'nav-icon-search-active',
      _sanitizer.bypassSecurityTrustResourceUrl('assets/svg/sidebar-icons/nav-icon-search-active.svg')
    );
    _iconRegistry.addSvgIcon(
      'nav-icon-saved',
      _sanitizer.bypassSecurityTrustResourceUrl('assets/svg/sidebar-icons/nav-icon-search.svg')
    );
    _iconRegistry.addSvgIcon(
      'nav-icon-saved-active',
      _sanitizer.bypassSecurityTrustResourceUrl('assets/svg/sidebar-icons/nav-icon-saved-active.svg')
    );
    _iconRegistry.addSvgIcon(
      'nav-icon-notices',
      _sanitizer.bypassSecurityTrustResourceUrl('assets/svg/sidebar-icons/nav-icon-notices.svg')
    );
    _iconRegistry.addSvgIcon(
      'nav-icon-notices-active',
      _sanitizer.bypassSecurityTrustResourceUrl('assets/svg/sidebar-icons/nav-icon-notices-active.svg')
    );
    _iconRegistry.addSvgIcon(
      'nav-icon-dash-new',
      _sanitizer.bypassSecurityTrustResourceUrl('assets/svg/sidebar-icons/nav-icon-dash-new.svg')
    );
    _iconRegistry.addSvgIcon(
      'nav-icon-dash-active-new',
      _sanitizer.bypassSecurityTrustResourceUrl('assets/svg/sidebar-icons/nav-icon-dash-active-new.svg')
    );
    _iconRegistry.addSvgIcon(
      'sidebar-icon-applications',
      _sanitizer.bypassSecurityTrustResourceUrl('assets/svg/sidebar-icons/nav-icon-applications.svg')
    );
    _iconRegistry.addSvgIcon(
      'sidebar-icon-applications-active',
      _sanitizer.bypassSecurityTrustResourceUrl('assets/svg/sidebar-icons/nav-icon-applications-active.svg')
    );
    _iconRegistry.addSvgIcon(
      'sidebar-icon-chat',
      _sanitizer.bypassSecurityTrustResourceUrl('assets/svg/sidebar-icons/nav-icon-chat.svg')
    );
    _iconRegistry.addSvgIcon(
      'sidebar-icon-chat-active',
      _sanitizer.bypassSecurityTrustResourceUrl('assets/svg/sidebar-icons/nav-icon-chat-active.svg')
    );
    _iconRegistry.addSvgIcon(
      'sidebar-icon-settings',
      _sanitizer.bypassSecurityTrustResourceUrl('assets/svg/sidebar-icons/nav-icon-settings.svg')
    );
    _iconRegistry.addSvgIcon(
      'sidebar-icon-settings-active',
      _sanitizer.bypassSecurityTrustResourceUrl('assets/svg/sidebar-icons/nav-icon-settings-active.svg')
    );
    _iconRegistry.addSvgIcon(
      'sidebar-icon-payments',
      _sanitizer.bypassSecurityTrustResourceUrl('assets/svg/sidebar-icons/nav-icon-payment.svg')
    );
    _iconRegistry.addSvgIcon(
      'sidebar-icon-payments-active',
      _sanitizer.bypassSecurityTrustResourceUrl('assets/svg/sidebar-icons/nav-icon-payment-active.svg')
    );
    _iconRegistry.addSvgIcon(
      'sidebar-icon-maintenance',
      _sanitizer.bypassSecurityTrustResourceUrl('assets/svg/sidebar-icons/nav-icon-maintenance.svg')
    );
    _iconRegistry.addSvgIcon(
      'sidebar-icon-maintenance-active',
      _sanitizer.bypassSecurityTrustResourceUrl('assets/svg/sidebar-icons/nav-icon-maintenance-active.svg')
    );
    _iconRegistry.addSvgIcon(
      'sidebar-icon-viewing-new',
      _sanitizer.bypassSecurityTrustResourceUrl('assets/svg/sidebar-icons/nav-icon-viewings-new.svg')
    );
    _iconRegistry.addSvgIcon(
      'sidebar-icon-viewing-active-new',
      _sanitizer.bypassSecurityTrustResourceUrl('assets/svg/sidebar-icons/nav-icon-viewings-active-new.svg')
    );
    _iconRegistry.addSvgIcon(
      'switch-living-searching',
      _sanitizer.bypassSecurityTrustResourceUrl('assets/svg/tenant-icons/switch_living_searching.svg')
    );
    _iconRegistry.addSvgIcon(
      'join-tenancy',
      _sanitizer.bypassSecurityTrustResourceUrl('assets/svg/tenant-icons/join_tenancy.svg')
    );
    _iconRegistry.addSvgIcon(
      'success-icon',
      _sanitizer.bypassSecurityTrustResourceUrl('assets/svg/tenant-icons/success_icon.svg')
    );

    _iconRegistry.addSvgIcon(
      'no-dashboard',
      _sanitizer.bypassSecurityTrustResourceUrl('assets/img/site/blockchain-house.svg')
    );
    _iconRegistry.addSvgIcon(
      'delete',
      _sanitizer.bypassSecurityTrustResourceUrl('assets/svg/delete-bin.svg')
    );
    _iconRegistry.addSvgIcon(
      'check-circle',
      _sanitizer.bypassSecurityTrustResourceUrl('assets/svg/check-circle.svg')
    );
    _iconRegistry.addSvgIcon(
      'check',
      _sanitizer.bypassSecurityTrustResourceUrl('assets/svg/check.svg')
    );
    _iconRegistry.addSvgIcon(
      'add-circle',
      _sanitizer.bypassSecurityTrustResourceUrl('assets/svg/add-circle-icon.svg')
    );
    _iconRegistry.addSvgIcon(
      'bunk-logo-white',
      _sanitizer.bypassSecurityTrustResourceUrl('assets/svg/logos/bunk-logo-icon-white.svg')
    );
    _iconRegistry.addSvgIcon(
      'bunk-verified-icon',
      _sanitizer.bypassSecurityTrustResourceUrl('assets/svg/logos/bunk-verified-icon.svg')
    );
    _iconRegistry.addSvgIcon(
      'icon-lock',
      _sanitizer.bypassSecurityTrustResourceUrl('assets/svg/baseline-lock-24px.svg')
    );
    _iconRegistry.addSvgIcon(
      'edit-pencil',
      _sanitizer.bypassSecurityTrustResourceUrl('assets/svg/edit-pencil.svg')
    );
    _iconRegistry.addSvgIcon(
      'call-icon',
      _sanitizer.bypassSecurityTrustResourceUrl('assets/svg/call-icon.svg')
    );
    _iconRegistry.addSvgIcon(
      'email-icon',
      _sanitizer.bypassSecurityTrustResourceUrl('assets/svg/email-icon.svg')
    );
    _iconRegistry.addSvgIcon(
      'location-icon',
      _sanitizer.bypassSecurityTrustResourceUrl('assets/svg/location.svg')
    );
    _iconRegistry.addSvgIcon(
      'clock-outline-icon',
      _sanitizer.bypassSecurityTrustResourceUrl('assets/svg/clock-outline-icon.svg')
    );
    _iconRegistry.addSvgIcon(
      'camera-icon',
      _sanitizer.bypassSecurityTrustResourceUrl('assets/svg/camera-icon.svg')
    );
    _iconRegistry.addSvgIcon(
      'floorplan-icon',
      _sanitizer.bypassSecurityTrustResourceUrl('assets/svg/floorplan-icon.svg')
    );
    _iconRegistry.addSvgIcon(
      'preview-icon',
      _sanitizer.bypassSecurityTrustResourceUrl('assets/svg/preview-icon.svg')
    );
    _iconRegistry.addSvgIcon(
      'document-icon',
      _sanitizer.bypassSecurityTrustResourceUrl('assets/svg/icons/document.svg')
    );
    _iconRegistry.addSvgIcon(
      'search-icon',
      _sanitizer.bypassSecurityTrustResourceUrl('assets/svg/icons/search.svg')
    );
    _iconRegistry.addSvgIcon(
      'pdf-icon',
      _sanitizer.bypassSecurityTrustResourceUrl('assets/svg/icons/pdf.svg')
    );
    _iconRegistry.addSvgIcon(
      'bin-icon',
      _sanitizer.bypassSecurityTrustResourceUrl('assets/svg/icons/bin.svg')
    );
    _iconRegistry.addSvgIcon(
      'house-icon',
      _sanitizer.bypassSecurityTrustResourceUrl('assets/svg/icons/house.svg')
    );
    _iconRegistry.addSvgIcon(
      'lock-icon',
      _sanitizer.bypassSecurityTrustResourceUrl('assets/svg/icons/lock.svg')
    );
    _iconRegistry.addSvgIcon(
      'attachment-icon',
      _sanitizer.bypassSecurityTrustResourceUrl('assets/svg/icons/attachment.svg')
    );
    _iconRegistry.addSvgIcon(
      'bunk-icon-notices',
      _sanitizer.bypassSecurityTrustResourceUrl('assets/svg/navigation/bunk-icon-notices.svg')
    );
    _iconRegistry.addSvgIcon(
      'bunk-icon-bank-feed',
      _sanitizer.bypassSecurityTrustResourceUrl('assets/svg/navigation/bunk-icon-bank-feed.svg')
    );
    _iconRegistry.addSvgIcon(
      'bunk-icon-tenants',
      _sanitizer.bypassSecurityTrustResourceUrl('assets/svg/navigation/bunk-icon-tenants.svg')
    );
    _iconRegistry.addSvgIcon(
      'bunk-icon-tenancies',
      _sanitizer.bypassSecurityTrustResourceUrl('assets/svg/navigation/bunk-icon-tenancies.svg')
    );
    _iconRegistry.addSvgIcon(
      'down-arrow',
      _sanitizer.bypassSecurityTrustResourceUrl('assets/svg/icons/down-arrow.svg')
    );
    _iconRegistry.addSvgIcon(
      'left-arrow',
      _sanitizer.bypassSecurityTrustResourceUrl('assets/svg/icons/left-long-arrow.svg')
    );
    _iconRegistry.addSvgIcon(
      'right-arrow',
      _sanitizer.bypassSecurityTrustResourceUrl('assets/svg/icons/right-long-arrow.svg.svg')
    );
    _iconRegistry.addSvgIcon(
      'up-arrow',
      _sanitizer.bypassSecurityTrustResourceUrl('assets/svg/icons/arrow-up.svg')
    );
    _iconRegistry.addSvgIcon(
      'individual',
      _sanitizer.bypassSecurityTrustResourceUrl('assets/svg/icons/individual.svg')
    );
    _iconRegistry.addSvgIcon(
      'person',
      _sanitizer.bypassSecurityTrustResourceUrl('assets/svg/icons/person.svg')
    );
    _iconRegistry.addSvgIcon(
      'group',
      _sanitizer.bypassSecurityTrustResourceUrl('assets/svg/icons/group.svg')
    );
    _iconRegistry.addSvgIcon(
      'notes',
      _sanitizer.bypassSecurityTrustResourceUrl('assets/svg/icons/notes.svg')
    );
    _iconRegistry.addSvgIcon(
      'open-tab',
      _sanitizer.bypassSecurityTrustResourceUrl('assets/svg/icons/open-tab.svg')
    );
    _iconRegistry.addSvgIcon(
      'bunk-icon-tasks',
      _sanitizer.bypassSecurityTrustResourceUrl('assets/svg/navigation/bunk-icon-tasks.svg')
    );
    _iconRegistry.addSvgIcon(
      'bunk-edit',
      _sanitizer.bypassSecurityTrustResourceUrl('assets/svg/bunk-edit.svg')
    );
    _iconRegistry.addSvgIcon(
      'bed-icon',
      _sanitizer.bypassSecurityTrustResourceUrl('assets/svg/icons/bed.svg')
    );
    _iconRegistry.addSvgIcon(
      'virtual',
      _sanitizer.bypassSecurityTrustResourceUrl('assets/svg/virtual.svg')
    );
    _iconRegistry.addSvgIcon(
      'in-person',
      _sanitizer.bypassSecurityTrustResourceUrl('assets/svg/in-person.svg')
    );
    _iconRegistry.addSvgIcon(
      'pointed-arrow-up',
      _sanitizer.bypassSecurityTrustResourceUrl('assets/svg/icons/pointed-arrow-up.svg')
    );
    _iconRegistry.addSvgIcon(
      'star-icon',
      _sanitizer.bypassSecurityTrustResourceUrl('assets/svg/icons/star.svg')
    );
    _iconRegistry.addSvgIcon(
      'bunk-icon-calendar',
      _sanitizer.bypassSecurityTrustResourceUrl('assets/svg/navigation/bunk-icon-calendar.svg')
    );
    _iconRegistry.addSvgIcon(
      'link',
      _sanitizer.bypassSecurityTrustResourceUrl('assets/svg/icons/link.svg')
    );
    _iconRegistry.addSvgIcon(
      'document-file',
      _sanitizer.bypassSecurityTrustResourceUrl('assets/svg/icons/document-file.svg')
    );
    _iconRegistry.addSvgIcon(
      'duplicate',
      _sanitizer.bypassSecurityTrustResourceUrl('assets/svg/duplicate.svg')
    );
    _iconRegistry.addSvgIcon(
      'close-minimal',
      _sanitizer.bypassSecurityTrustResourceUrl('assets/svg/close-minimal.svg')
    );
    _iconRegistry.addSvgIcon(
      'info',
      _sanitizer.bypassSecurityTrustResourceUrl('assets/svg/info.svg')
    );
    _iconRegistry.addSvgIcon(
      'phone-call',
      _sanitizer.bypassSecurityTrustResourceUrl('assets/svg/phone-call.svg')
    );
    _iconRegistry.addSvgIcon(
      'mail',
      _sanitizer.bypassSecurityTrustResourceUrl('assets/svg/mail.svg')
    );
    _iconRegistry.addSvgIcon(
      'copy',
      _sanitizer.bypassSecurityTrustResourceUrl('/assets/svg/icons/copy.svg')
    );
    _iconRegistry.addSvgIcon(
      'send-message',
      _sanitizer.bypassSecurityTrustResourceUrl('/assets/svg/send-message.svg')
    );
    _iconRegistry.addSvgIcon(
      'delete-bin',
      _sanitizer.bypassSecurityTrustResourceUrl('/assets/svg/icons/delete.svg')
    )
    _iconRegistry.addSvgIcon(
      'add-property',
      _sanitizer.bypassSecurityTrustResourceUrl('/assets/svg/icons/add-property.svg')
    );
    _iconRegistry.addSvgIcon(
      'add-tenant',
      _sanitizer.bypassSecurityTrustResourceUrl('/assets/svg/icons/add-tenant.svg')
    );
    _iconRegistry.addSvgIcon(
      'add-documents',
      _sanitizer.bypassSecurityTrustResourceUrl('/assets/svg/icons/add-documents.svg')
    );
    _iconRegistry.addSvgIcon(
      'add-tasks',
      _sanitizer.bypassSecurityTrustResourceUrl('/assets/svg/icons/add-tasks.svg')
    );
    _iconRegistry.addSvgIcon(
      'add-viewings',
      _sanitizer.bypassSecurityTrustResourceUrl('/assets/svg/icons/add-viewings.svg')
    );
    _iconRegistry.addSvgIcon(
      'play',
      _sanitizer.bypassSecurityTrustResourceUrl('/assets/svg/icons/play.svg')
    );
    _iconRegistry.addSvgIcon(
    'warning',
    _sanitizer.bypassSecurityTrustResourceUrl('/assets/svg/warning.svg')
    );
  }
}

import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'card-list-skeleton',
  template: `
    <ul class="flex flex-wrap flex-row">
    <li class="card__list loading-animation--header" *ngFor="let i of [0, 1, 2, 3, 4, 5, 6, 7, 8, 9]">

      <div class="flex flex-column">
        <div class="card_image--top loading-animation"></div>
        <div><hr></div>
          <div class="margin-top--sm title loading-animation"></div>
          <div class="margin-top--lg middle loading-animation"></div>
            <div class="margin-top--lg middle loading-animation"></div>
            <div class="margin-top--lg middle loading-animation"></div>

        <div class="margin-top--xs button loading-animation"></div><!--        <div class="flex flex-column buttons">-->

      </div>
    </li>
  </ul>  `,
  styleUrls: ['../skeleton-loading.component.scss']

})

export class CardListSkeletonComponent implements OnInit {
  constructor() {
  }

  ngOnInit() {
  }
}

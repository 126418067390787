import { TitleCasePipe } from "@angular/common";
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { IPropertyAddress, PropertyService, StorageService, UserService } from "@app/services";
import { CustomValidators } from "@app/shared/_validators/custom.validators";
import { ToastrService } from "ngx-toastr";
import { Observable, of, Subject } from "rxjs";
import { last, switchMap, takeUntil, tap } from "rxjs/operators";
import { environment } from "@env/environment";
import { AuthService } from "@app/core/services";
import { CompanyName } from "@app/shared/shared.constants";
import { EEnvironmentFlags } from "@rentbunk/bunk-models";

export const DOCUMENT_TYPES_LIST = [
  {value: 'title_deed', viewValue: 'Title Deed'},
  {value: 'mortgage_agreement', viewValue: 'Mortgage Agreement'},
  {value: 'certificate_of_insurance', viewValue: 'Certificate of insurance'},
  {value: 'signed_solicitors_letter', viewValue: 'Signed solicitors letter'}
];

@Component({
  selector: 'create-simple-property',
  templateUrl: './create-simple-property.component.html',
  styleUrls: ['./create-simple-property.component.scss'],
})
export class CreatePropertyComponent implements OnInit, OnDestroy {
  public property$: Observable<any>;
  public team$: Observable<any>;
  public formGroup: FormGroup;
  public propertyId: string;
  public editId: string;
  public showAddress = false;
  public isLoading = false;
  public teamIds: any[];
  public client_data = environment.client_data;
  public isPropertyForm = false;
  public documentTypesList = DOCUMENT_TYPES_LIST;
  isClientNRLA = CompanyName.isNrla;

  public submitAttempted = false;
  overRef;
  @ViewChild('propertyPhotosUpload', { static: false }) propertyPhotosUploadRef: any;
  // helpers
  private destroy$: Subject<boolean> = new Subject<boolean>();
  @Output() createdPropertyId: EventEmitter<any> = new EventEmitter<any>(null);
  @Output() propertyCreated: EventEmitter<any> = new EventEmitter<any>(null);
  @Input() dref;
  @Input() isHmo;

  public propertyTypeList = [
    { text: "Detached", form: "detached" },
    { text: "Semi-detached", form: "semi-detached" },
    { text: "Terraced", form: "terraced" },
    { text: "Flat", form: "flat" },
    { text: "Bungalow", form: "bungalow" },
    { text: "Maisonette", form: "maisonette" },
    { text: "Studio", form: "studio" },
    { text: "Apartment", form: "apartment" },
  ];
  private requiredFields = ['has_accepted_listing_terms',
  'does_landlord_own_property', 'property_listed_as',
  'property_type', 'number_bathrooms',
  'reception_rooms', 'is_house_share', 'has_live_in_landlord',
  'heating_type', 'utility_type']

  public isRentSmartRequired = environment.client_data.flags && environment.client_data.flags.includes(EEnvironmentFlags.RENT_SMART_NO_REQUIRED);

  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _titleCasePipe: TitleCasePipe,
    private _formBuilder: FormBuilder,
    private _property: PropertyService,
    private _user: UserService,
    private _storage: StorageService,
    private _auth: AuthService,
    private _toastr: ToastrService
  ) {
    this.propertyId = this._route.snapshot.paramMap.get("id");
    this.editId = this._route.snapshot.paramMap.get("editId");
  }

  get address() {
    return this.formGroup.get("address");
  }
  get team_id() {
    return this.formGroup.get("team_id");
  }
  get has_accepted_listing_terms() {
    return this.formGroup.get("has_accepted_listing_terms");
  }
  get ownership_document_full_path(){
    return this.formGroup.get('ownership_document_full_path');
  }
  get heating_type() {
    return this.formGroup.get("heating_type");
  }
  get utility_type() {
    return this.formGroup.get("utility_type");
  }
  get does_landlord_own_property(){
    return this.formGroup.get("does_landlord_own_property");
  }
  get property_listed_as(){
    return this.formGroup.get("property_listed_as");
  }
  get is_house_share(){
    return this.formGroup.get("is_house_share")
  }
  get has_live_in_landlord(){
    return this.formGroup.get("has_live_in_landlord")
  }
  get ownership_document_type(){
    return this.formGroup.get("ownership_document_type")
  }
  get is_hmo() {
    return this.formGroup.controls.is_hmo;
  }
  get number_bedrooms() {
    return this.formGroup.controls.number_bedrooms
  }
  ngOnInit(): void {
    this.formGroup = this.initFormGroup();
    if (this.isRentSmartRequired) {
      this.address.get('country').valueChanges.pipe(takeUntil(this.destroy$)).subscribe(country => {
        if (country === 'Wales') {
          this.address.get('rent_smart_no').setValidators(Validators.required);
        } else {
          this.address.get('rent_smart_no').setValidators(null);
        }
        this.address.get('rent_smart_no').updateValueAndValidity()
      });
      if (this._user.userDb.rent_smart_no) {
        this.address.patchValue({
          rent_smart_no: this._user.userDb.rent_smart_no, // Rent Smart Wales License Number
        });
      }
    }
    this.is_hmo.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(isHmo => {
      if (isHmo) {
        this.number_bedrooms.setValidators(Validators.required);
        this.number_bedrooms.updateValueAndValidity()
      }
      else {
        this.number_bedrooms.setValidators(null);
        this.number_bedrooms.updateValueAndValidity()
      }
    })
  }

  initFormGroup(): FormGroup {
    return this._formBuilder.group({
      address: this._formBuilder.group({
        first_line_address: [null, Validators.required],
        second_line_address: [null],
        third_line_address: [null],
        city: [null, Validators.required],
        post_code: [
          null,
          [Validators.required, CustomValidators.ValidatePostCodeUK],
        ],
        county: [null, Validators.required],
        country: [null, Validators.required],
        rent_smart_no: [null],
        lat: [null],
        lng: [null],
        thoroughfare: [null],
        dependant_locality: [null],
      }),
      property_ref: [Validators.required],
      team_id: [null],
      is_hmo: [this.isHmo !== undefined ? this.isHmo : null, Validators.required],
      listed_as_hmo: [null, this.isHmo === false ? [Validators.required] : []],
      has_accepted_listing_terms: [null],
      does_landlord_own_property: [true],
      property_listed_as: ['individual'],
      property_type: [null],
      number_bedrooms: [null],
      number_bathrooms: [null],
      reception_rooms: [null],
      is_house_share: [!!this.isHmo],
      has_live_in_landlord: [false],
      heating_type: [[]],
      utility_type:[[]],
      property_photos: this._formBuilder.array([], null),
      ownership_document_type:[null],
      ownership_document_full_path: [null],
    });
  }


  autocompleteAddress(address: any): void {
    this.showAddress = true;
    const item: any = {
      address: new IPropertyAddress(
        address.line_1,
        address.line_2,
        address.line_3,
        this._titleCasePipe.transform(address.post_town),
        address.postcode,
        address.postcode_outward,
        address.county,
        address.country,
        address.latitude,
        address.longitude,
        address.thoroughfare,
        address.dependant_locality
      ),
    };
    const property_ref = this._property.createPropertyRef(item.address);
    this.formGroup.get('property_ref').patchValue(property_ref);
    this.formGroup.patchValue(item);
  }

  createProperty() {
    this.submitAttempted = true;
    if (this.formGroup.valid) {
      this.isLoading = true;
      if(this.formGroup.controls.team_id.value){
        this.formGroup.controls.team_id.setValue(this.formGroup.controls.team_id.value.team_id);
      }

      this._property
        .initCreateSimpleProperty(this.formGroup.value)
        .pipe(
          takeUntil(this.destroy$),
          tap(propertyId => {
            this.propertyId = propertyId || '';
            this.property$ = this._property.getPropertyById(propertyId).valueChanges();
            this.isLoading = false;
            this.isPropertyForm = true;
            this.setValidatorsRequired(this.requiredFields)
            if (this.isHmo) {
              this.setValidatorsRequired(['number_bedrooms']);
            }
          }),
          switchMap(() => (this.isRentSmartRequired && this.formGroup.value.address.country === 'Wales') ? this._user.updateUserRentSmartNumber(this.formGroup.value.address.rent_smart_no) : of(null)),
        )
        .subscribe();
    } else {
      if (this.is_hmo.value) {
        this.number_bedrooms.markAllAsTouched();
      }
      this._toastr.error(`Please review incomplete fields`);
    }
  }

  dirtyFormGroup(){
    this.formGroup.markAllAsTouched();
  }

  setValidatorsRequired(controlNames:Array<any>)
  {
    controlNames.forEach(controlName =>{
      const formControl = this.formGroup.get(controlName);
      formControl.setValidators([Validators.required]);
      formControl.updateValueAndValidity();
    })

  }

  submit(){
    if(this.formGroup.invalid){
      this.dirtyFormGroup();
      this._toastr.error(`Please review incomplete fields`);
      return;
    }
    this.isLoading = true;
    this._property.updateProperty(this.propertyId, this.formGroup.value, false)
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        this.isLoading = false;
        this._toastr.success(`Property Created`);
        this.createdPropertyId.emit(this.propertyId)
        this.dref.close();
        this.propertyCreated.emit(true);
        },
        err => console.log('An error has occurred: ', err));
  }

  checkBoxSelect(formControlName:string, value:string){
    const formControl = this.formGroup.get(formControlName);
    const controlValues = formControl.value;
    if(controlValues){

      if(controlValues.includes(value)){
        formControl.patchValue(controlValues.filter(controlValue => controlValue !== value))
      }
      else{
        formControl.patchValue([...controlValues,value])
      }

    }

  }

  uploadDocument(event: any): void {
    this._storage
      .uploadFile(event, `user/${this._auth.currentUserId}/property/${this.propertyId}`)
      .pipe(last())
      .subscribe(metadata => this.ownership_document_full_path.patchValue(metadata.ref.fullPath));
  }

  beautifyPath = (path: string): string => {
    const arr = path.split('/');
    return arr[arr.length - 1];
  };

  removeDoc(){
  this.ownership_document_full_path.patchValue(null)
  }

  limitDigits(controlName:string,value:string, min:number = 0, max:number = 100){
    if (isNaN(parseInt(value))) { return }
    if(parseInt(value) < min)
    {
      this.formGroup.get(controlName).patchValue(min)
    }
    else if(parseInt(value) > max){
      const slicedValue = value.slice(0,(value.length - 1))
      const newValue = parseInt(slicedValue) < max ? parseInt(slicedValue) : max
      this.formGroup.get(controlName).patchValue(newValue)
    }
    else{
      this.formGroup.get(controlName).patchValue(parseInt(value))
    }
  }
  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}

import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import { Injectable } from '@angular/core';
import { Shared } from '@env/routing';
import {map} from 'rxjs/operators';
import {UserService} from '@app/services';



@Injectable()
export class NoUserDocsGuard implements CanActivate {

  constructor(private router: Router,
              private _user: UserService) {}

  canActivate(route: ActivatedRouteSnapshot,
              state: RouterStateSnapshot) {
    return this._user.user$
      .pipe(
        map(user => {
          if (user) {
              return true;
          } else {
            console.log('NOOO USER DOCSSS');
             this.router.navigate(['/', + Shared.no_user]);
            return false;
          }
        })
      );
  }
}


  <form [formGroup]="formGroup" [hidden]="showCardForm" class="margin-top--md">

  <div class="cc">
  <date-selector inputLabel="Date of birth" yearStart="1901" [yearEnd]="minAgeYear" formControlName="dob"
    [parentForm]="formGroup" [parentFormInvalid]="formGroup.invalid">
  </date-selector>  
  </div>

    <mat-form-field appearance="outline" class="w_100">
      <mat-label>Address line one</mat-label>
      <input matInput type="text" formControlName="first_line_address">
      <mat-error>Please enter your address line one</mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline" class="w_100">
      <mat-label>Address line two </mat-label>
      <input matInput type="text" formControlName="second_line_address">

    </mat-form-field>

    <div class="rc">
    <mat-form-field appearance="outline" class="w_50 mr_5">
      <mat-label>Address city</mat-label>
      <input matInput type="text" formControlName="city">
      <mat-error>Please enter your city</mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline" class="w_50">
      <mat-label>Address postcode</mat-label>
      <input matInput type="text" formControlName="post_code">
      <mat-error>Please enter your postcode</mat-error>
    </mat-form-field>
    </div>

    <div class="rc jc_sb">
     <button class="btn__warning" type="button"  (click)="cancel()"> Back </button> 
      <button class="btn__seethrough" type="submit" (click)="submit(formGroup.value)"> Next </button> 
    </div>

    </form>

    <cards-form
      class="margin-top--lg"
      *ngIf="showCardForm"
      (setupComplete)="addCard($event)"
      [disabled]="disableCardsForm"
      [clear]="clearCardsForm"
    ></cards-form> 
    <button class="btn__warning margin-top--lg" type="button" *ngIf="showCardForm" (click)="cancel()"> Back </button>

   
  
import { Component, Input, Optional, forwardRef } from '@angular/core';
import { FormGroupDirective, AbstractControl, NG_VALUE_ACCESSOR, NG_VALIDATORS, FormControl } from '@angular/forms';
import { AbstractValueAccessor } from './instant-search.utils';
import { debounce } from 'rxjs/operators';
import { timer } from 'rxjs';

type autocompleteVal = 'off' | 'on';

@Component({
  selector: 'instant-search-input',
  templateUrl: './instant-search-input.component.html',
  styleUrls: ['./instant-search-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InstantSearchInputComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => InstantSearchInputComponent),
      multi: true
    }
  ]
})
export class InstantSearchInputComponent extends AbstractValueAccessor {
  @Input() indexName: string;     // algolia table name
  @Input() autocomplete: autocompleteVal;
  @Input() placeholder = 'Search...';
  @Input() disabledMessage = '';
  public searchInput: FormControl = new FormControl('');

  constructor(
    @Optional() private reactiveForm: FormGroupDirective
  ) {
    super();
  }

  public onTouched: () => void = () => { };

  writeValue(searchInput: string): void {
    super.writeValue(searchInput);
    this.searchInput.setValue(searchInput, { emitEvent: false });
  }
  registerOnChange(fn: any): void {
    this.searchInput.valueChanges.pipe(
      debounce(() => timer(200))
    ).subscribe(fn);
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.searchInput.disable() : this.searchInput.enable();
  }

  validate(control: AbstractControl) {
    return this.searchInput.valid ? null : { invalidForm: { valid: false, message: 'InstantSearchInputComponent > formGroup fields are invalid' } };
  }

  clearSearch() {
    this.searchInput.setValue('');
  }
}

import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { UserService } from '@app/services';
import { map } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { Tenant } from '@env/routing';
import { AuthService } from '@app/core/services';
import {environment} from '@env/environment';

@Component({
  selector: 'property-owner-card',
  templateUrl: './property-owner-card.component.html',
  styleUrls: ['./property-owner-card.component.scss']
})
export class PropertyOwnerCardComponent implements OnInit {
  @Input() landlordId: string;
  @Input() property?: any;
  @Input() showManager = false;
  @Input() showLogo = false;
  @Input() dashboard = false;
  @Input() bio: boolean;
  @Input() search = false;
  @Output() closeOverlay: EventEmitter<any> = new EventEmitter();
  public landlordPublic$: Observable<any>;
  public showSignUpForm = false;
  public dref: any;
  public client_data = environment.client_data;
  public environment = environment;
  public isCampusKeyTenant = environment.firebaseConfig.projectId === 'client-campus-key' && this._auth.authenticated && this._user.userDb &&  this._user.userDb.role === 'tenant';

  constructor(
    private _activatedRoute: ActivatedRoute,
    public _router: Router,
    public _user: UserService,
    public _auth: AuthService
  ) { }

  ngOnInit() {
    this.landlordPublic$ = this.property && this.property.team_id ? this._user.getTeamPublicById(this.property.team_id).pipe(
      map(team => {
        let manager = null;
        if(team && team.team_data){
          const image_small_url = team.team_data.brand_assets && team.team_data.brand_assets.banner_aside && team.team_data.brand_assets.banner_aside.image_small_url
          const image_large_url = team.team_data.brand_assets && team.team_data.brand_assets.banner_aside && team.team_data.brand_assets.banner_aside.image_large_url

            manager ={
              companyName: team.team_data.company_name,
              companyNameShort: team.team_data.company_name_short,
              contactNumber: team.team_data.contact_number,
              contactEmail: team.team_data.contact_email,
              bannerAsideSm: image_small_url,
              bannerAsideLg: image_large_url,
              firstName: team.team_data.company_name,
              bio: team.team_data.bio
            };
          } else {
            return null;
          }
          return {
            firstName: team.team_data.company_name,
            companyName: team.team_data.company_name,
            profileImgUrl: team.team_data.company_logo,
            isTeamData: true,
            uid: this.property.landlord_uid ? this.property.landlord_uid : null,
            manager
          };

      })
    ) :
    this._user.getUserById(this.landlordId).valueChanges().pipe(
      map(landlordPublic => {
        let manager = null;
        if (landlordPublic.property_manager_data) {
          const { image_small_url , image_large_url } = landlordPublic.property_manager_data.brand_assets.banner_aside;
          manager = {
            companyName: landlordPublic.property_manager_data.company_name,
            companyNameShort: landlordPublic.property_manager_data.company_name_short,
            contactNumber: landlordPublic.property_manager_data.contact_number,
            bio: landlordPublic.property_manager_data.bio,
            bannerAsideSm: image_small_url,
            bannerAsideLg: image_large_url
          };
        }
        return {
          firstName: landlordPublic.profile_data.first_name,
          profileImgUrl: landlordPublic.profile_data.profile_image_url,
          uid: landlordPublic.uid,
          isTeamData: false,
          manager
        };
      })
    );
  }

  viewAllPropsByManager() {
    this.closeOverlay.emit(true);
    this._router.navigate([Tenant.rent_property], { queryParams: { location: 'all', manager: this.property.manager_uid } });
  }

  handleAnonymousMessage(): void {
    this.showSignUpForm = false;
    this._router.navigate( ['.'], { queryParams: { property_id: this.property.property_id } , relativeTo: this._activatedRoute, replaceUrl: true });
  }
}

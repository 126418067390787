import { Directive, OnInit, OnDestroy, Output, EventEmitter, ElementRef, Input } from '@angular/core';
import { Subscription, fromEvent, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

@Directive({
  exportAs: 'clickOutside',
  selector: 'clickOutside, [clickOutside], [click-outside]'
})
export class ClickOutsideDirective implements OnInit, OnDestroy {
  @Input('listen')
  set listen(listen: boolean) {
    this._listen = listen;
    this.check();
  }
  @Input() deactivate: any[] = [];
  
  private _listen = true;
  private globalClick$: Observable<any>;
  private globalEscape$: Observable<any>;
  private globalClickSub: Subscription;
  private globalEscapeSub: Subscription;
  
  @Output() clickOutside: EventEmitter<Object>;

  constructor(
    private _elRef: ElementRef
  ) {
    this.clickOutside = new EventEmitter();
    this.globalClick$ = fromEvent(document, 'click')
      .pipe(
        filter(({ target }: any) => {
          const materialElementsToCheck = ['mat-option-text', 'cdk-overlay-backdrop']; // Remove when Angular Material is not used
          const targetContains = !this._elRef.nativeElement.contains(target);
          const hasMaterialElements = target.className.length ? materialElementsToCheck.some((el: string) => target.className.includes(el)) : false;
          const value = hasMaterialElements ? false : targetContains;
          this.clickOutside.emit({
            ...target && { target },
            value
          });
          return value;
        })
      );
    // For accessibility...
    this.globalEscape$ = fromEvent(document, 'keydown')
      .pipe(
        filter(({ keyCode, target }: any) => {
          const value = keyCode === 27;
          this.clickOutside.emit({
            ...target && { target },
            value
          });
          return value;
        })
      );
  }

  ngOnInit() {
    this.check();
  }

  check() {
    if (this._listen) {
      this.globalClickSub = this.globalClick$.subscribe();
      this.globalEscapeSub = this.globalEscape$.subscribe();

      this.deactivate.forEach((element: HTMLElement) => {
        element.style.pointerEvents = 'none';
      });
    } else if (this.globalClickSub) {
      this.globalClickSub.unsubscribe();
      this.globalEscapeSub.unsubscribe();
      this.deactivate.forEach((element: HTMLElement) => {
        element.style.pointerEvents = 'auto';
      });
    } else {
      this.deactivate.forEach((element: HTMLElement) => {
        element.style.pointerEvents = 'auto';
      });
    }
  }

  ngOnDestroy() {
    if (this.globalClickSub) {
      this.globalClickSub.unsubscribe();
      this.globalEscapeSub.unsubscribe();
      this.deactivate.forEach((element: HTMLElement) => {
        element.style.pointerEvents = 'auto';
      });
    }
  }
}


import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {TenancyService} from '@app/services/tenancy.service';
import {ToastrService} from 'ngx-toastr';
import {finalize} from 'rxjs/operators';
import { Observable } from 'rxjs';
import { TenancyModel } from '@app/_models/tenancy.model';
import { UserService } from '@app/services';
import {environment} from '@env/environment';
import { TRANSLATIONS } from '@app/_constants/translations.constants';

@Component({
  selector: 'tenant-add-tenancy-card',
  template: `

  <div class="bunk-cards_container bunk-cards_container--invite  p-all_8 m-all_4 cc ai_c jc_sb">
    <mat-icon svgIcon="join-tenancy" class="join-icon"></mat-icon>
    <div class=" m-horizontal_10 cc">
      <h3>Has your {{landlord}} invited you to Bunk?</h3><p>Enter your unique code here from the email we sent you.</p>
      <a href="mailto:{{client_data.support_email}}" target="_blank">Haven't received a code?</a>
    </div>

    <bunk-dialog [component]="addTenancy"  (dialogRef)="dref = $event">
    <button class="btn--round btn__seethrough btn__seethrough--primary mt_5" type="button" >Add code</button>
    </bunk-dialog>

  </div>

  <ng-template #addTenancy>
    <tenant-add-tenancy (success)="onAddTenancy($event)" [dref]="dref"></tenant-add-tenancy>
  </ng-template>

  `,
  styleUrls: ['./tenant-add-tenancy.component.scss']
})
export class TenantAddTenancyCardComponent {
  public token: string;
  public loading = false;
  public tenancy$: Observable<TenancyModel[]>;
  public dref: any;
  public client_data = environment.client_data;
  public landlord = TRANSLATIONS.landlord;


  @Output()
  success = new EventEmitter<false | string>();

  constructor(private _tenancy: TenancyService,
              private _toastr: ToastrService,
              public _user: UserService) {

    if (this._tenancy.getIdCurrentTenancy()) {
      this.tenancy$ = this._tenancy.getUserTenancyById(this._tenancy.getIdCurrentTenancy()).valueChanges();
    }
  }


  onAddTenancy(tenancy_code: string) {
    if (tenancy_code) {
      this.tenancy$ = this._tenancy.getUserTenancyById(tenancy_code).valueChanges();
    }
  }


}

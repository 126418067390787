<img class="inst-search__input-icon" src='/assets/svg/baseline-search-24px.svg' alt="Search...">
<button *ngIf="searchInput.value.length > 1" class="inst-search__input-icon-close" (click)="clearSearch()">
  <img src='/assets/svg/baseline-close-24px.svg' alt="Close list" (click)="hideResults = true">
</button>
<input
  name="search-input"
  [formControl]="searchInput"
  [placeholder]="searchInput.enabled ? placeholder : disabledMessage"
  [autocomplete]="autocomplete"
  class="inst-search__input">

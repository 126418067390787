import * as tslib_1 from "tslib";
import { EventEmitter, OnInit } from '@angular/core';
import { Validators, FormBuilder } from '@angular/forms';
import { environment } from '@env/environment';
import { UserService } from '@app/services';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
export class BankAccountFormComponent {
    constructor(_user, _toastr, _formBuilder) {
        this._user = _user;
        this._toastr = _toastr;
        this._formBuilder = _formBuilder;
        this.emitter = new EventEmitter();
        this.stripe = Stripe(environment.stripeConfig.publicKey);
        this.isDefault = false;
        this.processingIntent = false;
        this.intentProcessed = false;
        this.awaitingCompletion = false;
        this.bankAccountForm = this.initFormGroup();
    }
    get account_number() {
        return this.bankAccountForm.get('account_number');
    }
    get sort_code() {
        return this.bankAccountForm.get('sort_code');
    }
    get account_holder_name() {
        return this.bankAccountForm.get('account_holder_name');
    }
    get default_for_currency() {
        return this.bankAccountForm.get('default_for_currency');
    }
    get business_type() {
        return this.bankAccountForm.get('business_type');
    }
    initFormGroup() {
        return this._formBuilder.group({
            account_number: [null, [Validators.required, Validators.maxLength(12)]],
            sort_code: [null, [Validators.required, Validators.maxLength(6), Validators.minLength(6)]],
            account_holder_name: ['', [Validators.required]],
            default_for_currency: [false],
            business_type: ['individual']
        });
    }
    submit(form) {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            if (this.bankAccountForm.valid) {
                this.processingIntent = true;
                this.bankAccountForm.disable();
                const tokenData = {
                    account_holder_name: form.account_holder_name,
                    account_holder_type: form.business_type,
                    country: 'GB',
                    currency: 'gbp',
                    account_number: form.account_number,
                    routing_number: form.sort_code
                };
                const { token, error } = yield this.stripe.createToken('bank_account', tokenData);
                if (error) {
                    this.processingIntent = false;
                    this.awaitingCompletion = false;
                    this.bankAccountForm.enable();
                    this._toastr.error('Please enter valid account details');
                }
                else {
                    this.processingIntent = false;
                    this.intentProcessed = true;
                    this.awaitingCompletion = !!this.clear;
                    const item = { token, default_for_currency: form.default_for_currency };
                    this.emitter.emit(item);
                    console.log({ item });
                }
            }
        });
    }
    ngOnInit() {
        if (this.clear) {
            this.clear.subscribe(clear => {
                if (clear) {
                    this.bankAccountForm.reset();
                    this.bankAccountForm.markAsPristine();
                    this.bankAccountForm.markAsUntouched();
                    this.bankAccountForm.enable();
                    this.intentProcessed = false;
                    this.awaitingCompletion = false;
                }
                console.log('clear the card form, reset', clear);
            });
        }
        if (this.disabled) {
            this.disabled.subscribe(disabled => {
                (disabled) ? this.bankAccountForm.disable() : this.bankAccountForm.enable();
            });
        }
    }
    updateBusinessType(event) {
        if (event.checked) {
            this.business_type.patchValue('company');
        }
        else {
            this.business_type.patchValue('individual');
        }
    }
}

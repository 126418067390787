import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common';
import { ProfileAvatarComponent } from '@app/shared/_components/cards/profile-cards/profile-avatar.component';

@NgModule({
  declarations: [
    ProfileAvatarComponent
  ],
  imports: [CommonModule],
  exports: [
    ProfileAvatarComponent
  ],
  providers: [],
})
export class UserModule { }

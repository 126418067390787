
    <div class="circle-loader"
      [ngClass]="{'load-complete': complete}"
      [ngStyle]="{
        'border-color': complete ? color : 'initial',
        'border-left-color': color,
        'width': size,
        'height': size
      }">

      <div
        class="checkmark draw"
        [ngStyle]="{'display': complete ? 'block' : 'none', 'border-right-color': color, 'border-top-color': color}">
      </div>
    </div>
  
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PropertyPicturesUploadComponent } from './property-pictures-upload.component';
import { BunkUploaderModule } from '../uploaders/bunk-uploader.module';
import { ReactiveFormsModule } from '@angular/forms';
import { LoadingSpinnerModule } from '../loading-spinner/loading-spinner.module';
import { SortableListModule } from '../sortable-list/sortable-list.module';

@NgModule({
  imports: [
    CommonModule,
    BunkUploaderModule,
    ReactiveFormsModule,
    LoadingSpinnerModule,
    SortableListModule
  ],
  declarations: [PropertyPicturesUploadComponent],
  exports:[PropertyPicturesUploadComponent]
})
export class PropertyPicturesUploadModule { }

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "@angular/material/dialog";
import * as i2 from "../../../../../node_modules/@angular/material/button/typings/index.ngfactory";
import * as i3 from "@angular/material/button";
import * as i4 from "@angular/cdk/a11y";
import * as i5 from "@angular/platform-browser/animations";
import * as i6 from "./dialog-alert.component";
var styles_DialogAlertComponent = [""];
var RenderType_DialogAlertComponent = i0.ɵcrt({ encapsulation: 0, styles: styles_DialogAlertComponent, data: {} });
export { RenderType_DialogAlertComponent as RenderType_DialogAlertComponent };
export function View_DialogAlertComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "h2", [["class", "mat-dialog-title"], ["mat-dialog-title", ""]], [[8, "id", 0]], null, null, null, null)), i0.ɵdid(1, 81920, null, 0, i1.MatDialogTitle, [[2, i1.MatDialogRef], i0.ElementRef, i1.MatDialog], null, null), (_l()(), i0.ɵted(2, null, ["", ""])), (_l()(), i0.ɵeld(3, 0, null, null, 2, "mat-dialog-content", [["class", "mat-dialog-content"]], null, null, null, null, null)), i0.ɵdid(4, 16384, null, 0, i1.MatDialogContent, [], null, null), (_l()(), i0.ɵted(-1, null, ["Are you sure?"])), (_l()(), i0.ɵeld(6, 0, null, null, 9, "mat-dialog-actions", [["class", "mat-dialog-actions"]], null, null, null, null, null)), i0.ɵdid(7, 16384, null, 0, i1.MatDialogActions, [], null, null), (_l()(), i0.ɵeld(8, 0, null, null, 3, "button", [["mat-button", ""], ["mat-dialog-close", ""]], [[1, "aria-label", 0], [1, "type", 0], [1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 9).dialogRef.close(i0.ɵnov(_v, 9).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i0.ɵdid(9, 606208, null, 0, i1.MatDialogClose, [[2, i1.MatDialogRef], i0.ElementRef, i1.MatDialog], { dialogResult: [0, "dialogResult"] }, null), i0.ɵdid(10, 180224, null, 0, i3.MatButton, [i0.ElementRef, i4.FocusMonitor, [2, i5.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i0.ɵted(-1, 0, ["No"])), (_l()(), i0.ɵeld(12, 0, null, null, 3, "button", [["mat-button", ""]], [[1, "aria-label", 0], [1, "type", 0], [1, "disabled", 0], [2, "_mat-animation-noopable", null]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 13).dialogRef.close(i0.ɵnov(_v, 13).dialogResult) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatButton_0, i2.RenderType_MatButton)), i0.ɵdid(13, 606208, null, 0, i1.MatDialogClose, [[2, i1.MatDialogRef], i0.ElementRef, i1.MatDialog], { dialogResult: [0, "dialogResult"] }, null), i0.ɵdid(14, 180224, null, 0, i3.MatButton, [i0.ElementRef, i4.FocusMonitor, [2, i5.ANIMATION_MODULE_TYPE]], null, null), (_l()(), i0.ɵted(-1, 0, ["Yes"]))], function (_ck, _v) { _ck(_v, 1, 0); var currVal_6 = ""; _ck(_v, 9, 0, currVal_6); var currVal_11 = true; _ck(_v, 13, 0, currVal_11); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = i0.ɵnov(_v, 1).id; _ck(_v, 0, 0, currVal_0); var currVal_1 = _co.data.title; _ck(_v, 2, 0, currVal_1); var currVal_2 = (i0.ɵnov(_v, 9).ariaLabel || null); var currVal_3 = i0.ɵnov(_v, 9).type; var currVal_4 = (i0.ɵnov(_v, 10).disabled || null); var currVal_5 = (i0.ɵnov(_v, 10)._animationMode === "NoopAnimations"); _ck(_v, 8, 0, currVal_2, currVal_3, currVal_4, currVal_5); var currVal_7 = (i0.ɵnov(_v, 13).ariaLabel || null); var currVal_8 = i0.ɵnov(_v, 13).type; var currVal_9 = (i0.ɵnov(_v, 14).disabled || null); var currVal_10 = (i0.ɵnov(_v, 14)._animationMode === "NoopAnimations"); _ck(_v, 12, 0, currVal_7, currVal_8, currVal_9, currVal_10); }); }
export function View_DialogAlertComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "dialog-alert", [], null, null, null, View_DialogAlertComponent_0, RenderType_DialogAlertComponent)), i0.ɵdid(1, 49152, null, 0, i6.DialogAlertComponent, [i1.MAT_DIALOG_DATA], null, null)], null, null); }
var DialogAlertComponentNgFactory = i0.ɵccf("dialog-alert", i6.DialogAlertComponent, View_DialogAlertComponent_Host_0, {}, {}, []);
export { DialogAlertComponentNgFactory as DialogAlertComponentNgFactory };

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./form-field-wrapper.component.scss.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "../../../core/utils/screen-detection/screen-detection.component.ngfactory";
import * as i4 from "../../../core/utils/screen-detection/screen-detection.component";
import * as i5 from "./form-field-wrapper.component";
var styles_BunkFormFieldComponent = [i0.styles];
var RenderType_BunkFormFieldComponent = i1.ɵcrt({ encapsulation: 2, styles: styles_BunkFormFieldComponent, data: {} });
export { RenderType_BunkFormFieldComponent as RenderType_BunkFormFieldComponent };
function View_BunkFormFieldComponent_1(_l) { return i1.ɵvid(0, [i1.ɵncd(null, 0), (_l()(), i1.ɵand(0, null, null, 0))], null, null); }
function View_BunkFormFieldComponent_4(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_BunkFormFieldComponent_3(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BunkFormFieldComponent_4)), i1.ɵdid(2, 540672, null, 0, i2.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = i1.ɵnov(_v.parent.parent, 2); _ck(_v, 2, 0, currVal_0); }, null); }
function View_BunkFormFieldComponent_2(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 3, "span", [["class", "bunk-form-field__label-stack"]], null, null, null, null, null)), i1.ɵncd(null, 1), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BunkFormFieldComponent_3)), i1.ɵdid(3, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = (_co.isDesktop && (_co.direction === "responsive")); _ck(_v, 3, 0, currVal_0); }, null); }
function View_BunkFormFieldComponent_6(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 0, null, null, null, null, null, null, null))], null, null); }
function View_BunkFormFieldComponent_5(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, null, null, null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BunkFormFieldComponent_6)), i1.ɵdid(2, 540672, null, 0, i2.NgTemplateOutlet, [i1.ViewContainerRef], { ngTemplateOutlet: [0, "ngTemplateOutlet"] }, null), (_l()(), i1.ɵand(0, null, null, 0))], function (_ck, _v) { var currVal_0 = i1.ɵnov(_v.parent, 2); _ck(_v, 2, 0, currVal_0); }, null); }
export function View_BunkFormFieldComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "screen-detection", [], null, [[null, "resized"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("resized" === en)) {
        var pd_0 = ((_co.isDesktop = $event.isDesktop) !== false);
        ad = (pd_0 && ad);
    } return ad; }, i3.View_ScreenDetectionComponent_0, i3.RenderType_ScreenDetectionComponent)), i1.ɵdid(1, 114688, null, 0, i4.ScreenDetectionComponent, [], null, { resized: "resized" }), (_l()(), i1.ɵand(0, [["labelStackTemplate", 2]], null, 0, null, View_BunkFormFieldComponent_1)), (_l()(), i1.ɵeld(3, 0, null, null, 9, "div", [["class", "bunk-form-field__wrapper"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(5, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(6, { "bunk-form-field__wrapper--responsive": 0, "block__quote": 1 }), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BunkFormFieldComponent_2)), i1.ɵdid(8, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), i1.ɵncd(null, 2), i1.ɵncd(null, 3), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_BunkFormFieldComponent_5)), i1.ɵdid(12, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); var currVal_0 = "bunk-form-field__wrapper"; var currVal_1 = _ck(_v, 6, 0, (_co.direction === "responsive"), _co.blockQuote); _ck(_v, 5, 0, currVal_0, currVal_1); var currVal_2 = _co.showLabel; _ck(_v, 8, 0, currVal_2); var currVal_3 = (!_co.isDesktop || (_co.direction === "column")); _ck(_v, 12, 0, currVal_3); }, null); }
export function View_BunkFormFieldComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "bunk-form-field", [], [[8, "className", 0]], null, null, View_BunkFormFieldComponent_0, RenderType_BunkFormFieldComponent)), i1.ɵdid(1, 114688, null, 0, i5.BunkFormFieldComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1).classList; _ck(_v, 0, 0, currVal_0); }); }
var BunkFormFieldComponentNgFactory = i1.ɵccf("bunk-form-field", i5.BunkFormFieldComponent, View_BunkFormFieldComponent_Host_0, { direction: "direction", blockQuote: "blockQuote", class: "class", showLabel: "showLabel" }, {}, ["[bunk-label-stack]", "[bunk-label]", "[bunk-input]", "[input-errors]"]);
export { BunkFormFieldComponentNgFactory as BunkFormFieldComponentNgFactory };

import { LocalStep } from '@app/core/navigation/stepper/stepper.constants';
import { Tenant } from '@env/routing';

export const BUNK_BANK_DETAILS =
  [
    {
      DETAIL: 'Bunk App Ltd',
      TITLE: 'Account name'
    },
    {
      DETAIL: 43039161,
      TITLE: 'Account number'
    },
    {
      DETAIL: '20-68-15',
      TITLE: 'Sort code'
    }
  ];
export const BUNK_INTERNATIONAL_BANK_DETAILS = [
  {
    DETAIL: 'Barclays Bank UK PLC',
    TITLE: 'Bank name in full'
  },
  {
    DETAIL: '1 Churchill Place, London, E14 5HP – United Kingdom',
    TITLE: 'Bank Address:'
  },
  {
    DETAIL: 'BUKBGB22',
    TITLE: 'SWIFTBIC Bank Identifier Code (BIC)'
  },
  {
    DETAIL: 'Bunk App Ltd',
    TITLE: 'Account name'
  },
  {
    DETAIL: 'GB37 BUKB 20681543039161',
    TITLE: 'International Bank Account Number (IBAN)'
  }
  ];
  
export const TENANCY_OFFER_SCHEMA = {
  INCOMPLETE: 'incomplete',
  PENDING_HOUSEMATE_ACCEPTANCE: 'pending_housemate_acceptance',
  PENDING_LANDLORD_DECISION: 'pending_landlord_decision',
  PENDING_HOLDING_DEPOSIT: 'pending_holding_deposit',
  NOTIFIED_PAID_HOLDING_DEPOSIT: 'notified_paid_holding_deposit',
  CANCELLED_BY_TENANT: 'cancelled_by_tenant',
  REJECTED_BY_LANDLORD: 'rejected_by_landlord',
  ACCEPTED: 'accepted'
};

export type OfferFlow = LocalStep[];

export function tenantApplicationStepArray(offerId: string): OfferFlow {
  return [
    { 
      title: 'Offer',
      routerLink: `/${Tenant.base}/${Tenant.make_an_offer.base}/${offerId}/${Tenant.make_an_offer.summary}`
    },
    { 
      title: 'References',
      routerLink: `/${Tenant.base}/${Tenant.make_an_offer.base}/${offerId}/${Tenant.make_an_offer.references.base}`
    },
    { 
      title: 'Agreement',
      routerLink: `/${Tenant.base}/${Tenant.make_an_offer.base}/${offerId}/${Tenant.make_an_offer.agreement.base}`
    }, 
    {
      title: 'Payment',
      routerLink: `/${Tenant.base}/${Tenant.make_an_offer.base}/${offerId}/${Tenant.make_an_offer.securing_payments.base}`
    }];
}

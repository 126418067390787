import { Component } from '@angular/core';
import { ProfileCardComponent } from './profile-card.component';

@Component({
  selector: 'profile-card-housemate',
  templateUrl: './profile-card-housemate.component.html',
  styleUrls: ['./profile-card.component.scss']
})
export class ProfileCardHousemateComponent extends ProfileCardComponent {
}

export const MinuteDuration = [
  {value: 5, viewValue: '5 mins'},
  {value: 10, viewValue: '10 mins'},
  {value: 15, viewValue: '15 mins'},
  {value: 20, viewValue: '20 mins'},
  {value: 25, viewValue: '25 mins'},
  {value: 30, viewValue: '30 mins'},
  {value: 35, viewValue: '35 mins'},
  {value: 40, viewValue: '40 mins'},
  {value: 45, viewValue: '45 mins'},
  {value: 50, viewValue: '50 mins'},
  {value: 55, viewValue: '55 mins'},
  {value: 60, viewValue: '65 mins'},
];

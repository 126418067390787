<div class="document-upload-box" [ngClass]="customClasses" appDragDrop (fileDropped)="bunkUploader.uploadFiles($event)">
  <h4 [ngClass]="{'mb-5': processingFile}">{{verificationTypeTitle}}</h4>
  <processing-spinner *ngIf="processingFile, else uploadImageBox" class="mt-4"></processing-spinner>
  <ng-template #uploadImageBox>
    <div *ngIf="!uploadedFileOriginalUrl && !uploadedFileName, else uploadedImage">
      <mat-icon svgIcon="{{svgIcon}}" class="mb-3 icon"></mat-icon>
      <div class="flex flex-column justify-content-center items-center h-45px">
        <span>Drag & drop file here</span>
        <span>or</span>
      </div>
      <button class="mt-2 mx-auto as_fs btn__generic text_light" (click)="bunkUploader.click()" type="button">Browse File</button>
      <small *ngIf="errorsMessage" class="text-danger mt-2">{{ errorsMessage }}</small>
    </div>
    <ng-template #uploadedImage>
      <img src="{{uploadedFileOriginalUrl}}" class="photo mb-3" [alt]="uploadedFileName"/>
      <div class="flex justify-content-center items-center h-45px">
        <mat-icon svgIcon="file-icon" class="mx-2 my-0 document-icon"></mat-icon>
        <span>{{uploadedFileName}}</span>
      </div>
      <button class="mt-2 mx-auto as_fs btn__seethrough text_light" (click)="bunkUploader.click()" type="button">Edit</button>
    </ng-template>
  </ng-template>
  <mat-select *ngIf="documentTypesList.length" [(ngModel)]="uploadedDocumentType" placeholder="Document Type" class="text-left mt-4" (selectionChange)="documentTypeChange()">
    <mat-option *ngFor="let documentType of documentTypesList" [value]="documentType.value">
      {{documentType?.viewValue}}
    </mat-option>
  </mat-select>
  <bunk-uploader #bunkUploader
                 [filePath]="imageFilePath"
                 (uploadStart)="onUpStart()"
                 (uploadChange)="onUploadedFile($event)"
                 [multiple]="false"
                 accept="'.doc,.docx,.pdf,image/png, image/jpeg, image/jpg'"
                 hidden>
  </bunk-uploader>
</div>

<screen-detection (resized)="isDesktop = $event.isDesktop"></screen-detection>
<ng-container *ngIf="navEvents$ | async"></ng-container>

<tenant-main-template *ngIf="_auth.authenticated">
  <ng-container *ngTemplateOutlet="searchProperties"></ng-container>
</tenant-main-template>

<ng-container *ngIf="!_auth.authenticated">
  <nav-top guest="true">
    <ng-container *ngIf="!isDesktop">
      <button [matMenuTriggerFor]="menu" class="btn__seethrough">
        <mat-icon class="mr_4">menu</mat-icon>Menu
      </button>
      <mat-menu #menu="matMenu">
        <button id="guest-login" mat-menu-item [routerLink]="['/' + routes.Auth.signin]">Log in</button>
        <button id="guest-signup" mat-menu-item  [routerLink]="['/' + routes.Auth.signup]">Sign up</button>
      </mat-menu>
    </ng-container>

    <span *ngIf="isDesktop" class="signup__guest-btns">
      <button id="guest-signup" class="btn__generic" [routerLink]="['/' + routes.Auth.signup]"> Sign up</button>
      <button id="guest-login" class="btn__cleanskin" [routerLink]="['/' + routes.Auth.signin]">Log in</button>
    </span>
  </nav-top>
  <div class="main-container__guest">
    <ng-container *ngTemplateOutlet="searchProperties"></ng-container>
  </div>
</ng-container>

<ng-template #searchProperties>
  <div #mainWrapper class="main__wrapper" *ngIf="{ properties: combinedResults$ | async, considerProperties: consideredResults$ | async} as combined">
    <!-- <div class="search-bar hide-md hide-lg">
      <instant-search-input
        class="search-bar__keyword"
        [formControl]="searchStr"
        [indexName]="indexName"
        placeholder="Search by address or postcode">
      </instant-search-input>
    </div> -->
    <ng-container *ngIf="campusFilterArray else filterSkeleton">
    <div class="search-filters w_100">
      <!-- <instant-search-input
        class="search-filters__keyword hide-sm"
        [formControl]="searchStr"
        [indexName]="indexName"
        placeholder="Search by address or postcode">
      </instant-search-input> -->
      <shared-filters class="w_100"
      #sharedFilter
      [indexName]="indexName"
      [typeFilterValues]= "campusFilterArray"
      [title]="'Properties'"
      *ngIf="isCampusKey"
      (filterEmitter)="updateBehaviourSubjects($event)">
      <ng-container *ngTemplateOutlet="extraFilters"></ng-container>
    </shared-filters>
    <shared-filters class="w_100"
    #sharedFilter
    [indexName]="indexName"
    [title]="'Properties'"
    *ngIf="!isCampusKey"
    (filterEmitter)="updateBehaviourSubjects($event)">
    <ng-container *ngTemplateOutlet="extraFilters"></ng-container>
  </shared-filters>
    </div>
  </ng-container>
  <ng-template #extraFilters>
     <div>
    <div #controls class="search-filters__controls">
      <div #filtersBtn (clickOutside)="$event.value && scrollBlur('filters')" [listen]="menuSubject.value === 'filters'">
        <button (click)="scrollFocus(filtersBtn, 'filters')" class="search-filters__btn-control btn__seethrough btn--round btn--sm hide-md hide-lg">
          <mat-icon svgIcon="icon-filter" style="transform: scale(0.8); margin-left: -3px; padding-top: 1px;"></mat-icon><span>Filters</span>
        </button>

        <div *ngIf="menuSubject.value === 'filters'" class="search-filters__controls-menu search-filters__controls-menu--all">
          <button class="search-filters__btn-clear btn__cleanskin" (click)="navigateClearParams()">Reset filters</button>

          <div class="search-filters__controls-menu-filters">
            <ng-container *ngTemplateOutlet="priceTemplate"></ng-container>
          </div>
          <div class="search-filters__controls-menu-filters">
            <ng-container *ngTemplateOutlet="bedsTemplate"></ng-container>
          </div>
          <div class="search-filters__controls-menu-filters">
            <h3 class="text--md">Students</h3>
            <mat-slide-toggle labelPosition="before" [formControl]="studentsFilter" [checked]="studentsFilter.value"><small>Show student lettings only</small></mat-slide-toggle>
          </div>

          <div class="search-filters__controls-menu-action">
            <div>
              <button class="btn__info search-filters__btn-done" (click)="scrollBlur('filters')">Done</button>
            </div>
          </div>
        </div>
      </div>

      <div #pricesBtn (clickOutside)="$event.value && scrollBlur('price')" [listen]="menuSubject.value === 'price'">
        <button (click)="scrollFocus(pricesBtn, 'price')" class="search-filters__btn-control btn__seethrough btn--round btn--sm" [ngClass]="{'btn__seethrough--selected': maxPriceFilter.value !== 'noMax' || minPriceFilter.value !== '0'}">
          <span *ngIf="maxPriceFilter.value === 'noMax' && minPriceFilter.value !== '0'">Above {{client_data.currency_symbol + minPriceFilter.value }}</span>
          <span *ngIf="maxPriceFilter.value !== 'noMax' && minPriceFilter.value === '0'">Below {{ client_data.currency_symbol + maxPriceFilter.value }}</span>
          <span *ngIf="maxPriceFilter.value !== 'noMax' && minPriceFilter.value !== '0'">{{client_data.currency_symbol + minPriceFilter.value }} - {{client_data.currency_symbol + maxPriceFilter.value }}</span>
          <span *ngIf="maxPriceFilter.value === 'noMax' && minPriceFilter.value === '0'">Price</span>
          <mat-icon svgIcon="icon-expand-more"></mat-icon>
        </button>
        <div *ngIf="menuSubject.value === 'price'" class="search-filters__controls-menu">
          <button class="search-filters__btn-clear btn__cleanskin" (click)="clearPriceRange()">Clear</button>

          <div class="search-filters__controls-menu-filters">
            <ng-container *ngTemplateOutlet="priceTemplate"></ng-container>
          </div>
          <div class="flex justify-center">
            <button class="btn__info search-filters__btn-done" (click)="scrollBlur('price')">Done</button>
          </div>
        </div>
      </div>

      <div #bedsBtn (clickOutside)="$event.value && scrollBlur('beds')" [listen]="menuSubject.value === 'beds'">
        <button (click)="scrollFocus(bedsBtn, 'beds')" class="search-filters__btn-control  btn__seethrough btn--round btn--sm" [ngClass]="{'btn__seethrough--selected': minBedsFilter.value !== '0' || maxBedsFilter.value !== '100'}">

          <span *ngIf="minBedsFilter.value === '0' && maxBedsFilter.value === 'studio'">Studio</span>
          <span *ngIf="(minBedsFilter.value === 'studio' && minBedsFilter.value !== '0') && (maxBedsFilter.value === 'studio')">Studio</span>
          <span *ngIf="minBedsFilter.value === '1' && (maxBedsFilter.value === '1' || maxBedsFilter.value === 'studio')">1 Bed</span>
          <span *ngIf="(minBedsFilter.value === '1') && (maxBedsFilter.value !== '1' && maxBedsFilter.value !== 'studio' && maxBedsFilter.value !== '100')">{{ minBedsFilter.value }} - {{ maxBedsFilter.value }} Beds</span>
          <span *ngIf="minBedsFilter.value !== '0' && maxBedsFilter.value === '100'">{{ minBedsFilter.value === 'studio' ? 'Studios+' : minBedsFilter.value + '+ bed' }}{{ minBedsFilter.value > 1 ? 's' : ''}}</span>
          <span *ngIf="(minBedsFilter.value !== '1') && minBedsFilter.value !== '0' && maxBedsFilter.value !== '100' && minBedsFilter.value !== maxBedsFilter.value">{{ minBedsFilter.value }} - {{ maxBedsFilter.value }} Bed{{ maxBedsFilter.value > 1 ? 's' : ''}}</span>
          <span *ngIf="(minBedsFilter.value !== 'studio' && minBedsFilter.value !== '1') && minBedsFilter.value !== '0' && maxBedsFilter.value !== '100' && minBedsFilter.value === maxBedsFilter.value && minBedsFilter.value != 'studio'">{{ maxBedsFilter.value }} Bed{{ maxBedsFilter.value > 1 ? 's' : ''}}</span>
          <span *ngIf="minBedsFilter.value === '0' && maxBedsFilter.value !== '100' && maxBedsFilter.value !== 'studio'">Up to {{ maxBedsFilter.value }} Bed{{ maxBedsFilter.value > 1 ? 's' : ''}}</span>
          <span *ngIf="minBedsFilter.value === '0' && maxBedsFilter.value === '100'">Beds</span>

          <mat-icon svgIcon="icon-expand-more"></mat-icon>
        </button>
        <div *ngIf="menuSubject.value === 'beds'" class="search-filters__controls-menu">
          <button class="search-filters__btn-clear btn__cleanskin" (click)="clearBedsRange()">Clear</button>

          <div class="search-filters__controls-menu-filters">
            <ng-container *ngTemplateOutlet="bedsTemplate"></ng-container>
          </div>
          <div class="flex justify-center">
            <button class="btn__info search-filters__btn-done" (click)="scrollBlur('beds')">Done</button>
          </div>
        </div>
      </div>

      <ng-template #priceTemplate>
        <h3 class="text--md">Price</h3>
        <div class="flex justify-between items-center margin-top--sm">
          <mat-form-field appearance="outline">
            <mat-label hidden>Min price: </mat-label>
            <mat-select [formControl]="minPriceFilter" [value]="minPriceFilter.value">
              <mat-option *ngFor="let type of minPriceFilterTypes" [value]="type.value">
                {{type.viewValue}}
              </mat-option>
            </mat-select>
          </mat-form-field>

          <span class="range-to text--subtle"> - </span>

          <mat-form-field appearance="outline">
            <mat-label hidden>Max price: </mat-label>
            <mat-select [formControl]="maxPriceFilter" [value]="maxPriceFilter.value">
              <mat-option *ngFor="let type of maxPriceFilterTypes" [value]="type.value">
                {{type.viewValue}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </ng-template>

      <ng-template #bedsTemplate>
        <h3 class="text--md">Beds</h3>
        <div class="flex justify-between items-center margin-top--sm">
          <mat-form-field appearance="outline">
            <mat-label hidden>Min beds: </mat-label>
            <mat-select [formControl]="minBedsFilter" [value]="minBedsFilter.value">
              <mat-option *ngFor="let type of minBedsFilterTypes" [value]="type.value">
                {{type.viewValue}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <span class="range-to text--subtle"> - </span>
          <mat-form-field appearance="outline">
            <mat-label hidden>Max beds: </mat-label>
            <mat-select [formControl]="maxBedsFilter" [value]="maxBedsFilter.value">
              <mat-option *ngFor="let type of maxBedsFilterTypes" [value]="type.value">
                {{type.viewValue}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </ng-template>

      <mat-slide-toggle labelPosition="before" [formControl]="studentsFilter" [checked]="studentsFilter.value"><small>Students</small></mat-slide-toggle>
    </div>
  </div>
  </ng-template>
  <ng-template #filterSkeleton>
    <filter-skeleton class="margin-bottom--lg"></filter-skeleton>
  </ng-template>
  <ng-template #loadSkeleton>
    <div class="hide-sm">
      <skeleton-loading></skeleton-loading>
    </div>
  </ng-template>

    <div [ngStyle]="{ 'pointer-events':  menuSubject.value ? 'none' : 'auto' }">

      <div class="w_100 margin-top" *ngIf="(propertyManager$ | async) as manager">
        <ng-container *ngIf="manager && manager[0] && manager[0].uid">
        <property-owner-card [landlordId]="manager[0].uid" [showLogo]="true" [showManager]="true" [search]="true"></property-owner-card>
      </ng-container>
      </div>
      <div class="prop-search__title-bar">
        <!-- <strong class="text--md" *ngIf="(managerFilter$ | async) as manager">
          Properties by {{manager | titlecase | replaceUnderscore}}
        </strong> -->
        <h2
          class="text--lg margin-bottom--sm margin-top--md"
          *ngIf="(combined.properties && totalProperties >= 1 && searchStr.value) ||
            totalProperties > minPropertiesToShowResultText"
        >
          <button *ngIf="paramsDirty" (click)="navigateClearParams()" class="btn__seethrough btn--round btn--xs"><mat-icon svgIcon="icon-close"></mat-icon><span>Clear</span></button>
          {{ totalProperties }} {{ (totalProperties > 1) ? 'Results' : 'Result'}}
          <span *ngIf="locationFilter.value !== 'all'"> in {{ locationFilter.value | titlecase }}</span>
          <span *ngIf="searchStr.value"> for "{{ searchStr.value }}"</span>
        </h2>
      </div>

      <div class="empty__state-short" *ngIf="combined.properties && combined.properties.length <= 0">
        <img class="w_100" src="/assets/img/no-state/no-viewings.png" alt="No viewings image">
        <h1 class="text--lg">No matching properties found</h1>
        <p>Adjust the filters to expand your search</p>
        <button class="btn__seethrough" (click)="navigateClearParams()">Clear search</button>
      </div>

      <div class="props-search">
        <property-card [property]="property" *ngFor="let property of combined.properties"></property-card>
      </div>

      <button *ngIf="showLoadMore" class="btn__generic btn--round btn--lg btn-load-more" (click)="loadMore()">View more</button>

      <ng-container *ngIf="combined.considerProperties">
        <ng-container *ngIf="combined.considerProperties.nearbyHits?.length >= 1">
          <hr>
          <h2 class="text--md margin-bottom--sm" *ngIf="combined.considerProperties.userSearchAddress">More properties near {{ combined.considerProperties.userSearchAddress }}</h2>
          <div class="props-search">
            <property-card [property]="property" *ngFor="let property of combined.considerProperties.nearbyHits"></property-card>
          </div>
        </ng-container>

        <ng-container *ngIf="!managerQuery && combined?.properties?.length <= MIN_PROPS && combined?.considerProperties?.otherHits?.length && combined?.considerProperties?.nearbyHits?.length < MIN_NEARBY_PROPS">
          <hr>
          <h2 class="text--md margin-bottom--sm">Other properties</h2>
          <div class="props-search">
            <property-card [property]="property" *ngFor="let property of combined.considerProperties.otherHits"></property-card>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </div>
</ng-template>

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import {
    MatDatepickerModule,
    MatDialogModule,
    MatFormFieldModule,
    MatIconModule, MatInputModule,
    MatMenuModule,
    MatPaginatorModule,
    MatRadioModule,
    MatSelectModule,
    MatTableModule
} from '@angular/material';
import { BunkDialogModule } from '@app/shared/_components/modal/bunk-dialog.module';
import { ProcessingSpinnerModule } from '@app/shared/_components/processing-spinner/processing-spinner.module';
import { BunkOverlayModule } from '@app/shared/_components/file-preview-overlay/bunk-overlay.module';
import { ProfileCardOverlayModule } from '@app/shared/_components/cards/profile-cards/profile-card-overlay.module';
import { BunkFormFieldModule } from '@app/shared/_forms/form-field-wrapper';

import { BunkPaymentTableComponent } from './bunk-payment-table.component';

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatDatepickerModule,
        MatRadioModule,
        MatPaginatorModule,
        MatSelectModule,
        MatTableModule,
        MatDialogModule,
        MatIconModule,
        MatMenuModule,
        BunkDialogModule,
        ProcessingSpinnerModule,
        BunkOverlayModule,
        ProfileCardOverlayModule,
        BunkFormFieldModule,
        MatInputModule
    ],
  exports: [BunkPaymentTableComponent],
  declarations: [BunkPaymentTableComponent],
  providers: [],
})
export class BunkPaymentTableModule { }

import {AfterContentChecked, Component, EventEmitter, Inject, OnDestroy, OnInit} from '@angular/core';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {StripeService, UserService} from '@app/services';
import {finalize, switchMap, takeUntil} from 'rxjs/operators';
import {from, of, Subject} from 'rxjs';
import {Router} from '@angular/router';
import {Tenant, Profile} from '@env/routing';
import {DatesService} from '@app/shared/_services/dates.service';
import {PaymentsService} from '@app/services/payments.service';
import {AngularFirestore} from '@angular/fire/firestore';
import {FormControl} from '@angular/forms';
import {Observable} from 'rxjs';
import {ToastrService} from 'ngx-toastr';
import {environment} from '@env/environment';

@Component({
  selector: 'app-checkout',
  template: `
    <div class="checkout-modal__container cc" (click)="clearErrors()">
      <div class="modal-header">{{(showNewCustomerForm ? 'ADD CARD' : data.title)}}</div>
      <div class="modal-body cc" *ngIf="!showConfirmation; else confirmationView">
        <mat-tab-group [(selectedIndex)]="selectedIndex" class="tab-size">
          <mat-tab label="Automatic Payments" *ngIf="!data.disable_automatic_payments">
            <div class="instructions" [hidden]="showMessage || showNewCustomerForm">Setup once
              and rent will
              be
              collected each month from the
              card you've selected
            </div>
            <div class="instructions is-overdue" [hidden]="!showMessage">
              Oops... <br> We are unable to setup a subscription if you have payments that
              are overdue. Please manually settle any outstanding balance and then come back.
            </div>
            <div class="cards__container rc" [hidden]="showMessage || showNewCustomerForm">
              <div class="card__container cc"
                   *ngFor="let card of (_user.user$ | async)?.payments_data.cards"
                   [ngClass]="{'selected': card === selectedCardAuto}"
                   (click)="selectCard(card, 'auto')">
                <div class="layer"></div>
                <img src="/assets/img/icons/cards-icons/Visa_logo.png" alt="" class="card-icon">
                <div class="card-last-four">Ending {{card.last4}}</div>
              </div>
              <div class="add-new-card cc" (click)="showCardForm()" *ngIf="!showCardsForm">
                <mat-icon>add</mat-icon>
                Add card
              </div>
            </div>
            <div class="spinner__container" *ngIf="subscriptionLoading">
              <loading-spinner [text_message]="message"></loading-spinner>
            </div>
            <div class="error" *ngIf="cardFormErrorMessage">{{cardFormErrorMessage}}</div>
            <div class="w_100">
              <new-stripe-customer-form
                *ngIf="showNewCustomerForm || showNewCustomerForm  && !_user.userDb.payments_data.cards"
                (showCustomerForm)=" showNewCustomerForm = $event"></new-stripe-customer-form>
            </div>
            <cards-form
              *ngIf="showCardsForm"
              (setupComplete)="addCard($event)"
              [disabled]="disableCardsForm"
              [clear]="clearCardsForm"
            >
              <div class="hide-form rc" *ngIf="showCardsForm" header><a
                (click)="showCardsForm = false">Hide</a>
              </div>
            </cards-form>
            <div class="summary__container cc"
                 [hidden]="showMessage || payablePayments.length <= 0">
              <div class="first-row rc">
                <div class="left">Payment breakdown:</div>
                <div class="right">My share</div>
              </div>
              <div class="month-row rc" *ngFor="let month of payablePayments">
                <div class="left" [ngSwitch]="month.payment_type">
                  <span *ngSwitchCase="'deposit'">Security Deposit</span>
                  <span *ngSwitchCase="'rent'">{{month.payment_type | titlecase}}
                    - {{_dates.printMonth(month.month)}} {{month.year}}</span>
                </div>
                <div class="right">&pound;{{month.amount}}</div>
              </div>
              <div class="last-row rc">
                <div class="left">Total:</div>
                <div class="right">&pound;{{data.total}}</div>
              </div>

            </div>

            <div class="link_2 mt_8" (click)="hasCoupon = !hasCoupon" [hidden]="showNewCustomerForm">Have a coupon?
            </div>
            <ng-container *ngIf="hasCoupon">
              <div class="rc ai_c">
                <mat-form-field (click)="couponCode.enable()">
                  <input type="text" matInput [formControl]="couponCode" [name]="couponCode"
                         style="padding-bottom: 2px;" placeholder="Enter code here">
                </mat-form-field>
                <mat-icon *ngIf="couponIsValid" color="accent">check</mat-icon>
                <button (click)="submitCoupon('rent')"
                        class="btn__generic ml_4"
                        [disabled]="couponCode.disabled"
                        *ngIf="!loadingCoupon; else couponIsLoading">Apply
                </button>
                <ng-template #couponIsLoading>
                  <loading-spinner class="ml_4"></loading-spinner>
                </ng-template>
              </div>
              <ng-container *ngIf="(coupons$ | async) as coupons">
                <div *ngIf="couponIsValid" class="mt_4 text_colour_1">
                  <span *ngIf="coupons[0].percent_off; else amountOffDiscount">Great! That's {{coupons[0].percent_off}}
                    %off your first {{coupons[0].duration_in_months}} months of membership!</span>
                  <ng-template #amountOffDiscount>
                    Great! That's {{client_data.currency_symbol + coupons[0].amount_off / 100}} off towards your future payments on
                    Bunk!
                  </ng-template>
                </div>
              </ng-container>
            </ng-container>


            <div class="checkout-button__container cc">
              <button class="btn__cta"
                      [disabled]="!selectedCardAuto"
                      [hidden]="showMessage || showNewCustomerForm"
                      (click)="setupSubscription()">Subscribe
              </button>
              <button class="btn__generic"
                      [hidden]="!showMessage"
                      (click)="selectedIndex = 1">Got it!
              </button>
            </div>
          </mat-tab>
          <mat-tab label="Manual payments">
            <div class="instructions" [hidden]="showNewCustomerForm">Select a card and make payment now but please
              remember to pay on time
            </div>
            <div class="cards__container rc" [hidden]="showNewCustomerForm">
              <div class="card__container cc"
                   *ngFor="let card of (_user.user$ | async)?.payments_data.cards"
                   [ngClass]="{'selected': card === selectedCardManual}"
                   (click)="selectCard(card, 'manual')">
                <div class="layer"></div>
                <img src="/assets/img/icons/cards-icons/Visa_logo.png" alt="" class="card-icon">
                <div class="card-last-four">Ending {{card.last4}}</div>
              </div>
              <div class="add-new-card cc" (click)="showCardForm()" *ngIf="!showCardsForm">
                <mat-icon>add</mat-icon>
                Add card
              </div>

            </div>
            <loading-spinner *ngIf="loading" [text_message]="message"></loading-spinner>
            <div class="error" *ngIf="cardFormErrorMessage">{{cardFormErrorMessage}}</div>

            <new-stripe-customer-form
              *ngIf="showNewCustomerForm || showNewCustomerForm  && !_user.userDb.payments_data.cards"
              (showCustomerForm)=" showNewCustomerForm = $event"></new-stripe-customer-form>

            <cards-form *ngIf="showCardsForm"
                        [hidden]="showNewCustomerForm"
                        (setupComplete)="addCard($event)"
                        [disabled]="disableCardsForm"
                        [clear]="clearCardsForm"
            >
              <div class="hide-form rc" *ngIf="showCardsForm" header><a
                (click)="showCardsForm = false">Hide</a>
              </div>
            </cards-form>
            <div class="summary__container cc" [hidden]="payablePayments.length <= 0">
              <div class="first-row rc">
                <div class="left">Payment breakdown:</div>
                <div class="right">My share</div>
              </div>
              <div class="month-row rc" *ngFor="let month of payablePayments">
                <div class="left" [ngSwitch]="month.payment_type">
                  <span *ngSwitchCase="'deposit'">Security Deposit</span>
                  <span *ngSwitchCase="'rent'">{{month.payment_type | titlecase}}
                    - {{_dates.printMonth(month.month)}} {{month.year}}</span>
                </div>
                <div class="right">&pound;{{month.amount}}</div>
              </div>
              <div class="last-row rc">
                <div class="left">Total:</div>
                <div class="right">&pound;{{data.total}}</div>
              </div>

            </div>
            <div class="checkout-button__container cc">
              <button class="btn__cta"
                      [hidden]="showNewCustomerForm"
                      [disabled]="!selectedCardManual || payablePayments.length <= 0"
                      (click)="pay()">Pay now
              </button>
            </div>

          </mat-tab>
        </mat-tab-group>
      </div>
      <ng-template #confirmationView>
        <div class="spinner__container" *ngIf="paymentProcessing">
          <loading-spinner [text_message]="checkoutMessage" class="confirmation-spinner"></loading-spinner>
        </div>

        <div class="confirmation__container pt_20 cc jc_sb" *ngIf="!paymentProcessing && !paymentError">
          <p class="text--lg margin-bottom--sm">Awesome!</p>
          <p class="text--subtle margin-bottom--lg">{{confirmationMessage}}</p>
          <button class="btn__generic" (click)="backHome()">Back home
          </button>
        </div>
        <div class="cc jc_sb pt_20" *ngIf="paymentError">
          <p class="text--lg margin-bottom--sm" *ngIf="paymentError.status === 402; else genericError">Please update
            your card</p>
          <ng-template #genericError>
            <p class="text--lg margin-bottom--sm">Something went wrong...</p>
          </ng-template>
          <p class="text--subtle margin-top--sm margin-bottom--lg">{{paymentError.message}}</p>
          <div class="action-bar">
            <button class="btn__subdued" (click)="backHome()">Close</button>
            <button class="btn__cta" (click)="resetForm()" *ngIf="!showScaButton; else scaButton">Try
              again
            </button>
            <ng-template #scaButton>
              <button class="btn__cta" (click)="updatePaymentMethods()">Update payment method</button>
            </ng-template>
          </div>
        </div>
      </ng-template>
    </div>


  `,
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit, AfterContentChecked, OnDestroy {
  public showCardsForm = false;
  public subscriptionLoading: boolean;
  public message: string;
  public confirmationMessage: string;
  public checkoutMessage: string;
  public cardFormErrorMessage: string;
  public selectedCardManual: any;
  public selectedCardAuto: any;
  public showConfirmation = false;
  public paymentProcessing: boolean;
  public paymentError: { status: number, message: string };
  public showMessage: boolean;
  public routes = {Tenant, Profile};
  public selectedIndex: number;
  private destroy$: Subject<boolean> = new Subject<boolean>();
  private rentData: {
    amount: number;
    interval: string;
    interval_count: number;
  };

  public hasCoupon: boolean;
  public couponIsValid = false;
  public couponCode = new FormControl('');
  public coupons$: Observable<any>;
  public loadingCoupon: boolean;
  public couponId: string;
  private couponTarget: string;
  public showScaButton = false;
  public showNewCustomerForm = false;
  public clearCardsForm: Subject<boolean> = new Subject();
  public disableCardsForm: Subject<boolean> = new Subject();
  public client_data = environment.client_data;

  emitter = new EventEmitter();
  stripe = Stripe(environment.stripeConfig.publicKey);

  public payablePayments: any[] = [];

  constructor(
    public _user: UserService,
    public _dates: DatesService,
    public dialogRef: MatDialogRef<CheckoutComponent>,
    private _stripe: StripeService,
    private router: Router,
    private readonly afs: AngularFirestore,
    private _pmt: PaymentsService,
    private _toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {

    if (this._user.userDb.payments_data && this._user.userDb.payments_data.customer_id) {
      this._pmt
        .getCurrentUserPaymentsOverviewDoc()
        .subscribe((poData: any) => {
          this.rentData = {
            amount: poData.rent_amount,
            interval: poData.rent_interval,
            interval_count: poData.rent_interval_count
          };
        });
    }

  }

  ngOnInit() {
    this.selectedIndex = 0;
    this.data.unpaidMonths.forEach((payment) => {
      if (payment.status === 'overdue') {
        return (this.showMessage = true);
      }
    });
  }

  ngAfterContentChecked(): void {
    const paidDuringSession = Object.keys(localStorage).filter(payment_id => localStorage[payment_id] === 'paid');
    this.payablePayments = this.data.unpaidMonths.filter(item => {
      return !paidDuringSession.includes(item.payment_id);
    });
  }

  showCardForm() {
    (this._user.userDb.payments_data.customer_id) ? this.showCardsForm = true : this.showNewCustomerForm = true;
  }

  addCard(token) {
    this.message = 'Please wait until we\'ve checked your card...';
    this._stripe.attachPaymentMethodToCustomer(token.payment_method)
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe((data) => {
        console.log(data);
        this._toastr.success('Your card has been successfully added to your account!');
        this.showCardsForm = false;
        this.clearCardsForm.next(true);
        this.disableCardsForm.next(false);

        // @TODO: automatically select the added card...
        /* if (this._user.userDb.payments_data.cards.length) {
          this.selectCard(this._user.userDb.payments_data.cards[this._user.userDb.payments_data.cards.length - 1], 'manual');
        } */
      }, error => {
        this.cardFormErrorMessage = error.error.message;
        this.clearCardsForm.next(false);
        this.disableCardsForm.next(false);
      });
  }

  selectCard(card: any, type: string) {
    console.log(card);
    if (type === 'auto') {
      this.selectedCardAuto = card;
    } else if (type === 'manual') {
      this.selectedCardManual = card;
    }
  }

  pay() {
    localStorage.clear();
    this.dialogRef.disableClose = true;
    const stripeAmount = Math.round(this.data.total * 100);
    const ids = this.getPaymentsIds();
    this._stripe
      .createTransaction(
        stripeAmount,
        this.selectedCardManual.id,
        this.data.landlord_id,
        this.data.tenancy_id,
        this.data.type,
        ids
      )
      .then((id) => {
        this.showConfirmation = true;
        this.paymentProcessing = true;
        this.checkoutMessage =
          'Please wait while we process the payment...';
        return this._stripe
          .processPayment(id, this.data.landlord_id)
          .pipe(
            takeUntil(this.destroy$),
            switchMap((result: any) => {
              if (result.intent.status === 'succeeded') {
                return of(result.intent);
              } else {
                return from(this.stripe.confirmCardPayment(result.intent.client_secret, {payment_method: result.intent.payment_method}));
              }
            })
          )
          .subscribe(
            (result: any) => {
              this.paymentProcessing = false;

              if (result.error) {
                this.paymentError = {
                  status: 401,
                  message: result.error.message
                };
                this.emitter.emit({status: 500});
              } else {
                this.confirmationMessage =
                  'You\'re all paid up to date!';
                for (const id of ids) {
                  localStorage.setItem(id, 'paid');
                }
                this.emitter.emit({status: 200});
              }
            },
            (err) => {
              this.paymentProcessing = false;
              if (err.status === 402) {
                this.showScaButton = true;
                this.paymentError = {
                  status: 402,
                  message: 'Due to changes in regulation, we need you to update your payment method so that you can continue making payments. This should only take a moment.'
                };
              } else {
                this.paymentError = {
                  message: err.error.message,
                  status: err.status
                };
                this.emitter.emit({status: 500});
              }
            }
          );
      });
  }

  setupSubscription() {
    this.subscriptionLoading = true;
    this.showMessage = false;
    this.showConfirmation = true;
    this.paymentProcessing = true;
    this.checkoutMessage = 'Please wait while we setup everything for you...';

    this._stripe.createSubscription(this.data.tenancy_id, this.selectedCardAuto.id, 'rent')
      .pipe(
        takeUntil(this.destroy$),
        finalize(() => {
          this.paymentProcessing = false;
          this.subscriptionLoading = false;
        })
      )
      .subscribe(
        (data) => {
          console.log({data});
          this.confirmationMessage = `You're all set! From now on we'll automatically collect ${
            this.client_data.currency_symbol + this.rentData.amount
          } every ${
            this.rentData.interval_count === 1
              ? 'month'
              : this.rentData.interval_count + ' months'
          } when your rent becomes due.`;
        },
        (e) => {
          console.log({e});
          this.paymentError = {
            message: e.error.raw.message,
            status: e.status
          };
        }
      )
  }

  getPaymentsIds(): Array<string> {
    const ids = [];
    this.data.unpaidMonths.forEach((month) => {
      ids.push(month.payment_id);
    });
    return ids;
  }

  clearErrors() {
    this.cardFormErrorMessage = null;
  }

  resetForm() {
    this.showConfirmation = false;
    this.paymentError = null;
  }

  backHome() {
    this.dialogRef.close();
    return this.router.navigate([Tenant.base, Tenant.manage.base, Tenant.manage.my_property.base, Tenant.manage.my_property.dashboard]);
  }

  submitCoupon(subscription_type: string) {
    this.coupons$ = this.afs.collection('coupons', ref => ref.where('code', '==', this.couponCode.value).limit(1)).valueChanges();
    this.loadingCoupon = true;
    this.coupons$.pipe(
      switchMap(coupons => {
        if (coupons[0].valid) {
          this.couponId = coupons[0].id;
          this.couponTarget = coupons[0].target;
        }
        return this._stripe.retrieveCoupon(this.couponCode.value);
      }),
      switchMap(coupon => this._stripe.redeemCoupon(this.couponCode.value, this.couponId))
    ).subscribe((coupon: any) => {
      this.loadingCoupon = false;
      this.couponCode.disable();
      if (coupon.valid && (this.couponTarget === subscription_type || this.couponTarget === 'customer')) {
        this.couponIsValid = coupon.valid;
      }
      if (!coupon.valid) {
        this._toastr.error('This coupon is invalid or it\'s already been redeemed').onHidden.subscribe(() => {
          this.couponCode.reset();
          this.couponCode.enable();
        });
      }
      if (this.couponTarget !== subscription_type && this.couponTarget !== 'customer') {
        this._toastr.error('This coupon cannot be used for this subscription type.').onHidden.subscribe(() => {
          this.couponCode.reset();
          this.couponCode.enable();
        });
      }
    }, () => {
      this.loadingCoupon = false;
      this.couponIsValid = false;
      this.couponCode.disable();
      this._toastr.error('This coupon is invalid or it\'s already been redeemed').onHidden.subscribe(() => {
        this.couponCode.reset();
        this.couponCode.enable();
      });
    });
  }

  updatePaymentMethods() {
    this.dialogRef.close();
    this.emitter.emit({status: 200});
    return this.router.navigate([Tenant.base, Tenant.account.base, Tenant.account.billing], {queryParams: {update_sca: true}});
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}

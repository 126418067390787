import { AuthService } from '@app/core/services';
import { AngularFirestore } from '@angular/fire/firestore';
import { Injectable } from '@angular/core';
import { of } from 'rxjs/internal/observable/of';
import { UserService } from '@app/services/user.service';
import * as moment from 'moment';
import * as _ from 'lodash';

export interface IChartData {
  name: string
  value_key: string
}

export interface IPaymentsData {
  total_owed_payments: number
  total_payments_overdue_2_4_weeks: number
  total_payments_overdue_2_weeks: number
  total_payments_overdue_4_weeks: number
  total_payments_received: number
}

@Injectable()
export class StatsService {
  constructor(private afs: AngularFirestore,
    private _auth: AuthService,
    private _user: UserService
  ) {
  }

  getStatsOf(filters: any, days: number = 1) {
    const endDay = this.getToday();
    const startDay = this.getToday(filters.filter, days);
    if (filters.portfolio && filters.portfolio !== 'all') {
      return this.afs.collection('stats', ref => ref.where('portfolio_id', '==', filters.portfolio)
        .where('date_created', '>=', startDay)
        .where('date_created', '<=', endDay)
        .orderBy('date_created', 'desc')).valueChanges();
    }

    const teamQuery = this._user.userReadOnlyDb.team_ids
      ? this.afs.collection('stats', ref => ref.where('team_id', 'in', this._user.userReadOnlyDb.team_ids)
        .where('date_created', '>=', startDay)
        .where('date_created', '<=', endDay)
        .orderBy('date_created', 'desc')).valueChanges() : of([]);

    // teamQuery.subscribe((query: any) => console.log({query}))

    const landlordQuery = this._user.userReadOnlyDb.team_ids ? of([]) : this.afs.collection('stats', ref => ref.where('uid', '==', this._auth.currentUserId)
      .where('date_created', '>=', startDay)
      .where('date_created', '<=', endDay)
      .orderBy('date_created', 'desc')).valueChanges();

    return this._auth.combineQueries(teamQuery, landlordQuery);

  }

  getToday(filter: any = null, numberOfDays: number = 0) {
    const date = new Date();
    const set = (numberOfDays) ? {
      hours: 0,
      minutes: 0,
      seconds: 0,
      milliseconds: 0
    } : null;
    const startOfMonth = (numberOfDays > 1 && filter === 'current_month') ? moment(date).startOf('month').toDate() : null;
    return (startOfMonth) ? moment(startOfMonth).toDate() : moment(date).set(set).subtract((numberOfDays > 1) ? numberOfDays : 0, 'days').toDate();
    // date.setHours(0, 0,0, milliSeconds);
    // return new Date(date.setDate(date.getDate() + numberOfDays));
  }

  formatChartData(paymentsData:IPaymentsData, metaData:IChartData[]) {
    const hasData = !_.isNil(paymentsData)
    return metaData.map((chartData) => {
      const dataValue = hasData ? paymentsData[chartData.value_key] || 0 : "" ;
      return { name: chartData.name, value: dataValue};
    });
  }
}

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {StepModel} from '@app/shared/_components/steppers/form-steps/_models/form-steps.model';
import {FormStepsService} from '@app/shared/_components/steppers/form-steps/form-steps.service';

@Component({
  selector: 'form-steps',
  templateUrl: './form-steps.component.html',
  styleUrls: ['./form-steps.component.scss']
})
export class FormStepsComponent implements OnInit {
  @Input() stepsArray: Array<StepModel>;    // get all Steps
  @Input() isStepValid;                     // check if step is valid (disable "Continue" button if step is not valid)
  @Output() currentIndex = new EventEmitter<number>();                                // Send index of current step
  @Output() stepCompleted = new EventEmitter<{ index: number, type: string }>();      // Send step completed state

  public activeStepIndex = 0;
  public formIsComplete = false;

  constructor(public _formSteps: FormStepsService) {
  }

  ngOnInit() {
    this.currentIndex.emit(this.activeStepIndex);
  }

  previous() {
    this.activeStepIndex = this.activeStepIndex - 1;

    this.currentIndex.emit(this.activeStepIndex);

    this.stepsArray[this.activeStepIndex].isComplete = false;
    this.stepsArray[this.activeStepIndex + 1].isActive = false;

    window.scrollTo(0, 0);
  }

  next() {
    this.activeStepIndex = this.activeStepIndex + 1;

    this.currentIndex.emit(this.activeStepIndex);
    this.stepCompleted.emit({index: this.activeStepIndex, type: 'STEP_COMPLETED'});

    this.stepsArray[this.activeStepIndex].isActive = true;
    this.stepsArray[this.activeStepIndex - 1].isComplete = true;

    window.scrollTo(0, 0);
  }

  complete() {
    this.formIsComplete = true;
    this.stepsArray[this.activeStepIndex].isComplete = true;

    this.stepCompleted.emit({index: this.activeStepIndex, type: 'FINAL_STEP_COMPLETED'});
  }

}

import * as i0 from "@angular/core";
export class IntercomService {
    constructor() { }
    initIntercom(user) {
        if (user && user.profile_data) {
            Intercom('boot', {
                app_id: 'ci0n4qi6',
                name: (user) ? user.profile_data.first_name : 'User',
                role: (user) ? user.role : null,
                email: (user) ? user.profile_data.email : null,
                phone: (user) ? user.profile_data.phone : null,
                UserId: (user) ? user.uid : null,
                hide_default_launcher: true
            });
            Intercom('update');
        }
    }
    startNewChat() {
        Intercom('showNewMessage');
    }
    updateIntercom(config) {
        Intercom('update', config);
    }
}
IntercomService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function IntercomService_Factory() { return new IntercomService(); }, token: IntercomService, providedIn: "root" });

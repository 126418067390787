
  <form [formGroup]="formGroup" (ngSubmit)="nextStep()">
    <div>
      <h2 class="text--lg">Please verify your identity</h2>
      <br>
      <p *ngIf="verificationUnverified">Please upload a scan or clear photo of either your passport or driving licence:</p>
      <ng-container *ngIf="this._user.user.verification_status === 'failed'">
        <p>Your {{verifiedDocumentType === 'passport' ? 'passport' : 'driving licence'}} verification was rejected.</p>
        <p>Contact {{client_data.company_name}} for assistance <a href="mailto:{{client_data.support_email}}">{{client_data.support_email}}</a></p>
      </ng-container>

      <ng-container *ngIf="verificationStatusPending">
        <p>We're busy confirming your identity.<br>Please continue with next steps...</p>
      </ng-container>

      <ng-container *ngIf="verificationApproved">
        <p>Your identity has been successfully verified.</p>
      </ng-container>
    </div>

    <div class="rc flex_wrap document-upload" *ngIf="this._user.user.verification_status !== 'failed'">
      <div class="cc w_100">
        <div class="action-bar">

          <label for="landlord-verify-passport-upload"
            class="verify-id__card-container"
            [hidden]="(documentType.value === 'drivers_licence' && uploadState !== '') ? 'hidden' : ''">
            <div role="button" class="btn__card" [ngClass]="{
              'btn__card--disabled': (uploadState === 'WAITING' || verificationStatusPending === true || verificationApproved === true),
              'btn__card--disabled-other': (documentType.value !== 'passport' && (uploadState === 'WAITING' || verificationStatusPending === true || verificationApproved === true))
            }">
              <div class="verify-id__card-status">
                <ng-container *ngIf="documentType.value === 'passport'; else unselectedPassport">
                  <span class="icon__add" *ngIf="(uploadState !== 'WAITING' && uploadState !== 'GREEN') && !verificationStatusPending && !verificationApproved"></span>
                  <span class="icon__tick-pending" *ngIf="verificationStatusPending"></span>
                  <span class="status__tick" *ngIf="verificationApproved"></span>
                </ng-container>
                <ng-template #unselectedPassport>
                  <span class="icon__add"></span>
                </ng-template>

                <processing-spinner
                  *ngIf="uploadState === 'WAITING' && !verificationStatusPending && !verificationApproved" color="#FFFFFF"
                  [size]="'42px'"></processing-spinner>
              </div>
              Passport
            </div>
            <input
              class="hidden"
              type="file"
              [disabled]="(uploadState === 'WAITING' || verificationStatusPending || verificationApproved)"
              id="landlord-verify-passport-upload"
              accept="image/bmp, image/tiff, image/pdf, image/jpeg, image/png"
              onclick="this.value=null;"
              (change)="documentType.setValue('passport'); uploadPictures($event)"
              [required]="true" />
          </label>


          <label for="landlord-verify-driver-upload"
            class="verify-id__card-container"
            [hidden]="(documentType.value === 'passport' && (!uploadState || uploadState !== '')) ? 'hidden' : ''">
            <div role="button" class="btn__card" [ngClass]="{
              'btn__card--disabled': (uploadState === 'WAITING' || verificationStatusPending === true || verificationApproved === true),
              'btn__card--disabled-other': (documentType.value !== 'drivers_licence' && (uploadState === 'WAITING' || verificationStatusPending === true || verificationApproved === true))
            }">
              <div class="verify-id__card-status">
                <ng-container *ngIf="documentType.value === 'drivers_licence'; else unselectedDriver">
                  <span class="icon__add" *ngIf="(uploadState !== 'WAITING' && uploadState !== 'GREEN') && !verificationStatusPending && !verificationApproved"></span>
                  <span class="icon__tick-pending" *ngIf="verificationStatusPending"></span>
                  <span class="status__tick" *ngIf="verificationApproved"></span>
                </ng-container>
                <ng-template #unselectedDriver>
                  <span class="icon__add"></span>
                </ng-template>

                <processing-spinner
                  *ngIf="uploadState === 'WAITING' && !verificationStatusPending && !verificationApproved" color="#FFFFFF"
                  [size]="'42px'"></processing-spinner>
              </div>
              Driving licence
            </div>
            <input
              class="hidden"
              type="file"
              [disabled]="(uploadState === 'WAITING' || verificationStatusPending || verificationApproved)"
              id="landlord-verify-driver-upload"
              accept="image/bmp, image/tiff, image/pdf, image/jpeg, image/png"
              onclick="this.value=null;"
              (change)="documentType.setValue('drivers_licence'); uploadPictures($event)"
              [required]="true" />
          </label>
        </div>


        <div class="margin-top--md" *ngIf="(uploadState !== 'GREEN') && !verificationStatusPending && !verificationApproved">
          <strong>Tips for being approved</strong>
          <ol>
            <li>Use a scan or a white background for best results</li>
            <li>Make sure the text is clearly visible with no blur</li>
          </ol>
        </div>
      </div>

      <bunk-dialog [component]="contentDialog" #errorDialog></bunk-dialog>

      <ng-template #contentDialog>
        <div class="verify-id__modal">
          <h2>Document upload failed</h2>
          <button class="btn__seethrough btn--round margin-top--md margin-bottom--lg" (click)="bunkDialogRef.closeAll()">Close</button>

          <p>Still failing? Contact <a href="mailto:{{client_data.support_email}}">{{client_data.support_email}}</a></p>
        </div>
      </ng-template>
    </div>

    <div class="margin-bottom--xl">
        <button *ngIf="showPrev" (click)="goBack()" data-test="backButton" class="btn__seethrough btn__form-prev">Back</button>
        <button class="btn__generic btn__form-submit btn__form-submit--align-right" type="submit" *ngIf="uploadState === 'GREEN'">
          <span>Continue</span>
        </button>
      </div>

  </form>
  
import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { Tenant, Profile } from '@env/routing';
import { BunkPassportService } from '@app/services';
import { tap, takeUntil, catchError } from 'rxjs/operators';
import { Observable, Subject, of  } from 'rxjs';
import { BunkPassportModel } from '@app/_models/bunk_passport.model';
import { TenantVerifyEventEmiterService } from '../profile-tenant.service';
import { TenantAccountStepComponent } from './tenant-account-step.component';


@Component({
  selector: 'tenant-account-credit-info',
  template: `
    <ng-container *ngIf="(passport$ | async) as passport">
      <div>
        <h2 class="text--lg">Your credit history</h2>
        <p>Please complete the forms below. This information is stored securely and we will only process this information once you have had an offer accepted on a property.</p>
      </div>

      <form class="cc w_100" [formGroup]="formGroup" (ngSubmit)="formGroup.valid && nextStep(formGroup.get('credit_info').value)">
        <form-credit-info formControlName="credit_info"></form-credit-info>

      </form>
    </ng-container>
  `,
  styleUrls: ['./tenant-account-step.component.scss'],
})
export class TenantAccountCreditInfoComponent extends TenantAccountStepComponent implements OnInit, OnDestroy {
  @Input() standAlone = false;
  public formGroup: FormGroup;
  public queryParams: string;
  public routes = {Tenant};
  public countryCodes;
  public passport$: Observable<BunkPassportModel>;

  private destroy$: Subject<boolean> = new Subject<boolean>();
  constructor(
    _verifyEvent: TenantVerifyEventEmiterService,
    private _formBuilder: FormBuilder,
    private _passport: BunkPassportService
  ) {
    super(_verifyEvent);
    this.formGroup = this.initFormGroup();

    this.passport$ = this._passport.getUsersBunkPassportById().valueChanges().pipe(
      tap((passport: any) => {
        if (passport.credit_info) {
        this.preloadFormGroup(passport.credit_info);
      }
      })
    );
  }

  initFormGroup(): FormGroup {
    return this._formBuilder.group({
      credit_info: [null]
    });
  }

  nextStep(form: any) {
    if (this.submittingData) {
      return;
    }
    this.submittingData = true;
    this.formGroup.disable();  
    this._passport.updateUserReferenceData(form)
    .pipe(
      takeUntil(this.destroy$),
      catchError(err => of([]))
    )
    .subscribe(
      res => {
        this.stepComplete();
      },
      err => {
        console.log('HTTP Error', err);
        this.stepError();
      },
      () => {
        this.formGroup.enable();
        console.log('HTTP request completed.');
      }
    );
  }

  preloadFormGroup(form: any): void {
    const item = {
        has_declared_bankruptcy: form.has_declared_bankruptcy,
        has_ccj: form.has_ccj ,
        has_criminal_history: form.has_criminal_history,
        has_confirmed_correct: form.has_confirmed_correct
    };
    this.formGroup.get('credit_info').patchValue(item);
  }

  goBack() {
    this._verifyEvent.prevStep();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

  ngOnInit() {}
}

import { HttpClient, HttpParams } from '@angular/common/http';
import { AngularFirestore } from '@angular/fire/firestore';
import { environment } from '@env/environment';
import { from as fromPromise } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "@angular/fire/firestore";
import * as i2 from "@angular/common/http";
export class AdminService {
    constructor(afs, _http) {
        this.afs = afs;
        this._http = _http;
        this.transactionCollection = afs.collection('transactions');
        this.balanceCollection = afs.collection('account_balances');
        this.referenceOutlawsCollection = afs.collection('reference_outlaws');
    }
    createDemoUser(form) {
        return this._http.post(environment.firebaseConfig.apiUrl + '/adminFirestore-adminCreateDemoUser', { form });
    }
    continueCreateDemoUser(team_id, uid) {
        return this._http.post(environment.firebaseConfig.apiUrl + '/adminFirestore-adminContinueCreateDemoUser', { team_id, uid });
    }
    removeNewDemoUser(uid) {
        console.log({ uid });
        return this._http.post(environment.firebaseConfig.apiUrl + '/adminFirestore-adminRemoveNewDemoUser', { uid });
    }
    runBalancingFormulas() {
        return this._http.post(environment.firebaseConfig.apiUrl + '/moneyhub-runTransactionBalancingFormulas', {});
    }
    updateTransaction(item, id) {
        return fromPromise(this.transactionCollection.doc(id).update(item));
    }
    getAccountBalanceDocument() {
        return this.balanceCollection.doc('client_account');
    }
    createReferenceOutlaw(refs) {
        console.log(refs, 'hiya refs');
        const reference_outlaws_id = this.afs.createId();
        const date = new Date();
        const item = {
            reference_outlaws_id,
            reference_outlaw: refs.reference_outlaw.substring(0, refs.reference_outlaw.lastIndexOf(' ')),
            user_reference_code: refs.user_reference_code.toUpperCase(),
            date_modified: date,
            date_created: date,
        };
        console.log(item, 'item');
        return fromPromise(this.referenceOutlawsCollection.doc(reference_outlaws_id).set(item));
    }
    matchTenancyToTransaction(transaction_id, tenancy_id) {
        console.log(transaction_id, tenancy_id);
        return this._http.post(environment.firebaseConfig.apiUrl + '/moneyhub-mapPaymentsByTenancy', { transaction_id, tenancy_id });
    }
    createUser(form) {
        return this._http.post(environment.firebaseConfig.apiUrl + '/adminFirestore-adminCreateUser', { form });
    }
    mapTransactionById(transaction_id) {
        const params = new HttpParams().set('id', transaction_id);
        return this._http.post(environment.firebaseConfig.apiUrl + '/transactions-httpMapTransaction', {}, { params });
    }
}
AdminService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function AdminService_Factory() { return new AdminService(i0.ɵɵinject(i1.AngularFirestore), i0.ɵɵinject(i2.HttpClient)); }, token: AdminService, providedIn: "root" });

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { InstantNewAlgoliaSearchComponent } from './instant-new-algolia-search.component';
import { MatCheckboxModule } from '@angular/material';
import { UserModule } from '@app/shared/user.module';

@NgModule({
  imports: [
    CommonModule,
    MatCheckboxModule,
    UserModule
  ],
  exports: [InstantNewAlgoliaSearchComponent],
  declarations: [InstantNewAlgoliaSearchComponent],
  providers: [],
})
export class InstantNewAlgoliaSearchModule { }

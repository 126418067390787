import { Component, Inject, ViewContainerRef, OnInit } from '@angular/core';
import { FILE_PREVIEW_TEMPLATE } from './file-preview-overlay.tokens';
import { PreviewOverlayComponent } from './preview-overlay.component';
import { TemplatePortal } from '@angular/cdk/portal';
import { FilePreviewOverlayRef } from './file-preview-overlay-ref';
import { animations } from './bunk-overlay.animations';

@Component({
  selector: 'template-preview-overlay',
  template: `
    <div class="overlay-content" 
      [@slideContent]="animationState"
      (@slideContent.start)="onAnimationStart($event)"
      (@slideContent.done)="onAnimationDone($event)">
      <ng-template [cdkPortalOutlet]="templatePortal"></ng-template>
    </div>
  `,
  styleUrls: ['./bunk-overlay.component.scss'],
  animations: [animations]
})
export class TemplatePreviewOverlayComponent extends PreviewOverlayComponent implements OnInit {
  templatePortal: TemplatePortal<any>;

  constructor(
    private viewContainerRef: ViewContainerRef,
    public dialogRef: FilePreviewOverlayRef,
    @Inject(FILE_PREVIEW_TEMPLATE) public dom: any) {
      super(dialogRef);
    }

  ngOnInit() {
    this.templatePortal = new TemplatePortal(this.dom, this.viewContainerRef);
  }
  
}


import { Component, OnInit, Optional, forwardRef, Input } from '@angular/core';
import { AbstractValueAccessor } from '@app/shared/_forms/abstract-value-accessor/abstract-value-accessor';
import { Validator, FormGroup, FormGroupDirective, FormBuilder, AbstractControl, Validators, NG_VALUE_ACCESSOR, NG_VALIDATORS} from '@angular/forms';
import { MAT_DATE_FORMATS, DateAdapter, MAT_DATE_LOCALE } from '@angular/material';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { UK_DATE_FORMAT } from '@app/_constants/date.constants';
import { TRANSLATIONS } from '@app/_constants/translations.constants';


@Component({
  selector: 'book-viewing-form',
  template: `
  <form [formGroup]="formGroup" autocomplete="none">

  <bunk-form-field direction="column" [showLabel]="showLabel">
    <label bunk-label for="viewing-date">Pick a date</label>
    <mat-form-field bunk-input appearance="outline">
      <mat-label *ngIf="!showLabel">Pick a date</mat-label>
      <input id="viewing-date"
            formControlName="viewing_date" matInput
            (click)="pickDate.open()"
            placeholder="Pick a date"
            [matDatepicker]="pickDate"
            [min]="minDate">
      <mat-datepicker-toggle matSuffix [for]="pickDate" ></mat-datepicker-toggle>
      <mat-datepicker #pickDate></mat-datepicker>
      <mat-error>Please pick a date</mat-error>
    </mat-form-field>
  </bunk-form-field>

  <bunk-form-field direction="column" [showLabel]="showLabel">
    <label bunk-label >Select a time (24-hour clock)</label>
    <div bunk-input>
      <time-picker formControlName="viewing_time"
                  [minHours]="7"
                  [maxHours]="20"
                  [submitAttempted]="submitAttempted"
      ></time-picker>
    </div>
  </bunk-form-field>

  <bunk-form-field direction="column" [showLabel]="showLabel" *ngIf="showDescription">
    <label bunk-label>Add any comments for the {{landlord}} (optional)</label>
    <mat-form-field  bunk-input appearance="outline">
      <mat-label *ngIf="!showLabel">Add any comments for the {{landlord}} (optional)</mat-label>
      <textarea rows="6" formControlName="viewing_request_message" matInput placeholder="Add any comments for the {{landlord}} (optional)"></textarea>
    </mat-form-field>
  </bunk-form-field>

</form>

  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BookViewingSharedFormComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => BookViewingSharedFormComponent),
      multi: true
    },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },
    { provide: MAT_DATE_FORMATS, useValue: UK_DATE_FORMAT }
  ]
  // styleUrls: ['./name.component.scss']
})
export class BookViewingSharedFormComponent extends AbstractValueAccessor implements OnInit, Validator {
  @Input()showLabel = true;
  @Input()showDescription = true;
  public submitAttempted = false;
  public formGroup: FormGroup;
  public minDate = new Date();
  public landlord = TRANSLATIONS.landlord;

  constructor(
    @Optional() public _reactiveForm: FormGroupDirective,
    private _formBuilder: FormBuilder,
  ) {
    super();
  }


  ngOnInit() {
    this.formGroup = this.initFormGroup();
      // Force validate the form when parent form submits, loop all controls
    this._reactiveForm.ngSubmit.subscribe((data: Event) => {
      this.submitAttempted = true;
      const controls = this.formGroup.controls;
      for (const control in controls) {
        if (controls.hasOwnProperty(control)) {
          this.formGroup.controls[control].markAsTouched();
        }
      }
    });
  }

  initFormGroup(): FormGroup {
    const formGroup: FormGroup = this._formBuilder.group({
      viewing_date: [null, Validators.required],
      viewing_time: [null, Validators.required],
      viewing_request_message: '',
    });
    return new FormGroup(formGroup.controls);
  }

  public onTouched: () => void = () => { };

  writeValue(val: any): void {
    super.writeValue(val);
    if (val) {
      this.formGroup.patchValue(val, { emitEvent: false });
    }
  }
  registerOnChange(fn: any): void {
    this.formGroup.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.formGroup.disable() : this.formGroup.enable();
  }

  validate(control: AbstractControl) {
    return this.formGroup.valid ? null : { invalidForm: { valid: false, message: 'BookViewingFormComponent > formGroup fields are invalid' } };
  }

}

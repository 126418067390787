import { Directive, HostListener, Input } from '@angular/core';
import { ViewingService } from '@app/services';
import { finalize } from 'rxjs/operators';

@Directive({selector: '[addToCalendarclickCatcher]'})

export class AddToCalendarClickCatcherDirective {
@Input() viewingId: string;
public addeventatc: any;
public calendarItems = [
    { calString: 'addeventatc1-appleical', newString: 'apple'},
    { calString: 'addeventatc1-google', newString: 'google'},
    { calString: 'addeventatc1-office365', newString: 'office365'},
    { calString: 'addeventatc1-outlook', newString: 'outlook'},
    { calString: 'addeventatc1-outlookcom', newString: 'outlook.com'},
    { calString: 'addeventatc1-yahoo', newString: 'yahoo'},
];

constructor( private _viewings: ViewingService) {}

@HostListener('click', ['$event.target.id']) onClick(id: any) {
    if (id !== 'addeventatc1') {
      return this.createCalendarMetadata(id);
    }
  } 

  createCalendarMetadata(id: string) {
    const calendar_type = this.formatIdName(id);
    return this._viewings.createMetaForViewings(calendar_type, this.viewingId).pipe(
      finalize(() =>  setTimeout(function() { this.addeventatc.refresh(); }, 200))
    );
  }

  formatIdName = (id: string): string => this.calendarItems.find((cal: any) => cal.calString === id).newString;
}

<ng-container *ngIf="userReadOnly$ | async"></ng-container>
<div *ngIf="(user$ | async) as user" class="app-root">
  <ng-container [ngSwitch]="user.role">
    <ng-container *ngSwitchCase="'landlord'">
      <ng-container *ngTemplateOutlet="authUsersTemplate; context: {user: user}"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'tenant'">
      <ng-container *ngTemplateOutlet="authUsersTemplate; context: {user: user}"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'guest_landlord'">
      <ng-container *ngTemplateOutlet="guestUsersTemplate"></ng-container>
    </ng-container>
    <ng-container *ngSwitchDefault>
      <ng-container *ngTemplateOutlet="guestTemplate"></ng-container>
    </ng-container>
  </ng-container>
</div>

<ng-template #guestUsersTemplate>
  <nav-top guest="true"></nav-top>
  <div class="template__body" [ngClass]="{'template__body--authenticated': _auth.authenticated}">
    <router-outlet></router-outlet>
  </div>
</ng-template>

<ng-template #guestTemplate>
  <div class="template__body" [ngClass]="{'template__body--authenticated': _auth.authenticated}">
    <router-outlet></router-outlet>
  </div>
</ng-template>

<ng-template #authUsersTemplate let-user="user">
  <screen-detection (resized)="isDesktop = $event.isDesktop"></screen-detection>
  <note-bar [(notebarNotificationOpen)]="notebarNotificationOpen">
    <notifications *ngIf="notebarNotificationOpen" (openVerificationDialog)="verificationDialogOpen = $event"
      (closeNotifications)="notebarNotificationOpen = $event"></notifications>
  </note-bar>
  <ng-container *ngIf="bunkPassportVersion$ | async"></ng-container>
  <nav-top *ngIf="_auth.authenticated" [navItems]="navbarList" [(notebarNotificationOpen)]="notebarNotificationOpen">

    <ng-container *ngIf="!hideStaticButtons">
      <button class="btn__seethrough btn--full-width mr_5" *ngIf="isDesktop && user.isLandlord"
        [routerLink]="['/', routes.Documents.base, routes.Documents.new_tenancy]"
      >
        {{( isNrla ? 'Add tenancy' : 'Create tenancy')}}
      </button>
      <button class="btn__seethrough btn--full-width" *ngIf="showAddProperty(isDesktop , user.isLandlord)"
        [routerLink]="['/' + routes.Landlord.base, routes.Landlord.property_type]"
      >
        Add property
      </button>
    </ng-container>

  </nav-top>
  <div class="template__body" [ngClass]="{'template__body--authenticated': _auth.authenticated}">
    <ng-container *ngIf="_auth.authenticated">
      <!--          <ng-container *ngIf="chatCount$ | async as chatCount"></ng-container>-->
      <ng-container *ngIf="savePropertiesCount$ | async as savePropertiesCount"></ng-container>
      <ng-container *ngIf="{sidebar: sidebarList$ | async} as sidebarList">
        <nav-sidebar [navItems]="sidebarList.sidebar">

          <ng-container *ngIf="!hideStaticButtons">
            <button class="btn__cta btn--full-width mb-3" *ngIf="showAddProperty(!isDesktop , user.isLandlord)"
              [routerLink]="['/' + routes.Landlord.base, routes.Landlord.property_type]"
            >
              Add property
            </button>

            <button class="btn__cta btn--full-width mb-3" *ngIf="!isDesktop && user.isLandlord"
              [routerLink]="['/', routes.Documents.base, routes.Documents.new_tenancy]"
            >
              {{( isNrla ? 'Add tenancy' : 'Create tenancy')}}
            </button>
          </ng-container>
        </nav-sidebar>
      </ng-container>
    </ng-container>
    <div class="lateral-container" [ngClass]="{'lateral-container--authenticated': _auth.authenticated}">
      <router-outlet></router-outlet>
    </div>
  </div>
</ng-template>

import {Injectable} from '@angular/core';

import {AuthService} from '@app/core/services/auth.service';
import {AngularFirestore, AngularFirestoreCollection} from '@angular/fire/firestore';

import { environment } from '@env/environment';
import { HttpClient } from '@angular/common/http';
import * as moment from 'moment';
import { map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

@Injectable()
export class ExpensesService {
  public tenancyCollection: AngularFirestoreCollection<any>;
  public rentExtensionsCollection: AngularFirestoreCollection<any>;
  public expensesTypeCollection: AngularFirestoreCollection<any>;

  constructor(
    private readonly afs: AngularFirestore,
    private _auth: AuthService,
    private _http: HttpClient
  ) {
   
  }


  userCreateExpense(data: any) {
    return this._http.post(environment.firebaseConfig.apiUrl + '/expenses-userCreateExpense', {...data, due_date: moment(data.due_date), timestamp_paid: (data.timestamp_paid) ? moment(data.timestamp_paid) : null, creator_uid: this._auth.currentUserId});
  }


}

 import { CanActivate, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Shared } from '@env/routing';
import { environment } from '@env/environment';

declare global {
  interface Document {
      documentMode?: any;
  }
}

@Injectable()
export class InternetExplorerGuard implements CanActivate {
  isIE = /*@cc_on!@*/!!document.documentMode;

  constructor(private router: Router) {}

  canActivate() {
    if (this.isIE && environment.production) {
      this.router.navigate(['/' + Shared.ie_user]);
    } else {
      return !this.isIE;
    }
  }
}

import {Injectable} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {AngularFireAuth} from '@angular/fire/auth';
import {environment} from '@env/environment';
import {AuthService} from '@app/core/services/auth.service';
import {HttpClient} from '@angular/common/http';
import {UserService} from '@app/services/user.service';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';

const headers = {
  'Content-Type': 'application/json',
  'Access-Control-Allow-Origin': '*'
};

@Injectable()
export class StripeService {
  constructor(
    private afs: AngularFirestore,
    private afAuth: AngularFireAuth,
    private auth: AuthService,
    private http: HttpClient,
    private _user: UserService
  ) {
  }

  loadConnectVerificationModal: BehaviorSubject<any> = new BehaviorSubject(false);

  // create setupIntent
  createSetupIntent() {
    return this.http.get(
      `${environment.firebaseConfig.apiUrl}/stripe-setupIntent`,
      {headers: headers}
    );
  }

  // for one-off payments
  createTransaction(
    amount: number,
    card_id: string,
    landlord_id: string,
    tenancy_id: string,
    type: string,
    payment_ids: Array<string>
  ) {
    const id = this.afs.createId();
    const date = new Date();
    const transaction = {
      date_modified: date,
      date_created: date,
      amount: amount,
      card_id: card_id,
      landlord_id: landlord_id,
      user_id: this.auth.currentUserId,
      tenancy_id: tenancy_id,
      customer_id: this._user.userDb.payments_data.customer_id,
      status: 'pending',
      payment_reference: '',
      payment_ids: payment_ids,
      transaction_id: id,
      type
    };

    return this.afs.collection('transactions').doc(id).set(transaction, {merge: true})
      .then(() => {
        console.log({id});
        return id;
      });
  }

  processPayment(id: string, landlordId: string) {
    return this.http.post(
      `${environment.firebaseConfig.apiUrl}/stripe-createPaymentIntent`,
      {
        transaction_id: id,
        landlord_id: landlordId
      },
      {headers: headers}
    );
  }

  updateCustomerCard(cardId: string, item: any) {
    return this.http.post(
      `${environment.firebaseConfig.apiUrl}/stripe-updateCard`,
      {
        card_id: cardId,
        data: item
      },
      {headers: headers}
    );
  }

  deleteAllCustomerCards() {
    return this.http.delete(
      `${environment.firebaseConfig.apiUrl}/stripe-deleteAllCards`,
      {headers: headers}
    );
  }

  attachPaymentMethodToCustomer(paymentMethodId: string) {
    return this.http.post(
      `${environment.firebaseConfig.apiUrl}/stripe-attachPaymentMethod`,
      {
        payment_method_id: paymentMethodId
      },
      {headers: headers}
    );
  }

  /**
  * @deprecated
  * */
  attachCardToCustomer(token_id: string) {
    return this.http.post(
      `${environment.firebaseConfig.apiUrl}/stripe-createSource`,
      {
        token_id: token_id
      },
      {headers: headers}
    );
  }

  deleteCard(cardId: string) {
    return this.http.post(
      `${environment.firebaseConfig.apiUrl}/stripe-deleteCard`,
      {
        card_id: cardId
      },
      {headers: headers}
    );
  }

  retrieveCoupon(couponCode) {
    return this.http.post(`${environment.firebaseConfig.apiUrl}/stripe-retrieveCoupon`, {coupon: couponCode}, {
      headers: headers,
      observe: 'response'
    });
  }

  redeemCoupon(couponCode: string, couponId: string) {
    return this.http.post(`${environment.firebaseConfig.apiUrl}/stripe-redeemCoupon`, {
      coupon_code: couponCode,
      coupon_id: couponId
    }, {headers: headers, observe: 'response'});
  }

  /* LANDLORD */

  /// Create connected account for landlord
  createCustomAccount(item: any) {
    console.log(item);
    const {bank_account} = item;
    return this.http.post(
      `${environment.firebaseConfig.apiUrl}/stripe-createLandlordAccount`,
      {type: bank_account.account_holder_type, name: bank_account.account_holder_name},
      {headers: headers}
    );
  }

  addPayout(acct_id: string, token_id: string, defaultForCurrency: boolean) {
    return this.http.post(
      `${environment.firebaseConfig.apiUrl}/stripe-addExternalAccount`,
      {
        acct_id: acct_id,
        token: token_id,
        default_for_currency: defaultForCurrency
      },
      {headers: headers}
    );
  }

  getAllExternalAccounts() {
    return this._user.user$.pipe(
      switchMap((user) => {
        if (user.payments_data.account_id) {
          return this.http.get(
            `${environment.firebaseConfig.apiUrl}/stripe-getExternalAccounts`,
            {headers: headers}
          );
        } else {
          return of(null);
        }
      })
    );
  }

  deleteExternalAccount(acct_id: string, ba_id: string) {
    return this.http.post(
      `${environment.firebaseConfig.apiUrl}/stripe-deleteExternalAccount`,
      {acct_id: acct_id, ba_id: ba_id},
      {headers: headers}
    );
  }

  getConnectedAccount(acct_id: string) {
    return this.http.get(
      `${environment.firebaseConfig.apiUrl}/stripe-getConnectAccount`,
      {headers: headers}
    );
  }

  uploadDocument(filename: string) {
    return this.http.post(
      `${environment.firebaseConfig.apiUrl}/stripe-uploadDocument`,
      {
        filename: filename
      },
      {headers: headers}
    );
  }

  getAccountLink() {
    return this.http.get(
      `${environment.firebaseConfig.apiUrl}/stripe-getAccountLink`,
      {headers}
    )
  }

  /* END LANDLORD */

  /* TENANT */

  createStripeCustomer(token_id: string) {
    return this.http.post(
      `${environment.firebaseConfig.apiUrl}/stripe-createCustomer`,
      {payment_method_id: token_id},
      {headers: headers}
    );
  }

  /**
   * @deprecated
   * */
  createStripeProduct(productName: string, tenancyId: string) {
    return this.http.post(
      `${environment.firebaseConfig.apiUrl}/stripe-createProduct`,
      {name: productName, tenancy_id: tenancyId},
      {headers: headers, observe: 'response'}
    );
  }

  /**
   * @deprecated
   * */
  createStripePlan(
    product_id: string,
    currency: string,
    interval: string,
    interval_count: number,
    amount: number,
    nickname: string
  ) {
    return this.http.post(
      `${environment.firebaseConfig.apiUrl}/stripe-createPlan`,
      {
        product_id: product_id,
        currency: currency,
        interval: interval,
        interval_count: interval_count,
        amount: amount,
        nickname: nickname
      },
      {headers: headers, observe: 'response'}
    );
  }

  /**
   * @deprecated
   * */
  subscribeCustomerToPlan(planId: string, cardId: string, type: string) {
    const body = {plan_id: planId, card_id: cardId, subscription_type: type};
    return this.http.post(
      `${
        environment.firebaseConfig.apiUrl
        }/stripe-subscribeCustomerToPlan`,
      body,
      {headers: headers, observe: 'response'}
    );
  }

  createSubscription(tenancy_id, card_id, subscription_type) {
    const body = {tenancy_id, card_id, subscription_type};
    console.log(body);
    return this.http.post(`${
        environment.firebaseConfig.apiUrl
      }/stripe-createSubscription`,
      body,
      {headers: headers, observe: 'response'})
  }

  cancelSubscription(id, subscriptionId) {
    return this.http.post(
      `${environment.firebaseConfig.apiUrl}/stripe-cancelSubscription`,
      {
        id: id,
        subscription_id: subscriptionId
      },
      {headers: headers, observe: 'response'}
    );
  }

  getStripeProducts(): Observable<any[]> {
    return this.http.get<any[]>(
      `${environment.firebaseConfig.apiUrl}/stripe-getProductsWithPrices`,
      {headers: headers, observe: 'response'}
    ).pipe(map(data => data.body));
  }

  createCheckoutForProperty(products: any[], propertyId: string, property_listing_id: string): Observable<any> {
    const body = {
      products,
      propertyId,
      property_listing_id,
    }
    return this.http.post<any>(
      `${environment.firebaseConfig.apiUrl}/stripe-createCheckoutForProperty`,
      body,
      {headers: headers, observe: 'response'}
    ).pipe(map(data => data.body));
  }

  getCheckoutSession(id: string): Observable<any> {
    return this.http.get<any>(
      `${environment.firebaseConfig.apiUrl}/stripe-getCheckoutSession?id=${id}`,
      {headers: headers, observe: 'response'}
    ).pipe(map(data => data.body));
  }

  /* END TENANT */
}

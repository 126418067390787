import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'partner-card',
  template: `
  <div class="partner-card">
    <profile-avatar [profileImgUrl]="profileImgUrl" profileName="{{partnerName}}" class="flex align-items-center"></profile-avatar>
      <span class="partner-bio-container">
        <strong class="margin-bottom--xs">References completed by our partner {{partnerName}}</strong>
        <p>If you wish to contact vouch about a report or update, please state the reference code of the tenant(s) in question.</p>
        <p class="contact">
          <span class="margin-bottom--xs"><mat-icon svgIcon="phone-call" class="contact-icon"></mat-icon>&nbsp;<a
          href="{{'tel:' + contact}}" target="_blank">{{contact}}</a> </span>
          <span class="margin-bottom--xs">
            <mat-icon svgIcon="mail" class="contact-icon"></mat-icon>&nbsp;<a href="mailto:{{mail}}" target="_blank">{{mail}}</a></span></p>
      </span>
    </div>
`,
  styleUrls: ['./property-owner-card.component.scss']
})
export class PartnerCardComponent implements OnInit {
  @Input() partnerName: string = '';
  @Input() contact: string = '';
  @Input() mail: string = '';
  @Input() profileImgUrl:string='';

  constructor(

  ) { }

  ngOnInit() {


  }



}

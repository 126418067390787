
  <form [formGroup]="creditInfoForm" autocomplete="none">
    <bunk-form-field *ngIf="getFormControl('has_declared_bankruptcy')">
    <label bunk-label >Have you ever been declared bankrupt?</label>
    <div bunk-input>
      <mat-radio-group formControlName="has_declared_bankruptcy" class="flex flex-row margin-bottom">
        <mat-radio-button [value]="true" class="bunk-outlined-radio mr_5" [ngClass]="{'bunk-outlined-radio--selected': getFormControl('has_declared_bankruptcy').value === true}" >Yes</mat-radio-button>
        <mat-radio-button [value]="false" class="bunk-outlined-radio" [ngClass]="{'bunk-outlined-radio--selected': getFormControl('has_declared_bankruptcy').value === false}">No</mat-radio-button>
      </mat-radio-group>
      <mat-error class="bunk-mat-error-font-match"*ngIf="getFormControl('has_declared_bankruptcy').getError('required') && submitAttempted">Please select</mat-error>

    </div>
    </bunk-form-field>

    <bunk-form-field *ngIf="getFormControl('has_ccj')">
    <label bunk-label>Have you ever had a County Court Judgment (CCJ), been declared bankrupt or insolvent?</label>
    <div bunk-input>
      <mat-radio-group formControlName="has_ccj" class="flex flex-row margin-bottom">
        <mat-radio-button [value]="true" class="bunk-outlined-radio mr_5" [ngClass]="{'bunk-outlined-radio--selected': getFormControl('has_ccj').value === true}" >Yes</mat-radio-button>
        <mat-radio-button [value]="false" class="bunk-outlined-radio" [ngClass]="{'bunk-outlined-radio--selected': getFormControl('has_ccj').value === false}">No</mat-radio-button>
      </mat-radio-group>
      <mat-error class="bunk-mat-error-font-match"*ngIf="getFormControl('has_ccj').getError('required') && submitAttempted">Please select</mat-error>
    </div>
    </bunk-form-field>

    <bunk-form-field *ngIf="getFormControl('has_criminal_history')">
    <label bunk-label>Have you got a criminal history?</label>
    <div bunk-input>
      <mat-radio-group formControlName="has_criminal_history" class="flex flex-row margin-bottom">
        <mat-radio-button [value]="true" class="bunk-outlined-radio mr_5" [ngClass]="{'bunk-outlined-radio--selected': getFormControl('has_criminal_history').value === true}" >Yes</mat-radio-button>
        <mat-radio-button [value]="false" class="bunk-outlined-radio" [ngClass]="{'bunk-outlined-radio--selected': getFormControl('has_criminal_history').value === false}">No</mat-radio-button>
      </mat-radio-group>
      <mat-error class="bunk-mat-error-font-match"*ngIf="getFormControl('has_criminal_history').getError('required') && submitAttempted">Please select</mat-error>

    </div>
    </bunk-form-field>

    <bunk-form-field *ngIf="getFormControl('has_confirmed_correct')">
    <div bunk-input>
        <mat-checkbox class="mr_4" [value]="true" formControlName="has_confirmed_correct"></mat-checkbox>
        <mat-label>The information I have provided is true and accurate and I consent for {{client_data.company_name}} to use my data in accordance with their
        <a href="{{client_data.privacy_policy_url}}" target="_blank" class="link link_2">Privacy Policy</a></mat-label>
        <mat-error class="bunk-mat-error-font-match"*ngIf="getFormControl('has_confirmed_correct').getError('required') && submitAttempted">Please select</mat-error>

    </div>
    </bunk-form-field>
  </form>

  
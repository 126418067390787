import {Injectable} from '@angular/core';
import {FeedbackModel} from '@app/core-tenant/account/_models/feedback.model';
import {Observable, from as fromPromise} from 'rxjs';
import {AuthService} from '@app/core/services/auth.service';
import {UserService} from '@app/services/user.service';
import {AngularFirestore, AngularFirestoreCollection} from '@angular/fire/firestore';

@Injectable()
export class FeedbackService {
  public feedbackCollection: AngularFirestoreCollection<any>;
  public inviteCollection: AngularFirestoreCollection<any>;
  public accountCancellationCollection: AngularFirestoreCollection<any>;

  constructor(private afs: AngularFirestore,
              private _auth: AuthService,
              private _user: UserService) {
    this.feedbackCollection = this.afs.collection<any>('feedback');
    this.inviteCollection = this.afs.collection<any>('invites');
    this.accountCancellationCollection = this.afs.collection<any>('accountCancellations');
  }

  createFeedbackDocument(feedback): Observable<any> {
    const feedback_id = this.afs.createId();
    const item: FeedbackModel = {
      date_created: new Date(),
      sender_id: this._auth.currentUser.uid,
      feedback_id: feedback_id,
      rating: feedback.rating,
      feedback_reason: feedback.feedback_reason,
      description: feedback.description
    };
    return fromPromise(this.feedbackCollection.doc(feedback_id).set(item));
  }

  createAccountCancellationDocument(description): Observable<any> {
    const id = this.afs.createId();
    const item = {
      sender_id: this._auth.currentUser.uid,
      cancellation_id: id,
      description: description,
      date_created: new Date()
    };
    return fromPromise(this.accountCancellationCollection.doc(id).set(item));
  }

  createInviteDocument(invite: any, device: any): Observable<any> {
    const id = this.afs.createId();
    const item = {
      invites_id: id,
      sender_id: this._auth.currentUser.uid,
      sender_name: `${this._user.userDb.profile_data.first_name} ${this._user.userDb.profile_data.last_name} `,
      date_sent: new Date(),
      receiver_name: invite.name,
      receiver_email: invite.email,
      device
    };
    return fromPromise(this.inviteCollection.doc(id).set(item));
  }

}

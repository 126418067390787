import { Component, OnInit, Input, forwardRef, Optional } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormGroupDirective, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validator, Validators } from '@angular/forms';
import { AuthService } from '@app/core/services';
import { BunkPassportService, UserService } from '@app/services';
import { AbstractValueAccessor } from '@app/shared/_components/searches/instant-search/instant-search.utils';
import { BunkPassportModel } from '@app/_models/bunk_passport.model';
import { Observable, combineLatest, of } from 'rxjs';
import { map } from 'rxjs/operators';
import * as moment from 'moment';
import {tap} from 'rxjs/internal/operators/tap';
import { environment } from '@env/environment';


@Component({
  selector: 'application-form-overview',
  template: `
  <form [formGroup]="applicationForm" *ngIf="(combined$ | async) as combined" autocomplete="none">

    <form-info *ngIf="formFields.info" formControlName="info" [requiredFields]="formFields.info"  [submitAttempted]="submitAttempted"></form-info>

    <form-search-details *ngIf="formFields.search_details" formControlName="search_details" [requiredFields]="{search_details: formFields.search_details, university_details: formFields.university_details}" [submitAttempted]="submitAttempted"></form-search-details>

    <form-identity-check *ngIf="formFields.identity && _auth.authenticated" formControlName="identity" [requiredFields]="formFields.identity" [form]="formGroup" [type]="type" [submitAttempted]="submitAttempted"></form-identity-check>

    <form-employment-details *ngIf="formFields.employment_details" formControlName="employment_details" [requiredFields]="formFields.employment_details" [submitAttempted]="submitAttempted"></form-employment-details>

    <form-previous-tenancy *ngIf="formFields.previous_tenancy"  formControlName="previous_tenancy" [requiredFields]="formFields.previous_tenancy" [submitAttempted]="submitAttempted" ></form-previous-tenancy>

    <form-guarantor-details *ngIf="formFields.guarantor_details"  formControlName="guarantor_details" [requiredFields]="formFields.guarantor_details" [submitAttempted]="submitAttempted" [showUKGuarantor]="hideUKGuarantor"></form-guarantor-details>

    <form-bank-details *ngIf="formFields.bank_details" formControlName="bank_details" [requiredFields]="formFields.bank_details" [submitAttempted]="submitAttempted"></form-bank-details>

    <form-credit-info *ngIf="formFields.credit_info" formControlName="credit_info" [requiredFields]="formFields.credit_info" [submitAttempted]="submitAttempted"></form-credit-info>

  </form>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ApplicationFormOverviewComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => ApplicationFormOverviewComponent),
      multi: true
    }
  ]
})
export class ApplicationFormOverviewComponent extends AbstractValueAccessor implements OnInit, Validator{
  @Input() formFields: any;
  @Input() type: string;
  private _submitAttempted = false;
  applicationForm: FormGroup;

  public passport$: Observable<BunkPassportModel>;
  public employerReferences$: Observable<any>;
  public landlordReferences$: Observable<any>;
  public guarantorReferences$: Observable<any>;
  public universityReferences$: Observable<any>;
  public combined$: Observable<any>;
  public requiredFields$: Observable<any>;
  public details = null;
  public hideUKGuarantor = environment.firebaseConfig.projectId !== 'client-campus-key';


  @Input('submitAttempted') set submitAttempted(boo: boolean) {
    this._submitAttempted = boo;
    if (boo) {
      this.dirtyFormGroup();
    }
  }
  get submitAttempted(): boolean {
    return this._submitAttempted;
  }


  constructor(
    @Optional() public _reactiveForm: FormGroupDirective,
    public _auth: AuthService,
    private _user: UserService,
    private _passport: BunkPassportService,
    private _formBuilder: FormBuilder,
  ) {
    super();
  }

  ngOnInit(): void {
    this.applicationForm = this.toFormGroupObject(this.formFields);
    if  (this.applicationForm.get('university_details'))  {
      this.applicationForm.get('university_details').setValidators(null);
      this.applicationForm.get('university_details').updateValueAndValidity();
    }

    this._reactiveForm.ngSubmit.subscribe((data: Event) => {
    this.submitAttempted = true;
    const controls = this.applicationForm.controls;
    for (const control in controls) {
        if (controls.hasOwnProperty(control)) {
          this.applicationForm.controls[control].markAsTouched();
        }
      }
    });



    if (this._auth.authenticated) {

      this.passport$ = this._passport.getUsersBunkPassportById().valueChanges();

      this.employerReferences$ = this._passport.getBunkReferencesById('employer').valueChanges().pipe(
        map((references: any) => (references.length > 0) ? references.filter((ref: any) => ref.status !== 'archived' && !ref.is_deleted) : of({})));

      this.landlordReferences$ = this._passport.getBunkReferencesById('previous_tenancy').valueChanges().pipe(
        map((references: any) => (references.length > 0) ? references.filter((ref: any) => ref.status !== 'archived' && !ref.is_deleted) : of({})));

      this.universityReferences$ = this._passport.getBunkReferencesById('university').valueChanges().pipe(
        tap((res: any) => console.log({res})),
        map((references: any) => (references.length > 0) ? references.filter((ref: any) => ref.status !== 'archived' && !ref.is_deleted) : of({})));

      this.guarantorReferences$ = this._passport.getBunkReferencesById('guarantor').valueChanges().pipe(
        map((references: any) => (references.length > 0) ? references.filter((ref: any) => ref.status !== 'archived' && !ref.is_deleted) : of({})));

      this.combined$ = combineLatest([this.universityReferences$, this.employerReferences$, this.guarantorReferences$, this.landlordReferences$, this.passport$]).pipe(
        map(([uni, employer, guarantor, landlord, passport]) => {

          if (passport && passport.personal_details && this.formFields.info ) {
            const personal_details = passport.personal_details;
            personal_details.address_details ? personal_details.address_details = {
              ...personal_details.address_details,
              country: personal_details.address_details.country === 'England' ? 'United Kingdom' : personal_details.address_details.country
            } : null;

            const detailItems = {
              ...personal_details,
              dob: personal_details.dob ? personal_details.dob.toDate() : null,

            };
            this.details = detailItems;
            this.applicationForm.get('info').patchValue(detailItems);

          }

          if (passport.search_preferences && this.formFields.search_details) {
            this.applicationForm.get('search_details').patchValue({
              ...passport.search_preferences,
              expected_move_in_date: passport.search_preferences.expected_move_in_date ? passport.search_preferences.expected_move_in_date.toDate() : null
            });
          }

          if (passport.credit_info && this.formFields.credit_info) {
            this.applicationForm.get('credit_info').patchValue(passport.credit_info);
          }

          console.log(uni, 'hello uni ');
          if (uni.length > 0 && this.formFields.university_details) {
            uni[0].reference_data = {
              ...uni[0].reference_data,
              bunk_passport_reference_id: uni[0].bunk_passport_reference_id
            };
            this.applicationForm.get('search_details').patchValue({...this.applicationForm.get('search_details').value, university_details: uni[0].reference_data});
          }

          if (employer.length > 0 && this.formFields.employment_details) {
            const data = employer[0].reference_data;
            const employerItem = {
              ...data,
              bunk_passport_reference_id: employer[0].bunk_passport_reference_id,
              referee_phone : {
                phone_number : data.referee_phone ? data.referee_phone : null,
                area_code: data.referee_country_code ? data.referee_country_code  : null,
              },
              start_date: data.start_date ? data.start_date.toDate() : null

            };
              this.applicationForm.get('employment_details').patchValue(employerItem);
          }


          if (guarantor.length > 0 && this.formFields.guarantor_details) {
            const data = guarantor[0].reference_data;
            if (guarantor.length > 0 ) {

              const guarantorItem = {
                ...data,
                bunk_passport_reference_id: guarantor[0].bunk_passport_reference_id,
                has_uk_guarantor: passport.has_uk_guarantor,
                referee_dob: data.referee_dob ? data.referee_dob.toDate() : null,
                referee_phone : {
                  phone_number : data.referee_phone ? data.referee_phone : null,
                  area_code: data.referee_country_code ? data.referee_country_code  : null,
                },
              };

              this.applicationForm.get('guarantor_details').patchValue(guarantorItem);
            }

          }

          if (!guarantor.length && this.applicationForm.get('guarantor_details') && passport.has_uk_guarantor === true
          || !guarantor.length && this.applicationForm.get('guarantor_details') && passport.has_uk_guarantor === false) {
            this.applicationForm.get('guarantor_details').patchValue({has_uk_guarantor: passport.has_uk_guarantor});
          }

          if (landlord.length > 0 && this.formFields.previous_tenancy) {
            const data = landlord[0].reference_data;

            const landlordItem = {
              ...data,
              bunk_passport_reference_id: landlord[0].bunk_passport_reference_id,
              referee_phone : {
                phone_number : data.referee_phone ? data.referee_phone : null,
                area_code: data.referee_country_code ? data.referee_country_code  : null,
              },
              end_of_tenancy_date: data.end_of_tenancy_date ? data.end_of_tenancy_date.toDate() : null
            };

            this.applicationForm.get('previous_tenancy').patchValue(landlordItem);
          }
          if (this.formFields.identity) {
            passport.verification_status === 'approved' ? this.applicationForm.get('identity').patchValue(passport.verification_status) : null;
          }

          if (this.formFields.bank_details) {
            this.applicationForm.get('bank_details').patchValue(this._user.userReadOnlyDb.bank_details);
          }
          return {
            uni, employer, guarantor, landlord, passport
          };

        }));

    }
  }

  public onTouched: () => void = () => { };

  writeValue(val: any): void {
    super.writeValue(val);
    if (val) {
      this.applicationForm.patchValue(val, { emitEvent: false });
    }
  }

  registerOnChange(fn: any): void {
    this.applicationForm.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.applicationForm.disable() : this.applicationForm.enable();
  }

  validate() {
    return this.applicationForm.valid ? null : { invalidForm: { valid: false, message: 'ApplicationFormOverviewComponent > formGroup fields are invalid' } };
  }

  toFormGroupObject(fieldObject) {
    const array = Object.keys(fieldObject);
    const group: any = {};
    array.map((field) => {
      group[field] = new FormControl(null, Validators.required);
    });
    return  this._formBuilder.group(group);

  }

  dirtyFormGroup() {
    const controls = this.applicationForm.controls;
    for (const control in controls) {
      if (controls.hasOwnProperty(control)) {
        this.applicationForm.controls[control].markAsTouched();
      }
    }
  }
}

<section class="main__wrapper">
  <div class="property__wrapper main__content card__base card__base--mb-intercom">
    <form [formGroup]="formGroup" autocomplete="none">
      <select-team formControlName="team_id" [submitAttempted]="submitAttempted"></select-team>
      <h1 class="text--lg margin-bottom--md">Create property</h1>
      <div>
        <ideal-postcodes-autocomplete labelText="Postcode / Address Lookup" appearanceType="outline"
          (selectedAddress)="autocompleteAddress($event)">
        </ideal-postcodes-autocomplete>
      </div>
      <div formGroupName="address" *ngIf="showAddress">
        <div class=" property__form-field flex flex-row flex-wrap justify-between">
          <mat-form-field appearance="outline" class="property__form-field__item ">
            <mat-label>Street address</mat-label>
            <input matInput type="text" formControlName="first_line_address" />
            <mat-error *ngIf="address.get('first_line_address').invalid">Please enter the street address</mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline" class="property__form-field__item">
            <mat-label>Street address 2 (optional)</mat-label>
            <input matInput type="text" formControlName="second_line_address" />
          </mat-form-field>
        </div>
        <div class=" property__form-field flex flex-row flex-wrap justify-between">
          <mat-form-field appearance="outline" class="property__form-field__item ">
            <mat-label>City</mat-label>
            <input matInput type="text" formControlName="city" />
            <mat-error *ngIf="address.get('city').invalid">Please enter the city</mat-error>
          </mat-form-field>

          <mat-form-field appearance="outline" class="property__form-field__item">
            <mat-label>Postcode</mat-label>
            <input matInput type="text" formControlName="post_code" />
            <mat-error *ngIf="address.get('post_code').invalid">Please enter the postcode</mat-error>
          </mat-form-field>
        </div>

        <div class=" property__form-field  flex flex-row">
          <mat-form-field appearance="outline" class="property__form-field__item property__form-field__item--full  ">
            <mat-label>County</mat-label>
            <input matInput type="text" formControlName="county" />
            <mat-error *ngIf="address.get('county').invalid">Please enter the county</mat-error>
          </mat-form-field>
        </div>
        <div class=" property__form-field  flex flex-row ">
          <mat-form-field appearance="outline" class="property__form-field__item property__form-field__item--full">
            <mat-label>County</mat-label>
            <input matInput type="text" formControlName="county" />
            <mat-error *ngIf="address.get('county').invalid">Please enter the county</mat-error>
          </mat-form-field>
        </div>

        <div class=" property__form-field flex flex-row">
          <mat-form-field appearance="outline" class="property__form-field__item property__form-field__item--full">
            <mat-label>Country</mat-label>
            <input matInput type="text" formControlName="country" />
            <mat-error *ngIf="address.get('country').invalid">Please enter the country</mat-error>
          </mat-form-field>
        </div>
        <div *ngIf="isRentSmartRequired && address.get('country').value === 'Wales'"
          class=" property__form-field flex flex-row">
          <mat-form-field appearance="outline" class="property__form-field__item property__form-field__item--full">
            <mat-label>Rent Smart Wales License Number</mat-label>
            <input matInput type="text" formControlName="rent_smart_no" />
            <mat-error *ngIf="address.get('rent_smart_no').invalid">Please enter the Rent Smart Wales License Number
            </mat-error>
          </mat-form-field>
        </div>
      </div>
      <div bunk-input class=" property__form-field" *ngIf="isHmo === undefined && showAddress">
        <label>Are you listing by bedrooms?</label>
        <mat-radio-group formControlName="is_hmo"
          class="flex flex-row margin-bottom       property__form-field__item--radio">
          <mat-radio-button [value]="true" class="bunk-outlined-radio" [ngClass]="{
                  'bunk-outlined-radio--selected':
                    formGroup.get('is_hmo').value === true
                }">Yes</mat-radio-button>
          <mat-radio-button [value]="false" class="bunk-outlined-radio" [ngClass]="{
                  'bunk-outlined-radio--selected':
                    formGroup.get('is_hmo').value === false
                }">No</mat-radio-button>
        </mat-radio-group>
      </div>
      <div bunk-input class=" property__form-field"
        *ngIf="isClientNRLA && showAddress && formGroup.get('is_hmo').value === false">
        <label>Is this property an HMO?</label>
        <mat-radio-group formControlName="listed_as_hmo"
          class="flex flex-row margin-bottom property__form-field__item--radio">
          <mat-radio-button [value]="true" class="bunk-outlined-radio" [ngClass]="{
                  'bunk-outlined-radio--selected':
                    formGroup.get('listed_as_hmo').value === true
                }">Yes</mat-radio-button>
          <mat-radio-button [value]="false" class="bunk-outlined-radio" [ngClass]="{
                  'bunk-outlined-radio--selected':
                    formGroup.get('listed_as_hmo').value === false
                }">No</mat-radio-button>
        </mat-radio-group>
      </div>
      <bunk-form-field *ngIf="formGroup.get('is_hmo').value === true">
        <label bunk-label>How many bedrooms does this property have?
          <p class="bunk-mat-error-font-match text--subtle"> Required</p>
        </label>
        <mat-form-field bunk-input appearance="outline">
          <input matInput type="number" (input)="limitDigits('number_bedrooms',$event.target.value,1, 100)"
            formControlName="number_bedrooms" />

          <mat-error>This field is required</mat-error>
        </mat-form-field>
      </bunk-form-field>
      <!-- isPropertyForm -->
      <ng-container *ngIf="isPropertyForm">
        <div>
          <bunk-form-field>
            <label bunk-label>What is the property type?
              <p class="bunk-mat-error-font-match text--subtle"> Required</p>
            </label>
            <mat-form-field bunk-input appearance="outline">
              <mat-select formControlName="property_type" placeholder="Please select">
                <mat-option *ngFor="let type of propertyTypeList" [value]="type.form">{{ type.text }}</mat-option>
              </mat-select>
              <mat-error>Property type required</mat-error>
            </mat-form-field>
          </bunk-form-field>

          <!-- <bunk-form-field>
                                          <label bunk-label>How many bedrooms does this property have?
              <p class="bunk-mat-error-font-match text--subtle"> Required</p>
            </label>
            <mat-form-field bunk-input appearance="outline">
              <input matInput type="number" (input)="limitDigits('number_bedrooms',$event.target.value,1, 100)"
                formControlName="number_bedrooms" />

              <mat-error>This field is required</mat-error>
              </mat-form-field>
          </bunk-form-field> -->

          <bunk-form-field>
            <label bunk-label>How many bathrooms does this property have?
              <p class="bunk-mat-error-font-match text--subtle"> Required</p>
            </label>
            <mat-form-field bunk-input appearance="outline">
              <input matInput type="number" (input)="limitDigits('number_bathrooms',$event.target.value,0, 20)"
                formControlName="number_bathrooms" />
              <mat-error>This field is required</mat-error>
            </mat-form-field>
          </bunk-form-field>

          <bunk-form-field>
            <label bunk-label>How many reception rooms does this property have?
              <p class="bunk-mat-error-font-match text--subtle"> Required</p>
            </label>
            <mat-form-field bunk-input appearance="outline">
              <input matInput type="number" (input)="limitDigits('reception_rooms',$event.target.value,0, 20)"
                formControlName="reception_rooms" />
              <mat-error>This field is required</mat-error>
            </mat-form-field>
          </bunk-form-field>
        </div>

        <bunk-form-field *ngIf="isHmo !== true" class="margin-bottom">
          <label bunk-label>Is this a house share?
            <p class="bunk-mat-error-font-match text--subtle"> Required</p>
          </label>
          <div bunk-input>
            <mat-radio-group formControlName='is_house_share' class="flex flex-row">
              <mat-radio-button class="bunk-outlined-radio mr_5" [value]="true"
                [ngClass]="{'bunk-outlined-radio--selected': is_house_share.value}">Yes</mat-radio-button>
              <mat-radio-button class="bunk-outlined-radio" [value]="false"
                [ngClass]="{'bunk-outlined-radio--selected': !is_house_share.value}">No</mat-radio-button>
              <mat-error class="bunk-mat-error-font-match" *ngIf="formGroup.get('is_house_share').getError('required')">
                Required
              </mat-error>
            </mat-radio-group>
          </div>
        </bunk-form-field>

        <bunk-form-field class="margin-bottom">
          <label bunk-label>Are you a live in landlord?
            <p class="bunk-mat-error-font-match text--subtle"> Required</p>
          </label>
          <div bunk-input>
            <mat-radio-group formControlName="has_live_in_landlord" class="flex flex-row">
              <mat-radio-button class="bunk-outlined-radio mr_5" [value]="true"
                [ngClass]="{'bunk-outlined-radio--selected': has_live_in_landlord.value}">Yes</mat-radio-button>
              <mat-radio-button class="bunk-outlined-radio" [value]="false"
                [ngClass]="{'bunk-outlined-radio--selected': !has_live_in_landlord.value}">No</mat-radio-button>
              <mat-error class="bunk-mat-error-font-match"
                *ngIf="formGroup.get('has_live_in_landlord').getError('required')">Required
              </mat-error>
            </mat-radio-group>
          </div>
        </bunk-form-field>

        <bunk-form-field class="margin-bottom">
          <label bunk-label>Type of heating
            <p class="bunk-mat-error-font-match text--subtle"> Required</p>
          </label>
          <div bunk-input>
            <p class="bunk-outlined-radio bunk-checkbox mr_5"
              [ngClass]="{'bunk-outlined-radio--selected': heating_type.value.includes('gas'),'bunk-outlined-radio--error': !heating_type.value.length && heating_type.touched}">
              <mat-checkbox (click)="checkBoxSelect('heating_type','gas')">Gas</mat-checkbox>
            </p>
            <p class="bunk-outlined-radio bunk-checkbox mr_5"
              [ngClass]="{'bunk-outlined-radio--selected': heating_type.value.includes('electric'),'bunk-outlined-radio--error': !heating_type.value.length && heating_type.touched}">
              <mat-checkbox (click)="checkBoxSelect('heating_type','electric')">Electric</mat-checkbox>
            </p>
            <p class="bunk-outlined-radio bunk-checkbox mr_5"
              [ngClass]="{'bunk-outlined-radio--selected': heating_type.value.includes('oil'),'bunk-outlined-radio--error': !heating_type.value.length && heating_type.touched}">
              <mat-checkbox (click)="checkBoxSelect('heating_type','oil')">Oil</mat-checkbox>
            </p>
            <p class="bunk-outlined-radio bunk-checkbox mr_5"
              [ngClass]="{'bunk-outlined-radio--selected': heating_type.value.includes('combi'),'bunk-outlined-radio--error': !heating_type.value.length && heating_type.touched}">
              <mat-checkbox (click)="checkBoxSelect('heating_type','combi')">Combi</mat-checkbox>
            </p>
          </div>
        </bunk-form-field>

        <bunk-form-field class="margin-bottom">
          <label bunk-label>What other utility supplies does the property have
            <p class="bunk-mat-error-font-match text--subtle"> Required</p>
          </label>
          <div bunk-input>
            <p class="bunk-outlined-radio bunk-checkbox mr_5"
              [ngClass]="{'bunk-outlined-radio--selected': utility_type.value.includes('gas'),'bunk-outlined-radio--error': !utility_type.value.length && utility_type.touched}">
              <mat-checkbox (click)="checkBoxSelect('utility_type','gas')">Gas</mat-checkbox>
            </p>
            <p class="bunk-outlined-radio bunk-checkbox mr_5"
              [ngClass]="{'bunk-outlined-radio--selected': utility_type.value.includes('electric'),'bunk-outlined-radio--error': !utility_type.value.length && utility_type.touched}">
              <mat-checkbox formC (click)="checkBoxSelect('utility_type','electric')">Electric</mat-checkbox>
            </p>
            <p class="bunk-outlined-radio bunk-checkbox mr_5"
              [ngClass]="{'bunk-outlined-radio--selected': utility_type.value.includes('oil'),'bunk-outlined-radio--error': !utility_type.value.length && utility_type.touched}">
              <mat-checkbox (click)="checkBoxSelect('utility_type','oil')">Oil</mat-checkbox>
            </p>
            <p class="bunk-outlined-radio bunk-checkbox mr_5"
              [ngClass]="{'bunk-outlined-radio--selected': utility_type.value.includes('combi'),'bunk-outlined-radio--error': !utility_type.value.length && utility_type.touched}">
              <mat-checkbox fo (click)="checkBoxSelect('utility_type','combi')">Combi</mat-checkbox>
            </p>
          </div>
        </bunk-form-field>



        <!-- Add Photos -->
        <div>
          <h1> Add Photos </h1>
          <property-list-pictures-form-new [property]="property$ | async" [isSimpleProperty]="true"
            [formGroup]="formGroup" #propertyPhotosUpload></property-list-pictures-form-new>
        </div>

        <!-- Property Ownership  -->



        <div>

          <h1 class="text--lg margin-bottom--sm">Property Ownership</h1>

          <bunk-form-field class="margin-bottom">
            <label bunk-label>Are you the owner of this property?
              <p class="bunk-mat-error-font-match text--subtle"> Required</p>
            </label>
            <div bunk-input>
              <mat-radio-group formControlName="does_landlord_own_property" class="flex flex-row">
                <mat-radio-button class="bunk-outlined-radio mr_5" [value]="true"
                  [ngClass]="{'bunk-outlined-radio--selected': does_landlord_own_property.value}">Yes</mat-radio-button>
                <mat-radio-button class="bunk-outlined-radio" [value]="false"
                  [ngClass]="{'bunk-outlined-radio--selected': !does_landlord_own_property.value}">No</mat-radio-button>
                <mat-error *ngIf="
                  formGroup
                    .get('does_landlord_own_property')
                    .getError('required')
                " class="bunk-mat-error-font-match ">Required</mat-error>
              </mat-radio-group>
            </div>
          </bunk-form-field>
          <br />

          <bunk-form-field class="margin-bottom">
            <label bunk-label>{{does_landlord_own_property.value ? 'Are you listing this property as' :'Is the property
              owner'}} an individual or a company?
              <p class="bunk-mat-error-font-match text--subtle"> Required</p>
            </label>
            <div bunk-input>
              <mat-radio-group formControlName="property_listed_as" class="flex flex-row">
                <mat-radio-button class="bunk-outlined-radio mr_5" value="individual"
                  [ngClass]="{'bunk-outlined-radio--selected': property_listed_as.value === 'individual'}">Individual
                </mat-radio-button>
                <mat-radio-button class="bunk-outlined-radio" value="company"
                  [ngClass]="{'bunk-outlined-radio--selected': property_listed_as.value === 'company'}">Company
                </mat-radio-button>
                <mat-error *ngIf="formGroup.get('property_listed_as').getError('required')"
                  class="bunk-mat-error-font-match ">Required</mat-error>
              </mat-radio-group>
            </div>
          </bunk-form-field>

        </div>
        <div>
          <h1 class="text--lg margin-bottom--sm">
            Proof of Property Ownership
          </h1>
          <p>
            If you wish to advertise your property, you will need to supply
            proof of ownership. Please note, we cannot accept a HM land
            registry record title deed as proof as this is publicly available
            data.
          </p>

          <bunk-form-field>
            <label bunk-label>Select document type
              <p class="bunk-mat-error-font-match text--subtle"> Optional</p>
            </label>
            <mat-form-field bunk-input appearance="outline">
              <mat-select placeholder="Please select" formControlName="ownership_document_type">
                <mat-option *ngFor="let documentType of documentTypesList" [value]="documentType.value">
                  {{documentType.viewValue}}
                </mat-option>
              </mat-select>
              <mat-error>This field is required</mat-error>
            </mat-form-field>
          </bunk-form-field>

          <bunk-form-field *ngIf="ownership_document_type.value">
            <label bunk-label>Please upload a scan or clear photo of proof of ownership
              <p class="bunk-mat-error-font-match text--subtle"> Optional</p>
              <p>
                <small class="flex margin-top--sm">We support: PDF, DOC, DOCX, JPG, JPEG, JPEG & PNG files - Max
                  5MB</small>
              </p>

            </label>
            <div class="upload-btn-wrapper relative" bunk-input>
              <button type="button" class="document-upload-btn">
                <span class="add-icon" *ngIf="!ownership_document_full_path.value;">+</span>
                <span *ngIf="!ownership_document_full_path.value;">Proof of ownership</span>
                <mat-icon svgIcon="file-icon" class="file-icon" *ngIf="ownership_document_full_path.value;"></mat-icon>
                <span class="flex">
                  <strong *ngIf="ownership_document_full_path.value;"
                    class="file-name pr-1">{{beautifyPath(ownership_document_full_path.value)}}</strong>
                  <mat-icon svgIcon="delete-bin" class="remove-doc-icon ml-1"
                    *ngIf="ownership_document_full_path.value;" (click)="removeDoc()"></mat-icon>
                </span>
              </button>
              <input type="file" name="myfile" (change)="uploadDocument($event)" #uploadDocumentInput
                *ngIf="!ownership_document_full_path.value;" />
            </div>
          </bunk-form-field>

          <!-- <landlord-upload-document-form [formGroup]="formGroup"></landlord-upload-document-form> -->
        </div>

        <div class="rc">
          <mat-checkbox formControlName="has_accepted_listing_terms"></mat-checkbox>
          <span class="ml_4">I have read and agree to {{ client_data.company_name }}'s
            <a href="{{ client_data.terms_url }}" target="_blank" class="link link_2">Terms and Conditions</a></span>
        </div>

      </ng-container>

      <div class="jc_sa rc mt_8 pb_4 margin-top--md" *ngIf="!isPropertyForm">
        <button id="button_lap-address" class="btn__generic" type="button" (click)="createProperty()"
          [disabled]="!showAddress">
          <span *ngIf="!this.isLoading">Next</span>
          <processing-spinner *ngIf="this.isLoading" color="#FFFFFF" size="30px"></processing-spinner>
        </button>
      </div>

      <div class="buttons flex justify-between mt_8 pb_4 margin-top--md" *ngIf="isPropertyForm">
        <button class="btn__seethrough" type="button" (click)="dref.close()">
          Cancel
        </button>
        <button type="button" class="btn__generic" [disabled]="!showAddress || !has_accepted_listing_terms.value"
          (click)="submit()">
          <span *ngIf="!this.isLoading">Create Property </span>
          <processing-spinner *ngIf="this.isLoading" color="#FFFFFF" size="30px"></processing-spinner>
        </button>
      </div>
    </form>
  </div>
</section>

<div class="siema">
  <ng-content></ng-content>
</div>
<ng-container>
  <ng-container *ngIf="(carousel$ | async) as slides">

<div *ngIf="slides.totalSlides >= 1 && !showDotFooter"  class="carousel__footer" >
  <span class="prev"><button (click)="prev()" class="btn__text-generic" *ngIf="slides.currentSlide !== 1"><mat-icon svgIcon="chevron-left"></mat-icon> prev</button></span>
  <span>{{ slides.currentSlide }} of {{ slides.totalSlides}}</span>
  <span class="next"><button (click)="next()" class="btn__text-generic" *ngIf="slides.currentSlide !== slides.totalSlides">next <mat-icon svgIcon="chevron-right"></mat-icon></button></span>
</div>

    <div *ngIf="slides.totalSlides >= 1 && showDotFooter" class="carousel__dot__footer">

      <span *ngFor="let slide of slides.footerArray; let i = index"  class="dot" [ngClass]="{'active': i + 1 === slides.currentSlide }"></span>
<!--      <span>{{ slides.currentSlide }} of {{ slides.totalSlides}}</span>-->
    </div>




  </ng-container>
</ng-container>

import { Component, OnDestroy, OnInit, Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { StripeService } from '@app/services/stripe.service';
import { UserService } from '@app/services/user.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'manage-cards',
  template: `
    <div (click)="clearErrors()" >
      <p class="overline margin-top--sm" *ngIf="showCardsForm">Saved cards</p>
      <div class="flex flex-column">
        <processing-spinner *ngIf="loading && (_user.user$ | async)?.payments_data.cards[0]" color="#FFFFFF"></processing-spinner>
        <p *ngIf="loading && (_user.user$ | async)?.payments_data.cards[0]">{{ cards_message }}</p>

        <ng-container *ngIf="!loading && (_user.user$ | async)?.payments_data.cards as cards">
          <div class="error" *ngIf="cardsError">{{ cardsError }}</div>

          <ng-container *ngFor="let card of cards">
            <div class="payment__card payment__card--added" *ngIf="updatingFromEdit === card.id || deleting === card.id">
              <div class="flex flex-row justify-between border-bottom pb_2">
                <div>
                  <div class="card-icon {{card.card_type}}"></div>
                  <small>ending in {{card.last4}}</small>
                </div>
              </div>
              <p class="margin-top--md margin-bottom--md">{{ updatingFromEdit ? 'Updating' : 'Removing' }} card</p>
              <processing-spinner color="#FFFFFF"></processing-spinner>
            </div>

            <div class="payment__card payment__card--added" *ngIf="updatingFromEdit !== card.id && deleting !== card.id">
              <div class="flex flex-row justify-between border-bottom pb_2">
                <div>
                  <div class="card-icon {{card.card_type}}"></div>
                  <small>ending in {{card.last4}}</small>
                </div>
                <div>
                  <small>Expires {{card.exp_month}}/{{card.exp_year}}</small>
                </div>
              </div>
              <div class="flex flex-row justify-between margin-top--sm">
                <div class="flex flex-column w_75">
                  <div>
                    <small>Name on card</small>
                    <p>{{card.name}}</p>
                  </div>
                  <div>
                    <small>Billing address</small>
                    <p class="truncate">
                      <span *ngIf="card.address_line1">{{card.address_line1}}, </span>
                      <span *ngIf="card.address_line2">{{card.address_line2}}, </span><br>
                      <span *ngIf="card.address_city">{{card.address_city}} </span>
                      <span *ngIf="card.address_zip">{{card.address_zip}}</span>
                    </p>
                  </div>
                </div>
                <div class="flex flex-column">
                  <button *ngIf="showCardsForm"
                    class="btn__warning btn--sm btn--round margin-top--sm"
                    (click)="deleteCard(card.id)">Remove
                  </button>
                  <button class="btn__seethrough btn--sm btn--round margin-top--sm"
                    (click)="card.editable = true"
                    *ngIf="card.editable != true; else updateButton">Edit
                  </button>
                  <ng-template #updateButton>
                    <button class="btn__seethrough btn--sm btn--round margin-top--sm"
                      [disabled]="updateCardForm.untouched"
                      (click)="updateCard(card)">update
                    </button>
                  </ng-template>
                </div>
              </div>
              <ng-container *ngIf="card.editable">
                <form [formGroup]="updateCardForm" class="update-card-form">
                  <div class="flex_100 cc">
                    <label class="text--xs">Cardholder name</label>
                    <input type="text" formControlName="cardholderName"
                            [ngModel]="card.name">
                  </div>
                  <div class="rc mt_8">
                    <label class="text--xs">Expiry</label>
                    <div class="cc ml_4 mr_4">
                      <select type="number" formControlName="expiryDateMonth"
                              [ngModel]="card.exp_month">
                        <option value="{{month}}" *ngFor="let month of months">{{month}}</option>
                      </select>
                    </div>
                    /
                    <div class="cc ml_4">
                      <select type="number" formControlName="expiryDateYear"
                              [ngModel]="card.exp_year">
                        <option value="{{year}}" *ngFor="let year of years">{{year}}</option>
                      </select>
                    </div>
                  </div>
                  <div class="flex-container_row mt_8">
                    <div class="flex_50 cc mr_4">
                      <label class="text--xs">First line of address</label>
                      <input type="text" formControlName="addressLine1"
                            placeholder="14 Bristol High Street"
                            [ngModel]="card.address_line1">
                    </div>
                    <div class="flex_50 cc mr_4">
                      <label class="text--xs">Second line of address</label>
                      <input type="text" formControlName="addressLine2"
                            [ngModel]="card.address_line2">
                    </div>
                  </div>
                  <div class="flex-container_row mt_8">
                    <div class="flex_50 cc mr_4">
                      <label class="text--xs">City</label>
                      <input type="text" formControlName="addressCity"
                            [ngModel]="card.address_city">
                    </div>
                    <div class="flex_50 cc mr_4">
                      <label class="text--xs">Postcode</label>
                      <input type="text" formControlName="addressPostCode"
                            placeholder="BS1 4FG"
                            [ngModel]="card.address_zip">
                    </div>
                  </div>
                </form>
              </ng-container>
            </div>
          </ng-container>

        </ng-container>

        <ng-container *ngIf="showCardsForm && !loading && (!(_user.user$ | async)?.payments_data.cards || !(_user.user$ | async)?.payments_data.cards.length)">
          <p>No cards have been saved.</p>
        </ng-container>
          
      </div>
    </div>
    <div *ngIf="showCardsForm">
      <cards-form
        (setupComplete)="addCard($event)"
        [disabled]="disableCardsForm"
        [clear]="clearCardsForm">
      </cards-form>
    </div>
  `,
  styleUrls: ['../payment-methods.scss']
})
export class CardsComponent implements OnInit, OnDestroy {

  @Input() showCardsForm ?= true;

  public months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  public years = [];
  public loading: boolean;
  public updatingFromEdit: string;
  public deleting: string;
  public cards_message = 'Please wait while we fetch your card(s) data...';
  private destroy$: Subject<boolean> = new Subject<boolean>();
  public cardsError: string;
  public clearCardsForm: Subject<boolean> = new Subject();
  public disableCardsForm: Subject<boolean> = new Subject();

  updateCardForm = new FormGroup({
    cardholderName: new FormControl(''),
    expiryDateMonth: new FormControl(''),
    expiryDateYear: new FormControl(''),
    addressCity: new FormControl(''),
    addressLine1: new FormControl(''),
    addressLine2: new FormControl(''),
    addressPostCode: new FormControl('')
  });

  constructor(
    private _stripe: StripeService,
    public _user: UserService,
    private _toastr: ToastrService
  ) {
    this.loading = true;
    this._user.user$
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe((user) => {
        this.loading = false;
        console.log(user);
      });
  }

  ngOnInit() {
    const thisYear = new Date().getFullYear();
    for (let i = 0; i < 20; i++) {
      this.years.push(thisYear + i);
    }
  }

  updateCard(card) {
    this.updatingFromEdit = card.id;
    this.cards_message = 'Please wait until we\'ve successfully updated your card data';
    const formValue = this.updateCardForm.value;
    const item = {
      name: formValue.cardholderName,
      address_city: formValue.addressCity,
      address_line1: formValue.addressLine1,
      address_line2: formValue.addressLine2,
      address_post_code: formValue.addressPostCode,
      exp_month: formValue.expiryDateMonth,
      exp_year: formValue.expiryDateYear
    };
    this._stripe.updateCustomerCard(card.id, item)
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe(cardData => {
        console.log('updated Card: ', cardData);
        card.editable = false;
        this.updatingFromEdit = undefined;
      }, error => {
        this.cardsError = error.error.message;
        this.updatingFromEdit = undefined;
      });
  }

  addCard(token) {
    this.cards_message = 'We\'re checking your card!';
    this._stripe.attachPaymentMethodToCustomer(token.payment_method)
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe((data) => {
        console.log(data);
        this.clearCardsForm.next(true);
        this.disableCardsForm.next(false);
        // this._toastr.success('Your card has been successfully added to your account!');
      }, error => {
        this.cardsError = error.error.message;
        this.clearCardsForm.next(false);
        this.disableCardsForm.next(false);
      });
  }

  deleteCard(id) {
    this.deleting = id;
    this._stripe.deleteCard(id)
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        this._toastr.success('Card successfully removed!');
        this.deleting = undefined;
      }, error => {
        this.cardsError = error.error.message;
        this.deleting = undefined;
      });
  }

  clearErrors() {
    this.cardsError = null;
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

}

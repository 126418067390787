

    <div>
      <h2 class="text--lg">Guarantor details</h2>
      <p>You will only need a guarantor if you earn less than 2.5 times the annual rental value or if the {{landlord}} requests one at the references stage.</p>
    </div>

    <form class="cc" [formGroup]="formGroup" (ngSubmit)="formGroup.valid && nextStep(formGroup.value)">

    <form-guarantor-details formControlName="guarantor_details"></form-guarantor-details>

      <div class="margin-bottom--xl">
        <button *ngIf="showPrev" (click)="goBack()" data-test="backButton" class="btn__seethrough btn__form-prev">Back</button>
        <button class="btn__generic btn__form-submit btn__form-submit--align-right" type="submit">
          <!--[disabled]="formGroup.invalid || submittingData">-->
          <span *ngIf="!submittingData && !submittingDataSuccess">Save<span *ngIf="!standAlone"> and continue</span></span>
          <processing-spinner *ngIf="submittingData || submittingDataSuccess" color="#FFFFFF" [complete]="submittingDataSuccess"></processing-spinner>
        </button>
      </div>

    </form>

  
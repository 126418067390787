import {Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {StorageService} from '@app/services';
import {AuthService} from '@app/core/services';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {ToastrService} from 'ngx-toastr';
import { Observable, of } from 'rxjs';
import { ADDRESS_DOCUMENT_TYPES_LIST } from '@app/shared/shared.constants';

@Component({
  selector: 'app-document-verification',
  templateUrl: './document-verification.component.html',
  styleUrls: ['./document-verification.component.scss']
})
export class DocumentVerificationComponent implements OnInit {
  formGroup: FormGroup;
  propertyId = this.data.propertyId;
  verificationType = this.data.verificationType;
  verificationDocumentType = this.data.verificationDocumentType;
  identityVerificationType = this.data.identityVerificationType;
  passportUploadedFileFullPath = this.data.passportUploadedFileFullPath;
  passportUploadedFileOriginalUrl$: Observable<string> = this.data.passportUploadedFileFullPath ? this._storage.getDownloadURLbyPath(this.data.passportUploadedFileFullPath) : of('');
  passportUploadedFileName = this.data.passportUploadedFileName;
  addressUploadedFileFullPath = this.data.addressUploadedFileFullPath;
  addressUploadedFileOriginalUrl$: Observable<string> = this.data.addressUploadedFileFullPath ? this._storage.getDownloadURLbyPath(this.data.addressUploadedFileFullPath) : of('');
  addressUploadedFileName = this.data.addressUploadedFileName;
  addressDocumentTypesList = ADDRESS_DOCUMENT_TYPES_LIST;
  addressDocumentType = this.data.addressDocumentType;
  drivingLicenseUploadedFileFullPath = this.data.drivingLicenseUploadedFileFullPath;
  drivingLicenseUploadedFileOriginalUrl$: Observable<string> = this.data.drivingLicenseUploadedFileFullPath ? this._storage.getDownloadURLbyPath(this.data.drivingLicenseUploadedFileFullPath) : of('');
  drivingLicenseUploadedFileName = this.data.drivingLicenseUploadedFileName;
  ownershipUploadedFileFullPath = this.data.ownershipUploadedFileFullPath;
  ownershipUploadedFileUrl$: Observable<string> = this.data.ownershipUploadedFileFullPath ? this._storage.getDownloadURLbyPath(this.data.ownershipUploadedFileFullPath) : of('');
  ownershipUploadedFileName = this.data.ownershipUploadedFileName;
  ownershipDocumentTypesList = this.data.ownershipDocumentTypesList;
  ownershipDocumentType = this.data.ownershipDocumentType;
  documentMaxSizeMb = 5;
  @Output() submitUploadedDocuments: EventEmitter<object> = new EventEmitter();

  constructor(
    private _auth: AuthService,
    private _formBuilder: FormBuilder,
    private _storage: StorageService,
    public dialogRef: MatDialogRef<DocumentVerificationComponent>,
    private _toastr: ToastrService,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {}

  ngOnInit() {
    this.formGroup = this.initFormGroup();
    this.setValidations(this.verificationDocumentType);
    this.identity_verification_type.valueChanges
      .subscribe(value => {
        switch (value) {
          case 'passport_and_address':
            if (this.data.identityVerificationType === value) {
              this.drivingLicenseUploadedFileName = '';
            }
            break;
          case 'driving_license':
            if (this.data.identityVerificationType === value) {
              this.passportUploadedFileName = '';
              this.addressUploadedFileName = '';
              this.addressDocumentType = '';
            }
            break;
        }
        this.setValidations(value);
      });
  }

  initFormGroup(): FormGroup {
    return this._formBuilder.group({
      identity_verification_type: [this.identityVerificationType],
      passport_document_full_path: [this.passportUploadedFileFullPath],
      address_document_full_path: [this.addressUploadedFileFullPath],
      address_document_type: [this.addressDocumentType],
      driving_license_full_path: [this.drivingLicenseUploadedFileFullPath],
      ownership_document_full_path: [this.ownershipUploadedFileFullPath],
      ownership_document_type: [this.ownershipDocumentType],
    });
  }

  get identity_verification_type(): FormControl {
    return this.formGroup.get('identity_verification_type') as FormControl;
  }
  get passport_document_full_path(): FormControl {
    return this.formGroup.get('passport_document_full_path') as FormControl;
  }
  get address_document_full_path(): FormControl {
    return this.formGroup.get('address_document_full_path') as FormControl;
  }
  get address_document_type(): FormControl {
    return this.formGroup.get('address_document_type') as FormControl;
  }
  get driving_license_full_path(): FormControl {
    return this.formGroup.get('driving_license_full_path') as FormControl;
  }
  get ownership_document_full_path(): FormControl {
    return this.formGroup.get('ownership_document_full_path') as FormControl;
  }
  get ownership_document_type(): FormControl {
    return this.formGroup.get('ownership_document_type') as FormControl;
  }

  setValidations(verificationDocumentType) {
      this.validatorUpdate(['passport_document_full_path', 'address_document_full_path', 'address_document_type'], verificationDocumentType !== this.data.identityVerificationType && verificationDocumentType === 'passport_and_address');
      this.validatorUpdate(['driving_license_full_path'], verificationDocumentType !== this.data.identityVerificationType && verificationDocumentType === 'driving_license');
      this.validatorUpdate(['ownership_document_full_path', 'ownership_document_type'], verificationDocumentType !== this.data.identityVerificationType && verificationDocumentType === 'ownership');
  }

  validatorUpdate(fields, setValidation = true) {
    setValidation ?
      fields.forEach(f => {
        this[f].setValidators([Validators.required]);
        this[f].updateValueAndValidity();
      }) :
      fields.forEach(f => {
        this[f].clearValidators();
        this[f].reset();
      });
  }

  onDocumentFileChange($event) {
    switch ($event.verificationDocumentType) {
      case 'passport':
        this.passport_document_full_path.patchValue($event.uploadedFileFullPath);
        break;
      case 'address':
        this.address_document_full_path.patchValue($event.uploadedFileFullPath);
        break;
      case 'driving-license':
        this.driving_license_full_path.patchValue($event.uploadedFileFullPath);
        break;
      case 'ownership':
        this.ownership_document_full_path.patchValue($event.uploadedFileFullPath);
        break;
    }
  }

  onDocumentTypeChange($event) {
    switch ($event.verificationDocumentType) {
      case 'address':
        this.address_document_type.patchValue($event.uploadedDocumentType);
        break;
      case 'ownership':
        this.ownership_document_type.patchValue($event.uploadedDocumentType);
        break;
    }
  }

  submit(formGroup) {
    const verificationData = {...formGroup.value};
    Object.keys(verificationData).forEach(key => {
      if (verificationData[key] === null) {
        delete verificationData[key];
      }
    });
    formGroup.valid ? this.dialogRef.close(verificationData) : this._toastr.error(`Please review incomplete fields`);
  }
}


  <form [formGroup]="employmentForm" autocomplete="none">
  <bunk-form-field *ngIf="getFormControl('employment_status')">
    <label bunk-label >What is your Employment Status?</label>
    <mat-form-field bunk-input appearance="outline">
      <mat-select formControlName="employment_status" placeholder="Select" (selectionChange)="updateEmploymentValidators($event.value)">
        <mat-option *ngFor="let status of employmentStatus" [value]="status.value">
          {{status.text}}
        </mat-option>
      </mat-select>
    <mat-error>Please select your employment status</mat-error>
    </mat-form-field>
  </bunk-form-field>

  <div *ngIf="getFormControl('employment_status') && getFormControl('employment_status').value !=='unemployed'">

  <bunk-form-field *ngIf="getFormControl('referee_name')">
  <label bunk-label>What is your employers name?</label>
    <mat-form-field bunk-input appearance="outline">
      <input formControlName="referee_name" matInput type="text" name="name" placeholder="E.g David Brown">
      <mat-error>Please enter your referees name</mat-error>
    </mat-form-field>
  </bunk-form-field>

  <bunk-form-field *ngIf="getFormControl('referee_email')">
    <label bunk-label>What is your employers email?</label>
    <mat-form-field bunk-input appearance="outline">
      <input formControlName="referee_email" matInput type="email" name="email" placeholder="E.g david@gmail.com">
      <mat-error>Please enter a valid email address for your employer</mat-error>
    </mat-form-field>
  </bunk-form-field>

  <bunk-form-field *ngIf="getFormControl('referee_phone')">
    <label bunk-label >What is your employers number?</label>
    <div bunk-input>
    <form-input-phone
      bunk-input
      formControlName="referee_phone"
      showLabel="true"
      [submitAttempted]="submitAttempted"
    ></form-input-phone>
    </div>
  </bunk-form-field>

  <bunk-form-field *ngIf="getFormControl('job_title')">
    <label bunk-label >What is your job title?</label>
    <mat-form-field bunk-input appearance="outline">
      <input matInput formControlName="job_title" type="text" placeholder="E.g Customer service assistant">
      <mat-error>Please enter your job title</mat-error>
    </mat-form-field>
  </bunk-form-field>

  <bunk-form-field *ngIf="getFormControl('company_name')">
  <label bunk-label >What is the company name?</label>
    <mat-form-field bunk-input appearance="outline">
      <mat-label>Company Name</mat-label>
      <input matInput formControlName="company_name" type="text">
      <mat-error>Please enter your company name</mat-error>
    </mat-form-field>
  </bunk-form-field>

    <bunk-form-field *ngIf="getFormControl('start_date')">
      <label bunk-label >When did your employment start?</label>
      <div bunk-input>
      <date-selector
        formControlName="start_date"
        [submitAttempted]="submitAttempted"
        >
      </date-selector>
      </div>
    </bunk-form-field>

    <bunk-form-field *ngIf="getFormControl('employee_salary')">
    <label bunk-label >What is your annual salary?</label>
      <mat-form-field bunk-input appearance="outline">
      <input formControlName="employee_salary" matInput bnkSwitchFormatToLocale step="1000" name="salary"
            autocomplete="off">
      <span matPrefix [ngClass]="{'disabled-label' : employmentForm.disabled}">{{client_data.currency_symbol}}&nbsp;</span>
      <mat-error>Please enter your salary at the time</mat-error>
      </mat-form-field>
    </bunk-form-field>

    <bunk-form-field *ngIf="getFormControl('employee_bonus')">
      <label bunk-label >What is your annual bonus optional)?</label>

      <mat-form-field bunk-input appearance="outline">
      <input formControlName="employee_bonus" matInput bnkSwitchFormatToLocale step="100" name="bonus"
            autocomplete="off">
      <span matPrefix [ngClass]="{'disabled-label' : employmentForm.disabled}">{{client_data.currency_symbol}}&nbsp;</span>
      <mat-error>Please enter your bonus at the time</mat-error>
      </mat-form-field>
    </bunk-form-field>

  </div>
  </form>

  
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HasFullAccess } from '@app/core/guards';
import { PaymentsManageComponent } from './payments-manage/payments-manage.component';

const routes: Routes = [{
  path:'',
  component: PaymentsManageComponent,
  canActivate: [HasFullAccess], data: {route: 'payments'},
  pathMatch:'full'
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PaymentsRoutingModule { }

import {Component} from '@angular/core';
import {Auth} from '@env/routing';

@Component({
    selector: 'ie-users',
    template: `
    <div class="main__wrapper">
        <div class="empty__state">
            <h1>The Internet Explorer site is coming soon... </h1>
            <p>In the meantime please upgrade to a modern browser.</p>
            <a class="btn__seethrough" href="https://bestvpn.org/outdatedbrowser/en" target="_blank">
            Download another browser</a>
        </div>
    </div>
    `,
})

export class IeUsersComponent {
    public routes = {Auth};
}

import { Injectable } from '@angular/core';

import { MatDialog, MatDialogRef, MatDialogConfig } from '@angular/material/dialog';
import { ComponentType } from '@angular/cdk/portal';

const DIALOG_CONFIG_DEFAULT = {
  width: '600px',
};

// Setting default maxWidth as cant use 'updateSize' for max & need to override the bunk 80vw
const DIALOG_CONFIG_FULL_SCREEN = {
  maxWidth: '100vw',
  panelClass: 'mat-dialog-no-border-radius'
};

// Using for sign up form - full screen on mobile and cannot close by clicking away
const DIALOG_CONFIG_RESTRICT = {
  disableClose: true,
  maxWidth: '100vw',
  panelClass: 'mat-dialog-no-border-radius',
};
@Injectable()
export class DialogService {
  constructor(private dialog: MatDialog) {
  }

  public open<TComponent>(component: ComponentType<any>, data?: any, config?: MatDialogConfig): MatDialogRef<TComponent> {
    return this.dialog.open(component, {
      ...DIALOG_CONFIG_DEFAULT,
      ...(config || {}),
      data: data || {}
    });
  }

  public openFullScreen<TComponent>(component: ComponentType<any>, data?: any, config?: MatDialogConfig): MatDialogRef<TComponent> {
    return this.dialog.open(component, {
      ...DIALOG_CONFIG_FULL_SCREEN,
      ...(config || {}),
      data: data || {}
    });
  }

  public openRestrict<TComponent>(component: ComponentType<any>, data?: any, config?: MatDialogConfig): MatDialogRef<TComponent> {
    return this.dialog.open(component, {
      ...DIALOG_CONFIG_RESTRICT,
      ...(config || {}),
      data: data || {}
    });
  }
}

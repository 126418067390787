import { Component, EventEmitter, Output, OnInit } from '@angular/core';

@Component({
  selector: 'tenant-account-request-viewings-summary',
  template: `
    <div class="jc_sb rc w_100">
      <button [routerLink]="['/', 'rent-property']" (click)="closeOverlay.emit()" class="btn__generic btn--round">Search properties</button>
    </div>`,
  styleUrls: ['./tenant-account-step.component.scss']
})
export class TenantAccountRequestViewingsSummaryComponent implements OnInit {
  @Output() callback: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() closeOverlay: EventEmitter<boolean> = new EventEmitter<boolean>();

  ngOnInit() {
    this.callback.emit(true);
  }
}

import { Component, OnInit, Input } from '@angular/core';
import { PropertyModel } from '@app/core-landlord/property/_models/property.model';
import { Tenant } from '@env/routing';
import * as moment from 'moment';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { StorageLocalService, ViewingService } from '@app/services';
import { map } from 'rxjs/operators';


@Component({
  selector: 'booking-success',
  templateUrl: './booking-success.component.html',
  styleUrls: ['./booking-success.component.scss']
})
export class BookingSuccessSharedComponent implements OnInit {
  @Input() property: PropertyModel;
  @Input() aside = false;

  public isDesktop: boolean;
  public viewingId: string;
  public viewing$: Observable<any>;
  public routes = {Tenant};
  public display;

  constructor(
    private _route: ActivatedRoute,
    private _viewings: ViewingService,
    protected _localStorage: StorageLocalService
  ) { 
    this.viewingId = this._route.snapshot.queryParamMap.get('success');
    // clear viewings so that they can book another viewing
    this._localStorage.clearStore();

  }

  getMomentDate(date: any) {
    return moment(date.toDate()).format('dddd, MMMM Do YYYY');
  }

  acceptedViewingTime(viewing): Date {
    if (viewing.accepted_viewing_time) {
      return viewing.accepted_viewing_time.toDate();
    }
    if (viewing.viewing_booked_time) {
      return viewing.viewing_booked_time.toDate();
    }
    return null;
  }

  ngOnInit(): void {
    this.viewing$ = this._viewings.getViewingById(this.viewingId).valueChanges().pipe(
      map((viewing: any) => {
      return this.display = {
        viewingDate: this.getMomentDate(viewing.accepted_viewing_time),
        viewingTime: this.acceptedViewingTime(viewing),
        propertyAddress: `${this.property.address.first_line_address}, ${this.property.address.post_code.split(' ')[0]}`
      };
      }));
  }
}

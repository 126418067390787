import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {TABLE_ITEMS_PP} from '@app/_constants/forms.constants';
import {MatPaginator, MatSort, MatTableDataSource} from '@angular/material';
import {Router} from '@angular/router';
import {UserService} from '@app/services';
import * as moment from 'moment';
import {AdminService} from '@app/services/admin.service';
import {FormBuilder, FormGroup} from '@angular/forms';
import {finalize, flatMap, map} from 'rxjs/operators';
import {ToastrService} from 'ngx-toastr';
import {environment} from '@env/environment';
import {PaymentsService} from '@app/services/payments.service';
import {tap} from 'rxjs/internal/operators';
import {Observable} from 'rxjs/Observable';
import {of} from 'rxjs/internal/observable/of';
import {combineLatest} from 'rxjs';
const SOURCES = [
  {view: 'Client transaction', value: 'client_transaction'},
  {view: 'Non client transaction', value: 'non_client_transaction'},
  {view: 'Forfeited holding fees', value: 'forfeited_holding_fees'},
  {view: 'Paying off platform', value: 'off_platform'},
  {view: 'Refund transaction', value: 'refund_transaction'},
  {view: 'Expenses', value: 'expenses'}
];

@Component({
  selector: 'transactions-table',
  template: `
    <div class="table-overflow">

      <table mat-table [dataSource]="dataSource" multiTemplateDataRows>
        <ng-container *ngFor="let item of columnsToDisplay; let i = index">
          <ng-container [matColumnDef]="item.value">
            <th mat-header-cell *matHeaderCellDef> {{item.title}} </th>

            <td mat-cell *matCellDef="let element;">

              <span class="table-margin" *ngIf="item.title === 'Date Paid'" [ngClass]="{'show-account ': element.show_account}">
            {{element.timestamp_paid}}
            </span>

              <span *ngIf="item.title === 'In / Out'" [ngClass]="{'show-account ': element.show_account, 'success-item': element.transaction_direction === 'In'}">
            {{element.transaction_direction}} <mat-icon *ngIf="element.transaction_direction === 'Out'" class="table-icon" svgIcon="right-arrow"></mat-icon>
                <mat-icon *ngIf="element.transaction_direction === 'In'" class="table-icon" svgIcon="left-arrow"></mat-icon>
            </span>

              <span *ngIf="item.title === 'Amount'" [ngClass]="{'show-account ': element.show_account, 'success-item': element.transaction_direction === 'In' }">
                <span>{{(element.transaction_direction === 'Out' ? '-' : '+')}}</span><span>{{client_data.currency_symbol}}</span>{{element.transaction_amount}}
            </span>

              <span *ngIf="item.title === 'Unassigned'" [ngClass]="{'show-account ': element.show_account}">
                <ng-container *ngIf="element.source === 'Client transaction'">
              <span *ngIf="element.transaction_amount_unassigned_to_payment" class="action-item flex flex-row items-center"
                    #tooltip="matTooltip"
                    matTooltip="Tenant over paid issue a refund"
                    matTooltipPosition="below"
                    matTooltipHideDelay="1000"
                    aria-label="Tenant over paid issue a refund"
              >
                 {{client_data.currency_symbol +' '+element.transaction_amount_unassigned_to_payment}} <span class="icon__warning icon__warning--xs ml_2"></span></span>
                  </ng-container>
            </span>

              <span *ngIf="item.title === 'Short Ref' || item.title === 'Long Ref'" [ngClass]="{'show-account ': element.show_account}">
               <span class="flex flex-row" [ngClass]="{'action-item': !element.has_payment_association && element.source === 'Client transaction'}">{{element[item.value]}}
                 <span *ngIf="!element.has_payment_association && element.source === 'Client transaction'" class="icon__warning icon__warning--xs ml_2"
                       #tooltip="matTooltip"
                       matTooltip="Reference does not match"
                       matTooltipPosition="below"
                       matTooltipHideDelay="1000"
                       aria-label="Reference does not match"
                 >

               </span> </span>
            </span>

              <span *ngIf="item.title === 'Payout Ref'"  [ngClass]="{'show-account ': element.show_account}">
                {{element.user_reference_code}}
              </span>

              <span *ngIf="item.title === 'Payout Amount'" [ngClass]="{'show-account ': element.show_account}" class="flex-column">
              <span *ngIf="element.total_to_payout" class="flex flex-row items-center">{{ client_data.currency_symbol + element.total_to_payout}}
                <span *ngIf="element.payout_is_less " class="icon__warning--orange icon__warning--orange--xs"
                      #tooltip="matTooltip"
                      matTooltip="{{(element.transaction_amount_unassigned_to_payment > 0 ? 'Payout amount is less because they have over paid'
                       : 'Payout amount is less because some of it is a deposit')}}"
                      matTooltipPosition="below"
                      matTooltipHideDelay="1000"
                ></span>
              </span>


              <div *ngIf="element.show_account" class="margin-top">
              <span *ngFor="let account of element.payout_accounts">
              {{client_data.currency_symbol + account.amount_to_pay}}
              </span>
              </div>
              </span>


              <span *ngIf="item.title === 'Account'" [ngClass]="{'show-account ': element.show_account}" class="flex-column ">
              <span *ngIf="element.payout_account_length && element.total_to_payout && element.transaction_direction === 'In'"
                    (click)="element.show_account = !element.show_account" class="flex items-center cursor-pointer">
                {{element.payout_account_length}} {{element.payout_account_length > 1 ? 'Accounts' : 'Account'}}
                <mat-icon svgIcon="down-arrow" class="table-icon"></mat-icon>
              </span>


              <ng-container *ngIf="element.show_account">
              <div *ngFor="let account of element.payout_accounts" class="margin-top">
                <ul>
                  <li>{{account.sort_code}}</li>
                  <li>{{account.account_number}}</li>
                  <li>{{account.account_holder_name}}</li>
                  <li>{{account.bank_name}}</li>
                </ul>
              </div>
              </ng-container>

            </span>

              <span *ngIf="item.title === 'Source'" class="table-column-small" [ngClass]="{'show-account ': element.show_account}">
                <span class="card__info-banner card__info-banner--clifton" [ngClass]="{'card__info-banner--ambra-vale': element.source === 'Off platform', 'card__info-banner--danger': (element.source === 'Non client transaction' || element.source === 'Refund Transaction')}"> {{element.source}}</span>
            <div>
            </div>
            </span>

              <span *ngIf="item.title === ' '" [ngClass]="{'show-account ': element.show_account}" class="flex flex-row ">

                <button [matMenuTriggerFor]="menu" class="btn__ellipsis mr_5">
                <span class="vertical__ellipsis"></span>
              </button>
                <mat-menu #menu="matMenu">
                <bunk-dialog [component]="source" (dialogRef)="dref = $event">
                <button mat-menu-item>Update Source</button>
                </bunk-dialog>
                <button mat-menu-item (click)="matchTransaction(element.transaction_id)">Match Transaction</button>
                  <button class="btn btn--sm menu__item" mat-menu-item (click)="openFirestoreDoc('transactions', element.transaction_id)">
                    <i class="material-icons btn-icons">open_in_new</i>
                    View transaction Doc
                  </button>
                     <!--                <button mat-menu-item></button>-->
              </mat-menu>

              <bunk-dialog [component]="reference" (dialogRef)="dref = $event">
              <button *ngIf="!element.has_payment_association && !element.has_multiple_tenancies && element.source === 'Client transaction'"
                      [disabled]="disabledButtons.includes(element.transaction_id)"
                      class="btn__generic btn--sm" type="button" (click)="patchValueReference(element.long_description)">Match references</button>
              </bunk-dialog>


                <bunk-dialog [component]="tenancy" (dialogRef)="dref = $event">
              <button [disabled]="disabledButtons.includes(element.transaction_id)" *ngIf="element.has_multiple_tenancies && !element.has_payment_association && element.source === 'Client transaction'"
                      class="btn__generic btn--sm" type="button" (click)="initPropertyForm()">Match tenancy</button>
              </bunk-dialog>

                <ng-template #source>

                  <form  [formGroup]="sourceGroup" class="dialog-container" (ngSubmit)="updateSource(sourceGroup.getRawValue(), element.transaction_id)">
                    <h3 class="text-center text--lg margin-bottom">Update source</h3>
                    <div class="flex flex-column">
                    <span class="text-center text--md">What would you like to update the source to?</span>
                    <mat-form-field appearance="outline">
                       <mat-select formControlName="source">
                                  <mat-option *ngFor="let source of sourcesFields" [value]="source.value">
                                      {{source.view}}
                                  </mat-option>
                              </mat-select>
                    </mat-form-field>
                    </div>


                    <div class="flex flex-row justify-between">
                    <button type="button" class="btn__seethrough" (click)="dref.close()">Cancel</button>
                    <button type="submit" class="btn__generic btn__form-submit">
                      <processing-spinner *ngIf="showSpinner" color="#FFFFFF"></processing-spinner>
                      Save</button>
                    </div>
                  </form>

                </ng-template>


                <ng-template #reference>

                  <form [formGroup]="referenceOutlawGroup" class="dialog-container">

                    <h3 class="text-center text--lg margin-bottom"> Match Reference</h3>

                    <div class="flex flex-column">
                    <span class="text-center text--md">What reference did the tenant use?</span>
                    <mat-form-field appearance="outline">
                      <input formControlName="reference_outlaw" matInput type="text">
                    </mat-form-field>
                    </div>

                    <div class="flex flex-column">
                    <span class="text-center text--md">What reference <strong>should</strong> the tenant use?</span>
                      <span class="text-center">Note: the system can take 15 minutes to update</span>
                    <mat-form-field appearance="outline">
                      <input formControlName="user_reference_code" matInput type="text" (input)="textChange($event)">
                    </mat-form-field>
                    </div>
                    <ng-container *ngIf="hasConfirmed">
                         <ng-container *ngIf="combined$ | async as data">
                     <ng-container *ngIf="!data.name">
                       <div class="block-info block-info--warning">
                         We could not find any payments matching that reference code.
                       </div>
                     </ng-container>
                      <ng-container *ngIf="data.name">
                        <p>We have found the following payment matching this reference code: </p>
                         <div class="flex flex-column margin-bottom--md">
                           <small> <strong>Name:</strong> {{data.name}}</small>
                             <small> <strong>Due Date:</strong> {{data.due_date}}</small>
                             <small> <strong>Payment type:</strong> {{data.payment_type}}</small>
                       </div>
                      </ng-container>
                    </ng-container>
                    </ng-container>
                    <div class="flex flex-row justify-between">
                    <button type="button" class="btn__seethrough" (click)="dref.close()">Cancel</button>
                     <button *ngIf="!hasConfirmed" type="button" class="btn__generic btn__form-submit" (click)="getPaymentData()">
                      <processing-spinner *ngIf="showSpinner" color="#FFFFFF"></processing-spinner>
                      Confirm reference</button>
                    <button *ngIf="hasConfirmed" type="button" class="btn__generic btn__form-submit" (click)="matchReference(referenceOutlawGroup.getRawValue(), element.transaction_id)">
                      <processing-spinner *ngIf="showSpinner" color="#FFFFFF"></processing-spinner>
                      Save</button>
                    </div>
                  </form>
                </ng-template>

                <ng-template #tenancy>

                  <form [formGroup]="matchTenancyGroup" (ngSubmit)="matchTenancy(matchTenancyGroup.getRawValue(), element.transaction_id)" class="dialog-container">
                    <h3 class="text-center text--lg margin-bottom"> Match Tenancy</h3>
                    <span class="text-center text--md ">Find the tenancy this transaction is associated with</span>
                    <div class="margin-top">
                   <form-get-tenancy-details [resetForm]="resetForm" [formControlName]="'property_details'" [submitAttempted]="submitAttempted"  [showReference]="false" [showTenants]="false"></form-get-tenancy-details>
                    </div>
<!--                    <div class="flex flex-column">-->
<!--                    <mat-form-field appearance="outline">-->
<!--                      <input formControlName="tenancy_id" matInput type="text">-->
<!--                    </mat-form-field>-->
<!--                    </div>-->

                    <div class="flex flex-row justify-between">
                    <button type="button" class="btn__seethrough" (click)="dref.close()">Cancel</button>
                    <button type="submit" class="btn__generic btn__form-submit">
                      <processing-spinner *ngIf="showSpinner" color="#FFFFFF"></processing-spinner>
                      Save</button>
                    </div>
                  </form>

                </ng-template>

            </span>

            </td>
          </ng-container>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

      </table>
    </div>


    <mat-paginator [disabled]="showSpinner" [pageSizeOptions]="pageSize" [pageIndex]="page" [length]="totalHits" (page)="updatePage($event)"></mat-paginator>

  `,
  styleUrls: ['./transactions-table.component.scss'],

})

export class TransactionsTableComponent implements OnInit {
  public paymentData$: Observable<any>;
  public user$: Observable<any>;
  public combined$: Observable<any>;
  public dataSource;
  public dref;
  public downloadData = [];
  public page;
  public hitsPerPage;
  public overRef;
  public pageSize = TABLE_ITEMS_PP;
  public showSpinner = false;
  public referenceOutlawGroup: FormGroup;
  public matchTenancyGroup: FormGroup;
  public sourceGroup: FormGroup;
  public disabledButtons: any = [];
  public sourcesFields = SOURCES;
  public submitAttempted = false;
  public resetForm = false;
  public client_data = environment.client_data;
  public hasConfirmed = false;

  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginate: MatPaginator;
  @Output() pagination = new EventEmitter();

  @Input() totalHits;
  @Input() displayedColumns;
  @Input() columnsToDisplay;

  @Input() get itemsPerPage() {
    return this.hitsPerPage;
  }

  set itemsPerPage(event: any) {
    this.hitsPerPage = event;
  }

  @Input()
  get data() {
    console.log(this.dataSource, 'datasourcce')
    return this.dataSource;
  }

  set data(tableData: any) {
    this.dataSource = new MatTableDataSource<any>(tableData);
    // this.dataSource.paginator = this.paginate;
    this.dataSource.sort = this.sort;

  }

  @Input()
  get pageNumber() {
    return this.page;
  }

  set pageNumber(event: any) {
    this.page = event;
  }

  constructor(private _router: Router,
              private _user: UserService,
              private _admin: AdminService,
              private _formBuilder: FormBuilder,
              private _toastr: ToastrService,
              private _payments: PaymentsService
  ) {
  }

  get user_reference_code(): FormGroup {
    return this.referenceOutlawGroup.get('user_reference_code') as FormGroup;
  }
  ngOnInit() {
    this.referenceOutlawGroup = this._formBuilder.group({
      reference_outlaw: '',
      user_reference_code: '',
    });

    this.initPropertyForm();

    this.sourceGroup = this._formBuilder.group({
      source: ''
    });
  }

  initPropertyForm(){
    this.matchTenancyGroup = this._formBuilder.group({
      property_details: ''
    });
  }


  updatePage(event: any) {
    this.pagination.emit({hitsPerPage: event.pageSize, page: event.pageIndex});
  }

  getAllData() {
    this.showSpinner = true;
    this.downloadData = [...this.dataSource.data, ...this.downloadData];

    if (this.downloadData.length < this.totalHits) {
      this.pagination.emit({hitsPerPage: this.hitsPerPage, page: this.page + 1});
      return setTimeout(() => {
        return this.getAllData();
      }, 500);
    }


    this.showSpinner = false;
    this.downloadData = [];
    this.pagination.emit({hitsPerPage: this.hitsPerPage, page: 0});
    this.dref.close();
  }

  getPaymentData() {
    this.paymentData$ = this._payments.getPaymentsByReferenceCode(this.user_reference_code.value).valueChanges().pipe(
      map((data: any) => {
        const payment =  (data && data.length > 0) ? data[0] : null;
        return (payment)
          ? {
          due_date: moment(payment.due_date.toDate()).format('Do MMM YYYY'),
          uid: payment.sender_uid,
          payment_type: payment.payment_type,
        }
        : '';
      }),
      // map(() => {
      //
      // })
    );

    this.user$ = this.paymentData$.pipe(
      flatMap((payment: any) => (payment && payment.uid) ? this._user.getUserById(payment.uid).valueChanges() : of(null))
    );
    this.hasConfirmed = true;
    this.combined$ = combineLatest([this.paymentData$, this.user$]).pipe(
      map(([payment, user]) => {
        return (payment && user) ? {
          ...payment,
          name: (user && user.profile_data) ? user.profile_data.full_name : ''
        } : {};
      }),
      tap((data: any) => console.log({data}))
    );
  }

  textChange(event: any) {
    console.log({event})
      this.hasConfirmed = false;
  }
  matchReference(form: any, id: string) {
    this.disabledButtons.push(id);
    const index = this.disabledButtons.indexOf(id);

    this.referenceOutlawGroup.disable();
    this.showSpinner = true;
    this._admin.createReferenceOutlaw(form).pipe(
      finalize(() => {
        this.showSpinner = false;
        this.referenceOutlawGroup.enable();
        this.hasConfirmed = false
      })
    ).subscribe(() => {
      this._toastr.success('We are busy checking the reference come back later...🦁');
      this.matchTransaction(id);
      this.dref.close();
    }, error => {
      this.disabledButtons.splice(index, 1);
      this.showSpinner = false;
      this.referenceOutlawGroup.enable();
    });
  }
  matchTransaction(id: string) {
    /** @todo: make available for all envs after testing on Livingway */
    if (environment.firebaseConfig.projectId === 'client-living-way') {
      this._admin.mapTransactionById(id).subscribe(
        () => { this._toastr.success('Transaction matched'); },
        error => { this._toastr.error('Transaction can\'t be matched'); }
      );
    }
  }

  patchValueReference(ref: string) {
    this.referenceOutlawGroup.get('reference_outlaw').patchValue(ref);
    this.referenceOutlawGroup.get('reference_outlaw').disable();
  }

  matchTenancy(form: any, transaction_id: string) {
    if(!form.property_details.tenancy_id) {
      return this._toastr.error('Please select a unit with a tenancy')
    }

    this.matchTenancyGroup.disable();
    this.showSpinner = true;

    this.disabledButtons.push(transaction_id);
    const index = this.disabledButtons.indexOf(transaction_id);
    console.log(form.property_details.tenancy_id, 'the form gellooo ');

    this._admin.matchTenancyToTransaction(transaction_id, form.property_details.tenancy_id).pipe(
      finalize(() => {
        this.showSpinner = false;
        this.referenceOutlawGroup.enable();
      })
    ).subscribe(() => {
      this._toastr.success('We have matched the tenancy!!🦦');
      this.dref.close();
    }, error => {
      this._toastr.error('Im not sure that is an tenancy id 👀');
      this.showSpinner = false;
      this.matchTenancyGroup.enable();
      this.disabledButtons.splice(index, 1);
      this.referenceOutlawGroup.enable();
    });
  }

  updateSource(sourceGroup: any, id: string){
    this.disabledButtons.push(id);
    const index = this.disabledButtons.indexOf(id);
    this._admin.updateTransaction({source: sourceGroup.source}, id).pipe(
      finalize(() => this.dref.close()))
      .subscribe(() => this._toastr.success('Source updated. Refresh the page 🐆'),
        error => {this._toastr.error('Something went wrong try again'), this.showSpinner = false, this.disabledButtons.splice(index, 1);
        } );

  }

  openFirestoreDoc(type: string, id: string) {
    const url = `${environment.firebaseConfig.firestoreUrl}${type}~2F${id}`;
    window.open(url, '_blank');
  }

}

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./loading-spinner.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "./loading-spinner.component";
var styles_LoadingSpinnerComponent = [i0.styles];
var RenderType_LoadingSpinnerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_LoadingSpinnerComponent, data: {} });
export { RenderType_LoadingSpinnerComponent as RenderType_LoadingSpinnerComponent };
export function View_LoadingSpinnerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 12, "div", [["class", "sk-circle"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "div", [["class", "sk-circle1 sk-child"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 0, "div", [["class", "sk-circle2 sk-child"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "div", [["class", "sk-circle3 sk-child"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "div", [["class", "sk-circle4 sk-child"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "div", [["class", "sk-circle5 sk-child"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "div", [["class", "sk-circle6 sk-child"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, "div", [["class", "sk-circle7 sk-child"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 0, "div", [["class", "sk-circle8 sk-child"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 0, "div", [["class", "sk-circle9 sk-child"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 0, "div", [["class", "sk-circle10 sk-child"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 0, "div", [["class", "sk-circle11 sk-child"]], null, null, null, null, null)), (_l()(), i1.ɵeld(12, 0, null, null, 0, "div", [["class", "sk-circle12 sk-child"]], null, null, null, null, null)), (_l()(), i1.ɵeld(13, 0, null, null, 1, "div", [["class", "message"]], null, null, null, null, null)), (_l()(), i1.ɵted(14, null, ["", ""]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.text_message; _ck(_v, 14, 0, currVal_0); }); }
export function View_LoadingSpinnerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "loading-spinner", [], null, null, null, View_LoadingSpinnerComponent_0, RenderType_LoadingSpinnerComponent)), i1.ɵdid(1, 114688, null, 0, i2.LoadingSpinnerComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var LoadingSpinnerComponentNgFactory = i1.ɵccf("loading-spinner", i2.LoadingSpinnerComponent, View_LoadingSpinnerComponent_Host_0, { text_message: "text_message" }, {}, []);
export { LoadingSpinnerComponentNgFactory as LoadingSpinnerComponentNgFactory };

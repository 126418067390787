import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { combineLatest, from, from as fromPromise, Observable, of, zip } from 'rxjs';
import { AuthService } from '@app/core/services/auth.service';
import { UserService } from '@app/services/user.service';
import { last, map, switchMap, tap } from 'rxjs/operators';
import { IMedia } from '@rentbunk/bunk-models';
import { StoredMedia } from '@app/core-landlord/property/property-list-form-new/property-list-info-form-new/property-list-certificates-form.component';
export interface ImageUrlsModel {
  image_small_url: string;
  image_large_url: string;
  image_original_url: string;
  image_full_path: string;
}
interface mediaRaw { file: Partial<File>; image: Partial<ImageUrlsModel>; extra: Partial<IMedia> }
@Injectable({
  providedIn: 'root'
})
export class MediaService {
  public notesCollection: AngularFirestoreCollection<any>;

  constructor(private readonly afs: AngularFirestore, private _auth: AuthService, private _user: UserService) {
    this.notesCollection = afs.collection<any>('media');
  }


  addMedia(media_data: Partial<mediaRaw>): Observable<any> {
    const temp_id = this.afs.createId();
    const date = new Date();
    const response = this.afs.collection('media').doc(temp_id).set({ ...this.formatMediaDoc(media_data), media_id: temp_id, date_created: date, date_modified: date, date_deleted: null, is_deleted: false });
    return fromPromise(response).pipe(last(), map(() => temp_id, err => ''))
  }

  addMedias(medias: Partial<mediaRaw>[]) {
    return of(medias).pipe(
      map(medias => medias.map(media => this.addMedia(media))),
      switchMap(medias => zip(...medias))
    )
  }

  updateMedia(id: string, item: Partial<IMedia>) {
    return fromPromise(this.afs.collection('media').doc(id).set(item, { merge: true }));
  }

  updateMedias(medias: { id: string, update: Partial<IMedia> }[]) {
    medias.forEach(media => {
      this.updateMedia(media.id, { ...media.update, date_modified: new Date() })
    })
  }

  getMediaById(id: string): Observable<any> {
    return this.afs.collection('media').doc(id).valueChanges()
  }

  getMediaInfo(id: string): Observable<any> {
    return this.getMediaById(id).pipe(map((res: any) => {
      return {
        ...res,
        name: res ? res.file_name : '',
        image_large_url: res ? res.thumbnail_large : '',
        image_small_url: res ? res.thumbnail_small : '',
        image_original_url: res ? res.url : '',
        image_full_path: res ? res.full_path : '',
        image_url: res ? res.thumbnail_large : '',
        media_id: res ? res.media_id : ''
      }
    }))
  }

  getMediaFromIds(ids: Array<string>): Observable<any> {
    return of(ids).pipe(map(ids => {
      return ids.map(id => this.getMediaInfo(id))
    }),
      switchMap(res => zip(...res)),
    )
  }

  removeMediaById(id: string) {
    const data = { is_deleted: true, date_deleted: new Date() };
    return fromPromise(this.afs.collection('media').doc(id).set(data, { merge: true }));

  }

  removeMediaByIds(ids: string[]) {
    ids.map(id => this.removeMediaById(id))
  }

  formatMediaDoc(media_data: any) {
    return {
      description: media_data && media_data.extra && media_data.extra.description || null,
      type: media_data && media_data.extra && media_data.extra.type || null,
      file_name: media_data && media_data.file && media_data.file.name || null,
      view_name: media_data && media_data.extra && media_data.extra.view_name || null,
      full_path: media_data && media_data.image && media_data.image.image_full_path || null,
      mime_type: media_data && media_data.file && media_data.file.type || null,
      url: media_data && media_data.image && media_data.image.image_original_url || null,
      thumbnail_small: media_data && media_data.image && media_data.image.image_small_url || null,
      thumbnail_large: media_data && media_data.image && media_data.image.image_large_url || null,
      creator_uid: this._auth.currentUserId,
      team_id: this._user.userReadOnly && this._user.userReadOnly.team_ids || null,
      size: media_data && media_data.file && media_data.file.size || null,
      date_expires: media_data && media_data.extra && media_data.extra.date_expires || null,
      epc_rating: media_data && media_data.extra && media_data.extra.epc_rating || null,
      property_ids: media_data && media_data.extra && media_data.extra.property_ids || [],
      tenancy_ids: media_data && media_data.extra && media_data.extra.tenancy_ids || [],
      unit_ids: media_data && media_data.extra && media_data.extra.unit_ids || [],
      tenants_ids: media_data && media_data.extra && media_data.extra.tenants_ids || [],
    }
  }

  generateMetaData(media: StoredMedia, expiry: Date, mediaName: string, propertyId: string, epc_rating: string, type = 'property_documents') {
    const file = {
      name: media.metadata.name,
      type: media.metadata.contentType,
      size: media.totalBytes
    };
    const image = {
      image_full_path: media.metadata.fullPath,
      image_original_url: media.url,
    };

    const extra = {
      date_expires: expiry ? new Date(expiry) : null,
      type,
      view_name: mediaName,
      property_ids: [propertyId],
      epc_rating: epc_rating
    };

    return { file, image, extra };
  }
}

import { Injectable } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class FormsService {

  toFormGroup(fieldArray: Array<any>): FormGroup { 
    const group: any = {};
    fieldArray.map((field) => {
      group[field] = new FormControl( null, Validators.required)}
    );
    return new FormGroup(group);
  }




}
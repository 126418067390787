import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AuthService} from '@app/core/services';
import {StorageService} from '@app/services';
import {BunkUploaderModel} from '@shared/_components';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';

@Component({
  selector: 'document-upload-box',
  templateUrl: './document-upload-box.component.html',
  styleUrls: ['./document-upload-box.component.scss']
})
export class DocumentUploadBoxComponent implements OnInit {
  @Input() customClasses: string;
  @Input() verificationDocumentType: string;
  @Input() verificationTypeTitle: string;
  @Input() svgIcon: string;
  @Input() documentTypesList: Array<object> = [];
  @Input() propertyId: number;
  @Input() documentMaxSizeMb: number;
  @Input() uploadedDocumentType: string;
  @Input() uploadedFileOriginalUrl: string;
  @Input() uploadedFileName: string;
  @Output() documentFileChanged: EventEmitter<object> = new EventEmitter();
  @Output() documentTypeChanged: EventEmitter<object> = new EventEmitter();
  formGroup: FormGroup;
  imageFilePath: string;
  errorsMessage: string;
  processingFile = false;

  constructor(
    private _auth: AuthService,
    private _storage: StorageService,
    private _formBuilder: FormBuilder,
  ) {
  }

  ngOnInit() {
    this.imageFilePath = `user/${this._auth.currentUserId}/property/${this.propertyId}`;
  }

  onUpStart() {
    this.processingFile = true;
    this.uploadedFileOriginalUrl = '';
    this.uploadedFileName = '';
    this.errorsMessage = '';
    this.documentFileChanged.emit({
      verificationDocumentType: this.verificationDocumentType,
      uploadedFileFullPath: '',
      uploadedFileOriginalUrl: ''
    });
  }

  onUploadedFile(task: BunkUploaderModel) {
    if (task.file && task.image) {
      if (task.file.size > this.documentMaxSizeMb * 1024 ** 2) {
        this.processingFile = false;
        this.errorsMessage = `Document maximum size will be ${this.documentMaxSizeMb}MB`;
      } else {
        this.uploadedFileOriginalUrl = task.image.image_original_url;
        this.uploadedFileName = this.beautifyPath(task.image.image_full_path);
        this.processingFile = false;
        this.documentFileChanged.emit({
          verificationDocumentType: this.verificationDocumentType,
          uploadedFileFullPath: task.image.image_full_path,
          uploadedFileOriginalUrl: task.image.image_original_url
        });
      }
    } else {
      this.processingFile = false;
      this.errorsMessage = `There was a problem uploading file.`;
    }
  }

  documentTypeChange() {
    this.documentTypeChanged.emit({
      verificationDocumentType: this.verificationDocumentType,
      uploadedDocumentType: this.uploadedDocumentType}
    );
  }

  beautifyPath(path: string) {
    const arr = path.split('/');
    return arr[arr.length - 1];
  }
}

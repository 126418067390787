import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared';
import { NavigationModule } from '@app/core/navigation/navigation.module';
import { PasswordStrengthBarModule } from 'ng2-password-strength-bar';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatListModule } from '@angular/material/list';

import { SigninComponent } from '@app/login/signin/signin.component';
import { SignupComponent } from '@app/login/signup/signup.component';
import { PhoneVerificationService } from '@app/login/services/phone-verification.service';
import { UtilsModule } from '@app/core/utils/utils.module';
import {ActivateAccountComponent} from '@app/login/signin/activate-account.component';
import {ResetPasswordComponent} from '@app/login/reset-password/reset-password.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    PasswordStrengthBarModule,
    NavigationModule,
    UtilsModule,
    MatIconModule,
    MatSidenavModule,
    MatButtonModule,
    MatListModule
  ],
  declarations: [
    SigninComponent,
    SignupComponent,
    ActivateAccountComponent,
    ResetPasswordComponent
  ],
  exports: [
    SigninComponent,
    SignupComponent,
  ],
  providers: [
    PhoneVerificationService
  ]
})
export class LoginModule {
}

import { Injectable } from '@angular/core';
import { from } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { of } from 'rxjs/internal/observable/of';
import { Observable } from 'rxjs/Observable';
import { ActivatedRoute } from '@angular/router';

@Injectable()
export class AlgoliaService {

  constructor(private _route: ActivatedRoute) {
  }

  getFilteredAlgoliaData(index: any, filters: any, query: string, pagination: any): Observable<any> {
    const { hitsPerPage, page } = pagination
    return from(index.search(
      {
        ...hitsPerPage && { hitsPerPage },
        ...page && { page },
        ...filters && { filters },
        ...query && { query }
      }
    )).pipe(
      map((res: any) => {
        return {
          combined: res.hits,
          totalHits: res.nbHits,
        };
      }),
      catchError((res: any) => {
        return of('No index')
      }));
  }
}

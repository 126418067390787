/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./processing-spinner.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./processing-spinner.component";
var styles_ProcessingSpinnerComponent = [i0.styles];
var RenderType_ProcessingSpinnerComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_ProcessingSpinnerComponent, data: {} });
export { RenderType_ProcessingSpinnerComponent as RenderType_ProcessingSpinnerComponent };
export function View_ProcessingSpinnerComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 10, "div", [["class", "circle-loader"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "load-complete": 0 }), i1.ɵprd(512, null, i2.ɵNgStyleImpl, i2.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(5, 278528, null, 0, i2.NgStyle, [i2.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(6, { "border-color": 0, "border-left-color": 1, "width": 2, "height": 3 }), (_l()(), i1.ɵeld(7, 0, null, null, 3, "div", [["class", "checkmark draw"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgStyleImpl, i2.ɵNgStyleR2Impl, [i1.ElementRef, i1.KeyValueDiffers, i1.Renderer2]), i1.ɵdid(9, 278528, null, 0, i2.NgStyle, [i2.ɵNgStyleImpl], { ngStyle: [0, "ngStyle"] }, null), i1.ɵpod(10, { "display": 0, "border-right-color": 1, "border-top-color": 2 })], function (_ck, _v) { var _co = _v.component; var currVal_0 = "circle-loader"; var currVal_1 = _ck(_v, 3, 0, _co.complete); _ck(_v, 2, 0, currVal_0, currVal_1); var currVal_2 = _ck(_v, 6, 0, (_co.complete ? _co.color : "initial"), _co.color, _co.size, _co.size); _ck(_v, 5, 0, currVal_2); var currVal_3 = _ck(_v, 10, 0, (_co.complete ? "block" : "none"), _co.color, _co.color); _ck(_v, 9, 0, currVal_3); }, null); }
export function View_ProcessingSpinnerComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "processing-spinner", [], null, null, null, View_ProcessingSpinnerComponent_0, RenderType_ProcessingSpinnerComponent)), i1.ɵdid(1, 114688, null, 0, i3.ProcessingSpinnerComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ProcessingSpinnerComponentNgFactory = i1.ɵccf("processing-spinner", i3.ProcessingSpinnerComponent, View_ProcessingSpinnerComponent_Host_0, { color: "color", complete: "complete", size: "size" }, {}, []);
export { ProcessingSpinnerComponentNgFactory as ProcessingSpinnerComponentNgFactory };

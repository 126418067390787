
    <ng-container *ngIf="(passport$ | async) as passport">
      <div>
        <h2 class="text--lg">Your credit history</h2>
        <p>Please complete the forms below. This information is stored securely and we will only process this information once you have had an offer accepted on a property.</p>
      </div>

      <form class="cc w_100" [formGroup]="formGroup" (ngSubmit)="formGroup.valid && nextStep(formGroup.get('credit_info').value)">
        <form-credit-info formControlName="credit_info"></form-credit-info>

      </form>
    </ng-container>
  
<h3 class="margin-bottom--md">{{isRentSchedule ? 'Add' : 'Create'}} Payment</h3>
<form [formGroup]="formGroup" autocomplete="none" (ngSubmit)="submitForm()">

  <bunk-form-field [showLabel]="true">
    <label bunk-label>What is the payment amount?</label>
    <small bunk-label-stack>If you are creating a quarterly payment, please enter the amount for an entire quarter.</small>
    <mat-form-field bunk-input appearance="outline">
      <input type="number" [formControlName]="'amount'" matInput (change)="generateSchedule()">
      <mat-error>Payment amount is required</mat-error>
    </mat-form-field>
  </bunk-form-field>

    <bunk-form-field [showLabel]="true">
      <label bunk-label>What is the payment type?</label>
      <mat-form-field  bunk-input appearance="outline">
        <mat-select [formControlName]="'payment_type'" (selectionChange)="generateSchedule()">
          <mat-option *ngFor="let status of paymentTypes" [value]="status.value">
            {{status.viewValue}}
          </mat-option>
        </mat-select>
        <mat-error>Payment type is required</mat-error>
      </mat-form-field>
    </bunk-form-field>


  <bunk-form-field [showLabel]="true">
    <label bunk-label>How often do you want to collect the payment?</label>
    <mat-form-field  bunk-input appearance="outline">
      <mat-select [formControlName]="'payment_frequency'" (selectionChange)="setLastPaymentValidation($event); generateSchedule()">
        <mat-option [value]="'monthly'">Monthly</mat-option>
        <mat-option [value]="'quarterly'">Quarterly</mat-option>
        <mat-option [value]="'one_time'">One off payment</mat-option>
      </mat-select>
      <mat-error>Payment frequency is required</mat-error>
    </mat-form-field>
  </bunk-form-field>

  <bunk-form-field [showLabel]="true">
    <label bunk-label>When is the <span *ngIf="payment_frequency.value && payment_frequency.value !== 'one_time'">first</span> payment due?</label>
    <mat-form-field  bunk-input appearance="outline">
      <input [formControlName]="'due_date'" matInput
             (click)="dueDate.open()"
             [matDatepicker]="dueDate"
             [min] = "datePickerMin"
             (dateChange)="generateSchedule()"
      >
      <mat-datepicker-toggle matSuffix [for]="dueDate"></mat-datepicker-toggle>
      <mat-datepicker #dueDate ></mat-datepicker>
      <mat-error>Due date is required</mat-error>
    </mat-form-field>
  </bunk-form-field>

  <bunk-form-field [showLabel]="true" *ngIf="payment_frequency.value && payment_frequency.value !== 'one_time'">
    <label bunk-label>How many {{payment_frequency.value}} payments do you want to create?</label>
    <mat-form-field  bunk-input appearance="outline">
      <input [formControlName]="'number_of_payments'" type="number" matInput (change)="generateSchedule()">
      <mat-error>The number of payments is required</mat-error>
    </mat-form-field>
  </bunk-form-field>

  <div class="block-info block-info--info" *ngIf="schedule">
    <small>{{schedule}}</small>
  </div>

  <ng-container *ngIf="!isRentSchedule">
  <bunk-form-field [showLabel]="true">
    <label bunk-label>What is the status of the <span *ngIf="payment_frequency.value && payment_frequency.value !== 'one_time'">first</span> payment?</label>
    <mat-form-field  bunk-input appearance="outline">
      <mat-select [formControlName]="'status'" (selectionChange)="setTimestampPaidValidation($event)">
        <mat-option *ngFor="let status of paymentStatuses" [value]="status.value">
          {{status.viewValue}}
        </mat-option>
      </mat-select>
      <mat-error>Payment status required</mat-error>
    </mat-form-field>
  </bunk-form-field>
  </ng-container>

  <bunk-form-field [showLabel]="true" *ngIf="status.value === 'paid'">
    <label bunk-label>When was the <span *ngIf="payment_frequency.value && payment_frequency.value !== 'one_time'">first</span> payment paid?</label>
    <mat-form-field  bunk-input appearance="outline">
      <input [formControlName]="'timestamp_paid'" matInput
             (click)="datePaid.open()"
             [matDatepicker]="datePaid"
      >
      <mat-datepicker-toggle matSuffix [for]="datePaid"></mat-datepicker-toggle>
      <mat-datepicker #datePaid ></mat-datepicker>
      <mat-error>Date paid is required</mat-error>
    </mat-form-field>
  </bunk-form-field>

  <ng-container *ngIf="isRentSchedule">
  <ng-container *ngIf="{ tenancyAgreement: tenancyAgreement$ | async , tenants: tenants$ | async} as values">
  <ng-container *ngIf="values.tenants && values.tenants.length > 1">
<bunk-form-field [showLabel]="true">
<label bunk-label>Who will be making this payment?</label>
<mat-form-field  bunk-input appearance="outline">
  <mat-select [formControlName]="'scheduleTenants'" [multiple]="true">
    <mat-option *ngFor="let tenant of values.tenants" [value]="tenant.uid">
      {{tenant.profile_data.full_name}}
    </mat-option>
  </mat-select>
  <mat-error>Atleast one tenant should be selected</mat-error>
</mat-form-field>
</bunk-form-field>
</ng-container>
</ng-container>
</ng-container>

<ng-container *ngIf="( payments$ | async ) as payments_overlap">
    <div class="block-info block-info--info" *ngIf=" payments_overlap.length">
       <small>Are you sure you want to generate an additional {{payments_overlap.length}} parking payments for the same month</small>
    </div>
</ng-container>

<ng-container *ngIf="!isRentSchedule" >
  <form-get-tenancy-details [resetForm]="resetForm" [formControlName]="'property_details'" [submitAttempted]="submitAttempted"></form-get-tenancy-details>
  </ng-container>
  <div class="margin-top--lg flex justify-end">
    <button  class="btn__generic btn__form-submit btn__form-submit--align-right" type="submit">
      <span *ngIf="!submittingData && !submittingDataSuccess"> {{isRentSchedule ? 'Add' : 'Create'}} Payment</span>
      <processing-spinner *ngIf="submittingData || submittingDataSuccess" color="#FFFFFF" [complete]="submittingDataSuccess"></processing-spinner>
    </button>
  </div>
</form>
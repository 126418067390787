import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  ViewChildren,
  QueryList,
  ChangeDetectorRef
} from '@angular/core';
import { environment } from '@env/environment';
import { MatCheckbox } from '@angular/material/checkbox';
import { AuthService } from '@app/core/services';
import algoliasearch from 'algoliasearch/lite';
import instantsearch from 'instantsearch.js';
import { connectHits } from 'instantsearch.js/cjs/connectors';
import * as _ from 'lodash';

@Component({
  selector: 'instant-algolia-search',
  template: `
    <div class="i-search" *ngIf="resultsList">
      <img class="i-search__input-icon" src='/assets/svg/baseline-search-24px.svg' alt="Search...">
      <button *ngIf="searchInput.value.length > 1" class="i-search__input-icon-close" (click)="clearSearch()">
        <img src='/assets/svg/baseline-close-24px.svg' alt="Close list" (click)="hideResults = true">
      </button>
      <input #searchInput
        placeholder="{{ !disabled ? message : disabledMessage }}"
        autocomplete="off"
        class="i-search__input"
        [disabled]="disabled"
        [ngClass]="{ 'i-search__input--active': showHits }"
        (input)="setSearchQuery($event)"
        (click)="setSearchQuery($event)"
        (keydown.esc)="hideSearchResults()"/>
      <small></small>
      <div #searchHits
        class="i-search__hits-container"
        *ngIf="showHits"
        (clickOutside)="clickHideSearchResults($event)">
        <ng-container *ngFor="let hit of resultsList">
          <ng-container *ngIf="indexName === 'USERS-full_name' && hit.role === 'tenant'">
            <ng-container *ngTemplateOutlet="algoliaUsersHit; context: {hit: hit}"></ng-container>
          </ng-container>
          <ng-container *ngIf="indexName === 'universities'">
            <ng-container *ngTemplateOutlet="algoliaUniversities; context: {hit: hit}"></ng-container>
          </ng-container>
          <ng-container *ngIf="indexName === 'properties'">
            <ng-container *ngTemplateOutlet="algoliaProperties; context: {hit: hit}"></ng-container>
          </ng-container>
        </ng-container>
      </div>
    </div>


    <ng-template #algoliaProperties let-hit="hit">

      <label type="label" (click)="selectAlgoliaProperty(hit.property_id)" *ngIf="storedHits.includes(hit.property_id)">
        <div class="i-search__hit-row"
            tabindex="0"
            aria-role="button"
            attr.aria-label="Add {{hit.name}}">
          <p>{{hit.address.first_line_address}}{{(hit.address.second_line_address ? ',' : '')}}
            <span *ngIf="hit.address.second_line_address">{{hit.address.second_line_address | truncate:[25, '...']}}</span>
          </p>


          <mat-checkbox
            *ngIf="multiselect"
            class="prompt"
            #checkbox
            [id]="hit.property_id">
          </mat-checkbox>

        </div>
      </label>
    </ng-template>

    <ng-template #algoliaUsersHit let-hit="hit">
      <div class="i-search__hit-row"
        tabindex="0"
        aria-role="button"
        attr.aria-label="Add {{ hit.profile_data.full_name }}"
        (click)="getSelectedId(hit)"
        (keydown.space)="getSelectedId(hit)"
        (keydown.enter)="getSelectedId(hit)"
        (keydown.esc)="hideSearchResults()">
<!--
        <label type="label">
        <div class="i-search__hit-row"
            tabindex="0"
            aria-role="button"
            attr.aria-label="Add {{hit.name}}">
            <p>{{hit.profile_data.full_name}}</p>

           <mat-checkbox
            class="prompt"
            #checkbox
            [id]="hit.uid">
          </mat-checkbox>
        </div>
      </label> -->

          <profile-avatar size="sm" [profileImgUrl]="hit.profile_data.profile_image_url" [profileName]="hit.profile_data.full_name"></profile-avatar>
          <p>{{hit.profile_data.full_name}}</p>
      </div>
    </ng-template>


    <ng-template #algoliaUniversities let-hit="hit">
      <div class="i-search__hit-row"
        tabindex="0"
        aria-role="button"
        attr.aria-label="Add {{hit.name}}"
        (click)="value = hit"
        (keydown.space)="value = hit"
        (keydown.enter)="value = hit"
        (keydown.esc)="hideSearchResults()">
        <img class="i-search__hit-image" [alt]="hit.name" src="/assets/img/icons/bunk-default-avatar.svg">
        <p class="truncate">{{hit.name}}</p>
        <small class="prompt">Add</small>
      </div>
    </ng-template>
  `,
  styleUrls: ['./instant-algolia-search.component.scss']
})
export class InstantAlgoliaSearchComponent implements OnInit {
  @ViewChild('searchInput', { static: false }) searchInput: ElementRef;
  @ViewChild('searchHits', { static: false }) searchHits: ElementRef;
  @ViewChildren('checkbox') private myCheckboxes: QueryList<MatCheckbox>;

  /** @deprecated selectedId in favor of valueChange */
  @Output() selectedId = new EventEmitter();
  @Output() valueChange: EventEmitter<any> = new EventEmitter<any>();
  @Input() env: 'prod' | 'test';  // force environment prefix for algolia
  @Input() indexName: string;     // algolia table name
  @Input() disabled = false;
  @Input() disabledMessage = '';
  @Input() message = 'Type and search...';
  @Input() ignoreList: string[] = [];     // @todo: works just for uid field at the moment check for dupes, do not display any item from the ignore list prop
  @Input() hideResults = true;
  @Input() showImmediately = false;
  @Input() multiselect = true;
  public noImage = 'https://firebasestorage.googleapis.com/v0/b/bunkapptest.appspot.com/o/default_image%2Fbunk-default-avatar.svg?alt=media&token=573dba30-b1e9-4b6d-99f9-2f80bb0edb0b';
  public showHits = false;
  public search: any;
  public resultsList: any[] = [];
  private instantsearchWidgetHelper: any;
  public searchError: boolean;
  public totalHits: number;

  storedIdsValue: string[];
  storedHits: string[];
  @Output() storedIdsChange: EventEmitter<string[]> = new EventEmitter<string[]>();

  @Input()
  get storedIds(): string[] {
    return this.storedIdsValue;
  }

  @Input()
  get availableHits(): string[] {
    return this.storedHits;
  }

  set availableHits(ids: string[]) {
    this.storedHits = ids;
  }

  set storedIds(ids: string[]) {
    this.storedIdsValue = ids;
    this.storedIdsChange.emit(ids);
  }

  set value(value: any) {
    this.valueChange.emit(value);
    this.clearSearch();
    this.searchInput.nativeElement.focus();
  }


  constructor(
    private ref: ChangeDetectorRef,
    public _auth: AuthService
  ) { }

  ngOnInit() {
    const { apiKey, appId, env } = environment.algolia;
    const indexName = (this.indexName === 'properties') ? `${env}-${this.indexName}` : `${env}_${this.indexName}`;

    this.search = instantsearch({
      indexName,
      searchClient: algoliasearch(appId, apiKey),
      searchFunction: (helper: any) => {
        // skip search on page load....
        // https://www.algolia.com/doc/api-reference/widgets/instantsearch/js/#widget-param-searchfunction
        if (!this.instantsearchWidgetHelper) {
          this.instantsearchWidgetHelper = helper;
        } else {
          helper.search();
        }
      }
    });

    this.search.on('error', () => {
      this.totalHits = 0;
      this.searchError = true;
    });

    // 1. Create a render function
    const renderHits = (opts: any, isFirstRender: boolean) => {
      this.instantsearchWidgetHelper = opts.instantSearchInstance.helper;
      // if the results are identical to the last do nothing, otherwise return the results...
      if (!_.isEqual(this.resultsList, opts.hits)) {
        this.totalHits = opts.results.nbHits; // keep a reference of the total hits (for pagination) that are not filtered by the page factor
        this.resultsList = this.checkForDupes(opts.results.hits);
      } else if (!opts.hits.length) {
        this.totalHits = 0;
      }
      this.searchError = false;
    };

    // 2. Create the custom widget
    const customHits = connectHits(
      renderHits
    );

    // 3. Instantiate
    this.search.addWidgets([
      customHits()
    ]);

    this.search.start();
  }

  selectAlgoliaProperty(id: string): void {
    this.storedIds = this.updateIdArray(id);
  }

  updateCheckboxStatus(): void {
    this.ref.detectChanges();

    setTimeout(() => {
      this.storedIds.forEach(id => {
        const x = this.myCheckboxes.find(((item) => item.id === id));
        if (x) {
          x.checked = true;
        }
      });
    }, 100);
  }

  setSearchQuery(e: any) {
    console.log({ e });
    const searchStr = e.currentTarget.value;
    console.log({ searchStr });

    this.showHits = this.showImmediately || searchStr.length > 1;
    this.instantsearchWidgetHelper.setQuery(searchStr).search();

    // manual update checkbox status
    if (this.storedIds) {
      this.updateCheckboxStatus();
    }
  }

  clearSearch(): void {
    if (this.hideResults) {
      this.hideSearchResults();
    }
    this.searchInput.nativeElement.value = '';
    this.storedIds = [];
  }

  hideSearchResults(): void {
    this.showHits = false;
    this.searchInput.nativeElement.blur();
  }

  clickHideSearchResults(e: any) {
    if (e.target !== this.searchInput.nativeElement && (this.hideResults || e.value)) {
      this.hideSearchResults();
    }
  }

  // @todo: works just for uid field at the moment check for dupes, do not display any item from the ignore list prop
  checkForDupes = (hitsArr): any[] => hitsArr.filter(hit => !this.ignoreList.find(ignoreItem => ignoreItem === hit['uid']));

  /** @deprecated in favor of setter value*/
  getSelectedId(result) {
    this.selectedId.emit(result.uid);
    this.clearSearch();
    this.searchInput.nativeElement.focus();
  }

  updateIdArray(id: string) {
    return (this.storedIds.includes(id)) ? [...this.storedIds.filter(e => e !== id)] : [...this.storedIds, id];
  }
}

import { Component, ViewChild, forwardRef, Optional, Input } from '@angular/core';
import { UserService } from '@app/services/user.service';
import { Tenant } from '@env/routing';
import { of, throwError as _throw, Subject, EMPTY, timer } from 'rxjs';
import { takeUntil, expand, debounce, switchMap, map, delay } from 'rxjs/operators';
import { FormBuilder, FormControl, FormGroup, Validators, NG_VALIDATORS, NG_VALUE_ACCESSOR, FormGroupDirective } from '@angular/forms';
import { BunkPassportService } from '@app/services';
import { ActivatedRoute, Router } from '@angular/router';
import { IdentificationService } from '@app/shared/_services/identification.service';
import { BunkDialogComponent } from '@app/shared/_components';
import { AbstractValueAccessor } from '@app/shared/_components/searches/instant-search/instant-search.utils';
import { FormsService } from '@app/services/form.service';
import { AuthService } from '@app/core/services';
import {environment} from '@env/environment';
import {FORM_IDENTITY} from '@app/shared/_forms/application-forms/application-forms.constants';

@Component({
  selector: 'form-identity-check',
  template: `
  <form [formGroup]="identityForm">
    <div>
      <br>

      <bunk-form-field>
      <label  bunk-label >Please upload a scan or clear photo of your identity</label>
        <small bunk-label-stack  *ngIf="(uploadState !== 'GREEN') && !verificationStatusPending && !verificationApproved">
<!--            <strong>Tips for being approved</strong>-->
            <ul>
              <li>We support: JPEG/JPG, PNG, TIFF, BMP, PDF upto a maximum file size of 5MB. </li>
              <li>Use a scan or a white background for best results</li>
              <li>Make sure the text is clearly visible with no blur</li>
            </ul>
        </small>
      <mat-error class="bunk-mat-error-font-match" bunk-label-stack *ngIf="infoError && !verificationApproved || submitAttempted && !verificationApproved"> Please enter the above information before you upload your document</mat-error>
      <mat-error class="bunk-mat-error-font-match" bunk-label-stack *ngIf="submitAttempted && !verificationApproved "> Please upload your identity document</mat-error>

    <div bunk-input>

    <div class="rc flex_wrap document-upload" >
      <div class="cc w_100">
        <div class="action-bar">

          <ng-container *ngIf="requiredFields.includes('passport')">

          <label for="landlord-verify-passport-upload"
            class="verify-id__card-container"
            [hidden]="(documentType.value === 'drivers_licence' && uploadState !== '') ? 'hidden' : ''">
            <div role="button" class="btn__card" [ngClass]="{
              'btn__card--disabled': (uploadState === 'WAITING' || verificationStatusPending === true || verificationApproved === true),
              'btn__card--disabled-other': (documentType.value !== 'passport' && (uploadState === 'WAITING' || verificationStatusPending === true || verificationApproved === true))
            }">
              <div class="verify-id__card-status">
                <ng-container *ngIf="documentType.value === 'passport'; else unselectedPassport">
                  <span class="icon__add" *ngIf="(uploadState !== 'WAITING' && uploadState !== 'GREEN') && !verificationStatusPending && !verificationApproved"></span>
                  <span class="icon__tick-pending" *ngIf="verificationStatusPending"></span>
                  <span class="status__tick" *ngIf="verificationApproved"></span>
                </ng-container>
                <ng-template #unselectedPassport>
                  <span class="icon__add"></span>
                </ng-template>

                <processing-spinner
                  *ngIf="uploadState === 'WAITING' && !verificationStatusPending && !verificationApproved" color="#FFFFFF"
                  [size]="'42px'"></processing-spinner>
              </div>
              Passport
            </div>
            <input
              hidden
              type="file"
              [disabled]="(uploadState === 'WAITING' || verificationStatusPending || verificationApproved)"
              id="landlord-verify-passport-upload"
              accept="image/bmp, image/tiff, image/pdf, application/pdf, image/jpeg, image/png"
              onclick="this.value=null;"
              (change)="documentType.setValue('passport'); uploadPictures($event)"
              [required]="true" />
          </label>
          </ng-container>

          <ng-container *ngIf="requiredFields.includes('drivers_license')">
            <label for="landlord-verify-driver-upload"
            class="verify-id__card-container"
            [hidden]="(documentType.value === 'passport' && (!uploadState || uploadState !== '')) ? 'hidden' : ''">
            <div role="button" class="btn__card" [ngClass]="{
              'btn__card--disabled': (uploadState === 'WAITING' || verificationStatusPending === true || verificationApproved === true),
              'btn__card--disabled-other': (documentType.value !== 'drivers_licence' && (uploadState === 'WAITING' || verificationStatusPending === true || verificationApproved === true))
            }">
              <div class="verify-id__card-status">
                <ng-container *ngIf="documentType.value === 'drivers_licence'; else unselectedDriver">
                  <span class="icon__add" *ngIf="(uploadState !== 'WAITING' && uploadState !== 'GREEN') && !verificationStatusPending && !verificationApproved"></span>
                  <span class="icon__tick-pending" *ngIf="verificationStatusPending"></span>
                  <span class="status__tick" *ngIf="verificationApproved"></span>
                </ng-container>
                <ng-template #unselectedDriver>
                  <span class="icon__add"></span>
                </ng-template>

                <processing-spinner
                  *ngIf="uploadState === 'WAITING' && !verificationStatusPending && !verificationApproved" color="#FFFFFF"
                  [size]="'42px'"></processing-spinner>
              </div>
              Driving licence
            </div>
            <input
              hidden
              type="file"
              [disabled]="(uploadState === 'WAITING' || verificationStatusPending || verificationApproved)"
              id="landlord-verify-driver-upload"
              accept="image/bmp, image/tiff, image/pdf, application/pdf, image/jpeg, image/png"
              onclick="this.value=null;"
              (change)="documentType.setValue('drivers_licence'); uploadPictures($event)"
              [required]="true" />
          </label>
          </ng-container>
        </div>



      </div>

      <bunk-dialog [component]="contentDialog" #errorDialog></bunk-dialog>

      <ng-template #contentDialog>
        <div class="verify-id__modal">
          <h2>Document upload failed</h2>
          <button class="btn__seethrough btn--round margin-top--md margin-bottom--lg" (click)="bunkDialogRef.closeAll()">Close</button>

          <p>Still failing? Contact <a href="mailto:{{client_data.support_email}}">{{client_data.support_email}}</a></p>
        </div>
      </ng-template>
    </div>

      </div>
    </bunk-form-field>



    </div>


  </form>
  `,
  styleUrls: ['./application-forms.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormIdentityCheckComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => FormIdentityCheckComponent),
      multi: true
    }
  ]
})
export class FormIdentityCheckComponent extends AbstractValueAccessor {
  @ViewChild('errorDialog', {static: false}) bunkDialogRef: BunkDialogComponent;
  @Input() form: any;
  @Input() type: string;
  @Input() requiredFields = FORM_IDENTITY;
  @Input() submitAttempted = false;

  identityForm: FormGroup;
  public queryParams: string;
  private destroy$: Subject<boolean> = new Subject<boolean>();
  public client_data = environment.client_data;

  // state
  public error: string;
  public uploadState: string;
  public idStatus: string;
  public infoError: boolean;

  // helpers
  public routes = { Tenant };

  constructor(
    @Optional() public _reactiveForm: FormGroupDirective,
    private _forms: FormsService,
    private _fb: FormBuilder,
    private _identify: IdentificationService,
    public _user: UserService,
    public _passport: BunkPassportService,
    public _route: ActivatedRoute,
    public _router: Router,
    private _auth: AuthService,
    ) {
    super();

    this.identityForm = this.createFormGroup();
    this.queryParams = _route.snapshot.queryParams.tenancy_offer_id;
    this.idStatus = 'unverified';
  }

  get documentType(): FormControl {
    return this.identityForm.get('document_type') as FormControl;
  }

  createFormGroup(): FormGroup {
    return this._fb.group({
      document_type: [this.verifiedDocumentType ? this.verifiedDocumentType : '', Validators.required],
    });
  }

  uploadPictures(event: any) {
    if (!this.form.get('info').valid) {
      this.infoError = true;
      this.identityForm.reset();
      return;
    }

    // if document type has not been selected
    if (!this.documentType) {
      return;
    }

    const docType = this.documentType.value;
    // reset
    this.uploadState = 'WAITING';
    this.error = null;
    this.infoError = false;

    // file
    const uri = event.target.value; // URI
    const fileName = uri.replace(/^.*[\\\/]/, ''); // name file
    const reader = new FileReader();
    const file = event.target.files[0];
    console.log(file, 'hey file');

    if (!file) {
      return;
    }
    const mimeType = file.type;
    /* console.log('uri: ', uri);
    console.log('name: ', fileName);
    console.log('type: ', mimeType);
    console.log('File: ', file); */

    if (!uri) {
      return;
    }
    const search_details = this.form.get('search_details').value;
    const info = this.form.get('info').value;
    const address_details = info.address_details && info.address_details !== null
    ? this._passport.formatAddress(info.address_details) : null;
    const university_details = search_details && search_details.university_details !== null ? search_details.university_details : null;


    const finalForm = {
      search_details,
      university_details,
      info: {
        ...info,
        address_details
      }
    };

    reader.readAsDataURL(file);

    return reader.onload = () => {
      const fileData = (<string>reader.result).split(',')[1];
      return this._passport.updateUserReferenceData(finalForm, this.type).pipe(
        switchMap(() => {
          return this._identify.startCheck(fileData, docType, mimeType);
        }),
        map((res: any ) => {
          console.log(res.response);  // success
          this.idStatus = (res.response === 'success') ? 'pending' : 'document-not-recognized';
          if (res.response === 'success') {
            this.checkResults();
            this.uploadState = 'GREEN';
            this.idStatus = 'pending';
          } else {
            this.uploadState = '';
            this.idStatus = 'document-not-recognized';
            this.bunkDialogRef.openDialog();
            this.documentType.setValue('');
          }
        })
      ).subscribe(
        (res: any) => {
        },
        (err) => {
            console.log('Error: ', err);
            this.error = err.error.message;
            this.uploadState = '';
            this.bunkDialogRef.openDialog();
            this.documentType.setValue('');

        }
      );



    };


  }



    // Read as DataURL: https://developer.mozilla.org/en-US/docs/Web/API/FileReader/readAsDataURL




  checkResults() {
    const recursiveObservable = this._identify.checkResults().pipe(
      takeUntil(this.destroy$),
      debounce(() => timer(4000)),
      expand((res) => {
        console.log(res);
        if (res.verification_status === 'failed' || 'approved') {
          this.idStatus = res.verification_status;
          console.log('checkResults: ' + res.verification_status);
          return EMPTY;
        } else {
          return recursiveObservable;
        }
      })
    ).subscribe();
  }

  get verificationUnverified() {
    if (this._auth.authenticated) {

    return !this._user.user.verification_status || this._user.user.verification_status === '' || this._user.user.verification_status === 'unverified'; }
  }

  get verificationApproved() {
    if (this._auth.authenticated) {

    return this._user.user.verification_status === 'approved'; }
  }

  get verificationStatusPending() {
    if (this._auth.authenticated) {

    return this._user.user.verification_status === 'pending' || this._user.user.verification_status === 'pending_review'; }
  }

  get verificationStatusFailed() {
    if (this._auth.authenticated) {
    return this._auth.authenticated ? this._user.user.verification_status === 'failed' : undefined ;}
  }

  get verifiedDocumentType() {
    return this._auth.authenticated ? (this._user.user.verification_data && this._user.user.verification_data.document_data) ? this._user.user.verification_data.document_data.document_type : undefined : undefined ;
  }

  ngOnInit(): void {
    this._reactiveForm.ngSubmit.subscribe((data: Event) => {
      const controls = this.identityForm.controls;
      for (const control in controls) {
        if (controls.hasOwnProperty(control)) {
          this.identityForm.controls[control].markAsTouched();
        }
      }
    });
  }

  public onTouched: () => void = () => { };

  writeValue(val: any): void {
    super.writeValue(val);
    if (val) {
      this.identityForm.patchValue(val, { emitEvent: false });
    }
  }
  registerOnChange(fn: any): void {
    this.identityForm.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.identityForm.disable() : this.identityForm.enable();
  }

  validate() {
    return this.identityForm.valid ? null : { invalidForm: { valid: false, message: 'FormCreditInfoComponent > formGroup fields are invalid' } };
  }

}

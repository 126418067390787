import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FileDownloadDirective } from './file-download.directive';

@NgModule({
  imports: [CommonModule],
  exports: [FileDownloadDirective],
  declarations: [FileDownloadDirective],
  providers: [],
})
export class FileDownloadDirectiveModule {

}

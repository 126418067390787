export const FORM_INFO = ['title', 'first_name', 'last_name', 'middle_name', 'phone', 'gender', 'dob', 'address_details', 'application_year'];

export const FORM_UNIVERSITY_DETAILS = ['university_name', 'course_end_year', 'course_name'];
export const FORM_RENTAL_ACADEMIC_YEAR = [
  {
    viewValue: '1st',
    value: '1',
  },
  {
    viewValue: '2nd',
    value: '2',
  },
  {
    viewValue: '3rd',
    value: '3',
  },
  {
    viewValue: 'Masters',
    value: 'masters',
  },
  {
    viewValue: 'PHD',
    value: 'phd',
  }
];
export const FORM_SEARCH_DETAILS = {
  search_details: ['is_student', 'searching_with', 'number_children', 'has_pets', 'is_smoker', 'is_renting', 'currently_renting_from', 'expected_move_in_date', 'university_details'],
  university_details: [...FORM_UNIVERSITY_DETAILS]
};

export const FORM_SEARCH_DETAILS_CAMPUS_KEY = {
  search_details: ['is_student', 'institution_location', 'study_year', 'is_student_at_uni', 'has_friends_at_uni', 'years_at_uni'],
  university_details: []
};

export const FORM_PREVIOUS_TENANCY = ['has_previous_tenancy', 'previous_address', 'referee_name', 'referee_email', 'referee_phone', 'tenancy_duration_months', 'end_of_tenancy_date', 'monthly_rent_paid' ];

export const FORM_EMPLOYMENT_DETAILS = ['employment_status', 'referee_name', 'referee_email', 'referee_phone', 'job_title', 'company_name', 'employee_salary', 'employee_bonus', 'start_date'];

export const FORM_GUARANTOR_DETAILS = ['has_uk_guarantor', 'referee_name', 'referee_email', 'referee_phone', 'relationship', 'referee_country_of_residence', 'has_declared_uk_homeowner'];

export const FORM_GUARANTOR_DETAILS_CAMPUS_KEY = ['referee_email', 'relationship', 'referee_phone', 'referee_country_of_residence', 'referee_name'];

export const FORM_IDENTITY = ['passport', 'drivers_license'];

export const FORM_CREDIT_INFO = ['has_declared_bankruptcy', 'has_ccj', 'has_criminal_history', 'has_confirmed_correct'];

export const FORM_BANK_DETAILS = ['bank_name', 'account_holder_name', 'account_number', 'sort_code'];

export const SEARCHING_OPTIONS = [
  { value: 'sharers', text: 'Sharers' },
  { value: 'myself', text: 'Myself' },
  { value: 'couple', text: 'Couple' },
  { value: 'family', text: 'Family' },
];

export const RENTING_OPTIONS = [
  { value: 'letting_agency', text: 'Letting agency' },
  { value: 'university', text: 'University' },
  { value: 'housing_association', text: 'Housing association' },
  { value: 'private_landlord', text: 'Private landlord' },
  { value: 'other', text: 'Other' },
  { value: 'prefer_not_to_say', text: 'Prefer not to say' },
];

export const EMPLOYMENT_STATUS = [
  { value: 'full_time', text: 'Full-time' },
  { value: 'part_time', text: 'Part-time' },
  { value: 'self_employed', text: 'Self-employed' },
  { value: 'unemployed', text: 'Unemployed' },
];

export const DURATION_MONTHS = [
  {
    viewValue: '1 month',
    value: 1
  },
  {
    viewValue: '3 months',
    value: 3
  },
  {
    viewValue: '6 months',
    value: 6
  },
  {
    viewValue: '9 months',
    value: 9
  },
  {
    viewValue: '12 months',
    value: 12
  },
  {
    viewValue: '18 months',
    value: 18
  },
  {
    viewValue: '2+ years',
    value: 24
  }
];

export const RELATIONSHIP_TYPES = [
  {
    viewValue: 'Parent',
    value: 'parent'
  },
  {
    viewValue: 'Guardian',
    value: 'guardian'
  },
  {
    viewValue: 'Other-family',
    value: 'other'
  },
  {
    viewValue: 'Colleague',
    value: 'colleague'
  }
];

export const STUDY_YEAR = [
  {
    viewValue: '1st',
    value: 1
  },
  {
    viewValue: '2nd',
    value: 2
  },
  {
    viewValue: '3rd',
    value: 3
  },
  {
    viewValue: '4th',
    value: 4
  },
  {
    viewValue: '5th',
    value: 5
  },
  {
    viewValue: '6th',
    value: 6
  }
]

export const CAMPUS_KEY_YEARS = [
  {
    viewValue: '1 year',
    value: 1
  },
  {
    viewValue: '2 years',
    value: 2
  },
  {
    viewValue: '3 years',
    value: 3
  },
  {
    viewValue: '4 years',
    value: 4
  },
  {
    viewValue: '5 years',
    value: 5
  },
  {
    viewValue: '6 years+',
    value: 6
  }
]

export const REFERRAL_SOURCES = [
  {
    viewValue: 'Word of mouth',
    value: 'word of mouth'
  },
  {
    viewValue: 'I’m a current ck student',
    value: 'i’m a current ck student'
  },
  {
    viewValue: 'Referral Campaign',
    value: 'referral campaign'
  },
  {
    viewValue: 'Google',
    value: 'google'
  },
  {
    viewValue: 'Social Media',
    value: 'social media'
  },
  {
    viewValue: 'Newspaper/Radio/Magazine',
    value: 'newspaper/radio/magazine'
  },
  {
    viewValue: 'On-site Advertising',
    value: 'on-site advertising'
  }
]

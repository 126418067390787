import { NgModule } from '@angular/core';

import {
  KeysPipe,
  ReplaceCurrentUserNamePipe,
  ReplaceUndescorePipe,
  TruncatePipe,
  ReversePipe,
  OrderByPipe,
  NumbersToOrdinalsPipe,
  MdToHtmlPipe,
  TypeofPipe,
  TitleHyphenCasePipe,
  DescriptionFormat,
  MonthIndexToNamePipe
} from './';

@NgModule({
  imports: [],
  exports: [
    KeysPipe,
    MdToHtmlPipe,
    NumbersToOrdinalsPipe,
    OrderByPipe,
    ReplaceCurrentUserNamePipe,
    ReplaceUndescorePipe,
    ReversePipe,
    TitleHyphenCasePipe,
    TruncatePipe,
    TypeofPipe,
    DescriptionFormat,
    MonthIndexToNamePipe
  ],
  declarations: [
    KeysPipe,
    MdToHtmlPipe,
    NumbersToOrdinalsPipe,
    OrderByPipe,
    ReplaceCurrentUserNamePipe,
    ReplaceUndescorePipe,
    ReversePipe,
    TitleHyphenCasePipe,
    TruncatePipe,
    TypeofPipe,
    DescriptionFormat,
    MonthIndexToNamePipe
  ],
  providers: [],
})
export class PipesModule { }

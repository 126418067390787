import {Component, Input} from '@angular/core';

@Component({
  selector: 'step-info-header',
  template: `
    <div class="info-step__container rc">
      <div class="flex_15 title_4 text_colour_1">Step {{step}}:</div>
      <div class="flex_85 cc">
        <div class="title_3">{{title}}</div>
        <ng-content></ng-content>
      </div>
    </div>
  `,
  styleUrls: [`./step-info-header.component.scss`]
})
export class StepInfoHeaderComponent {
  @Input() step: string;
  @Input() title: string;
}


    <div class="payment__card">
      <form #checkout="ngForm" [formGroup]="updateCardForm" (ngSubmit)="onSubmit(checkout)">

          <ng-content #header></ng-content>
          <h3 class="text--md" *ngIf="title" data-test="cardholderTitle">{{title}}</h3>
          <img *ngIf="!title" class="margin-bottom--sm" src="/assets/svg/account-icons/stripe-checkout-banner.svg" alt="">
          <p *ngIf="subtitle" data-test="cardholderSubtitle">{{subtitle}}</p>

          <strong class="flex margin-bottom--sm margin-top--sm text--md">Add new payment card</strong>

          <mat-form-field appearance="outline" class="w_100">
            <mat-label>Cardholder name</mat-label>
            <input matInput
              name="cardholder-name"
              formControlName="cardHolderName"
              data-test="cardholderNameInput">
            <mat-error>Please update the cardholder name as it appears on the card</mat-error>
          </mat-form-field>

          <div>
            <label hidden for="card-info" data-test="cardholderInfoText">New card numbers</label>
            
            <div class="card-actions__container">
              <div class="flex flex-column margin-bottom--sm">
                <div id="card-info" #cardInfo class="w_100" [ngClass]="{'StripeElement--invalid': cardError}"></div>
                <div id="card-errors" #cardInfoErrors role="alert" class="mat-error error" *ngIf="cardError">{{ cardError }}</div>
              </div>
              <div class="secure__container">
                <div class="flex flex-row justify-end">
                  <small>Secure Payment</small>
                  <img class="secure-payment-icon" src="/assets/img/icons/cards-icons/secure-server-lock.png" alt="">
                </div>
              </div>
              <processing-spinner *ngIf="processingIntent || awaitingCompletion" color="#FFFFFF" [complete]="submittingDataSuccess" class="margin-top--sm"></processing-spinner>
              <span *ngIf="intentProcessed && !clear" class="status__tick status__tick--lg margin-top--sm"></span>
              <div *ngIf="!processingIntent && !intentProcessed && !isVerification">
                <button type="submit" class="btn__seethrough btn--md btn--round margin-top--sm" data-test="addCardButton" [disabled]="processingIntent || intentProcessed">
                <mat-icon>add</mat-icon>
                <span>Add card</span>
                </button>
              </div>
            </div>
          </div>

      </form>
    </div>
    <ng-container *ngIf="isVerification">
      <div class="jc_sb rc mt_20">
        <button id="button_verify-payments-prev"
                (click)="goBack()"
                class="btn__seethrough"
        >
          Back
        </button>
        <button id="button_verify-payments-next"
                class="btn__generic" type="submit"
                (click)="onSubmit()"
                data-test="continueButton">Next
        </button>
      </div>
    </ng-container>
  
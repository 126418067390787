import * as i0 from "@angular/core";
export class GoogleAddressService {
    constructor() { }
    getPlaceId(place) {
        return place['place_id'];
    }
    getFormattedAddress(place) {
        return place['formatted_address'];
    }
    getAddrComponent(place, componentTemplate) {
        let result;
        for (let i = 0; i < place.address_components.length; i++) {
            const addressType = place.address_components[i].types[0];
            if (componentTemplate[addressType]) {
                result = place.address_components[i][componentTemplate[addressType]];
                return result;
            }
        }
        return;
    }
    getStreetNumber(place) {
        const COMPONENT_TEMPLATE = { street_number: 'short_name' }, streetNumber = this.getAddrComponent(place, COMPONENT_TEMPLATE);
        return streetNumber === undefined ? '' : streetNumber;
    }
    getStreet(place) {
        const COMPONENT_TEMPLATE = { route: 'long_name' }, street = this.getAddrComponent(place, COMPONENT_TEMPLATE);
        return street;
    }
    getSublocalityLevel1(place) {
        const COMPONENT_TEMPLATE = { sublocality_level_1: 'long_name' }, street = this.getAddrComponent(place, COMPONENT_TEMPLATE);
        return street;
    }
    getLocality(place) {
        const COMPONENT_TEMPLATE = { locality: 'long_name' }, city = this.getAddrComponent(place, COMPONENT_TEMPLATE);
        return city;
    }
    getLat(place) {
        const location = place.geometry.location;
        return location.lat();
    }
    getLng(place) {
        const location = place.geometry.location;
        return location.lng();
    }
    getState(place) {
        const COMPONENT_TEMPLATE = { administrative_area_level_1: 'short_name' }, state = this.getAddrComponent(place, COMPONENT_TEMPLATE);
        return state;
    }
    getDistrict(place) {
        const COMPONENT_TEMPLATE = { administrative_area_level_2: 'short_name' }, state = this.getAddrComponent(place, COMPONENT_TEMPLATE);
        return state;
    }
    getCountryShort(place) {
        const COMPONENT_TEMPLATE = { country: 'short_name' }, countryShort = this.getAddrComponent(place, COMPONENT_TEMPLATE);
        return countryShort;
    }
    getCountry(place) {
        const COMPONENT_TEMPLATE = { country: 'long_name' }, country = this.getAddrComponent(place, COMPONENT_TEMPLATE);
        return country;
    }
    getCity(place) {
        const COMPONENT_TEMPLATE = { country: 'postal_town' }, city = this.getAddrComponent(place, COMPONENT_TEMPLATE);
        return city;
    }
    getPostCode(place) {
        const COMPONENT_TEMPLATE = { postal_code: 'long_name' }, postCode = this.getAddrComponent(place, COMPONENT_TEMPLATE);
        return postCode;
    }
    getPhone(place) {
        const COMPONENT_TEMPLATE = { formatted_phone_number: 'formatted_phone_number' }, phone = this.getAddrComponent(place, COMPONENT_TEMPLATE);
        return phone;
    }
}
GoogleAddressService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function GoogleAddressService_Factory() { return new GoogleAddressService(); }, token: GoogleAddressService, providedIn: "root" });

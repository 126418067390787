import {Component, Input, AfterViewInit, ChangeDetectorRef} from '@angular/core';
import Siema, { SiemaOptions } from 'siema';
import { BehaviorSubject, Observable, from } from 'rxjs';
import {tap} from 'rxjs/internal/operators/tap';

@Component({
  selector: 'carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
})
export class CarouselComponent implements AfterViewInit {
  @Input() gallery: Array<{name: string, image_url: string}>;
  @Input() showDotFooter = false;
  public carouselEl: Siema;
  public obs: BehaviorSubject<any>;
  public carousel$: Observable<any>;

  constructor(
    private cdr: ChangeDetectorRef
  ) {}

  ngAfterViewInit(): void {
    const obs = new BehaviorSubject(this.carouselEl);
    this.carousel$ = from(obs).pipe(
      tap((car: any) => {
        console.log(car, 'hello');
        const item =  {
          ...car,
          footerArray: Array.from(Array(car.totalSlides).keys())
        };
        return item;
      })
    );

    function printSlideNumber() {
      obs.next({ currentSlide: this.currentSlide + 1, footerArray: Array.from(Array(this.innerElements.length).keys()), totalSlides: this.innerElements.length});
    }

    this.carouselEl = new Siema({
      selector: '.siema',
      perPage: 1,
      startIndex: 0,
      draggable: true,
      onInit: printSlideNumber,
      onChange: printSlideNumber,
    });

    this.cdr.detectChanges();
  }

  prev = () => this.carouselEl.prev();
  next = () => this.carouselEl.next();
}


import {Component, ViewChild} from '@angular/core';

// import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'modal-basic',
  templateUrl: './modal-basic.component.html',
  styleUrls: ['./modal-basic.component.scss']
})
export class ModalBasicComponent {
  @ViewChild('content', { static: false }) content;
  modalReference: any;

  closeResult: string;

  // constructor(private modalService: NgbModal) {
  // }

  // open() {
  //   this.modalReference = this.modalService.open(this.content);
  //   this.modalReference.result
  //     .then(
  //       (result) => {
  //         this.closeResult = `Closed with: ${result}`;
  //       },
  //       (reason) => {
  //         console.log(reason);
  //         this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
  //       });
  // }
  //
  // private getDismissReason(reason: any): string {
  //   if (reason === ModalDismissReasons.ESC) {
  //     return 'by pressing ESC';
  //   } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
  //     return 'by clicking on a backdrop';
  //   } else {
  //     return `with: ${reason}`;
  //   }
  // }
}


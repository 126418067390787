import {Component, OnInit, Input, OnDestroy} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormGroup, FormBuilder, FormArray} from '@angular/forms';
import {AuthService} from '@app/core/services';
import {StorageService, PropertyService} from '@app/services';
import {Landlord} from '@env/routing';
import {BunkUploaderModel} from '@app/shared/_components';

import { map, switchMap, takeUntil } from 'rxjs/operators';
import {Subject, of} from 'rxjs';
import {environment} from '@env/environment';


@Component({
  selector: 'property-list-pictures-form-new',
  templateUrl: './property-pictures-upload.component.html',
  styleUrls: ['./property-pictures-upload.component.scss']
})

export class PropertyPicturesUploadComponent implements OnInit, OnDestroy {
  @Input() formGroup: FormGroup;
  @Input() property: any;
  @Input() isSimpleProperty = false;
  public propertyId: string;
  public editId: string;
  public bedroomId: string;

  imagesFilePath: string;
  processingFiles: boolean;
  totalFiles: number;
  fileLoadCounter: number;
  errorsCount: number;
  errorsMessage: string;
  oldFormValue;
  // helpers
  public routes = {Landlord};

  private destroy$: Subject<boolean> = new Subject<boolean>();
  public client_data = environment.client_data;


  constructor(private _storage: StorageService,
              private _auth: AuthService,
              private _formBuilder: FormBuilder,
              private route: ActivatedRoute,
              private _property: PropertyService,
              private _router: Router) {
    this.propertyId = this.route.snapshot.paramMap.get('id') ? this.route.snapshot.paramMap.get('id') : this.property && this.property.property_id;
    this.editId = this.route.snapshot.paramMap.get('editId');
    this.processingFiles = false;
    this.fileLoadCounter = 0;
  }



  ngOnInit() {
    this.imagesFilePath = `user/${this._auth.currentUserId}/property/${this.propertyId}`;
    if (!this.oldFormValue) { this.oldFormValue = this.formGroup.value; }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

   onUploadedFile(task: BunkUploaderModel){
    this.fileLoadCounter++;

    if (task.file && task.image) {
      (this.formGroup.controls.property_photos as FormArray).push(this._formBuilder.group({
        name: task.file.name,
        image_large_url: task.image.image_large_url,
        image_small_url: task.image.image_small_url,
        image_original_url: task.image.image_original_url,
        image_full_path: task.image.image_full_path,
        image_url: task.image.image_large_url
      }));
    } else {
      this.errorsCount++;
      this.errorsMessage = `There was a problem uploading ${this.errorsCount} file${this.errorsCount > 1 ? 's' : ''}.`;
    }

    if (this.fileLoadCounter >= this.totalFiles) {
      this.processingFiles = false;
      this.fileLoadCounter = 0;
    }
  };

  onUpStart(task: BunkUploaderModel) {
    this.processingFiles = true;
    this.totalFiles = task.uploadsTotal;
    this.errorsMessage = '';
    this.errorsCount = 0;
  }

  submit(value: any = this.formGroup.value): void {
    const id = (this.property.is_editing) ? this.editId : this.propertyId;
    const has_edited = this._property.hasFormUpdated(this.oldFormValue, this.formGroup.value);
    this._property.updateProperty(id, value, this.property.is_editing)
      .pipe(
        switchMap(() => (has_edited) ? this._property.updateListAPropertyStepArray(id, 4, this.property.is_editing) : of(null)),
        map(() => has_edited && this.editId ? this._property.updatePropertyEditStatus('pictures', this.editId) : of()),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
         this.gotoRoute(Landlord.property_list_form.confirm);
        });
  }

  scrollTo() {
    window.scrollTo(0, 0);
  }

  uploadingFilesMessage(){
    return `Uploading ${ this.fileLoadCounter + 1 } ${ (this.totalFiles === 1) ? ' file' : ' of ' + this.totalFiles + ' files' }`
  }

  goBack() {
    this.gotoRoute(Landlord.property_list_form.info);
  }

  gotoRoute(step:string){
    let params = this.editId ? [ Landlord.property_list_form.edit, this.editId] : []
    this._router.navigate([Landlord.base, Landlord.property_list_form.base, step, this.propertyId,...params]) 
  }

}

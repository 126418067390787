/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./counter.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./counter.component";
var styles_CounterComponent = [i0.styles];
var RenderType_CounterComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_CounterComponent, data: {} });
export { RenderType_CounterComponent as RenderType_CounterComponent };
function View_CounterComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 4, "div", [["class", "counter"]], null, null, null, null, null)), i1.ɵprd(512, null, i2.ɵNgClassImpl, i2.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(2, 278528, null, 0, i2.NgClass, [i2.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(3, { "counter--wide": 0 }), (_l()(), i1.ɵted(4, null, ["", ""]))], function (_ck, _v) { var _co = _v.component; var currVal_0 = "counter"; var currVal_1 = _ck(_v, 3, 0, _co.wide); _ck(_v, 2, 0, currVal_0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_2 = ((_co.value < 10) ? _co.value : "9+"); _ck(_v, 4, 0, currVal_2); }); }
export function View_CounterComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(16777216, null, null, 1, null, View_CounterComponent_1)), i1.ɵdid(1, 16384, null, 0, i2.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.value; _ck(_v, 1, 0, currVal_0); }, null); }
export function View_CounterComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "counter", [], null, null, null, View_CounterComponent_0, RenderType_CounterComponent)), i1.ɵdid(1, 49152, null, 0, i3.CounterComponent, [], null, null)], null, null); }
var CounterComponentNgFactory = i1.ɵccf("counter", i3.CounterComponent, View_CounterComponent_Host_0, { value: "value", wide: "wide" }, {}, []);
export { CounterComponentNgFactory as CounterComponentNgFactory };

import { OnDestroy } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { UserService } from '@app/services/user.service';
import { MY_FORMATS } from '@app/shared/_services/dates.service';
import { MIN_TENANT_AGE, TITLES, COUNTRY_CODES, GENDER_VALUES } from '@app/_constants/forms.constants';
import { BunkPassportService } from '@app/services';
import { Tenant } from '@env/routing';
import { AuthService } from '@app/core/services';
import { tap, takeUntil, catchError, first, finalize } from 'rxjs/operators';
import { Subject, of } from 'rxjs';
import { TenantVerifyEventEmiterService } from '../profile-tenant.service';
import { TenantAccountStepComponent } from './tenant-account-step.component';
import { TitleCasePipe } from '@angular/common';
const ɵ0 = MY_FORMATS;
export class TenantAccountVerifyInfoComponent extends TenantAccountStepComponent {
    constructor(_verifyEvent, _user, _formBuilder, _auth, _passport, _titleCasePipe) {
        super(_verifyEvent);
        this._user = _user;
        this._formBuilder = _formBuilder;
        this._auth = _auth;
        this._passport = _passport;
        this._titleCasePipe = _titleCasePipe;
        this.standAlone = false;
        this.userGender = GENDER_VALUES;
        this.routes = { Tenant };
        this.showAddress = false;
        this.avatar = 'assets/img/icons/bunk-default-avatar.svg';
        this.filePath = `user/${this._auth.currentUserId}/profile`;
        this.destroy$ = new Subject();
        this.showSpinner = false;
        this.onUpStart = () => {
            this.showSpinner = true;
        };
        this.onUploadedFile = (task) => {
            this._passport.updateProfileImage(task.image.image_original_url).pipe(takeUntil(this.destroy$), finalize(() => this.showSpinner = false)).subscribe();
        };
        this.formGroup = this.initFormGroup();
        this.minAgeYear = new Date().getFullYear() - MIN_TENANT_AGE;
        this.titles = TITLES;
        this.countryCodes = COUNTRY_CODES.sort();
        this.passport$ = this._passport.getUsersBunkPassportById().valueChanges()
            .pipe(first(), tap((passport) => {
            if (passport.personal_details) {
                console.log(passport, 'passport');
                this.preloadFormGroup(passport);
            }
        }));
    }
    get profile_image_url() {
        return this.formGroup.get('profile_image_url');
    }
    initFormGroup() {
        return this._formBuilder.group({
            info: [null],
            profile_image_url: [null],
        });
    }
    preloadFormGroup(form) {
        const personalDetails = form.personal_details;
        console.log(personalDetails, 'personalDetails');
        const item = {
            profile_image_url: personalDetails.profile_image_url ? personalDetails.profile_image_url
                : this._user.userDb.profile_data.profile_image_url,
            info: {
                title: personalDetails.title,
                first_name: personalDetails.first_name,
                middle_name: personalDetails.middle_name ? personalDetails.middle_name : null,
                last_name: personalDetails.last_name,
                dob: personalDetails.dob ? personalDetails.dob.toDate() : null,
                gender: personalDetails.gender ? personalDetails.gender : null,
                phone: {
                    phone_number: personalDetails.phone,
                    area_code: personalDetails.country_code ? personalDetails.country_code : +44,
                },
                profile_image_url: personalDetails.profile_image_url ? personalDetails.profile_image_url
                    : this._user.userDb.profile_data.profile_image_url,
                address_details: Object.assign({}, personalDetails.address_details)
            }
        };
        this.formGroup.patchValue(item);
    }
    nextStep(form) {
        const finalForm = {
            info: Object.assign({}, form.info, { address_details: form.info && form.info.address_details && form.info.address_details !== null ? this._passport.formatAddress(form.info.address_details) : null, phone: form.info.phone ? form.info.phone.phone_number : null, country_code: form.info.phone.area_code ? form.info.phone.area_code : null })
        };
        console.log(finalForm, 'final form');
        this.submittingData = true;
        this.formGroup.disable();
        this.profile_image_url.disable();
        this._passport.updateUserReferenceData(finalForm)
            .pipe(takeUntil(this.destroy$), catchError(err => of([])))
            .subscribe(res => {
            this.stepComplete();
        }, err => {
            console.log('HTTP Error', err);
            this.stepError();
        }, () => {
            this.formGroup.enable();
            this.profile_image_url.enable();
            console.log('HTTP request completed.');
        });
    }
    remove() {
        this._passport.updateProfileImage('assets/img/icons/bunk-default-avatar.svg')
            .pipe(takeUntil(this.destroy$))
            .subscribe(success => {
            console.log('Success upload Profile Picture');
        }, error => console.log(error));
    }
    goBack() {
        this._verifyEvent.navigateStep('prev');
    }
    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
}
export { ɵ0 };

import {environment} from '@env/environment';
const client_data: any = environment.client_data;
const translation = client_data && client_data.translation ? client_data.translation : null;
const labels = translation &&translation.application_overview_labels ? translation.application_overview_labels: null;
export const TRANSLATIONS: any = {
  unit_name: client_data.unit_name ? client_data.unit_name : 'Bedroom',
  offer_message_label: translation && translation.offer_message_label ? translation.offer_message_label : 'Add a message for the landlord (optional)',
  offer_message_placeholder: translation && translation.offer_message_placeholder ? translation.offer_message_placeholder : 'Stand out from the crowd with a personal message...',
  application_overview_labels : {
    all_applications: labels && labels.all_applications ? labels.all_applications : 'All applications',
    pending: labels && labels.pending ?  labels.pending : 'Pending Offers',
    referencing: labels && labels.referencing ?  labels.referencing : 'Referencing',
    agreement: labels && labels.agreement ?  labels.agreement : 'Agreement',
    completed: labels && labels.completed ?  labels.completed : 'Completed',
    cancelled: labels && labels.cancelled ?  labels.cancelled : 'Cancelled',
    backup: labels && labels.backup ?  labels.backup : 'Backup',
    incomplete: labels && labels.incomplete ?  labels.incomplete : 'Incomplete Offers',
  },
  rent_label_abbreviation: translation && translation.rent_label_abbreviation ? translation.rent_label_abbreviation: 'pcm',
  country_code: translation && translation.country_code ? translation.country_code : +44,
  tenancy_agreement: translation && translation.tenancy_agreement ? translation.tenancy_agreement : 'Assured Shorthold Tenancy Agreement',
  rent_amount : translation && translation.rent_amount ? translation.rent_amount: 'Rent Amount',
  move_in_date: translation && translation.move_in_date ? translation.move_in_date: 'Move-in',
  holding_deposit: translation && translation.holding_deposit ? translation.holding_deposit: 'Holding Deposit',
  holding_deposit_paid: translation && translation.holding_deposit_paid ? translation.holding_deposit_paid : 'Holding Deposit Paid',
  holding_deposit_confirm: translation && translation.holding_deposit_confirm ? translation.holding_deposit_confirm : 'Do you want to let the landlord know that you\'ve sent the holding deposit?',
  service_fee: translation && translation.service_fee ? translation.service_fee: 'Service fee',
  rent_property: translation && translation.rent_property ? translation.rent_property : 'Rent Property',
  letting_information: translation && translation.letting_information ? translation.letting_information : 'Letting Information',
  amenities: translation && translation.amenities ? translation.amenities : 'Amenities',
  tenancy_period: translation && translation.tenancy_period ? translation.tenancy_period : 'Tenancy Period',
  landlord: translation && translation.landlord ? translation.landlord : 'landlord',
  all_years: translation && translation.all_years ? translation.all_years : 'All Years',
};

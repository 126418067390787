<ng-template #verify>
  <div class="bunk-overlay__external-content bunk-overlay__external-content--scroll">
    <header class="bunk-pp__header bunk-pp__header--overlay-bubble">
      <div class="main__wrapper main__wrapper--narrow">
        <div class="main__wrapper main__wrapper--narrow bunk-overlay__close-bar">
          <button class="bunk-overlay__close-btn" (click)="whatPassportOverlay.close()">
            <mat-icon svgIcon="icon-close"></mat-icon>
          </button>
        </div>

        <h1 class="margin-top--sm margin-bottom--sm verify__intro-title">Join the future of renting!</h1>
      </div>
    </header>

    <section class="main__wrapper main__wrapper--narrow">
      <tenant-bunk-passport-overview [standAlone]="true"></tenant-bunk-passport-overview>
    </section>
  </div>
</ng-template>

<tenant-main-template>
  <screen-detection (resized)="isDesktop = $event.isDesktop; desktop = isDesktop"></screen-detection>
  <header class="bunk-pp__header bunk-pp__header--unverified"
    [ngClass]="{'bunk-pp__header--mobile-profile': !isDesktop && !isPassportMenuUrl}">
    <div class="main__wrapper">
      <ng-container *ngIf="isPassportMenuUrl || isDesktop; else stepHeader">
        <div class="header-text">
          <h1>Your Rental Passport</h1>
          <p class="text--subtle">Your Passport is your free rental profile which saves you time when you’re moving. It
            stores your references and provides instant reference checks to make finding your new home quick and
            hassle-free.</p>
        </div>
      </ng-container>

      <ng-template #stepHeader>
        <div (click)="goBackMobile()">
          <button class="btn__crumb-back btn__crumb-back--white btn__crumb-back--lg">Profile</button>
        </div>
      </ng-template>

    </div>
  </header>

  <div #verifyForm class="lateral-container verify-full__passport background"
    *ngIf=" ( userReadOnly$ | async) as userReadOnly">
    <div class="main__wrapper ">
      <div class="users__info"
        [ngClass]="{'users__info--mobile' : !isDesktop, 'flex-end': !isDesktop && !isPassportMenuUrl }">
        <div class="profile__container"
          [ngClass]="{'profile__container--back-profile': !isDesktop && !isPassportMenuUrl}">
<!--
          <img *ngIf="_user.userDb?.profile_data.profile_image_url && (isPassportMenuUrl || isDesktop)"
            [src]="_user.userDb?.profile_data.profile_image_url" alt=""
            class="profile__image profile__background profile__background--lg"> -->


          <profile-avatar class="mr_10" *ngIf="(isPassportMenuUrl || isDesktop)" size="lg" justified="center" [profileImgUrl]="_user.userDb?.profile_data.profile_image_url" [profileName]="_user.userDb?.profile_data.full_name"></profile-avatar>

            <div class="flex flex-column mr_10 margin-top--sm" *ngIf="isDesktop">
            <div>
              <span class="bunk-pp__logo-left">RENTAL</span><span class="bunk-pp__logo-right">PASSPORT</span>
            </div>
            <div *ngIf="verifiedScale" class="bunk-pp__progress-bar">
              <div class="bunk-pp__progress-bar-scale" [ngStyle]="{'width': verifiedScale + '%'}"><strong
                  *ngIf="verifiedScale > 26">{{verifiedScale}}%</strong></div>
            </div>
          </div>
          <strong class="text--md hide-lg flex flex-column items-center margin-top--sm"
            *ngIf="isPassportMenuUrl">{{_user.userDb.profile_data.full_name}}</strong>
        </div>

        <ul *ngIf="isDesktop">
          <li><strong>Message {{landlord}}s </strong><span class="status__tick"></span></li>
          <li [ngClass]="{'opacity': userReadOnly.verification_tier === 1}"><strong>Request viewings</strong>
            <span *ngIf="userReadOnly.verification_tier >=2 " class="status__tick"></span>
            <mat-icon *ngIf="userReadOnly.verification_tier === 1" svgIcon="icon-lock" class="lock"></mat-icon>
          </li>
          <li [ngClass]="{'opacity': userReadOnly.verification_tier === 1  || userReadOnly.verification_tier === 2}">
            <strong>Make offers</strong>
            <span *ngIf="userReadOnly.verification_tier === 3" class="status__tick"></span>
            <mat-icon *ngIf="userReadOnly.verification_tier === 1 || userReadOnly.verification_tier === 2"
              svgIcon="icon-lock" class="lock"></mat-icon>
          </li>
        </ul>
      </div>

      <div class="main__content-row" [ngClass]="{'margin-top': isDesktop}" *ngIf="userSteps$ | async as userSteps">
        <div *ngIf="isPassportMenuUrl || isDesktop"
          class="main__content-aside main__content-aside--left flex flex-column"
          [ngClass]="{'flex-column-reverse': userReadOnly.verification_tier >= 2 && !isDesktop}">

          <div>
            <div *ngIf="userReadOnly.verification_tier < 2" class="margin-top--sm margin-bottom--md">
              <strong><em>Before you can request a viewing...</em></strong>
            </div>
            <ul class="card__list-item card__list-item--bp steps-container">
              <li *ngFor="let step of userSteps; let i = index" class="cursor-pointer">
                <div *ngIf="step.tier === 2" class="step-align"
                  [ngClass]="{'active':currentStepNumber === i  && isDesktop}" (click)="gotoStep(step)"
                  (mouseover)="onHover(i)" (mouseout)="onHover(-1)">
                  <div class="flex items-center">
                    <span *ngIf="!step.status" class="status__step status__step--verify-add"></span>
                    <span *ngIf="step.status === 'approved'" class="status__step status__step--verify-complete"></span>
                    <span *ngIf="step.status === 'pending' || step.status === 'pending_review'"
                      class="status__step status__step--verify-pending"></span>
                    <span *ngIf="step.status === 'failed'" class="status__step status__step--verify-error"></span>

                    <strong class="text--sm">{{ step.title }}</strong>
                  </div>
                  <div *ngIf="currentStepNumber === i  || !isDesktop || i == hoverIndex">
                    <mat-icon svgIcon="chevron-right"></mat-icon>
                  </div>
                </div>
              </li>
            </ul>
          </div>

          <div>
            <div *ngIf="userReadOnly.verification_tier < 3" class="margin-top--md margin-bottom--md" [ngClass]="{
                'margin-bottom--sm': userReadOnly.verification_tier >= 2,
                'margin-top--sm': userReadOnly.verification_tier >= 2
              }">
              <strong><em>Before you can make an offer...</em></strong>
            </div>
            <ul class="card__list-item card__list-item--bp steps-container"
              [ngClass]="{'opacity tier-locked': userReadOnly.verification_tier === 1}">

              <li *ngFor="let step of filteredSteps; let i = index;" class="cursor-pointer">
                <div *ngIf="step.tier === 3" class="step-align"
                  [ngClass]="{'active': currentStepNumber === i  && isDesktop}" (click)="gotoStep(step)"
                  (mouseover)="onHover(i)" (mouseout)="onHover(-1)">
                  <div class="flex items-center">
                    <span *ngIf="userReadOnly.verification_tier > 1 && !step.status"
                      class="status__step status__step--verify-add"></span>
                    <span *ngIf="userReadOnly.verification_tier !== 1 && step.status === 'approved'"
                      class="status__step status__step--verify-complete"></span>
                    <span
                      *ngIf="userReadOnly.verification_tier > 1 && (step.status === 'pending' || step.status === 'pending_review')"
                      class="status__step status__step--verify-pending"></span>
                    <span *ngIf="userReadOnly.verification_tier > 1 && step.status === 'failed'"
                      class="status__step status__step--verify-error"></span>
                    <mat-icon class="add-icon" *ngIf="userReadOnly.verification_tier === 1" svgIcon="icon-lock">
                    </mat-icon>

                    <strong class="text--sm">{{ step.title }}</strong>
                  </div>
                  <div *ngIf="currentStepNumber === i || !isDesktop || i == hoverIndex"
                    [hidden]="userReadOnly.verification_tier === 1">
                    <mat-icon svgIcon="chevron-right"></mat-icon>
                  </div>
                </div>
              </li>
            </ul>
          </div>

        </div>

        <section [hidden]="isPassportMenuUrl && !isDesktop"
          class="main__content-body card__list-item card__list-item--bp  card__list-item--bp--min-height  card__list-item--bp--mb-intercom">

          <div *ngIf="!isPassportMenuUrl">
            <ng-container *ngIf="!isPassportMenuUrl">
              <div class="flex flex-column header" *ngIf="currentStepNumber >= 0">
                <p class="overline text--subtle">Step {{ currentStepNumber + 1 }} of {{ filteredSteps.length }}:</p>
              </div>
            </ng-container>

            <router-outlet></router-outlet>
          </div>

        </section>
      </div>

      <bunk-overlay *ngIf="userReadOnly.verification_tier" [template]="verified"
        (overlayRef)="completePassportOverlay = $event"
        [autoOpen]="showVerfiedOverlay && userReadOnly.verification_tier >= 3" (closed)="gotoNextPage()">
        <ng-template #verified>
          <div class="bunk-pp__overlay">
            <section class="main__wrapper main__wrapper--skinny  text-center">
              <div class="bunk-overlay__close-bar">
                <button class="bunk-overlay__close-btn bunk-overlay__close-btn--white" (click)="closeAndGotoNextPage()">
                  <mat-icon svgIcon="icon-close"></mat-icon>
                </button>
              </div>
              <div class="bunk-pp__logo">
                <span class="bunk-pp__logo-left">RENTAL</span><span class="bunk-pp__logo-right">PASSPORT</span>
              </div>

              <img *ngIf="_user.userDb?.profile_data.profile_image_url"
                [src]="_user.userDb?.profile_data.profile_image_url" alt="My profile picture"
                class="bunk-pp__avatar bunk-pp__avatar--overlay profile__background">

              <img class="margin-top--sm margin-bottom--lg" src="/assets/svg/passport/verified-badge.svg">

              <h2 class="text--white">You’re good to go!</h2>
              <p class="text--subtle">You are now fully verified and can make offers on properties with Bunk.</p>

              <ul class="bunk-pp__tiers ml_10 mr_10">
                <li class="bunk-pp__tier-level">
                  <span class="status__tick bunk-pp__tier-level-icon"></span><strong>Message {{landlord}}s</strong>
                </li>
                <li class="bunk-pp__tier-level">
                  <span class="status__tick bunk-pp__tier-level-icon"></span><strong>Request viewings</strong>
                </li>
                <li class="bunk-pp__tier-level">
                  <span class="status__tick bunk-pp__tier-level-icon"></span><strong>Make offers</strong>
                </li>
              </ul>

              <button class="btn__cta btn--round bloat-center margin-top--md"
                (click)="gotoNextPage(); completePassportOverlay.close();">{{ tenancyOfferId ? 'I want to submit my offer' : 'Take me to my profile' }}</button>
            </section>
          </div>
        </ng-template>
      </bunk-overlay>

      <bunk-overlay *ngIf="userReadOnly.verification_tier" [template]="verified" (overlayRef)="overProgress = $event"
        [autoOpen]="showPassportProgressOverlay" (closed)="showPassportProgressOverlay = false">
        <ng-template #verified>
          <div class="bunk-pp__overlay">
            <section class="main__wrapper main__wrapper--skinny  text-center">
              <div class="bunk-overlay__close-bar">
                <button class="bunk-overlay__close-btn bunk-overlay__close-btn--white" (click)="overProgress.close();">
                  <mat-icon svgIcon="icon-close"></mat-icon>
                </button>
              </div>
              <div class="bunk-pp__logo">
                <span class="bunk-pp__logo-left">RENTAL</span><span class="bunk-pp__logo-right">PASSPORT</span>
              </div>

              <img *ngIf="_user.userDb?.profile_data.profile_image_url"
                [src]="_user.userDb?.profile_data.profile_image_url" alt="My profile picture"
                class="bunk-pp__avatar profile__background">

              <ul class="bunk-pp__tiers ml_10 mr_10">
                <li class="bunk-pp__tier-level">
                  <span class="status__tick bunk-pp__tier-level-icon"></span><strong>Message {{landlord}}s</strong>
                </li>
                <li class="bunk-pp__tier-level"
                  [ngClass]="{'bunk-pp__tier-level--inactive': userReadOnly.verification_tier < 2}">
                  <span *ngIf="userReadOnly.verification_tier >= 2"
                    class="status__tick bunk-pp__tier-level-icon"></span>
                  <mat-icon *ngIf="userReadOnly.verification_tier < 2" svgIcon="icon-lock"
                    class="bunk-pp__tier-level-icon"></mat-icon>
                  <strong>Request viewings</strong>
                </li>
                <li class="bunk-pp__tier-level"
                  [ngClass]="{'bunk-pp__tier-level--inactive': userReadOnly.verification_tier < 3}">
                  <span *ngIf="userReadOnly.verification_tier >= 3"
                    class="status__tick bunk-pp__tier-level-icon"></span>
                  <mat-icon *ngIf="userReadOnly.verification_tier < 3" svgIcon="icon-lock"
                    class="bunk-pp__tier-level-icon"></mat-icon>
                  <strong>Make offers</strong>
                </li>
              </ul>

              <button class="btn__cta btn--round bloat-center margin-top--lg" (click)="overProgress.close();">Complete
                my profile</button>

              <bunk-overlay [template]="verify" (overlayRef)="whatPassportOverlay = $event">
                <button (click)="overProgress.close();" class="btn__text-secondary margin-top--sm bloat-center">What
                  is Rental Passport?</button>
              </bunk-overlay>
            </section>
          </div>
        </ng-template>
      </bunk-overlay>

    </div>
    <footer *ngIf="isPassportMenuUrl" class="bunk-pp__progress-tray hide-lg"
      (click)="showPassportProgressOverlay = (verifiedScale ? true : false)">
      <div>
        <div class="bunk-pp__logo">
          <span class="bunk-pp__logo-left">RENTAL</span><span class="bunk-pp__logo-right">PASSPORT</span>
        </div>
        <mat-icon svgIcon="chevron-left" class="bunk-pp__expander"></mat-icon>
      </div>

      <bunk-overlay *ngIf="!verifiedScale" [template]="verify" (overlayRef)="whatPassportOverlay = $event">
        <button class="btn__text-secondary">What is Rental Passport?</button>
      </bunk-overlay>

      <div class="bunk-pp__progress-bar" *ngIf="verifiedScale">
        <div class="bunk-pp__progress-bar-scale" [ngStyle]="{'width': verifiedScale + '%'}"><strong
            *ngIf="verifiedScale > 26">{{verifiedScale}}%</strong></div>
      </div>
    </footer>
  </div>
</tenant-main-template>

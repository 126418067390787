 
      <div class="margin-bottom--md">
        <h2 class="text--lg">Previous {{landlord}} details</h2>
        <em >Please note we will not contact your {{landlord}} until an offer has been accepted on a property.</em>
      </div>

      <form [formGroup]="formGroup" (ngSubmit)="nextStep(formGroup.value)" class="flex flex-column" >

      <form-previous-tenancy formControlName="previous_tenancy"></form-previous-tenancy>
    
        <div class="margin-bottom--xl">
          <button *ngIf="showPrev" (click)="goBack()" data-test="backButton" class="btn__seethrough btn__form-prev">Back</button>
          <button class="btn__generic btn__form-submit btn__form-submit--align-right" type="submit">
            <span *ngIf="!submittingData && !submittingDataSuccess">Save<span *ngIf="!standAlone"> and continue</span></span>
            <processing-spinner *ngIf="submittingData || submittingDataSuccess" color="#FFFFFF" [complete]="submittingDataSuccess"></processing-spinner>
          </button>
        </div>
      </form>

  
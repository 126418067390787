import {Component, Input, OnInit} from '@angular/core';
import {TenancyService} from '@app/services/tenancy.service';
import {Observable, of, combineLatest} from 'rxjs';
import {ToastrService} from 'ngx-toastr';
import { finalize, map } from 'rxjs/operators';
import {PropertyService} from '@app/services/property.service';
import { PaymentsService } from '@app/services/payments.service';
import { AuthService } from '@app/core/services';
import { ActivatedRoute, Router } from '@angular/router';
import { Tenant } from '@env/routing';


@Component({
  selector: 'form-rent-extension',
  templateUrl: './form-rent-extension.component.html',
  styleUrls: ['./form-rent-extension.component.scss']
})
export class FormRentExtensionComponent implements OnInit {
  @Input() formGroup: any;
  @Input() tenancy: any;
  public property$: Observable<any>;
  public duePayment$: Observable<any>;
  public combined$: Observable<any>;
  public showSpinner = false;
  public completeState = false;
  constructor(private _router: Router,
              private _route: ActivatedRoute,
              private _tenancy: TenancyService,
              private toastr: ToastrService,
              private _property: PropertyService,
              private _payments: PaymentsService,
              private _auth: AuthService) {

  }

  ngOnInit() {
    this.property$ = this._property.getPropertyById(this.tenancy.property_id).valueChanges();
    this.duePayment$ = this._payments.getUserDuePayments(this._tenancy.getIdCurrentTenancy(), this._auth.currentUserId).pipe(
      map((payments: any) => (payments[0]) ? payments[0] : of({}))
    );

    this.combined$ = combineLatest(this.property$, this.duePayment$).pipe(
      map(([property, payment]) => {
        return {
          propertyAddress: property.address,
          payment_id: (payment) ? payment.payment_id : null,
          due_date: (payment) ? payment.due_date : null
        };
      })
    );

  }

  createRentExtensionRequest(address: any, due_date: Date, payment_id: string) {
    this.showSpinner = true;
    this.formGroup.disable();
    this._tenancy.createRentExtensionRequest(this.formGroup.getRawValue(), this.tenancy, address, due_date, payment_id).pipe(
      finalize(() => this.showSpinner = false)
    ).subscribe(() => {
      this.completeState = true;
      this._router.navigate(['../', Tenant.manage.my_property.rent_bills], { relativeTo: this._route })
      this.toastr.success('Request is sent.');
    });
  }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {BunkUploaderModule, DocumentUploadBoxComponent, ProcessingSpinnerModule} from '@shared/_components';
import {MatIconModule} from '@angular/material/icon';
import {MatSelectModule} from '@angular/material/select';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DragDropDirective} from '@app/drag-drop.directive';

@NgModule({
  imports: [
      CommonModule,
      MatIconModule,
      MatSelectModule,
      ReactiveFormsModule,
      FormsModule,
      BunkUploaderModule,
      ProcessingSpinnerModule,
  ],
  declarations: [DocumentUploadBoxComponent, DragDropDirective],
  exports: [DocumentUploadBoxComponent]
})
export class DocumentUploadBoxModule { }

import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Landlord, Shared } from '@env/routing';
import { TitleCasePipe } from '@angular/common';
import { AuthService } from '@app/core/services';
import { UserService } from '@app/services';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import * as moment from 'moment';
import { TENANT_FIND_100_200, SELF_MANAGE_100_200 } from '../../../../core-landlord/core-landlord.constants';
import { map, take, tap } from 'rxjs/operators';
import { PaymentsService } from '@app/services/payments.service';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { TenancyModel } from '@app/_models/tenancy.model';
import { PropertyModel } from '@app/core-landlord/property/_models/property.model';
import { TenancyService } from '../../../../services/tenancy.service';
import { MathService } from '@app/services/math.service';
import { environment } from '@env/environment';
import { ToastrService } from 'ngx-toastr';
import { MediaService } from '@app/services/media.service';
import { IMedia } from '@rentbunk/bunk-models';
import { PropertyType } from '@app/_constants/property_docs.constants';

@Component({
  selector: 'property-card-list',
  template: `
    <screen-detection (resized)="isDesktop = $event.isDesktop"></screen-detection>

    <div>
  <span [style.cursor]="!static ? 'pointer' : 'default'">
  <div class="prop-card__image-main prop-card__image-main--small" [ngClass]="{'image__placeholder': !display.photo}" (click)="gotoProperty()">
    <img
      *ngIf="display.photo"
      src="{{ display.photo }}"
      alt="{{ display.propertyType }}">
  </div>

  <div class="flex flex-row">
    <span class="prop__card-document-icons" *ngIf="display.hasFloorplan || display.totalPictures >= 1">
      <span *ngIf="display.hasFloorplan"> <mat-icon svgIcon="floorplan-icon" class="prop__card-icon"></mat-icon></span>
      <span class="prop__card-divider" *ngIf="display.hasFloorplan"></span>
      <span *ngIf="display.totalPictures >= 1"><mat-icon svgIcon="camera-icon" class="prop__card-icon"></mat-icon>
        {{display.totalPictures}}</span>
    </span>
    <button class=" btn__preview" type="button" (click)="gotoProperty()"><mat-icon svgIcon="preview-icon"></mat-icon>Preview</button>
  </div>

  <div class="prop-card__body--list">

  <ng-container *ngIf="_user.userDb.role === 'tenant'">
    <span class="prop-card__status-banner prop-card__status-banner--warning" *ngIf="!display.isHMO && display.underOffer">Under Offer</span>
    <span class=" prop-card__status-banner prop-card__status-banner--danger " *ngIf="display.isDeleted || !display.isListed">Not Available</span>

    <ng-container *ngIf="display.isHMO && !display.isDeleted && display.isListed">
      <span class="prop-card__status-banner prop-card__status-banner--success"
            *ngIf="display.totalRoomsAvailable >= 1 && !(display.totalRoomsUnderOffer >= 1 && display.totalRoomsAvailable == 1)">{{ (display.totalRoomsAvailable - display.totalRoomsUnderOffer) > 1 ? (display.totalRoomsAvailable - display.totalRoomsUnderOffer) + ' rooms' : display.totalRoomsAvailable + ' room'}}
        still available! <br> </span>
      <span class="prop-card__status-banner prop-card__status-banner--success"
            *ngIf="display.totalRoomsUnderOffer >= 1">{{ display.totalRoomsUnderOffer > 1 ? display.totalRoomsUnderOffer + ' rooms' : display.totalRoomsUnderOffer + ' room'  }} under offer </span>
    </ng-container>
  </ng-container>


    <span class="prop-card__title--list">{{ display.firstLineAddress }}
      <span *ngIf="display.isListed" copyToClipboard [text_copy]="sharedUrl" (isCopied)="copiedText = $event; checkCopiedText()" #tooltip="matTooltip"
            matTooltip="Copy listing link" matTooltipPosition="below" matTooltipHideDelay="1000">
    <mat-icon
      class="icon-copy" svgIcon="copy" aria-hidden="false"></mat-icon>
    </span>
    </span>

    <p class="prop-card__subtitle">{{display.city}}</p>

    <!-- commenting out as not much use for B2b & sometimes erros -->
    <!--
      <ng-container *ngIf="pricing$ | async">

        <div class="prop__card-pricing-chip" *ngIf="_user.userDb.role === 'landlord' && display.pricingPlan && !property.is_hmo">
          <div>
            <span>{{display.pricingPlan}}</span>
            <span>
              {{client_data.currency_symbol}}{{this.subscription$.getValue()}}
              {{(display.pricingPlan === 'Self manage' ? '/' + this.subscriptionInterval$.getValue() : display.pricingPlan === 'Tenant find'? '': '/annually')}}</span>
          </div>
        </div>

      </ng-container> -->

    <ul class="prop-card__list">

      <li>Bathrooms <span>{{display.totalBathrooms}}</span></li>
      <li>Bedrooms <span>{{display.totalBedrooms}}</span></li>
      <li *ngIf="!display.tenancy">Rent <span *ngIf="display.rent"> {{client_data.currency_symbol + display.rent}}<small>{{(!display.isHMO ? rentFrequency(display.rentInterval) : '/monthly')}}</small>  </span></li>
      <li *ngIf="display.tenancy">Rent <span
        *ngIf="display.rent || display.tenancy.rentAmount"> {{client_data.currency_symbol}}{{display.tenancy && !display.tenancy.rentAmount ? display.rent : display.tenancy.rentAmount }}
        <small>{{(!display.isHMO ? rentFrequency(display.tenancy.rentInterval) : '/month')}}</small>  </span></li>

      <li>Deposit <span *ngIf="display.deposit">{{client_data.currency_symbol}}{{display.deposit}}</span></li>
      <li *ngIf="!tenancy && !display.isHMO && !display.isMidTenants">Date available <span>{{display.dateAvailable}}</span></li>

    <ng-container *ngIf="tenancy">
      <li>Start date<span>{{display.tenancy.tenancyStart}}</span></li>
      <li>End date <span>{{display.tenancy.tenancyEnd}}</span></li>
      <li *ngIf="display.tenancy.tenancyAgreement">Agreement <span class="prop__card-underline" (click)="getTenancyAgreementDocument(display.tenancy)">View</span></li>
    </ng-container>

    <span *ngIf="!showMore && isDesktop" class="flex justify-center">
      <button type="button" class="btn__pseudo-link btn__pseudo-link--text-secondary" (click)="switchViews()">Show more</button>
    </span>

    <ng-container *ngIf="showMore || !isDesktop">
      <li>Property type <span>{{display.propertyType}}</span></li>
      <li *ngIf="display.parking">Parking <span>{{display.parking}}</span></li>
      <li>Bills <span>{{display.bills}}</span></li>
      <ng-template *ngIf="client_data !== 'CampusKey'">
        <li>House share <span>{{display.houseShare}}</span></li>
        <li>Live-in landlord <span>{{display.liveInLL}}</span></li>
      </ng-template>
    </ng-container>

    </ul>

    <span *ngIf="showMore && isDesktop" class="flex justify-center">
      <button type="button" class="btn__pseudo-link btn__pseudo-link--text-secondary" (click)="switchViews()">Show less</button>
    </span>


  </div>
  <ng-content></ng-content>
</span>
    </div>


  `,
  styleUrls: ['./property-card.component.scss']
})
export class PropertyCardListComponent implements OnInit {
  @Input() property: PropertyModel;
  @Input() bedroomId: string;
  @Input() static = false;
  @Output() closeOverlay?: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input() tenancy?: TenancyModel;

  @Input()
  get tenancyData(): TenancyModel {
    return this.tenancy;
  }

  set tenancyData(event: TenancyModel) {
    if (event) {
      this.tenancy = event;
      this.setPropertyCard();
    }
  }

  public routes = { Shared, Landlord };
  public display: any = {};
  public showMore = false;
  public sharedUrl;
  public copiedText;


  public subscriptionInterval$: BehaviorSubject<string> = new BehaviorSubject(null);
  public subscription$: BehaviorSubject<string> = new BehaviorSubject(null);
  public pricing$: Observable<any>;
  public pricingInterval$: Observable<any>;
  public isDesktop: boolean;
  public pricingPlan$: Observable<any>;
  public client_data = environment.client_data;

  constructor(
    public _titlecasePipe: TitleCasePipe,
    public _auth: AuthService,
    public _user: UserService,
    public _router: Router,
    private _pmt: PaymentsService,
    private _tenancy: TenancyService,
    private _math: MathService,
    private _toastr: ToastrService,
    private _media: MediaService
  ) {
  }

  ngOnInit() {
    this.setPropertyCard();
    this.sharedUrl = `${environment.url}/rent-property/${this.property.property_id}`;

    if (this._user.userDb.role === 'landlord' && this.property.pricing_plan) {
      this.pricingInterval$ = this._pmt.getCurrentUserPaymentsOverviewDoc();
      this.pricingPlan$ = this._pmt.getPricingDocument(this.property.pricing_plan);

      this.pricing$ = combineLatest([this.pricingInterval$, this.pricingPlan$]).pipe(
        map(([interval, pricingPlan]) => {
          if (interval && interval.subscription_interval && this.property.pricing_plan === SELF_MANAGE_100_200) {
            this.subscriptionInterval$.next(interval.subscription_interval);
            console.log(this.subscriptionInterval$.getValue());
            this.getSelfManagedSubscriptions(pricingPlan);
          } else {
            this.property.is_hmo ? this.subscription$.next(pricingPlan.unit_net_price_hmo) : this.subscription$.next(pricingPlan.unit_net_price_whole_property);
          }
          return { interval, pricingPlan };
        })
      );
    }


  }

  setPropertyCard() {

    const city = this.property.address && this.property.address.city ? this._titlecasePipe.transform(this.property.address.city) : null;
    const bedsArray: Array<any> = _.values(this.property.bedrooms);
    const totalRoomsUnAvailable = this.property.is_hmo && this.property.bedrooms ? bedsArray.filter(bed => !bed.is_listed).length : 0;
    const totalRoomsAvailable = this.property.is_hmo ? bedsArray.length - totalRoomsUnAvailable : 0;
    const totalRoomsUnderOffer = this.property.is_hmo ? bedsArray.filter(bed => bed.is_listed && bed.is_under_offer).length : 0;

    const depositArray: number[] = bedsArray.map((bed: any) => bed.deposit_amount ? bed.deposit_amount : null);
    const HMODeposit = depositArray && depositArray.length ? Math.min(...depositArray) + ' - ' + Math.max(...depositArray) : null;

    const housePhoto = this.property.property_photos && this.property.property_photos.length ? this.property.property_photos[0].image_large_url : null;
    const bedroomPhoto = this.bedroomId && this.property.bedrooms[this.bedroomId].room_thumbnail_url &&
      this.property.bedrooms[this.bedroomId].room_thumbnail_url.image_url !== null ?
      this.property.bedrooms[this.bedroomId].room_thumbnail_url.image_large_url : housePhoto;
    const dateAvailable = !this.property.is_hmo && !this.property.is_mid_tenants && this.property.date_available ? this.formatDateAvailable(this.property.date_available) : undefined;
    if (!(this.property.media_ids && this.property.media_ids.length)) {
      const propertyDocs = this.property.property_documents;
      const floorPlan = propertyDocs && propertyDocs.length ? propertyDocs[3] : null;

      this.display = {
        isMidTenants: this.property.is_mid_tenants,
        photo: this.property.is_hmo && this.bedroomId ? bedroomPhoto : housePhoto,
        rent: this.getRentAmount(),

        rentInterval: !this.property.is_hmo ? this.property.rent_interval_count : null,
        rentQtr: !this.property.is_hmo && this.property.rent_qtr ? this.property.rent_qtr : null,
        deposit: !this.property.is_hmo ? (this.property.deposit_amount ? parseInt(this.property.deposit_amount, 10).toLocaleString() : null) : HMODeposit ? parseInt(HMODeposit, 10).toLocaleString() : null,
        isHMO: this.property.is_hmo,
        totalBedrooms: this.property.number_bedrooms,
        totalBathrooms: this.property.number_bathrooms,
        totalRoomsAvailable,
        totalRoomsUnderOffer,
        underOffer: this.property.is_under_offer || false,
        isDeleted: this.property.is_deleted,
        isListed: this.property.is_listed,
        firstLineAddress: this.property.address && this.property.address.first_line_address ? this.property.address.first_line_address : null,
        city: city ? this._user.userDb.role === 'landlord' ? city + ', ' + this.property.address.post_code : city + ', ' + this.property.address.post_code.split(' ')[0] : null,
        dateAvailable: !this.tenancy ? dateAvailable : undefined,
        pricingPlan: this.property.pricing_plan ? this.getPricingPlanName(this.property.pricing_plan) : undefined,
        parking: this.property.parking_access ? this.property.parking_access.charAt(0).toUpperCase() + this.property.parking_access.substr(1).replace('_', ' ') : undefined,
        heating: this.property.heating ? this.property.heating.charAt(0).toUpperCase() : undefined,
        bills: this.property.bills_included === true ? 'Included' : 'Not included',
        tenantType: this.property.tenant_preference ? this.property.tenant_preference.charAt(0).toUpperCase() + this.property.tenant_preference.substring(1) : 'Incomplete',
        houseShare: this.property.is_house_share === true ? 'Yes' : 'No',
        liveInLL: this.property.has_live_in_landlord === true ? 'Yes' : 'No',
        totalPictures: this.property.property_photos && this.property.property_photos.length ? this.property.property_photos.length : null,
        hasFloorplan: propertyDocs && floorPlan
          && floorPlan.image_url ? true : false,
        propertyType: PropertyType[this.property.property_type],
        tenancy: this.tenancy ? {
          tenancyStart: this.getMomentDate(this.tenancy.tenancy_start),
          tenancyEnd: this.tenancy.tenancy_end === null || this.tenancy.tenancy_end === false ? 'No end date' : this.getMomentDate(this.tenancy.tenancy_end),
          tenancyAgreement: this.tenancy.agreement_url ? this.tenancy.agreement_url : null,
          tenancyAgreementId: this.tenancy.tenancy_agreement_id,
          rentAmount: this._tenancy.getTenancyRentAmount(this.tenancy),
          rentInterval: this.tenancy.rent_interval_count,
        } : undefined

      };
    }
    else {
      this._media.getMediaFromIds(this.property.media_ids).pipe(map((medias: IMedia[]) => {
        return medias.filter(media => media.type === 'property_documents');
      }), tap(
        (medias: IMedia[]) => {
          const floorDoc = medias.find(media => media.view_name === 'Floorplan');
          let floorPlan = null;
          if (floorDoc) {
            floorPlan = {
              name: floorDoc.view_name || null,
              expiry_date: floorDoc.date_expires ? new Date(floorDoc.date_expires['seconds'] * 1000) : null,
              ...floorDoc.epc_rating && { epc_rating: floorDoc.epc_rating },
              image_url: floorDoc.url || null
            }
          }
          this.display = {
            isMidTenants: this.property.is_mid_tenants,
            photo: this.property.is_hmo && this.bedroomId ? bedroomPhoto : housePhoto,
            rent: this.getRentAmount(),

            rentInterval: !this.property.is_hmo ? this.property.rent_interval_count : null,
            rentQtr: !this.property.is_hmo && this.property.rent_qtr ? this.property.rent_qtr : null,
            deposit: !this.property.is_hmo ? (this.property.deposit_amount ? parseInt(this.property.deposit_amount, 10).toLocaleString() : null) : HMODeposit ? parseInt(HMODeposit, 10).toLocaleString() : null,
            isHMO: this.property.is_hmo,
            totalBedrooms: this.property.number_bedrooms,
            totalBathrooms: this.property.number_bathrooms,
            totalRoomsAvailable,
            totalRoomsUnderOffer,
            underOffer: this.property.is_under_offer || false,
            isDeleted: this.property.is_deleted,
            isListed: this.property.is_listed,
            firstLineAddress: this.property.address && this.property.address.first_line_address ? this.property.address.first_line_address : null,
            city: city ? this._user.userDb.role === 'landlord' ? city + ', ' + this.property.address.post_code : city + ', ' + this.property.address.post_code.split(' ')[0] : null,
            dateAvailable: !this.tenancy ? dateAvailable : undefined,
            pricingPlan: this.property.pricing_plan ? this.getPricingPlanName(this.property.pricing_plan) : undefined,
            parking: this.property.parking_access ? this.property.parking_access.charAt(0).toUpperCase() + this.property.parking_access.substr(1).replace('_', ' ') : undefined,
            heating: this.property.heating ? this.property.heating.charAt(0).toUpperCase() : undefined,
            bills: this.property.bills_included === true ? 'Included' : 'Not included',
            tenantType: this.property.tenant_preference ? this.property.tenant_preference.charAt(0).toUpperCase() + this.property.tenant_preference.substring(1) : 'Incomplete',
            houseShare: this.property.is_house_share === true ? 'Yes' : 'No',
            liveInLL: this.property.has_live_in_landlord === true ? 'Yes' : 'No',
            totalPictures: this.property.property_photos && this.property.property_photos.length ? this.property.property_photos.length : null,
            hasFloorplan: !!floorPlan
              && floorPlan.image_url ? true : false,
            propertyType: PropertyType[this.property.property_type],

            tenancy: this.tenancy ? {
              tenancyStart: this.getMomentDate(this.tenancy.tenancy_start),
              tenancyEnd: this.tenancy.tenancy_end === null || this.tenancy.tenancy_end === false ? 'No end date' : this.getMomentDate(this.tenancy.tenancy_end),
              tenancyAgreement: this.tenancy.agreement_url ? this.tenancy.agreement_url : null,
              tenancyAgreementId: this.tenancy.tenancy_agreement_id,
              rentAmount: this._tenancy.getTenancyRentAmount(this.tenancy),
              rentInterval: this.tenancy.rent_interval_count,
            } : undefined

          };
        }
      ), take(1)).subscribe();
    }
  }

  getRentAmount() {
    const rentPCM = this.property.household_rent_pcm !== undefined ? this.getRentPCM() : null;
    const roomMin = this.property.is_hmo && this.property.hmo_min_pcm ? this.property.hmo_min_pcm : null;
    const roomMax = this.property.is_hmo && this.property.hmo_max_pcm ? this.property.hmo_max_pcm : null;
    return (this.property.is_hmo && roomMin !== roomMax) ? this._math.roundToTwoDP(roomMin) + ' - ' + this.client_data.currency_symbol + this._math.roundToTwoDP(roomMax) :
      (this.property.is_hmo && roomMin === roomMax) ? this._math.roundToTwoDP(roomMin) : this._math.roundToTwoDP(rentPCM);

  }

  getRentPCM() {
    const intervalCount = this.property.rent_interval_count;
    if (intervalCount === 1 && this.property.household_rent_pcm) {
      return this.property.household_rent_pcm;
    } else if (intervalCount === 3 && this.property.rent_qtr) {
      return this.property.rent_qtr;
    } else if (intervalCount === 0 && this.property.rent_weekly) {
      return this.property.rent_weekly;
    } else {
      return null;
    }

  }

  checkCopiedText() {
    if (this.copiedText === this.sharedUrl) {
      this._toastr.success('Listing link copied');
    }
  }


  gotoProperty() {
    if (this._user.userDb.role === 'tenant') {
      this.closeOverlay.emit(true);
      this._router.navigate(['/' + this.routes.Shared.search, this.property.property_id]);
    } else {
      this.closeOverlay.emit(true);
      this._router.navigate(['/' + Landlord.base, Landlord.properties.base, Landlord.properties.detail, this.property.property_id]);
    }
  }

  formatDateAvailable(value: any) {
    if (value && value._seconds) {
      const timeStamp = (value._seconds * 1000);
      const date = new Date(timeStamp);
      return date.toLocaleDateString();
    } else {
      return this.getMomentDate(value);
    }
  }

  getMomentDate(date: any) {
    return moment(date.toDate()).format('DD MMM YYYY');
  }

  getPricingPlanName(pricingName: string): string {
    return (pricingName === TENANT_FIND_100_200) ? 'Tenant find' : (pricingName === SELF_MANAGE_100_200)
      ? 'Self manage' : 'Fully managed';
  }

  switchViews() {
    this.showMore = !this.showMore;
  }

  getSelfManagedSubscriptions(pricing: any) {
    if (this.property.is_hmo) {
      this.subscriptionInterval$.getValue() === 'month' ? this.subscription$.next(pricing.unit_monthly_price_hmo) : this.subscription$.next(pricing.unit_annual_price_hmo);
    } else {
      this.subscriptionInterval$.getValue() === 'month' ? this.subscription$.next(pricing.unit_monthly_price_whole_property) : this.subscription$.next(pricing.unit_annual_price_whole_property);
    }
  }

  openDocument(url: string) {
    window.open(url, '_blank');
  }

  getTenancyAgreementDocument(tenancy: any) {

    if (tenancy.tenancyAgreement.includes('esignatures')) {
      this._tenancy.getTenancyEsignatureDocument(tenancy.tenancyAgreementId)
        .subscribe((res: any) =>
          this.openDocument(res.agreement_url),
          err => {
            console.log('HTTP Error', err);
          });
    } else {
      this.openDocument(tenancy.tenancyAgreement);
    }
  }

  rentFrequency(intervalCount) {
    if (intervalCount === 1) {
      return '/monthly';
    } else if (intervalCount === 0) {
      return '/weekly';
    } else {
      return '/quarterly';
    }
  }

}

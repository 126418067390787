import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { AuthService } from '@app/core/services';

@Injectable()
export class IdentificationService {
  constructor(
    private _http: HttpClient,
    private _auth: AuthService
  ) {
  }

  public startCheck(data: string, document_type: string, mime_type: string): Observable<any> {
    console.log(data, document_type, mime_type, 'the body start check');

    const body = {
      uid: this._auth.currentUserId,
      document_image: {
        data,
        document_type,
        mime_type
      }
    };
    return this._http.post(environment.firebaseConfig.apiUrl + '/identityVerification-startCheck', body);
  }

  public checkResults(): Observable<any> {
    const body: any = {
      uid: this._auth.currentUserId
    };
    // Check for sandbox
    if (!environment.production) {
      body.test_reference = 'pdc-1';
    }

    return this._http.post(environment.firebaseConfig.apiUrl + '/identityVerification-checkResults', body);
  }
}

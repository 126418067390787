
    <ng-container *ngIf="data$ | async as dataSets">
      <ng-container *ngIf="dataSets && dataSets.length > 0; else noData">
        <cdk-virtual-scroll-viewport itemSize="100" (scrolledIndexChange)="nextBatch($event, (dataSets[dataSets.length - 1])[query.order.key])">
            <ng-container *cdkVirtualFor="let data of dataSets; trackBy: trackByIdx">
            <ng-container *ngTemplateOutlet="templateRef; context: { $implicit: data }"></ng-container>
<!--              THIS BELOW IS FOR END OF SCROLL NOTIFICATIONS ETC-->
              <ng-content></ng-content>
            </ng-container>
          <ng-container *ngIf="loading">
            <div class="flex justify-center margin-top--sm margin-bottom--sm">
              <processing-spinner color="#FFFFFF" [size]="'52px'"></processing-spinner>
            </div>
          </ng-container>
        </cdk-virtual-scroll-viewport>
      </ng-container>
    </ng-container>

    <ng-template #noData>
      <div class="flex justify-center margin-top--sm margin-bottom--sm">
        <p class="text--subtle">You have no notifications.</p>
      </div>
    </ng-template>
  
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {PropertyService, TenancyService, UserService} from '@app/services';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {PAYMENT_STATUS} from '@app/shared/shared.constants';
import {finalize, map, tap} from 'rxjs/operators';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material';
import {MomentDateAdapter} from '@angular/material-moment-adapter';
import {MY_FORMATS} from '@app/shared/_services/dates.service';
import {PaymentsService} from '@app/services/payments.service';
import {ToastrService} from 'ngx-toastr';
import * as moment from 'moment';
import { ExpensesService } from '@app/services/expenses.service';
import { Observable } from 'rxjs';
import { AuthService } from '@app/core/services';
import {environment} from '@env/environment';

@Component({
  selector: 'form-create-expenses',
  template: `
    <h3 class="margin-bottom--md">Create Expenses</h3>
    <form [formGroup]="formGroup" autocomplete="none" (ngSubmit)="submitForm()">

    <bunk-form-field [showLabel]="true">
    <label bunk-label>What is the expense type?</label>

    <mat-form-field  bunk-input appearance="outline">
      <mat-select [formControlName]="'expense_type'">
      <mat-option *ngFor="let status of expenseTypes" [value]="status.value">
      {{status.viewValue}}
    </mat-option>
      </mat-select>
      <mat-error>Expense type is required</mat-error>
    </mat-form-field>
  </bunk-form-field>

      <bunk-form-field [showLabel]="true">
        <label bunk-label>What is the status of the expense?</label>
        <mat-form-field  bunk-input appearance="outline">
          <mat-select [formControlName]="'status'">
            <mat-option *ngFor="let status of expenseStatuses" [value]="status.value">
              {{status.viewValue}}
            </mat-option>
          </mat-select>
          <mat-error>Expense status is required</mat-error>
        </mat-form-field>
      </bunk-form-field>

      <bunk-form-field [showLabel]="true" *ngIf="(isAdmin$ | async) || moneyhubUserId">
        <label bunk-label>Select the outward transaction</label>
        <div bunk-input>
            <instant-new-algolia-search
              [indexName]="'transactions'"
              [multiselect]="false"
              [fieldsToReturn] = "['transaction_id','long_description', 'short_description','timestamp_paid', 'transaction_amount']"
              [fieldsToDisplay]="['long_description', {path:'transaction_amount', prefix:'£'}, {path:'timestamp_paid', formatTime:true}]"
              (valueChange)="setTransaction($event)"></instant-new-algolia-search>
        </div>
          <mat-error>Expense transaction is required</mat-error>
      </bunk-form-field>

      <bunk-form-field [showLabel]="true">
      <label bunk-label>What is the expense amount?</label>
      <mat-form-field  bunk-input appearance="outline">
        <input [formControlName]="'amount'" type="number" matInput>
        <mat-error>The expense amount is required</mat-error>
      </mat-form-field>
    </bunk-form-field>

    <bunk-form-field [showLabel]="true">
    <label bunk-label>When was the expense due?</label>
    <mat-form-field  bunk-input appearance="outline">
      <input [formControlName]="'due_date'" matInput
             (click)="datePaid.open()"
             [matDatepicker]="datePaid"
      >
      <mat-datepicker-toggle matSuffix [for]="datePaid"></mat-datepicker-toggle>
      <mat-datepicker #datePaid ></mat-datepicker>
      <mat-error>Expense due date is required</mat-error>
    </mat-form-field>
  </bunk-form-field>

    <form-get-tenancy-details [resetForm]="resetForm" [formControlName]="'property_details'" [submitAttempted]="submitAttempted" [showReference] = "false"></form-get-tenancy-details>
    <ng-container>
        <bunk-form-field [showLabel]="true">
          <label bunk-label>What reference code do you want to use?</label>
          <mat-form-field bunk-input appearance="outline">
            <input type="text" [formControlName]="'expense_reference_code'" matInput>
            <mat-error>Please add a reference code</mat-error>
          </mat-form-field>
        </bunk-form-field>
      </ng-container>
      <div class="margin-top--lg flex justify-end">
        <button  class="btn__generic btn__form-submit btn__form-submit--align-right" type="submit">
          <span *ngIf="!submittingData && !submittingDataSuccess"> Create Expense</span>
          <processing-spinner *ngIf="submittingData || submittingDataSuccess" color="#FFFFFF" [complete]="submittingDataSuccess"></processing-spinner>
        </button>
      </div>
    </form>
  `,
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
  ]
})

export class FormCreateExpensesComponent implements OnInit {

  @Input() expenseTypes = [];
  public formGroup: FormGroup;
  public submitAttempted = false;
  public isValidation = false;
  public tenants = [];
  public expenseStatuses = [
   { viewValue: 'Paid', value: 'paid'},
   { viewValue: 'Overdue', value: 'overdue'},
  ];

  public hideSearch = false;
  public paidStatuses = ['paid', 'partially_paid', 'received', 'partially_received'];
  public resetForm = false;
  public submittingData = false;
  public submittingDataSuccess = false;
  public client_data = environment.client_data;
  public moneyhubUserId;
  @Output() closeDialog: EventEmitter<boolean> = new EventEmitter<boolean>();
  public schedule;
  isAdmin$;
  constructor(
              private _expenses: ExpensesService,
              private _toastr: ToastrService,
              private _formBuilder: FormBuilder,
              private _auth: AuthService,
              private _user:UserService) {
    this.formGroup = this.initFormGroup();
  }

  get status(): FormControl {
    return this.formGroup.get('status') as FormControl;
  }

  get expense_type(): FormControl {
    return this.formGroup.get('expense_type') as FormControl;
  }

  get amount(): FormControl {
    return this.formGroup.get('amount') as FormControl;
  }

  get due_date(): FormControl {
    return this.formGroup.get('due_date') as FormControl;
  }

  get transaction_id(): FormControl {
    return this.formGroup.get('transaction_id') as FormControl;
  }

  get expense_reference_code(): FormControl {
    return this.formGroup.get('expense_reference_code') as FormControl;
  }

  get timestamp_paid(): FormControl{
    return this.formGroup.get('timestamp_paid') as FormControl;
  }

  get property_details(): FormControl{
    return this.formGroup.get('property_details') as FormControl;
  }

  ngOnInit() {
    this.isAdmin$ = this._auth.getCurrentUserCustomClaims();
    this.moneyhubUserId = this._user.userReadOnlyDb.moneyhub_user_id;
  }


  initFormGroup() {
    return this._formBuilder.group({
      amount: [null, Validators.required],
      status: [null, Validators.required],
      expense_type: [null, Validators.required],
      due_date: [null, Validators.required],
      transaction_id: null,
      expense_reference_code: null,
      property_details: null,
      timestamp_paid: null
    });
  }

  submitForm() {
    this.property_details.setValidators([]);
    this.property_details.updateValueAndValidity();
    console.log(this.formGroup.valid)
    if (this.formGroup.valid) {
      this.submittingData = true;
     console.log(this.formGroup.getRawValue(), 'raw value');
     const property_details = this.formGroup.get('property_details').value;
     const item = {
       ...this.formGroup.getRawValue(),
       property_details: {
         // being passed in from another component
          property_id: property_details.property_details.property_id,
          bedroom_id: property_details.property_details.bedroom_id,

          tenancy_id: property_details.tenancy_id,
          sender_uid: property_details.sender_uid,
         user_reference_code: property_details.user_reference_code
       },
     };

     console.log(item, 'hello item')
     return this._expenses.userCreateExpense(item).pipe(
       finalize(() => this.submittingData = false)
     ).subscribe(() => {
         this._toastr.success(`Expenses created successfully`);
         this.formGroup.reset();
         this.formGroup.updateValueAndValidity();
         this.resetForm = true;
         this.closeDialog.emit(true);
         this.submittingDataSuccess = true;
       },
       (err: any) => {
       console.log({err});
       if (err.status === 403) {
         this._toastr.error(err.error.message);
       } else {
         this._toastr.error(`Expense creation failed`);
       }
       });
    } else {
      this._toastr.error('Please fill in all required fields');
    }
  }
  setTransaction(e){
    console.log(e, 'helloo');
    if(!e.length){
      this.transaction_id.patchValue(null);
      this.expense_reference_code.patchValue(null);
      this.timestamp_paid.patchValue(null);
      this.amount.patchValue(null);
      return;
    }
    let object = e[0]
    this.transaction_id.patchValue(object.transaction_id);
    this.expense_reference_code.patchValue(object.long_description);
    this.timestamp_paid.patchValue(object.timestamp_paid);
    this.amount.patchValue(object.transaction_amount);
  }
}


    <ng-container *ngIf="userSteps$ | async as userSteps">
      <section *ngIf="stepComponent" class="main__wrapper main__wrapper--narrow main__wrapper--mb-lg">
        <ng-container *ngComponentOutlet="stepComponent; ndcDynamicOutputs: outputs; ndcDynamicInputs: inputs;"></ng-container>
      </section>
      <footer *ngIf="userSteps.length > 1 && !requestViewings" class="verify-tiers__footer">
        <ul>
          <li><span role="button" class="status__step status__step--verify-tier-pending" [ngClass]="{'status__step--verify-complete': currentStepNumber !== -1}"></span></li>
          <li *ngFor="let step of userSteps, let i = index">
            <span
              role="button"
              class="status__step status__step--verify"
              [ngClass]="{
                'status__step--verify-tier-pending': currentStepNumber === i,
                'status__step--verify-complete': step.status === 'approved'
              }">
            </span>
          </li>
        </ul>
      </footer>
    </ng-container>
  
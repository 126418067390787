import { Component, forwardRef, Input, OnInit, Optional } from '@angular/core';
import { FormControl, FormGroup, FormGroupDirective, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validator, Validators } from '@angular/forms';
import { AbstractValueAccessor } from '@app/shared/_components/searches/instant-search/instant-search.utils';
import { FormsService } from '@app/services/form.service';
import { CAMPUS_KEY_YEARS, FORM_SEARCH_DETAILS, FORM_SEARCH_DETAILS_CAMPUS_KEY, RENTING_OPTIONS, SEARCHING_OPTIONS, STUDY_YEAR } from './application-forms.constants';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material';
import { UK_DATE_FORMAT } from '@app/_constants/date.constants';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { environment } from '@env/environment';

@Component({
  selector: 'form-search-details',
  template: `
    <form [formGroup]="searchForm" autocomplete="none">
      <bunk-form-field *ngIf="getFormControl('is_student')">
        <label bunk-label>Are you a student?</label>
        <div bunk-input>
          <mat-radio-group formControlName="is_student" class="flex flex-row margin-bottom" (change)="isUniValidation(getFormControl('is_student').value)">
            <mat-radio-button [value]="true" class="bunk-outlined-radio mr_5" [ngClass]="{'bunk-outlined-radio--selected': getFormControl('is_student').value === true}">Yes</mat-radio-button>
            <mat-radio-button [value]="false" class="bunk-outlined-radio" [ngClass]="{'bunk-outlined-radio--selected': getFormControl('is_student').value === false}">No</mat-radio-button>
          </mat-radio-group>
          <mat-error class="bunk-mat-error-font-match" *ngIf="getFormControl('is_student').getError('required') && submitAttempted">Please select</mat-error>
        </div>
      </bunk-form-field>


      <bunk-form-field *ngIf="getFormControl('searching_with')">
        <label bunk-label>Who are you searching with?</label>
        <mat-form-field bunk-input appearance="outline">
          <mat-select formControlName="searching_with" placeholder="Select">
            <mat-option *ngFor="let search of searching" [value]="search.value">
              {{search.text}}
            </mat-option>
          </mat-select>
          <mat-error>Please select who you are searching with</mat-error>
        </mat-form-field>
      </bunk-form-field>
      <bunk-form-field *ngIf="getFormControl('searching_with') && getFormControl('searching_with').value === 'family' && getFormControl('number_children')">
        <label bunk-label>Do you have children, if so how many?</label>
        <mat-form-field bunk-input appearance="outline">
          <input matInput formControlName="number_children" type="number" min="0" placeholder="E.g 2">
        </mat-form-field>
      </bunk-form-field>

      <bunk-form-field *ngIf="getFormControl('has_pets')">
        <label bunk-label>Do you have any pets?</label>
        <div bunk-input>
          <mat-radio-group formControlName="has_pets" class="flex flex-row margin-bottom">
            <mat-radio-button [value]="true" class="bunk-outlined-radio mr_5" [ngClass]="{'bunk-outlined-radio--selected': getFormControl('has_pets').value === true}">Yes</mat-radio-button>
            <mat-radio-button [value]="false" class="bunk-outlined-radio" [ngClass]="{'bunk-outlined-radio--selected': getFormControl('has_pets').value === false}">No</mat-radio-button>
          </mat-radio-group>
          <mat-error class="bunk-mat-error-font-match" *ngIf="getFormControl('has_pets').getError('required') && submitAttempted">Please select</mat-error>
        </div>
      </bunk-form-field>
      <bunk-form-field *ngIf="getFormControl('is_smoker')">
        <label bunk-label>Are you a smoker?</label>
    <div bunk-input>
      <mat-radio-group formControlName="is_smoker" class="flex flex-row margin-bottom">
        <mat-radio-button [value]="true" class="bunk-outlined-radio mr_5" [ngClass]="{'bunk-outlined-radio--selected': getFormControl('is_smoker').value === true}" >Yes</mat-radio-button>
        <mat-radio-button [value]="false" class="bunk-outlined-radio" [ngClass]="{'bunk-outlined-radio--selected': getFormControl('is_smoker').value === false}">No</mat-radio-button>
      </mat-radio-group>
      <mat-error class="bunk-mat-error-font-match"*ngIf="getFormControl('is_smoker').getError('required') && submitAttempted">Please select</mat-error>
    </div>
    </bunk-form-field>
    <bunk-form-field *ngIf="getFormControl('expected_move_in_date')">
    <label bunk-label>When are you looking to move into a property?</label>
    <mat-form-field bunk-input appearance="outline">
        <input matInput
              formControlName="expected_move_in_date"
              [matDatepicker]="moveInDate"
              (click)="moveInDate.open()"
              readonly
              placeholder="Choose a date"
              [min]="minDate"
        >
        <mat-datepicker-toggle matSuffix [for]="moveInDate"></mat-datepicker-toggle>
        <mat-datepicker #moveInDate></mat-datepicker>
        <mat-error>Please enter a move in date</mat-error>
    </mat-form-field>
    </bunk-form-field>
    <bunk-form-field *ngIf="getFormControl('is_renting')">
    <label bunk-label>Are you currently renting?</label>
    <div bunk-input>
      <mat-radio-group formControlName="is_renting" class="flex flex-row margin-bottom"  (change)="isRentingValidation(getFormControl('is_renting').value)">
        <mat-radio-button [value]="true" class="bunk-outlined-radio mr_5" [ngClass]="{'bunk-outlined-radio--selected': getFormControl('is_renting').value === true}" >Yes</mat-radio-button>
        <mat-radio-button [value]="false" class="bunk-outlined-radio" [ngClass]="{'bunk-outlined-radio--selected': getFormControl('is_renting').value === false}">No</mat-radio-button>
      </mat-radio-group>
      <mat-error class="bunk-mat-error-font-match"*ngIf="getFormControl('is_renting').getError('required') && submitAttempted">Please select</mat-error>
    </div>
    </bunk-form-field>

    <bunk-form-field *ngIf="getFormControl('currently_renting_from') && searchForm.get('is_renting').value === true">
    <label bunk-label> Who are you currently renting with?</label>
    <mat-form-field bunk-input appearance="outline">
          <mat-select formControlName="currently_renting_from" placeholder="Select">
            <mat-option *ngFor="let current of renting" [value]="current.value">
              {{current.text}}
            </mat-option>
          </mat-select>
    </mat-form-field>
  </bunk-form-field>



  <bunk-form-field *ngIf="getFormControl('institution_location')">
        <label bunk-label>Which tertiary institution will you be studying at? </label>
          <mat-form-field bunk-input appearance="outline">
            <input formControlName="institution_location" matInput type="text" name="institution_location" placeholder="E.g University of Cape Town">
            <mat-error>Please enter the name of your institution</mat-error>
          </mat-form-field>
  </bunk-form-field>

  <bunk-form-field *ngIf="getFormControl('study_year')">
    <label bunk-label >What study year are you next year?</label>
    <mat-form-field bunk-input appearance="outline">
      <mat-select formControlName="study_year" placeholder="Select">
        <mat-option *ngFor="let year of study_years" [value]="year.value">
          {{year.viewValue}}
        </mat-option>
      </mat-select>
    <mat-error>Please select the year</mat-error>
    </mat-form-field>
  </bunk-form-field>

  <bunk-form-field *ngIf="getFormControl('is_student_at_uni')">
    <label bunk-label >Are you a current {{client_data.company_name}} student?</label>
    <div bunk-input>
      <mat-radio-group formControlName="is_student_at_uni" class="flex flex-row margin-bottom">
        <mat-radio-button [value]="true" class="bunk-outlined-radio mr_5" [ngClass]="{'bunk-outlined-radio--selected': getFormControl('is_student_at_uni').value === true}" >Yes</mat-radio-button>
        <mat-radio-button [value]="false" class="bunk-outlined-radio" [ngClass]="{'bunk-outlined-radio--selected': getFormControl('is_student_at_uni').value === false}">No</mat-radio-button>
      </mat-radio-group>
      <mat-error class="bunk-mat-error-font-match"*ngIf="getFormControl('is_student_at_uni').getError('required') && submitAttempted">Please select</mat-error>
    </div>
    </bunk-form-field>

    <bunk-form-field *ngIf="getFormControl('years_at_uni') && searchForm.get('is_student_at_uni').value === true">
    <label bunk-label > How long have you been living with us?</label>
    <mat-form-field bunk-input appearance="outline">
      <mat-select formControlName="years_at_uni" placeholder="Select">
        <mat-option *ngFor="let year of years_at_campus" [value]="year.value">
          {{year.viewValue}}
        </mat-option>
      </mat-select>
    <mat-error>Please select the years</mat-error>
    </mat-form-field>
  </bunk-form-field>



    <bunk-form-field *ngIf="getFormControl('has_friends_at_uni')">
    <label bunk-label >Will any of your friends be living at {{client_data.company_name}}?</label>
    <div bunk-input>
      <mat-radio-group formControlName="has_friends_at_uni" class="flex flex-row margin-bottom">
        <mat-radio-button [value]="true" class="bunk-outlined-radio mr_5" [ngClass]="{'bunk-outlined-radio--selected': getFormControl('has_friends_at_uni').value === true}" >Yes</mat-radio-button>
        <mat-radio-button [value]="false" class="bunk-outlined-radio" [ngClass]="{'bunk-outlined-radio--selected': getFormControl('has_friends_at_uni').value === false}">No</mat-radio-button>
      </mat-radio-group>
      <mat-error class="bunk-mat-error-font-match"*ngIf="getFormControl('has_friends_at_uni').getError('required') && submitAttempted">Please select</mat-error>
    </div>
    </bunk-form-field>


  </form>
  `,

  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormSearchDetailsComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => FormSearchDetailsComponent),
      multi: true
    },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },
    { provide: MAT_DATE_FORMATS, useValue: UK_DATE_FORMAT }
  ]
})
export class FormSearchDetailsComponent extends AbstractValueAccessor implements OnInit, Validator {
  @Input() requiredFields = FORM_SEARCH_DETAILS;
  searchForm: FormGroup;
  public searching = SEARCHING_OPTIONS;
  public renting = RENTING_OPTIONS;
  public study_years = STUDY_YEAR;
  public years_at_campus = CAMPUS_KEY_YEARS
  private _submitAttempted = false;
  public minDate = new Date();
  public client_data = environment.client_data;
  public isCampusKey = environment.client_data.company_name === 'CampusKey';

  @Input('submitAttempted') set submitAttempted(boo: boolean) {
    this._submitAttempted = boo;
    if (boo) {
      this.dirtyFormGroup();
    }
  }
  get submitAttempted(): boolean {
    return this._submitAttempted;
  }

  dirtyFormGroup() {
    if(this.searchForm ){
    const controls = this.searchForm.controls;
    for (const control in controls) {
      if (controls.hasOwnProperty(control)) {
        this.searchForm.controls[control].markAsTouched();
      }
    }
  }
  }
  constructor(
    @Optional() public _reactiveForm: FormGroupDirective,
    private _forms: FormsService,
  ) {
    super();
  }

  getFormControl(name: string): FormControl {
    return this.searchForm.get(name) as FormControl;
  }

  ngOnInit(): void {

    if (this.isCampusKey) {
      this.requiredFields = FORM_SEARCH_DETAILS_CAMPUS_KEY;
    }

    this.searchForm = this._forms.toFormGroup(this.requiredFields.search_details);
    this.isUniValidation(this.getFormControl('is_student').value);
    if (this.getFormControl('number_children')) {
      this.getFormControl('number_children').setValidators(null);
      this.getFormControl('number_children').updateValueAndValidity();
    }
    if(this.getFormControl('years_at_uni')){
      this.getFormControl('years_at_uni').setValidators(null);
      this.getFormControl('years_at_uni').updateValueAndValidity();
    }
    this._reactiveForm.ngSubmit.subscribe((data: Event) => {
      this.submitAttempted = true;
      const controls = this.searchForm.controls;
      for (const control in controls) {
        if (controls.hasOwnProperty(control)) {
          this.searchForm.controls[control].markAsTouched();
        }
      }
    });
    if(this.getFormControl('is_student_at_uni')){
      this.getFormControl('is_student_at_uni').valueChanges.subscribe(value => {
        if(value){
          this.getFormControl('years_at_uni').setValidators(Validators.required);
          this.getFormControl('years_at_uni').updateValueAndValidity();
        }
        else{
          this.getFormControl('years_at_uni').patchValue(null);
          this.getFormControl('years_at_uni').setValidators(null);
          this.getFormControl('years_at_uni').updateValueAndValidity();
        }
      })
    }
   }

   isRentingValidation(selectValue: any) {
    if (selectValue === false && this.getFormControl('currently_renting_from') ) {
      this.getFormControl('currently_renting_from').setValidators(null);
      this.getFormControl('currently_renting_from').updateValueAndValidity();
    } else {
      if (this.getFormControl('currently_renting_from')) {
        this.getFormControl('currently_renting_from').setValidators([Validators.required]);
        this.getFormControl('currently_renting_from').updateValueAndValidity();
      }


    }

   }

   isUniValidation(selectValue: any) {
    if (selectValue === false) {
      this.searchForm.removeControl('university_details');
      this.searchForm.updateValueAndValidity();
    } else {
      this.searchForm.addControl('university_details', new FormControl(null));
      this.getFormControl('university_details').updateValueAndValidity();
      this.searchForm.updateValueAndValidity();
    }
   }

  public onTouched: () => void = () => { };



  writeValue(val: any): void {
    super.writeValue(val);
    if (val) {
      this.searchForm.patchValue(val, { emitEvent: false });
      if ( val.is_renting !== null) {
        this.isRentingValidation(val.is_renting );
      }
      if (val.is_student !== null) {
        this.isUniValidation(val.is_student);
      }
    }

  }
  registerOnChange(fn: any): void {
    this.searchForm.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.searchForm.disable() : this.searchForm.enable();
  }

  validate() {
    return this.searchForm.valid ? null : { invalidForm: { valid: false, message: 'FormSearchDetailsComponent > formGroup fields are invalid' } };
  }

}



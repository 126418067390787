import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@app/core/services';
import { map, tap } from 'rxjs/operators';
import { ViewingService, UserService, PropertyService } from '@app/services';
import { Component, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Landlord, Tenant } from '@env/routing';
import * as moment from 'moment';

@Component({
  selector: 'viewings-summary',
  templateUrl: './viewings-summary.component.html',
  styleUrls: ['./viewings-summary.component.scss']
})
export class ViewingsSummaryComponent implements OnInit {
  public viewings$: Observable<any>;
  constructor(private _viewings: ViewingService,
    public _auth: AuthService,
    private router: Router) {
    this.viewings$ = this._viewings.getViewings(20)
      .pipe(
        map(data => data.filter((obj: any) => !obj.has_expired))
      )
  }

  ngOnInit() {
  }

  viewingDate(date) {
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    const difference = moment(date).diff(today, 'days');

    if (difference === 0) {
      return { value: 'Today', color: 'success' }
    }
    if (difference === 1) {
      return { value: 'Tomorrow', color: 'subtle' }
    }
    else {
      return { value: 'date', color: difference > 1 ? 'subtle' : 'danger' }
    }
  }
  redirectToViewings(viewing) {
    return this.router.navigate(['./', Landlord.base, Landlord.manage.base, Landlord.manage.viewings.base, Landlord.manage.viewings.reschedule, viewing.viewing_id]);
  }
}

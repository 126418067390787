import {AfterContentChecked, Component, Input, OnInit, ViewChild} from '@angular/core';
import { Observable } from 'rxjs';
import { filter, flatMap, map } from 'rxjs/operators';
import {RENT_STATUS} from '@app/core-landlord/core-landlord.constants';

@Component({
    selector: 'payments-overview-bar',
    templateUrl: './payments-overview-bar.component.html',
    styleUrls: ['./payments-overview-bar.component.scss'],
})
export class PaymentsOverviewBarComponent implements OnInit, AfterContentChecked {
    @Input()
    payments$: Observable<any>;
    @Input()
    maxLength: number;
    @ViewChild('progressBar', { static: false })
    progressBar;
    public RENT_STATUS = RENT_STATUS;
    public paidDuringCurrentSession: string[] = [];

    public navItems: any[] = [];
    public months: Array<string> = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
    ];

    constructor() {}

    ngOnInit() {
        // Generate x dummy items on the bar, one for each month;
        for (let i = 0; i < this.maxLength; i++) {
            const date = new Date();
            const currentMonth = date.getMonth();
            const period =
                i + currentMonth > 11
                    ? {
                          month: i + currentMonth - 12,
                          year: date.getFullYear() + 1,
                      }
                    : { month: i + currentMonth, year: date.getFullYear() };
            this.navItems.push({
                status: '',
                month: period.month,
                year: period.year,
            });
        }

        this.payments$
            .pipe(
                map((data) => {
                    // Loop through user's payment
                    data.forEach((payment) => {
                        this.navItems.forEach((item) => {
                            // Remove dummy item from the bar if a payment for the respective month exists
                            if (
                                payment.month === item.month &&
                                payment.year === item.year
                            ) {
                                this.navItems.splice(
                                    this.navItems.indexOf(item),
                                    1,
                                    payment
                                );
                            }
                        });
                    });
                    return data;
                }),
                flatMap((data) => data),
                filter((payment: any) => {
                    const date = new Date();

                    // remove from the bar payments for past months, unless they're still overdue
                    if (
                        (payment.month >= date.getMonth() &&
                            payment.year === date.getFullYear()) ||
                        payment.year > date.getFullYear() ||
                        payment.status === 'due' ||
                        payment.status === 'overdue'
                    ) {
                        return payment;
                    }
                })
            )
            .subscribe((data) => {
                this.navItems.push(data);

                // trim bar according to max-length input
                this.navItems.splice(
                    this.maxLength,
                    this.navItems.length - this.maxLength
                );

                // fill inner line with a gradient
                this.navItems.forEach((item) => {
                    if (item.status === 'waived' || item.status === 'paid') {
                        this.updateProgressBarWidth(
                            this.navItems.indexOf(item)
                        );
                        this.progressBar.nativeElement.style.background =
                            'linear-gradient(to right, #53D691, #43E279)';
                    } else if (
                        item.status === 'scheduled' ||
                        item.status === 'due'
                    ) {
                        const index = this.navItems.indexOf(item);
                        this.updateProgressBarWidth(index);
                        const yellowStartAt =
                            (index * 100) / this.navItems.length;
                        this.progressBar.nativeElement.style.background = `linear-gradient(to right, #53D691 ${yellowStartAt /
                            3}% , #ffd728 150%)`;
                    }
                });
            });
    }

    ngAfterContentChecked(): void {
      const localStorageProps = Object.keys(localStorage);
      for (const property of localStorageProps) {
        if (localStorage[property] === 'paid') {
          this.paidDuringCurrentSession.push(property);
        }
      }
    }

  updateProgressBarWidth(index) {
        const value = (index * 100) / (this.navItems.length - 1);

        this.progressBar.nativeElement.style.width = value + '%';
    }
}

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material';

import { BunkDialogComponent } from './bunk-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    MatDialogModule
  ],
  exports: [BunkDialogComponent],
  declarations: [BunkDialogComponent],
  providers: [],
})
export class BunkDialogModule { }

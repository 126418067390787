import { Injectable } from '@angular/core';
import { ExportToCsv } from 'export-to-csv';

const DEFAULT_CSV_SETTINGS = {
  fieldSeparator: ',',
  quoteStrings: '"',
  decimalSeparator: '.',
  showLabels: true,
  useTextFile: false,
  useBom: true,
  useKeysAsHeaders: true,
}

@Injectable()
export class CSVService {
  constructor() { }

  exportToCSV(filename: string, jsonData: any): void {
    const csvExporter = new ExportToCsv({
      ...DEFAULT_CSV_SETTINGS,
      filename
    });

    csvExporter.generateCsv(jsonData);
  }
  
}
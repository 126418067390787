/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./file-preview-overlay.service";
import * as i2 from "@angular/cdk/overlay";
import * as i3 from "./bunk-overlay.component";
import * as i4 from "@angular/common";
var styles_BunkOverlayComponent = [];
var RenderType_BunkOverlayComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_BunkOverlayComponent, data: {} });
export { RenderType_BunkOverlayComponent as RenderType_BunkOverlayComponent };
export function View_BunkOverlayComponent_0(_l) { return i0.ɵvid(0, [i0.ɵncd(null, 0)], null, null); }
export function View_BunkOverlayComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 2, "bunk-overlay", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i0.ɵnov(_v, 2).onClick($event.target) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_BunkOverlayComponent_0, RenderType_BunkOverlayComponent)), i0.ɵprd(512, null, i1.FilePreviewOverlayService, i1.FilePreviewOverlayService, [i0.Injector, i2.Overlay]), i0.ɵdid(2, 704512, null, 0, i3.BunkOverlayComponent, [i1.FilePreviewOverlayService, i4.Location], null, null)], null, null); }
var BunkOverlayComponentNgFactory = i0.ɵccf("bunk-overlay", i3.BunkOverlayComponent, View_BunkOverlayComponent_Host_0, { autoOpen: "autoOpen", file: "file", pdf: "pdf", template: "template" }, { closed: "closed", overlayRef: "overlayRef" }, ["*"]);
export { BunkOverlayComponentNgFactory as BunkOverlayComponentNgFactory };

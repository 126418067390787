<screen-detection (resized)="isDesktop = $event.isDesktop"></screen-detection>

<div class="main__wrapper main__wrapper--reset-password">
  <div class="reset-password__section reset-password__section--background"
       [ngClass]="{'reset-password__section--background--cover': client_data.company_name === 'Bunk'}">
    <div class="logo">
      <a href="{{client_data.website_url}}"> <img src="{{logo}}" alt="{{client_data.company_name}}"> </a>
    </div>
  </div>

  <div *ngIf="!isValidToken, else resetPassword" class="invalid-token__section">
    <h1 class="margin-top margin-bottom">Token is invalid.</h1>
    <button class="btn__generic" routerLink="/">Go to home page</button>
  </div>

  <ng-template #resetPassword>
    <div class="reset-password__section">

    <div class="reset-password__form">

      <h1 class="margin-bottom">{{'Enter new password for your ' + client_data.company_name + ' account'}}</h1>

      <form [formGroup]="formGroup"
            (ngSubmit)="updatePassword()">
        <bunk-form-field direction="column" class="bunk-form-field--sm-margin" [showLabel]="showLabel">
          <label bunk-label for="password">Choose a password</label>
          <small bunk-label-stack class="flex">
            <ng-container *ngTemplateOutlet="password"></ng-container>
          </small>
          <div bunk-input class="flex flex-column">
            <mat-form-field appearance="outline">
              <mat-label *ngIf="!showLabel">Password</mat-label>
              <input matInput type="password" formControlName="password" (blur)="trim('password')" id="password"
                     placeholder="New password">
              <mat-error *ngIf="submitAttempted && !formGroup.get('password').valid">Please enter a valid password
              </mat-error>
            </mat-form-field>
          </div>
        </bunk-form-field>
        <button id="user-reset-password" class="btn__generic btn--lg reset-password__button" type="submit"
                [disabled]="showSpinner"><span *ngIf="!showSpinner; else spinner">Update Password</span></button>
      </form>
    </div>
  </div>
  </ng-template>
</div>

<ng-template #spinner>
  <processing-spinner color="#FFFFFF"></processing-spinner>
</ng-template>

<ng-template #password>
  <ul class="ul-reset password-strength" *ngIf="passwordStrength$ | async as passwordStrength">
    <li>At least 8 characters <span class="status__tick" *ngIf="passwordStrength.hasLength"></span></li>
    <li>Includes a uppercase <span class="status__tick" *ngIf="passwordStrength.hasUppercase"></span></li>
    <li>Includes a lowercase letter <span class="status__tick" *ngIf="passwordStrength.hasLowercase"></span></li>
    <li>Includes a number <span class="status__tick" *ngIf="passwordStrength.hasNumber"></span></li>
  </ul>
</ng-template>

import { OnInit, EventEmitter } from '@angular/core';
import * as moment from 'moment';
import { FormBuilder, Validators } from '@angular/forms';
import { AuthService } from '@app/core/services';
import { PropertyService, StorageService } from '@app/services';
import { last, map, mergeMap, switchMap, tap } from 'rxjs/operators';
import { DatesService, UK_FORMAT } from '@app/shared/_services/dates.service';
import { ActivatedRoute, Router } from '@angular/router';
import { PROPERTY_DOCS } from '@app/_constants/property_docs.constants';
import { of } from 'rxjs';
import { MediaService } from '@app/services/media.service';
export const DOCUMENT_TYPES = [
    { text: 'Energy Performance Certificate', value: 'EPC' },
    { text: 'Gas safety', value: 'GAS' },
    { text: 'Fire Safety', value: 'Fire Safety' },
    { text: 'Floorplan', value: 'Floorplan' },
    { text: 'Electric Installation Condition Report', value: 'EICR' },
    { text: 'Inventory', value: 'Inventory' },
    { text: 'Deposit certificate', value: 'Deposit certificate' },
    { text: 'Other', value: 'other' },
];
export const EPC_RATING = ['A', 'B', 'C', 'D', 'E', 'F', 'G'];
const ɵ0 = UK_FORMAT;
export class UploadDocumentsForm {
    constructor(_formBuilder, _auth, _property, _storage, _router, _date, _activatedRoute, _media) {
        this._formBuilder = _formBuilder;
        this._auth = _auth;
        this._property = _property;
        this._storage = _storage;
        this._router = _router;
        this._date = _date;
        this._activatedRoute = _activatedRoute;
        this._media = _media;
        this.mediaIds = [];
        this.closeOverly = new EventEmitter();
        this.display = {};
        this.documentTypes = DOCUMENT_TYPES;
        this.epcRatings = EPC_RATING;
        this.currentDate = new Date();
        this.submittingDataSuccess = false;
        this.submittingData = false;
        this.onUploadedFile = (task) => {
            this.documentFile = task.target.files[0];
            this._storage
                .uploadFile(task, this.filePath)
                .pipe(last(), tap(data => { this.mediaData = data; }), mergeMap(() => this._storage.downloadURL), tap((url) => {
                this.image_url.patchValue(url);
            }))
                .subscribe(success => console.log('Upload certificate: ', success), error => {
                console.log('An error as occurred: ', error);
            });
        };
        this.isCertificatePDF = (url) => {
            return (url.toString().toLowerCase().indexOf('.pdf') > 0);
        };
        this.documentForm = this.initFormGroup();
    }
    get property() {
        return this.propertyData;
    }
    set property(data) {
        this.propertyData = data;
        if (!this.bedroomId) {
            this.mediaIds = data.media_ids ? data.media_ids : [];
        }
    }
    get bedroomId() {
        return this._bedroomId;
    }
    set bedroomId(id) {
        if (id) {
            this._bedroomId = id;
            this.mediaIds = this.property ? this.property.bedrooms[id].media_ids || [] : [];
        }
    }
    get document_type() {
        return this.documentForm.get('document_type');
    }
    get property_documents() {
        return this.documentForm.get('property_documents');
    }
    get image_url() {
        return this.documentForm.get('image_url');
    }
    get has_expiry() {
        return this.documentForm.get('has_expiry');
    }
    get expiry_date() {
        return this.documentForm.get('expiry_date');
    }
    get other() {
        return this.documentForm.get('other');
    }
    get epc_rating() {
        return this.documentForm.get('epc_rating');
    }
    ngOnInit() {
        this.filePath = this.bedroomId
            ? `user/${this._auth.currentUserId}/property/${this.property.property_id}/${this.property.bedroom_id}/certificate` :
            `user/${this._auth.currentUserId}/property/${this.property.property_id}/certificate`;
        if (this.index >= 0) {
            const doc = this.bedroomId ? this.property.bedrooms[this.bedroomId].property_documents[this.index] : this.property.property_documents[this.index];
            this.image_url.patchValue(doc.image_url);
            if (doc.expiry_date) {
                this.expiry_date.patchValue(doc.expiry_date.toDate());
            }
            if (this.index >= 7) {
                this.document_type.patchValue('other');
                this.other.patchValue(doc.name);
                if (doc.expiry_date) {
                    this.has_expiry.patchValue(true);
                }
                if (doc.name === 'Inventory') {
                    this.document_type.patchValue('Inventory');
                }
            }
            else {
                this.document_type.patchValue(doc.name);
            }
            if (doc.name === 'EPC') {
                this.epc_rating.patchValue(doc.epc_rating);
            }
            console.log(this.property_documents.value, 'property docs in value');
        }
    }
    getMomentDate(date) {
        return moment(date.toDate()).format('DD MMM YYYY');
    }
    checkIfDocExpired(date) {
        const expiredDate = this._date.getTimeZone();
        const docDate = date ? date.toDate() : null;
        switch (true) {
            case expiredDate.isAfter(docDate):
                return 'Expired';
            case expiredDate.isBefore(docDate):
                return 'Up-to-date';
            default:
                return 'Incomplete';
        }
    }
    initFormGroup() {
        return this._formBuilder.group({
            document_type: ['', Validators.required],
            other: [''],
            image_url: [null],
            expiry_date: null,
            epc_rating: null,
            has_expiry: null,
            property_documents: this._formBuilder.array([
                this._formBuilder.group({
                    name: 'EPC',
                    image_url: null,
                    expiry_date: null,
                    epc_rating: null
                }),
                this._formBuilder.group({
                    name: 'GAS',
                    image_url: null,
                    expiry_date: null
                }),
                this._formBuilder.group({
                    name: 'Fire Safety',
                    image_url: null,
                    expiry_date: null
                }),
                this._formBuilder.group({
                    name: 'Floorplan',
                    image_url: null
                }),
                this._formBuilder.group({
                    name: 'EICR',
                    image_url: null,
                    expiry_date: null
                }),
                this._formBuilder.group({
                    name: 'Inventory',
                    image_url: null,
                }),
                this._formBuilder.group({
                    name: 'Deposit certificate',
                    image_url: null,
                })
            ])
        });
    }
    submitDocument(value) {
        const docs = this.bedroomId ? this.property.bedrooms[this.bedroomId].property_documents : this.property.property_documents ? this.property.property_documents : PROPERTY_DOCS;
        if (value.image_url === null) {
            this.image_url.setErrors({ required: true });
        }
        console.log('DOES INDEX EXIST', this.index);
        if (this.documentForm.valid) {
            this.submittingData = true;
            this.documentForm.get('property_documents').patchValue(docs);
            if (value.document_type === 'other' && !this.index) {
                const otherDoc = {
                    name: value.other,
                    expiry_date: value.expiry_date ? moment(value.expiry_date).toDate() : null,
                    image_url: this.image_url.value
                };
                docs.push(otherDoc);
            }
            else {
                console.log(this.property_documents.value, 'BEFORE UPDATING ONE');
                const docIndex = this.documentTypes.findIndex(x => x.value === value.document_type);
                docs[docIndex].image_url = this.image_url.value;
                docs[docIndex].name = value.document_type;
                value.expiry_date = value.expiry_date ? moment(value.expiry_date).toDate() : null;
                docIndex !== 3 && docIndex !== 5 && docIndex !== 6 ? docs[docIndex].expiry_date = value.expiry_date : docs[docIndex].expiry_date = null;
                docIndex === 0 ? docs[docIndex].epc_rating = value.epc_rating : null;
                console.log(docs, 'HELLO DOCS');
            }
            if (this.index >= 7) {
                docs[this.index].image_url = this.image_url.value;
                docs[this.index].name = value.other;
                value.expiry_date = value.expiry_date ? moment(value.expiry_date).toDate() : null;
                docs[this.index].expiry_date = value.expiry_date ? docs[this.index].expiry_date = value.expiry_date : null;
            }
            let finalDocs;
            if (this.bedroomId) {
                finalDocs = {
                    bedrooms: {
                        [this.bedroomId]: {
                            property_documents: [
                                ...docs
                            ]
                        }
                    }
                };
            }
            else {
                finalDocs = {
                    property_documents: [
                        ...docs
                    ]
                };
            }
            return this._property.updatePropertyDoc(this.property.property_id, this.bedroomId, finalDocs, this.property.is_editing).pipe(switchMap(() => {
                if (this.mediaIds.length) {
                    return this._media.getMediaFromIds(this.mediaIds).pipe(map((medias) => {
                        return medias.filter(media => media.type === 'property_documents');
                    }));
                }
                return of([]);
            }), switchMap((medias) => {
                const viewName = this.document_type.value === 'other' ? this.other.value : this.document_type.value;
                const exisitingDoc = medias.find(media => media.view_name === viewName);
                if (exisitingDoc) {
                    this._media.removeMediaById(exisitingDoc.media_id);
                    this.mediaIds = this.mediaIds.filter(mediaId => mediaId !== exisitingDoc.media_id);
                }
                const data = this._media.generateMetaData(Object.assign({}, this.mediaData, { url: this.image_url.value }), this.expiry_date.value, viewName, this.property.property_id, this.epc_rating.value);
                return this._media.addMedia(data);
            }), switchMap((mediaId) => {
                this.mediaIds = [...this.mediaIds, mediaId];
                let finalDocs;
                if (this.bedroomId) {
                    finalDocs = {
                        date_modified: new Date(),
                        bedrooms: {
                            [this.bedroomId]: {
                                media_ids: this.mediaIds
                            }
                        }
                    };
                }
                else {
                    finalDocs = {
                        date_modified: new Date(),
                        media_ids: this.mediaIds
                    };
                }
                return this._property.updatePropertyDoc(this.property.property_id, this.bedroomId, finalDocs, this.property.is_editing);
            })).subscribe(() => {
                this.submittingData = false;
                this._router.navigate(['./'], { queryParams: { document_uploaded: true }, relativeTo: this._activatedRoute });
                this.closeOverly.emit();
                this.submittingDataSuccess = true;
                this.documentForm.reset();
            });
        }
    }
    remove(image_url) {
        this._storage.deleteFileFromStorageByURL(image_url);
        this.image_url.reset();
    }
    openDocument(url) {
        window.open(url, '_blank');
    }
}
export { ɵ0 };

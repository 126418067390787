import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { FormGroup} from '@angular/forms';
import { PropertyService, TenancyOfferService } from '@app/services';
import { first, map } from 'rxjs/operators';
import * as _ from 'lodash';
import { PropertyModel } from '@app/core-landlord/property/_models/property.model'
import { Observable } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { environment } from '@env/environment';

@Component({
  selector: 'form-swap-room',
  templateUrl: './form-swap-room.component.html',
  styleUrls: ['./form-swap-room.component.scss']
})
export class FormSwapRoomComponent implements OnInit {
  @Input() dref;
  @Input() propertyId;
  @Input() parentPropertyId;
  @Input() tenancyOfferId;
  @Output() roomData =new EventEmitter();
  propertiesList$: Observable<PropertyModel[]> ;
  // properties;
  currentProperty$;
  updatingProperty: boolean= false;
  selectedPropertyId: string = null;
  public assigned =null;
  public  display: any = {
  };

  public formGroup: FormGroup;
  constructor(
    private _property: PropertyService,
    private _tenancy: TenancyOfferService,
    private _toastr: ToastrService,
  ) {
    
  }
 
   
  ngOnInit (){    
    this.propertiesList$ = this.propertyId === this.parentPropertyId ? this._property.getAllPropertiesByParentPropertyId(this.parentPropertyId): this._property.getAllPropertiesByParentPropertyId(this.parentPropertyId).pipe(map((properties:any) => properties.filter((property:any)=> property.property_id !== this.propertyId) ))
    this.currentProperty$ = this.propertyId !== this.parentPropertyId ? this._property.getPropertyById(this.propertyId).valueChanges() : null;

}
onButtonClick(data){
  this.selectedPropertyId = data 
}

swapRoom(){
  
  this.updatingProperty = true;
  if(this.propertyId === this.parentPropertyId){
    
    const skipReferencing = environment.firebaseConfig.projectId === 'client-campus-key';

    this._tenancy.landlordAcceptOffer(this.tenancyOfferId, skipReferencing, this.selectedPropertyId ).pipe(first()).subscribe(() => {
      this.updatingProperty = false;
      this.dref.close();
      this._toastr.success("Room assigned successfully");
    }, err => {
      this.updatingProperty = false;
      
    })
  }else{
    
    this._property.updateIsUnderOfferWhileSwappingRooms(this.selectedPropertyId, this.propertyId).pipe(first()).subscribe(() => {
      this._tenancy.changeProperty(this.tenancyOfferId, this.selectedPropertyId).subscribe(()=> {
        this.updatingProperty = false;
        this.dref.close();
        this._toastr.success("Room updated successfully")
      },
      err=> {
        this.updatingProperty = false;
        console.log("Some error occured");
        
      })
    })    
}

}
 
}

import {Component, Input, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {FeedbackService} from '@app/services';
import {tap} from 'rxjs/operators';
import {AuthService} from '@app/core/services';
import {Router} from '@angular/router';
@Component({
  selector: 'form-remove-account',
  templateUrl: './form-remove-account.component.html',
  styleUrls: ['./form-remove-account.component.scss']
})
export class FormRemoveAccountComponent implements OnInit {
  @Input() formGroup: FormGroup;
  constructor(private _feedback: FeedbackService,
              private _auth: AuthService,
              public router: Router) { }

  ngOnInit() {
  }

  cancelAccount() {
    this._feedback.createAccountCancellationDocument(this.formGroup.controls.description.value)
      .subscribe(() => {
        this._auth.logout()
          .subscribe(() => this.router.navigate(['/login']), err => console.log(err));
      });
  }

}

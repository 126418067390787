import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'pie-skeleton',
  template: `
<!--    <div class="dough">-->

<!--    </div>-->
<ul>
  <li class="card__base loading-animation--header">
     <div class="flex flex-row">
       <span class="char_dough"></span>
       <div class="flex flex-column">
         <div class="margin-top--sm tiny loading-animation"></div>
         <div class="margin-top--sm middle loading-animation"></div>
         <div class="flex flex-row">
           <div class="margin-top--sm chunk loading-animation"></div>
           <div class="margin-top--sm chunk loading-animation"></div>
           <div class="margin-top--sm chunk loading-animation"></div>
           <div class="margin-top--sm chunk loading-animation"></div>
         </div>
       </div>
<!--       &#45;&#45;header-->
     </div>
  </li>
</ul>


  `,
  styleUrls: ['../skeleton-loading.component.scss']
})

export class PieSkeletonCardComponent implements OnInit {
  constructor() {
  }

  ngOnInit() {
  }
}

import { OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BunkPassportService } from '@app/services';
import { MY_FORMATS } from '@app/shared/_services/dates.service';
import { Tenant } from '@env/routing';
import { ActivatedRoute, Router } from '@angular/router';
import { tap, takeUntil, flatMap, catchError } from 'rxjs/operators';
import { Subject, of } from 'rxjs';
import { TenantVerifyEventEmiterService } from '../profile-tenant.service';
import { TenantAccountStepComponent } from './tenant-account-step.component';
import { AuthService } from '@app/core/services';
const ɵ0 = MY_FORMATS;
export class TenantAccountVerifySearchPreferencesComponent extends TenantAccountStepComponent {
    constructor(_verifyEvent, _formBuilder, _router, _route, _passport, _auth) {
        super(_verifyEvent);
        this._formBuilder = _formBuilder;
        this._router = _router;
        this._route = _route;
        this._passport = _passport;
        this._auth = _auth;
        this.standAlone = false;
        this.routes = { Tenant };
        this.destroy$ = new Subject();
        this.formGroup = this.initFormGroup();
        this.queryParams = _route.snapshot.queryParams.tenancy_offer_id;
        this.passport$ = this._passport.getUsersBunkPassportById().valueChanges()
            .pipe(tap((passport) => {
            if (passport.search_preferences) {
                this.preloadFormGroup(passport.search_preferences);
            }
        }));
        this.references$ = this._passport.getBunkReferencesById('university').valueChanges();
        this.reference$ = this.references$
            .pipe(flatMap((references) => {
            if (references.length > 0) {
                return this._passport.getBunkReferenceById(references[0].bunk_passport_reference_id).valueChanges().pipe(tap((reference) => {
                    if (reference.bunk_passport_reference_id) {
                        this.preloadUniFormGroup(reference.reference_data);
                        this._router.navigate(['.'], { queryParams: { uniReferenceId: reference.bunk_passport_reference_id }, relativeTo: this._route, replaceUrl: true });
                    }
                }));
            }
            else {
                return of([]);
            }
        }));
    }
    initFormGroup() {
        return this._formBuilder.group({
            search_details: [null],
        });
    }
    nextStep(form) {
        this.submittingData = true;
        this.formGroup.disable();
        this._passport.updateUserReferenceData(form)
            .pipe(takeUntil(this.destroy$), catchError(err => of([])))
            .subscribe(res => {
            this.stepComplete();
        }, err => {
            console.log('HTTP Error', err);
            this.stepError();
        }, () => {
            this.formGroup.enable();
            console.log('HTTP request completed.');
        });
    }
    autocompleteUniversity(event) {
        this.formGroup.patchValue({ university_name: event.name });
    }
    preloadFormGroup(form) {
        const item = {
            is_student: form.is_student,
            searching_with: form.searching_with,
            number_children: form.number_children ? form.number_children : null,
            has_pets: form.has_pets,
            is_smoker: form.is_smoker,
            is_renting: form.is_renting,
            currently_renting_from: form.currently_renting_from ? form.currently_renting_from : null,
            expected_move_in_date: form.expected_move_in_date ? form.expected_move_in_date.toDate() : null
        };
        this.formGroup.get('search_details').patchValue(item);
    }
    preloadUniFormGroup(value) {
        const item = {
            university_name: value.university_name,
            course_end_year: value.course_end_year,
            course_name: value.course_name,
        };
        this.formGroup.patchValue(item);
    }
    goBack() {
        this._verifyEvent.navigateStep('prev');
    }
    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
    }
    ngOnInit() {
    }
}
export { ɵ0 };

import { Component, OnInit, ViewChild, Input, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';
import { AuthService } from '@app/core/services';
import { Auth } from '@env/routing';
import { NotificationsService, UserService } from '@app/services';
import { Observable } from 'rxjs';
import { map, finalize } from 'rxjs/operators';
import { MatMenuTrigger } from '@angular/material';
import {environment} from '@env/environment';
import {CompanyName} from '@app/shared/shared.constants';

interface INavbar {
  text: string;
  url: any[] | string;
  isHidden$?: Observable<boolean>;
}

export type NavbarItems = INavbar[];

@Component({
  selector: 'nav-top',
  templateUrl: './nav-top.component.html',
  styleUrls: ['./nav-top.component.scss']
})
export class NavTopComponent implements OnInit {
  @ViewChild('matMenu', { static: false }) trigger: MatMenuTrigger;
  @Output() notebarNotificationOpenChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input() guest = false;
  @Input('navItems') set navItems(arr: NavbarItems) {
    this._navItems = arr;
  }
  get navItems(): NavbarItems | null {
    return this._navItems;
  }
  @Input()
  get notebarNotificationOpen() {
    return this._notebarNotificationOpenValue;
  }
  set notebarNotificationOpen(bool: boolean) {
    this._notebarNotificationOpenValue = bool;
    this.notebarNotificationOpenChange.emit(bool);
  }

  private _notebarNotificationOpenValue: boolean;
  private _navItems: NavbarItems;
  public isAdmin$: Observable<any>;
  public notifications$: Observable<any>;
  public notifications;
  public showSpinner = false;
  public user;
  public client_data = environment.client_data;
  public isClientNrla = CompanyName.isNrla;

  constructor(
    public _user: UserService,
    private _auth: AuthService,
    private _router: Router,
    private _notifications: NotificationsService,
    protected _iconRegistry: MatIconRegistry,
    protected _sanitizer: DomSanitizer,
  ) {
    _iconRegistry.addSvgIcon(
      'bunk-icon-chevron',
      _sanitizer.bypassSecurityTrustResourceUrl('assets/svg/navigation/bunk-icon-chevron.svg')
    );
    _iconRegistry.addSvgIcon(
      'bunk-icon-notifications',
      _sanitizer.bypassSecurityTrustResourceUrl('assets/svg/navigation/bunk-icon-notifications.svg')
    );
    _iconRegistry.addSvgIcon(
      'bunk-icon-notifications-new',
      _sanitizer.bypassSecurityTrustResourceUrl('assets/svg/navigation/bunk-icon-notifications-new.svg')
    );

    if (this._auth.authenticated && !this._auth.currentUserAnonymous) {
      this.isAdmin$ = this._auth.getCurrentUserCustomClaims();
    }
  }

  ngOnInit(): void {
    this.notifications = (this._user.userDb && this._user.userDb.notification_counters) ? this._user.userDb.notification_counters.total : 0;
    // this.notifications$ = this._notifications.getAllUserNotifications().valueChanges().pipe(
    //   map((notifications: any) => {
    //     return {
    //       unread: notifications.filter((notification: any) => !notification.notification_is_viewed).length,
    //       read: notifications.length
    //     };
    //   })
    // );

  }

  openMenu() {
    this.trigger.openMenu();
  }

  openNotificationsSideBar() {
    this.notebarNotificationOpen = !this.notebarNotificationOpen;
  }

  closeNotificationsSideBar = () => this.notebarNotificationOpen = false;

  logout() {
    const role = this._user.userDb.role;
    this._auth.logout()
      .subscribe(
        () => {
          console.log(role, environment.firebaseConfig.projectId, 'LOGGGING OUT ');
          const projectIds = ['client-nrla', 'client-nrla-staging' ];

          if (role === 'landlord' && projectIds.includes(environment.firebaseConfig.projectId)) {
            return window.location.href = 'https://www.nrla.org.uk/account';
          }
            this._router.navigate([Auth.signin]);
        },
        err => console.log(err)
      );
  }

  updateUserAuth() {
    this.showSpinner = true;
    this._auth.updateUserAuth().pipe(
      finalize(() => this.showSpinner = false)
    ).subscribe(() => location.reload());
  }
}

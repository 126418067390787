import { UserModule } from './user.module';
import { NgModule } from '@angular/core';
import { CommonModule, TitleCasePipe } from '@angular/common';
import { RouterModule } from '@angular/router';
import { PortalModule } from '@angular/cdk/portal';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@app/shared/material.module';
import { SortablejsModule } from 'ngx-sortablejs';
import { UtilsModule } from '@app/core/utils/utils.module';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { AgmCoreModule } from '@agm/core';

import {
  FormStepsComponent,
  StepInfoHeaderComponent,
  CardComponent,
  LandlordCardComponent,
  PropertyCardComponent,
  PropertyCardMobileComponent,
  PropertyOwnerCardComponent,
  ProfileCardApplicantComponent,
  ProfileCardHousemateComponent,
  ModalBasicComponent,
  CarouselComponent,
  ProgressBarComponent,
  IdealPostcodesAutocompleteComponent,
  TenancyMembersCardComponent,
  DialogAlertComponent,
  PayoutsComponent,
  CardsComponent,
  CouponsComponent,
  CheckoutComponent,
  ProfileComponent,
  PieSkeletonCardComponent,
  StatsCardSmallComponent,
  PaymentsOverviewBarComponent,
  InstantAlgoliaSearchComponent,
  InstantSearchInputComponent,
  BunkMenuComponent,
  FilePreviewOverlayComponent,
  PdfPreviewOverlayComponent,
  TemplatePreviewOverlayComponent,
  FilePreviewOverlayToolbarComponent,
  MembersListComponent,
  DateSelectorComponent,
  BedroomFormListComponent,
  StepperNewComponent,
  ViewingsEditComponent,
  ViewingsCardComponent,
  MaintenanceCardComponent,
  MaintenanceOverviewComponent,
  BunkVerifiedComponent,
  BunkTabsComponent,
  TimePickerComponent,
  PropertyCardListComponent,
  AvatarPickerComponent,
  BedroomCardListComponent,
  PropertySmallCardComponent,
  SampleTenancyAgreementComponent,
  PropertyOwnerCardInfoComponent,
  PartnerCardComponent,
  CardSkeletonComponent,
  CalendarSkeletonComponent,
  FilterSkeletonComponent,
  SharedFiltersComponent,
  NotesComponent,
  ApplicationsSummaryComponent,
  ViewingsSummaryComponent,
  TasksSummaryComponent,
  PortfolioSkeletonComponent,
  BunkMaintenanceTableComponent,
  InfinteScrollComponent,
  ScrollSkeletonComponent,
  PropertyAddressComponent,
  ApplicationMembersComponent,
  SummaryInfoComponent,
  SearchInputComponent,
  BookingSuccessSharedComponent,
  BookViewingSharedFormComponent,
  DocumentSummaryListComponent,
  TransactionsComponent,
  TransactionsTableComponent,
  SharedExtendedFiltersComponent,
  /** Modules */
  BunkPaymentTableModule,
  SkeletonLoadingModule,
  BunkDialogModule,
  ProcessingSpinnerModule,
  BunkOverlayModule,
  ProfileCardOverlayModule,
  InstantNewAlgoliaSearchModule,
  IdealPostcodesAutocompleteModule,
  BunkUploaderModule,
  PropertyPicturesUploadModule,
  LoadingSpinnerModule,
  SortableListModule,
  AddressModule,
  SelectTeamModule,
  DocumentVerificationComponent
} from '@app/shared/_components';
import { AddPropertyModule } from '@app/shared/_components/add-property';

import {
  PipesModule
} from '@app/shared/_pipes/pipes.module';

import {
  StickySectionComponent,
} from '@app/shared/_layouts';

import {
  DisableControlDirective,
  FireFormDirective,
  PostcodeUkValidator,
  ScrollableDirective,
  ClickOutsideDirective,
  NumberToLocaleStringDirective,
  AddToCalendarClickCatcherDirective,
  CopyToClipboardDirective,
  ContactButtonDirectiveModule,
  SendMessageModule,
  FileDownloadDirectiveModule
} from '@app/shared/_directives';

import { DatesService } from '@app/shared/_services/dates.service';
import {
  InvalidCharsComponent,
  InvalidLengthComponent
} from '@app/shared/_validators';

import {
  FormTenantMaintenanceRequestComponent,
  FormFeedbackComponent,
  FormNewChatGroupComponent,
  FormRentExtensionComponent,
  FormRemoveAccountComponent,
  BankAccountFormComponent,
  CardsFormComponent,
  NewStripeCustomerFormComponent,
  InputAddressComponent,
  InputPhoneComponent,
  InputUploaderComponent,
  UploadDocumentsForm,
  FormCreateAccountComponent,
  FormNameRowComponent,
  FormCreditInfoComponent,
  FormBankDetailsComponent,
  FormInfoComponent,
  FormSearchDetailsComponent,
  FormEmploymentDetailsComponent,
  FormPreviousTenancyComponent,
  FormGuarantorDetailsComponent,
  FormUniversityDetailsComponent,
  FormIdentityCheckComponent, ApplicationFormOverviewComponent,
  FormAddTenantsComponent,
  BunkFormFieldModule,
  FormCreateExpensesModule,
  FormCreatePaymentsModule,
  FormGetTenancyDetailsModule,
  FormGetPropertyDetailsModule,
  CreateSimplePropertyModule,
  FormAssignRoomModule
} from '@app/shared/_forms';

import {
  DoughnutComponent,
  BarChartComponent
} from '@app/shared/_charts';
import { BunkMaintenanceRowComponent } from '@app/shared/_components/bunk-tables/bunk-maintenance-table/bunk-maintenance-row.component';
import { NgxPaginationModule } from 'ngx-pagination';
import {CardListSkeletonComponent} from '@app/shared/_components/skeleton-loading/card-list-skeleton/card-list-skeleton.component';
import { CreatePropertyComponent } from './_forms/properties/create-simple-property/create-simple-property.component';
import { FormSwapRoomComponent } from './_forms/form-swap-room/form-swap-room.component';
import {DocumentUploadBoxModule} from '@shared/_components/document-upload-box/document-upload-box.module';

const FORM_MAINTENANCE_COMPONENTS = [FormTenantMaintenanceRequestComponent];

const FORM_SHARED_COMPONENTS = [
  FormFeedbackComponent,

  FormNewChatGroupComponent,

  FormRentExtensionComponent,

  FormRemoveAccountComponent,

  CardsFormComponent,
  BankAccountFormComponent,
  NewStripeCustomerFormComponent,
  InputAddressComponent,
  InputPhoneComponent,
  InputUploaderComponent,
  UploadDocumentsForm,
  FormCreateAccountComponent,
  FormNameRowComponent,
  FormCreditInfoComponent,
  FormBankDetailsComponent,
  FormInfoComponent,
  FormSearchDetailsComponent,
  FormEmploymentDetailsComponent,
  FormPreviousTenancyComponent,
  FormGuarantorDetailsComponent,
  FormUniversityDetailsComponent,
  FormIdentityCheckComponent,
  ApplicationFormOverviewComponent,
  FormAddTenantsComponent,
  FormSwapRoomComponent
];

const FORM_SHARED_COMPONENTS_MODULES = [
  BunkFormFieldModule,
  FormCreateExpensesModule,
  FormCreatePaymentsModule,
  FormGetTenancyDetailsModule,
  FormGetPropertyDetailsModule,
  CreateSimplePropertyModule,
  FormAssignRoomModule
];

const UTIL_COMPONENTS = [
  StickySectionComponent,
];


const SHARED_COMPONENTS = [
  FormStepsComponent,
  StepInfoHeaderComponent,
  ProgressBarComponent,
  CarouselComponent,

  LandlordCardComponent,
  PropertyCardComponent,
  PropertyCardMobileComponent,
  PropertyOwnerCardComponent,
  ProfileCardApplicantComponent,
  ProfileCardHousemateComponent,
  ModalBasicComponent,
  DialogAlertComponent,
  PaymentsOverviewBarComponent,

  InvalidCharsComponent,
  InvalidLengthComponent,

  CardComponent,
  TenancyMembersCardComponent,

  CardsComponent,
  PayoutsComponent,
  CouponsComponent,
  CheckoutComponent,

  ProfileComponent,
  PieSkeletonCardComponent,
  StatsCardSmallComponent,
  PortfolioSkeletonComponent,
  CardSkeletonComponent,
  CalendarSkeletonComponent,
  FilterSkeletonComponent,
  SharedFiltersComponent,
  BunkMenuComponent,
  FilePreviewOverlayComponent,
  PdfPreviewOverlayComponent,
  TemplatePreviewOverlayComponent,
  FilePreviewOverlayToolbarComponent,
  InstantAlgoliaSearchComponent,
  InstantSearchInputComponent,
  MembersListComponent,
  DateSelectorComponent,
  BedroomFormListComponent,
  StepperNewComponent,
  ViewingsEditComponent,
  ViewingsCardComponent,
  MaintenanceCardComponent,
  MaintenanceOverviewComponent,
  BunkVerifiedComponent,
  BunkTabsComponent,
  TimePickerComponent,
  PropertyCardListComponent,
  AvatarPickerComponent,
  BedroomCardListComponent,
  PropertySmallCardComponent,
  SampleTenancyAgreementComponent,
  PropertyOwnerCardInfoComponent,
  PartnerCardComponent,
  NotesComponent,
  ApplicationsSummaryComponent,
  ViewingsSummaryComponent,
  TasksSummaryComponent,
  InfinteScrollComponent,
  BunkMaintenanceTableComponent,
  BunkMaintenanceRowComponent,
  ScrollSkeletonComponent,
  PropertyAddressComponent,
  ApplicationMembersComponent,
  SummaryInfoComponent,
  CardListSkeletonComponent,
  SearchInputComponent,
  BookingSuccessSharedComponent,
  BookViewingSharedFormComponent,
  DocumentSummaryListComponent,
  TransactionsTableComponent,
  TransactionsComponent,
  SharedExtendedFiltersComponent,
  DocumentVerificationComponent
];



const SHARED_COMPONENTS_MODULES = [
  BunkPaymentTableModule,
  SkeletonLoadingModule,
  BunkDialogModule,
  ProcessingSpinnerModule,
  BunkOverlayModule,
  ProfileCardOverlayModule,
  InstantNewAlgoliaSearchModule,
  IdealPostcodesAutocompleteModule,
  BunkFormFieldModule,
  BunkUploaderModule,
  PropertyPicturesUploadModule,
  LoadingSpinnerModule,
  SortableListModule,
  AddPropertyModule,
  AddressModule,
  SelectTeamModule
];

// Directives
const SHARED_DIRECTIVES = [
  ScrollableDirective,
  FireFormDirective,
  DisableControlDirective,
  ClickOutsideDirective,
  NumberToLocaleStringDirective,
  AddToCalendarClickCatcherDirective,
  CopyToClipboardDirective
];

const SHARED_DIRECTIVES_MODULES = [
  ContactButtonDirectiveModule,
  SendMessageModule,
  FileDownloadDirectiveModule
];

const CHARTS_COMPONENTS = [
  DoughnutComponent,
  BarChartComponent
];
// Validators
const SHARED_VALIDATORS = [PostcodeUkValidator];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule.withConfig({warnOnNgModelWithFormControl: 'always'}),
    MaterialModule,
    PortalModule,
    SortablejsModule,
    UserModule,
    UtilsModule,
    UserModule,
    NgxChartsModule,
    ScrollingModule,
    NgxPaginationModule,
    AgmCoreModule,
    SHARED_COMPONENTS_MODULES,
    SHARED_DIRECTIVES_MODULES,
    FORM_SHARED_COMPONENTS_MODULES,
    PipesModule,
    DocumentUploadBoxModule
  ],
  declarations: [
    FORM_MAINTENANCE_COMPONENTS,
    FORM_SHARED_COMPONENTS,
    UTIL_COMPONENTS,
    SHARED_COMPONENTS,
    SHARED_DIRECTIVES,
    SHARED_VALIDATORS,
    CHARTS_COMPONENTS

  ],
  exports: [
    CommonModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    MaterialModule,
    FORM_MAINTENANCE_COMPONENTS,
    FORM_SHARED_COMPONENTS,
    UTIL_COMPONENTS,
    SHARED_COMPONENTS,
    SHARED_DIRECTIVES,
    SHARED_VALIDATORS,
    CHARTS_COMPONENTS,
    SHARED_COMPONENTS_MODULES,
    SHARED_DIRECTIVES_MODULES,
    FORM_SHARED_COMPONENTS_MODULES,
    PipesModule
  ],
  providers: [
    DatesService,
    TitleCasePipe
  ],
  entryComponents: [
    DialogAlertComponent,
    CheckoutComponent,
    FilePreviewOverlayComponent,
    PdfPreviewOverlayComponent,
    TemplatePreviewOverlayComponent,
    DocumentVerificationComponent,
  ]
})
export class SharedModule {
}

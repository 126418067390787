import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Observable} from 'rxjs';
import {TenancyPackageService} from '@app/services/tenancy-package.service';
import {map} from 'rxjs/operators';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {environment} from '@env/environment';

@Component({
  selector: 'tenancy-package',
  template: `
  <ng-container *ngIf="(tenancyPackages$ | async) as packages">
    <div class="package__container">
    <ul *ngFor="let item of packages" class="package__card" (click)="listingView ? null : selectPackage(item.tenancy_package_id)"
        [ngClass]="{'package__card--active': this.selectedId$.value === item.tenancy_package_id && item.is_featured,
        'package__card--standard': this.selectedId$.value === item.tenancy_package_id && !item.is_featured,
         'package__card--listing-view': listingView }">

      <div [ngClass]="{'package__card--layout': listingView, 'flex flex-column': !listingView}">
      <div class="package__card--title">
        <span class="flex items-center">
          <mat-icon *ngIf="item.is_featured" svgIcon="star-icon"></mat-icon>
          {{item.name}}
        </span>
        <span *ngIf="!listingView && item.is_featured" class="package__card--title--featured">
          +{{client_data.currency_symbol}}{{item.amount.toFixed(2)}}/{{(item.amount_interval_count > 1 ? item.amount_interval_count : '')}}
          {{item.amount_interval}}{{(item.amount_interval_count > 1 ? 's' : '')}}</span>
        <span *ngIf="!listingView &&!item.is_featured">No Extra cost</span>
      </div>

      <div class="package__card--feature-list-wrap" [ngClass]="{'package__card--feature-list': listingView }">
        <li *ngFor="let feature of item.features"><ng-container *ngIf="!listingView">• </ng-container>{{feature}}</li>
      </div>
      </div>
    </ul>
    </div>
  </ng-container>
  `,
  styleUrls: ['tenancy-package.component.scss']
})

export class TenancyPackageComponent implements OnInit {
  @Input() tenancyPackageIds: [string];
  @Input() listingView = false;
  @Output() selectedPackage?: EventEmitter<string> = new EventEmitter<string>();
  public tenancyPackages$: Observable<any>;
  public selectedId$: BehaviorSubject<string> = new BehaviorSubject<string>('');
  public client_data = environment.client_data;

  constructor(
    private _tenancyPackage: TenancyPackageService
  ) {
  }

  ngOnInit() {
    this.tenancyPackages$ = this._tenancyPackage.getTenancyPackagesByIds(this.tenancyPackageIds).pipe(
      map((packages: any) => packages.sort((a, b) => a.is_featured - b.is_featured)));
  }

  selectPackage(packageId: string) {
    console.log(packageId, 'hello');
    this.selectedId$.next(packageId);
    this.selectedPackage.emit(packageId);
  }
}

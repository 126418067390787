
    <div class="checkout-modal__container cc" (click)="clearErrors()">
      <div class="modal-header">{{(showNewCustomerForm ? 'ADD CARD' : data.title)}}</div>
      <div class="modal-body cc" *ngIf="!showConfirmation; else confirmationView">
        <mat-tab-group [(selectedIndex)]="selectedIndex" class="tab-size">
          <mat-tab label="Automatic Payments" *ngIf="!data.disable_automatic_payments">
            <div class="instructions" [hidden]="showMessage || showNewCustomerForm">Setup once
              and rent will
              be
              collected each month from the
              card you've selected
            </div>
            <div class="instructions is-overdue" [hidden]="!showMessage">
              Oops... <br> We are unable to setup a subscription if you have payments that
              are overdue. Please manually settle any outstanding balance and then come back.
            </div>
            <div class="cards__container rc" [hidden]="showMessage || showNewCustomerForm">
              <div class="card__container cc"
                   *ngFor="let card of (_user.user$ | async)?.payments_data.cards"
                   [ngClass]="{'selected': card === selectedCardAuto}"
                   (click)="selectCard(card, 'auto')">
                <div class="layer"></div>
                <img src="/assets/img/icons/cards-icons/Visa_logo.png" alt="" class="card-icon">
                <div class="card-last-four">Ending {{card.last4}}</div>
              </div>
              <div class="add-new-card cc" (click)="showCardForm()" *ngIf="!showCardsForm">
                <mat-icon>add</mat-icon>
                Add card
              </div>
            </div>
            <div class="spinner__container" *ngIf="subscriptionLoading">
              <loading-spinner [text_message]="message"></loading-spinner>
            </div>
            <div class="error" *ngIf="cardFormErrorMessage">{{cardFormErrorMessage}}</div>
            <div class="w_100">
              <new-stripe-customer-form
                *ngIf="showNewCustomerForm || showNewCustomerForm  && !_user.userDb.payments_data.cards"
                (showCustomerForm)=" showNewCustomerForm = $event"></new-stripe-customer-form>
            </div>
            <cards-form
              *ngIf="showCardsForm"
              (setupComplete)="addCard($event)"
              [disabled]="disableCardsForm"
              [clear]="clearCardsForm"
            >
              <div class="hide-form rc" *ngIf="showCardsForm" header><a
                (click)="showCardsForm = false">Hide</a>
              </div>
            </cards-form>
            <div class="summary__container cc"
                 [hidden]="showMessage || payablePayments.length <= 0">
              <div class="first-row rc">
                <div class="left">Payment breakdown:</div>
                <div class="right">My share</div>
              </div>
              <div class="month-row rc" *ngFor="let month of payablePayments">
                <div class="left" [ngSwitch]="month.payment_type">
                  <span *ngSwitchCase="'deposit'">Security Deposit</span>
                  <span *ngSwitchCase="'rent'">{{month.payment_type | titlecase}}
                    - {{_dates.printMonth(month.month)}} {{month.year}}</span>
                </div>
                <div class="right">&pound;{{month.amount}}</div>
              </div>
              <div class="last-row rc">
                <div class="left">Total:</div>
                <div class="right">&pound;{{data.total}}</div>
              </div>

            </div>

            <div class="link_2 mt_8" (click)="hasCoupon = !hasCoupon" [hidden]="showNewCustomerForm">Have a coupon?
            </div>
            <ng-container *ngIf="hasCoupon">
              <div class="rc ai_c">
                <mat-form-field (click)="couponCode.enable()">
                  <input type="text" matInput [formControl]="couponCode" [name]="couponCode"
                         style="padding-bottom: 2px;" placeholder="Enter code here">
                </mat-form-field>
                <mat-icon *ngIf="couponIsValid" color="accent">check</mat-icon>
                <button (click)="submitCoupon('rent')"
                        class="btn__generic ml_4"
                        [disabled]="couponCode.disabled"
                        *ngIf="!loadingCoupon; else couponIsLoading">Apply
                </button>
                <ng-template #couponIsLoading>
                  <loading-spinner class="ml_4"></loading-spinner>
                </ng-template>
              </div>
              <ng-container *ngIf="(coupons$ | async) as coupons">
                <div *ngIf="couponIsValid" class="mt_4 text_colour_1">
                  <span *ngIf="coupons[0].percent_off; else amountOffDiscount">Great! That's {{coupons[0].percent_off}}
                    %off your first {{coupons[0].duration_in_months}} months of membership!</span>
                  <ng-template #amountOffDiscount>
                    Great! That's {{client_data.currency_symbol + coupons[0].amount_off / 100}} off towards your future payments on
                    Bunk!
                  </ng-template>
                </div>
              </ng-container>
            </ng-container>


            <div class="checkout-button__container cc">
              <button class="btn__cta"
                      [disabled]="!selectedCardAuto"
                      [hidden]="showMessage || showNewCustomerForm"
                      (click)="setupSubscription()">Subscribe
              </button>
              <button class="btn__generic"
                      [hidden]="!showMessage"
                      (click)="selectedIndex = 1">Got it!
              </button>
            </div>
          </mat-tab>
          <mat-tab label="Manual payments">
            <div class="instructions" [hidden]="showNewCustomerForm">Select a card and make payment now but please
              remember to pay on time
            </div>
            <div class="cards__container rc" [hidden]="showNewCustomerForm">
              <div class="card__container cc"
                   *ngFor="let card of (_user.user$ | async)?.payments_data.cards"
                   [ngClass]="{'selected': card === selectedCardManual}"
                   (click)="selectCard(card, 'manual')">
                <div class="layer"></div>
                <img src="/assets/img/icons/cards-icons/Visa_logo.png" alt="" class="card-icon">
                <div class="card-last-four">Ending {{card.last4}}</div>
              </div>
              <div class="add-new-card cc" (click)="showCardForm()" *ngIf="!showCardsForm">
                <mat-icon>add</mat-icon>
                Add card
              </div>

            </div>
            <loading-spinner *ngIf="loading" [text_message]="message"></loading-spinner>
            <div class="error" *ngIf="cardFormErrorMessage">{{cardFormErrorMessage}}</div>

            <new-stripe-customer-form
              *ngIf="showNewCustomerForm || showNewCustomerForm  && !_user.userDb.payments_data.cards"
              (showCustomerForm)=" showNewCustomerForm = $event"></new-stripe-customer-form>

            <cards-form *ngIf="showCardsForm"
                        [hidden]="showNewCustomerForm"
                        (setupComplete)="addCard($event)"
                        [disabled]="disableCardsForm"
                        [clear]="clearCardsForm"
            >
              <div class="hide-form rc" *ngIf="showCardsForm" header><a
                (click)="showCardsForm = false">Hide</a>
              </div>
            </cards-form>
            <div class="summary__container cc" [hidden]="payablePayments.length <= 0">
              <div class="first-row rc">
                <div class="left">Payment breakdown:</div>
                <div class="right">My share</div>
              </div>
              <div class="month-row rc" *ngFor="let month of payablePayments">
                <div class="left" [ngSwitch]="month.payment_type">
                  <span *ngSwitchCase="'deposit'">Security Deposit</span>
                  <span *ngSwitchCase="'rent'">{{month.payment_type | titlecase}}
                    - {{_dates.printMonth(month.month)}} {{month.year}}</span>
                </div>
                <div class="right">&pound;{{month.amount}}</div>
              </div>
              <div class="last-row rc">
                <div class="left">Total:</div>
                <div class="right">&pound;{{data.total}}</div>
              </div>

            </div>
            <div class="checkout-button__container cc">
              <button class="btn__cta"
                      [hidden]="showNewCustomerForm"
                      [disabled]="!selectedCardManual || payablePayments.length <= 0"
                      (click)="pay()">Pay now
              </button>
            </div>

          </mat-tab>
        </mat-tab-group>
      </div>
      <ng-template #confirmationView>
        <div class="spinner__container" *ngIf="paymentProcessing">
          <loading-spinner [text_message]="checkoutMessage" class="confirmation-spinner"></loading-spinner>
        </div>

        <div class="confirmation__container pt_20 cc jc_sb" *ngIf="!paymentProcessing && !paymentError">
          <p class="text--lg margin-bottom--sm">Awesome!</p>
          <p class="text--subtle margin-bottom--lg">{{confirmationMessage}}</p>
          <button class="btn__generic" (click)="backHome()">Back home
          </button>
        </div>
        <div class="cc jc_sb pt_20" *ngIf="paymentError">
          <p class="text--lg margin-bottom--sm" *ngIf="paymentError.status === 402; else genericError">Please update
            your card</p>
          <ng-template #genericError>
            <p class="text--lg margin-bottom--sm">Something went wrong...</p>
          </ng-template>
          <p class="text--subtle margin-top--sm margin-bottom--lg">{{paymentError.message}}</p>
          <div class="action-bar">
            <button class="btn__subdued" (click)="backHome()">Close</button>
            <button class="btn__cta" (click)="resetForm()" *ngIf="!showScaButton; else scaButton">Try
              again
            </button>
            <ng-template #scaButton>
              <button class="btn__cta" (click)="updatePaymentMethods()">Update payment method</button>
            </ng-template>
          </div>
        </div>
      </ng-template>
    </div>


  
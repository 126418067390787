import {
  Component,
  AfterViewInit,
  ViewChild,
  ElementRef,
  ChangeDetectorRef,
  Output,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit
} from '@angular/core';
import { NgForm, FormGroup, Validators, FormBuilder, FormControl } from '@angular/forms';
import { environment } from '@env/environment';
import { StripeService, UserService } from '@app/services';
import { ToastrService } from 'ngx-toastr';
import { switchMap, takeUntil } from 'rxjs/operators';
import { from, Subject } from 'rxjs';

@Component({
  selector: '`cards-form`',
  template: `
    <div class="payment__card">
      <form #checkout="ngForm" [formGroup]="updateCardForm" (ngSubmit)="onSubmit(checkout)">

          <ng-content #header></ng-content>
          <h3 class="text--md" *ngIf="title" data-test="cardholderTitle">{{title}}</h3>
          <img *ngIf="!title" class="margin-bottom--sm" src="/assets/svg/account-icons/stripe-checkout-banner.svg" alt="">
          <p *ngIf="subtitle" data-test="cardholderSubtitle">{{subtitle}}</p>

          <strong class="flex margin-bottom--sm margin-top--sm text--md">Add new payment card</strong>

          <mat-form-field appearance="outline" class="w_100">
            <mat-label>Cardholder name</mat-label>
            <input matInput
              name="cardholder-name"
              formControlName="cardHolderName"
              data-test="cardholderNameInput">
            <mat-error>Please update the cardholder name as it appears on the card</mat-error>
          </mat-form-field>

          <div>
            <label hidden for="card-info" data-test="cardholderInfoText">New card numbers</label>
            
            <div class="card-actions__container">
              <div class="flex flex-column margin-bottom--sm">
                <div id="card-info" #cardInfo class="w_100" [ngClass]="{'StripeElement--invalid': cardError}"></div>
                <div id="card-errors" #cardInfoErrors role="alert" class="mat-error error" *ngIf="cardError">{{ cardError }}</div>
              </div>
              <div class="secure__container">
                <div class="flex flex-row justify-end">
                  <small>Secure Payment</small>
                  <img class="secure-payment-icon" src="/assets/img/icons/cards-icons/secure-server-lock.png" alt="">
                </div>
              </div>
              <processing-spinner *ngIf="processingIntent || awaitingCompletion" color="#FFFFFF" [complete]="submittingDataSuccess" class="margin-top--sm"></processing-spinner>
              <span *ngIf="intentProcessed && !clear" class="status__tick status__tick--lg margin-top--sm"></span>
              <div *ngIf="!processingIntent && !intentProcessed && !isVerification">
                <button type="submit" class="btn__seethrough btn--md btn--round margin-top--sm" data-test="addCardButton" [disabled]="processingIntent || intentProcessed">
                <mat-icon>add</mat-icon>
                <span>Add card</span>
                </button>
              </div>
            </div>
          </div>

      </form>
    </div>
    <ng-container *ngIf="isVerification">
      <div class="jc_sb rc mt_20">
        <button id="button_verify-payments-prev"
                (click)="goBack()"
                class="btn__seethrough"
        >
          Back
        </button>
        <button id="button_verify-payments-next"
                class="btn__generic" type="submit"
                (click)="onSubmit()"
                data-test="continueButton">Next
        </button>
      </div>
    </ng-container>
  `,
  styleUrls: ['./payment-forms.component.scss']
})
export class CardsFormComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('cardInfo', { static: true }) cardInfo: ElementRef;
  @ViewChild('cardInfoErrors', { static: false }) cardInfoErrors: ElementRef;
  @Output() setupComplete = new EventEmitter<any>();
  @Output() goBackVerification = new EventEmitter<any>();
  @Input() title: string;
  @Input() subtitle: string;
  @Input() disabled: Subject<boolean>; // disabled event to re-enable the form if setupComplete emission is used by parent
  @Input() clear: Subject<boolean>; // form reset event to empty and reset the form if setupComplete emission is used by parent
  @Input() isVerification = false;
  private destroy$: Subject<boolean> = new Subject<boolean>();
  private stripe = Stripe(environment.stripeConfig.publicKey);
  public elements = this.stripe.elements({ fonts: [{ cssSrc: 'https://fonts.googleapis.com/css?family=Open+Sans&display=optional' }] });
  public card: any;
  public cardError: string;
  public processingIntent = false;
  public intentProcessed = false;
  public awaitingCompletion = false;
  public cardComplete = false;
  public disableForm = false;
  public updateCardForm: FormGroup;

  constructor(
    private cd: ChangeDetectorRef,
    private _user: UserService,
    private _toastr: ToastrService,
    private _stripe: StripeService,
    private _formBuilder: FormBuilder
  ) {}

  ngOnInit() {
    this.updateCardForm = this._formBuilder.group({
      cardHolderName: [undefined, Validators.required]
    });

    if (this.clear) {
      this.clear.subscribe(clear => { 
        if (clear) {
          this.card.clear();
          this.updateCardForm.get('cardHolderName').markAsPristine();
          this.updateCardForm.get('cardHolderName').markAsUntouched();
          this.updateCardForm.enable();
          this.processingIntent = false;
          this.intentProcessed = false;
          this.card.update({ disabled: false });
          this.awaitingCompletion = false;
        }
        console.log('clear the card form, reset', clear);
      });
    }
    if (this.disabled) {
      this.disabled.subscribe(disabled => { 
        this.card.update({ disabled });
        this.disableForm = disabled;
        (disabled) ? this.updateCardForm.disable() : this.updateCardForm.enable();
      });
    }
  }

  ngAfterViewInit() {
    const style = {
      base: {
        fontFamily: '-apple-system, Arial, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px'
      },
      invalid: {
        color: '#E81120',
        iconColor: '#E81120'
      }
    };
    this.card = this.elements.create('card', { 
      style, 
      iconStyle: 'solid'
    });
    this.card.mount(this.cardInfo.nativeElement);

    this.card.addEventListener('change', (e: any) => {
      console.log(e);
      this.cardComplete = e.complete;
      if (e.error) {
        this.cardError = e.error.message;
      } else {
        this.cardError = null;
      }
    });
  }

  async onSubmit(form?: NgForm) {
    console.log('onSubmit');
    if (!this.cardComplete) {
      if (!this.cardError) {
        this.cardError = 'Please enter your card details';
      }
      return;
    }

    const cardHolderName = this.updateCardForm.get('cardHolderName').value;
    if (!cardHolderName.length) {
      return;
    }
    this.card.update({ disabled: true });
    this.processingIntent = true;
    this.updateCardForm.disable();

    const userAddressData = this._user.userDb.verification_data.address_details;
    const data = {
      billing_details: {
        address: {
          city: userAddressData.city,
          country: 'GB',
          line1: userAddressData.first_line_address,
          line2: userAddressData.second_line_address,
          postal_code: userAddressData.post_code
        },
        name: cardHolderName
      },
    };
   return this._stripe.createSetupIntent()
      .pipe(
        takeUntil(this.destroy$),
        switchMap((intent: any) => {
          console.log(intent);
          return from(this.stripe.handleCardSetup(intent.client_secret, this.card, {
            payment_method_data: data
          }));
        }))
      .subscribe((item: any) => {
        console.log(item);
        if (item.error) {
          this.processingIntent = false;
          this.updateCardForm.enable();
          this._toastr.error(item.error.message);
          this.card.update({ disabled: false });
          this.awaitingCompletion = false;
        } else {
          this._toastr.success('Card successfully added');
          this.processingIntent = false;
          this.intentProcessed = true;
          this.awaitingCompletion = this.clear ? true : false;
          this.setupComplete.emit(item.setupIntent);
        }
      });
  }

  goBack() {
    this.goBackVerification.emit(true);
  }

  /* getError(code: string): string {
    let message: string;
    switch (code) {
      case 'parameter_invalid_empty':
        message = this.cardHolderName.value ? 'Please ';
        break;
    
      default:
        message = 'An error has occured - please contact Bunk';
        break;
    }
    return message;
  } */

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}

import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class TypesService {

  constructor(private readonly afs: AngularFirestore, ) { }
  getTypes(type: string) {
    return  this.afs.collection('types', (ref) => ref.where('type', '==', type)
       .where('is_default', '==', true)
    ).valueChanges().pipe(map(res => {console.log('iam from expense service::', res); return res[0]; } ),
    map((res: any) => res.types));
  }

  getViewValuePairs(types: any) {
    return of(types).pipe
    (
      map((types: any) => Object.keys(types).map(key => ({ value: key, viewValue: types[key] }))),
    );
  }
}

import { Component, Input, OnInit } from '@angular/core';
// refer https://swimlane.gitbook.io/ngx-charts/examples/bar-charts/stacked-vertical-bar-chart
interface BarChartSingleData {
  'name': string;
  'series': Array<{ 'name': string, 'value': number }>;
}
@Component({
  selector: 'bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.scss']
})


export class BarChartComponent implements OnInit {

  constructor() { }
  @Input() header: object = { title: '', linkName: '', linkTo: '' };
  @Input() chartData: Array<BarChartSingleData> = [];
  @Input() view;
  // options
  colorScheme = {
    domain: ['#e4e4e4']
  };
  showXAxis = true;
  showYAxis = true;
  gradient = false;
  showLegend = true;
  showXAxisLabel = false;
  showYAxisLabel = false;
  ngOnInit() {
}

  get chartEmpty() {
    return (!this.chartData.length || this.chartData.every(obj => obj.series.every(obj => obj.value === 0)))
  }
}

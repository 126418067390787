<screen-detection (resized)="isDesktop = $event.isDesktop"></screen-detection>

<div class="booking__success" *ngIf="(viewing$ | async) as viewing">

  <img class="margin-bottom" src="/assets/svg/confirmation-svgs/confetti.svg" alt="Confetti image"/>

  <h1 class="text-center" [ngClass]="{'text--lg': isDesktop}">Your viewing request has been sent!</h1>
  <span class="text-center margin-bottom" [ngClass]="{'text--sm': isDesktop}">A confirmation email will be sent to you when the {{landlord}} accepts your request.</span>

  <div class="booking__success--details">
  <ul>
    <li><strong>{{display.propertyAddress }}</strong></li>
    <li><strong>{{display.viewingDate}}</strong></li>
    <li><strong> at {{display.viewingTime  | date:'shortTime' }}</strong></li>
  </ul>
  </div>

  <div class="action-bar" [ngClass]="{'action-bar-column': aside}">
    <button *ngIf="!aside" type="button" class="btn__generic" [routerLink]="['/', 'rent-property', property.property_id]">Back to property</button>
    <button type="button" class="btn__seethrough btn__seethrough--primary" [routerLink]="['/', routes.Tenant.base, routes.Tenant.manage.base, routes.Tenant.viewing.base, routes.Tenant.viewing.reschedule, viewingId]">Go to my viewing</button>
  </div>

</div>

<screen-detection (resized)="isDesktop = $event.isDesktop"></screen-detection>

<div class="prop-small-card">
  <div>
    <div class="prop-small-card__photo" [ngClass]="{'image__placeholder': !display.photo, 'prop-small-card__photo--lg': imageSize === 'lg'}" (click)="gotoProperty()">
      <img
        *ngIf="display.photo"
        src="{{ display.photo }}"
        alt="{{ display.propertyType }}">
    </div>
  </div>

  <div class="prop-small-card__text">
    <div class="flex flex-column">

      <span class="prop-small-card__rent"  *ngIf="bedroomId || !display.isHMO">{{client_data.currency_symbol + ' ' + display.rent}}<sup class="text--subtle">{{rentFrequency(display.rentInterval)}}</sup></span>

      <span class="prop-small-card__rent" *ngIf="display.isHMO && (display.roomMin !== display.roomMax) && !bedroomId">{{  client_data.currency_symbol +  display.roomMin }} - £{{ display.roomMax }}<sup class="text--subtle">{{rentFrequency(display.rentInterval)}}</sup></span>
      <span class="prop-small-card__rent" *ngIf="display.isHMO && (display.roomMin == display.roomMax) && !bedroomId">{{  client_data.currency_symbol + display.roomMin }}<sup class="text--subtle">{{rentFrequency(display.rentInterval)}}</sup></span>

      <span class="prop-small-card__title">{{display.firstLineAddress}} <span *ngIf="property.is_hmo && display.roomName"> - {{display.roomName}}</span></span>
      <span class="prop-small-card__city">{{display.city}}</span>

    </div>

    <div *ngIf="(landlordPublic$ | async) as landlord" class="flex flex-column">
      <span *ngIf="!property.team_id" class="prop-small-card__landlord">Let by {{landlord.firstName}}</span>
      <ng-container *ngIf="viewing && isDesktop && isViewing">
      <small>
        Booking on <strong>{{display.date | date: 'fullDate' }} </strong>at <strong>
          {{display.time | date:'shortTime'}}. </strong>
        </small>
        <small><button class="btn__pseudo-link" type="button" (click)="gotoProperty()">Change</button></small>
      </ng-container>
    </div>
  </div>

</div>


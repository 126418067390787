import {Directive, HostListener, Input, OnDestroy, OnInit} from '@angular/core';
import { Subject} from 'rxjs';
import { UserService } from '@app/services';
import { environment } from '@env/environment';
import { AuthService } from '@app/core/services';
import { first } from 'rxjs/operators';
@Directive({
  selector: '[appSendMessage]'
})
export class SendMessageDirective implements OnDestroy {
  private onDestroy: Subject<null> = new Subject();
  private heyHubData = environment.client_data.company_name === 'CampusKey' ? environment.hey_hub : null;


  @Input() property: any;
  @HostListener('click', ['$event.target'])


 onClick(){
  let property_id = null;
  const heyhub_uid = this._user.userDb.custom_fields && this._user.userDb.custom_fields.heyhub_uid || null;

        if (this.property && this.property.is_marketing_listing){
          property_id = this.property.custom_fields.campuskey_building_code || null;
        } else {
          property_id = this.property.custom_fields.campuskey_flat || null;
        }

        if (heyhub_uid) {
          this._auth.getHeyHubAuthId().pipe(first()).subscribe(response => {
            if(response['auth_key']){
              window.open(`${this.heyHubData.url+'chat/create' }?user_id=${heyhub_uid}&auth_key=${response['auth_key']}&api_key=${this.heyHubData.api_key}&property_id=${property_id}`)
            }
          })
        }
  }

  constructor( private _user: UserService,
              private _auth: AuthService
            ) {
  }

  ngOnDestroy(): void {
    this.onDestroy.next();
    this.onDestroy.complete();
  }

}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TooltipComponent } from './tooltip.component';
import { MatIconModule, MatTooltipModule } from '@angular/material';

@NgModule({
  imports: [
    CommonModule,
    MatTooltipModule,
    MatIconModule,
  ],
  declarations: [TooltipComponent],
  exports: [TooltipComponent],
})
export class TooltipModule { }

import {Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {Auth, Shared} from '@env/routing';
import {ActivatedRoute} from '@angular/router';
import {PropertyService} from '@app/services';
import {AuthService} from '@app/core/services';

@Component({
  selector: 'bedroom-page-component',
  template: `
    <screen-detection (resized)="isDesktop = $event.isDesktop"></screen-detection>
    <!-- <ng-container *ngIf="_auth.authenticated">
      <tenant-main-template *ngIf="_auth.authenticated">
        <ng-container *ngTemplateOutlet="preview"></ng-container>
      </tenant-main-template>
      </ng-container>
     -->


    .        <ng-container *ngIf="!_auth.authenticated">
      <nav-top [guest]="!_auth?.authenticated" [notebarNotificationOpen]="notebarNotificationOpen">
        <ng-container *ngIf="!isDesktop">
          <button [matMenuTriggerFor]="menu" class="btn__seethrough">
            <mat-icon class="mr_4">menu</mat-icon>Menu
          </button>
          <mat-menu #menu="matMenu">
            <button id="guest-search" mat-menu-item [routerLink]="['/' + routes.Shared.search]">Search</button>
            <button id="guest-login" mat-menu-item [routerLink]="['/' + routes.Auth.signin]">Log in</button>
            <button id="guest-signup" mat-menu-item  [routerLink]="['/' + routes.Auth.signup]">Sign up</button>
          </mat-menu>
        </ng-container>

        <span *ngIf="isDesktop" class="signup__guest-btns">
          <button id="guest-search" class="btn__seethrough" [routerLink]="['/' + routes.Shared.search]">
            <mat-icon svgIcon="nav-icon-search"></mat-icon>Search
          </button>
          <button id="guest-signup" class="btn__generic" [routerLink]="['/' + routes.Auth.signup]"> Sign up</button>
          <button id="guest-login" class="btn__cleanskin" [routerLink]="['/' + routes.Auth.signin]">Log in</button>
        </span>
      </nav-top>

    </ng-container>

    <div [ngClass]="{'margin-top--xl': !_auth.authenticated}">
      <property-preview-bedroom></property-preview-bedroom>
    </div>

  `,
  styleUrls: ['property-page.component.scss']

})

export class BedroomPageComponent {
  private readonly propertyId: string;
  public isDesktop: boolean;
  public property$: Observable<any>;
  public sidebarMainOpen = true;
  public notebarNotificationOpen = false;
  public routes = { Auth, Shared };

  constructor(private route: ActivatedRoute,
              private _property: PropertyService,
              public _auth: AuthService) {

    this.propertyId = this.route.snapshot.paramMap.get('id');
    this.property$ = this._property.getPropertyById(this.propertyId).valueChanges();
  }
}

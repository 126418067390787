import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'stats-card-small',
  template: `
    <div class="card-base card-base__payments loading-animation--header">
      <div class="card-base--title">
       <div class="margin-top--sm middle loading-animation"></div>
      </div>
      <div class="margin-top--md flex flex-column">
        <div class="margin-top--sm title large loading-animation" [style.margin-left.px]="28"></div>
        <div class="margin-top--sm margin-bottom--md title large loading-animation" [style.margin-left.px]="28"></div>
      </div>
    </div>
  `,
  styleUrls: ['../skeleton-loading.component.scss']
})

export class StatsCardSmallComponent implements OnInit {
  constructor() {
  }

  ngOnInit() {
  }
}

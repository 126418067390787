import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatTableDataSource } from '@angular/material';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { RENT_STATUS } from '@app/core-landlord/core-landlord.constants';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import * as moment from 'moment';
import { PaymentsService } from '@app/services/payments.service';
import { finalize, flatMap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { PAYMENT_PAID_ON_TYPES, PAYMENT_STATUS } from '@app/shared/shared.constants';
import { environment } from '@env/environment';
import { TABLE_ITEMS_PP } from '@app/_constants/forms.constants';
import { ExportToCsv } from 'export-to-csv';
import { UserService } from '@app/services';
import { MY_FORMATS } from '@app/shared/_services/dates.service';
import { Observable } from 'rxjs';


@Component({
  selector: 'bunk-payment-table',
  templateUrl: './bunk-payment-table.component.html',
  styleUrls: ['./bunk-table.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
  ]
})

export class BunkPaymentTableComponent implements OnInit {
  @Input() columnsToDisplay;
  @Input() displayedColumns;
  @Input() isAdmin = false;
  @Input() totalHits;
  @Output() selectedId = new EventEmitter();
  @Output() pagination = new EventEmitter();
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  public pageSize = TABLE_ITEMS_PP;
  public displayedAdminColumns: string[] = ['month', 'user_reference', 'mark_paid', 'more_options'];
  public page;
  public dataSource;
  public RENT_STATUS = RENT_STATUS;
  public displayAllColumns;
  public dref;
  public overRef;
  public showSpinner = false;
  public downloadData = [];
  public hitsPerPage;
  public deletePaymentsSpinner = false;

  public paymentPaidOnTypes = PAYMENT_PAID_ON_TYPES;
  public paymentStatuses = PAYMENT_STATUS;
  public adminFormGroup;
  public disabledButtons: any = [];

  public showKey = false;
  public environment = environment;
  public deletePaymentGroup: FormGroup;
  public client_data = environment.client_data;
  private selected_payment_amount: number;
  team$: Observable<any>;

  @Output() recallAlgolia: EventEmitter<any> = new EventEmitter<any>(null);

  @Input()
  get data() {
    return this.dataSource;
  }
  set data(tableData: any) {
    this.dataSource = new MatTableDataSource<any>(tableData);
    // this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    // moment dates cant be sorted by mat-table: this is their recommendation to convert it to timestamp to be sorted (still displays as moment date tho :D)
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'momentDueDate': return new Date(item.momentDueDate);
        case 'timestampPaid': return item.timestampPaid.toDate();
        default: return item[property];
      }
    };
  }

  @Input() get itemsPerPage() {
    return this.hitsPerPage;
  }

  set itemsPerPage(event: any) {
    this.hitsPerPage = event;
  }

  @Input()
  get pageNumber() {
    return this.page;
  }
  set pageNumber(event: any) {
    this.page = event;
  }


  constructor(private _formBuilder: FormBuilder,
              private _payment: PaymentsService,
              public _user: UserService,
              private _toastr: ToastrService) {
    this.adminFormGroup = this.initAdminForm();
    this.partially_paid_input.setValidators(this.partiallyPaidValidator());
    this.deletePaymentGroup = this._formBuilder.group({is_deleting_all: false});
  }

  get is_deleting_all():FormControl {
    return this.deletePaymentGroup.get('is_deleting_all') as FormControl;
  }

  get due_date(): FormControl {
    return this.adminFormGroup.get('due_date') as FormControl;
  }

  get amount(): FormControl {
    return this.adminFormGroup.get('amount') as FormControl;
  }

  get payment_paid_on(): FormControl {
    return this.adminFormGroup.get('payment_paid_on') as FormControl;
  }
  get payment_paid_string(): FormControl {
    return this.adminFormGroup.get('payment_paid_string') as FormControl;
  }
  get payment_status(): FormControl {
    return this.adminFormGroup.get('payment_status') as FormControl;
  }

  get payment_status_string() {
    return (this.payment_status.value as string).replace(/_/g, ' ');
  }

  get is_being_held(): FormControl {
    return this.adminFormGroup.get('is_being_held') as FormControl;
  }

  get notes(): FormControl {
    return this.adminFormGroup.get('notes') as FormControl;
  }

  get partially_paid_input(): FormControl {
    return this.adminFormGroup.get('partially_paid_amount') as FormControl;
  }

  get payment_amount() {
    if (this.payment_status.value === 'partially_paid') {
      return this.partially_paid_input.value || 0;
    }
    return this.selected_payment_amount;
  }

  partiallyPaidValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (this.payment_status.value !== 'partially_paid') {
        return null;
      }
      const value = control.value;
      if (value === null || value === undefined) {
        return  { missing_value: true };
      }
      if (value <= 0) {
        return { not_positive: true };
      }
      if (value >= this.selected_payment_amount) {
        return { more_than_payment: true };
      }
      return null;
    };
  }

  get partially_paid_error_message() {
    const { errors } = this.partially_paid_input;
    if (errors.missing_value) {
      return 'Please enter the amount that has been paid';
    } else if (errors.not_positive) {
      return 'Amount paid must be positive';
    } else if (errors.more_than_payment) {
      return 'Partially paid paymemts must be less than the total payment';
    }
    return '';
  }

  updateValue(element: { amount: number }) {
    this.selected_payment_amount = element.amount;
    this.setFormGroup();
  }

  emitId(id: string) {
    this.selectedId.emit(id);
  }

  markAsPaid(payment_id: string) {
    if (this.payment_status.value === 'paid') {
      this.payment_paid_on.setValidators([Validators.required]);
      this.payment_paid_on.updateValueAndValidity();
    }

    if (this.adminFormGroup.valid) {
      this.disabledButtons.push(payment_id);
      const index = this.disabledButtons.indexOf(payment_id);
      const date = this.payment_paid_on.value ? this.payment_paid_on.value.toDate() : null;
      this.dref.close();
      this._payment.updatePaymentStatus(payment_id, date, this.payment_status.value, this.notes.value, this.partially_paid_input.value).subscribe(
        () => {
          this.payment_paid_on.setValidators(null);
          this.payment_paid_on.updateValueAndValidity();
          this._toastr.success(`Payment update successful`);
          this.setFormGroup();
          // if (this.paymentStatus.value !== 'paid') { this.disabledButtons.splice(index, 1); }
        },
        () => {
          this.disabledButtons.splice(index, 1);
          this.payment_paid_on.setValidators(null);
          this.payment_paid_on.updateValueAndValidity();
          this.setFormGroup();
          this._toastr.error(`Payment update failed`);
        }
      );
    }
  }

  setFormGroup() {
    this.adminFormGroup.reset();
    this.payment_status.setValue('paid');
    this.payment_status.updateValueAndValidity();
  }

  setDatePaidOnType(type: string) {
    this.payment_paid_string.setValue(type);
    if (type !== 'other') {
      const isToday = (type === 'today');
      this.setDatePaid(isToday);
    }
  }

  setDatePaid(isToday: boolean) {
    (isToday)
      ? this.payment_paid_on.setValue(moment())
      : this.payment_paid_on.setValue(moment().subtract(1, 'days'));
  }

  initAdminForm() {
    return this._formBuilder.group({
      amount: null,
      due_date: null,
      payment_paid_on: null,
      payment_paid_string: '',
      payment_status: 'paid',
      partially_paid_amount: 0,
      notes: '',
      is_being_held: false
    });
  }

  setFormValues(due_date: any, amount: number) {

    this.due_date.setValue(moment(due_date));
    this.amount.setValue(amount);
  }

  updatePaymentDetails(payment_id: string) {
    this.dref.close();
    this._payment.adminUpdatePayment(payment_id, moment(this.due_date.value) , parseFloat(this.amount.value), this.notes.value, this.is_being_held.value).subscribe(() => {
        this.payment_paid_on.setValidators(null);
        this.payment_paid_on.updateValueAndValidity();
        this._toastr.success(`Payment update successful`);
        this.setFormGroup();
        // if (this.paymentStatus.value !== 'paid') { this.disabledButtons.splice(index, 1); }
      },
      () => {
        this._toastr.error(`Payment update failed`);
      });
  }

  ngOnInit(): void {

    this.displayAllColumns = (this.isAdmin || this._user.userDb.is_paid_off_platform)
      ? [...this.displayedColumns, ...this.displayedAdminColumns]
      : this.displayedColumns;
  }
  updatePage(event: any) {
    this.pagination.emit({hitsPerPage: event.pageSize, page: event.pageIndex})
  }
  openFirestoreDoc(type: string, id: string) {
    const url = `${environment.firebaseConfig.firestoreUrl}${type}~2F${id}`;
    window.open(url, '_blank');
  }
  getAllData() {
    this.showSpinner = true;
    this.downloadData = [...this.dataSource.data, ...this.downloadData];

    if (this.downloadData.length < this.totalHits) {
      this.pagination.emit({hitsPerPage: this.hitsPerPage, page: this.page + 1});
      return setTimeout(() => {
        return this.getAllData();
      }, 500);
    }

    const csvExporter = new ExportToCsv({
      filename: `${this._user.userDb.profile_data.full_name} Payments`,
      fieldSeparator: ',',
      quoteStrings: '"',
      decimalSeparator: '.',
      showLabels: true,
      useTextFile: false,
      useBom: true,
      useKeysAsHeaders: true,
    });

    csvExporter.generateCsv(this.generateCSVData(this.downloadData));
    this.showSpinner = false;
    this.downloadData = [];
    this.pagination.emit({hitsPerPage: this.hitsPerPage, page: 0});
    this.dref.close();
  }

  generateCSVData(data: any) {
    return data.map((hit: any) => {
      return {
        ['Due Date']: hit.momentDueDate,
        Address: hit.propertyAddress,
        ['Tenant name']: (hit.tenantFullName) ? hit.tenantFullName : hit.tenantName,
        Type: hit.paymentType,
        Amount: hit.amount,
        ['Date paid by tenant']: hit.timestampPaid || '',
        Status: hit.status.viewValue,
        ['Amount outstanding']: hit.amountOutstanding || '',
        ['Payment Id']: hit.id
      };
    });
  }


  openXero(payment: any) {
    if (payment.team_id) {
       this._user.getTeamById(payment.team_id).valueChanges().pipe(
         flatMap((team) => {
             console.log('the team', team);
             if (payment.custom_fields.xero_invoice_id) {
               const url = `https://go.xero.com/organisationlogin/default.aspx?shortcode=!${team.xero_short_code}&redirecturl=/AccountsReceivable/View.aspx?InvoiceID=${payment.custom_fields.xero_invoice_id}`;
               return window.open(url, '_blank');
             }
           }
         )
       ).subscribe();
    }


  }

  deletePayments(payment: any){
    console.log(payment, 'hello payment');
    const item = {
      id: payment.objectID,
      is_deleting_all: this.is_deleting_all.value,
      payment_type: payment.paymentType,
      sender_uid: payment.uid,
      tenancy_id: payment.tenancyId ? payment.tenancyId : null
    };

    this.deletePaymentsSpinner = true;
    console.log(item, 'item to send to CF');
    this._payment.adminDeletePayment(item).pipe(
      finalize(() => {
        this.deletePaymentsSpinner = false;
      })
    ).subscribe((res: any) => {
        this.recallAlgolia.emit({removedId: res.ids});
        this.is_deleting_all.setValidators(null);
        this.is_deleting_all.updateValueAndValidity();
        this._toastr.success(`Payment deleted successful`);
        this.dref.close();
        },
      () => {
        this.deletePaymentsSpinner = false;
        this._toastr.error(`Payment deletion failed`);
      });

  }
}


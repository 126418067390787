import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { MaintenanceService, PropertyService, UserService } from '@app/services';
import * as moment from 'moment';
import { Landlord } from '@env/routing';
import { ActivatedRoute, Router } from '@angular/router';
import { TRANSLATIONS } from '@app/_constants/translations.constants';

@Component({
  selector: 'maintenance-card',
  template: `
    <li class="card__list-item" *ngIf="property$ | async as property">
        <span *ngIf="request?.is_urgent"
              class="card__notification-counter">
                   Urgent
        </span>
      <div class="card__list-item-image" *ngIf=" request.maintenance_images_url && request.maintenance_images_url[0] && request.maintenance_images_url[0].image_small_url"
          >
        <img [src]="request.maintenance_images_url[0].image_small_url" alt="">

      </div>
      <div class="card__list-item-image" *ngIf="request.maintenance_images_url &&  request.maintenance_images_url[0] && !request.maintenance_images_url[0].image_small_url"
           [style.backgroundImage]="'url(' + request.maintenance_images_url[0] + ')'">
        <img [src]="request.maintenance_images_url[0]" alt="">
      </div>

      <div class="card__list-item-image pointer-events-none image__placeholder image__placeholder--ll-applications image__placeholder--small" *ngIf="!request.maintenance_images_url || !request.maintenance_images_url[0]" ></div>

      <div class="card__list-item-body pointer-events-none flex flex-column  justify-between">

        <div class="flex flex-column justify-end">
        <div class="margin-bottom--sm">
        <span *ngIf="_user.userDb.role === 'landlord' && request.status !== 'Completed' && request.priority" class="mb_5 flex items-center text--{{priority[request.priority]}} text--sm">
        <span class = "mr_2 card__status-banner card__status-banner--{{priority[request.priority]}} pointed-arrow" [ngStyle] = "{'padding.px':8}">
          <mat-icon svgIcon = "pointed-arrow-up" class = "" [ngStyle] = "{'height.px':12,'width.px':12, 'display':'flex'}"></mat-icon>
        </span>
        {{request.priority | titlecase}}</span>
          <span class="card__title mr_3">{{request.title}}</span>
          <small class="card__status-banner"
                 [ngClass]="{
              'card__status-banner--danger' : request.status === 'Request submitted' || request.status === 'New request',
             'card__status-banner--warning' : request.status === 'In progress',
              'card__status-banner--success':  request.status === 'Completed'
              }" >{{request.status}}</small>
        </div>
        <span class="truncate">{{(property?.address.second_line_address ? property?.address.first_line_address + ', ' : property?.address.first_line_address)}}
          {{(property?.address.second_line_address ? property?.address.second_line_address + ', ' : ', ')}}
          {{property?.address.post_code}}</span>
            <!--          change for ll-->
        <small *ngIf="request.tenant_notes" class="notes"><mat-icon svgIcon="notes" class="notes"></mat-icon>
          {{(_user.userDb.role === 'tenant' ? 'Notes added from your ' + landlord : 'Notes added')}}
          </small>
        </div>

      </div>

      <div class="card__list-item-cta">
        <button class="btn__generic margin-bottom--sm" (click)="editMaintenanceRequest()">{{(_user.userDb.role === 'tenant' ? 'View' : 'Manage')}}</button>
        <ng-container *ngIf="_user.userDb.role === 'tenant'">
          <button class="btn__seethrough btn--sm " appContactButton
                  [property]="property"
                  [addresseeId]="request.landlord_id">Contact</button>
        </ng-container>
        <small class="text--subtle text--no-wrap">{{request.dateCreated}}</small>
      </div>
    </li>
  `,
  styleUrls: ['./maintenance-card.component.scss']
})

export class MaintenanceCardComponent implements OnInit {
  @Input() request: any;
  public property$: Observable<any>;
  dateCreated;
  priority = {
    'high': 'danger',
    'medium': 'warning',
    'non-urgent': 'primary',
    'low': 'success'
  };
  public landlord = TRANSLATIONS.landlord;
  constructor(private _property: PropertyService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _maintenance: MaintenanceService,
    public _user: UserService) {
  }

  ngOnInit() {
    this.property$ = this._property.getPropertyById(this.request.property_id).valueChanges();
    this.request = {
      ...this.request,
      status: this._maintenance.getMaintenanceStatus(this._user.userDb.role, this.request.status),
      dateCreated: this.getUpdateTime(this.request.status)
    };

  }

  editMaintenanceRequest() {
    this._router.navigate([this.request.request_id], { relativeTo: this._route });
  }

  getUpdateTime(status) {
    switch (status) {
      case 'pending':
        return 'Request sent ' + moment(this.request.created_at.toDate()).fromNow();
      case 'in_progress':
        return this.dateCreated = 'Request accepted ' + moment(this.request.accepted_at.toDate()).fromNow();
      case 'completed':
        return this.dateCreated = 'Request completed ' + moment(this.request.completed_at.toDate()).fromNow();
    }
  }
}

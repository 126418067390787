import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {isNullOrUndefined} from 'util';
import {ToastrService} from 'ngx-toastr';
import {AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument} from '@angular/fire/firestore';
import {AngularFireAuth} from '@angular/fire/auth';
import {map, switchMap} from 'rxjs/operators';

@Injectable()
export class TenancyIdGuard implements CanActivate {
  public usersCollection: AngularFirestoreCollection<any>;
  public userDoc: AngularFirestoreDocument<any>;

  user: Observable<any>;

  constructor(private router: Router,
              public afAuth: AngularFireAuth,
              private readonly afs: AngularFirestore,
              private toastr: ToastrService) {
  }

  // Check if tenant has verified tenancy
  getTenancyId() {
    return this.afAuth.authState
      .pipe(
        switchMap((auth: any) => {
          this.usersCollection = this.afs.collection<any>('users');
          this.userDoc = this.usersCollection.doc(auth.uid);
          return this.userDoc.valueChanges();
        }),
        map(user => {
         return (user.user_defaults && user.user_defaults.tenancy && user.user_defaults.tenancy.selected_tenancy_id)
          ? user.user_defaults.tenancy.selected_tenancy_id
           : null;
        })
      );

  }

  canActivate(route: ActivatedRouteSnapshot,
              state: RouterStateSnapshot) {
    return this.getTenancyId()
      .pipe(
        map(tenancyId => {
          if (isNullOrUndefined(tenancyId) || tenancyId === '') {
            this.router.navigate(['/']);
            this.toastr.success('You need to add your tenancy code to view this.');
            return false;
          } else {
            console.log('Tenancy Id Confirmed!');
            return true;
          }
        })
      );
  }
}

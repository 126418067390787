export const getExpression = (base: string, characters: string = '') => {
    characters.split('').forEach((c) => base += `\\${c}`);
    return base;
};

export const getForbiddenCharacters = (value: string, allowedCharacters: string) => {
    const expression = new RegExp(`[^${allowedCharacters}]`, 'g');
    const matchResult = value.match(expression);
    return (matchResult != null) ? matchResult.join('') : '';
};

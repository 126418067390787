import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef } from '@angular/core';
import {Landlord} from '@env/routing';
import { Observable } from 'rxjs/internal/Observable';
import { PropertyService } from '@app/services';
import { NavigationEnd, Router } from '@angular/router';
import {finalize, flatMap, map} from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import {FormBuilder, FormGroup} from '@angular/forms';

@Component({
  selector: 'bunk-tabs',
  template: `
    <section class="main__wrapper" *ngIf="propertyEdit$ | async as property_edit">
    <nav class="bread-crumbs-back">
        <ol>
        <bunk-dialog  [component]="contentDialog" (dialogRef)="dref = $event">
          <li class="bread-crumb-prior">Back to property</li>
        </bunk-dialog>
        </ol>
    </nav>
      <div class="property__notes-header">
        <div class="property__header margin-top--md">
          <div class="property__header--info">
            <strong class="text--lg">Edit {{property.address.first_line_address}} <span *ngIf="property_edit.pending_approval">- Pending</span></strong>
            <p class="mt_8" *ngIf="!property_edit.pending_approval"> Save all your edits below, once you're all done you can publish them for review </p>
            <p class="mt_8" *ngIf="property_edit.pending_approval"> Your {{(property.has_current_tenancy && !property.is_hmo ? 'changes' : 'edits')}} have been sent for approval. You can not make any changes until they have been accepted.</p>
          </div>
          <div class="btn__container" *ngIf="!property_edit.pending_approval">
            <bunk-dialog  [component]="publishDialog" (dialogRef)="dref = $event">
              <button class="btn__cta btn--round w_100">Publish edits</button>
            </bunk-dialog>
            <ng-template #publishDialog>
              <h3  class="margin-bottom--sm">{{(property.has_current_tenancy && !property.is_hmo ? 'Confirm updates' : 'Confirm edits')}}</h3>
              <p class="margin-bottom"> You will not be able to make any more changes until they have been confirmed.</p>

              <form [formGroup]="formGroup" >
                <ng-container *ngIf="!property.is_listed">
                <p>Are you ready for your property to be published across all online portals? By clicking no you are saying you do not want these edits to update our live online portals. Please note you will still be able to see your edits successfully changed under your property overview.</p>
                <mat-radio-group formControlName="is_listed" class="flex flex-column margin-bottom">
                  <mat-radio-button  class="bunk-outlined-radio margin-bottom--xxs" [ngClass]="{'bunk-outlined-radio--selected': formGroup.get('is_listed').value === true}" [value]="true">Yes</mat-radio-button>
                  <mat-radio-button  class="bunk-outlined-radio margin-bottom--xxs" [ngClass]="{'bunk-outlined-radio--selected': formGroup.get('is_listed').value === false}" [value]="false">No</mat-radio-button>

                </mat-radio-group>
                </ng-container>

                <ng-container *ngIf="property_edit.is_hmo">
                  <p > Please select which rooms you would like to re publish - if none please leave blank</p>

                  <mat-form-field appearance="outline" class="w_100">
                    <mat-select [formControlName]="'bedroom_ids'" multiple placeholder="Please select the rooms you want to re list">
                      <ng-container *ngFor="let bedroom of property_edit.hmoBedrooms" >
                        <mat-option  *ngIf="!bedroom.is_mid_tenants" value="{{bedroom.bedroom_id}}" >{{bedroom.room_name}}</mat-option>
                      </ng-container>
                    </mat-select>
                  </mat-form-field>
                </ng-container>


              </form>

              <nav class="action-bar">
                <button type="button"
                        class="btn__seethrough" (click)="dref.close()">Close</button>
                <button class="btn__generic" (click)="landlordPublishPropertyEdit(formGroup.getRawValue())">
                  Publish edits
                <processing-spinner *ngIf="showSpinner"></processing-spinner>
                </button>
              </nav>
            </ng-template>
            <bunk-overlay [template]="previewTemplate" (overlayRef)="tabRef.emit($event)">
            <button class="btn--round  btn__seethrough btn__seethrough--white-border margin-top--xs">Preview Listing</button>
            </bunk-overlay>
          </div>
        </div>
        <nav mat-tab-nav-bar class="mat__tab mt_8" >
          <a mat-tab-link routerLinkActive #rla="routerLinkActive" [ngClass]="{'is-active': step.index === currentIndex }"
             [active]="rla.isActive" *ngFor="let step of steps" [routerLink]="step.routerLink" [queryParams]="step.queryParams ? step.queryParams : ''">
            {{step.label}} <span *ngIf="checkForUpdates(property_edit.steps, step.label)">*</span>
          </a>
        </nav>
      </div>
      <ng-template #contentDialog>
              <p  class="text--lg margin-bottom--sm">Are you sure you want to cancel?</p>
              <p *ngIf="!showSpinner">Any changes that you have made to this listing will not be saved.</p>
              <loading-spinner *ngIf="showSpinner"></loading-spinner>
              <nav class="action-bar">
                <button type="button"
                        class="btn__seethrough" (click)="dref.close()">Close</button>
                <button type="button"
                        class="btn__warning" [disabled]="showSpinner"  (click)="cancelPropertyEdits()">Yes, Cancel</button>
              </nav>
      </ng-template>
    </section>
  `,
  styleUrls: ['./bunk-tabs.component.scss']
})

export class BunkTabsComponent implements OnInit {
  @Input() steps: any;
  @Input() property: any;
  @Input() editId: string;
  @Input() previewTemplate: TemplateRef<any>;
  @Output() tabRef: EventEmitter<any> = new EventEmitter();
  public propertyEdit$: Observable<any>;
  public currentIndex: number;
  public dref: any;
  routes = {Landlord};
  showSpinner = false;
  public formGroup: FormGroup;

  constructor(private _property: PropertyService,
              private router: Router,
              private _toastr: ToastrService,
              private _formBuilder: FormBuilder) {
  }

  ngOnInit() {
    this.propertyEdit$ = this._property.getPropertyEditById(this.editId).valueChanges().pipe(
      map((property: any) => {
        return {
          ...property,
          hmoBedrooms: property.is_hmo ? Object.values(property.bedrooms) : null
        };
      })
    )
    ;

    this.router.events.subscribe((val: NavigationEnd) => this.update(val.url));
    this.update(this.router.url);

    this.formGroup = this._formBuilder.group({
      is_listed: null,
      bedroom_ids: [[]]
    });
  }

  checkForUpdates(steps: any, label: string) {
    return !!(steps && steps[label.toLowerCase()]);
  }

  update(url: any ) {
    url = this.router.url;
    for (const step of this.steps) {
      if (url.includes(step.routerName)) {
        this.currentIndex = step.index;
      } else if (url.includes('checkout') || url.includes('preview')) {
        this.currentIndex = 4;
      }
    }
  }

  cancelPropertyEdits() {
    this.showSpinner = true;
    this._property.cancelPropertyEdits(this.property.property_id).pipe(
      finalize(() => {this.showSpinner = false; this.dref.close(); })
    ).subscribe(() => this.router.navigate(['/', Landlord.base, Landlord.properties.base, Landlord.properties.summary]));
  }

  landlordPublishPropertyEdit(form: any) {
    this.showSpinner = true;
    console.log(this.property.property_edit_id, this.property.property_id);

    console.log(form, 'the bedroom ids');
    const isListed =  form.is_listed !== null ? form.is_listed : this.property.is_listed;

    this._property.updateListed(form.bedroom_ids, this.property, isListed).pipe(
      flatMap(() => this._property.landlordPublishPropertyEdit(this.property.property_id)),
      finalize(() => {this.showSpinner = false; this.dref.close(); })
    ).subscribe(
      () => this.router.navigate(['/', Landlord.base, Landlord.properties.base, Landlord.properties.summary], {queryParams: {propertyEditPublished: true}}),
      (err: any) => this._toastr.error(err.error.message)
    );

   }

}

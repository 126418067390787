
    <div class="i-search" *ngIf="resultsList">
      <img class="i-search__input-icon" src='/assets/svg/baseline-search-24px.svg' alt="Search...">
      <button *ngIf="searchInput.value.length > 1" class="i-search__input-icon-close" (click)="clearSearch()">
        <img src='/assets/svg/baseline-close-24px.svg' alt="Close list" (click)="hideResults = true">
      </button>
      <input #searchInput
        placeholder="{{ !disabled ? message : disabledMessage }}"
        autocomplete="off"
        class="i-search__input"
        [disabled]="disabled"
        [ngClass]="{ 'i-search__input--active': showHits }"
        (input)="setSearchQuery($event)"
        (click)="setSearchQuery($event)"
        (keydown.esc)="hideSearchResults()"/>
      <small></small>
      <div #searchHits
        class="i-search__hits-container"
        *ngIf="showHits"
        (clickOutside)="clickHideSearchResults($event)">
        <ng-container *ngFor="let hit of resultsList">
          <ng-container *ngIf="indexName === 'USERS-full_name' && hit.role === 'tenant'">
            <ng-container *ngTemplateOutlet="algoliaUsersHit; context: {hit: hit}"></ng-container>
          </ng-container>
          <ng-container *ngIf="indexName === 'universities'">
            <ng-container *ngTemplateOutlet="algoliaUniversities; context: {hit: hit}"></ng-container>
          </ng-container>
          <ng-container *ngIf="indexName === 'properties'">
            <ng-container *ngTemplateOutlet="algoliaProperties; context: {hit: hit}"></ng-container>
          </ng-container>
        </ng-container>
      </div>
    </div>


    <ng-template #algoliaProperties let-hit="hit">

      <label type="label" (click)="selectAlgoliaProperty(hit.property_id)" *ngIf="storedHits.includes(hit.property_id)">
        <div class="i-search__hit-row"
            tabindex="0"
            aria-role="button"
            attr.aria-label="Add {{hit.name}}">
          <p>{{hit.address.first_line_address}}{{(hit.address.second_line_address ? ',' : '')}}
            <span *ngIf="hit.address.second_line_address">{{hit.address.second_line_address | truncate:[25, '...']}}</span>
          </p>


          <mat-checkbox
            *ngIf="multiselect"
            class="prompt"
            #checkbox
            [id]="hit.property_id">
          </mat-checkbox>

        </div>
      </label>
    </ng-template>

    <ng-template #algoliaUsersHit let-hit="hit">
      <div class="i-search__hit-row"
        tabindex="0"
        aria-role="button"
        attr.aria-label="Add {{ hit.profile_data.full_name }}"
        (click)="getSelectedId(hit)"
        (keydown.space)="getSelectedId(hit)"
        (keydown.enter)="getSelectedId(hit)"
        (keydown.esc)="hideSearchResults()">
<!--
        <label type="label">
        <div class="i-search__hit-row"
            tabindex="0"
            aria-role="button"
            attr.aria-label="Add {{hit.name}}">
            <p>{{hit.profile_data.full_name}}</p>

           <mat-checkbox
            class="prompt"
            #checkbox
            [id]="hit.uid">
          </mat-checkbox>
        </div>
      </label> -->

          <profile-avatar size="sm" [profileImgUrl]="hit.profile_data.profile_image_url" [profileName]="hit.profile_data.full_name"></profile-avatar>
          <p>{{hit.profile_data.full_name}}</p>
      </div>
    </ng-template>


    <ng-template #algoliaUniversities let-hit="hit">
      <div class="i-search__hit-row"
        tabindex="0"
        aria-role="button"
        attr.aria-label="Add {{hit.name}}"
        (click)="value = hit"
        (keydown.space)="value = hit"
        (keydown.enter)="value = hit"
        (keydown.esc)="hideSearchResults()">
        <img class="i-search__hit-image" [alt]="hit.name" src="/assets/img/icons/bunk-default-avatar.svg">
        <p class="truncate">{{hit.name}}</p>
        <small class="prompt">Add</small>
      </div>
    </ng-template>
  
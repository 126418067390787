import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BunkUploaderComponent } from './bunk-uploader.component';
import { BunkInputUploaderComponent } from './bunk-input-uploader.component';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [BunkUploaderComponent,BunkInputUploaderComponent],
  exports:[BunkUploaderComponent, BunkInputUploaderComponent]

})
export class BunkUploaderModule { }


  <table >
    <thead>
    <tr>
      <th >
        <div class="flex">
          <div class="full__width full__width--small full_width--small u-mg--sm u-pd--sm loading-animation--header"></div>
          <div class="full__width u-mg--sm u-pd--sm loading-animation--header"></div>
          <div class="full__width full__width--narrow u-mg--sm u-pd--sm loading-animation--header"></div>
          <div class="full__width full__width--narrow u-mg--sm u-pd--sm loading-animation--header"></div>
          <div class="full__width full__width--narrow u-mg--sm u-pd--sm loading-animation--header"></div>
          <div class="full__width full__width--narrow u-mg--sm u-pd--sm loading-animation--header"></div>
        </div>
      </th>
    </tr>
    </thead>
    <tbody>
    <tr class="js-table-row-loader" *ngFor="let i of [0, 1, 2, 3, 4, 5]">
      <td colspan="4" class="table-cell-loader">
        <div class="flex">
          <div class="full__width full__width--small u-mg--sm u-pd--sm loading-animation"></div>
          <div class="full__width u-mg--sm u-pd--sm loading-animation "></div>
          <div class="full__width full__width--narrow u-mg--sm u-pd--sm loading-animation"></div>
          <div class="full__width full__width--narrow u-mg--sm u-pd--sm loading-animation"></div>
          <div class="full__width full__width--narrow u-mg--sm u-pd--sm loading-animation"></div>
          <div class="full__width full__width--narrow u-mg--sm u-pd--sm loading-animation"></div>
        </div>
      </td>
    </tr>
    </tbody>
  </table>

import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ReferencesService, UserService } from '@app/services';
import { Observable, combineLatest, of } from 'rxjs';
import { map, flatMap, tap } from 'rxjs/operators';
import * as _ from 'lodash';
import * as moment from 'moment';
import { BunkPassportService } from '@app/services/bunk-passport.service';
import { COUNTRY_IS03166 } from '@app/_constants/countries.constants';
import { DatesService } from '@app/shared/_services/dates.service';
import { PROPERTY_MANAGER_IDS } from '@app/core-landlord/core-landlord.constants';
import { AuthService } from '@app/core/services';
import {environment} from '@env/environment';
import { IPassportReferences, IReferenceDoc } from '@app/_models/references.model';
import { IUserPassport, IUserReadOnly } from '@app/core/models';
import { VerificationStatusString } from '@app/_models/verfify-types.model';
import { BunkPassportReferences } from '@app/_models/bunk_passport.model';
import { IPropertyAddressDetails } from '@rentbunk/bunk-models';


const RENTINGFROM = {
  letting_agency: 'Letting agency',
  university: 'University',
  housing_association: 'Housing association',
  private_landlord: 'Private landlord',
  other: 'Other',
  prefer_not_to_say: 'n/a',
  family: 'Family'
};
interface IFormatCredit{
  hasCredit?: boolean,
  verificationTier?: number,
  verificationStatus?: VerificationStatusString,
  tier?:number,
  credit?: {title: string, value:string}[]
}
interface IFormatLandlord{
  tenancyLength?: string,
  rent?: string,
  type?: string,
  status?: string
}

interface IFormatEmployer{
  employmentStatus?: string,
  jobTitle?: string,
  companyName?: string,
  startDate?: Date,
  salary?: string,
  type?: string,
  status?: string,
  workingDuringTenancy?: string,
  passedProbation?: string
}

interface IFormatUniversity{
  courseEndYear?: Date,
    courseName?:string,
    uniName?: string,
    type?: string,
    status?: string,
    student_id?: string,
    rental_academic_year?: string
}

interface IFormatGuarantor{
  id?: string,
  hasBunkVerified?: boolean,
  name?: string,
  relationship ?: string,
  type?: string,
  status?: string,
  countryOfResidence?: string,
  homeOwner?: string,
  version?: number,
  url?: string,
  address?: string,
  phone?: string,
  email?: string,
  dob?: Date,
  employmentStatus?: string,
  employmentType?: string,
  contractLength?: string,
  positionHeld?: string,
  salary?: string,
  bankStatements?: string
}

interface IUser {
  userEmail?: string,
  userProfileImage?: string
}

interface IUserReference {

}

@Component({
  selector: 'profile-card-overlay',
  templateUrl: `./profile-card-overlay.component.html`,
  styleUrls: ['./profile-card.component.scss']
})
export class ProfileCardOverlayComponent implements OnInit {
  @Input() uid: string;
  @Input() property: any;
  @Output() closeOverlay: EventEmitter<boolean> = new EventEmitter<boolean>();
  usersPassport$: Observable<IUserPassport>;
  usersReferences$: Observable<Array<IFormatEmployer | IFormatGuarantor | IFormatLandlord | IFormatUniversity>>;
  usersReadOnly$: Observable<IFormatCredit>;
  userIdentity$:  Observable<{url: string}|{message: string}>;
  users$: Observable<IUser>;
  vouchReport$: Observable<string>;
  guarantorDetails$: Observable<string>;
  public identityDate;
  countryResidence = COUNTRY_IS03166;
  PROPERTY_MANAGER_IDS = PROPERTY_MANAGER_IDS;
  client_data = environment.client_data;
  toolTip = `${this.client_data.company_name} is in the process of confirming this information`;
  public isCampusKeyTenant = environment.firebaseConfig.projectId === 'client-campus-key';
  public isCampusKey = environment.firebaseConfig.projectId === 'client-campus-key';

  constructor(
    public _auth: AuthService,
    private _passport: BunkPassportService,
    private _user: UserService,
    public _date: DatesService,
    private _references: ReferencesService
  ) {}

  ngOnInit() {
    console.log(this.property, 'hey property');
    if (this.uid) {
      this.userIdentity$ = this._passport.getTenantIdentityUrl(this.uid) as Observable<{url: string}|{message: string}>;
      this.vouchReport$ = this._references.getReferencesByTenantUid(this.uid).valueChanges().pipe(
        map((referenceData:any)=>{
          if(referenceData && referenceData.length){
            const referenceDoc = referenceData.sort(this.sortByDate)[0]['reference_data']['documents']
            const reportObj = referenceDoc && referenceDoc.find(doc => doc.type === "Report");
            return reportObj && reportObj['path'];
          }
        })
      )
      this.guarantorDetails$ = !this.isCampusKey ? this._references.getReferencesByTenantUid(this.uid).valueChanges().pipe(
        map(referenceData =>{
          const findCompletedReference = (referenceDoc:IReferenceDoc) => {
            const statusComplete = referenceDoc.status === 'complete';
            const agreementUrlExist = referenceDoc.guarantor_details && referenceDoc.guarantor_details.agreement_url
            return statusComplete && agreementUrlExist
          }
          const someCompletedReferenceHaveAgreement = referenceData.some(findCompletedReference);
          const completedReferencesWithAgreement = referenceData.filter(findCompletedReference);
          const pendingReferencesWithAgreement = referenceData.filter((referenceDoc:IReferenceDoc) => referenceDoc.status === 'pending_signature' && referenceDoc.guarantor_details && referenceDoc.guarantor_details.agreement_url)

          const data = someCompletedReferenceHaveAgreement ? completedReferencesWithAgreement : pendingReferencesWithAgreement;

          const dataWithGuarantor = data.length ? data.sort(this.sortByDate)[0] : null;
          const reference = dataWithGuarantor && {...dataWithGuarantor.guarantor_details,
                                                  reference_id: dataWithGuarantor.reference_id,
                                                  doc:'references'}
          return reference;
        }
      )) : this._passport.getBunkReferencesByUidAndType(this.uid, 'guarantor').pipe(map(referenceData => {
        const docHaveAgreement = (referenceDoc: BunkPassportReferences) => referenceDoc.status === 'complete' && referenceDoc['agreement_url']
        const someCompletedReferenceHaveAgreement = referenceData.some(docHaveAgreement);
        const completedReferencesWithAgreement = referenceData.filter(docHaveAgreement);
        const pendingReferencesWithAgreement = referenceData.filter(referenceDoc => referenceDoc.status === 'pending_signature' && referenceDoc['agreement_url']);

        const data = someCompletedReferenceHaveAgreement ? completedReferencesWithAgreement : pendingReferencesWithAgreement;

        const dataWithGuarantor = data ? data.sort(this.sortByDate)[0] : null;
        const GuarantorDetails = {
          email: dataWithGuarantor.reference_data.referee_email,
          first_name: dataWithGuarantor.reference_data.referee_first_name,
          last_name: dataWithGuarantor.reference_data.referee_last_name,
          phone: dataWithGuarantor.reference_data.referee_phone,
          agreement_url: dataWithGuarantor.agreement_url,
          doc: 'bunk_passports_references',
          reference_id: dataWithGuarantor.bunk_passport_reference_id
        }
        return GuarantorDetails;
      }))
      this.users$ = this._user.getUserPermissionedById(this.uid).valueChanges().pipe(
        map((user: any) => {
          const userDoc = this.formatUser(user);
          return userDoc;
        })
      );
      this.usersReadOnly$ = this._user.getUserReadOnlyById(this.uid).valueChanges().pipe(
        map((user: IUserReadOnly) => {
          const usersReadOnly = this.formatCredit(user);
          return usersReadOnly;
        })
      );

      this.usersPassport$ = this._passport.getTenantsBunkPassport(this.uid).valueChanges().pipe(
        map((passport: IUserPassport) => {
            if (passport.personal_details) {
              passport.personal_details = this.formatProfile(passport.personal_details);
            }
            if (passport.search_preferences) {
            passport.search_preferences = this.formatSearchPreferences(passport);
          }

          return passport;
        })
      );

      this.usersReferences$ = this.usersPassport$.pipe(
        map((passport: IUserPassport) => {
          let references = [];

          references = references.concat( (passport.guarantor_references) ? passport.guarantor_references : []);
          references = references.concat( (passport.employment_references) ? passport.employment_references : []);
          references = references.concat( (passport.previous_tenancy_references) ? passport.previous_tenancy_references : []);
          references = references.concat( (passport.university_references) ? passport.university_references : []);
          return references.length ? this._passport.getReferencesByIds(references) : of([]) ;
        }),
          flatMap(refsArr => combineLatest([refsArr])),
          map(refs => {
            let flatRefs = _.flatten(refs);
             flatRefs = flatRefs.filter((ref: any) => !ref.is_deleted);

            // Getting each verified reference
            const isCompletedGuarantor = (ref: IPassportReferences) => ref.type === 'guarantor' && ref.status === 'complete' && !ref.is_deleted
            const completedGuarantorExist = flatRefs.some(isCompletedGuarantor);
            const allCompletedGuarantors = flatRefs.filter(isCompletedGuarantor).sort(this.sortByDate);
            const guarantorsWithPendingSignature = flatRefs.filter((ref: IPassportReferences) => ref.type === 'guarantor' && ref.status === 'pending_signature' && !ref.is_deleted).sort(this.sortByDate)

            const guarantorsVerifiedSorted =  completedGuarantorExist ? allCompletedGuarantors : guarantorsWithPendingSignature;

            const guarantorsVerified : IFormatGuarantor[]= guarantorsVerifiedSorted[0] ? [this.formatGuarantorData(guarantorsVerifiedSorted[0])] : [];

            const universityVerified: IFormatUniversity[] = flatRefs.filter((ref: any) => ref.type === 'university' && ref.status === 'complete' && !ref.is_deleted)
              .map((ref: any ) => this.formatUniversityData(ref));

            const landlordVerified: IFormatLandlord[] = flatRefs.filter((ref: any) => ref.type === 'previous_tenancy' && ref.status === 'complete' && ref.reference_data.has_previous_tenancy && !ref.is_deleted)
              .map((ref: any) => this.formatLandlordData(ref));

            const employerVerified: IFormatEmployer[] = flatRefs.filter((ref: any) => ref.type === 'employer' && ref.status === 'complete' && ref.reference_data.employment_status !== 'unemployed' && !ref.is_deleted)
              .map((ref: any) => this.formatEmployerData(ref));

            const universityUnVerified: IFormatUniversity[] = flatRefs.filter((ref: any) => ref.type === 'university' && ref.status !== 'complete' && !ref.is_deleted)
              .map((ref: any ) => this.formatUniversityData(ref));

            const landlordUnVerified: IFormatLandlord[] = flatRefs.filter((ref: any) => ref.type === 'previous_tenancy' && ref.status !== 'complete' && ref.reference_data.has_previous_tenancy && !ref.is_deleted)
              .map((ref: any) => this.formatLandlordData(ref));

            const employerUnVerified: IFormatEmployer[] = flatRefs.filter((ref: any) => ref.type === 'employer' && ref.status !== 'complete' && ref.reference_data.employment_status !== 'unemployed' && !ref.is_deleted)
              .map((ref: any) => this.formatEmployerData(ref));

            // Ordering them with verified first

            const References = [ ...employerVerified, ...guarantorsVerified, ...universityVerified, ...landlordVerified, ...employerUnVerified,
                                          ...landlordUnVerified, ...universityUnVerified];

            return References;

          }),
      );
    }
  }

  sortByDate = (a: BunkPassportReferences, b: BunkPassportReferences) => b.date_modified.seconds - a.date_modified.seconds;

  formatAddress(address: IPropertyAddressDetails) {
    if (!address) {
      return '';
    }
    let address_string = address.first_line_address;
    address_string += address.second_line_address ? `, ${address.second_line_address}` : '';
    address_string += address.city ? `, ${address.city}` : '';
    address_string += address.post_code ? `, ${address.post_code}` : '';
    return address_string;
  }

  formatProfile(details: any) {
    details.dob = details.dob ? moment(details.dob.toDate()) : null;
    return {
      fullName : details.first_name + ' ' + details.last_name,
      phone : details.country_code &&  details.phone ? details.country_code + ' ' + details.phone : null,
      profileImage: details.profile_image_url ? details.profile_image_url : null,
      address: details.address_details ? this.formatAddress(details.address_details) : 'Incomplete',
      dob : details.dob ? details.dob.format('Do MMMM YYYY') : 'Incomplete',
      email: details.email ? details.email : null,
      gender: details.gender? details.gender : null,
      nationality: details.nationality ? details.nationality : null,
      rent_responsibility: details.rent_responsibility ? details.rent_responsibility : null,
      payment_preference: details.payment_preference ? details.payment_preference : null,
      school_name: details.school_name? details.school_name: null,
      referral_source: details.referral_source? details.referral_source: null,
      referral_source_name: details.referral_source_name? details.referral_source_name: null,
      referral_source_phone: details.referral_source_country_code && details.referral_source_phone? details.referral_source_country_code + ' ' + details.referral_source_phone: null,
      native_language: details.native_language? details.native_language: null,
      is_bursary_student: typeof details.is_bursary_student === 'boolean'? details.is_bursary_student: null,
      bursary_name: details.bursary_name? details.bursary_name: null,
      bursary_phone: details.bursary_country_code && details.bursary_phone? details.bursary_country_code + ' ' + details.bursary_phone: null,
      one_word_description: details.one_word_description? details.one_word_description: null,
      has_covid_vaccine: typeof details.has_covid_vaccine === 'boolean'? details.has_covid_vaccine: null
    };
  }

  booleanToYesNoString(value: boolean) {
    return value === true ? 'Yes' : value === false ? 'No' : null;
  }

  formatSearchPreferences(passport: any) {
    const search = passport.search_preferences;

    const searchPreferences = [
      { title: 'Searching With', value: search.searching_with ? search.searching_with.charAt(0).toUpperCase() + search.searching_with.substr(1) : null },
      { title: 'Student', value: this.booleanToYesNoString(search.is_student) },
      { title: 'Smoker', value: this.booleanToYesNoString(search.is_smoker) },
      { title: 'Pets', value: this.booleanToYesNoString(search.has_pets) },
      { title: 'Renting currently', value: this.booleanToYesNoString(search.is_renting) },
      { title: 'Letting provider',
        value: search.is_renting && search.currently_renting_from !== undefined ? RENTINGFROM[search.currently_renting_from] : null },
      { title: 'Tertiary Institution', value: search.institution_location ? search.institution_location : null },
      { title: 'Study year', value: search.study_year ? search.study_year : null },
      { title: 'Current Student? ', value: this.booleanToYesNoString(search.is_student_at_uni) },
    ];

    if (environment.client_data.company_name === 'CampusKey' ) {
      const livingAtCampusKey = { title: 'Living at CampusKey', value: search.years_at_uni ? search.years_at_uni > 1 ? `${search.years_at_uni}  years` : `${search.years_at_uni}  year`: null };
      const friendsAtCampusKey = { title: 'Friends Living at CampusKey', value: this.booleanToYesNoString(search.has_friends_at_uni) };
      searchPreferences.push(livingAtCampusKey);
      searchPreferences.push(friendsAtCampusKey);
    }

    return searchPreferences;
  }

  formatCredit(user: any) {
    const launchDate = this._passport.bunkPassportLaunchDate;
    const identityStep = this._passport.getBunkPassportIdentityStep(user);
    const hasCreditInfo = identityStep && identityStep.date_completed ? moment(identityStep.date_completed.toDate()).isAfter(launchDate) : false;
    return {
      hasCredit: hasCreditInfo,
      verificationTier: user.verification_tier,
      verificationStatus: user.verification_status ? user.verification_status : null,
      tier: user.verification_tier,
      ...(user.bank_details && this._auth.currentUserId > '') && {
        bank_details: {
          ...user.bank_details
        }
      },
      credit: [
        {title: 'Adverse data collected', value: user.verification_status !== 'unverified' ? (user.has_negative_register ? 'Yes' : 'None') : '-'},
        {title: 'Bankruptcies', value: user.verification_status !== 'unverified' ? (user.has_ccj ? 'Yes' : 'No') : '-'},
        {title: 'Linked addresses', value: user.number_linked_addresses ? user.number_linked_addresses.slice(0, 2) : '-'},
        {title: 'Current address electoral role', value: user.verification_status !== 'unverified' ? (user.has_linked_address_electoral ? 'Yes' : 'No') : '-'}
      ]
    }


  }

  formatUniversityData(uni: any) {
    const isDate = uni.reference_data.course_end_year ? uni.reference_data.course_end_year instanceof Date : false;
  return {
    courseEndYear: isDate ? moment(uni.reference_data.course_end_year.toDate()).format('YYYY') : uni.reference_data.course_end_year,
    courseName: uni.reference_data.course_name ? uni.reference_data.course_name.charAt(0).toUpperCase() + uni.reference_data.course_name.substr(1): null,
    uniName: uni.reference_data.university_name ? uni.reference_data.university_name.charAt(0).toUpperCase() + uni.reference_data.university_name.substr(1) : null,
    type: uni.type,
    status: uni.status,
    student_id: uni.reference_data.student_id,
    rental_academic_year: uni.reference_data.rental_academic_year
    };
  }



  formatGuarantorData(guarantor: any) {
    const data = guarantor.reference_data;
    const complete = guarantor.status === 'complete';
    const api = guarantor.custom_fields ? guarantor.custom_fields : null;
    let countryResidence: string;

    data.referee_country_of_residence ? this.countryResidence.map((value: any) => {
      if (value.value === data.referee_country_of_residence ) {
        return countryResidence = value.country;
      }
    }) : countryResidence = null;

    const shared = {
      id: guarantor.bunk_passport_reference_id,
      hasBunkVerified: guarantor.has_bunk_verified,
      name:  guarantor.reference_data.referee_name ? guarantor.reference_data.referee_name.charAt(0).toUpperCase() + guarantor.reference_data.referee_name.substr(1) : null,
      relationship : guarantor.reference_data.relationship ? guarantor.reference_data.relationship.charAt(0).toUpperCase() + guarantor.reference_data.relationship.substr(1) : null,
      type: guarantor.type,
      status: guarantor.status,
      countryOfResidence: countryResidence,
      homeOwner: data.has_declared_uk_homeowner && data.has_declared_uk_homeowner !== null ? 'Yes' : data.has_declared_uk_homeowner === false ? 'No' :  null,
      version: guarantor.version,
      url: guarantor.agreement_url ? guarantor.agreement_url : null,
      phone: data.referee_country_code ? '+ ' + data.referee_country_code + ' ' + data.referee_phone : null,
      email: data.referee_email ? data.referee_email : null,
    };

    if (complete && guarantor.version === 2) {
      const general = {
        ...shared,
        address: data.address_details.first_line_address + ' ' + data.address_details.city,
        dob: data.referee_dob.toDate(),
        employmentStatus: data.employment_status ? data.employment_status.charAt(0).toUpperCase() + data.employment_status.substr(1).replace('_', ' ') : null
      };

      if (data.employment_status === 'employed') {

      return {
        ...general,
        employmentType: data.employment_data.employment_type ? data.employment_data.employment_type.charAt(0).toUpperCase() + data.employment_data.employment_type.substr(1) : null,
        contractLength: data.employment_data.contract_length ? data.employment_data.contract_length : null,
        positionHeld: data.employment_data.position_held.charAt(0).toUpperCase() + data.employment_data.position_held.substr(1),
        salary: this.client_data.currency_symbol+ ' ' + parseInt(data.employment_data.annual_salary, 10).toLocaleString()
      };
    } else {
      return {
        ...general,
        salary: data.employment_status === 'self_employed' ? this.client_data.currency_symbol+ ' ' + parseInt(data.employment_data.annual_salary, 10).toLocaleString() : null,
        bankStatements: guarantor.has_bunk_verified ? 'Verified' : 'Pending verification'
      };
    }

    }

    if (complete && api && guarantor.version === 1) {
      let referee_occupation: string;
      let referee_salary;

      for (const field of api) {
        switch (field.api_key) {
          case 'occupation':
            referee_occupation = field.value;
            break;
          case 'guarantor_salary':
            referee_salary = field.value;
            break;
        default:
          break;
        }
      }

      return {
        ...shared,
        hasBunkVerified : guarantor.has_bunk_verified,
        phone: data.referee_country_code ? '+ ' + data.referee_country_code + ' ' + data.referee_phone : api[7].value,
        email: data.referee_email ? data.referee_email : null,
        address: data.address,
        occupation: referee_occupation,
        salary: this.client_data.currency_symbol+ ' ' + parseInt(referee_salary, 10).toLocaleString(),
        dob: data.dob.toDate()
      };
    }
    return { ...shared };
  }

  formatEmployerData(employer: any) {
    const data = employer.reference_data;
    let workingDuringTenancy = null;
    let passedProbation = null;
    if (employer.status === 'complete' && employer.custom_fields) {
      for (const field of employer.custom_fields) {
        switch (field.api_key) {
          case 'is-working-during-tenancy':
            workingDuringTenancy = field.value;
            break;
          case 'has-passed-probation' || 'has-passed-probabtion':
            passedProbation = field.value;
            break;
          default:
            break;
        }
      }

    }

    return {
      employmentStatus: data.employment_status ? data.employment_status.charAt(0).toUpperCase() + data.employment_status.substr(1).replace('_', '-') : null,
      jobTitle: data.job_title ? data.job_title.charAt(0).toUpperCase() +  data.job_title.substr(1) : null,
      companyName: data.company_name ? data.company_name.charAt(0).toUpperCase() + data.company_name.substr(1) : null ,
      startDate: data.start_date ? data.start_date.toDate() : null,
      salary: data.employee_salary ? this.client_data.currency_symbol+ ' ' + data.employee_salary : null,
      type: employer.type,
      status: employer.status,
      workingDuringTenancy,
      passedProbation
    };
  }

  formatLandlordData(landlord: any) {
    const complete = landlord.status === 'complete';
    const data = landlord.reference_data;
    const api = landlord.custom_fields;
    const shared = {
      tenancyLength: data.tenancy_duration_months ? data.tenancy_duration_months + ' Months' : null,
      rent: data.monthly_rent_paid ? this.client_data.currency_symbol+ ' ' + parseInt(data.monthly_rent_paid, 10).toLocaleString() + ' / Month' : null,
      type: landlord.type,
      status: landlord.status
    };

    if (complete && api) {
      let satisfactory_tenant: string;
      let rent_again: string;

      for (const field of api) {
        switch (field.api_key) {
          case 'satisfactory_correct':
          satisfactory_tenant = field.value;
            break;
          case 'would_rent_again_correct':
          rent_again = field.value;
            break;
        default:
          break;
        }
      }

      return {
        ...shared,
        satisfactoryTenant: satisfactory_tenant,
        rentAgain: rent_again,
      };
    }
    return { ...shared };

  }

  formatUser(user: any) {
    return {
    userEmail: user.profile_data.email,
    userProfileImage: user.profile_data.profile_image_url
    };
  }

  openDocument(url: string) {
    window.open(url, '_blank');
  }

  getGuarantorDocument(referenceId: string, doc:string) {
    this._passport.getGuarantorEsignatureDocument(referenceId,doc)
    .subscribe((res: any) =>
     this.openDocument(res.agreement_url),
      err => {
      console.log('HTTP Error', err);
    }, );
  }

  getYearsAgo(date) {
    return this._date.getYearsAgo(date);
  }

  displayProfile(values :{passport:IUserPassport, user:IUser, usersReadOnly: IFormatCredit} ){
    return !!values.passport && !!values.user && !!values.usersReadOnly
  }
}

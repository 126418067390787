import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SelectTeamComponent } from './select-team.component';
import { ReactiveFormsModule } from '@angular/forms';
import { BunkFormFieldModule } from '@app/shared/_forms/form-field-wrapper/form-field-wrapper.module';
import { MaterialModule } from '@app/shared/material.module';
@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    BunkFormFieldModule,
    MaterialModule
  ],
  declarations: [SelectTeamComponent],
  exports:[SelectTeamComponent]
})
export class SelectTeamModule { }

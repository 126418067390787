import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { isNullOrUndefined } from 'util';
import { map, switchMap } from 'rxjs/operators';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { MatDialog } from '@angular/material/dialog';
import { AvatarPickerComponent } from '@app/shared/_components/avatar-picker/avatar-picker.component';

@Injectable()
export class ProfilePictureGuard implements CanActivate {
  public usersCollection: AngularFirestoreCollection<any>;
  public userDoc: AngularFirestoreDocument<any>;
  user: Observable<any>;

  constructor(
    public afAuth: AngularFireAuth,
    private readonly afs: AngularFirestore,
    public dialog: MatDialog
  ) { }

  openDialog(): Observable<boolean> {
    const dialogRef = this.dialog.open(AvatarPickerComponent, {
      disableClose: true,
      width: '400px',
      closeOnNavigation: true
    });
    return dialogRef.afterClosed().pipe(map(res => res));
  }


  isProfilePictureDefined() {
    return this.afAuth.authState
      .pipe(
        switchMap((auth: any) => {
          this.usersCollection = this.afs.collection<any>('users');
          this.userDoc = this.usersCollection.doc(auth.uid);
          return this.userDoc.valueChanges();
        }),
        map((user: any) => user)
      );
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> {
    return this.isProfilePictureDefined()
      .pipe(
        map(user => {
          if (user && !user.profile_data.profile_image_url && !user.is_phone_verified) {
            this.openDialog();
            return true;
          } else {
            this.dialog.closeAll();
            console.log('Profile picture is set');
            return true;
          }
        })
      );
  }
}

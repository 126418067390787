import { Component, forwardRef, Input, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormGroupDirective, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validator, Validators } from '@angular/forms';
import { FormsService } from '@app/services/form.service';
import { AbstractValueAccessor } from '@app/shared/_components/searches/instant-search/instant-search.utils';
import { flatMap, map } from 'rxjs/operators';
import { of } from 'rxjs/internal/observable/of';
import { tap } from 'rxjs/internal/operators/tap';
import { Observable } from 'rxjs/Observable';
import { BehaviorSubject } from 'rxjs';
import { PAYMENT_STATUS } from '@app/shared/shared.constants';
import { PropertyService, TenancyService } from '@app/services';
import * as moment from 'moment';
import { TenancyModel } from '@app/_models/tenancy.model';
// import { ITenancy } from '@rentbunk/bunk-models';
type ITenancy = any;
@Component({
  selector: 'form-get-tenancy-details',
  template: `
    <form [formGroup]="formGroup">
      <form-get-properties-details [propertyRequired]="propertyRequired" [formControlName]="'property_details'" (tenancyPropertyId)="setIds($event)" [submitAttempted]="submitAttempted"></form-get-properties-details>
    <ng-container *ngIf="tenancies$ | async as tenancies">
      <bunk-form-field [showLabel]="true">
        <label bunk-label>Select a tenancy</label>
        <mat-form-field bunk-input  appearance="outline">
          <mat-select [formControlName]="'tenancy_id'">
            <mat-optgroup *ngFor="let group of tenancies" [label]="group.name"
                          [disabled]="group.disabled">
            <mat-option *ngFor="let tenancy of group.tenancies" [value]="tenancy.value" (click)="selectTenancy(tenancy.tenants)">
              {{tenancy.viewValue}}
            </mat-option>
            </mat-optgroup>
          </mat-select>
        </mat-form-field>
      </bunk-form-field>
    </ng-container>

    <ng-container *ngIf="tenants && tenants.length && !tenantsHidden">
      <bunk-form-field [showLabel]="true">
        <label bunk-label>Which tenant is the payment associated with?</label>
        <mat-form-field bunk-input  appearance="outline">
          <mat-select [formControlName]="'sender_uid'">
            <mat-option *ngFor="let tenant of tenants" [value]="tenant.value" (click)="setUserReferenceCode(tenant.user_reference_code)">
              {{tenant.viewValue}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </bunk-form-field>
    </ng-container>

      <ng-container *ngIf="showReference">
        <bunk-form-field [showLabel]="true">
          <label bunk-label>What reference code do you want to use?</label>
          <mat-form-field bunk-input appearance="outline">
            <input type="text" [formControlName]="'user_reference_code'" matInput>
            <mat-error>Please add a reference code</mat-error>
          </mat-form-field>
        </bunk-form-field>
      </ng-container>
    </form>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormGetTenancyDetailsComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => FormGetTenancyDetailsComponent),
      multi: true
    }
  ]
})

export class FormGetTenancyDetailsComponent extends AbstractValueAccessor implements OnInit, Validator {

  public tenancies$: Observable<any>;
  public propertyID$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  public tenancyPropertyId$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public formGroup: FormGroup;

  public tenants = [];
  public hideSearch = false;
  @Input() showReference = true;
  @Input() showTenants = true;
  @Input() propertyRequired = true;

  @Input() isExpense = false;
  @Input() tenantsHidden = false;
  @Input('submitAttempted') set submitAttempted(boo: boolean) {
    this._submitAttempted = boo;
    if (boo) {
      this.dirtyFormGroup();
    }
  }
  get submitAttempted(): boolean {
    return this._submitAttempted;
  }

  set resetForm(boo: boolean) {
    if (boo) {
      this.removeProperty();
    }
  }

  @Input() get resetForm(): boolean {
    return this._resetForm;
  }

  private _submitAttempted = false;
  private _resetForm = false;
  constructor(
    @Optional() public _reactiveForm: FormGroupDirective,
    private _forms: FormsService,
    private _property: PropertyService,
    private _tenancy: TenancyService,
    private _formBuilder: FormBuilder
  ) {
    super();
  }

  get property_details(): FormControl {
    return this.formGroup.get('property_details') as FormControl;
  }

  get property_id(): FormControl {
    return this.property_details.get('property_id') as FormControl;
  }
  get user_reference_code(): FormControl {
    return this.formGroup.get('user_reference_code') as FormControl;
  }
  get sender_uid(): FormControl {
    return this.formGroup.get('sender_uid') as FormControl;
  }
  get tenancy_id(): FormControl {
    return this.formGroup.get('tenancy_id') as FormControl;
  }
  get bedroom_id(): FormControl {
    return this.property_details.get('bedroom_id') as FormControl;
  }

  ngOnInit() {

    this.tenancies$ = this.tenancyPropertyId$.asObservable().pipe(
      flatMap((ids: { bedroom_id?: string, property_id?: string }) => (ids && ids.bedroom_id)
        ? this._tenancy.getAllTenanciesByBedroomId(ids.bedroom_id)
        : (ids && ids.property_id)
          ? this._tenancy.getAllTenanciesByPropertyId(ids.property_id)
          : of(null)),
      map((tenancies: Partial<ITenancy>[]) => {
        if (tenancies && tenancies.length) {
          return tenancies
            .filter((tenancy: any) => !tenancy.is_deleted)
            .map((tenancy: any) => {
              const startDate = moment(tenancy.tenancy_start.toDate()).format('DD MMM YYYY');
              const endDate = (tenancy.tenancy_end) ? moment(tenancy.tenancy_end.toDate()).format('DD MMM YYYY') : 'Rolling';
              return {
                value: tenancy.tenancy_id,
                viewValue: `${startDate} - ${endDate}`,
                tenants: tenancy.tenants,
                status: tenancy.status
              };
            });
        }
        return {};
      }),
      map((tenancies: any) => ([{
        name: (tenancies.length > 1) ? 'All tenancies' : 'Tenancy',
        tenancies
      }]))
    );

    this.formGroup = this.initFormGroup();
    if (this.isExpense) {
      this.user_reference_code.setValidators([]); // or clearValidators()
      this.user_reference_code.updateValueAndValidity();
    }
  }

  initFormGroup() {
    return this._formBuilder.group({
      property_details: null,
      user_reference_code: ['', Validators.required],
      sender_uid: null,
      tenancy_id: null,
    });
  }


  selectTenancy(tenantsData: any) {
    this.tenants = tenantsData.map((tenant: any) => {
      return {
        viewValue: tenant.full_name,
        value: tenant.tenant_uid,
        user_reference_code: tenant.user_reference_code
      };
    });
  }

  removeProperty() {
    this.tenancyPropertyId$.next(null);
    this.hideSearch = false;
    this.tenants = [];
    this.tenancyPropertyId$.next(null);
    this.hideSearch = false;
    this.setUserReferenceCode();
    this.sender_uid.setValue(null);
    this.property_details.setValue(null);
    this.tenancy_id.setValue(null);
    this.sender_uid.updateValueAndValidity();
    this.property_details.updateValueAndValidity();
    this.tenancy_id.updateValueAndValidity();
    this.tenants = [];
  }

  setIds(e: any) {
    if (e) {
      this.tenancyPropertyId$.next(e);
      return;
    }
    this.removeProperty();
  }

  setUserReferenceCode(code: string = null) {
    this.user_reference_code.setValue(code);
    this.user_reference_code.updateValueAndValidity();
  }

  dirtyFormGroup() {
    if (this.formGroup) {
      const controls = this.formGroup.controls;
      for (const control in controls) {
        if (controls.hasOwnProperty(control)) {
          this.formGroup.controls[control].markAsTouched();
        }
      }
    }
  }

  public onTouched: () => void = () => { };

  writeValue(val: any): void {
    super.writeValue(val);
    if (val) {
      this.formGroup.patchValue(val, { emitEvent: false });
      val.property_details ? this.setIds(val.property_details) : '';
    }
  }
  registerOnChange(fn: any): void {
    this.formGroup.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.formGroup.disable() : this.formGroup.enable();
  }

  validate() {
    return this.formGroup.valid ? null : { invalidForm: { valid: false, message: 'FormInfoComponent > formGroup fields are invalid' } };
  }
}

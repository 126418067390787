import {Injectable} from '@angular/core';
import {from as fromPromise, Observable, from} from 'rxjs';
import {TenantViewingModel} from '@app/core-tenant/property/_models/viewing.model';
import {AuthService} from '@app/core/services/auth.service';
import {AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument} from '@angular/fire/firestore';
import {switchMap } from 'rxjs/operators';
import {UserService} from '@app/services/user.service';
import * as moment from 'moment';
import { DeviceDetectorService } from 'ngx-device-detector';
import { of } from 'rxjs/internal/observable/of';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
@Injectable()
export class ViewingService {
  public viewingsCollection: AngularFirestoreCollection<any>;
  public viewingMetadataCollection: AngularFirestoreCollection<any>;

  constructor(private readonly afs: AngularFirestore,
              private _auth: AuthService,
              private _user: UserService,
              private _deviceService: DeviceDetectorService,
              private _http: HttpClient
              ) {
    this.viewingsCollection = afs.collection<any>('viewings');
    this.viewingMetadataCollection = afs.collection<any>('viewing_metadata');
  }

  getViewingById(id): AngularFirestoreDocument<any> {
    return this.viewingsCollection.doc(id);
  }

  getAllTenantViewings() {
    return this.afs.collection('viewings', ref =>
      ref.where(`tenant_uid`, '==', this._auth.currentUserId)
    );
  }

  getViewings(limit: number = null) {
    const date = new Date();
    const teamQuery = this._user.userReadOnlyDb.team_ids
      ? this.afs.collection('viewings', ref => ref
        .where('team_id', 'in', this._user.userReadOnlyDb.team_ids)
        .where('accepted_viewing_time', '>=', date)
        .orderBy('accepted_viewing_time', 'asc')
        .limit(limit)
      ).valueChanges()
      : of([]);
    const landlordQuery = this.afs.collection('viewings', ref => ref
      .where('landlord_uid', '==', this._auth.currentUserId)
      .where('accepted_viewing_time', '>=', date)
      .orderBy('accepted_viewing_time', 'asc')
      .limit(limit)).valueChanges();
    return this._auth.getCurrentUserCustomClaims().pipe(
      switchMap((res: boolean) => {
        return (res && !this._auth.currentUser.displayName)
          ? this.afs.collection('viewings', ref => ref
          .where('accepted_viewing_time', '>=', date)
          .orderBy('accepted_viewing_time', 'asc')).valueChanges()
          : this._auth.combineQueries(teamQuery, landlordQuery);
      })
    );
  }

  getAllLandlordViewings(): Observable<any> {
    const teamQuery = this._user.userReadOnlyDb.team_ids
      ? this.afs.collection('viewings', ref => ref
        .where('team_id', 'in', this._user.userReadOnlyDb.team_ids)).valueChanges()
      : of([]);

    const landlordQuery = this.afs.collection('viewings', ref => ref
      .where('landlord_uid', '==', this._auth.currentUserId)).valueChanges();

    return this._auth.getCurrentUserCustomClaims().pipe(
      switchMap((res: boolean) => {
        return (res && !this._auth.currentUser.displayName)
          ? this.afs.collection('viewings', ref => ref.where('viewing_id', '>', '')).valueChanges()
          : this._auth.combineQueries(teamQuery, landlordQuery);
      })
    );
  }

  rescheduleViewing(viewing_id: string, receiver_id: string, sender_uid: string, request_index: number, form: any) {
    const date = moment(form.viewing_date).toDate();
    const viewing_date = moment(new Date(date.getFullYear(), date.getMonth(), date.getDate(), form.viewing_time.getHours(), form.viewing_time.getMinutes(), 0));

    const new_request_index = request_index + 1;

    const item = {
      date_modified: new Date(),
      accepted_viewing_time: viewing_date.toDate(),
      status: 'pending',
      requests: {
        [request_index]: {
          status: 'rejected'
        },
        [new_request_index]: {
          index: new_request_index,
          requested_viewing_time: viewing_date.toDate(),
          status: 'pending',
          sender_uid,
          receiver_uid: receiver_id,
          viewing_request_message: form.viewing_request_message,
          date_created: new Date()
        }
      },
      is_read: false,
      last_viewing_request_index: new_request_index,
      last_viewing_request_sender_uid: sender_uid
    };
    return fromPromise(this.updateViewingDoc(item, viewing_id));
  }

  acceptViewing(viewing_id: string, request_index: number, sender_uid: string) {
    const item = {
      date_modified: new Date(),
      status: 'accepted',
      is_read: false,
      requests: {
        [request_index]: {
          status: 'accepted'
        }
      },
      last_viewing_request_sender_uid: sender_uid
    };
    return fromPromise(this.updateViewingDoc(item, viewing_id));
  }

  cancelViewing(viewing_id: string, request_index: number, cancellation_reason: string, sender_uid: string) {
    let cancelled_by;
    (this._user.userDb.role === 'landlord') ? cancelled_by = 'cancelled_by_landlord' : cancelled_by = 'cancelled_by_tenant';
    const item = {
      date_modified: new Date(),
      status: cancelled_by,
      is_read: false,
      requests: {
        [request_index]: {
          status: cancelled_by
        }
      },
      cancellation: {
        cancelled_time: new Date(),
        cancelled_by_uid: sender_uid,
        cancellation_reason: cancellation_reason
      },
      last_viewing_request_sender_uid: sender_uid
    };
    return fromPromise(this.updateViewingDoc(item, viewing_id));
  }

  updateIsRead(viewing_id: string) {
    return fromPromise(this.updateViewingDoc({is_read: true}, viewing_id));
  }

  updateViewingType(viewing_id: string, item: any) {
    return fromPromise(this.updateViewingDoc(item, viewing_id));
  }

  createViewingDocument(property_id: string, landlord_uid: string, form: any, team_id: string = null, viewings_id: string, tenant_uid = null) {
    const date = moment(form.viewing_date).toDate();
    const viewing_date = moment(new Date(date.getFullYear(), date.getMonth(), date.getDate(), form.viewing_time.getHours(), form.viewing_time.getMinutes(), 0));
    const todaysDate = new Date();

    console.log(form, 'hello form');

    const item: TenantViewingModel = {
      viewing_id: viewings_id,
      property_id: property_id,
      landlord_uid: landlord_uid,
      tenant_uid: tenant_uid ? tenant_uid : this._auth.currentUserId,
      date_modified: todaysDate,
      date_created: todaysDate,
      accepted_viewing_time: viewing_date.toDate(),
      team_id,
      status: tenant_uid ? 'accepted' : 'pending',
      type: form.type ? form.type : null,
      creator_uid: this._auth.currentUserId, // This could be a landlord or a tenant but kinda works?
      assignee_uid: landlord_uid,
      has_admin_created:  !!tenant_uid,
      duration_minutes: form.duration_minutes ? form.duration_minutes : null,
      has_expired: false,
      requests: {
        0: {
          index: 0,
          requested_viewing_time: viewing_date.toDate(),
          status: 'pending',
          sender_uid: this._auth.currentUserId,
          receiver_uid: landlord_uid,
          viewing_request_message: form.viewing_request_message ? form.viewing_request_message : null ,
          date_created: todaysDate
        }
      },
      last_viewing_request_index: 0,
      is_read: false,
      last_viewing_request_sender_uid: this._auth.currentUserId
    };
    console.log(item, 'in viewing service');

    return fromPromise(this.updateViewingDoc(item, viewings_id));
  }

  private updateViewingDoc(item, id: string) {
    console.log(item, 'viewing item', id);
    return this.viewingsCollection.doc(id).set(item, {merge: true})
      .catch((err: any) => console.log(err));
  }

  createMetaForViewings(calendar_type: string, viewing_id: string) {
    const metadata_id = this.afs.createId();
    const screen_height = window.screen.height + 'px';
    const screen_width = window.screen.width + 'px';
    console.log(calendar_type, 'calendar type ');
    const item = {
      metadata_id,
      viewing_id,
      date_created: new Date(),
      uid: this._auth.currentUserId,
      type: 'calendar',
      calendar_type,
      user_agent: this._deviceService.getDeviceInfo(),
      screen_height,
      screen_width,
    };
    return from(this.viewingMetadataCollection.doc(metadata_id).set(item));
  }


  getAmountOfViewings(viewings: any): { value: string; viewValue: string; amount: number }[] {
    const allActiveViewingsArray = viewings.filter(viewing => !viewing.has_expired);
    const allViewings = allActiveViewingsArray.reduce((acc, cur) => !cur.has_expired ? ++acc : acc, 0);
    const pendingViewings = allActiveViewingsArray.reduce((acc, cur) => cur.status === 'pending' ? ++acc : acc, 0);
    const acceptedViewings = allActiveViewingsArray.reduce((acc, cur) => cur.status === 'accepted' ? ++acc : acc, 0);
    const cancelledViewings = allActiveViewingsArray.reduce((acc, cur) => cur.status === 'cancelled_by_tenant' || cur.status === 'cancelled_by_landlord'  ? ++acc : acc, 0);
    const expiredViewings = viewings.reduce((acc, cur) => cur.has_expired ? ++acc : acc, 0);

    return [{
      viewValue: 'All viewings',
      value: 'all',
      amount: allViewings
    },
    {
      viewValue: 'Pending viewings',
      value: 'pending',
      amount: pendingViewings
    },
    {
      viewValue: 'Accepted viewings',
      value: 'accepted',
      amount: acceptedViewings
    },
    {
      viewValue: 'Cancelled viewings',
      value: 'cancelled',
      amount: cancelledViewings
    },
    {
      viewValue: 'Expired',
      value: 'expired',
      amount: expiredViewings
    },
  ];
  }

  getAllPropertiesViewings(property_id: string): Observable<any> {
    const teamQuery = this._user.userReadOnlyDb.team_ids
      ? this.afs.collection('viewings', ref => ref
        .where('team_id', 'in', this._user.userReadOnlyDb.team_ids)
        .where('property_id', '==', property_id)).valueChanges()
      : of([]);

    const landlordQuery = this.afs.collection('viewings', ref => ref
      .where('landlord_uid', '==', this._auth.currentUserId)
      .where('property_id', '==', property_id)).valueChanges();

    return this._auth.getCurrentUserCustomClaims().pipe(
      switchMap((res: boolean) => {
        return (res && !this._auth.currentUser.displayName)
          ? this.afs.collection('viewings', ref => ref.where('viewing_id', '>', '').where('property_id', '==', property_id)).valueChanges()
          : this._auth.combineQueries(teamQuery, landlordQuery);
      })
    );
  }

  getViewingsWithExpired(hasExpired: boolean): Observable<any> {
    const teamQuery = this._user.userReadOnlyDb.team_ids
      ? this.afs.collection('viewings', ref => ref
        .where('has_expired', '==', hasExpired)
        .where('team_id', 'in', this._user.userReadOnlyDb.team_ids)
        .orderBy('accepted_viewing_time', 'asc')
        // .limit(20)
        // .startAfter((offset) ? offset : '')
      ).valueChanges()
      : of([]);

    const landlordQuery = this.afs.collection('viewings', ref => ref
      .where('has_expired', '==', hasExpired)
      .where('landlord_uid', '==', this._auth.currentUserId)
      .orderBy('accepted_viewing_time', 'asc')
      // .limit(20)
      // .startAfter((offset) ? offset : '')
    ).valueChanges();

    return this._auth.getCurrentUserCustomClaims().pipe(
      switchMap((res: boolean) => {
        return (res && !this._auth.currentUser.displayName)
          ? this.afs.collection('viewings', ref => ref.where('viewing_id', '>', '')).valueChanges()
          : this._auth.combineQueries(teamQuery, landlordQuery);
      })
    );

  }

  getPaginatedViewings(hasExpired = true)  {
    const first = this.afs.collection('viewings', ref => ref
      .where('has_expired', '==', hasExpired)
      // .where('accepted_viewing_time', '>=', date)
      .where('landlord_uid', '==', this._auth.currentUserId)
      .orderBy('accepted_viewing_time', 'asc')
      .limit(20)
      // .startAfter((offset) ? offset : '')
    );


  }


}

import { Component, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {Auth, Pages, Profile, Shared, Landlord, Tenant, Dashboards} from '@env/routing';
import { AuthService } from '@app/core/services';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { COUNTRY_CODES } from '@app/_constants/forms.constants';
import { Observable, of, combineLatest } from 'rxjs';
import {PropertyService, TenancyService, UserService} from '@app/services';
import { map, switchMap, flatMap } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {environment} from '@env/environment';
import {tap} from 'rxjs/internal/operators/tap';
import { TRANSLATIONS } from '@app/_constants/translations.constants';


@Component({
  selector: 'app-signup',
  templateUrl: `./signup.component.html`,
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent {
  @Output() singupSuccess?: EventEmitter<boolean> = new EventEmitter<boolean>();

  public isDesktop: boolean;
  public countryCodes: (string | number)[];
  public routes = { Auth, Profile, Pages, Shared };
  public propertyId: string;
  public bedroomId: string;
  public uniqueCode: string;
  public tenantName: string;
  public property$: Observable<any>;
  public combined$: Observable<any>;
  public role;
  public formGroup: FormGroup;
  public client_data = environment.client_data;
  public clientCompanyNames = [ 'client-campus-key', 'client-living-way', 'client-nrla'];

  public showSpinner$: BehaviorSubject<boolean> =  new BehaviorSubject<boolean>(false);


  public submittingDataSuccess = false;
  public loading = false;
  public viewingProperty: string;
  public landlord = TRANSLATIONS.landlord;

  constructor(public _auth: AuthService,
    private _formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private _property: PropertyService,
    private _router: Router,
    private _tenancy: TenancyService,
    private _toastr: ToastrService,
              private _user: UserService

   ) {

    this.propertyId = this.route.snapshot.queryParamMap.get('property');
    this.bedroomId = this.route.snapshot.queryParamMap.get('bedroom');
    this.tenantName = this.route.snapshot.queryParamMap.get('name');
    this.viewingProperty = this.route.snapshot.queryParamMap.get('property_id');
    this.uniqueCode = this.route.snapshot.queryParamMap.get('invite');

    this.property$ = this.propertyId ? this._property.getPropertyById(this.propertyId).valueChanges() : of([]);
    this.combined$ = combineLatest([this.property$]).pipe(
      map(([property]) => {
        console.log({property});
        return {property};
      })
    );

    this.formGroup = this.initFormGroup();
    this.client_data.company_name = environment.firebaseConfig.projectId;
  }

  initFormGroup() {
    return this._formBuilder.group({
      user_account: [null]
    });
  }

  setRole(event: any) {
    this.role = event;
  }


  get user_account(): FormControl {
    return this.formGroup.get('user_account') as FormControl;
  }


  emailSignup() {
    if(this.formGroup.invalid){
      return;
    }
      const {...form } = this.formGroup.get('user_account').value;
      const userDoc = {
        ...form,
        phone_number: form.signup_phone_number_with_area.phone_number,
        country_code: form.signup_phone_number_with_area.area_code,
        first_name: form.name.first_name,
        last_name: form.name.last_name
      };

      this.showSpinner$.next(true);
      this.submittingDataSuccess = false;
      this.formGroup.disable();

        this._auth.createUserWithEmailAndPassword({
            email: form.email,
            password: form.password,
          }).pipe(
          flatMap((auth: any) => this._auth.createUserDocuments(auth.user.uid, userDoc)),
          tap((user: any) => console.log(user, 'is this a user??')),
          switchMap(() => {
            if (this.uniqueCode && this._user.userDb) {
              return this._tenancy.joinTenancy(this.uniqueCode, form.email);
            } else {
              return of(null);
            }
          }),
        ).subscribe(
          () => {
            if (!this._user.userDb) {
              return this._router.navigate(['/',  Shared.no_user]);
            }

           if (this.viewingProperty) {
              this.singupSuccess.emit(true);
            }

            if (form.role === 'tenant' && this.uniqueCode) {
              this._router.navigate(['/' + Tenant.base]);
            }

            if (form.role === 'tenant' && !this.uniqueCode) {
              this._router.navigate(['/' + 'rent-property']);
            }

            if (form.role === 'landlord') {
              this._router.navigate(['/' + Dashboards.base]);
            }

            this.showSpinner$.next(false);
            this.submittingDataSuccess = true;

        },
          // Handle Errors
          err => {
            console.log('Error is: ', err);
            this.formGroup.enable();
              if (err.error) {
              this._toastr.error(err.error, 'Oops!', {
                timeOut: 5000
              });
            } else {
              this._toastr.error('Something went wrong.', 'Oops!', {
                timeOut: 5000
              });
            }
          });
    }



}

import { Component, OnInit, forwardRef, Optional, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR, NG_VALIDATORS, Validator, FormGroupDirective, FormGroup, FormControl, Validators } from '@angular/forms';
import { AbstractValueAccessor } from '@app/shared/_components/searches/instant-search/instant-search.utils';
import { FormsService } from '@app/services/form.service';
import { FORM_EMPLOYMENT_DETAILS, EMPLOYMENT_STATUS } from './application-forms.constants';
import { COUNTRY_CODES } from '@app/_constants/forms.constants';
import {environment} from '@env/environment';

@Component({
  selector: 'form-employment-details',
  template: `
  <form [formGroup]="employmentForm" autocomplete="none">
  <bunk-form-field *ngIf="getFormControl('employment_status')">
    <label bunk-label >What is your Employment Status?</label>
    <mat-form-field bunk-input appearance="outline">
      <mat-select formControlName="employment_status" placeholder="Select" (selectionChange)="updateEmploymentValidators($event.value)">
        <mat-option *ngFor="let status of employmentStatus" [value]="status.value">
          {{status.text}}
        </mat-option>
      </mat-select>
    <mat-error>Please select your employment status</mat-error>
    </mat-form-field>
  </bunk-form-field>

  <div *ngIf="getFormControl('employment_status') && getFormControl('employment_status').value !=='unemployed'">

  <bunk-form-field *ngIf="getFormControl('referee_name')">
  <label bunk-label>What is your employers name?</label>
    <mat-form-field bunk-input appearance="outline">
      <input formControlName="referee_name" matInput type="text" name="name" placeholder="E.g David Brown">
      <mat-error>Please enter your referees name</mat-error>
    </mat-form-field>
  </bunk-form-field>

  <bunk-form-field *ngIf="getFormControl('referee_email')">
    <label bunk-label>What is your employers email?</label>
    <mat-form-field bunk-input appearance="outline">
      <input formControlName="referee_email" matInput type="email" name="email" placeholder="E.g david@gmail.com">
      <mat-error>Please enter a valid email address for your employer</mat-error>
    </mat-form-field>
  </bunk-form-field>

  <bunk-form-field *ngIf="getFormControl('referee_phone')">
    <label bunk-label >What is your employers number?</label>
    <div bunk-input>
    <form-input-phone
      bunk-input
      formControlName="referee_phone"
      showLabel="true"
      [submitAttempted]="submitAttempted"
    ></form-input-phone>
    </div>
  </bunk-form-field>

  <bunk-form-field *ngIf="getFormControl('job_title')">
    <label bunk-label >What is your job title?</label>
    <mat-form-field bunk-input appearance="outline">
      <input matInput formControlName="job_title" type="text" placeholder="E.g Customer service assistant">
      <mat-error>Please enter your job title</mat-error>
    </mat-form-field>
  </bunk-form-field>

  <bunk-form-field *ngIf="getFormControl('company_name')">
  <label bunk-label >What is the company name?</label>
    <mat-form-field bunk-input appearance="outline">
      <mat-label>Company Name</mat-label>
      <input matInput formControlName="company_name" type="text">
      <mat-error>Please enter your company name</mat-error>
    </mat-form-field>
  </bunk-form-field>

    <bunk-form-field *ngIf="getFormControl('start_date')">
      <label bunk-label >When did your employment start?</label>
      <div bunk-input>
      <date-selector
        formControlName="start_date"
        [submitAttempted]="submitAttempted"
        >
      </date-selector>
      </div>
    </bunk-form-field>

    <bunk-form-field *ngIf="getFormControl('employee_salary')">
    <label bunk-label >What is your annual salary?</label>
      <mat-form-field bunk-input appearance="outline">
      <input formControlName="employee_salary" matInput bnkSwitchFormatToLocale step="1000" name="salary"
            autocomplete="off">
      <span matPrefix [ngClass]="{'disabled-label' : employmentForm.disabled}">{{client_data.currency_symbol}}&nbsp;</span>
      <mat-error>Please enter your salary at the time</mat-error>
      </mat-form-field>
    </bunk-form-field>

    <bunk-form-field *ngIf="getFormControl('employee_bonus')">
      <label bunk-label >What is your annual bonus optional)?</label>

      <mat-form-field bunk-input appearance="outline">
      <input formControlName="employee_bonus" matInput bnkSwitchFormatToLocale step="100" name="bonus"
            autocomplete="off">
      <span matPrefix [ngClass]="{'disabled-label' : employmentForm.disabled}">{{client_data.currency_symbol}}&nbsp;</span>
      <mat-error>Please enter your bonus at the time</mat-error>
      </mat-form-field>
    </bunk-form-field>

  </div>
  </form>

  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormEmploymentDetailsComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => FormEmploymentDetailsComponent),
      multi: true
    }
  ]
})
export class FormEmploymentDetailsComponent extends AbstractValueAccessor implements OnInit, Validator {
  @Input() requiredFields = FORM_EMPLOYMENT_DETAILS;
  employmentForm: FormGroup;
  public employmentStatus = EMPLOYMENT_STATUS;
  public countryCodes;
  public bunk_reference_id;
  public client_data = environment.client_data;

  private _submitAttempted = false;

  @Input('submitAttempted') set submitAttempted(boo: boolean) {
    this._submitAttempted = boo;
    if (boo) {
      this.dirtyFormGroup();
    }
  }
  get submitAttempted(): boolean {
    return this._submitAttempted;
  }

  constructor(
    @Optional() public _reactiveForm: FormGroupDirective,
    private _forms: FormsService
  ) {
    super();
    this.countryCodes = COUNTRY_CODES.sort();

  }

  dirtyFormGroup() {
    if (this.employmentForm){
    const controls = this.employmentForm.controls;
    for (const control in controls) {
      if (controls.hasOwnProperty(control)) {
        this.employmentForm.controls[control].markAsTouched();
      }
    }
  }
  }

  getFormControl(name: string): FormControl {
    return this.employmentForm.get(name) as FormControl;
  }

  ngOnInit(): void {
    this.employmentForm = this._forms.toFormGroup(this.requiredFields);
    this.employmentForm.addControl('bunk_passport_reference_id', new FormControl(''));

    this._reactiveForm.ngSubmit.subscribe((data: Event) => {
      this.submitAttempted = true;
      const controls = this.employmentForm.controls;
      for (const control in controls) {
        if (controls.hasOwnProperty(control) ) {
          this.employmentForm.controls[control].markAsTouched();
        }
      }
    });
   }


  public onTouched: () => void = () => { };

  writeValue(val: any): void {
    super.writeValue(val);
    if (val) {
      this.employmentForm.patchValue(val, { emitEvent: false });
      if (val.employment_status) {
        this.updateEmploymentValidators(val.employment_status);
      }
      if (val.employee_bonus ) {
        this.getFormControl('employee_bonus').setValidators(null);
        this.getFormControl('employee_bonus').updateValueAndValidity();
      }
      if (val.start_date) {
        this.getFormControl('start_date').updateValueAndValidity();
        this.getFormControl('start_date').markAllAsTouched();
        this.employmentForm.updateValueAndValidity();

      }
    }

  }

  updateEmploymentValidators(selectValue: any) {
    if (selectValue === 'unemployed') {
      for (const field of this.requiredFields ) {
        if ( field !== 'employment_status') {
          this.employmentForm.removeControl(field);
          this.employmentForm.updateValueAndValidity();
        }
      }
    } else {
      for (const field of this.requiredFields ) {
          this.employmentForm.addControl(field, new FormControl(null, Validators.required));
          if (field === 'employee_bonus' ) {
            this.getFormControl(field).setValidators(null);
            this.getFormControl(field).updateValueAndValidity();
          }
          this.getFormControl(field).updateValueAndValidity();
      }
    }


  }

  registerOnChange(fn: any): void {
    this.employmentForm.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.employmentForm.disable() : this.employmentForm.enable();
  }

  validate() {
    return this.employmentForm.valid ? null : { invalidForm: { valid: false, message: 'FormEmploymentDetailsComponent > formGroup fields are invalid' } };
  }

}

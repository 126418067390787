import {Component, OnDestroy, OnInit, EventEmitter, Output} from '@angular/core';
import {FormControl, FormGroup, FormBuilder, Validators} from '@angular/forms';
import {StripeService} from '@app/services/stripe.service';
import {UserService} from '@app/services/user.service';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import { CustomValidators } from '@app/shared/_validators/custom.validators';

const MIN_TENANT_AGE = 18;

@Component({
  selector: 'new-stripe-customer-form',
  template: `
  <form [formGroup]="formGroup" [hidden]="showCardForm" class="margin-top--md">

  <div class="cc">
  <date-selector inputLabel="Date of birth" yearStart="1901" [yearEnd]="minAgeYear" formControlName="dob"
    [parentForm]="formGroup" [parentFormInvalid]="formGroup.invalid">
  </date-selector>  
  </div>

    <mat-form-field appearance="outline" class="w_100">
      <mat-label>Address line one</mat-label>
      <input matInput type="text" formControlName="first_line_address">
      <mat-error>Please enter your address line one</mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline" class="w_100">
      <mat-label>Address line two </mat-label>
      <input matInput type="text" formControlName="second_line_address">

    </mat-form-field>

    <div class="rc">
    <mat-form-field appearance="outline" class="w_50 mr_5">
      <mat-label>Address city</mat-label>
      <input matInput type="text" formControlName="city">
      <mat-error>Please enter your city</mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline" class="w_50">
      <mat-label>Address postcode</mat-label>
      <input matInput type="text" formControlName="post_code">
      <mat-error>Please enter your postcode</mat-error>
    </mat-form-field>
    </div>

    <div class="rc jc_sb">
     <button class="btn__warning" type="button"  (click)="cancel()"> Back </button> 
      <button class="btn__seethrough" type="submit" (click)="submit(formGroup.value)"> Next </button> 
    </div>

    </form>

    <cards-form
      class="margin-top--lg"
      *ngIf="showCardForm"
      (setupComplete)="addCard($event)"
      [disabled]="disableCardsForm"
      [clear]="clearCardsForm"
    ></cards-form> 
    <button class="btn__warning margin-top--lg" type="button" *ngIf="showCardForm" (click)="cancel()"> Back </button>

   
  `,
  // styleUrls: ['../payment-methods.scss']
})
export class NewStripeCustomerFormComponent implements OnInit, OnDestroy {
  @Output() showCustomerForm: EventEmitter<boolean> = new EventEmitter<boolean>();
  private destroy$: Subject<boolean> = new Subject<boolean>();
  private token: any;
  public months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  public years = [];
  public cards_message = 'Please wait while we fetch your card(s) data...';
  public cardsError: string;
  public formGroup: FormGroup;
  public clearCardsForm: Subject<boolean> = new Subject();
  public disableCardsForm: Subject<boolean> = new Subject();
  public cardSubmitted: boolean;
  public showCardForm = false;
  public minAgeYear: number;

  constructor(
    private _pmt: StripeService,
    public _user: UserService,
    public _formBuilder: FormBuilder,
  ) {

    this.formGroup = this.initFormGroup();
    this.minAgeYear = new Date().getFullYear() - MIN_TENANT_AGE;
    this._user.user$
      .pipe(
        takeUntil(this.destroy$)
      )
      .subscribe((user: any) => {
        if (user.verification_data) {
          if (user.verification_data.address_details) {
            this.formGroup.patchValue(user.verification_data.address_details);
          }
          if (user.verification_data.document_data && user.verification_data.document_data.dob) {
            const dob = user.verification_data.document_data.dob.toDate();
            this.dob.patchValue(dob);

          }
      }
      });
  }

  get dob(): FormControl {
    return this.formGroup.get('dob') as FormControl;
  }

  ngOnInit() {
    for (let i = 0; i < 32; i++) {
      this.years.push(2018 + i);
    }
  }


  initFormGroup() {
    return this._formBuilder.group({
      dob: ['', [Validators.required, CustomValidators.ValidDate]],
      second_line_address: [''],
      first_line_address: ['', Validators.required],
      city: ['', Validators.required],
      post_code: ['', Validators.required],
    });
  }

  cancel() {
    this.showCustomerForm.emit(false);
  }

  submit(form: any) {
    if (this.formGroup.valid)  {
      const item = {
        verification_data : {
          address_details : {
            first_line_address : form.first_line_address,
            second_line_address : form.second_line_address,
            city: form.city,
            post_code: form.post_code        
          },
          document_data : {
            dob : form.dob
          }
        }
  
      };
      this._user.userDetailsForStripe(item)
        .pipe(
          takeUntil(this.destroy$))
          .subscribe(
                  res => {
                    this.showCardForm = true;
                  },
                  err => {
                    console.log('Error', err);
                  });
    }       
  }

  addCard(token: any) {
    this.token = token;
    if (this.token) {
      this._pmt.createStripeCustomer(this.token.payment_method).subscribe(
        res => {
          this.cardSubmitted = true;
          this.showCustomerForm.emit(false);
          this.clearCardsForm.next(true);
          this.disableCardsForm.next(false);
        },
        err => {
          console.log('Error', err);
          this.clearCardsForm.next(false);
          this.disableCardsForm.next(false);
        });
    } 
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}

import {Injectable} from '@angular/core';
import {AngularFirestore, AngularFirestoreCollection} from '@angular/fire/firestore';
import { scan, tap, take } from 'rxjs/operators';
import {Observable} from 'rxjs/Observable';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {AuthService} from '@app/core/services';

interface QueryConfig {
  path: string;
  field: string;
  limit?: number;
  reverse?: boolean;
  prepend?: boolean;
}

@Injectable()
export class PaginationService {

  // Source data
  private _done = new BehaviorSubject(false);
  private _loading = new BehaviorSubject(false);
  private _data = new BehaviorSubject([]);

  private query: QueryConfig;

  // Observable data
  public data$: Observable<any>;
  public done$: Observable<boolean> = this._done.asObservable();
  public loading$: Observable<boolean> = this._loading.asObservable();


  constructor(private afs: AngularFirestore,
              private _auth: AuthService) {

  }

  // Initial query sets options and defines the Observable
  // passing opts will override the defaults
  init(path: string, field: string, opts?: any) {
    this.query = {
      path,
      field,
      limit: 2,
      reverse: false,
      prepend: false,
      ...opts
    };
    // return this.afs.collection('notifications', ref =>
    //   ref.where('uid', '==', this._auth.currentUserId)
    //     .where('is_deleted', '==', false)
    //     .orderBy('notification_sent_time', 'desc')
    //
    const first = this.afs.collection(this.query.path, ref => ref
      .where('uid', '==', this._auth.currentUserId)
      .where('is_deleted', '==', false)
      .orderBy(this.query.field, this.query.reverse ? 'desc' : 'asc')
      .limit(this.query.limit));

    this.mapAndUpdate(first);
    console.log('IN INIT FUNCTION');
    this.data$ = this._data.asObservable().pipe(
      scan( (acc, val) => {
        console.log('IN DATA UPDATING!');
        return this.query.prepend ? val.concat(acc) : acc.concat(val)
      })
    );
    // Create the observable array for consumption in components
  }


  // Retrieves additional data from firestore
  more() {
    const cursor = this.getCursor();
    const more = this.afs.collection(this.query.path, ref => ref
      .where('uid', '==', this._auth.currentUserId)
      .where('is_deleted', '==', false)
      .orderBy(this.query.field, this.query.reverse ? 'desc' : 'asc')
      .limit(this.query.limit)
      .startAfter(cursor));

    this.mapAndUpdate(more);
  }


  // Determines the doc snapshot to paginate query
  private getCursor() {
    const current = this._data.value;
    if (current.length) {
      return this.query.prepend ? current[0].doc : current[current.length - 1].doc
    }
    return null;
  }


  // Maps the snapshot to usable format the updates source
  private mapAndUpdate(col: AngularFirestoreCollection<any>) {

    if (this._done.value || this._loading.value) { return };

    // loading
    this._loading.next(true);

    // Map snapshot with doc ref (needed for cursor)
    return col.snapshotChanges().pipe(
      tap((arr: any) => {
        let values = arr.map(snap => {
          const data = snap.payload.doc.data();
          const doc = snap.payload.doc;
          return {...data, doc};
        });
        // If prepending, reverse the batch order
        values = this.query.prepend ? values.reverse() : values;

        // update source with new values, done loading
        this._data.next(values);
        this._loading.next(false);

        // no more values, mark done
        if (!values.length) {
          this._done.next(true);
        }
      }),
      take(1)).subscribe();

  }

}

import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class IntercomService {

  constructor() { }

  initIntercom(user?: any): void {
    if (user && user.profile_data) {
      Intercom('boot', {
        app_id: 'ci0n4qi6',
        name: (user) ? user.profile_data.first_name : 'User',
        role: (user) ? user.role : null,
        email: (user) ? user.profile_data.email : null,
        phone: (user) ? user.profile_data.phone : null,
        UserId: (user) ? user.uid : null,
        hide_default_launcher: true
      });
      Intercom('update');
    }
  }

  startNewChat(): void {
    Intercom('showNewMessage');
  }

  updateIntercom(config: any): void {
    Intercom('update', config);
  }
}

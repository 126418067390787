import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TitleCasePipe } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
// import { PropertyModel } from '@app/core-landlord/property/models/property.model';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { PropertyService, UserService } from '@app/services';
import { AuthService } from '@app/core/services';
import { flatMap, map, switchMap, take, tap } from 'rxjs/operators';
import { Landlord, Shared, Tenant } from '@env/routing';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { environment } from '@env/environment';
import { TRANSLATIONS } from '@app/_constants/translations.constants';
import { IMedia } from '@rentbunk/bunk-models';
import { MediaService } from '@app/services/media.service';
import { PropertyType } from '@app/_constants/property_docs.constants';


@Component({
  selector: 'property-details',
  templateUrl: './property-details.component.html',
  styleUrls: ['./property-details.component.scss']
})
export class PropertyDetailsComponent implements OnInit {
  @Input() property: any;
  @Input() isPreview = false;
  public display: any = {};
  public landlordPublic$: Observable<any>;
  public furnished = [];
  public amenities = [];
  public minimumDuration: string;
  public showSignUpForm = false;
  @Output() closeOverlay: EventEmitter<any> = new EventEmitter();
  public formGroup: FormGroup;
  public showSpinner$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public dref;
  public client_data = environment.client_data;
  public isCampusKey = environment.firebaseConfig.projectId === 'client-campus-key';
  public TRANSLATIONS = TRANSLATIONS;
  public rent_label_abbreviation = TRANSLATIONS.rent_label_abbreviation;
  public isCampusKeyTenant = environment.firebaseConfig.projectId === 'client-campus-key' && this._auth.authenticated && this._user.userDb && this._user.userDb.role === 'tenant';
  public landlord = TRANSLATIONS.landlord;


  constructor(
    public _titlecasePipe: TitleCasePipe,
    public _user: UserService,
    public _auth: AuthService,
    private _property: PropertyService,
    public _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _formBuilder: FormBuilder,
    private _toastr: ToastrService,
    private _media: MediaService
  ) { }


  ngOnInit(): void {
    this.formGroup = this.initFormGroup();

    if (!this._auth.currentUserId || this._auth.currentUserId !== this.property.landlord_uid) {
      this._property.createPropertyMetaData(this.property.property_id, this.property.is_hmo);
    }
    this.landlordPublic$ = this.property.team_id && this.property.team_id ? this._user.getTeamPublicById(this.property.team_id).pipe(
      map((team: any) => {
        let manager = null;
        if (team && team.team_data) {
          const image_small_url = team.team_data.brand_assets && team.team_data.brand_assets.banner_aside && team.team_data.brand_assets.banner_aside.image_small_url;
          const image_large_url = team.team_data.brand_assets && team.team_data.brand_assets.banner_aside && team.team_data.brand_assets.banner_aside.image_large_url;

          manager = {
            companyName: team.team_data.company_name,
            companyNameShort: team.team_data.company_name_short,
            contactNumber: team.team_data.contact_number,
            contactEmail: team.team_data.contact_email,
            bannerAsideSm: image_small_url,
            bannerAsideLg: image_large_url,
            profileImgUrl: team.team_data.company_logo
          };
        }
        return {
          firstName: manager && manager.companyName ? manager.companyName : null,
          companyName: manager && manager.companyName ? manager.companyName : null,
          profileImgUrl: manager && manager.profileImgUrl ? manager.profileImgUrl : null,
          uid: this.property.landlord_uid ? this.property.landlord_uid : null,
          manager
        };
      })) :
      this._user.getUserById(this.property.landlord_uid).valueChanges().pipe(
        map(landlordPublic => {
          let manager = null;
          if (landlordPublic.property_manager_data) {
            const { image_small_url, image_large_url } = landlordPublic.property_manager_data.brand_assets.banner_aside;
            manager = {
              companyName: landlordPublic.property_manager_data.company_name,
              companyNameShort: landlordPublic.property_manager_data.company_name_short,
              contactNumber: landlordPublic.property_manager_data.contact_number,
              bio: landlordPublic.property_manager_data.bio,
              bannerAsideSm: image_small_url,
              bannerAsideLg: image_large_url
            };
          }
          return {
            firstName: landlordPublic.profile_data.first_name,
            profileImgUrl: landlordPublic.profile_data.profile_image_url,
            uid: landlordPublic.uid,
            manager
          };
        })
      );

    const city = this.property.address && this.property.address.city ? this._titlecasePipe.transform(this.property.address.city) : '';
    const bedsArray = _.values(this.property.bedrooms);
    const totalRoomsUnAvailable = this.property.is_hmo && this.property.bedrooms && bedsArray.filter(b => !b.is_listed).length ? bedsArray.filter(b => !b.is_listed).length : null;

    const totalRoomsAvailable = this.property.is_hmo && bedsArray.length - totalRoomsUnAvailable ? bedsArray.length - totalRoomsUnAvailable : null;
    const totalRoomsUnderOffer = this.property.is_hmo && bedsArray.filter(bed => bed.is_listed && bed.is_under_offer).length ? bedsArray.filter(bed => bed.is_listed && bed.is_under_offer).length : null;

    // pickBy to find the amenities that are true
    const amenities = this.property.default_amenities ? _.keys(_.pickBy(this.property.default_amenities)).map((am) => {
      let amString = am.replace('_', '-');
      amString = amString.charAt(0).toUpperCase() + amString.substr(1);
      return amString;
    }) : [];

    const customAmenities = this.property.custom_amenities ? this.property.custom_amenities.map((am) => {
      let amString = am.replace('_', '-');
      amString = amString.charAt(0).toUpperCase() + amString.substr(1);
      return amString;
    }) : [];

    if (!this.property.is_hmo && !this.property.is_mid_tenants) {
      this.minimumDuration = this.property.minimum_duration ? this.property.minimum_duration.replace('-', ' ') : null;
    }
    if (!(this.property.media_ids && this.property.media_ids.length)) {

      const propertyDocuments = this.property.property_documents ? this.property.property_documents.filter(doc => {
        return (doc.image_url && (doc.name === 'EPC' || doc.name === 'GAS' || doc.name === 'Floorplan' || doc.name === 'EICR' || doc.name === 'Fire Safety'))
      }) : [];
      this.display = {
        propertyId: this.property.property_id,
        midTenants: this.property.is_mid_tenants,

        dateAvailable: this.property.date_available ? (this.property.is_hmo || !this.property.is_hmo && this.property.is_mid_tenants ? undefined : this.getMomentDate(this.property.date_available || this.property.date_avaible)) : '',
        deposit: this.property.is_hmo ? undefined : this.property.deposit_amount,
        minimumDuration: this.property.is_hmo ? undefined : this.minimumDuration,
        furnishing: this.furnished,
        hasServiceFee: this.property.has_service_fee,
        hasHoldingAmount: this.property.has_holding_deposit,
        serviceFeeAmount: this.property.service_fee_amount,
        holdingDepositAmount: this.property.holding_deposit_amount,
        dateListed: this.property.date_listed ? moment(this.property.date_listed.toDate()).fromNow() : '',
        landlordUid: this.property.landlord_uid,
        favourite: false, // TODO: Liked props
        rentPCM: this.property.household_rent_pcm !== undefined ? parseInt(this.property.household_rent_pcm, 10).toLocaleString() : undefined,
        rentInterval: !this.property.is_hmo ? this.property.rent_interval_count : null,
        rentQtr: !this.property.is_hmo && this.property.rent_qtr ? this.property.rent_qtr : null,
        rentWeekly: !this.property.is_hmo && this.property.rent_weekly ? this.property.rent_weekly : null,
        roomRentPCM: !this.property.household_rent_pcm && bedsArray.length ? parseInt(bedsArray.sort((a, b) => (a.listed_rent_pcm ? a.listed_rent_pcm : null) - (b.listed_rent_pcm ? b.listed_rent_pcm : null))[0].listed_rent_pcm, 10).toLocaleString() : undefined,
        isHMO: this.property.is_hmo,
        isMarketingListing: this.property.is_marketing_listing,
        isDeleted: this.property.is_deleted,
        isListed: this.property.is_listed,
        isUnderOffer: this.property.is_under_offer,
        totalBedrooms: this.property.number_bedrooms,
        totalBathrooms: this.property.number_bathrooms,
        totalRoomsAvailable,
        totalRoomsUnderOffer,
        propertyType: this.property.property_type ? (this.property.number_bedrooms ?
          this.property.number_bedrooms + ' bedroom ' + PropertyType[this.property.property_type].toLowerCase()
          : PropertyType[this.property.property_type]) : null,
        isHouseShare: this.property.number_bedrooms ?
          this.property.number_bedrooms + ((this.property.number_bedrooms > 1) ? ' bedrooms ' : ' bedroom ') + ' in house share' : 'house share',
        address: this.property.address && this.property.address.thoroughfare ?
          `${this.property.address.thoroughfare}, ${city}, ${this.property.address.post_code.split(' ')[0]}` : `${city} , ${this.property.address && this.property.address.post_code ? this.property.address.post_code.split(' ')[0] : ''}`,
        thoroughfare: this.property.address && this.property.address.thoroughfare ? this.property.address.thoroughfare : null,
        description: this.property.description,
        amenities: [...amenities, ...customAmenities],
        propertyPhotos: this.property.property_photos ? this.property.property_photos : [],
        propertyDocuments: propertyDocuments.length ? propertyDocuments : null,
        billsIncluded: this.property.bills ? Object.keys(this.property.bills) : null,
        justPropertyType: this.property.property_type ? this.property.property_type : null,
        listingTitle: this.property.property_title.length ? this.property.property_title : null,
        tenancyPackageIds: this.property.tenancy_package_ids ? this.property.tenancy_package_ids : null,
        virtualTourUrl: this.property.virtual_tour_url ? this.property.virtual_tour_url : null,
      };
      this.ifFurnishing(this.display.amenities);
      this.display.amenities = this.amenities;
    } else {

      this._media.getMediaFromIds(this.property.media_ids).pipe(map((medias: IMedia[]) => {
        return medias.filter(media => media.type === 'property_documents');
      }), tap(
        (medias: IMedia[]) => {
          const defaultmedias = medias.length ? medias.filter(doc => {
            return (doc.url && (doc.view_name === 'EPC' || doc.view_name === 'GAS' || doc.view_name === 'Floorplan' || doc.view_name === 'EICR' || doc.view_name === 'Fire Safety'))
          }) : [];
          const propertyDocuments = defaultmedias.map(doc => {
            return {
              name: doc.view_name || null,
              expiry_date: doc.date_expires ? new Date(doc.date_expires['seconds'] * 1000) : null,
              ...doc.epc_rating && { epc_rating: doc.epc_rating },
              image_url: doc.url || null
            }
          })
          this.display = {
            propertyId: this.property.property_id,
            midTenants: this.property.is_mid_tenants,

            dateAvailable: this.property.date_available ? (this.property.is_hmo || !this.property.is_hmo && this.property.is_mid_tenants ? undefined : this.getMomentDate(this.property.date_available || this.property.date_avaible)) : '',
            deposit: this.property.is_hmo ? undefined : this.property.deposit_amount,
            minimumDuration: this.property.is_hmo ? undefined : this.minimumDuration,
            furnishing: this.furnished,
            hasServiceFee: this.property.has_service_fee,
            hasHoldingAmount: this.property.has_holding_deposit,
            serviceFeeAmount: this.property.service_fee_amount,
            holdingDepositAmount: this.property.holding_deposit_amount,
            dateListed: this.property.date_listed ? moment(this.property.date_listed.toDate()).fromNow() : '',
            landlordUid: this.property.landlord_uid,
            favourite: false, // TODO: Liked props
            rentPCM: this.property.household_rent_pcm !== undefined ? parseInt(this.property.household_rent_pcm, 10).toLocaleString() : undefined,
            rentInterval: !this.property.is_hmo ? this.property.rent_interval_count : null,
            rentQtr: !this.property.is_hmo && this.property.rent_qtr ? this.property.rent_qtr : null,
            rentWeekly: !this.property.is_hmo && this.property.rent_weekly ? this.property.rent_weekly : null,
            roomRentPCM: !this.property.household_rent_pcm && bedsArray.length ? parseInt(bedsArray.sort((a, b) => (a.listed_rent_pcm ? a.listed_rent_pcm : null) - (b.listed_rent_pcm ? b.listed_rent_pcm : null))[0].listed_rent_pcm, 10).toLocaleString() : undefined,
            isHMO: this.property.is_hmo,
            isMarketingListing: this.property.is_marketing_listing,
            isDeleted: this.property.is_deleted,
            isListed: this.property.is_listed,
            isUnderOffer: this.property.is_under_offer,
            totalBedrooms: this.property.number_bedrooms,
            totalBathrooms: this.property.number_bathrooms,
            totalRoomsAvailable,
            totalRoomsUnderOffer,
            propertyType: this.property.property_type ? (this.property.number_bedrooms ?
              this.property.number_bedrooms + ' bedroom ' + PropertyType[this.property.property_type].toLowerCase()
              : PropertyType[this.property.property_type]) : null,
            isHouseShare: this.property.number_bedrooms ?
              this.property.number_bedrooms + ((this.property.number_bedrooms > 1) ? ' bedrooms ' : ' bedroom ') + ' in house share' : 'house share',
            address: this.property.address && this.property.address.thoroughfare ?
              `${this.property.address.thoroughfare}, ${city}, ${this.property.address.post_code.split(' ')[0]}` : `${city} , ${this.property.address && this.property.address.post_code ? this.property.address.post_code.split(' ')[0] : ''}`,
            thoroughfare: this.property.address && this.property.address.thoroughfare ? this.property.address.thoroughfare : null,
            description: this.property.description,
            amenities: [...amenities, ...customAmenities],
            propertyPhotos: this.property.property_photos ? this.property.property_photos : [],
            propertyDocuments: propertyDocuments.length ? propertyDocuments : null,
            billsIncluded: this.property.bills ? Object.keys(this.property.bills) : null,
            justPropertyType: this.property.property_type ? this.property.property_type : null,
            listingTitle: this.property.property_title.length ? this.property.property_title : null,
            tenancyPackageIds: this.property.tenancy_package_ids ? this.property.tenancy_package_ids : null
          };
          this.ifFurnishing(this.display.amenities);
          this.display.amenities = this.amenities;
        }
      ), take(1)).subscribe();
    }

    if (this.display.propertyPhotos.length && this.display.virtualTourUrl) {
      this.display.propertyPhotos
        .splice(1, 0, { virtualTourUrl: this.display.virtualTourUrl });
    }
  }

  openVideoPreview(url: string): void {
    window.open(url, '_blank').focus();
  }

  isCertificatePDF = (url: string): boolean => {
    return (url.toString().toLowerCase().indexOf('.pdf') > 0);
  };

  ifFurnishing(array: Array<string>) {
    for (const word of array) {
      if (word.includes('furnished')) {
        this.furnished.push(word);
      } else {
        this.amenities.push(word);
      }
    }
    return this.furnished;
  }

  getMomentDate(date: any) {
    return moment(date.toDate()).format('DD/MM/YYYY');
  }

  checkForBills() {
    const bills = [];
    for (const bill of Object.keys(this.property.bills ? this.property.bills : {})) {
      if (this.property.bills[bill]) {
        bills.push(bill);
      }
    }
    return !!(bills.length);
  }

  // Anonymous users...
  handleAnonymousOffer(): void {
    this.showSignUpForm = false;
    this._router.navigate(['.'], { queryParams: { property_id: this.property.property_id }, relativeTo: this._activatedRoute, replaceUrl: true });
  }


  initFormGroup() {
    return this._formBuilder.group({
      user_account: [null]
    });
  }


  emailSignup() {
    if (this.formGroup.valid) {

      const form = this.formGroup.get('user_account').value;
      this.showSpinner$.next(true);
      this.formGroup.disable();


      const userDoc = {
        ...form,
        phone_number: form.signup_phone_number_with_area.phone_number,
        country_code: form.signup_phone_number_with_area.area_code,
        first_name: form.name.first_name,
        last_name: form.name.last_name
      };


      this._auth.createUserWithEmailAndPassword({
        email: form.email,
        password: form.password,
      }).pipe(
        flatMap((auth: any) => this._auth.createUserDocuments(auth.user.uid, userDoc)),
      ).subscribe(
        () => {
          if (!this._user.userDb) {
            return this._router.navigate(['/', Shared.no_user]);
          }
          // nagivate to message a Landlord
          //   this._router.navigate(['/', Tenant.base]);
          this.dref.close();
          this.showSpinner$.next(false);

          // this.loading = false;
          // this.submittingDataSuccess = true;
        },
        // Handle Errors
        err => {
          console.log('Error is: ', err);
          this.formGroup.enable();
          if (err.message) {
            this._toastr.error(err.message, 'Oops!', {
              timeOut: 5000
            });
          } else {
            this._toastr.error('Something went wrong.', 'Oops!', {
              timeOut: 5000
            });
          }
        }
      );
    }
  }

}

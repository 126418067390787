import {Injectable} from '@angular/core';

import {AuthService} from '@app/core/services/auth.service';
import {PropertyService} from '@app/services/property.service';
import {UserService} from '@app/services/user.service';
import {HttpClient} from '@angular/common/http';
import {environment} from '@env/environment';
import {GroupService} from '@app/services/group.service';
import {AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument} from '@angular/fire/firestore';
import {FirebaseApp} from '@angular/fire';
import {HelpersService} from '@app/shared/_services/helpers.service';

import * as firebase from 'firebase/app';
import * as moment from 'moment';

import {from as fromPromise, from} from 'rxjs';
import {flatMap, map, switchMap} from 'rxjs/operators';
import { Observable } from 'rxjs/internal/Observable';
import { DatesService } from '@app/shared/_services/dates.service';
import { of } from 'rxjs/internal/observable/of';
import { MathService } from './math.service';

@Injectable()
export class TenancyService {
  public tenancyCollection: AngularFirestoreCollection<any>;
  public rentExtensionsCollection: AngularFirestoreCollection<any>;

  constructor(
    private readonly afs: AngularFirestore,
    private fb: FirebaseApp,
    private _http: HttpClient,
    private _property: PropertyService,
    private _group: GroupService,
    private _auth: AuthService,
    private _user: UserService,
    private _helpers: HelpersService,
    private _date: DatesService,
    private _math: MathService
  ) {
    this.tenancyCollection = afs.collection<any>('tenancies');
    this.rentExtensionsCollection = afs.collection<any>('rentExtensions');
  }

  createTenancyDocument(property: any, bedrooms: Array<any>) {
    const batch = this.afs.firestore.batch();
    bedrooms.forEach((bedroom: any) => {
      const tenancy_id = this.afs.createId();
      const propertyRef = this._property.propertiesCollection.doc(
        property.property_id
      ).ref;
      // const groupRef = this._group.groupsCollection.doc(property.property_id).ref;
      const tenancyRef = this.tenancyCollection.doc(tenancy_id).ref;

      bedroom.mid_tenants.forEach((tenant) => {
        // For each Tenant create tenant-account-invite
        const tenancy_invite_id = this.afs.createId();
        const tenancyInviteesCollectionRef = this.tenancyCollection
          .doc(tenancy_id)
          .collection('invitees')
          .doc(tenancy_invite_id).ref;

        batch.set(tenancyInviteesCollectionRef, {
          id: tenancy_invite_id,
          landlord_uid: property.landlord_uid,
          token: tenancy_id,
          email: tenant.email,
          first_name: tenant.first_name,
          currency: tenant.currency,
          rent_amount: tenant.rent_amount,
          bed_type: tenant.bed_type,
          has_ensuite: tenant.has_ensuite
        });
      });

      // Create Tenancy
      const tenancy = {
        status: 'pending_landlord_decision',
        tenancy_id: tenancy_id,
        property_id: property.property_id,
        bedroom_id: bedroom.bedroom_id,
        landlord_uid: property.landlord_uid,
        tenancy_start: bedroom.tenancy_start,
        tenancy_end: bedroom.tenancy_end,
        rent_due_day: bedroom.rent_due_day,
        mid_tenant_invitees: bedroom.mid_tenants
      };

      // Create Property Chat Group
      // const chat_group: ChatGroupModel = {
      //   group_id: property.property_id,
      //   property_id: property.property_id,
      //   creator_uid: property.landlord_uid,
      //   tenancy_id: tenancy_id,
      //   members_uid: {[property.landlord_uid]: 'ACCEPTED'},
      //   group_name: `${property.address.post_code} ${property.address.first_line_address} ${property.address.second_line_address}`,
      //   date_created: firestore.FieldValue.serverTimestamp(),
      //   group_icon_url: this._user.userDb.profile_data.profile_image_url,
      //   is_renting: false,
      //   last_message: {index: 0, message_body: null, sender_name: null, sender_first_name: null, message_time: null},
      //   last_read_index: {[property.landlord_uid]: 0},
      //   user_notified: {[property.landlord_uid]: false}
      // };

      // batch.set(groupRef, chat_group);
      batch.set(tenancyRef, tenancy);
      batch.set(
        propertyRef,
        {
          is_listed: true,
          date_listed: this._helpers.serverTimestamp()
        },
        {merge: true}
      );

      console.log('Property LISTED:', property.property_id);
    });
    console.log('Yuppy! Your property has been listed!');
    return from(batch.commit());
  }

  getIdCurrentTenancy() {
    const user = this._user.userDb;
    return (user.user_defaults && user.user_defaults.tenancy && user.user_defaults.tenancy.selected_tenancy_id)
      ? user.user_defaults.tenancy.selected_tenancy_id
      : null;
  }

  getUserTenancyById(id): AngularFirestoreDocument<any> {
    return this.tenancyCollection.doc(id);
  }

  getAllTenanciesByPropertyId(property_id?: string): Observable<any> {
    const teamQuery = this._user.userReadOnlyDb.team_ids
      ? this.afs.collection('tenancies', ref => ref
        .where('team_id', 'in', this._user.userReadOnlyDb.team_ids)
        .where('property_id', '==', property_id)
        .orderBy('tenancy_start', 'desc')).valueChanges()
      : of([]);

    const landlordQuery = this.afs.collection('tenancies', ref => ref
      .where('landlord_uid', '==', this._auth.currentUserId)
      .where('property_id', '==', property_id)
      .orderBy('tenancy_start', 'desc')).valueChanges();

    return this._auth.getCurrentUserCustomClaims().pipe(
      switchMap((res: boolean) => {
        return (res && !this._auth.currentUser.displayName)
          ? this.afs.collection('tenancies', ref => ref.where('property_id', '==', property_id).orderBy('tenancy_start', 'desc')).valueChanges()
          : this._auth.combineQueries(teamQuery, landlordQuery);
      })
      );
    }

  getTenanciesByTenantId(): Observable<any> {
    return this.afs.collection('tenancies', (ref) =>
      ref
        .where(`tenant_uids`, 'array-contains', this._auth.currentUserId)
        .where(`status`, 'in', ['active', 'upcoming', 'archived'])
        .orderBy('tenancy_start', 'desc')
    ).valueChanges();
  }



  getAllTenanciesForPropertyByStatus(property_id: string, status: string): Observable<any>{
    return this._auth.getCurrentUserCustomClaims().pipe(
      switchMap((res: boolean) => {
        return (res && !this._auth.currentUser.displayName)
          ? this.afs.collection('tenancies', ref => ref.where('property_id', '==', property_id).where('status', '==', status).orderBy('tenancy_start', 'desc')).valueChanges()
          : this.afs.collection('tenancies', ref => ref.where('property_id', '==', property_id)
          .where('landlord_uid', '==', this._auth.currentUserId ).where('status', '==', status).orderBy('tenancy_start', 'desc')).valueChanges();
      })
    );
  }

  getCurrentRentExtension(payment_id: string): AngularFirestoreCollection<any> {
    return this.afs.collection('rentExtensions', (ref) =>
      ref
        .where(`payment_id`, '==', payment_id)
        .where(`status`, '==', 'PENDING')
        .where('sender_id', '==', this._auth.currentUserId)
    );
  }


  getAllTenanciesByLandlordId(): Observable<any> {
    const teamQuery = this._user.userReadOnlyDb.team_ids
      ? this.afs.collection('tenancies', ref => ref
        .where('team_id', 'in', this._user.userReadOnlyDb.team_ids)).valueChanges()
      : of([]);

    const landlordQuery = this.afs.collection('tenancies', ref => ref
      .where('landlord_uid', '==', this._auth.currentUserId)).valueChanges();

    return this._auth.getCurrentUserCustomClaims().pipe(
      switchMap((res: boolean) => {
        return (res && !this._auth.currentUser.displayName)
          ? this.afs.collection('tenancies', ref => ref.where('landlord_uid', '>', '')).valueChanges()
          : this._auth.combineQueries(teamQuery, landlordQuery);
      })
    );
  }

  getAllLandlordRentPaymentsByTenancyId(tenancy_id: string) {
    return this.afs.collection('payments', (ref) =>
      ref
        .where('receiver_uid', '==', this._auth.currentUserId)
        .where('tenancy_id', '==', tenancy_id)
        .where('payment_type', '==', 'rent')
        .orderBy('year', 'desc')
        .orderBy('month', 'desc')
    );
  }

  getAllLandlordPaymentsByTenancyId(tenancy_id: string) {
    const teamQuery = this._user.userReadOnlyDb.team_ids
      ? this.afs.collection('payments', ref => ref
        .where('team_id', 'in', this._user.userReadOnlyDb.team_ids)
        .where('tenancy_id', '==', tenancy_id)
        .orderBy('year', 'desc')
        .orderBy('month', 'desc')).valueChanges()
      : of([]);

    const landlordQuery = this.afs.collection('payments', ref => ref
      .where('receiver_uid', '==', this._auth.currentUserId)
      .where('tenancy_id', '==', tenancy_id)
      .orderBy('year', 'desc')
      .orderBy('month', 'desc')).valueChanges();

    return this._auth.combineQueries(teamQuery, landlordQuery);
  }

  getAllTenanciesByBedroomId(bedroom_id: string) {
    const teamQuery = this._user.userReadOnlyDb.team_ids
      ? this.afs.collection('tenancies', ref => ref
        .where('team_id', 'in', this._user.userReadOnlyDb.team_ids)
        .where('bedroom_id', '==', bedroom_id)
        .orderBy('tenancy_start', 'desc')).valueChanges()
      : of([]);

    const landlordQuery = this.afs.collection('tenancies', ref => ref
      .where('landlord_uid', '==', this._auth.currentUserId)
      .where('bedroom_id', '==', bedroom_id)
      .orderBy('tenancy_start', 'desc')).valueChanges();

    return this._auth.combineQueries(teamQuery, landlordQuery);
  }

  getAllPaymentsByTenantId(): AngularFirestoreCollection<any> {
    return this.afs.collection('payments', (ref) =>
      ref.where(`sender_uid`, '==', this._auth.currentUserId)
    );
  }

  getAllCurrentMonthPaymentsByTenantId(): AngularFirestoreCollection<any> {
    return this.afs.collection('payments', (ref) =>
      ref.where(`receiver_uid`, '==', this._auth.currentUserId)
        .where(`payment_type`, '==', 'rent')
    );
  }

  getAllLandlordPaymentsByStatus(status: string): Observable<any> {
    return this._auth.getCurrentUserCustomClaims().pipe(
      switchMap((res: boolean) => {
        return (res && !this._auth.currentUser.displayName)
          ? this.afs.collection('payments', ref => ref.where('payment_type', '==', 'rent').where('status', '==', status)
            .orderBy('month', 'desc')).valueChanges()
          : this.afs.collection('payments', (ref) => ref.where(`receiver_uid`, '==', this._auth.currentUserId).where('payment_type', '==', 'rent')
            .where('status', '==', status)
            .orderBy('month', 'desc')).valueChanges();
      })
    );

  }

  getAllCurrentMonthPaidPayments(month): AngularFirestoreCollection<any> {
    return this.afs.collection('payments-overview', (ref) =>
      ref
        .where(`landlord_id`, '==', this._auth.currentUserId)
        .where('month', '==', month)
        .where('status', '==', 'paid')
    );
  }

  getTenancyById(tenancy_id: string) {
    return this.tenancyCollection.doc(tenancy_id);
  }


  getMaintenanceRequestByTenancyId(
    tenancy_id: string
  ): AngularFirestoreCollection<any> {
    return this.afs.collection('maintenance', (ref) =>
      ref.where(`tenancy_id`, '==', tenancy_id)
    );
  }

  getIncompleteMaintenanceRequestByTenancyId(
    tenancy_id: string
  ): AngularFirestoreCollection<any> {
    return this.afs.collection('maintenance', (ref) =>
      ref
        .where(`tenancy_id`, '==', tenancy_id)
        .where('is_complete', '==', false)
    );
  }

  getCompleteMaintenanceRequestByTenancyId(
    tenancy_id: string
  ): AngularFirestoreCollection<any> {
    return this.afs.collection('maintenance', (ref) =>
      ref
        .where(`tenancy_id`, '==', tenancy_id)
        .where('is_complete', '==', true)
    );
  }

  getTenancyBedroomByBedroomId(
    property_id: string,
    bedroom_id: string
  ): AngularFirestoreDocument<any> {
    return this._property.propertiesCollection
      .doc(property_id)
      .collection('bedrooms')
      .doc(bedroom_id);
  }


  getAllRentExtensionRequests(): AngularFirestoreCollection<any> {

    return this.afs.collection('rentExtensions', (ref) =>
      ref
        .where('landlord_uid', '==', this._auth.currentUserId)
        .where('status', '==', 'PENDING')
    );
  }

  joinTenancy(unique_code: string, email: string) {
    return from(firebase.auth().currentUser.getIdToken()).pipe(
      flatMap((authToken) => {
        // attach security header

        console.log('AUTH TOKEN = ', authToken);
        console.log('unique_code', unique_code);
        console.log(this._auth.currentUserId, 'this._auth.currentUserId');


        const myUID = {
          user_uid: this._auth.currentUserId,
          user_email: email,
          unique_code,

          // tenancy_offer_id: '0UVtckEkaBXu77DOieJt'
        };
        console.log(myUID);
        // const notMyUID = {uid: 'some-other-user-uid'}; // error 403 response
        return this._http.post(
          environment.firebaseConfig.apiUrl + '/tenant-joinTenancy',
          myUID
        );
      })
    );
  }

  createRentExtensionRequest(form: any, tenancy: any, address: any, due_date: Date, payment_id: string) {
    const id = this.afs.createId();

    const item = {
      extension_id: id,
      landlord_uid: tenancy.landlord_uid,
      tenancy_id: tenancy.tenancy_id,
      sender_id: this._auth.currentUserId,
      property_id: tenancy.property_id,
      payment_id,
      status: 'PENDING',
      description: form.description,
      due_date,
      extension_date: form.extension_date,
      first_line_address: address.first_line_address,
      post_code: address.post_code
    };

    return from(this.rentExtensionsCollection.doc(id).set(item, {merge: true}));
  }

  updateRentExtensionRequest(id: string, status: string) {
    const item = {
      status: status
    };
    return from(this.rentExtensionsCollection.doc(id).update(item));
  }

  getTenancyData() {
    return this.afs.collection('tenancies').doc(this.getIdCurrentTenancy());
  }

  getTenancyEsignatureDocument(tenancy_id: string) {
    return this._http.post(environment.firebaseConfig.apiUrl + '/bunkpassport-getEsignaturesSignedDocument', {tenancy_id});
  }

  isTenancyEnding(endDate: any) {
    if (endDate !== null || endDate !== false) {
      return this._date.daysFromToday(endDate.toDate());
    } return null;
  }

  getSelectTenancyLabels(tenancy: any) {
    const fromDate = tenancy.tenancy_start ?  moment(tenancy.tenancy_start.toDate()).format('DD MMM YYYY'): null;
    const toDate = (tenancy.rolling_end_date)
      ? moment(tenancy.rolling_end_date.toDate()).format('DD MMM YYYY') :
    (tenancy.tenancy_end && tenancy.tenancy_end !== null  || tenancy.tenancy_end && tenancy.tenancy_end !== false )
      ? moment(tenancy.tenancy_end.toDate()).format('DD MMM YYYY') : null;
    (tenancy.tenancy_end)
      ? moment(tenancy.tenancy_end.toDate()).format('DD MMM YYYY') : null ;


    const name = (toDate) ? `${fromDate} → ${toDate}` : `${fromDate} → Rolling monthly`;
    const tenancy_id = tenancy.tenancy_id;
    return {
      ...tenancy,
      name,
    };
  }

  // cloud function
  endTenancyAndServeNotice(tenancy_id: string, tenancy_end: any) {
    console.log(tenancy_id, tenancy_end, 'CF');
    return this._http.post(environment.firebaseConfig.apiUrl + '/tenancyrenewal-updateTenancyEnd', { tenancy_id, tenancy_end });
  }

  getTenancyRentAmount(tenancy: any) {
    if (tenancy.tenants || tenancy.mid_tenant_invitees) {
      const rentArray = [];
      const tenants = tenancy.mid_tenant_invitees && tenancy.mid_tenant_invitees.length > 0 ? tenancy.mid_tenant_invitees : tenancy.tenants;
      tenants.map((tenant: any) => rentArray.push(tenant.rent_amount) );

      const sum =  tenancy.rent_interval_count === 1 ? this.getSumOfArray(rentArray) : this.getSumOfArray(rentArray) * 3;

      return  tenancy.rent_interval_count === 1  ? this._math.roundToTwoDP(sum) : Math.round(sum);
    }
    return;
  }

  getSumOfArray(amount: any) {
    return amount.reduce(function (a, b) {
      return a + b;
    }, 0);
  }

  adminCancelTenancy(is_relisting: boolean, tenancy_id: string) {
    return this._http.post(environment.firebaseConfig.apiUrl + '/tenancyrenewal-adminCancelTenancy', {is_relisting, tenancy_id});
  }

  sendTenantsNotices(notice_id: string, form: any) {
    console.log({...form});
    console.log({notice_id});
    return this._http.post(environment.firebaseConfig.apiUrl + '/notice-sendTenantsNotices', {...form, notice_id});
  }

  createIncompleteTenancy(agreement: any, tenancy_offer_id: string) {

    const date = new Date();
    const id = !agreement.tenancy_id ? this.afs.createId() : agreement.tenancy_id;

    const tenancyItem = {
      date_created: date,
      date_modified: date,
      property_id: agreement.property_id,
      bedroom_id: agreement.bedroom_id ? agreement.bedroom_id : null,
      landlord_uid: agreement.landlord_uid,
      team_id: agreement.team_id ? agreement.team_id : null,
      status: 'incomplete',
      tenancy_offer_id,
      tenancy_id: id,
      previous_tenancy_id: agreement.previous_tenancy_id ? agreement.previous_tenancy_id : null
    };

    console.log(tenancyItem, 'tenancyItem');

    return from(this.tenancyCollection.doc(id).set(tenancyItem, {merge: true})).pipe(
      flatMap(() => agreement.previous_tenancy_id ?  
         this.tenancyCollection.doc(agreement.previous_tenancy_id).set({tenancy_renewal_id: id, is_rolling : false}, {merge: true}) : 
          of({})),
         map(() => id)
       )
  }

  getAllOverlappingTenancies(property_id:string, bedroom_id:string, tenancy_start:Date, allTenantUids:string[]): AngularFirestoreCollection<any> {

    return this.afs.collection('tenancies', (ref) =>{
      if(bedroom_id){
        return ref
          .where('property_id', '==', property_id)
          .where('bedroom_id', '==', bedroom_id)
          .where('tenancy_end', '>', tenancy_start)
          .where('landlord_uid','==',this._auth.currentUserId)
       }
          return ref
          .where('property_id', '==', property_id)
          .where('tenancy_end', '>', tenancy_start)
          .where('landlord_uid','==',this._auth.currentUserId)
       }
    );
  }

  deleteTenancy(id:string){
    return from(this.tenancyCollection.doc(id).set({is_deleted:true}, {merge: true}))
  }
  
  tenancySwapRoom(tenancyId:string, newPropertyId:string){
    const requestData = {
      tenancy_id: tenancyId,
      new_property_id: newPropertyId
    }
    return this._http.post(environment.firebaseConfig.apiUrl + '/activeTenancies-swapRoom', requestData);

  }

  makeTenancyRolling(tenancy_id: string) {
    return this.tenancyCollection.doc(tenancy_id).ref.update({ is_rolling: true });
  }
}


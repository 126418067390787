/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./skeleton-loading.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/common";
import * as i3 from "./skeleton-loading.component";
var styles_SkeletonLoadingComponent = [i0.styles];
var RenderType_SkeletonLoadingComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_SkeletonLoadingComponent, data: {} });
export { RenderType_SkeletonLoadingComponent as RenderType_SkeletonLoadingComponent };
function View_SkeletonLoadingComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "tr", [["class", "js-table-row-loader"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 7, "td", [["class", "table-cell-loader"], ["colspan", "4"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 6, "div", [["class", "flex"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 0, "div", [["class", "full__width full__width--small u-mg--sm u-pd--sm loading-animation"]], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 0, "div", [["class", "full__width u-mg--sm u-pd--sm loading-animation "]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "div", [["class", "full__width full__width--narrow u-mg--sm u-pd--sm loading-animation"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "div", [["class", "full__width full__width--narrow u-mg--sm u-pd--sm loading-animation"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, "div", [["class", "full__width full__width--narrow u-mg--sm u-pd--sm loading-animation"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 0, "div", [["class", "full__width full__width--narrow u-mg--sm u-pd--sm loading-animation"]], null, null, null, null, null))], null, null); }
export function View_SkeletonLoadingComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 14, "table", [], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 9, "thead", [], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 8, "tr", [], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 7, "th", [], null, null, null, null, null)), (_l()(), i1.ɵeld(4, 0, null, null, 6, "div", [["class", "flex"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 0, "div", [["class", "full__width full__width--small full_width--small u-mg--sm u-pd--sm loading-animation--header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(6, 0, null, null, 0, "div", [["class", "full__width u-mg--sm u-pd--sm loading-animation--header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(7, 0, null, null, 0, "div", [["class", "full__width full__width--narrow u-mg--sm u-pd--sm loading-animation--header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(8, 0, null, null, 0, "div", [["class", "full__width full__width--narrow u-mg--sm u-pd--sm loading-animation--header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 0, "div", [["class", "full__width full__width--narrow u-mg--sm u-pd--sm loading-animation--header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 0, "div", [["class", "full__width full__width--narrow u-mg--sm u-pd--sm loading-animation--header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(11, 0, null, null, 3, "tbody", [], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 2, null, View_SkeletonLoadingComponent_1)), i1.ɵdid(13, 278528, null, 0, i2.NgForOf, [i1.ViewContainerRef, i1.TemplateRef, i1.IterableDiffers], { ngForOf: [0, "ngForOf"] }, null), i1.ɵpad(14, 6)], function (_ck, _v) { var currVal_0 = _ck(_v, 14, 0, 0, 1, 2, 3, 4, 5); _ck(_v, 13, 0, currVal_0); }, null); }
export function View_SkeletonLoadingComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "skeleton-loading", [], null, null, null, View_SkeletonLoadingComponent_0, RenderType_SkeletonLoadingComponent)), i1.ɵdid(1, 114688, null, 0, i3.SkeletonLoadingComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var SkeletonLoadingComponentNgFactory = i1.ɵccf("skeleton-loading", i3.SkeletonLoadingComponent, View_SkeletonLoadingComponent_Host_0, {}, {}, []);
export { SkeletonLoadingComponentNgFactory as SkeletonLoadingComponentNgFactory };

import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { isNullOrUndefined } from 'util';
import { UserService } from '@app/services/user.service';
import { map, tap } from 'rxjs/operators';
import { AngularFireAuth } from '@angular/fire/auth';
import { Auth } from '@env/routing';


@Injectable()
export class OnlyLoggedinUsersGuard implements CanActivate {
  constructor(
    private router: Router,
    public afAuth: AngularFireAuth
  ) { }

  canActivate(route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.afAuth.authState
      .pipe(
        map((auth: any) => {
          if (isNullOrUndefined(auth) || auth.isAnonymous) {
            this.router.navigate(['/' + Auth.signin]);
            return false;
          } else {
            return true;
          }
        })
      );
  }
}

@Injectable()
export class OnlyLoggedinNotAuthenticatedRouteGuard implements CanActivate {
  constructor(
    private router: Router,
    private _user: UserService) {
  }

  canActivate(route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    return this._user.user$
      .pipe(
        map((auth: any) => !(!!auth) || auth.isAnonymous),
        tap(not_authenticated => {
          if (!not_authenticated) {
            const role = this._user.user.role;
            if (role === 'landlord') {
              console.log('is landlord');
              this.router.navigate(['dashboard/']);
            } else if (role === 'tenant') {
              console.log('is tenant');
              this.router.navigate(['/tenant']);
            } else if (isNullOrUndefined(role)) {
              this.router.navigate(['/set-role']);
            }
          }
        })
      );
  }
}

import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { TenancyOfferService } from '@app/services';
import { flatMap, map } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'members-list',
  template: `
    <ng-container *ngFor="let user$ of (users$ | async)">
      <div class="ml-row" *ngIf="user$ | async as user">

        <profile-avatar size="sm" [profileImgUrl]="user?.profile_data?.profile_image_url" [profileName]="user?.profile_data?.full_name"></profile-avatar>

        <div class="ml-text">
          <p class="truncate">{{ user.profile_data?.full_name | titlecase }}</p>
          <small *ngIf="showStatus"><span class="status__tick" *ngIf="getStatus(user.uid) === 'accepted'"></span>{{ getStatus(user.uid) | titlecase }}</small>
          <small *ngIf="showSigned"><span class="status__tick" *ngIf="this.tenancyMembers[user.uid].has_signer_signed; else notSigned"></span>{{ getSignedBoolean(user.uid) | titlecase }} 
          </small>
        </div>
        <button *ngIf="canRemove" (click)="deleteUserFromTempObject(user.uid)" class="btn__text-generic">Remove</button>
      </div>
      
      <ng-template #notSigned>
        <img class="tick" src="/assets/svg/tenant-icons/failed_icon.svg" alt="">
      </ng-template>
    </ng-container>
    `,
  styleUrls: ['members-list.component.scss']
})
export class MembersListComponent implements OnInit {
  @Input() offer: any;
  @Input() tenancyMembers: any;
  @Input() canRemove: boolean;
  @Input() tenancyOfferId;
  @Input() showStatus = false;
  @Input() showSigned = false;
  @Output() tenant_has_signed: EventEmitter<boolean> = new EventEmitter<boolean>();

  public users$: Observable<any>;
  constructor(private _tenancyOffer: TenancyOfferService,
              private _toastr: ToastrService) {
  }
  ngOnInit() {
    this.users$ = this._tenancyOffer.getUsersFromOffer(this.tenancyMembers);
    // this.emitSignedBoolean();
  }

  getStatus(uid: number) {
    return this.tenancyMembers[uid].status;
  }

  getSignedBoolean(uid: string) {
    return (this.tenancyMembers[uid].has_signer_signed) ? 'Signed' : 'Not Signed';
  }

  // emitSignedBoolean() {
  //   for (const member of Object.values(this.tenancyMembers)) {
  //     (member.has_signer_signed) ? this.tenant_has_signed.emit(true) : null;
  //   }
  // }

  getPaidBoolean(uid: string) {
    if (this.offer.securing_payments) {
      if (this.offer.securing_payments.members[uid]) {
        return (this.offer.securing_payments.members[uid].date_paid) ? 'Paid' : 'Not Paid';
      }
    }
    return 'Not Paid';
  }

  deleteUserFromTempObject(uid: string) {
    this._tenancyOffer.deleteUserFromTempObject(this.tenancyOfferId, uid).pipe(
      flatMap(() => this.users$ = this._tenancyOffer.getUsersFromOffer(this.tenancyMembers))
    ).subscribe(() => this._toastr.success('User removed'));
  }
}

<bunk-uploader #bunkUploader
                     [filePath]="imagesFilePath"
                     (uploadStart)="onUpStart($event)"
                     (uploadChange)="onUploadedFile($event)"
                     hidden>
      </bunk-uploader>

      <div class="main__wrapper" *ngIf="property">
          <section class="main__content card__base card__base--mb-intercom">

              <form [formGroup]="formGroup" class="cc" (ngSubmit)="submit(formGroup.value)">
                  <div class="upload-image card__base">
                      <label for="property-pictures-upload" (click)="bunkUploader.click()" class="cc jc_c ai_c">
                          <strong>Upload photos</strong>
                          <button class="btn__generic" type="button">Choose file</button>
                          <div class="cc jc_c">
                              <span>Add at least 5 photos</span>
                          </div>
                      </label>
                      <div *ngIf="errorsMessage" class="processing-errors">
                          <small>{{ errorsMessage }}</small>
                          <small><a href="mailto:{{client_data.support_email}}" target="_blank">Please contact {{client_data.company_name}} for assistance</a></small>
                      </div>
                  </div>
                  <div *ngIf="processingFiles" class="processing-files">
                      <span>{{uploadingFilesMessage()}}</span>
                      <loading-spinner class="margin-top--md"></loading-spinner>
                  </div>
                  <sortable-list [listData]="formGroup.controls.property_photos"></sortable-list>

                  <div class="buttons mt_5" *ngIf="!isSimpleProperty">
                      <button id="button_lap-photos-prev"
                              class="btn__seethrough"
                              type="button"
                              (click)="goBack()">
                          Previous
                      </button>
                      <button id="button_lap-photos-next" class="btn__generic" type="submit" [disabled]="property.pending_approval">
                      {{ editId ? 'Save' : 'Next'}}
                      </button>
                  </div>
              </form>

          </section>
      </div>

 

      <div>
        <h2 class="text--lg">Employment details</h2>
        <p>Please fill in information about your employer. We will not contact your referee until you have had an offer accepted on a property.</p>
      </div>

      <form class="cc" [formGroup]="formGroup" (ngSubmit)="nextStep(formGroup.value)">
          
        <form-employment-details formControlName="employment_details"></form-employment-details>

        <div class="margin-bottom--xl">
          <button *ngIf="showPrev" (click)="goBack()" data-test="backButton" class="btn__seethrough btn__form-prev">Back</button>
          <button class="btn__generic btn__form-submit btn__form-submit--align-right" type="submit">
            <!--[disabled]="formGroup.invalid || submittingData">-->
            <span *ngIf="!submittingData && !submittingDataSuccess">Save<span *ngIf="!standAlone"> and continue</span></span>
            <processing-spinner *ngIf="submittingData || submittingDataSuccess" color="#FFFFFF" [complete]="submittingDataSuccess"></processing-spinner>
          </button>
        </div>
        
      </form>

  
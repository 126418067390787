import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';

import { Tenant, Profile } from '@env/routing';
import { BunkPassportService, UserService } from '@app/services';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@app/core/services';
import { takeUntil, map, catchError, finalize } from 'rxjs/operators';
import { Subject, of  } from 'rxjs';

import { TenantVerifyEventEmiterService } from '../profile-tenant.service';
import { TenantAccountStepComponent } from './tenant-account-step.component';



@Component({
  selector: 'tenant-account-university-details',
  template: `
      <div>
        <h2 class="text--lg">University details</h2>
        <p>If you're currently studying at university please complete the form below</p>

      </div>

      <form [formGroup]="formGroup" (ngSubmit)="nextStep(formGroup.value)">
        <form-university-details formControlName="university_details"></form-university-details>

        <div class="margin-bottom--xl">
          <button class="btn__generic btn__form-submit btn__form-submit--align-right" type="submit">
            <!--[disabled]="formGroup.invalid || submittingData">-->
            <span *ngIf="!submittingData && !submittingDataSuccess">Save<span *ngIf="!standAlone"> and continue</span></span>
            <processing-spinner *ngIf="submittingData || submittingDataSuccess" color="#FFFFFF" [complete]="submittingDataSuccess"></processing-spinner>
          </button>
        </div>
      </form>
  `,
  styleUrls: ['./tenant-account-step.component.scss'],
})
export class TenantAccountVerifyUnivertsityDetailsComponent extends TenantAccountStepComponent implements OnDestroy, OnInit {
  @Input() showPrev: boolean;
  @Input() standAlone = false;
  @Input() reference?: any;
  public formGroup: FormGroup;
  public routes = { Tenant };
  public showAddress = false;
  showSpinner = false;


  constructor(
    _verifyEvent: TenantVerifyEventEmiterService,
    public _user: UserService,
    private _formBuilder: FormBuilder,
    private _auth: AuthService,
    private _passport: BunkPassportService,
  ) {
    super(_verifyEvent);
    this.formGroup = this.initFormGroup();

  }

  initFormGroup(): FormGroup {
    return this._formBuilder.group({
      university_details: [null],
    });
  }

  preloadFormGroup(form: any): void {
    const personalDetails = form.personal_details;
  
    // this.formGroup.patchValue(item);
  }


  nextStep(form: any) {
    const reference_id = this.reference ? this.reference.bunk_passport_reference_id : null;

    const finalForm ={
      university_details: {
        ...form.university_details,
        bunk_passport_reference_id:reference_id
      }
    }

    this.submittingData = true;
    this.formGroup.disable();
    this._passport.updateUserReferenceData(finalForm)
      .pipe(
        catchError(err => of([]))
      )
      .subscribe(
        res => {
          this.stepComplete();
        },
        err => {
          console.log('HTTP Error', err);
          this.stepError();
        },
        () => {
          this.formGroup.enable();
          console.log('HTTP request completed.');
        }
      );
  }


  ngOnDestroy(): void {

  }

  ngOnInit(): void {
    console.log(this.reference, 'uNI')
    if (this.reference) {
      this.formGroup.get('university_details').patchValue(this.reference.reference_data);
    }
  }
}

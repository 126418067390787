import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { PropertyService, UserService, ViewingService } from '@app/services';
import { Landlord, Tenant } from '@env/routing';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@app/core/services';
import * as moment from 'moment';
import { Viewing } from '@app/viewings/views/viewing-summary/viewing-model';

const ViewingStatus = {
  accepted: 'Accepted',
  pending: 'Pending',
  cancelled_by_landlord: 'Cancelled',
  cancelled_by_tenant: 'Cancelled',
  cancelled: 'Cancelled'
};

@Component({
  selector: 'viewings-card',
  templateUrl: './viewings-card.component.html',
  styleUrls: ['./viewings-card.component.scss']
})

export class ViewingsCardComponent implements OnChanges {
  @Input() viewing: Viewing;
  public property$: Observable<any>;
  public user$: Observable<any>;
  status: string;
  public VIEWINGSTATUS = ViewingStatus;

  constructor(private _property: PropertyService,
    public _user: UserService,
    public _auth: AuthService,
    private _viewing: ViewingService,
    private _route: ActivatedRoute,
    private router: Router) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.property$ = this._property.getPropertyById(this.viewing.property_id).valueChanges();
    this.status = this.VIEWINGSTATUS[this.viewing.status];

    (this.viewing.tenant_uid === this._auth.currentUserId) ? this.user$ = this._user.getUserById(this.viewing.landlord_uid).valueChanges()
      : this.user$ = this._user.getUserById(this.viewing.tenant_uid).valueChanges();
  }

  rescheduleViewing() {
    this._viewing.updateIsRead(this.viewing.viewing_id).subscribe(() => {
      if (this._user.userDb.role === 'landlord') {
        return this.router.navigate(['../../' + Landlord.base, Landlord.manage.base, Landlord.manage.viewings.base, Landlord.manage.viewings.reschedule, this.viewing.viewing_id])
      } else {
        return this.router.navigate(['../' + Tenant.viewing.reschedule, this.viewing.viewing_id], { relativeTo: this._route });
      }
    })
  }

  normalisedDate = (date: any /* Timestamp | string */) => {
    if (!!date.seconds)
      return moment.unix(date.seconds)
    else
      return moment(date)
  }

  getDate(date: string): string {
    return this.normalisedDate(date).format('Do MMM, YYYY');
  }

  getTime(date: string): string {
    return this.normalisedDate(date).format('HH:MM A');
  }

  getDateFromNow(date: string): string {
    return this.normalisedDate(date).fromNow();
  }

  dateAndTimeString(date: string): string { //todo replace with pipe
    return `${this.getDate(date)} - ${this.getTime(date)}`
  }
}

import { Component, Inject, ViewChild, ElementRef, OnDestroy, AfterViewInit, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { FilePreviewOverlayRef } from './file-preview-overlay-ref';
import { PreviewOverlayComponent } from './preview-overlay.component';
import { PDF_PREVIEW_DOC } from './file-preview-overlay.tokens';
import { animations } from './bunk-overlay.animations';
import { BehaviorSubject, fromEvent, of, Subscription, timer } from 'rxjs';
import { map, take, timeout, catchError, retry, tap, first, retryWhen, delayWhen, timeoutWith } from 'rxjs/operators';

@Component({
  selector: 'pdf-preview-overlay',
  template: `
    <div class="overlay-content"
      [@slideContent]="animationState"
      (@slideContent.start)="onAnimationStart($event)"
      (@slideContent.done)="onAnimationDone($event)">
      <div class="bunk-overlay__external-content">
        <div class="bunk-overlay__header bunk-overlay__header--white">
          <h2 *ngIf="pdf.name" class="truncate text--md">{{pdf.name}}</h2>
          <button class="bunk-overlay__close-btn" (click)="close()">close<mat-icon svgIcon="icon-close"></mat-icon></button>
        </div>
        <div class="bunk-overlay__content bunk-overlay__content--scroll-y">
          <div class="spinner-wrapper" *ngIf="loading">
          <loading-spinner></loading-spinner>
          </div>
          <iframe #pdfFrame [@fade]="(loading ? 'fadeOut' : 'fadeIn')" [src]="src | async"></iframe>
        </div>
      </div>
    </div>`,
  styleUrls: ['./bunk-overlay.component.scss'],
  animations: [animations]
})
export class PdfPreviewOverlayComponent extends PreviewOverlayComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('pdfFrame', { static: false }) pdfFrameCont: ElementRef;
  private pdfLoadSub: Subscription;
  // public srcFile: SafeResourceUrl;
  src: BehaviorSubject<any> = new BehaviorSubject('')
  constructor(
    public dialogRef: FilePreviewOverlayRef,
    private sanitizer: DomSanitizer,
    @Inject(PDF_PREVIEW_DOC) public pdf: any) {
      super(dialogRef);
    }

  ngOnInit() {
    // this.srcFile = this.sanitizer.bypassSecurityTrustResourceUrl(`https://docs.google.com/viewer?url=${ encodeURIComponent(this.pdf.url) }&embedded=true`);
    this.src.next(this.sanitizer.bypassSecurityTrustResourceUrl(`https://docs.google.com/viewer?url=${ encodeURIComponent(this.pdf.url) }&embedded=true`));
  }

  ngAfterViewInit() {
    // console.log('-------- Loading PDF ngAfterViewInit: ', this.loading);
    const el = this.pdfFrameCont.nativeElement;
    const source = fromEvent(el, 'load').pipe(
      retryWhen(errors =>
        errors.pipe(
          tap(val => {
            // console.log('Attempting PDF reload');
            // el.src += '';
            this.src.next(this.sanitizer.bypassSecurityTrustResourceUrl(`https://docs.google.com/viewer?url=${ encodeURIComponent(this.pdf.url) }&embedded=true`));
          }),
          delayWhen(val => {
            // console.log(val);
            return this.loading ? timer(500) : null;
          })
        )
      )
    );

    this.pdfLoadSub = source.subscribe(val => {
      this.loading = false;
      // console.log('Iframe event: ' + val['type']);
    });

    // setTimeout(() => {
    //   if (this.loading) {
    //     // console.log('------> Load pdf in external window/tab');
    //     this.close();
    //     this.loading = false;
    //     window.open(this.pdf.url, '_blank'); // This will be blocked on iOS - @TODO: add a button or other
    //   }
    // }, 3000);
    const iframeRefresher = setInterval(()=>{
      if(!this.loading){
        clearInterval(iframeRefresher)
      }
      this.src.next(this.sanitizer.bypassSecurityTrustResourceUrl(`https://docs.google.com/viewer?url=${ encodeURIComponent(this.pdf.url) }&embedded=true`));
    }, 3000)
  }

  ngOnDestroy() {
    if (this.pdfLoadSub) {
      this.pdfLoadSub.unsubscribe();
    }
  }
}

<ng-template #incomplete>
    <i>Incomplete</i>
</ng-template>
<div class="overlay-profile">
    <header class="bunk-overlay__close-bar">
    <button class="bunk-overlay__close-btn bunk-overlay__close-btn--bp" (click)="closeOverlay.emit()">
      <mat-icon svgIcon="icon-close"></mat-icon>
    </button>
    </header>
    <ng-container *ngIf="{passport: usersPassport$ | async, url : userIdentity$ |async, user: users$ | async, usersReadOnly: usersReadOnly$ | async } as values">
    <ng-container *ngIf="displayProfile(values)">
    <div class="profile-info">
    <profile-avatar [profileImgUrl]="values.user?.userProfileImage" size="lg" [profileName]="values.passport?.personal_details?.fullName"></profile-avatar>
        <div class="profile-text">
          <h1>{{values.passport.personal_details.fullName}}</h1>
          <small class="text--subtle">Member since {{values.passport?.date_created.toDate()| date: 'MMMM y'}}</small>
          <p *ngIf="values.passport?.personal_details.phone" class="contact-details--overlay">
          <mat-icon svgIcon="call-icon"></mat-icon><a href="{{'tel:' + values.passport?.personal_details?.phone}}">
          + {{values.passport?.personal_details?.phone}}</a></p>
          <div class="flex flex-row" *ngIf="property" >
          <mat-icon class="chat-icon" svgIcon="bunk-icon-chat"></mat-icon>
          <small
            class="bp-message"
            type="button"
            (click)="closeOverlay.emit()"
            appContactButton
            [property]="property"
            [addresseeId]="uid">
            Send message</small>
        </div>
        </div>
    </div>
    <ng-template #noProperty>Chat message</ng-template>
    <div class="field-table">
    <div class="step-title">
      <h2>Profile</h2>
      </div>
      <ul>
        <li>Location<strong class="mr_5" *ngIf="values.passport?.personal_details?.address !== 'Incomplete'; else incomplete">{{values.passport?.personal_details?.address | truncate:[30, '...'] | titlecase}}</strong></li>
        <li>DOB<strong class="mr_5" *ngIf="values.passport?.personal_details.dob !== 'Incomplete'; else incomplete">{{values.passport?.personal_details.dob}}</strong></li>
        <li *ngIf="values.user.userEmail">Email<strong class="mr_5">{{values.user?.userEmail}}</strong></li>
        <li *ngIf="values.passport?.personal_details?.address">Address<strong class="mr_5">{{values.passport.personal_details.address}}</strong></li>
        <li *ngIf="values.passport?.personal_details?.nationality">Nationality<strong class="mr_5">{{values.passport?.personal_details?.nationality | titlecase}}</strong></li>
        <li *ngIf="values.passport?.personal_details?.rent_responsibility">Rent responsibility<strong class="mr_5">{{values?.passport.personal_details.rent_responsibility | titlecase}}</strong></li>
        <li *ngIf="values.passport?.personal_details?.payment_preference">Payment preference<strong class="mr_5">{{values.passport?.personal_details.payment_preference | titlecase | replaceUnderscore}}</strong></li>
        <li *ngIf="values?.passport.has_uk_guarantor || values.passport?.has_uk_guarantor === false">UK guarantor
          <strong class="mr_5" *ngIf="values.passport?.has_uk_guarantor">Yes
          </strong>
          <strong class="mr_5" *ngIf="!values.passport?.has_uk_guarantor">No
          </strong>
        </li>
        <ng-container *ngIf="PROPERTY_MANAGER_IDS.includes(_auth.currentUserId)">
          <ng-container *ngIf="values.url as url">
        <li *ngIf="url.url">Identity document<strong class="mr_5"><button class="btn__seethrough btn__seethrough--white btn--xs" (click)="openDocument(url.url)"> Open</button></strong></li>
        </ng-container>
        </ng-container>
    </ul>
    <ng-container *ngIf="vouchReport$ | async as reportPath">
      <div class="step-title">
        <h2>Vouch referencing</h2>
      </div>
      <ul>
        <li>Report
          <strong class="mr_5">
            <button class="btn__seethrough btn__seethrough--white btn--xs" fileDownload [path]="reportPath"> View Report</button>
          </strong>
        </li>
      </ul>
    </ng-container>
    <ng-container *ngIf="guarantorDetails$ | async as guarantorDetails">
      <div class="step-title">
        <h2>Guarantor</h2>
      </div>
      <ul>
        <li>First Name<strong class="mr_5">{{guarantorDetails.first_name | titlecase}}</strong></li>
        <li>Last Name<strong class="mr_5">{{guarantorDetails.last_name | titlecase}}</strong></li>
        <li>Email<strong class="mr_5">{{guarantorDetails.email}}</strong></li>
        <li>Phone<strong class="mr_5">{{guarantorDetails.phone}}</strong></li>
        <li *ngIf="guarantorDetails.agreement_url">Agreement<strong class="mr_5"><button class="btn__seethrough btn__seethrough--white btn--xs" (click)="getGuarantorDocument(guarantorDetails.reference_id,guarantorDetails.doc)"> View Agreement</button></strong></li>
      </ul>
    </ng-container>
      <ng-container *ngIf="values?.userReadOnly?.bank_details && PROPERTY_MANAGER_IDS.includes(_auth.currentUserId)">
        <div class="step-title">
          <h2>Bank details</h2>
        </div>
        <ul>
          <li>Account holder name<strong class="mr_5">{{values.userReadOnly?.bank_details?.account_holder_name | titlecase}}</strong></li>
          <li>Bank name<strong class="mr_5">{{values.userReadOnly?.bank_details?.bank_name | titlecase}}</strong></li>
          <li>Account number<strong class="mr_5">{{values.userReadOnly?.bank_details.account_number}}</strong></li>
          <li>Sort code<strong class="mr_5">{{values.userReadOnly?.bank_details.sort_code}}</strong></li>
        </ul>
      </ng-container>
    <div class="step-title">
      <h2>Search preferences</h2>
    </div>
    <ul *ngIf="!values.passport?.search_preferences" class="field-table">
      <li><i>This user has not completed this section yet</i></li>
    </ul>
  <ng-container *ngIf="values.passport?.search_preferences">
    <ul>
    <ng-container *ngFor="let details of values.passport?.search_preferences">
      <li *ngIf="details.title !== null">
      {{details.title}}<strong *ngIf="details.value; else incomplete">{{details.value}}</strong>
      </li>
    </ng-container>
    </ul>
  </ng-container>
  </div>
  <div class="field-table">
  </div>
    <div class="field-table">
    <div class="step-title">
          <h2>Credit summary</h2>
          <small
        *ngIf="values.userReadOnly?.verificationStatus === 'approved'"
        class="status-text"
        #tooltip="matTooltip"
        matTooltip="This information has been verified by Bunk"
        matTooltipPosition="above"
        aria-tooltip="This information has been verified by Bunk"
        >
        <span class="status__tick"></span>
        Verified
        </small>
    </div>
      <ul *ngIf="!values.userReadOnly?.hasCredit">
          <li><i>This user has not completed this section yet</i></li>
      </ul>
    <ng-container *ngIf="values.userReadOnly?.hasCredit">
      <ul>
      <li *ngFor="let credit of values.userReadOnly?.credit">
      {{credit.title}}<strong>{{credit.value}}</strong>
      </li>
      </ul>
  </ng-container>
  </div>
  </ng-container>
  </ng-container>
    <ng-container *ngIf="usersReferences$ | async as references">
      <ng-container  *ngFor="let reference of references">
      <ng-container *ngTemplateOutlet="reference.type === 'employer' ? employerTemplate : reference.type === 'university' ?
      universityTemplate : reference.type === 'previous_tenancy' ? previousTenancyTemplate : guarantorTemplate; context : {reference: reference}" ></ng-container>
      </ng-container>
    </ng-container>
    <ng-template #employerTemplate
                let-reference="reference">
              <div class="field-table">
                <div class="step-title">
                  <h2>Employment</h2>
                  <ng-container *ngTemplateOutlet="reference.status === 'complete' ? completeTemplate :
                  reference.status === 'pending_signature' ? pendingTemplate :  reference.status === 'incomplete' ? incompleteTemplate : declinedTemplate ; context : {status: reference.status}" >
                  </ng-container>
                </div>
                <ul>
                  <li>Type <strong *ngIf="reference.employmentStatus; else incomplete">{{reference.employmentStatus}}</strong></li>
                  <li>Job title <strong *ngIf="reference.jobTitle; else incomplete">{{reference.jobTitle}}</strong></li>
                  <li>Company name <strong *ngIf="reference.companyName; else incomplete">{{reference.companyName}}</strong></li>
                  <li>Start date <strong *ngIf="reference.startDate; else incomplete" >{{reference.startDate | date:'dd MMMM yyyy'}}</strong></li>
                  <li>Salary <strong *ngIf="reference.salary; else incomplete">{{reference.salary}}</strong></li>
                  <li>Passed probation<strong *ngIf="reference.passedProbation; else incomplete">{{reference.passedProbation}}</strong></li>
                  <li>Working during tenancy<strong *ngIf="reference.workingDuringTenancy; else incomplete">{{reference.workingDuringTenancy}}</strong></li>
                </ul>
              </div>
    </ng-template>
    <ng-template #universityTemplate
                  let-reference="reference">
                  <div class="field-table">
                  <div class="step-title">
                  <h2>University</h2>
                  </div>
                <ul class="field-table">
                  <li>University name <strong *ngIf="reference.uniName; else incomplete" >{{reference.uniName  | truncate:[30, '...']}}</strong></li>
                  <li>Course name <strong *ngIf="reference.courseName; else incomplete" >{{reference.courseName  | truncate:[30, '...']}}</strong></li>
                  <li>Graduation year<strong *ngIf="reference.courseEndYear; else incomplete">{{reference.courseEndYear}}</strong></li>
                  <li *ngIf="reference.student_id">Student ID<strong>{{reference.student_id}}</strong></li>
                  <li *ngIf="reference.rental_academic_year">Rental academic year<strong>{{reference.rental_academic_year | titlecase}}</strong></li>
                </ul>
                </div>
    </ng-template>
    <ng-template #previousTenancyTemplate
                let-reference="reference">
                <div class="field-table">
                <div class="step-title">
                <h2>Previous tenancy</h2>
                <ng-container *ngTemplateOutlet="reference.status === 'complete' ? completeTemplate :
                reference.status === 'pending_signature' ? pendingTemplate : reference.status === 'incomplete' ? incompleteTemplate : declinedTemplate ; context : {status: reference.status}" >
                </ng-container>
                </div>
              <ul class="field-table">
                <li *ngIf="reference.status === 'complete'">Satisfactory tenant<strong>{{reference.satisfactoryTenant}}</strong></li>
                <li *ngIf="reference.status === 'complete'">Would rent again<strong>{{reference.rentAgain}}</strong></li>
                <li>Tenancy Length <strong *ngIf="reference.tenancyLength; else incomplete">{{reference.tenancyLength}}</strong></li>
                <li>Rent <strong *ngIf="reference.rent; else incomplete">{{reference.rent}}</strong></li>
              </ul>
              </div>
     </ng-template>
    <ng-template #guarantorTemplate
                  let-reference="reference">
                  <div class="field-table">
                  <div class="step-title">
                  <h2>Guarantor</h2>
                  <ng-container *ngTemplateOutlet="reference.status === 'complete' && reference.hasBunkVerified ===  true || reference.status === 'pending_signature' && reference.hasBunkVerified ===  true ? completeTemplate :
                  reference.status === 'pending_signature' || reference.status === 'complete' && reference.hasBunkVerified === false
                  ? pendingTemplate :reference.status === 'incomplete' ? incompleteTemplate : declinedTemplate ; context : {status: reference.status}" >
                  </ng-container>
                  </div>
                  <ul class="field-table">
                  <li *ngIf="reference.url !== null && reference.status === 'pending_signature' && reference.hasBunkVerified ===  true || reference.url !== null && reference.status === 'complete' ">Signed Guarantor Agreement<strong>
                  <button class="btn__seethrough btn__seethrough--white btn--xs" (click)="getGuarantorDocument(reference.id)">Open</button></strong></li>
                  <li>Name <strong *ngIf="reference.name; else incomplete">{{reference.name}}</strong></li>
                  <li>Relationship <strong *ngIf="reference.relationship; else incomplete">{{reference.relationship}}</strong></li>
                  <li *ngIf="reference.countryOfResidence !== null">Country of Residence<strong>{{reference.countryOfResidence}}</strong></li>
                  <li *ngIf="reference.homeOwner !== null">UK homeowner<strong>{{reference.homeOwner}}</strong></li>
                  <ng-container *ngIf="reference.status === 'complete'">
                    <li *ngIf="reference.address">Address<strong>{{reference.address | truncate:[30, '...']}}</strong></li>
                    <li *ngIf="reference.phone">Phone<strong>{{reference.phone}}</strong></li>
                    <li *ngIf="reference.email !== null" >Email<strong>{{reference.email}}</strong></li>
                    <li *ngIf="getYearsAgo(reference.dob) >= 18">DOB<strong>{{reference.dob | date: 'MMMM, yyyy'}}</strong></li>
                  </ng-container>
                  <ng-container *ngIf="reference.status === 'complete' && reference.version === 1">
                    <li >Occupation<strong>{{reference.occupation}}</strong></li>
                    <li >Salary<strong>{{reference.salary}}</strong></li>
                  </ng-container>
                  <ng-container *ngIf="reference.status === 'complete' && reference.version === 2">
                    <li >Employment status<strong>{{reference.employmentStatus}}</strong></li>
                      <ng-container *ngIf="reference.employmentStatus === 'Employed' ; else otherEmployment">
                        <li >Employment type<strong>{{reference.employmentType}}</strong></li>
                        <li *ngIf="reference.employmentType === 'contract'">Contract length<strong>{{reference.contractLength}}</strong></li>
                        <li >Occupation<strong>{{reference.positionHeld}}</strong></li>
                        <li >Salary<strong>{{reference.salary}}</strong></li>
                      </ng-container>
                    <ng-template #otherEmployment>
                      <ng-container>
                        <li  *ngIf="reference.employmentStatus === 'Self employed'">Salary<strong>{{reference.salary}}</strong></li>
                        <li >Bank statements<strong>{{reference.bankStatements}}</strong></li>
                      </ng-container>
                    </ng-template>
                  </ng-container>
                  </ul>
                  </div>
    </ng-template>
  <!--- verified states with tool tips --->
  <ng-template #completeTemplate
              let-status="status">
    <small
    class="status-text"
    #tooltip="matTooltip"
    matTooltip="This information has been verified"
    matTooltipPosition="above"
    aria-tooltip="This information has been verified"
    >
    <span class="status__tick"></span>
    Verified
    </small>
  </ng-template>
  <ng-template #declinedTemplate
                let-status="status">
    <small
    class="status-text"
    #tooltip="matTooltip"
    matTooltip="The information submitted could not be verified please contact us to find out why"
    matTooltipPosition="above"
    aria-tooltip="The information submitted could not be verified please contact us to find out why">
    <mat-icon svgIcon="icon-close" class="incomplete-icon"></mat-icon>
    Declined
    </small>
  </ng-template>
  <ng-template #pendingTemplate
              let-status="status">
    <small
    class="status-text"
    #tooltip="matTooltip"
    matTooltip="We are in the process of confirming this information"
    matTooltipPosition="above"
    aria-tooltip="We are in the process of confirming this information">
    <span class="status__step status__step--verify-pending status__step--verify-pending-sm"></span>
    Pending verification
    </small>
  </ng-template>
  <ng-template #incompleteTemplate
               let-status="status">
    <small
      class="status-text"
      #tooltip="matTooltip"
      matTooltip="Once an offer is accepted the tenant can submit their reference"
      matTooltipPosition="above"
      aria-tooltip="Once an offer is accepted the tenant can submit their reference">
      Incomplete by tenant
    </small>

import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {Auth, Landlord, Pages, Profile, Shared, Tenant} from '@env/routing';
import {combineLatest, Observable, of} from 'rxjs';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {environment} from '@env/environment';
import {AuthService} from '@app/core/services';
import {ActivatedRoute, Router} from '@angular/router';
import {PropertyService, TenancyService, UserService} from '@app/services';
import {ToastrService} from 'ngx-toastr';
import {flatMap, map, switchMap} from 'rxjs/operators';
import {tap} from 'rxjs/internal/operators/tap';

@Component({
  selector: 'no-user-doc',
  template: `
    <div class="main__wrapper main__wrapper--narrow ">
      <div class="empty__state">
        <h1>There is something wrong with your account</h1>
        <p>Please fill in your details again to sign up</p>
      </div>
<div class="card-base">

      <h2 class="margin-bottom--sm" *ngIf="propertyId && tenantName; else noMid">Join your tenancy</h2>
      <ng-template #noMid>
        <h2 class="margin-bottom--sm" *ngIf="!propertyId && !tenantName">Sign up <span *ngIf="role">as a {{role}}</span></h2>
      </ng-template>
      <ng-container *ngIf="propertyId && tenantName">


        <hr class="margin-bottom--md margin-top--md">
      </ng-container>


      <form [formGroup]="formGroup">

        <form-create-account class="margin-bottom--md" formControlName="user_account" direction="column" [showLabel]="false"
                             (submitForm)="emailSignup()" [showSpinner]="showSpinner$.value" roleInput="{{(client_data.company_name === 'Livingway' || client_data.company_name === 'NRLA' ? 'tenant' : null )}}"></form-create-account>

      </form>


    </div>
    </div>

  `
})

export class NoUserDocComponent  {

  public isDesktop: boolean;
  public countryCodes: (string | number)[];
  public routes = { Auth, Profile, Pages, Shared };
  public propertyId: string;
  public bedroomId: string;
  public uniqueCode: string;
  public tenantName: string;
  public property$: Observable<any>;
  public combined$: Observable<any>;
  public role;
  public formGroup: FormGroup;

  public showSpinner$: BehaviorSubject<boolean> =  new BehaviorSubject<boolean>(false);

  public client_data = environment.client_data;

  public submittingDataSuccess = false;
  public loading = false;
  public viewingProperty: string;

  constructor(public _auth: AuthService,
              private _formBuilder: FormBuilder,
              private route: ActivatedRoute,
              private _property: PropertyService,
              private _router: Router,
              private _tenancy: TenancyService,
              private _toastr: ToastrService,
              private _user: UserService

  ) {

    this.propertyId = this.route.snapshot.queryParamMap.get('property');
    this.bedroomId = this.route.snapshot.queryParamMap.get('bedroom');
    this.tenantName = this.route.snapshot.queryParamMap.get('name');
    this.viewingProperty = this.route.snapshot.queryParamMap.get('property_id');
    this.uniqueCode = this.route.snapshot.queryParamMap.get('invite');

    this.property$ = this.propertyId ? this._property.getPropertyById(this.propertyId).valueChanges() : of([]);
    this.combined$ = combineLatest([this.property$]).pipe(
      map(([property]) => {
        console.log({property});
        return {property};
      })
    );

    this.formGroup = this.initFormGroup();

  }

  initFormGroup() {
    return this._formBuilder.group({
      user_account: [null]
    });
  }

  setRole(event: any) {
    this.role = event;
  }


  get user_account(): FormControl {
    return this.formGroup.get('user_account') as FormControl;
  }


  emailSignup() {
    const {...form } = this.formGroup.get('user_account').value;

    const userDoc = {
      ...form,
      phone_number: form.signup_phone_number_with_area.phone_number,
      country_code: form.signup_phone_number_with_area.area_code,
      first_name: form.name.first_name,
      last_name: form.name.last_name
    };

    this.showSpinner$.next(true);
    this.submittingDataSuccess = false;
    this.formGroup.disable();

    this._auth.createUserDocuments(this._auth.currentUserId, userDoc).subscribe(
        () => {
          if (this._user.userDb.role === 'tenant') {
            this._router.navigate(['/' + Tenant.base]);
          }
          if (this._user.userDb.role === 'landlord') {
            console.log('hellooo')
            this._router.navigate(['/' + Landlord.base, Landlord.property_type ]);
          }

          this.showSpinner$.next(false);
          this.submittingDataSuccess = true;

        },
        // Handle Errors
        err => {
          console.log('Error is: ', err);
          this.formGroup.enable();
          if (err.error) {
            this._toastr.error(err.error, 'Oops!', {
              timeOut: 5000
            });
          } else {
            this._toastr.error('Something went wrong.', 'Oops!', {
              timeOut: 5000
            });
          }}
      );

  }

}

import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'bunk-verified-logo',
  template: `
    <img src="/assets/svg/logos/bunk-verified-icon.svg" alt="Tenant is Verified">
    <small *ngIf="showTag"></small>`,
  styleUrls: ['./bunk-verified.component.scss']
})
export class BunkVerifiedComponent implements OnInit {
  @Input() showTag = true;
  constructor() { }

  ngOnInit(): void { }
}

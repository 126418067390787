import {Injectable} from '@angular/core';
import {Observable, from as fromPromise, of, forkJoin} from 'rxjs';
import {AuthService} from '@app/core/services';
import * as firebase from 'firebase/app';
import {catchError} from 'rxjs/operators';
import {AngularFireStorage, AngularFireStorageReference, AngularFireUploadTask} from '@angular/fire/storage';
import {UploadTaskSnapshot} from '@angular/fire/storage/interfaces';


//  image_small -> url thumb small
//  image_url -> url thumb large
//  image_original_url -> url original image
//  image_full_path -> full path of original file

export const THUMB_PREFIX = 'thumb_';
export const THUMB_DIMENSIONS = [
  {width: 200, height: 200, value: 'small'},
  {width: 1024, height: 685, value: 'large'}
];

// const thumbName = `${THUMB_PREFIX}${THUMB_DIMENSIONS[i].value}_${fileName}`;

@Injectable()
export class StorageService {
  public task: AngularFireUploadTask;
  public fileRef: AngularFireStorageReference;
  public uploadPercent$: Observable<number>;

  constructor(private _auth: AuthService,
              private _storage: AngularFireStorage) {
  }

  // in order to get the url we should use the finalize method from RxJS on top of the storage ref.
  // eg. .pipe(finalize(() => this._storage.downloadURL.subscribe(url => console.log(url))))
  // or, better use last() combined with map or mergeMap
  // eg. pipe(last(), mergeMap(() => this._storage.downloadURL)).subscribe(url => console.log(url))

  get downloadURL(): Observable<any> {
    return this.fileRef.getDownloadURL();
  }

  /*
   * Get Thumbnail name
   */
  getThumbnailFullPath(fullPath: string, type: string | 'small' | 'large') {
    if (fullPath.split('/').length > 1) {
      // include path (x/y/z/name.jpg)
      const arrFullFinalPath: string[] = fullPath.split('/');
      const arrFullPath: string[] = arrFullFinalPath.slice(0, -1);
      return `${arrFullPath.join('/')}/${THUMB_PREFIX}${type}_${this.beautifyPath(fullPath)}`;
    } else {
      // (name.jpg)
      return `${THUMB_PREFIX}${type}_${this.beautifyPath(fullPath)}`;
    }
  }

  beautifyPath = (path: string): string => {
    const arr = path.split('/');
    return arr[arr.length - 1];
  };

  /*
   * Upload file to Storage (https://github.com/angular/angularfire2/blob/master/docs/storage/storage.md)
   */
  uploadFile(event: any, path: string, file_name?: string): Observable<UploadTaskSnapshot | undefined> {
    const file = event.target.files[0] || event.dataTransfer.files[0];
    // if a new file name is present use it, else generate random string and use original file name
    const name = (file_name) ? file_name : `${this.createRandomString()}-${file.name}`;
    const filePath = `${path}/${name}`;

    this.task = this._storage.upload(filePath, file);
    this.fileRef = this._storage.ref(filePath);
    this.uploadPercent$ = this.task.percentageChanges(); // get percentage of download

    return this.task.snapshotChanges();
  }

  /*
   * Upload a batch of files to Storage (https://github.com/angular/angularfire2/blob/master/docs/storage/storage.md)
   */
  uploadFileBatch(files: File[], bucketPath: string): Observable<any> {
    const zipped: Observable<any>[] = files.map(file => {
      const name = `${this.createRandomString()}-${file.name}`;
      const filePath = `${bucketPath}/${name}`;
      const batchTask: AngularFireUploadTask = this._storage.upload(filePath, file);
      return batchTask.snapshotChanges();
    });
    return forkJoin(zipped);
  }

  /**
   * Upload task
   * @param {File} file
   * @param {string} filePath - path + file_name (eg test/bunk-is-awesome)
   */
  uploadTask(file: any, filePath: string): AngularFireUploadTask {
    return this._storage.upload(filePath, file);
  }

  /**
   * Get storage reference
   * @param {string} filePath - path + file_name (eg test/bunk-is-awesome)
   */
  storageReference(filePath: string): AngularFireStorageReference {
    return this._storage.ref(filePath);
  }

  /*
   * Delete File from Storage via path
   */
  deleteFileFromStorage(path: string): Observable<any> {
    const ref = this._storage.ref(path);
    return fromPromise(ref.delete().toPromise())
      .pipe(catchError((error) => this.errorsHandler(error)));
  }

  /*
   * Delete File from Storage via url
   */
  deleteFileFromStorageByURL(url: string): Observable<any> {
    const ref = firebase.storage().refFromURL(url);
    return fromPromise(ref.delete())
      .pipe(catchError((error) => this.errorsHandler(error)));
  }

  /*
   * Generate download URL for already uploaded files
   */
  getDownloadURLbyPath(path: string): Observable<any> {
    const fileRef = this._storage.ref(path);
    return fileRef.getDownloadURL();
  }

  /*
   * ERROR HANDLER for Storage Service (https://firebase.google.com/docs/storage/web/handle-errors)
   */
  private errorsHandler = (error) => {
    switch (error.code) {
      case 'storage/unknown':
        // Unknown error occurred, inspect error.serverResponse
        return of({error: 'An unknown error occurred.'});
      case 'storage/unauthorized':
        throw {error: `User is not authorized to perform the desired action, check your security rules to ensure they are correct.`};
      case 'storage/canceled':
        throw {error: `User canceled the operation.`};
      case 'storage/object-not-found':
        return of({error: 'No object exists at the desired reference.'});
      default:
        console.log('Something wrong: need to add some handle error for ', error.code);
        return of({error: 'error'});
    }
  };

  // create random string to avoid images with same name
  private createRandomString = (): string =>
    Math.random()
      .toString(36)
      .substring(7);
}

import { AfterContentInit, Component, forwardRef, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { of } from 'rxjs/internal/observable/of';
import { UserService } from '@app/services';
import { FormBuilder, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validator, Validators } from '@angular/forms';
import { AbstractValueAccessor } from '@app/shared/_components/searches/instant-search/instant-search.utils';
import { ITeam } from '@app/core/models';

@Component({
  selector: 'select-team',
  templateUrl: './select-team.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectTeamComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => SelectTeamComponent),
      multi: true
    }
  ]
})

export class SelectTeamComponent extends AbstractValueAccessor implements OnInit, Validator, AfterContentInit {

  public teams$: Observable<ITeam[]> = of([]);
  public teamIds: Array<string>;
  public teamFormGroup: FormGroup;

  @Input() isTenancy = false;

  @Input('submitAttempted') set submitAttempted(attempted: boolean) {
    if (attempted) {
      this.dirtyFormGroup();
    }
  }

  constructor(
    public _user: UserService,
    public _formBuilder: FormBuilder
  ) {
    super();
  }

  get team_id() {
    return this.teamFormGroup.get('team_id');
  }

  ngOnInit(): void {
    this.teamFormGroup = this.initFormGroup();
    this.teamIds = this._user.userTeamIds;
  }

  ngAfterContentInit(): void {
    if (this.teamIds.length) {
      if (this.teamIds.length === 1) {
        this.team_id.setValue(this.teamIds[0]);
        this.teamFormGroup.updateValueAndValidity();
      } else {
        this.teams$ = this._user.getAllTeamsPublic();
        this.team_id.setValidators([Validators.required]);
        this.team_id.updateValueAndValidity();
      }
    }

  }

  dirtyFormGroup() {
    if (this.teamFormGroup) {
      const controls = this.teamFormGroup.controls;
      for (const control in controls) {
        if (controls.hasOwnProperty(control)) {
          this.teamFormGroup.controls[control].markAsTouched();
        }
      }
    }
  }
  public onTouched: () => void = () => { };

  initFormGroup(): FormGroup {
    const formGroup: FormGroup = this._formBuilder.group({
      team_id: [null]
    });
    return new FormGroup(formGroup.controls);
  }

  writeValue(val: any): void {
    super.writeValue(val);
    if (val) {
      this.teamFormGroup.patchValue(val, { emitEvent: false });
    }
  }

  registerOnChange(fn: any): void {
    this.teamFormGroup.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  validate() {
    return this.teamFormGroup.valid ? null : { invalidForm: { valid: false, message: 'select team field is invalid' } };
  }
}

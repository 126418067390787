<form [formGroup]="formGroup">
  <div class="form-get-property" *ngIf="!hideSearch">
    <bunk-form-field [showLabel]="true" direction="column">
      <label bunk-label>Select a property</label>
      <div bunk-input>
        <instant-new-algolia-search *ngIf="properties$ | async as properties" [indexName]="'properties'"
          [multiselect]="false" [message]="'Select a property'" [hideResults]="false"
          (valueChange)="getPropertyId($event)" [showImmediately]="true" [availableHits]="properties">
        </instant-new-algolia-search>
        <mat-error class="text--xs" *ngIf="property_id.errors?.required && property_id.touched">Please select a property
        </mat-error>
      </div>
    </bunk-form-field>
    <div class="form-get-property__cta">
      <div class="form-get-property__text">NOT LISTED?</div>
      <bunk-dialog [component]="addProperty" (dialogRef)="dialogRef = $event">
        <span class="btn__text-generic" type="button">+ Add a new property</span>
      </bunk-dialog>
    </div>
  </div>
  <ng-container *ngIf="property$ | async as property">
    <bunk-form-field [showLabel]="true" direction="column" *ngIf="hideSearch">
      <label bunk-label>Property</label>
      <mat-chip-list bunk-input>
        <mat-chip class="chip" [class.unverified]="isDocumentsRequired && !property.proof_of_ownership?.is_approved">
          <span class="chip-title">
            {{property.address.first_line_address}}
            {{property.address.second_line_address}}
          </span>
          <span *ngIf="isDocumentsRequired && !property.proof_of_ownership?.is_approved">– Awaiting proof of
            ownership</span>
          <mat-icon class="chip-icon" (click)="removeProperty()">cancel</mat-icon>
        </mat-chip>
      </mat-chip-list>
    </bunk-form-field>
    <bunk-form-field [showLabel]="true" *ngIf="property && property.bedrooms_form">
      <label bunk-label>Select an {{client_data.unit_name}}</label>
      <mat-form-field bunk-input appearance="outline">
        <mat-select [formControlName]="'bedroom_id'" (selectionChange)="selectBedroom($event.value)">
          <mat-option *ngFor="let bedroom of property.bedrooms_form" [value]="bedroom.bedroom_id">
            {{bedroom.room_name}}
          </mat-option>
        </mat-select>
        <mat-error>Please select an {{client_data.unit_name}}</mat-error>
      </mat-form-field>
    </bunk-form-field>
  </ng-container>



</form>


<ng-template #overlay>

  <div class="bunk-overlay__external-content bunk-overlay__external-content--scroll">
    <div class="bunk-overlay__header bunk-overlay__header--white margin-bottom border-bottom ">
      <span>Add property</span>
      <button class="bunk-overlay__close-btn" (click)="overRef.close()">
        <mat-icon svgIcon="icon-close"></mat-icon>
      </button>
    </div>
    <section class="main__wrapper main__wrapper--narrow main__wrapper--mb-lg">
      <create-simple-property [isHmo]="currentOption" (createdPropertyId)="test($event)" [dref]="overRef">
      </create-simple-property>
    </section>
  </div>
</ng-template>
<ng-template #addProperty>
  <div>
    <h2>Create a property</h2>
    <div class="add-property__container margin-top">
      <section (click)="setOption(false)" [ngClass]="{'add-property__card--standard': currentOption === false}"
        class=" margin-top--sm add-property__card margin-right">
        <h4 class="add-property__card--title justify-content-center">Whole Property</h4>
        <p class="text--subtle text-center">If you want to add a whole house and one tenancy agreement</p>
      </section>
      <section (click)="setOption(true)" [ngClass]="{'add-property__card--standard': currentOption === true}"
        class=" margin-top--sm add-property__card margin-right">
        <h4 class="add-property__card--title justify-content-center">By Room</h4>
        <p class="text--subtle text-center">If you are adding a HMO with separate tenancy agreements</p>
      </section>
    </div>
    <div class="rc flex_wrap jc_sb mt_20 step-nav">
      <button class="btn__seethrough" (click)="dialogRef.close()"> back </button>
      <bunk-overlay *ngIf="currentOption !== null; else dialogButton" [template]="overlay"
        (overlayRef)="overRef = $event; dialogRef.close()">
        <ng-container *ngTemplateOutlet="dialogButton"></ng-container>
      </bunk-overlay>
    </div>
  </div>
</ng-template>
<ng-template #dialogButton>
  <button class="btn__generic">
    <span>next</span>
  </button>
</ng-template>

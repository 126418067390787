import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { FormControl } from '@angular/forms';
import { TitleCasePipe } from '@angular/common';
import { Observable, combineLatest, from, BehaviorSubject, of, iif } from 'rxjs';
import { startWith, map, tap, flatMap, scan, filter } from 'rxjs/operators';
import { environment } from '@env/environment';
import { Auth } from '@env/routing';
import { AuthService } from '@app/core/services';
import { PropertyService, UserService } from '@app/services';
import * as _ from 'lodash';
import algoliasearch from 'algoliasearch/lite';
import { SeoService } from '@app/services';
import { PROPERTY_SEARCH_META } from '@app/_constants/seo.constants';
import { TRANSLATIONS } from '@app/_constants/translations.constants';

const MILES_TO_METERS_MULTIPLIER = 1609;
const MIN_METER_RADIUS = 1000;

/*
  Algolia property filter and query search with 2 mile radius on singular result
  This is an MVP approach to providing contextual hits without a places lookup
*/
const TypeFilterData = {
  "search": "",
  "filter": null,
  "type": "all",
  "sort": null,
  "instantSearch": [
      "all"
  ],
}

const CampusFilterArray = [
        {
            "value": "all",
            "viewValue": "Campus"
        },
        {
            "value": "Bloemfontein",
            "viewValue": "Bloemfontein"
        },
        {
          "value": "Cape Town",
          "viewValue": "Cape Town"
        },
        {
          "value": "Port Elizabeth",
          "viewValue": "Port Elizabeth"
        },
        {
            "value": "Pretoria",
            "viewValue": "Pretoria"
        },
        {
          "value": "Potchefstroom",
            "viewValue": "Potchefstroom"
        },
        {
            "value": "Stellenbosch",
            "viewValue": "Stellenbosch"
        },

    ]

@Component({
  selector: 'search-properties',
  templateUrl: './properties-search.component.html',
  styleUrls: ['./properties-search.component.scss']
})
export class PropertiesSearchComponent implements OnInit {
  @Input() env: 'prod' | 'test' | 'staging';
  @ViewChild('controls', { static: false }) controls: any;
  @ViewChild('mainWrapper', { static: false }) mainWrapper: any;
  @ViewChild('sharedFilter',{ static: false }) sharedFilter:any;

  private algoliaIndex: any;
  public rent_label_abbreviation = TRANSLATIONS.rent_label_abbreviation;

  private obsSub: BehaviorSubject<number> = new BehaviorSubject(0);

  public property$: Observable<any>;
  public allCities$: Observable<any>;
  public combinedResults$: Observable<any>;
  public navEvents$: Observable<any>;

  public searchStr: FormControl;
  public searchStr$: Observable<string>;
  public searchQuery: string;

  public locationQuery: string;
  public locationFilter: FormControl;
  public locationFilter$: Observable<string>;

  public minBedsQuery: string;
  public minBedsFilterTypes: object[];
  public minBedsFilter: FormControl;
  public minBedsFilter$: Observable<string>;

  public maxBedsQuery: string;
  public maxBedsFilterTypes: object[];
  public maxBedsFilter: FormControl;
  public maxBedsFilter$: Observable<string>;

  public minPriceQuery: string;
  public minPriceFilterTypes: object[];
  public minPriceFilter: FormControl;
  public minPriceFilter$: Observable<string>;

  public maxPriceQuery: string;
  public maxPriceFilterTypes: object[];
  public maxPriceFilter: FormControl;
  public maxPriceFilter$: Observable<string>;

  public studentsQuery: string;
  public studentsFilter: FormControl;
  public studentsFilter$: Observable<boolean>;

  public managerQuery: string;
  public managerFilter: FormControl;
  public managerFilter$: Observable<string>;

  public pageFilter$: Observable<number>;
  public filteredResults$: Observable<Array<any>>;
  public consideredResults$: Observable<any>;
  public propertyManager$: Observable<any>;
  public campusFilterData$: Observable<any>;
  public typeFilterData = TypeFilterData;
  public campusFilterArray = CampusFilterArray

  public bedNumberArr: string[] = ['1', '2', '3', '4', '5'];
  public priceArr: string[] = ['100', '150', '200', '250', '300', '350', '400', '450', '500', '600', '700', '800', '900', '1000', '1100', '1200', '1300', '1400',
  '1500', '1750', '2000', '2250', '2500', '2750', '3000', '3500', '4000', '4500', '5000', '6000', '7000', '8000', '9000', '10000'];

  private minBeds = '0';
  private maxBeds = '100';
  private minPCM = '0';
  private maxPCM = 'noMax';

  public currentPage = 0;
  public lastPage = 0;
  public cityLabels: any;
  public totalProperties: number;
  public showLoadMore: boolean;
  public indexName: string;

  public paramsDirty = false;
  public isDesktop: any;
  public routes = { Auth };

  public menuSubject = new BehaviorSubject(null);
  public client_data = environment.client_data;
  readonly MIN_PROPS = 8;
  readonly MIN_NEARBY_PROPS = 8;
  public filters$: BehaviorSubject<any> = new BehaviorSubject<any>({status: 'all', search: '', type: 'all'});
    public typeFilter$: Observable<any>;
    public typeFilter: FormControl;
  isCampusKey = environment.firebaseConfig.projectId === 'client-campus-key';
  minPropertiesToShowResultText = 50;

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _route: ActivatedRoute,
    private _titlecasePipe: TitleCasePipe,
    public _auth: AuthService,
    public _seo: SeoService,
    public _user: UserService,
    public _property:PropertyService,
  ) {
    const {apiKey, appId} = environment.algolia;
    const envAlgolia = (this.env) ? this.env : environment.algolia.env;
    const searchClient = algoliasearch(appId, apiKey);
    this.indexName = `${envAlgolia}_PROPERTIES-listed`;
    console.log(this.indexName, 'INDEX NAME ')
    this.algoliaIndex = searchClient.initIndex(this.indexName);
    // console.log('this.indexName: ' + this.indexName);

    const startUpTypeFilter = (this._route.snapshot.queryParamMap.get('type') ) ? this._route.snapshot.queryParamMap.get('type') : 'all';
    this.typeFilter = new FormControl(startUpTypeFilter);
    this.typeFilter$ = this.typeFilter.valueChanges.pipe(startWith(startUpTypeFilter));
  }

  updateActiveAccount() {
    return this._user.updateHasActiveAccount().subscribe(() => console.log('updated :d '));

  }
  titleCase(str) {
    return str.toLowerCase().replace(/\b(\w)/g, s => s.toUpperCase());
  }

  ngOnInit() {
    // this.campusFilterData$ = this._property.getAllCampusPropertyData().pipe(
    //    map((properties:any)=> _.uniq(properties.map((campusProperty:any)=> campusProperty.campus)).map((campusData:any) =>{
    //       return{
    //           value:this.titleCase(campusData),
    //           // viewValue:  campusData.substr(0,1).toUpperCase() + campusData.substr(1)
    //           viewValue:  this.titleCase(campusData),
    //           }
    //       })
    //   ),map(campusData =>{
    //     return campusData.filter(campus=>campus.value)
    //   })
    //   ,map((campusItem:any) => [{value: 'all', viewValue: 'Campus'}].concat(campusItem))
    // )

    if (this._auth.authenticated && this._user && this._user.userDb.has_inactive_account) {
      this.updateActiveAccount();
    }

      // const startUpTypeFilter = (this._route.snapshot.queryParamMap.get('type') ) ? this._route.snapshot.queryParamMap.get('type') : 'all';
      // console.log(startUpTypeFilter,"startUpTypeFilter");

      // this.typeFilter$ = this.typeFilter.valueChanges.pipe(startWith(startUpTypeFilter));
    // this._seo.updateMeta(PROPERTY_SEARCH_META.DESCRIPTION, PROPERTY_SEARCH_META.KEYWORDS, PROPERTY_SEARCH_META.OG_TITLE, PROPERTY_SEARCH_META.IMG);

    this.locationQuery = this._activatedRoute.snapshot.queryParamMap.get('location');
    this.minBedsQuery = this._activatedRoute.snapshot.queryParamMap.get('minbeds');
    this.maxBedsQuery = this._activatedRoute.snapshot.queryParamMap.get('maxbeds');
    this.minPriceQuery = this._activatedRoute.snapshot.queryParamMap.get('minprice');
    this.maxPriceQuery = this._activatedRoute.snapshot.queryParamMap.get('maxprice');
    this.studentsQuery = this._activatedRoute.snapshot.queryParamMap.get('students');
    this.managerQuery = this._activatedRoute.snapshot.queryParamMap.get('manager'); // passed as a query param to list property developer listings only
    this.searchQuery = this._activatedRoute.snapshot.queryParamMap.get('search'); // passed as a query param to list property developer listings only

    const locationsInitValue = this.locationQuery ? this.locationQuery : 'all';
    const minBedsInitValue = this.minBedsQuery ? this.minBedsQuery : this.minBeds;
    const maxBedsInitValue = this.maxBedsQuery ? this.maxBedsQuery : this.maxBeds;
    const minPriceInitValue = this.minPriceQuery ? this.minPriceQuery : this.minPCM;
    const maxPriceInitValue = this.maxPriceQuery ? this.maxPriceQuery : this.maxPCM;
    const studentsInitValue: boolean = this.studentsQuery === 'true';
    const managerInitValue = this.managerQuery ? this.managerQuery : undefined;
    const searchInitValue = this.searchQuery ? this.searchQuery : '';

    this.searchStr = new FormControl(searchInitValue);
    this.locationFilter = new FormControl(locationsInitValue);
    this.minBedsFilter = new FormControl(minBedsInitValue);
    this.maxBedsFilter = new FormControl(maxBedsInitValue);
    this.minPriceFilter = new FormControl(minPriceInitValue);
    this.maxPriceFilter = new FormControl(maxPriceInitValue);
    this.studentsFilter = new FormControl(studentsInitValue);
    this.managerFilter = new FormControl(managerInitValue);

    this.searchStr$ = this.searchStr.valueChanges.pipe(
      startWith(searchInitValue),
      tap(() => this.locationFilter.setValue('all'))
    );
    this.locationFilter$ = this.locationFilter.valueChanges.pipe(startWith(locationsInitValue));
    this.managerFilter$ = this.managerFilter.valueChanges.pipe(startWith(managerInitValue));

    this.propertyManager$ = this.managerQuery ? this._user.getManagerByManagerId(this.managerQuery).valueChanges() : of([]);

    /*
      Beds & Price. x

      We need to deal with the user selecting a min value thats greater than a max value
      Here we're comparing those value at the time of the event map()
      ATM I've chosed to directly switch the value and directly set the other input via setValue()
    */
    this.minBedsFilter$ = this.minBedsFilter.valueChanges.pipe(
      startWith(minBedsInitValue),
      map(value => {
        const newVal = value === 'studio' ? 1 : value;
        const numericMaxVal = this.maxBedsFilter.value === 'studio' ? 1 : this.maxBedsFilter.value;
        if (parseInt(newVal, 10) > parseInt(numericMaxVal, 10)) {
          const newBedVal = this.maxBedsFilter.value;
          // Unfortunately using 2 side-effects here but no other way...
          this.maxBedsFilter.setValue(value.toString());
          this.minBedsFilter.setValue(newBedVal);
          return newBedVal;
        } else {
          return value;
        }
      })
    );
    this.maxBedsFilter$ = this.maxBedsFilter.valueChanges.pipe(
      startWith(maxBedsInitValue),
      map(value => {
        const newVal = value === 'studio' ? 1 : value;
        if (parseInt(newVal, 10) < parseInt(this.minBedsFilter.value, 10) && this.minBedsFilter.value !== 'studio') {
          const newBedVal = this.minBedsFilter.value;
          // Unfortunately using 2 side-effects here but no other way...
          this.minBedsFilter.setValue(value.toString());
          this.maxBedsFilter.setValue(newBedVal);
          return newBedVal;
        } else {
          return value;
        }
      })
    );

    this.minPriceFilter$ = this.minPriceFilter.valueChanges.pipe(
      startWith(minPriceInitValue),
      map(value => {
        if (parseInt(value, 10) > parseInt(this.maxPriceFilter.value, 10)) {
          const newVal = this.maxPriceFilter.value;
          // Unfortunately using 2 side-effects here but no other way...
          this.maxPriceFilter.setValue(value.toString());
          this.minPriceFilter.setValue(newVal);
          return newVal;
        } else {
          return value;
        }
      })
    );
    this.maxPriceFilter$ = this.maxPriceFilter.valueChanges.pipe(
      startWith(maxPriceInitValue),
      map(value => {
        if (parseInt(value, 10) < parseInt(this.minPriceFilter.value, 10)) {
          const newVal = this.minPriceFilter.value;
          // Unfortunately using 2 side-effects here but no other way...
          this.minPriceFilter.setValue(value.toString());
          this.maxPriceFilter.setValue(newVal);
          return newVal;
        } else {
          return value;
        }
      })
    );
    this.studentsFilter$ = this.studentsFilter.valueChanges.pipe(startWith(studentsInitValue));
    this.pageFilter$ = from(this.obsSub);

    const bedrange = this.bedNumberArr.map(bed => {
      return { viewValue: bed + ' Bed', value: bed };
    });
    this.minBedsFilterTypes = [ { viewValue: 'No min', value: '0' }, { viewValue: 'Studio', value: 'studio' }, ...bedrange ];
    this.maxBedsFilterTypes = [ { viewValue: 'No max', value: this.maxBeds }, { viewValue: 'Studio', value: 'studio' }, ...bedrange ];

    const priceRange = this.priceArr.map((price: string) => {
      return { viewValue: this.client_data.currency_symbol + parseInt(price, 10).toLocaleString()  + ' ' + this.rent_label_abbreviation.toUpperCase(), value: price };
    });
    this.minPriceFilterTypes = [ { viewValue: 'No min', value: '0' }, ...priceRange ];
    this.maxPriceFilterTypes = [ { viewValue: 'No max', value: this.maxPCM }, ...priceRange ];


    // Here we listen for 'this.pageFilter$' but we don't actually use its value bacause currently I don't know how to reset it without creating a stack overflow
    this.filteredResults$ = combineLatest(
      [ this.searchStr$, this.locationFilter$, this.studentsFilter$, this.minBedsFilter$, this.maxBedsFilter$, this.minPriceFilter$, this.maxPriceFilter$, this.managerFilter$, this.pageFilter$, this.typeFilter$]
    ).pipe(
      tap(([searchString, locationFilterString, students, minBedsFilterNum, maxBedsFilterNum, minPriceFilterNum, maxPriceFilterNum, managerFilterString,pageFilter,type]: any[]) => {
        this.navigateWithQueryParams(locationFilterString, managerFilterString, students, minBedsFilterNum, maxBedsFilterNum, minPriceFilterNum, maxPriceFilterNum, searchString,type);
        this.lastPage = (this.currentPage > this.lastPage) ? this.currentPage : 0;
        if (this.lastPage === 0) {
          this.currentPage = 0;
        }
      }),
      flatMap(([ searchString, locationFilterString, students, minBedsFilterNum, maxBedsFilterNum, minPriceFilterNum, maxPriceFilterNum, managerFilterString,pageFilter,type]: any[]) => {
        const minBedsOrStudio = minBedsFilterNum === 'studio' ? 1 : minBedsFilterNum;
        const maxBedsOrStudio = maxBedsFilterNum === 'studio' ? 1 : maxBedsFilterNum;
        return this.searchAlgoliaProperties(
          searchString, locationFilterString, students, minBedsOrStudio, maxBedsOrStudio, minPriceFilterNum, maxPriceFilterNum, managerFilterString, this.currentPage, null, MIN_METER_RADIUS, window.innerWidth >= 1024 ? 15 : 9, type,
        );
      }),
      tap((properties: any) => {
        this.totalProperties = properties.nbHits;
        this.showLoadMore = (properties.nbPages > 1) && (properties.page < properties.nbPages - 1) ;
      }),
      map((properties: any) => properties.hits),
      tap((properties: any) => {
        const otherProperties$ = this.searchAlgoliaProperties( '', 'all', false, this.minBeds, this.maxBeds, this.minPCM, this.maxPCM, undefined );
        const userSearchStrProp = properties[0];

        let nearbyProperties$: Observable<{ hits: [] }>;
        if (properties.length === 1) {
          const { lat, lng } = userSearchStrProp.address;
          const aroundLatLng = lat ? `${lat}, ${lng}` : null;
          const searchRadiusMiles = 2; // Would be a param in the future when user UI
          const searchRadiusAsKilometers = (searchRadiusMiles * MILES_TO_METERS_MULTIPLIER);
          nearbyProperties$ = this.searchAlgoliaProperties( '', 'all', false, this.minBeds, this.maxBeds, this.minPCM, this.maxPCM, undefined, 0, aroundLatLng, searchRadiusAsKilometers );
        } else {
          nearbyProperties$ = of({ hits: [] });
        }

        this.consideredResults$ = nearbyProperties$.pipe(
          // if there are enough nearby properties (to provide a good selection) omit other properties
          flatMap((nearProps: any) => iif(() => (nearProps.hits > this.MIN_NEARBY_PROPS),
            combineLatest([ nearbyProperties$, of({ hits: [] }) ]),
            combineLatest([ nearbyProperties$, otherProperties$ ])
          )),
          map(([ nearbyProperties, otherProperties ]: any[]) => {
            // Filter out the single searchstr property
            const nearbyHits: any[] = userSearchStrProp ? nearbyProperties.hits.filter((hit: any) => hit.property_id !== userSearchStrProp.property_id) : nearbyProperties.hits;
            // filter out nearby props we will show from the final Other results (inc the single searchstr property)...
            const others: any[] = userSearchStrProp ? otherProperties.hits.filter((hit: any) => hit.property_id !== userSearchStrProp.property_id) : otherProperties.hits;
            const otherHits = _.pullAllBy(others, nearbyHits, 'property_id');

            const userSearchAddress = userSearchStrProp ? (userSearchStrProp.address.thoroughfare || userSearchStrProp.address.first_line_address) : null;
            return {
              ...userSearchAddress && { userSearchAddress },
              nearbyHits,
              otherHits
            };
          })
        );
      })
    );

    this.allCities$ = this.getAlgoliaCities().pipe(
      map((data: any) => {
        const allCities = data.facetHits.map((city: any) => {
          return {
            viewValue: this._titlecasePipe.transform(city.value),
            value: city.value.toLowerCase()
          };
        });

        const ALL = {
          viewValue: 'All',
          value: 'all'
        };
        return [ ALL, ...allCities ];
      })
    );

    this.combinedResults$ = this.filteredResults$.pipe(
      // Here we combine the last results set with the requested page set to provide a lazy loaded results list in the UI
      scan((acc, curr) => (this.currentPage === 0) ? [...curr] : [...acc,  ...curr], [])
    );

    this.navEvents$ = this._router.events.pipe(
      filter((event: any) => event instanceof NavigationEnd),
      tap(() => {
        if (!this.isDesktop) {
          this.controls.nativeElement.scrollIntoView(true);
        }
      })
    );
  }

  loadMore(): void {
    this.currentPage++;
    this.obsSub.next(this.currentPage);
  }

  navigateWithQueryParams(
    location: string, manager: string, students: boolean,
    minbeds: string = this.minBeds,
    maxbeds: string = this.maxBeds,
    minprice: string = this.minPCM,
    maxprice: string = this.maxPCM,
    search: string,
    type:any
  ): void {
    this._router.navigate( ['.'], {
      queryParams: {
        location,
        students,
        minbeds,
        maxbeds,
        minprice,
        maxprice,
        manager,
        search,
        type:type
      },
      queryParamsHandling: 'merge',
      relativeTo: this._activatedRoute,
      // replaceUrl: true
    });
    this.paramsDirty = !this.checkDefaults();
  }

  checkDefaults() {
    return this.searchStr.value.length === 0 &&
      this.locationFilter.value === 'all' &&
      this.minBedsFilter.value === '0' &&
      this.maxBedsFilter.value === this.maxBeds &&
      this.minPriceFilter.value === '0' &&
      this.maxPriceFilter.value === 'noMax' &&
      this.studentsFilter.value === false &&
      !this.managerFilter.value && this.typeFilter.value === 'all';
  }

  navigateClearParams() {
    this.searchStr.setValue('');
    this.locationFilter.setValue('all');
    this.clearBedsRange();
    this.clearPriceRange();
    this.studentsFilter.setValue(false);
    this.managerFilter.setValue(undefined);
    this.sharedFilter.typeFilter.setValue('all');
     this.sharedFilter.searchStr.setValue('');
    this.paramsDirty = false;
    setTimeout(() => {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    });
  }

  clearPriceRange(): void {
    this.maxPriceFilter.setValue('noMax');
    this.minPriceFilter.setValue('0');
    this.paramsDirty = false;
  }

  clearBedsRange(): void {
    this.maxBedsFilter.setValue(this.maxBeds);
    this.minBedsFilter.setValue('0');
    this.paramsDirty = false;
  }

  searchAlgoliaProperties(
    query: string, location: string, studentOnly: boolean, minBeds: string, maxBeds: string, minPCM: string, maxPCM: string, managerId: string,
    page: number = 0,
    aroundLatLng: string = null,
    aroundRadius: number = MIN_METER_RADIUS,
    hitsPerPage: number = (window.innerWidth >= 1024 ? 15 : 9),
    type: any = this.typeFilterData.type

  ): Observable<any> {

    let filters = `is_listed=1`;
    if(type !== 'all'){
      filters+= ` AND (campus:"${type}")`;
    }
    if (location !== 'all') {
      filters += ` AND (number_bedrooms:${minBeds} TO ${maxBeds})`; // must use simple or double quotes when value has spaces
    } else {
      filters += ` AND (number_bedrooms:${minBeds} TO ${maxBeds})`;
    }

    if (managerId) {
      filters += ` AND (manager_uid:'${managerId}')`;
    }

    if (studentOnly) {
      filters += ' AND (tenant_preference:either OR tenant_preference:student)';
    } else {
      filters += ' AND (tenant_preference:either OR tenant_preference:professional OR tenant_preference:student)';
    }

    if (minPCM && (minPCM === maxPCM)) {
      // filters += ` AND (household_rent_pcm:${minPCM}) OR (hmo_min_pcm <= ${minPCM} AND hmo_max_pcm >= ${minPCM})`;
      // https://www.algolia.com/doc/api-reference/api-parameters/filters/?language=javascript
      filters += ` AND (household_rent_pcm:${minPCM})`;
    } else if (maxPCM === 'noMax') {
      filters += ` AND (household_rent_pcm >= ${minPCM}) OR (hmo_min_pcm >= ${minPCM}) OR (hmo_max_pcm >= ${minPCM})`;
    } else {
      filters += ` AND (household_rent_pcm:${minPCM} TO ${maxPCM}) OR (hmo_min_pcm:${minPCM} TO ${maxPCM}) OR (hmo_max_pcm:${minPCM} TO ${maxPCM})`;
    }
    // Return 9 properties per page (load more) for mobile users and 15 for desktop
    return from(this.algoliaIndex.search({
      hitsPerPage,
      filters,
      page,
      ...query && { query },
      ...aroundLatLng && { aroundLatLng },
      ...aroundRadius && { aroundRadius }
    }).then((res: any[]) => {
      console.log({res});
      return res;
    }));
  }


  getAlgoliaCities() {
    return from(this.algoliaIndex.searchForFacetValues({
      facetName: 'address.city',
      facetQuery: '*',
    }).then(res => {
      return res;
    }));
  }

  setSearchQuery(e: any) {
    const query = e.target.value;
    return from(this.algoliaIndex.search({
      filters: `is_listed=1`,
      query,
      facets: [
        'address.city'
      ],
    }).then(res => {
      return res;
    }));
  }

  scrollFocus(btn: any, menu: string): void {
    if (!this.isDesktop && !this.menuSubject.value) {
      this.controls.nativeElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
      setTimeout(() => {
        btn.scrollIntoView({ behavior: 'smooth' });
      }, 300);
    }
    this.menuSubject.next(menu);
  }

  scrollBlur(menu: string) {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    if (menu === this.menuSubject.value) {
      this.menuSubject.next('');
    }
  }
  updateBehaviourSubjects(event: any) {
    event.type && this.typeFilter.value !== event.type ? this.typeFilter.patchValue(event.type) : '';
    this.searchStr.value !== event.search ? this.searchStr.patchValue(event.search) : '';
 }
}

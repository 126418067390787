import {ApplicationRef, Injectable} from '@angular/core';
import {SwUpdate} from '@angular/service-worker';
import {concat, interval} from 'rxjs';
import {first, switchMap} from 'rxjs/operators';

@Injectable()
export class PWAService {

  constructor(appRef: ApplicationRef, public updates: SwUpdate) {
    console.log('Service worker is enabled: ', updates.isEnabled);
    if (updates.isEnabled) {
      const appIsStable$ = appRef.isStable.pipe(first(isStable => isStable === true));
      const checkUpdates$ = interval(1 * 60 * 1000); // each minute
      concat(appIsStable$, checkUpdates$)
        .pipe(switchMap(() => updates.checkForUpdate()))
        .subscribe(() => {console.log('Checking for updates...'); });
    }
  }

  public checkForUpdates(): void {
    this.updates.available.subscribe(event => {
      console.log('current version is', event.current);
      console.log('available version is', event.available);
      this.promptUser(event);
    });
  }

  private promptUser(e: any): void {
    console.log('updating to new version', e);
    this.updates.activateUpdate().then(() => document.location.reload());
  }
}

import { Component, OnInit, forwardRef, Optional, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR, NG_VALIDATORS, Validator, FormGroupDirective, FormGroup, FormControl, Validators } from '@angular/forms';
import { AbstractValueAccessor } from '@app/shared/_components/searches/instant-search/instant-search.utils';
import { FormsService } from '@app/services/form.service';
import { FORM_UNIVERSITY_DETAILS, FORM_RENTAL_ACADEMIC_YEAR } from './application-forms.constants';

@Component({
  selector: 'form-university-details',
  template: `
  <form [formGroup]="formGroup" autocomplete="none" *ngIf="isStudent">
        <bunk-form-field *ngIf="getFormControl('university_name')">
        <label bunk-label>What is your university's name?</label>
          <mat-form-field bunk-input appearance="outline">
            <input formControlName="university_name" matInput type="text" name="university_name" placeholder="E.g Southampton">
            <mat-error>Please enter the name of your university</mat-error>
          </mat-form-field>
        </bunk-form-field>

        <bunk-form-field *ngIf="getFormControl('course_name')">
        <label bunk-label>What course do you study?</label>
          <mat-form-field bunk-input appearance="outline">
            <input formControlName="course_name" matInput type="text" name="course_name" placeholder="E.g Advertising">
            <mat-error>Please enter the name of your course</mat-error>
          </mat-form-field>
        </bunk-form-field>

        <bunk-form-field *ngIf="getFormControl('course_end_year')">
        <label bunk-label>What year does your course end?</label>
          <mat-form-field bunk-input  appearance="outline" class="bottom-border">
            <input formControlName="course_end_year" matInput type="text" name="course_end_year" placeholder="E.g 2022">
            <mat-error>Please enter your course end year</mat-error>
          </mat-form-field>
        </bunk-form-field>

      <bunk-form-field *ngIf="getFormControl('student_id')">
        <label bunk-label>What is your student ID?</label>
        <mat-form-field bunk-input  appearance="outline" class="bottom-border">
          <input formControlName="student_id" matInput type="text" name="student_id" placeholder="E.g 14003886">
          <mat-error>Please enter your student id</mat-error>
        </mat-form-field>
      </bunk-form-field>

      <bunk-form-field *ngIf="getFormControl('rental_academic_year')">
        <label bunk-label >What academic year are you in?</label>
        <mat-form-field bunk-input appearance="outline">
          <mat-select formControlName="rental_academic_year" placeholder="Select">
            <mat-option *ngFor="let year of rental_academic_years" [value]="year.value">
              {{year.viewValue}}
            </mat-option>
          </mat-select>
          <mat-error>Please select who you are searching with</mat-error>
        </mat-form-field>
      </bunk-form-field>

  </form>

  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormUniversityDetailsComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => FormUniversityDetailsComponent),
      multi: true
    }
  ]
})
export class FormUniversityDetailsComponent extends AbstractValueAccessor implements OnInit, Validator {
  @Input() requiredFields = FORM_UNIVERSITY_DETAILS;
  @Input() isStudent = true;
  public formGroup: FormGroup;
  private _submitAttempted = false;
  public rental_academic_years = FORM_RENTAL_ACADEMIC_YEAR;
  @Input('submitAttempted') set submitAttempted(boo: boolean) {
    this._submitAttempted = boo;
    if (boo) {
      this.dirtyFormGroup();
    }
  }
  get submitAttempted(): boolean {
    return this._submitAttempted;
  }

  @Input()
  get student(): any {
    return this.isStudent;
  }
  set student(event: any) {
    if (event !== null) {
      this.isStudent = event;
      this.formGroup = this._forms.toFormGroup(this.requiredFields);
      this.setValidators(this.isStudent);
    }
  }

  constructor(
    @Optional() public _reactiveForm: FormGroupDirective,
    private _forms: FormsService
  ) {
    super();
  }

  dirtyFormGroup() {
    if(this.formGroup ){
    const controls = this.formGroup.controls;
    for (const control in controls) {
      if (controls.hasOwnProperty(control)) {
        this.formGroup.controls[control].markAsTouched();
      }
    }
  }
  }

  getFormControl(name: string): FormControl {
    return this.formGroup.get(name) as FormControl;
  }

  ngOnInit(): void {
    this.formGroup = this._forms.toFormGroup(this.requiredFields);
    this.formGroup.addControl('bunk_passport_reference_id', new FormControl(''));
    this._reactiveForm.ngSubmit.subscribe((data: Event) => {
      this.submitAttempted = true;
      const controls = this.formGroup.controls;
      for (const control in controls) {
        if (controls.hasOwnProperty(control)) {
          this.formGroup.controls[control].markAsTouched();
        }
      }
    });
  }

  setValidators(selectValue: any) {
    if (selectValue === false) {
      for (const field of this.requiredFields ) {
        if (this.formGroup.get(field)) {
          this.formGroup.removeControl(field);
          this.formGroup.updateValueAndValidity();
        }
      }
    } else {
      for (const field of this.requiredFields ) {
        if (this.formGroup.get(field)) {
          this.formGroup.addControl(field, new FormControl(null, Validators.required));
          this.formGroup.get(field).updateValueAndValidity();
          this.formGroup.updateValueAndValidity();
        }
      }
    }
  }

  public onTouched: () => void = () => { };

  writeValue(val: any): void {
    super.writeValue(val);
    super.writeValue(val);
    if (val) {
      this.formGroup.patchValue(val, { emitEvent: false });
    }

    this.setValidators(this.student)

  }
  registerOnChange(fn: any): void {
    this.formGroup.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.formGroup.disable() : this.formGroup.enable();
  }

  validate() {
    return this.formGroup.valid ? null : { invalidForm: { valid: false, message: 'FormUniversityDetailsComponent > formGroup fields are invalid' } };
  }

}

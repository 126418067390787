<screen-detection (resized)="isDesktop = $event.isDesktop"></screen-detection>
<div class="main__wrapper main__wrapper--signin" [hidden]="hideForm">
  <div class="signin__section signin__section--background">
    <div class="logo">
      <a href="{{client_data.website_url}}"> <img src="/assets/logos/main-logo.svg" alt="{{client_data.company_name}}">
      </a>
    </div>
  </div>

  <div class="signin__section">

    <div class="signin__form">

      <span class="signup__back-btn" type="button" *ngIf="isPasswordForgotten" (click)="toggleIsPasswordForgotten()">
        <mat-icon svgIcon="chevron-left"></mat-icon>
      </span>

      <h1 class="margin-bottom">Activate your account for {{client_data.company_name}}</h1>

      <form [formGroup]="formGroup" (ngSubmit)="activateAccount()">

        <mat-form-field appearance="outline" *ngIf="!email.value">
          <mat-label>Email address</mat-label>
          <input type="text" matInput [formControlName]="'email'" (blur)="trim('email')">
          <mat-error *ngIf="email.hasError('required')">Email is required</mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Password</mat-label>
          <input type="password" matInput [formControlName]="'password'" (blur)="trim('email')">
          <mat-error>Password is required</mat-error>
        </mat-form-field>
        <small bunk-label-stack class="flex">
          <ng-container *ngTemplateOutlet="password"></ng-container>
        </small>
        <button id="user-signin" class="btn__generic btn--lg margin-top--md signin__button" type="submit"
          [disabled]="showSpinner"> <span *ngIf="!showSpinner; else spinner">Activate Account</span></button>
      </form>
      <ng-template #password>
        <ul class="ul-reset password-strength" *ngIf="passwordStrength$ | async as passwordStrength">
          <li>At least 8 characters <span class="status__tick" *ngIf="passwordStrength.hasLength"></span></li>
          <li>Includes a uppercase <span class="status__tick" *ngIf="passwordStrength.hasUppercase"></span></li>
          <li>Includes a lowercase letter <span class="status__tick" *ngIf="passwordStrength.hasLowercase"></span></li>
          <li>Includes a number <span class="status__tick" *ngIf="passwordStrength.hasNumber"></span></li>
        </ul>
      </ng-template>
    </div>
  </div>
</div>

<ng-template #spinner>
  <processing-spinner color="#FFFFFF"></processing-spinner>
</ng-template>
import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { environment } from '@env/environment';
import { UserService } from '@app/services';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';

@Component({
  selector: 'bank-account-form',
  template: `
  <div class="payment__card">
    <form [formGroup]="bankAccountForm">

      <ng-content #header></ng-content>
      <h3 class="text--md" *ngIf="title" data-test="defaultAccountTitle">{{title}}</h3>
      <p *ngIf="subtitle" data-test="defaultAccountSubtitle">{{subtitle}}</p>

      <strong class="flex margin-bottom--sm margin-top--sm text--md">Add new payout bank</strong>

      <div class="cc">

        <mat-checkbox [checked]="business_type.value === 'company'"
         (change)="updateBusinessType($event)"
         [disabled]="intentProcessed  && !clear">
          <mat-label>Business account</mat-label>
        </mat-checkbox>


        <mat-form-field appearance="outline" class="w_100">
          <input matInput formControlName="account_holder_name"
                 type="text"
                 [placeholder]="business_type.value === 'company' ? 'Company name':'Account holder name'">
            <mat-error>Please add the account holders name</mat-error>
        </mat-form-field>


        <mat-form-field appearance="outline" class="w_100">
          <input matInput formControlName="account_number"
                 type="text"
                 placeholder="Bank account number"
                 id="account_number"
                 maxlength="12"
                 data-test="bankAccountInput">
            <mat-error>Please enter your bank account number</mat-error>
            <mat-error *ngIf="account_number.touched && account_number.hasError('maxLength')"> Must be 8 digits long</mat-error>
        </mat-form-field>

      </div>

      <div class="card-actions__container">

        <div>
          <mat-form-field class="w_100" appearance="outline" >
            <input matInput formControlName="sort_code"
                   type="text"
                   placeholder="Sort code"
                   id="sort_code"
                   maxlength="9"
                   data-test="sortCodeInput">
          <mat-error>Please enter your bank sort code</mat-error>
          <mat-error *ngIf="sort_code.touched && account_number.hasError('maxLength')"> Must be 6 digits long</mat-error>

          </mat-form-field>
        </div>


        <div class="rc">
        <mat-checkbox
          formControlName="default_for_currency"
          [disabled]="intentProcessed  && !clear"
          [value]="isDefault" data-test="defaultAccountCheckbox">Default account</mat-checkbox>
        </div>

        <div>
          <processing-spinner *ngIf="processingIntent || awaitingCompletion" color="#FFFFFF" class="margin-top--sm"></processing-spinner>
          <span *ngIf="intentProcessed && !clear" class="status__tick status__tick--lg margin-top--sm"></span>


          <button *ngIf="!processingIntent && !intentProcessed" type="button"
          (click)="submit(bankAccountForm.value)"
          class="btn__seethrough btn--md btn--round margin-top--sm" data-test="confirmButton">
          <mat-icon>add</mat-icon>
          <span>Add bank account</span>
          </button>
        </div>
      </div>



    </form>
    </div>`,
  styleUrls: ['./payment-forms.component.scss']
})
export class BankAccountFormComponent implements OnInit{
  @Output() emitter = new EventEmitter<any>();
  @Input() title: string;
  @Input() subtitle: string;
  @Input() disabled: Subject<boolean>; // disabled event to re-enable the form if setupComplete emission is used by parent
  @Input() clear: Subject<boolean>; // form reset event to empty and reset the form if setupComplete emission is used by parent


  public stripe = Stripe(environment.stripeConfig.publicKey);
  public isDefault = false;
  public error: string;
  public bankAccountForm: FormGroup;
  public processingIntent = false;
  public intentProcessed = false;
  public awaitingCompletion = false;

  constructor(private _user: UserService,
              private _toastr: ToastrService,
              private _formBuilder: FormBuilder
              ) {
    this.bankAccountForm = this.initFormGroup();

  }

  get account_number(): FormControl {
    return this.bankAccountForm.get('account_number') as FormControl;
  }

  get sort_code(): FormControl {
    return this.bankAccountForm.get('sort_code') as FormControl;
  }

  get account_holder_name(): FormControl {
    return this.bankAccountForm.get('account_holder_name') as FormControl;
  }

  get default_for_currency(): FormControl {
    return this.bankAccountForm.get('default_for_currency') as FormControl;
  }

  get business_type(): FormControl {
    return this.bankAccountForm.get('business_type') as FormControl;
  }

  initFormGroup(): FormGroup {
    return this._formBuilder.group({
      account_number: [null, [Validators.required, Validators.maxLength(12)]],
      sort_code: [null, [Validators.required, Validators.maxLength(6), Validators.minLength(6)]],
      account_holder_name: ['', [Validators.required]],
      default_for_currency: [false],
      business_type: ['individual']
    });
  }

  async submit(form: any) {

    if (this.bankAccountForm.valid) {
    this.processingIntent = true;
    this.bankAccountForm.disable();


    const tokenData = {
      account_holder_name: form.account_holder_name,
      account_holder_type: form.business_type,
      country: 'GB',
      currency: 'gbp',
      account_number: form.account_number,
      routing_number: form.sort_code
    };
    const { token, error } = await this.stripe.createToken('bank_account', tokenData);
    if (error) {
      this.processingIntent = false;
      this.awaitingCompletion = false;
      this.bankAccountForm.enable();
      this._toastr.error('Please enter valid account details');
    } else {
      this.processingIntent = false;
      this.intentProcessed = true;
      this.awaitingCompletion = !!this.clear;
      const item = { token, default_for_currency: form.default_for_currency };
      this.emitter.emit(item);
      console.log({item});
    }
  }
  }


  ngOnInit() {
    if (this.clear) {
      this.clear.subscribe(clear => {
        if (clear) {
          this.bankAccountForm.reset();
          this.bankAccountForm.markAsPristine();
          this.bankAccountForm.markAsUntouched();
          this.bankAccountForm.enable();
          this.intentProcessed = false;
          this.awaitingCompletion = false;
        }
        console.log('clear the card form, reset', clear);
      });
    }

    if (this.disabled) {
      this.disabled.subscribe(disabled => {
        (disabled) ? this.bankAccountForm.disable() : this.bankAccountForm.enable();
      });
    }
  }

  updateBusinessType(event) {
    if (event.checked) {
      this.business_type.patchValue('company');
    } else {
      this.business_type.patchValue('individual');
    }
  }
}

import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { BunkPassportService } from '@app/services';

import { Tenant, Profile } from '@env/routing';
import { Observable, Subject, of } from 'rxjs';
import { tap, takeUntil, flatMap, map, catchError} from 'rxjs/operators';
import { TenantVerifyEventEmiterService } from '../profile-tenant.service';
import { TenantAccountStepComponent } from './tenant-account-step.component';
import { AuthService } from '@app/core/services';
import { COUNTRY_CODES } from '@app/_constants/forms.constants';
import { TRANSLATIONS } from '@app/_constants/translations.constants';


@Component({
  selector: 'tenant-account-verify-previous-tenancy',
  template: ` 
      <div class="margin-bottom--md">
        <h2 class="text--lg">Previous {{landlord}} details</h2>
        <em >Please note we will not contact your {{landlord}} until an offer has been accepted on a property.</em>
      </div>

      <form [formGroup]="formGroup" (ngSubmit)="nextStep(formGroup.value)" class="flex flex-column" >

      <form-previous-tenancy formControlName="previous_tenancy"></form-previous-tenancy>
    
        <div class="margin-bottom--xl">
          <button *ngIf="showPrev" (click)="goBack()" data-test="backButton" class="btn__seethrough btn__form-prev">Back</button>
          <button class="btn__generic btn__form-submit btn__form-submit--align-right" type="submit">
            <span *ngIf="!submittingData && !submittingDataSuccess">Save<span *ngIf="!standAlone"> and continue</span></span>
            <processing-spinner *ngIf="submittingData || submittingDataSuccess" color="#FFFFFF" [complete]="submittingDataSuccess"></processing-spinner>
          </button>
        </div>
      </form>

  `,
  styleUrls: ['./tenant-account-step.component.scss'],
  
})
export class TenantAccountVerifyPreviousTenancyComponent extends TenantAccountStepComponent implements OnInit, OnDestroy {
  @Input() standAlone = false;
  @Input() reference?: any;
  public formGroup: FormGroup;
  public queryParams: string;
  public routes = { Tenant };
  public reference$: Observable<any>;
  public references$: Observable<any>;
  public referenceId$: Observable<any>;
  public passport$: Observable<any>;
  public formControls;
  public countryCodes;

  private destroy$: Subject<boolean> = new Subject<boolean>();
  public landlord = TRANSLATIONS.landlord;

  constructor(
    _verifyEvent: TenantVerifyEventEmiterService,
    private _formBuilder: FormBuilder,
    private _router: Router,
    private _route: ActivatedRoute,
    private _auth: AuthService,
    public _passport: BunkPassportService
  ) {
    super(_verifyEvent);
    this.formGroup = this.initFormGroup();
    this.queryParams = _route.snapshot.queryParams.tenancy_offer_id;
    this.countryCodes = COUNTRY_CODES.sort(); 
  }


  initFormGroup(): FormGroup {
    return this._formBuilder.group({
      previous_tenancy: [null]
    });
  }

  preloadFormGroup(form: any): void {
    const item = {
      previous_address: form.previous_address,
      referee_name: form.referee_name,
      referee_email: form.referee_email,
      referee_phone: {
        phone_number: form.referee_phone,
        area_code: form.referee_country_code
      },
      tenancy_duration_months: form.tenancy_duration_months,
      monthly_rent_paid: form.monthly_rent_paid,
      has_previous_tenancy: form.has_previous_tenancy,
      end_of_tenancy_date: form.end_of_tenancy_date ? form.end_of_tenancy_date.toDate() : null
    };
    this.formGroup.get('previous_tenancy').patchValue(item);
  }

  setValidators() {
    for (const form of this.formControls) {
      this.formGroup.get('previous_tenancy').valueChanges
        .pipe(takeUntil(this.destroy$))
        .subscribe((previous) => {
          if (previous === true) {
            form.name.setValidators([Validators.required]);
          } else {
            form.name.clearValidators();
          }
          form.name.updateValueAndValidity();
        });
    }
  }


  nextStep(form: any) {
    console.log(form, 'next step');
    const reference_id = this.reference ? this.reference.bunk_passport_reference_id : null;

    const finalForm = {
      previous_tenancy : {
        ...form.previous_tenancy,
        referee_phone: form.previous_tenancy.referee_phone ? form.previous_tenancy.referee_phone.phone_number : null,
        referee_country_code: form.previous_tenancy.referee_phone ? form.previous_tenancy.referee_phone.area_code : null,
        bunk_passport_reference_id: reference_id,
      }
     
    };

    this.submittingData = true;
    this.formGroup.disable();  
    // passing in a referenceId if it exists - cloud function deals with updating
    this._passport.updateUserReferenceData(finalForm)
    .pipe(
      takeUntil(this.destroy$),
      catchError(err => of([]))
    )
    .subscribe(
      res => {
        this.stepComplete();
      },
      err => {
        console.log('HTTP Error', err);
        this.stepError();
      },
      () => {
        this.formGroup.enable();
        console.log('HTTP request completed.');
      }
    );
  }


  goBack() {
    this._verifyEvent.navigateStep('prev');
  }

  ngOnInit() {
    console.log(this.reference);
    if (this.reference) {
      this.preloadFormGroup(this.reference.reference_data)
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}

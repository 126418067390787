import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'bunk-input-uploader',
  template: `
    <label for="{{id}}" class="document-upload">
      +
      <input type="file" (change)="handleChange($event)" id="{{id}}">
    </label>
  `,
  styleUrls: ['bunk-input-uploader.component.scss']
})
export class BunkInputUploaderComponent {
  @Input() id: string;
  @Output() change: EventEmitter<Event> = new EventEmitter<Event>();

  handleChange = (event: Event): void => this.change.emit(event);
}



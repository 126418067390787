import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { BunkOverlayComponent } from './bunk-overlay.component';

@NgModule({
  imports: [CommonModule],
  exports: [BunkOverlayComponent],
  declarations: [BunkOverlayComponent],
  providers: [],
})
export class BunkOverlayModule { }

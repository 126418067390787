import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { MatSelectModule, MatFormFieldModule, MatInputModule } from '@angular/material';
import { BunkFormFieldModule } from '../form-field-wrapper';

import { FormGetTenancyDetailsComponent } from './form-get-tenancy-details.component';
import { FormGetPropertyDetailsModule } from '../form-get-property-details';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,

    MatSelectModule,
    MatFormFieldModule,
    MatInputModule,

    BunkFormFieldModule,
    FormGetPropertyDetailsModule
  ],
  exports: [FormGetTenancyDetailsComponent],
  declarations: [FormGetTenancyDetailsComponent],
  providers: [],
})
export class FormGetTenancyDetailsModule { }

<screen-detection (resized)="isDesktop = $event.isDesktop"></screen-detection>
<ng-container *ngIf="this.activeTenancies$ | async"> </ng-container>

<button class="nav-sidebar__btn" [ngClass]="{ 'nav-sidebar__btn--hidden': isDesktop || sidenav.opened }"
  (click)="handleOpenMenu()">
  <mat-icon svgIcon="bunk-icon-burger"></mat-icon>
</button>
<mat-sidenav-container class="nav-sidebar__bg-redundant" (backdropClick)="handleCloseMenu()">
  <mat-sidenav #sidenav [fixedInViewport]="true" (keydown.escape)="handleCloseMenu()" [opened]="isDesktop"
    [mode]="isDesktop ? 'side' : 'over'" disableClose>
    <div class="nav-sidebar__menu">
      <div>
        <div class="nav-sidebar__menu-logo">
          <div class="nav-sidebar__menu-container">
            <img alt="bunk" src="/assets/logos/white-logo.svg" [routerLink]="['/']" />
            <button class="nav-sidebar__btn-close" (click)="handleCloseMenu()">
              <mat-icon svgIcon="icon-close"></mat-icon>
            </button>
          </div>
          <div *ngIf="hasBackHome" class="nav-sidebar__btn-back" (click)="goNrlaHome()">
            <mat-icon svgIcon="chevron-left"></mat-icon>
            Back to NRLA Home
          </div>
        </div>
        <nav class="nav-sidebar__nav" *ngIf="showDashboard()">
          <ul *ngIf="navItems" class="flex flex-column items-left">
            <ng-container *ngIf="adminRoutes$ | async"></ng-container>
            <div>
              <ng-container *ngFor="let item of navItems; let i = index">
                <hr *ngIf="item.label === 'Admin'" class="nav-border-top hide-sm hide-md" />

                <li [id]="item.id" class="nav-sidebar__nav-item" [attr.aria-label]="item.label"
                  routerLinkActive="active" #rla="routerLinkActive" [routerLink]="item.routerLink"
                  (click)="!isDesktop && handleCloseMenu()" (mouseenter)="navbarListHovers[i] = true"
                  (mouseleave)="navbarListHovers[i] = false">
                  <mat-icon [svgIcon]="item.svg"></mat-icon>
                  <span class="nav-sidebar__nav-item-label">
                    {{ item.label
                    }}<span class="nav-sidebar__nav-item-brackets"
                      *ngIf="item.bracketsValue$ | async as bracketsValue">
                      ({{ bracketsValue }})</span>
                  </span>
                  <counter *ngIf="item.counterValue" [value]="item.counterValue" wide="true"></counter>
                </li>
              </ng-container>
            </div>
          </ul>

          <div class="nav-sidebar__trans-content">
            <ng-content></ng-content>
          </div>
        </nav>
      </div>
      <ng-container *ngIf="showDashboard() && isLoading">
        <div class="nav-sidebar__bottom-menu-logo" *ngIf="client_data.company_name !== 'Bunk'">
          <img alt="bunk" src="/assets/svg/logos/powered-by-bunk.svg" (click)="generateUTM()" />
        </div>
      </ng-container>
    </div>
  </mat-sidenav>
</mat-sidenav-container>

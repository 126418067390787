export enum REFERENCE_STATUS {
  INCOMPLETE = 'incomplete',
  PENDING_SIGNATURE = 'pending_signature',
  DECLINED = 'declined',
  COMPLETE = 'complete',
}

export namespace REFERENCE_STATUS {
  const REFERENCE_STATUS_VIEW = {
    INCOMPLETE: 'Unsent',
    PENDING_SIGNATURE: 'Pending signature',
    DECLINED: 'Declined',
    COMPLETE: 'Complete'
  };

  export function view(status: REFERENCE_STATUS): string {
    return REFERENCE_STATUS_VIEW[status.toUpperCase()];
  }
}




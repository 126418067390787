import { Component, OnDestroy, ViewChild } from '@angular/core';
import { UserService } from '@app/services/user.service';
import { Tenant, Profile } from '@env/routing';
import { of, throwError as _throw, Subject, EMPTY, timer } from 'rxjs';
import { takeUntil, catchError, expand, debounce } from 'rxjs/operators';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { TenantVerifyEventEmiterService } from '../profile-tenant.service';
import { BunkPassportService } from '@app/services';
import { TenantAccountStepComponent } from './tenant-account-step.component';
import { ActivatedRoute, Router } from '@angular/router';
import { IdentificationService } from '@app/shared/_services/identification.service';
import { BunkDialogComponent } from '@app/shared/_components';
import {environment} from '@env/environment';

@Component({
  selector: 'tenant-account-verify-identity',
  template: `
  <form [formGroup]="formGroup" (ngSubmit)="nextStep()">
    <div>
      <h2 class="text--lg">Please verify your identity</h2>
      <br>
      <p *ngIf="verificationUnverified">Please upload a scan or clear photo of either your passport or driving licence:</p>
      <ng-container *ngIf="this._user.user.verification_status === 'failed'">
        <p>Your {{verifiedDocumentType === 'passport' ? 'passport' : 'driving licence'}} verification was rejected.</p>
        <p>Contact {{client_data.company_name}} for assistance <a href="mailto:{{client_data.support_email}}">{{client_data.support_email}}</a></p>
      </ng-container>

      <ng-container *ngIf="verificationStatusPending">
        <p>We're busy confirming your identity.<br>Please continue with next steps...</p>
      </ng-container>

      <ng-container *ngIf="verificationApproved">
        <p>Your identity has been successfully verified.</p>
      </ng-container>
    </div>

    <div class="rc flex_wrap document-upload" *ngIf="this._user.user.verification_status !== 'failed'">
      <div class="cc w_100">
        <div class="action-bar">

          <label for="landlord-verify-passport-upload"
            class="verify-id__card-container"
            [hidden]="(documentType.value === 'drivers_licence' && uploadState !== '') ? 'hidden' : ''">
            <div role="button" class="btn__card" [ngClass]="{
              'btn__card--disabled': (uploadState === 'WAITING' || verificationStatusPending === true || verificationApproved === true),
              'btn__card--disabled-other': (documentType.value !== 'passport' && (uploadState === 'WAITING' || verificationStatusPending === true || verificationApproved === true))
            }">
              <div class="verify-id__card-status">
                <ng-container *ngIf="documentType.value === 'passport'; else unselectedPassport">
                  <span class="icon__add" *ngIf="(uploadState !== 'WAITING' && uploadState !== 'GREEN') && !verificationStatusPending && !verificationApproved"></span>
                  <span class="icon__tick-pending" *ngIf="verificationStatusPending"></span>
                  <span class="status__tick" *ngIf="verificationApproved"></span>
                </ng-container>
                <ng-template #unselectedPassport>
                  <span class="icon__add"></span>
                </ng-template>

                <processing-spinner
                  *ngIf="uploadState === 'WAITING' && !verificationStatusPending && !verificationApproved" color="#FFFFFF"
                  [size]="'42px'"></processing-spinner>
              </div>
              Passport
            </div>
            <input
              class="hidden"
              type="file"
              [disabled]="(uploadState === 'WAITING' || verificationStatusPending || verificationApproved)"
              id="landlord-verify-passport-upload"
              accept="image/bmp, image/tiff, image/pdf, image/jpeg, image/png"
              onclick="this.value=null;"
              (change)="documentType.setValue('passport'); uploadPictures($event)"
              [required]="true" />
          </label>


          <label for="landlord-verify-driver-upload"
            class="verify-id__card-container"
            [hidden]="(documentType.value === 'passport' && (!uploadState || uploadState !== '')) ? 'hidden' : ''">
            <div role="button" class="btn__card" [ngClass]="{
              'btn__card--disabled': (uploadState === 'WAITING' || verificationStatusPending === true || verificationApproved === true),
              'btn__card--disabled-other': (documentType.value !== 'drivers_licence' && (uploadState === 'WAITING' || verificationStatusPending === true || verificationApproved === true))
            }">
              <div class="verify-id__card-status">
                <ng-container *ngIf="documentType.value === 'drivers_licence'; else unselectedDriver">
                  <span class="icon__add" *ngIf="(uploadState !== 'WAITING' && uploadState !== 'GREEN') && !verificationStatusPending && !verificationApproved"></span>
                  <span class="icon__tick-pending" *ngIf="verificationStatusPending"></span>
                  <span class="status__tick" *ngIf="verificationApproved"></span>
                </ng-container>
                <ng-template #unselectedDriver>
                  <span class="icon__add"></span>
                </ng-template>

                <processing-spinner
                  *ngIf="uploadState === 'WAITING' && !verificationStatusPending && !verificationApproved" color="#FFFFFF"
                  [size]="'42px'"></processing-spinner>
              </div>
              Driving licence
            </div>
            <input
              class="hidden"
              type="file"
              [disabled]="(uploadState === 'WAITING' || verificationStatusPending || verificationApproved)"
              id="landlord-verify-driver-upload"
              accept="image/bmp, image/tiff, image/pdf, image/jpeg, image/png"
              onclick="this.value=null;"
              (change)="documentType.setValue('drivers_licence'); uploadPictures($event)"
              [required]="true" />
          </label>
        </div>


        <div class="margin-top--md" *ngIf="(uploadState !== 'GREEN') && !verificationStatusPending && !verificationApproved">
          <strong>Tips for being approved</strong>
          <ol>
            <li>Use a scan or a white background for best results</li>
            <li>Make sure the text is clearly visible with no blur</li>
          </ol>
        </div>
      </div>

      <bunk-dialog [component]="contentDialog" #errorDialog></bunk-dialog>

      <ng-template #contentDialog>
        <div class="verify-id__modal">
          <h2>Document upload failed</h2>
          <button class="btn__seethrough btn--round margin-top--md margin-bottom--lg" (click)="bunkDialogRef.closeAll()">Close</button>

          <p>Still failing? Contact <a href="mailto:{{client_data.support_email}}">{{client_data.support_email}}</a></p>
        </div>
      </ng-template>
    </div>

    <div class="margin-bottom--xl">
        <button *ngIf="showPrev" (click)="goBack()" data-test="backButton" class="btn__seethrough btn__form-prev">Back</button>
        <button class="btn__generic btn__form-submit btn__form-submit--align-right" type="submit" *ngIf="uploadState === 'GREEN'">
          <span>Continue</span>
        </button>
      </div>

  </form>
  `,
  styleUrls: ['./tenant-account-step.component.scss']
})
export class TenantAccountVerifyIdentityComponent extends TenantAccountStepComponent implements OnDestroy {
  @ViewChild('errorDialog', {static: false}) bunkDialogRef: BunkDialogComponent;
  public formGroup: FormGroup;
  public queryParams: string;

  private destroy$: Subject<boolean> = new Subject<boolean>();

  // state
  public error: string;
  public uploadState: string;
  public idStatus: string;

  // helpers
  public routes = { Tenant };
  public client_data = environment.client_data;

  constructor(
    public _verifyEvent: TenantVerifyEventEmiterService,
    private _fb: FormBuilder,
    private _identify: IdentificationService,
    public _user: UserService,
    public _passport: BunkPassportService,
    public _route: ActivatedRoute,
    public _router: Router
    ) {
    super(_verifyEvent);
    this.formGroup = this.createFormGroup();
    this.queryParams = _route.snapshot.queryParams.tenancy_offer_id;
    this.idStatus = 'unverified';

    if (this.verifiedDocumentType && this.verificationStatusPending) {
      this.checkResults();
    }
    if (this.verificationApproved || this.verificationStatusPending) {
      this.checkResults();
      this.uploadState = 'GREEN';
    }
  }

  get documentType(): FormControl {
    return this.formGroup.get('document_type') as FormControl;
  }

  createFormGroup(): FormGroup {
    return this._fb.group({
      document_type: [this.verifiedDocumentType ? this.verifiedDocumentType : '', Validators.required]
    });
  }

  uploadPictures(event: any) {
    // if document type has not been selected
    if (!this.documentType) {
      return;
    }
    const docType = this.documentType.value;
    // reset
    this.uploadState = 'WAITING';
    this.error = null;

    // file
    const uri = event.target.value; // URI
    const fileName = uri.replace(/^.*[\\\/]/, ''); // name file
    const reader = new FileReader();
    const file = event.target.files[0];

    if (!file) {
      return;
    }
    const mimeType = file.type;
    /* console.log('uri: ', uri);
    console.log('name: ', fileName);
    console.log('type: ', mimeType);
    console.log('File: ', file); */

    if (!uri) {
      return;
    }

    // Read as DataURL: https://developer.mozilla.org/en-US/docs/Web/API/FileReader/readAsDataURL
    reader.readAsDataURL(file);

    reader.onload = () => {
      const fileData = (<string>reader.result).split(',')[1];
      return this._identify.startCheck(fileData, docType, mimeType)
        .pipe((
          takeUntil(this.destroy$)
        ))
        .subscribe(
          (res: any) => {
            console.log(res.response);  // success
            this.idStatus = (res.response === 'success') ? 'pending' : 'document-not-recognized';

            if (res.response === 'success') {
              this.updateBunkPassport();
              this.checkResults();
              this.uploadState = 'GREEN';
              this.idStatus = 'pending';
            } else {
              this.uploadState = '';
              this.idStatus = 'document-not-recognized';
              this.bunkDialogRef.openDialog();
              this.documentType.setValue('');
            }
          },
          (err) => {
            console.log('Error: ', err);
            this.error = err.error.message;
            this.uploadState = '';
            this.bunkDialogRef.openDialog();
            this.documentType.setValue('');
          }
        );
    };
  }

  checkResults() {
    const recursiveObservable = this._identify.checkResults().pipe(
      takeUntil(this.destroy$),
      debounce(() => timer(4000)),
      expand((res) => {
        console.log(res);
        if (res.verification_status === 'failed' || 'approved') {
          this.idStatus = res.verification_status;
          console.log('checkResults: ' + res.verification_status);
          this.updateBunkPassport();
          return EMPTY;
        } else {
          return recursiveObservable;
        }
      })
    ).subscribe();
  }

  get verificationUnverified() {
    return !this._user.user.verification_status || this._user.user.verification_status === '' || this._user.user.verification_status === 'unverified';
  }

  get verificationApproved() {
    return this._user.user.verification_status === 'approved';
  }

  get verificationStatusPending() {
    return this._user.user.verification_status === 'pending' || this._user.user.verification_status === 'pending_review';
  }

  get verificationStatusFailed() {
    return this._user.user.verification_status === 'failed';
  }

  get verifiedDocumentType() {
    return (this._user.user.verification_data && this._user.user.verification_data.document_data) ? this._user.user.verification_data.document_data.document_type : undefined;
  }

  updateBunkPassport() {
    this._passport.updateBunkPassport(Profile.tenant.bunk_passport.identity).pipe(
      takeUntil(this.destroy$),
      catchError(err => of([]))
    )
    .subscribe(
      res => {},
      err => {
        console.log('HTTP Error', err);
        this.stepError();
      },
      () => {
        this.formGroup.enable();
        console.log('HTTP request completed.');
      }
    );
  }

  nextStep() {
    if (this.submittingData) {
      return;
    }
    this.submittingData = true;
    this.formGroup.disable();
    if (this.verificationStatusPending || this.verificationApproved) {
      this.stepComplete(0);
    } else {
      console.log('Looks you are not still verified!');
      this.stepError();
      // this._router.navigate(['/' + Tenant.base, Tenant.account.base, Tenant.account.verification.base, Tenant.account.verification.identity]);
    }
  }

  goBack() {
    this._verifyEvent.navigateStep('prev');
  }

  ngOnDestroy(): void {
    console.log('DESTROY:  tenant-account-verify-info.component');
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}

import {Component, Input, OnInit} from '@angular/core';


@Component({
  selector: 'landlord-card',
  template: `
    <div class="dialog-viewer_base landlord-card">
      <div class="title_4">The Landlord</div>
      <div class="rc mt_16 ai_c">
        <div class="landlord-image-container">
          <img src="{{landlord.profile_data.profile_image_url}}" alt="" class="landlord-image profile__background">
        </div>
        <div class="landlord-info cc jc_c ml_8">
          <div class="title_4">{{landlord.first_name}}</div>
          <div class="text_3 mt_4 text_grey">Joined {{landlord.account_created | date: 'MMM y' }}</div>
          <div class="landlord-rating ai_c rc mt_4">
            <span class="text_grey b_500">{{landlord.user_rating}}.00</span>
            <mat-icon color="yellow">star</mat-icon>
          </div>
        </div>
        <button class="btn btn_blue ml_8 flex_auto">Contact</button>

      </div>
    </div>
  `,
  styleUrls: ['./landlord-card.component.scss']
})
export class LandlordCardComponent implements OnInit {

  @Input() landlord: any;
  constructor() { }

  ngOnInit() {
  }

}

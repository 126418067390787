<form [formGroup]="formGroup">
  <label for="address-search" *ngIf="showLabel">{{ labelValue }}</label>
  <ng-container *ngIf="postcodeSearch">
    <ideal-postcodes-autocomplete
      id="address-search"
      [labelText]="labelValue"
      appearanceType="outline"
      [showError]="submitAttempted && formGroup.invalid && (manualForm === false)"
      (selectedAddress)="autocompleteAddress($event)" *ngIf="!isNonUKClient"
      [address]="address"
    ></ideal-postcodes-autocomplete>

      <app-address [labelText]="'Type address here'" appearanceType="outline" (selectedAddress)="autocompleteAddress($event)" *ngIf="isNonUKClient" [showError]="submitAttempted && formGroup.invalid && (manualForm === false)">
      </app-address>
    <button
      *ngIf="!manualForm && showManualFormButton"
      (click)="manualForm = true"
      class="btn__address btn__text-generic"
      [ngClass]="{'btn__address--is-invalid': !(submitAttempted && formGroup.invalid && (manualForm === false))}">Manually enter address</button>
  </ng-container>

  <div class="flex flex-column" *ngIf="!postcodeSearch ? true : manualForm">
    <mat-form-field appearance="outline">
      <mat-label>Street address</mat-label>
      <input matInput type="text" formControlName="first_line_address">
      <mat-error *ngIf="formGroup.get('first_line_address').invalid">Please enter the street address</mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Street address 2 (optional)</mat-label>
      <input matInput type="text" formControlName="second_line_address">
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>City</mat-label>
      <input matInput type="text" formControlName="city">
      <mat-error *ngIf="formGroup.get('city').invalid">Please enter the city</mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>Postcode</mat-label>
      <input matInput type="text" formControlName="post_code">
      <mat-error *ngIf="formGroup.get('post_code').invalid">Please enter the postcode</mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>County</mat-label>
      <input matInput type="text" formControlName="county">
      <mat-error *ngIf="formGroup.get('county').invalid">Please enter the county</mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Country</mat-label>
      <input matInput type="text" formControlName="country">
      <mat-error *ngIf="formGroup.get('country').invalid">Please enter the country</mat-error>
    </mat-form-field>


    <mat-form-field appearance="outline" class="w_100">
      <mat-label>Property reference</mat-label>
      <input matInput type="text" formControlName="property_ref">
      <mat-error *ngIf="formGroup.get('property_ref').invalid">Please enter the property ref</mat-error>
    </mat-form-field>
    <div class="property__form-field" *ngIf="isClientNRLA">

    <mat-form-field *ngIf="isRentSmartRequired && formGroup.get('country').value === 'Wales'" appearance="outline">
      <mat-label>Rent Smart Wales License Number</mat-label>
      <input matInput type="text" formControlName="rent_smart_no">
      <mat-error *ngIf="formGroup.get('rent_smart_no').invalid">Please enter the Rent Smart Wales License Number</mat-error>
    </mat-form-field>

    <div class="property__form-field" *ngIf="isClientNRLA && isLandlord">
      <label>Is this property an HMO?</label>
      <mat-radio-group
        formControlName="listed_as_hmo"
        class="flex flex-row margin-bottom property__form-field__item--radio"
      >
        <mat-radio-button
          [value]="true"
          class="bunk-outlined-radio"
          [class.bunk-outlined-radio--selected]="formGroup.get('listed_as_hmo').value === true"
          >Yes</mat-radio-button
        >
        <mat-radio-button
          [value]="false"
          class="bunk-outlined-radio"
          [class.bunk-outlined-radio--selected]="formGroup.get('listed_as_hmo').value === false"
        >
          No
        </mat-radio-button>
      </mat-radio-group>
    </div>

    <agm-map
            *ngIf="isLandlord && formGroup.get('lat').value && formGroup.get('lng').value"
            [latitude]="formGroup.get('lat').value"
            [longitude]="formGroup.get('lng').value"
            [zoom]="18"
    >
      <agm-marker
              [latitude]="formGroup.get('lat').value"
              [longitude]="formGroup.get('lng').value">
      </agm-marker>
    </agm-map>
    </div>
  </div>
</form>

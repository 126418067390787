import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { PropertyModel } from '@app/core-landlord/property/_models/property.model';
import { PropertyService } from '@app/services';
import { Landlord } from '@env/routing';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { FilePreviewOverlayRef } from '../file-preview-overlay/file-preview-overlay-ref';
import {addPropertySteps} from './add-property.steps';
interface SelectedProperty{
  property_id:string,
  has_completed_information: boolean
}
@Component({
  selector: 'add-property',
  templateUrl: './add-property.component.html',
  styleUrls: ['./add-property.component.scss']
})
export class AddPropertyComponent implements OnInit {
  properties$: Observable<string[]>;
  @Input() dref:FilePreviewOverlayRef;
  overRef:FilePreviewOverlayRef;
  showSpinner = false;
  previousSteps = [];
  steps = addPropertySteps;
  selectedProperty: FormControl = new FormControl(null)
  currentStep = 'initial';
  currentOption = null;
  selectedOptions = {
    initial: null,
    advertise: null,
    create_to_advertise: null,
    create: null
  }

  constructor(private _formBuilder: FormBuilder,
    private _router: Router,
    private _property: PropertyService) {
  }


  ngOnInit() {
    this.properties$ = this._property.getAllUserProperties().pipe(
      map((properties: PropertyModel[]) => {
        return properties.map((property: PropertyModel) => property.property_id);
      })
    );
  }


  goBack() {
    const previousStep = this.previousSteps.pop();
    this.currentStep = previousStep;
    this.currentOption = this.selectedOptions[previousStep];
  }


  setOption(option: string) {
    this.selectedOptions[this.currentStep] = option;
    this.currentOption = option;
  }

  goToStep(step: string) {
    if (!step) {
      if (this.currentStep === 'create_to_advertise') {
        this.createPropertyToAdvertise(this.currentOption === 'hmo');
      }
      if (this.currentOption === 'advertise-existing') {
        if (!this.selectedProperty.value) {
          this.selectedProperty.setErrors({ isEmpty: true });
          return;
        }
        else {
          this.selectedProperty.setErrors(null);
          this.dref.close();
          const hasCompleteInfo = !!this.selectedProperty.value.has_completed_information
          const routePhase = hasCompleteInfo ? "preview" : "address"
          this._router.navigate([Landlord.base, Landlord.property_list_form.base, Landlord.property_list_form[routePhase], this.selectedProperty.value.property_id ])

        }
      }
    }
    else {
      this.previousSteps.push(this.currentStep);
      this.currentStep = step;
      this.currentOption = this.selectedOptions[step] ? this.selectedOptions[step] : this.steps[step].options[0].id;
    }
  }

  createPropertyToAdvertise(isHmo: boolean) {
    this.showSpinner = true;
    this._property.initCreationProperty(isHmo).pipe(take(1))
      .subscribe(
        data => {
          this._router.navigate(['/' + Landlord.base, Landlord.property_list_form.base, Landlord.property_list_form.address, data.property_id]);
          this.showSpinner = false;
          this.dref.close();
        }
      );
  }

  getSelectedProperty(event: SelectedProperty) {
    if (event) {
      this.selectedProperty.setValue(event[0]);
      this.selectedProperty.updateValueAndValidity();
    }
  }

  routeToProperties() {
    this.dref.close()
    this._router.navigate([Landlord.base, Landlord.properties.base, Landlord.properties.summary]);
  }
}

import { EventEmitter, OnInit } from '@angular/core';
import { UserService } from '@app/services';
import { FormBuilder, Validators } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { MY_FORMATS } from '@app/shared/_services/dates.service';
import { ToastrService } from 'ngx-toastr';
import { ExpensesService } from '@app/services/expenses.service';
import { AuthService } from '@app/core/services';
import { environment } from '@env/environment';
const ɵ0 = MY_FORMATS;
export class FormCreateExpensesComponent {
    constructor(_expenses, _toastr, _formBuilder, _auth, _user) {
        this._expenses = _expenses;
        this._toastr = _toastr;
        this._formBuilder = _formBuilder;
        this._auth = _auth;
        this._user = _user;
        this.expenseTypes = [];
        this.submitAttempted = false;
        this.isValidation = false;
        this.tenants = [];
        this.expenseStatuses = [
            { viewValue: 'Paid', value: 'paid' },
            { viewValue: 'Overdue', value: 'overdue' },
        ];
        this.hideSearch = false;
        this.paidStatuses = ['paid', 'partially_paid', 'received', 'partially_received'];
        this.resetForm = false;
        this.submittingData = false;
        this.submittingDataSuccess = false;
        this.client_data = environment.client_data;
        this.closeDialog = new EventEmitter();
        this.formGroup = this.initFormGroup();
    }
    get status() {
        return this.formGroup.get('status');
    }
    get expense_type() {
        return this.formGroup.get('expense_type');
    }
    get amount() {
        return this.formGroup.get('amount');
    }
    get due_date() {
        return this.formGroup.get('due_date');
    }
    get transaction_id() {
        return this.formGroup.get('transaction_id');
    }
    get expense_reference_code() {
        return this.formGroup.get('expense_reference_code');
    }
    get timestamp_paid() {
        return this.formGroup.get('timestamp_paid');
    }
    get property_details() {
        return this.formGroup.get('property_details');
    }
    ngOnInit() {
        this.isAdmin$ = this._auth.getCurrentUserCustomClaims();
        this.moneyhubUserId = this._user.userReadOnlyDb.moneyhub_user_id;
    }
    initFormGroup() {
        return this._formBuilder.group({
            amount: [null, Validators.required],
            status: [null, Validators.required],
            expense_type: [null, Validators.required],
            due_date: [null, Validators.required],
            transaction_id: null,
            expense_reference_code: null,
            property_details: null,
            timestamp_paid: null
        });
    }
    submitForm() {
        this.property_details.setValidators([]);
        this.property_details.updateValueAndValidity();
        console.log(this.formGroup.valid);
        if (this.formGroup.valid) {
            this.submittingData = true;
            console.log(this.formGroup.getRawValue(), 'raw value');
            const property_details = this.formGroup.get('property_details').value;
            const item = Object.assign({}, this.formGroup.getRawValue(), { property_details: {
                    // being passed in from another component
                    property_id: property_details.property_details.property_id,
                    bedroom_id: property_details.property_details.bedroom_id,
                    tenancy_id: property_details.tenancy_id,
                    sender_uid: property_details.sender_uid,
                    user_reference_code: property_details.user_reference_code
                } });
            console.log(item, 'hello item');
            return this._expenses.userCreateExpense(item).pipe(finalize(() => this.submittingData = false)).subscribe(() => {
                this._toastr.success(`Expenses created successfully`);
                this.formGroup.reset();
                this.formGroup.updateValueAndValidity();
                this.resetForm = true;
                this.closeDialog.emit(true);
                this.submittingDataSuccess = true;
            }, (err) => {
                console.log({ err });
                if (err.status === 403) {
                    this._toastr.error(err.error.message);
                }
                else {
                    this._toastr.error(`Expense creation failed`);
                }
            });
        }
        else {
            this._toastr.error('Please fill in all required fields');
        }
    }
    setTransaction(e) {
        console.log(e, 'helloo');
        if (!e.length) {
            this.transaction_id.patchValue(null);
            this.expense_reference_code.patchValue(null);
            this.timestamp_paid.patchValue(null);
            this.amount.patchValue(null);
            return;
        }
        let object = e[0];
        this.transaction_id.patchValue(object.transaction_id);
        this.expense_reference_code.patchValue(object.long_description);
        this.timestamp_paid.patchValue(object.timestamp_paid);
        this.amount.patchValue(object.transaction_amount);
    }
}
export { ɵ0 };

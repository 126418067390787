import {Injectable} from '@angular/core';
import * as moment from 'moment';
import 'moment-timezone';
import {environment} from '@env/environment';

// See the Moment.js docs for the meaning of these formats:
// https://momentjs.com/docs/#/displaying/format/
export const MY_FORMATS = {
  parse: {
    dateInput: 'LL'
  },
  display: {
    dateInput: 'LL',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  }
};
export const UK_FORMAT = {
  parse: {
    dateInput: 'input',
    },
    display: {
    dateInput: 'DD MMMM YYYY',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'MM/DD/YYYY',
    monthYearA11yLabel: 'MMMM YYYY',
    }
};

@Injectable()
export class DatesService {
  public monthDays: Array<number> = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31];
  public months: Array<string> = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

  constructor() {
  }

  getCurrentMonth = (i?) => {
    if (i) {
     return   parseInt(moment().add( i, 'months').format('M'), 10) - 1;
    } else {
      return new Date().getMonth();
    }
  };

  getYear = (i?) => {
    if (i) {
      return parseInt(moment().add(i, 'months').format('YYYY'), 10);
    } else {
      return new Date().getFullYear();
    }
  };

  // get difference in days between today and a given date
  daysFromToday = (date: Date) => {
    if (date instanceof Date ) {
      // Get 1 day in milliseconds
      const one_day = 1000 * 60 * 60 * 24;
      // Convert both dates to milliseconds
      const date1_ms = date.getTime();
      const date2_ms = new Date().getTime();
      // Calculate the difference in milliseconds
      const difference_ms = date1_ms - date2_ms;
      // Convert back to days and return
      return Math.round(difference_ms / one_day);
    }
  };

  getDaysBetweenTwoDates(d1: Date, d2: Date) {
    const timeDiff = Math.abs(d2.getTime() - d1.getTime());
    return Math.ceil(timeDiff / (1000 * 3600 * 24));
  }

  getYearsAgo(date: Date) {
    return moment().diff(date, 'years');
  }

  printMonth = (number: number) => {
    switch (number) {
      case 0:
        return 'January';
      case 1:
        return 'February';
      case 2:
        return 'March';
      case 3:
        return 'April';
      case 4:
        return 'May';
      case 5:
        return 'June';
      case 6:
        return 'July';
      case 7:
        return 'August';
      case 8:
        return 'September';
      case 9:
        return 'October';
      case 10:
        return 'November';
      case 11:
        return 'December';
    }
  };

  printTime = (hours: number, minutes: any) => {
    let mins;
    if (minutes === 0) {
      mins = '00';
    } else {
      mins = minutes;
    }
    if (hours < 12 && hours !== 0) {
      return `${hours}:${mins}am`;
    } else if (hours === 12) {
      return `${hours}:${mins}pm`;
    } else if (hours === 0) {
      return `00:${mins}am`;
    } else if (hours > 12) {
      return `${hours - 12}:${mins}pm`;
    }
  };

  printDate = (number: number) => {
    switch (number) {
      case 1:
      case 21:
      case 31:
        return number + 'st';
      case 2:
      case 22:
        return number + 'nd';
      case 3:
      case 23:
        return number + 'rd';
      default:
        return number + 'th';
    }
  };

  printDay = (number: number) => {
    switch (number) {
      case 1:
        return 'Monday';
      case 2:
        return 'Tuesday';
      case 3:
        return 'Wednesday';
      case 4:
        return 'Thursday';
      case 5:
        return 'Friday';
      case 6:
        return 'Saturday';
      case 0:
        return 'Sunday';
    }
  };

  getMonthsBetweenDates = (d1: Date, d2: Date) => {
    let months;
    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth() + 1;
    months += d2.getMonth();
    return months <= 0 ? 0 : months;
  };

  getGreetingMesssge() {
    const todayHours = new Date().getHours();
    if (todayHours < 12) {
       return'Good morning';
    } else if (todayHours < 18) {
      return 'Good afternoon';
    } else {
      return 'Good evening';
    }
  }

    getTimeZone = (date?:any) => {
      const client_data = environment.client_data;
      const todayDate = new Date();
    return moment(date ? date : todayDate).tz(client_data.time_zone);
  }

}

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./screen-detection.component";
var styles_ScreenDetectionComponent = [];
var RenderType_ScreenDetectionComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_ScreenDetectionComponent, data: {} });
export { RenderType_ScreenDetectionComponent as RenderType_ScreenDetectionComponent };
export function View_ScreenDetectionComponent_0(_l) { return i0.ɵvid(0, [i0.ɵqud(402653184, 1, { checker: 0 }), (_l()(), i0.ɵeld(1, 0, [[1, 0], ["checker", 1]], null, 0, "span", [["class", "hide-sm hide-md"]], null, [["window", "resize"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("window:resize" === en)) {
        var pd_0 = (_co.handleResize($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null))], null, null); }
export function View_ScreenDetectionComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "screen-detection", [], null, null, null, View_ScreenDetectionComponent_0, RenderType_ScreenDetectionComponent)), i0.ɵdid(1, 114688, null, 0, i1.ScreenDetectionComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var ScreenDetectionComponentNgFactory = i0.ɵccf("screen-detection", i1.ScreenDetectionComponent, View_ScreenDetectionComponent_Host_0, {}, { resized: "resized" }, []);
export { ScreenDetectionComponentNgFactory as ScreenDetectionComponentNgFactory };

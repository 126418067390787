/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./notebar.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../../../node_modules/@angular/material/sidenav/typings/index.ngfactory";
import * as i3 from "@angular/material/sidenav";
import * as i4 from "@angular/cdk/bidi";
import * as i5 from "@angular/cdk/scrolling";
import * as i6 from "@angular/platform-browser/animations";
import * as i7 from "@angular/cdk/a11y";
import * as i8 from "@angular/cdk/platform";
import * as i9 from "@angular/common";
import * as i10 from "../../../../../node_modules/@angular/material/list/typings/index.ngfactory";
import * as i11 from "@angular/material/list";
import * as i12 from "./notebar.component";
var styles_NotebarComponent = [i0.styles];
var RenderType_NotebarComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_NotebarComponent, data: {} });
export { RenderType_NotebarComponent as RenderType_NotebarComponent };
export function View_NotebarComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 8, "mat-sidenav-container", [["class", "nav-topbar__bg mat-drawer-container mat-sidenav-container"]], [[2, "mat-drawer-container-explicit-backdrop", null]], [[null, "backdropClick"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("backdropClick" === en)) {
        var pd_0 = (_co.closeNotificationsSideBar() !== false);
        ad = (pd_0 && ad);
    } return ad; }, i2.View_MatSidenavContainer_0, i2.RenderType_MatSidenavContainer)), i1.ɵdid(1, 1490944, null, 2, i3.MatSidenavContainer, [[2, i4.Directionality], i1.ElementRef, i1.NgZone, i1.ChangeDetectorRef, i5.ViewportRuler, i3.MAT_DRAWER_DEFAULT_AUTOSIZE, [2, i6.ANIMATION_MODULE_TYPE]], null, { backdropClick: "backdropClick" }), i1.ɵqud(603979776, 1, { _drawers: 1 }), i1.ɵqud(603979776, 2, { _content: 0 }), (_l()(), i1.ɵeld(4, 0, null, 0, 4, "mat-sidenav", [["class", "notifications mat-drawer mat-sidenav"], ["disableClose", ""], ["mode", "over"], ["tabIndex", "-1"]], [[1, "align", 0], [2, "mat-drawer-end", null], [2, "mat-drawer-over", null], [2, "mat-drawer-push", null], [2, "mat-drawer-side", null], [2, "mat-drawer-opened", null], [2, "mat-sidenav-fixed", null], [4, "top", "px"], [4, "bottom", "px"], [40, "@transform", 0]], [[null, "keydown.escape"], ["component", "@transform.start"], ["component", "@transform.done"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("component:@transform.start" === en)) {
        var pd_0 = (i1.ɵnov(_v, 5)._animationStartListener($event) !== false);
        ad = (pd_0 && ad);
    } if (("component:@transform.done" === en)) {
        var pd_1 = (i1.ɵnov(_v, 5)._animationDoneListener($event) !== false);
        ad = (pd_1 && ad);
    } if (("keydown.escape" === en)) {
        var pd_2 = (_co.closeNotificationsSideBar() !== false);
        ad = (pd_2 && ad);
    } return ad; }, i2.View_MatSidenav_0, i2.RenderType_MatSidenav)), i1.ɵdid(5, 3325952, [[1, 4]], 0, i3.MatSidenav, [i1.ElementRef, i7.FocusTrapFactory, i7.FocusMonitor, i8.Platform, i1.NgZone, [2, i9.DOCUMENT]], { position: [0, "position"], mode: [1, "mode"], disableClose: [2, "disableClose"], opened: [3, "opened"], fixedInViewport: [4, "fixedInViewport"] }, null), (_l()(), i1.ɵeld(6, 0, null, 0, 2, "mat-nav-list", [["class", "notification__side mat-nav-list mat-list-base"], ["role", "navigation"]], null, null, null, i10.View_MatNavList_0, i10.RenderType_MatNavList)), i1.ɵdid(7, 704512, null, 0, i11.MatNavList, [], null, null), i1.ɵncd(0, 0)], function (_ck, _v) { var _co = _v.component; _ck(_v, 1, 0); var currVal_11 = i1.ɵinlineInterpolate(1, "", _co.position, ""); var currVal_12 = "over"; var currVal_13 = ""; var currVal_14 = _co.notebarNotificationOpen; var currVal_15 = true; _ck(_v, 5, 0, currVal_11, currVal_12, currVal_13, currVal_14, currVal_15); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 1)._backdropOverride; _ck(_v, 0, 0, currVal_0); var currVal_1 = null; var currVal_2 = (i1.ɵnov(_v, 5).position === "end"); var currVal_3 = (i1.ɵnov(_v, 5).mode === "over"); var currVal_4 = (i1.ɵnov(_v, 5).mode === "push"); var currVal_5 = (i1.ɵnov(_v, 5).mode === "side"); var currVal_6 = i1.ɵnov(_v, 5).opened; var currVal_7 = i1.ɵnov(_v, 5).fixedInViewport; var currVal_8 = (i1.ɵnov(_v, 5).fixedInViewport ? i1.ɵnov(_v, 5).fixedTopGap : null); var currVal_9 = (i1.ɵnov(_v, 5).fixedInViewport ? i1.ɵnov(_v, 5).fixedBottomGap : null); var currVal_10 = i1.ɵnov(_v, 5)._animationState; _ck(_v, 4, 0, currVal_1, currVal_2, currVal_3, currVal_4, currVal_5, currVal_6, currVal_7, currVal_8, currVal_9, currVal_10); }); }
export function View_NotebarComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "note-bar", [], null, null, null, View_NotebarComponent_0, RenderType_NotebarComponent)), i1.ɵdid(1, 114688, null, 0, i12.NotebarComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var NotebarComponentNgFactory = i1.ɵccf("note-bar", i12.NotebarComponent, View_NotebarComponent_Host_0, { position: "position", notebarNotificationOpen: "notebarNotificationOpen" }, { notebarNotificationOpenChange: "notebarNotificationOpenChange" }, ["*"]);
export { NotebarComponentNgFactory as NotebarComponentNgFactory };

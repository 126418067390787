import { Component, Input, OnInit } from '@angular/core';
import {environment} from '@env/environment';

@Component({
  selector: 'doughnut',
  templateUrl: './doughnut.component.html',
  styleUrls: ['./doughnut.component.scss']
})
export class DoughnutComponent implements OnInit {
  @Input() header: object = { title: '', linkName: '', linkTo: '' };
  @Input() chartData: Array<{ 'name': string, 'value': number }> = [];
  @Input() view;
  @Input() label = 'Appliances';
  @Input() valueFormat: object = { prefix: true, display: false };
  public client_data = environment.client_data;
  colorScheme = {
    domain: ['#e4e4e4']
  };

  constructor() {

  }

  ngOnInit() {


  }


  setValuePrefixFormat(val) {
    val = val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    const currency_symbol = environment.client_data.currency_symbol;
    return `${currency_symbol + val}`;
  }
  setValueSufixFormat(val) {
    val = val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return `${val}%`;
  }

  get chartEmpty() {
    return !this.chartData.length || this.chartData.every(obj => obj.value === 0)
  }
}

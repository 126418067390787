
    <div class="main__wrapper main__wrapper--narrow ">
      <div class="empty__state">
        <h1>There is something wrong with your account</h1>
        <p>Please fill in your details again to sign up</p>
      </div>
<div class="card-base">

      <h2 class="margin-bottom--sm" *ngIf="propertyId && tenantName; else noMid">Join your tenancy</h2>
      <ng-template #noMid>
        <h2 class="margin-bottom--sm" *ngIf="!propertyId && !tenantName">Sign up <span *ngIf="role">as a {{role}}</span></h2>
      </ng-template>
      <ng-container *ngIf="propertyId && tenantName">


        <hr class="margin-bottom--md margin-top--md">
      </ng-container>


      <form [formGroup]="formGroup">

        <form-create-account class="margin-bottom--md" formControlName="user_account" direction="column" [showLabel]="false"
                             (submitForm)="emailSignup()" [showSpinner]="showSpinner$.value" roleInput="{{(client_data.company_name === 'Livingway' || client_data.company_name === 'NRLA' ? 'tenant' : null )}}"></form-create-account>

      </form>


    </div>
    </div>

  
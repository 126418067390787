
  <form [formGroup]="formGroup" autocomplete="none" *ngIf="isStudent">
        <bunk-form-field *ngIf="getFormControl('university_name')">
        <label bunk-label>What is your university's name?</label>
          <mat-form-field bunk-input appearance="outline">
            <input formControlName="university_name" matInput type="text" name="university_name" placeholder="E.g Southampton">
            <mat-error>Please enter the name of your university</mat-error>
          </mat-form-field>
        </bunk-form-field>

        <bunk-form-field *ngIf="getFormControl('course_name')">
        <label bunk-label>What course do you study?</label>
          <mat-form-field bunk-input appearance="outline">
            <input formControlName="course_name" matInput type="text" name="course_name" placeholder="E.g Advertising">
            <mat-error>Please enter the name of your course</mat-error>
          </mat-form-field>
        </bunk-form-field>

        <bunk-form-field *ngIf="getFormControl('course_end_year')">
        <label bunk-label>What year does your course end?</label>
          <mat-form-field bunk-input  appearance="outline" class="bottom-border">
            <input formControlName="course_end_year" matInput type="text" name="course_end_year" placeholder="E.g 2022">
            <mat-error>Please enter your course end year</mat-error>
          </mat-form-field>
        </bunk-form-field>

      <bunk-form-field *ngIf="getFormControl('student_id')">
        <label bunk-label>What is your student ID?</label>
        <mat-form-field bunk-input  appearance="outline" class="bottom-border">
          <input formControlName="student_id" matInput type="text" name="student_id" placeholder="E.g 14003886">
          <mat-error>Please enter your student id</mat-error>
        </mat-form-field>
      </bunk-form-field>

      <bunk-form-field *ngIf="getFormControl('rental_academic_year')">
        <label bunk-label >What academic year are you in?</label>
        <mat-form-field bunk-input appearance="outline">
          <mat-select formControlName="rental_academic_year" placeholder="Select">
            <mat-option *ngFor="let year of rental_academic_years" [value]="year.value">
              {{year.viewValue}}
            </mat-option>
          </mat-select>
          <mat-error>Please select who you are searching with</mat-error>
        </mat-form-field>
      </bunk-form-field>

  </form>

  
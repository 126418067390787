import { Routes } from '@angular/router';
import { HasFullAccess } from '@app/core/guards';
import { PaymentsManageComponent } from './payments-manage/payments-manage.component';
const ɵ0 = { route: 'payments' };
const routes = [{
        path: '',
        component: PaymentsManageComponent,
        canActivate: [HasFullAccess], data: ɵ0,
        pathMatch: 'full'
    }];
export class PaymentsRoutingModule {
}
export { ɵ0 };

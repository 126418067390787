import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'scroll-skeleton',
  template: `
    <ul class="scroll-card">
      <li class="scroll-card__item loading-animation--header" *ngFor="let i of [0, 1, 2, 3]">
        <div class="flex flex-row">
         
          <div class="flex flex-column">
            <div class="scroll-card__title loading-animation"></div>
            <div class="margin-top--sm scroll-card__middle loading-animation"></div>
            <div class="margin-top--sm scroll-card__small loading-animation"></div>
          </div>

          <div class="flex flex-column">
          <div class="scroll-card__circle loading-animation"></div>
        </div>
        </div>
      </li>
    </ul>
  `,
  styleUrls: ['../skeleton-loading.component.scss']
})
export class ScrollSkeletonComponent implements OnInit {
  @Input() is_small_card = false;
  constructor() {
  }

  ngOnInit() {
  }
}





  <form [formGroup]="formGroup" autocomplete="none">

  <bunk-form-field direction="column" [showLabel]="showLabel">
    <label bunk-label for="viewing-date">Pick a date</label>
    <mat-form-field bunk-input appearance="outline">
      <mat-label *ngIf="!showLabel">Pick a date</mat-label>
      <input id="viewing-date"
            formControlName="viewing_date" matInput
            (click)="pickDate.open()"
            placeholder="Pick a date"
            [matDatepicker]="pickDate"
            [min]="minDate">
      <mat-datepicker-toggle matSuffix [for]="pickDate" ></mat-datepicker-toggle>
      <mat-datepicker #pickDate></mat-datepicker>
      <mat-error>Please pick a date</mat-error>
    </mat-form-field>
  </bunk-form-field>

  <bunk-form-field direction="column" [showLabel]="showLabel">
    <label bunk-label >Select a time (24-hour clock)</label>
    <div bunk-input>
      <time-picker formControlName="viewing_time"
                  [minHours]="7"
                  [maxHours]="20"
                  [submitAttempted]="submitAttempted"
      ></time-picker>
    </div>
  </bunk-form-field>

  <bunk-form-field direction="column" [showLabel]="showLabel" *ngIf="showDescription">
    <label bunk-label>Add any comments for the {{landlord}} (optional)</label>
    <mat-form-field  bunk-input appearance="outline">
      <mat-label *ngIf="!showLabel">Add any comments for the {{landlord}} (optional)</mat-label>
      <textarea rows="6" formControlName="viewing_request_message" matInput placeholder="Add any comments for the {{landlord}} (optional)"></textarea>
    </mat-form-field>
  </bunk-form-field>

</form>

  
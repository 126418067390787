import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { UserService } from '@app/services';
import { Shared } from '@env/routing';
import { map } from 'rxjs/operators';

@Injectable()
export class IsLivingPhaseGuard implements CanActivate {
  constructor(private router: Router,
              private _user: UserService) {
  }



  canActivate(route: ActivatedRouteSnapshot,
              state: RouterStateSnapshot) {
    return this._user.user$
      .pipe(
        map(user => {
            if (user.has_tenant_living_dashboard) {
              return true;
            } else {
              this.router.navigate(['/' + Shared.search]);
              return false;
            }
        })
      );
  }
}

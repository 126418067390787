import { Component, OnInit, forwardRef, Optional, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR, NG_VALIDATORS, FormGroup, Validator, Validators, FormGroupDirective, FormBuilder, AbstractControl } from '@angular/forms';
import { AbstractValueAccessor } from '../abstract-value-accessor/abstract-value-accessor';
@Component({
  selector: 'form-name-row',
  template: `

    <form [formGroup]="formGroup" class="bunk-input flex justify-content-between">
      <mat-form-field bunk-input appearance="outline" class="bunk-input__first-name">
        <input id="signup-first-name" matInput type="text" formControlName="first_name" placeholder="First Name">
        <mat-error *ngIf="submitAttempted && formGroup.get('first_name').errors?.required">Please enter your first name</mat-error>
      </mat-form-field>

      <mat-form-field bunk-input appearance="outline" class="bunk-input__last-name">
        <input id="signup-last-name" matInput type="text" formControlName="last_name" placeholder="Last Name">
        <mat-error *ngIf="submitAttempted && formGroup.get('last_name').errors?.required">Please enter your last name</mat-error>
      </mat-form-field>

    </form>
  `,
  styleUrls: ['./form-name-row.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormNameRowComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => FormNameRowComponent),
      multi: true
    }
  ]
})
export class FormNameRowComponent extends AbstractValueAccessor implements OnInit, Validator  {
  @Input() showLabel = false;
  @Input() labelValue = 'Phone number';
  @Input('submitAttempted') set submitAttempted(boo: boolean) {
    this._submitAttempted = boo;
    if (boo) {
      this.dirtyFormGroup();
    }
  }
  get submitAttempted(): boolean {
    return this._submitAttempted;
  }
  private _submitAttempted = false;
  public formGroup: FormGroup;
  public countryCodes: (string | number)[];

  constructor(
    @Optional() private reactiveForm: FormGroupDirective,
    private _formBuilder: FormBuilder
  ) {
    super();
  }

  dirtyFormGroup() {
    const controls = this.formGroup.controls;
    for (const control in controls) {
      if (controls.hasOwnProperty(control)) {
        this.formGroup.controls[control].markAsTouched();
      }
    }
  }

  ngOnInit() {
    this.formGroup = this.initFormGroup();
    this.reactiveForm.ngSubmit.subscribe((data: Event) => {
      this.submitAttempted = true;
      if (this.reactiveForm.submitted) {
        this.dirtyFormGroup();
      }
    });
  }

  initFormGroup(): FormGroup {
    const addrFormGroup = this._formBuilder.group({
      first_name: [null, Validators.required],
      last_name: [null, Validators.required]
    });
    return new FormGroup(addrFormGroup.controls, { updateOn: 'blur' });
  }

  public onTouched: () => void = () => { };

  writeValue(val: any): void {
    super.writeValue(val);
    if (val) {
      this.formGroup.patchValue(val, { emitEvent: false });
    }
  }
  registerOnChange(fn: any): void {
    this.formGroup.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.formGroup.disable() : this.formGroup.enable();
  }

  validate(control: AbstractControl) {
    return this.formGroup.valid ? null : { invalidForm: { valid: false, message: 'FormNameRowComponent > formGroup fields are invalid' } };
  }
}

import { Injectable } from '@angular/core';
import { AuthService } from '@app/core/services/auth.service';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { FirebaseApp } from '@angular/fire';
import { ITeam, IUser, IUserIdentityDocuments, IUserPermissioned, IUserPublic, IUserReadOnly } from '@app/core/models';
import { BunkPassportModel, BunkPassportModelPermissioned } from '@app/_models/bunk_passport.model';
import { from as fromPromise, Observable, of, zip } from 'rxjs';
import { flatMap, switchMap, map, tap, take, first } from 'rxjs/operators';
import { RoleString } from '@app/_models/verfify-types.model';
import { IntercomService } from '@app/services/intercom.service';
import * as moment from 'moment';
import * as _ from 'lodash';
import { firestore } from 'firebase';
import { combineLatest } from 'rxjs/internal/observable/combineLatest';
import { from } from 'rxjs/internal/observable/from';
import {EUserRole} from '@rentbunk/bunk-models';

@Injectable()
export class UserService {
  public user$: Observable<any>;
  public userAnonymous$: Observable<any>;
  public userReadOnly$: Observable<any>;
  public bunkPassport$: Observable<any>;
  public user: any;
  public userReadOnly: any;

  // Users Collections
  public usersCollection: AngularFirestoreCollection<any>;
  public usersAnonymousCollection: AngularFirestoreCollection<any>;
  public usersPermissionedCollection: AngularFirestoreCollection<any>;
  public usersPublicCollection: AngularFirestoreCollection<any>;
  public usersReadOnlyCollection: AngularFirestoreCollection<any>;
  // Passport Collections
  public bunkPassportCollection: AngularFirestoreCollection<any>;
  public bunkPassportPermissionedCollection: AngularFirestoreCollection<any>;
  // teams collection
  public teamCollection: AngularFirestoreCollection<any>;

  // User Documents
  private userDoc: AngularFirestoreDocument<IUser>;
  private userPermissionedDoc: AngularFirestoreDocument<IUserPermissioned>;
  private userPublicDoc: AngularFirestoreDocument<IUserPublic>;
  private userReadOnlyDoc: AngularFirestoreDocument<IUserReadOnly>;


  constructor(
    private readonly afs: AngularFirestore,
    private afAuth: AngularFireAuth,
    private _auth: AuthService,
    private fb: FirebaseApp,
    private _intercom: IntercomService
  ) {

    // Declare Users Collection
    this.usersCollection = afs.collection<any>('users');
    this.usersAnonymousCollection = afs.collection<any>('users_anonymous');
    this.usersPermissionedCollection = afs.collection<any>('users_permissioned');
    this.usersPublicCollection = afs.collection<any>('users_public');
    this.usersReadOnlyCollection = afs.collection<any>('users_read_only');

    this.bunkPassportCollection = afs.collection<any>('bunk_passports');
    this.bunkPassportPermissionedCollection = afs.collection<any>('bunk_passports_permissioned');

    // Get user
    this.user$ = this.afAuth.authState.pipe(
      switchMap((auth: any) => (auth && this._auth.currentUser && !this._auth.currentUser.displayName)
        ? this.usersCollection.doc(auth.uid).valueChanges()
        : (auth && this._auth.currentUser.displayName)
          ? this.usersCollection.doc(this._auth.currentUser.displayName).valueChanges()
          : of(null))
    );

    this.userReadOnly$ = this.afAuth.authState.pipe(
      switchMap(auth => (auth && !this._auth.currentUser.displayName)
        ? this.usersReadOnlyCollection.doc(auth.uid).valueChanges()
        : (auth && this._auth.currentUser.displayName)
          ? this.usersReadOnlyCollection.doc(this._auth.currentUser.displayName).valueChanges()
          : of(null))
    );

    this.userAnonymous$ = this.afAuth.authState.pipe(
      switchMap(auth => (auth.isAnonymous) ? this.usersAnonymousCollection.doc(auth.uid).valueChanges() : of(null))
    );

    // Get user read only

    this.bunkPassport$ = this.afAuth.authState.pipe(
      switchMap(auth => (auth && !this._auth.currentUser.displayName)
        ? this.bunkPassportCollection.doc(auth.uid).valueChanges()
        : (auth && this._auth.currentUser.displayName)
          ? this.bunkPassportCollection.doc(this._auth.currentUser.displayName).valueChanges()
          : of(null))
    );


    this.user$
      .subscribe((user: IUser) => {
        console.log({userFull: user});
        this.user = user;
        if (user) {
          // Declare User Documents
          this._intercom.initIntercom(user);
          this.userDoc = this.usersCollection.doc(user.uid);
          this.userPermissionedDoc = this.usersPermissionedCollection.doc(user.uid);
          this.userPublicDoc = this.usersPublicCollection.doc(user.uid);
          this.userReadOnlyDoc = this.usersReadOnlyCollection.doc(user.uid);
          // Sync authentication providers
          /*
                    @todo: feature temporary disabled
                    const stored_provider_ids = user.auth_provider_ids;
                    this.syncAuthProviderIds(this._auth.authState.providerData[0], stored_provider_ids);*/
        }
      });

    this.userReadOnly$
      .subscribe(user => {
        console.log({user});
        this.userReadOnly = user;
      });
  }

  get userDb(): any {
    return this.user;
  }

  get userReadOnlyDb(): any {
    return this.userReadOnly;
  }


  private get isTenantFullyVerified(): boolean {
    return this.userReadOnly && this.userReadOnly.verification_tier === 3;
  }


  private get isLandlordFullyVerified(): boolean {
    return this.user && this.user.is_fully_verified || this.user.verification_status === 'approved';
  }
  getCurrentUserCustomClaims(): Observable<boolean> {
    return from(this.afAuth.auth.currentUser.getIdTokenResult(true)).pipe(
      map((res: any) => res.claims.admin)
    );
  }

  public get userHasTeam(): boolean {
    return !!(this.userReadOnlyDb && this.userReadOnlyDb.team_ids);
  }

  public get userTeamIds(): Array<any> {
    return this.userHasTeam ? this.userReadOnlyDb.team_ids : [];
    }

  get isFullyVerified(): boolean {
    if (this.user) {
      switch (this.user.role) {
        case 'landlord':
          return this.isLandlordFullyVerified;
        case 'tenant':
          return this.isTenantFullyVerified;
        default:
          return false;
      }
    }
    return false;
  }


  getSelectedPAyoutAccountFromUser(payout_account_id: string) {
    if (this.userDb.payout_accounts) {
      return this.userDb.payout_accounts.find((account: any) => account.payout_account_id === payout_account_id);
    }
  }

  /*
   * Get Read Only User by
   */
  getUserReadOnlyById(id: string): AngularFirestoreDocument<any> {
    return this.usersReadOnlyCollection.doc(id);
  }



  /*
   * Get Public User by
   */
  getUserById(id: string): AngularFirestoreDocument<any> {
    return this.usersPublicCollection.doc(id);
  }

  getManagerByManagerId(email: string): AngularFirestoreCollection<any> {
    return this.afs.collection('users_public', ref =>
      ref.where('property_manager_data.company_uid', '==', email)
    );
  }


  getUserAsAdmin(id: string): AngularFirestoreDocument<any> {
    return this.usersCollection.doc(id);
  }

  getUserByName(name: string): AngularFirestoreCollection<any> {
    return this.afs.collection('users_public', ref =>
      ref.where('first_name', '==', name)
    );
  }
  /*
    Get sets of users with FireStore 'in' (max 10)
  */
  private getUsersByIn(idArr: string[]) {
    return this.afs.collection('users_public', ref => ref.where('uid', 'in', idArr));
  }

  getUsersFromIds(idArr: string[]): Observable<any> {
    const chunksArr = _.chunk(idArr, 10);
    return zip(...chunksArr.map((chunk: any) => this.getUsersByIn(chunk).valueChanges())).pipe(
      map((d: any) => d.flat()),
      tap((res: any) => console.log({res}))
    );
  }

  getUserPermissionedById(id: string): AngularFirestoreDocument<any> {
    return this.usersPermissionedCollection.doc(id);
  }

  getUserByEmail(email: string): AngularFirestoreCollection<any> {
    return this.afs.collection('users_public', ref =>
      ref.where('email', '==', email)
    );
  }

  getUserMetaData(): AngularFirestoreCollection<any> {
    return this.afs.collection('metadata', (ref) =>
      ref.where('uid', '==', this._auth.currentUserId)
    );
  }

  /*
   * (Private, Permissioned): Update user email
   */
  updateUserEmail(email: string, currentPassword: string): Observable<any> {
    return this._auth.reAuthenticate(currentPassword)
      .pipe(
        flatMap(() => this._auth.currentUser.updateEmail(email)),
        flatMap(() => {
          const user: IUser = { profile_data: { email } };
          const bunkPassport: any = { personal_details: { email } };
          return this.updateUserBunkPassportPermissioned(user, user, bunkPassport, bunkPassport);
        })
      );
  }
  /*
 * (Private, Permissioned, Public): Update profile_data & notification_preferences
 */
  updateUserAccount(user: any) {
    const profile_data_base = {
      full_name: (user.name) ? `${user.name} ${user.last_name}` : this.userDb.profile_data.full_name,
      first_name: (user.name) ? user.name : this.userDb.profile_data.first_name,
      last_name: (user.last_name) ? user.last_name : this.userDb.profile_data.last_name
    };

    const notifications_data = {
      notification_preferences: {
        email: (user.email_notification) ? user.email_notification : true,
        phone: (user.phone_notification) ? user.phone_notification : true
      }
    };

    const contact_data = {
      email: user.email,
      phone: user.phone_number
    };

    const userPublic: IUserPublic = {
      date_modified: new Date(),
      profile_data: {
        ...profile_data_base
      }
    };
    const userPermissioned: IUserPermissioned = {
      date_modified: new Date(),
      profile_data: {
        ...profile_data_base,
        ...contact_data
      }
    };

    const userPrivate: IUser = {
      date_modified: new Date(),
      profile_data: {
        ...profile_data_base,
        ...contact_data,
      },
      ...notifications_data
    };

    const bunkPassportPermissioned: any = {
      date_modified: new Date(),
      personal_details: {
        ...profile_data_base,
        ...contact_data,
      }
    };

    const bunkPassport: any = {
      ...bunkPassportPermissioned,
      ...notifications_data

    };

    return this.updateUserBunkPassport(userPrivate, userPermissioned, userPublic, bunkPassport, bunkPassportPermissioned);
  }


  /** @deprecated for security reason*/
  updatedUserFullyVerfied() {
    const userPublic = { is_fully_verified: true };
    const userPrivate = {
      date_modified: new Date(),
      is_fully_verified: true,
      verification_data: {
        date_verified: new Date()
      }
    };
    const userPermissioned = { is_fully_verified: true };
    return this.updateUserPublic(userPrivate, userPermissioned, userPublic);
  }


  updateProfileData(user: any) {
    return this.updateUserPublic(user, user, user);
  }

  updateUserRentSmartNumber(rent_smart_no: string) {
    const body = {
      rent_smart_no,
    }
    return this.updateUserPublic(body, body, body);
  }


  /*
   * Batch (Private, Permissioned): Verify Phone number and User phone number
   */
  updatePhoneNumberAndVerificationStatus(phone_number: string, verification_status: boolean): Observable<void> {
    const userPrivate: IUser = {
      date_modified: new Date(),
      is_phone_verified: verification_status,
      profile_data: {
        phone: phone_number
      }
    };

    const userPermissioned: IUserPermissioned = {
      date_modified: new Date(),
      profile_data: {
        phone: phone_number
      }
    };

    return this.updateUserPermissioned(userPrivate, userPermissioned);
  }

  /*
   * (Private, Permissioned, Public) Batch to update User profile image
   */
  updateProfileImage(profile_image_url: string): Observable<void> {
    const user: IUser = { profile_data: { profile_image_url: profile_image_url }, date_modified: new Date() };
    const bunkPassport: any = { personal_details: {  profile_image_url }, date_modified: new Date()  };

    return this.updateUserBunkPassport(user, user, user, bunkPassport, bunkPassport);
  }

  updateUserPhoneNumber(phone: string, country_code): Observable<any> {
    const user: IUser = { profile_data: { phone, country_code }, date_modified: new Date() };
    const bunkPassport: any = { personal_details: {  phone, country_code }, date_modified: new Date()  };

    return this.updateUserBunkPassport(user, user, {}, bunkPassport, bunkPassport);
  }

  /*
   * (Private, Permissioned, Public) Batch to update User bio
   */
  updateUserBio(bio: string): Observable<void> {
    const userPrivate: IUser = { profile_data: { bio: bio }, date_modified: new Date() };
    const userPermissioned: IUserPermissioned = userPrivate;
    const userPublic: IUserPublic = userPrivate;

    return this.updateUserPublic(userPrivate, userPermissioned, userPublic);
  }

  /*
   * (Private, Permissioned, Public) Batch to update User role
   */
  updateRole(role: RoleString): Observable<void> {
    const getRoleDataKey = (user_role: string): string | null => (user_role === 'landlord') ? 'landlord_data' : 'tenant_data';

    const userPrivate: IUser = { role: role, [getRoleDataKey(role)]: {} };
    const userPermissioned: IUserPermissioned = { role: role };
    const userPublic: IUserPublic = { role: role };

    return this.updateUserPublic(userPrivate, userPermissioned, userPublic);
  }

  updateUserName(first_name: string, last_name: string) {
    const userPrivate: IUser = {
      date_modified: new Date(),
      profile_data: { first_name: first_name, last_name: last_name, full_name: first_name + ' ' + last_name }
    };
    const userPermissioned: IUserPermissioned = userPrivate;
    const userPublic: IUserPublic = userPrivate;

    return this.updateUserPublic(userPrivate, userPermissioned, userPublic);
  }

  /*
   * (Private) Transaction let you delete reference of owned Property
   */
  deleteRefPropertyUser(property_id: string): Observable<any> {
    // declare document references
    const userDocRef = this.usersCollection.doc(this._auth.currentUserId).ref;

    return fromPromise(
      this.fb.firestore().runTransaction((transaction) =>
        transaction.get(userDocRef).then((doc) => {
          if (!doc.exists) {
            return;
          }

          const userData = doc.data();
          const properties_id = userData.properties_id;
          // check if passed ID is present in user stored properties id
          if (properties_id.includes(property_id)) {
            const index = properties_id.indexOf(property_id);
            properties_id.splice(index, 1); // remove id from stored properties id
          }
          transaction.set(userDocRef, { properties_id: properties_id, date_modified: new Date() }, { merge: true });
        })
      )
    );

  }

  /*
   * (Private) Transaction let you create documents into landlord_data
   */
  updateOrCreateLandlordDocuments(item): Observable<void> {
    // declare document references
    const userDocRef = this.userDoc.ref;

    return fromPromise(
      this.fb.firestore().runTransaction((transaction) =>
        transaction.get(userDocRef)
          .then((user) => {
            const userData = user.data();
            const oldLandlordDocuments = userData.landlord_data.documents;
            const newLandlordDocuments = oldLandlordDocuments ? {
              date_modified: new Date(),
              landlord_data: { documents: [...oldLandlordDocuments, item] }
            } : { landlord_data: { documents: [item] } };
            transaction.set(userDocRef, newLandlordDocuments, { merge: true });
          })
      )
    );
  }


  /*
   * (Private) Update User liked properties merging the passed one defined via id
   */
  updateUserLikedProperties(property_id: string): Observable<void> {
    const userPrivate: IUser = { date_modified: new Date(), tenant_data: { liked_property_ids: { [property_id]: new Date() } } };
    return this.updateUserPrivate(userPrivate);
  }

  userDetailsForStripe(item: any) {
    return this.updateUserPrivate(item);
  }

  removeUserLikedProperty(property_id: string) {
    const likedProperties = this.userDb.tenant_data.liked_property_ids;
    Object.keys(likedProperties).forEach((key, index) => {
      if (key === property_id) {
        delete likedProperties[key];
      }
    });
    return fromPromise(
      this.usersCollection.doc(`${this._auth.currentUserId}`).update({
        'tenant_data.liked_property_ids': likedProperties,
        date_modified: new Date()
      })
    );
  }

  // Updating step 1 landlord verification
  updateLandlordsOfficialDetails(form: any) {
    const userPublic = {
      date_modified: new Date(),
      profile_data: {
        title: form.title,
        first_name: form.first_name,
        full_name: form.first_name + ' ' + form.last_name,
        middle_name: form.middle_name,
        last_name: form.last_name,
        gender: form.gender,
        dob: moment(form.dob).toDate()
      }
    };

    const userPermissioned = {
      ...userPublic
    };

    const userPrivate = {
      ...userPublic,
      verification_data: {
        address_details: {
          first_line_address: form.address_data.first_line_address,
          second_line_address: form.address_data.second_line_address,
          third_line_address: form.address_data.third_line_address,
          city: form.address_data.city,
          post_code: form.address_data.post_code,
          county: form.address_data.county,
          country: form.address_data.country,
          lat: form.address_data.lat,
          lng: form.address_data.lng
        },
        document_data: {
          title: form.title,
          first_name: form.first_name,
          middle_name: form.middle_name,
          last_name: form.last_name,
          gender: form.gender,
          dob: moment(form.dob).toDate()
        }
      }
    };

    return this.updateUserPublic(userPrivate, userPermissioned, userPublic);
  }

  updateUserIdentityDocuments(form: IUserIdentityDocuments) {
    return this.updateUserPermissioned(form, form);
  }

  /*
   * Update user Private
   */
  public updateUserPrivate(user_private: IUser, merge: boolean = true): Observable<void> {
    return (merge) ? fromPromise(this.userDoc.set(user_private, { merge: true })) : fromPromise(this.userDoc.update(user_private));
  }

  /*
   * Update user Permissioned
   */
  private updateUserPermissioned(user_private: IUser, user_permissioned: IUserPermissioned, merge: boolean = true): Observable<void> {
    // declare document references
    const userDocPrivateRef = this.userDoc.ref;
    const userDocPermissionedRef = this.userPermissionedDoc.ref;


    const batch = this.afs.firestore.batch();

    batch.set(userDocPrivateRef, user_private, { merge: true });
    batch.set(userDocPermissionedRef, user_permissioned, { merge: true });

    return fromPromise(batch.commit());
  }

  /*
   * Update user Private, Permissioned, Public
   */
  private updateUserPublic(user_private: IUser, user_permissioned: IUserPermissioned, user_public: IUserPublic): Observable<void> {

    console.log({user_private, user_permissioned, user_public})
    // declare document references
    const userDocPrivateRef = this.userDoc.ref;
    const userDocPermissionedRef = this.userPermissionedDoc.ref;
    const userDocPublicRef = this.userPublicDoc.ref;

    const batch = this.afs.firestore.batch();

    batch.set(userDocPrivateRef, user_private, { merge: true });
    batch.set(userDocPermissionedRef, user_permissioned, { merge: true });
    batch.set(userDocPublicRef, user_public, { merge: true });

    return fromPromise(batch.commit());
  }

  private updateUserBunkPassport(user_private: IUser, user_permissioned: IUserPermissioned, user_public: IUserPublic,
    bunk_passport: BunkPassportModel, bunk_passports_permissioned: BunkPassportModelPermissioned
  ): Observable<void> {
    // declare document references
    const id = this._auth.currentUserId;

    console.log(user_private)

    const userDocPrivateRef = this.userDoc.ref;
    const userDocPermissionedRef = this.userPermissionedDoc.ref;
    const userDocPublicRef = this.userPublicDoc.ref;
    const bunkPassportRef = this.bunkPassportCollection.doc(id).ref;
    const bunkPassportPermissionedRef = this.bunkPassportPermissionedCollection.doc(id).ref;

    const batch = this.afs.firestore.batch();

    batch.set(userDocPrivateRef, user_private, { merge: true });
    batch.set(userDocPermissionedRef, user_permissioned, { merge: true });
    batch.set(userDocPublicRef, user_public, { merge: true });
    batch.set(bunkPassportRef, bunk_passport, { merge: true });
    batch.set(bunkPassportPermissionedRef, bunk_passports_permissioned, { merge: true });

    return fromPromise(batch.commit());
  }
  private updateUserBunkPassportPermissioned(user_private: IUser, user_permissioned: IUserPermissioned,
    bunk_passport: BunkPassportModel, bunk_passports_permissioned: BunkPassportModelPermissioned
  ): Observable<void> {
    // declare document references
    const id = this._auth.currentUserId;

    const userDocPrivateRef = this.userDoc.ref;
    const userDocPermissionedRef = this.userPermissionedDoc.ref;

    const bunkPassportRef = this.bunkPassportCollection.doc(id).ref;
    const bunkPassportPermissionedRef = this.bunkPassportPermissionedCollection.doc(id).ref;

    const batch = this.afs.firestore.batch();

    batch.set(userDocPrivateRef, user_private, { merge: true });
    batch.set(userDocPermissionedRef, user_permissioned, { merge: true });
    batch.set(bunkPassportRef, bunk_passport, { merge: true });
    batch.set(bunkPassportPermissionedRef, bunk_passports_permissioned, { merge: true });

    return fromPromise(batch.commit());
  }



  /*
   * Sync Authentication Provider
   */
  private syncAuthProviderIds(new_provider: any, stored_provider: any): Observable<any> {
    const getKeyProviderName = (provider: string): string => {
      switch (provider) {
        case 'google.com':
          return 'google_id';
        case 'facebook.com':
          return 'facebook_id';
        case 'password':
          return 'email_auth';
        default:
          return null;
      }
    };
    const curr_provider = { [getKeyProviderName(new_provider.providerId)]: new_provider.uid };
    const obj = {
      ...curr_provider,
      ...stored_provider
    };
    return fromPromise(this.afs.collection('users').doc(this.userDb.uid).set({ auth_provider_ids: obj }, { merge: true }));
  }

  getTeamById(id: string): AngularFirestoreDocument {
    return this.afs.collection('teams').doc(id);
  }

  getTeamPublicById(id: string): Observable<any>{
    return this.afs.collection('teams_public').doc(id).valueChanges();
  }

  getAllTeamsPublic(): Observable<ITeam[]> {
    return this.afs.collection('teams_public', ref => ref
    .where('team_id', 'in', this.userReadOnlyDb.team_ids)).valueChanges() as Observable<ITeam[]>;
    }


  updateHasActiveAccount() {
    return fromPromise(this.afs.collection('users').doc(this.userDb.uid).set({ has_inactive_account: false }, { merge: true }));
  }

  createTeamString() {
    const team_ids = this.userReadOnlyDb.team_ids;
    return (team_ids && team_ids.length) ? team_ids.map((id: string) => ` OR team_id:${id}`).join('') : '';
  }

  getAllTeamMembers() {
    return from(this.getAllTeams().pipe(
      map((teams: any) => {
        return teams.map((team: any) => {
          return team.members.map((member: any) => member.uid);
        });
      }),
      map((uids: any) => uids && uids.length ? uids.flat().map((uid: string) => this.getUserPermissionedById(uid).valueChanges()) : of([])),
      switchMap((users: Array<Observable<IUserPermissioned>>) => users && users.length ? zip(...users) : of([])),
      map((teamMembers:Array<IUserPermissioned>) => teamMembers.filter((member:IUserPermissioned) => !!member))
    ))
  }

  private getAllTeams() {
   return this.afs.collection('teams', ref => ref
        .where('team_id', 'in', this.userReadOnlyDb.team_ids)).valueChanges();

  }

  public getAllMoneyHubUsers() {
    return this._auth.getCurrentUserCustomClaims().pipe(
      switchMap((res: boolean) => {
        if (res && this._auth.currentUser.displayName) {
          return of([]);
        } else if (res && !this._auth.currentUser.displayName) {
          return this.afs.collection('users', ref => ref
          .where('moneyhub_user_id', '>', '')).valueChanges()
        } else {
          return this.userDb.moneyhub_user_id ? of([this.userDb]) : of([]);
        }
      })
    );

  }

  get isLandlord(): boolean {
    return this.userDb.role === EUserRole.landlord;
  }

}

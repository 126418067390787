import { Component, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { UserService } from '@app/services';
import { TenantVerifyEventEmiterService } from '../profile-tenant.service';
import { Router, ActivatedRoute } from '@angular/router';
import { TenantAccountVerifyControllerComponent } from './bunk-passport-verify-controller.component';
import { VeriStepRoute, VeriStepRoutes, requestIntro } from '../profile-tenant.constants';
import { Subscription } from 'rxjs';
import { first } from 'rxjs/operators';
import { TenantAccountRequestViewingsSummaryComponent } from '../_shared/tenant-account-request-viewing-summary.component';
import { AuthService } from '@app/core/services';

@Component({
  selector: 'app-tenant-verify-tier',
  template: `
    <ng-container *ngIf="userSteps$ | async as userSteps">
      <section *ngIf="stepComponent" class="main__wrapper main__wrapper--narrow main__wrapper--mb-lg">
        <ng-container *ngComponentOutlet="stepComponent; ndcDynamicOutputs: outputs; ndcDynamicInputs: inputs;"></ng-container>
      </section>
      <footer *ngIf="userSteps.length > 1 && !requestViewings" class="verify-tiers__footer">
        <ul>
          <li><span role="button" class="status__step status__step--verify-tier-pending" [ngClass]="{'status__step--verify-complete': currentStepNumber !== -1}"></span></li>
          <li *ngFor="let step of userSteps, let i = index">
            <span
              role="button"
              class="status__step status__step--verify"
              [ngClass]="{
                'status__step--verify-tier-pending': currentStepNumber === i,
                'status__step--verify-complete': step.status === 'approved'
              }">
            </span>
          </li>
        </ul>
      </footer>
    </ng-container>
  `,
  styleUrls: ['bunk-passport-verify-tiers.component.scss']
})
export class TenantAccountVerifyTiersComponent extends TenantAccountVerifyControllerComponent implements OnDestroy {
  @Input() tier: number;
  @Input() propertyThoroughfare: string;
  @Input() overlay: any;
  @Output() callback: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() currentPageEvent: EventEmitter<number> = new EventEmitter<number>();
  
  public stepComponent: any;
  public memorySteps: VeriStepRoutes;
  public requestViewings = false;
  public userSub: Subscription;
  public outputs = {
    // a feature of 'ngComponentOutlet'
    // Used for request-viewing-summary.component - when we need to confirm the viewing date request
    callback: () => this.callback.emit(true),
    closeOverlay: () => this.overlay.close()
  };
  public inputs = {
    // a feature of 'ngComponentOutlet'
    showPrev: true  // this.currentStepNumber >= 1
  };

  constructor(
    _eventEmiter: TenantVerifyEventEmiterService,
    _router: Router,
    _route: ActivatedRoute,
    _user: UserService,
    _auth: AuthService
  ) {
    super(_eventEmiter, _router, _route, _user, _auth);

    // One time subscription to load the first step...
    this.userSub = this.userSteps$.pipe(
      first(),
    // ).subscribe(() => this.gotoStep(this.memorySteps[0]));
    ).subscribe(() => this.gotoStep(requestIntro));
  }

  getVerificationSteps(user_re_steps: VeriStepRoutes): VeriStepRoutes {
    const steps = [ ...this.steps ];
    /* 
      Filter out the completed steps,
      and return the incomplete steps that are greater than the users tier and <= than the component Input:tier
      This will allow the inclusion of steps in later tiers such as Make a Booking when only at tier 1*/
    // Use the master array of steps to build the expected verification flow
    user_re_steps.forEach((re_item: any) => {
      steps[re_item.step - 1].status = re_item.status !== '' ? re_item.status : 'unverified';
    });
    if (!this.memorySteps) {
      this.memorySteps = steps.filter((step: VeriStepRoute) => !step.status && (step.tier > this.userTier) && (step.tier <= this.tier));
    }
    return this.memorySteps;
  }

  getNextFromNavigation(navigateStep: string): VeriStepRoute  {
    let nextStep: VeriStepRoute;
    switch (navigateStep) {
      case 'next':
        nextStep = this.memorySteps[this.currentStepNumber + 1];
        break;

      case 'prev':
        if (this.currentStepNumber === 0) {
          nextStep = requestIntro;
        } else {
          nextStep = this.memorySteps[this.currentStepNumber - 1];
        }
        break;
    
      default:
        nextStep = this.getStep(this.currentStepNumber);
        break;
    }
    return nextStep;
  }

  getNextStep() {
    return this.memorySteps.slice(this.currentStepNumber + 1).find((step: VeriStepRoute) => !step.status || step.status === 'unverified');
  }

  gotoStep(targetStep: VeriStepRoute): void {
    console.log(this.memorySteps);
    // check if tier complete...
    if (this.memorySteps.every((step: VeriStepRoute) => step.status === 'approved')) {
      // wipe history with direct route and show success screen 
      this.stepComponent = TenantAccountRequestViewingsSummaryComponent;
      this.requestViewings = true;
      return;
    }
    if (!targetStep) {
      return;
    }
    this.currentStepNumber = this.memorySteps.findIndex((step: VeriStepRoute) => targetStep.path === step.path);
    // this.inputs.showPrev = this.currentStepNumber >= 1;
    this.currentPageEvent.emit(this.currentStepNumber);
    setTimeout(() => {
      this.stepComponent = targetStep.component;
    });
  }

  getStep(id: number): VeriStepRoute {
    return this.memorySteps.find((step: VeriStepRoute) => this.steps[id].path === step.path);
  }

  ngOnDestroy() {
    this.userSub.unsubscribe();
  }
}

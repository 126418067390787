/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "@angular/core";
import * as i1 from "./bunk-dialog.component";
import * as i2 from "@angular/material/dialog";
import * as i3 from "@angular/cdk/layout";
var styles_BunkDialogComponent = [];
var RenderType_BunkDialogComponent = i0.ɵcrt({ encapsulation: 2, styles: styles_BunkDialogComponent, data: {} });
export { RenderType_BunkDialogComponent as RenderType_BunkDialogComponent };
export function View_BunkDialogComponent_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "span", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openDialog() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i0.ɵncd(null, 0)], null, null); }
export function View_BunkDialogComponent_Host_0(_l) { return i0.ɵvid(0, [(_l()(), i0.ɵeld(0, 0, null, null, 1, "bunk-dialog", [], null, null, null, View_BunkDialogComponent_0, RenderType_BunkDialogComponent)), i0.ɵdid(1, 245760, null, 0, i1.BunkDialogComponent, [i2.MatDialog, i3.BreakpointObserver], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var BunkDialogComponentNgFactory = i0.ɵccf("bunk-dialog", i1.BunkDialogComponent, View_BunkDialogComponent_Host_0, { config: "config", component: "component", snapFullScreen: "snapFullScreen", restrict: "restrict" }, { dialogRef: "dialogRef", onClosed: "onClosed" }, ["*"]);
export { BunkDialogComponentNgFactory as BunkDialogComponentNgFactory };

<div class="main__wrapper">
  <ng-container *ngIf="{paymentTypes: paymentTypes$ | async, expenseTypes: expenseTypes$| async} as data">

    <div class="btn_filter__container">
      <shared-extended-filters [title]="'Payments'" class="w_100 relative" [otherFilters]="allFilterTypes"
        (filterEmitter)="updateBehaviourSubjects($event)">
        <button class="margin-top--xs search-filters__btn-control btn__seethrough btn--round btn--sm mr_5"
          (click)="showFilters = true">
          <span>{{dateTitle}}</span>
          <mat-icon svgIcon="icon-expand-more" class="icon-expand-more"></mat-icon>
        </button>
        <div class="search-filters__controls-menu date-range-dropdown" *ngIf="showFilters">
          <ng-container *ngTemplateOutlet="displayChoices"></ng-container>
          <button class="btn__info" (click)="showFilters = false">Done</button>
        </div>

        <div class="btn__align" actionButton>
          <bunk-dialog [component]="contentDialog" (dialogRef)="dref = $event">
            <button class="btn__generic margin-right" type="button">Create payment</button>
          </bunk-dialog>
          <bunk-dialog [component]="contentExpenseDialog" (dialogRef)="dref = $event"
            *ngIf="_user.userDb.role === 'landlord'">
            <button class="btn__seethrough btn__expense" type="button">Add Expense</button>
          </bunk-dialog>
        </div>

      </shared-extended-filters>
    </div>

    <ng-template #contentDialog>
      <mat-dialog-content>
        <form-create-payments (closeDialog)="closeDialog($event)" [paymentTypes]="data.paymentTypes">
        </form-create-payments>
      </mat-dialog-content>
    </ng-template>
    <ng-template #contentExpenseDialog>
      <mat-dialog-content>
        <form-create-expenses (closeDialog)="closeDialog($event)" [expenseTypes]="data.expenseTypes">
        </form-create-expenses>
      </mat-dialog-content>
    </ng-template>

  </ng-container>

  <ng-template #loadSkeleton>
    <div class="hide-sm">
      <skeleton-loading></skeleton-loading>
    </div>
  </ng-template>

  <section class="margin-top--lg">
    <ng-template #noPayments>
      <div class="main__wrapper">
        <div class="margin-top--md flex flex-column items-center text">
          <img class="state__image" src="/assets/img/no-state/no-payments.png" alt="">
          <h3 class="text--lg margin-top--sm">Any payments you receive you will see here.</h3>
          <p>You currently do not have any payments. Once you have a tenancy set up, you will view the payments here.
          </p>
        </div>
      </div>
    </ng-template>
    <ng-container *ngIf="combinedPaymentsAndfilters$ | async as payments; else loadSkeleton">
      <ng-container *ngIf="payments.combined.length; else noPayments">
        <div class="hide-sm">
          <bunk-payment-table (selectedId)="goToProperty($event)" (pagination)="updatePagination($event)"
            [data]="payments.combined" [totalHits]="payments.totalHits" [columnsToDisplay]="columnsToDisplay"
            [displayedColumns]="displayedColumns" [pageNumber]="payments.page" [itemsPerPage]="payments.hitsPerPage"
            [isAdmin]="payments.isAdmin">
          </bunk-payment-table>
        </div>
        <div class="card__list-item-new hide-md hide-lg" *ngFor="let payment of payments.combined">
          <div class="payment__container-top">
            <profile-avatar size="sm" *ngIf="payment.tenantImage && payment.tenantName"
              [profileImgUrl]="payment.tenantImage" [profileName]="payment.tenantName"></profile-avatar>
            <div class="payment__info-container">
              <div class="flex flex-column">
                <span>{{payment.propertyAddress}}</span>
                <span class="text--subtle text--sm">{{payment.momentDueDate}}</span>
              </div>
              <div class="flex flex-column">
                <span>£{{payment.amount}}</span>
                <span class="text--subtle text--sm">{{payment.paymentType}}</span>
              </div>
            </div>
          </div>
          <div class="flex flex-row margin-top--md">
            <span class="card__status-banner " [ngClass]="{
            'card__status-banner--warning': payment.status.value === RENT_STATUS.DUE
            || payment.status.value === RENT_STATUS.PARTIALLY_PAID
            ||  payment.status.value === RENT_STATUS.PARTIALLY_RECEIVED,
            'card__status-banner--danger': payment.status.value === RENT_STATUS.OVERDUE,
            'card__status-banner--success': payment.status.value === RENT_STATUS.PAID
            }">{{payment.status.viewValue}}</span>
          </div>
        </div>

        <div class="flex justify-center hide-lg hide-md">
          <button class="btn__generic" *ngIf="showMore" (click)="loadMore()">load more</button>
        </div>
      </ng-container>
    </ng-container>
  </section>

</div>

<ng-template #displayChoices>
  <div class="filter__type-container filter__type-container--content cursor-pointer"
    (click)="updateFormValue(filter.value)" *ngFor="let filter of dateFilterType">
    <p> {{filter.viewValue}}</p>
    <span class="selected" *ngIf="paymentsFilterControls.date.value === filter.value"></span>
  </div>
  <ng-container *ngIf="paymentsFilterControls.date.value === 'custom_date'">
    <form class="date__form" [formGroup]="customDateFormGroup">
      <mat-form-field appearance="outline">
        <mat-label>From:</mat-label>
        <input [formControlName]="'date_from'" matInput (click)="fromDate.open()" [matDatepicker]="fromDate"
          (dateChange)="dateHasChanged()">
        <mat-datepicker-toggle matSuffix [for]="fromDate"></mat-datepicker-toggle>
        <mat-datepicker #fromDate></mat-datepicker>
        <mat-error>This field is required</mat-error>
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label>To:</mat-label>
        <input [formControlName]="'date_to'" matInput (click)="toDate.open()" [matDatepicker]="toDate"
          (dateChange)="dateHasChanged()">
        <mat-datepicker-toggle matSuffix [for]="toDate"></mat-datepicker-toggle>
        <mat-datepicker #toDate></mat-datepicker>
        <mat-error>This field is required</mat-error>
      </mat-form-field>
    </form>
  </ng-container>
</ng-template>

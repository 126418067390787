import { Component, OnInit, forwardRef, Optional, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR, NG_VALIDATORS, Validator, FormGroupDirective, FormGroup, FormControl, Validators } from '@angular/forms';
import { AbstractValueAccessor } from '@app/shared/_components/searches/instant-search/instant-search.utils';
import { FormsService } from '@app/services/form.service';
import { FORM_BANK_DETAILS } from './application-forms.constants';
import { TRANSLATIONS } from '@app/_constants/translations.constants';

@Component({
  selector: 'form-bank-details',
  template: `
  <form [formGroup]="bankDetailsForm" autocomplete="none">
    <bunk-form-field *ngIf="getFormControl('account_holder_name')">
      <label bunk-label >Bank account holder name</label>
      <small bunk-label-stack>The {{landlord}} requires your bank details for receiving a holding fee</small>
z    <mat-form-field bunk-input appearance="outline">
      <input matInput type="text" [formControlName]="'account_holder_name'" placeholder="e.g James Rees">
      <mat-error>Please choose enter the name of the account holder</mat-error>
    </mat-form-field>
    </bunk-form-field>

    <bunk-form-field *ngIf="getFormControl('account_number')">
      <label bunk-label >Bank account number</label>
    <mat-form-field bunk-input appearance="outline">
      <input (keydown)="getFormControl('account_number').markAsTouched();" matInput type="text" inputmode="numeric" [formControlName]="'account_number'" maxlength="8" placeholder="e.g 12345678" (blur)="checkLength('account_number', 8)">
      <mat-error *ngIf="getFormControl('account_number').hasError('required')">Please enter your account number</mat-error>
      <mat-error *ngIf="getFormControl('account_number').invalid && getFormControl('account_number').errors.pattern">Please only provide numbers</mat-error>
      <mat-error *ngIf="getFormControl('account_number').touched && getFormControl('account_number').hasError('maxLength')">Account number must be 8 characters long</mat-error>
    </mat-form-field>
    </bunk-form-field>

    <bunk-form-field *ngIf="getFormControl('bank_name')">
      <label bunk-label >Bank name</label>
    <mat-form-field bunk-input appearance="outline">
      <input matInput type="text" [formControlName]="'bank_name'" placeholder="e.g Bank name">
      <mat-error>Please choose enter the name of the bank</mat-error>
    </mat-form-field>
    </bunk-form-field>

    <bunk-form-field *ngIf="getFormControl('sort_code')">
      <label bunk-label >Sort code</label>
    <mat-form-field bunk-input appearance="outline">
      <input (keydown)="getFormControl('sort_code').markAsTouched();" matInput type="text" [formControlName]="'sort_code'" maxlength="6" placeholder="e.g 200128" (blur)="checkLength('sort_code', 6)" >
      <mat-error *ngIf="getFormControl('sort_code').hasError('required')">Please provide your sort code</mat-error>
      <mat-error *ngIf="getFormControl('sort_code').invalid && getFormControl('sort_code').errors.pattern">Please only provide numbers</mat-error>
      <mat-error *ngIf="getFormControl('sort_code').touched && getFormControl('sort_code').hasError('maxLength')">Sort code must be 6 characters long</mat-error>
    </mat-form-field>
    </bunk-form-field>
  </form>

  `,
  styleUrls: ['./application-forms.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormBankDetailsComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => FormBankDetailsComponent),
      multi: true
    }
  ]
})
export class FormBankDetailsComponent extends AbstractValueAccessor implements OnInit, Validator {
  @Input() requiredFields = FORM_BANK_DETAILS;
  bankDetailsForm: FormGroup;
  private _submitAttempted = false;
    public landlord = TRANSLATIONS.landlord;

  @Input('submitAttempted') set submitAttempted(boo: boolean) {
    this._submitAttempted = boo;
    if (boo) {
      this.dirtyFormGroup();
    }
  }
  get submitAttempted(): boolean {
    return this._submitAttempted;
  }


  constructor(
    @Optional() public _reactiveForm: FormGroupDirective,
    private _forms: FormsService
  ) {
    super();
  }

  dirtyFormGroup() {
    if(this.bankDetailsForm ){
    const controls = this.bankDetailsForm.controls;
    for (const control in controls) {
      if (controls.hasOwnProperty(control)) {
        this.bankDetailsForm.controls[control].markAsTouched();
      }
    }
  }
  }


  getFormControl(name: string): FormControl {
    return this.bankDetailsForm.get(name) as FormControl;
  }

  ngOnInit(): void {
    this.bankDetailsForm = this._forms.toFormGroup(this.requiredFields);
    this._reactiveForm.ngSubmit.subscribe((data: Event) => {
      this.submitAttempted = true;
      const controls = this.bankDetailsForm.controls;
      for (const control in controls) {
        if (controls.hasOwnProperty(control)) {
          this.bankDetailsForm.controls[control].markAsTouched();
        }
      }
    });
  }


  public onTouched: () => void = () => { };

  writeValue(val: any): void {
    super.writeValue(val);
    if (val) {
      this.bankDetailsForm.patchValue(val, { emitEvent: false });
    }
  }
  registerOnChange(fn: any): void {
    this.bankDetailsForm.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.bankDetailsForm.disable() : this.bankDetailsForm.enable();
  }

  validate() {
    return this.bankDetailsForm.valid ? null : { invalidForm: { valid: false, message: 'FormBankDetailsComponent > formGroup fields are invalid' } };
  }

  checkLength(formControl: string, length: number) {
    const control = this.bankDetailsForm.get(formControl);
    control.setValidators(Validators.pattern('^(0|[0-9][0-9]*)$'));
    if (control.value && control.value.toString().length !== length) {
      control.setErrors({maxLength: true});
    } else {
      control.setErrors(null);
    }
    control.updateValueAndValidity();
  }

}

import { Component, OnInit, forwardRef, Optional, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR, NG_VALIDATORS, Validator, FormGroupDirective, FormGroup, FormControl, Validators } from '@angular/forms';
import { AbstractValueAccessor } from '@app/shared/_components/searches/instant-search/instant-search.utils';
import { FormsService } from '@app/services/form.service';
import { FORM_INFO, REFERRAL_SOURCES } from './application-forms.constants';
import { TITLES, MIN_TENANT_AGE, GENDER_VALUES, RENT_RESPONSIBILITY, PAYMENT_PREFERENCE } from '@app/_constants/forms.constants';
import { environment } from '@env/environment';

@Component({
  selector: 'form-info',
  template: `
  <form [formGroup]="infoForm" autocomplete="none">
  <bunk-form-field *ngIf="getFormControl('title')">
    <label bunk-label>What is your title?</label>
    <mat-form-field bunk-input appearance="outline">
      <mat-select formControlName="title" placeholder="Select title">
        <mat-option *ngFor="let title of titles" [value]="title.value">
        {{title.viewValue}}</mat-option>
      </mat-select>
      <mat-error>Please select your title</mat-error>
    </mat-form-field>
  </bunk-form-field>

  <bunk-form-field *ngIf="getFormControl('first_name')">
    <label bunk-label>What is your first name?</label>
    <mat-form-field bunk-input appearance="outline">
      <input matInput type="text" formControlName="first_name" placeholder="E.g Ben">
    </mat-form-field>
    <mat-error>Please enter your first name</mat-error>
  </bunk-form-field>

  <bunk-form-field *ngIf="getFormControl('middle_name')">
    <label bunk-label>What is your middle name? (optional)</label>
    <mat-form-field bunk-input appearance="outline">
      <input matInput type="text" formControlName="middle_name" placeholder="E.g Dave">
    </mat-form-field>
  </bunk-form-field>

  <bunk-form-field *ngIf="getFormControl('last_name')">
    <label bunk-label>What is your last name?</label>
    <mat-form-field bunk-input appearance="outline">
      <input matInput type="text" formControlName="last_name" placeholder="E.g Brown">
    </mat-form-field>
    <mat-error>Please enter your last name</mat-error>
  </bunk-form-field>


  <bunk-form-field *ngIf="getFormControl('dob')">
    <label bunk-label>What is your Date of birth?</label>
    <div bunk-input>
      <date-selector
        yearStart="1901"
        [yearEnd]="minAgeYear"
        formControlName="dob"
        [submitAttempted]="submitAttempted">
      </date-selector>
    </div>
  </bunk-form-field>


  <bunk-form-field *ngIf="getFormControl('gender')">
  <label bunk-label>What is your gender?</label>
  <mat-form-field appearance="outline" bunk-input>
    <mat-select  [formControl] = "genderDisplayFormField"  placeholder="Select" >
      <mat-option *ngFor="let gender of userGender" [value]="gender.value">
        {{gender.viewValue}}
      </mat-option>
    </mat-select>
    <mat-error>Please enter your gender</mat-error>
  </mat-form-field>
  </bunk-form-field>

  <bunk-form-field *ngIf="genderDisplayFormField.value ==='other'">
    <label bunk-label>If 'other' please state</label>
    <mat-form-field  bunk-input appearance="outline">
      <input [formControl] = "otherGenderDisplayFormField"  matInput type="text" placeholder="E.g Gender Fluid">
      <mat-error >Please specify your gender</mat-error>
    </mat-form-field>

  </bunk-form-field>


  <bunk-form-field *ngIf="getFormControl('phone')">
        <label bunk-label >What is your phone number?</label>
        <div bunk-input>
        <form-input-phone
          bunk-input
          formControlName="phone"
          showLabel="true"
          [submitAttempted]="submitAttempted"
        ></form-input-phone>
        </div>
  </bunk-form-field>

    <bunk-form-field *ngIf="getFormControl('nationality')">
      <label bunk-label> What is your nationality?</label>
      <mat-form-field bunk-input appearance="outline">
        <input  matInput formControlName="nationality" type="text" placeholder="E.g British">
        <mat-error>Please enter your nationality</mat-error>
      </mat-form-field>
    </bunk-form-field>

    <bunk-form-field *ngIf="getFormControl('rent_responsibility')">
      <label bunk-label>Rent responsibility</label>
      <mat-form-field bunk-input appearance="outline">
        <mat-select formControlName="rent_responsibility" placeholder="Please select">
          <mat-option *ngFor="let rent of rentResponsibility" [value]="rent.value">
            {{rent.viewValue}}</mat-option>
        </mat-select>
        <mat-error>Please select</mat-error>
      </mat-form-field>
    </bunk-form-field>

    <bunk-form-field *ngIf="getFormControl('payment_preference')">
      <label bunk-label>What is your preferred payment schedule?</label>
      <mat-form-field bunk-input appearance="outline">
        <mat-select formControlName="payment_preference" placeholder="Please select">
          <mat-option *ngFor="let rent of paymentPreference" [value]="rent.value">
            {{rent.viewValue}}</mat-option>
        </mat-select>
        <mat-error>Please select</mat-error>
      </mat-form-field>
    </bunk-form-field>

    <bunk-form-field *ngIf="getFormControl('address_details')">
    <label bunk-label>What is your address?</label>
    <div bunk-input>
      <form-input-address formControlName="address_details" [submitAttempted]="submitAttempted"></form-input-address>
    </div>
  </bunk-form-field>
  <bunk-form-field *ngIf="getFormControl('school_name')">
        <label bunk-label>What high school did you attend? </label>
          <mat-form-field bunk-input appearance="outline">
            <input formControlName="school_name" matInput type="text" name="school_name" placeholder="E.g High Grove">
            <mat-error>Please enter the name of your school</mat-error>
          </mat-form-field>
  </bunk-form-field>
  <bunk-form-field *ngIf="getFormControl('referral_source')">
    <label bunk-label > Where did you hear about {{client_data.company_name}}?</label>
    <mat-form-field bunk-input appearance="outline">
      <mat-select formControlName="referral_source" placeholder="Select">
        <mat-option *ngFor="let source of referralSources" [value]="source.value">
          {{source.viewValue}}
        </mat-option>
      </mat-select>
    <mat-error>Please select the referral source</mat-error>
    </mat-form-field>
  </bunk-form-field>

  <bunk-form-field *ngIf="getFormControl('application_year')">
    <label bunk-label > What year are you applying for?</label>
    <mat-form-field bunk-input appearance="outline">
      <mat-select formControlName="application_year" placeholder="Select">
        <mat-option [value]="currentYear"> {{currentYear}} </mat-option>
        
        <mat-option [value]="currentYear+1"> {{currentYear+1}} </mat-option>
      </mat-select>
    <mat-error>Please select the application year</mat-error>
    </mat-form-field>
  </bunk-form-field>

  <bunk-form-field *ngIf="getFormControl('referral_source_name') && infoForm.get('referral_source').value ==='referral campaign'">
        <label bunk-label>What is the full name of the student who referred you?</label>
          <mat-form-field bunk-input appearance="outline">
            <input formControlName="referral_source_name" matInput type="text" name="referral_source_name" placeholder="E.g Joe Bloggs">
            <mat-error>Please enter the name</mat-error>
          </mat-form-field>
  </bunk-form-field>
  <bunk-form-field *ngIf="getFormControl('referral_source_phone') &&getFormControl('referral_source_country_code')   && infoForm.get('referral_source').value ==='referral campaign'">
        <label bunk-label >What is their phone number?</label>
        <div bunk-input>
        <form-input-phone
          bunk-input
          [formControl] ="referralPhoneNumberField"
          showLabel="true"
          [submitAttempted]="submitAttempted"
        ></form-input-phone>
        </div>
  </bunk-form-field>
  <bunk-form-field *ngIf="getFormControl('native_language')">
        <label bunk-label>What is your home language?</label>
          <mat-form-field bunk-input appearance="outline">
            <input formControlName="native_language" matInput type="text" name="native_language" placeholder="E.g English">
            <mat-error>Please enter the language</mat-error>
          </mat-form-field>
  </bunk-form-field>
  <bunk-form-field *ngIf="getFormControl('is_bursary_student')">
    <label bunk-label >Are you a bursary student?</label>
    <div bunk-input>
      <mat-radio-group formControlName="is_bursary_student" class="flex flex-row margin-bottom">
        <mat-radio-button [value]="true" class="bunk-outlined-radio mr_5" [ngClass]="{'bunk-outlined-radio--selected': getFormControl('is_bursary_student').value === true}" >Yes</mat-radio-button>
        <mat-radio-button [value]="false" class="bunk-outlined-radio" [ngClass]="{'bunk-outlined-radio--selected': getFormControl('is_bursary_student').value === false}">No</mat-radio-button>
      </mat-radio-group>
      <mat-error class="bunk-mat-error-font-match"*ngIf="getFormControl('is_bursary_student').getError('required') && submitAttempted">Please select</mat-error>
    </div>
    </bunk-form-field>
    <bunk-form-field *ngIf="getFormControl('bursary_name') && infoForm.get('is_bursary_student').value === true">
    <label bunk-label>What is the name of the bursary?</label>
          <mat-form-field bunk-input appearance="outline">
            <input formControlName="bursary_name" matInput type="text" name="bursary_name" placeholder="E.g Academy of Digital Arts">
            <mat-error>Please enter the bursary name</mat-error>
          </mat-form-field>
    </bunk-form-field>
    <bunk-form-field *ngIf="getFormControl('bursary_phone') && getFormControl('bursary_country_code') && infoForm.get('is_bursary_student').value === true">
        <label bunk-label >What is the bursary contact number?</label>
        <div bunk-input>
        <form-input-phone
          bunk-input
          [formControl] = "bursaryPhoneNumberField"
          showLabel="true"
          [submitAttempted]="submitAttempted"
        ></form-input-phone>
        </div>
  </bunk-form-field>
  <bunk-form-field *ngIf="getFormControl('one_word_description')">
    <label bunk-label>How would you describe yourself in one word?</label>
          <mat-form-field bunk-input appearance="outline">
            <input formControlName="one_word_description" matInput type="text" name="one_word_description" placeholder="E.g Adventurous">
            <mat-error>Please enter the description</mat-error>
          </mat-form-field>
    </bunk-form-field>
    <bunk-form-field *ngIf="getFormControl('has_covid_vaccine')">
    <label bunk-label >Have you been vaccinated against COVID-19?</label>
    <div bunk-input>
      <mat-radio-group formControlName="has_covid_vaccine" class="flex flex-row margin-bottom">
        <mat-radio-button [value]="true" class="bunk-outlined-radio mr_5" [ngClass]="{'bunk-outlined-radio--selected': getFormControl('has_covid_vaccine').value === true}" >Yes</mat-radio-button>
        <mat-radio-button [value]="false" class="bunk-outlined-radio" [ngClass]="{'bunk-outlined-radio--selected': getFormControl('has_covid_vaccine').value === false}">No</mat-radio-button>
      </mat-radio-group>
      <mat-error class="bunk-mat-error-font-match"*ngIf="getFormControl('has_covid_vaccine').getError('required') && submitAttempted">Please select</mat-error>
    </div>
    </bunk-form-field>

  </form>
  `,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormInfoComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => FormInfoComponent),
      multi: true
    }
  ]
})
export class FormInfoComponent extends AbstractValueAccessor implements OnInit, Validator {
  @Input() requiredFields = FORM_INFO;
  public titles = TITLES;
  public userGender = GENDER_VALUES;
  public rentResponsibility = RENT_RESPONSIBILITY;
  public paymentPreference = PAYMENT_PREFERENCE;
  public referralSources = REFERRAL_SOURCES;
  public minAgeYear = new Date().getFullYear() - MIN_TENANT_AGE;
  public currentYear = new Date().getFullYear();

  infoForm: FormGroup;
  public genderDisplayField : FormControl  = new FormControl(null, Validators.required);
  public otherGenderField: FormControl = new FormControl(null,  Validators.required);
  public bursaryPhoneWithCountryCode: FormControl = new FormControl(null, Validators.required);
  public referralPhoneWithCountryCode: FormControl = new FormControl(null, Validators.required);
  public client_data = environment.client_data;
  @Input('submitAttempted') set submitAttempted(boo: boolean) {
    this._submitAttempted = boo;
    if (boo) {
      this.dirtyFormGroup();
    }
  }
  get submitAttempted(): boolean {
    return this._submitAttempted;
  }
  private _submitAttempted = false;

  constructor(
    @Optional() public _reactiveForm: FormGroupDirective,
    private _forms: FormsService
  ) {
    super();
  }

  dirtyFormGroup() {
    if(this.infoForm ){

    const controls = this.infoForm.controls;
    for (const control in controls) {
      if (controls.hasOwnProperty(control)) {
        this.infoForm.controls[control].markAsTouched();
      }
    }
  }
  if(this.genderDisplayField.value ==='other'){
    this.otherGenderDisplayFormField.markAsTouched()
  }
  if(!this.genderDisplayField.value){

    this.genderDisplayField.markAsTouched();
  }
  }

  getFormControl(name: string): FormControl {
    return this.infoForm.get(name) as FormControl;
  }

  ngOnInit(): void {

    this.infoForm = this._forms.toFormGroup(this.requiredFields);

    if  (this.getFormControl('middle_name'))  {
      this.setFormControlValidationToNull(this.getFormControl('middle_name'))
    }
    if(this.getFormControl('bursary_name')){
      this.setFormControlValidationToNull(this.getFormControl('bursary_name'))
    }
    if(this.getFormControl('bursary_country_code')){
      this.setFormControlValidationToNull(this.getFormControl('bursary_country_code'))
    }
    if(this.getFormControl('bursary_phone')){
      this.setFormControlValidationToNull(this.getFormControl('bursary_phone'))
    }
    if(this.getFormControl('referral_source_name')){
    this.setFormControlValidationToNull(this.getFormControl('referral_source_name'))
    }
    if(this.getFormControl('referral_source_country_code')){
      this.setFormControlValidationToNull(this.getFormControl('referral_source_country_code'))
    }
    if(this.getFormControl('referral_source_phone')){
      this.setFormControlValidationToNull(this.getFormControl('referral_source_phone'));
    }


    this._reactiveForm.ngSubmit.subscribe((data: Event) => {
      this.submitAttempted = true;
      if (this._reactiveForm.submitted) {
        this.dirtyFormGroup();
      }
    });



    this.genderDisplayFormField.valueChanges.subscribe(value => {
      if(value!== 'other'){
        this.getFormControl('gender').patchValue(value)
      } else {
        this.otherGenderDisplayFormField.value? this.getFormControl('gender').patchValue(this.otherGenderDisplayFormField.value) : this.getFormControl('gender').patchValue(null)
      }
    });
    this.otherGenderDisplayFormField.valueChanges.subscribe(value => this.getFormControl('gender').patchValue(value) )

    if(this.getFormControl('is_bursary_student')){
      if(this.getFormControl('is_bursary_student').value === true){
        this.setFormControlValidationToRequired(this.getFormControl('bursary_name'));
        this.setFormControlValidationToRequired(this.getFormControl('bursary_country_code'));
        this.setFormControlValidationToRequired(this.getFormControl('bursary_phone'));
      }
      this.getFormControl('is_bursary_student').valueChanges.subscribe(value => {
        if(value === true){
          this.setFormControlValidationToRequired(this.getFormControl('bursary_name'));
          this.setFormControlValidationToRequired(this.getFormControl('bursary_country_code'));
          this.setFormControlValidationToRequired(this.getFormControl('bursary_phone'));
          this.bursaryPhoneNumberField.valueChanges.subscribe(value =>  {
            if(value){
            this.getFormControl('bursary_country_code').patchValue(value.area_code);
            this.getFormControl('bursary_phone').patchValue(value.phone_number);

            }
          })
        }
        else{
          this.getFormControl('bursary_name').patchValue(null);
          this.setFormControlValidationToNull(this.getFormControl('bursary_name'))
          this.getFormControl('bursary_country_code').patchValue(null);
          this.setFormControlValidationToNull(this.getFormControl('bursary_country_code'));
          this.getFormControl('bursary_phone').patchValue(null);
          this.setFormControlValidationToNull(this.getFormControl('bursary_phone'));
          this.bursaryPhoneNumberField.patchValue(null);

        }
      })

    }
    if(this.getFormControl('referral_source') ){

      if(this.getFormControl('referral_source').value === 'referral campaign'){
        this.setFormControlValidationToRequired(this.getFormControl('referral_source_name'));
        this.setFormControlValidationToRequired(this.getFormControl('referral_source_country_code'));
        this.setFormControlValidationToRequired(this.getFormControl('referral_source_phone'));
      }
      this.getFormControl('referral_source').valueChanges.subscribe(value => {
        if(value === 'referral campaign'){
          this.setFormControlValidationToRequired(this.getFormControl('referral_source_name'));
          this.setFormControlValidationToRequired(this.getFormControl('referral_source_country_code'));
          this.setFormControlValidationToRequired(this.getFormControl('referral_source_phone'));
          this.referralPhoneNumberField.valueChanges.subscribe(value => {
            if(value){
            this.getFormControl('referral_source_country_code').patchValue(value.area_code);
            this.getFormControl('referral_source_phone').patchValue(value.phone_number);
            }

          })
        }
        else{
          this.getFormControl('referral_source_name').patchValue(null)
          this.setFormControlValidationToNull(this.getFormControl('referral_source_name'))
          this.getFormControl('referral_source_country_code').patchValue(null);
          this.setFormControlValidationToNull(this.getFormControl('referral_source_country_code'))
          this.getFormControl('referral_source_phone').patchValue(null);
          this.setFormControlValidationToNull(this.getFormControl('referral_source_phone'))
          this.referralPhoneNumberField.patchValue(null);
        }
      })
    }
   }



  public onTouched: () => void = () => { };

  writeValue(val: any): void {
    super.writeValue(val);
    if (val) {
      if(val.gender){
        if(val.gender !== 'male' && val.gender !== 'female' && val.gender){
          this.genderDisplayFormField.setValue('other');
          this.otherGenderDisplayFormField.setValue(val.gender);
        }else{
          this.genderDisplayFormField.setValue(val.gender);
        }
        this.infoForm.patchValue(val,  { emitEvent: false } )
      }
      if(val.bursary_phone && val.bursary_country_code){
        this.bursaryPhoneNumberField.patchValue({
          area_code: val.bursary_country_code,
          phone_number: val.bursary_phone
        })
      }
      if(val.referral_source_phone && val.referral_source_country_code){
        this.referralPhoneNumberField.patchValue({
          area_code: val.referral_source_country_code,
          phone_number: val.referral_source_phone
        })
      }
      this.infoForm.patchValue(val, { emitEvent: false });
        }
  }
  registerOnChange(fn: any): void {
    this.infoForm.valueChanges.subscribe(fn);
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.infoForm.disable() : this.infoForm.enable();
  }

  validate() {
    return this.infoForm.valid ? null : { invalidForm: { valid: false, message: 'FormInfoComponent > formGroup fields are invalid' } };
  }

  get genderDisplayFormField(){
    return this.genderDisplayField as FormControl
  }
  get otherGenderDisplayFormField(){
    return this.otherGenderField as FormControl
  }

  get bursaryPhoneNumberField(){
    return this.bursaryPhoneWithCountryCode as FormControl;
  }

  get referralPhoneNumberField(){
    return this.referralPhoneWithCountryCode as FormControl;
  }

  setFormControlValidationToNull(control: FormControl){
    control.setValidators(null);
    control.updateValueAndValidity();
  }

  setFormControlValidationToRequired(control: FormControl){
    control.setValidators(Validators.required);
    control.updateValueAndValidity();
  }

}

import {Component, Input, ViewChild} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {Observable, from as fromPromise} from 'rxjs';
import {AngularFirestore} from '@angular/fire/firestore';
import {UserService} from '@app/services/user.service';
import {GroupService} from '@app/services/group.service';
import {AuthService} from '@app/core/services/auth.service';
import {ToastrService} from 'ngx-toastr';
import {map, mergeMap, switchMap, tap} from 'rxjs/operators';

@Component({
  selector: 'new-chat-group',
  templateUrl: './form-new-chat-group.component.html',
  styleUrls: ['./form-new-chat-group.component.scss']
})
export class FormNewChatGroupComponent {
  @Input() formGroup: FormGroup;
  @Input() modal;
  @Input() group_id: string;
  @ViewChild('mySelect', { static: false }) mySelect;

  public users$: Observable<any>;
  public user$: Observable<any>;
  public drop_down = false;
  members_id = [];
  users = [];

  constructor(private _user: UserService,
              private _group: GroupService,
              private afs: AngularFirestore,
              private _auth: AuthService,
              private toastr: ToastrService) {}

  updateMembersArray() {
    this.members_id.push(this.formGroup.controls.members_uid.value);
    this.user$ = this._user.getUserById(this.formGroup.controls.members_uid.value).valueChanges();
    this.users.push(this.user$);
  }

  createGroup() {
    this.drop_down = false;
    const id = this.afs.createId();
    const item = {
      group_name: this._user.userDb.profile_data.first_name,
      group_id: id,
      members_uid: {
        [this._auth.currentUserId]: 'ACCEPTED'
      },
      creator_uid: this._auth.currentUserId,
      date_created: new Date(),
      group_icon_url: this._user.userDb.profile_data.profile_image_url,   // @todo group_icon_url
      is_renting: false,    // @ todo is_renting

      last_message: {index: 1, message_body:  `${this._user.userDb.profile_data.first_name} started a group chat ` , sender_name: null, message_time: null},

      last_read_index: {[this._auth.currentUserId]: 1},
      user_notified: {[this._auth.currentUserId]: false}
    };

    for (const tenant_id of this.members_id) {
      item['members_uid'] = {...item.members_uid, [tenant_id]: 'PENDING'};
      item['last_read_index'] = {...item.last_read_index, [tenant_id]: 1};
    }

    return fromPromise(this._group.groupsCollection.doc(id).set(item)
      .then(() => this.toastr.success('Group Created!')
        && this.users.splice(0, this.users.length)
        && this.members_id.splice(0, this.members_id.length))
    );
  }

  removeGroupMember(idx, id) {
    this.users.splice(idx, 1);
    this.members_id.splice(this.members_id.indexOf(id), 1);
  }

  searchForUser() {
    this.drop_down = true;
    if (this.formGroup.controls.name.value.includes('@')) {
      return this.users$ = this._group.searchUsersByEmail(this.formGroup.controls.name.value).valueChanges()
        .pipe(
          tap(() => this.mySelect.focus())
        );
    } else {
      return this.users$ = this._group.searchUsersByName(this.formGroup.controls.name.value.charAt(0).toUpperCase().replace(/\s/g, '')
        + this.formGroup.controls.name.value.substr(1)).valueChanges()
        .pipe(
          tap(() => this.mySelect.focus())
        );
    }
  }

  addUsersToGroup() {
    const item = {
      members_uid: {},
      last_read_index: {}
    };
    for (const tenant_id of this.members_id) {
      item['members_uid'] = {...item.members_uid, [tenant_id]: 'PENDING'};
      item['last_read_index'] = {...item.last_read_index, [tenant_id]: 0};
      this._user.getUserById(tenant_id);
    }
    return fromPromise(this._group.groupsCollection.doc(this.group_id).set(item, {merge: true})
      .then(() => this.toastr.success('Recipients added!')
        && this.users.splice(0, this.users.length)
        && this.members_id.splice(0, this.members_id.length))
    );
  }
}

import { Component, Input, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { PropertyService, ViewingService, UserService } from '@app/services';
import { AuthService } from '@app/core/services';
import {finalize, flatMap, map, switchMap, takeUntil} from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs/internal/Observable';
import * as moment from 'moment';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { UK_FORMAT } from '@app/shared/_services/dates.service';
import {ActivatedRoute, Router} from '@angular/router';
import {of, Subject, zip} from 'rxjs';
import {tap} from 'rxjs/internal/operators/tap';
import {MinuteDuration} from '@app/viewings/viewings.constant';
import {environment} from '@env/environment';
import {EUserRole} from '@rentbunk/bunk-models';

@Component({
  selector: 'viewings-edit',
  templateUrl: './viewings-edit.component.html',
  styleUrls: ['./viewings-edit.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },
    { provide: MAT_DATE_FORMATS, useValue: UK_FORMAT }
  ]
})

export class ViewingsEditComponent implements OnInit,OnDestroy {
  @Input() viewing: any;
  @Input() property: any;
  public dref: any;
  private destroy$:Subject<boolean> = new Subject <boolean>();
  public sender$: Observable<any>;
  public landlord$: Observable<any>;
  public tenant$: Observable<any>;
  public minDate = new Date();
  public isTeamMember;
  formGroup: FormGroup;
  cancellationReason: FormControl;
  showSpinner = false;
  isEditing = false;
  elemAtTop: any;
  addeventatc: any;
  isLandlord = this._user.isLandlord;
  viewingCanceled: boolean;
  public teamFormGroup: FormGroup;

  public teamMembers$: Observable<any>;
  public team$: Observable<any>;
  public MIN_DURATION = MinuteDuration;
  isCampusKey = environment.firebaseConfig.projectId === 'client-campus-key';
  isTenant = this._user.userDb.role === EUserRole.tenant;
  isCampusKeyTenant = this.isCampusKey && this.isTenant;

  viewTypes = [
    { value: 'virtual', viewValue: 'Online Viewing', icon: 'virtual' },
    { value: 'in_person', viewValue: 'In-Person Viewing', icon: 'in-person' }
  ];
  constructor(private _formBuilder: FormBuilder,
              private _viewings: ViewingService,
              private _property: PropertyService,
              public _auth: AuthService,
              public _user: UserService,
              private _toastr: ToastrService,
              private _router: Router,
              private _activatedRoute: ActivatedRoute
    ) {
  }

  get viewing_date(): FormControl {
    return this.formGroup.get('viewing_date') as FormControl;
  }

  get viewing_time(): FormControl {
    return this.formGroup.get('viewing_time') as FormControl;
  }

  get viewing_request_message(): FormControl {
    return this.formGroup.get('viewing_request_message') as FormControl;
  }
  get type(): FormControl {
    return this.teamFormGroup.get('type') as FormControl;
  }


  get selectedViewType() {
    return this.viewing.type ? this.viewTypes.find(view => view.value === this.viewing.type) : this.viewTypes[1];
  }

  ngOnInit(): void {
    setTimeout(function () { this.addeventatc.refresh(); }, 200);
    this.isTeamMember = (this.viewing.team_id && (this._auth.currentUserId !== this.viewing.landlord_uid && this._auth.currentUserId !== this.viewing.tenant_uid));
    this.landlord$ = this._user.getUserPermissionedById(this.viewing.landlord_uid).valueChanges();
    this.tenant$ = this._user.getUserPermissionedById(this.viewing.tenant_uid).valueChanges();

    this.sender$ = this._user.getUserById(this.viewing.tenant_uid).valueChanges().pipe(
      map(user => {
        if (user) {
          return {
            name: user.profile_data.full_name,
            firstName: user.profile_data.first_name,
            image: user.profile_data.profile_image_url,
            uid: user.profile_data.uid,
          };
        }
      })
    );


    this.team$ = this.viewing && this.viewing.team_id ? this._user.getTeamById(this.viewing.team_id).valueChanges() : of([]);

    this.teamMembers$ = this.team$.pipe(
      map((team: any) => team && team.members ? team.members.map((member: any) => this._user.getUserById(member.uid).valueChanges()) : of([])),
      switchMap((users: any) => (users && users.length) ? zip(...users) : of([])),
      tap(user => console.log('hello user', user))
    );

    this.formGroup = this.initFormGroup();
    this.teamFormGroup = this.initTeamFormGroup();
    this.cancellationReason = new FormControl('');

    this.viewing_date.patchValue(this.getAcceptedViewingTime());
    this.viewing_time.patchValue(this.getAcceptedViewingTime());
    console.log(this.viewing, 'hello viewing');

    this.formGroup.patchValue(this.viewing);
    this.teamFormGroup.patchValue(this.viewing);

    this.viewingCanceled = this.viewing.status === 'cancelled_by_tenant' || this.viewing.status === 'cancelled_by_landlord';
  }

  initFormGroup() {
    const roundUpTo = roundTo => x => Math.ceil(x / roundTo) * roundTo;
    const roundUpTo5Minutes = roundUpTo(1000 * 60 * 5);
    const defaultViewingTime = roundUpTo5Minutes(new Date());
    return this._formBuilder.group({
      viewing_date: [null, Validators.required],
      viewing_time: [new Date(defaultViewingTime), Validators.required],
      viewing_request_message: '',
    });
  }

  initTeamFormGroup(): FormGroup {
    return this._formBuilder.group({
      type: null,
      assignee_uid:  null,
      duration_minutes: null
    });
  }


  updateViewing( type: string, value: any) {
    let item;
    switch (type) {
      case 'type':
         item = {type: value};
         break;
      case 'assignee_uid':
         item = {assignee_uid: value};
         break;
      case 'duration_minutes':
         item = {duration_minutes: value};
    }
    console.log(item, 'HELLOOO ITEM')
    return this._viewings.updateViewingType(this.viewing.viewing_id, item)
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => this._toastr.success(`Viewing Updated`));
  }



  rescheduleViewing() {
    this.showSpinner = true;
    const receiver_uid = (this.viewing.landlord_uid === this._auth.currentUserId || this.isTeamMember)
      ? this.viewing.tenant_uid
      : this.viewing.landlord_uid;
    const sender_uid = (receiver_uid === this.viewing.tenant_uid) ? this.viewing.landlord_uid : this.viewing.tenant_uid;
    this._viewings.rescheduleViewing(this.viewing.viewing_id, receiver_uid, sender_uid, this.viewing.last_viewing_request_index, this.formGroup.getRawValue()).pipe(
      finalize(() => {
        this.showSpinner = false;
        this.isEditing = false;
      })
    ).subscribe(() => this._toastr.success('Viewing successfully rescheduled!'));
  }

  acceptViewing() {
    this.showSpinner = true;

    const sender_uid = (this.viewing.landlord_uid === this._auth.currentUserId || this.isTeamMember)
      ? this.viewing.landlord_uid
      : this.viewing.tenant_uid;

    this._viewings.acceptViewing(this.viewing.viewing_id, this.viewing.last_viewing_request_index, sender_uid).pipe(
      finalize(() => {
        setTimeout(function() {
          this.addeventatc.refresh(); }, 200);
        this.showSpinner = false;
        this._router.navigate(['./'], {queryParams: {viewing_accepted: true}, relativeTo: this._activatedRoute});
        this.dref.close();
      })
    ).subscribe(() => this._toastr.success('Viewing accepted!'));
  }

  cancelViewing() {
    const sender_uid = (this.viewing.landlord_uid === this._auth.currentUserId || this.isTeamMember)
      ? this.viewing.landlord_uid
      : this.viewing.tenant_uid;

    this.showSpinner = true;
    console.log(this.viewing.viewing_id);
    this._viewings.cancelViewing(this.viewing.viewing_id, this.viewing.last_viewing_request_index, this.cancellationReason.value, sender_uid).pipe(
      finalize(() => this.showSpinner = false)
    ).subscribe(() => {
      this.dref.close();
      this._toastr.success('Viewing cancelled!');
    });
  }

  hasTimeDateChanged() {
    return this.viewing.last_viewing_request_index !== 0
      && this.getRequestedViewingTime(this.viewing.last_viewing_request_index - 1).getTime() !== this.getRequestedViewingTime(this.viewing.last_viewing_request_index).getTime();
  }

  getAcceptedViewingTime(): Date | null {
    if (this.viewing.accepted_viewing_time) {
      return this.viewing.accepted_viewing_time.toDate();
    }
    if (this.viewing.viewing_booked_time) {
      return this.viewing.viewing_booked_time.toDate();
    }
    return null;
  }

  getRequestedViewingTime(lastViewingRequestIndex: number): Date | null {
    if (this.viewing.requests[lastViewingRequestIndex]) {
      if (this.viewing.requests[lastViewingRequestIndex].requested_viewing_time) {
        return this.viewing.requests[lastViewingRequestIndex].requested_viewing_time.toDate();
      }
      if (this.viewing.requests[lastViewingRequestIndex].viewing_booked_time) {
        return this.viewing.requests[lastViewingRequestIndex].viewing_booked_time.toDate();
      }
    }
    return null;
  }

  getMomentDate(date: Date) {
    return moment(date).format('dddd Do MMMM YYYY');
  }

  getDateFromNow(dateCreated: any) {
    return moment(dateCreated.toDate()).fromNow();
  }

  getAddToCalendarDate(date: any) {
    return moment(date).format('DD-MM-YYYY H:m');
  }

  getEndTime(date: any) {
    const endDate = date.setTime(date.getTime() + 30 * 60 * 1000);
    return moment(endDate).format('DD-MM-YYYY H:m');
  }
  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./bunk-overlay.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "@angular/cdk/portal";
import * as i3 from "./template-preview-overlay.component";
import * as i4 from "./file-preview-overlay-ref";
import * as i5 from "./file-preview-overlay.tokens";
var styles_TemplatePreviewOverlayComponent = [i0.styles];
var RenderType_TemplatePreviewOverlayComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_TemplatePreviewOverlayComponent, data: { "animation": [{ type: 7, name: "fade", definitions: [{ type: 0, name: "fadeOut", styles: { type: 6, styles: { opacity: 0 }, offset: null }, options: undefined }, { type: 0, name: "fadeIn", styles: { type: 6, styles: { opacity: 1 }, offset: null }, options: undefined }, { type: 1, expr: "* => fadeIn", animation: { type: 4, styles: null, timings: "400ms cubic-bezier(0.25, 0.8, 0.25, 1)" }, options: null }], options: {} }, { type: 7, name: "slideContent", definitions: [{ type: 0, name: "void", styles: { type: 6, styles: { transform: "translate3d(0, 25%, 0)", opacity: 0 }, offset: null }, options: undefined }, { type: 0, name: "enter", styles: { type: 6, styles: { transform: "none", opacity: 1 }, offset: null }, options: undefined }, { type: 0, name: "leave", styles: { type: 6, styles: { transform: "translate3d(0, 25%, 0)", opacity: 0 }, offset: null }, options: undefined }, { type: 1, expr: "* => *", animation: { type: 4, styles: null, timings: "400ms cubic-bezier(0.25, 0.8, 0.25, 1)" }, options: null }], options: {} }] } });
export { RenderType_TemplatePreviewOverlayComponent as RenderType_TemplatePreviewOverlayComponent };
function View_TemplatePreviewOverlayComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵand(0, null, null, 0))], null, null); }
export function View_TemplatePreviewOverlayComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "div", [["class", "overlay-content"]], [[24, "@slideContent", 0]], [[null, "@slideContent.start"], [null, "@slideContent.done"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("@slideContent.start" === en)) {
        var pd_0 = (_co.onAnimationStart($event) !== false);
        ad = (pd_0 && ad);
    } if (("@slideContent.done" === en)) {
        var pd_1 = (_co.onAnimationDone($event) !== false);
        ad = (pd_1 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_TemplatePreviewOverlayComponent_1)), i1.ɵdid(2, 212992, null, 0, i2.CdkPortalOutlet, [i1.ComponentFactoryResolver, i1.ViewContainerRef], { portal: [0, "portal"] }, null)], function (_ck, _v) { var _co = _v.component; var currVal_1 = _co.templatePortal; _ck(_v, 2, 0, currVal_1); }, function (_ck, _v) { var _co = _v.component; var currVal_0 = _co.animationState; _ck(_v, 0, 0, currVal_0); }); }
export function View_TemplatePreviewOverlayComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 16777216, null, null, 1, "template-preview-overlay", [], null, [["document", "keydown"]], function (_v, en, $event) { var ad = true; if (("document:keydown" === en)) {
        var pd_0 = (i1.ɵnov(_v, 1).handleKeydown($event) !== false);
        ad = (pd_0 && ad);
    } return ad; }, View_TemplatePreviewOverlayComponent_0, RenderType_TemplatePreviewOverlayComponent)), i1.ɵdid(1, 114688, null, 0, i3.TemplatePreviewOverlayComponent, [i1.ViewContainerRef, i4.FilePreviewOverlayRef, i5.FILE_PREVIEW_TEMPLATE], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var TemplatePreviewOverlayComponentNgFactory = i1.ɵccf("template-preview-overlay", i3.TemplatePreviewOverlayComponent, View_TemplatePreviewOverlayComponent_Host_0, {}, {}, []);
export { TemplatePreviewOverlayComponentNgFactory as TemplatePreviewOverlayComponentNgFactory };

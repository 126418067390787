import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'calendar-skeleton',
  template:  `
  <div class=" calendar__container">
  <div class="calendar__header loading-animation--header"></div>
  <div class="calendar__body">
    <div class="calendar__cell loading-animation--header"></div>
    <div class="calendar__cell loading-animation--header"></div>
    <div class="calendar__cell loading-animation--header"></div>
    <div class="calendar__cell loading-animation--header"></div>
    <div class="calendar__cell loading-animation--header"></div>
    <div class="calendar__cell loading-animation--header"></div>
    <div class="calendar__cell loading-animation--header"></div>
    <div class="calendar__cell loading-animation--header"></div>
    <div class="calendar__cell loading-animation--header"></div>
    <div class="calendar__cell loading-animation--header"></div>
    <div class="calendar__cell loading-animation--header"></div>
    <div class="calendar__cell loading-animation--header"></div>
    <div class="calendar__cell loading-animation--header"></div>
    <div class="calendar__cell loading-animation--header"></div>
    <div class="calendar__cell loading-animation--header"></div>
    <div class="calendar__cell loading-animation--header"></div>
    <div class="calendar__cell loading-animation--header"></div>
    <div class="calendar__cell loading-animation--header"></div>
    <div class="calendar__cell loading-animation--header"></div>
    <div class="calendar__cell loading-animation--header"></div>
    <div class="calendar__cell loading-animation--header"></div>
    <div class="calendar__cell loading-animation--header"></div>
    <div class="calendar__cell loading-animation--header"></div>
    <div class="calendar__cell loading-animation--header"></div>
    <div class="calendar__cell loading-animation--header"></div>
    <div class="calendar__cell loading-animation--header"></div>
    <div class="calendar__cell loading-animation--header"></div>
    <div class="calendar__cell loading-animation--header"></div>
    <div class="calendar__cell loading-animation--header"></div>
    <div class="calendar__cell loading-animation--header"></div>
    <div class="calendar__cell loading-animation--header"></div>
    <div class="calendar__cell loading-animation--header"></div>
    <div class="calendar__cell loading-animation--header"></div>
    <div class="calendar__cell loading-animation--header"></div>
    <div class="calendar__cell loading-animation--header"></div>
  </div>
  </div>
  `,
  styleUrls: ['../skeleton-loading.component.scss']
})
export class CalendarSkeletonComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

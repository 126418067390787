import { Component, OnInit, Input } from '@angular/core';
import * as moment from 'moment';
import { PropertyModel } from '@app/core-landlord/property/_models/property.model';
import { PropertyService } from '@app/services';
import { finalize, map, switchMap, take } from 'rxjs/operators';
import { DatesService } from '@app/shared/_services/dates.service';
import { PROPERTY_DOCS } from '@app/_constants/property_docs.constants';
import { MediaService } from '@app/services/media.service';
import { DOCUMENT_TYPES } from '@app/shared/_forms/form-upload-documents/form-upload-documents.component';
import { of } from 'rxjs';


@Component({
  selector: 'document-summary-list',
  template: `
  <screen-detection (resized)="isDesktop = $event.isDesktop"></screen-detection>
  <div class="widget__card">
    <table class="widget__summary-table">
        <tr>
          <th>{{( isTenant ? 'Tenancy Documents' : 'Documents')}}</th>
          <th *ngIf="!isTenant">Expires</th>
          <th *ngIf="isDesktop && !isTenant">Status</th>
        </tr>
        <tr *ngFor="let doc of this.display; let idx = index" (click)="openDocument(doc)" [ngClass]="{'cursor-pointer':  doc.url}">
          <ng-container *ngIf="isTenant && doc.name && doc.url || !isTenant && doc.name">
          <td>

              <div class="flex flex-column align-center">
                <span> {{doc.name}} </span>
              </div>
          </td>
          <td  class="margin-bottom--sm" *ngIf="!isTenant">
              <div class="flex flex-row align-center justify-between">
                <div class="flex flex-column">
                    <span>{{doc.expiry}}</span>
                    <div class="items-center hide-lg flex flex-row">
                      <span class="icon__warning icon__warning--sm" *ngIf="doc.isExpired === 'Expired'"></span>
                      <small class="flex items-center">{{doc.isExpired}}</small>
                    </div>
                </div>
                <div class="hide-lg" *ngIf="!property.is_editing">
                    <ng-container *ngTemplateOutlet="optionsMenu; context: { doc: doc, idx: idx }"></ng-container>
                </div>
              </div>
          </td>
          <td class="margin-bottom--sm hide-sm hide-md">
              <div [ngClass]="{'flex':true, 'justify-content-end':isTenant, 'justify-between':!isTenant}">
                <div class="flex flex-row items-center" *ngIf="!isTenant" >
                    <span class="icon__warning icon__warning--sm" *ngIf="doc.isExpired === 'Expired'"></span>
                    <span class="status flex items-center">{{doc.isExpired}}</span>
                </div>
                <span *ngIf="doc.url" (click)="openDocument(doc)" class="view-circle">
                <mat-icon class="view-icon" svgIcon="search-icon"></mat-icon>
                </span>
                <div *ngIf="!property.is_editing">
                  <ng-container *ngTemplateOutlet="optionsMenu; context: { doc: doc, idx: idx }"></ng-container>
                </div>
              </div>
          </td>
          </ng-container>
        </tr>
    </table>
    <bunk-overlay [template]="uploadDocs" (overlayRef)="overRefDocs = $event" *ngIf="!isTenant">
    <div class="flex flex-row button-border" *ngIf="!property.is_editing">
        <button class="btn__seethrough btn__seethrough--primary" type="button">Add document</button>
    </div>
    <ng-template #uploadDocs>
        <div class="bunk-overlay__external-content bunk-overlay__external-content--scroll">
          <header class="bunk-overlay__close-bar">
              <button class="bunk-overlay__close-btn" (click)="overRefDocs.close()">
                Close
                <mat-icon svgIcon="icon-close"></mat-icon>
              </button>
          </header>
          <div class="main__wrapper main__wrapper--narrow margin-bottom--lg">
              <upload-documents [property]="property" [bedroomId]="bedroomId" (closeOverly)="overRefDocs.close()"></upload-documents>
          </div>
        </div>
    </ng-template>

    </bunk-overlay>
  </div>

  <ng-template #optionsMenu let-doc="doc" let-idx="idx" *ngIf="!isTenant">
    <button [matMenuTriggerFor]="menu" class="btn__ellipsis"  *ngIf="doc.url">
    <span class="vertical__ellipsis"></span>
    </button>
    <mat-menu #menu="matMenu" >
        <button class="hide-lg" *ngIf="doc.url" (click)="openDocument(doc.url)" mat-menu-item>View document</button>
        <bunk-overlay [template]="editDocs" (overlayRef)="overRefDocs = $event">
        <button mat-menu-item>Edit document</button>
        </bunk-overlay>
        <bunk-dialog [component]="deleteDoc" (dialogRef)="dialogRef = $event;" >
        <button mat-menu-item>Delete document</button>
        </bunk-dialog>
      <ng-template #editDocs>
        <div class="bunk-overlay__external-content bunk-overlay__external-content--scroll">
            <header class="bunk-overlay__close-bar">
              <button class="bunk-overlay__close-btn" (click)="overRefDocs.close()">
                  Close
                  <mat-icon svgIcon="icon-close"></mat-icon>
              </button>
            </header>
            <div class="main__wrapper main__wrapper--narrow">
              <upload-documents [property]="property" [bedroomId]="bedroomId" (closeOverly)="overRefDocs.close()" [index]="idx"></upload-documents>
            </div>
        </div>
      </ng-template>

      <ng-template #deleteDoc>
        <h3 class="margin-bottom">Delete document</h3>
        <span> Are you sure you want to delete your {{doc.name}} document from your property documents? Once this has been done there is no way to retrieve this.</span>
        <div class="action-bar margin-top">
            <button type="button" (click)="dialogRef.close()" class="btn__seethrough">Cancel</button>
            <button type="button" (click)="deleteDocument(doc.name, doc.media_id)" class="btn__warning">
            <span *ngIf="!submittingData">Delete</span>
            <processing-spinner *ngIf="submittingData" color="#FFFFFF" ></processing-spinner>
            </button>
        </div>
      </ng-template>
    </mat-menu>
  </ng-template>

`,
  styleUrls: ['./document-summary-list.component.scss']
})
export class DocumentSummaryListComponent implements OnInit {
  @Input() isTenant = false;
  @Input()
  get propertyData(): PropertyModel {
    return this.property;
  }
  set propertyData(property: PropertyModel) {
    if (property) {
      this.property = property;
      if (!this.bedroomId) {
        this.mediaIds = property.media_ids || [];
        this.updateDocuments();
      }
    }
  }

  @Input()
  get bedroomData(): PropertyModel {
    return this.bedroomId;
  }
  set bedroomData(bedroomInfo: PropertyModel) {
    const id = bedroomInfo && bedroomInfo.bedroom_id
    if (id) {
      this.mediaIds = this.propertyData.bedrooms[id].media_ids || [];
      this.bedroomId = id;
      this.updateDocuments();
    }
  }
  documentTypes = DOCUMENT_TYPES.map(doc => ({ value: doc.value, viewValue: doc.text }))
  defaultDocs = DOCUMENT_TYPES.map(doc => doc.value);
  public property: PropertyModel;
  public bedroomId; string;
  public isDesktop: boolean;
  public display = [];
  public overRefDocs;
  public dialogRef;
  public submittingDataSuccess = false;
  public submittingData = false;
  mediaIds = []
  constructor(
    private _property: PropertyService,
    public _date: DatesService,
    private _media: MediaService
  ) { }

  ngOnInit() { }

  getMomentDate(date: any) {
    return moment(date.toDate()).format('DD MMM YYYY');
  }

  checkIfDocExpired(date: any) {
    const expiredDate = this._date.getTimeZone();
    const docDate = date ? date.toDate() : null;

    switch (true) {
      case expiredDate.isAfter(docDate):
        return 'Expired';
      case expiredDate.isBefore(docDate):
        return 'Up-to-date';
      default:
        return 'Incomplete';
    }
  }

  getBedroomDocs(bedroomDocs: any, propertyDocs: any) {
    if (bedroomDocs) {
      bedroomDocs = bedroomDocs.map(bedroomDoc => {
        if (bedroomDoc.image_url) {
          return bedroomDoc;
        }
        const propertyDoc = propertyDocs.length ? propertyDocs.find(doc => doc.name === bedroomDoc.name) : null;
        bedroomDoc.image_url = propertyDoc && propertyDoc.image_url ? propertyDoc.image_url : bedroomDoc.image_url;
        return bedroomDoc;
      });
      const bedroomDocNames = bedroomDocs.map(obj => obj.name);
      const extraDocs = propertyDocs.length ? propertyDocs.filter(doc => !bedroomDocNames.includes(doc.name) && doc.image_url) : null;
      return [...bedroomDocs, ...extraDocs];
    }

  }

  updateDocuments() {
    this.display = [];
    if (this.mediaIds.length) {
      this._media.getMediaFromIds(this.mediaIds).pipe(map(medias => {
        return medias.filter(media => media.type === 'property_documents');
      }), map(medias => {

        const defaults = this.documentTypes.map(type => {
          const document = medias.find(media => media.view_name === type.value);
          if (!document) {
            return { name: type.viewValue, url: null }
          }
          return document;
        })
        const others = medias.filter(media => {
          return !this.defaultDocs.includes(media.view_name)
        })
        return [...defaults, ...others]
      }), take(1)).subscribe(
        medias => {
          this.setPropertyDocs(medias);
        }
      )
    }
    else {
      const propDocs = this.bedroomData ? this.getBedroomDocs(this.propertyData.bedrooms[this.bedroomId].property_documents, this.propertyData.property_documents ? this.propertyData.property_documents : PROPERTY_DOCS) : this.propertyData.property_documents;
      this.setPropertyDocs(propDocs);
    }

  }

  setPropertyDocs(propDocs) {
    if (propDocs) {
      this.display = [];
      propDocs.map((doc: any) => {
        const name = this.getDocumentName(doc.view_name || doc.name);
        const dateOfExpiry = doc.expiry_date || doc.date_expires;
        const docUrl = doc.image_url || doc.url;
        const expiry = dateOfExpiry ? this.getMomentDate(dateOfExpiry) : '-';
        const isExpired = dateOfExpiry ? this.checkIfDocExpired(dateOfExpiry) : docUrl && name ? 'No expiry' : 'Incomplete';
        const url = docUrl ? docUrl : null;

        const item: any = {
          name,
          expiry,
          isExpired,
          url,
          media_id: doc.media_id || null
        };
        return this.display.push(item);
      });

      this.display.sort();
    }
  }
  deleteDocument(name = '', media_id = '') {
    this.submittingData = true;
    let propsDoc = this.bedroomId ? this.property.bedrooms[this.bedroomId].property_documents : this.property.property_documents;
    let mediaIds = this.bedroomId ? (this.property.bedrooms[this.bedroomId].media_ids || []) : (this.property.media_ids || []);
    const docName = this.getDocumentName(name, true);
    const currentDoc = propsDoc.find(doc => doc.name === docName);
    const isDefaultDoc = this.defaultDocs.includes(currentDoc.name);
    if (isDefaultDoc) {
      currentDoc.expiry_date = null;
      currentDoc.image_url = null;
    }
    else {
      propsDoc = propsDoc.filter(doc => doc.name !== docName);
    }
    if (media_id) {
      mediaIds = mediaIds.filter(mediaId => mediaId !== media_id)
    }
    let document = {}
    if (this.bedroomId) {
      document = {
        date_modified: new Date(),
        bedrooms: {
          [this.bedroomId]: {
            property_documents: propsDoc,
            media_ids: mediaIds
          }
        }
      }
    }
    else {
      document = {
        date_modified: new Date(),
        property_documents: propsDoc,
        media_ids: mediaIds
      }
    }
    return this._property.updatePropertyDoc(this.property.property_id, this.bedroomId, document, this.property.is_editing).pipe(
      switchMap(() => media_id ? this._media.removeMediaById(media_id) : of(null))
      , finalize(() => {
        this.dialogRef.close();
        this.submittingData = false;
      })
    )
      .subscribe(() => console.log('Property updated'));
  }

  openDocument(doc: any) {
    if (doc.url) {
      window.open(doc.url, '_blank');

    }
  }

  getDocumentName(docName: string, reverse = false) {
    // if reverse is true
    // we give value when viewValue is given as docName
    if (!reverse) {
      switch (docName) {
        case 'GAS':
          return 'Gas Safety';
        case 'EICR':
          return 'EICR';
        default:
          return docName;
      }
    }
    else {
      switch (docName) {
        case 'Gas Safety':
          return 'GAS';
        case 'EICR':
          return 'EICR';
        default:
          return docName;
      }
    }
  }



}


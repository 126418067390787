import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { StorageService, THUMB_DIMENSIONS } from '@app/services';
import { Observable } from 'rxjs/internal/Observable';
import { AngularFireStorageReference } from '@angular/fire/storage';

import { of, combineLatest, iif, throwError, from } from 'rxjs';
import { concatMap, delay, flatMap, last, map, mergeMap, retryWhen, take, tap } from 'rxjs/operators';
// import * as jo from 'jpeg-autorotate';
import { getBase64Strings } from 'exif-rotate-js/lib';
import { MediaService } from '@app/services/media.service';
import { IMedia } from '@rentbunk/bunk-models';
/***
 * Upload Multiple Files
 * @important: Output each url as a string, once completed, you need to wait all the files to be uploaded before
 *
 *
 *   onUploadedFile = (task: BunkUploaderModel): string[] => { // do something };
 *

 @example:

 <button (click)="bunkUploader.click()">Click</button>

 <bunk-uploader #bunkUploader
 (uploadChange)="onUploadedFile($event)"
 filePath="my-awesome/path/folder"
 hidden>
 </bunk-uploader>

 */

export interface BunkUploaderModel {
  file?: File;
  image?: ImageUrlsModel;
  uploadsTotal?: number;
  media_id?: string;
}

export interface ImageUrlsModel {
  image_small_url: string;
  image_large_url: string;
  image_original_url: string;
  image_full_path: string;
}
export interface mediaDataModel {
  date_expires?: Date,
  epc_rating?: string,
  view_name?: string,
  type?: string,
  description?: string,
  property_ids?: Array<string>,
  tenancy_ids: Array<string>,
  unit_ids: Array<string>,
  tenants_ids: Array<string>
}

@Component({
  selector: 'bunk-uploader',
  template: `
    <input type="file" [multiple]="multiple" [accept]="accept" (change)="uploadFiles($event)" #inputRef>
  `
})

export class BunkUploaderComponent {
  @Input() filePath: string;  // path where to store the file (eg. foo/path/123.jpg -> filePath is "foo/path")
  @Input() multiple = true;   // Sets or retrieves the Boolean value indicating whether multiple items can be selected from a list
  @Input() accept: string;
  @Output() uploadStart: EventEmitter<BunkUploaderModel> = new EventEmitter<BunkUploaderModel>();
  @Output() uploadChange: EventEmitter<BunkUploaderModel> = new EventEmitter<BunkUploaderModel>();
  @Input() mediaData: Partial<IMedia>;
  // allPercentage$: Observable<number>;   // task get percentages sum of all uploaded files

  set uploadedFile({ file, image }: BunkUploaderModel) {
    const data = { file, image, extra: this.mediaData }
    this._media.addMedia(data).pipe(take(1)).subscribe(res => {
      this.uploadChange.next({ file, image, media_id: res });
    })
  }

  @ViewChild('inputRef', { static: false }) fileInputRef: ElementRef<HTMLInputElement>;
  click = (): void => this.fileInputRef.nativeElement.click();

  constructor(private _storage: StorageService, private _media: MediaService) { }

  uploadFiles(event: any) {
    const files = event.target.files || event.dataTransfer.files;

    for (const file of files) {
      const isImage = file.type.includes('image');
      // (isImage)
      //   ? this.rotateImage(file).pipe(tap((base64Strings: any) => {
      //     const rotatedFile = this.base64ToFile(base64Strings[0], file.name);
      //     this.uploadFileToStorage(rotatedFile, files);
      //   })).subscribe()
      //   :
      this.uploadFileToStorage(file, files);
    }
  }

  rotateImage(file: File): Observable<any> {
    return of(getBase64Strings([file])).pipe(concatMap((res) => from(res)));
  }

  base64ToFile(base64: any, fileName: string): File {
    const arr = base64.split(',');
    const bstr = atob(arr[1]);

    let n = bstr.length;
    const u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], fileName, { type: 'image/jpeg' });
  }

  uploadFileToStorage(file: File, files: any) {
    const regex = new RegExp(/[^\/]$/);   // string that do not end with a slash character
    const path = regex.test(this.filePath) ? this.filePath + '/' : this.filePath;

    const fullPath = `${path}${new Date().getTime()}-${file.name}`;   // generate filePath

    const task = this._storage.uploadTask(file, fullPath);

    // obsPercentage.push(task.percentageChanges());

    const ref = this._storage.storageReference(fullPath);

    const thumbs: { size: string, full_path: string }[] = THUMB_DIMENSIONS.map(THUMB => ({
      size: THUMB.value,
      full_path: this._storage.getThumbnailFullPath(fullPath, THUMB.value)
    }));
    const thumbsStorage: { size: string, full_path: string, ref: AngularFireStorageReference }[] = thumbs.map(thumb => ({
      ...thumb,
      ref: this._storage.storageReference(thumb.full_path)
    }));

    const thumbDownloadUrls: Observable<{ url: any, size: any }>[] = (file.type !== 'application/pdf') ? thumbsStorage.map(thumb => {
      const thumbRef = thumb.ref; // get ref of thumb
      this.uploadStart.next({ uploadsTotal: files.length });

      const retryPipeline = retryWhen(errors => errors.pipe(
        concatMap((e, i) => {
          return iif(
            () => i > 15,
            throwError(e),
            of(e).pipe(delay(1500), tap(() => console.log(`Retry to fetch thumb ${thumb.size}...`)))
          );
        })
      ));

      const retryDownloadURL = of(true).pipe(
        flatMap(() => thumbRef.getDownloadURL()),
        retryPipeline
      );

      return retryDownloadURL.pipe(map(url => ({ url, size: thumb.size })));
    }) : [of({ url: '', size: '' })];

    const originalUrl = task.snapshotChanges().pipe(last(), mergeMap(() => ref.getDownloadURL()));
    console.log({ thumbDownloadUrls });
    combineLatest(originalUrl, ...thumbDownloadUrls)
      .subscribe(
        ([image_original_url, ...thumbsUrls]) => {
          console.log('Thumb urls: ', thumbsUrls);
          const image_small_url = (file.type !== 'application/pdf') ? thumbsUrls.find(o => o.size === 'small').url : null;
          const image_large_url = (file.type !== 'application/pdf') ? thumbsUrls.find(o => o.size === 'large').url : null;
          this.uploadedFile = {
            file: file,
            image: {
              image_full_path: fullPath,
              image_small_url: image_small_url ? image_small_url : null,
              image_large_url: image_large_url ? image_large_url : null,
              image_original_url
            }
          };
        },
        err => {
          this.uploadedFile = {
            file: null,
            image: null
          };
          console.log('Error THUMB is: ', err);
        }
      );
  }
}

<!-- for buttons -->
<div class="flex flex-row justify-between margin-bottom shared-filter__header">
  <h2>{{title}}</h2>
  <ng-content select="[actionButton]"></ng-content>
</div>

<nav class="secondary__nav" *ngIf="showStatusNav && otherFilters['status'] && otherFilters['status'].length">
  <span *ngFor="let item of otherFilters['status']" class="secondary__nav-title"
    [ngClass]="{'active': item.viewValue && getViewValue(otherFilters['status'],filterFormGroup.get('status').value) === item.viewValue }"
    (click)="displayFilterChoices(filterFormGroup.get('status'), item.value)">{{item.viewValue}}</span>
</nav>

<div class="filter__container">
  <div class="filter__container--inner w_100">
    <div class="search-bar--payments" *ngIf="showSearch">
      <instant-search-input class="search-bar__keyword" [formControl]="searchStr" [indexName]="indexName"
        placeholder={{searchPlaceholder}}>
      </instant-search-input>
    </div>

    <div class="search-bar--payments" *ngIf="instantSearchFilerValues">
      <instant-algolia-search class="search-bar__keyword" [indexName]="'properties'" [message]="'Filter by property'"
        [hideResults]="false" [storedIds]="storedIds" (storedIdsChange)="onStoredIdsChange($event)"
        [showImmediately]="true" [availableHits]="instantSearchFilerValues">
      </instant-algolia-search>
    </div>
    <div class="search-filters margin-bottom" (clickOutside)="$event.value && clickedOutside($event)"
      [listen]="showFilters">

      <ng-container *ngIf="otherFilters">
        <ng-container *ngFor="let filterType of filterTypes">
          <mat-select *ngIf="otherFilters[filterType].length"
            (click)="displayFilterChoices(filterFormGroup.get(filterType))"
            class="auto-width margin-top--xs  btn__seethrough btn--round btn--sm mr_5 shared-filter-select"
            [formControl]="filterFormGroup.get(filterType)">
            <mat-option class="shared-filter-options filter__type-container--content cursor-pointer"
              *ngFor="let filter of otherFilters[filterType]" [value]="filter.value"
              (click)="updateFormValue(filter.value)">
              <div class="flex justify-between items-center">
                <span> {{filter.viewValue}}</span>
                <span class="selected" *ngIf="selectedFormControl.value === filter.value"></span>
              </div>
            </mat-option>
          </mat-select>
        </ng-container>
      </ng-container>
      <!-- Add any unique filtering items -->

      <ng-content></ng-content>

    </div>

    <strong class="margin-bottom" *ngIf="numberOfHits !== undefined">{{numberOfHits}} Results</strong>
  </div>
</div>

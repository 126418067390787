import { Component, OnInit, OnDestroy, Input, TemplateRef, ChangeDetectionStrategy } from '@angular/core';
import { animate, animation, style } from '@angular/animations';
import { trigger, transition, useAnimation } from '@angular/animations';

export const moveFadeIn = animation(
  [
    style({ opacity: '0', transform: 'translateY({{slide}})' }),
    animate('{{time}}', style({ opacity: '1', transform: '*' }))
  ],
  { params: { time: '200ms', slide: '5px' } }
);

export const moveFadeOut = animation(
  [
    style({ opacity: '*' }),
    animate('{{time}}', style({ opacity: '0', transform: 'translateY({{slide}})' }))
  ],
  { params: { time: '200ms', slide: '5px' } }
);

@Component({
  selector: 'app-popover',
  templateUrl: './popover.component.html',
  styleUrls: ['./popover.component.scss'],
  animations: [
    trigger('popover', [
      transition(':enter', useAnimation(moveFadeIn, { params: { time: '150ms', slide: '5px' }})),
      transition(':leave', useAnimation(moveFadeOut))
    ])
  ]
})
export class PopoverComponent implements OnInit, OnDestroy {

  @Input() content: TemplateRef<any>;
  @Input() callback: Function;
  @Input() disabledClickOutside: boolean;

  // TODO: use native cdk click outside
  public clickOutsideEnabled = false;
  private _timer: any;

  constructor() { }

  ngOnInit() {
    if (!this.disabledClickOutside) {
      this._timer = setTimeout(() => {
        this.clickOutsideEnabled = true;
      }, 0);
    }
  }

  ngOnDestroy() {
    if (this._timer) {
      clearTimeout(this._timer);
    }
  }

  public close(): void {
    this.callback();
  }

  public onOutsideClick(): void {
    this.callback();
  }

}

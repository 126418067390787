
     <div class="avatar" *ngIf="!showPhoneVerification" [hidden]="successScreen">
  <span class="avatar__title">Hi, {{_user.userDb?.profile_data?.full_name}}</span>

  <img *ngIf="!hasProfilePic" src="/assets/img/new-icons/add-avatar.png" alt="Add image">

  <profile-avatar class="margin-top margin-bottom" *ngIf="hasProfilePic" size="lg" justified="center"
    [profileImgUrl]="_user.userDb?.profile_data.profile_image_url" [profileName]="_user.userDb?.profile_data.full_name">
  </profile-avatar>
  <h2>Let's add a photo and verify your account</h2>

  <span class="margin-bottom">It’s nice to put a face to a name, a photo might help your profile stand out amongst others.</span>

  <form [formGroup]="profileImgformGroup">

    <div class="form_input text-center">

      <label for="profile-picture-upload" formGroupName="profile_image_url">

        <processing-spinner *ngIf="showSpinner" color="#FFFFFF" size="40px"></processing-spinner>

        <button role="button"
          class="btn__generic btn--lg margin-top" (click)="profileUploader.click()" [hidden]="showSpinner">
          <processing-spinner *ngIf="showSpinner" color="#FFFFFF"></processing-spinner>
          <span *ngIf="!showSpinner">{{(hasProfilePic ? 'Update photo' : 'Add photo')}}</span>
        </button>
      </label>

      <bunk-uploader #profileUploader [filePath]="filePath" (uploadChange)="onUploadedFile($event)"
        (uploadStart)="onUpStart()" [multiple]="false" hidden>
      </bunk-uploader>
    </div>
      <div class="flex justify-center">
        <button class="btn__text-generic"
          aria-label="Profile image skip upload" (click)="setDefaultProfilePicture()" [hidden]="showSpinner">
          {{( hasProfilePic ? 'Continue' : 'Skip')}}</button>
      </div>
  </form>

  <div *ngIf="profile_image" class="continue text-center" routerLink="/">continue</div>
  <div *ngIf="profile_image" class="back text-center" (click)="profile_image = !profile_image">change image
  </div>
</div>

<!-- <ng-container *ngIf="showPhoneVerification && !successScreen">
  <profile-avatar size="lg" justified="center" [profileImgUrl]="_user.userDb?.profile_data.profile_image_url"
    [profileName]="_user.userDb?.profile_data.full_name"></profile-avatar>
  <div class="flex flex-column items-center">
  <ng-container *ngIf="!resendCode">
    <h2 class="margin-top">Verify your account</h2>
    <span class="text-center">We've sent a 4-digit code to {{last4digits}}</span>
  </ng-container>


  <form [formGroup]="phoneFormGroup" class="phone__verification-container" [hidden]="successScreen">
    <div class="flex justify-between" *ngIf="!resendCode">
      <mat-form-field appearance="outline">
        <input matInput type="text" pattern="[0-9]*" [formControlName]="'verification_code'" (input)="verifyPhone()"  class="text-center" id="code">
      </mat-form-field>
    </div>

    <div class="phone__verification-container" *ngIf="resendCode">

      <div class="flex justify-start w_100">
        <span class="grey__circle" type="button" (click)="resendCode = false">
          <mat-icon svgIcon="chevron-left"></mat-icon>
        </span>
      </div>
      <div>
        <mat-form-field appearance="outline" class="country_code">
          <mat-label>Code</mat-label>
          <mat-select [formControlName]="'country_code'">
            <mat-option *ngFor="let code of countryCodes" [value]="code">
              +{{code}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline" class="number">
          <mat-label>Phone number</mat-label>
          <input matInput type="tel" [formControlName]="'phone_number'" id="phoneNumber">
          <mat-error>Please enter your phone number</mat-error>
        </mat-form-field>
      </div>
    </div>

  </form>



  <button *ngIf="!resendCode" class="btn__generic btn--lg margin-top--md" type="button" (click)="verifyPhone()">
    <processing-spinner *ngIf="showPhoneSpinner" color="#FFFFFF"></processing-spinner>

    <span *ngIf="!showPhoneSpinner">Verify</span>
  </button>

  <button *ngIf="resendCode" class="btn__generic btn--lg margin-top--md" (click)="requestPhoneVerification(true)">
    <processing-spinner *ngIf="showPhoneSpinner" color="#FFFFFF"></processing-spinner>

    <span *ngIf="!showPhoneSpinner">Resend code</span>
  </button>


  <small *ngIf="!resendCode" class="flex flex-row align-center margin-top items-center"> Didn't receive a code?
    <button class="btn__text-generic ml_3" type="button" (click)="showPhoneNumber()">Resend code</button></small>

  </div>
  </ng-container> -->



<ng-container *ngIf="successScreen">
  <div class="flex flex-column justify-center items-center w_100">

    <div class="tick-container">
      <!-- <span class="status__tick status__tick--circled status__tick--circled--large"></span> -->
      <svg version="1.1" [ngClass]="{'drawn' : showTick}" id="tick" xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 37 37"
        style="enable-background:new 0 0 37 37;" xml:space="preserve">
        <path class="circ path"
          style="fill:none;stroke:#119AE4;stroke-width:2;stroke-linejoin:round;stroke-miterlimit:10;"
          d="
M30.5,6.5L30.5,6.5c6.6,6.6,6.6,17.4,0,24l0,0c-6.6,6.6-17.4,6.6-24,0l0,0c-6.6-6.6-6.6-17.4,0-24l0,0C13.1-0.2,23.9-0.2,30.5,6.5z" />
        <polyline class="tick path"
          style="fill:none;stroke:#119AE4;stroke-width:2;stroke-linejoin:round;stroke-miterlimit:10;" points="
11.7,20.1 11.6,20 15.9,24.2 26.4,13.8 26.3,13.9" />
      </svg>
    </div>


    <h1 class="margin-top">You're all set!</h1>
    <span> Your account is now verified</span>
    <button type="button" class="btn__generic btn-lg margin-top--lg" (click)="dialog.closeAll()">Great!</button>
  </div>
</ng-container>

<div class="ellipsis__stepper" [hidden]="successScreen">
  <span class="step" [ngClass]="{'step--selected' : !showPhoneVerification}"
    (click)="showPhoneVerification = false"></span>
  <span class="step" [ngClass]="{'step--selected' : showPhoneVerification}"
    (click)="hasProfilePic ? showPhoneVerification = true : showPhoneVerification = false;  focusInput(); "></span>
</div>

  
import {Component, OnInit, Input, Output, EventEmitter, OnDestroy} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {map, startWith, tap, takeUntil, debounceTime} from 'rxjs/operators';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {Observable, combineLatest, BehaviorSubject, Subject} from 'rxjs';
import {isArray} from 'rxjs/internal-compatibility';
import * as _ from 'lodash';
interface IPagination {page:number,hits:number}
interface IFilterObject {value:string,viewValue:string,additional?:string[]};
interface ICombinedFilter {
  portfolio?: {portfolio_id?: string, property_ids?: string[]} | string;
  search: string;
  instantSearch: string[];
  hits: number;
  page: number;
}
type IOtherFilters = Record<string,IFilterObject[]>;
@Component({
  selector: 'shared-extended-filters',
  templateUrl: './shared-extended-filters.component.html',
  styleUrls: ['./shared-extended-filters.component.scss']
})
export class SharedExtendedFiltersComponent implements OnInit, OnDestroy {
  //legacy variables
   searchStr$: Observable<string>;
   instantSearchFilter$: Observable<string[]>;
   pagination$: BehaviorSubject<IPagination> = new BehaviorSubject<IPagination>({page: null, hits: null});
   showChoices = false;
   showFilters = false;
   selectedFormControl: FormControl = new FormControl(null);
   searchStr: FormControl;
   instantSearchFilter: FormControl;
  // instant search
   storedIds: string[] = [];
  private state = new BehaviorSubject([]);

  //extended variables
   filterFormGroup: FormGroup;
   private destroy$: Subject<boolean> = new Subject<boolean>();
   filterTypes = [];
   filterViewValues = {};
   filterObservables = new Observable()

  //legacy inputs
  @Input() title;
  @Input() indexName;
  @Input() statusFilterValues;
  @Input() typeFilterValues;
  @Input() portfolioFilterValues;
  @Input() sortFilterValues;
  @Input() assigneeFilterValues;
  @Input() instantSearchFilerValues;
  @Input() showStatusNav = false;
  @Input() showSearch = true;
  @Input() searchPlaceholder = "Search";
  @Input()
  get getPaginationParams() {
    return this.pagination$.value;
  }
  set getPaginationParams(pagination: IPagination) {
    this.pagination$.next({page: pagination.page, hits: pagination.hits});
  }

  //extended inputs
  @Input() otherFilters:IOtherFilters;
  @Input() numberOfHits:number;

  //legacy output
  @Output() filterEmitter: EventEmitter<ICombinedFilter> = new EventEmitter<ICombinedFilter>();

  constructor(private _route: ActivatedRoute,
              private _router: Router,
              private _formBuilder: FormBuilder,) {
  }

  ngOnInit() {
    //extended implementation
    if(!this.otherFilters){
      this.otherFilters = {};
    }
    const oldFilterTypes = {
      status: this.statusFilterValues,
      portfolio: this.portfolioFilterValues,
      sort: this.sortFilterValues,
      assignee: this.assigneeFilterValues,
      type: this.typeFilterValues
    }
    Object.entries(oldFilterTypes).forEach(([key, values]) =>{ if (values) this.otherFilters[key] = values })

    this.filterTypes = Object.keys(this.otherFilters);

    this.initFilterFormGroup(); //extended implementation
    this.initFilterObservables();    //legacy implementation
    // if we make change to filtervalues, based on change in other filter, from child component
    // we will update the query param from child, in that case we need to update formcontrols of sharedfilter as well

    //@todo, can find a better solution like sharing formgroup of filters with child.
    this._route.queryParamMap.pipe(
      takeUntil(this.destroy$),
      tap((params:ParamMap) => {
        const query = {}
        this.filterTypes.forEach((type: string) => {
          query[type] = params.get(type);
        })
        const emptyProfile = _.values(query).every(_.isEmpty);
        if (!emptyProfile) {
          this.updateFormValues(query)
        }
      })
    ).subscribe();
  }


//extended
  initFilterFormGroup(){
    const filterObject = {}
    //make a filterObject with active filters and their selected values
    this.filterTypes.forEach((type:string) => {
        const defaultValue = (type === 'sort') ? 'date' : 'all'
        const valueForFilterInQuery = this._route.snapshot.queryParamMap.get(type)
        if(valueForFilterInQuery){
          filterObject[type] = valueForFilterInQuery
        }
        else{
          const firstValueInGivenFilter = this.otherFilters[type] && this.otherFilters[type][0] && this.otherFilters[type][0].value;
          filterObject[type] = firstValueInGivenFilter || defaultValue;
        }

    });

    this.filterFormGroup =  this._formBuilder.group(filterObject);
    this.filterTypes.forEach((type)=>{
      this.filterObservables[type] = this.filterFormGroup.get(type).valueChanges.pipe(startWith(filterObject[type]));
    })
  }

  initFilterObservables() {
    //legacy
     const startUpSearchString = (this._route.snapshot.queryParamMap.get('search')) ? this._route.snapshot.queryParamMap.get('search') : '';
     const startUpInstantSearch = (this._route.snapshot.queryParamMap.get('instantSearch')) ? this._route.snapshot.queryParamMap.get('instantSearch') : 'all';
     this.searchStr = new FormControl(startUpSearchString);
     this.instantSearchFilter = new FormControl(startUpInstantSearch);

    this.searchStr$ = this.searchStr.valueChanges.pipe(startWith(startUpSearchString),
      map((value: string) => (this.showSearch) ? value : null));
    this.instantSearchFilter$ = this.getInstantFilter(startUpInstantSearch);

    //extended -- rather than having an observable for each filter, we make a observable array
    const filterObservableArray = this.filterTypes.map( type => this.filterObservables[type]);
    combineLatest([this.searchStr$, this.instantSearchFilter$, this.pagination$.asObservable(), ...filterObservableArray]).pipe(
      debounceTime(200),
      takeUntil(this.destroy$),
      map(([search, instantSearch, pagination, ...restOfObservables]) => {
        return this.updateQueryParams(search as string, instantSearch as string[], pagination as {hits:number,page:number}, restOfObservables as string[])

    }),
      map((filterObject:ICombinedFilter) => {
        if(this.filterTypes.includes('portfolio') && filterObject.portfolio !== 'all'){
          const selectedPortfolioData = this.otherFilters.portfolio.find((filterData:IFilterObject) => filterData.value === filterObject.portfolio);
          filterObject.portfolio = {portfolio_id: filterObject.portfolio as string, property_ids: selectedPortfolioData.additional};
        }

        this.filterEmitter.emit(filterObject);
      })).subscribe();
  }

  updateQueryParams(search:string, instantSearch:string[], pagination:{hits:number,page:number},restOfObservables:string[]){
    const queryParams = {search, instantSearch, hits: pagination.hits, page: pagination.page};
    this.filterTypes.forEach((type,index)=>{
      queryParams[type] = restOfObservables[index];

    })

    this._router.navigate(['.'], {
    queryParams: queryParams,
    relativeTo: this._route,
    queryParamsHandling: 'merge',
  })
  delete queryParams.page;
  delete queryParams.hits;
  return queryParams
  }
  //legacy
  getInstantFilter(startUpInstantFilter: string) {
    let updatedState;
    if (startUpInstantFilter && isArray(startUpInstantFilter)) {
      updatedState = [...startUpInstantFilter];
      this.storedIds = startUpInstantFilter.filter((v) => v !== 'all');
    } else if (startUpInstantFilter) {
      updatedState = [startUpInstantFilter];
      this.storedIds = startUpInstantFilter === 'all' ? [] : [startUpInstantFilter];
    } else {
      updatedState = ['all'];
      this.storedIds = [];
    }
    this.state.next(updatedState);
    return this.state.asObservable();

  }

  //legacy updated -- extended
  displayFilterChoices(formControl: FormControl,statusValue?: string) {

    this.selectedFormControl = formControl;
    if (statusValue) {
      this.updateFormValue(statusValue)
    }
  }

  //legacy
  clickedOutside(event) {
    if (!event.target.className.includes('mat-calendar') && !event.target.className.includes('btn__pseudo-link')) {
      this.showFilters = false;
    }
  }

  //extended
  updateFormValue(value: string) {
    this.selectedFormControl.setValue(value);
  }

  //legacy + type and validation
  getViewValue(filterValues: IFilterObject[], value: string) {
    if(!filterValues || !value){
      return ''
    }
    const arrayValue = filterValues.find((type: IFilterObject) => type.value === value);
    return arrayValue && arrayValue.viewValue;
  }

  //legacy
  onStoredIdsChange(value: string[]) {
    this.storedIds = value;
    const updatedState = (value.length <= 0) ? ['all'] : value;
    this.state.next(updatedState);
  }
  //extended
  updateFormValues(query: Record<string, string>) {
    const formValueOutdated = !_.isEqual(this.filterFormGroup.value, query);
    if (formValueOutdated) {
      Object.entries(this.filterFormGroup.controls).forEach(([filterKey, control]) => {
        const mismatchedControl = control.value !== query[filterKey];
        if (mismatchedControl) {
          control.patchValue(query[filterKey])
        }
      })
    }
  }
//extended
  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }

}


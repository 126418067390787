import { OnInit } from '@angular/core';
import { Validator, FormGroupDirective, FormGroup, FormControl, Validators } from '@angular/forms';
import { AbstractValueAccessor } from '@app/shared/_components/searches/instant-search/instant-search.utils';
import { FormsService } from '@app/services/form.service';
import { DURATION_MONTHS, FORM_PREVIOUS_TENANCY } from './application-forms.constants';
import { UK_DATE_FORMAT } from '@app/_constants/date.constants';
import { environment } from '@env/environment';
import { TRANSLATIONS } from '@app/_constants/translations.constants';
const ɵ0 = UK_DATE_FORMAT;
export class FormPreviousTenancyComponent extends AbstractValueAccessor {
    constructor(_reactiveForm, _forms) {
        super();
        this._reactiveForm = _reactiveForm;
        this._forms = _forms;
        this.requiredFields = FORM_PREVIOUS_TENANCY;
        this.showHasPreviousTenancy = true;
        this.tenancyDurationMonthsList = DURATION_MONTHS;
        this._submitAttempted = false;
        this.client_data = environment.client_data;
        this.landlord = TRANSLATIONS.landlord;
        this.onTouched = () => { };
    }
    set submitAttempted(boo) {
        this._submitAttempted = boo;
        if (boo) {
            this.dirtyFormGroup();
        }
    }
    get submitAttempted() {
        return this._submitAttempted;
    }
    dirtyFormGroup() {
        if (this.prevTenancyForm) {
            const controls = this.prevTenancyForm.controls;
            for (const control in controls) {
                if (controls.hasOwnProperty(control)) {
                    this.prevTenancyForm.controls[control].markAsTouched();
                }
            }
        }
    }
    getFormControl(name) {
        return this.prevTenancyForm.get(name);
    }
    ngOnInit() {
        this.prevTenancyForm = this._forms.toFormGroup(this.requiredFields);
        this.prevTenancyForm.addControl('bunk_passport_reference_id', new FormControl(''));
        this._reactiveForm.ngSubmit.subscribe((data) => {
            this.submitAttempted = true;
            const controls = this.prevTenancyForm.controls;
            for (const control in controls) {
                if (controls.hasOwnProperty(control)) {
                    this.prevTenancyForm.controls[control].markAsTouched();
                }
            }
        });
    }
    writeValue(val) {
        super.writeValue(val);
        if (val) {
            this.prevTenancyForm.patchValue(val, { emitEvent: false });
            if (val.has_previous_tenancy !== null) {
                this.setValidators(val.has_previous_tenancy);
            }
        }
    }
    setValidators(selectValue) {
        if (selectValue === false) {
            for (const field of this.requiredFields) {
                if (field !== 'has_previous_tenancy') {
                    this.prevTenancyForm.removeControl(field);
                    // this.getFormControl(field).setValidators(null);
                    this.prevTenancyForm.updateValueAndValidity();
                }
            }
        }
        else {
            for (const field of this.requiredFields) {
                this.prevTenancyForm.addControl(field, new FormControl(null, Validators.required));
                this.prevTenancyForm.get(field).updateValueAndValidity();
            }
        }
    }
    registerOnChange(fn) {
        this.prevTenancyForm.valueChanges.subscribe(fn);
    }
    registerOnTouched(fn) {
        this.onTouched = fn;
    }
    setDisabledState(isDisabled) {
        isDisabled ? this.prevTenancyForm.disable() : this.prevTenancyForm.enable();
    }
    validate() {
        return this.prevTenancyForm.valid ? null : { invalidForm: { valid: false, message: 'FormCreditInfoComponent > formGroup fields are invalid' } };
    }
    setPreviousAddress(value) {
        const address = `${value.line_1} ${value.postcode}`;
        this.prevTenancyForm.get('previous_address').setValue(address);
    }
}
export { ɵ0 };

import { ActivatedRoute } from '@angular/router';
import { AuthService } from '@app/core/services';
import {Component, OnInit, Optional, forwardRef, Input, Output, EventEmitter} from '@angular/core';
import { AbstractValueAccessor } from '@app/shared/_forms/abstract-value-accessor/abstract-value-accessor';
import { Validator, FormGroup, FormGroupDirective, FormBuilder, AbstractControl, NG_VALUE_ACCESSOR, NG_VALIDATORS, Validators, FormControl } from '@angular/forms';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {environment} from '@env/environment';
/**
 * form-create-account
 * @param submitAttempted boolean
 * @usage <form-create-account formControlName="user_account"></form-create-account>
 */
@Component({
  selector: 'form-create-account',
  templateUrl: './create-account.form.html',
  styleUrls: ['./create-account.form.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormCreateAccountComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => FormCreateAccountComponent),
      multi: true
    }
  ]
})
export class FormCreateAccountComponent extends AbstractValueAccessor implements OnInit, Validator {
  @Input() showLabel = true;
  @Input() direction = 'responsive';
  @Input() roleInput: string;
  @Input() additionalInfo = false;
  @Input() title = 'Create account';
  @Input() showPassword = true;
  @Output() submitForm: EventEmitter<boolean> = new EventEmitter<any>(false);
  public loading;
  public client_data = environment.client_data;

  @Input() get showSpinner() {
    return this.loading;
  }

  set showSpinner(data: any) {
    this.loading = data;
  }


  public submitAttempted = false;
  public formGroup: FormGroup;
  public countryCodes: any[];
  public passwordStrength$: Observable<any>;
  public referralSources$: Observable<any>;
  public smallTimeLL$: BehaviorSubject<string> = new BehaviorSubject<string>('no');


  // Query params for mid-tenants
  public uniqueCode: string;
  public roleQuery: string;
  constructor(
    @Optional() public _reactiveForm: FormGroupDirective,
    private _formBuilder: FormBuilder,
    private _auth: AuthService,
    private _activatedRoute: ActivatedRoute,
  ) {
    super();
    this.formGroup = this.initFormGroup();
    this.countryCodes = this._auth.countryCodes.sort();
    this.uniqueCode = this._activatedRoute.snapshot.queryParamMap.get('invite');
    this.roleQuery = this._activatedRoute.snapshot.queryParamMap.get('role');
    this.referralSources$ = this._auth.getReferralSources().valueChanges().pipe(
      map((sources: any) => sources.sources.sort((a: any, b: any) => a.order - b.order))
    );
  }
  get role(): FormControl {
    return this.formGroup.get('role') as FormControl;
  }
  ngOnInit() {
    this.roleQuery ? this.role.patchValue(this.roleQuery) :  this.roleInput ? this.role.patchValue(this.roleInput) : this.role.patchValue(null);
    // Force validate the form when parent form submits, loop all controls
    this._reactiveForm.ngSubmit.subscribe((data: Event) => {
      this.submitAttempted = true;
      const controls = this.formGroup.controls;
      for (const control in controls) {
        if (controls.hasOwnProperty(control)) {
          this.formGroup.controls[control].markAsTouched();
        }
      }
    });
    this.passwordStrength$ = this.formGroup.get('password').valueChanges.pipe(
      map((val: string) => {
        return {
          hasLowercase: /[a-z]/.test(val),
          hasUppercase: /[A-Z]/.test(val),
          hasNumber: /\d/.test(val),
          hasLength: val.length >= environment.minimumPasswordLength
        };
      })
    );
  }

  initFormGroup(): FormGroup {
    const formGroup: FormGroup = this._formBuilder.group({
      name: [null, { validators: Validators.required}],
      role: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      confirm_email: ['', Validators.required],
      signup_phone_number_with_area: [undefined, { validators: Validators.required}],
      password: ['', [Validators.required, Validators.pattern('^(?:(?:(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z]))|(?:(?=.*[a-z])(?=.*[A-Z])(?=.*[*.!@$%^&(){}[]:;<>,.?/~_+-=|\]))|(?:(?=.*[0-9])(?=.*[A-Z])(?=.*[*.!@$%^&(){}[]:;<>,.?/~_+-=|\]))|(?:(?=.*[0-9])(?=.*[a-z])(?=.*[*.!@$%^&(){}[]:;<>,.?/~_+-=|\]))).{8,32}$')]],
      has_accepted_terms: [true],
      terms_version: [null],
      // terms_consent_context: ['lap-guest-step-1'], @TODO
      has_accepted_privacy_policy: [true],
      privacy_policy_version: [null], // @TODO read from config file (not remote config!),
      // privacy_policy_consent_context: ['lap-guest-step-1'], @TODO
      referral_source: [null],
      number_of_properties_declared: [null],
    });
    return new FormGroup(formGroup.controls);
  }
  public onTouched: () => void = () => { };

  writeValue(val: any): void {
    super.writeValue(val);
    if (val) {
      this.formGroup.patchValue(val, { emitEvent: false });
    }
  }
  registerOnChange(fn: any): void {
    this.formGroup.valueChanges.subscribe(fn);
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.formGroup.disable() : this.formGroup.enable();
  }
  validate(control: AbstractControl) {
    return this.formGroup.valid ? null : { invalidForm: { valid: false, message: 'FormCreateAccountComponent > formGroup fields are invalid' } };
  }
  checkUserEmail() {
    this.trim('email')
    const email = this.formGroup.get('email') as FormGroup;
    if (email.valid) {
      this._auth.checkUserEmail(email.value).subscribe((res: any) => {
        if (res.length >= 1) {
          email.setErrors({ emailExists: true });
        }
      });
    }
  }
  setRole(role: string) {
    this.role.patchValue(role);
    this.setValidators(role);
  }

  setValidators(role) {
    const number_of_properties_declared = this.formGroup.get('number_of_properties_declared');
    const referral_source = this.formGroup.get('referral_source');
    if (role === 'landlord') {
      number_of_properties_declared.setValidators([Validators.required]);
      referral_source.setValidators([Validators.required]);
      number_of_properties_declared.updateValueAndValidity();
      referral_source.updateValueAndValidity();
    } else {
      number_of_properties_declared.setValidators(null);
      referral_source.setValidators(null);
      number_of_properties_declared.updateValueAndValidity();
      referral_source.updateValueAndValidity();
    }
  }

  checkSmallTimeLL(numberProps: number) {
    console.log(this.client_data.company_name, 'hello company name');
    return (numberProps < 10 && this.client_data.company_name !== 'NRLA') ? this.smallTimeLL$.next('yes') : this.smallTimeLL$.next('no');
  }

  continueAsSmallTimeLL() {
    // this.setValidators(this.role.value);
    this.formGroup.updateValueAndValidity();
    console.log(this.formGroup, 'form group');
    return this.smallTimeLL$.next('continue');
  }
  trim(controlName){
    this.formGroup.get(controlName).patchValue(this.formGroup.get(controlName).value.trim())
  }
  emitForm(form: any) {

    // if (form) {
      this.submitForm.emit(true);
    // }

  }
}

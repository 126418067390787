import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProfileTenantRoutingModule } from '@app/profile-tenant/profile-tenant-routing.module';
import { SharedModule } from '@app/shared';
import { SharedTenantModule } from '@app/core-tenant/_shared/shared-tenant.module';
import { UserVerificationService, UserVerificationStepsService, BunkPassportService } from '@app/services';
import { DynamicModule } from 'ng-dynamic-component';

import { TenantAccountProfileComponent } from './bunk-passport-profile/tenant-account-profile.component';
import { TenantAccountVerifyFullComponent } from './bunk-passport-verify/bunk-passport-verify-full.component';
import { TenantAccountVerifyTiersComponent } from './bunk-passport-verify/bunk-passport-verify-tiers.component';
import { TenantAccountVerifyInfoComponent } from './_shared/tenant-account-verify-info.component';
import { TenantAccountVerifySearchPreferencesComponent } from './_shared/tenant-account-verify-search-preferences';
import { TenantAccountVerifyEmploymentComponent } from './_shared/tenant-account-verify-employment.component';
import { TenantAccountVerifyGuarantorDetailsComponent } from './_shared/tenant-account-verify-guarantor-details.component';
import { TenantAccountVerifyIdentityComponent } from './_shared/tenant-account-verify-identity.component';
import { TenantAccountVerifyPaymentComponent } from './_shared/tenant-account-verify-payment.component';
import { TenantAccountCreditInfoComponent } from './_shared/tenant-account-verify-credit-info.component';
import { TenantVerifyEventEmiterService } from './profile-tenant.service';
import { TenantAccountRequestViewingsSummaryComponent } from './_shared/tenant-account-request-viewing-summary.component';
import { TenantBunkPassportOverViewComponent } from './_shared/tenant-bunk-passport-overview.component';
import { IsTenantVerifiedBunkPassportGuard, IsTenantUnVerifiedBunkPassportGuard, TenantBunkPassportTierTwoGuard } from './guards/profile-tenant.guard';
import { IdentificationService } from '@app/shared/_services/identification.service';
import { TenantAccountVerifyPreviousTenancyComponent } from './_shared/tenant-account-verify-previous-tenancy.component';

import { UtilsModule } from '@app/core/utils/utils.module';
import { ProfilePictureGuard } from '@app/login/guards/profile-picture.guard';
import { AvatarPickerComponent } from '@app/shared/_components/avatar-picker/avatar-picker.component';
import { UserModule } from '@app/shared';
import { TenantAccountVerifyUnivertsityDetailsComponent } from './_shared/tenant-account-university-details.component';

@NgModule({
  declarations: [
    TenantAccountVerifyFullComponent,
    TenantAccountVerifyTiersComponent,
    TenantAccountProfileComponent,
    TenantAccountVerifyInfoComponent,
    TenantAccountVerifySearchPreferencesComponent,
    TenantAccountVerifyEmploymentComponent,
    TenantAccountVerifyPreviousTenancyComponent,
    TenantAccountVerifyGuarantorDetailsComponent,
    TenantAccountVerifyIdentityComponent,
    TenantAccountVerifyPaymentComponent,
    TenantAccountCreditInfoComponent,
    TenantAccountRequestViewingsSummaryComponent,
    TenantBunkPassportOverViewComponent,
    TenantAccountVerifyUnivertsityDetailsComponent
  ],
  imports: [
    SharedModule,
    CommonModule,
    ProfileTenantRoutingModule,
    SharedTenantModule,
    UtilsModule,
    UserModule,
    DynamicModule.withComponents([
      TenantAccountVerifyInfoComponent,
      TenantAccountVerifySearchPreferencesComponent,
      TenantAccountVerifyEmploymentComponent,
      TenantAccountVerifyPreviousTenancyComponent,
      TenantAccountVerifyGuarantorDetailsComponent,
      TenantAccountVerifyIdentityComponent,
      TenantAccountVerifyPaymentComponent,
      TenantAccountCreditInfoComponent,
      TenantAccountRequestViewingsSummaryComponent,
      TenantBunkPassportOverViewComponent
    ])
  ],
  exports: [
    TenantAccountVerifyTiersComponent,
    TenantAccountVerifyInfoComponent,
    TenantAccountVerifySearchPreferencesComponent,
    TenantAccountVerifyEmploymentComponent,
    TenantAccountVerifyPreviousTenancyComponent,
    TenantAccountVerifyGuarantorDetailsComponent,
    TenantAccountVerifyIdentityComponent,
    TenantAccountVerifyPaymentComponent,
    TenantAccountCreditInfoComponent,
    TenantAccountRequestViewingsSummaryComponent,
    TenantBunkPassportOverViewComponent,
    TenantAccountVerifyUnivertsityDetailsComponent
  ],
  providers: [
    TenantVerifyEventEmiterService,
    UserVerificationService,
    IdentificationService,
    UserVerificationStepsService,
    BunkPassportService,
    IsTenantVerifiedBunkPassportGuard,
    IsTenantUnVerifiedBunkPassportGuard,
    TenantBunkPassportTierTwoGuard,
    ProfilePictureGuard
  ],
  entryComponents: [
    TenantAccountVerifyTiersComponent,
    TenantBunkPassportOverViewComponent,
    AvatarPickerComponent
  ]
})
export class ProfileTenantModule { }

<form  class="ideal-postcodes__form" [formGroup]="addressForm">
  <mat-form-field appearance="{{appearanceType}}">
    <mat-label *ngIf="labelText">{{labelText}}</mat-label>
    <input matInput
      #addresstext
      name="address"
      type="text"
      placeholder="Type postcode or address here e.g. BS16 1QY"
      [attr.aria-label]="labelText"
      formControlName="address"
      [attr.disabled]="disabled"
      data-test="selectAddressInput">
    <mat-error *ngIf="showError" class="margin-bottom--sm">Please enter the street address</mat-error>
  </mat-form-field>

</form>

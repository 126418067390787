import { Component, Input, OnInit} from '@angular/core';
import { Landlord } from '@env/routing';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { PropertyService } from '@app/services';
import { map } from 'rxjs/operators';
import { of } from 'rxjs/internal/observable/of';
import { ToastrService } from 'ngx-toastr';
import {environment} from '@env/environment';
import { TRANSLATIONS } from '@app/_constants/translations.constants';


@Component({
  selector: 'bedroom-form-list',
  template: `
    <ng-container *ngIf="bedrooms">

    <ul *ngFor="let bedroom of bedrooms; let i=index" class="cc w_100" >
      <li class="card__base--bedroom-list cursor mb_8"
        [ngClass]="{'active':  bedroom.bedroom_id === currentBedroomId}"
        (click)="goToBedroom(bedroom.bedroom_id)"
        >
        <span *ngIf="disableRoom && !bedroom.room_name" class="incomplete-room">Incomplete </span>
          <div class="cc">
          <div class="rc space-between">
          <p *ngIf="bedroom.room_name" class="text--md font-colour-primary mb_1">{{bedroom?.room_name}}</p>
          <p *ngIf="!bedroom.room_name" class="text--md font-colour-primary mb_1">{{client_data.unit_name}} {{i +1}}</p>
          <div class="rc">
            <div class="icons">
                <img src='/assets/svg/delete.svg' class="w_100" alt="" (click)="deleteBedroom(bedroom.bedroom_id)"></div>
            </div>
          </div>
          <p class="text--sm mb_1" [hidden]="bedroom.listed_rent_pcm || bedroom.rent_qtr">{{client_data.currency_symbol}} 0</p>
          <p class="text--sm mb_1" *ngIf="bedroom.listed_rent_pcm" [hidden]="bedroom.rent_interval_count === 3">{{client_data.currency_symbol + (bedroom?.listed_rent_pcm ? bedroom.listed_rent_pcm : ' 0 ')}}
          <small  class="text--xs">{{rent_label_abbreviation.toUpperCase()}}</small></p>

          <p class="text--sm mb_1" *ngIf="bedroom.rent_qtr && bedroom.rent_interval_count === 3">{{client_data.currency_symbol + (bedroom?.rent_qtr ? bedroom.rent_qtr : ' 0 ') }} <small class="text--xs">Quarterly</small>
          </p>
          <p *ngIf="bedroom.is_mid_tenants" class="text--subtle">Tenancy Management</p>
          <p *ngIf="!bedroom.is_mid_tenants" class="text--subtle">Tenancy Find</p>
          </div>
      </li>
    </ul>
    <div class="card__base--bedroom-list text--md font-colour-primary" [ngClass]="{'disabled' : disableRoom}"
        (click)="addRoom()">
          + Add {{client_data.unit_name}}
    </div>
    </ng-container>
    `,
  styleUrls: ['./bedroom-form-list.component.scss']

})

export class BedroomFormListComponent implements OnInit {
    public routes = {Landlord};
    public property_id: string;
    public editId: string;
    public disableRoom: boolean;
    public currentBedroomId: string;
    @Input() bedrooms: any;
    @Input() property: any;
    public client_data = environment.client_data;
    public rent_label_abbreviation = TRANSLATIONS.rent_label_abbreviation;


    constructor(
        private router: Router,
        private _property: PropertyService,
        private route: ActivatedRoute,
        private _toastr: ToastrService
        ) {
            this.property_id = this.route.snapshot.firstChild.paramMap.get('id');
            this.editId = this.route.snapshot.firstChild.paramMap.get('editId');
        }

    deleteBedroom(bedroom_id: string) {
      this.disableRoom = false;

      const is_editing = !!(this.editId);

      if (is_editing && this.property.bedrooms[bedroom_id].has_current_tenancy) {
        this._toastr.error('Please contact us if you would like to delete this room.', 'Sorry, this bedroom has a current tenancy');
      } else {
        this._property.deleteBedroomCollections(this.getPropertyId(), bedroom_id, is_editing).pipe(
          map(() => is_editing ? this._property.updatePropertyEditStatus('rooms', this.editId) : of()),
        )
          .subscribe(() => {
            console.log('in subscription');
            (this.editId)
              ? this.router.navigate(['/' + Landlord.base,
                Landlord.property_list_form.base,
                Landlord.property_list_form.rooms.base,
                Landlord.property_list_form.rooms.tenants_selection,
                this.property_id, Landlord.property_list_form.edit, this.editId])
              : this.router.navigate(['/' + Landlord.base,
                Landlord.property_list_form.base,
                Landlord.property_list_form.rooms.base,
                Landlord.property_list_form.rooms.tenants_selection,
                this.property_id]) ;
          });
      }
    }

    addRoom() {
      for (const bed of this.bedrooms) {
        if (!bed.room_name) {
          return this.disableRoom = true;
          } else {
            this.disableRoom = false;
        }
    }
    this.router.navigate([
      '/' + Landlord.base, Landlord.property_list_form.base,
      Landlord.property_list_form.rooms.base,
      Landlord.property_list_form.rooms.tenants_selection,
      this.property_id]);
    }



    goToBedroom(bedroom_id: string) {
      (this.editId)
        ? this.router.navigate(['/' + Landlord.base,
        Landlord.property_list_form.base,
        Landlord.property_list_form.rooms.base,
        Landlord.property_list_form.rooms.listing,
        this.property_id,
        bedroom_id, Landlord.property_list_form.edit, this.editId])
        : this.router.navigate(['/' + Landlord.base,
        Landlord.property_list_form.base,
        Landlord.property_list_form.rooms.base,
        Landlord.property_list_form.rooms.listing,
        this.property_id,
        bedroom_id]) ;
    }


  update(url: any ) {
    url = this.router.url;
    for (const bed of this.bedrooms) {
        if (url.includes(bed.bedroom_id)) {
            this.currentBedroomId = bed.bedroom_id;
        }
    }
  }

    ngOnInit() {
    this.router.events
    .subscribe((val: NavigationEnd) => this.update(val.url));
    this.update(this.router.url);

    for (const bed of this.bedrooms) {
      if (!bed.room_name) {
         this.disableRoom = true;
        this.router.navigate(['/' + Landlord.base,
        Landlord.property_list_form.base,
        Landlord.property_list_form.rooms.base,
        Landlord.property_list_form.rooms.listing,
        this.property_id,
        bed.bedroom_id]);
        } else {
          this.disableRoom = false;
      }
  }

    }

    getPropertyId() {
      return (this.editId) ? this.editId : this.property_id;
    }

}


import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule, MatTooltipModule } from '@angular/material';
import { UserModule } from '@app/shared/user.module';
import { ContactButtonDirectiveModule, SendMessageModule, FileDownloadDirectiveModule } from '@app/shared/_directives';
import { PipesModule } from '@app/shared/_pipes/pipes.module';

import { ProfileCardOverlayComponent } from './profile-card-overlay.component';

@NgModule({
  imports: [
    CommonModule,
    MatIconModule,
    MatTooltipModule,
    UserModule,
    ContactButtonDirectiveModule,
    SendMessageModule,
    PipesModule,
    FileDownloadDirectiveModule
  ],
  exports: [ProfileCardOverlayComponent],
  declarations: [ProfileCardOverlayComponent],
  providers: [],
})
export class ProfileCardOverlayModule { }

import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '@app/core/services';
import { UserService } from '@app/services';
import { environment } from '@env/environment.client-campus-key';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';


@Component({
  selector: 'property-owner-info-card',
  template: `
  <ng-container *ngIf="(landlordPublic$ | async) as landlordPublic">
    <div class="landlord-card--info items-center">

    <div class="image-container">
      <profile-avatar [profileImgUrl]="landlordPublic.profileImgUrl" profileName="{{(landlordPublic.manager.companyName ? landlordPublic.manager.companyName : landlordPublic.firstName)}}"></profile-avatar>
    </div>

      <ng-container *ngIf="isCampusKey; else notCampusKey">
            <p> If you have any questions contact our CK Team. You’ll be able to message them anytime from your applications dashboard.</p>
   
      </ng-container>
      <ng-template #notCampusKey>
          <p> If you have any questions or problems, contact {{ landlordPublic.manager.companyName }} who let and manage this property. You’ll be able to message them anytime from your applications dashboard during the offers process.</p>
    </ng-template>
    </div>
    
</ng-container>`,
  styleUrls: ['./property-owner-card.component.scss']
})
export class PropertyOwnerCardInfoComponent implements OnInit {

  @Input() property?: any;
  public landlordPublic$: Observable<any>;
  public showSignUpForm = false;
  public dref: any;
  public isCampusKey = environment.firebaseConfig.projectId === 'client-campus-key';

  constructor(
    private _activatedRoute: ActivatedRoute,
    public _router: Router,
    public _user: UserService,
    public _auth: AuthService
  ) { }

  ngOnInit() {

    this.landlordPublic$ =
    this.property && this.property.team_id ? this._user.getTeamPublicById(this.property.team_id).pipe(
      map(team => {
        let manager = null;
        if(team && team.team_data){
            manager ={
              companyName: team.team_data.company_name,
            };
          }
          return {
            firstName: team.team_data.company_name,
            profileImgUrl: team.team_data.company_logo,
            uid: this.property.landlord_uid ? this.property.landlord_uid : null,
            manager
          };
        
      })
    ) :
    this._user.getUserById(this.property.landlord_uid).valueChanges().pipe(
      map(landlordPublic => {
        let manager = null;
        if (landlordPublic.property_manager_data) {
          manager = {
            companyName: landlordPublic.property_manager_data.company_name,
          };
        }
        return {
          firstName: landlordPublic.profile_data.first_name,
          profileImgUrl: landlordPublic.profile_data.profile_image_url,
          uid: landlordPublic.uid,
          manager
        };
      })
    );

  }



}

<span [style.cursor]="!static ? 'pointer' : 'default'" *ngIf ="display$ | async as display">
  <div class="prop-card__image-main" [ngClass]="{'image__placeholder': !display.photo}" (click)="gotoProperty()">
    <img
      *ngIf="display.photo"
      src="{{ display.photo }}"
      alt="{{ display.propertyType }}">
  </div>

  <p class="prop-card__available" *ngIf="(display.isHMO && !display.isDeleted && display.isListed && !display.listingTitle) && !display.isMarketingListing">
    <span *ngIf="display.totalRoomsAvailable >= 1 && !(display.totalRoomsUnderOffer >= 1 && display.totalRoomsAvailable == 1)">{{ (display.totalRoomsAvailable - display.totalRoomsUnderOffer) > 1 ? (display.totalRoomsAvailable - display.totalRoomsUnderOffer) + ' rooms' : display.totalRoomsAvailable + ' room'}} still available! <br>
    </span>
    <span *ngIf="display.totalRoomsUnderOffer >= 1">{{ display.totalRoomsUnderOffer > 1 ? display.totalRoomsUnderOffer + ' rooms' : display.totalRoomsUnderOffer + ' room'  }} under offer </span>
  </p>

  <p  class="prop-card__available" *ngIf="(display.isHMO && !display.isDeleted && display.isListed && display.listingTitle) && !display.isMarketingListing">
    <span *ngIf="display.totalRoomsAvailable >= 1 && !(display.totalRoomsUnderOffer >= 1 && display.totalRoomsAvailable == 1)">{{ (display.totalRoomsAvailable - display.totalRoomsUnderOffer) > 1 ?
      (display.totalRoomsAvailable - display.totalRoomsUnderOffer) + ' ' + display.justPropertyType : display.totalRoomsAvailable + ' ' + display.justPropertyType }}s still available! <br>
      <span *ngIf="display.totalRoomsUnderOffer >= 1">{{ display.totalRoomsUnderOffer > 1 ? display.totalRoomsUnderOffer + ' ' + display.justPropertyType + 's' : display.totalRoomsUnderOffer + ' ' + display.justPropertyType }} under offer </span>
     </span>
  </p>

  <p class="prop-card__available uppercase" *ngIf="(!display.isHMO && display.underOffer) && !display.isMarketingListing">Under offer</p>
  <p class="prop-card__available " *ngIf="(display.isDeleted || !display.isListed)&& !display.isMarketingListing">Not Available</p>

  <div class="prop-card__title" (click)="gotoProperty()">
    <p class="prop-card__rent">
      <strong class="text--md" *ngIf="!display.isHMO">{{client_data.currency_symbol + display.rentPCM}}<sup class="text--subtle">{{rent_label_abbreviation}}</sup></strong>
      <strong class="text--md" *ngIf="display.isHMO && bedroomId">{{ client_data.currency_symbol + this.property.bedrooms[this.bedroomId].listed_rent_pcm }}<sup class="text--subtle">{{rent_label_abbreviation}}</sup></strong>

      <strong class="text--md" *ngIf="display.isHMO && (display.roomMin !== display.roomMax) && !bedroomId">{{  client_data.currency_symbol + display.roomMin }} - {{ client_data.currency_symbol + display.roomMax }}
        <sup class="text--subtle">{{(property.landlord_uid !== 'R4EGdbwhhlWwF6KyKwmTBTxRXey1' ? rent_label_abbreviation : 'weekly')}}</sup></strong>
      <strong class="text--md" *ngIf="display.isHMO && (display.roomMin == display.roomMax) && !bedroomId">{{ client_data.currency_symbol + display.roomMin }}<sup class="text--subtle">{{(property.landlord_uid !== 'R4EGdbwhhlWwF6KyKwmTBTxRXey1' ? rent_label_abbreviation : 'weekly')}}</sup></strong>
    </p>
    <div class="prop-card__amenities">
      <p class="prop-card__amenities_item" *ngIf="display.totalBedrooms >= 1"><img src='/assets/img/icons/bed@3x.png' alt="Bedrooms" class="prop-card__icon"> {{ display.totalBedrooms }}</p>
      <p class="prop-card__amenities_item" *ngIf="display.totalBathrooms >= 1"><img src='/assets/img/icons/bath@3x.png' alt="Bathrooms" class="prop-card__icon">{{ display.totalBathrooms }}</p>
    </div>
  </div>

  <div class="prop-card__body">
    <div class="flex flex-row justify-between">
    <div class="details" (click)="gotoProperty()">
      <p class="proptype" *ngIf="display.listingTitle; else noListType">{{ display.listingTitle }}</p>
        <ng-template #noListType><p class="proptype">{{ (property.is_hmo || property.is_house_share) ? display.isHouseShare :  display.propertyType }}</p></ng-template>

     <div class="flex prop-card__body__address ">
       <div>
        <img src='/assets/img/icons/location.svg' alt="Bathrooms" width="15" height="15">
       </div>

      <p class="text--subtle margin-left--xs">{{ display.address }}</p>
     </div>
      <div class="prop-card__body__features flex-wrap margin-bottom--xs  margin-top--xs">
        <span *ngFor="let features of display.features" > {{features}} </span>
      </div>
      <div *ngIf="property.tenancy_package_ids?.length > 1" class="flex flex-column prop-card__body__features-list margin-top--xs margin-bottom--xs">
        <p  class="prop-card__body__features-list--standard ">Standard Tenancy Available</p>
        <p class="prop-card__body__features-list--premium">Premium Tenancy Available</p>
      </div>
      <small class="text--subtle" *ngIf="!display.isHMO">Available: {{display.dateAvailable}}</small>
    </div>
    <div class="favourite__btn__wrapper flex items-center justify-center">
    <button class="favourite__btn" (click)="likeProperty(property?.property_id)" *ngIf="showLiked && _auth.authenticated">
      <mat-icon *ngIf=" !_user?.userDb?.tenant_data?.liked_property_ids" class="favourite" svgIcon="favorite_border" aria-label="Save property" aria-hidden="false"></mat-icon>

      <button class="favourite__btn" *ngIf="_auth.authenticated && _user?.userDb?.tenant_data?.liked_property_ids" attr.aria-label="{{_user?.userDb.tenant_data.liked_property_ids.hasOwnProperty(property?.property_id) ? 'Remove from saved properties' : 'Save this property' }}">
        <mat-icon *ngIf="_user?.userDb.tenant_data.liked_property_ids.hasOwnProperty(property?.property_id)" class="favourite--selected" svgIcon="favorite" aria-hidden="false"></mat-icon>
        <mat-icon *ngIf="!_user?.userDb.tenant_data.liked_property_ids.hasOwnProperty(property?.property_id)" class="favourite" svgIcon="favorite_border" aria-hidden="false"></mat-icon>
      </button>
    </button>
  </div>
    </div>


      <div class="prop-card__footer" #tooltip="matTooltip"
           matTooltip="This property is managed by {{client_name}} and you will be redirected to their website"
           matTooltipPosition="below" matTooltipHideDelay="1000" *ngIf="redirect_project_id">
    <small class="prop-card__footer__txt" aria-label="Property Tooltip"> Managed by {{client_name}} <mat-icon
      class="open-tab" svgIcon="open-tab" aria-hidden="false"></mat-icon>
    </small>

    <img class="prop-card__footer__img" src="./../../../../../assets/clients/{{redirect_project_id}}/logos/main-logo.svg" alt="Logo">
  </div>
  </div>

  <ng-content></ng-content>
</span>

import { environment } from '@env/environment';

const projectId: string = environment.firebaseConfig.projectId;

export const PAYMENT_STATUS = [
  {
    value: 'paid',
    viewValue: 'Paid'
  },
  {
    value: 'due',
    viewValue: 'Due'
  },
  {
    value: 'overdue',
    viewValue: 'Overdue'
  },
  {
    value: 'scheduled',
    viewValue: 'Scheduled'
  },
  {
    value: 'waived',
    viewValue: 'Waived'
  },
  {
    value: 'notified_paid',
    viewValue: 'Declared paid'
  },
  {
    value: 'received',
    viewValue: 'Received'
  },
  {
    value: 'partially_paid',
    viewValue: 'Partially paid'
  },
  {
    value: 'partially_received',
    viewValue: 'Partially received'
  },
  {
    value: 'registered',
    viewValue: 'Registered'
  },
  {
    value: 'register_now',
    viewValue: 'Register now'
  },
];


export const PAYMENT_PAID_ON_TYPES = [
  {
    value: 'today',
    viewValue: 'Today'
  },
  {
    value: 'yesterday',
    viewValue: 'Yesterday'
  },
  {
    value: 'other',
    viewValue: 'Other'
  }
];


export const PAYMENT_STATUSES = {
  paid: 'Paid',
  waived: 'Waived',
  due: 'Due',
  overdue: 'Overdue',
  scheduled: 'Scheduled',
  notified_paid: 'Notified Paid',
  received: 'Received',
  partially_paid: 'Partially paid',
  partially_received: 'Partially received',
  register_now: 'Register now',
  register: 'Register'
};

export const CAMPUS_TYPES = [
  {
    viewValue: 'All Campuses',
    value: 'all'
  },
  {
    viewValue: 'Bloemfontein',
    value: 'Bloemfontein',
    teamIds: ['9VeX0ynaJGy4SnabmGcc', 'niCSn5VlTeCPo1n2JzKD']
  },
  {
    viewValue: 'Cape Town',
    value: 'Cape Town',
    teamIds: ['mOniJcyZEqxl5Oc5DKuR']
  },
  {
    viewValue: 'Port Elizabeth',
    value: 'Port Elizabeth',
    teamIds: ['csXwXOFz4ti9Dx6MuOok']
  },
  {
    viewValue: 'Pretoria',
    value: 'Pretoria',
    teamIds: ['8h1nBbYi57VmMUGTqiyQ']
  },
  {
    viewValue: 'Potchefstroom',
    value: 'Potchefstroom',
    teamIds: ['ncQQV7eUIUJYAePOdM9l', 'vpgT0G0O6jw7PxHyOvsR', 'XPVBUOL2ivB5aB4n5Nc9']
  },
  {
    viewValue: 'Stellenbosch',
    value: 'Stellenbosch',
    teamIds: ['RdtMXemWEQAVXfUtPgDV']
  }
];
export const CAMPUS_STRUCTURE = {
  'all': [{ value: 'all', viewValue: 'All Buildings' }],
  'Bloemfontein': [{ value: 'Groenewoud', viewValue: 'Groenewoud' }, { value: 'Logeman', viewValue: 'Logeman' }],
  'Cape Town': [{ value: 'Observatory', viewValue: 'Observatory' }, { value: 'Rosebank', viewValue: 'Rosebank' }],
  'Port Elizabeth': [{ value: 'Scarborough', viewValue: 'Scarborough' }, { value: '9th Avenue', viewValue: '9th Avenue' }],
  'Potchefstroom': [{ value: 'InniLaan', viewValue: 'InniLaan' }, { value: 'Molen', viewValue: 'Molen' }, { value: 'Girls@', viewValue: 'Girls@' }],
  'Pretoria': [{ value: 'South Street', viewValue: 'South Street' }, { value: 'Glyn', viewValue: 'Glyn' }],
  'Stellenbosch': [{ value: 'Banghoek', viewValue: 'Banghoek' }, { value: 'Laranja', viewValue: 'Laranja' }, { value: 'Verde', viewValue: 'Verde' }, { value: 'Bordeaux', viewValue: 'Bordeaux' }]
};
export const CompanyName = {
  isNrla: ['client-nrla', 'client-nrla-staging'].includes(projectId),
  isNrlaProd: projectId === 'client-nrla',
  isCampusKey : projectId === 'client-campus-key',
  isARPM :  projectId === 'client-arpm',
  isLivingWay : projectId === 'client-living-way',
  isDemo : projectId === 'bunk-demo-app',
  isBunkAppTest : projectId === 'bunkapptest',
  isTailorMade: projectId === 'client-tailor-made-management'
};

export const DOCUMENT_TYPES_LIST = [
  {value: 'hm_land_registry_title', viewValue: 'HM Land Registry title'},
  { value: 'title_deed', viewValue: 'Title Deed' },
  { value: 'mortgage_agreement', viewValue: 'Mortgage Agreement' },
  { value: 'certificate_of_insurance', viewValue: 'Certificate of insurance' },
  { value: 'signed_solicitors_letter', viewValue: 'Signed solicitors letter' }
];

export const ADDRESS_DOCUMENT_TYPES_LIST = [
  {value: 'utility_bill', viewValue: 'Utility Bill'},
  {value: 'bank_statement', viewValue: 'Bank Statement'},
  {value: 'mortgage_statement', viewValue: 'Mortgage Statement'},
  {value: 'council_tax', viewValue: 'Council Tax'}
];

import {Component, EventEmitter, Output, OnInit, ViewChild, OnDestroy} from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import {NotificationsService, PaginationService, UserService} from '@app/services';
import * as moment from 'moment';
import { Router } from '@angular/router';
import {Landlord, Tenant, Shared, Documents, Tenancies} from '@env/routing';
import {finalize, map, tap, scan, mergeMap, throttleTime, takeUntil} from 'rxjs/operators';
import {from} from 'rxjs/internal/observable/from';
// import {tap} from 'rxjs/internal/operators/tap';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import {BehaviorSubject} from 'rxjs/BehaviorSubject';
import {AngularFirestore} from '@angular/fire/firestore';
import {AuthService} from '@app/core/services';
import {Subject} from 'rxjs';

@Component({
  selector: 'notifications',
  template: `

      <div class="flex justify-end">
        <div class="notifications-nav_header">
          <div class="title_3">Notifications</div>
          <mat-icon [matMenuTriggerFor]="list" aria-hidden="false" aria-label="Example home icon" class="cursor-pointer">more_vert</mat-icon>
          <mat-menu #list="matMenu">
            <button class="btn btn--sm menu__item" mat-menu-item (click)="clearNotifications()">
              <span>Mark all as read</span>
            </button>
            <button class="btn btn--sm menu__item" mat-menu-item (click)="clearNotifications(true)">
              <span>Clear all notifications</span>
            </button>
          </mat-menu>
        </div>

      </div>
      <infinite-scroll [batch]='8' [getScrollQuery]="this.queryData" (emitTheEnd)="hasEnded($event)">
        <ng-template let-data>
          <li [ngClass]="{'unread': !data.notification_is_viewed}" (click)="updateNotificationToRead(data.notification_id, data.category, data.reference_id)">
            <p class="text--md mb_0">{{data.title | titlecase}}</p>
            <p class="text--subtle text--sm mb_0">{{data.body | truncate:[80, '...']}}</p>
            <small class="text--subtle">{{getMomentTime(data)}}</small>
          </li>
        </ng-template>
        <ng-container *ngIf="theEnd">
          <div class="flex justify-center margin-top--sm margin-bottom--sm">
            <p class="text--subtle">You've reached the end of your notifications.</p>
          </div>
        </ng-container>
      </infinite-scroll>
  `,
  styleUrls: ['./notifications.component.scss']
})

export class NotificationsComponent implements OnDestroy {
  @Output() closeNotifications: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() openVerificationDialog: EventEmitter<boolean> = new EventEmitter<boolean>();
  @ViewChild(CdkVirtualScrollViewport, {static: false}) viewport: CdkVirtualScrollViewport;

  protected destroy$: Subject<boolean> = new Subject<boolean>();
  public offset = new BehaviorSubject(null);

  theEnd = false;

  public queryData;

  constructor(
    private _notifications: NotificationsService,
    private _router: Router,
    private _user: UserService,
    private _afs: AngularFirestore,
    public _auth: AuthService
  ) {
   this.queryData = {
      collection: 'notifications',
      clauses: [
        {
          key: 'uid',
          condition: '==',
          value: this._auth.currentUserId
        },
        {
          key: 'is_deleted',
          condition: '==',
          value: false
        }
      ],
     order: {key: 'notification_sent_time', condition: 'desc'}
    };
  }

  sortArrayDescending = (array: any, item: any) => (array && array.length > 0) ? array.sort((a: any, b: any) =>  b[item].toDate() - a[item].toDate()) : [];

  getMomentTime(notification: any) {
    return moment(notification.notification_sent_time.toDate()).fromNow();
  }

  clearNotifications(clear_all: boolean = false): any {
    return this._notifications.getAllUserNotifications().valueChanges().pipe(
      finalize(() => this.closeNotifications.emit(false)),
      map((notifications: any) => this._notifications.clearAllNotifications(clear_all, notifications)),
      takeUntil(this.destroy$),
      map(() => {
        if (clear_all) {
          this.offset.next('cleared');
        }
      })
    ).subscribe();
  }

  updateNotificationToRead(notification_id: string, category: string, refId: string) {
    const role = (this._user.userDb.role === 'tenant') ? 'tenant' : 'landlord';

    this._notifications.updateNotificationToRead(notification_id).pipe(
      finalize(() => this.closeNotifications.emit(false))
    ).subscribe(
      () => {
        switch (category) {
          case 'viewing_request':
            (role === 'tenant') ? this._router.navigate(['/' + Tenant.base, Tenant.manage.base, Tenant.viewing.base, Tenant.viewing.reschedule, refId])
              : this._router.navigate(['/' + Landlord.base, Landlord.manage.base, Landlord.manage.viewings.base, Landlord.manage.viewings.reschedule, refId]);
            break;
          case 'tenancy_invitation':
            (role === 'tenant') ? this._router.navigate(['/' + Tenant.base, Tenant.manage.base, Tenant.my_offers])
              : this._router.navigate(['/' + Landlord.base, Landlord.manage.base, Landlord.manage.applications.base]);
            break;
          case 'tenancy_offer':
            this._router.navigate(['/' + Tenant.base, Tenant.make_an_offer.base, refId, Tenant.make_an_offer.summary]);
            break;
          case 'tenancy':
            (role === 'tenant') ? this._router.navigate(['/' + Tenant.base, Tenant.manage.base]) : this._router.navigate(['/', Documents.base]);;
            break;
          case 'pending_stripe_verification':
            this.openVerificationDialog.emit(true);
            break;
          case 'chat_message':
            this._router.navigate(['/' + Shared.chat.inbox, refId]);
            break;
          case 'tenancy_agreements':
            this._router.navigate(['/', Documents.base, Documents.tenancy_agreement.base, Documents.tenancy_agreement.terms, refId]);
            break;
        }
      }
    );
  }

  hasEnded(event: any) {
    this.theEnd = event;
  }


  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}

import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { Injectable } from '@angular/core';
import { AuthService } from '@app/core/services';
import { map } from 'rxjs/operators';
import { of } from 'rxjs';

const validPostCode = /^([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([AZa-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9]?[A-Za-z]))))[0-9][A-Za-z]{2})$/;
// create your class that extends the angular validator class
@Injectable()
export class CustomValidators extends Validators {

  // create a static method for your validation
  static ValidatePostCodeUK(control: FormControl) {

    // first check if the control has a value
    if (control.value && control.value.length > 0) {

      const replaceWhiteSpaces = control.value.replace(/\s/g, '');
      const valueLowerCase = replaceWhiteSpaces.toLowerCase();
      const isValid = validPostCode.test(valueLowerCase);

      return (isValid) ? null : {validPostCode: false};
    } else {
      return null;
    }
  }

  static ValidDate = (control: FormControl) => {
    return control.value instanceof Date || control.value === '' ? null : {
      validateDate: {
        valid: false
      }
    };
  };


  static emailMatchValidator(control: AbstractControl) {
    const email = control.get('email').value;
    const confirmEmail = control.get('confirm_email').value;
    if (email !== confirmEmail) {
      control.get('confirm_email').setErrors({'Emails do not match': true});
    }
  }
}


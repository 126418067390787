import { Component, OnDestroy, Renderer2 } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';

import { AuthService } from '@app/core/services/';
import { UserService } from '@app/services/user.service';

import { Subject, of } from 'rxjs';
import {flatMap, finalize, switchMap, map} from 'rxjs/operators';
import { BunkUploaderModel } from '@app/shared/_components';
import { PhoneVerificationService } from '@app/login/services/phone-verification.service';
import { ToastrService } from 'ngx-toastr';
import { BunkPassportService } from '@app/services';
import { Observable } from 'rxjs/Observable';

@Component({
  selector: 'app-avatar-picker',
  template: `
     <div class="avatar" *ngIf="!showPhoneVerification" [hidden]="successScreen">
  <span class="avatar__title">Hi, {{_user.userDb?.profile_data?.full_name}}</span>

  <img *ngIf="!hasProfilePic" src="/assets/img/new-icons/add-avatar.png" alt="Add image">

  <profile-avatar class="margin-top margin-bottom" *ngIf="hasProfilePic" size="lg" justified="center"
    [profileImgUrl]="_user.userDb?.profile_data.profile_image_url" [profileName]="_user.userDb?.profile_data.full_name">
  </profile-avatar>
  <h2>Let's add a photo and verify your account</h2>

  <span class="margin-bottom">It’s nice to put a face to a name, a photo might help your profile stand out amongst others.</span>

  <form [formGroup]="profileImgformGroup">

    <div class="form_input text-center">

      <label for="profile-picture-upload" formGroupName="profile_image_url">

        <processing-spinner *ngIf="showSpinner" color="#FFFFFF" size="40px"></processing-spinner>

        <button role="button"
          class="btn__generic btn--lg margin-top" (click)="profileUploader.click()" [hidden]="showSpinner">
          <processing-spinner *ngIf="showSpinner" color="#FFFFFF"></processing-spinner>
          <span *ngIf="!showSpinner">{{(hasProfilePic ? 'Update photo' : 'Add photo')}}</span>
        </button>
      </label>

      <bunk-uploader #profileUploader [filePath]="filePath" (uploadChange)="onUploadedFile($event)"
        (uploadStart)="onUpStart()" [multiple]="false" hidden>
      </bunk-uploader>
    </div>
      <div class="flex justify-center">
        <button class="btn__text-generic"
          aria-label="Profile image skip upload" (click)="setDefaultProfilePicture()" [hidden]="showSpinner">
          {{( hasProfilePic ? 'Continue' : 'Skip')}}</button>
      </div>
  </form>

  <div *ngIf="profile_image" class="continue text-center" routerLink="/">continue</div>
  <div *ngIf="profile_image" class="back text-center" (click)="profile_image = !profile_image">change image
  </div>
</div>

<!-- <ng-container *ngIf="showPhoneVerification && !successScreen">
  <profile-avatar size="lg" justified="center" [profileImgUrl]="_user.userDb?.profile_data.profile_image_url"
    [profileName]="_user.userDb?.profile_data.full_name"></profile-avatar>
  <div class="flex flex-column items-center">
  <ng-container *ngIf="!resendCode">
    <h2 class="margin-top">Verify your account</h2>
    <span class="text-center">We've sent a 4-digit code to {{last4digits}}</span>
  </ng-container>


  <form [formGroup]="phoneFormGroup" class="phone__verification-container" [hidden]="successScreen">
    <div class="flex justify-between" *ngIf="!resendCode">
      <mat-form-field appearance="outline">
        <input matInput type="text" pattern="[0-9]*" [formControlName]="'verification_code'" (input)="verifyPhone()"  class="text-center" id="code">
      </mat-form-field>
    </div>

    <div class="phone__verification-container" *ngIf="resendCode">

      <div class="flex justify-start w_100">
        <span class="grey__circle" type="button" (click)="resendCode = false">
          <mat-icon svgIcon="chevron-left"></mat-icon>
        </span>
      </div>
      <div>
        <mat-form-field appearance="outline" class="country_code">
          <mat-label>Code</mat-label>
          <mat-select [formControlName]="'country_code'">
            <mat-option *ngFor="let code of countryCodes" [value]="code">
              +{{code}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline" class="number">
          <mat-label>Phone number</mat-label>
          <input matInput type="tel" [formControlName]="'phone_number'" id="phoneNumber">
          <mat-error>Please enter your phone number</mat-error>
        </mat-form-field>
      </div>
    </div>

  </form>



  <button *ngIf="!resendCode" class="btn__generic btn--lg margin-top--md" type="button" (click)="verifyPhone()">
    <processing-spinner *ngIf="showPhoneSpinner" color="#FFFFFF"></processing-spinner>

    <span *ngIf="!showPhoneSpinner">Verify</span>
  </button>

  <button *ngIf="resendCode" class="btn__generic btn--lg margin-top--md" (click)="requestPhoneVerification(true)">
    <processing-spinner *ngIf="showPhoneSpinner" color="#FFFFFF"></processing-spinner>

    <span *ngIf="!showPhoneSpinner">Resend code</span>
  </button>


  <small *ngIf="!resendCode" class="flex flex-row align-center margin-top items-center"> Didn't receive a code?
    <button class="btn__text-generic ml_3" type="button" (click)="showPhoneNumber()">Resend code</button></small>

  </div>
  </ng-container> -->



<ng-container *ngIf="successScreen">
  <div class="flex flex-column justify-center items-center w_100">

    <div class="tick-container">
      <!-- <span class="status__tick status__tick--circled status__tick--circled--large"></span> -->
      <svg version="1.1" [ngClass]="{'drawn' : showTick}" id="tick" xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 37 37"
        style="enable-background:new 0 0 37 37;" xml:space="preserve">
        <path class="circ path"
          style="fill:none;stroke:#119AE4;stroke-width:2;stroke-linejoin:round;stroke-miterlimit:10;"
          d="
M30.5,6.5L30.5,6.5c6.6,6.6,6.6,17.4,0,24l0,0c-6.6,6.6-17.4,6.6-24,0l0,0c-6.6-6.6-6.6-17.4,0-24l0,0C13.1-0.2,23.9-0.2,30.5,6.5z" />
        <polyline class="tick path"
          style="fill:none;stroke:#119AE4;stroke-width:2;stroke-linejoin:round;stroke-miterlimit:10;" points="
11.7,20.1 11.6,20 15.9,24.2 26.4,13.8 26.3,13.9" />
      </svg>
    </div>


    <h1 class="margin-top">You're all set!</h1>
    <span> Your account is now verified</span>
    <button type="button" class="btn__generic btn-lg margin-top--lg" (click)="dialog.closeAll()">Great!</button>
  </div>
</ng-container>

<div class="ellipsis__stepper" [hidden]="successScreen">
  <span class="step" [ngClass]="{'step--selected' : !showPhoneVerification}"
    (click)="showPhoneVerification = false"></span>
  <span class="step" [ngClass]="{'step--selected' : showPhoneVerification}"
    (click)="hasProfilePic ? showPhoneVerification = true : showPhoneVerification = false;  focusInput(); "></span>
</div>

  `,
  styleUrls: ['./avatar-picker.component.scss']
})
export class AvatarPickerComponent implements OnDestroy {
  profileImgformGroup: FormGroup;
  phoneFormGroup: FormGroup;
  // @TODO:Add back into add once re designed.Routes straight to searching now
  public profile_image;
  public completed = false;
  public defaultImage = null;
  public queryParam: { property_id?: string };
  public showSpinner = false;
  public showPhoneVerification = false;
  filePath = `user/${this._auth.currentUserId}/profile`;
  public loading = false;
  public showPhoneSpinner = false;
  public last4digits: string;
  public hasProfilePic = false;
  public resendCode = false;
  public successScreen = false;
  countryCodes;
  public showTick = false;

  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    public _user: UserService,
    private _formBuilder: FormBuilder,
    private _auth: AuthService,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    private _phoneVerification: PhoneVerificationService,
    private _renderer: Renderer2,
    private _toastr: ToastrService,

  ) {
    this.countryCodes = this._auth.countryCodes.sort();
    this.profileImgformGroup = this.createProfileImgFormGroup();
    this.phoneFormGroup = this.createPhoneGroup();
    this.route.queryParams.subscribe(params => {
      this.queryParam = params;
    });

    if (this._user.userDb && this._user.userDb.profile_data && this._user.userDb.profile_data.phone && this._user.userDb.profile_data.country_code) {
      this.phone_number.patchValue(this._user.userDb.profile_data.phone);
      this.country_code.patchValue(this._user.userDb.profile_data.country_code);
      // this.requestPhoneVerification();

    }
    this.generateLast4Digits();
  }

  get phone_number(): FormGroup {
    return this.phoneFormGroup.get('phone_number') as FormGroup;
  }
  get country_code(): FormGroup {
    return this.phoneFormGroup.get('country_code') as FormGroup;
  }
  get verification_code(): FormGroup {
    return this.phoneFormGroup.get('verification_code') as FormGroup;
  }

  generateLast4Digits() {
    this.last4digits = this.phone_number.value.replace(/\d(?=\d{4})/g, "*");
  }

  createProfileImgFormGroup(): FormGroup {
    return this._formBuilder.group({
      profile_image_url: '',
    });
  }

  createPhoneGroup(): FormGroup {
    return this._formBuilder.group({
      phone_number: [''],
      country_code: [44],
      verification_code: ['',  [Validators.required]]
    });
  }

  setDefaultProfilePicture() {
    if (!this.hasProfilePic) {
      this.showSpinner = true;
      this._user.updateProfileImage(this.defaultImage)
        .pipe(
          map(() => this.profileImageSet()),
          finalize(() => this.showSpinner = false),
        )
        .subscribe(
          () => console.log('done'),
          error => {
            console.log(error);
          }
        );
    }
    // this.showPhoneVerification = true;
    else{
      this.profileImageSet();
    }
    this.showPhoneSpinner = false;
    this._user.updateUserPrivate({ is_phone_verified: true });
  }

  profileImageSet() {
    console.log('SENDING MESSAGE NOW');
    // this.showPhoneVerification = true;
    this.successScreen= true;
    this.hasProfilePic = true;
    setTimeout(() => {
      this.showTick = true;
    }, 600);
  }

  onUpStart = () => {
    this.showSpinner = true;
  };

  onUploadedFile = (task: BunkUploaderModel) => {
    this._user.updateProfileImage(task.image.image_original_url).pipe(
      map(() =>  this.profileImageSet()),
      finalize(() => this.showSpinner = false)
    ).subscribe();

    this.showPhoneVerification = true;

  };

  verifyPhone() {
    if (this.phoneFormGroup.valid && this.verification_code.value.length === 4) {
      this.showPhoneSpinner = true;
    this.phoneFormGroup.disable();
    const vcs = {
      verification_code: this.verification_code.value,
      phone_number: this._user.userDb.profile_data.phone,
      country_code: this._user.userDb.profile_data.country_code
    };

    this._phoneVerification.verifyPhone(vcs)
      .pipe(
        flatMap(() => this._user.updateUserPrivate({ is_phone_verified: true }))
      ).subscribe(() => {
        this.successScreen = true;
        this.showPhoneSpinner = false;
        this.phoneFormGroup.enable();
        setTimeout(() => {
          this.showTick = true;
        }, 600);
      });
}

  }

  requestPhoneVerification(is_resent: boolean = false) {
    this.showPhoneSpinner = is_resent;
    this.verification_code.setErrors(null);
    this.generateLast4Digits();

    return ((is_resent)
      ? this._user.updateUserPhoneNumber(this.phone_number.value, this.country_code.value)
      : of({})).pipe(
      switchMap(() => this._phoneVerification.requestPhoneVerification(this.phone_number.value, this.country_code.value)),
      finalize(() => this.showPhoneSpinner = false)
    ).subscribe((data: any) => {
        if (data.success) {
          this.resendCode = false;
          this.verification_code.patchValue(null);
          // this.vc2.patchValue(null);
          // this.vc3.patchValue(null);
          // this.vc4.patchValue(null);
          setTimeout(() => {
            if(this.showPhoneVerification) {
              this.focusInput();

            }
            this._toastr.success(data.message);
          }, 1000);
        } else {
          this._toastr.error(data.errors.message);
        }

      });
  }

  focusInput() {
    setTimeout(() => {

    const el = this._renderer.selectRootElement('#code');
    this.autoFocus(el);

    }, 300);

  }

  autoFocus(el) {
    setTimeout(() => el.focus(), 100);
  }

  showPhoneNumber() {
    this.resendCode = true;
    setTimeout(() => {
      const el = this._renderer.selectRootElement('#phoneNumber');
      this.autoFocus(el);
    }, 600);
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}

import { Component, HostListener, Input, ViewChild, TemplateRef, Output, EventEmitter, OnDestroy, SimpleChanges, OnChanges } from '@angular/core';
import { Location } from '@angular/common';
import { FilePreviewOverlayService } from './file-preview-overlay.service';
import { FilePreviewOverlayRef } from './file-preview-overlay-ref';
import { Subscription } from 'rxjs';

@Component({
  selector: 'bunk-overlay',
  template: `
    <ng-content></ng-content>
  `,
  providers: [ FilePreviewOverlayService ]
})
export class BunkOverlayComponent implements OnDestroy, OnChanges {
  @Input() autoOpen: any;
  @Input() file: any;
  @Input() pdf: any;
  @Input() template: TemplateRef<any>;
  @Output() closed: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() overlayRef: EventEmitter<FilePreviewOverlayRef> = new EventEmitter<FilePreviewOverlayRef>();
  
  overlayRefSub: Subscription;
  overlayRefcloseSub: Subscription;
  locationSub: any;

  @HostListener('click', ['$event.target'])
  onClick(target) {
    this.showPreview();
  }

  constructor(
    private previewDialog: FilePreviewOverlayService,
    private location: Location
    ) {}

  showPreview() {
    const dialogOverlayRef: FilePreviewOverlayRef = this.previewDialog.open({ image: this.file, template: this.template, pdf: this.pdf });
    this.overlayRef.emit(dialogOverlayRef.componentInstance.dialogRef);
    const userPath = this.location.path();

    this.overlayRefSub = dialogOverlayRef.afterClosed().subscribe(() => {
      this.closed.emit(true);
      this.overlayRefSub.unsubscribe();
      this.overlayRefcloseSub.unsubscribe();
      this.locationSub.unsubscribe();
    });

    // Allow us to remain on the underlying page and trick the browser by simply adding a forward slash to the end of the URL
    this.location.go(`${userPath}/`);

    this.locationSub = this.location.subscribe((e) => {
      dialogOverlayRef.close();
      this.locationSub.unsubscribe();
      this.location.replaceState(userPath);
    });

    this.overlayRefcloseSub = dialogOverlayRef.beforeClose().subscribe(() => {
      this.location.replaceState(userPath);
    });
  }

  ngOnChanges(changes: any) {
    if (changes.autoOpen && changes.autoOpen.currentValue) {
      setTimeout(() => this.showPreview(), 100);
    }
  }

  ngOnDestroy(): void {
    if (this.overlayRefSub) {
      this.overlayRefSub.unsubscribe();
      this.overlayRefcloseSub.unsubscribe();
      this.locationSub.unsubscribe();
    }
  }

}

/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./payments-manage.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../components/payments-overview/payments-overview.component.ngfactory";
import * as i3 from "../components/payments-overview/payments-overview.component";
import * as i4 from "../../core/services/auth.service";
import * as i5 from "../../services/types.service";
import * as i6 from "../../services/user.service";
import * as i7 from "@angular/forms";
import * as i8 from "@angular/common";
import * as i9 from "@angular/router";
import * as i10 from "../../services/math.service";
import * as i11 from "./payments-manage.component";
var styles_PaymentsManageComponent = [i0.styles];
var RenderType_PaymentsManageComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_PaymentsManageComponent, data: {} });
export { RenderType_PaymentsManageComponent as RenderType_PaymentsManageComponent };
export function View_PaymentsManageComponent_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "payments-overview", [], null, null, null, i2.View_PaymentsOverviewComponent_0, i2.RenderType_PaymentsOverviewComponent)), i1.ɵdid(1, 49152, null, 0, i3.PaymentsOverviewComponent, [i4.AuthService, i5.TypesService, i6.UserService, i7.FormBuilder, i8.TitleCasePipe, i9.Router, i9.ActivatedRoute, i10.MathService], null, null)], null, null); }
export function View_PaymentsManageComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 1, "payments-manage", [], null, null, null, View_PaymentsManageComponent_0, RenderType_PaymentsManageComponent)), i1.ɵdid(1, 114688, null, 0, i11.PaymentsManageComponent, [], null, null)], function (_ck, _v) { _ck(_v, 1, 0); }, null); }
var PaymentsManageComponentNgFactory = i1.ɵccf("payments-manage", i11.PaymentsManageComponent, View_PaymentsManageComponent_Host_0, {}, {}, []);
export { PaymentsManageComponentNgFactory as PaymentsManageComponentNgFactory };

import { Injectable } from '@angular/core';
import { from, Observable, combineLatest } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { AngularFirestore } from '@angular/fire/firestore';

import { IPaymentsFilter, IPaymentCombined } from '@rentbunk/bunk-models';
import { IBaseResponse, IBaseRequest } from '@rentbunk/bunk-models';

import { AlgoliaAdapter } from './algolia-adapter';

export type IPaymentsResponse = IBaseResponse<IPaymentCombined>;
export type IPaymentsRequest = IBaseRequest<IPaymentsFilter>;

import { environment } from '@env/environment';
import { IPayment, IProperty, IUser, ITransactionRegistry } from '@rentbunk/bunk-models';

@Injectable()
export class PaymentsNewService {

  private indexName = 'payments'; // <-- replace with enum data

  constructor(
    private _algolia: AlgoliaAdapter,
    private _http: HttpClient,
    private afs: AngularFirestore
  ) {
  }

  getPaymentById(payment_id: string): Observable<IPayment> {
    return this.afs.collection<IPayment>('payments').doc(payment_id).valueChanges() as any
  }

  getCombinedPaymentById(payment_id: string): Observable<IPaymentCombined> {
    return this._algolia.getByObjectId(this.indexName, payment_id)
  }

  getPaymentRegistries(payment_id: string): Observable<ITransactionRegistry[]> {
    return this.afs.collection<ITransactionRegistry>('transactions_registry_in',
      ref => ref.where('payment_id', '==', payment_id)).valueChanges() as any
  }

  getPayments(request: IPaymentsRequest, isAll = false): Observable<IPaymentsResponse> {
    return this._algolia.getFilteredAlgoliaData<IPaymentCombined>(this.indexName, request, isAll);
  }

  deletePayment(item: any) {
    console.log(item, 'ITEM  in CF');
    return this._http.post(environment.firebaseConfig.apiUrl + '/payments-adminDeletePayment', { ...item });
  }

  updatePayment(payment_id: string, due_date: any, amount: number, notes: any, is_being_held: boolean = false) {
    console.log({ is_being_held });
    return this._http.post(environment.firebaseConfig.apiUrl + '/payments-adminUpdatePayment', { payment_id, due_date, notes, amount, is_being_held });
  }

  markPaymentAsPaid(payment_id: string, date_paid: any, status: string, notes: string) {
    console.log(status, 'CF');
    return this._http.post(environment.firebaseConfig.apiUrl + '/payments-updatePaymentStatus', { payment_id, date_paid, status, notes });
  }

  disassociatePayment(payment_id: string) {
    console.log(payment_id, 'payment_id');
    console.log(environment.firebaseConfig.apiUrl, 'environment.firebaseConfig.apiUrl');
    return this._http.post(environment.firebaseConfig.apiUrl + '/transactions-httpDisassociatePayment', { payment_id });
  }

  disassociateMultiplePayments(payment_ids: string[]) {
    console.log(payment_ids, 'payment_ids');
    console.log(environment.firebaseConfig.apiUrl, 'environment.firebaseConfig.apiUrl');
    return this._http.post(environment.firebaseConfig.apiUrl + '/transactions-httpDisassociateMultiplePayments', { payment_ids });
  }

}

import {Component, Input, OnChanges, SimpleChanges, ViewChild, OnInit} from '@angular/core';
import {StepModel} from '../_models/form-steps.model';

@Component({
  selector: 'step-bar',
  templateUrl: './step-bar.component.html',
  styleUrls: ['./step-bar.component.scss']
})
export class ProgressBarComponent implements OnInit, OnChanges {
  @Input() steps: Array<StepModel>;

  @Input('activeStepIndex')
  set activeStepIndex(index) {
    this._indexStep = index;
  }

  get activeStepIndex() {
    return this._indexStep;
  }

  @ViewChild('progressBar', { static: true }) progressBar;

  headline: string;
  responsiveStep: string;
  _indexStep: number;

  constructor() {
  }

  ngOnInit() {
    /// bring steps to default status
    for (const step of this.steps) {
      step.isComplete = false;
      step.isActive = false;
      this.steps[0].isActive = true;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    this.updateProgressBarWidth();
    this.updateHeadline();
    this.updateResponsiveBar();
  }

  updateProgressBarWidth() {
    const widthBar = 100 / (this.steps.length - 1);
    const updatedWidth = this.activeStepIndex * widthBar;
    this.progressBar.nativeElement.style.width = updatedWidth + '%';
  }

  updateHeadline() {
    this.headline = this.steps[this.activeStepIndex].headline;
  }

  updateResponsiveBar() {
    this.responsiveStep = this.steps[this.activeStepIndex].text;
  }
}

import { NgModule } from '@angular/core';

import { FormCreatePaymentsComponent } from './form-create-payments.component';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule, MatInputModule, MatSelectModule, MatDatepickerModule } from '@angular/material';
import { BunkFormFieldModule } from '../form-field-wrapper';
import { FormGetTenancyDetailsModule } from '../form-get-tenancy-details';
import { ProcessingSpinnerModule } from '../../_components';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,

    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatDatepickerModule,

    BunkFormFieldModule,
    FormGetTenancyDetailsModule,
    ProcessingSpinnerModule
  ],
  exports: [FormCreatePaymentsComponent],
  declarations: [FormCreatePaymentsComponent],
  providers: [],
})
export class FormCreatePaymentsModule { }

import {
  Directive, HostListener, Input, OnInit, OnDestroy, ComponentRef,
  ElementRef, TemplateRef, Output, EventEmitter, OnChanges, SimpleChanges, SimpleChange
} from '@angular/core';
import { Overlay, OverlayRef, OverlayPositionBuilder } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { PopoverComponent } from './popover.component';

export enum PopoverPositionEnum {
  bottom = 'bottom',
  bottom_left = 'bottom-left',
  bottom_right = 'bottom-right',
  top = 'top',
  top_left = 'top-left',
  top_right = 'top-right',
  left = 'left',
  left_bottom = 'left-bottom',
  left_top = 'left-top',
  right = 'right',
  right_bottom = 'right-bottom',
  right_top = 'right-top',
}

const OFFSET = 8;

const POSITIONS = {
  [PopoverPositionEnum.top]: {
    originX: 'center',
    originY: 'top',
    overlayX: 'center',
    overlayY: 'bottom',
    offsetY: -OFFSET
  },
  [PopoverPositionEnum.top_left]: {
    originX: 'start',
    originY: 'top',
    overlayX: 'start',
    overlayY: 'bottom',
    offsetY: -OFFSET
  },
  [PopoverPositionEnum.top_right]: {
    originX: 'end',
    originY: 'top',
    overlayX: 'end',
    overlayY: 'bottom',
    offsetY: -OFFSET
  },
  [PopoverPositionEnum.bottom]: {
    originX: 'center',
    originY: 'bottom',
    overlayX: 'center',
    overlayY: 'top',
    offsetY: OFFSET,
    panelClass: 'pointer'
  },
  [PopoverPositionEnum.bottom_left]: {
    originX: 'start',
    originY: 'bottom',
    overlayX: 'start',
    overlayY: 'top',
    offsetY: OFFSET
  },
  [PopoverPositionEnum.bottom_right]: {
    originX: 'end',
    originY: 'bottom',
    overlayX: 'end',
    overlayY: 'top',
    offsetY: OFFSET
  },
  [PopoverPositionEnum.left]: {
    originX: 'start',
    originY: 'center',
    overlayX: 'end',
    overlayY: 'center',
    offsetX: -OFFSET
  },
  [PopoverPositionEnum.left_top]: {
    originX: 'start',
    originY: 'top',
    overlayX: 'end',
    overlayY: 'bottom',
    offsetX: -OFFSET
  },
  [PopoverPositionEnum.left_bottom]: {
    originX: 'start',
    originY: 'bottom',
    overlayX: 'end',
    overlayY: 'top',
    offsetX: -OFFSET
  },
  [PopoverPositionEnum.right]: {
    originX: 'end',
    originY: 'center',
    overlayX: 'start',
    overlayY: 'center',
    offsetX: OFFSET
  },
  [PopoverPositionEnum.right_top]: {
    originX: 'end',
    originY: 'top',
    overlayX: 'start',
    overlayY: 'bottom',
    offsetX: OFFSET
  },
  [PopoverPositionEnum.right_bottom]: {
    originX: 'end',
    originY: 'bottom',
    overlayX: 'start',
    overlayY: 'top',
    offsetX: OFFSET
  }
};

const DEFAULT_POSITIONS_PRIORITY = [
  PopoverPositionEnum.bottom,
  PopoverPositionEnum.top,
];

const generatePositions = (position: PopoverPositionEnum) => {
  let positionsPriority = [...DEFAULT_POSITIONS_PRIORITY];

  const index = positionsPriority.indexOf(position);
  if (index !== -1) {
    positionsPriority.splice(index, 1);
  }

  const priorPosition = POSITIONS[position];
  if (priorPosition) {
    positionsPriority = [position, ...positionsPriority];
  }

  return positionsPriority.map(key => POSITIONS[key]);
};

@Directive({
  selector: '[appPopover]'
})
export class PopoverDirective implements OnInit, OnDestroy, OnChanges {

  @Input('appPopover') content: TemplateRef<any>;
  @Input() disabledPopover: boolean;
  @Input() disabledClickOutside: boolean;
  @Input() position: PopoverPositionEnum = PopoverPositionEnum.bottom;
  @Input() close: boolean;
  @Input() offset = OFFSET;

  @Output() opened = new EventEmitter<void>();
  @Output() closed = new EventEmitter<void>();

  private overlayRef: OverlayRef;
  private isOpened = false;

  constructor(
    private overlayPositionBuilder: OverlayPositionBuilder,
    private elementRef: ElementRef,
    private overlay: Overlay) { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.close && !changes.close.firstChange && changes.close.currentValue) {
      this.hide();
    }
  }

  ngOnDestroy() {
    this.hide();
  }

  @HostListener('click', ['$event']) onClick() {
    if (this.disabledPopover) {
      return;
    }

    if (!this.isOpened) {
      this.show();
    } else {
      this.hide();
    }
  }

  private init() {
    const positionStrategy = this.overlayPositionBuilder
    .flexibleConnectedTo(this.elementRef)
    .withLockedPosition()
    .withPositions(
      generatePositions(this.position) as any
    );

    this.overlayRef = this.overlay.create({
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.reposition()
    });
  }

  private destroy() {
    if (this.overlayRef) {
      this.overlayRef.detach();
    }
  }

  private show() {
    this.init();

    this.isOpened = true;
    this.opened.emit();
    // Create tooltip portal
    const popoverPortal = new ComponentPortal(PopoverComponent);

    // Attach tooltip portal to overlay
    const popoverRef: ComponentRef<PopoverComponent> = this.overlayRef.attach(popoverPortal);

    // Pass content to tooltip component instance
    popoverRef.instance.content = this.content;
    popoverRef.instance.disabledClickOutside = this.disabledClickOutside;
    popoverRef.instance.callback = () => {
      this.hide();
    };
  }

  private hide() {
    this.isOpened = false;
    this.closed.emit();
    this.destroy();
  }

}

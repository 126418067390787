import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PageEvent } from '@angular/material';
import { IBasePagination } from '@rentbunk/bunk-models';

import { TABLE_ITEMS_PP } from '@app/_constants/forms.constants';

const DEFAULT_PAGINATION: IBasePagination = {
  page: 0,
  hitsPerPage: 10,
  totalHits: 0
};
@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaginatorComponent implements OnInit {

  @Input() set pagination(value: IBasePagination) {
    this._pagination = value ? { ...this._pagination, ...value } : this._pagination;
  }
  get pagination(): IBasePagination {
    return this._pagination;
  }

  @Output() changed = new EventEmitter<IBasePagination>();

  private _pagination: IBasePagination = DEFAULT_PAGINATION;
  public pageSizeOptions: number[] = TABLE_ITEMS_PP;

  constructor() { }

  ngOnInit() {
  }

  public onPageChanged(event: PageEvent) {
    this._pagination = { ...this._pagination, page: event.pageIndex, hitsPerPage: event.pageSize };
    this.changed.emit(this.pagination);
  }

}

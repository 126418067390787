import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { BunkPassportService } from '@app/services';

import { Tenant } from '@env/routing';
import { Observable, Subject, of } from 'rxjs';
import { takeUntil, catchError} from 'rxjs/operators';
import { TenantVerifyEventEmiterService } from '../profile-tenant.service';
import { TenantAccountStepComponent } from './tenant-account-step.component';


@Component({
  selector: 'app-tenant-account-verify-employment',
  template: ` 

      <div>
        <h2 class="text--lg">Employment details</h2>
        <p>Please fill in information about your employer. We will not contact your referee until you have had an offer accepted on a property.</p>
      </div>

      <form class="cc" [formGroup]="formGroup" (ngSubmit)="nextStep(formGroup.value)">
          
        <form-employment-details formControlName="employment_details"></form-employment-details>

        <div class="margin-bottom--xl">
          <button *ngIf="showPrev" (click)="goBack()" data-test="backButton" class="btn__seethrough btn__form-prev">Back</button>
          <button class="btn__generic btn__form-submit btn__form-submit--align-right" type="submit">
            <!--[disabled]="formGroup.invalid || submittingData">-->
            <span *ngIf="!submittingData && !submittingDataSuccess">Save<span *ngIf="!standAlone"> and continue</span></span>
            <processing-spinner *ngIf="submittingData || submittingDataSuccess" color="#FFFFFF" [complete]="submittingDataSuccess"></processing-spinner>
          </button>
        </div>
        
      </form>

  `,
  styleUrls: ['./tenant-account-step.component.scss'],
})
export class TenantAccountVerifyEmploymentComponent extends TenantAccountStepComponent implements OnInit, OnDestroy {
  @Input() standAlone = false;
  @Input() reference?: any;

  public formGroup: FormGroup;
  public queryParams: string;
  public routes = { Tenant }


  private destroy$: Subject<boolean> = new Subject<boolean>();

  constructor(
    _verifyEvent: TenantVerifyEventEmiterService,
    private _formBuilder: FormBuilder,
    public _passport: BunkPassportService
    
  ) {
    super(_verifyEvent);
    this.formGroup = this.initFormGroup();
   
  }

  public employmentStatus = [
    { value: 'full_time', text: 'Full-time' },
    { value: 'part_time', text: 'Part-time' },
    { value: 'self_employed', text: 'Self-employed' },
    { value: 'unemployed', text: 'Unemployed' },
  ];

  get start_date(): FormControl {
    return this.formGroup.get('start_date') as FormControl;
  }
  get type(): FormControl {
    return this.formGroup.get('type') as FormControl;
  }

  initFormGroup(): FormGroup {
    return this._formBuilder.group({
      employment_details: [null]
    });
  }

  
  nextStep(form: any) {
    console.log(form, 'form');
    const reference_id = this.reference ? this.reference.bunk_passport_reference_id : null;

    const finalForm = {
      employment_details : {
        ...form.employment_details,
        referee_phone: form.employment_details.referee_phone ? form.employment_details.referee_phone.phone_number : null,
        referee_country_code: form.employment_details.referee_phone ? form.employment_details.referee_phone.area_code : null,
        bunk_passport_reference_id: reference_id,
      }
    };

    this.submittingData = true;
    this.formGroup.disable();  
    // passing in a referenceId if it exists - cloud function deals with updating
    this._passport.updateUserReferenceData(finalForm)
    .pipe(
      takeUntil(this.destroy$),
      catchError(err => of([]))
    )
    .subscribe(
      res => {
        this.stepComplete();
      },
      err => {
        console.log('HTTP Error', err);
        this.stepError();
      },
      () => {
        this.formGroup.enable();
        console.log('HTTP request completed.');
      }
    );
  }

  preloadFormGroup(form: any): void {
    const item = {
      employment_status: form.employment_status,
      job_title: form.job_title,
      referee_name: form.referee_name,
      referee_email: form.referee_email,
      referee_phone: {
        phone_number: form.referee_phone,
        area_code: form.area_code
      },
      company_name: form.company_name,
      employee_salary: form.employee_salary,
      employee_bonus: form.employee_bonus ? form.employee_bonus : null,
      start_date: form.start_date ? form.start_date.toDate() : null
    };
    this.formGroup.get('employment_details').patchValue(item);
  }



  goBack() {
    this._verifyEvent.navigateStep('prev');
  }

  ngOnInit() {
    console.log(this.reference, 'employer');
    if (this.reference) {
      this.preloadFormGroup(this.reference.reference_data);
    }
   
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}

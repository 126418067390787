import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { from as fromPromise, Observable } from 'rxjs';
import { AuthService } from '@app/core/services';
import * as i0 from "@angular/core";
import * as i1 from "@angular/fire/auth";
import * as i2 from "@angular/fire/firestore";
import * as i3 from "../core/services/auth.service";
export class NotesService {
    constructor(afAuth, afs, _auth) {
        this.afAuth = afAuth;
        this.afs = afs;
        this._auth = _auth;
        this.notesCollection = afs.collection('notes');
    }
    getPropertyViewingNotes(property_id, viewing_id) {
        return this.afs.collection('notes', ref => ref.where('viewing_id', '==', viewing_id)
            .where('property_id', '==', property_id)
            .where('type', '==', 'viewing')).valueChanges();
    }
    getMaintenanceNotes(property_id, maintenance_id) {
        return this.afs.collection('notes', ref => ref.where('maintenance_id', '==', maintenance_id)
            .where('property_id', '==', property_id)
            .where('type', '==', 'maintenance')).valueChanges();
    }
    getPropertyNotes(property_id) {
        return this.afs.collection('notes', ref => ref.where('property_id', '==', property_id)
            .where('type', '==', 'property')).valueChanges();
    }
    getPropertyNotesWithTenancy(property_id, tenancy_id) {
        return this.afs.collection('notes', ref => ref.where('property_id', '==', property_id)
            .where('tenancy_id', '==', tenancy_id)
            .where('type', '==', 'tenancy')).valueChanges();
    }
    getRoomNotes(property_id, bedroom_id) {
        return this.afs.collection('notes', ref => ref.where('property_id', '==', property_id)
            .where('bedroom_id', '==', bedroom_id)
            .where('type', '==', 'bedroom')).valueChanges();
    }
    getRoomNotesWithTenancy(property_id, bedroom_id, tenancy_id) {
        return this.afs.collection('notes', ref => ref.where('property_id', '==', property_id)
            .where('bedroom_id', '==', bedroom_id)
            .where('tenancy_id', '==', tenancy_id)
            .where('type', '==', 'tenancy')).valueChanges();
    }
    getTaskNotes(note_ids) {
        let note_id_array = this.splitArray(note_ids, 10);
        let queries = note_id_array.map(id_array => {
            return this.afs.collection('notes', ref => ref
                .where('note_id', 'in', id_array)
                .where(`is_deleted`, '==', false)).valueChanges();
        });
        return this._auth.combineMultipleQueries(queries);
    }
    saveNote(note) {
        const temp_id = this.afs.createId();
        const date = new Date();
        return fromPromise(this.afs.collection('notes').doc(temp_id).set(Object.assign({}, note, { note_id: temp_id, date_created: date, date_modified: date, date_deleted: null, is_deleted: false })));
    }
    generateId() {
        return this.afs.createId();
    }
    saveNotesWithCustomId(note, id) {
        const date = new Date();
        return fromPromise(this.afs.collection('notes').doc(id).set(Object.assign({}, note, { date_created: date, date_modified: date, date_deleted: null, is_deleted: false })));
    }
    updateNote(note) {
        const { title, description, note_id, tenancy_id, type, media_ids, note_type } = note;
        const possibleUpdates = { title, description, media_ids, type, note_type, tenancy_id, date_modified: new Date(), has_edited: true };
        const updationData = {};
        Object.keys(possibleUpdates).forEach(key => possibleUpdates[key] ? updationData[key] = possibleUpdates[key] : '');
        return fromPromise(this.afs.collection('notes').doc(note_id).set(updationData, { merge: true }));
    }
    deleteNote(note_id) {
        return fromPromise(this.afs.collection('notes').doc(note_id).set({ date_deleted: new Date(), is_deleted: true }, { merge: true }));
    }
    splitArray(array, limit) {
        const result = [];
        const givenArray = array.slice(0); // make a copy without reference
        while (givenArray.length > limit) {
            result.push(givenArray.splice(0, limit));
        }
        if (givenArray.length) {
            result.push(givenArray);
        }
        return result;
    }
}
NotesService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function NotesService_Factory() { return new NotesService(i0.ɵɵinject(i1.AngularFireAuth), i0.ɵɵinject(i2.AngularFirestore), i0.ɵɵinject(i3.AuthService)); }, token: NotesService, providedIn: "root" });

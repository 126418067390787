import { Injectable } from '@angular/core';
import { AuthService } from '@app/core/services/auth.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { GroupService } from '@app/services/group.service';
import { map, tap } from 'rxjs/operators';
import {AngularFirestore} from '@angular/fire/firestore';
import {group} from '@angular/animations';

@Injectable()
export class NotificationService {
  public unreadMessages: BehaviorSubject<number> = new BehaviorSubject(null);

  constructor(
    private _auth: AuthService,
    private _group: GroupService,
    private afs: AngularFirestore
  ) {}

  /*
    returns the number of unread messages for displaying in a ui counter
  */
  public getUnreadMessageCount(): Observable<number> {
    return this._group.getAllGroupsOfCurrentUser().pipe(
      map((items: any[]) => items.filter((group: any) => group.last_read_index && this.hasUnreadGroupMessages(group))),
      map((d: any) => d.length),
    );
  }

  markChatNotificationAsRead(group_id: string) {
    return  this.afs.collection('notifications', ref => ref
      .where(`uid`, '==', this._auth.currentUserId)
      .where('reference_id', '==', group_id)
      .where('notification_is_viewed', '==', false))
      .valueChanges().pipe(
        map((res: any) => {
          if (res) {
            const batch = this.afs.firestore.batch();
            for (const notification of res) {
              batch.set(this.afs.collection('notifications').doc(notification.notification_id).ref, {
                notification_is_viewed: true
              }, {merge: true});
            }
            return batch.commit();
          }
        })
      );
  }

  hasUnreadGroupMessages(group: any): boolean {
    const hasInitialMessage = group.last_message
      && group.last_message.index === 0
      && group.last_read_index[this._auth.currentUserId] === null
      && group.user_notified[this._auth.currentUserId]
      && Object.values(group.last_read_index).some(item => item !== null);

    const hasMail = group.last_message
      && group.last_message.index > group.last_read_index[this._auth.currentUserId];

    return hasInitialMessage || hasMail;
  }
}

import {Component, Input, ViewChild} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {Router} from '@angular/router';

import {AuthService} from '@app/core/services';
import {FeedbackService, UserService} from '@app/services';

import {ModalBasicComponent} from '@app/shared/_components/modal/modal-basic.component';

import {ToastrService} from 'ngx-toastr';

import {environment} from '@env/environment';
import {Observable} from 'rxjs/internal/Observable';
import {finalize} from 'rxjs/operators';

@Component({
  selector: 'form-settings',
  templateUrl: './form-settings.component.html',
  styleUrls: ['./form-settings.component.scss']
})
export class FormSettingsComponent {
  @ViewChild(ModalBasicComponent, { static: false }) modal;
  @Input() user: any;
  @Input() formGroup: FormGroup;
  @Input() email: string;
  @Input() meta_data: any;
  modal_open = false;
  modalReference: any;
  edit = 0;

  environment = environment;
  showSpinner = false;

  constructor(private _user: UserService,
              private _auth: AuthService,
              private _toastr: ToastrService,
              private _feedback: FeedbackService,
              public router: Router) {
  }


  editItem() {
    this.edit = 0;
  }

  updateUserInfo() {
    this._user.updateUserAccount(this.formGroup.getRawValue());
  }

  updateUserEmail() {
    const email = this.formGroup.get('email').value;
    const currentPassword = this.formGroup.get('current_password').value;
    this._user.updateUserEmail(email, currentPassword)
      .subscribe(
        () => {
          this._toastr.success('Email has been updated!');
          this.formGroup.controls.current_password.reset();
        },
        err => {
          console.log('An error has occurred: ', err);
          this._toastr.error('An error has occurred!');
        }
      );
  }

  updateUserPassword() {
    const currentPassword = this.formGroup.get('current_password').value;
    const newPassword = this.formGroup.get('password').value;
    this._auth.updateUserPassword(currentPassword, newPassword)
      .subscribe(
        () => {
          this._toastr.success('Password has been updated!');
          this.formGroup.controls.current_password.reset();
          this.formGroup.controls.password.reset();
        },
        err => {
          console.log('An error has occurred: ', err);
          this._toastr.error('An error has occurred!');
        }
      );
  }

  cancelAccount() {
    this._feedback.createAccountCancellationDocument(this.formGroup.controls.description.value)
      .subscribe(() => {
        this.modalReference.close();
        this.modal_open = false;
        this._auth.logout()
          .subscribe(() => this.router.navigate(['/signin']), err => console.log(err));
      });

  }


  orderByDate(prop: any[]) {
    return prop.sort((a, b) => {
      return b.latest_login.toDate().getTime() > a.latest_login.toDate().getTime()
        ? 1
        : -1;
    });
  }

}

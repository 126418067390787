import { Component, OnDestroy, Input } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material';
import { UserService } from '@app/services/user.service';
import { MY_FORMATS } from '@app/shared/_services/dates.service';
import { MIN_TENANT_AGE, TITLES, COUNTRY_CODES, GENDER_VALUES } from '@app/_constants/forms.constants';
import { BunkPassportService } from '@app/services';
import { Tenant, Profile } from '@env/routing';
import { AuthService } from '@app/core/services';
import { BunkPassportModel } from '@app/_models/bunk_passport.model';
import { tap, takeUntil, catchError, first, finalize } from 'rxjs/operators';
import { Observable, Subject, of } from 'rxjs';
import { TenantVerifyEventEmiterService } from '../profile-tenant.service';
import { TenantAccountStepComponent } from './tenant-account-step.component';
import * as moment from 'moment';
import { BunkUploaderModel } from '@app/shared/_components';
import { ToastrService } from 'ngx-toastr';
import { TitleCasePipe } from '@angular/common';

@Component({
  selector: 'tenant-account-verify-info',
  template: `
    <ng-container *ngIf="(passport$ | async) as passport">
      <div>
        <h2 class="text--lg">Official details</h2>
        <p data-test="landlordVerificationTitle">Please complete all information as it appears <strong>on your passport</strong>. This will enable you to be verified faster</p>
      </div>

      <form [formGroup]="formGroup" (ngSubmit)="nextStep(formGroup.value)">
        <label formGroupName="profile_image_url" #profileUploader>
          <bunk-uploader #profileUploader
                         [filePath]="filePath"
                         (uploadChange)="onUploadedFile($event)"
                         (uploadStart)="onUpStart()"
                         [multiple]="false"
                         hidden>
          </bunk-uploader>
        </label>
        <div class="cc ai_c margin-bottom--md">
          <div class="img_container">
            <ng-container *ngIf="!showSpinner; else spinner">
              <div>

              <profile-avatar size="lg" justified="center" [profileImgUrl]="_user.userDb?.profile_data.profile_image_url" [profileName]="_user.userDb?.profile_data.full_name"></profile-avatar>

                <!-- <img class="profile_image margin-bottom--sm" [src]="_user?.userDb.profile_data.profile_image_url" alt=""> -->
              </div>
              <label for="image" class="text">
                <div *ngIf="_user?.userDb.profile_data.profile_image_url !== null || !_user?.userDb.profile_data.profile_image_url " class="overlay">
                  <img class="delete" src='assets/svg/delete-bin.svg' (click)="remove()">
                </div>
              </label>
            </ng-container>
            <ng-template #spinner>
              <div class="img-background">
                <processing-spinner></processing-spinner>
              </div>
            </ng-template>
          </div>
          <p class="mb_2">Upload a profile image</p>
          <label for="property-pictures-upload" (click)="profileUploader.click()" class="">
            <button class="btn__seethrough btn--round" type="button" [disabled]="submittingData">Choose file</button>
          </label>
        </div>

        <form-info formControlName="info"></form-info>

        <div class="margin-bottom--xl">
          <button *ngIf="showPrev" (click)="goBack()" data-test="backButton" class="btn__seethrough btn__form-prev">Back</button>
          <button class="btn__generic btn__form-submit btn__form-submit--align-right" type="submit">
            <!--[disabled]="formGroup.invalid || submittingData">-->
            <span *ngIf="!submittingData && !submittingDataSuccess">Save<span *ngIf="!standAlone"> and continue</span></span>
            <processing-spinner *ngIf="submittingData || submittingDataSuccess" color="#FFFFFF" [complete]="submittingDataSuccess"></processing-spinner>
          </button>
        </div>
      </form>
    </ng-container>
  `,
  styleUrls: ['./tenant-account-step.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
  ]
})
export class TenantAccountVerifyInfoComponent extends TenantAccountStepComponent implements OnDestroy {
  @Input() showPrev: boolean;
  @Input() standAlone = false;
  public formGroup: FormGroup;
  public userGender = GENDER_VALUES;
  public routes = { Tenant };
  public showAddress = false;
  public avatar = 'assets/img/icons/bunk-default-avatar.svg';
  public passport$: Observable<BunkPassportModel>;
  public filePath = `user/${this._auth.currentUserId}/profile`;
  private destroy$: Subject<boolean> = new Subject<boolean>();
  public titles: object[];
  public countryCodes: (string | number)[];
  public formGroupInvalid: boolean;
  showSpinner = false;

  // state
  queryParams: string;
  minAgeYear: number;

  constructor(
    _verifyEvent: TenantVerifyEventEmiterService,
    public _user: UserService,
    private _formBuilder: FormBuilder,
    private _auth: AuthService,
    private _passport: BunkPassportService,
    private _titleCasePipe: TitleCasePipe
  ) {
    super(_verifyEvent);
    this.formGroup = this.initFormGroup();
    this.minAgeYear = new Date().getFullYear() - MIN_TENANT_AGE;
    this.titles = TITLES;
    this.countryCodes = COUNTRY_CODES.sort();

    this.passport$ = this._passport.getUsersBunkPassportById().valueChanges()
      .pipe(
        first(),
        tap((passport: BunkPassportModel) => {
          if (passport.personal_details) {
            console.log(passport, 'passport')
            this.preloadFormGroup(passport);
          }
        })
      );
  }

  get profile_image_url(): FormControl {
    return this.formGroup.get('profile_image_url') as FormControl;
  }

  initFormGroup(): FormGroup {
    return this._formBuilder.group({
      info: [null],
      profile_image_url: [null],
    });
  }

  preloadFormGroup(form: any): void {
    const personalDetails = form.personal_details;
    console.log(personalDetails, 'personalDetails')
    const item = {
      profile_image_url: personalDetails.profile_image_url ? personalDetails.profile_image_url
      : this._user.userDb.profile_data.profile_image_url,
        info: {
          title: personalDetails.title,
          first_name: personalDetails.first_name,
          middle_name: personalDetails.middle_name ? personalDetails.middle_name : null,
          last_name: personalDetails.last_name,
          dob: personalDetails.dob ? personalDetails.dob.toDate() : null,
          gender: personalDetails.gender ? personalDetails.gender : null,
          phone: { 
            phone_number: personalDetails.phone,
            area_code: personalDetails.country_code ? personalDetails.country_code : +44,
          },
          profile_image_url: personalDetails.profile_image_url ? personalDetails.profile_image_url
          : this._user.userDb.profile_data.profile_image_url,
          address_details: {
            ...personalDetails.address_details
          }
        }
    };

    this.formGroup.patchValue(item);
  }


  nextStep(form: any) {
    const finalForm = {
      info: {
        ...form.info,
        address_details: form.info && form.info.address_details && form.info.address_details !== null ? this._passport.formatAddress(form.info.address_details) : null,
        phone: form.info.phone ? form.info.phone.phone_number : null,
        country_code: form.info.phone.area_code ? form.info.phone.area_code : null
      }
    };
    console.log(finalForm, 'final form');

    this.submittingData = true;
    this.formGroup.disable();
    this.profile_image_url.disable();

    this._passport.updateUserReferenceData(finalForm)
      .pipe(
        takeUntil(this.destroy$),
        catchError(err => of([]))
      )
      .subscribe(
        res => {
          this.stepComplete();
        },
        err => {
          console.log('HTTP Error', err);
          this.stepError();
        },
        () => {
          this.formGroup.enable();
          this.profile_image_url.enable();
          console.log('HTTP request completed.');
        }
      );
  }

  remove() {
    this._passport.updateProfileImage('assets/img/icons/bunk-default-avatar.svg')
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        success => {
          console.log('Success upload Profile Picture');
        },
        error => console.log(error)
      );
  }

  goBack() {
    this._verifyEvent.navigateStep('prev');
  }

  onUpStart = () => {
    this.showSpinner = true;
  };

  onUploadedFile = (task: BunkUploaderModel) => {
    this._passport.updateProfileImage(task.image.image_original_url).pipe(
      takeUntil(this.destroy$),
      finalize(() => this.showSpinner = false)
    ).subscribe();
  };

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.unsubscribe();
  }
}
